import dayjs from "dayjs";
import { t } from "i18next";
import { store } from "../../../app/store";
import { Tooltip } from "../../../ui/Forms/Tooltip";
import { IconClose } from "../../../ui/Icon/Line/Close";
import { IconExternalLink } from "../../../ui/Icon/Line/ExternalLink";
import { IconUser } from "../../../ui/Icon/Line/User";
import { Table } from "../../../ui/Table/Table";
import {
  AbstractTableBuilder,
  Row,
} from "../../../ui/Table/utils/AbstractTableBuilder";
import "../../../ui/Table/utils/TableBuilder.css";
import { ConvertTimeZone } from "../../../utils/DateAndTimeUtils";
import {
  convertEuroOnCurrency,
  formatData,
  getAddress,
  getCity,
  getQueryString,
  kmToMiles,
  ltToGal,
  mtToKm,
} from "../../../utils/Utils";
import { Driver } from "../../driver/driversSlice";
import { deleteEventAsync } from "../../event/eventsSlice";
import { Preferences } from "../../users/preference/preferencesSlice";
import { RefuellingReports } from "./refuellingReportSlice";

export class RefuellingReportTableBuilder extends AbstractTableBuilder {
  /**
   * Given data, it builds a table row
   * @param vehicle
   * @returns
   */
  rowsBuilder = (
    columns: { label: string; field: string; sort: boolean }[] | undefined,
    entities: {
      refuellingReport: RefuellingReports;
      driver: Driver;
    },
    preferencesContext: Preferences
  ) => {
    let row: Row = { render: {} as Row };

    let refuellingReport: RefuellingReports =
      entities.refuellingReport ?? ({} as RefuellingReports);

    let driver: Driver = entities.driver ?? ({} as Driver);

    // #region Support methods
    /**
     * ATTENZIONE: QUESTO METODO PUO' ESSERE USATO PREVIA APPROVAZIONE DAL RESPONSABILE TECNICO
     *
     */
    const handleButtonClick = () => {
      store.dispatch(
        deleteEventAsync({
          vehicleId: refuellingReport.vehicleId,
          timestamp: refuellingReport.date,
          name: "FUEL_REFILLED",
        })
      );
    };
    //#endregion

    columns?.forEach((column) => {
      switch (column.field) {
        case "driver":
          row.render.driver = driver.firstName && (
            <Table.TextIcon
              text={driver.firstName + " " + driver.lastName}
              icon={
                <IconUser
                  size={14}
                  color="var(--global-colors-ink-light)"
                  class="classTravelDriver"
                />
              }
            />
          );
          break;
        case "dateTime":
          if (refuellingReport?.date !== undefined) {
            const startTime = ConvertTimeZone(
              refuellingReport?.date,
              preferencesContext.timeZone ?? undefined,
              preferencesContext.localeFormat ?? undefined
            ).split(" ");
            row.render.date = (
              <>
                <p className="reportMarked">{startTime[0]}</p>
                <p className="reportNormal">{startTime[1]}</p>
              </>
            );
          } else {
            row.render.date = (
              <>
                <p className="reportMarked">--:--:----</p>
                <p className="reportNormal">--:--:--</p>
              </>
            );
          }
          break;
        case "initialLevel":
          row.render.initialLevel =
            refuellingReport?.initialLevel !== undefined
              ? `${formatData(
                  refuellingReport?.initialLevel,
                  ltToGal,
                  preferencesContext,
                  2
                )} ${preferencesContext.isMetric ? "lt" : "gal"}`
              : t("common.na");
          break;
        case "refueledLevel":
          row.render.refueledLevel =
            refuellingReport?.refueledLevel !== undefined
              ? `${formatData(
                  refuellingReport?.refueledLevel,
                  ltToGal,
                  preferencesContext,
                  2
                )} ${preferencesContext.isMetric ? "lt" : "gal"}`
              : t("common.na");
          break;
        case "postRefuelLevel":
          row.render.postRefuelLevel =
            refuellingReport?.postRefuelLevel !== undefined
              ? `${formatData(
                  refuellingReport?.postRefuelLevel,
                  ltToGal,
                  preferencesContext,
                  2
                )} ${preferencesContext.isMetric ? "lt" : "gal"}`
              : t("common.na");
          break;
        case "odometer":
          row.render.odometer =
            refuellingReport.odometer !== undefined
              ? `${formatData(
                  mtToKm(refuellingReport.odometer),
                  kmToMiles,
                  preferencesContext,
                  0
                )} ${preferencesContext.isMetric ? "km" : "mil"}`
              : "--";
          break;
        case "refuelAddress":
          row.render.refuelAddress = refuellingReport?.refuelAddress ? (
            <>
              <p className="reportMarked">
                {getCity(refuellingReport?.refuelAddress)}
              </p>
              <p className="reportNormal">
                {getAddress(refuellingReport?.refuelAddress)}
              </p>
            </>
          ) : (
            t("common.na")
          );
          break;
        case "price":
          row.render.price =
            refuellingReport?.price !== undefined
              ? preferencesContext.currency === "EURO"
                ? `${formatData(
                    refuellingReport.price,
                    0,
                    preferencesContext,
                    2
                  )} €/l`
                : `${formatData(
                    convertEuroOnCurrency("DOLLAR", refuellingReport.price),
                    0,
                    preferencesContext,
                    2
                  )} $/gal`
              : t("common.na");
          break;
        case "totalPrice":
          row.render.totalPrice =
            refuellingReport?.price !== undefined
              ? preferencesContext.currency === "EURO"
                ? `${formatData(
                    refuellingReport.totalPrice,
                    null,
                    preferencesContext,
                    2
                  )} €`
                : `${formatData(
                    convertEuroOnCurrency(
                      "DOLLAR",
                      refuellingReport.totalPrice
                    ),
                    0,
                    preferencesContext,
                    2
                  )} $`
              : t("common.na");
          break;
        default:
          break;
      }
    });

    row.render.action = (
      <>
        <Tooltip />
        <span data-for="tooltip" data-tip={t("refuelReport.tooltip")}>
          <IconExternalLink
            className=""
            size={14}
            color="--global-colors-ink-ink"
            onClick={() => {
              window.open(
                `/dashboard/vehicles/location-history/stop/${refuellingReport.routeId}` +
                  getQueryString({
                    firstPointDate: [
                      dayjs(
                        new Date(refuellingReport.date).setDate(
                          new Date(refuellingReport.date).getDate() - 1
                        )
                      ).format("YYYY/MM/DD HH:mm"),
                      dayjs(
                        new Date(refuellingReport.date).setDate(
                          new Date(refuellingReport.date).getDate() + 1
                        )
                      ).format("YYYY/MM/DD HH:mm"),
                    ],
                    "vehicle.id": refuellingReport.vehicleId ?? "",
                    currentPos: refuellingReport.date,
                  }),
                "_blank",
                "noopener,noreferrer"
              );
            }}
          />
        </span>
        {process.env.REACT_APP_DEPLOY_TARGET === "local" ||
          (process.env.REACT_APP_DEPLOY_TARGET === "stage" && (
            <IconClose
              size={12}
              onClick={handleButtonClick}
              color="--global-colors-palette-red"
            />
          ))}
      </>
    );
    return row;
  };
}
