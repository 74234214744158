import "./ListAvatar.css";
import { ReactComponent as AvatarFirst } from "./ImageAvatar/1.svg";
import { ReactComponent as AvatarSecond } from "./ImageAvatar/2.svg";
import { ReactComponent as AvatarThird } from "./ImageAvatar/3.svg";
import { ReactComponent as AvatarFourth } from "./ImageAvatar/4.svg";
import { ReactComponent as AvatarFifth } from "./ImageAvatar/5.svg";
import { ReactComponent as AvatarSixth } from "./ImageAvatar/6.svg";
import { ReactComponent as AvatarSeventh } from "./ImageAvatar/7.svg";
import { ReactComponent as AvatarEighth } from "./ImageAvatar/8.svg";
import { ReactComponent as AvatarNinth } from "./ImageAvatar/9.svg";
import { ReactComponent as AvatarTenth } from "./ImageAvatar/10.svg";
import { ReactComponent as AvatarEleventh } from "./ImageAvatar/11.svg";
import { ReactComponent as AvatarTwelfth } from "./ImageAvatar/12.svg";
import { ReactComponent as AvatarThirteenth } from "./ImageAvatar/13.svg";
import { ReactComponent as AvatarFifteenth } from "./ImageAvatar/14.svg";
import { ReactComponent as AvatarDefault } from "./ImageAvatar/default.svg";

interface ListAvatarProps {
  setAvatar: (e: any) => any;
}

export const ListAvatar = ({ setAvatar }: ListAvatarProps) => {
  const getImageAvatar = (avatarClick: any) => {
    setAvatar({
      id: avatarClick.currentTarget.id,
      image:
        "data:image/svg+xml;charset=utf-8," +
        encodeURIComponent(String(avatarClick.currentTarget.outerHTML)),
    });
  };

  return (
    <>
      <div className="cointainer-avatar">
        <div>
          <AvatarFirst id="1" onClick={getImageAvatar} />
          <AvatarSecond id="2" onClick={getImageAvatar} />
          <AvatarThird id="3" onClick={getImageAvatar} />
        </div>
        <div>
          <AvatarFourth id="4" onClick={getImageAvatar} />
          <AvatarFifth id="5" onClick={getImageAvatar} />
          <AvatarSixth id="6" onClick={getImageAvatar} />
        </div>
        <div>
          <AvatarSeventh id="7" onClick={getImageAvatar} />
          <AvatarEighth id="8" onClick={getImageAvatar} />
          <AvatarNinth id="9" onClick={getImageAvatar} />
        </div>
        <div>
          <AvatarTenth id="10" onClick={getImageAvatar} />
          <AvatarEleventh id="11" onClick={getImageAvatar} />
          <AvatarTwelfth id="12" onClick={getImageAvatar} />
        </div>
        <div>
          <AvatarThirteenth id="13" onClick={getImageAvatar} />
          <AvatarFifteenth id="14" onClick={getImageAvatar} />
          <AvatarDefault id="default" onClick={getImageAvatar} />
        </div>
      </div>
    </>
  );
};
