import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { IconCornerUpLeft } from "../Icon/Line/CornerUpLeft";
import { IconCornerUpRight } from "../Icon/Line/CornerUpRight";
import { IconTrash } from "../Icon/Line/Trash";

import "./GeofenceButton.css";

export const EditGeofences = ({
  disableCancel,
  onCancel,
  disableAhead,
  onAhead,
  disableDelete,
  onDelete,
}) => {
  const handleOnCancel = () => {
    onCancel();
  };

  const handleOnAhead = () => {
    onAhead();
  };

  const handleOnDelete = () => {
    onDelete();
  };

  const [disabled, setDisabled] = useState(true);
  useEffect(() => {
    setDisabled(disableAhead.value);
  }, [disableAhead.value]);

  return (
    <div className="edit-geofence-container">
      <div
        className={`edit-geofence-button${disableCancel ? " disable" : ""}`}
        onClick={handleOnCancel}
      >
        <IconCornerUpLeft size={14} />
      </div>
      <div
        className={`edit-geofence-button${disabled ? " disable" : ""}`}
        onClick={handleOnAhead}
      >
        <IconCornerUpRight size={14} />
      </div>
      <div
        className={`edit-geofence-button${disableDelete ? " disable" : ""}`}
        onClick={handleOnDelete}
      >
        <IconTrash size={14} />
      </div>
    </div>
  );
};

EditGeofences.propTypes = {
  disableCancel: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onAhead: PropTypes.func.isRequired,
  disableDelete: PropTypes.bool,
  onDelete: PropTypes.func.isRequired,
};

EditGeofences.defaultProps = {
  disableCancel: false,
  disableDelete: false,
};
