import { GetObjectCommand, S3Client } from "@aws-sdk/client-s3";
import { t } from "i18next";
import { ToastNotification } from "./ToastNotification";

const client = new S3Client({
  region: process.env.REACT_APP_AMAZON_S3_REGION,
  credentials: {
    accessKeyId: process.env.REACT_APP_AMAZON_S3_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AMAZON_S3_SECRET_ACCESS_KEY,
  },
});

export const downloadFileFromS3 = async (filePath, fileName, bucket) => {
  const command = new GetObjectCommand({
    Bucket: bucket,
    Key: filePath,
  });

  try {
    const response = await client.send(command);
    let blob = await new Response(response.Body).blob();
    saveFile(blob, fileName);
  } catch (err) {
    if (err.$metadata.httpStatusCode === 403) {
      ToastNotification({
        toastId: "fileNotFoundError",
        status: "error",
        description: t("common.fileNotFoundError"),
      });
    } else {
      ToastNotification({
        toastId: "networkError",
        status: "error",
        description: t("common.networkError"),
      });
    }
  }
};

const saveFile = async (blob, fileName) => {
  const a = document.createElement("a");
  a.download = fileName;
  a.href = URL.createObjectURL(blob);
  a.addEventListener("click", (e) => {
    setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000);
  });
  a.click();
};
