import i18next from "i18next";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import Form from "../Forms/Form";
import TextField from "../Forms/TextField";
import { IconAdd } from "../Icon/Line/Add";
import { IconEuro } from "../Icon/Line/Euro";
import { IconMinus } from "../Icon/Line/Minus";
import "./ScheduleCost.css";

interface ScheduleCostProps {
  defaultCost?: number;
  open?: boolean;
  typeIsEmpty?: boolean;
  getValue?: (e: any) => any;
  setIsOpened?: (e: any) => any;
}

export const ScheduleCost: React.FC<ScheduleCostProps> = ({
  defaultCost,
  open,
  getValue,
  typeIsEmpty,
  setIsOpened,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(open ?? false);
  const [cost, setCost] = useState(defaultCost ?? 0);
  const [selectedCost, setSelectedCost] = useState(defaultCost ?? 0);

  useEffect(() => {
    if (!typeIsEmpty) {
      setIsOpen(open ?? false);
      cost !== 0 && setSelectedCost(cost);
    }
  }, [open]);

  const toggleGroup = () => {
    setIsOpened && setIsOpened(1);
    setIsOpen((prevState) => !prevState);
  };

  const handleReset = (e: any) => {
    setCost(0);
    setSelectedCost(0);
    if (getValue) {
      getValue(0);
    }
    e.stopPropagation();
  };

  return (
    <>
      <div
        className={isOpen ? "schedule-cost-simple" : "schedule-cost"}
        onClick={() => {
          if (!typeIsEmpty) {
            setSelectedCost(cost);
            toggleGroup();
          }
        }}
      >
        <div className="schedule-cost-button">
          <div className="schedule-cost-circle">
            <IconEuro size={14} color="--global-colors-ui-primary" />
          </div>
          <div className="schedule-cost-label">
            {selectedCost !== 0 && (
              <>
                <span className="schedule-cost-pretitle">
                  {i18next.t("schedule.costTitle")}
                </span>
                <br />
              </>
            )}
            <span className={`schedule-cost-title`}>
              {selectedCost === 0
                ? i18next.t("schedule.costTitle")
                : selectedCost + " €"}
            </span>
          </div>

          <span className="schedule-cost-icon">
            {isOpen ? (
              <>
                <div className="schedule-cost-reset" onClick={handleReset}>
                  {i18next.t("common.reset")}
                </div>
                <IconMinus size={14} color="--global-colors-ui-primary" />
              </>
            ) : (
              <IconAdd size={14} color="--global-colors-ui-primary" />
            )}
          </span>
        </div>
      </div>
      {isOpen && (
        <div className="schedule-cost-body">
          <span className="schedule-cost-selection">
            {i18next.t("schedule.costSelection")}
          </span>
          <div className="schedule-cost-form">
            <Form>
              <TextField
                id="driverId"
                placeholder="0"
                value={cost}
                size="small"
                type="text"
                maxLength="7"
                formattedNumber={true}
                events={{
                  onChange: (data) => {
                    const valid = data.value.match("^[0-9]+.?[0-9]*$");
                    valid ? setCost(data.value) : setCost(0);
                    if (getValue && valid) {
                      getValue(data.value);
                      setCost(data.value);
                    } else if (getValue) {
                      if (defaultCost) {
                        setCost(defaultCost);
                        getValue(defaultCost);
                      } else {
                        setCost(0);
                        getValue(0);
                      }
                    }
                  },
                }}
              >
                <IconEuro size={14} color="--global-colors-ink-light" />
              </TextField>
            </Form>
          </div>
        </div>
      )}
    </>
  );
};

ScheduleCost.propTypes = {
  defaultCost: PropTypes.number,
  open: PropTypes.bool,
  typeIsEmpty: PropTypes.bool,
  getValue: PropTypes.func,
  setIsOpened: PropTypes.func,
};

ScheduleCost.defaultProps = {
  open: false,
};
