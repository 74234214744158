import HttpClient from "../../utils/HttpClient";
import { TachographFileType } from "./tachographFilesSlice";

export default class TachographFileRepository extends HttpClient {
  constructor() {
    super(true);
  }

  getTachographFilesStatusSummary(queryParams?: string) {
    const tenantId = localStorage.getItem("tenantId");
    let path = "/tenants/" + tenantId + "/tachograph/status/summary";
    return this.instance.get(path + (queryParams ?? ""));
  }

  getTachographFilesStatus(
    queryParams?: string,
    body?: { pagingState?: string }
  ) {
    const tenantId = localStorage.getItem("tenantId");
    let path = "/tenants/" + tenantId + "/tachograph/status";
    return this.instance.post(path + (queryParams ?? ""), body);
  }

  getTachographFiles(
    type: TachographFileType,
    entityId: number,
    queryParams?: string,
    body?: { pagingState?: string }
  ) {
    const tenantId = localStorage.getItem("tenantId");
    let path = "/tenants/" + tenantId + "/tachograph/" + type + "/" + entityId;
    return this.instance.post(path + (queryParams ?? ""), body);
  }

  getTachographFilesReport(
    type: TachographFileType,
    queryParams?: string,
    body?: { pagingState?: string }
  ) {
    const tenantId = localStorage.getItem("tenantId");
    let path = "/tenants/" + tenantId + "/tachograph/report/" + type;
    return this.instance.post(path + (queryParams ?? ""), body);
  }
}
