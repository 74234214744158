import { t } from "i18next";
import { FeedbackModal } from "../../ui/Modal/CustomModals/FeedbackModal";
import { Geofence } from "./geofenceSlice";

interface DeactivateGeofenceModalProps {
  geofence: Geofence;
  open: boolean;
  onClose: (id?: number) => void;
}

const DeactivateGeofenceModal: React.FC<DeactivateGeofenceModalProps> = ({
  geofence,
  open,
  onClose,
}) => {
  const nameGeofence = geofence.name.split(",")[0];
  const addressGeofence =
    geofence.name.split(",")[1] + ", " + geofence.name.split(",")[2];

  function deactivateGeofence() {
    onClose(geofence.id);
  }
  function close() {
    onClose();
  }

  return (
    <FeedbackModal
      open={open}
      title={`${t("admin.geofences.deactivateGeofence.title")}`}
      desc={`${t("admin.geofences.deactivateGeofence.desc")
        .replace("*name", nameGeofence)
        .replace("*address", addressGeofence)}`}
      primaryLabel={`${t("common.confirm")}`}
      isDanger={false}
      primaryAction={deactivateGeofence}
      secondaryLabel={`${t("common.cancel")}`}
      secondaryAction={close}
      closeAction={close}
    />
  );
};

export default DeactivateGeofenceModal;
