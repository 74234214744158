import { t } from "i18next";
import _ from "lodash";
import { useEffect, useRef, useState } from "react";
import {
  Location,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { store } from "../../app/store";
import { DropdownButton } from "../../ui/Button/DropdownButton";
import { Dropdown } from "../../ui/Dropdown/Dropdown";
import Form from "../../ui/Forms/Form";
import SearchField from "../../ui/Forms/SearchField";
import Select from "../../ui/Forms/Select";
import { IconAdd } from "../../ui/Icon/Line/Add";
import { IconMaintenanceDeadlines } from "../../ui/Icon/Line/MaintenanceDeadlines";
import { getQueryString } from "../../utils/Utils";
import "./MaintenanceFilterBar.css";
import {
  deadlinesEmptyState,
  getDeadlinesAsync,
  statusTypeValues,
} from "./deadlinesSlice";

interface QueryParams {
  [paramName: string]: any;
}
interface DropDownItem {
  label: string;
  value: string;
  checked: boolean;
}

interface MaintenanceFilterBarProps {
  callback: (buildQueryParam: string) => any;
}

export const MaintenanceFilterBar: React.FC<MaintenanceFilterBarProps> = ({
  callback,
}) => {
  const queryParamsRef = useRef<QueryParams>({});
  const navigate = useNavigate();
  let queryParams: QueryParams = queryParamsRef.current;

  const [vehicleStringSearch, setVehicleStringSearch] = useState<string>("");
  const [, setSelectedMaintenanceType] = useState<string[]>([]);
  const [viewSelectedMaintenance, setViewSelectedMaintenance] = useState<{}>(
    {}
  );

  // Handle routes
  const [searchParams, setSearchParams] = useSearchParams();
  const location: Location = useLocation();

  useEffect(() => {
    return function cleanUp() {
      store.dispatch(deadlinesEmptyState());
    };
  }, []);

  /**
   * This use effect executes every time the location changes.
   * It retrieves the query params from the location (from route or from state).
   */
  const selectMaintenceType = (type: string) => {
    let findMaintenance: boolean = false;
    options[1].options?.forEach((option) => {
      if (option.value === type) {
        setViewSelectedMaintenance({
          value: option.value,
          label: option.label,
        });
        findMaintenance = true;
      }
    });

    !findMaintenance &&
      options[2].options?.forEach((option) => {
        if (option.value === type) {
          setViewSelectedMaintenance({
            value: option.value,
            label: option.label,
          });
        }
      });
  };

  useEffect(() => {
    const map = new Map();
    const currentSearchParams =
      searchParams.toString() !== "" ? searchParams : null;
    if (!!currentSearchParams && _.isEmpty(queryParams)) {
      const size = currentSearchParams.get("size");
      map.set("size", size ?? "10");

      const maintenanceTypes = currentSearchParams.getAll("type");
      if (!!maintenanceTypes) {
        if (maintenanceTypes?.length > 0) {
          setSelectedMaintenanceType(maintenanceTypes);
          map.set("type", maintenanceTypes);
          if (maintenanceTypes.length === 1) {
            selectMaintenceType(maintenanceTypes[0]);
          } else {
            if (maintenanceTypes[0].startsWith("MAINTENANCE")) {
              selectMaintenceType("ALL_MAINTENANCE");
            } else {
              selectMaintenceType("ALL_DEADLINES");
            }
          }
        }
      }
      const aliasSearch = currentSearchParams.get("vehicle.alias");
      const plateSearch = currentSearchParams.get("vehicle.plate");
      if (plateSearch) {
        setVehicleStringSearch(plateSearch);
        map.set("vehicle.plate", plateSearch);
      } else if (aliasSearch) {
        setVehicleStringSearch(aliasSearch);
        map.set("vehicle.alias", aliasSearch);
      }

      const deadlineStatuses = currentSearchParams.getAll("status");
      if (deadlineStatuses && deadlineStatuses.length > 0) {
        setStatusesValues(
          statusesValues.map((statusesValue) => {
            if (deadlineStatuses.includes(statusesValue.value)) {
              statusesValue.checked = true;
            } else {
              statusesValue.checked = false;
            }
            return statusesValue;
          })
        );
        map.set(
          "status",
          deadlineStatuses.map((x) => x)
        );
      } else {
        map.set(
          "status",
          statusesValues.filter((x) => x.checked).map((x) => x.value)
        );
      }
    }

    if (map.size === 0 && _.isEmpty(queryParams)) {
      map.set(
        "status",
        statusesValues.filter((x) => x.checked).map((x) => x.value)
      );
      map.set("size", "10");
    }
    if (map.size > 0) {
      handleChanges(map);
    }
  }, [location]);

  const handleChanges = (params: Map<string, string[] | string>): void => {
    let queryString = "";
    let executeQuery = false;
    if (!!params) {
      params.forEach((value, key) => {
        if (!!value && value.length > 0) {
          queryParams[key] = value;
          executeQuery = true;
        } else {
          if (queryParams.hasOwnProperty(key)) {
            delete queryParams[key];
            executeQuery = true;
          }
        }
      });
      queryString = getQueryString(queryParams);
    }
    // effettuare chiamata API con parametri in query string
    if (executeQuery) {
      store.dispatch(getDeadlinesAsync({ queryParams: queryString }));
      navigate({
        pathname: "/dashboard/maintenance",
        search: queryString,
      });
      callback(queryString);
    }
  };

  const [statusesValues, setStatusesValues] = useState<DropDownItem[]>(
    _.keys(statusTypeValues).map((key) => {
      const labelStatusType = t("maintenance.table.status." + key);

      return {
        label:
          labelStatusType.charAt(0) +
          labelStatusType.substring(1).toLowerCase(),
        value: key,
        checked: key !== statusTypeValues.COMPLETED ? true : false,
      };
    })
  );

  const options = [
    {
      value: "all",
      label: t("maintenance.filterBar.select.all"),
    },
    {
      label: t("maintenance.filterBar.select.maintenance"),
      options: [
        {
          value: "MAINTENANCE_CAR_SERVICE",
          label: t(
            "maintenance.table.maintenanceDeadlines.MAINTENANCE_CAR_SERVICE"
          ),
        },
        {
          value: "MAINTENANCE_TIRES",
          label: t("maintenance.table.maintenanceDeadlines.MAINTENANCE_TIRES"),
        },
        {
          value: "MAINTENANCE_INSPECTION",
          label: t(
            "maintenance.table.maintenanceDeadlines.MAINTENANCE_INSPECTION"
          ),
        },
        {
          value: "MAINTENANCE_CAR_BODYWORK",
          label: t(
            "maintenance.table.maintenanceDeadlines.MAINTENANCE_CAR_BODYWORK"
          ),
        },
        {
          value: "MAINTENANCE_EXTRAORDINARY",
          label: t(
            "maintenance.table.maintenanceDeadlines.MAINTENANCE_EXTRAORDINARY"
          ),
        },
        {
          value: "MAINTENANCE_ORDINARY",
          label: t(
            "maintenance.table.maintenanceDeadlines.MAINTENANCE_ORDINARY"
          ),
        },
        {
          value: "ALL_MAINTENANCE",
          label: t("maintenance.table.maintenanceDeadlines.ALL_MAINTENANCE"),
        },
        {
          value: "MAINTENANCE_CUSTOM",
          label: t("maintenance.table.maintenanceDeadlines.MAINTENANCE_CUSTOM"),
        },
      ],
    },
    {
      label: t("maintenance.filterBar.select.deadlines"),
      options: [
        {
          value: "INSURANCE",
          label: t("maintenance.table.maintenanceDeadlines.INSURANCE"),
        },
        {
          value: "TAXES",
          label: t("maintenance.table.maintenanceDeadlines.TAXES"),
        },
        {
          value: "REVISION",
          label: t("maintenance.table.maintenanceDeadlines.REVISION"),
        },
        {
          value: "ALL_DEADLINES",
          label: t("maintenance.table.maintenanceDeadlines.ALL_DEADLINES"),
        },
        {
          value: "CUSTOM",
          label: t("maintenance.table.maintenanceDeadlines.CUSTOM"),
        },
      ],
    },
  ];

  const list = [
    {
      id: 0,
      title: t("maintenance.filterBar.button.maintenance"),
      icon: <IconAdd size={14} color="--global-colors-ink-light" />,
      onClick: () =>
        navigate("/dashboard/maintenance/new-maintenance", {
          state: { page: 1 },
        }),
    },
    {
      id: 1,
      title: t("maintenance.filterBar.button.deadlines"),
      icon: <IconAdd size={14} color="--global-colors-ink-light" />,
      onClick: () => navigate("/dashboard/maintenance/new-deadline"),
    },
  ];

  const debouncedSearch = useRef(
    _.debounce(async (val) => {
      const searchVehicle = new Map();
      searchVehicle.set("vehicle.alias", val);
      searchVehicle.set("vehicle.plate", val);
      handleChanges(searchVehicle);
      setVehicleStringSearch(val);
    }, 1000)
  ).current;

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  async function handleSearch(val: string) {
    await debouncedSearch(val);
  }

  return (
    <div className="maintenance-filterbar-row">
      <div className="maintenance-filterbar-search">
        <Form>
          <SearchField
            name="search"
            id="vehicles"
            size="small"
            placeholder={t("maintenance.filterBar.searchField")}
            value={vehicleStringSearch}
            onChange={(val: string) => {
              handleSearch(val);
            }}
          />
        </Form>
      </div>
      <div className="maintenance-filterbar-select">
        <Form>
          <Select
            id="select"
            options={options}
            size="small"
            itemAttribute="label"
            events={{
              onChange: (data) => {
                const map = new Map();
                if (data.value === "all") {
                  map.set("type", []);
                  setSelectedMaintenanceType([]);
                } else if (data.value === "ALL_MAINTENANCE") {
                  let allMaintenance: string[] = [];
                  options[1]?.options?.forEach((option) => {
                    if (option.value.startsWith("MAINTENANCE")) {
                      allMaintenance.push(option.value);
                    }
                  });
                  map.set("type", allMaintenance);
                  setSelectedMaintenanceType(allMaintenance);
                } else if (data.value === "ALL_DEADLINES") {
                  let allDeadlines: string[] = [];
                  options[2]?.options?.forEach((option) => {
                    if (
                      !(
                        option.value.startsWith("MAINTENANCE") ||
                        option.value.startsWith("ALL")
                      )
                    ) {
                      allDeadlines.push(option.value);
                    }
                  });
                  map.set("type", allDeadlines);
                  setSelectedMaintenanceType(allDeadlines);
                } else {
                  map.set("type", data.value);
                  setSelectedMaintenanceType([data.value]);
                }
                handleChanges(map);
              },
            }}
            defaultValue={viewSelectedMaintenance}
          />
        </Form>
      </div>
      <div className="maintenance-filterbar-statuses">
        <Dropdown
          placeholderInValue={t(
            "fleetControl.filterBar.placeholderInValStatus"
          )}
          itemAttribute="label"
          hasCheckbox={true}
          placeholder={t("fleetControl.filterBar.status")}
          size={"small"}
          onChange={(val: DropDownItem[]) => {
            setStatusesValues(
              statusesValues.map((statusesValue) => {
                if (val.includes(statusesValue)) {
                  statusesValue.checked = true;
                } else {
                  statusesValue.checked = false;
                }
                return statusesValue;
              })
            );
            const map = new Map();
            map.set(
              "status",
              val.map((x) => x.value)
            );
            handleChanges(map);
          }}
          options={statusesValues}
          value={statusesValues.filter((x) => x.checked)}
        />
      </div>
      <div className="maintenance-filterbar-button">
        <DropdownButton
          aspect="primary"
          size="small"
          list={list}
          label={t("maintenance.filterBar.button.label")}
        >
          <IconMaintenanceDeadlines
            size={14}
            color="--global-colors-ink-white"
          />
        </DropdownButton>
      </div>
    </div>
  );
};
