import React from "react";

export const MapMarkerCluster = ({ number, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="60"
    height="60"
    {...props}
    className="map-marker-svg"
  >
    <defs>
      <filter
        id="MapMarkerClusterA"
        width="116.7%"
        height="122.2%"
        x="-8.3%"
        y="-5.6%"
        filterUnits="objectBoundingBox"
      >
        <feMorphology
          in="SourceAlpha"
          radius="0.5"
          result="shadowSpreadOuter1"
        ></feMorphology>
        <feOffset
          dy="3"
          in="shadowSpreadOuter1"
          result="shadowOffsetOuter1"
        ></feOffset>
        <feGaussianBlur
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
          stdDeviation="1"
        ></feGaussianBlur>
        <feComposite
          in="shadowBlurOuter1"
          in2="SourceAlpha"
          operator="out"
          result="shadowBlurOuter1"
        ></feComposite>
        <feColorMatrix
          in="shadowBlurOuter1"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
        ></feColorMatrix>
      </filter>
      <filter
        id="MapMarkerClusterD"
        width="103.1%"
        height="103.1%"
        x="-1.6%"
        y="-1.6%"
        filterUnits="objectBoundingBox"
      >
        <feMorphology
          in="SourceAlpha"
          radius="1"
          result="shadowSpreadInner1"
        ></feMorphology>
        <feOffset
          in="shadowSpreadInner1"
          result="shadowOffsetInner1"
        ></feOffset>
        <feComposite
          in="shadowOffsetInner1"
          in2="SourceAlpha"
          k2="-1"
          k3="1"
          operator="arithmetic"
          result="shadowInnerInner1"
        ></feComposite>
        <feColorMatrix
          in="shadowInnerInner1"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
        ></feColorMatrix>
      </filter>
      <circle id="MapMarkerClusterB" cx="30" cy="30" r="18"></circle>
      <circle id="MapMarkerClusterC" cx="30" cy="30" r="16"></circle>
    </defs>
    <g fill="none" fillRule="evenodd">
      <circle cx="30" cy="30" r="30" fill="#0052BD" fillOpacity="0.1"></circle>
      <use
        fill="#000"
        filter="url(#MapMarkerClusterA)"
        xlinkHref="#MapMarkerClusterB"
      ></use>
      <circle
        cx="30"
        cy="30"
        r="17"
        fill="#FFF"
        stroke="#FFF"
        strokeLinejoin="square"
        strokeWidth="2"
      ></circle>
      <use fill="#0052BD" xlinkHref="#MapMarkerClusterC"></use>
      <use
        fill="#000"
        filter="url(#MapMarkerClusterD)"
        xlinkHref="#MapMarkerClusterC"
      ></use>
      <text fill="#FFF" fontFamily="Helvetica" fontSize="14">
        <tspan x="50%" y="50%" dominantBaseline="middle" textAnchor="middle">
          {number}
        </tspan>
      </text>
    </g>
  </svg>
);
