//#region Slice
import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import _ from "lodash";
import { normalize } from "normalizr";
import { RootState } from "../../app/store";
import { Config } from "../../config/Config";
import { GTFleetSuccessCodes } from "../../config/GTFleetSuccessCodes";
import {
  notificationEventSchema,
  notificationEventsSchema,
} from "./notificationNormalization";
import NotificationRepository from "./NotificationRepository";

export type NotificationEvent = {
  id: number;
  message: string;
  read: boolean;
  readDate: Date;
  sendDate: Date;
  title: string;
  notificationCategory: number;
  user: number;
  unReadNotifications: number;
};

export type NotificationEventChanges = {
  id: number;
  changes: NotificationEvent;
};

export const getFilteredNotificationsAsync = createAsyncThunk(
  "notifications/getFilteredNotificatios",
  async (data: { queryParams?: string }, { dispatch, rejectWithValue }) => {
    try {
      const notificationsRepository = new NotificationRepository();
      const response = await notificationsRepository.getFilteredNotifications(
        data.queryParams
      );

      const notification = _.get(
        response,
        Config.NOTIFICATION_EVENTS_RESPONSE_PATH
      );
      const totalPages = _.get(
        response,
        Config.NOTIFICATION_EVENTS_TOTAL_PAGES_RESPONSE_PATH
      );
      const unReadNotifications = _.get(
        response,
        Config.NOTIFICATION_EVENTS_UNREADNOTIFICATION_RESPONSE_PATH
      );
      const totalElements = _.get(
        response,
        Config.NOTIFICATION_EVENTS_TOTAL_ELEMENTS_RESPONSE_PATH
      );
      if (totalPages) {
        dispatch(setNumberOfPages(totalPages));
      }
      if (unReadNotifications != null) {
        dispatch(setUnReadNotifications(unReadNotifications));
      }
      if (totalElements) {
        dispatch(setNumberOfElements(totalElements));
      }

      const normalizedData = normalize(
        notification ?? [],
        notificationEventsSchema
      );
      return normalizedData.entities;
    } catch (error: any) {
      if (!error.response) throw error;
      return rejectWithValue(error.response.status);
    }
  }
);

export const markOneAsReadAsync = createAsyncThunk(
  "notifications/markOneAsRead",
  async (
    data: {
      id: number;
    },
    { rejectWithValue }
  ) => {
    try {
      const notificationEventsRepository = new NotificationRepository();
      const response = await notificationEventsRepository.readOneNotification(
        data.id
      );
      const notification = _.get(response, Config.NOTIFICATION_RESPONSE_PATH);
      const normalizedData = normalize(notification, notificationEventSchema);
      return normalizedData.entities;
    } catch (error: any) {
      if (!error.response) throw error;
      return rejectWithValue(error.response.status);
    }
  }
);

export const markAllAsReadAsync = createAsyncThunk(
  "notifications/markAllAsRead",
  async (_data, { rejectWithValue }) => {
    try {
      const notificationEventsRepository = new NotificationRepository();
      await notificationEventsRepository.readAllNotifications();
    } catch (error: any) {
      if (!error.response) throw error;
      return rejectWithValue(error.response.status);
    }
  }
);

function setFilteredData(state: any, action: PayloadAction<any>) {
  action.payload.notification
    ? notificationEventsAdapter.setAll(state, action.payload.notification)
    : notificationEventsAdapter.setAll(state, []);
}

const notificationEventsAdapter = createEntityAdapter<NotificationEvent>({
  selectId: (notification) => notification.id,
});

export const notificationEventsSlice = createSlice({
  name: "notificationSlice",
  initialState: notificationEventsAdapter.getInitialState({
    status: "idle",
    reasonCode: "",
    totalPages: 0,
    unReadNotifications: 0,
    totalElements: 0,
  }),
  reducers: {
    notificationEventsEmptyState: (state) => {
      notificationEventsAdapter.setAll(state, []);
      state.reasonCode = "";
      state.status = "idle";
      state.unReadNotifications = 0;
    },
    setNumberOfPages: (state, action: PayloadAction<number>) => {
      state.totalPages = action.payload;
    },
    setNumberOfElements: (state, action: PayloadAction<number>) => {
      state.totalElements = action.payload;
    },
    setUnReadNotifications: (state, action: PayloadAction<number>) => {
      state.unReadNotifications = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      //#region Entity Reducers
      .addCase(
        getFilteredNotificationsAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          setFilteredData(state, action);
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.GET;
        }
      )
      .addCase(getFilteredNotificationsAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(getFilteredNotificationsAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = "";
      })
      .addCase(
        markOneAsReadAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          notificationEventsAdapter.upsertMany(
            state,
            action.payload.notification
          );
          state.unReadNotifications = state.unReadNotifications - 1;
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.PATCH;
        }
      )
      .addCase(markOneAsReadAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = "";
      })
      .addCase(markOneAsReadAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(markAllAsReadAsync.fulfilled, (state: any) => {
        state.status = "idle";
        state.reasonCode = GTFleetSuccessCodes.PATCH;
      })
      .addCase(markAllAsReadAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = "";
      })
      .addCase(markAllAsReadAsync.pending, (state: any) => {
        state.status = "loading";
      });
  },
});

export const notificationEventsSelectors =
  notificationEventsAdapter.getSelectors<RootState>(
    (state) => state.notificationsEvent
  );

export const selectNotificationEventsSliceStatus = (state: any) =>
  state.notificationsEvent.status;
export const selectNotificationEventsSliceReasonCode = (state: any) =>
  state.notificationsEvent.reasonCode;
export const selectNotificationEventsSlicePage = (state: any) =>
  state.notificationsEvent.totalPages;
export const selectNotificationEventsSliceTotalElements = (state: any) =>
  state.notificationsEvent.totalElements;
export const selectNotificationEventsSliceUnreadNotifications = (state: any) =>
  state.notificationsEvent.unReadNotifications;
export const {
  notificationEventsEmptyState,
  setNumberOfPages,
  setUnReadNotifications,
  setNumberOfElements,
} = notificationEventsSlice.actions;

export default notificationEventsSlice.reducer;
