import React, { useState } from "react";
import { IconArrowDown } from "../Icon/Line/ArrowDown";
import { CardVehicle } from "./CardVehicle";

import "./FilterBar.css";

export const FilterBar = ({ googleMapsApiKey, openDropArea, vehicles, children }) => {
  const [isOpen, setIsOpen] = useState(true);
  const className = isOpen
    ? "mn-mw-filter-bar mn-mw-filter-bar--open"
    : "mn-mw-filter-bar";
  return (
    <div className={className}>
      <div className="mn-mw-filter-bar__header">
        {isOpen ? <div>{children}</div> : <div>Vehicles</div>}
        <div className="arrow-down">
        <IconArrowDown
          size={16}
          color="currentColor"
          onClick={() => setIsOpen((prevState) => !prevState)}
        />
        </div>
      </div>
      {isOpen && (
        <div className="mn-mw-filter-bar__section">
          {vehicles.map((vehicle, index) => {
            return <CardVehicle openDropArea={openDropArea} googleMapsApiKey={googleMapsApiKey} key={index} {...vehicle} />;
          })}
        </div>
      )}
    </div>
  );
};

FilterBar.propTypes = {};
FilterBar.defaultProps = {};
