import React from 'react'
import PropTypes from 'prop-types'

import './Radio.css'

export const Radio = ({ id, label, name, onChange, disabled, className, checked, ...props }) => {

	return (
		<label htmlFor={id} className={['radio', className].join(' ')} {...props}>
			<input id={id} type="radio" name={name} onChange={onChange} disabled={disabled} checked={checked} />
			<span>{label}</span>
		</label>
	)
}

Radio.propTypes = {
	id: PropTypes.number,
	label: PropTypes.string,
	checked: PropTypes.bool,
	name: PropTypes.string,
	disabled: PropTypes.bool,
	onChange: PropTypes.func
}

Radio.defaultProps = {
	label: 'Radio Button',
	name: '',
	disabled: false,
}