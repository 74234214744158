import HttpClient from "../../utils/HttpClient";
import { Device } from "./devicesSlice";

export default class DevicesRepository extends HttpClient {
  constructor() {
    super(true);
  }

  public partiallyUpdateDevice(id: number, device: Device) {
    return this.instance.patch("/devices/" + id, device);
  }
}
