import HttpClient from "../../../utils/HttpClient";

export default class SpeedRepository extends HttpClient {
  constructor() {
    super(true);
  }

  getSpeedDataReports(queryParams?: string) {
    const tenantId = localStorage.getItem("tenantId");
    return this.instance.get(
      "tenants/" + tenantId + "/reports/speedData" + (queryParams ?? "")
    );
  }
}
