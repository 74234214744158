import React from "react";
import PropTypes from "prop-types";
import "./Divider.css";
import i18n from "../../i18n/i18nextConf";
/**
 * Transform a date sting into a formatted string.
 * Ex. 12/01/2021 => 12 Gen 2021
 *
 * @param {String} date The date to format.
 * @returns String
 */
const formatDate = (date) => {
  const dateObj = new Date(date);
  const dateFormatOption = { year: "numeric", month: "short", day: "numeric" };
  return dateObj.toLocaleString(i18n.language, dateFormatOption);
};

export const Divider = ({ hasData, date, ...props }) => {
  const formattedDate = formatDate(date);
  let className = "mn-divider";
  className += hasData ? " mn-divider--has-data" : "";
  return (
    <div className={className} {...props}>
      <div className="mn-divider-data">
        <span>{formattedDate}</span>
      </div>
    </div>
  );
};

Divider.propTypes = {
  hasData: PropTypes.bool,
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Divider.defaultProps = {
  hasData: true,
};
