import HttpClient from "../../utils/HttpClient";
import { Deadline, DeadlineMultiVehicle } from "./deadlinesSlice";

export default class DeadlinesRepository extends HttpClient {
  constructor() {
    super(true);
  }

  getDeadlines(customQueryParams?: string) {
    const tenantId = localStorage.getItem("tenantId");
    let path = "tenants/" + tenantId + "/deadlines";
    return this.instance.get(path + (customQueryParams ?? ""));
  }

  getDeadline(deadlineId: number) {
    const tenantId = localStorage.getItem("tenantId");
    let path = "tenants/" + tenantId + "/deadlines/" + deadlineId;
    return this.instance.get(path);
  }

  deleteDeadline(deadlineId: number) {
    const tenantId = localStorage.getItem("tenantId");
    let path = "tenants/" + tenantId + "/deadlines/" + deadlineId;
    return this.instance.delete(path);
  }

  createDeadline(deadlineMultiVehicle: DeadlineMultiVehicle) {
    const tenantId = localStorage.getItem("tenantId");
    let path = "tenants/" + tenantId + "/deadlines";
    return this.instance.post(path, deadlineMultiVehicle);
  }

  partiallyUpdateDeadline(id: number, deadline: Partial<Deadline>) {
    const tenantId = localStorage.getItem("tenantId");
    let path = "tenants/" + tenantId + "/deadlines/" + id;
    return this.instance.patch(path, {
      ...deadline,
      vehicle: { id: deadline.vehicle },
    });
  }
}
