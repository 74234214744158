import PropTypes from "prop-types";

import { IconGroups } from "../Icon/Solid/Groups";
import { IconUser } from "../Icon/Solid/User";
import "./thumbprofile.css";

export const ThumbProfile = ({ imgUrl, size, alt, hasGroups, ...props }) => {
  const dimension = `mn-thumb--${size}`;
  const hasImg = imgUrl ? "mn-thumb--has-image" : "mn-thumb--has-svg";
  const sizeMapping = {
    large: 50,
    medium: 32,
    mediumsmall: 24,
    small: 19,
    smaller: 14,
  };

  let thumbnail;
  if (imgUrl) {
    thumbnail = <img src={imgUrl} alt={alt} />;
  } else {
    thumbnail = (
      <IconUser size={sizeMapping[size]} color="--global-colors-ink-light" />
    );
  }

  return (
    <div
      className={["mn-thumb", dimension, hasImg].join(" ")}
      onClick={props.onClick}
    >
      {hasGroups ? (
        <IconGroups
          size={sizeMapping[size]}
          color="--global-colors-ink-light"
        />
      ) : (
        thumbnail
      )}
    </div>
  );
};

ThumbProfile.propTypes = {
  /**
   * How large should the thumbnail be?
   */
  size: PropTypes.oneOf(["large", "medium", "mediumsmall", "small", "smaller"]),
  /**
   * Alternative text if image is for some reason missing.
   */
  alt: PropTypes.string,
  /**
   * The thumbnail URL
   */
  imgUrl: PropTypes.string,
  /**
   * Type of icon
   */
  hasGroups: PropTypes.bool,
};

ThumbProfile.defaultProps = {
  size: "large",
  alt: "User Thumbnail",
  imgUrl: "",
};
