import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import _ from "lodash";
import { normalize } from "normalizr";
import { RootState } from "../../app/store";
import { Config } from "../../config/Config";
import { GTFleetSuccessCodes } from "../../config/GTFleetSuccessCodes";
import { getErrorCodes } from "../../utils/Utils";
import { vehiclesFuelCostsSchema } from "../vehicle/vehicleNormalization";
import VehiclesFuelsRepository from "../vehicle/vehiclesFuelsRepository";

//#region Type
export interface InstantPoint {
  data: number;
  date: string;
}
export interface VehiclesFuelCosts {
  tenantId: number;
  summary: {
    totalFuelCosts: number;
    avgFuelCosts: number;
  };
  fuelsCost: InstantPoint[];
}
//#endregion Type

//#region API
export const getVehiclesFuelCostsAsync = createAsyncThunk(
  "vehicles/getVehiclesFuelCosts",
  async (data: { queryParams: string; isActive?: boolean }) => {
    const vehiclesFuelsRepository = new VehiclesFuelsRepository();
    fleetEmptyState();
    if (data.isActive != undefined) {
      data.queryParams =
        (_.isEmpty(data.queryParams)
          ? "?isActive="
          : data.queryParams + "&isActive=") + data.isActive;
    }
    const response = await vehiclesFuelsRepository.getVehiclesFuelCosts(
      data.queryParams
    );
    // The value we return becomes the `fulfilled` action payload
    const costs = _.get(response, Config.VEHICLES_FUEL_COSTS_RESPONSE_PATH);
    const normalizedData = normalize(costs, vehiclesFuelCostsSchema);
    return normalizedData.entities;
  }
);
//#region API

//#region Slice
const fleetFuelCostsAdapter = createEntityAdapter<VehiclesFuelCosts>({
  selectId: (vehiclesFuelCosts) => vehiclesFuelCosts.tenantId,
});

export const fleetFuelCostsSlice = createSlice({
  name: "vehiclesFuelCosts",
  initialState: fleetFuelCostsAdapter.getInitialState({
    status: "idle",
    reasonCode: "",
  }),
  reducers: {
    fleetEmptyState: (state: any) => {
      fleetFuelCostsAdapter.setAll(state, []);
    },
  },
  extraReducers: (builder) => {
    builder
      //#region Entity Reducers
      .addCase(
        getVehiclesFuelCostsAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          fleetFuelCostsAdapter.setAll(
            state,
            action.payload.vehiclesFuelCosts ?? []
          );
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.GET;
        }
      )
      .addCase(
        getVehiclesFuelCostsAsync.rejected,
        (state: any, action: PayloadAction<any>) => {
          state.status = "failed";
          state.reasonCode = getErrorCodes(action.payload);
        }
      )
      .addCase(getVehiclesFuelCostsAsync.pending, (state: any) => {
        state.status = "loading";
      });
    //#endregion Entity Reducers
  },
});
//#endregion Slice

//#region Status
export const fleetFuelCostsSelectors =
  fleetFuelCostsAdapter.getSelectors<RootState>(
    (state) => state.fleetFuelCosts
  );

export const selectFleetFuelCostsSliceStatus = (state: any) =>
  state.fleetFuelCosts.status;
export const selectFleetFuelCostsSliceReasonCode = (state: any) =>
  state.fleetFuelCosts.reasonCode;
//#endregion Status

export const { fleetEmptyState } = fleetFuelCostsSlice.actions;

export default fleetFuelCostsSlice.reducer;
