import PropTypes from "prop-types";
import React, { useRef } from "react";

export const MenuItem = ({
  icon,
  label,
  onClick,
  isActive,
  isLoading,
  isCollapsed,
  ...props
}) => {
  const parentRef = useRef();

  let classname = isActive
    ? "mn-menu__nav-list__item mn-menu__nav-list__item--active"
    : "mn-menu__nav-list__item";
  if (!isCollapsed && isLoading && isActive && parentRef.current) {
    classname += parentRef.current.closest(".mn-menu.mn-menu--admin")
      ? " isActiveCircleAdmin "
      : " isActiveCircle ";
  }
  if (isCollapsed && isLoading && isActive && parentRef.current) {
    classname += parentRef.current.closest(".mn-menu.mn-menu--admin")
      ? " isActiveCircleAdminCollapsed "
      : " isActiveCircleCollapsed ";
  }

  const handleClick = () => {
    onClick();
  };

  return (
    <li ref={parentRef} className={classname} {...props} onClick={handleClick}>
      {icon}
      <div>{label}</div>
    </li>
  );
};

MenuItem.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func,
  isActive: PropTypes.bool,
  isCollapsed: PropTypes.bool,
};

MenuItem.defaultProps = {
  isActive: false,
};
