import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import _ from "lodash";
import { normalize } from "normalizr";
import { RootState } from "../../app/store";
import { Config } from "../../config/Config";
import { GTFleetErrorCodes } from "../../config/GTfleetErrorCodes";
import { GTFleetSuccessCodes } from "../../config/GTFleetSuccessCodes";
import { getErrorCodes } from "../../utils/Utils";
import { DriverView } from "../driver/driversSlice";
import {
  routeHistorySchema,
  routesHistorySchema,
} from "./routeHistoryNormalization";
import RoutesHistoryRepository from "./routesHistoryRepository";

//#region Type
export type RouteStateType = "TRACK" | "STOP";

export type RouteEventValue = {
  speedLimit?: number;
};

export type SeverityType = "ALARM" | "WARNING" | "INFO";

export type RouteEventName =
  | "IGNITION_KEY_ON"
  | "IGNITION_KEY_OFF"
  | "START"
  | "END"
  | "SPEED_LIMIT"
  | "ASSET_LOADED"
  | "ASSET_UNLOADED"
  | "DRIVER_CHANGED"
  | "DRIVER_IDENTIFIED"
  | "DRIVER_NOT_IDENTIFIED"
  | "FUEL_REFILLED"
  | "FUEL_LEVEL_EMPTY"
  | "FUEL_LEVEL_FULL"
  | "GEOFENCE_IN"
  | "GEOFENCE_OUT"
  | "CAR_ALARM"
  | "LIFT"
  | "CRASH"
  | "SOS"
  | "STOP"
  | "ANTI_JAMMER"
  | "TIRE_PRESSURE"
  | "CHANGE_OIL"
  | "ENGINE_BLOCK"
  | "PRIVACY_MODE"
  | "LOCATE_REQUEST"
  | "PARKING"
  | "PARKING_PROTECTION"
  | "MISSING_ODOMETER"
  | "FUEL_LEVEL_RESERVE"
  | "ANOMALOUS_ODOMETER_DECREASE"
  | "PLANNED_VEHICLE_STOPPED"
  | "NO_PLANNING_MOVING_VEHICLE";

export type RouteEvent = {
  type: {
    id: number;
    category: {
      id: number;
      name: string;
    };
    description: string;
    name: RouteEventName;
    severity: SeverityType;
    hardwareSource: boolean;
  };
  color: string;
  value: RouteEventValue;
  name: string;
};

export type RoutePosition = {
  latitude: number;
  longitude: number;
  gpsPositionTimestamp: Date;
  address: string;
};

export type RouteState = {
  routeStateType: RouteStateType;
  driver: DriverView;
  dynamicFields: {
    latitude: number;
    longitude: number;
    lastUpdate: Date;
    address: string;
    direction: number;
    speed: number;
    fuelLevel: number;
    fuelLevelLiters: number;
    odometer: number;
  };
  events: RouteEvent[];
  covered: number;
  routeId: number;
};

export type RouteHistory = {
  id: number;
  routeStateType: RouteStateType;
  initialOdometer: number;
  finalOdometer: number;
  firstPosition: RoutePosition;
  lastPosition: RoutePosition;
  routeStates: RouteState[];
  vehicleDetails: number;
  driverDetails: number;
};
//#endregion Type

//#region API
// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const getRoutesHistoryAsync = createAsyncThunk(
  "routesHistory/getRoutesHistory",
  async (queryParams: string) => {
    const routesHistoryRepository = new RoutesHistoryRepository();
    const response = await routesHistoryRepository.getRoutesHistory(
      queryParams
    );
    // The value we return becomes the `fulfilled` action payload
    const routesHistory = _.get(response, Config.ROUTES_HISTORY_PATH);
    const normalizedData = normalize(routesHistory, routesHistorySchema);

    if (
      normalizedData.entities &&
      normalizedData.entities.routeHistory &&
      normalizedData.result
    ) {
      let unorderedRoutesHistory: RouteHistory[] = [];
      normalizedData.result.forEach((element: number) => {
        const unorderedRouteHistory = _.get(
          normalizedData.entities.routeHistory,
          element
        );
        const routeStates = unorderedRouteHistory.routeStates;
        const sortedRouteStates = _.sortBy(routeStates, [
          "dynamicFields.lastUpdate",
        ]);
        unorderedRouteHistory.routeStates = sortedRouteStates;
        unorderedRoutesHistory.push(unorderedRouteHistory);
      });
      normalizedData.entities.routeHistory = unorderedRoutesHistory;
    }
    return normalizedData.entities;
  }
);

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const readRouteHistoryAsync = createAsyncThunk(
  "routesHistory/readRouteHistory",
  async (
    data: { vehicleId: number; routeHistoryId: number; queryParams?: string },
    { rejectWithValue }
  ) => {
    try {
      const routesHistoryRepository = new RoutesHistoryRepository();
      const response = await routesHistoryRepository.readRouteHistoryAsync(
        data.vehicleId,
        data.routeHistoryId,
        data.queryParams
      );
      // The value we return becomes the `fulfilled` action payload
      const routeHistory = _.get(response, Config.ROUTE_HISTORY_PATH);
      const normalizedData = normalize(routeHistory, routeHistorySchema);
      if (normalizedData.entities.routeHistory && normalizedData.result) {
        const currentRouteHistory =
          normalizedData?.entities?.routeHistory[normalizedData.result];
        if (currentRouteHistory) {
          const routeStates = currentRouteHistory.routeStates;
          const sortedRouteStates = _.sortBy(routeStates, [
            "dynamicFields.lastUpdate",
          ]);
          normalizedData.entities.routeHistory[
            normalizedData.result
          ].routeStates = sortedRouteStates;
        }
      }

      return normalizedData.entities;
    } catch (error: any) {
      if (!error.response) throw error;
      return rejectWithValue(error.response.status);
    }
  }
);
//#endregion API

//#region Custom Functions
function setFilteredData(state: any, action: PayloadAction<any>) {
  action.payload.routeHistory
    ? routesHistoryAdapter.setAll(state, action.payload.routeHistory)
    : routesHistoryAdapter.setAll(state, []);
}
//#endregiorn Custom Functions

//#region Slice
const routesHistoryAdapter = createEntityAdapter<RouteHistory>({
  selectId: (routeHistory) => routeHistory.id,
  sortComparer: (a, b) => a.id.toString().localeCompare(b.id.toString()),
});

export const routeHistorySlice = createSlice({
  name: "routesHistory",
  initialState: routesHistoryAdapter.getInitialState({
    status: "idle",
    reasonCode: "",
  }),
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    routeHistoryEmptyRoutes: (state: any) => {
      routesHistoryAdapter.setAll(state, []);
      state.reasonCode = "";
      state.status = "idle";
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      //#region Entity Reducers
      // getRoutesHistoryAsync extra reducers
      .addCase(
        getRoutesHistoryAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          setFilteredData(state, action);
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.GET;
        }
      )
      .addCase(getRoutesHistoryAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(getRoutesHistoryAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = "";
      })
      // readRouteHistoryAsync extra reducers
      .addCase(
        readRouteHistoryAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          routesHistoryAdapter.upsertMany(state, action.payload.routeHistory);
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.GET;
        }
      )
      .addCase(
        readRouteHistoryAsync.rejected,
        (state: any, action: PayloadAction<any>) => {
          state.status = "failed";
          let castAction: any = action;
          if (castAction.payload && castAction.payload === 500) {
            state.reasonCode = GTFleetErrorCodes.ROUTE_HISTORY_DOES_NOT_EXIST;
          } else state.reasonCode = getErrorCodes(action.payload);
        }
      )
      .addCase(readRouteHistoryAsync.pending, (state: any) => {
        state.status = "loading";
      });
    //#endregion Entity Reducers
  },
});
//#endregion Slice

//#region Status
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const routesHistorySelectors =
  routesHistoryAdapter.getSelectors<RootState>((state) => state.routesHistory);

export const selectRoutesHistorySliceStatus = (state: any) =>
  state.routesHistory.status;
export const selectRoutesHistorySliceReasonCode = (state: any) =>
  state.routesHistory.reasonCode;
//#endregion Status

export const { routeHistoryEmptyRoutes } = routeHistorySlice.actions;

export default routeHistorySlice.reducer;
