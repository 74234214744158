import { useEffect, useState } from "react";
import { IconEndPoint } from "../Icon/Line/EndPoint";
import { IconStartPoint } from "../Icon/Line/StartPoint";

import { t } from "i18next";
import i18n from "../../i18n/i18nextConf";
import { Pending } from "../Indicators/Pending";
import "./SingleTrackRow.css";

export const SingleTrackRow = ({ id, points, active, clickCallback }) => {
  const [isSelected, setIsSelected] = useState(active);
  let className = "mn-single-track-row";

  if (isSelected) {
    className += " mn-single-track-row--focused";
  }

  const onClick = () => {
    if (clickCallback !== undefined) {
      clickCallback && clickCallback(!isSelected);
    }
    setIsSelected(!isSelected);
  };

  useEffect(() => {
    setIsSelected(active);
  }, [active]);

  /**
   * Transform a time sting into a formatted string.
   *
   * @param {String} time The date to format.
   * @returns String
   */
  const formatTime = (time) => {
    const dateObj = new Date(time);
    const dateFormatOption = { hour: "2-digit", minute: "2-digit" };
    return dateObj.toLocaleTimeString(i18n.language, dateFormatOption);
  };

  return (
    <div
      key={id}
      id={`single-track-row-${id}`}
      className={className}
      onClick={onClick}
    >
      <div className="mn-single-track-row__end-point">
        <span>
          <IconEndPoint size={20} />
        </span>
        <div>
          <span>{points.end.location}</span>
          <span className="mn-single-track-row__end-time">
            {!points?.open ? (
              formatTime(points.end.time)
            ) : (
              <span data-for="tooltip" data-tip={t("common.inProgress")}>
                <div className="mn-single-track-row__pending">
                  <Pending />
                </div>
              </span>
            )}
          </span>
        </div>
      </div>
      <div className="mn-single-track-row__mid-point"></div>
      <div className="mn-single-track-row__start-point">
        <span>
          <IconStartPoint size={20} />
        </span>
        <div>
          <span>{points.start.location}</span>
          <span>{formatTime(points.start.time)}</span>
        </div>
      </div>
    </div>
  );
};
