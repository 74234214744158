import PropTypes from "prop-types";
import { MessageStatusEnum } from "../../features/messages/messagesSlice";
import { AlertSimple } from "../../stories/Icon.line.stories";
import { IconCheck } from "../Icon/Line/Check";
import { IconLoader } from "../Icon/Line/Loader";
import { IconDoubleCheck } from "../Icon/Solid/DoubleCheck";
import { ThumbProfile } from "../ThumbProfile/ThumbProfile";

export const ChatMessage = ({
  id,
  userPic,
  messageText,
  position,
  status,
  time,
  ...props
}) => {
  const messageDirection =
    position === "right"
      ? "mn-chat__message--reverse mn-chat__message--from-me"
      : "mn-chat__message--from-user";

  const getStatusMessage = (status) => {
    switch (status) {
      case MessageStatusEnum.DISPATCHED:
        return <IconCheck size={8} color="--global-colors-ink-light" />;
      case MessageStatusEnum.DELIVERED:
        return <IconDoubleCheck size={8} color="--global-colors-ink-light" />;
      case MessageStatusEnum.ERROR:
        return <AlertSimple size={8} color="--global-colors-ink-light" />;
      default:
        return (
          <div className="single-message-loader">
            <IconLoader size={8} color="--global-colors-ink-light" />
          </div>
        );
    }
  };

  return (
    <div
      key={id}
      className={["mn-chat__message", messageDirection].join(" ")}
      {...props}
    >
      <ThumbProfile imgUrl={userPic} size="small" />
      <div className="mn-chat__message-text">
        <div>{messageText}</div>
        <small>
          {getStatusMessage(status)}
          <time>{time}</time>
        </small>
      </div>
    </div>
  );
};

ChatMessage.propTypes = {
  id: PropTypes.number.isRequired,
  userPic: PropTypes.string,
  messageText: PropTypes.string.isRequired,
  position: PropTypes.string,
  status: PropTypes.string,
  time: PropTypes.string.isRequired,
};

ChatMessage.defaultProps = {
  position: "right",
  time: "00:00",
};
