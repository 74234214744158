import {
  PayloadAction,
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import _ from "lodash";
import { normalize } from "normalizr";
import { RootState } from "../../../app/store";
import { Config } from "../../../config/Config";
import { GTFleetSuccessCodes } from "../../../config/GTFleetSuccessCodes";
import { DriverView } from "../../driver/driversSlice";
import ReportsRepository from "../reportRepository";
import { reportIOAndSummarySchema } from "../reportsNormalization";

//#region Type
type Event = {
  latitude: number;
  longitude: number;
  address: string;
  gpsPositionTimestamp: Date;
};

type ReportIOSummary = {
  id: number;
  duration: number;
  eventDistance: number;
};

export type ReportIO = {
  id: number;
  routeId: number;
  eventStart: Event;
  eventEnd: Event;
  duration: number;
  eventDistance: number;
  startAddress: string;
  endAddress: string;
  driverStart: DriverView;
  driverEnd: DriverView;
};

export type ReportIOAndSummary = {
  summary: ReportIOSummary;
  reportsIO: ReportIO[];
};

//#endregion Type

//#region API
// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const getReportsIOAsync = createAsyncThunk(
  "reportsIO/getReportsIO",
  async (queryParams: string, thunkAPI) => {
    const reportsRepository = new ReportsRepository();
    const response = await reportsRepository.getReportsIO(queryParams);
    // The value we return becomes the `fulfilled` action payload
    const reports = _.get(response, Config.REPORTS_IO_RESPONSE_PATH);
    const normalizedData = normalize(reports, reportIOAndSummarySchema);
    return normalizedData.entities;
  }
);
//#endregion API

//#region Custom Functions
function setFilteredData(state: any, action: PayloadAction<any>) {
  action
    ? reportsIOAdapter.setAll(state, action)
    : reportsIOAdapter.setAll(state, []);
}
//#endregiorn Custom Functions

//#region Slice
const reportsIOAdapter = createEntityAdapter<ReportIO>({
  selectId: (report) => report.id,
  sortComparer: (a, b) => a.id - b.id,
});

export const reportsIOSlice = createSlice({
  name: "reportsIOSlice",
  initialState: reportsIOAdapter.getInitialState({
    status: "idle",
    reasonCode: "",
  }),
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    reportsIOEmptyState: (state) => {
      reportsIOAdapter.setAll(state, []);
      state.reasonCode = "";
      state.status = "idle";
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      //#region Entity Reducers
      // getRoutesHistoryAsync extra reducers
      .addCase(
        getReportsIOAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          setFilteredData(state, action.payload.reportsIO);
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.GET;
        }
      )
      .addCase(getReportsIOAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(getReportsIOAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = "";
      });
    //#endregion Entity Reducers
  },
});
//#endregion Slice

//#region Status
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const reportsIOSelectors = reportsIOAdapter.getSelectors<RootState>(
  (state) => state.reportsIO
);

export const selectReportsIOSliceStatus = (state: any) =>
  state.reportsIO.status;
export const selectReportsIOSliceReasonCode = (state: any) =>
  state.reportsIO.reasonCode;
//#endregion Status
export const { reportsIOEmptyState } = reportsIOSlice.actions;

export default reportsIOSlice.reducer;
