import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import _ from "lodash";
import { normalize } from "normalizr";
import { RootState } from "../../app/store";
import { Config } from "../../config/Config";
import VehicleStatusAmountRepository from "./vehiclesStatusAmountRepository";
import { StatusVehicleType } from "./vehiclesStatusSlice";
import { vehicleStatusCountsSchema } from "./vehicleStatusAmountNormalization";

//#region Type
export type VehicleStatusCounts = {
  status: StatusVehicleType;
  vehicles: number;
};

export type FleetStatusCounts = {
  fleetId: number;
  vehicleStatusCounts: VehicleStatusCounts[];
};

export type FleetVehicleStatusSummary = {
  tenantId: number;
  fleetStatusCounts: FleetStatusCounts[];
  vehicleStatusCounts: VehicleStatusCounts[];
};
//#endregion Type

//#region API
export const getVehicleStatusAmountAsync = createAsyncThunk(
  "status/getVehicleStatusAmount",
  async (_data, { rejectWithValue }) => {
    try {
      const vehicleStatusAmountRepository = new VehicleStatusAmountRepository();
      const response =
        await vehicleStatusAmountRepository.getVehicleStatusAmount();
      const vehicleStatusAmount = _.get(
        response,
        Config.STATUS_AMOUNT_RESPONSE_PATH
      );
      const normalizedData = normalize(
        vehicleStatusAmount,
        vehicleStatusCountsSchema
      );
      return normalizedData.entities;
    } catch (error: any) {
      if (!error.response) throw error;
      return rejectWithValue(error?.response?.data);
    }
  }
);

//#endregion API

//#region Slice
const vehicleStatusAmountAdapter =
  createEntityAdapter<FleetVehicleStatusSummary>({
    selectId: (vehicleStatusAmount) => vehicleStatusAmount.tenantId,
  });

export const vehicleStatusAmountSlice = createSlice({
  name: "vehicleStatusAmount",
  initialState: vehicleStatusAmountAdapter.getInitialState({
    status: "idle",
    reasonCode: "",
    totalPages: 0,
  }),
  reducers: {
    vehicleStatusAmountEmptyState: (state) => {
      vehicleStatusAmountAdapter.setAll(state, []);
      state.reasonCode = "";
      state.status = "idle";
    },
  },
  extraReducers: (builder) => {
    builder
      //#region Entity Reducers
      .addCase(
        getVehicleStatusAmountAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          vehicleStatusAmountAdapter.upsertMany(
            state,
            action.payload.vehicleStatusCounts ?? {}
          );
          state.status = "idle";
        }
      )
      .addCase(getVehicleStatusAmountAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(getVehicleStatusAmountAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = "";
      });
    //#endregion Entity Reducers
    //#region External Entity Reducers
    //#endregion External Entity Reducers
  },
});
//#endregion Slice

//#region Status
export const vehicleStatusAmountSelectors =
  vehicleStatusAmountAdapter.getSelectors<RootState>(
    (state) => state.vehicleStatusAmount
  );

export const { vehicleStatusAmountEmptyState } =
  vehicleStatusAmountSlice.actions;
export const selectVehicleStatusAmountSliceSliceStatus = (state: any) =>
  state.vehicleStatusAmount.status;
export const selectVehicleStatusAmountSliceSliceReasonCode = (state: any) =>
  state.vehicleStatusAmount.reasonCode;

//#endregion Status

export default vehicleStatusAmountSlice.reducer;
