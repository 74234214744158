import dayjs from "dayjs";
import { t } from "i18next";
import _ from "lodash";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Location,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { TypeOptions } from "react-toastify";
import { useAppSelector } from "../../../app/hooks";
import { store } from "../../../app/store";
import { GTFleetErrorCodes } from "../../../config/GTfleetErrorCodes";
import { Button } from "../../../ui/Button/Button";
import { DropdownButton } from "../../../ui/Button/DropdownButton";
import { Dropdown, JsonOption } from "../../../ui/Dropdown/Dropdown";
import { DatePickerDropdown } from "../../../ui/Forms/DatePickerDropdown";
import Form from "../../../ui/Forms/Form";
import SearchField from "../../../ui/Forms/SearchField";
import { IconCalendar } from "../../../ui/Icon/Line/Calendar";
import { IconDown } from "../../../ui/Icon/Line/Down";
import { IconDownload } from "../../../ui/Icon/Line/Download";
import { IconLocatorOff } from "../../../ui/Icon/Line/LocatorOff";
import { CustomizeColumnsModal } from "../../../ui/Modal/CustomModals/CustomizeColumnsModal";
import { ToastNotification } from "../../../utils/ToastNotification";
import { getPresetsNotification, getQueryString } from "../../../utils/Utils";
import { eventsEmptyState } from "../../event/eventsSlice";
import {
  EventIO,
  eventsIOSelectors,
  getEventsIOAsync,
} from "../../eventIO/eventsIOSlice";
import {
  fleetViewsSelectors,
  getFleetViewsAsync,
} from "../../fleet/fleetViewsSlice";
import { Preferences } from "../../users/preference/preferencesSlice";
import {
  Preset,
  addPreset,
  deletePresetAsync,
  getPresetsAsync,
  newPresetAsync,
  removePreset,
  restoreState,
  selectpresetsSliceReasonCode,
  selectpresetsSliceStatus,
  updatePresetAsync,
} from "../../users/preset/presetsSlice";
import UserContext from "../../users/userContext";
import {
  Vehicle,
  getFilteredVehiclesDetailsAsync,
  selectVehiclesSliceStatus,
  vehiclesEmptyState,
  vehiclesSelectors,
} from "../../vehicle/vehiclesSlice";
import ReportsRepository from "../reportRepository";
import "./ReportIOFilterBar.css";
import {
  getReportsIOAsync,
  reportsIOEmptyState,
  selectReportsIOSliceReasonCode,
  selectReportsIOSliceStatus,
} from "./reportsIOSlice";
import {
  reportsIOSummaryEmptyState,
  selectReportsIOSummarySliceStatus,
} from "./reportsIOSummarySlice";

interface QueryParams {
  [paramName: string]: any;
}

interface DropDownItem {
  label: string;
  value: string;
}

interface IoReportFilterBarProps {
  callback: (buildQueryParam: string) => any;
  presets: any[];
  disableButton: boolean;
}

interface TableColumnEntry {
  id: number;
  name: string;
  value: string;
}

let context: string = "reportIO";

export const IoReportFilterBar: React.FC<IoReportFilterBarProps> = ({
  callback,
  presets,
  disableButton,
}) => {
  const navigate = useNavigate();

  const vehicles: Vehicle[] = useAppSelector(vehiclesSelectors.selectAll);
  const vehicleSliceStatus = useAppSelector(selectVehiclesSliceStatus);
  const queryParamsRef = useRef<QueryParams>({});
  let queryParams: QueryParams = queryParamsRef.current;

  const reportsSliceStatus = useAppSelector(selectReportsIOSliceStatus);
  const reportsSliceReasonCode = useAppSelector(selectReportsIOSliceReasonCode);
  const reportsSummarySliceStatus = useAppSelector(
    selectReportsIOSummarySliceStatus
  );
  const isReportsIdle = reportsSummarySliceStatus === "idle";
  const [isSelectedPeriod, setIsSelectedPeriod] = useState(false);
  const [selectedPeriod, setSelectedPeriod] = useState<Date[]>([]);

  const [isDisabledGenerate, setDisabledGenerate] = useState(true);
  const [queryString, setQueryString] = useState("");
  const [vehicle, setVehicle] = useState<Vehicle>();
  const [vehicleId, setVehicleId] = useState(-1);
  const [alias, setAlias] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isPdfGenerated, setIsPdfGenerated] = useState<boolean>(true);
  const [isExcelGenerated, setIsExcelGenerated] = useState<boolean>(true);
  const searchbarRef = useRef<HTMLHeadingElement>(null);
  const parentRef = useRef<HTMLHeadingElement>(null);
  const reportsRepository = new ReportsRepository();

  const eventsIO: EventIO[] = useAppSelector(eventsIOSelectors.selectAll);

  const [eventIOValues, setEventIOValues] = useState(
    eventsIO.map((value) => ({
      label: t(`IOEvents.${_.camelCase(value.name)}`),
      value: value.name,
    }))
  );
  let defaultColumns: Preset = {} as Preset;
  let customColumns: TableColumnEntry[] = [];

  useEffect(() => {
    store.dispatch(getPresetsAsync(getQueryString({ context: context })));
    store.dispatch(getFleetViewsAsync());
    store.dispatch(getFilteredVehiclesDetailsAsync(""));
    store.dispatch(getEventsIOAsync());

    return () => {
      store.dispatch(eventsEmptyState());
      store.dispatch(reportsIOEmptyState());
      store.dispatch(reportsIOSummaryEmptyState());
      store.dispatch(vehiclesEmptyState());
    };
  }, []);

  //#region " get columns of table "
  const [preferencesContext]: [Preferences] = useContext(UserContext);
  if (!!presets && presets.length > 0) {
    defaultColumns = presets.filter((x) => x.name === "Default")[0];
    if (!!defaultColumns) {
      defaultColumns?.columns.forEach((column, index) =>
        customColumns.push({
          id: index,
          name: t("table.columns." + column),
          value: column,
        })
      );
    }
  }
  //#endregion

  //#region " get group (fleet) of vehicles "
  let vehiclesGroupByFleet = vehicles.reduce((group: any, vehicle: Vehicle) => {
    const { fleet } = vehicle;
    const fleetName = fleetViewsSelectors.selectById(
      store.getState(),
      fleet
    )?.name;
    if (fleetName) {
      group[fleetName] = group[fleetName] ?? [];
      group[fleetName].push(vehicle);
    }
    return group;
  }, {});
  let fleetNames = Object.keys(vehiclesGroupByFleet);
  //#endregion

  useEffect(() => {
    if (eventsIO && eventsIO.length > 0) {
      setEventIOValues(
        eventsIO.map((value) => ({
          label: t(`IOEvents.${_.camelCase(value.name)}`),
          value: value.name,
        }))
      );
    }
  }, [eventsIO]);

  const [selectEventIOValue, setSelectEventIOValue] = useState<JsonOption>();

  const presetsSliceStatus = useAppSelector(selectpresetsSliceStatus);
  const presetsSliceReasonCode = useAppSelector(selectpresetsSliceReasonCode);

  useEffect(() => {
    let presetNotification: {
      status: string | undefined;
      description: string | undefined;
    } = getPresetsNotification(presetsSliceStatus, presetsSliceReasonCode);
    if (
      presetNotification.status !== undefined &&
      presetNotification.description !== undefined
    ) {
      ToastNotification({
        toastId: t(presetNotification.description),
        status: presetNotification.status as TypeOptions,
        description: t(presetNotification.description),
      });
      store.dispatch(restoreState());
    }
  }, [presetsSliceStatus, presetsSliceReasonCode]);

  /**
   * This useEffect takes the vehicles identifiers retrieved from the URL and based
   * on the downloaded vehicles details builds the vehicle list (vehicle hook).
   */
  useEffect(() => {
    if (vehicleId && vehicleSliceStatus === "idle" && vehicles.length > 0) {
      let selectedVehicle =
        vehiclesSelectors.selectById(store.getState(), vehicleId ?? -1) ??
        ({} as Vehicle);

      setVehicle(selectedVehicle);
      if (selectedVehicle.alias !== undefined) {
        setAlias(selectedVehicle.alias);
      }
    }
  }, [vehicleId, vehicles]);

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (
        searchbarRef.current &&
        !searchbarRef.current.contains(event.target) &&
        parentRef.current &&
        !parentRef.current.contains(event.target)
      )
        setIsOpen(false);
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [searchbarRef]);

  const updatePreset = (newPreset: Preset) => {
    if (newPreset?.columns?.length > 0) {
      let temporaryPreset = presets.find((preset) => preset.name === undefined);
      if (temporaryPreset) {
        store.dispatch(removePreset(temporaryPreset.id));
      }
      if (newPreset.name) {
        if (newPreset.name !== "Default" && newPreset.context === undefined) {
          newPreset.context = context;
          newPreset.lastSelected = true;
          store.dispatch(newPresetAsync(newPreset));
        } else {
          store.dispatch(
            updatePresetAsync({
              id: newPreset.id,
              preset: {
                lastSelected: true,
              },
              context: context,
            })
          );
        }
        return true;
      } else {
        newPreset.id = Math.max(...presets.map((preset) => preset.id)) + 1;
        store.dispatch(addPreset(newPreset));
        return false;
      }
    }
  };

  //#region " checking parameters in the url "
  const [searchParams] = useSearchParams();
  const location: Location = useLocation();

  useEffect(() => {
    let generateImmediatly = false;
    const map = new Map();
    const currentSearchParams =
      searchParams.toString() !== "" ? searchParams : null;
    if (!!currentSearchParams && _.isEmpty(queryParams)) {
      // retrieving ids from URL
      generateImmediatly = true;
      const retrievedVehicleId = currentSearchParams.get("vehicleId");
      generateImmediatly = generateImmediatly && Boolean(retrievedVehicleId);
      if (!!retrievedVehicleId) {
        setVehicleId(Number(retrievedVehicleId));
        map.set("vehicleId", retrievedVehicleId);
      }
      // retrieving the start period from URL
      const startPeriod = currentSearchParams.get("startPeriod");
      generateImmediatly = generateImmediatly && !!startPeriod;
      if (startPeriod) {
        map.set("startPeriod", startPeriod);
        setIsSelectedPeriod(true);
        setSelectedPeriod((prev) => {
          let prevValue = prev;
          prevValue[0] = new Date(startPeriod);
          return prevValue;
        });
      }
      // retrieving the end period from URL
      const endPeriod = currentSearchParams.get("endPeriod");
      if (endPeriod) {
        map.set("endPeriod", endPeriod);
        setSelectedPeriod((prev) => {
          let prevValue = prev;
          prevValue[1] = new Date(endPeriod);
          return prevValue;
        });
      }
      // retrieving the events from URL
      const eventName = currentSearchParams.get("event");
      if (eventName) {
        map.set("event", eventName);
        setSelectEventIOValue({ label: eventName });
      }
    }
    if (map.size === 0 && _.isEmpty(queryParams)) {
      const endPeriod = dayjs().format("YYYY-MM-DDTHH:mm");
      const startPeriodDate = new Date(endPeriod);
      startPeriodDate.setHours(0, 0, 0, 0);
      const startPeriod = dayjs(startPeriodDate).format("YYYY-MM-DDTHH:mm");
      map.set("startPeriod", startPeriod);
      map.set("endPeriod", endPeriod);
      setIsSelectedPeriod(true);
      setSelectedPeriod((prev) => {
        let prevValue = prev;
        prevValue[0] = new Date(startPeriod);
        prevValue[1] = new Date(endPeriod);
        return prevValue;
      });
    }

    if (map.size > 0) {
      handleChanges(map, generateImmediatly);
    }
  }, [location]);

  //#endregion

  const handleChanges = (
    params: Map<string, string[] | string>,
    generateImmediatly?: boolean
  ): void => {
    if (!!params) {
      params.forEach((value, key) => {
        if (!!value) {
          queryParams[key] = value;
        } else {
          if (queryParams.hasOwnProperty(key)) {
            delete queryParams[key];
          }
        }
      });
      const stringifiedQueryString = getQueryString(queryParams);
      navigate(`/reports/io-report${stringifiedQueryString}`);
      setQueryString(stringifiedQueryString);

      if (generateImmediatly) {
        store.dispatch(getReportsIOAsync(stringifiedQueryString));
        callback(stringifiedQueryString);
      }
    }
  };

  useEffect(() => {
    setDisabledGenerate(
      !(queryParams && isSelectedPeriod && alias && selectEventIOValue)
    );
  }, [queryString, isSelectedPeriod, vehicle, selectEventIOValue]);

  const GenerateResult = () => {
    if (queryString !== "" && vehicle && isSelectedPeriod) {
      store.dispatch(getReportsIOAsync(queryString));
      callback(queryString);
    }
  };

  if (reportsSliceStatus === "failed" && reportsSliceReasonCode === "") {
    ToastNotification({
      toastId: "networkError",
      status: "error",
      description: t("common.networkError"),
    });
  }

  const options = fleetNames?.map((element: string) => ({
    label: element,
    hasCheckbox: true,
    hasDropdown: true,
    hasCount: true,
    vehicles: vehiclesGroupByFleet[element],
  }));

  const list = [
    {
      id: 0,
      title: t("report.filterBar.downloadExcel"),
      icon: isExcelGenerated ? (
        <IconDownload size={14} color="--global-colors-ink-light" />
      ) : (
        <div className="details-driverReport-download-spinner"></div>
      ),
      onClick: () => {
        downloadResult(false);
      },
    },
    {
      id: 1,
      title: t("report.filterBar.downloadPDF"),
      icon: isPdfGenerated ? (
        <IconDownload size={14} color="--global-colors-ink-light" />
      ) : (
        <div className="details-driverReport-download-spinner"></div>
      ),
      onClick: () => {
        downloadResult(true);
      },
    },
    {
      id: 2,
      title: t("report.filterBar.sendReportEmail"),
      icon: <IconLocatorOff size={14} color="--global-colors-ink-light" />,
      onClick: () => {
        sendReportEmail();
      },
    },
  ];

  const downloadResult = (pdf: boolean) => {
    if ((vehicleId && queryString !== "") || isSelectedPeriod) {
      const params = queryString + (pdf ? "&isPdf=true" : "&isPdf=false");
      pdf ? setIsPdfGenerated(false) : setIsExcelGenerated(false);
      reportsRepository
        .getEventsIOReportDownload(params)
        .then((response) => {
          return response.data;
        })
        .then((data) => {
          const fileName = data.split("/").pop();
          const attachmentDownloadLink = document.createElement("a");
          attachmentDownloadLink.href = process.env.REACT_APP_BUCKET_URL + data;
          attachmentDownloadLink.download = fileName;
          attachmentDownloadLink.target = "_blank";
          attachmentDownloadLink.style.display = "none";
          document.body.appendChild(attachmentDownloadLink);
          attachmentDownloadLink.click();
          document.body.removeChild(attachmentDownloadLink);
          pdf ? setIsPdfGenerated(true) : setIsExcelGenerated(true);
        })
        .catch((error) => {
          pdf ? setIsPdfGenerated(true) : setIsExcelGenerated(true);

          if (
            error.response &&
            error.response.data.message ==
              GTFleetErrorCodes.REPORT_TIMEOUT_EXCEPTION
          ) {
            console.log(error?.message || "Report Timeout Exception");
            ToastNotification({
              toastId: "reportTimeoutError",
              status: "default",
              title: t("common.reportTimeoutErrorTitle"),
              description: t("common.reportTimeoutErrorDesc"),
            });
          } else {
            console.log(error.response?.data?.error || "Unknown Error");
            ToastNotification({
              toastId: "networkError",
              status: "error",
              description: t("common.networkError"),
            });
          }
        });
    }
  };

  const sendReportEmail = () => {
    if ((vehicleId && queryString !== "") || isSelectedPeriod) {
      reportsRepository
        .getEventsIOReportEmail(queryString)
        .then((response) => {
          return response.data;
        })
        .then((data) => {
          ToastNotification({
            toastId: "requestSendingMailSuccess",
            status: "success",
            description: t(
              "report.toastNotification.requestSendingMailSuccess"
            ),
          });
        })
        .catch((error) => {
          if (!error.response) console.log(error?.message || "Unknown Error");
          else console.log(error.response?.data?.error || "Unknown Error");
          ToastNotification({
            toastId: "networkError",
            status: "error",
            description: t("common.networkError"),
          });
        });
    }
    return null;
  };

  const filterVehicle = (vehicle: Vehicle) => {
    const filterVehicle =
      alias == "" ||
      (vehicle?.alias &&
        vehicle?.alias?.toLowerCase().includes(alias.toLowerCase())) ||
      (vehicle?.plate &&
        vehicle?.plate?.toLowerCase().includes(alias.toLowerCase())) ||
      (vehicle?.id &&
        vehicle?.id?.toString()?.toLowerCase().includes(alias.toLowerCase()));
    return filterVehicle;
  };

  return (
    <div className="ior-filterbar-row">
      <div className="ior-filterbar-search">
        <div
          onClick={() => {
            setIsOpen(true);
          }}
          ref={parentRef}
        >
          <Form>
            <SearchField
              name="search"
              id="search-field"
              size="small"
              placeholder={t(
                "locationHistory.searchDropdown.placeholderVehicle"
              )}
              value={alias}
              onChange={(val: string) => {
                const map = new Map();
                !/[a-z]/i.test(val) && map.set("id", val);
                handleChanges(map);
                setAlias(val);
              }}
            />
          </Form>
        </div>
        {isOpen && vehicles.length > 0 && (
          <div className="ior-search-results" ref={searchbarRef}>
            {fleetNames?.map((element: string) => {
              return (
                <React.Fragment key={element}>
                  <div className="ior-fleet-label">
                    {element}
                    {` (${
                      vehiclesGroupByFleet[element].filter(filterVehicle).length
                    })`}
                  </div>
                  {vehiclesGroupByFleet[element]
                    .filter(filterVehicle)
                    .map((vehicle: Vehicle) => (
                      <div
                        key={vehicle.alias}
                        className={
                          alias === vehicle.alias
                            ? "ior-alias-selected"
                            : "ior-alias"
                        }
                        onClick={() => {
                          let map = new Map();
                          map.set("vehicleId", vehicle.id);
                          setIsOpen(false);
                          setVehicleId(vehicle.id);
                          setAlias(vehicle.alias);
                          handleChanges(map);
                        }}
                      >
                        {vehicle.alias} <span className="ior-dot" />
                        {vehicle.plate}
                      </div>
                    ))}
                </React.Fragment>
              );
            })}
          </div>
        )}
      </div>
      <div className="ior-date-picker">
        <Form>
          <DatePickerDropdown
            setDate={(val) => {
              const map = new Map();
              const startPeriod =
                typeof val[0] === "string"
                  ? val[0]
                  : val[0].format("YYYY-MM-DDTHH:mm");
              map.set("startPeriod", startPeriod);
              map.set("endPeriod", val[1]?.format("YYYY-MM-DDTHH:mm") ?? "");
              setIsSelectedPeriod(true);
              handleChanges(map);
            }}
            hasTime={true}
            initialValue={selectedPeriod.length ? selectedPeriod : []}
            defaultValue={new Date()}
            localeFormat={preferencesContext.localeFormat ?? "DD / MM / YYYY"}
            language={preferencesContext.language ?? "it"}
            icon={<IconCalendar size={12} color="--global-colors-ink-light" />}
            dateRange={[dayjs().subtract(1, "year"), dayjs()]} //From today to past 1 year
            limitDaysRange={31}
            clearCallback={() => {
              const map = new Map();
              const startPeriod = dayjs().format("YYYY-MM-DDTHH:mm");
              map.set("startPeriod", startPeriod);
              setIsSelectedPeriod(true);
              handleChanges(map);
            }}
          />
        </Form>
      </div>
      {eventIOValues.length > 0 && (
        <div className="ior-dropdown-events">
          <Dropdown
            itemAttribute="label"
            placeholder={t("report.filterBar.IoEvents")}
            size="small"
            onChange={(val: DropDownItem[]) => {
              const map = new Map<string, any>();
              map.set(
                "event",
                val.map((x) => x.value)
              );
              handleChanges(map);
              setSelectEventIOValue({ label: val[0].value });
            }}
            options={eventIOValues}
            value={eventIOValues.filter(
              (event) => event.value === selectEventIOValue?.label
            )}
          />
        </div>
      )}

      <div className="ior-dropdown-customize">
        <CustomizeColumnsModal
          columns={customColumns}
          presets={presets ?? []}
          onClose={(newPreset) => updatePreset(newPreset)}
          handleDelete={(e: any) => store.dispatch(deletePresetAsync(e))}
        />
      </div>

      <div className="ior-filterbar-btn-generate">
        <Button
          aspect="primary"
          size="small"
          label={t("report.filterBar.buttonGenerate")}
          onClick={() => GenerateResult()}
          isLoading={isReportsIdle ? false : true}
          disabled={isDisabledGenerate}
        />
      </div>

      <div className="ior-filterbar-btn ">
        <div className="ior-filterbar-btn-schedule">
          {false && (
            <Button
              aspect="secondary"
              size="small"
              label={t("report.filterBar.schedule")}
              onClick={() => alert("schedulation generated")}
              disabled={true}
            >
              <IconCalendar size={14} color="--global-colors-ink-ink" />
            </Button>
          )}
        </div>
        {
          <DropdownButton
            aspect="secondary"
            size="small"
            disabled={disableButton}
            label={t("common.export")}
            list={list}
          >
            <IconDown size={14} color="--global-colors-ink-ink" />
          </DropdownButton>
        }
      </div>
    </div>
  );
};
