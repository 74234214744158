import HttpClient from "../../utils/HttpClient";
import { Fleet, FleetUpdate } from "./fleetsSlice";

export default class FleetViewsRepository extends HttpClient {
  constructor() {
    super(true);
  }

  public getFleet(fleetId: number) {
    const tenantId = localStorage.getItem("tenantId");
    let path = "/tenants/" + tenantId + "/fleets/" + fleetId;
    return this.instance.get(path);
  }

  public getFleets(queryParams?: string) {
    const tenantId = localStorage.getItem("tenantId");
    let path = "/tenants/" + tenantId + "/fleets/views";
    return this.instance.get(path + (queryParams ?? ""));
  }

  public getFilteredFleets(queryParams?: string) {
    const tenantId = localStorage.getItem("tenantId");
    let path = "/tenants/" + tenantId + "/fleets/view";
    return this.instance.get(path + (queryParams ?? ""));
  }

  public createFleet(fleet: Partial<Fleet>) {
    const tenantId = localStorage.getItem("tenantId");
    const userId = localStorage.getItem("customerId");
    return this.instance.post(
      `/tenants/${tenantId}/users/${userId}/fleets`,
      fleet
    );
  }

  public updateFleet(id: number, fleet: Partial<FleetUpdate>) {
    const tenantId = localStorage.getItem("tenantId");
    return this.instance.patch(`/tenants/${tenantId}/fleets/` + id, fleet);
  }

  public deleteFleet(fleetId: number) {
    const tenantId = localStorage.getItem("tenantId");
    return this.instance.delete(`tenants/${tenantId}/fleets/${fleetId}`);
  }
}
