import React from "react";
import "./WidgetHelp.css";

export const WidgetHelp = ({ children, ...props }) => {
  return (
    <div className="mn-widget-help" {...props}>
      <ul className="mn-widget-help__list">{children}</ul>
    </div>
  );
};
