import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { IconParkingPoint } from "../Icon/Line/ParkingPoint";

import { t } from "i18next";
import { Pending } from "../Indicators/Pending";
import "./BreakRow.css";

export const BreakRow = ({
  id,
  title,
  time,
  breakLocation,
  active,
  clickCallback,
  open,
  ...props
}) => {
  const [isSelected, setIsSelected] = useState(active);
  let className = "mn-break-row";

  if (breakLocation) {
    className += " mn-break-row--location";
  }

  if (isSelected) {
    className += " mn-break-row--focused";
  }

  const onClick = () => {
    if (clickCallback !== undefined) {
      clickCallback && clickCallback(!isSelected);
    }
    setIsSelected(!isSelected);
  };

  useEffect(() => {
    setIsSelected(active);
  }, [active]);

  return (
    <div
      key={id}
      id={`mn-break-row-${id}`}
      className={className}
      onClick={onClick}
      {...props}
    >
      <div className="mn-break-row__icon">
        <IconParkingPoint size={20} />
      </div>
      <div className="mn-break-row__location">
        {breakLocation && <div>{breakLocation}</div>}
        <div className="mn-break-row__content">
          <div>
            <span>{title}</span>
            <span className="mn-break-row__content-time">{time}</span>
          </div>
          {open && (
            <div className="mn-break-row__content__stop-info">
              <span data-for="tooltip" data-tip={t("common.inProgress")}>
                <div className="mn-break-row__pending">
                  <Pending />
                </div>
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

BreakRow.propTypes = {
  id: PropTypes.number.isRequired,
  time: PropTypes.string,
  title: PropTypes.string,
  breakLocation: PropTypes.string,
};
