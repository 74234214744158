import i18next from "i18next";
import { useState } from "react";

import { Direction } from "../Direction/Direction";
import { Tooltip } from "../Forms/Tooltip";
import "../LocationHistory/ViewArrow.css";

export interface VehicleArrowDisabledProps {
  isDisabled: boolean;
}

interface ViewArrowProps {
  onClick: (e: boolean) => any;
  isDisableArrow: VehicleArrowDisabledProps | undefined;
}

export const ViewArrow: React.FC<ViewArrowProps> = ({ onClick }) => {
  const [active, setActive] = useState<boolean>(false);

  const handleClick = () => {
    setActive((prevActive) => !prevActive);

    onClick(!active);
  };

  return (
    <>
      <Tooltip />
      <div
        className="zoom-control"
        data-for="tooltip"
        data-tip={i18next.t("tooltip.arrows")}
      >
        <div
          onClick={handleClick}
          className={
            !active
              ? "map-button-view-arrow-deactive"
              : "map-button-view-arrow-active"
          }
        >
          <Direction color={!active ? "white" : "#35465b"} />
        </div>
      </div>
    </>
  );
};
