import i18next, { t } from "i18next";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { store } from "../../app/store";
import { GTFleetSuccessCodes } from "../../config/GTFleetSuccessCodes";
import { GTFleetErrorCodes } from "../../config/GTfleetErrorCodes";
import { Button } from "../../ui/Button/Button";
import { Dropdown } from "../../ui/Dropdown/Dropdown";
import Form from "../../ui/Forms/Form";
import TextField from "../../ui/Forms/TextField";
import { IconAdd } from "../../ui/Icon/Line/Add";
import { NewCategoryModal } from "../../ui/Modal/CustomModals/NewCategoryModal";
import { ToastNotification } from "../../utils/ToastNotification";
import { getQueryString } from "../../utils/Utils";
import {
  GeofenceCategory,
  createGeofenceCategoryAsync,
  geofenceCategoriesEmptyState,
  geofenceCategoriesSelectors,
  selectGeofenceCategoriesSliceReasonCode,
  selectGeofenceCategoriesSliceStatus,
} from "../geofenceCategory/geofenceCategoriesSlice";
import "./CreateGeofenceSaveBar.css";
import {
  Circle,
  Geofence,
  GeofenceShapeEnum,
  Polygon,
  geofencesEmptyState,
  getGeofencesAsync,
  selectGeofencesSliceReasonCode,
  selectGeofencesSliceStatus,
  updateGeofenceAsync,
} from "./geofenceSlice";

interface DropdownCategoriesProps {
  label: string;
  color: string;
}
interface EditGeofenceFilterBarProps {
  drawedPoints: Polygon | Circle;
  shapeType: GeofenceShapeEnum;
  geofenceId: number;
  setGeofence: Geofence;
  editedShape: boolean;
}

export const EditGeofenceFilterBar: React.FC<EditGeofenceFilterBarProps> = ({
  drawedPoints,
  shapeType,
  geofenceId,
  setGeofence,
  editedShape,
}) => {
  const [geofenceName, setGeofenceName] = useState("");
  const [newCategory, setNewCategory] = useState(false);

  const navigate = useNavigate();

  const [selectedCategory, setSelectedCategory] = useState(
    {} as DropdownCategoriesProps
  );
  const [editGeofenceInfo, setEditGeofenceInfo] = useState(
    {} as Partial<Geofence>
  );

  const geofenceCategories: GeofenceCategory[] = useAppSelector(
    geofenceCategoriesSelectors.selectAll
  );
  const geofenceCategoriesSliceStatus = useAppSelector(
    selectGeofenceCategoriesSliceStatus
  );
  const geofenceCategoriesSliceReasonCode = useAppSelector(
    selectGeofenceCategoriesSliceReasonCode
  );

  const geofencesSliceStatus = useAppSelector(selectGeofencesSliceStatus);
  const geofencesSliceReasonCode = useAppSelector(
    selectGeofencesSliceReasonCode
  );

  useEffect(() => {
    if (!!setGeofence) {
      setEditGeofenceInfo(setGeofence);
      setGeofenceName(setGeofence.name);
      if (geofenceCategories.length) {
        const categoryGeofence = geofenceCategories.find(
          (item) => item.id === setGeofence.geofenceCategory
        );

        setSelectedCategory({
          ...selectedCategory,
          label: categoryGeofence?.name ?? "",
          color: categoryGeofence?.color ?? "",
        });
      }
    }
  }, []);

  // Chiamata API per la dropdown delle categorie
  useEffect(() => {
    store.dispatch(
      getGeofencesAsync({ queryParams: getQueryString({ status: "ACTIVE" }) })
    );
    return function cleanUp() {
      store.dispatch(geofencesEmptyState());
      store.dispatch(geofenceCategoriesEmptyState());
    };
  }, []);

  function calculateWidth(inputValue: string) {
    return { width: inputValue.length + 5 + "ch" };
  }

  useEffect(() => {
    if (geofenceCategoriesSliceStatus === "idle") {
      switch (geofenceCategoriesSliceReasonCode) {
        case GTFleetSuccessCodes.POST:
          ToastNotification({
            toastId: "geofenceCategorySuccess",
            status: "success",
            description: t("common.newCategorySuccess"),
          });
          break;
      }
    } else if (geofenceCategoriesSliceStatus === "failed") {
      switch (geofenceCategoriesSliceReasonCode) {
        case GTFleetErrorCodes.GEOFENCE_CATEGORIES_NAME_ALREADY_EXISTS:
          ToastNotification({
            toastId: "geofenceCategoriesNameExists",
            status: "error",
            description: t(
              "admin.geofences.editViewCreate.geofenceCategoriesNameExists"
            ),
          });
          break;
        case "":
          ToastNotification({
            toastId: "networkError",
            status: "error",
            description: t("common.networkError"),
          });
          break;
      }
    }
  }, [geofenceCategoriesSliceStatus, geofenceCategoriesSliceReasonCode]);

  useEffect(() => {
    if (
      geofencesSliceStatus === "idle" &&
      geofencesSliceReasonCode === GTFleetSuccessCodes.PATCH
    ) {
      ToastNotification({
        toastId: "editGeofenceSuccess",
        status: "success",
        description: t("common.editGeofenceSuccess"),
      });

      navigate("/admin/geofences");
    }
    if (geofencesSliceStatus === "failed") {
      //gestire not found
      if (geofencesSliceReasonCode === "") {
        ToastNotification({
          toastId: "networkError",
          status: "error",
          description: t("common.networkError"),
        });
      }
    }
  }, [geofencesSliceStatus, geofencesSliceReasonCode]);

  const handleButtonDisable = () => {
    if (
      !editedShape &&
      geofenceName === setGeofence.name &&
      editGeofenceInfo.geofenceCategory === setGeofence.geofenceCategory
    ) {
      if (document.querySelector(".infoMessage"))
        (document.querySelector(".infoMessage") as HTMLElement).style.display =
          "block";
      return true;
    }
    if (document.querySelector(".infoMessage"))
      (document.querySelector(".infoMessage") as HTMLElement).style.display =
        "none";
    return false;
  };

  return (
    <>
      <div className="cg-savebar-row-splitter">
        <div className="cg-savebar-search">
          <Form>
            <TextField
              id={t("common.name")}
              validate="alphabetic"
              placeholder={t(
                "admin.geofences.editViewCreate.filterBar.namePlaceholder"
              )}
              value={geofenceName}
              events={{
                onChange: (data) => {
                  setEditGeofenceInfo({
                    ...editGeofenceInfo,
                    name: data.value,
                  });
                  setGeofenceName(data.value);
                },
              }}
              maxLength="100"
            />
          </Form>
        </div>
        <div className="cg-savebar-dropdown-columns">
          <Dropdown
            value={selectedCategory}
            itemAttribute="label"
            placeholder={t(
              "admin.geofences.editViewCreate.filterBar.categoryPlaceholder"
            )}
            placeholderInValue={t(
              "admin.geofences.editViewCreate.filterBar.categoryDefaultPlaceholder"
            )}
            size={"small"}
            onChange={(val: any) => {
              setEditGeofenceInfo({
                ...editGeofenceInfo,
                geofenceCategory: parseInt(val[0]?.id),
              });
              setSelectedCategory({
                label: val[0]?.label,
                color: val[0]?.color,
              });
            }}
            options={geofenceCategories.map((category) => {
              return {
                id: category.id,
                label: category.name,
                color: category.color,
              };
            })}
            hasButton={true}
            buttonClick={() => setNewCategory(true)}
            buttonText={t(
              "admin.geofences.editViewCreate.filterBar.buttonDropdown"
            )}
          >
            <IconAdd color="--global-colors-ink-light" size={14} />
          </Dropdown>
        </div>
        <div className="cg-savebar-btn">
          <Button
            id="saveGeofenceButton"
            size="small"
            aspect="primary"
            label={i18next.t("common.save")}
            onClick={() => {
              store.dispatch(
                updateGeofenceAsync({
                  geofenceId: geofenceId,
                  geofenceObj: {
                    ...editGeofenceInfo,
                    shape: drawedPoints,
                    geofenceShapeEnum: shapeType,
                  },
                })
              );
            }}
            disabled={handleButtonDisable()}
          />
        </div>
      </div>
      <NewCategoryModal
        type="geofence"
        open={newCategory}
        onClose={() => {
          setNewCategory(false);
        }}
        primaryAction={(e?: any) => {
          if (e !== undefined) {
            let geofenceCategory: GeofenceCategory = e as GeofenceCategory;
            store.dispatch(createGeofenceCategoryAsync({ geofenceCategory }));
          }
          setNewCategory(false);
        }}
      />
    </>
  );
};
