import {
  PayloadAction,
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import _ from "lodash";
import { normalize } from "normalizr";
import { RootState } from "../../../app/store";
import { Config } from "../../../config/Config";
import { GTFleetSuccessCodes } from "../../../config/GTFleetSuccessCodes";
import {
  driverTravelSummarySchema,
  driverTravelsSummarySchema,
} from "./driverTravelNormalization";
import DriverTravelsRepository from "./driverTravelRepository";

//#region Type

export type RoutePosition = {
  latitude: number;
  longitude: number;
  gpsPositionTimestamp: Date;
  address: string;
};

export type DriverTravelSummary = {
  uuid: number;
  routeId: number;
  firstPosition: RoutePosition;
  lastPosition: RoutePosition;
  vehicleId: number;
  driverDetails: number;
  traveled: number;
  parkingTime: number;
  drivingTime: number;
  consumption: number;
  averageSpeed: number;
  maxSpeed: number;
  costs: number;
};

type PagingStateType = {
  pagingState?: string;
};

type PagingStateMapping = {
  page: number;
  pagingState: string;
};

//#endregion Type

//#region API
// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const getDriverTravelsSummaryAsync = createAsyncThunk(
  "driverTravels/getDriverTravelsSummary",
  async (
    data: { driverId: number; queryParams?: string; page: number },
    thunkAPI
  ) => {
    const driverTravelsRepository = new DriverTravelsRepository();
    const pagingStates: PagingStateMapping[] =
      selectDriverTravelsSummarySlicePagingStates(thunkAPI.getState());
    const pagingStateMap = pagingStates.find((x) => x.page === data.page);
    const pagingState = {
      pagingState: pagingStateMap?.pagingState,
    } as PagingStateType;
    const response = await driverTravelsRepository.getDriverTravelsSummary(
      data.driverId,
      data.queryParams,
      pagingState
    );
    // The value we return becomes the `fulfilled` action payload
    const driverTravelsSummary = _.get(
      response,
      Config.DRIVER_TRAVELS_RESPONSE_PATH
    );
    const currentPagingState = _.get(
      response,
      Config.DRIVER_TRAVELS_PAGING_STATE_RESPONSE_PATH
    );
    thunkAPI.dispatch(
      setNewPagingState({
        page: data.page + 1,
        pagingState: currentPagingState,
      })
    );
    const normalizedData = normalize(
      driverTravelsSummary,
      driverTravelsSummarySchema
    );
    return normalizedData.entities;
  }
);

export const getDriverTravelSummaryAsync = createAsyncThunk(
  "driverTravelsSummary/getDriverTravel", //dp: rinominare
  async (data: { driverId: number; routeId: number; queryParams?: string }) => {
    const reportsRepository = new DriverTravelsRepository();
    const response = await reportsRepository.getDriverTravelSummary(
      data.driverId,
      data.routeId,
      data.queryParams
    );
    // // The value we return becomes the `fulfilled` action payload
    const vehicleTravel = _.get(
      response,
      Config.VEHICLE_TRAVEL_SUMMARY_RESPONSE_PATH
    );
    const normalizedData = normalize(vehicleTravel, driverTravelSummarySchema);
    return normalizedData.entities;
  }
);

export const getDriverLastTracksAsync = createAsyncThunk(
  "driverTravelsSummary/getDriverLastTracks",
  async (data: { driverId: number; queryParams?: string }, thunkAPI) => {
    try {
      const driverTravelsRepository = new DriverTravelsRepository();

      const response = await driverTravelsRepository.getDriverLastTracks(
        data.driverId,
        data.queryParams
      );
      // The value we return becomes the `fulfilled` action payload
      const driverTravelSummary = _.get(
        response,
        Config.DRIVER_LAST_TRACKS_RESPONSE_PATH
      );

      const normalizedData = normalize(
        driverTravelSummary,
        driverTravelsSummarySchema
      );
      return normalizedData.entities;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

//#endregion API

//#region Custom Functions
function setFilteredData(state: any, action: PayloadAction<any>) {
  action.payload.driverTravelsSummary
    ? driverTravelsSummaryAdapter.setAll(
        state,
        action.payload.driverTravelsSummary
      )
    : driverTravelsSummaryAdapter.setAll(state, []);
}
//#endregiorn Custom Functions

//#region Slice
const driverTravelsSummaryAdapter = createEntityAdapter<DriverTravelSummary>({
  selectId: (driverTravel) => driverTravel.routeId,
  sortComparer: (a, b) =>
    a.routeId.toString().localeCompare(b.routeId.toString()),
});

export const driverTravelsSummarySlice = createSlice({
  name: "driverTravelsSummarySlice",
  initialState: driverTravelsSummaryAdapter.getInitialState({
    status: "idle",
    reasonCode: "",
    totalPages: 0,
    pagingStates: [] as PagingStateMapping[],
  }),
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setNumberOfPages: (state, action: PayloadAction<number>) => {
      state.totalPages = action.payload;
    },
    setNewPagingState: (
      state,
      action: PayloadAction<{
        page: number;
        pagingState: string;
      }>
    ) => {
      const pagingStates = state.pagingStates;
      const newPagingState = [...pagingStates];
      const pagingStateMap = newPagingState.find(
        (x) => x.page === action.payload.page
      );
      if (!pagingStateMap) {
        newPagingState.push({
          page: action.payload.page,
          pagingState: action.payload.pagingState,
        } as PagingStateMapping);
      }
      state.pagingStates = newPagingState;
    },
    driverTravelsSummaryEmptyState: (state) => {
      driverTravelsSummaryAdapter.setAll(state, []);
      state.status = "idle";
      state.reasonCode = "";
      state.pagingStates = [] as PagingStateMapping[];
      state.totalPages = 0;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      //#region Entity Reducers
      // getDriverTravelsAsync extra reducers
      .addCase(
        getDriverTravelsSummaryAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          setFilteredData(state, action);
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.GET;
        }
      )
      .addCase(getDriverTravelsSummaryAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(getDriverTravelsSummaryAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = "";
      })
      .addCase(
        getDriverTravelSummaryAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          setFilteredData(state, action);
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.GET;
        }
      )
      .addCase(getDriverTravelSummaryAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(getDriverTravelSummaryAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = "";
      })
      .addCase(
        getDriverLastTracksAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          setFilteredData(state, action);
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.GET;
        }
      )
      .addCase(getDriverLastTracksAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(getDriverLastTracksAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = "";
      });
    //#endregion Entity Reducers
  },
});
//#endregion Slice

//#region Status
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const driverTravelsSummarySelectors =
  driverTravelsSummaryAdapter.getSelectors<RootState>(
    (state) => state.driverTravelsSummary
  );

export const selectDriverTravelsSummarySliceStatus = (state: any) =>
  state.driverTravelsSummary.status;
export const selectDriverTravelsSummarySliceReasonCode = (state: any) =>
  state.driverTravelsSummary.reasonCode;
export const selectDriverTravelsSummarySlicePage = (state: any) =>
  state.driverTravelsSummary.totalPages;
export const selectDriverTravelsSummarySlicePagingStates = (state: any) =>
  state.driverTravelsSummary.pagingStates;

export const {
  setNumberOfPages,
  driverTravelsSummaryEmptyState,
  setNewPagingState,
} = driverTravelsSummarySlice.actions;

//#endregion Status

export default driverTravelsSummarySlice.reducer;
