import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import _ from "lodash";
import { normalize } from "normalizr";
import { RootState } from "../../app/store";
import { Config } from "../../config/Config";
import { GTFleetSuccessCodes } from "../../config/GTFleetSuccessCodes";
import {
  getFilteredDriversStatusAndDetailsAsync,
  getFilteredDriversStatusAndDetailsPaginationAsync,
} from "../driver/driversStatusSlice";
import { getDeadlinesAsync } from "../maintenance-deadline/deadlinesSlice";
import { getReportsIOAsync } from "../report/vehicle/reportsIOSlice";
import { getRoutesHistoryAsync } from "../route/routesHistorySlice";
import {
  getFilteredVehiclesDetailsAsync,
  getVehicleDetailsAsync,
  getVehiclesDetailsAsync,
  getVehiclesDetailsPaginationAsync,
} from "../vehicle/vehiclesSlice";
import {
  getFilteredVehiclesStatusAsync,
  getVehicleAsync,
  getVehiclesAsync,
} from "../vehicle/vehiclesStatusSlice";
import { getVehiclesViewAsync } from "../vehicle/vehiclesStatusViewSlice";
import { fleetsSchema } from "./fleetNormalization";
import FleetsRepository from "./fleetsRepository";
import {
  createFleetAsync,
  deleteFleetAsync,
  Fleet,
  updateFleetAsync,
} from "./fleetsSlice";

//#region Type
export type FleetView = {
  id: number;
  name: string;
  user: number;
  vehiclesSize: number;
  driversSize: number;
  geofencesSize: number;
};
//#endregion Type

//#region API
export const getFleetViewsAsync = createAsyncThunk(
  "fleets/getFleetViews",
  async (_data, { rejectWithValue }) => {
    try {
      const fleetsRepository = new FleetsRepository();
      const response = await fleetsRepository.getFleets();
      const fleets = _.get(response, Config.FLEET_VIEWS_RESPONSE_PATH);
      const normalizedData = normalize(fleets, fleetsSchema);
      return normalizedData.entities;
    } catch (error: any) {
      if (!error.response) throw error;
      return rejectWithValue(error.response);
    }
  }
);
export const getFilteredFleetsAsync = createAsyncThunk(
  "fleets/getFilteredFleets",
  async (
    data: { queryParams?: string; isActive?: boolean },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const fleetsRepository = new FleetsRepository();
      if (data.isActive != undefined) {
        data.queryParams =
          (_.isEmpty(data.queryParams)
            ? "?isActive="
            : data.queryParams + "&isActive=") + data.isActive;
      }
      const response = await fleetsRepository.getFilteredFleets(
        data.queryParams
      );
      const fleets = _.get(response, Config.FLEET_VIEWS_FILTERED_RESPONSE_PATH);
      const totalPages = _.get(
        response,
        Config.FLEETS_VIEW_TOTAL_PAGES_RESPONSE_PATH
      );
      const totalElements = _.get(
        response,
        Config.FLEETS_VIEW_TOTAL_ELEMENTS_RESPONSE_PATH
      );
      if (totalPages) {
        dispatch(setNumberOfPages(totalPages));
      }
      if (totalElements) {
        dispatch(setNumberOfElements(totalElements));
      }
      if (fleets) {
        const normalizedData = normalize(fleets, fleetsSchema);
        return normalizedData.entities;
      } else {
        return [];
      }
    } catch (error: any) {
      if (!error.response) throw error;
      return rejectWithValue(error.response);
    }
  }
);

//#endregion API

//#region Custom Functions
function setFilteredData(state: any, action: PayloadAction<any>) {
  action.payload.fleet
    ? fleetViewsAdapter.setAll(state, action.payload.fleet)
    : fleetViewsAdapter.setAll(state, []);
}
//#endregiorn Custom Functions

//#region Slice
const fleetViewsAdapter = createEntityAdapter<FleetView>({
  selectId: (fleetView) => fleetView.id,
  sortComparer: (a, b) => a.name.localeCompare(b.name),
});

export const fleetViewSlice = createSlice({
  name: "fleetViews",
  initialState: fleetViewsAdapter.getInitialState({
    status: "idle",
    reasonCode: "",
    totalPages: 0,
    totalElements: 0,
  }),
  reducers: {
    fleetViewsEmptyState: (state) => {
      fleetViewsAdapter.setAll(state, []);
      state.reasonCode = "";
      state.status = "idle";
    },

    setNumberOfPages: (state, action: PayloadAction<number>) => {
      state.totalPages = action.payload;
    },
    setNumberOfElements: (state, action: PayloadAction<number>) => {
      state.totalElements = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      //#region Entity Reducers
      .addCase(
        getFleetViewsAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          fleetViewsAdapter.upsertMany(state, action.payload.fleet ?? []);
          state.status = "idle";
        }
      )
      .addCase(getFleetViewsAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(getFleetViewsAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = "";
      })
      //#endregion Entity Reducers

      //#region External Entity Reducers
      .addCase(
        getVehiclesViewAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          action.payload.fleet &&
            fleetViewsAdapter.upsertMany(state, action.payload.fleet);
        }
      )
      .addCase(
        getVehiclesDetailsPaginationAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          action.payload.fleet &&
            fleetViewsAdapter.upsertMany(state, action.payload.fleet);
        }
      )
      .addCase(
        getVehicleAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          action.payload.fleet &&
            fleetViewsAdapter.upsertMany(state, action.payload.fleet);
        }
      )
      .addCase(
        getVehiclesAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          action.payload.fleet &&
            fleetViewsAdapter.upsertMany(state, action.payload.fleet);
        }
      )
      .addCase(
        getVehiclesDetailsAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          action.payload.fleet &&
            fleetViewsAdapter.upsertMany(state, action.payload.fleet);
        }
      )
      .addCase(
        getVehicleDetailsAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          action.payload.fleet &&
            fleetViewsAdapter.upsertMany(state, action.payload.fleet);
        }
      )
      .addCase(
        getFilteredVehiclesDetailsAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          action.payload.fleet &&
            fleetViewsAdapter.upsertMany(state, action.payload.fleet);
        }
      )
      .addCase(
        getRoutesHistoryAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          action.payload.fleet &&
            fleetViewsAdapter.upsertMany(state, action.payload.fleet);
        }
      )
      .addCase(
        getFilteredVehiclesStatusAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          setFilteredData(state, action);
        }
      )
      .addCase(
        getReportsIOAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          action.payload.fleet &&
            fleetViewsAdapter.upsertMany(state, action.payload.fleet);
        }
      )
      .addCase(
        getDeadlinesAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          if (action.payload?.fleet) {
            fleetViewsAdapter.upsertMany(state, action.payload.fleet);
          }
        }
      )
      // getFilteredDriversStatusAndDetailsPaginationAsync extra reducers
      .addCase(
        getFilteredDriversStatusAndDetailsPaginationAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          if (action.payload.fleet) {
            fleetViewsAdapter.upsertMany(state, action.payload.fleet);
          }
        }
      )
      .addCase(
        getFilteredDriversStatusAndDetailsAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          if (action.payload.fleet) {
            fleetViewsAdapter.upsertMany(state, action.payload.fleet);
          }
        }
      )
      .addCase(
        getFilteredFleetsAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          setFilteredData(state, action);
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.GET;
        }
      )
      .addCase(getFilteredFleetsAsync.rejected, (state: any) => {
        state.status = "failed";
      })
      .addCase(getFilteredFleetsAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(
        createFleetAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          if (action.payload.fleet) {
            const fleet = Object.entries(action.payload.fleet)[0][1] as Fleet;
            const fleetView: FleetView = {} as FleetView;
            fleetView.id = fleet.id;
            fleetView.driversSize = fleet?.drivers?.length ?? 0;
            fleetView.vehiclesSize = fleet?.vehicles?.length ?? 0;
            fleetView.geofencesSize = fleet?.geofences?.length ?? 0;
            fleetView.user = fleet?.user;
            fleetView.name = fleet.name;
            fleetViewsAdapter.addOne(state, fleetView);
          }
        }
      )
      .addCase(
        deleteFleetAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          if (action.payload && _.isNumber(action.payload)) {
            fleetViewsAdapter.removeOne(state, Number(action.payload));
          }
        }
      )
      .addCase(
        updateFleetAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          if (action.payload.fleet) {
            const fleet = Object.entries(action.payload.fleet)[0][1] as Fleet;
            const fleetView: FleetView = {} as FleetView;
            fleetView.id = fleet.id;
            fleetView.driversSize = fleet?.drivers?.length ?? 0;
            fleetView.vehiclesSize = fleet?.vehicles?.length ?? 0;
            fleetView.geofencesSize = fleet?.geofences?.length ?? 0;
            fleetView.user = fleet?.user;
            fleetView.name = fleet.name;
            fleetViewsAdapter.addOne(state, fleetView);
          }
        }
      );

    //#endregion External Entity Reducers
  },
});
//#endregion Slice

//#region Status
export const fleetViewsSelectors = fleetViewsAdapter.getSelectors<RootState>(
  (state) => state.fleetViews
);

export const selectFleetsSlicePage = (state: any) =>
  state.fleetViews.totalPages;
export const selectFleetsSliceTotalElements = (state: any) =>
  state.fleetViews.totalElements;

export const { fleetViewsEmptyState, setNumberOfPages, setNumberOfElements } =
  fleetViewSlice.actions;
export const selectFleetViewsSliceStatus = (state: any) =>
  state.fleetViews.status;
export const selectFleetViewsSliceReasonCode = (state: any) =>
  state.fleetViews.reasonCode;

//#endregion Status

export default fleetViewSlice.reducer;
