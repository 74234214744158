import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import _ from "lodash";
import { normalize } from "normalizr";
import { RootState } from "../../app/store";
import { Config } from "../../config/Config";
import { GTFleetSuccessCodes } from "../../config/GTFleetSuccessCodes";
import { tachographFilesStatusSchema } from "./tachographFileNormalization";
import TachographFileRepository from "./tachographFilesRepository";
import { TachographFile } from "./tachographFilesSlice";

//#region Type
export interface TachographFileStatus extends TachographFile {
  status: TachographFileStatusType;
  delay: number;
  deadline: Date;
}

type PagingStateType = {
  pagingState?: string;
};

type PagingStateMapping = {
  page: number;
  pagingState: string;
};

export const tachographFileStatusStatusValues = {
  OK: "OK",
  PENDING: "PENDING",
  LATE: "LATE",
};
export type TachographFileStatusType =
  keyof typeof tachographFileStatusStatusValues;

//#endregion Type

//#region API
export const getTachographFilesStatusAsync = createAsyncThunk(
  "tachographFile/getTachographFilesStatus",
  async (
    data: {
      queryParams?: string;
      page: number;
    },
    thunkAPI
  ) => {
    const tachographFileRepository = new TachographFileRepository();
    const pagingStates: PagingStateMapping[] =
      selectTachographFilesStatusSlicePagingStates(thunkAPI.getState());
    const pagingStateMap = pagingStates.find((x) => x.page === data.page);
    const pagingState = {
      pagingState: pagingStateMap?.pagingState,
    } as PagingStateType;
    const response = await tachographFileRepository.getTachographFilesStatus(
      data.queryParams,
      pagingState
    );
    // The value we return becomes the `fulfilled` action payload
    const tachographFilesStatus = _.get(
      response,
      Config.TACHOGRAPH_FILES_STATUS_RESPONSE_PATH
    );
    const currentPagingState = _.get(
      response,
      Config.TACHOGRAPH_FILES_STATUS_PAGING_STATE_RESPONSE_PATH
    );
    thunkAPI.dispatch(
      setNewPagingState({
        page: data.page + 1,
        pagingState: currentPagingState,
      })
    );
    const normalizedData = normalize(
      tachographFilesStatus,
      tachographFilesStatusSchema
    );
    return normalizedData.entities;
  }
);

//#endregion API

//#region Custom Functions
function setFilteredData(state: any, action: PayloadAction<any>) {
  action.payload.tachographFileStatus
    ? tachographFilesStatusAdapter.setAll(
        state,
        action.payload.tachographFileStatus
      )
    : tachographFilesStatusAdapter.setAll(state, []);
}
//#endregiorn Custom Functions

//#region Slice
const tachographFilesStatusAdapter = createEntityAdapter<TachographFileStatus>({
  selectId: (tachographFileStatus) => tachographFileStatus.fileName,
  sortComparer: (a, b) => a.fileName.localeCompare(b.fileName),
});

export const tachographFileStatusSlice = createSlice({
  name: "tachographFiles",
  initialState: tachographFilesStatusAdapter.getInitialState({
    status: "idle",
    reasonCode: "",
    totalPages: 0,
    pagingStates: [] as PagingStateMapping[],
  }),
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setNumberOfPages: (state, action: PayloadAction<number>) => {
      state.totalPages = action.payload;
    },
    setNewPagingState: (
      state,
      action: PayloadAction<{
        page: number;
        pagingState: string;
      }>
    ) => {
      const pagingStates = state.pagingStates;
      const newPagingState = [...pagingStates];
      const pagingStateMap = newPagingState.find(
        (x) => x.page === action.payload.page
      );
      if (!pagingStateMap) {
        newPagingState.push({
          page: action.payload.page,
          pagingState: action.payload.pagingState,
        } as PagingStateMapping);
      }
      state.pagingStates = newPagingState;
    },
    tachographFileStatusEmptyState: (state) => {
      tachographFilesStatusAdapter.setAll(state, []);
      state.status = "idle";
      state.reasonCode = "";
      state.pagingStates = [] as PagingStateMapping[];
      state.totalPages = 0;
    },
  },

  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      //#region Entity Reducers
      .addCase(
        getTachographFilesStatusAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.GET;
          setFilteredData(state, action);
        }
      )
      .addCase(getTachographFilesStatusAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(getTachographFilesStatusAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = "";
      });
    //#endregion Entity Reducers
  },
});
//#endregion Slice

//#region Status
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const tachographFilesStatusSelectors =
  tachographFilesStatusAdapter.getSelectors<RootState>(
    (state) => state.tachographFilesStatus
  );

export const selectTachographFilesStatusSliceStatus = (state: any) =>
  state.tachographFilesStatus.status;
export const selectTachographFilesStatusSliceReasonCode = (state: any) =>
  state.tachographFilesStatus.reasonCode;
export const selectTachographFilesStatusSlicePage = (state: any) =>
  state.tachographFilesStatus.totalPages;
export const selectTachographFilesStatusSlicePagingStates = (state: any) =>
  state.tachographFilesStatus.pagingStates;
//#endregion Status
export const {
  setNumberOfPages,
  tachographFileStatusEmptyState,
  setNewPagingState,
} = tachographFileStatusSlice.actions;

export default tachographFileStatusSlice.reducer;
