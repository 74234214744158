import React from 'react'
import PropTypes from 'prop-types'

import './switch.css'

export const Switch = ({ id, label, name, disabled, checked, onChange, className, ...props }) => {
	return (
		<label className={['switch', className].join(' ')} htmlFor={id} {...props}>
			<input type="checkbox" name={name} checked={checked} onChange={onChange} disabled={disabled} />
			<span>{label}</span>
		</label>
	)
}

Switch.propTypes = {
	id: PropTypes.string,
	label: PropTypes.string,
	disabled: PropTypes.bool,
	checked: PropTypes.bool,
	name: PropTypes.string,
	onChange: PropTypes.func
}

Switch.defaultProps = {
	label: 'Checkbox',
	name: '',
	disabled: false,
}