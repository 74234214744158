import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import _ from "lodash";
import { normalize } from "normalizr";
import { RootState } from "../../app/store";
import { Config } from "../../config/Config";
import { GTFleetSuccessCodes } from "../../config/GTFleetSuccessCodes";
import { getErrorCodes } from "../../utils/Utils";
import { getFilteredFleetsAsync } from "../fleet/fleetViewsSlice";
import { Preferences } from "./preference/preferencesSlice";
import { Privileges } from "./privilege/privilegesSlice";
import { userInfoSchema, usersInfoSchema } from "./userInfoNormalization";
import UsersRepository from "./usersRepository";

//#region Type

export type SecurityProfile = {
  id: number;
  name: string;
  privileges?: Privileges[];
};

export type UserInfo = {
  id: number;
  active: boolean;
  username: string;
  firstName: string;
  secondName: string;
  address: string;
  city: string;
  province: string;
  country: string;
  zip: string;
  prefix: string;
  telephone: string;
  email: string;
  tenantEmail: string;
  dateOfBirth: Date;
  fiscalCode: string;
  profilePicture: string;
  platformLogo: string;
  platformWhite: string;
  preferences: number;
  tenant: number;
  lastAccess: Date;
  activationDate: Date;
  expirationDate: Date | null;
  securityProfile: SecurityProfile;
  privileges: Privileges[];
  password: string;
};
//#endregion Type

//#region API
export const getUsersInfoAsync = createAsyncThunk(
  "userInfo/getUsersInfo",
  async (_data, { rejectWithValue }) => {
    try {
      const usersRepository = new UsersRepository();
      const response = await usersRepository.getUsers();
      const usersInfo = _.get(response, Config.USERS_INFO_RESPONSE_PATH);
      if (usersInfo) {
        const normalizedData = normalize(usersInfo, usersInfoSchema);
        return normalizedData.entities;
      } else {
        return [];
      }
    } catch (error: any) {
      if (!error.response) throw error;
      return rejectWithValue(error.response);
    }
  }
);

export const getFilteredUsersInfoPaginationAsync = createAsyncThunk(
  "userInfo/getFilteredUsersInfoPaginationAsync",
  async (data: { queryParams?: string }, { rejectWithValue, dispatch }) => {
    try {
      const usersRepository = new UsersRepository();
      const response = await usersRepository.getFilteredUsers(data.queryParams);
      const usersInfo = _.get(response, Config.USERS_INFO_RESPONSE_PATH);
      const totalPages = _.get(
        response,
        Config.USERS_INFO_TOTAL_PAGES_RESPONSE_PATH
      );
      const totalElements = _.get(
        response,
        Config.USERS_INFO_TOTAL_ELEMENTS_RESPONSE_PATH
      );
      if (totalPages) {
        dispatch(setNumberOfPages(totalPages));
      }
      if (totalElements) {
        dispatch(setNumberOfElements(totalElements));
      }
      if (usersInfo) {
        const normalizedData = normalize(usersInfo, usersInfoSchema);
        return normalizedData.entities;
      } else {
        return [];
      }
    } catch (error: any) {
      if (!error.response) throw error;
      return rejectWithValue(error.response);
    }
  }
);

export const getUserInfoAsync = createAsyncThunk(
  "userInfo/getUserInfo",
  async (data: { id: number }, { rejectWithValue }) => {
    try {
      const usersRepository = new UsersRepository();
      const response = await usersRepository.getUser(data.id);
      const userInfo = _.get(response, Config.USER_INFO_RESPONSE_PATH);
      const normalizedData = normalize(userInfo, userInfoSchema);
      return normalizedData.entities;
    } catch (error: any) {
      if (!error.response) throw error;
      return rejectWithValue(error.response);
    }
  }
);

export const updateUserInfoAsync = createAsyncThunk(
  "userInfo/updateUserInfo",
  async (data: { id: number; userInfo: UserInfo }, { rejectWithValue }) => {
    try {
      const usersRepository = new UsersRepository();
      const response = await usersRepository.partiallyUpdateUser(
        data.id,
        data.userInfo
      );
      const userInfo = _.get(response, Config.USER_INFO_RESPONSE_PATH);
      const normalizedData = normalize(userInfo, userInfoSchema);
      return normalizedData.entities;
    } catch (error: any) {
      if (!error.response) throw error;
      return rejectWithValue(error.response);
    }
  }
);

export const createUserAsync = createAsyncThunk(
  "userInfo/createUser",
  async (
    data: {
      userInfo: UserInfo;
      preferences: Partial<Preferences>;
    },
    { rejectWithValue }
  ) => {
    try {
      const usersRepository = new UsersRepository();
      const response = await usersRepository.createUser(
        data.userInfo,
        data.preferences
      );
      const user = _.get(response, Config.USER_INFO_RESPONSE_PATH);
      const normalizedData = normalize(user, userInfoSchema);
      return normalizedData.entities;
    } catch (err: any) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data.message);
    }
  }
);
//#endregion API

//#region Custom Functions
function setFilteredData(state: any, action: PayloadAction<any>) {
  action.payload.userInfo
    ? usersInfoAdapter.setAll(state, action.payload.userInfo)
    : usersInfoAdapter.setAll(state, []);
}
//#endregiorn Custom Functions

//#region Slice
const usersInfoAdapter = createEntityAdapter<UserInfo>({
  selectId: (userInfo) => userInfo.id,
  sortComparer: (a, b) => a.firstName.localeCompare(b.firstName),
});

export const usersInfoSlice = createSlice({
  name: "usersInfo",
  initialState: usersInfoAdapter.getInitialState({
    status: "idle",
    reasonCode: "",
    totalPages: 0,
    totalElements: 0,
  }),
  reducers: {
    usersInfoEmptyState: (state: any) => {
      usersInfoAdapter.setAll(state, []);
      state.reasonCode = "";
      state.status = "idle";
    },
    setNumberOfPages: (state, action: PayloadAction<number>) => {
      state.totalPages = action.payload;
    },
    setNumberOfElements: (state, action: PayloadAction<number>) => {
      state.totalElements = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      //#region Entity Reducers
      .addCase(getUsersInfoAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(
        getUsersInfoAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          usersInfoAdapter.upsertMany(state, action.payload.userInfo);
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.GET;
        }
      )
      .addCase(
        getUsersInfoAsync.rejected,
        (state: any, action: PayloadAction<any>) => {
          state.status = "failed";
          state.reasonCode = getErrorCodes(action.payload);
        }
      )
      .addCase(getFilteredUsersInfoPaginationAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(
        getFilteredUsersInfoPaginationAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          setFilteredData(state, action);
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.GET;
        }
      )
      .addCase(
        getFilteredUsersInfoPaginationAsync.rejected,
        (state: any, action: PayloadAction<any>) => {
          state.status = "failed";
          state.reasonCode = getErrorCodes(action.payload);
        }
      )
      .addCase(getUserInfoAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(
        getUserInfoAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          usersInfoAdapter.upsertMany(state, action.payload.userInfo);
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.GET;
        }
      )
      .addCase(
        getUserInfoAsync.rejected,
        (state: any, action: PayloadAction<any>) => {
          state.status = "failed";
          state.reasonCode = getErrorCodes(action.payload);
        }
      )
      .addCase(updateUserInfoAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(
        updateUserInfoAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          usersInfoAdapter.upsertMany(state, action.payload.userInfo);
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.PATCH;
        }
      )
      .addCase(
        updateUserInfoAsync.rejected,
        (state: any, action: PayloadAction<any>) => {
          state.status = "failed";
          state.reasonCode = getErrorCodes(action.payload);
        }
      )
      .addCase(
        createUserAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          usersInfoAdapter.upsertMany(state, action.payload.userInfo);
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.POST;
        }
      )
      //TODO check code error
      .addCase(
        createUserAsync.rejected,
        (state: any, action: PayloadAction<any>) => {
          state.status = "failed";
          state.reasonCode = getErrorCodes(action.payload);
        }
      )
      .addCase(createUserAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(
        getFilteredFleetsAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          action.payload.userInfo &&
            usersInfoAdapter.upsertMany(state, action.payload.userInfo);
        }
      );
    //#endregion Entity Reducers
  },
});
//#endregion Slice

//#region Status
export const userInfoSelectors = usersInfoAdapter.getSelectors<RootState>(
  (state) => state.usersInfo
);

export const selectUsersInfoSliceStatus = (state: any) =>
  state.usersInfo.status;
export const selectUsersInfoSliceReasonCode = (state: any) =>
  state.usersInfo.reasonCode;
export const selectUsersInfoSlicePage = (state: any) =>
  state.usersInfo.totalPages;
export const selectUsersInfoSliceTotalElements = (state: any) =>
  state.usersInfo.totalElements;
export const { usersInfoEmptyState, setNumberOfPages, setNumberOfElements } =
  usersInfoSlice.actions;
//#endregion Status

export default usersInfoSlice.reducer;
