import { t } from "i18next";
import { FeedbackModal } from "../../../../ui/Modal/CustomModals/FeedbackModal";
import { NotificationRuleAggregation } from "../notificationRuleAggregationSlice";

interface DeleteNotificationRuleAggregationModalProps {
  openDeleteNotificationRuleAggregationModal: boolean;
  selectedNotificationRuleAggregation: NotificationRuleAggregation;
  closeDeleteNotificationRuleAggregationModal: (e: any) => any;
}

const DeleteNotificationRuleAggregationModal: React.FC<DeleteNotificationRuleAggregationModalProps> =
  ({
    openDeleteNotificationRuleAggregationModal,
    selectedNotificationRuleAggregation,
    closeDeleteNotificationRuleAggregationModal,
  }) => {
    return (
      <FeedbackModal
        open={openDeleteNotificationRuleAggregationModal}
        title={`${t("admin.notifications.actions.deleteRule.title")}`}
        desc={
          `${t("admin.notifications.actions.deleteRule.desc").replace(
            "*name",
            selectedNotificationRuleAggregation?.name
              ? selectedNotificationRuleAggregation?.name?.split(" ")[0]
              : t("common.na")
          )}` +
          " " +
          `${t("admin.notifications.actions.deleteRule.actionPermanent")}`
        }
        primaryLabel={`${t("common.confirm")}`}
        isDanger={true}
        primaryAction={() => {
          closeDeleteNotificationRuleAggregationModal(
            selectedNotificationRuleAggregation?.id
          );
        }}
        secondaryLabel={`${t("common.cancel")}`}
        secondaryAction={closeDeleteNotificationRuleAggregationModal}
        closeAction={closeDeleteNotificationRuleAggregationModal}
      />
    );
  };
export default DeleteNotificationRuleAggregationModal;
