import {
  PayloadAction,
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";

import _ from "lodash";
import { normalize } from "normalizr";
import { RootState } from "../../../app/store";
import { Config } from "../../../config/Config";
import { getUserInfoAsync, getUsersInfoAsync } from "../usersInfoSlice";
import { preferencesSchema } from "./preferencesNormalization";
import PreferencesRepository from "./preferencesRepository";

//#region Type
export type Preferences = {
  id: number;
  localeFormat: string;
  language: string;
  timeZone: string;
  displayLanguage: string;
  isMetric: boolean;
  listOnFleetCont: boolean;
  clusterOnMap: boolean;
  vehIdOnMap: boolean;
  tooltipOnMap: boolean;
  trafficInfoOnMap: boolean;
  satelliteOnMap: boolean;
  currency: PreferencesCurrencyType;
};

export const preferencesCurrencyValues = {
  EURO: "EURO",
  DOLLAR: "DOLLAR",
  POUND_STERLING: "POUND_STERLING",
};
export type PreferencesCurrencyType = keyof typeof preferencesCurrencyValues;
//#endregion Type

//#region API
export const getPreferencesAsync = createAsyncThunk(
  "preferences/getPreferences",
  async () => {
    const preferencesRepository = new PreferencesRepository();
    const response = await preferencesRepository.getPreferences();
    const preferences = _.get(response, Config.PREFERENCES_RESPONSE_PATH);
    const normalizedData = normalize(preferences, preferencesSchema);
    return normalizedData.entities;
  }
);

export const updatePreferencesAsync = createAsyncThunk(
  "preferences/updatePreferences",
  async (data: { id: number; preferences: Preferences }) => {
    const preferencesRepository = new PreferencesRepository();
    const response = await preferencesRepository.partiallyUpdatePreferences(
      data.id,
      data.preferences
    );
    const preferences = _.get(response, Config.PREFERENCES_RESPONSE_PATH);
    const normalizedData = normalize(preferences, preferencesSchema);
    return normalizedData.entities;
  }
);
//#endregion API

//#region Slice
const preferencesAdapter = createEntityAdapter<Preferences>({
  selectId: (preferences) => preferences.id,
});

export const preferencesSlice = createSlice({
  name: "preferences",
  initialState: preferencesAdapter.getInitialState({
    status: "idle",
    reasonCode: "",
  }),
  reducers: {
    preferencesEmptyState: (state) => {
      preferencesAdapter.setAll(state, []);
      state.reasonCode = "";
      state.status = "idle";
    },
  },
  extraReducers: (builder) => {
    builder
      //#region Entity Reducers
      .addCase(getPreferencesAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(
        getPreferencesAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          preferencesAdapter.upsertMany(state, action.payload.preferences);
          state.status = "idle";
        }
      )
      .addCase(getPreferencesAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = "";
      })
      .addCase(updatePreferencesAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(
        updatePreferencesAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          preferencesAdapter.upsertMany(state, action.payload.preferences);
          state.status = "idle";
        }
      )
      .addCase(updatePreferencesAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = "";
      })
      //#endregion Entity Reducers
      //#region External Entity Reducers
      .addCase(
        getUsersInfoAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          action.payload.preferences &&
            preferencesAdapter.upsertMany(state, action.payload.preferences);
        }
      )
      .addCase(
        getUserInfoAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          action.payload.preferences &&
            preferencesAdapter.upsertMany(state, action.payload.preferences);
        }
      );
    //#endregion External Entity Reducers
  },
});
//#endregion Slice

//#region Status
export const preferencesSelectors = preferencesAdapter.getSelectors<RootState>(
  (state) => state.preferences
);

export const selectPreferencesSliceStatus = (state: any) =>
  state.preferences.status;
export const selectPreferencesSliceReasonCode = (state: any) =>
  state.preferences.reasonCode;

export const { preferencesEmptyState } = preferencesSlice.actions;
//#endregion Status

export default preferencesSlice.reducer;
