import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import _ from "lodash";
import { RootState } from "../../../app/store";
import { Config } from "../../../config/Config";
import { GTFleetSuccessCodes } from "../../../config/GTFleetSuccessCodes";
import { default as RefuellingReportRepository } from "./refuellingReportRepository";

//#region Type
export type RefuellingReports = {
  id: number;
  driverId: number;
  date: string;
  initialLevel: number;
  refueledLevel: number;
  postRefuelLevel: number;
  odometer: number;
  refuelAddress: string;
  price: number;
  totalPrice: number;
  routeId: number;
  vehicleId: number;
};
//#endregion Type

//#region API
// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const getRefuellingReportAsync = createAsyncThunk(
  "refuellingReport/getRefuellingReport",
  async (data: { vehicleId: number; queryParams: string }, thunkAPI) => {
    const refuellingReportRepository = new RefuellingReportRepository();
    const response = await refuellingReportRepository.getRefuellingReport(
      data.vehicleId,
      data.queryParams
    );
    const totalPages = _.get(
      response,
      Config.REFUELLING_REPORT_TOTAL_PAGES_RESPONSE_PATH
    );
    const totalElements = _.get(
      response,
      Config.REFUELLING_REPORT_TOTAL_ELEMENTS_RESPONSE_PATH
    );
    if (totalPages) {
      thunkAPI.dispatch(setNumberOfPages(totalPages));
    }
    if (totalElements) {
      thunkAPI.dispatch(setNumberOfElements(totalElements));
    }
    // The value we return becomes the `fulfilled` action payload
    const reports = _.get(response, Config.REFUELLING_REPORT_PAGINATED_PATH);
    return reports;
  }
);
//#endregion API

//#region Slice
const refuellingReportAdapter = createEntityAdapter<RefuellingReports>({
  selectId: (report) => report.id,
  sortComparer: (a, b) => a.id - b.id,
});

export const refuellingReportSlice = createSlice({
  name: "refuellingReportSlice",
  initialState: refuellingReportAdapter.getInitialState({
    status: "idle",
    reasonCode: "",
    totalPages: 0,
    totalElements: 0,
  }),
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    refuellingReportEmptyState: (state) => {
      refuellingReportAdapter.setAll(state, []);
      state.reasonCode = "";
      state.status = "idle";
    },
    setNumberOfPages: (state, action: PayloadAction<number>) => {
      state.totalPages = action.payload;
    },
    setNumberOfElements: (state, action: PayloadAction<number>) => {
      state.totalElements = action.payload;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      //#region Entity Reducers
      // getRoutesHistoryAsync extra reducers
      .addCase(
        getRefuellingReportAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          refuellingReportAdapter.setAll(state, action.payload);
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.GET;
        }
      )
      .addCase(getRefuellingReportAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(getRefuellingReportAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = "";
      });
    //#endregion Entity Reducers
  },
});
//#endregion Slice

//#region Status
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const refuellingReportSelectors =
  refuellingReportAdapter.getSelectors<RootState>(
    (state) => state.refuelReport
  );

export const selectRefuellingReportSliceStatus = (state: any) =>
  state.refuelReport.status;
export const selectRefuellingReportSliceReasonCode = (state: any) =>
  state.refuelReport.reasonCode;
export const selectRefuellingReportSlicePage = (state: any) =>
  state.refuelReport.totalPages;
export const selectRefuellingReportSliceTotalElements = (state: any) =>
  state.refuelReport.totalElements;
//#endregion Status
export const {
  refuellingReportEmptyState,
  setNumberOfPages,
  setNumberOfElements,
} = refuellingReportSlice.actions;

export default refuellingReportSlice.reducer;
