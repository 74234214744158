import dayjs from "dayjs";
import { t } from "i18next";
import { NavigateFunction } from "react-router-dom";
import { IconExternalLink } from "../../ui/Icon/Line/ExternalLink";
import { LabelText } from "../../ui/LabelText/LabelText";
import { Table } from "../../ui/Table/Table";
import {
  AbstractTableBuilder,
  Row,
} from "../../ui/Table/utils/AbstractTableBuilder";
import "../../ui/Table/utils/TableBuilder.css";
import { Tag } from "../../ui/Tags/Tag";
import { ConvertTimeZone } from "../../utils/DateAndTimeUtils";
import { getAddress, getCity } from "../../utils/Utils";
import { Driver } from "../driver/driversSlice";
import { DriverStatus } from "../driver/driversStatusSlice";
import { FleetView } from "../fleet/fleetViewsSlice";
import { Preferences } from "../users/preference/preferencesSlice";
import { Vehicle } from "../vehicle/vehiclesSlice";
import { VehicleStatus } from "../vehicle/vehiclesStatusSlice";

export class DashboardDriversTableBuilder extends AbstractTableBuilder {
  /**
   * Given data, it builds a table row
   * @param vehicle
   * @returns
   */
  rowsBuilder = (
    columns: { label: string; field: string; sort: boolean }[] | undefined,
    context: string,
    entities: {
      vehicle?: Vehicle;
      vehicleStatus?: VehicleStatus;
      driver?: Driver;
      driverStatus?: DriverStatus;
      fleetView?: FleetView;
    },
    preferencesContext: Preferences,
    navigate: NavigateFunction,
    writePermission: boolean = false
  ) => {
    let row: Row = { render: {} as Row };

    let vehicle: Vehicle = entities.vehicle ?? ({} as Vehicle);

    let vehicleStatus: VehicleStatus =
      entities.vehicleStatus ?? ({} as VehicleStatus);

    let driver: Driver = entities.driver ?? ({} as Driver);

    let driverStatus: DriverStatus =
      entities.driverStatus ?? ({} as DriverStatus);

    let fleetView: FleetView = entities.fleetView ?? ({} as FleetView);

    columns &&
      columns.forEach((column) => {
        switch (column.field) {
          case "driver":
            row.driver = driver.id ?? t("common.na");
            row.render.driver = (
              <LabelText
                label={driver.id ? driver.id + "" : t("common.na")}
                text={
                  driver.firstName
                    ? driver.firstName + " " + driver.lastName
                    : t("common.na")
                }
              />
            );
            break;
          case "license":
            row.render.license = vehicle.plate ?? t("common.na");
            break;
          case "vehicleId":
            row.vehicleId = vehicle.id ?? t("common.na");
            row.render.vehicleId = vehicle.id ?? t("common.na");
            break;
          case "group":
            row.group = fleetView.name ?? t("common.na");
            row.render.group = fleetView.name ?? t("common.na");
            break;
          case "driverStatus":
            row.render.driverStatus = (
              <Table.TextStatus
                text={t(`table.status.${driver.status}`) ?? t("common.na")}
              />
            );
            break;
          case "driverStatusActivity":
            let driverStatusLabel = driverStatus.driverStatus;
            row.render.driverStatus = (
              <Tag
                text={driverStatusLabel}
                size="small"
                mode={
                  `${driverStatusLabel}` === t("common.na") ? "outline" : "fill"
                }
                type={
                  `${driverStatusLabel}` === "ONLINE" ? "success" : "neutro"
                }
                hasIcon={false}
              />
            );
            break;
          case "currentLocation":
            if (context === "driverDashboard") {
              if (driverStatus.dynamicFields?.currentPosition) {
                row.render.currentLocation = (
                  <>
                    <p className="reportMarked">
                      {getCity(driverStatus.dynamicFields?.currentPosition)}
                    </p>
                    <p className="reportNormal">
                      {getAddress(driverStatus.dynamicFields?.currentPosition)}
                    </p>
                  </>
                );
              } else {
                row.render.currentLocation = t("common.na");
              }
              break;
            } else {
              if (vehicleStatus.dynamicFields?.address) {
                row.render.currentLocation = (
                  <>
                    <p className="reportMarked">
                      {getCity(vehicleStatus.dynamicFields?.address)}
                    </p>
                    <p className="reportNormal">
                      {getAddress(vehicleStatus.dynamicFields?.address)}
                    </p>
                  </>
                );
              } else {
                row.render.currentLocation = t("common.na");
              }
            }
            break;
          case "drive":
            row.render.drive =
              driverStatus?.dynamicFields?.drivingSince &&
              driverStatus?.dynamicFields?.drivingSince !== undefined
                ? dayjs
                    .duration(driverStatus?.dynamicFields?.drivingSince)
                    .format("HH:mm")
                : "--:--";
            break;
          case "totalToday":
            row.render.totalToday =
              driverStatus?.dynamicFields?.totalToday !== undefined
                ? dayjs
                    .duration(driverStatus?.dynamicFields?.totalToday)
                    .format("HH:mm")
                : "--:--";
            break;
          case "kmTraveled":
            row.render.kmTraveled =
              driverStatus?.dynamicFields?.kmTraveled !== undefined
                ? Number(
                    (driverStatus?.dynamicFields?.kmTraveled / 1000).toFixed(1)
                  )
                : t("common.na");
            break;
          case "lastActivity":
            row.render.lastActivity = driverStatus?.dynamicFields?.lastUpdate
              ? ConvertTimeZone(
                  driverStatus.dynamicFields?.lastUpdate,
                  preferencesContext.timeZone ?? undefined,
                  preferencesContext.localeFormat ?? undefined
                )
              : t("common.na");
            break;
          case "available":
            row.render.available =
              driverStatus?.dynamicFields?.available !== undefined
                ? dayjs
                    .duration(driverStatus?.dynamicFields?.available)
                    .format("HH:mm")
                : "--:--";
            break;
          default:
            break;
        }
      });

    row.render.action = (
      <IconExternalLink
        className=""
        size={14}
        color="--global-colors-ink-ink"
        onClick={() => {
          window
            .open(`/dashboard/drivers/details/${driver.id}`, "_blank")
            ?.focus();
        }}
      />
    );
    return row;
  };
}
