import {
  PayloadAction,
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import _ from "lodash";
import { normalize } from "normalizr";
import { RootState } from "../../../app/store";
import { Config } from "../../../config/Config";
import { DriverView } from "../../driver/driversSlice";
import ReportsRepository from "../reportRepository";
import { reportUtilizationAndSummarySchema } from "../reportsNormalization";

type Event = {
  latitude: number;
  longitude: number;
  address: string;
  gpsPositionTimestamp: Date;
};

export type ReportUtilization = {
  id: number;
  routeId: number;
  vehiclePlate: string;
  vehicleAlias: string;
  driverStart: DriverView;
  driverEnd: DriverView;
  keyOnPositionTimestamp: Event;
  keyOffPositionTimestamp: Event;
  workingTime: string;
};

export type ReportsUtilizationSummary = {
  id: number;
  startDate: Date;
  endDate: Date;
  totalWorkingTime: string;
};

export type ReportUtilizationAndSummary = {
  summary: ReportsUtilizationSummary;
  reportsUtilization: ReportUtilization[];
};

export const getReportsUtilizationAsync = createAsyncThunk(
  "reportsUtilization/getReportsUtilization",
  async (queryParams: string, thunkAPI) => {
    const reportsRepository = new ReportsRepository();
    const response = await reportsRepository.getReportsUtilization(queryParams);
    const reports = _.get(response, Config.REPORTS_UTILIZATION_RESPONSE_PATH);
    const normalizedData = normalize(
      reports,
      reportUtilizationAndSummarySchema
    );
    return normalizedData.entities;
  }
);

function setFilteredData(state: any, action: PayloadAction<any>) {
  action
    ? reportsUtilizationAdapter.setAll(state, action)
    : reportsUtilizationAdapter.setAll(state, []);
}

const reportsUtilizationAdapter = createEntityAdapter<ReportUtilization>({
  selectId: (report) => report.id,
  sortComparer: (a, b) => a.id - b.id,
});

export const reportsUtilizationSlice = createSlice({
  name: "reportsUtilizationSlice",
  initialState: reportsUtilizationAdapter.getInitialState({
    status: "idle",
    reasonCode: "",
  }),
  reducers: {
    reportsUtilizationEmptyState: (state) => {
      reportsUtilizationAdapter.setAll(state, []);
      state.reasonCode = "";
      state.status = "";
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(
        getReportsUtilizationAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          setFilteredData(state, action.payload.reportsUtilization);
          state.status = "idle";
        }
      )
      .addCase(getReportsUtilizationAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(getReportsUtilizationAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = "";
      });
  },
});

export const reportsUtilizationSelectors =
  reportsUtilizationAdapter.getSelectors<RootState>(
    (state) => state.reportsUtilization
  );

export const selectReportsUtilizationSliceStatus = (state: any) =>
  state.reportsUtilization.status;
export const selectReportsUtilizationSliceReasonCode = (state: any) =>
  state.reportsUtilization.reasonCode;
//#endregion Status
export const { reportsUtilizationEmptyState } = reportsUtilizationSlice.actions;

export default reportsUtilizationSlice.reducer;
