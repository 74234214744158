import HttpClient from "../../utils/HttpClient";

export default class GPSDataRepository extends HttpClient {
  constructor() {
    super(true);
  }

  getGPSDataTravel(routeId: number) {
    const tenantId = localStorage.getItem("tenantId");
    return this.instance.get(
      "tenants/" + tenantId + "/route/" + routeId + "/gpsData"
    );
  }

  getGPSDataReports(queryParams?: string, body?: { pagingState?: string }) {
    const tenantId = localStorage.getItem("tenantId");
    return this.instance.post(
      "tenants/" + tenantId + "/reports/gpsData" + (queryParams ?? ""),
      body
    );
  }

  getGPSDataReportsSummary(queryParams?: string) {
    const tenantId = localStorage.getItem("tenantId");
    return this.instance.get(
      "tenants/" + tenantId + "/reports/gpsData/summary" + (queryParams ?? "")
    );
  }
}
