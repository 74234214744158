import React, { useState } from "react";
import { NumericFormat } from "react-number-format";

const TestTSLuigi = () => {
  function addZeroes() {
    if (value === "") {
      setValue("");
    } else if (!value.includes(",")) {
      let val = value;
      val += ",00";
      setValue(val);
    } else {
      let alternativeVal = value.substring(value.indexOf(",") + 1);
      if (alternativeVal.length === 1) {
        setValue(value + "0");
      }
    }
  }

  const [value, setValue] = useState<string>("");

  return (
    <div className="example">
      <h3>
        Demo for <code>NumericFormat - Import with decimal</code>
      </h3>

      <div>
        <NumericFormat
          placeholder="€ 0,00"
          value={value}
          prefix="€ "
          thousandSeparator="."
          decimalSeparator=","
          maxLength={8}
          decimalScale={2}
          valueIsNumericString={true}
          onValueChange={(values) => {
            setValue(values.value);
          }}
          onBlur={() => {
            addZeroes();
          }}
        />
      </div>
    </div>
  );
};

export default TestTSLuigi;
