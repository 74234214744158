import PropTypes from "prop-types";
import "../IconFont/icons.css";

export const IconTimer = ({ size, withBackground = false, ...props }) => (
  <div
    className="svg"
    onClick={props.onClick}
    style={
      withBackground
        ? {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "50%",
            backgroundColor: `var(${props.backgroundColor})`,
            height: size,
            width: size,
          }
        : undefined
    }
  >
    <span
      style={
        props.color
          ? {
              fontSize: `${withBackground ? size / 2 + 1 : size}px`,
              color: `var(${props.color})`,
            }
          : { fontSize: `${withBackground ? size / 2 + 1 : size}px` }
      }
    >
      &#xe87f;
    </span>
  </div>
);

IconTimer.propTypes = {
  /**
   * Size of the icon.
   */
  size: PropTypes.number,
  /**
   * Color of the icon.
   */
  color: PropTypes.string,
};

IconTimer.defaultProps = {
  color: "--global-colors-ui-primary",
  size: 24,
};
