import { t } from "i18next";
import { useEffect, useRef, useState } from "react";
import { useMatch, useNavigate, useSearchParams } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { store } from "../../app/store";
import { GTFleetSuccessCodes } from "../../config/GTFleetSuccessCodes";
import { GTFleetErrorCodes } from "../../config/GTfleetErrorCodes";
import PageContent from "../../layout/PageContent";
import PageFilters from "../../layout/PageFilters";
import { Button } from "../../ui/Button/Button";
import { IconAdd } from "../../ui/Icon/Line/Add";
import { IconGeofence } from "../../ui/Icon/Line/Geofence";
import { IconTitle } from "../../ui/IconTitle/IconTitle";
import { NewCategoryModal } from "../../ui/Modal/CustomModals/NewCategoryModal";
import { PageCounter } from "../../ui/Table/PageCounter";
import { Table } from "../../ui/Table/Table";
import { ToastNotification } from "../../utils/ToastNotification";
import { getQueryString } from "../../utils/Utils";
import {
  GeofenceCategory,
  createGeofenceCategoryAsync,
  deleteGeofenceCategoryAsync,
  geofenceCategoriesEmptyState,
  geofenceCategoriesSelectors,
  getGeofenceCategoriesAsync,
  selectGeofenceCategoriesSliceReasonCode,
  selectGeofenceCategoriesSliceStatus,
} from "../geofenceCategory/geofenceCategoriesSlice";
import { UserPermissions } from "../users/privilege/privilegesSlice";
import ActivateGeofenceModal from "./ActivateGeofenceModal";
import DeactivateGeofenceModal from "./DeactivateGeofenceModal";
import "./Geofence.css";
import { GeofenceFilterBar } from "./GeofenceFilterBar";
import { GeofenceTableBuilder } from "./GeofenceTableBuilder";
import {
  Geofence,
  activateGeofenceAsync,
  deactivateGeofenceAsync,
  deleteGeofenceAsync,
  geofencesEmptyState,
  geofencesSelectors,
  getFilteredGeofencesAsync,
  selectGeofencesSlicePage,
  selectGeofencesSliceReasonCode,
  selectGeofencesSliceStatus,
  selectGeofencesSliceTotalElement,
} from "./geofenceSlice";
import DeleteGeofenceCategoryModal from "./modals/DeleteGeofenceCategoryModal";
import DeleteGeofenceModal from "./modals/DeleteGeofenceModal";
import { EditCategoriesModal } from "./modals/EditCategoriesModal";
import { EditCategoryModal } from "./modals/EditCategoryModal";

interface GeofencesProps {
  permissions: UserPermissions;
}

interface TableData {
  columns: { label: string; field: string; sort: boolean }[] | undefined;
  rows: { [key: string]: any };
}

const tableData: TableData = {} as TableData;

const tableSchemaFix = [
  { name: "geofence", sort: true },
  { name: "category" },
  { name: "geofenceStatus" },
];

const Geofences: React.FC<GeofencesProps> = ({ permissions }) => {
  //#region Initial variables
  const navigate = useNavigate();
  const tableBuilderRef = useRef<GeofenceTableBuilder>(
    new GeofenceTableBuilder()
  );
  let tableBuilder = tableBuilderRef.current;
  const [queryParamsFromFilterBar, setQueryParamsFromFilterBar] =
    useState<string>("");
  const [selectedGeofenceId, setSelectedGeofenceId] = useState<number>(-1);
  const [searchParams] = useSearchParams();

  const noQueryParamsFromFilterBar =
    (searchParams.toString().split("&").length === 1 &&
      !!searchParams.get("size")) ||
    searchParams.toString() === "";
  const [resetPage, setResetPage] = useState<boolean>(false);
  const [selectedGeofenceCategoryId, setSelectedGeofenceCategoryId] =
    useState<number>(-1);
  const [queryParamsChanged, setQueryParamsChanged] = useState<boolean>(false);
  //#endregion Initial variables

  //#region Object
  const geofences: Geofence[] = useAppSelector(geofencesSelectors.selectAll);
  const selectedGeofence: Geofence =
    geofencesSelectors.selectById(store.getState(), selectedGeofenceId) ??
    ({} as Geofence);
  const selectedGeofenceCategory: GeofenceCategory =
    geofenceCategoriesSelectors.selectById(
      store.getState(),
      selectedGeofenceCategoryId
    ) ?? ({} as GeofenceCategory);
  //#endregion Object

  //#region Modal
  const [openAddCategoryModal, setOpenAddCategoryModal] = useState(false);
  const [openEditCategoriesModal, setOpenEditCategoriesModal] = useState(false);
  const [openEditCategoryModal, setOpenEditCategoryModal] = useState(false);
  const [openActivateGeofenceModal, setOpenActivateGeofenceModal] =
    useState(false);
  const [openDeactivateGeofenceModal, setOpenDeactivateGeofenceModal] =
    useState(false);
  const [openDeleteGeofenceModal, setOpenDeleteGeofenceModal] = useState(false);
  const [openDeleteGeofenceCategoryModal, setOpenDeleteGeofenceCategoryModal] =
    useState(false);
  //#endregion Modal

  //#region Route
  const geofencesRoute = useMatch("/admin/geofences/:action");
  const geofenceRoute = useMatch("/admin/geofences/:action/:geofenceId");

  useEffect(() => {
    if (geofencesRoute !== null) {
      switch (geofencesRoute.params.action) {
        case "add-category":
          setOpenAddCategoryModal(true);
          break;
        case "edit-categories":
          setOpenEditCategoriesModal(true);
          break;
        default:
          break;
      }
    }
  }, [navigate, geofencesRoute]);

  useEffect(() => {
    if (geofenceRoute !== null) {
      if (geofenceRoute.params.geofenceId) {
        setSelectedGeofenceId(Number(geofenceRoute.params.geofenceId));
        setSelectedGeofenceCategoryId(Number(geofenceRoute.params.geofenceId));
        switch (geofenceRoute.params.action) {
          case "edit-category":
            setOpenEditCategoryModal(true);
            setOpenEditCategoriesModal(false);
            break;
          case "delete-category":
            setOpenDeleteGeofenceCategoryModal(true);
            setOpenEditCategoriesModal(false);
            break;
          case "activate":
            setOpenActivateGeofenceModal(true);
            break;
          case "deactivate":
            setOpenDeactivateGeofenceModal(true);
            break;
          case "delete":
            setOpenDeleteGeofenceModal(true);
            break;
          default:
            break;
        }
      }
    }
  }, [navigate, geofenceRoute]);
  //#endregion Route

  //#region Status
  const geofencesSliceStatus = useAppSelector(selectGeofencesSliceStatus);
  const geofencesSliceReasonCode = useAppSelector(
    selectGeofencesSliceReasonCode
  );
  const geofenceCategoriesSliceStatus = useAppSelector(
    selectGeofenceCategoriesSliceStatus
  );
  const geofenceCategoriesSliceReasonCode = useAppSelector(
    selectGeofenceCategoriesSliceReasonCode
  );
  const geofencesSlicePages = useAppSelector(selectGeofencesSlicePage);

  const geofencesSliceTotalElements = useAppSelector(
    selectGeofencesSliceTotalElement
  );
  const isGeofencesIdle = geofencesSliceStatus === "idle";
  const inLoading =
    geofencesSliceStatus === "loading" ||
    geofenceCategoriesSliceStatus === "loading";

  useEffect(() => {
    if (geofencesSliceStatus === "idle") {
      switch (geofencesSliceReasonCode) {
        case GTFleetSuccessCodes.DELETE:
          ToastNotification({
            toastId: "geofenceDeleteSuccess",
            status: "success",
            description: t("common.geofenceDeleteSuccess"),
          });
          break;
        case GTFleetSuccessCodes.PATCH:
          if (geofenceRoute?.params?.action === "activate") {
            ToastNotification({
              toastId: "geofenceActivateSuccess",
              status: "success",
              description: t("admin.geofences.activateGeofence.success"),
            });
          } else if (geofenceRoute?.params?.action === "deactivate") {
            ToastNotification({
              toastId: "geofenceDeactivateSuccess",
              status: "success",
              description: t("admin.geofences.deactivateGeofence.success"),
            });
          }
          break;
      }
    }
    if (geofencesSliceStatus === "failed") {
      switch (geofencesSliceReasonCode) {
        case GTFleetErrorCodes.GEOFENCE_TO_BE_DELETED_NOT_FOUND:
          ToastNotification({
            toastId: "geofenceDeleteError",
            status: "error",
            description: t("admin.geofences.deleteGeofence.success"),
          });
          break;
        case GTFleetErrorCodes.GEOFENCE_TO_BE_ACTIVATED_NOT_FOUND:
          ToastNotification({
            toastId: "geofenceActivateError",
            status: "error",
            description: t("admin.geofences.activateGeofence.error"),
          });
          break;
        case GTFleetErrorCodes.GEOFENCE_TO_BE_DEACTIVATED_NOT_FOUND:
          ToastNotification({
            toastId: "geofenceDeactivateError",
            status: "error",
            description: t("admin.geofences.deactivateGeofence.error"),
          });
          break;
        case "":
          ToastNotification({
            toastId: "networkError",
            status: "error",
            description: t("common.networkError"),
          });
          break;
      }
    }
  }, [geofencesSliceStatus, geofencesSliceReasonCode]);

  useEffect(() => {
    if (geofenceCategoriesSliceStatus === "idle") {
      switch (geofenceCategoriesSliceReasonCode) {
        case GTFleetSuccessCodes.POST:
          ToastNotification({
            toastId: "geofenceCategorySuccess",
            status: "success",
            description: t("common.newCategorySuccess"),
          });
          break;
      }
    }
    if (geofenceCategoriesSliceStatus === "failed") {
      switch (geofenceCategoriesSliceReasonCode) {
        case GTFleetErrorCodes.GEOFENCE_CATEGORIES_NAME_ALREADY_EXISTS:
          ToastNotification({
            toastId: "geofenceCategoriesNameExists",
            status: "error",
            description: t(
              "admin.geofences.editViewCreate.geofenceCategoriesNameExists"
            ),
          });
          break;
        case "":
          ToastNotification({
            toastId: "networkError",
            status: "error",
            description: t("common.networkError"),
          });
          break;
      }
    }
  }, [geofenceCategoriesSliceStatus, geofenceCategoriesSliceReasonCode]);

  useEffect(() => {
    if (!!queryParamsFromFilterBar) {
      setQueryParamsChanged(true);
    }
  }, [queryParamsFromFilterBar]);

  useEffect(() => {
    if (queryParamsChanged && isGeofencesIdle) {
      setQueryParamsChanged(false);
      setResetPage(true);
    } else {
      setResetPage(false);
    }
  }, [isGeofencesIdle, queryParamsChanged]);
  //#endregion Status

  useEffect(() => {
    document.title = t("navigation.adminMenu.geofences") + " - Admin";
    /**
     * When the component is unmountend the related redux state should be cleaned.
     */
    store.dispatch(getGeofenceCategoriesAsync());
    return function cleanUp() {
      store.dispatch(geofencesEmptyState());
      store.dispatch(geofenceCategoriesEmptyState());
    };
  }, []);

  //#region Table
  tableData.columns = tableBuilder.setColumns(tableSchemaFix);

  if (
    geofencesSliceStatus === "idle" &&
    tableData.columns &&
    tableData?.columns?.length > 0 &&
    geofences.length
  ) {
    tableData.rows = geofences.map((getGeofences: Geofence) => {
      let geofence: Geofence =
        geofencesSelectors.selectById(
          store.getState(),
          getGeofences.id ?? -1
        ) ?? ({} as Geofence);
      let geofenceCategory: GeofenceCategory =
        geofenceCategoriesSelectors.selectById(
          store.getState(),
          geofence.geofenceCategory ?? -1
        ) ?? ({} as GeofenceCategory);
      return tableBuilder.rowsBuilder(
        tableData.columns,
        {
          geofence,
          geofenceCategory,
        },
        navigate,
        permissions.geofences.write
      );
    });
  } else if (!geofences.length) {
    tableData.rows = [];
  }
  //#endregion Table

  //#region Function
  function closeNewCategoryModal(object?: unknown) {
    if (object !== undefined) {
      let geofenceCategory: GeofenceCategory = object as GeofenceCategory;
      store.dispatch(createGeofenceCategoryAsync({ geofenceCategory }));
    }
    setOpenAddCategoryModal(false);
    navigate({
      pathname: "/admin/geofences",
      search: queryParamsFromFilterBar,
    });
  }

  function closeEditCategoriesModal() {
    setOpenEditCategoriesModal(false);
    navigate({
      pathname: "/admin/geofences",
      search: queryParamsFromFilterBar,
    });
  }

  function closeEditCategoryModal() {
    setOpenEditCategoryModal(false);
    navigate({
      pathname: "/admin/geofences/edit-categories",
      search: queryParamsFromFilterBar,
    });
  }

  function closeActivateGeofenceModal(id?: number) {
    if (id) {
      store.dispatch(activateGeofenceAsync(id));
    }
    setOpenActivateGeofenceModal(false);
    navigate({
      pathname: "/admin/geofences",
      search: queryParamsFromFilterBar,
    });
  }

  function closeDeactivateGeofenceModal(id?: number) {
    if (id) {
      store.dispatch(deactivateGeofenceAsync(id));
    }
    setOpenDeactivateGeofenceModal(false);
    navigate({
      pathname: "/admin/geofences",
      search: queryParamsFromFilterBar,
    });
  }

  function closeDeleteGeofenceModal(id?: number) {
    if (id) {
      store.dispatch(deleteGeofenceAsync(id));
    }
    setOpenDeleteGeofenceModal(false);
    navigate({
      pathname: "/admin/geofences",
      search: queryParamsFromFilterBar,
    });
  }

  function closeDeleteGeofenceCategoryModal(id?: number) {
    if (id) {
      store.dispatch(deleteGeofenceCategoryAsync(id));
    }
    setOpenDeleteGeofenceCategoryModal(false);
    navigate({
      pathname: "/admin/geofences/edit-categories",
      search: queryParamsFromFilterBar,
    });
  }
  //#endregion Function

  const [isLoading, setIsLoading] = useState(true);

  //#region Render
  return (
    <>
      <PageFilters>
        <div className="col col-16">
          <GeofenceFilterBar
            callback={(queryString: string) => {
              setQueryParamsFromFilterBar(queryString);
              isLoading && setIsLoading(false);
            }}
          />
        </div>
      </PageFilters>
      {openAddCategoryModal ? (
        <NewCategoryModal
          type={"geofence"}
          open={openAddCategoryModal}
          primaryAction={closeNewCategoryModal}
          onClose={closeNewCategoryModal}
        />
      ) : null}
      {openEditCategoriesModal ? (
        <EditCategoriesModal
          open={openEditCategoriesModal}
          onClose={closeEditCategoriesModal}
        />
      ) : null}
      {openEditCategoryModal ? (
        <EditCategoryModal
          defaultValues={selectedGeofenceCategory}
          open={openEditCategoryModal}
          onClose={closeEditCategoryModal}
        />
      ) : null}
      {openActivateGeofenceModal ? (
        <ActivateGeofenceModal
          open={openActivateGeofenceModal}
          geofence={selectedGeofence}
          onClose={closeActivateGeofenceModal}
        />
      ) : null}
      {openDeactivateGeofenceModal ? (
        <DeactivateGeofenceModal
          open={openDeactivateGeofenceModal}
          geofence={selectedGeofence}
          onClose={closeDeactivateGeofenceModal}
        />
      ) : null}
      {openDeleteGeofenceModal ? (
        <DeleteGeofenceModal
          open={openDeleteGeofenceModal}
          geofence={selectedGeofence}
          onClose={closeDeleteGeofenceModal}
        />
      ) : null}
      {openDeleteGeofenceCategoryModal ? (
        <DeleteGeofenceCategoryModal
          geofenceCategory={selectedGeofenceCategory}
          open={openDeleteGeofenceCategoryModal}
          onClose={closeDeleteGeofenceCategoryModal}
        />
      ) : null}
      {tableData?.rows?.length > 0 && (
        <PageContent>
          <div className="geofence-table">
            <Table data={tableData}>
              <Table.Head hasTableSpace={true} />
              <Table.Body />
            </Table>
          </div>
          <div className="geofence-pagination">
            <PageCounter
              isActionPerforming={inLoading}
              resetPage={resetPage}
              disabled={!isGeofencesIdle}
              numOfPages={geofencesSlicePages}
              totalElements={geofencesSliceTotalElements}
              onClick={(id, currentPage) => {
                if (id !== currentPage) {
                  const pageAndSize = getQueryString({
                    page: id - 1,
                    size: "10",
                  });
                  const finalQueryParams = queryParamsFromFilterBar
                    ? queryParamsFromFilterBar + pageAndSize.replace("?", "&")
                    : pageAndSize;
                  store.dispatch(
                    getFilteredGeofencesAsync({ queryParams: finalQueryParams })
                  );
                }
              }}
            />
          </div>
        </PageContent>
      )}
      {!isLoading &&
        isGeofencesIdle &&
        geofences.length === 0 &&
        noQueryParamsFromFilterBar && (
          <PageContent>
            <div className="col">
              <div className="geofence-page-withoutTable">
                <div className="geofence-page-withoutTable-item">
                  <IconTitle
                    icon={<IconGeofence color="currentColor" size={48} />}
                    title={t("admin.geofences.withoutTableComponent.title")}
                    text={t("admin.geofences.withoutTableComponent.desc")}
                  />
                  <div className="geofence-page-withoutTable-addGeofence">
                    <Button
                      aspect="primary"
                      size="small"
                      label={t("admin.geofences.geofenceFilterBar.addGeofence")}
                      onClick={() =>
                        navigate("/admin/geofences/create-geofence")
                      }
                    >
                      <IconAdd size={14} color="--global-colors-ink-white" />
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </PageContent>
        )}
    </>
  );
  //#endregion Render
};
export default Geofences;
