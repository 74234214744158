import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { normalize } from "normalizr";
import { RootState } from "../../app/store";
import { Config } from "../../config/Config";
import { GTFleetErrorCodes } from "../../config/GTfleetErrorCodes";
import { GTFleetSuccessCodes } from "../../config/GTFleetSuccessCodes";
import { getErrorCodes } from "../../utils/Utils";
import VehiclesRepository from "./vehiclesRepository";
import { vehicleMaintenanceCostsSchema } from "./vehicleNormalization";
import _ from "lodash";

//#region Type
export interface InstantPoint {
  data: number;
  date: string;
}
export interface VehicleMaintenanceCosts {
  vehicleId: number;
  summary: {
    totalDeadlineCosts: number;
    avgDeadlineCosts: number;
  };
  deadlineMaintenancePoint: InstantPoint[];
}
//#endregion Type

//#region API
export const getVehicleMaintenanceCostsAsync = createAsyncThunk(
  "vehicles/getVehicleMaintenanceCosts",
  async (
    data: { queryParams: string; vehicleId: number },
    { rejectWithValue }
  ) => {
    try {
      const vehiclesRepository = new VehiclesRepository();
      const response = await vehiclesRepository.getVehicleMaintenanceCosts(
        data.queryParams,
        data.vehicleId
      );
      const vehicleCosts = _.get(response, Config.DEADLINE_RESPONSE_PATH);
      const normalizedData = normalize(
        vehicleCosts,
        vehicleMaintenanceCostsSchema
      );
      return normalizedData.entities;
    } catch (error: any) {
      if (!error.response) throw error;
      return rejectWithValue(error.response.status);
    }
  }
);
//#endregion API

//#region Slice
const vehiclesMaintenanceCostsAdapter =
  createEntityAdapter<VehicleMaintenanceCosts>({
    selectId: (vehicleMaintenanceCosts) => vehicleMaintenanceCosts.vehicleId,
  });

export const vehiclesMaintenanceCostsSlice = createSlice({
  name: "vehiclesMaintenanceCosts",
  initialState: vehiclesMaintenanceCostsAdapter.getInitialState({
    status: "idle",
    reasonCode: "",
  }),
  reducers: {
    vehiclesMaintenanceCostsEmptyState: (state) => {
      vehiclesMaintenanceCostsAdapter.setAll(state, []);
      state.reasonCode = "";
      state.status = "idle";
    },
  },
  extraReducers: (builder) => {
    builder
      //#region Entity Reducers
      .addCase(
        getVehicleMaintenanceCostsAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          vehiclesMaintenanceCostsAdapter.setAll(
            state,
            action.payload.VehicleMaintenanceCosts ?? []
          );
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.GET;
        }
      )
      .addCase(
        getVehicleMaintenanceCostsAsync.rejected,
        (state: any, action: PayloadAction<any>) => {
          state.status = "failed";
          let errorType: any = action.payload.message;
          if (action?.payload && action.payload?.status === 500) {
            errorType = GTFleetErrorCodes.INTERNAL_SERVER_ERROR;
          }
          state.reasonCode = getErrorCodes(errorType);
        }
      )
      .addCase(getVehicleMaintenanceCostsAsync.pending, (state: any) => {
        state.status = "loading";
      });
    //#endregion Entity Reducers
  },
});
//#endregion Slice

//#region Status
export const vehiclesMaintenanceCostsSelectors =
  vehiclesMaintenanceCostsAdapter.getSelectors<RootState>(
    (state) => state.vehiclesMaintenanceCosts
  );

export const selectVehiclesMaintenanceCostsSliceStatus = (state: any) =>
  state.vehiclesMaintenanceCosts.status;
export const selectVehiclesMaintenanceCostsSliceReasonCode = (state: any) =>
  state.vehiclesMaintenanceCosts.reasonCode;
//#endregion Status

export const { vehiclesMaintenanceCostsEmptyState } =
  vehiclesMaintenanceCostsSlice.actions;

export default vehiclesMaintenanceCostsSlice.reducer;
