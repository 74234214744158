import PropTypes from "prop-types";
import ReactApexChart from "react-apexcharts";

export const FuelLevelMini = ({ title, dates, series, size, ...props }) => {
  const graphOption = {
    chart: {
      height: size.height,
      width: size.width,
      type: "area",
      toolbar: {
        show: false,
      },
      sparkline: {
        enabled: true,
      },
    },
    title: {
      text: title,
      margin: -8,
      style: {
        fontFamily: undefined,
        fontWeight: "400",
        fontSize: "10px",
        color: "#687484",
      },
    },
    grid: {
      show: false,
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.6,
        opacityTo: 0.5,
        stops: [0, 90, 100],
      },
    },
    colors: ["#00CFA2"],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 0.5,
    },
    xaxis: {
      type: "datetime",
      categories: dates,
      tickAmount: 2,
    },
    yaxis: {
      show: false,
    },
    tooltip: {
      enabled: false,
    },
  };
  return (
    <div className="mn-graph">
      <ReactApexChart
        options={graphOption}
        series={[series]}
        {...props}
        type="area"
        width={size.width}
        height={size.height}
      />
    </div>
  );
};

FuelLevelMini.propTypes = {
  /**
   * Title of the graph
   */
  title: PropTypes.string,
  /**
   * Dates of the ticks on x axis.
   * @see https://apexcharts.com/docs/options/xaxis/
   */
  dates: PropTypes.array,
  /**
   * Series of data to render.
   * @see https://apexcharts.com/docs/options/series/
   */
  series: PropTypes.shape({
    data: PropTypes.array.isRequired,
  }).isRequired,
  /**
   * Size of the chart.
   */
  size: PropTypes.shape({
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
};

FuelLevelMini.defaultProps = {
  title: "Lorem Ipsum",
  categories: [
    "2018-09-19T00:00:00.000Z",
    "2018-09-19T01:30:00.000Z",
    "2018-09-19T02:30:00.000Z",
    "2018-09-19T03:30:00.000Z",
    "2018-09-19T04:30:00.000Z",
    "2018-09-19T05:30:00.000Z",
    "2018-09-19T06:30:00.000Z",
  ],
  series: [[109, 100, 80, 70, 70, 60, 50]],
  size: { width: "105px", height: "auto" },
};
