import { datadogRum } from "@datadog/browser-rum";
if (
  process.env.REACT_APP_DEPLOY_TARGET === "pre-prod" ||
  process.env.REACT_APP_DEPLOY_TARGET === "prod"
) {
  datadogRum.init({
    applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID,
    clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
    site: "datadoghq.com",
    service: "gtfleet",
    env: process.env.REACT_APP_DEPLOY_TARGET,
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sampleRate: 100,
    sessionReplaySampleRate: 100,
    trackInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "allow",
  });
}
export default datadogRum;
