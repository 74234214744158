import _ from "lodash";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../app/hooks";
import { store } from "../../app/store";
import ContentSidebar from "../../layout/ContentSidebar";
import PageContent from "../../layout/PageContent";
import PageFilters from "../../layout/PageFilters";
import { DropdownList } from "../../ui/Chat/TextFieldDropdown";
import { IconMail } from "../../ui/Icon/Line/Mail";
import { IconVehicle } from "../../ui/Icon/Line/Vehicle";
import { IconDriver } from "../../ui/Icon/Solid/Driver";
import { IconPhone } from "../../ui/Icon/Solid/Phone";
import { IconUser } from "../../ui/Icon/Solid/User";
import { Sidebar } from "../../ui/Navigation/Sidebar";
import { ThumbProfile } from "../../ui/ThumbProfile/ThumbProfile";
import { getQueryString } from "../../utils/Utils";
import {
  AddressBook,
  addressBookSelectors,
  getAddressBooksAsync,
} from "../address/addressBookSlice";
import {
  Driver,
  driversEmptyState,
  driversSelectors,
} from "../driver/driversSlice";
import {
  driversStatusEmptyState,
  driversStatusSelectors,
  getFilteredDriversStatusAndDetailsAsync,
} from "../driver/driversStatusSlice";
import { UserPermissions } from "../users/privilege/privilegesSlice";
import {
  UserInfo,
  getUsersInfoAsync,
  userInfoSelectors,
} from "../users/usersInfoSlice";
import { vehiclesSelectors } from "../vehicle/vehiclesSlice";
import { Chat, ChatMessageType } from "./Chat";
import { DashboardMessagesFilterbar } from "./DashboardMessagesFilterbar";
import { DashboardMessagesSidebar } from "./DashboardMessagesSidebar";
import {
  ChatAliasType,
  chatAliasesSelector,
  getChatAliasesAsync,
} from "./chatAliasSlice";
import { RecipientType } from "./chatSlice";
import {
  MessageType,
  getChatMessagesAsync,
  messagesEmptyState,
  messagesSelector,
} from "./messagesSlice";

interface DashboardMessagesProps {
  permissions: UserPermissions;
}
export interface ChatSelected {
  selectedChatId: number;
  selectedRecipientId: number;
  selectedType: RecipientType;
  selectedUsername: string;
}

export interface UserListDropdownElement {
  id?: number;
  title: string;
  status?: string;
  iconTitle?: JSX.Element;
  subTitle?: string;
  type?: string;
  phone?: string;
  icon?: JSX.Element;
  iconSubTitle?: JSX.Element;
}

export const DashboardMessages: React.FC<DashboardMessagesProps> = ({
  permissions,
}) => {
  const [selectedChat, setSelectedChat] = useState<ChatSelected>(
    {} as ChatSelected
  );

  const [selectedChatState, setSelectedChatState] =
    useState<"INITIAL" | "SELECTED" | "NO_SELECTION">("INITIAL");
  const [showContactsList, setShowContactsList] = useState<boolean>(false);
  const [expanded, setExpanded] = useState(true);
  const [dropdownUserlistAvailable, setDropdownUserlistAvailable] = useState<
    UserListDropdownElement[]
  >([]);
  const [senderId, setSenderId] = useState<number>();

  let messageList: MessageType[] = useAppSelector((state) =>
    messagesSelector.selectAll(state)
  );

  let users: UserInfo[] =
    useAppSelector(userInfoSelectors.selectAll) ?? ({} as UserInfo[]);

  let addressBook: AddressBook[] = useAppSelector(
    addressBookSelectors.selectAll
  );

  let drivers: Driver[] = useAppSelector(driversSelectors.selectAll);
  let chatAliases: ChatAliasType[] = useAppSelector(
    chatAliasesSelector.selectAll
  );

  const [messages, setMessages] = useState<ChatMessageType[]>([]);
  const [messagesPage, setMessagesPage] = useState<number>(0);

  useEffect(() => {
    store.dispatch(getAddressBooksAsync({}));
    store.dispatch(getFilteredDriversStatusAndDetailsAsync(""));
    store.dispatch(getUsersInfoAsync());

    return () => {
      // empty state of slices
      store.dispatch(driversEmptyState());
      store.dispatch(driversStatusEmptyState());
      setMessages([]);
    };
  }, []);

  useEffect(() => {
    if (messageList && messageList.length > 0) {
      const chatAlias = chatAliases.find(
        (chatAlias) =>
          chatAlias.entityId === selectedChat.selectedRecipientId &&
          chatAlias.recipientTypeEnum === selectedChat.selectedType
      );
      if (chatAlias) {
        const messagesInfo: ChatMessageType[] = messageList
          .filter((message) => message.recipientAlias === chatAlias.alias)
          .map((message) => {
            let time = "";
            if (message.status === "DISPATCHED") {
              time = message.dispatchTime;
            } else if (message.status === "DELIVERED") {
              time = message.deliveryTime;
            } else {
              time = message.creationTime;
            }
            const chatMessageType: ChatMessageType = {
              id: message.id,
              messageText: message.body,
              position: "right",
              status: message.status,
              time: time ?? new Date(),
              userPic: users.filter((el) => el.id === senderId)[0]
                ?.profilePicture,
            } as ChatMessageType;
            return chatMessageType;
          });
        setMessages(messagesInfo);
      }
    }
  }, [messageList, chatAliases]);

  /**
   * Each time a chat is selected:
   * - the messages store should be emptied
   * - the messages hook should be wiped out
   * - the contact list dropdpown should be open
   */
  useEffect(() => {
    if (selectedChatState === "SELECTED") {
      setShowContactsList(false);
      store.dispatch(messagesEmptyState());
    }
    if (selectedChatState === "INITIAL") {
      setShowContactsList(false);
    }
    if (selectedChatState === "NO_SELECTION") {
      setShowContactsList(true);
    }
    setMessages([]);
  }, [selectedChatState, selectedChat]);

  useEffect(() => {
    setMessages([]);
  }, [showContactsList]);

  /**
   * When a chat is selected, the sender is present and the contact list is not empty:
   * - the chat aliases are requested
   * - the message between the current sender and recipient are requested
   */
  useEffect(() => {
    if (
      !_.isEmpty(selectedChat) &&
      !!selectedChat?.selectedRecipientId &&
      !!selectedChat?.selectedType &&
      senderId &&
      !showContactsList
    ) {
      store.dispatch(
        getChatAliasesAsync({
          queryParams: getQueryString({
            entityId: selectedChat.selectedRecipientId,
            recipientTypeEnum: selectedChat.selectedType,
          }),
        })
      );
      store.dispatch(
        getChatMessagesAsync({
          chatId: selectedChat.selectedChatId,
          userId: senderId,
          queryParams:
            messagesPage !== 0
              ? getQueryString({
                  page: messagesPage,
                  size: "10",
                })
              : getQueryString({
                  size: "10",
                }),
        })
      );
      setMessagesPage(0);
    }
  }, [selectedChat, showContactsList, messagesPage]);

  // once page is loaded, it builds array of userBox props for dropdown
  useEffect(() => {
    if (users.length > 0) {
      const userId = users.filter(
        (el) => el.username === localStorage.getItem("username")
      )[0]?.id;
      if (userId !== senderId) {
        setSenderId(userId);
      }

      const dropdownUserList: UserListDropdownElement[] = users
        .filter((x: UserInfo) => !!x.telephone && x.telephone !== "")
        .map((item: UserInfo) => {
          return {
            id: item.id,
            title: `${item.firstName} ${item.secondName}`,
            iconTitle: <IconUser size={8} color="--global-colors-ink-dark" />,
            subTitle: item.email,
            phone: item.telephone,
            type: "USER",
            iconSubTitle: (
              <IconMail size={8} color="--global-colors-ink-light" />
            ),
            icon: <ThumbProfile size="small" alt="User Thumbnail" />,
          } as UserListDropdownElement;
        });
      setDropdownUserlistAvailable([
        ...dropdownUserlistAvailable,
        ...dropdownUserList,
      ]);
    }
  }, [users]);

  useEffect(() => {
    if (drivers.length > 0) {
      const dropdownUserList: UserListDropdownElement[] = drivers
        .filter((x: Driver) => !!x.phoneNumber && x.phoneNumber !== "")
        .map((item: Driver) => {
          return {
            id: item.id,
            status: driversStatusSelectors.selectById(store.getState(), item.id)
              ?.driverStatus,
            title: `${item.firstName} ${item.lastName}`,
            iconTitle: <IconDriver size={8} color="--global-colors-ink-dark" />,
            iconSubTitle:
              vehiclesSelectors.selectById(store.getState(), item.vehicle)
                ?.brand !== "" ? (
                <IconVehicle size={8} color="--global-colors-ink-light" />
              ) : (
                <IconPhone size={8} color="--global-colors-ink-light" />
              ),
            subTitle:
              vehiclesSelectors.selectById(store.getState(), item.vehicle)
                ?.brand ?? item.phoneNumberPrefix + item.phoneNumber,
            phone: item.phoneNumberPrefix + item.phoneNumber,
            type: "DRIVER",
            icon: (
              <ThumbProfile
                size="small"
                alt="Driver Thumbnail"
                imgUrl={item.avatar}
              />
            ),
          } as UserListDropdownElement;
        });
      setDropdownUserlistAvailable([
        ...dropdownUserlistAvailable,
        ...dropdownUserList,
      ]);
    }
  }, [drivers]);

  useEffect(() => {
    if (addressBook.length > 0) {
      const dropdownUserList: UserListDropdownElement[] = addressBook
        .filter((x: AddressBook) => !!x.telephone && x.telephone !== "")
        .map((item: AddressBook) => {
          return {
            id: item.id,
            title: `${item.firstName} ${item.lastName}`,
            iconTitle: <IconUser size={8} color="--global-colors-ink-dark" />,
            subTitle: item.telephonePrefix + item.telephone,
            phone: item.telephonePrefix + item.telephone,
            type: "ADDRESS_BOOK",
            iconSubTitle: (
              <IconPhone size={8} color="--global-colors-ink-light" />
            ),
            icon: <ThumbProfile size="small" alt="Driver Thumbnail" />,
          } as UserListDropdownElement;
        });
      setDropdownUserlistAvailable([
        ...dropdownUserlistAvailable,
        ...dropdownUserList,
      ]);
    }
  }, [addressBook]);

  return (
    <>
      <PageFilters>
        <DashboardMessagesFilterbar
          newMessageClick={(isOpen: boolean) => {
            setShowContactsList(isOpen);
          }}
          openNewMessage={showContactsList}
        />
      </PageFilters>
      <PageContent>
        <ContentSidebar>
          <Sidebar expanded={expanded} expandedUpdated={setExpanded}>
            <DashboardMessagesSidebar
              selectedChat={(chat: any) => {
                if (!!chat && !_.isEmpty(chat)) {
                  setSelectedChatState("SELECTED");
                } else {
                  setSelectedChatState("NO_SELECTION");
                }
                setSelectedChat(chat);
              }}
              deactive={showContactsList}
              userList={dropdownUserlistAvailable}
            />
          </Sidebar>
        </ContentSidebar>
        <div className="col" style={{ height: "100%", position: "relative" }}>
          <Chat
            selectedChat={selectedChat}
            userlistAvailable={dropdownUserlistAvailable as DropdownList[]}
            messages={messages}
            sender={senderId}
            messagesPage={messagesPage}
            openDropdown={showContactsList}
            setOpenDropdown={setShowContactsList}
            getMessagesPage={setMessagesPage}
          />
        </div>
      </PageContent>
    </>
  );
};
