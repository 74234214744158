import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import _ from "lodash";
import { normalize } from "normalizr";
import { RootState } from "../../app/store";
import { Config } from "../../config/Config";
import { GTFleetErrorCodes } from "../../config/GTfleetErrorCodes";
import { GTFleetSuccessCodes } from "../../config/GTFleetSuccessCodes";
import ChatRepository from "./chatRepository";
import { sendMessageAsync } from "./chatSlice";
import { messagesSchema } from "./messagesNormalization";

// #region Type
export const MessageStatusEnum = {
  DISPATCHED: "DISPATCHED",
  DELIVERED: "DELIVERED",
  ERROR: "ERROR",
  READYTOPROCESS: "READYTOPROCESS",
  SCHEDULED: "SCHEDULED",
  PROCESSING: "PROCESSING",
};

export type MessageStatus = keyof typeof MessageStatusEnum;

export type MessageType = {
  id: number;
  messageRequestId: number;
  userId: number;
  type: string;
  sender: string;
  senderAlias: string;
  subject: string;
  body: string;
  expirationTimeMinutes: number;
  address: string;
  notify: boolean;
  externalMessageId: string;
  status: MessageStatus;
  smsStatus: string;
  errorMessage: string;
  scheduledTime: string;
  creationTime: string;
  dispatchTime: string;
  deliveryTime: string;
  recipientAlias: string;
};
//#endregion Type

export const getChatMessagesAsync = createAsyncThunk(
  "messages/getChatMessages",
  async (
    data: { queryParams?: string; chatId: number; userId: number },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const chatRepository = new ChatRepository();
      const response = await chatRepository.getChatMessages(
        data.chatId,
        data.userId,
        data.queryParams
      );
      const messages = _.get(response, Config.GET_MESSAGES_RESPONSE_PATH);
      const totalPages = _.get(
        response,
        Config.GET_MESSAGES_TOTAL_PAGES_RESPONSE_PATH
      );
      if (totalPages) {
        dispatch(setNumberOfPages(totalPages));
      }
      const normalizedData = normalize(messages, messagesSchema);
      return normalizedData.entities;
    } catch (error: any) {
      if (!error.response) throw error;
      return rejectWithValue(error.response.data.status);
    }
  }
);

export const getMessageStatusRequestAsync = createAsyncThunk(
  "chats/getMessageStatus",
  async (queryParams: string, { rejectWithValue }) => {
    try {
      const chatRepository = new ChatRepository();
      const response = await chatRepository.getMessageStatus(queryParams);
      const message = _.get(response, Config.GET_MESSAGES_STATUS_PATH);
      const normalizedData = normalize(message, messagesSchema);
      return normalizedData.entities;
    } catch (error: any) {
      if (!error.response) throw error;
      return rejectWithValue(error.response.data.status);
    }
  }
);

const messagesAdapter = createEntityAdapter<MessageType>({
  selectId: (message) => message.id,
  sortComparer: (a, b) => a.id - b.id,
});

//#region Custom Functions
function setFilteredData(state: any, action: PayloadAction<any>) {
  if (action.payload.messages) {
    messagesAdapter.upsertMany(state, action.payload.messages);
  } else {
    messagesAdapter.upsertMany(state, []);
  }
}
//#endregiorn Custom Functions

export const messagesSlice = createSlice({
  name: "messages",
  initialState: messagesAdapter.getInitialState({
    status: "idle",
    reasonCode: "",
    totalPages: 0,
  }),
  reducers: {
    messagesEmptyState: (state) => {
      messagesAdapter.setAll(state, []);
      state.reasonCode = "";
      state.status = "idle";
    },
    setNumberOfPages: (state, action: PayloadAction<number>) => {
      state.totalPages = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getChatMessagesAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(
        getChatMessagesAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          setFilteredData(state, action);
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.GET;
        }
      )
      .addCase(getChatMessagesAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = GTFleetErrorCodes.INTERNAL_SERVER_ERROR;
      })
      .addCase(getMessageStatusRequestAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(
        getMessageStatusRequestAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          messagesAdapter.upsertMany(state, action.payload.messages ?? []);
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.GET;
        }
      )
      .addCase(getMessageStatusRequestAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = GTFleetErrorCodes.INTERNAL_SERVER_ERROR;
      })
      .addCase(
        sendMessageAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          messagesAdapter.upsertMany(state, action.payload.messages ?? []);
        }
      );
  },
});

export const messagesSelector = messagesAdapter.getSelectors<RootState>(
  (state) => state.messages
);

export const selectMessagesSliceStatus = (state: any) => state.messages.status;
export const selectMessagesSliceReasonCode = (state: any) =>
  state.messages.reasonCode;

export const { messagesEmptyState, setNumberOfPages } = messagesSlice.actions;
export default messagesSlice.reducer;
