import propTypes from "prop-types";

import { IconAlertSimple } from "../Icon/Line/AlertSimple";
import { IconCheck } from "../Icon/Line/Check";
import { IconClose } from "../Icon/Line/Close";
import { IconTimer } from "../Icon/Line/Timer";
import "./Check.css";

export const Check = ({ status, size, ...props }) => {
  let icon = null;

  const getSize = (iconSize) => {
    switch (iconSize) {
      case "small":
        return 9;
      case "xsmall":
        return 6;
      case "medium":
        return 10;
      default:
        return 12;
    }
  };

  const getSizeClose = (iconSize) => {
    switch (iconSize) {
      case "small":
        return 5;
      case "xsmall":
        return 4;
      default:
        return 8;
    }
  };

  switch (status) {
    case "success":
    case "active":
      icon = (
        <IconCheck color="--global-colors-ui-white" size={getSize(size)} />
      );
      break;
    case "failed":
      icon = (
        <IconAlertSimple
          color="--global-colors-ui-white"
          size={getSize(size)}
        />
      );
      break;
    case "nothing":
      icon = <></>;
      break;
    case "pending":
      icon = (
        <IconTimer color="--global-colors-ui-white" size={getSize(size)} />
      );
      break;
    case "deactive":
    default: {
      icon = (
        <IconClose color="--global-colors-ink-ink" size={getSizeClose(size)} />
      );
    }
  }

  return (
    <span
      className={["check", `check--${status}`, `check--${size}`].join(" ")}
      {...props}
    >
      {icon}
    </span>
  );
};

Check.propTypes = {
  /**
   * The status.
   */
  status: propTypes.oneOf([
    "success",
    "failed",
    "pending",
    "active",
    "deactive",
    "nothing",
  ]),
  /**
   * The size.
   */
  size: propTypes.oneOf(["small", "regular", "medium", "xsmall"]),
};

Check.defaultProps = {
  status: "success",
  size: "small",
};
