import i18next from "i18next";
import _ from "lodash";
import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import { Button } from "../Button/Button";
import { Checkbox } from "../Forms/Checkbox";
import Form from "../Forms/Form";
import { Radio } from "../Forms/Radio";
import SearchField from "../Forms/SearchField";
import { IconArrowDown } from "../Icon/Line/ArrowDown";
import { IconClose } from "../Icon/Line/Close";
import { IconLoader } from "../Icon/Line/Loader";
import "./Dropdown.css";

export const Dropdown = ({
  isOpen,
  options,
  placeholder,
  size,
  value,
  itemAttribute,
  onChange,
  isDisabled,
  hasSearch,
  hasCheckbox,
  hasRadio,
  isClearable,
  buttonClick,
  fontSize,
  dropdownWidth,
  scroll,
  buttonText,
  hasButton,
  children,
  placeholderInValue,
  removeItem,
  handleRemoveItem,
  defaultValue,
  hasSelectAll,
  loading,
}) => {
  const [open, setOpen] = useState(false);
  const [query, setQuery] = useState("");
  const outsideClick = useRef(null);

  const [previousValue, setPreviousValue] = useState(
    getValueSelectedFromValue(value)
  );

  const [valueSelected, setValueSelected] = useState(
    getValueSelectedFromValue(value)
  );

  const [optionSelectedView, setOptionSelectedView] = useState([...options]);

  useEffect(() => {
    isOpen(open);
  }, [open]);

  useEffect(() => {
    setPreviousValue(getValueSelectedFromValue(value));
    setValueSelected(getValueSelectedFromValue(value));
  }, [value]);

  useEffect(() => {
    defaultValue && setPreviousValue(getValueSelectedFromValue(defaultValue));
    defaultValue && setValueSelected(getValueSelectedFromValue(defaultValue));
  }, [defaultValue]);

  useEffect(() => {
    setOptionSelectedView([...options]);
    if (hasSelectAll) {
      const areAllSelected =
        value?.filter((el) => el.value !== "SELECT_ALL").length ==
        options.length;

      const splicedOptions = [
        {
          label: i18next.t("common.selectAll") + " (" + options.length + ")",
          value: "SELECT_ALL",
          checked: areAllSelected,
        },
        ...options,
      ];
      setOptionSelectedView(splicedOptions);
    }
  }, [options]);

  function getValueSelectedFromValue(value) {
    let tempValueSelected = [];
    // When value is an array
    if (_.isArray(value) && value.length > 0) {
      tempValueSelected = value;
    }
    // When value is not an array, an object instead
    else if (_.isObject(value) && !_.isEmpty(value) && value !== undefined) {
      tempValueSelected = [value];
    }

    if (hasSelectAll) {
      // Check if all options are selected
      const areAllSelected =
        tempValueSelected.length === options.length && options.length > 0;

      // If all options are selected, add the "SELECT_ALL" option to the valueSelected array
      if (areAllSelected) {
        tempValueSelected = [
          {
            label: i18next.t("common.selectAll") + " (" + options.length + ")",
            value: "SELECT_ALL",
            checked: true,
          },
          ...value,
        ];
      }
    }

    return tempValueSelected;
  }

  function dropDownStyle() {
    const className = ["dropdown"];
    if (size) className.push(`dropdown--${size}`);
    if (hasCheckbox) className.push("dropdown--has-checkbox");
    if (hasRadio) className.push("dropdown--has-radio");
    return className.join(" ");
  }

  // search field method to filter items
  function filter(items) {
    const orderedItems = orderDefaultItems(items);
    if (orderedItems.length) {
      return orderedItems.filter((item) => {
        if (typeof item[itemAttribute] === "string") {
          return (
            item[itemAttribute].toLowerCase().indexOf(query.toLowerCase()) > -1
          );
        } else {
          return item[itemAttribute];
        }
      });
    }
    return [];
  }

  //manage outside click to close menu
  useEffect(() => {
    const onBodyClick = (event) => {
      if (outsideClick.current && outsideClick.current.contains(event.target)) {
        return;
      }
      setOpen(false);
    };
    document.body.addEventListener("click", onBodyClick);
    return () => {
      document.body.removeEventListener("click", onBodyClick);
    };
  }, []);

  // display selected menu item in dropdown
  function displayValue() {
    switch (true) {
      case valueSelected.length === 0:
        return "";
      case valueSelected.length === 1:
        const currentPlaceHolder = placeholderInValue
          ? placeholderInValue
          : placeholder && hasCheckbox == true
          ? placeholder + ":"
          : "";
        if (valueSelected[0][itemAttribute]) {
          return `${currentPlaceHolder} ${valueSelected[0][itemAttribute]}`;
        } else {
          return "";
        }
      case valueSelected.length > 1:
        if (!!placeholderInValue) {
          return `${placeholderInValue} ${valueSelected.length} ${i18next.t(
            "common.itemSelected"
          )}`;
        } else {
          const countOfItemChecked =
            valueSelected.filter((value) => value.value === "SELECT_ALL")
              .length > 0;
          return (
            placeholder +
            ": " +
            (countOfItemChecked
              ? `${valueSelected.length - 1}`
              : `${valueSelected.length}`) +
            " " +
            `${i18next.t("common.itemSelected")}`
          );
        }
      default:
        return valueSelected.map((el) => el[itemAttribute]);
    }
  }

  /**
   * Compares the previous selected values with the current ones.
   * In case of changes calls back the onChange functions.
   */
  useEffect(() => {
    if (
      !!previousValue &&
      !!valueSelected &&
      !_.isEqual(valueSelected, previousValue)
    ) {
      setPreviousValue(valueSelected);
      if (onChange) {
        const selectedValues = valueSelected.filter(
          (item) => item.value !== "SELECT_ALL"
        );
        onChange(selectedValues);
      }
    }
  }, [valueSelected]);

  function selectOptions(option) {
    setQuery("");
    setValueSelected([option]);
    setOpen(false);
  }

  function clearOption() {
    setOpen(!open);
    setQuery("");
    setValueSelected([]);
  }

  // mark menu item with the option selected or with default option (when value is not empty)
  function optionSelected(opt) {
    return valueSelected.some((el) => el[itemAttribute] === opt[itemAttribute]);
  }

  function selectCheckboxOptions(option) {
    setOpen(true);
    const compareElement = valueSelected.find(
      (o) => o[itemAttribute] === option[itemAttribute]
    );
    if (option.value === "SELECT_ALL") {
      if (!option.checked) {
        setOptionSelectedView(
          optionSelectedView.map((option) => {
            option.checked = true;
            setValueSelected((prevArray) => [...prevArray, option]);
            return option;
          })
        );
      } else if (option.checked) {
        setOptionSelectedView(
          optionSelectedView.map((option) => {
            option.checked = false;
            return option;
          })
        );
        setValueSelected([]);
      }
    } else if (compareElement) {
      setValueSelected(
        valueSelected.filter(
          (el) => el !== compareElement && el.value !== "SELECT_ALL"
        )
      );
    } else {
      if (optionSelectedView.some((el) => el.value == "SELECT_ALL")) {
        const select = optionSelectedView.find(
          (el) => el.value == "SELECT_ALL"
        );
        if (!_.isEmpty(select)) {
          select.checked = true;
          setValueSelected((prevArray) => [...prevArray, select, option]);
        }
      } else {
        setValueSelected((prevArray) => [...prevArray, option]);
      }
    }
  }

  // order "default" and "all fields" item as least in array
  function orderDefaultItems(array) {
    array.forEach((item, i) => {
      if (item.name && item.name === "Default") {
        if (i !== -1) {
          const items = array.splice(i, 1);
          array.splice(array.length, 0, ...items);
        }
      }
    });
    return array;
  }

  function calculateWidth() {
    const displayedValue = displayValue();
    if (displayedValue === "") {
      return "100%";
    } else {
      const max = Math.max(buttonText?.length, displayedValue.length);
      return max + 6 + "ch";
    }
  }

  return (
    <div
      style={{ width: calculateWidth() }}
      className={dropDownStyle()}
      ref={outsideClick}
    >
      <div
        onClick={() => {
          if (!isDisabled) {
            setOpen(!open);
          }
        }}
      >
        <div className={`selected-value ${open ? "permanentBorder" : ""}`}>
          <input
            type="text"
            readOnly
            placeholder={placeholder}
            value={displayValue()}
            onChange={(e) => {
              setQuery(e.target.value);
            }}
            disabled={isDisabled}
          />
        </div>
        {isClearable && valueSelected.length !== 0 && (
          <div
            className="clear"
            onClick={() => {
              clearOption();
            }}
          >
            <IconClose size={8} color="currentColor" />
          </div>
        )}
        <div
          className={`arrow ${open ? "open" : null} ${
            isDisabled ? "iconDisabled" : null
          }`}
        >
          <IconArrowDown size={14} color="currentColor" />
        </div>
      </div>
      <div
        className={`options ${open ? "open" : null}`}
        style={
          (hasCheckbox || hasRadio
            ? { padding: "6px 2px" }
            : { padding: "0px" },
          scroll &&
            dropdownWidth && {
              maxHeight: "200px",
              overflowY: "scroll",
              width: dropdownWidth,
            })
        }
      >
        {hasSearch && (
          <div className="search">
            <Form>
              <SearchField
                id="search-field"
                size="small"
                placeholder={i18next.t("common.search")}
                value={query}
                onChange={(e) => {
                  setQuery(e);
                }}
              />
            </Form>
          </div>
        )}
        {!loading &&
          filter(optionSelectedView).map((option, index) => {
            return (
              <div
                style={{ fontSize: fontSize ? fontSize : undefined }}
                key={index}
                className={`option ${optionSelected(option) ? "selected" : ""}`}
                onClick={() => {
                  if (hasCheckbox) selectCheckboxOptions(option);
                  else selectOptions(option);
                }}
              >
                {hasCheckbox && (
                  <Checkbox
                    id={index}
                    label=""
                    checked={optionSelected(option)}
                    onChange={() => selectCheckboxOptions(option)}
                  />
                )}
                {hasRadio && (
                  <Radio
                    id={index}
                    label=""
                    checked={optionSelected(option)}
                    onChange={() => selectOptions(option)}
                  />
                )}
                {option.color && (
                  <div
                    className="color"
                    style={{
                      backgroundColor: option.color,
                    }}
                  />
                )}
                {option.icon}
                {option[itemAttribute]}
                {(option["count"] || option["count"] === 0) &&
                  "  (" + option["count"] + ")"}
                {removeItem && option[itemAttribute] !== "Default" && (
                  <div
                    className="clear-item"
                    onClick={(e) => {
                      handleRemoveItem({
                        id: option["id"],
                        name: option[itemAttribute],
                      });
                      setOpen(false);
                      e.stopPropagation();
                    }}
                  >
                    <IconClose size={8} color="currentColor" />
                  </div>
                )}
              </div>
            );
          })}
        {!!loading && (
          <div className="childs-loader-wrapper">
            <div className="childs-loader">
              <IconLoader size={16} color="--global-colors-ink-light" />
            </div>
          </div>
        )}
        {hasButton && (
          <>
            <div className="split-line"></div>
            <div className="dropdown-button">
              <Button
                aspect="ghost"
                size="small"
                label={buttonText}
                onClick={() => {
                  buttonClick();
                  setOpen(false);
                }}
              >
                {children}
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

Dropdown.propTypes = {
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  itemAttribute: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.any,
  size: PropTypes.oneOf(["small", "normal"]),
  isDisabled: PropTypes.bool,
  fontSize: PropTypes.number,
  dropdownWidth: PropTypes.string,
  scroll: PropTypes.bool,
  isClearable: PropTypes.bool,
  hasSearch: PropTypes.bool,
  hasCheckbox: PropTypes.bool,
  hasRadio: PropTypes.bool,
  hasButton: PropTypes.bool,
  buttonText: PropTypes.string,
  buttonClick: PropTypes.func,
  isOpen: PropTypes.func,
  placeholderInValue: PropTypes.string,
};

Dropdown.defaultProps = {
  size: "normal",
  isDisabled: false,
  isOpen: () => {},
};
