import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { DataLabel } from "../../../ui/DataLabel/DataLabel";
import { IconCalendar } from "../../../ui/Icon/Line/Calendar";
import { IconClock } from "../../../ui/Icon/Line/Clock";
import { Preferences } from "../../users/preference/preferencesSlice";
import UserContext from "../../users/userContext";

interface ReportUtilizationDetailsProps {
  startDate: any;
  endDate: any;
  workingTime?: string;
}

export const ReportUtilizationDetails: React.FC<ReportUtilizationDetailsProps> =
  ({ startDate, endDate, workingTime }) => {
    const { t } = useTranslation();
    const [preferencesContext]: [Preferences] = useContext(UserContext);

    function dateBuilder(date: any): string {
      let dateString = "";
      let dateToDate = new Date(date);
      let day = dateToDate.getUTCDate().toString().padStart(2, "0");
      let month = (dateToDate.getUTCMonth() + 1).toString().padStart(2, "0"); // Adding 1 to the month
      let year = dateToDate.getUTCFullYear().toString();
      let hour = dateToDate.getUTCHours().toString();
      let minutes = dateToDate.getUTCMinutes().toString();
      hour.length === 1 && (hour = "0" + hour);
      minutes.length === 1 && (minutes = "0" + minutes);
      dateString = `${day}/${month}/${year} ${hour}:${minutes}`;

      return dateString;
    }

    function instantToDuration(instant: number): string {
      // Converti l'istantanea (in millisecondi) in secondi
      const durataInSecondi: number = Math.floor(instant / 1000);

      // Calcola il numero di ore, minuti e secondi
      const ore: number = Math.floor(durataInSecondi / 3600);
      const minuti: number = Math.floor((durataInSecondi % 3600) / 60);

      // Crea una stringa formattata per la durata
      const durataString: string = `${ore}h ${minuti}m`;

      return durataString;
    }

    return (
      <>
        {startDate != null && (
          <div className="details-report-item">
            <DataLabel
              dataText={dateBuilder(startDate)}
              icon={<IconCalendar size={14} />}
              label={t("report.utilization.detailsStartTime")}
            />
          </div>
        )}
        {endDate != null && (
          <div className="details-report-item">
            <DataLabel
              dataText={dateBuilder(endDate)}
              icon={<IconCalendar size={14} />}
              label={t("report.utilization.detailsEndTime")}
            />
          </div>
        )}
        {workingTime != null && (
          <div className="details-report-item">
            <DataLabel
              dataText={workingTime}
              icon={<IconClock size={14} />}
              label={t("report.utilization.detailsTotalWorking")}
            />
          </div>
        )}
      </>
    );
  };
