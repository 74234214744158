import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { IconAdd } from "../Icon/Line/Add";
import { IconCalendar } from "../Icon/Line/Calendar";
import { IconMinus } from "../Icon/Line/Minus";

import dayjs from "dayjs";
import i18next from "i18next";
import Form from "../Forms/Form";
import { SingleDatePicker } from "../Forms/SingleDatePicker";
import { Switch } from "../Forms/Switch";
import TextField from "../Forms/TextField";
import { convertToCurrentLocalesFormat } from "../Forms/utils/dateConversion";
import "./ScheduleDate.css";

interface DefaultDate {
  value: string;
  repeat: number;
}
interface ScheduleDateProps {
  defaultDate?: DefaultDate;
  open?: boolean;
  localeFormat?: string;
  language?: string;
  typeIsEmpty?: boolean;
  getValue?: (e: any) => any;
  setIsOpened?: (e: any) => any;
}

export const ScheduleDate: React.FC<ScheduleDateProps> = ({
  defaultDate,
  open,
  language,
  localeFormat,
  typeIsEmpty,
  getValue,
  setIsOpened,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(open ?? false);
  const [date, setDate] = useState(defaultDate?.value ?? "");
  const [selectedDate, setSelectedDate] = useState(defaultDate?.value ?? "");
  const [errorList, setErrorList] = useState([]);
  const [isSwitched, setIsSwitched] = useState(!!defaultDate?.repeat ?? false);
  const [repeatDate, setRepeatDate] = useState<number>(
    defaultDate?.repeat ?? 0
  );

  useEffect(() => {
    if (!typeIsEmpty) {
      setIsOpen(open ?? false);
      setDate(selectedDate);
      errorList.length = 0;
    }
  }, [open, selectedDate]);

  const toggleGroup = () => {
    setIsOpen((prevState) => !prevState);
    setIsOpened && setIsOpened(2);
  };

  const handleReset = (e: any) => {
    setDate("");
    setIsSwitched(false);
    setRepeatDate(0);
    setSelectedDate("");
    if (getValue) {
      getValue({
        date: "",
        repeat: 0,
        isRepeated: false,
      });
    }
    e.stopPropagation();
  };

  const handleTitle = () => {
    if (date === "" || isOpen) {
      return i18next.t("schedule.dateTitle");
    } else if (repeatDate && !isOpen && isSwitched) {
      return (
        convertToCurrentLocalesFormat(date, true, localeFormat) +
        ", " +
        i18next.t("schedule.then") +
        repeatDate +
        " " +
        i18next.t("schedule.months")
      );
    } else {
      return !isOpen && convertToCurrentLocalesFormat(date, true, localeFormat);
    }
  };

  function convertNumber(num: string) {
    const { format } = new Intl.NumberFormat(i18next.language);
    const [, decimalSign]: any = /^0(.)1$/.exec(format(0.1));
    return +num
      .replace(new RegExp(`[^${decimalSign}\\d]`, "g"), "")
      .replace(decimalSign, ".");
  }

  return (
    <>
      <div
        className={isOpen ? "schedule-date-simple" : "schedule-date"}
        onClick={() => {
          if (!typeIsEmpty) {
            date !== "" && setSelectedDate(date ?? "");
            !errorList.length && toggleGroup();
          }
        }}
      >
        <div className="schedule-date-button">
          <div className="schedule-date-circle">
            <IconCalendar size={14} color="--global-colors-ui-primary" />
          </div>
          <div className="schedule-date-label">
            {date !== "" && !isOpen && (
              <>
                <span className="schedule-date-pretitle">
                  {i18next.t("schedule.dateTitle")}
                </span>
                <br />
              </>
            )}
            <span className="schedule-date-title">{handleTitle()}</span>
          </div>
          <span className="schedule-date-icon">
            {isOpen ? (
              <>
                <div className="schedule-date-reset" onClick={handleReset}>
                  {i18next.t("common.reset")}
                </div>
                <IconMinus size={14} color="--global-colors-ui-primary" />
              </>
            ) : (
              <IconAdd size={14} color="--global-colors-ui-primary" />
            )}
          </span>
        </div>
      </div>
      {isOpen && localeFormat && language && (
        <div className="schedule-date-body">
          <span className="schedule-date-selection">
            {i18next.t("schedule.dateSelection")}
          </span>
          <div className="schedule-date-picker">
            <Form>
              <SingleDatePicker
                id="ScheduleDate"
                size="small"
                setErrorList={setErrorList}
                errorList={errorList}
                validation="isNotEmpty"
                dateRange={[
                  dayjs().subtract(1, "day"),
                  dayjs().add(20, "year"),
                ]}
                placeholder=""
                setDate={(data: string) => {
                  setSelectedDate(data);
                  if (getValue) {
                    getValue({
                      date: data,
                      repeat: repeatDate,
                      isRepeated: isSwitched,
                    });
                  }
                }}
                oldDate={
                  selectedDate ? selectedDate : dayjs().format("YYYY-MM-DD")
                }
                localeFormat={localeFormat ?? "DD / MM / YYYY"}
                language={language}
              />
            </Form>
          </div>
          <div className="schedule-date-left-column">
            <span className="schedule-date-labels">
              {i18next.t("schedule.repeatTitle")}
            </span>
            <Switch
              label=""
              checked={isSwitched}
              onChange={(e) => {
                setIsSwitched(e.target.checked);
                if (getValue) {
                  if (e.target.checked) {
                    getValue({
                      date: selectedDate,
                      repeat: repeatDate,
                      isRepeated: true,
                    });
                  } else {
                    getValue({
                      date: selectedDate,
                      repeat: 0,
                      isRepeated: false,
                    });
                  }
                }
              }}
            />
          </div>
          {isSwitched && (
            <div className="schedule-date-right-column">
              <span className="schedule-date-labels">
                {i18next.t("schedule.every")}
              </span>
              <div className="schedule-date-value">
                <Form>
                  <TextField
                    id="repeatDate"
                    type="text"
                    size="small"
                    maxLength="2"
                    placeholder={i18next.t("schedule.datePlaceholder")}
                    formattedNumber={true}
                    events={{
                      onChange: (data) => {
                        setRepeatDate(convertNumber(data.value));
                        if (getValue) {
                          getValue({
                            date: selectedDate,
                            repeat: convertNumber(data.value),
                            isRepeated: isSwitched,
                          });
                        }
                      },
                    }}
                    value={repeatDate}
                  ></TextField>
                </Form>
                <span className="schedule-date-months">
                  {i18next.t("schedule.months")}
                </span>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

ScheduleDate.propTypes = {
  defaultDate: PropTypes.any,
  open: PropTypes.bool,
  localeFormat: PropTypes.string,
  language: PropTypes.string,
  typeIsEmpty: PropTypes.bool,
  getValue: PropTypes.func,
  setIsOpened: PropTypes.func,
};

ScheduleDate.defaultProps = {
  open: false,
};
