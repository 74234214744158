import HttpClient from "../../utils/HttpClient";
import { GeofenceCategory } from "./geofenceCategoriesSlice";

export default class GeofenceCategoriesRepository extends HttpClient {
  constructor() {
    super(true);
  }

  getGeofenceCategories() {
    const tenantId = localStorage.getItem("tenantId");
    return this.instance.get(
      `tenants/${tenantId}/geofences/categories?size=100000&page=0&deleted=false`
    );
  }

  createGeofenceCategory(geofenceCategory: GeofenceCategory) {
    const tenantId = localStorage.getItem("tenantId");
    return this.instance.post(`geofence_categories`, {
      ...geofenceCategory,
      tenant: { id: tenantId },
    });
  }

  public updateGeofenceCategory(
    id: number,
    geofenceCategory: Partial<GeofenceCategory>
  ) {
    const tenantId = localStorage.getItem("tenantId");
    return this.instance.patch("/geofence_categories/" + id, {
      ...geofenceCategory,
      tenant: { id: tenantId },
    });
  }
}
