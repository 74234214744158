import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Deadline } from "../deadlinesSlice";
import { AddDeadlineModal } from "./AddDeadline";

interface DropDownItem {
  label: string;
}

interface DeadlineNewModalProps {
  deadlineDefaultValues?: Deadline;
  vehicleDefaultValue?: number;
}

const DeadlineNewModal: React.FC<DeadlineNewModalProps> = ({
  deadlineDefaultValues,
  vehicleDefaultValue,
}) => {
  const [addDeadlineModal, setAddDeadlineModal] = useState(true);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    return () => {};
  }, []);

  return (
    <AddDeadlineModal
      open={addDeadlineModal}
      deadlineDefaultValues={deadlineDefaultValues}
      vehicleDefaultValue={vehicleDefaultValue}
      onClose={() => {
        setAddDeadlineModal(false);
        navigate({
          pathname: "/dashboard/maintenance",
          search: searchParams.toString(),
        });
      }}
    />
  );
};
export default DeadlineNewModal;
