import { t } from "i18next";
import _ from "lodash";
import { useEffect, useRef, useState } from "react";
import {
  Location,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { TypeOptions } from "react-toastify";
import { useAppSelector } from "../../app/hooks";
import { store } from "../../app/store";
import { TableColumnEntry, TableColumns } from "../../config/TableConfig";
import { Dropdown } from "../../ui/Dropdown/Dropdown";
import Form from "../../ui/Forms/Form";
import SearchField from "../../ui/Forms/SearchField";
import { CustomizeColumnsModal } from "../../ui/Modal/CustomModals/CustomizeColumnsModal";
import { ToastNotification } from "../../utils/ToastNotification";
import { getPresetsNotification, getQueryString } from "../../utils/Utils";
import {
  fleetViewsEmptyState,
  fleetViewsSelectors,
  getFleetViewsAsync,
} from "../fleet/fleetViewsSlice";
import {
  Preset,
  addPreset,
  deletePresetAsync,
  newPresetAsync,
  removePreset,
  restoreState,
  selectpresetsSliceReasonCode,
  selectpresetsSliceStatus,
  updatePresetAsync,
} from "../users/preset/presetsSlice";
import "./DashboardVehiclesFilterBar.css";
import { vehiclesEmptyState } from "./vehiclesSlice";
import {
  getFilteredVehiclesStatusPaginationAsync,
  statusVehicleType,
} from "./vehiclesStatusSlice";

interface QueryParams {
  [paramName: string]: any;
}

interface DashboardVehiclesFilterBarProps {
  presets: any[];
  callback: (buildQueryParam: string) => any;
}
interface StatusItem {
  key: string;
  label: string;
}
interface FleetItem {
  key: string;
  label: string;
  count: number;
}

interface DropDownItem {
  key: string;
  label: string;
}

let context: string = "vehicleDashboard";

export const DashboardVehiclesFilterBar: React.FC<DashboardVehiclesFilterBarProps> =
  ({ presets, callback }) => {
    const [idDriverVehicle, setIdDriverVehicle] = useState<any>("");
    const navigate = useNavigate();
    const [checkedGroupsValues, setCheckedGroupsValues] = useState<
      DropDownItem[]
    >([]);
    const [checkedStatusesValues, setCheckedStatusesValues] = useState<
      DropDownItem[]
    >([]);
    const queryParamsRef = useRef<QueryParams>({});
    let queryParams: QueryParams = queryParamsRef.current;

    const [searchParams] = useSearchParams();
    const location: Location = useLocation();
    const [vehicleDashboardAllColumns, setVehicleDashboardAllColumns] =
      useState<TableColumnEntry[]>([] as TableColumnEntry[]);

    useEffect(() => {
      store.dispatch(getFleetViewsAsync());
      setVehicleDashboardAllColumns(
        new TableColumns().getVehicleDashboardTableColumns()
      );
      return () => {
        store.dispatch(fleetViewsEmptyState());
        store.dispatch(vehiclesEmptyState());
      };
    }, []);

    //#region PRESETS
    const presetsSliceStatus = useAppSelector(selectpresetsSliceStatus);
    const presetsSliceReasonCode = useAppSelector(selectpresetsSliceReasonCode);

    useEffect(() => {
      let presetNotification: {
        status: string | undefined;
        description: string | undefined;
      } = getPresetsNotification(presetsSliceStatus, presetsSliceReasonCode);
      if (
        presetNotification.status !== undefined &&
        presetNotification.description !== undefined
      ) {
        ToastNotification({
          toastId: t(presetNotification.description),
          status: presetNotification.status as TypeOptions,
          description: t(presetNotification.description),
        });
        store.dispatch(restoreState());
      }
    }, [presetsSliceStatus, presetsSliceReasonCode]);

    const updatePreset = (newPreset: Preset) => {
      if (newPreset?.columns?.length > 0) {
        let temporaryPreset = presets.find(
          (preset) => preset.name === undefined
        );
        if (temporaryPreset) {
          store.dispatch(removePreset(temporaryPreset.id));
        }
        if (newPreset.name) {
          if (newPreset.name !== "Default" && newPreset.context === undefined) {
            newPreset.context = context;
            newPreset.lastSelected = true;
            store.dispatch(newPresetAsync(newPreset));
          } else {
            store.dispatch(
              updatePresetAsync({
                id: newPreset.id,
                preset: {
                  lastSelected: true,
                },
                context: context,
              })
            );
          }
          return true;
        } else {
          newPreset.id = Math.max(...presets.map((preset) => preset.id)) + 1;
          store.dispatch(addPreset(newPreset));
          return false;
        }
      }
    };
    //#endregion PRESETS

    useEffect(() => {
      const map = new Map();
      const currentSearchParams =
        searchParams.toString() !== "" ? searchParams : null;
      if (!!currentSearchParams && _.isEmpty(queryParams)) {
        const size = currentSearchParams.get("size");
        map.set("size", size ?? "10");
        // Search Field
        const aliasSearch = currentSearchParams.get("alias");
        const plateSearch = currentSearchParams.get("plate");
        const driversFirstNameSearch =
          currentSearchParams.get("drivers.firstName");
        const driversLastNameSearch =
          currentSearchParams.get("drivers.lastName");
        if (plateSearch) {
          setIdDriverVehicle(plateSearch);
          map.set("plate", plateSearch);
        } else if (aliasSearch) {
          setIdDriverVehicle(aliasSearch);
          map.set("alias", aliasSearch);
        } else if (driversFirstNameSearch) {
          setIdDriverVehicle(driversFirstNameSearch);
          map.set("drivers.firstName", driversFirstNameSearch);
        } else if (driversLastNameSearch) {
          setIdDriverVehicle(driversLastNameSearch);
          map.set("drivers.lastName", driversLastNameSearch);
        }

        // Fleet Dropdown
        const fleetNames = currentSearchParams.getAll("fleet.name");
        if (!!fleetNames) {
          const selectedFleetNames: DropDownItem[] = [];
          fleetNames.forEach((fleetName) => {
            selectedFleetNames.push({
              key: fleetName,
              label: fleetName,
            } as DropDownItem);
          });
          if (selectedFleetNames && selectedFleetNames.length > 0) {
            setCheckedGroupsValues(selectedFleetNames);
            map.set(
              "fleet.name",
              selectedFleetNames.map((x) => x.key)
            );
          }
        }

        // Statuses Dropdown
        const vehiclesStatus = currentSearchParams?.getAll("vehicleStatus");
        if (!!vehiclesStatus) {
          const selectedStatuses: DropDownItem[] = [];
          vehiclesStatus.forEach((vehicleStatus) => {
            selectedStatuses.push({
              key: vehicleStatus,
              label: t(
                "fleetControl.filterBar.optionStatusVehicle." +
                  vehicleStatus.toLowerCase()
              ),
            } as DropDownItem);
          });

          if (selectedStatuses && selectedStatuses.length > 0) {
            setCheckedStatusesValues(selectedStatuses);
            map.set(
              "vehicleStatus",
              selectedStatuses.map((x) => x.key)
            );
          }
        }
      }

      if (map.size === 0 && _.isEmpty(queryParams)) {
        map.set("size", "10");
      }
      if (map.size > 0) {
        handleChanges(map);
      }
    }, [location]);

    const handleChanges = (params: Map<string, string[] | string>): void => {
      let queryString = "";
      let executeQuery = false;
      if (!!params) {
        params.forEach((value, key) => {
          if (!!value && value.length > 0) {
            queryParams[key] = value;
            executeQuery = true;
          } else {
            if (queryParams.hasOwnProperty(key)) {
              delete queryParams[key];
              executeQuery = true;
            }
          }
        });
        queryString = getQueryString(queryParams);
      }
      // effettuare chiamata API con parametri in query string
      if (executeQuery) {
        store.dispatch(
          getFilteredVehiclesStatusPaginationAsync({ queryParams: queryString })
        );
        navigate({
          pathname: "/dashboard/vehicles",
          search: queryString,
        });
        callback(queryString);
      }
    };

    const fleets = fleetViewsSelectors.selectAll(store.getState());

    const groupsValues: FleetItem[] =
      fleets?.map((value) => {
        return {
          label: value.name,
          key: value.name,
          count: value.vehiclesSize,
        };
      }) ?? [];

    const statusesValues: StatusItem[] =
      Object.values(statusVehicleType)
        .filter((value) => typeof value === "string")
        .map((value) => {
          return {
            label: t(
              "fleetControl.filterBar.optionStatusVehicle." +
                value.toLowerCase()
            ),
            key: value,
          };
        }) ?? [];

    const debouncedSearch = useRef(
      _.debounce(async (val) => {
        setIdDriverVehicle(val);
        const map = new Map();
        map.set("alias", val);
        map.set("plate", val);
        map.set("drivers.firstName", val);
        map.set("drivers.lastName", val);
        handleChanges(map);
      }, 1000)
    ).current;

    useEffect(() => {
      return () => {
        debouncedSearch.cancel();
      };
    }, [debouncedSearch]);

    async function handleSearch(val: string) {
      await debouncedSearch(val);
    }

    return (
      <div className="vehicle-filterbar-row-splitter">
        <>
          <div className="vehicle-filterbar-search">
            <Form>
              <SearchField
                name="search"
                id="driverName"
                size="small"
                placeholder={t("fleetControl.filterBar.searchField")}
                value={idDriverVehicle}
                onChange={(val: string) => {
                  handleSearch(val);
                }}
              />
            </Form>
          </div>
          <div className="vehicle-dropdown-groups">
            <Dropdown
              placeholderInValue={t(
                "fleetControl.filterBar.placeholderInValGroups"
              )}
              hasCheckbox={true}
              itemAttribute="label"
              placeholder={t("fleetControl.filterBar.searchGroups")}
              size={"small"}
              onChange={(val: DropDownItem[]) => {
                const map = new Map();
                const groupNames: string[] = [];
                val.forEach((v) => {
                  const groupName = groupsValues.find(
                    (groupValue) => groupValue.key === v.key
                  )?.key;
                  if (groupName) {
                    groupNames.push(groupName);
                  }
                });
                map.set("fleet.name", groupNames);
                handleChanges(map);
              }}
              options={groupsValues}
              value={checkedGroupsValues}
            />
          </div>
          <div className="vehicle-dropdown-statuses">
            <Dropdown
              placeholderInValue={t(
                "fleetControl.filterBar.placeholderInValStatus"
              )}
              itemAttribute="label"
              hasCheckbox={true}
              placeholder={t("fleetControl.filterBar.orderByStatus")}
              size={"small"}
              onChange={(val: DropDownItem[]) => {
                const map = new Map();
                const statusValues: string[] = [];
                val.forEach((v) => {
                  const statusValue = statusesValues.find(
                    (statusValue) => statusValue.key === v.key
                  )?.key;
                  if (statusValue) {
                    statusValues.push(statusValue);
                  }
                });
                map.set("vehicleStatus", statusValues);
                handleChanges(map);
              }}
              options={statusesValues}
              value={checkedStatusesValues}
            />
          </div>
          {vehicleDashboardAllColumns && vehicleDashboardAllColumns.length > 0 && (
            <div className="vehicle-dropdown-customize">
              <CustomizeColumnsModal
                columns={vehicleDashboardAllColumns}
                presets={presets ?? []}
                onClose={(newPreset) => updatePreset(newPreset)}
                handleDelete={(e: any) => store.dispatch(deletePresetAsync(e))}
              />
            </div>
          )}
        </>
      </div>
    );
  };
