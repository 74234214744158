import HttpClient from "../../utils/HttpClient";
import { Geofence } from "./geofenceSlice";

export default class GeofencesRepository extends HttpClient {
  constructor() {
    super(true);
  }

  public getAllGeofences() {
    return this.instance.get("/geofences");
  }

  public getGeofences(customQueryParams?: string) {
    let queryParams = "size=10000&page=0";
    if (customQueryParams) {
      queryParams = customQueryParams + "&" + queryParams;
    } else {
      queryParams = "?" + queryParams;
    }
    const tenantId = localStorage.getItem("tenantId");
    return this.instance.get(`/tenants/${tenantId}/geofences` + queryParams);
  }

  public getGeofence(geofenceId: number) {
    const tenantId = localStorage.getItem("tenantId");
    return this.instance.get(`/tenants/${tenantId}/geofences?id=${geofenceId}`);
  }

  public getFilteredGeofences(customQueryParams?: string) {
    const tenantId = localStorage.getItem("tenantId");
    let path = `/tenants/${tenantId}/geofences`;
    let queryParams = "";
    if (customQueryParams) {
      queryParams = customQueryParams;
    } else {
      queryParams = "?";
    }
    return this.instance.get(path + queryParams);
  }

  public updateGeofence(id: number, geofence: Partial<Geofence>) {
    const tenantId = localStorage.getItem("tenantId");
    return this.instance.patch(
      `/tenants/${tenantId}/geofences/` + id,
      geofence
    );
  }

  public createGeofence(geofenceObj: Partial<Geofence>) {
    const tenantId = localStorage.getItem("tenantId");

    const geofenceWithTenant: any = {
      ...geofenceObj,
      geofenceCategory: { id: geofenceObj.geofenceCategory },
    };
    return this.instance.post(
      `/tenants/${tenantId}/geofences`,
      geofenceWithTenant
    );
  }
}
