import dayjs from "dayjs";
import { t } from "i18next";
import { TravelDetail } from "../../../features/report/TravelDetailTypes";
import { DropdownButton } from "../../../ui/Button/DropdownButton";
import {
  AbstractTableBuilder,
  Row,
} from "../../../ui/Table/utils/AbstractTableBuilder";
import "../../../ui/Table/utils/TableBuilder.css";
import {
  ConvertTimeZone,
  tableFormatTime,
} from "../../../utils/DateAndTimeUtils";
import {
  getAddress,
  getCity,
  getQueryString,
  kmPerHToMilesPerH,
  kmToMiles,
  mtToKm,
  numberAnnotation,
} from "../../../utils/Utils";
import { FleetView } from "../../fleet/fleetViewsSlice";
import { Preferences } from "../../users/preference/preferencesSlice";
import { Vehicle } from "../../vehicle/vehiclesSlice";

export class DriverTravelReportTableBuilder extends AbstractTableBuilder {
  /**
   * Given data, it builds a table row
   * @param vehicle
   * @returns
   */
  rowsBuilder = (
    columns: { label: string; field: string; sort: boolean }[] | undefined,
    context: string,
    entities: {
      travelDetail?: TravelDetail;
      vehicle?: Vehicle;
      fleetView: FleetView;
      setCurrentSelectedTravel?: any;
    },
    preferencesContext: Preferences,
    writePermission: boolean = false
  ) => {
    let row: Row = { render: {} as Row };

    let vehicle: Vehicle = entities.vehicle ?? ({} as Vehicle);

    let fleetView: FleetView = entities.fleetView ?? ({} as FleetView);

    let travelDetail: TravelDetail =
      entities.travelDetail ?? ({} as TravelDetail);

    let setCurrentSelectedTravel: any = entities.setCurrentSelectedTravel;

    columns?.forEach((column) => {
      switch (column.field) {
        case "travelVehicle":
          row.render.travelVehicle = (
            <>
              <p className="reportMarked">
                {vehicle.alias !== undefined ? vehicle.alias : "---"}
              </p>
              {vehicle.brand && <p className="reportNormal">{vehicle.brand}</p>}
            </>
          );
          break;
        case "travelVehicleGroup":
          row.render.travelVehicleGroup =
            fleetView.name !== undefined ? fleetView.name : "---";
          break;
        case "travel": // Driver Travel Report
          let routeId = undefined;
          routeId = travelDetail.routeId;
          row.render.travel = !!routeId ? routeId : t("common.na");
          break;
        case "startTime": // Driver Travel Report
          let nonConvertedStartTime = undefined;
          nonConvertedStartTime =
            travelDetail?.firstPosition?.gpsPositionTimestamp;
          if (nonConvertedStartTime !== undefined) {
            const startTime = ConvertTimeZone(
              nonConvertedStartTime,
              preferencesContext.timeZone ?? undefined,
              preferencesContext.localeFormat ?? undefined
            ).split(" ");
            row.render.startTime = (
              <>
                <p className="reportMarked">{startTime[0]}</p>
                <p className="reportNormal">{startTime[1]}</p>
              </>
            );
          } else {
            row.render.startTime = (
              <>
                <p className="reportMarked">--:--:----</p>
                <p className="reportNormal">--:--:--</p>
              </>
            );
          }
          break;
        case "startPosition": // Driver Travel Report
          let startPosition = undefined;
          startPosition = travelDetail?.firstPosition?.address;
          if (startPosition !== undefined) {
            row.render.startPosition = (
              <>
                <p className="reportMarked">{getCity(startPosition)}</p>
                <p className="reportNormal">{getAddress(startPosition)}</p>
              </>
            );
          } else {
            row.render.startPosition = "--";
          }
          break;
        case "endTime": // Driver Travel Report
          let nonConvertedEndTime = undefined;
          nonConvertedEndTime =
            travelDetail?.lastPosition?.gpsPositionTimestamp;
          if (nonConvertedEndTime !== undefined) {
            const startTime = ConvertTimeZone(
              nonConvertedEndTime,
              preferencesContext.timeZone ?? undefined,
              preferencesContext.localeFormat ?? undefined
            ).split(" ");
            row.render.endTime = (
              <>
                <p className="reportMarked">{startTime[0]}</p>
                <p className="reportNormal">{startTime[1]}</p>
              </>
            );
          } else {
            row.render.endTime = (
              <>
                <p className="reportMarked">--:--:----</p>
                <p className="reportNormal">--:--:--</p>
              </>
            );
          }
          break;
        case "endPosition": // Driver Travel Report
          let lastPosition = undefined;
          lastPosition = travelDetail?.lastPosition?.address;
          if (lastPosition !== undefined) {
            row.render.endPosition = (
              <>
                <p className="reportMarked">{getCity(lastPosition)}</p>
                <p className="reportNormal">{getAddress(lastPosition)}</p>
              </>
            );
          } else {
            row.render.endPosition = "--";
          }
          break;
        case "traveled": // Driver Travel Report
          if (travelDetail?.traveled !== undefined) {
            row.render.traveled = preferencesContext.isMetric
              ? numberAnnotation(mtToKm(travelDetail.traveled))
              : numberAnnotation(kmToMiles(mtToKm(travelDetail.traveled)));
          } else {
            row.render.traveled = "--";
          }
          break;
        case "avgSpeed": // Driver Travel Report
          if (travelDetail?.avgSpeed !== undefined) {
            row.render.avgSpeed = preferencesContext.isMetric
              ? numberAnnotation(travelDetail.avgSpeed)
              : numberAnnotation(kmPerHToMilesPerH(travelDetail.avgSpeed));
          } else {
            row.render.avgSpeed = "--";
          }
          break;
        case "driveTime": // Driver Travel Report
          let drivingTime = undefined;
          drivingTime = travelDetail?.drivingTime;
          if (drivingTime !== undefined) {
            row.render.driveTime = tableFormatTime(drivingTime, "seconds");
          } else {
            row.render.driveTime = "--";
          }
          break;
        case "idleTime": // Driver Travel Report
          let idleTime = undefined;
          idleTime = travelDetail?.idleTime;
          if (idleTime !== undefined) {
            row.render.idleTime = tableFormatTime(idleTime, "seconds");
          } else {
            row.render.idleTime = "--";
          }
          break;
        default:
          break;
      }
    });

    const driverTravelsDropDownList = [
      {
        id: 0,
        title: t("common.viewOnMap"),
        onClick: () => {
          window.open(
            `/dashboard/vehicles/location-history/track/${travelDetail.routeId}` +
              getQueryString({
                firstPointDate: [
                  dayjs(
                    new Date(
                      travelDetail.firstPosition.gpsPositionTimestamp
                    ).setDate(
                      new Date(
                        travelDetail.firstPosition.gpsPositionTimestamp
                      ).getDate() - 1
                    )
                  ).format("YYYY/MM/DD HH:mm"),
                  dayjs(
                    new Date(
                      travelDetail.lastPosition.gpsPositionTimestamp
                    ).setDate(
                      new Date(
                        travelDetail.lastPosition.gpsPositionTimestamp
                      ).getDate() + 1
                    )
                  ).format("YYYY/MM/DD HH:mm"),
                ],
                routeStateTypeEnum: "TRACK",
                "vehicle.id": travelDetail.vehicleId ?? "",
              }),
            "_blank",
            "noopener,noreferrer"
          );
        },
      },
      {
        id: 1,
        title: t("common.viewTravelDetails"),
        onClick: () => {
          setCurrentSelectedTravel(travelDetail.routeId);
        },
      },
    ];

    row.render.action = (
      <DropdownButton
        aspect="ghost"
        size="small"
        list={driverTravelsDropDownList}
      />
    );
    return row;
  };
}
