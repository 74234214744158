import { schema } from "normalizr";
import { driverSchema } from "../../driver/driverNormalization";

//#region DRIVER_TRAVELS
export const driverTravelSummarySchema: schema.Entity<any> = new schema.Entity(
  "driverTravelsSummary",
  {
    driverDetails: driverSchema,
  },
  { idAttribute: "routeId" }
);
export const driverTravelsSummarySchema = new schema.Array(
  driverTravelSummarySchema
);

export const driverTravelsMacroSummarySchema: schema.Entity<any> =
  new schema.Entity("driverTravelsMacroSummary");
//#endregion DRIVER_TRAVELS
