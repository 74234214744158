import { t } from "i18next";
import { NavigateFunction } from "react-router-dom";
import { Button } from "../../../ui/Button/Button";
import { Tooltip } from "../../../ui/Forms/Tooltip";
import { IconEdit } from "../../../ui/Icon/Line/Edit";
import { IconTrash } from "../../../ui/Icon/Line/Trash";
import { LabelText } from "../../../ui/LabelText/LabelText";
import { Table } from "../../../ui/Table/Table";
import {
  AbstractTableBuilder,
  Row,
} from "../../../ui/Table/utils/AbstractTableBuilder";
import "../../../ui/Table/utils/TableBuilder.css";
import { GeofenceCategory } from "../../geofenceCategory/geofenceCategoriesSlice";
import "./EditCategoriesModal.css";

export class EditCategoriesModalTableBuilder extends AbstractTableBuilder {
  rowsBuilder = (
    columns: { label: string; field: string; sort: boolean }[] | undefined,
    entities: {
      geofenceCategory?: GeofenceCategory;
    },
    navigate: NavigateFunction,
    writePermission: boolean = false
  ) => {
    let row: Row = { render: {} as Row };
    let geofenceCategory: GeofenceCategory =
      entities.geofenceCategory ?? ({} as GeofenceCategory);

    columns &&
      columns.forEach((column) => {
        switch (column.field) {
          case "category":
            row.category = geofenceCategory.id ?? t("common.na");
            row.render.category = (
              <LabelText label={geofenceCategory?.name} text="" />
            );
            break;
          case "color":
            row.render.color = geofenceCategory?.color && (
              <Table.TextIcon
                text=""
                icon={
                  <span
                    className="circle"
                    style={{
                      width: "15px",
                      height: "15px",
                      backgroundColor: geofenceCategory?.color,
                      border: `1px solid ${geofenceCategory?.color}`,
                    }}
                  ></span>
                }
              />
            );
            break;
          case "actions":
            const actionsButton = () => {
              return (
                <>
                  <Button
                    aspect="secondary"
                    size="small"
                    label=""
                    onlyIcon={true}
                    onClick={() => {
                      navigate(
                        "/admin/geofences/edit-category/" + geofenceCategory.id,
                        {
                          state: {
                            id: geofenceCategory.id,
                            name: geofenceCategory.name,
                            color: geofenceCategory.color,
                          },
                        }
                      );
                    }}
                  >
                    <IconEdit size={14} color="--global-colors-ink-dark" />
                  </Button>
                  {geofenceCategory?.numberOfGeofences > 0 ? (
                    <>
                      <Tooltip />
                      <div
                        className="deletion-false"
                        data-for="tooltip"
                        data-tip={t(
                          "admin.geofences.deleteGeofenceCategory.disabled"
                        )}
                      >
                        <Button
                          aspect="danger"
                          size="small"
                          label=""
                          onlyIcon={true}
                          disabled={true}
                        >
                          <IconTrash
                            size={14}
                            color="--global-colors-ui-white"
                          />
                        </Button>
                      </div>
                    </>
                  ) : (
                    <Button
                      aspect="danger"
                      size="small"
                      label=""
                      onlyIcon={true}
                      disabled={false}
                      onClick={() => {
                        navigate(
                          "/admin/geofences/delete-category/" +
                            geofenceCategory.id
                        );
                      }}
                    >
                      <IconTrash size={14} color="--global-colors-ui-white" />
                    </Button>
                  )}
                </>
              );
            };
            row.render.actions = (
              <Table.TextIcon text="" icon={actionsButton()} />
            );
            break;
          default:
            break;
        }
      });

    return row;
  };
}
