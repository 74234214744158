import { t } from "i18next";
import PropTypes from "prop-types";
import { Tooltip } from "../Forms/Tooltip";
import { Check } from "../Indicators/Check";
import "./ActionStatusColumns.css";

export const ActionStatusColumns = ({
  label,
  clickAction,
  serviceStatus,
  vehicleStatus,
  subLabel,
  disabled,
  serviceType,
  commandType,
  ...props
}) => {
  return (
    <>
      <div
        className={
          disabled
            ? "mn-action-status-columns-disabled"
            : "mn-action-status-columns"
        }
        onClick={disabled ? () => {} : clickAction}
        {...props}
      >
        <div className="mn-action-status-columns__inner">
          <div>
            {label}
            {subLabel && (
              <div className="mn-action-status-columns__sub-label">
                {subLabel}
              </div>
            )}
          </div>
          <Tooltip />
          <div className="mn-action-status-columns__check">
            <span
              key="serviceStatus"
              data-for="tooltip"
              data-tip={disabled ? t("common.underdevelopment") : serviceType}
            >
              <div className="mn-action-status-columns__check-left">
                <Check status={serviceStatus.toLowerCase()} size="regular" />
              </div>
            </span>
            <span
              key="vehicleStatus"
              data-for="tooltip"
              data-tip={disabled ? t("common.underdevelopment") : commandType}
            >
              <div className="mn-action-status-columns__check-right">
                <Check status={vehicleStatus.toLowerCase()} size="regular" />
              </div>
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

ActionStatusColumns.propTypes = {
  label: PropTypes.string.isRequired,
  clickAction: PropTypes.func.isRequired,
  subLabel: PropTypes.string,
  serviceStatus: PropTypes.string,
  vehicleStatus: PropTypes.string,
};

ActionStatusColumns.defaultProps = {
  label: "Action",
  serviceStatus: "deactive",
  vehicleStatus: "deactive",
  subLabel: "",
};
