import React, { useState } from "react";
import PropTypes from "prop-types";
import { Signal } from "../Indicators/IndicatorSignal";
import { Button } from "../Button/Button";
import "./DriverSummary.css";
import { useTranslation } from "react-i18next";
import { Tooltip } from "../Forms/Tooltip";

export const DriverSummary = ({
  status,
  details,
  icon,
  onToggle,
  ...props
}) => {
  const [show, setShow] = useState(false);
  const { t } = useTranslation();

  const driverSummaryMarkup = details.map((detail, index) => {
    return (
      <div className="mn-driver-summary__details-item-container" key={index}>
        <Tooltip />
        <span
          key={index}
          className="mn-driver-summary__details-item"
          data-for="tooltip"
          data-tip={detail.description}
        >
          {detail.icon} {detail.label}
        </span>
      </div>
    );
  });
  const classnames = show
    ? "mn-driver-summary mn-driver-summary--show"
    : "mn-driver-summary";
  return (
    <div className={classnames} {...props}>
      <div className="mn-driver-summary__left-col">
        <div className="mn-driver-summary__icon-status">
          <div className="mn-driver-summary__icon">{icon}</div>
          <Signal mode={status} />
        </div>
        <div className="mn-driver-summary__details">{driverSummaryMarkup}</div>
      </div>
      <div className="mn-driver-summary__right-col">
        <div className="mn-driver-summary__toggle-details">
          <Button
            aspect="outline"
            label={show ? t("common.hideButton") : t("common.showButton")}
            size="small"
            onClick={() => {
              if (onToggle) {
                onToggle(!show);
              }
              setShow((prevState) => !prevState);
            }}
          />
        </div>
      </div>
    </div>
  );
};

DriverSummary.propTypes = {
  icon: PropTypes.object.isRequired,
  status: PropTypes.oneOf(["ONLINE", "OFFLINE", "UNKNOWN"]),
  details: PropTypes.array,
};
