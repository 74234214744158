import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { getReportsMetadataAsync } from "../report/reportsMetadataSlice";

//#region Type
export interface Schedule {
  id: number;
  reportId: number;
  period: string;
  time: string;
  reportType: string;
  users: string[];
}
//#endregion Type

const schedulesAdapter = createEntityAdapter<Schedule>({
  selectId: (schedule) => schedule.id,
  sortComparer: (a, b) => a.id - b.id,
});

export const scheduleSlice = createSlice({
  name: "schedules",
  initialState: schedulesAdapter.getInitialState({
    status: "idle",
    reasonCode: "",
  }),
  reducers: {
    schedulesEmptyState: (state) => {
      schedulesAdapter.setAll(state, []);
      state.reasonCode = "";
      state.status = "idle";
    },
  },
  extraReducers: (builder) => {
    builder
      //#region Entity Reducers
      // get reportMetadata details async extra reducers
      .addCase(
        getReportsMetadataAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          action.payload.schedule &&
            schedulesAdapter.upsertMany(state, action.payload.schedule);
        }
      );
  },
});

export const schedulesSelectors = schedulesAdapter.getSelectors<RootState>(
  (state) => state.schedules
);

export const selectSchedulesSliceStatus = (state: any) => state.schedule.status;
export const selectSchedulesSliceReasonCode = (state: any) =>
  state.schedule.reasonCode;
export const { schedulesEmptyState } = scheduleSlice.actions;
//#endregion Status

export default scheduleSlice.reducer;
