import "./PageContent.css";

interface PageContentProps {
  children: object;
  height?: boolean;
  className?: string;
}

function PageContent({ children, height, className }: PageContentProps) {
  let classes =
    (height === null || height === undefined || height
      ? "withFilterBar "
      : "withoutFilterBar ") + className ?? "";

  return <div className={`row page-content ${classes}`}>{children}</div>;
}

export default PageContent;
