import { t } from "i18next";
import { useContext, useEffect, useRef, useState } from "react";
import { useMatch, useNavigate, useSearchParams } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { store } from "../../app/store";
import PageContent from "../../layout/PageContent";
import PageFilters from "../../layout/PageFilters";
import { IconLicense } from "../../ui/Icon/Line/License";
import { IconTitle } from "../../ui/IconTitle/IconTitle";
import { PageCounter } from "../../ui/Table/PageCounter";
import { Table } from "../../ui/Table/Table";
import { TabSection } from "../../ui/Tabs/TabSection";
import { Tabs } from "../../ui/Tabs/Tabs";
import { getQueryString } from "../../utils/Utils";
import { FileArchiveModal } from "../archive/FileArchiveModal";
import { Driver, driversSelectors } from "../driver/driversSlice";
import { selectDriverStatusSliceStatus } from "../driver/driversStatusSlice";
import { Preferences } from "../users/preference/preferencesSlice";
import { UserPermissions } from "../users/privilege/privilegesSlice";
import UserContext from "../users/userContext";
import {
  Vehicle,
  selectVehiclesSliceStatus,
  vehiclesSelectors,
} from "../vehicle/vehiclesSlice";
import "./DashboardTachographFilesStatus.css";
import { DashboardTachographFilesStatusDetails } from "./DashboardTachographFilesStatusDetails";
import { DashboardTachographFilesStatusFilterBar } from "./DashboardTachographFilesStatusFilterBar";
import { DashboardTachographFilesStatusTableBuilder } from "./DashboardTachographFilesStatusTableBuilder";
import {
  TachographFileType,
  getTachographFilesAsync,
  selectTachographFilesSliceStatus,
  tachographFilesSelectors,
} from "./tachographFilesSlice";
import {
  TachographFileStatus,
  getTachographFilesStatusAsync,
  selectTachographFilesStatusSliceStatus,
  tachographFilesStatusSelectors,
} from "./tachographFilesStatusSlice";
import {
  TachographFileStatusSummary,
  selectTachographFilesStatusSummarySliceStatus,
  tachographFilesStatusSummarySelectors,
} from "./tachographFilesStatusSummarySlice";

interface DashboardTachographFilesStatusProps {
  permissions: UserPermissions;
}

interface TableData {
  columns: { label: string; field: string; sort: boolean }[] | undefined;
  rows: { [key: string]: any };
}

const tableData: TableData = {} as TableData;
const tableVehicleSchemaFix = [
  { id: 1, name: "downloadDate" },
  { id: 2, name: "vehicle" },
  { id: 3, name: "license" },
  { id: 4, name: "expirationDate" },
  { id: 5, name: "delay" },
  { id: 6, name: "status" },
  { id: 7, name: "fileName" },
  { id: 8, name: "download" },
  { id: 9, name: "archive" },
];
const tableDriverSchemaFix = [
  { id: 1, name: "downloadDate" },
  { id: 2, name: "driver" },
  { id: 3, name: "expirationDate" },
  { id: 4, name: "delay" },
  { id: 5, name: "status" },
  { id: 6, name: "fileName" },
  { id: 7, name: "download" },
  { id: 8, name: "archive" },
];

export const DashboardTachographFilesStatus: React.FC<DashboardTachographFilesStatusProps> =
  ({ permissions }) => {
    //#region Initialization
    const navigate = useNavigate();
    const [preferencesContext]: [Preferences] = useContext(UserContext);

    const [resetPage, setResetPage] = useState<boolean>(false);
    const [searchParams] = useSearchParams();
    const [selectedTab, setSelectedTab] = useState<string>(
      searchParams.get("type") != null && searchParams.get("type") === "DRIVER"
        ? t("pages.dashboardTachographFile.drivers")
        : t("pages.dashboardTachographFile.vehicles")
    );
    const [tachographFileType, setTachographFileType] =
      useState<TachographFileType>(
        searchParams.get("type") != null &&
          searchParams.get("type") === "DRIVER"
          ? "DRIVER"
          : "VEHICLE"
      );

    useEffect(() => {
      document.title = t("navigation.userMenu.tachographFiles");
    }, []);
    //#endregion Initialization

    //#region Data
    let tachographFilesStatusSummary: TachographFileStatusSummary[] =
      useAppSelector(tachographFilesStatusSummarySelectors.selectAll);

    let tachographFilesStatus: TachographFileStatus[] = useAppSelector(
      tachographFilesStatusSelectors.selectAll
    );
    //#endregion Data

    //#region Status

    const tachographFilesStatusSummarySliceStatus = useAppSelector(
      selectTachographFilesStatusSummarySliceStatus
    );

    const tachographFilesStatusSliceStatus = useAppSelector(
      selectTachographFilesStatusSliceStatus
    );

    const vehicleSliceStatus = useAppSelector(selectVehiclesSliceStatus);

    const driverStatusSliceStatus = useAppSelector(
      selectDriverStatusSliceStatus
    );

    const isLoading =
      tachographFilesStatusSliceStatus === "loading" ||
      vehicleSliceStatus === "loading" ||
      driverStatusSliceStatus === "loading";

    const isIdle =
      tachographFilesStatusSliceStatus === "idle" &&
      vehicleSliceStatus === "idle" &&
      driverStatusSliceStatus === "idle";

    //#endregion Status

    //#region Search
    const [queryParamsFromFilterBar, setQueryParamsFromFilterBar] =
      useState<string>("");

    const [queryParamsChanged, setQueryParamsChanged] =
      useState<boolean>(false);
    useEffect(() => {
      if (!!queryParamsFromFilterBar) {
        setQueryParamsChanged(true);
      }
    }, [queryParamsFromFilterBar]);

    useEffect(() => {
      if (queryParamsChanged && tachographFilesStatusSliceStatus === "idle") {
        setQueryParamsChanged(false);
        setResetPage(true);
      } else {
        setResetPage(false);
      }
    }, [tachographFilesStatusSliceStatus, queryParamsChanged]);
    //#endregion Search

    //#region Navigation Modal
    const tachographFileRoute = useMatch(
      "/dashboard/tachograph-files/:type/:entityId"
    );
    const [entityId, setEntityId] = useState(-1);

    useEffect(() => {
      if (tachographFileRoute !== null) {
        if (
          tachographFileRoute.params.entityId &&
          tachographFileRoute.params.type &&
          (tachographFileRoute.params.type === "VEHICLE" ||
            tachographFileRoute.params.type === "DRIVER")
        ) {
          setEntityId(Number(tachographFileRoute.params.entityId));
          setTachographFileType(tachographFileRoute.params.type);
        }
      }
    }, [tachographFileRoute]);
    //#endregion Navigation Modal

    //#region Table
    const tableBuilderRef = useRef<DashboardTachographFilesStatusTableBuilder>(
      new DashboardTachographFilesStatusTableBuilder()
    );
    let tableBuilder = tableBuilderRef.current;

    tableData.columns = tableBuilder.setColumns(
      tachographFileType === "VEHICLE"
        ? tableVehicleSchemaFix
        : tableDriverSchemaFix
    );

    tableData.rows =
      isIdle && tableData.columns && tableData?.columns?.length > 0
        ? tachographFilesStatus.map(
            (tachographFileStatus: TachographFileStatus) => {
              let vehicle: Vehicle = {} as Vehicle;
              let driver: Driver = {} as Driver;
              if (tachographFileStatus.type === "VEHICLE") {
                vehicle =
                  vehiclesSelectors.selectById(
                    store.getState(),
                    tachographFileStatus.entityId
                  ) ?? vehicle;
              } else {
                driver =
                  driversSelectors.selectById(
                    store.getState(),
                    tachographFileStatus.entityId
                  ) ?? driver;
              }

              return tableBuilder.rowsBuilder(
                tableData.columns,
                {
                  tachographFileStatus,
                  vehicle,
                  driver,
                },
                preferencesContext
              );
            }
          )
        : tableData.rows;
    //#endregion Table

    ///#region Render
    function getnumOfPages() {
      if (tachographFileType === "VEHICLE") {
        if (tachographFilesStatusSummary[0]?.vehiclesCount) {
          return Math.ceil(tachographFilesStatusSummary[0]?.vehiclesCount / 10);
        } else {
          return 0;
        }
      } else if (tachographFilesStatusSummary[0]?.driversCount) {
        return Math.ceil(tachographFilesStatusSummary[0]?.driversCount / 10);
      } else {
        return 0;
      }
    }
    function getTabSection() {
      return (
        <>
          <PageFilters>
            <div className="col col-16">
              <DashboardTachographFilesStatusFilterBar
                type={tachographFileType}
                callback={setQueryParamsFromFilterBar}
              />
            </div>
          </PageFilters>
          <div className="col col-16">
            <div className="details-tachograph-file-wrapper">
              <div className="details-tachograph-file-title">
                {t("pages.dashboardTachographFile.details.title")}
                {tachographFilesStatusSummarySliceStatus === "loading" && (
                  <div className="details-tachograph-file-loader" />
                )}
              </div>
              <div className="details-tachograph-file-container">
                <div className="details-tachograph-file-icons">
                  {tachographFilesStatusSummarySliceStatus === "idle" ? (
                    <DashboardTachographFilesStatusDetails
                      vehiclesCount={
                        tachographFileType === "VEHICLE"
                          ? tachographFilesStatusSummary[0]?.vehiclesCount ??
                            t("common.na")
                          : undefined
                      }
                      driversCount={
                        tachographFileType === "DRIVER"
                          ? tachographFilesStatusSummary[0]?.driversCount ??
                            t("common.na")
                          : undefined
                      }
                      expiringCount={
                        tachographFilesStatusSummary[0]?.expiringCount ??
                        t("common.na")
                      }
                      expiredCount={
                        tachographFilesStatusSummary[0]?.expiredCount ??
                        t("common.na")
                      }
                      okCount={
                        tachographFilesStatusSummary[0]?.okCount ??
                        t("common.na")
                      }
                    />
                  ) : undefined}
                </div>
              </div>
            </div>
          </div>
          {tableData.rows?.length > 0 && isIdle ? (
            <PageContent>
              <div className="tachograph-file-table-container">
                <div className="tachograph-file-container">
                  <Table data={tableData}>
                    <Table.Head />
                    <Table.Body />
                  </Table>
                </div>
                <PageCounter
                  isActionPerforming={isLoading}
                  totalElements={
                    tachographFileType === "VEHICLE"
                      ? tachographFilesStatusSummary[0]?.vehiclesCount
                      : tachographFilesStatusSummary[0]?.driversCount
                  }
                  resetPage={resetPage}
                  disabled={!isIdle}
                  progressiveArrowMode={true}
                  numOfPages={getnumOfPages()}
                  numSelected={0}
                  onClick={(id, currentPage) => {
                    if (id !== currentPage) {
                      const pageAndSize = getQueryString({
                        page: id - 1,
                        size: "10",
                      });
                      const finalQueryParams = queryParamsFromFilterBar
                        ? queryParamsFromFilterBar +
                          pageAndSize.replace("?", "&")
                        : pageAndSize;
                      store.dispatch(
                        getTachographFilesStatusAsync({
                          queryParams: finalQueryParams,
                          page: id - 1,
                        })
                      );
                    }
                  }}
                />
              </div>
            </PageContent>
          ) : (
            <PageContent>
              <div className="file-not-found">
                <IconTitle
                  icon={<IconLicense color="currentColor" size={48} />}
                  title={t("pages.dashboardTachographFile.filesNotFound.title")}
                  text={t("pages.dashboardTachographFile.filesNotFound.desc")}
                />
              </div>
            </PageContent>
          )}
        </>
      );
    }

    function getModalTitle() {
      if (tachographFileType === "VEHICLE") {
        let vehicle = vehiclesSelectors.selectById(store.getState(), entityId);
        return vehicle !== undefined
          ? `${vehicle.alias} - ${vehicle.plate}`
          : t("common.na");
      } else {
        let driver = driversSelectors.selectById(store.getState(), entityId);
        return driver !== undefined
          ? `${driver.tachographCard} - ${driver.firstName} ${driver.lastName}`
          : t("common.na");
      }
    }
    ///#endregion Render

    return (
      <>
        <div className="tachograph-file-tabs">
          <h1 className="title">{t("pages.dashboardTachographFile.title")}</h1>
          <Tabs
            onClick={(label) => {
              if (label === t("pages.dashboardTachographFile.vehicles")) {
                setTachographFileType("VEHICLE");
              } else {
                setTachographFileType("DRIVER");
              }
              setSelectedTab(label);
            }}
            enableTab={selectedTab}
          >
            <TabSection label={t("pages.dashboardTachographFile.vehicles")}>
              {getTabSection()}
            </TabSection>
            <TabSection label={t("pages.dashboardTachographFile.drivers")}>
              {getTabSection()}
            </TabSection>
          </Tabs>
        </div>
        {entityId !== -1 ? (
          <FileArchiveModal
            open={entityId !== -1}
            tableSchema={[
              { id: 1, name: "downloadDate" },
              { id: 2, name: "fileName" },
              { id: 3, name: "download" },
            ]}
            data={{
              selector: tachographFilesSelectors,
              status: selectTachographFilesSliceStatus,
              api: (data: { queryParams: string; page: number }) =>
                getTachographFilesAsync({
                  entityId: entityId,
                  type: tachographFileType,
                  queryParams: data.queryParams,
                  page: data.page,
                }),
              bucket: process.env.REACT_APP_AMAZON_S3_BUCKET_TACHOGRAPHFILE,
              valuePath: {
                fileName: "fileName",
                downloadDate: "timestamp",
                path: "path",
              },
              queryParamsName: {
                date: ["startPeriod", "endPeriod"],
              },
            }}
            title={getModalTitle()}
            desc={t("customModals.archive.tachograph.file")}
            iconSection={
              <IconLicense color="--global-colors-ink-dark" size={48} />
            }
            filesNotFoundTexts={{
              title: t("customModals.archive.tachograph.filesNotFound.title"),
              desc: t("customModals.archive.tachograph.filesNotFound.desc"),
            }}
            onClose={() => {
              setEntityId(-1);
              navigate(
                `/dashboard/tachograph-files${getQueryString({
                  type: tachographFileType,
                })}`
              );
            }}
          />
        ) : undefined}
      </>
    );
  };
