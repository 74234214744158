import i18next from "i18next";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { kmToMiles, mtToKm, numberAnnotation } from "../../utils/Utils";
import Form from "../Forms/Form";
import { Switch } from "../Forms/Switch";
import TextField from "../Forms/TextField";
import { IconAdd } from "../Icon/Line/Add";
import { IconMinus } from "../Icon/Line/Minus";
import { IconDistance } from "../Icon/Solid/Distance";
import "./ScheduleDistance.css";

interface DefaultDistance {
  value: number;
  repeat: number;
}
interface ScheduleDistanceProps {
  initialDistance: number;
  defaultDistance?: DefaultDistance;
  open?: boolean;
  typeIsEmpty?: boolean;
  isMetric?: boolean;
  getValue?: (e: any) => any;
  setIsOpened?: (e: any) => any;
}

export const ScheduleDistance: React.FC<ScheduleDistanceProps> = ({
  initialDistance,
  defaultDistance,
  open,
  typeIsEmpty,
  isMetric,
  getValue,
  setIsOpened,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(open ?? false);
  const [distance, setDistance] = useState<number>(defaultDistance?.value ?? 0);

  const [repeatDistance, setRepeatDistance] = useState<number>(
    defaultDistance?.repeat ?? 0
  );
  const [selectedDistance, setSelectedDistance] = useState(
    defaultDistance?.value ?? 0
  );

  const [isSwitched, setIsSwitched] = useState(
    !!defaultDistance?.repeat ?? false
  );

  useEffect(() => {
    if (!typeIsEmpty) {
      setIsOpen(open ?? false);
      setDistance(selectedDistance);
    }
  }, [open, selectedDistance]);

  const toggleGroup = () => {
    setIsOpen((prevState) => !prevState);
    setIsOpened && setIsOpened(4);
  };

  const handleReset = (e: any) => {
    setDistance(0);
    setRepeatDistance(0);
    setSelectedDistance(0);
    setIsSwitched(false);
    if (getValue) {
      getValue({
        distance: 0,
        repeat: 0,
        isRepeated: false,
      });
    }
    e.stopPropagation();
  };

  const handleTitle = () => {
    if (Number.isNaN(selectedDistance) || selectedDistance === 0 || isOpen) {
      return i18next.t("schedule.distancePreTitle");
    } else if (repeatDistance !== 0 && !isOpen && isSwitched) {
      return (
        i18next.t("schedule.next") +
        numberAnnotation(selectedDistance, 0) +
        " " +
        unit +
        ", " +
        i18next.t("schedule.then") +
        numberAnnotation(repeatDistance, 0) +
        " " +
        unit
      );
    } else {
      return (
        !isOpen &&
        i18next.t("schedule.next") +
          numberAnnotation(selectedDistance, 0) +
          " " +
          unit
      );
    }
  };
  function getNextExpiration(distance: number, isMetric: boolean) {
    if (distance) {
      if (isMetric) {
        return `${numberAnnotation(
          mtToKm(initialDistance, 2) + distance
        )} ${unit}`;
      } else {
        return `${numberAnnotation(
          kmToMiles(mtToKm(initialDistance, 2)) + distance
        )} ${unit}`;
      }
    } else {
      return "-" + unit;
    }
  }
  const unit = isMetric ? " km" : " mil";

  function convertNumber(num: string) {
    const { format } = new Intl.NumberFormat(i18next.language);
    const [, decimalSign]: any = /^0(.)1$/.exec(format(0.1));
    return +num
      .replace(new RegExp(`[^${decimalSign}\\d]`, "g"), "")
      .replace(decimalSign, ".");
  }

  return (
    <>
      <div
        className={isOpen ? "schedule-distance-simple" : "schedule-distance"}
        onClick={() => {
          if (!typeIsEmpty) {
            setSelectedDistance(distance);
            toggleGroup();
          }
        }}
      >
        <div className="schedule-distance-button">
          <div className="schedule-distance-circle">
            <IconDistance size={14} color="--global-colors-ui-primary" />
          </div>
          <div className="schedule-distance-label">
            {!Number.isNaN(distance) && distance !== 0 && !isOpen && (
              <>
                <span className="schedule-distance-pretitle">
                  {i18next.t("schedule.distancePreTitle")}
                </span>
                <br />
              </>
            )}
            <span className="schedule-distance-title">{handleTitle()}</span>
          </div>
          <span className="schedule-distance-icon">
            {isOpen ? (
              <>
                <div className="schedule-distance-reset" onClick={handleReset}>
                  {i18next.t("common.reset")}
                </div>
                <IconMinus size={14} color="--global-colors-ui-primary" />
              </>
            ) : (
              <IconAdd size={14} color="--global-colors-ui-primary" />
            )}
          </span>
        </div>
      </div>
      {isOpen && (
        <div
          className={
            initialDistance !== 0
              ? "schedule-distance-body-expanded"
              : "schedule-distance-body"
          }
        >
          {initialDistance !== 0 && (
            <>
              <span className="schedule-distance-labels">
                {unit + " " + i18next.t("schedule.distanceCurrent")}
              </span>
              <div className="schedule-distance-value">
                {isMetric
                  ? numberAnnotation(mtToKm(initialDistance, 2)) + unit
                  : numberAnnotation(kmToMiles(mtToKm(initialDistance, 2))) +
                    unit}
              </div>
            </>
          )}
          <span className="schedule-distance-labels">
            {i18next.t("schedule.distanceDeadline")}
          </span>
          <div className="schedule-distance-value">
            <Form>
              <TextField
                id="distance"
                type="text"
                size="small"
                maxLength="8"
                placeholder={i18next.t("schedule.distancePlaceholder")}
                formattedNumber={true}
                events={{
                  onChange: (data) => {
                    setSelectedDistance(convertNumber(data.value));
                    if (getValue) {
                      getValue({
                        distance: convertNumber(data.value),
                        repeat: repeatDistance,
                        isRepeated: isSwitched,
                      });
                    }
                  },
                }}
                value={numberAnnotation(distance)}
              ></TextField>
            </Form>
            <span className="schedule-distance-metric">{unit}</span>
          </div>
          {initialDistance !== 0 && (
            <>
              <span className="schedule-distance-labels">
                {i18next.t("schedule.distanceNotice")}
              </span>
              <div className="schedule-distance-value">
                {getNextExpiration(distance, isMetric ?? true)}
              </div>
            </>
          )}
          <div className="schedule-distance-left-column">
            <span className="schedule-distance-labels">
              {i18next.t("schedule.repeatTitle")}
            </span>
            <Switch
              label=""
              checked={isSwitched}
              onChange={(e) => {
                setIsSwitched(e.target.checked);
                if (getValue) {
                  if (e.target.checked) {
                    getValue({
                      distance: selectedDistance,
                      repeat: repeatDistance,
                      isRepeated: true,
                    });
                  } else {
                    getValue({
                      distance: selectedDistance,
                      repeat: 0,
                      isRepeated: false,
                    });
                  }
                }
              }}
            />
          </div>
          {isSwitched && (
            <div className="schedule-distance-right-column">
              <span className="schedule-distance-labels">
                {i18next.t("schedule.every")}
              </span>
              <div className="schedule-distance-value">
                <Form>
                  <TextField
                    id="repeatDistance"
                    type="text"
                    size="small"
                    maxLength="8"
                    placeholder={i18next.t("schedule.distancePlaceholder")}
                    formattedNumber={true}
                    events={{
                      onChange: (data) => {
                        setRepeatDistance(convertNumber(data.value));
                        if (getValue) {
                          getValue({
                            distance: selectedDistance,
                            repeat: convertNumber(data.value),
                            isRepeated: isSwitched,
                          });
                        }
                      },
                    }}
                    value={numberAnnotation(repeatDistance)}
                  ></TextField>
                </Form>
                <span className="schedule-distance-metric">{unit}</span>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

ScheduleDistance.propTypes = {
  initialDistance: PropTypes.number.isRequired,
  defaultDistance: PropTypes.any,
  open: PropTypes.bool,
  typeIsEmpty: PropTypes.bool,
  isMetric: PropTypes.bool,
  getValue: PropTypes.func,
  setIsOpened: PropTypes.func,
};

ScheduleDistance.defaultProps = {
  open: false,
};
