
import PropTypes from "prop-types";
import ReactApexChart from "react-apexcharts";
import "./TrackBarMarker.css";

export const TrackBarMarker = ({ color, data, textColor, icon, text, isClustered, onClick }) => {
    const PieOption = {
        colors: data && data.map(item => (item.color)),
        stroke: {
            width: 0,
        },
        dataLabels: {
            enabled: false,
        },
        plotOptions: {
            pie: {
                expandOnClick: false,
                donut: {
                    size: "85%"
                },
            },
        },
        legend: {
            show: false,
        },
        tooltip: {
            enabled: false,
        },
        states: {
            hover: {
                filter: {
                    type: 'none',
                }
            },
            active: {
                filter: {
                    type: 'none',
                }
            },
        },
    };

    return (
        <div onClick={onClick} className={`track-bar-marker${!isClustered ? "-small" : ""}`} style={{ backgroundColor: `var(${color})` }}>
            <div className={`track-bar-marker-inner${!isClustered ? "-small" : ""}`} style={{ color: `var(${textColor})`, fontSize: text.length > 2 ? "var(--global-font-size-xs)" : "var(--global-font-size-sm)" }}>
                {isClustered && data &&
                    <div className="cluster-pie">
                        <ReactApexChart
                            options={PieOption}
                            series={data.map(item => (item.percentage))}
                            type="donut"
                            width={66}
                            height={66}
                        />
                    </div>}
                {icon ? icon : text.length > 2 ? text.length > 3 ? "1k+" : "99+" : text}
            </div>
            <div className={`track-bar-marker-triangle${!isClustered ? "-small" : ""}`} />
        </div>
    );
};
TrackBarMarker.propTypes = {
    /**
     * data for pie chartof marker.
     */
    data: PropTypes.arrayOf(PropTypes.shape({ percentage: PropTypes.number, color: PropTypes.string })),
    /**
    * icon for marker.
    */
    icon: PropTypes.object,
    /**
     * Backgound color.
     */
    color: PropTypes.string,
    /**
    * text color.
    */
    textColor: PropTypes.string,
    /**
    * text for marker.
    */
    text: PropTypes.string,
    /**
    * text for marker.
    */
    isClustered: PropTypes.bool,
    /**
    * click for marker.
    */
    onClick: PropTypes.func,

};

TrackBarMarker.defaultProps = {
    color: '--global-colors-ui-primary',
};
