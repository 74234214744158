import i18next from "i18next";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { numberAnnotation } from "../../utils/Utils";
import Form from "../Forms/Form";
import { Switch } from "../Forms/Switch";
import TextField from "../Forms/TextField";
import { IconAdd } from "../Icon/Line/Add";
import { IconClock } from "../Icon/Line/Clock";
import { IconMinus } from "../Icon/Line/Minus";
import "./ScheduleWorkingHours.css";

interface DefaultHour {
  value: number;
  repeat: number;
}
interface ScheduleWorkingHoursProps {
  defaultHour?: DefaultHour;
  initialHours: number;
  open?: boolean;
  typeIsEmpty?: boolean;
  getValue?: (e: any) => any;
  setIsOpened?: (e: any) => any;
}

export const ScheduleWorkingHours: React.FC<ScheduleWorkingHoursProps> = ({
  defaultHour,
  open,
  typeIsEmpty,
  initialHours,
  getValue,
  setIsOpened,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(open ?? false);
  const [hour, setHour] = useState<number>(defaultHour?.value ?? 0);

  const [repeatHour, setRepeatHour] = useState<number>(
    defaultHour?.repeat ?? 0
  );
  const [selectedHour, setSelectedHour] = useState(defaultHour?.value ?? 0);

  const [isSwitched, setIsSwitched] = useState(!!defaultHour?.repeat ?? false);

  useEffect(() => {
    if (!typeIsEmpty) {
      setIsOpen(open ?? false);
      setHour(selectedHour);
    }
  }, [open, selectedHour]);

  const toggleGroup = () => {
    setIsOpen((prevState) => !prevState);
    setIsOpened && setIsOpened(6);
  };

  const handleReset = (e: any) => {
    setHour(0);
    setRepeatHour(0);
    setSelectedHour(0);
    setIsSwitched(false);
    if (getValue) {
      getValue({
        hour: 0,
        repeat: 0,
        isRepeated: false,
      });
    }
    e.stopPropagation();
  };

  const handleTitle = () => {
    if (Number.isNaN(selectedHour) || selectedHour === 0 || isOpen) {
      return i18next.t("schedule.hoursPreTitle");
    } else if (repeatHour !== 0 && !isOpen && isSwitched) {
      return (
        i18next.t("schedule.next") +
        numberAnnotation(selectedHour, 0) +
        " " +
        unit +
        ", " +
        i18next.t("schedule.then") +
        numberAnnotation(repeatHour, 0) +
        " " +
        unit
      );
    } else {
      return (
        !isOpen &&
        i18next.t("schedule.next") +
          numberAnnotation(selectedHour, 0) +
          " " +
          unit
      );
    }
  };
  function getNextExpiration(hour: number) {
    if (hour) {
      return `${numberAnnotation(initialHours + hour)} ${unit}`;
    } else {
      return "-" + unit;
    }
  }
  const unit = " h";

  function convertNumber(num: string) {
    const { format } = new Intl.NumberFormat(i18next.language);
    const [, decimalSign]: any = /^0(.)1$/.exec(format(0.1));
    return +num
      .replace(new RegExp(`[^${decimalSign}\\d]`, "g"), "")
      .replace(decimalSign, ".");
  }

  return (
    <>
      <div
        className={isOpen ? "schedule-working-simple" : "schedule-distance"}
        onClick={() => {
          if (!typeIsEmpty) {
            setSelectedHour(hour);
            toggleGroup();
          }
        }}
      >
        <div className="schedule-working-button">
          <div className="schedule-working-circle">
            <IconClock size={14} color="--global-colors-ui-primary" />
          </div>
          <div className="schedule-working-label">
            {!Number.isNaN(hour) && hour !== 0 && !isOpen && (
              <>
                <span className="schedule-working-pretitle">
                  {i18next.t("schedule.hoursPreTitle")}
                </span>
                <br />
              </>
            )}
            <span className="schedule-working-title">{handleTitle()}</span>
          </div>
          <span className="schedule-working-icon">
            {isOpen ? (
              <>
                <div className="schedule-working-reset" onClick={handleReset}>
                  {i18next.t("common.reset")}
                </div>
                <IconMinus size={14} color="--global-colors-ui-primary" />
              </>
            ) : (
              <IconAdd size={14} color="--global-colors-ui-primary" />
            )}
          </span>
        </div>
      </div>
      {isOpen && (
        <div
          className={
            initialHours !== 0
              ? "schedule-working-body-expanded"
              : "schedule-working-body"
          }
        >
          {initialHours !== 0 && (
            <>
              <span className="schedule-working-labels">
                {i18next.t("schedule.currentHours")}
              </span>
              <div className="schedule-working-value">
                {numberAnnotation(initialHours, 2) + unit}
              </div>
            </>
          )}
          <span className="schedule-working-labels">
            {i18next.t("schedule.distanceDeadline")}
          </span>
          <div className="schedule-working-value">
            <Form>
              <TextField
                id="distance"
                type="text"
                size="small"
                maxLength="7"
                placeholder={i18next.t("schedule.distancePlaceholder")}
                formattedNumber={true}
                events={{
                  onChange: (data) => {
                    setSelectedHour(convertNumber(data.value));
                    if (getValue) {
                      getValue({
                        hour: convertNumber(data.value),
                        repeat: repeatHour,
                        isRepeated: isSwitched,
                      });
                    }
                  },
                }}
                value={numberAnnotation(hour)}
              ></TextField>
            </Form>
            <span className="schedule-working-metric">{unit}</span>
          </div>
          {initialHours !== 0 && (
            <>
              <span className="schedule-working-labels">
                {i18next.t("schedule.distanceNotice")}
              </span>
              <div className="schedule-working-value">
                {getNextExpiration(hour)}
              </div>
            </>
          )}
          <div className="schedule-working-left-column">
            <span className="schedule-working-labels">
              {i18next.t("schedule.repeatTitle")}
            </span>
            <Switch
              label=""
              checked={isSwitched}
              onChange={(e) => {
                setIsSwitched(e.target.checked);
                if (getValue) {
                  if (e.target.checked) {
                    getValue({
                      hour: selectedHour,
                      repeat: repeatHour,
                      isRepeated: true,
                    });
                  } else {
                    getValue({
                      hour: selectedHour,
                      repeat: 0,
                      isRepeated: false,
                    });
                  }
                }
              }}
            />
          </div>
          {isSwitched && (
            <div className="schedule-working-right-column">
              <span className="schedule-working-labels">
                {i18next.t("schedule.every")}
              </span>
              <div className="schedule-working-value">
                <Form>
                  <TextField
                    id="repeatDistance"
                    type="text"
                    size="small"
                    maxLength="8"
                    placeholder={i18next.t("schedule.distancePlaceholder")}
                    formattedNumber={true}
                    events={{
                      onChange: (data) => {
                        setRepeatHour(convertNumber(data.value));
                        if (getValue) {
                          getValue({
                            hour: selectedHour,
                            repeat: convertNumber(data.value),
                            isRepeated: isSwitched,
                          });
                        }
                      },
                    }}
                    value={numberAnnotation(repeatHour)}
                  ></TextField>
                </Form>
                <span className="schedule-working-metric">{unit}</span>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

ScheduleWorkingHours.propTypes = {
  initialHours: PropTypes.number.isRequired,
  defaultHour: PropTypes.any,
  open: PropTypes.bool,
  typeIsEmpty: PropTypes.bool,
  getValue: PropTypes.func,
  setIsOpened: PropTypes.func,
};

ScheduleWorkingHours.defaultProps = {
  open: false,
};
