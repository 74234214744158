import React from "react";
import PropTypes from "prop-types";
import "../IconFont/icons.css";

export const IconLoader = ({ size, color, ...props }) => (
  <div className="svg" onClick={props.onClick}>
    <span
      style={
        color
          ? { fontSize: `${size}px`, color: `${color}` }
          : { fontSize: `${size}px` }
      }
    >
      &#xe83c;
    </span>
  </div>
);

IconLoader.propTypes = {
  /**
   * Size of the icon.
   */
  size: PropTypes.number,
  /**
   * Color of the icon.
   */
  color: PropTypes.string,
};

IconLoader.defaultProps = {
  color: "#0052BD",
  size: 24,
};
