import { useEffect, useState } from "react";

import { IconGeofenceOval } from "../Icon/Line/GeofenceOval";
import { IconGeofenceLine } from "../Icon/Line/GeofenceLine";

import "./GeofenceButton.css";

export const DrawGeofence = ({ clickOval, clickLine, hasDrawedShape }) => {
  const [isFocused, setIsFocused] = useState(0);

  useEffect(() => {
    setIsFocused(!hasDrawedShape.value ? 2 : 0);
  }, [hasDrawedShape.value]);

  const className = (valSeleted) => {
    if (isFocused === valSeleted) {
      return "draw-geofence-focused";
    }
    return "draw-geofence-button";
  };

  return (
    <div className="draw-geofence-container">
      <div
        className={className(2)}
        onClick={() => {
          if (!hasDrawedShape.value) {
            setIsFocused(2);
          }
          clickLine();
        }}
      >
        <IconGeofenceLine
          size={14}
          color={isFocused === 2 ? "--global-colors-ui-white" : ""}
        />
      </div>
      <div
        className={className(1)}
        onClick={() => {
          if (!hasDrawedShape.value) {
            setIsFocused(1);
          }
          clickOval();
        }}
      >
        <IconGeofenceOval
          size={14}
          color={isFocused === 1 ? "--global-colors-ui-white" : ""}
        />
      </div>
    </div>
  );
};
