import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { IconSearch } from "../Icon/Line/Search";
import { Checkbox } from "../Forms/Checkbox";
import "./GeofenceActionMenu.css";
import { useTranslation } from "react-i18next";

export const GeofenceActionMenu = ({
  options,
  setGeofence,
  enabledTracking,
  ...props
}) => {
  const { t } = useTranslation();
  const [query, setQuery] = useState("");
  const [geofenceCheck, setGeofenceCheck] = useState([]);

  useEffect(() => {
    options.map((option, index) => {
      option.index = index;
      if (!option.checked) {
        option.checked = false;
      }
      return option;
    });
  }, [options]);

  useEffect(() => {
    if (enabledTracking) {
      options.map((option) => {
        option.checked = false;
        return option;
      });
      setGeofenceCheck([]);
    }
  }, [enabledTracking]);

  function filter(items) {
    return (
      items &&
      items.filter(
        (item) => item["label"].toLowerCase().indexOf(query.toLowerCase()) > -1
      )
    );
  }

  return (
    <>
      {
        <div className="mn-menu-geofence-action" {...props}>
          <div className="mn-menu-geofence-title">
            {t("map.actions.geofenceActionMenu.title")}
          </div>
          <div className="mn-menu-geofence-search">
            <input
              placeholder={t(
                "map.actions.geofenceActionMenu.searchPlaceholder"
              )}
              type="text"
              value={query}
              onChange={(e) => {
                setQuery(e.target.value);
              }}
            />
            <IconSearch size={14} color="--global-colors-ink-light" />
          </div>
          <div className="mn-menu-geofence-options">
            {filter(options).map((option, index) => {
              return (
                <React.Fragment key={index}>
                  <div className="mn-menu-geofence-option">
                    {option.color && (
                      <div
                        className="color"
                        style={{
                          backgroundColor: option.color,
                        }}
                      />
                    )}
                    {option.label}
                    <Checkbox
                      label=""
                      checked={option.checked}
                      onChange={() => {
                        if (option.checked) {
                          option.checked = false;
                          let checkedOptions = geofenceCheck.filter(function (
                            element
                          ) {
                            return element !== option;
                          });
                          setGeofenceCheck(checkedOptions);
                        } else {
                          option.checked = true;
                          setGeofenceCheck((prev) => [...prev, option]);
                        }
                        setGeofence(options);
                      }}
                    />
                  </div>
                </React.Fragment>
              );
            })}
          </div>
        </div>
      }
    </>
  );
};

GeofenceActionMenu.defaultProps = {
  label: "name",
};

GeofenceActionMenu.propTypes = {
  options: PropTypes.array.isRequired,
};
