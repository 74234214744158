import { schema } from "normalizr";
import { fleetSchema } from "../fleet/fleetNormalization";
import { tenantSchema } from "../tenants/tenantNormalization";
import { vehicleSchema } from "../vehicle/vehicleNormalization";

export const driverSchema: schema.Entity<any> = new schema.Entity("driver", {
  tenant: tenantSchema,
  fleet: fleetSchema,
  vehicle: vehicleSchema,
});

export const driverStatusSchema: schema.Entity<any> = new schema.Entity(
  "driverStatus",
  {},
  { idAttribute: "id" }
);

export const driverStatusAndDetailsSchema: schema.Entity<any> =
  new schema.Entity(
    "driverStatusAndDetails",
    {
      driverDetails: driverSchema,
      driverStatus: driverStatusSchema,
    },
    { idAttribute: "id" }
  );

export const driversStatusAndDetailsSchema = new schema.Array(
  driverStatusAndDetailsSchema
);
export const driversSchema = new schema.Array(driverSchema);
export const driversStatusSchema = new schema.Array(driverStatusSchema);
