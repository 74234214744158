import HttpClient from "../../utils/HttpClient";
export default class NotificationsCategoryRepository extends HttpClient {
  constructor() {
    super(true);
  }

  getNotifications(categoryId: number) {
    const tenantId = localStorage.getItem("tenantId");
    let path = "tenants/" + tenantId + "/notifications/" + categoryId;
    return this.instance.get(path);
  }

  getNotificationCategory(customQueryParams?: string) {
    let path = "notificationCategories";
    let queryParams = "";
    if (!!customQueryParams) {
      queryParams = customQueryParams + "&";
    } else {
      queryParams = "?";
    }
    return this.instance.get(path + (queryParams ?? ""));
  }
}
