import React from 'react'

import './ButtonGroup.css'

export const ButtonGroup = ({ className, ...props }) => {

	const classes = [className, 'btn-group'].join(' ')

	return (
		<div {...props} className={classes} />
	)
}