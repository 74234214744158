import propTypes from "prop-types";

export const PoweredBy = ({ isNegative }) => {
  if (isNegative) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="116"
        height="15"
        viewBox="0 0 116 15"
      >
        <defs>
          <path
            id="path-1"
            d="M0 0L10.5791993 0 10.5791993 3.29490778 0 3.29490778z"
          ></path>
          <path id="path-3" d="M0 0L3.6244 0 3.6244 12.314 0 12.314z"></path>
          <path id="path-5" d="M0 0L5.166 0 5.166 6.9939 0 6.9939z"></path>
        </defs>
        <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
          <g transform="translate(-38 -100)">
            <g transform="translate(38 100.6)">
              <path
                fill="#DF643A"
                d="M80.021 5.852c.54 0 .968.18 1.283.495.068.068.203.068.27 0l1.103-1.305c.068-.068.045-.203-.022-.248-.676-.54-1.576-.833-2.61-.833-1.06 0-1.96.293-2.635.833-.068.068-.09.18-.023.248l1.103 1.305c.068.09.18.068.27 0 .293-.315.721-.495 1.261-.495"
              ></path>
              <g transform="translate(74.705)">
                <mask id="mask-2" fill="#fff">
                  <use xlinkHref="#path-1"></use>
                </mask>
                <path
                  fill="#DF643A"
                  d="M5.316 0C3.29 0 1.489.608.07 1.688a.161.161 0 00-.023.248l1.058 1.283c.068.068.158.09.248.022 1.08-.788 2.43-1.216 3.962-1.216 1.486 0 2.836.45 3.917 1.239.067.045.18.045.248-.022l1.058-1.262c.067-.067.045-.202-.023-.248C9.098.607 7.296 0 5.316 0"
                  mask="url(#mask-2)"
                ></path>
              </g>
              <path
                fill="#DF643A"
                d="M81.935 10.31c0 1.058-.585 1.576-1.913 1.576-1.036 0-1.6-.383-1.6-1.148 0-.856.609-1.238 2.005-1.238h1.215c.158 0 .293.135.293.292v.518zm1.89-3.827a3.401 3.401 0 00-.27-.653c-.067-.112-.202-.135-.292-.022l-1.351 1.598c-.045.045-.045.09-.045.135 0 .023 0 .045.023.09.022.113-.068.203-.18.203h-1.621c-2.296 0-3.714.923-3.714 2.949 0 1.936 1.418 2.836 3.106 2.836 1.306 0 1.98-.45 2.43-1.08h.024v.495c0 .202.158.36.36.36h1.44c.203 0 .36-.158.36-.36V8.757c.024-1.013-.066-1.621-.27-2.274z"
              ></path>
              <path
                fill="#013878"
                d="M72.322 13.439c-.113 0-.203-.09-.203-.225V7.879c0-1.193-.585-2.026-1.755-2.026-1.126 0-1.779.833-1.779 2.026v5.335c0 .112-.067.202-.202.202h-1.779c-.112 0-.202-.067-.202-.202V7.879c0-1.193-.586-2.026-1.756-2.026-1.126 0-1.779.833-1.779 2.026v5.335c0 .112-.067.202-.202.202h-1.779c-.112 0-.202-.067-.202-.202v-8.87c0-.112.067-.202.202-.202h1.78c.111 0 .201.067.201.203v.675h.023c.405-.585 1.215-1.081 2.431-1.081 1.103 0 1.981.45 2.544 1.238h.022c.721-.765 1.644-1.238 2.95-1.238 2.16 0 3.353 1.464 3.353 3.445v5.852c0 .113-.067.203-.202.203h-1.666z"
              ></path>
              <path
                fill="#013878"
                d="M86.73 11.075c-.204-.585-.294-1.306-.294-2.296 0-.991.09-1.711.293-2.296.54-1.644 1.958-2.567 3.827-2.567 1.373 0 2.476.563 3.22 1.441a.184.184 0 01-.024.27l-1.215 1.058c-.09.068-.203.068-.27-.045-.473-.517-.946-.81-1.71-.81-.834 0-1.464.405-1.734 1.215-.158.451-.203.991-.203 1.711 0 .721.045 1.261.203 1.734.27.788.9 1.193 1.733 1.193.765 0 1.261-.293 1.711-.811.067-.09.18-.09.27-.022l1.215 1.058c.091.067.068.18.023.27-.72.855-1.823 1.418-3.219 1.418-1.869.023-3.287-.878-3.827-2.521"
              ></path>
              <path
                fill="#013878"
                d="M101.992 13.439c-.113 0-.203-.09-.203-.225V7.879c0-1.193-.585-2.026-1.755-2.026-1.126 0-1.78.833-1.78 2.026v5.335c0 .112-.066.202-.201.202h-1.78c-.111 0-.201-.067-.201-.202v-8.87c0-.112.067-.202.202-.202h1.779c.112 0 .202.067.202.203v.675h.023c.405-.585 1.215-1.081 2.43-1.081 1.982 0 3.22 1.464 3.22 3.445v5.852c0 .113-.068.203-.203.203h-1.733z"
              ></path>
              <path
                fill="#013878"
                d="M106.9 13.416c-.114 0-.204-.068-.204-.203V4.344c0-.113.068-.203.203-.203h1.778c.113 0 .203.068.203.203v8.87c0 .112-.068.202-.203.202H106.9z"
              ></path>
              <g transform="translate(111.671 1.26)">
                <mask id="mask-4" fill="#fff">
                  <use xlinkHref="#path-3"></use>
                </mask>
                <path
                  fill="#013878"
                  d="M2.544 12.314C.743 12.314 0 11.414 0 9.613V.203C0 .091.068 0 .203 0h1.778c.113 0 .203.068.203.203v9.32c0 .63.247.855.81.855h.428c.112 0 .203.068.203.203v1.53c0 .113-.068.203-.203.203h-.878z"
                  mask="url(#mask-4)"
                ></path>
              </g>
              <path
                fill="#013878"
                d="M106.9 3.421c-.114 0-.204-.068-.204-.203V1.553c0-.113.068-.203.203-.203h1.778c.113 0 .203.068.203.203v1.665c0 .113-.068.203-.203.203H106.9z"
              ></path>
              <path
                fill="#013878"
                d="M3.664 9.563c0-.94-.437-1.511-1.158-1.511-.721 0-1.167.576-1.167 1.516 0 .939.446 1.51 1.163 1.51.73 0 1.162-.566 1.162-1.515m1.39 0c0 1.655-.786 2.646-2.069 2.646-.735 0-1.297-.321-1.548-.865h-.084v2.488H0V7h1.311v.842h.084c.279-.577.86-.926 1.567-.926 1.297 0 2.092.986 2.092 2.646"
              ></path>
              <path
                fill="#013878"
                d="M9.565 9.563c0-.986-.42-1.567-1.135-1.567-.72 0-1.134.58-1.134 1.567 0 1 .409 1.572 1.134 1.572.72 0 1.135-.577 1.135-1.572m-3.66 0c0-1.66.963-2.674 2.525-2.674 1.563 0 2.525 1.009 2.525 2.674 0 1.679-.948 2.678-2.525 2.678-1.576 0-2.525-1-2.525-2.678"
              ></path>
              <path
                fill="#013878"
                d="M17.5907 12.1294L16.1497 12.1294 15.2327 8.5904 15.1497 8.5904 14.2427 12.1294 12.8197 12.1294 11.4527 7.0004 12.8247 7.0004 13.5867 10.6784 13.6707 10.6784 14.5687 7.0004 15.8607 7.0004 16.7627 10.6784 16.8467 10.6784 17.6097 7.0004 18.9527 7.0004z"
              ></path>
              <path
                fill="#013878"
                d="M20.808 9.028h2.185c-.027-.68-.437-1.102-1.069-1.102-.628 0-1.069.437-1.116 1.102zm3.474 1.64c-.2.964-1.089 1.573-2.344 1.573-1.562 0-2.483-.986-2.483-2.65 0-1.67.94-2.702 2.464-2.702 1.512 0 2.404.963 2.404 2.59v.414h-3.52v.07c.02.762.465 1.237 1.172 1.237.535 0 .902-.191 1.046-.531h1.261z"
              ></path>
              <path
                fill="#013878"
                d="M25.43 7h1.311v.818h.085c.134-.51.665-.902 1.316-.902.186 0 .413.024.53.065v1.233c-.108-.042-.424-.084-.643-.084-.738 0-1.245.437-1.245 1.158v2.841H25.43V7z"
              ></path>
              <path
                fill="#013878"
                d="M30.56 9.028h2.184c-.027-.68-.437-1.102-1.069-1.102-.628 0-1.07.437-1.116 1.102zm3.473 1.64c-.2.964-1.089 1.573-2.344 1.573-1.562 0-2.483-.986-2.483-2.65 0-1.67.94-2.702 2.464-2.702 1.512 0 2.404.963 2.404 2.59v.414h-3.52v.07c.02.762.465 1.237 1.172 1.237.535 0 .902-.191 1.046-.531h1.261z"
              ></path>
              <path
                fill="#013878"
                d="M36.32 9.563c0 .949.433 1.516 1.159 1.516.725 0 1.162-.572 1.162-1.511 0-.935-.441-1.516-1.162-1.516-.721 0-1.158.572-1.158 1.51m-1.39-.004c0-1.632.799-2.641 2.087-2.641.693 0 1.265.335 1.53.902h.079V5.043h1.354v7.087h-1.312v-.805h-.083c-.275.554-.856.884-1.568.884-1.292 0-2.087-1.014-2.087-2.651"
              ></path>
              <path
                fill="#013878"
                d="M45.225 9.567c.005.93.451 1.511 1.167 1.511.721 0 1.158-.576 1.158-1.516 0-.934-.441-1.51-1.157-1.51-.712 0-1.163.58-1.168 1.515m.056 1.758h-.084v.804h-1.31V5.043h1.352V7.82h.085c.26-.563.837-.902 1.538-.902 1.297 0 2.078.99 2.078 2.645 0 1.646-.79 2.646-2.078 2.646-.726 0-1.306-.325-1.58-.883"
              ></path>
              <g transform="translate(49.416 7)">
                <mask id="mask-6" fill="#fff">
                  <use xlinkHref="#path-5"></use>
                </mask>
                <path
                  fill="#013878"
                  d="M.539 6.98V5.892c.046.009.251.009.307.009.507 0 .795-.163.907-.553l.046-.186L0 0h1.492l1.079 3.888h.084L3.734 0h1.432L3.399 5.208c-.437 1.321-1.065 1.786-2.344 1.786-.051 0-.46-.005-.516-.014"
                  mask="url(#mask-6)"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </svg>
    );
  } else {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="116"
        height="15"
        viewBox="0 0 116 15"
      >
        <defs>
          <path
            id="path-1"
            d="M0 0L10.5791993 0 10.5791993 3.29490778 0 3.29490778z"
          ></path>
          <path id="path-3" d="M0 0L3.6244 0 3.6244 12.314 0 12.314z"></path>
          <path id="path-5" d="M0 0L5.166 0 5.166 6.9939 0 6.9939z"></path>
        </defs>
        <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
          <g transform="translate(-38 -100)">
            <g transform="translate(38 100.6)">
              <path
                fill="#DF643A"
                d="M80.021 5.852c.54 0 .968.18 1.283.495.068.068.203.068.27 0l1.103-1.305c.068-.068.045-.203-.022-.248-.676-.54-1.576-.833-2.61-.833-1.06 0-1.96.293-2.635.833-.068.068-.09.18-.023.248l1.103 1.305c.068.09.18.068.27 0 .293-.315.721-.495 1.261-.495"
              ></path>
              <g transform="translate(74.705)">
                <mask id="mask-2" fill="#fff">
                  <use xlinkHref="#path-1"></use>
                </mask>
                <path
                  fill="#DF643A"
                  d="M5.316 0C3.29 0 1.489.608.07 1.688a.161.161 0 00-.023.248l1.058 1.283c.068.068.158.09.248.022 1.08-.788 2.43-1.216 3.962-1.216 1.486 0 2.836.45 3.917 1.239.067.045.18.045.248-.022l1.058-1.262c.067-.067.045-.202-.023-.248C9.098.607 7.296 0 5.316 0"
                  mask="url(#mask-2)"
                ></path>
              </g>
              <path
                fill="#DF643A"
                d="M81.935 10.31c0 1.058-.585 1.576-1.913 1.576-1.036 0-1.6-.383-1.6-1.148 0-.856.609-1.238 2.005-1.238h1.215c.158 0 .293.135.293.292v.518zm1.89-3.827a3.401 3.401 0 00-.27-.653c-.067-.112-.202-.135-.292-.022l-1.351 1.598c-.045.045-.045.09-.045.135 0 .023 0 .045.023.09.022.113-.068.203-.18.203h-1.621c-2.296 0-3.714.923-3.714 2.949 0 1.936 1.418 2.836 3.106 2.836 1.306 0 1.98-.45 2.43-1.08h.024v.495c0 .202.158.36.36.36h1.44c.203 0 .36-.158.36-.36V8.757c.024-1.013-.066-1.621-.27-2.274z"
              ></path>
              <path
                fill="#FFF"
                d="M72.322 13.439c-.113 0-.203-.09-.203-.225V7.879c0-1.193-.585-2.026-1.755-2.026-1.126 0-1.779.833-1.779 2.026v5.335c0 .112-.067.202-.202.202h-1.779c-.112 0-.202-.067-.202-.202V7.879c0-1.193-.586-2.026-1.756-2.026-1.126 0-1.779.833-1.779 2.026v5.335c0 .112-.067.202-.202.202h-1.779c-.112 0-.202-.067-.202-.202v-8.87c0-.112.067-.202.202-.202h1.78c.111 0 .201.067.201.203v.675h.023c.405-.585 1.215-1.081 2.431-1.081 1.103 0 1.981.45 2.544 1.238h.022c.721-.765 1.644-1.238 2.95-1.238 2.16 0 3.353 1.464 3.353 3.445v5.852c0 .113-.067.203-.202.203h-1.666z"
              ></path>
              <path
                fill="#FFF"
                d="M86.73 11.075c-.204-.585-.294-1.306-.294-2.296 0-.991.09-1.711.293-2.296.54-1.644 1.958-2.567 3.827-2.567 1.373 0 2.476.563 3.22 1.441a.184.184 0 01-.024.27l-1.215 1.058c-.09.068-.203.068-.27-.045-.473-.517-.946-.81-1.71-.81-.834 0-1.464.405-1.734 1.215-.158.451-.203.991-.203 1.711 0 .721.045 1.261.203 1.734.27.788.9 1.193 1.733 1.193.765 0 1.261-.293 1.711-.811.067-.09.18-.09.27-.022l1.215 1.058c.091.067.068.18.023.27-.72.855-1.823 1.418-3.219 1.418-1.869.023-3.287-.878-3.827-2.521"
              ></path>
              <path
                fill="#FFF"
                d="M101.992 13.439c-.113 0-.203-.09-.203-.225V7.879c0-1.193-.585-2.026-1.755-2.026-1.126 0-1.78.833-1.78 2.026v5.335c0 .112-.066.202-.201.202h-1.78c-.111 0-.201-.067-.201-.202v-8.87c0-.112.067-.202.202-.202h1.779c.112 0 .202.067.202.203v.675h.023c.405-.585 1.215-1.081 2.43-1.081 1.982 0 3.22 1.464 3.22 3.445v5.852c0 .113-.068.203-.203.203h-1.733z"
              ></path>
              <path
                fill="#FFF"
                d="M106.9 13.416c-.114 0-.204-.068-.204-.203V4.344c0-.113.068-.203.203-.203h1.778c.113 0 .203.068.203.203v8.87c0 .112-.068.202-.203.202H106.9z"
              ></path>
              <g transform="translate(111.671 1.26)">
                <mask id="mask-4" fill="#fff">
                  <use xlinkHref="#path-3"></use>
                </mask>
                <path
                  fill="#FFF"
                  d="M2.544 12.314C.743 12.314 0 11.414 0 9.613V.203C0 .091.068 0 .203 0h1.778c.113 0 .203.068.203.203v9.32c0 .63.247.855.81.855h.428c.112 0 .203.068.203.203v1.53c0 .113-.068.203-.203.203h-.878z"
                  mask="url(#mask-4)"
                ></path>
              </g>
              <path
                fill="#FFF"
                d="M106.9 3.421c-.114 0-.204-.068-.204-.203V1.553c0-.113.068-.203.203-.203h1.778c.113 0 .203.068.203.203v1.665c0 .113-.068.203-.203.203H106.9z"
              ></path>
              <path
                fill="#FFF"
                d="M3.664 9.563c0-.94-.437-1.511-1.158-1.511-.721 0-1.167.576-1.167 1.516 0 .939.446 1.51 1.163 1.51.73 0 1.162-.566 1.162-1.515m1.39 0c0 1.655-.786 2.646-2.069 2.646-.735 0-1.297-.321-1.548-.865h-.084v2.488H0V7h1.311v.842h.084c.279-.577.86-.926 1.567-.926 1.297 0 2.092.986 2.092 2.646"
              ></path>
              <path
                fill="#FFF"
                d="M9.565 9.563c0-.986-.42-1.567-1.135-1.567-.72 0-1.134.58-1.134 1.567 0 1 .409 1.572 1.134 1.572.72 0 1.135-.577 1.135-1.572m-3.66 0c0-1.66.963-2.674 2.525-2.674 1.563 0 2.525 1.009 2.525 2.674 0 1.679-.948 2.678-2.525 2.678-1.576 0-2.525-1-2.525-2.678"
              ></path>
              <path
                fill="#FFF"
                d="M17.5907 12.1294L16.1497 12.1294 15.2327 8.5904 15.1497 8.5904 14.2427 12.1294 12.8197 12.1294 11.4527 7.0004 12.8247 7.0004 13.5867 10.6784 13.6707 10.6784 14.5687 7.0004 15.8607 7.0004 16.7627 10.6784 16.8467 10.6784 17.6097 7.0004 18.9527 7.0004z"
              ></path>
              <path
                fill="#FFF"
                d="M20.808 9.028h2.185c-.027-.68-.437-1.102-1.069-1.102-.628 0-1.069.437-1.116 1.102zm3.474 1.64c-.2.964-1.089 1.573-2.344 1.573-1.562 0-2.483-.986-2.483-2.65 0-1.67.94-2.702 2.464-2.702 1.512 0 2.404.963 2.404 2.59v.414h-3.52v.07c.02.762.465 1.237 1.172 1.237.535 0 .902-.191 1.046-.531h1.261z"
              ></path>
              <path
                fill="#FFF"
                d="M25.43 7h1.311v.818h.085c.134-.51.665-.902 1.316-.902.186 0 .413.024.53.065v1.233c-.108-.042-.424-.084-.643-.084-.738 0-1.245.437-1.245 1.158v2.841H25.43V7z"
              ></path>
              <path
                fill="#FFF"
                d="M30.56 9.028h2.184c-.027-.68-.437-1.102-1.069-1.102-.628 0-1.07.437-1.116 1.102zm3.473 1.64c-.2.964-1.089 1.573-2.344 1.573-1.562 0-2.483-.986-2.483-2.65 0-1.67.94-2.702 2.464-2.702 1.512 0 2.404.963 2.404 2.59v.414h-3.52v.07c.02.762.465 1.237 1.172 1.237.535 0 .902-.191 1.046-.531h1.261z"
              ></path>
              <path
                fill="#FFF"
                d="M36.32 9.563c0 .949.433 1.516 1.159 1.516.725 0 1.162-.572 1.162-1.511 0-.935-.441-1.516-1.162-1.516-.721 0-1.158.572-1.158 1.51m-1.39-.004c0-1.632.799-2.641 2.087-2.641.693 0 1.265.335 1.53.902h.079V5.043h1.354v7.087h-1.312v-.805h-.083c-.275.554-.856.884-1.568.884-1.292 0-2.087-1.014-2.087-2.651"
              ></path>
              <path
                fill="#FFF"
                d="M45.225 9.567c.005.93.451 1.511 1.167 1.511.721 0 1.158-.576 1.158-1.516 0-.934-.441-1.51-1.157-1.51-.712 0-1.163.58-1.168 1.515m.056 1.758h-.084v.804h-1.31V5.043h1.352V7.82h.085c.26-.563.837-.902 1.538-.902 1.297 0 2.078.99 2.078 2.645 0 1.646-.79 2.646-2.078 2.646-.726 0-1.306-.325-1.58-.883"
              ></path>
              <g transform="translate(49.416 7)">
                <mask id="mask-6" fill="#fff">
                  <use xlinkHref="#path-5"></use>
                </mask>
                <path
                  fill="#FFF"
                  d="M.539 6.98V5.892c.046.009.251.009.307.009.507 0 .795-.163.907-.553l.046-.186L0 0h1.492l1.079 3.888h.084L3.734 0h1.432L3.399 5.208c-.437 1.321-1.065 1.786-2.344 1.786-.051 0-.46-.005-.516-.014"
                  mask="url(#mask-6)"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </svg>
    );
  }
};

PoweredBy.propTypes = {
  isNegative: propTypes.bool,
};
