import dayjs from "dayjs";
import { t } from "i18next";
import _ from "lodash";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Location,
  useLocation,
  useMatch,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { TypeOptions } from "react-toastify";
import { useAppSelector } from "../../../app/hooks";
import { store } from "../../../app/store";
import { Button } from "../../../ui/Button/Button";
import { DropdownButton } from "../../../ui/Button/DropdownButton";
import { DatePickerDropdown } from "../../../ui/Forms/DatePickerDropdown";
import Form from "../../../ui/Forms/Form";
import SearchField from "../../../ui/Forms/SearchField";
import { IconCalendar } from "../../../ui/Icon/Line/Calendar";
import { IconDown } from "../../../ui/Icon/Line/Down";
import { IconDownload } from "../../../ui/Icon/Line/Download";
import { IconLocatorOff } from "../../../ui/Icon/Line/LocatorOff";
import { CustomizeColumnsModal } from "../../../ui/Modal/CustomModals/CustomizeColumnsModal";
import { ToastNotification } from "../../../utils/ToastNotification";
import { getPresetsNotification, getQueryString } from "../../../utils/Utils";
import {
  fleetViewsSelectors,
  getFleetViewsAsync,
} from "../../fleet/fleetViewsSlice";
import { Preferences } from "../../users/preference/preferencesSlice";
import {
  Preset,
  addPreset,
  deletePresetAsync,
  getPresetsAsync,
  newPresetAsync,
  removePreset,
  restoreState,
  selectpresetsSliceReasonCode,
  selectpresetsSliceStatus,
  updatePresetAsync,
} from "../../users/preset/presetsSlice";
import UserContext from "../../users/userContext";
import {
  Vehicle,
  getFilteredVehiclesDetailsAsync,
  selectVehiclesSliceStatus,
  vehiclesEmptyState,
  vehiclesSelectors,
} from "../../vehicle/vehiclesSlice";
import ReportsRepository from "../reportRepository";
import "./RefuellingReportFilterBar.css";
import {
  getRefuellingChartDataReportAsync,
  refuellingChartDataReportEmptyState,
  selectRefuellingChartDataReportSliceReasonCode,
  selectRefuellingChartDataReportSliceStatus,
} from "./refuellingChartDataReportSlice";
import {
  getRefuellingReportAsync,
  refuellingReportEmptyState,
  selectRefuellingReportSliceStatus,
} from "./refuellingReportSlice";

import { GTFleetErrorCodes } from "../../../config/GTfleetErrorCodes";
import {
  selecteventsSliceReasonCode,
  selecteventsSliceStatus,
} from "../../event/eventsSlice";
import {
  getRefuellingReportSummaryAsync,
  refuellingReportSummaryEmptyState,
  selectRefuellingReportSummarySliceReasonCode,
  selectRefuellingReportSummarySliceStatus,
} from "./refuellingReportSummarySlice";

interface QueryParams {
  [paramName: string]: any;
}

interface RefuellingReportFilterBarProps {
  callback: (vehicleId: number, buildQueryParam: string) => any;
  presets: Preset[];
  disableButton?: boolean;
}

interface TableColumnEntry {
  id: number;
  name: string;
  value: string;
}

let context: string = "refuellingReport";
const reportsRepository = new ReportsRepository();

export const RefuellingReportFilterBar: React.FC<RefuellingReportFilterBarProps> =
  ({ callback, disableButton, presets }) => {
    const navigate = useNavigate();
    const [justReloaded, setJustReloaded] = useState(false);
    const vehicles: Vehicle[] = useAppSelector(vehiclesSelectors.selectAll);
    const [oldVehicleId, setOldVehicleId] = useState<number>();

    const vehicleSliceStatus = useAppSelector(selectVehiclesSliceStatus);
    const queryParamsRef = useRef<QueryParams>({});
    let queryParams: QueryParams = queryParamsRef.current;
    const [oldQueryParams, setOldQueryParams] = useState<string>();
    const [isPdfGenerated, setIsPdfGenerated] = useState<boolean>(true);
    const [isExcelGenerated, setIsExcelGenerated] = useState<boolean>(true);

    const refuelReportsSliceStatus = useAppSelector(
      selectRefuellingReportSliceStatus
    );
    const refuelReportsSliceReasonCode = useAppSelector(
      selectpresetsSliceReasonCode
    );
    const reportsSummarySliceStatus = useAppSelector(
      selectRefuellingReportSummarySliceStatus
    );
    const reportsSummarySliceReasonCode = useAppSelector(
      selectRefuellingReportSummarySliceReasonCode
    );
    const reportsChartDataSliceStatus = useAppSelector(
      selectRefuellingChartDataReportSliceStatus
    );
    const reportsChartDataSliceReasonCode = useAppSelector(
      selectRefuellingChartDataReportSliceReasonCode
    );
    const eventSliceStatus = useAppSelector(selecteventsSliceStatus);
    const eventSliceReasonCode = useAppSelector(selecteventsSliceReasonCode);
    const isReportsFailed =
      refuelReportsSliceStatus === "failed" ||
      reportsSummarySliceStatus === "failed" ||
      reportsChartDataSliceStatus === "failed";

    const isReportsIdle =
      refuelReportsSliceStatus === "idle" ||
      reportsSummarySliceStatus === "idle" ||
      reportsChartDataSliceStatus === "idle";
    const [isSelectedPeriod, setIsSelectedPeriod] = useState(false);
    const [initialDateValue, setInitialDateValue] = useState<Date[]>([]);

    const [queryString, setQueryString] = useState("");
    const [vehicleId, setVehicleId] = useState(-1);
    const [newPreset, setNewPreset] = useState<boolean>(true);

    const [alias, setAlias] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const searchbarRef = useRef<HTMLHeadingElement>(null);
    const parentRef = useRef<HTMLHeadingElement>(null);
    let defaultColumns: Preset = {} as Preset;
    let customColumns: TableColumnEntry[] = [];

    useEffect(() => {
      store.dispatch(getPresetsAsync(getQueryString({ context: context })));
      store.dispatch(getFleetViewsAsync());
      store.dispatch(getFilteredVehiclesDetailsAsync(""));

      return () => {
        store.dispatch(refuellingReportEmptyState());
        store.dispatch(refuellingChartDataReportEmptyState());
        store.dispatch(refuellingReportSummaryEmptyState());
        store.dispatch(vehiclesEmptyState());
      };
    }, []);

    //#region " get columns of table "
    const [preferencesContext]: [Preferences] = useContext(UserContext);
    if (!!presets && presets.length > 0) {
      defaultColumns = presets.filter((x) => x.name === "Default")[0];
      if (!!defaultColumns) {
        defaultColumns?.columns.forEach((column, index) =>
          customColumns.push({
            id: index,
            name: t("table.columns." + column),
            value: column,
          })
        );
      }
    }
    //#endregion

    //#region " get group (fleet) of vehicles "
    let vehiclesGroupByFleet = vehicles.reduce(
      (group: any, vehicle: Vehicle) => {
        const { fleet } = vehicle;
        const fleetName = fleetViewsSelectors.selectById(
          store.getState(),
          fleet
        )?.name;
        if (fleetName) {
          group[fleetName] = group[fleetName] ?? [];
          group[fleetName].push(vehicle);
        }
        return group;
      },
      {}
    );
    let fleetNames = Object.keys(vehiclesGroupByFleet);
    //#endregion

    const presetsSliceStatus = useAppSelector(selectpresetsSliceStatus);
    const presetsSliceReasonCode = useAppSelector(selectpresetsSliceReasonCode);

    useEffect(() => {
      let presetNotification: {
        status: string | undefined;
        description: string | undefined;
      } = getPresetsNotification(presetsSliceStatus, presetsSliceReasonCode);
      if (
        presetNotification.status !== undefined &&
        presetNotification.description !== undefined
      ) {
        ToastNotification({
          toastId: t(presetNotification.description),
          status: presetNotification.status as TypeOptions,
          description: t(presetNotification.description),
        });
        store.dispatch(restoreState());
      }
    }, [presetsSliceStatus, presetsSliceReasonCode]);

    /**
     * This useEffect takes the vehicles identifiers retrieved from the URL and based
     * on the downloaded vehicles details builds the vehicle list (vehicle hook).
     */
    useEffect(() => {
      if (
        vehicleId != -1 &&
        vehicleSliceStatus === "idle" &&
        vehicles.length > 0
      ) {
        let selectedVehicle: Vehicle = {} as Vehicle;

        selectedVehicle =
          vehiclesSelectors.selectById(store.getState(), vehicleId ?? -1) ??
          ({} as Vehicle);

        setAlias(selectedVehicle.alias);
      }
    }, [vehicleId, vehicles]);

    useEffect(() => {
      function handleClickOutside(event: any) {
        if (
          searchbarRef.current &&
          !searchbarRef.current.contains(event.target) &&
          parentRef.current &&
          !parentRef.current.contains(event.target)
        )
          setIsOpen(false);
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [searchbarRef]);

    //#region " checking parameters in the url "
    const [searchParams] = useSearchParams();
    const location: Location = useLocation();
    const refuellingReportRoute = useMatch(
      `/reports/refuelling-report/:vehicleId`
    );
    let isRefuellingReportRoute = refuellingReportRoute !== null;

    useEffect(() => {
      const map = new Map();
      let vehicleIdUrl = -1;
      const currentSearchParams =
        searchParams.toString() !== "" ? searchParams : null;
      vehicleIdUrl =
        refuellingReportRoute && refuellingReportRoute?.params?.vehicleId
          ? Number(refuellingReportRoute?.params?.vehicleId)
          : -1;
      if (vehicleIdUrl !== -1 && vehicleId === -1) {
        setVehicleId(Number(vehicleIdUrl));
        setJustReloaded(true);
      }
      if (!!currentSearchParams && _.isEmpty(queryParams)) {
        // retrieving the start period from URL
        const startPeriod = currentSearchParams.get("startPeriod");
        const endPeriod = currentSearchParams.get("endPeriod");
        if (startPeriod) {
          map.set("startPeriod", startPeriod);
          setIsSelectedPeriod(true);
          setInitialDateValue((prev) => {
            let prevValue = prev;

            prevValue[0] = new Date(startPeriod);

            return prevValue;
          });
        }
        if (endPeriod) {
          map.set("endPeriod", endPeriod);
          setInitialDateValue((prev) => {
            let prevValue = prev;

            prevValue[1] = new Date(endPeriod);

            return prevValue;
          });
        }
      }
      if (map.size === 0 && _.isEmpty(queryParams)) {
        const endPeriod = dayjs().format("YYYY-MM-DDTHH:mm");
        const startPeriodDate = new Date(endPeriod);
        startPeriodDate.setHours(0, 0, 0, 0);
        const startPeriod = dayjs(startPeriodDate).format("YYYY-MM-DDTHH:mm");
        map.set("startPeriod", startPeriod);
        map.set("endPeriod", endPeriod);
        setIsSelectedPeriod(true);
        setInitialDateValue((prev) => {
          let prevValue = prev;
          prevValue[0] = new Date(startPeriod);
          prevValue[1] = new Date(endPeriod);
          return prevValue;
        });
      }

      if (map.size > 0) {
        handleChanges(map, vehicleIdUrl, true);
      }
    }, [location]);
    //#endregion

    const updatePreset = (newPreset: Preset) => {
      setNewPreset(true);
      if (newPreset?.columns?.length > 0) {
        let temporaryPreset = presets.find(
          (preset) => preset.name === undefined
        );
        if (temporaryPreset) {
          store.dispatch(removePreset(temporaryPreset.id));
        }
        if (newPreset.name) {
          if (newPreset.name !== "Default" && newPreset.context === undefined) {
            newPreset.context = context;
            newPreset.lastSelected = true;
            store.dispatch(newPresetAsync(newPreset));
          } else {
            store.dispatch(
              updatePresetAsync({
                id: newPreset.id,
                preset: {
                  lastSelected: true,
                },
                context: context,
              })
            );
          }
          return true;
        } else {
          newPreset.id = Math.max(...presets.map((preset) => preset.id)) + 1;
          store.dispatch(addPreset(newPreset));
          return false;
        }
      }
    };

    const handleChanges = (
      params: Map<string, string[] | string>,
      vehicleId?: number,
      generateMacroSummary?: boolean,
      generateSummary?: boolean
    ): void => {
      if (generateSummary && vehicleId && vehicleId !== -1) {
        let queryParamsFixed: QueryParams = {};
        params.forEach((value, key) => {
          if (!!value && value.length > 0) {
            queryParamsFixed[key] = value;
          }
        });
        const stringifiedQueryParams = getQueryString(queryParamsFixed);
        generateResultWithParams(vehicleId, stringifiedQueryParams);
        return;
      }
      if (!!params) {
        params.forEach((value, key) => {
          if (!!value && value.length > 0) {
            queryParams[key] = value;
          } else {
            if (queryParams.hasOwnProperty(key)) {
              delete queryParams[key];
            }
          }
        });
        const stringifiedQueryParams = getQueryString(queryParams);
        setQueryString(stringifiedQueryParams);
        if (vehicleId && vehicleId !== -1) {
          navigate(
            `/reports/refuelling-report/${vehicleId}${getQueryString(
              queryParams
            )}`
          );
          if (generateMacroSummary) {
            generateResultWithParams(vehicleId, stringifiedQueryParams);
          }
        }
      }
    };

    /**
     * When another vehicle is selected, the URL should be changed
     */
    useEffect(() => {
      vehicleId !== -1 &&
        navigate(
          `/reports/refuelling-report/${vehicleId}${getQueryString(
            queryParams
          )}`
        );
    }, [vehicleId]);

    /**
     * When another vehicle is selected and there are vehicles, the related alias should be saved.
     */
    useEffect(() => {
      if (vehicleId !== -1 && vehicles.length && justReloaded) {
        const aliasVehicle = vehicles.find(
          (vehicle) => vehicle.id === Number(vehicleId)
        );
        setAlias(aliasVehicle?.alias ?? "");
        setJustReloaded(false);
      }
    }, [vehicleId, vehicles, justReloaded]);

    const generateResult = () => {
      if (
        (vehicleId !== -1 && queryString !== "") ||
        initialDateValue ||
        isSelectedPeriod
      ) {
        setOldVehicleId(vehicleId);
        setOldQueryParams(queryString);
        generateResultWithParams(vehicleId, queryString);
      }
    };

    useEffect(() => {
      if (!_.isEqual(getQueryString(queryParams), oldQueryParams)) {
        setOldQueryParams(getQueryString(queryParams));
      }
      if (
        !_.isEqual(
          oldVehicleId,
          Number(refuellingReportRoute?.params.vehicleId)
        )
      ) {
        setOldVehicleId(Number(refuellingReportRoute?.params.vehicleId));
      }
    }, [queryParams, vehicleId]);

    const generateResultWithParams = (
      vehicleId: number,
      queryString: string
    ) => {
      if (queryString !== "" && !_.isEmpty(queryParams) && vehicleId !== -1) {
        store.dispatch(
          getRefuellingReportAsync({
            vehicleId: vehicleId,
            queryParams: queryString,
          })
        );
        store.dispatch(
          getRefuellingChartDataReportAsync({
            vehicleId: vehicleId,
            queryParams: queryString,
          })
        );
        store.dispatch(
          getRefuellingReportSummaryAsync({
            vehicleId: vehicleId,
            queryParams: queryString,
          })
        );
        callback(vehicleId, queryString);
      }
    };

    useEffect(() => {
      if (eventSliceStatus === "failed" && eventSliceReasonCode === "") {
        ToastNotification({
          toastId: "networkError",
          status: "error",
          description: t("common.networkError"),
        });
      }
    }, [eventSliceStatus, eventSliceReasonCode]);

    useEffect(() => {
      if (
        (refuelReportsSliceStatus === "failed" &&
          refuelReportsSliceReasonCode === "") ||
        (reportsSummarySliceStatus === "failed" &&
          reportsSummarySliceReasonCode === "") ||
        (reportsChartDataSliceStatus === "failed" &&
          reportsChartDataSliceReasonCode === "")
      ) {
        ToastNotification({
          toastId: "refuellingReportError",
          status: "error",
          description: t("common.networkError"),
        });
      }
    }, [
      refuelReportsSliceStatus,
      refuelReportsSliceReasonCode,
      reportsSummarySliceStatus,
      reportsSummarySliceReasonCode,
      reportsChartDataSliceStatus,
      reportsChartDataSliceReasonCode,
    ]);

    //#region export report
    const downloadResult = (pdf: boolean) => {
      if ((vehicleId && queryString !== "") || isSelectedPeriod) {
        const params = queryString + (pdf ? "&isPdf=true" : "&isPdf=false");
        pdf ? setIsPdfGenerated(false) : setIsExcelGenerated(false);
        reportsRepository
          .getRefuellingReportDownload(params, vehicleId)
          .then((response) => {
            return response.data;
          })
          .then((data) => {
            const fileName = data.split("/").pop();
            const attachmentDownloadLink = document.createElement("a");
            attachmentDownloadLink.href =
              process.env.REACT_APP_BUCKET_URL + data;
            attachmentDownloadLink.download = fileName;
            attachmentDownloadLink.target = "_blank";
            attachmentDownloadLink.style.display = "none";
            document.body.appendChild(attachmentDownloadLink);
            attachmentDownloadLink.click();
            document.body.removeChild(attachmentDownloadLink);
            pdf ? setIsPdfGenerated(true) : setIsExcelGenerated(true);
          })
          .catch((error) => {
            pdf ? setIsPdfGenerated(true) : setIsExcelGenerated(true);
            if (
              error.response &&
              error.response.data.message ==
                GTFleetErrorCodes.REPORT_TIMEOUT_EXCEPTION
            ) {
              console.log(error?.message || "Report Timeout Exception");
              ToastNotification({
                toastId: "reportTimeoutError",
                status: "default",
                title: t("common.reportTimeoutErrorTitle"),
                description: t("common.reportTimeoutErrorDesc"),
              });
            } else {
              console.log(error.response?.data?.error || "Unknown Error");
              ToastNotification({
                toastId: "networkError",
                status: "error",
                description: t("common.networkError"),
              });
            }
          });
      }
    };

    const sendReportEmail = () => {
      if ((vehicleId && queryString !== "") || isSelectedPeriod) {
        reportsRepository
          .getRefuellingReportEmail(queryString, vehicleId)
          .then((response) => {
            return response.data;
          })
          .then((data) => {
            ToastNotification({
              toastId: "requestSendingMailSuccess",
              status: "success",
              description: t(
                "report.toastNotification.requestSendingMailSuccess"
              ),
            });
          })
          .catch((error) => {
            if (!error.response) console.log(error?.message || "Unknown Error");
            else console.log(error.response?.data?.error || "Unknown Error");
            ToastNotification({
              toastId: "networkError",
              status: "error",
              description: t("common.networkError"),
            });
          });
      }
    };

    const list = [
      {
        id: 0,
        title: t("report.filterBar.downloadExcel"),
        icon: isExcelGenerated ? (
          <IconDownload size={14} color="--global-colors-ink-light" />
        ) : (
          <div className="details-driverReport-download-spinner"></div>
        ),
        onClick: () => {
          downloadResult(false);
        },
      },
      {
        id: 1,
        title: t("report.filterBar.downloadPDF"),
        icon: isPdfGenerated ? (
          <IconDownload size={14} color="--global-colors-ink-light" />
        ) : (
          <div className="details-driverReport-download-spinner"></div>
        ),
        onClick: () => {
          downloadResult(true);
        },
      },
      {
        id: 2,
        title: t("report.filterBar.sendReportEmail"),
        icon: <IconLocatorOff size={14} color="--global-colors-ink-light" />,
        onClick: () => {
          sendReportEmail();
        },
      },
    ];
    //#endregion export report

    const filterVehicle = (vehicle: Vehicle) => {
      const filterVehicle =
        alias == "" ||
        (vehicle?.alias &&
          vehicle?.alias?.toLowerCase().includes(alias.toLowerCase())) ||
        (vehicle?.plate &&
          vehicle?.plate?.toLowerCase().includes(alias.toLowerCase())) ||
        (vehicle?.id &&
          vehicle?.id?.toString()?.toLowerCase().includes(alias.toLowerCase()));
      return filterVehicle;
    };

    return (
      <div className="rr-filterbar-row">
        <div className="rr-filterbar-search">
          <div
            onClick={() => {
              setIsOpen(true);
            }}
            ref={parentRef}
          >
            <Form>
              <SearchField
                name="search"
                id="search-field"
                size="small"
                placeholder={t(
                  "locationHistory.searchDropdown.placeholderVehicle"
                )}
                value={alias}
                onChange={(val: string) => {
                  setAlias(val);
                }}
              />
            </Form>
          </div>
          {isOpen && vehicles.length > 0 && (
            <div className="rr-search-results" ref={searchbarRef}>
              {fleetNames?.map((element: string) => {
                return (
                  <React.Fragment key={element}>
                    <div className="rr-fleet-label">
                      {element}
                      {` (${
                        vehiclesGroupByFleet[element].filter(filterVehicle)
                          .length
                      })`}
                    </div>
                    {vehiclesGroupByFleet[element]
                      .filter(filterVehicle)
                      .map((vehicle: Vehicle) => (
                        <div
                          key={vehicle.alias}
                          className={
                            alias === vehicle.alias
                              ? "rr-alias-selected"
                              : "rr-alias"
                          }
                          onClick={() => {
                            setIsOpen(false);
                            setVehicleId(vehicle.id);
                            setAlias(vehicle.alias);
                          }}
                        >
                          {vehicle.alias} <span className="rr-dot" />
                          {vehicle.plate}
                        </div>
                      ))}
                  </React.Fragment>
                );
              })}
            </div>
          )}
        </div>
        <div className="rr-date-picker">
          <Form>
            <DatePickerDropdown
              setDate={(val) => {
                const map = new Map();
                let startPeriod = "";
                let endPeriod = "";
                if (_.isArray(val)) {
                  if (val.length === 1) {
                    endPeriod =
                      typeof val[0] === "string"
                        ? val[0]
                        : val[0].format("YYYY-MM-DDTHH:mm");
                    const startPeriodDate = new Date(endPeriod);
                    startPeriodDate.setHours(0, 0, 0, 0);
                    startPeriod =
                      dayjs(startPeriodDate).format("YYYY-MM-DDTHH:mm");
                  } else {
                    startPeriod =
                      typeof val[0] === "string"
                        ? val[0]
                        : val[0].format("YYYY-MM-DDTHH:mm");
                    endPeriod = val[1]?.format("YYYY-MM-DDTHH:mm") ?? "";
                  }
                }
                map.set("startPeriod", startPeriod);
                map.set("endPeriod", endPeriod);
                setIsSelectedPeriod(true);
                handleChanges(map, vehicleId, false);
              }}
              hasTime={true}
              initialValue={initialDateValue.length ? initialDateValue : []}
              defaultValue={new Date()}
              localeFormat={preferencesContext.localeFormat ?? "DD / MM / YYYY"}
              language={preferencesContext.language ?? "it"}
              icon={
                <IconCalendar size={12} color="--global-colors-ink-light" />
              }
              dateRange={[dayjs().subtract(1, "year"), dayjs()]} //From today to past 1 year
              limitDaysRange={31}
              clearCallback={() => {
                const map = new Map();
                const startPeriod = dayjs().format("YYYY-MM-DDTHH:mm");
                map.set("startPeriod", startPeriod);
                setIsSelectedPeriod(true);
                handleChanges(map, vehicleId, false);
              }}
            />
          </Form>
        </div>
        <div className="rr-dropdown-customize">
          <CustomizeColumnsModal
            columns={customColumns}
            presets={presets ?? []}
            onClose={(newPreset) => updatePreset(newPreset)}
            handleDelete={(e: any) => store.dispatch(deletePresetAsync(e))}
          />
        </div>

        <div className="rr-filterbar-btn-generate">
          <Button
            aspect="primary"
            size="small"
            label={t("report.filterBar.buttonGenerate")}
            onClick={() => {
              store.dispatch(refuellingReportEmptyState());
              store.dispatch(refuellingChartDataReportEmptyState());
              store.dispatch(refuellingReportSummaryEmptyState());
              generateResult();
            }}
            isLoading={!isReportsIdle && !isReportsFailed}
            disabled={
              ((initialDateValue === undefined ||
                alias === "" ||
                isOpen ||
                (oldQueryParams === getQueryString(queryParams) &&
                  oldVehicleId === vehicleId)) &&
                isReportsIdle) ||
              !newPreset
            }
          />
        </div>
        <div className="rr-filterbar-btn-export">
          <DropdownButton
            aspect="secondary"
            disabled={disableButton}
            size="small"
            label={t("common.export")}
            list={list}
          >
            <IconDown size={14} color="--global-colors-ink-ink" />
          </DropdownButton>
        </div>
      </div>
    );
  };
