import React from "react";
import propTypes from "prop-types";

import "./mapMarker.css";

export const MapMarkerTruckNumber = ({ number, color, ...props }) => {
	return (
		<span className="map-marker" style={{ backgroundColor: `var(${color})` }}{...props}>
			{number}
		</span>
	)
}

MapMarkerTruckNumber.propTypes = {
	/**
	 * The number displayed in the marker.
	 */
	number: propTypes.number,
	/**
	* The background color displayed in the marker.
	*/
	color: propTypes.string
}

MapMarkerTruckNumber.defaultProps = {
	number: 1
}