import { SpeedReport } from "../ui/Chart/SpeedReport";

const TestTSDavide = () => {
  const searchFrom = "2021-11-12T01:10:54.352Z",
    searchTo = "2021-12-14T10:10:54.352Z",
    series = [
      {
        name: "Jim Terry",
        data: [
          { date: "2021-12-02", kms: [71, 195, 86, 147, 130] },
          { date: "2021-12-11", kms: [105, 23, 148, 115, 150] },
          { date: "2021-12-07", kms: [164, 96, 71, 202, 208] },
          { date: "2021-11-29", kms: [20, 222, 57, 99, 139] },
          { date: "2021-11-17", kms: [174, 178, 159, 190, 154] },
          { date: "2021-11-17", kms: [216, 132, 162, 154, 156] },
          { date: "2021-11-16", kms: [215, 200, 212, 90, 242] },
          { date: "2021-11-29", kms: [168, 104, 93, 164, 61] },
          { date: "2021-11-27", kms: [151, 26, 105, 167, 178] },
          { date: "2021-11-29", kms: [119, 33, 31, 245, 151] },
          { date: "2021-11-23", kms: [88, 180, 53, 86, 53] },
          { date: "2021-11-25", kms: [231, 201, 198, 73, 184] },
          { date: "2021-11-24", kms: [161, 153, 174, 214, 236] },
          { date: "2021-11-22", kms: [178, 154, 218, 219, 197] },
          { date: "2021-11-18", kms: [205, 138, 101, 115, 165] },
          { date: "2021-11-20", kms: [63, 93, 72, 160, 240] },
          { date: "2021-11-27", kms: [95, 219, 61, 62, 46] },
          { date: "2021-11-28", kms: [153, 85, 228, 89, 107] },
          { date: "2021-12-08", kms: [190, 152, 181, 197, 222] },
          { date: "2021-11-30", kms: [249, 249, 172, 230, 204] },
          { date: "2021-12-01", kms: [178, 193, 23, 93, 187] },
          { date: "2021-11-23", kms: [57, 105, 141, 194, 242] },
          { date: "2021-11-22", kms: [195, 28, 79, 138, 22] },
        ],
      },
      {
        name: "Mary Castillo",
        data: [
          { date: "2021-12-05", kms: [81, 28, 90, 86, 119] },
          { date: "2021-11-27", kms: [261, 238, 57, 160, 83] },
          { date: "2021-12-06", kms: [161, 62, 244, 109, 25] },
          { date: "2021-11-26", kms: [147, 129, 168, 42, 225] },
          { date: "2021-11-22", kms: [205, 167, 167, 52, 114] },
          { date: "2021-12-12", kms: [126, 27, 95, 42, 170] },
          { date: "2021-12-08", kms: [162, 21, 44, 51, 36] },
          { date: "2021-12-04", kms: [67, 120, 184, 150, 186] },
          { date: "2021-12-02", kms: [50, 228, 178, 57, 213] },
          { date: "2021-11-20", kms: [68, 64, 176, 120, 125] },
        ],
      },
      {
        name: "Hilda Snyder",
        data: [
          { date: "2021-11-22", kms: [72, 225, 247, 67, 117] },
          { date: "2021-12-10", kms: [237, 56, 194, 224, 201] },
          { date: "2021-11-24", kms: [175, 104, 30, 116, 171] },
          { date: "2021-11-25", kms: [224, 165, 104, 155, 133] },
          { date: "2021-11-17", kms: [168, 107, 73, 119, 173] },
          { date: "2021-12-05", kms: [163, 180, 172, 112, 50] },
          { date: "2021-11-27", kms: [121, 161, 162, 149, 173] },
          { date: "2021-11-30", kms: [63, 101, 150, 146, 194] },
          { date: "2021-12-03", kms: [249, 160, 163, 136, 149] },
          { date: "2021-12-05", kms: [193, 244, 91, 207, 187] },
          { date: "2021-11-21", kms: [239, 110, 102, 184, 40] },
          { date: "2021-11-30", kms: [249, 194, 24, 100, 112] },
          { date: "2021-11-30", kms: [139, 229, 174, 68, 248] },
          { date: "2021-12-06", kms: [245, 153, 40, 199, 186] },
          { date: "2021-12-02", kms: [96, 140, 137, 33, 112] },
          { date: "2021-11-25", kms: [152, 248, 162, 112, 99] },
          { date: "2021-11-30", kms: [78, 107, 231, 184, 156] },
          { date: "2021-12-09", kms: [20, 93, 32, 54, 151] },
          { date: "2021-12-01", kms: [155, 34, 155, 71, 126] },
          { date: "2021-11-23", kms: [146, 158, 153, 234, 178] },
        ],
      },
      {
        name: "Hunter Marshall",
        data: [
          { date: "2021-11-27", kms: [246, 104, 160, 220, 233] },
          { date: "2021-12-11", kms: [206, 171, 208, 48, 79] },
          { date: "2021-12-10", kms: [75, 40, 210, 32, 33] },
          { date: "2021-12-09", kms: [163, 26, 25, 63, 124] },
          { date: "2021-11-23", kms: [199, 116, 176, 184, 87] },
          { date: "2021-12-04", kms: [140, 157, 249, 142, 59] },
          { date: "2021-11-17", kms: [52, 172, 179, 126, 96] },
          { date: "2021-12-09", kms: [84, 145, 138, 76, 151] },
          { date: "2021-11-24", kms: [111, 71, 170, 84, 166] },
          { date: "2021-11-19", kms: [40, 90, 48, 155, 132] },
          { date: "2021-12-11", kms: [201, 104, 201, 96, 163] },
          { date: "2021-11-23", kms: [156, 150, 143, 99, 213] },
          { date: "2021-11-23", kms: [101, 196, 83, 68, 245] },
          { date: "2021-12-10", kms: [239, 111, 182, 221, 102] },
          { date: "2021-12-09", kms: [213, 136, 107, 152, 55] },
          { date: "2021-11-29", kms: [127, 212, 41, 129, 41] },
          { date: "2021-11-30", kms: [179, 33, 242, 183, 171] },
          { date: "2021-11-28", kms: [137, 70, 164, 174, 167] },
          { date: "2021-11-23", kms: [232, 218, 212, 151, 27] },
          { date: "2021-11-23", kms: [83, 131, 143, 44, 238] },
          { date: "2021-11-17", kms: [215, 58, 240, 199, 154] },
          { date: "2021-11-29", kms: [114, 178, 53, 59, 178] },
          { date: "2021-11-24", kms: [204, 31, 90, 127, 201] },
          { date: "2021-11-16", kms: [44, 199, 202, 173, 135] },
          { date: "2021-11-29", kms: [175, 171, 142, 234, 232] },
          { date: "2021-12-10", kms: [62, 195, 70, 138, 114] },
        ],
      },
      {
        name: "Willie Harris",
        data: [
          { date: "2021-12-05", kms: [115, 199, 196, 133, 66] },
          { date: "2021-11-16", kms: [123, 209, 235, 213, 22] },
          { date: "2021-12-09", kms: [73, 107, 155, 102, 213] },
          { date: "2021-11-21", kms: [218, 182, 247, 98, 124] },
          { date: "2021-12-13", kms: [91, 138, 144, 220, 244] },
          { date: "2021-12-10", kms: [57, 181, 67, 60, 44] },
          { date: "2021-11-21", kms: [201, 73, 183, 142, 206] },
          { date: "2021-12-03", kms: [216, 170, 116, 85, 131] },
          { date: "2021-11-18", kms: [247, 107, 163, 40, 164] },
          { date: "2021-12-12", kms: [144, 74, 200, 181, 197] },
          { date: "2021-11-16", kms: [150, 219, 189, 95, 84] },
          { date: "2021-11-17", kms: [185, 33, 164, 170, 127] },
          { date: "2021-11-20", kms: [130, 144, 214, 165, 153] },
          { date: "2021-12-03", kms: [227, 40, 46, 173, 143] },
          { date: "2021-11-27", kms: [39, 48, 45, 127, 23] },
          { date: "2021-12-01", kms: [46, 223, 227, 195, 151] },
          { date: "2021-12-03", kms: [177, 156, 28, 149, 230] },
          { date: "2021-11-20", kms: [116, 99, 109, 245, 52] },
          { date: "2021-11-23", kms: [139, 166, 64, 109, 114] },
          { date: "2021-11-17", kms: [46, 175, 238, 130, 83] },
          { date: "2021-12-02", kms: [73, 176, 74, 241, 95] },
          { date: "2021-12-13", kms: [209, 123, 154, 170, 102] },
          { date: "2021-12-10", kms: [181, 26, 156, 139, 147] },
          { date: "2021-11-28", kms: [172, 245, 57, 180, 102] },
          { date: "2021-11-16", kms: [200, 159, 164, 47, 64] },
        ],
      },
      {
        name: "Jerry Wilkerson",
        data: [
          { date: "2021-11-28", kms: [197, 214, 210, 89, 130] },
          { date: "2021-11-16", kms: [225, 174, 198, 149, 158] },
          { date: "2021-12-07", kms: [185, 248, 67, 223, 212] },
          { date: "2021-12-06", kms: [207, 161, 71, 76, 55] },
          { date: "2021-11-26", kms: [85, 227, 78, 50, 151] },
          { date: "2021-12-10", kms: [102, 244, 95, 193, 186] },
          { date: "2021-11-22", kms: [39, 116, 217, 51, 150] },
          { date: "2021-11-28", kms: [156, 174, 129, 35, 53] },
          { date: "2021-11-26", kms: [106, 175, 45, 71, 104] },
          { date: "2021-11-26", kms: [137, 183, 89, 167, 189] },
          { date: "2021-11-21", kms: [188, 224, 91, 99, 32] },
          { date: "2021-11-18", kms: [237, 208, 169, 228, 166] },
          { date: "2021-11-17", kms: [162, 150, 37, 99, 70] },
          { date: "2021-11-29", kms: [161, 99, 174, 211, 128] },
          { date: "2021-11-30", kms: [161, 170, 53, 136, 42] },
          { date: "2021-12-10", kms: [37, 39, 192, 224, 182] },
          { date: "2021-11-19", kms: [107, 184, 170, 30, 165] },
          { date: "2021-11-25", kms: [223, 43, 38, 222, 223] },
          { date: "2021-11-19", kms: [150, 160, 92, 178, 63] },
          { date: "2021-12-11", kms: [89, 137, 128, 44, 181] },
          { date: "2021-11-17", kms: [125, 234, 167, 219, 214] },
          { date: "2021-11-26", kms: [60, 85, 154, 229, 249] },
          { date: "2021-11-15", kms: [184, 24, 94, 224, 244] },
          { date: "2021-11-24", kms: [193, 168, 216, 114, 75] },
          { date: "2021-11-30", kms: [56, 246, 115, 128, 79] },
          { date: "2021-11-28", kms: [201, 192, 40, 147, 153] },
          { date: "2021-11-22", kms: [175, 120, 49, 113, 228] },
          { date: "2021-12-12", kms: [116, 164, 197, 223, 167] },
        ],
      },
      {
        name: "Fannie Palmer",
        data: [
          { date: "2021-11-22", kms: [27, 174, 69, 172, 151] },
          { date: "2021-11-24", kms: [229, 75, 102, 227, 246] },
          { date: "2021-12-06", kms: [75, 21, 136, 35, 98] },
          { date: "2021-12-09", kms: [181, 222, 54, 197, 119] },
          { date: "2021-11-29", kms: [240, 29, 65, 153, 61] },
          { date: "2021-12-01", kms: [171, 239, 81, 211, 51] },
          { date: "2021-11-20", kms: [43, 78, 192, 175, 39] },
          { date: "2021-12-12", kms: [190, 109, 80, 197, 59] },
          { date: "2021-12-10", kms: [162, 234, 55, 75, 61] },
          { date: "2021-11-16", kms: [35, 162, 137, 31, 156] },
          { date: "2021-11-30", kms: [81, 239, 154, 22, 170] },
          { date: "2021-12-01", kms: [216, 154, 213, 186, 63] },
          { date: "2021-11-20", kms: [123, 98, 137, 187, 233] },
          { date: "2021-11-19", kms: [225, 91, 162, 125, 119] },
          { date: "2021-11-27", kms: [85, 157, 202, 244, 113] },
          { date: "2021-11-21", kms: [192, 149, 178, 181, 87] },
          { date: "2021-11-25", kms: [211, 76, 211, 178, 232] },
          { date: "2021-12-11", kms: [146, 98, 35, 53, 113] },
          { date: "2021-11-18", kms: [44, 207, 170, 153, 62] },
          { date: "2021-12-07", kms: [196, 60, 186, 107, 88] },
          { date: "2021-12-11", kms: [154, 164, 105, 123, 165] },
          { date: "2021-12-08", kms: [74, 164, 131, 151, 225] },
          { date: "2021-11-18", kms: [74, 61, 248, 74, 173] },
          { date: "2021-12-10", kms: [109, 97, 207, 85, 188] },
          { date: "2021-11-20", kms: [152, 173, 237, 155, 124] },
          { date: "2021-11-16", kms: [200, 246, 216, 174, 153] },
          { date: "2021-12-01", kms: [93, 173, 105, 29, 173] },
        ],
      },
      {
        name: "Clara Hunt",
        data: [
          { date: "2021-12-03", kms: [34, 248, 226, 248, 217] },
          { date: "2021-12-13", kms: [226, 230, 154, 36, 177] },
          { date: "2021-11-21", kms: [111, 242, 190, 222, 55] },
          { date: "2021-11-18", kms: [243, 247, 26, 164, 172] },
          { date: "2021-11-16", kms: [117, 33, 99, 62, 35] },
          { date: "2021-11-29", kms: [87, 136, 148, 204, 212] },
          { date: "2021-11-28", kms: [87, 168, 115, 39, 198] },
          { date: "2021-12-03", kms: [92, 112, 82, 173, 197] },
          { date: "2021-12-09", kms: [159, 173, 86, 224, 209] },
          { date: "2021-12-03", kms: [46, 157, 157, 85, 80] },
          { date: "2021-11-20", kms: [249, 30, 80, 35, 125] },
          { date: "2021-12-03", kms: [147, 40, 205, 61, 29] },
          { date: "2021-11-21", kms: [75, 34, 231, 170, 145] },
          { date: "2021-11-24", kms: [126, 192, 134, 69, 21] },
          { date: "2021-11-21", kms: [176, 209, 110, 233, 71] },
          { date: "2021-11-26", kms: [162, 215, 119, 151, 152] },
          { date: "2021-12-01", kms: [217, 29, 100, 41, 151] },
          { date: "2021-12-08", kms: [111, 222, 134, 93, 40] },
          { date: "2021-12-05", kms: [37, 122, 128, 88, 49] },
          { date: "2021-12-02", kms: [207, 134, 54, 142, 244] },
          { date: "2021-12-03", kms: [29, 121, 208, 152, 99] },
          { date: "2021-11-29", kms: [152, 219, 84, 186, 38] },
          { date: "2021-11-24", kms: [205, 137, 85, 165, 180] },
          { date: "2021-12-03", kms: [124, 228, 38, 175, 69] },
          { date: "2021-12-04", kms: [175, 112, 74, 73, 68] },
          { date: "2021-12-09", kms: [99, 225, 232, 177, 82] },
        ],
      },
      {
        name: "Celia Barton",
        data: [
          { date: "2021-11-21", kms: [75, 65, 248, 32, 206] },
          { date: "2021-12-12", kms: [113, 85, 166, 36, 164] },
          { date: "2021-11-23", kms: [49, 180, 206, 133, 75] },
          { date: "2021-11-28", kms: [114, 138, 143, 45, 222] },
          { date: "2021-12-03", kms: [217, 197, 45, 65, 121] },
          { date: "2021-11-18", kms: [238, 141, 139, 163, 143] },
          { date: "2021-12-08", kms: [124, 169, 170, 230, 236] },
          { date: "2021-11-17", kms: [34, 27, 215, 99, 129] },
          { date: "2021-11-25", kms: [177, 164, 97, 78, 231] },
          { date: "2021-12-03", kms: [213, 153, 83, 100, 187] },
          { date: "2021-11-16", kms: [104, 225, 114, 203, 182] },
          { date: "2021-11-25", kms: [124, 214, 194, 67, 51] },
          { date: "2021-11-18", kms: [68, 158, 46, 172, 39] },
          { date: "2021-12-10", kms: [154, 96, 238, 164, 149] },
          { date: "2021-12-12", kms: [94, 200, 207, 150, 85] },
          { date: "2021-11-30", kms: [226, 104, 164, 205, 152] },
          { date: "2021-12-09", kms: [208, 113, 232, 63, 246] },
          { date: "2021-12-12", kms: [30, 79, 164, 228, 34] },
          { date: "2021-11-30", kms: [180, 155, 173, 157, 243] },
          { date: "2021-12-08", kms: [122, 61, 198, 153, 94] },
          { date: "2021-12-12", kms: [76, 29, 227, 160, 189] },
          { date: "2021-12-12", kms: [225, 192, 79, 226, 34] },
          { date: "2021-11-19", kms: [153, 179, 114, 151, 130] },
          { date: "2021-12-10", kms: [120, 176, 167, 178, 196] },
          { date: "2021-12-13", kms: [164, 131, 216, 165, 123] },
          { date: "2021-12-09", kms: [200, 33, 132, 218, 119] },
        ],
      },
      {
        name: "Jason Underwoo",
        data: [
          { date: "2021-12-06", kms: [208, 129, 138, 98, 165] },
          { date: "2021-12-06", kms: [78, 155, 203, 43, 47] },
          { date: "2021-11-26", kms: [122, 101, 204, 209, 237] },
          { date: "2021-12-06", kms: [148, 112, 164, 122, 153] },
          { date: "2021-12-07", kms: [89, 197, 65, 152, 38] },
          { date: "2021-12-10", kms: [171, 228, 164, 217, 205] },
          { date: "2021-12-06", kms: [210, 122, 36, 162, 213] },
          { date: "2021-12-07", kms: [132, 101, 21, 180, 194] },
          { date: "2021-12-03", kms: [43, 22, 58, 194, 158] },
          { date: "2021-12-03", kms: [38, 124, 82, 87, 113] },
          { date: "2021-12-06", kms: [133, 227, 30, 90, 171] },
          { date: "2021-12-12", kms: [178, 231, 36, 147, 201] },
          { date: "2021-12-04", kms: [163, 227, 118, 153, 62] },
          { date: "2021-11-16", kms: [47, 228, 103, 199, 37] },
          { date: "2021-12-05", kms: [79, 193, 23, 113, 179] },
          { date: "2021-11-24", kms: [239, 126, 153, 41, 162] },
          { date: "2021-12-11", kms: [215, 138, 145, 29, 123] },
          { date: "2021-12-10", kms: [167, 247, 201, 218, 182] },
          { date: "2021-12-10", kms: [27, 171, 116, 110, 119] },
          { date: "2021-11-22", kms: [231, 83, 135, 93, 164] },
          { date: "2021-12-12", kms: [67, 168, 177, 27, 87] },
          { date: "2021-12-11", kms: [199, 76, 121, 151, 49] },
          { date: "2021-11-22", kms: [129, 44, 156, 34, 171] },
          { date: "2021-12-08", kms: [175, 219, 155, 175, 48] },
          { date: "2021-11-17", kms: [123, 231, 158, 147, 204] },
          { date: "2021-11-28", kms: [174, 169, 221, 230, 124] },
          { date: "2021-11-19", kms: [153, 69, 152, 147, 107] },
          { date: "2021-12-13", kms: [21, 157, 171, 163, 68] },
          { date: "2021-12-06", kms: [170, 197, 69, 133, 139] },
        ],
      },
    ],
    size = { width: "100%", height: "100%" };

  return (
    <SpeedReport
      searchFrom={searchFrom}
      searchTo={searchTo}
      series={series}
      size={size}
    />
  );
};
export default TestTSDavide;
