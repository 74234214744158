import {
  PayloadAction,
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import { normalize } from "normalizr";
import { RootState } from "../../../app/store";
import { GTFleetSuccessCodes } from "../../../config/GTFleetSuccessCodes";
import { publicRoutePlanningsSchema } from "./publicRoutePlanningNormalization";
import PublicRoutePlanningRepository from "./publicRoutePlanningRepository";
import { getPublicRoutePlanningSummaryAsync } from "./publicRoutePlanningSummarySlice";

//ENUM REGION

export const PublicShiftStatusEnum = {
  ON_DUTY: "ON_DUTY",
  OFF_DUTY: "OFF_DUTY",
  RESTING: "RESTING",
  SICK_LEAVE: "SICK_LEAVE",
  HOLIDAYS: "HOLIDAYS",
  LAW_104: "LAW_104",
  OTHER: "OTHER",
};

export type PublicShiftStatusEnum = keyof typeof PublicShiftStatusEnum;

//ENUM ENDREGION

export interface PublicRoutePlanningType {
  id: number;
  name: string;
  driverId: number;
  vehicleId: number;
  publicRouteId: number;
  expectedWorkingTime: number;
  actualWorkingTime: number;
  planningStatusEnum: PublicShiftStatusEnum;
  date: Date;
  shiftTimeRangeId: number;
  publicRoutePlanningSummaryId: number;
}

export interface PublicRoutePlanningRequest {
  id: number | null;
  name: string | undefined;
  driverId: number;
  vehicleId: number;
  publicRouteId: number;
  planningStatusEnum: PublicShiftStatusEnum;
  date: Date;
  shiftTimeRangeId: number;
}

//ASYNC CALLS REGION

const publicRoutePlanningAdapter = createEntityAdapter<PublicRoutePlanningType>(
  {
    selectId: (publicRoutePlanning) => publicRoutePlanning.id,
  }
);

export const updatePublicRoutePlanningsAsync = createAsyncThunk(
  "publicRoutePlannings/updatePublicRoutePlannings",
  async (
    data: {
      objs: PublicRoutePlanningRequest[];
      status: string;
      startDate: String;
      endDate: String;
    },
    { rejectWithValue }
  ) => {
    try {
      const publicRoutePlanningRepository = new PublicRoutePlanningRepository();
      const response =
        await publicRoutePlanningRepository.updatePublicRoutePlannings(
          data.objs,
          data.status,
          data.startDate,
          data.endDate
        );
      const normalizedData = normalize(
        response.data,
        publicRoutePlanningsSchema
      );
      return normalizedData.entities;
    } catch (err: any) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data.message);
    }
  }
);

//ASYNC CALLS ENDREGION

export const publicRoutePlanningSlice = createSlice({
  name: "publicRoutePlanning",
  initialState: publicRoutePlanningAdapter.getInitialState({
    status: "idle",
    reasonCode: "",
  }),
  reducers: {
    publicRoutePlanningEmptyState: (state: any) => {
      publicRoutePlanningAdapter.setAll(state, []);
      state.reasonCode = "";
      state.status = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getPublicRoutePlanningSummaryAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          action.payload?.publicRoutePlanning &&
            publicRoutePlanningAdapter.setMany(
              state,
              action.payload?.publicRoutePlanning
            );
        }
      )
      .addCase(updatePublicRoutePlanningsAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(
        updatePublicRoutePlanningsAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          action.payload?.publicRoutePlanning &&
            publicRoutePlanningAdapter.upsertMany(
              state,
              action.payload.publicRoutePlanning
            );
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.GET;
        }
      )
      .addCase(updatePublicRoutePlanningsAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = "";
      });
  },
});

export const publicRoutePlanningSelectors =
  publicRoutePlanningAdapter.getSelectors<RootState>(
    (state) => state.publicRoutePlannings
  );

export const selectPublicRoutePlanningStatus = (state: any) =>
  state.publicRoutePlannings.status;
export const selectPublicRoutePlanningSliceReasonCode = (state: any) =>
  state.publicRoutePlannings.reasonCode;
export const { publicRoutePlanningEmptyState } =
  publicRoutePlanningSlice.actions;
//#endregion Status

export default publicRoutePlanningSlice.reducer;
