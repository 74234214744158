import { useTranslation } from "react-i18next";
import { DataLabel } from "../../../ui/DataLabel/DataLabel";
import { IconKilometers } from "../../../ui/Icon/Line/Kilometers";
import { IconClock } from "../../../ui/Icon/Line/Clock";
import { IconMenuRoutes } from "../../../ui/Icon/Line/MenuRoutes";
import { useContext } from "react";
import UserContext from "../../users/userContext";
import { tableFormatTime } from "../../../utils/DateAndTimeUtils";
import { formatData, kmToMiles, ltToGal } from "../../../utils/Utils";
import { Preferences } from "../../users/preference/preferencesSlice";

interface ReportIODetailsProps {
  duration?: number | string;
  eventDistance?: number;
  distanceCovered?: number;
}

export const ReportIODetails: React.FC<ReportIODetailsProps> = ({
  duration,
  eventDistance,
  distanceCovered,
}) => {
  const { t } = useTranslation();
  const [preferencesContext]: [Preferences] = useContext(UserContext);

  return (
    <>
      {duration != null && (
        <div className="details-report-item">
          <DataLabel
            dataText={tableFormatTime(duration, "seconds")}
            icon={<IconClock size={14} />}
            label={t("report.details.duration")}
          />
        </div>
      )}
      {eventDistance != null && (
        <div className="details-report-item">
          <DataLabel
            dataText={`${formatData(
              eventDistance,
              kmToMiles,
              preferencesContext,
              1
            )} ${preferencesContext.isMetric ? "km" : "mil"}`}
            icon={<IconKilometers size={14} />}
            label={t("report.details.eventDistance")}
          />
        </div>
      )}
      {distanceCovered != null && (
        <div className="details-report-item">
          <DataLabel
            dataText={`${formatData(
              distanceCovered,
              ltToGal,
              preferencesContext,
              2
            )} ${preferencesContext.isMetric ? "lt" : "gal"}`}
            icon={<IconMenuRoutes size={14} />}
            label={t("report.details.distanceCovered")}
          />
        </div>
      )}
    </>
  );
};
