import { useTranslation } from "react-i18next";

export const DataCommon = () => {
  const { t } = useTranslation();

  return {
    fleetControl: {
      filterBar: {
        optionStatus: [
          {
            code: "online",
            label: t("admin.adminFilterBar.optionStatus.online"),
          },
          {
            code: "offline",
            label: t("admin.adminFilterBar.optionStatus.offline"),
          },
          {
            code: "no_signal",
            label: t("admin.adminFilterBar.optionStatus.nosignal"),
          },
          {
            code: "unknown",
            label: t("admin.adminFilterBar.optionStatus.unknown"),
          },
        ],
        optionOrderBy: [
          {
            code: "id",
            label: t("admin.adminFilterBar.optionOrderBy.id"),
          },
          {
            code: "groups",
            label: t("admin.adminFilterBar.optionOrderBy.groups"),
          },
          {
            code: "status",
            label: t("admin.adminFilterBar.optionOrderBy.signalState"),
          },
        ],
      },
    },
    action: {
      settingsActionMenu: {
        open: true,
        options: [
          {
            code: "alias",
            label: t("fleetControl.map.actions.settingsActionMenu.alias"),
          },
          {
            code: "lock",
            label: t("fleetControl.map.actions.settingsActionMenu.lock"),
          },
          {
            code: "cluster",
            label: t("fleetControl.map.actions.settingsActionMenu.cluster"),
          },
          {
            code: "mouseover",
            label: t("fleetControl.map.actions.settingsActionMenu.mouseover"),
          },
        ],
      },
    },
    prefixList: [
      { label: "+39" },
      { label: "+34" },
      { label: "+33" },
      { label: "+351" },
      { label: "+30" },
      { label: "+31" },
      { label: "+32" },
      { label: "+350" },
      { label: "+352" },
      { label: "+353" },
      { label: "+354" },
      { label: "+355" },
      { label: "+356" },
      { label: "+357" },
      { label: "+358" },
      { label: "+359" },
      { label: "+36" },
      { label: "+370" },
      { label: "+371" },
      { label: "+372" },
      { label: "+373" },
      { label: "+374" },
      { label: "+375" },
      { label: "+376" },
      { label: "+377" },
      { label: "+378" },
      { label: "+380" },
      { label: "+381" },
      { label: "+382" },
      { label: "+383" },
      { label: "+385" },
      { label: "+386" },
      { label: "+387" },
      { label: "+388" },
      { label: "+389" },
      { label: "+40" },
      { label: "+41" },
      { label: "+420" },
      { label: "+421" },
      { label: "+423" },
      { label: "+43" },
      { label: "+44" },
      { label: "+45" },
      { label: "+46" },
      { label: "+47" },
      { label: "+48" },
      { label: "+49" },
    ],
    timeZoneList: [
      { label: "Africa/Abidjan" },
      { label: "Africa/Accra" },
      { label: "Africa/Algiers" },
      { label: "Africa/Bissau" },
      { label: "Africa/Cairo" },
      { label: "Africa/Casablanca" },
      { label: "Africa/Ceuta" },
      { label: "Africa/El_Aaiun" },
      { label: "Africa/Johannesburg" },
      { label: "Africa/Juba" },
      { label: "Africa/Khartoum" },
      { label: "Africa/Lagos" },
      { label: "Africa/Maputo" },
      { label: "Africa/Monrovia" },
      { label: "Africa/Nairobi" },
      { label: "Africa/Ndjamena" },
      { label: "Africa/Sao_Tome" },
      { label: "Africa/Tripoli" },
      { label: "Africa/Tunis" },
      { label: "Africa/Windhoek" },
      { label: "America/Adak" },
      { label: "America/Anchorage" },
      { label: "America/Araguaina" },
      { label: "America/Argentina/Buenos_Aires" },
      { label: "America/Argentina/Catamarca" },
      { label: "America/Argentina/Cordoba" },
      { label: "America/Argentina/Jujuy" },
      { label: "America/Argentina/La_Rioja" },
      { label: "America/Argentina/Mendoza" },
      { label: "America/Argentina/Rio_Gallegos" },
      { label: "America/Argentina/Salta" },
      { label: "America/Argentina/San_Juan" },
      { label: "America/Argentina/San_Luis" },
      { label: "America/Argentina/Tucuman" },
      { label: "America/Argentina/Ushuaia" },
      { label: "America/Asuncion" },
      { label: "America/Atikokan" },
      { label: "America/Bahia" },
      { label: "America/Bahia_Banderas" },
      { label: "America/Barbados" },
      { label: "America/Belem" },
      { label: "America/Belize" },
      { label: "America/Blanc-Sablon" },
      { label: "America/Boa_Vista" },
      { label: "America/Bogota" },
      { label: "America/Boise" },
      { label: "America/Cambridge_Bay" },
      { label: "America/Campo_Grande" },
      { label: "America/Cancun" },
      { label: "America/Caracas" },
      { label: "America/Cayenne" },
      { label: "America/Chicago" },
      { label: "America/Chihuahua" },
      { label: "America/Costa_Rica" },
      { label: "America/Creston" },
      { label: "America/Cuiaba" },
      { label: "America/Curacao" },
      { label: "America/Danmarkshavn" },
      { label: "America/Dawson" },
      { label: "America/Dawson_Creek" },
      { label: "America/Denver" },
      { label: "America/Detroit" },
      { label: "America/Edmonton" },
      { label: "America/Eirunepe" },
      { label: "America/El_Salvador" },
      { label: "America/Fort_Nelson" },
      { label: "America/Fortaleza" },
      { label: "America/Glace_Bay" },
      { label: "America/Goose_Bay" },
      { label: "America/Grand_Turk" },
      { label: "America/Guatemala" },
      { label: "America/Guayaquil" },
      { label: "America/Guyana" },
      { label: "America/Halifax" },
      { label: "America/Havana" },
      { label: "America/Hermosillo" },
      { label: "America/Indiana/Indianapolis" },
      { label: "America/Indiana/Knox" },
      { label: "America/Indiana/Marengo" },
      { label: "America/Indiana/Petersburg" },
      { label: "America/Indiana/Tell_City" },
      { label: "America/Indiana/Vevay" },
      { label: "America/Indiana/Vincennes" },
      { label: "America/Indiana/Winamac" },
      { label: "America/Inuvik" },
      { label: "America/Iqaluit" },
      { label: "America/Jamaica" },
      { label: "America/Juneau" },
      { label: "America/Kentucky/Louisville" },
      { label: "America/Kentucky/Monticello" },
      { label: "America/La_Paz" },
      { label: "America/Lima" },
      { label: "America/Los_Angeles" },
      { label: "America/Maceio" },
      { label: "America/Managua" },
      { label: "America/Manaus" },
      { label: "America/Martinique" },
      { label: "America/Matamoros" },
      { label: "America/Mazatlan" },
      { label: "America/Menominee" },
      { label: "America/Merida" },
      { label: "America/Metlakatla" },
      { label: "America/Mexico_City" },
      { label: "America/Miquelon" },
      { label: "America/Moncton" },
      { label: "America/Monterrey" },
      { label: "America/Montevideo" },
      { label: "America/Nassau" },
      { label: "America/New_York" },
      { label: "America/Nipigon" },
      { label: "America/Nome" },
      { label: "America/Noronha" },
      { label: "America/North_Dakota/Beulah" },
      { label: "America/North_Dakota/Center" },
      { label: "America/North_Dakota/New_Salem" },
      { label: "America/Nuuk" },
      { label: "America/Ojinaga" },
      { label: "America/Panama" },
      { label: "America/Pangnirtung" },
      { label: "America/Paramaribo" },
      { label: "America/Phoenix" },
      { label: "America/Port-au-Prince" },
      { label: "America/Port_of_Spain" },
      { label: "America/Porto_Velho" },
      { label: "America/Puerto_Rico" },
      { label: "America/Punta_Arenas" },
      { label: "America/Rainy_River" },
      { label: "America/Rankin_Inlet" },
      { label: "America/Recife" },
      { label: "America/Regina" },
      { label: "America/Resolute" },
      { label: "America/Rio_Branco" },
      { label: "America/Santarem" },
      { label: "America/Santiago" },
      { label: "America/Santo_Domingo" },
      { label: "America/Sao_Paulo" },
      { label: "America/Scoresbysund" },
      { label: "America/Sitka" },
      { label: "America/St_Johns" },
      { label: "America/Swift_Current" },
      { label: "America/Tegucigalpa" },
      { label: "America/Thule" },
      { label: "America/Thunder_Bay" },
      { label: "America/Tijuana" },
      { label: "America/Toronto" },
      { label: "America/Vancouver" },
      { label: "America/Whitehorse" },
      { label: "America/Winnipeg" },
      { label: "America/Yakutat" },
      { label: "America/Yellowknife" },
      { label: "Antarctica/Casey" },
      { label: "Antarctica/Davis" },
      { label: "Antarctica/DumontDUrville" },
      { label: "Antarctica/Macquarie" },
      { label: "Antarctica/Mawson" },
      { label: "Antarctica/Palmer" },
      { label: "Antarctica/Rothera" },
      { label: "Antarctica/Syowa" },
      { label: "Antarctica/Troll" },
      { label: "Antarctica/Vostok" },
      { label: "Asia/Almaty" },
      { label: "Asia/Amman" },
      { label: "Asia/Anadyr" },
      { label: "Asia/Aqtau" },
      { label: "Asia/Aqtobe" },
      { label: "Asia/Ashgabat" },
      { label: "Asia/Atyrau" },
      { label: "Asia/Baghdad" },
      { label: "Asia/Baku" },
      { label: "Asia/Bangkok" },
      { label: "Asia/Barnaul" },
      { label: "Asia/Beirut" },
      { label: "Asia/Bishkek" },
      { label: "Asia/Brunei" },
      { label: "Asia/Chita" },
      { label: "Asia/Choibalsan" },
      { label: "Asia/Colombo" },
      { label: "Asia/Damascus" },
      { label: "Asia/Dhaka" },
      { label: "Asia/Dili" },
      { label: "Asia/Dubai" },
      { label: "Asia/Dushanbe" },
      { label: "Asia/Famagusta" },
      { label: "Asia/Gaza" },
      { label: "Asia/Hebron" },
      { label: "Asia/Ho_Chi_Minh" },
      { label: "Asia/Hong_Kong" },
      { label: "Asia/Hovd" },
      { label: "Asia/Irkutsk" },
      { label: "Asia/Jakarta" },
      { label: "Asia/Jayapura" },
      { label: "Asia/Jerusalem" },
      { label: "Asia/Kabul" },
      { label: "Asia/Kamchatka" },
      { label: "Asia/Karachi" },
      { label: "Asia/Kathmandu" },
      { label: "Asia/Khandyga" },
      { label: "Asia/Kolkata" },
      { label: "Asia/Krasnoyarsk" },
      { label: "Asia/Kuala_Lumpur" },
      { label: "Asia/Kuching" },
      { label: "Asia/Macau" },
      { label: "Asia/Magadan" },
      { label: "Asia/Makassar" },
      { label: "Asia/Manila" },
      { label: "Asia/Nicosia" },
      { label: "Asia/Novokuznetsk" },
      { label: "Asia/Novosibirsk" },
      { label: "Asia/Omsk" },
      { label: "Asia/Oral" },
      { label: "Asia/Pontianak" },
      { label: "Asia/Pyongyang" },
      { label: "Asia/Qatar" },
      { label: "Asia/Qostanay" },
      { label: "Asia/Qyzylorda" },
      { label: "Asia/Riyadh" },
      { label: "Asia/Sakhalin" },
      { label: "Asia/Samarkand" },
      { label: "Asia/Seoul" },
      { label: "Asia/Shanghai" },
      { label: "Asia/Singapore" },
      { label: "Asia/Srednekolymsk" },
      { label: "Asia/Taipei" },
      { label: "Asia/Tashkent" },
      { label: "Asia/Tbilisi" },
      { label: "Asia/Tehran" },
      { label: "Asia/Thimphu" },
      { label: "Asia/Tokyo" },
      { label: "Asia/Tomsk" },
      { label: "Asia/Ulaanbaatar" },
      { label: "Asia/Urumqi" },
      { label: "Asia/Ust-Nera" },
      { label: "Asia/Vladivostok" },
      { label: "Asia/Yakutsk" },
      { label: "Asia/Yangon" },
      { label: "Asia/Yekaterinburg" },
      { label: "Asia/Yerevan" },
      { label: "Atlantic/Azores" },
      { label: "Atlantic/Bermuda" },
      { label: "Atlantic/Canary" },
      { label: "Atlantic/Cape_Verde" },
      { label: "Atlantic/Faroe" },
      { label: "Atlantic/Madeira" },
      { label: "Atlantic/Reykjavik" },
      { label: "Atlantic/South_Georgia" },
      { label: "Atlantic/Stanley" },
      { label: "Australia/Adelaide" },
      { label: "Australia/Brisbane" },
      { label: "Australia/Broken_Hill" },
      { label: "Australia/Darwin" },
      { label: "Australia/Eucla" },
      { label: "Australia/Hobart" },
      { label: "Australia/Lindeman" },
      { label: "Australia/Lord_Howe" },
      { label: "Australia/Melbourne" },
      { label: "Australia/Perth" },
      { label: "Australia/Sydney" },
      { label: "CET" },
      { label: "CST6CDT" },
      { label: "EET" },
      { label: "EST" },
      { label: "EST5EDT" },
      { label: "Etc/GMT" },
      { label: "Etc/GMT+1" },
      { label: "Etc/GMT+10" },
      { label: "Etc/GMT+11" },
      { label: "Etc/GMT+12" },
      { label: "Etc/GMT+2" },
      { label: "Etc/GMT+3" },
      { label: "Etc/GMT+4" },
      { label: "Etc/GMT+5" },
      { label: "Etc/GMT+6" },
      { label: "Etc/GMT+7" },
      { label: "Etc/GMT+8" },
      { label: "Etc/GMT+9" },
      { label: "Etc/GMT-1" },
      { label: "Etc/GMT-10" },
      { label: "Etc/GMT-11" },
      { label: "Etc/GMT-12" },
      { label: "Etc/GMT-13" },
      { label: "Etc/GMT-14" },
      { label: "Etc/GMT-2" },
      { label: "Etc/GMT-3" },
      { label: "Etc/GMT-4" },
      { label: "Etc/GMT-5" },
      { label: "Etc/GMT-6" },
      { label: "Etc/GMT-7" },
      { label: "Etc/GMT-8" },
      { label: "Etc/GMT-9" },
      { label: "Etc/UTC" },
      { label: "Europe/Amsterdam" },
      { label: "Europe/Andorra" },
      { label: "Europe/Astrakhan" },
      { label: "Europe/Athens" },
      { label: "Europe/Belgrade" },
      { label: "Europe/Berlin" },
      { label: "Europe/Brussels" },
      { label: "Europe/Bucharest" },
      { label: "Europe/Budapest" },
      { label: "Europe/Chisinau" },
      { label: "Europe/Copenhagen" },
      { label: "Europe/Dublin" },
      { label: "Europe/Gibraltar" },
      { label: "Europe/Helsinki" },
      { label: "Europe/Istanbul" },
      { label: "Europe/Kaliningrad" },
      { label: "Europe/Kiev" },
      { label: "Europe/Kirov" },
      { label: "Europe/Lisbon" },
      { label: "Europe/London" },
      { label: "Europe/Luxembourg" },
      { label: "Europe/Madrid" },
      { label: "Europe/Malta" },
      { label: "Europe/Minsk" },
      { label: "Europe/Monaco" },
      { label: "Europe/Moscow" },
      { label: "Europe/Oslo" },
      { label: "Europe/Paris" },
      { label: "Europe/Prague" },
      { label: "Europe/Riga" },
      { label: "Europe/Rome" },
      { label: "Europe/Samara" },
      { label: "Europe/Saratov" },
      { label: "Europe/Simferopol" },
      { label: "Europe/Sofia" },
      { label: "Europe/Stockholm" },
      { label: "Europe/Tallinn" },
      { label: "Europe/Tirane" },
      { label: "Europe/Ulyanovsk" },
      { label: "Europe/Uzhgorod" },
      { label: "Europe/Vienna" },
      { label: "Europe/Vilnius" },
      { label: "Europe/Volgograd" },
      { label: "Europe/Warsaw" },
      { label: "Europe/Zaporozhye" },
      { label: "Europe/Zurich" },
      { label: "HST" },
      { label: "Indian/Chagos" },
      { label: "Indian/Christmas" },
      { label: "Indian/Cocos" },
      { label: "Indian/Kerguelen" },
      { label: "Indian/Mahe" },
      { label: "Indian/Maldives" },
      { label: "Indian/Mauritius" },
      { label: "Indian/Reunion" },
      { label: "MET" },
      { label: "MST" },
      { label: "MST7MDT" },
      { label: "PST8PDT" },
      { label: "Pacific/Apia" },
      { label: "Pacific/Auckland" },
      { label: "Pacific/Bougainville" },
      { label: "Pacific/Chatham" },
      { label: "Pacific/Chuuk" },
      { label: "Pacific/Easter" },
      { label: "Pacific/Efate" },
      { label: "Pacific/Enderbury" },
      { label: "Pacific/Fakaofo" },
      { label: "Pacific/Fiji" },
      { label: "Pacific/Funafuti" },
      { label: "Pacific/Galapagos" },
      { label: "Pacific/Gambier" },
      { label: "Pacific/Guadalcanal" },
      { label: "Pacific/Guam" },
      { label: "Pacific/Honolulu" },
      { label: "Pacific/Kiritimati" },
      { label: "Pacific/Kosrae" },
      { label: "Pacific/Kwajalein" },
      { label: "Pacific/Majuro" },
      { label: "Pacific/Marquesas" },
      { label: "Pacific/Nauru" },
      { label: "Pacific/Niue" },
      { label: "Pacific/Norfolk" },
      { label: "Pacific/Noumea" },
      { label: "Pacific/Pago_Pago" },
      { label: "Pacific/Palau" },
      { label: "Pacific/Pitcairn" },
      { label: "Pacific/Pohnpei" },
      { label: "Pacific/Port_Moresby" },
      { label: "Pacific/Rarotonga" },
      { label: "Pacific/Tahiti" },
      { label: "Pacific/Tarawa" },
      { label: "Pacific/Tongatapu" },
      { label: "Pacific/Wake" },
      { label: "Pacific/Wallis" },
      { label: "WET" },
    ],
    languageList: [
      {
        label: "English US",
      },
      {
        label: "English GB",
      },
      {
        label: "Italiano",
      },
      {
        label: "Français",
      },
      {
        label: "Português",
      },
      {
        label: "Español",
      },
    ],
    licenseTypeList: [
      { label: "AM" },
      { label: "A" },
      { label: "A1" },
      { label: "A2" },
      { label: "B" },
      { label: "B1" },
      { label: "BE" },
      { label: "C" },
      { label: "C1" },
      { label: "C1E" },
      { label: "CE" },
      { label: "D" },
      { label: "D1" },
      { label: "D1E" },
      { label: "DE" },
      { label: "D" },
    ],
    maintenanceTypes: [
      {
        value: "MAINTENANCE_CAR_SERVICE",
        label: t(
          "maintenance.table.maintenanceDeadlines.MAINTENANCE_CAR_SERVICE"
        ),
      },
      {
        value: "MAINTENANCE_TIRES",
        label: t("maintenance.table.maintenanceDeadlines.MAINTENANCE_TIRES"),
      },
      {
        value: "MAINTENANCE_INSPECTION",
        label: t(
          "maintenance.table.maintenanceDeadlines.MAINTENANCE_INSPECTION"
        ),
      },
      {
        value: "MAINTENANCE_CAR_BODYWORK",
        label: t(
          "maintenance.table.maintenanceDeadlines.MAINTENANCE_CAR_BODYWORK"
        ),
      },
      {
        value: "MAINTENANCE_ORDINARY",
        label: t("maintenance.table.maintenanceDeadlines.MAINTENANCE_ORDINARY"),
      },
      {
        value: "MAINTENANCE_EXTRAORDINARY",
        label: t(
          "maintenance.table.maintenanceDeadlines.MAINTENANCE_EXTRAORDINARY"
        ),
      },
      {
        value: "MAINTENANCE_CUSTOM",
        label: t("maintenance.table.maintenanceDeadlines.MAINTENANCE_CUSTOM"),
      },
    ],
    deadlineTypes: [
      {
        value: "INSURANCE",
        label: t("maintenance.table.maintenanceDeadlines.INSURANCE"),
      },
      {
        value: "TAXES",
        label: t("maintenance.table.maintenanceDeadlines.TAXES"),
      },
      {
        value: "REVISION",
        label: t("maintenance.table.maintenanceDeadlines.REVISION"),
      },
      {
        value: "CUSTOM",
        label: t("maintenance.table.maintenanceDeadlines.CUSTOM"),
      },
    ],
    companies: [
      { label: "Allianz" },
      { label: "Generali" },
      { label: "Italiana Asicurazioni" },
      { label: "Unipol" },
    ],
  };
};
