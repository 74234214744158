import {
  PayloadAction,
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import _ from "lodash";
import { normalize } from "normalizr";
import { RootState } from "../../../app/store";
import { Config } from "../../../config/Config";
import { GTFleetSuccessCodes } from "../../../config/GTFleetSuccessCodes";
import { vehicleStopsSummarySchema } from "./vehicleStopNormalization";
import VehicleStopsRepository from "./vehicleStopRepository";

//#region Type

export type RoutePosition = {
  latitude: number;
  longitude: number;
  gpsPositionTimestamp: Date;
  address: string;
};

export type VehicleStopSummary = {
  uuid: number;
  routeId: number;
  firstPosition: RoutePosition;
  lastPosition: RoutePosition;
  vehicleId: number;
  driverIds: number[];
  traveled: number;
  parkingTime: number;
  drivingTime: number;
  consumption: number;
  movingConsumption: number;
  idleConsumption: number;
  avgSpeed: number;
  maxSpeed: number;
  costs: number;
  idleTime: number;
  fuelsConsumption: { data: number; date: string }[];
  fuelsLevel: { data: number; date: string }[];
};

type PagingStateType = {
  pagingState?: string;
};

type PagingStateMapping = {
  page: number;
  pagingState: string;
};
//#endregion Type

//#region API

export const getVehicleLastStopsAsync = createAsyncThunk(
  "vehicleStopsSummary/getVehicleLastStops",
  async (data: { vehicleId: number; queryParams?: string }, thunkAPI) => {
    const vehicleStopsRepository = new VehicleStopsRepository();

    const response = await vehicleStopsRepository.getVehicleLastStops(
      data.vehicleId,
      data.queryParams
    );
    // The value we return becomes the `fulfilled` action payload
    const vehicleStopsSummary = _.get(
      response,
      Config.VEHICLE_LAST_STOPS_RESPONSE_PATH
    );

    const normalizedData = normalize(
      vehicleStopsSummary,
      vehicleStopsSummarySchema
    );
    return normalizedData.entities;
  }
);
//#endregion API

//#region Custom Functions
function setFilteredData(state: any, action: PayloadAction<any>) {
  action.payload.vehicleStopSummary
    ? vehicleStopsSummaryAdapter.setAll(
        state,
        action.payload.vehicleStopSummary
      )
    : vehicleStopsSummaryAdapter.setAll(state, []);
}
//#endregiorn Custom Functions

//#region Slice
const vehicleStopsSummaryAdapter = createEntityAdapter<VehicleStopSummary>({
  selectId: (vehicleStopSummary) => vehicleStopSummary.routeId,
  sortComparer: (a, b) =>
    a.routeId.toString().localeCompare(b.routeId.toString()),
});

export const vehicleStopsSummarySlice = createSlice({
  name: "vehicleStopsSummary",
  initialState: vehicleStopsSummaryAdapter.getInitialState({
    status: "idle",
    reasonCode: "",
    totalPages: 0,
    pagingStates: [] as PagingStateMapping[],
  }),
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setNumberOfPages: (state, action: PayloadAction<number>) => {
      state.totalPages = action.payload;
    },
    setNewPagingState: (
      state,
      action: PayloadAction<{
        page: number;
        pagingState: string;
      }>
    ) => {
      const pagingStates = state.pagingStates;
      const newPagingState = [...pagingStates];
      const pagingStateMap = newPagingState.find(
        (x) => x.page === action.payload.page
      );
      if (!pagingStateMap) {
        newPagingState.push({
          page: action.payload.page,
          pagingState: action.payload.pagingState,
        } as PagingStateMapping);
      }
      state.pagingStates = newPagingState;
    },
    vehicleStopsSummaryEmptyState: (state) => {
      vehicleStopsSummaryAdapter.setAll(state, []);
      state.status = "idle";
      state.reasonCode = "";
      state.pagingStates = [] as PagingStateMapping[];
      state.totalPages = 0;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      //#region Entity Reducers
      // getRoutesHistoryAsync extra reducers
      .addCase(
        getVehicleLastStopsAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          setFilteredData(state, action);
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.GET;
        }
      )
      .addCase(getVehicleLastStopsAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(getVehicleLastStopsAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = "";
      });
    //#endregion Entity Reducers
  },
});
//#endregion Slice

//#region Status
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const vehicleStopsSummarySelectors =
  vehicleStopsSummaryAdapter.getSelectors<RootState>(
    (state) => state.vehicleStopsSummary
  );

export const selectVehicleStopsSummarySliceStatus = (state: any) =>
  state.vehicleStopsSummary.status;
export const selectVehicleStopsSummarySliceReasonCode = (state: any) =>
  state.vehicleStopsSummary.reasonCode;
export const selectVehicleStopsSummarySlicePage = (state: any) =>
  state.vehicleStopsSummary.totalPages;
export const selectVehicleStopsSummarySlicePagingStates = (state: any) =>
  state.vehicleStopsSummary.pagingStates;
export const {
  setNumberOfPages,
  vehicleStopsSummaryEmptyState,
  setNewPagingState,
} = vehicleStopsSummarySlice.actions;
export default vehicleStopsSummarySlice.reducer;
//#endregion Status
