import { Cluster, MarkerClusterer } from "@googlemaps/markerclusterer";
import { ReactElement } from "react";
import {
  RouteEvent,
  RouteState,
} from "../../features/route/routesHistorySlice";
import { searchObject } from "../../utils/Utils";
import { MarkerAsComponent } from "../Map/MarkerAsComponent";
import { ClusterMarker } from "../Marker/ClusterMarker";
import { MapMarkerCluster } from "../Marker/MapMarkerCluster";
import { MapMarkerGps } from "../Marker/MapMarkerGps";
import { MapMarkerLocator } from "../Marker/MapMarkerLocator";
import "./Polyline.css";

/**
 * @todo RouteState type belongs to GTFleet project.
 * The GTFleet project uses the Design System (UI components).
 * Therefore, UI cannot use types of the target project. Remove
 * the RouteState type in favor of an internal type that serves the GTFleet project.
 * Problem: cyclic reference.
 */
export interface ClassPolylineProps {
  googleMap: any;
  map: any;
  batchPolyLine: RouteState[];
  initialCurrentPoint?: {
    start: number;
    stop: number;
    indexPoint: number;
  };
  viewPointMarkers?: boolean;
  viewEventMarkers?: boolean;
  hideEventColors?: boolean;
  color?: string;
  polylineMarkerStart?: ReactElement;
  polylineMarkerEnd?: ReactElement;
  hasArrowView?: boolean;
  infoWindow?: google.maps.InfoWindow;
}

export const ClassPolyline = ({
  googleMap,
  map,
  batchPolyLine,
  initialCurrentPoint,
  viewPointMarkers,
  viewEventMarkers,
  hideEventColors,
  color,
  polylineMarkerStart,
  polylineMarkerEnd,
}: ClassPolylineProps) => {
  class Polyline extends google.maps.Polyline {
    polyline: any = [];
    incrementalPolyline: any = [];
    gpsPointMarkers: any = [];
    currentPoint: ClassPolylineProps["initialCurrentPoint"] | undefined;
    markersList: any = [];
    cluster: any;
    arrows: any = [];
    clusterArrow: any = [];

    constructor() {
      super();
      if (batchPolyLine !== undefined) {
        this.currentPoint = initialCurrentPoint;
        this.polyline = this.settingPolyline();
        this.currentPoint && this.settingIncrementalPolyline(this.currentPoint);
        if (viewEventMarkers) this.gpsPointMarkers = this.settingMarker();
        viewPointMarkers ? this.onShowMarker() : this.onHideMarker();
      }
    }

    addMarker(
      image: JSX.Element,
      id: string,
      lat: number = 0,
      lng: number = 0,
      viewOnMap: any = map
    ) {
      return MarkerAsComponent({
        googleMap: googleMap,
        id: id,
        lat: lat,
        lng: lng,
        map: viewOnMap,
        component: image,
        onClick: () => {},
        showTooltip: false,
        isGeofence: false,
      });
    }

    chooseMarker(eventName: string) {
      if (polylineMarkerStart && eventName === "START") {
        return polylineMarkerStart;
      } else if (polylineMarkerEnd && eventName === "END") {
        return polylineMarkerEnd;
      }
    }

    getEventMarker(
      type: RouteEvent["type"],
      eventName: string,
      lat: number,
      lng: number
    ) {
      //TODO: generate different markers based on type

      return this.addMarker(
        <>{this.chooseMarker(eventName)}</>,
        "marker-" + type.severity + "-" + eventName,
        lat,
        lng
      );
    }

    settingPolyline() {
      let _polyline = [] as any;
      if (!batchPolyLine) return _polyline;

      const lenghtPoint: any = batchPolyLine?.length;
      let tempPolyline = [] as any;
      this.cluster && this.cluster.setMap(map);
      for (let i = 0; i < lenghtPoint; i++) {
        const showPoint: RouteState = batchPolyLine[i];
        const showPointNext: RouteState = batchPolyLine[i + 1];
        const showPointPrev: RouteState = batchPolyLine[i - 1];

        const addPoint = new google.maps.LatLng(
          showPoint?.dynamicFields.latitude,
          showPoint?.dynamicFields.longitude
        );

        let tempMarker: any;

        let drawnRoute = false;
        if (viewEventMarkers) {
          for (let j = 0; j < showPoint?.events.length; j++) {
            const eventName = showPoint?.events[j]?.type.name || "UNKNOWN";
            const eventSeverity =
              showPoint?.events[j]?.type.severity || "UNKNOWN";
            if (eventName.includes("START") || eventName.includes("END")) {
              tempMarker = this.getEventMarker(
                showPoint?.events[j]?.type || "INFO",
                eventName,
                showPoint?.dynamicFields.latitude,
                showPoint?.dynamicFields.longitude
              );
            }

            if (eventSeverity.includes("ALARM") && !hideEventColors) {
              if (
                showPointPrev?.events &&
                searchObject(showPointPrev?.events, "ALARM")
              ) {
                tempPolyline[tempPolyline.length - 1].point.push(addPoint);
                drawnRoute = true;
              } else if (
                showPointNext?.events &&
                searchObject(showPointNext?.events, "ALARM")
              ) {
                tempPolyline.length &&
                  tempPolyline[tempPolyline.length - 1].point.push(addPoint);
                const tempPoly = {
                  point: [addPoint],
                  color: "#ff4f48",
                };

                tempPolyline = [...tempPolyline, tempPoly];
                drawnRoute = true;
              }
            }

            if (eventSeverity.includes("WARNING") && !hideEventColors) {
              if (
                showPointPrev?.events &&
                searchObject(showPointPrev?.events, "WARNING")
              ) {
                tempPolyline[tempPolyline.length - 1].point.push(addPoint);
                drawnRoute = true;
              } else if (
                showPointNext?.events &&
                searchObject(showPointNext?.events, "WARNING")
              ) {
                tempPolyline.length &&
                  tempPolyline[tempPolyline.length - 1].point.push(addPoint);
                const tempPoly = {
                  point: [addPoint],
                  color: "#fbcd03",
                };

                tempPolyline = [...tempPolyline, tempPoly];
                drawnRoute = true;
              }
            }
          }
        }
        if (!drawnRoute && !!showPointNext) {
          if (
            !(
              showPointPrev?.events.length &&
              (searchObject(showPointPrev?.events, "ALARM") ||
                searchObject(showPointPrev?.events, "WARNING"))
            ) &&
            i !== 0
          ) {
            tempPolyline[tempPolyline.length - 1].point.push(addPoint);
          } else {
            let tempPoly = {};
            if (!!showPointPrev) {
              tempPoly = {
                point: [
                  new google.maps.LatLng(
                    showPointPrev?.dynamicFields.latitude,
                    showPointPrev?.dynamicFields.longitude
                  ),
                  addPoint,
                ],
                color: color ?? "#0052BD",
              };
            } else {
              tempPoly = {
                point: [addPoint],
                color: color ?? "#0052BD",
              };
            }
            tempPolyline = [...tempPolyline, tempPoly];
          }
        } else if (!drawnRoute) {
          if (tempPolyline.length > 0) {
            tempPolyline[tempPolyline.length - 1].point.push(addPoint);
          }
        }
        if (tempMarker) {
          _polyline = [..._polyline, tempMarker];
          this.markersList.push(tempMarker);
        }
      }

      tempPolyline.forEach((element: any) => {
        _polyline = [
          ..._polyline,
          new google.maps.Polyline({
            path: element.point,
            geodesic: true,
            strokeColor: element.color,
            strokeOpacity: 1.0,
            strokeWeight: 5,
            map: map,
          }),
        ];
      });

      return _polyline;
    }

    returnMarker(object: any): any {
      return object.find((element: any) => {
        if (element.id && element.id === "batchPolyLineMarker") {
          return element;
        }
        return null;
      });
    }

    drawingCluster() {
      // this method is in charge add custom cluster component for googleMarkerClusterer library
      const renderer = {
        render(__namedParameters: Cluster) {
          return MarkerAsComponent({
            id: "markerCluster",
            googleMap: googleMap,
            lat: __namedParameters.position.lat(),
            lng: __namedParameters.position.lng(),
            map: map,
            onClick: () => {
              map.fitBounds(__namedParameters.bounds, 100);
            },
            component: <ClusterMarker hasChart={false} text={`A|B`} />,
          });
        },
      };

      // this method use MarkerClusterer library to create cluster based on render method
      this.cluster = new MarkerClusterer({
        map: map,
        markers: this.markersList as google.maps.Marker[],
        renderer: renderer,
      });
    }

    drawingIncremental(indexPoint: number) {
      let gpsPoints = [] as any;
      if (batchPolyLine) {
        for (let i = 0; i <= indexPoint; i++) {
          gpsPoints = [
            ...gpsPoints,
            {
              lat: Number(batchPolyLine[i]?.dynamicFields.latitude),
              lng: Number(batchPolyLine[i]?.dynamicFields.longitude),
            },
          ];
        }
      }
      return gpsPoints;
    }

    //Controllare nell'esecuzione: se è pesante, rifatturizzare magari facendo una polilinea
    //incrementale unica e nel momento in cui viene incrementata o decrementata viene
    //cancellata la precedente e ridisegnata la nuova.
    settingIncrementalPolyline(
      newCurrentPoint: ClassPolylineProps["initialCurrentPoint"]
    ) {
      if (!batchPolyLine) return;

      if (!newCurrentPoint) return;

      if (!this.incrementalPolyline.length) {
        let firstDirection: number = 0;
        batchPolyLine.forEach((point) => {
          if (
            point?.dynamicFields?.direction &&
            point?.dynamicFields?.direction > 0
          ) {
            firstDirection = point?.dynamicFields?.direction;
          }
        });
        const tempMarkerPolyline = this.addMarker(
          <MapMarkerLocator rotate={firstDirection} />,
          "batchPolyLineMarker",
          batchPolyLine[newCurrentPoint.indexPoint]?.dynamicFields.latitude,
          batchPolyLine[newCurrentPoint.indexPoint]?.dynamicFields.longitude
        );

        // Add cluster to map
        this.drawingCluster();

        this.incrementalPolyline = [
          ...this.incrementalPolyline,
          tempMarkerPolyline,
        ];

        if (newCurrentPoint.indexPoint > 0) {
          this.incrementalPolyline = [
            ...this.incrementalPolyline,
            new google.maps.Polyline({
              path: this.drawingIncremental(newCurrentPoint.indexPoint),
              geodesic: true,
              strokeColor: "#00AAFF",
              strokeOpacity: 1.0,
              strokeWeight: 5,
              map: map,
              zIndex: 2800,
            }),
          ];
        }
      }
      if (newCurrentPoint === this.currentPoint) return;
      this.currentPoint = newCurrentPoint;

      const markerPolylineTemp = this.returnMarker(this.incrementalPolyline);
      markerPolylineTemp.setMap(null);

      this.incrementalPolyline.forEach((item: any) => {
        if (!item.id) {
          item.getPath().clear();
          item.setMap(null);
        }
      });
      this.incrementalPolyline.splice(1, this.incrementalPolyline.length - 1);

      markerPolylineTemp.setPosition(
        new google.maps.LatLng(
          batchPolyLine[newCurrentPoint.indexPoint]?.dynamicFields.latitude ||
            0,
          batchPolyLine[newCurrentPoint.indexPoint]?.dynamicFields.longitude ||
            0
        )
      );
      if (markerPolylineTemp) {
        markerPolylineTemp.setComponent(
          <MapMarkerLocator
            rotate={
              batchPolyLine[newCurrentPoint.indexPoint]?.dynamicFields.direction
            }
          />
        );
      }

      markerPolylineTemp.setMap(map);

      this.incrementalPolyline = [
        ...this.incrementalPolyline,
        new google.maps.Polyline({
          path: this.drawingIncremental(newCurrentPoint.indexPoint),
          geodesic: true,
          strokeColor: "#00AAFF",
          strokeOpacity: 1.0,
          strokeWeight: 5,
          map: map,
          zIndex: 2800,
        }),
      ];
    }

    settingMarker() {
      let _polyline = [] as any;
      batchPolyLine &&
        batchPolyLine.forEach((item: any) => {
          const events: RouteEvent[] = item?.events;
          let lengthEvents = events.length;
          events.length &&
            events.forEach((element: any) => {
              if (element.name === "START" || element.name === "END") {
                lengthEvents--;
              }
              const tempMarkerPoint = !(element && element.color)
                ? this.addMarker(
                    <MapMarkerGps />,
                    "event",
                    item.dynamicFields.latitude,
                    item.dynamicFields.longitude,
                    null
                  )
                : this.addMarker(
                    <MapMarkerGps isRed={true} color={element.color} />,
                    "event",
                    item.dynamicFields.latitude,
                    item.dynamicFields.longitude,
                    null
                  );
              _polyline = [..._polyline, tempMarkerPoint];
            });

          if (lengthEvents > 1) {
            const numberEvents = this.addMarker(
              <MapMarkerCluster number={events.length.toString()} />,
              "eventsNumber",
              item.dynamicFields.latitude,
              item.dynamicFields.longitude,
              null
            );
            _polyline = [..._polyline, numberEvents];
          } else if (!events.length) {
            const tempMarkerPoint = this.addMarker(
              <MapMarkerGps />,
              "event",
              item.dynamicFields.latitude,
              item.dynamicFields.longitude,
              null
            );
            _polyline = [..._polyline, tempMarkerPoint];
          }
        });
      return _polyline;
    }

    onShowMarker() {
      let _polyline = this.gpsPointMarkers;
      _polyline.forEach((item: any) => {
        item.setMap(map);
      });
    }

    onHideMarker() {
      let polyline = this.gpsPointMarkers;
      polyline.forEach((item: any) => {
        item.setMap(null);
      });
    }

    onRemove() {
      if (this.clusterArrow?.clusters?.length > 0) {
        this.clusterArrow && this.clusterArrow.clearMarkers();
      }

      if (this.markersList.length > 0) {
        this.cluster && this.cluster.clearMarkers();
      }

      this.markersList.map((e: any) => e.setMap(null));
      let _polyline = this.polyline;
      _polyline.forEach((item: any) => {
        !item.id && item.getPath().clear();
        item.setMap(null);
      });
      _polyline = [];

      let _incrementalPolyline = this.incrementalPolyline;
      _incrementalPolyline.forEach((item: any) => {
        !item.id && item.getPath().clear();
        item.setMap(null);
      });
      _incrementalPolyline = [];

      let _viewMarkerOnPolyline = this.gpsPointMarkers;
      _viewMarkerOnPolyline.forEach((item: any) => {
        item.setMap(null);
      });
      _viewMarkerOnPolyline = [];
    }

    onRemoveArrows() {
      let _arrows = this.arrows;
      _arrows?.forEach((item: any) => {
        item.setMap(null);
      });
      _arrows = [];
    }
  }

  return new Polyline();
};
