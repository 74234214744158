import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { store } from "../../../app/store";
import {
  GeofenceCategory,
  updateGeofenceCategoryAsync,
} from "../../../features/geofenceCategory/geofenceCategoriesSlice";
import { ColorPicker } from "../../../ui/Forms/ColorPicker";
import Form from "../../../ui/Forms/Form";
import TextField from "../../../ui/Forms/TextField";
import { LabelText } from "../../../ui/LabelText/LabelText";
import { ModalBody } from "../../../ui/Modal/ModalBody";
import { ModalFooter } from "../../../ui/Modal/ModalFooter";
import { ModalWrapper } from "../../../ui/Modal/ModalWrapper";
import "./EditCategoryModal.css";

interface DefaultValuesProps {
  id: number;
  name: string;
  color: string;
}

interface EditCategoryModalProps {
  open: boolean;
  onClose: (e?: any) => any;
  defaultValues: DefaultValuesProps;
}

export const EditCategoryModal: React.FC<EditCategoryModalProps> = ({
  open = false,
  onClose,
  defaultValues,
}) => {
  //#region const
  const colors = [
    ["#000000", "#493802", "#7A3CA1", "#B089C6", "#6667B9"],
    ["#0265B3", "#66A3D2", "#007F4D", "#FBCD03", "#FEF305"],
    ["#F57631", "#F7AB82", "#FF6766", "#FFCCCB", "#A8272B"],
  ];
  const [category, setCategory] = useState({
    id: !_.isEmpty(defaultValues) ? defaultValues.id : -1,
    name: !_.isEmpty(defaultValues) ? defaultValues.name : "",
    color: !_.isEmpty(defaultValues) ? defaultValues.color : "",
  } as DefaultValuesProps);

  const [errorList, setErrorList] = useState([]);
  const { t } = useTranslation();

  const [editCategory, setEditCategory] = useState({} as GeofenceCategory);
  const [editName, setEditName] = useState<boolean>(false);
  const [editColor, setEditColor] = useState<boolean>(false);
  //#endregion

  useEffect(() => {
    setEditCategory({
      ...editCategory,
      name: category?.name ?? "",
      color: category?.color ?? "",
    });
  }, []);

  // dispatch for update category
  const handleSubmit = () => {
    if (!_.isEmpty(defaultValues)) {
      store.dispatch(
        updateGeofenceCategoryAsync({
          id: defaultValues.id,
          geofenceCategory: editCategory,
        })
      );
    }
  };

  return (
    <div className="edit-category-container">
      <ModalWrapper open={open} closeAction={onClose}>
        <div className="edit-category">
          <ModalBody
            title={t("breadcrumbs.editCategory")}
            desc={defaultValues?.name}
          >
            <Form>
              <div className="left-forms">
                <LabelText
                  text={`${t("customModals.category.categoryName")}`}
                />
              </div>
              <div className="right-forms">
                <TextField
                  id="Name"
                  placeholder={`${t(
                    "customModals.category.categoryNamePlaceholder"
                  )}`}
                  validate="alphaNumeric"
                  errorList={setErrorList}
                  events={{
                    onChange: (data) => {
                      setCategory({ ...category, name: data.value });
                      if (data.value !== category?.name) {
                        setEditName(true);
                        setEditCategory({
                          ...editCategory,
                          name: data.value,
                        });
                      } else {
                        setEditName(false);
                        setEditCategory({
                          ...editCategory,
                          name: category.name,
                        });
                      }
                    },
                  }}
                  value={category.name}
                />
              </div>
            </Form>
            <Form>
              <div className="left-forms">
                <LabelText text={`${t("customModals.category.color")}`} />
              </div>
              <div className="right-forms">
                <ColorPicker
                  colors={colors}
                  onClick={(data) => {
                    setCategory({ ...category, color: data });
                    if (data !== category?.color) {
                      setEditColor(true);
                      setEditCategory({
                        ...editCategory,
                        color: data,
                      });
                    } else {
                      setEditColor(false);
                      setEditCategory({
                        ...editCategory,
                        color: category.color,
                      });
                    }
                  }}
                  defaultColor={
                    !_.isEmpty(defaultValues) ? defaultValues.color : ""
                  }
                />
              </div>
            </Form>
          </ModalBody>
          <div className="edit-category-custom-footer">
            <ModalFooter
              primaryLabel={`${t("customModals.category.primaryButton")}`}
              primaryAction={() => {
                handleSubmit();
                onClose();
              }}
              disablePrimaryButton={
                !(editName || editColor) ||
                category.name === "" ||
                category.color === "" ||
                errorList.length !== 0
              }
              secondaryLabel={t("common.back")}
              secondaryAction={onClose}
            />
          </div>
        </div>
      </ModalWrapper>
    </div>
  );
};
