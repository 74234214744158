import PropTypes from "prop-types";
import { useState } from "react";
import { Tab } from "./Tab";

import "./Tabs.css";

export const Tabs = ({ children, enableTab, onClick }) => {
  const [activeTab, setActiveTab] = useState(
    enableTab || children[0] && children[0].props && children[0].props.label && children[0].props.label
  );

  const onClickTabItem = (tab) => {
    setActiveTab(tab);
    onClick && onClick(tab);
  };

  return (
    <div className="mn-tabs">
      <ol className="mn-tabs__list">
        {children.map((child) => {
          if (child && child.props && child.props.label !== "") {
            const { label, icon } = child.props;

            return (
              <Tab
                activeTab={activeTab}
                icon={icon}
                key={label}
                label={label}
                onClickHandle={onClickTabItem}
              />
            );
          } else {
            return null;
          }
        })}

      </ol>
      <div className="mn-tabs__content">
        {children.map((child) => {
          if (child && child.props && child.props.label !== activeTab) return undefined;
          return child?.props?.children;
        })}
      </div>
    </div>
  );
};

Tabs.propTypes = {
  children: PropTypes.instanceOf(Array).isRequired,
  enableTab: PropTypes.string,
  onClick: PropTypes.func,
};
