import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import _ from "lodash";
import { normalize } from "normalizr";
import { RootState } from "../../app/store";
import { Config } from "../../config/Config";
import { GTFleetSuccessCodes } from "../../config/GTFleetSuccessCodes";
import {
  TypeOfVehicleType,
  VehicleStatusType,
} from "../../ui/Vehicles/VehicleTypes";
import { getErrorCodes } from "../../utils/Utils";
import { vehiclesDetailsSchema } from "./vehicleNormalization";
import VehiclesRepository from "./vehiclesRepository";
import { getVehiclesViewAsync } from "./vehiclesStatusViewSlice";

//#region Type
export interface VehicleView {
  id: number;
  alias: string;
  plate: string;
  model: string;
  imageUrl: string;
  type: TypeOfVehicleType;
  status: VehicleStatusType;
  driver: number;
  tenant: number;
  fleet: number;
  vehicleStatus: number;
  maintenance: boolean;
}
const vehiclesViewAdapter = createEntityAdapter<VehicleView>({
  selectId: (vehicleView) => vehicleView.id,
});
//#endregion Type

//#region API
export const getFilteredVehiclesDetailsViewsAsync = createAsyncThunk(
  "vehicles/getFilteredVehiclesDetailsViews",
  async (queryParams: string, { rejectWithValue }) => {
    try {
      const vehiclesRepository = new VehiclesRepository();
      const response = await vehiclesRepository.getVehiclesDetailsViews(
        queryParams
      );
      const vehicles = _.get(
        response,
        Config.VEHICLE_DETAILS_VIEWS_RESPONSE_PATH
      );
      if (vehicles) {
        const normalizedData = normalize(vehicles, vehiclesDetailsSchema);
        return normalizedData.entities;
      } else {
        return [];
      }
    } catch (err: any) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data.message);
    }
  }
);
//#endregion

//#region Custom Functions
function setFilteredData(state: any, action: PayloadAction<any>) {
  action.payload.vehicle
    ? vehiclesViewAdapter.setAll(state, action.payload.vehicle)
    : vehiclesViewAdapter.setAll(state, []);
}
//#endregiorn Custom Functions

//#region Slice
export const vehicleViewSlice = createSlice({
  name: "vehiclesView",
  initialState: vehiclesViewAdapter.getInitialState({
    status: "idle",
    reasonCode: "",
  }),
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    vehiclesViewEmptyState: (state) => {
      vehiclesViewAdapter.setAll(state, []);
      state.reasonCode = "";
      state.status = "idle";
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      //#region External Entity Reducers
      .addCase(
        getVehiclesViewAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          setFilteredData(state, action);
        }
      )
      .addCase(
        getFilteredVehiclesDetailsViewsAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          setFilteredData(state, action);
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.GET;
        }
      )
      .addCase(
        getFilteredVehiclesDetailsViewsAsync.rejected,
        (state: any, action: PayloadAction<any>) => {
          state.status = "failed";
          state.reasonCode = getErrorCodes(action.payload);
        }
      )
      .addCase(getFilteredVehiclesDetailsViewsAsync.pending, (state: any) => {
        state.status = "loading";
      });
    //#endregion External Entity Reducers
  },
});
//#endregion Slice
export const vehiclesViewSelectors =
  vehiclesViewAdapter.getSelectors<RootState>((state) => state.vehiclesView);

export const selectVehiclesViewSliceStatus = (state: any) =>
  state.vehiclesView.status;
export const selectVehiclesViewSliceReasonCode = (state: any) =>
  state.vehiclesView.reasonCode;
export const { vehiclesViewEmptyState } = vehicleViewSlice.actions;

export default vehicleViewSlice.reducer;
