import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import _ from "lodash";
import { normalize } from "normalizr";
import { RootState } from "../../app/store";
import { Config } from "../../config/Config";
import { GTFleetErrorCodes } from "../../config/GTfleetErrorCodes";
import { GTFleetSuccessCodes } from "../../config/GTFleetSuccessCodes";
import { chatAliasesSchema, chatsSchema } from "./chatNormalization";
import ChatRepository from "./chatRepository";

//#region Type
export const RecipientTypeEnum = {
  DRIVER: "DRIVER",
  USER: "USER",
  ADDRESS_BOOK: "ADDRESS_BOOK",
};
export type RecipientType = keyof typeof RecipientTypeEnum;

export type ChatAliasType = {
  id: number;
  alias: string;
  recipientTypeEnum: RecipientType;
  entityId: number;
  tenantId: number;
};

export type Recipient = {
  recipient: number;
  type: RecipientType;
  phone: string;
};

//#endregion Type

export const getChatAliasesAsync = createAsyncThunk(
  "chatsAliases/getChatAliases",
  async (data: { queryParams?: string }, { rejectWithValue }) => {
    try {
      const chatRepository = new ChatRepository();
      const response = await chatRepository.getChatAliases(data.queryParams);
      const chatAliases = _.get(
        response,
        Config.CHATALIASES_DETAILS_RESPONSE_PATH
      );
      const normalizedData = normalize(chatAliases, chatAliasesSchema);
      return normalizedData.entities;
    } catch (error: any) {
      if (!error.response) throw error;
      return rejectWithValue(error.response.data.status);
    }
  }
);

const chatsAliasesAdapter = createEntityAdapter<ChatAliasType>({
  selectId: (chat) => chat.id,
  sortComparer: (a, b) => a.id - b.id,
});

export const chatsAliasesSlice = createSlice({
  name: "chatsAliases",
  initialState: chatsAliasesAdapter.getInitialState({
    status: "idle",
    reasonCode: "",
  }),
  reducers: {
    chatsEmptyState: (state) => {
      chatsAliasesAdapter.setAll(state, []);
      state.reasonCode = "";
      state.status = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getChatAliasesAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(
        getChatAliasesAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          chatsAliasesAdapter.upsertMany(state, action.payload.chatAlias ?? []);
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.GET;
        }
      )
      .addCase(getChatAliasesAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = GTFleetErrorCodes.INTERNAL_SERVER_ERROR;
      });
  },
});

export const chatAliasesSelector = chatsAliasesAdapter.getSelectors<RootState>(
  (state) => state.chatsAliases
);

export const selectChatsSliceStatus = (state: any) => state.chatsAliases.status;
export const selectChatsSliceReasonCode = (state: any) =>
  state.chatsAliases.reasonCode;

export const { chatsEmptyState } = chatsAliasesSlice.actions;
export default chatsAliasesSlice.reducer;
