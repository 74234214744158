import { t } from "i18next";
import _ from "lodash";
import { useEffect, useRef, useState } from "react";
import {
  Location,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { store } from "../../app/store";
import { Button } from "../../ui/Button/Button";
import { DropdownButton } from "../../ui/Button/DropdownButton";
import { Dropdown } from "../../ui/Dropdown/Dropdown";
import Form from "../../ui/Forms/Form";
import SearchField from "../../ui/Forms/SearchField";
import { IconAdd } from "../../ui/Icon/Line/Add";
import { IconEdit } from "../../ui/Icon/Line/Edit";
import { IconGeofence } from "../../ui/Icon/Line/Geofence";
import { getQueryString } from "../../utils/Utils";
import { geofenceCategoriesSelectors } from "../geofenceCategory/geofenceCategoriesSlice";
import "./GeofenceFilterBar.css";
import {
  GeofenceStatusEnum,
  geofencesEmptyState,
  getFilteredGeofencesAsync,
} from "./geofenceSlice";

interface QueryParams {
  [paramName: string]: any;
}

interface StatusItem {
  key: string;
  label: string;
}

interface DropDownItem {
  key: string;
  label: string;
}

interface GeofenceFilterBarProps {
  callback: (buildQueryParam: string) => any;
}

export const GeofenceFilterBar: React.FC<GeofenceFilterBarProps> = ({
  callback,
}) => {
  const queryParamsRef = useRef<QueryParams>({});
  const navigate = useNavigate();
  let queryParams: QueryParams = queryParamsRef.current;

  const [geofenceStringSearch, setGeofenceStringSearch] = useState<string>("");
  const [checkedStatusesValues, setCheckedStatusesValues] = useState<
    DropDownItem[]
  >([]);

  const categories = geofenceCategoriesSelectors.selectAll(store.getState());
  const groupsValues: DropDownItem[] = !!categories
    ? categories.map((value) => {
        return {
          label: value.name,
          key: value.id.toString(),
          color: value?.color,
        };
      })
    : [];

  // Handle routes
  const [searchParams] = useSearchParams();
  const location: Location = useLocation();

  useEffect(() => {
    return function cleanUp() {
      store.dispatch(geofencesEmptyState());
    };
  }, []);

  useEffect(() => {
    const searchGeofence = new Map();
    const currentSearchParams =
      searchParams.toString() !== "" ? searchParams : null;
    if (!!currentSearchParams && _.isEmpty(queryParams)) {
      const size = currentSearchParams.get("size");
      searchGeofence.set("size", size ?? "10");
      searchGeofence.set("sort", "name,ASC");
      // Search Field
      const nameSearch = currentSearchParams.get("name");
      if (nameSearch) {
        setGeofenceStringSearch(nameSearch);
        searchGeofence.set("name", nameSearch);
      }
      // Statuses Dropdown
      const geofencesStatus = currentSearchParams?.getAll("geofenceStatus");
      if (!!geofencesStatus) {
        const selectedStatuses: DropDownItem[] = [];
        geofencesStatus.forEach((geofenceStatus) => {
          selectedStatuses.push({
            key: geofenceStatus,
            label: t(
              "admin.geofences.geofenceFilterBar.statuses." +
                geofenceStatus.toLowerCase()
            ),
          } as DropDownItem);
        });

        if (selectedStatuses && selectedStatuses.length > 0) {
          setCheckedStatusesValues(selectedStatuses);
          searchGeofence.set(
            "geofenceStatus",
            selectedStatuses.map((x) => x.key)
          );
        }
      }
    }
    if (searchGeofence.size === 0 && _.isEmpty(queryParams)) {
      searchGeofence.set("size", "10");
      searchGeofence.set("sort", "name,ASC");
    }
    if (searchGeofence.size > 0) {
      handleChanges(searchGeofence);
    }
  }, [location]);

  const handleChanges = (params: Map<string, string[] | string>): void => {
    let queryString = "";
    let executeQuery = false;
    if (!!params) {
      params.forEach((value, key) => {
        if (!!value && value.length > 0) {
          queryParams[key] = value;
          executeQuery = true;
        } else {
          if (queryParams.hasOwnProperty(key)) {
            delete queryParams[key];
            executeQuery = true;
          }
        }
      });
      queryString = getQueryString(queryParams);
    }
    // effettuare chiamata API con parametri in query string
    if (executeQuery) {
      store.dispatch(getFilteredGeofencesAsync({ queryParams: queryString }));
      navigate({
        pathname: "/admin/geofences",
        search: queryString,
      });
      callback(queryString);
    }
  };

  const statusesValues: StatusItem[] =
    Object.values(GeofenceStatusEnum)
      .filter((value) => typeof value === "string" && value !== "DELETED")
      .map((value) => {
        return {
          label: t(
            "admin.geofences.geofenceFilterBar.statuses." + value.toLowerCase()
          ),
          key: value,
        };
      }) ?? [];

  const categoriesList = [
    {
      id: 0,
      title: t("admin.geofences.geofenceFilterBar.categories.addCategory"),
      icon: <IconAdd size={14} color="--global-colors-ink-light" />,
      onClick: () =>
        navigate({
          pathname: "/admin/geofences/add-category",
          search: searchParams.toString(),
        }),
    },
    {
      id: 1,
      title: t("admin.geofences.geofenceFilterBar.categories.editCategory"),
      icon: <IconEdit size={14} color="--global-colors-ink-light" />,
      onClick: () =>
        navigate({
          pathname: "/admin/geofences/edit-categories",
          search: searchParams.toString(),
        }),
    },
  ];

  const debouncedSearch = useRef(
    _.debounce(async (val) => {
      setGeofenceStringSearch(val);
      const searchGeofenceName = new Map();
      searchGeofenceName.set("name", val);
      handleChanges(searchGeofenceName);
    }, 1000)
  ).current;

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  async function handleSearch(val: string) {
    await debouncedSearch(val);
  }

  return (
    <div className="geofence-filterbar-row">
      <div className="geofence-filterbar-search">
        <Form>
          <SearchField
            name="search"
            id="geofences"
            size="small"
            placeholder={t("admin.geofences.geofenceFilterBar.search")}
            value={geofenceStringSearch}
            onChange={(val: string) => {
              handleSearch(val);
            }}
          />
        </Form>
      </div>
      <div className="geofence-filterbar-statuses">
        <Dropdown
          placeholderInValue={t(
            "fleetControl.filterBar.placeholderInValStatus"
          )}
          itemAttribute="label"
          hasCheckbox={true}
          placeholder={t("fleetControl.filterBar.orderByStatus")}
          size={"small"}
          onChange={(val: DropDownItem[]) => {
            const map = new Map();
            const statusValues: string[] = [];
            val.forEach((v) => {
              const statusValue = statusesValues.find(
                (statusValue) => statusValue.key === v.key
              )?.key;
              if (statusValue) {
                statusValues.push(statusValue);
              }
            });
            map.set("geofenceStatus", statusValues);
            handleChanges(map);
          }}
          options={statusesValues}
          value={checkedStatusesValues}
        />
      </div>
      <div className="geofence-filterbar-dropdown-categories">
        <Dropdown
          placeholderInValue={t(
            "admin.geofences.geofenceFilterBar.categories.categories"
          )}
          hasCheckbox={true}
          itemAttribute="label"
          placeholder={t(
            "admin.geofences.geofenceFilterBar.categories.categories"
          )}
          size={"small"}
          onChange={(val: DropDownItem[]) => {
            const map = new Map();
            const categoriesValues: string[] = [];
            val.forEach((v) => {
              const categoryValue = groupsValues.find(
                (categoryValue) => categoryValue.key === v.key
              )?.key;
              if (categoryValue) {
                categoriesValues.push(categoryValue);
              }
            });
            map.set("geofenceCategory.id", categoriesValues);
            handleChanges(map);
          }}
          options={groupsValues}
        />
      </div>
      <div className="geofence-filterbar-categories">
        <DropdownButton
          aspect="secondary"
          size="small"
          list={categoriesList}
          label={t("admin.geofences.geofenceFilterBar.categories.categories")}
        >
          <IconGeofence size={14} color="--global-colors-ink-ink" />
        </DropdownButton>
      </div>
      <div className="geofence-filterbar-addGeofence">
        <Button
          aspect="primary"
          size="small"
          label={t("admin.geofences.geofenceFilterBar.addGeofence")}
          onClick={() => navigate("/admin/geofences/create-geofence")}
        >
          <IconAdd size={14} color="--global-colors-ink-white" />
        </Button>
      </div>
    </div>
  );
};
