import HttpClient from "../../../utils/HttpClient";
import { Preset } from "./presetsSlice";

export default class PresetsRepository extends HttpClient {
  constructor() {
    super(true);
  }

  getPresets(customQueryParams?: string) {
    const tenantId = localStorage.getItem("tenantId");
    const userId = localStorage.getItem("customerId");
    let path = "/tenants/" + tenantId + "/users/" + userId + "/presets";
    let queryParams = "";
    if (customQueryParams) {
      queryParams = customQueryParams;
    } else {
      queryParams = "?";
    }
    return this.instance.get(path + queryParams);
  }

  partiallyUpdatePreset(
    id: number,
    preset: { [key: string]: string | boolean | number }
  ) {
    const tenantId = localStorage.getItem("tenantId");
    const userId = localStorage.getItem("customerId");
    return this.instance.patch(
      "tenants/" +
        tenantId +
        "/users/" +
        userId +
        "/presets/" +
        id +
        "/selected",
      preset
    );
  }

  newPreset(preset: Preset) {
    const tenantId = localStorage.getItem("tenantId");
    const userId = localStorage.getItem("customerId");
    return this.instance.post(
      "tenants/" + tenantId + "/users/" + userId + "/presets",
      preset
    );
  }

  deletePreset(presetId: number) {
    const tenantId = localStorage.getItem("tenantId");
    const userId = localStorage.getItem("customerId");
    return this.instance.delete(
      "tenants/" + tenantId + "/users/" + userId + "/presets/" + presetId
    );
  }
}
