import React from "react";
import PropTypes from "prop-types";

import './balloon.css';

export const Balloon = ({ align, text, size, className, ...props }) => {
	return (
		<div className={['balloon-tooltip', `balloon-tooltip--${align}`, `balloon-tooltip--${size}`, className].join(' ')} {...props}>
			<span>{text}</span>
		</div>
	)
}

Balloon.propTypes = {
	align: PropTypes.oneOf(['top', 'bottom']),
	size: PropTypes.oneOf(['regular', 'small']),
	text: PropTypes.string,
}


Balloon.defaultProps = {
	align: 'top',
	size: 'regular',
	text: 'Text'
}