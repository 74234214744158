import {
  PayloadAction,
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import _ from "lodash";
import { RootState } from "../../../app/store";
import { Config } from "../../../config/Config";
import { GTFleetSuccessCodes } from "../../../config/GTFleetSuccessCodes";
import { GTFleetErrorCodes } from "../../../config/GTfleetErrorCodes";
import { getErrorCodes } from "../../../utils/Utils";
import PublicRoutesRepository from "./publicRoutesRepository";

export interface PublicRouteListView {
  id: number;
  name: string;
  vehicleId: number;
  color: string;
}

export const getPublicRouteListViewAsync = createAsyncThunk(
  "publicRoutes/getPublicRouteListView",
  async () => {
    try {
      const publicRoutesRepository = new PublicRoutesRepository();
      const response = await publicRoutesRepository.getPublicRoutesListView();
      const routesListView = _.get(response, Config.PUBLIC_ROUTE_LIST_VIEW);
      return routesListView;
    } catch (error: any) {
      if (!error.response) throw error;
      return null;
    }
  }
);

const publicRoutesAdapter = createEntityAdapter<PublicRouteListView>({
  selectId: (publicRouteListView) => publicRouteListView.id,
});

export const publicRouteListViewSlice = createSlice({
  name: "publicRoutesListView",
  initialState: publicRoutesAdapter.getInitialState({
    status: "idle",
    reasonCode: "",
  }),
  reducers: {
    publicRoutesListViewEmptyState: (state) => {
      publicRoutesAdapter.setAll(state, []);
      state.reasonCode = "";
      state.status = "idle";
    },
  },
  extraReducers: (builder) => {
    builder
      //#region Entity Reducers
      //#endregion
      .addCase(
        getPublicRouteListViewAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          publicRoutesAdapter.setAll(state, action.payload ?? []);
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.GET;
        }
      )
      .addCase(
        getPublicRouteListViewAsync.rejected,
        (state: any, action: PayloadAction<any>) => {
          state.status = "failed";
          handleFailedData(state, action);
        }
      )
      .addCase(getPublicRouteListViewAsync.pending, (state: any) => {
        state.status = "loading";
      });
  },
});

function handleFailedData(state: any, action: PayloadAction<any>) {
  let castAction: any = action;
  if (castAction?.payload?.data?.message) {
    state.reasonCode = getErrorCodes(castAction?.payload?.data?.message);
  }
  if (castAction?.payload?.status && castAction?.payload?.status === 500) {
    state.reasonCode = GTFleetErrorCodes.INTERNAL_SERVER_ERROR;
  }
}

export const publicRoutesListViewSelectors =
  publicRoutesAdapter.getSelectors<RootState>(
    (state) => state.publicRoutesListView
  );

export const selectPublicRoutesSliceStatus = (state: any) =>
  state.PublicRouteListView.status;

export const selectPublicRoutesSliceReasonCode = (state: any) =>
  state.PublicRouteListView.reasonCode;

export const { publicRoutesListViewEmptyState } =
  publicRouteListViewSlice.actions;

export default publicRouteListViewSlice.reducer;
