import { useTranslation } from "react-i18next";
import { DataLabel } from "../../../ui/DataLabel/DataLabel";
import { IconAverageSpeed } from "../../../ui/Icon/Line/AverageSpeed";
import { IconGasStation } from "../../../ui/Icon/Line/GasStation";
import { IconKilometers } from "../../../ui/Icon/Line/Kilometers";
import { IconSpeed } from "../../../ui/Icon/Line/Speed";
import { IconTruck } from "../../../ui/Icon/Line/Truck";
import UserContext from "../../users/userContext";
import { useContext } from "react";
import {
  kmPerHToMilesPerH,
  kmToMiles,
  ltToGal,
  mtToKm,
  numberAnnotation,
} from "../../../utils/Utils";
import { Preferences } from "../../users/preference/preferencesSlice";

interface GPSDataReportRoutePointsDetailsProps {
  vehicles?: number;
  kmTot?: number;
  consTot?: number;
  speedMax?: number;
  speedAvg?: number;
}

export const GPSDataReportRoutePointsDetails: React.FC<GPSDataReportRoutePointsDetailsProps> =
  ({ vehicles, kmTot, consTot, speedMax, speedAvg }) => {
    const { t } = useTranslation();
    const [preferencesContext]: [Preferences] = useContext(UserContext);

    return (
      <>
        {vehicles != null && (
          <div className="details-report-item" key={0}>
            <DataLabel
              dataText={vehicles}
              icon={<IconTruck size={14} />}
              label={t("report.details.vehicles")}
            />
          </div>
        )}
        {kmTot != null && (
          <div className="details-report-item" key={1}>
            <DataLabel
              dataText={
                preferencesContext.isMetric
                  ? numberAnnotation(mtToKm(kmTot)) + " km"
                  : numberAnnotation(kmToMiles(mtToKm(kmTot))) + " mil"
              }
              icon={<IconKilometers size={14} />}
              label={t("report.details.kmTot")}
            />
          </div>
        )}
        {consTot != null && (
          <div className="details-report-item" key={2}>
            <DataLabel
              dataText={
                preferencesContext.isMetric
                  ? numberAnnotation(consTot) + " lt"
                  : numberAnnotation(ltToGal(consTot)) + " gal"
              }
              icon={<IconGasStation size={14} />}
              label={t("report.details.consTot")}
            />
          </div>
        )}
        {speedMax != null && (
          <div className="details-report-item" key={3}>
            <DataLabel
              dataText={
                preferencesContext.isMetric
                  ? numberAnnotation(speedMax) + " km/h"
                  : numberAnnotation(kmPerHToMilesPerH(speedMax)) + " mph"
              }
              icon={<IconSpeed size={14} />}
              label={t("report.details.speedMax")}
            />
          </div>
        )}
        {speedAvg != null && (
          <div className="details-report-item" key={4}>
            <DataLabel
              dataText={
                preferencesContext.isMetric
                  ? numberAnnotation(speedAvg) + " km/h"
                  : numberAnnotation(kmPerHToMilesPerH(speedAvg)) + " mph"
              }
              icon={<IconAverageSpeed size={14} />}
              label={t("report.details.speedAvg")}
            />
          </div>
        )}
      </>
    );
  };
