import PropTypes from "prop-types";
import { Checkbox } from "../Forms/Checkbox";
import "./SettingsActionMenu.css";

export const SettingsActionMenu = ({
  options,
  settingActionChange,
  settingData,
  openSettings,
  ...props
}) => {
  const onChange = (target) => {
    settingActionChange(
      target.currentTarget.value,
      target.currentTarget.checked
    );
  };

  return (
    <>
      {
        <div className="mn-menu-settings-action" {...props}>
          <div className="mn-menu-settings-options">
            {options.map((option, index) => {
              if (settingData) {
                return (
                  <Checkbox
                    key={index}
                    label={typeof option === "object" ? option.label : option}
                    value={typeof option === "object" ? option.code : null}
                    checked={settingData[option.code]}
                    onChange={onChange}
                  />
                );
              }
              return (
                <Checkbox
                  key={index}
                  label={typeof option === "object" ? option.label : option}
                  value={typeof option === "object" ? option.code : null}
                  onChange={onChange}
                />
              );
            })}
          </div>
        </div>
      }
    </>
  );
};

SettingsActionMenu.propTypes = {
  options: PropTypes.array.isRequired,
};
