import { t } from "i18next";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { DeadlineEnum } from "../../features/maintenance-deadline/deadlinesSlice";
import { Dropdown } from "../Dropdown/Dropdown";
import Form from "../Forms/Form";
import TextField from "../Forms/TextField";
import { IconAdd } from "../Icon/Line/Add";
import { IconMaintenanceDeadlines } from "../Icon/Line/MaintenanceDeadlines";
import { IconMinus } from "../Icon/Line/Minus";
import "./ScheduleType.css";
interface Type {
  label: string;
  value: DeadlineEnum;
}
interface DefaultType {
  typeName?: DeadlineEnum;
  customType?: string;
}
interface ScheduleTypeProps {
  defaultType?: DefaultType;
  open?: boolean;
  types?: Type[];
  getTypeIsEmpty?: (e: any) => any;
  getValue?: (e: any) => any;
  getCustomType?: (e: any) => any;
  setIsOpened?: (e: any) => any;
}

export const ScheduleType: React.FC<ScheduleTypeProps> = ({
  defaultType,
  open,
  types,
  getValue,
  getCustomType,
  getTypeIsEmpty,
  setIsOpened,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(open ?? false);
  const [type, setType] = useState<Type>(
    types?.find((x) => x.value === defaultType?.typeName) ?? ({} as Type)
  );

  const [typeIsEmpty, setTypeIsEmpty] = useState(false);
  const [customMaintenance, setCustomMaintenance] = useState(
    defaultType?.customType ?? ""
  );
  const [selectedType, setSelectedType] = useState<string>(
    types?.find((x) => x.value === defaultType?.typeName)?.label ?? ""
  );

  useEffect(() => {
    setIsOpen(open ?? false);
    if (type) {
      setSelectedType(type.label);
      if (getValue) {
        getValue(type.value);
      }
    }

    getTypeIsEmpty && getTypeIsEmpty(typeIsEmpty);
  }, [open, typeIsEmpty, type]);

  const toggleGroup = () => {
    setIsOpen((prevState) => !prevState);
    setIsOpened && setIsOpened(0);
  };

  const handleReset = (e: any) => {
    setType({} as Type);
    setCustomMaintenance("");
    setSelectedType("");
    if (getValue) {
      getValue("");
    }
    if (getCustomType) {
      getCustomType("");
    }
    e.stopPropagation();
  };

  const wrapperRef = useRef<any>(null);
  // Method for closed dropdown if u clicked outside
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target) &&
        customMaintenance === ""
      ) {
        setTypeIsEmpty(true);
      } else {
        setTypeIsEmpty(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef, customMaintenance]);

  const handleTitle = () => {
    if (
      (type?.value === "MAINTENANCE_CUSTOM" || type?.value === "CUSTOM") &&
      customMaintenance !== "" &&
      !isOpen
    ) {
      return customMaintenance;
    } else if (
      (selectedType === "" && customMaintenance === "") ||
      Object.keys(type).length === 0 ||
      isOpen
    ) {
      return t("schedule.typeTitle");
    } else {
      return !isOpen && selectedType !== "" && selectedType;
    }
  };

  return (
    <>
      <div
        className={isOpen ? "schedule-type-simple" : "schedule-type"}
        onClick={() => {
          if (!typeIsEmpty) {
            type && setSelectedType(type.label);
            getTypeIsEmpty && getTypeIsEmpty(typeIsEmpty);
            toggleGroup();
          }
        }}
      >
        <div className="schedule-type-button">
          <div className="schedule-type-circle">
            <IconMaintenanceDeadlines
              size={14}
              color="--global-colors-ui-primary"
            />
          </div>
          <div className="schedule-type-label">
            {(selectedType !== "" || customMaintenance !== "") &&
              Object.keys(type).length !== 0 &&
              !isOpen && (
                <>
                  <span className="schedule-type-pretitle">
                    {t("schedule.typeTitle")}
                  </span>
                  <br />
                </>
              )}
            <span className="schedule-type-title">{handleTitle()}</span>
          </div>
          <span className="schedule-type-icon">
            {isOpen ? (
              <>
                <div className="schedule-type-reset" onClick={handleReset}>
                  {t("common.reset")}
                </div>
                <IconMinus size={14} color="--global-colors-ui-primary" />
              </>
            ) : (
              <IconAdd size={14} color="--global-colors-ui-primary" />
            )}
          </span>
        </div>
      </div>
      {isOpen && types?.length && (
        <div className="schedule-type-body">
          <div className="schedule-type-container">
            <span className="schedule-type-selection">
              {t("schedule.typeTitle")}
            </span>
            <div className="schedule-type-dropdown">
              <Dropdown
                options={types}
                placeholder={t("schedule.typePlaceholder")}
                size="small"
                hasRadio={true}
                itemAttribute="label"
                onChange={(val) => {
                  const selectedValue = {
                    label: val[0].label,
                    value: val[0].value,
                  };
                  setType(selectedValue);
                  if (getValue) {
                    getValue(val[0].value);
                  }
                }}
                value={type}
              />
            </div>
          </div>
          {type &&
            (type.value === "MAINTENANCE_CUSTOM" ||
              type.value === "CUSTOM") && (
              <div className="schedule-type-container">
                <span className="schedule-type-selection">
                  {type?.value === "CUSTOM"
                    ? t("schedule.customDeadlineLabel")
                    : t("schedule.customMaintenanceLabel")}
                </span>
                <div
                  className={
                    typeIsEmpty
                      ? "schedule-type-empty-form"
                      : "schedule-type-form"
                  }
                  ref={wrapperRef}
                >
                  <Form>
                    <TextField
                      id="customMaintenance"
                      placeholder={t("schedule.typeCustomizedPlaceholder")}
                      value={customMaintenance}
                      type="text"
                      size="small"
                      events={{
                        onChange: (e: any) => {
                          setCustomMaintenance(e.value);
                          if (getCustomType) {
                            getCustomType(e.value);
                          }
                        },
                      }}
                    ></TextField>
                  </Form>
                  {typeIsEmpty && (
                    <div className="empty-type-label">
                      {t("schedule.typeConstraint")}
                    </div>
                  )}
                </div>
              </div>
            )}
        </div>
      )}
    </>
  );
};

ScheduleType.propTypes = {
  defaultType: PropTypes.any,
  open: PropTypes.bool,
  getTypeIsEmpty: PropTypes.func,
  getValue: PropTypes.func,
  setIsOpened: PropTypes.func,
};

ScheduleType.defaultProps = {
  open: false,
};
