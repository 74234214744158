import dayjs from "dayjs";

export function convertDateToIsoString(date, isISO, localeFormat) {
    if (isISO) {
        return date
    } else {
        const dateSplitted = date.split(' / ');
        if (localeFormat.split("/")[0].includes("DD")) {
            return dateSplitted[2] + '-' + dateSplitted[1] + '-' + dateSplitted[0];
        } else {
            return dateSplitted[2] + '-' + dateSplitted[0] + '-' + dateSplitted[1];
        }
    }
}

export function convertToCurrentLocalesFormat(date, isISO, localeFormat) {
    return dayjs(convertDateToIsoString(date, isISO)).format(localeFormat);
}

export function isValidDate(date) {
    if (date) return date.isValid();
    else return false
}
