import { t } from "i18next";
import { useEffect, useState } from "react";
import { DropdownButton, ListItem } from "../../../ui/Button/DropdownButton";
import { Dropdown } from "../../../ui/Dropdown/Dropdown";
import {
  convertMillisecondsToShortedTime,
  convertMillisecondsToTime,
  getIconFromVehicleType,
} from "../../../utils/Utils";
import { Vehicle } from "../../vehicle/vehiclesSlice";
import { PublicRouteListView } from "../route/publicRouteViewSlice";
import { PublicRouteShift } from "../shift/publicRouteShiftSlice";
import "./PublicRoutePlanner.css";
import {
  PublicRoutePlanningRequest,
  PublicRoutePlanningType,
  PublicShiftStatusEnum,
} from "./publicRoutePlanningSlice";

interface PublicRoutePlannerProps {
  id: number | null;
  shift: PublicRouteShift | null;
  routes: PublicRouteListView[];
  route?: number;
  vehicles: Vehicle[];
  vehicle?: number;
  driverId: number;
  name?: string;
  date: Date;
  status?: PublicShiftStatusEnum;
  duration?: number;
  onlyDuration?: boolean;
  elementsLoaded?: PublicRoutePlanningRequest[];
  elementsAlreadyLoaded?: PublicRoutePlanningType[];
  response: (e: PublicRoutePlanningRequest) => any;
  responseDuration: (e: number) => any;
}

interface DropDownItem {
  label: string;
  value: string;
  icon?: string;
  color?: string;
}

export const PublicRoutePlanner: React.FC<PublicRoutePlannerProps> = ({
  id,
  response,
  responseDuration,
  shift,
  routes,
  status,
  route,
  vehicle,
  name,
  date,
  duration,
  driverId,
  onlyDuration,
  vehicles,
  elementsLoaded,
  elementsAlreadyLoaded,
}) => {
  const [newStatus, setNewStatus] = useState<string>(
    status?.toString() ?? PublicShiftStatusEnum.ON_DUTY
  );
  const [newVehicle, setNewVehicle] = useState<any>(vehicle);
  const [newRoute, setNewRoute] = useState<any>(route);
  const [startHours, setStartHours] = useState<any>();
  const [shiftTimeRange, setShiftTimeRange] = useState(0);
  const [savedDuration, setSavedDuration] = useState(0);
  const [preparationTime, setPreparationTime] = useState(0); //TO BE REMOVED

  const [routesList, setRoutesList] = useState<DropDownItem[]>(
    routes?.map((route: any) => {
      return {
        label: route.name,
        value: route.id,
        color: route.color,
      };
    }) ?? []
  );
  const [vehiclesList, setVehicleList] = useState<DropDownItem[]>(
    vehicles?.map((vehicle: any) => {
      return {
        label: vehicle.alias + " · " + vehicle.plate,
        value: vehicle.id,
        icon: (
          <div style={{ paddingRight: "10px" }}>
            {getIconFromVehicleType(
              vehicle.type,
              14,
              vehicle.id === newVehicle
                ? "--global-colors-ui-white"
                : "--global-colors-ui-primary"
            )}
          </div>
        ),
      } as unknown as DropDownItem;
    }) ?? []
  );

  useEffect(() => {
    setNewStatus(status?.toString() ?? PublicShiftStatusEnum.ON_DUTY);
  }, [status]);

  useEffect(() => {
    setNewVehicle(vehicle);
  }, [vehicle]);

  useEffect(() => {
    setNewRoute(route);
  }, [route]);

  useEffect(() => {
    newRoute !== 0 &&
      newVehicle === 0 &&
      setNewVehicle(routes.find((e) => e.id === newRoute)?.vehicleId);

    let timeRange = shift?.shiftTimeRanges.find((e) => e.routeId === newRoute);
    setShiftTimeRange(timeRange?.id ?? shift?.shiftTimeRanges[0]?.id ?? 0);
    if (!!timeRange) {
      setPreparationTime(timeRange.preparationTotalTime ?? 0); //TO BE REMOVED
      const [startHours, startMinutes, startSeconds] = timeRange.startDate
        .split(":")
        .map(Number);
      const [endHours, endMinutes, endSeconds] = timeRange.endDate
        .split(":")
        .map(Number);
      const firstDate = new Date();
      firstDate.setHours(startHours, startMinutes, startSeconds, 0);

      const secondDate = new Date();
      secondDate.setHours(endHours, endMinutes, endSeconds, 0);

      let milliDiff = secondDate.getTime() - firstDate.getTime();
      responseDuration(savedDuration * -1);
      responseDuration(milliDiff + (timeRange.preparationTotalTime ?? 0));
      setSavedDuration(milliDiff + (timeRange.preparationTotalTime ?? 0));
      setStartHours(firstDate);
    } else {
      responseDuration(savedDuration * -1);
      setSavedDuration(0);
      setStartHours(null);
    }
  }, [newRoute]);

  useEffect(() => {
    let newElement: PublicRoutePlanningRequest = {
      id: id,
      name: name,
      driverId: driverId,
      vehicleId: newVehicle,
      publicRouteId: newRoute,
      planningStatusEnum: newStatus as unknown as PublicShiftStatusEnum,
      date: date,
      shiftTimeRangeId: shiftTimeRange,
    };
    if (
      (newRoute !== route && newRoute !== 0) ||
      (newVehicle !== vehicle && newVehicle !== 0) ||
      (newStatus !== "ON_DUTY" && newVehicle === 0 && newRoute === 0) ||
      (!Number.isNaN(id) &&
        (newRoute !== route || newVehicle !== vehicle || newStatus !== status))
    ) {
      response(newElement);
    }
  }, [newRoute, newVehicle, newStatus]);

  function limitRoutesManager() {
    const newRoutesList =
      routes
        ?.filter((route) => {
          return !elementsLoaded?.some((element) => {
            const elementDate = new Date(element.date);
            const compareDate = new Date(date);
            const sameDay =
              elementDate.getFullYear() === compareDate.getFullYear() &&
              elementDate.getMonth() === compareDate.getMonth() &&
              elementDate.getDate() === compareDate.getDate();
            return (
              element.publicRouteId === route.id &&
              sameDay &&
              element.name === shift?.name &&
              element.driverId !== driverId
            );
          });
        })
        .filter((route) => {
          return !elementsAlreadyLoaded?.some((element: any) => {
            let tempElement: {
              id: any;
              date: string | number | Date;
              publicRouteId: number;
              name: string | undefined;
              driverId: number;
            };
            tempElement =
              (elementsLoaded?.find(
                (e: any) => e?.id === element?.id
              ) as any) ?? element;
            // Estrai i componenti delle date
            const elementDate = new Date(tempElement.date);
            const compareDate = new Date(date);

            // Confronta i componenti delle date
            const sameDay =
              elementDate.getFullYear() === compareDate.getFullYear() &&
              elementDate.getMonth() === compareDate.getMonth() &&
              elementDate.getDate() === compareDate.getDate();

            return (
              tempElement.publicRouteId === route.id &&
              sameDay &&
              tempElement.name === shift?.name &&
              tempElement.driverId !== driverId
            );
          });
        })

        .map((route: any) => {
          return {
            label: route.name,
            value: route.id,
            color: route.color,
          };
        }) ?? [];
    setRoutesList(newRoutesList);
  }

  function limitVehiclesManager() {
    setVehicleList(
      vehicles
        ?.filter((vehicle) => {
          return !elementsLoaded?.some((element) => {
            const elementDate = new Date(element.date);
            const compareDate = new Date(date);
            const sameDay =
              elementDate.getFullYear() === compareDate.getFullYear() &&
              elementDate.getMonth() === compareDate.getMonth() &&
              elementDate.getDate() === compareDate.getDate();
            return (
              element.vehicleId === vehicle.id &&
              sameDay &&
              element.name === shift?.name &&
              element.driverId !== driverId
            );
          });
        })
        .filter((vehicle) => {
          return !elementsAlreadyLoaded?.some((element: any) => {
            let tempElement: {
              id: any;
              date: string | number | Date;
              vehicleId: number;
              publicRouteId: number;
              name: string | undefined;
              driverId: number;
            };
            tempElement =
              (elementsLoaded?.find(
                (e: any) => e?.id === element?.id
              ) as any) ?? element;

            const elementDate = new Date(element.date);
            const compareDate = new Date(date);
            const sameDay =
              elementDate.getFullYear() === compareDate.getFullYear() &&
              elementDate.getMonth() === compareDate.getMonth() &&
              elementDate.getDate() === compareDate.getDate();
            return (
              tempElement.vehicleId === vehicle.id &&
              sameDay &&
              tempElement.name === shift?.name &&
              tempElement.driverId !== driverId
            );
          });
        })
        .map((vehicle: any) => {
          return {
            label: vehicle.alias + " · " + vehicle.plate,
            value: vehicle.id,
            icon: (
              <div style={{ paddingRight: "10px" }}>
                {getIconFromVehicleType(
                  vehicle.type,
                  14,
                  vehicle.id === newVehicle
                    ? "--global-colors-ui-white"
                    : "--global-colors-ui-primary"
                )}
              </div>
            ),
          } as unknown as DropDownItem;
        }) ?? []
    );
  }

  const statusesDropDownList: ListItem[] = [
    {
      id: 0,
      title: t("publicRoutePlanning.statuses." + PublicShiftStatusEnum.ON_DUTY),
      onClick: () => {
        setNewStatus(PublicShiftStatusEnum.ON_DUTY);
      },
    },
    {
      id: 1,
      title: t(
        "publicRoutePlanning.statuses." + PublicShiftStatusEnum.OFF_DUTY
      ),
      onClick: () => {
        setNewStatus(PublicShiftStatusEnum.OFF_DUTY);
        setNewVehicle(0);
        setNewRoute(0);
      },
    },
    {
      id: 2,
      title: t(
        "publicRoutePlanning.statuses." + PublicShiftStatusEnum.SICK_LEAVE
      ),
      onClick: () => {
        setNewStatus(PublicShiftStatusEnum.SICK_LEAVE);
        setNewVehicle(0);
        setNewRoute(0);
      },
    },
    {
      id: 3,
      title: t("publicRoutePlanning.statuses." + PublicShiftStatusEnum.RESTING),
      onClick: () => {
        setNewStatus(PublicShiftStatusEnum.RESTING);
        setNewVehicle(0);
        setNewRoute(0);
      },
    },
    {
      id: 4,
      title: t(
        "publicRoutePlanning.statuses." + PublicShiftStatusEnum.HOLIDAYS
      ),
      onClick: () => {
        setNewStatus(PublicShiftStatusEnum.HOLIDAYS);
        setNewVehicle(0);
        setNewRoute(0);
      },
    },
    {
      id: 5,
      title: t("publicRoutePlanning.statuses." + PublicShiftStatusEnum.LAW_104),
      onClick: () => {
        setNewStatus(PublicShiftStatusEnum.LAW_104);
        setNewVehicle(0);
        setNewRoute(0);
      },
    },
    {
      id: 6,
      title: t("publicRoutePlanning.statuses." + PublicShiftStatusEnum.OTHER),
      onClick: () => {
        setNewStatus(PublicShiftStatusEnum.OTHER);
        setNewVehicle(0);
        setNewRoute(0);
      },
    },
  ];

  let vehicleSelected: DropDownItem | undefined = vehiclesList?.find(
    (e) => e.value === newVehicle
  );
  let routeSelected: DropDownItem | undefined = routesList?.find(
    (e) => e.value === newRoute
  );

  if (onlyDuration) {
    return (
      <>
        <div
          className="public-route-planner-duration"
          id="element-planner-component"
        >
          <div
            className="public-route-planner-header-text"
            style={{ paddingTop: "20px", alignSelf: "center" }}
          >
            {duration ? convertMillisecondsToTime(duration) : "00:00:00"}
          </div>
          <div style={{ opacity: 0 }}>
            <Dropdown
              size="small"
              itemAttribute={"label"}
              options={[]}
              onChange={function (e: any) {}}
            />
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div style={{ width: "100%" }}>
          {newStatus === "ON_DUTY" ? (
            <div className="public-route-planner-header">
              <div className="public-route-planner-header-left">
                <div className="public-route-planner-header-text">
                  {name}{" "}
                  {startHours && <span style={{ color: "#CDD1D6" }}> · </span>}{" "}
                  {startHours &&
                    new Date(startHours).getHours() +
                      ":" +
                      new Date(startHours).getMinutes()}
                </div>
                <div className="public-route-planner-header-text-two">
                  {t("navigation.publicRoutePlanning.workHours")}
                  <span style={{ color: "#CDD1D6", fontSize: "14px" }}>
                    {" "}
                    ·{" "}
                  </span>
                  {savedDuration
                    ? convertMillisecondsToShortedTime(savedDuration)
                    : "00:00"}
                </div>
                {/* TO BE REMOVED REGION*/}
                <div className="public-route-planner-header-text-two">
                  {t("navigation.publicRoutePlanning.preparationHours")}
                  <span style={{ color: "#CDD1D6", fontSize: "14px" }}>
                    {" "}
                    ·{" "}
                  </span>
                  {preparationTime
                    ? convertMillisecondsToShortedTime(preparationTime)
                    : "00:00"}
                </div>
                {/* TO BE REMOVED ENDREGION*/}
              </div>
              <div style={{ paddingRight: "3px", marginBottom: "8px" }}>
                <DropdownButton
                  aspect="ghost"
                  size="small"
                  list={statusesDropDownList}
                  closeOnClick={true}
                />
              </div>
            </div>
          ) : (
            <div className="public-route-planner-header-statused">
              <DropdownButton
                aspect="ghost"
                size="small"
                list={statusesDropDownList}
                closeOnClick={true}
              />
            </div>
          )}
          <div
            className={
              newStatus === "ON_DUTY"
                ? "public-route-planner"
                : "public-route-planner-statused"
            }
          >
            {newStatus === "ON_DUTY" ? (
              <>
                {!!routesList && (
                  <Dropdown
                    isOpen={(e) => {
                      e && limitRoutesManager();
                    }}
                    placeholder={t("publicRoutePlanning.route")}
                    fontSize={12}
                    isClearable={true}
                    value={routeSelected}
                    size="small"
                    itemAttribute={"label"}
                    options={routesList}
                    onChange={(e: any) => {
                      setNewRoute(e.length > 0 ? e[0].value : 0);
                    }}
                  />
                )}
                {!!vehiclesList && (
                  <Dropdown
                    isOpen={(e) => e && limitVehiclesManager()}
                    placeholder={t("publicRoutePlanning.vehicle")}
                    scroll={true}
                    fontSize={12}
                    isClearable={true}
                    dropdownWidth="200px"
                    value={vehicleSelected}
                    size="small"
                    itemAttribute={"label"}
                    options={vehiclesList}
                    onChange={(e: any) => {
                      setNewVehicle(e.length > 0 ? e[0].value : 0);
                    }}
                  />
                )}
              </>
            ) : (
              <>
                <div
                  className="public-route-planner-header-text"
                  style={{ paddingTop: "10px", alignSelf: "center" }}
                >
                  {t("publicRoutePlanning.statuses." + newStatus)}
                </div>
                {/*This component is used only for get the same size of other table's component, don't delete */}
                <div style={{ opacity: 0 }}>
                  <Dropdown
                    size="small"
                    itemAttribute={"label"}
                    options={[]}
                    onChange={function (e: any) {}}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </>
    );
  }
};
