import PropTypes from "prop-types";
import { useContext, useEffect, useRef } from "react";
import { IconSearch } from "../Icon/Line/Search";
import { FormCtx } from "./Form";
import "./SearchField.css";

const SearchField = (props) => {
  const { id } = props;
  const { setFields, addField, fields } = useContext(FormCtx);
  const field = fields[id] || {};
  const {
    name,
    value,
    disabled,
    size = "normal",
    placeholder,
    events = {},
    classes = {},
  } = field;
  const { onChange, onAddressClick, ...restEvents } = events;
  let { contClass, fieldClass } = classes;

  if (size !== "normal") {
    fieldClass = [fieldClass, "input--small"].join(" ");
  }

  const handleChange = (event) => {
    setFields(event, field);

    if (typeof onChange === "function") {
      onChange({
        ...field,
        value: event.target.value,
      });
    }

    if (props.onChange) {
      props.onChange(event.target.value);
    }
  };

  useEffect(() => {
    addField({
      field: props,
    });
  }, [props]);

  let fieldProps = {
    ...restEvents,
    name,
    value: value ?? "",
    placeholder,
    size: !!placeholder ? Math.max(placeholder.length - 2, 20) : 20,
    disabled,
    className: fieldClass,
    onChange: handleChange,
  };

  const ref = useRef();
  let autocomplete;

  useEffect(() => {
    const placesChanged = () => {
      const places = autocomplete.getPlace();

      if (
        places.length === 0 &&
        (!places.geometry || !places.geometry.location)
      ) {
        console.log("Returned place contains no geometry");
        return;
      }
      if (typeof onAddressClick === "function") {
        onAddressClick(places);
      }

      if (props.onChange) {
        props.onAddressClick(places);
      }
    };

    let googleAddress = window.google;
    if (googleAddress && ref && ref.current) {
      const input = ref.current;
      const option = {
        fields: ["formatted_address", "geometry", "address_components"],
        strictBounds: false,
        ...props.autocompleteOptions,
      };
      autocomplete = new googleAddress.maps.places.Autocomplete(input, option);
      let eventGoogle = autocomplete.addListener(
        "place_changed",
        placesChanged
      );

      const keydown = (event) => {
        if (event.keyCode === 13) {
          event.preventDefault();
        }
      };
      ref.current.addEventListener("keydown", keydown);
      let parentInput = ref;

      return () => {
        googleAddress.maps.event.removeListener(eventGoogle);
        parentInput.current &&
          parentInput.current.removeEventListener("keydown", keydown);
      };
    }
  }, []);

  function clickRef() {
    for (let menu of document.getElementsByClassName("pac-container")) {
      setTimeout(() => ref.current?.parentNode.append(menu));
    }
  }

  return (
    field && (
      <div className={[contClass, "search-field"].join(" ")}>
        <input
          {...fieldProps}
          ref={props?.searchAddress ? ref : null}
          name="search-field"
          autoComplete="off"
          onClick={clickRef}
        />
        {!props.hasSearchIcon && (
          <IconSearch size={14} color="--global-colors-ink-light" />
        )}
      </div>
    )
  );
};

SearchField.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  autoComplete: PropTypes.string,
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(["small", "normal"]),
  onChange: PropTypes.func.isRequired,
  classes: PropTypes.exact({
    contClass: PropTypes.string,
    fieldClass: PropTypes.string,
  }),
};

export default SearchField;
