import { t } from "i18next";
import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { IconArrowDown } from "../../../ui/Icon/Line/ArrowDown";
import { IconArrowUp } from "../../../ui/Icon/Line/ArrowUp";
import { getDayOfWeek } from "../../../utils/Utils";
import { Driver } from "../../driver/driversSlice";
import { Vehicle } from "../../vehicle/vehiclesSlice";
import { PublicRouteListView } from "../route/publicRouteViewSlice";
import { PublicRouteShift } from "../shift/publicRouteShiftSlice";
import "./PublicRoutePlan.css";
import { PublicRoutePlanner } from "./PublicRoutePlanner";
import {
  PublicRoutePlanningRequest,
  PublicRoutePlanningType,
} from "./publicRoutePlanningSlice";

interface PublicRoutePlanProps {
  startDate: Date;
  endDate: Date;
  shifts: PublicRouteShift[];
  routes: PublicRouteListView[];
  drivers: Driver[];
  vehicles: Vehicle[];
  plannings: PublicRoutePlanningType[];
  planningToUpdate: (e: PublicRoutePlanningRequest[]) => any;
  cleanPlannings: any;
  undoPlannings: any;
  undoPlanningsPostAction: () => any;
  planningStatus: (e: string) => any;
  previousStatus: any;
  planningsInitializedWithDbPlannings: (
    e: PublicRoutePlanningRequest[][]
  ) => any;
}

export const PublicRoutePlan: React.FC<PublicRoutePlanProps> = ({
  startDate,
  endDate,
  drivers,
  vehicles,
  routes,
  shifts,
  plannings,
  cleanPlannings,
  previousStatus,
  undoPlannings,
  undoPlanningsPostAction,
  planningToUpdate,
  planningStatus,
  planningsInitializedWithDbPlannings,
}) => {
  //USE STATE REGION
  endDate.setHours(23, 59, 59, 999);
  startDate.setHours(0, 0, 0, 0);
  const [sorted, setSorted] = useState(false);
  const [driverWidth, setDriverWidth] = useState(0);
  const [componentWidth, setComponentWidth] = useState(0);
  const [internalDrivers, setInternalDrivers] = useState<Driver[]>([]);
  const [internalStartDate, setInternalStartDate] = useState<Date>(new Date());
  const [internalShifts, setInternalShifts] = useState<any[]>([]);
  const [internalVehicles, setInternalVehicles] = useState<any[]>([]);
  const [planningsToBeUpdated, setPlanningsToBeUpdated] = useState<
    PublicRoutePlanningRequest[]
  >([]);
  const [planningsAlreadyRendered, setPlanningsAlreadyRendered] = useState<
    PublicRoutePlanningRequest[][]
  >([]);
  const [planningToBeRendered, setPlanningToBeRendered] = useState<
    PublicRoutePlanningRequest[][]
  >([]);

  let colors = ["#FED005", "#66A3D2"];
  const [durations, setDurations] = useState<number[]>([0]);

  //USE EFFECT SECTION

  const updateWidth = useCallback(() => {
    const element1 = document.getElementById(
      "element-planner-component-duration"
    );
    const element2 = document.getElementById(
      "element-planner-component-driver"
    );

    if (element1) {
      setComponentWidth(element1.offsetWidth);
    }

    if (element2) {
      setDriverWidth(element2.offsetWidth + 2);
    }
  }, []);

  useEffect(() => {
    if (
      startDate &&
      drivers.length > 0 &&
      shifts.length > 0 &&
      vehicles.length > 0
    ) {
      let planningToBeRendered = initializePlanningToBeRendered();
      if (!_.isEqual(planningToBeRendered, planningsAlreadyRendered)) {
        planningsInitializedWithDbPlannings(planningToBeRendered);
        setPlanningsAlreadyRendered(planningToBeRendered);
      }

      if (planningsToBeUpdated.length > 0) {
        const updatedPlansInRange = planningsToBeUpdated.filter(
          (e) => new Date(e.date) >= startDate && new Date(e.date) <= endDate
        );
        if (updatedPlansInRange.length > 0) {
          updatedPlansInRange.filter(
            (e) =>
              !(
                (e.publicRouteId === 0 || e.vehicleId === 0) &&
                e.planningStatusEnum === "ON_DUTY" &&
                e.id === null
              )
          )?.length
            ? planningStatus("TO_COMPLETE")
            : planningStatus("TO_COMPILE");

          const newPlanningToBeRendered = planningToBeRendered.map(
            (driverPlans) => {
              return driverPlans.map((plan) => {
                const updatedPlan = updatedPlansInRange.find(
                  (e) =>
                    e.driverId === plan.driverId &&
                    e.name === plan.name &&
                    new Date(e.date).toDateString() ===
                      new Date(plan.date).toDateString()
                );
                return updatedPlan ? { ...plan, ...updatedPlan } : plan;
              });
            }
          );
          planningToBeRendered = newPlanningToBeRendered;
          const notCompleted = newPlanningToBeRendered.some((driverPlans) =>
            driverPlans.some(
              (plan) =>
                plan.publicRouteId === 0 &&
                plan.planningStatusEnum === "ON_DUTY"
            )
          );
          if (!notCompleted) {
            planningStatus("COMPLETED");
          }
        }
      }

      setPlanningToBeRendered(planningToBeRendered);
      !_.isEqual(drivers, internalDrivers) && setInternalDrivers(drivers);
      !_.isEqual(startDate, internalStartDate) &&
        setInternalStartDate(startDate);
      !_.isEqual(shifts, internalShifts) && setInternalShifts(shifts);
      !_.isEqual(vehicles, internalVehicles) && setInternalVehicles(vehicles);
    }
  }, [startDate, sorted, plannings.length]);
  useEffect(() => {
    // Esegui subito l'aggiornamento delle dimensioni
    updateWidth();

    // Aggiungi l'evento di resize
    window.addEventListener("resize", updateWidth);

    // Rimuovi l'evento di resize al momento della pulizia dell'uso dell'effetto
    return () => {
      window.removeEventListener("resize", updateWidth);
    };
  }, [updateWidth, planningToBeRendered]);
  useEffect(() => {
    planningToUpdate(
      planningsToBeUpdated.filter(
        (e) =>
          !(
            (e.publicRouteId === 0 || e.vehicleId === 0) &&
            (e.id === 0 || e.id === null) &&
            e.planningStatusEnum === "ON_DUTY"
          )
      )
    );
    if (planningsToBeUpdated.length > 0) {
      planningsToBeUpdated.filter(
        (e) =>
          !(
            (e.publicRouteId === 0 || e.vehicleId === 0) &&
            e.planningStatusEnum === "ON_DUTY" &&
            e.id === null
          )
      )?.length === 0
        ? planningStatus("TO_COMPILE")
        : planningStatus("TO_COMPLETE");
    }
  }, [planningsToBeUpdated]);

  /**
   * Remove last changes and restore planning with original data.
   */
  useEffect(() => {
    if (undoPlannings) {
      setPlanningsToBeUpdated(
        planningsToBeUpdated.filter(
          (e) => !(new Date(e.date) >= startDate && new Date(e.date) <= endDate)
        )
      );
      planningStatus(previousStatus);
      if (!!undoPlanningsPostAction) {
        undoPlanningsPostAction();
      }
    }
  }, [undoPlannings]);

  useEffect(() => {
    if (
      startDate &&
      drivers.length > 0 &&
      shifts.length > 0 &&
      vehicles.length > 0 &&
      cleanPlannings > 0
    ) {
      setPlanningsToBeUpdated([]);
      let planningToBeRendered = initializePlanningToBeRendered();

      // Array temporaneo per accumulare le pianificazioni da aggiornare
      let newPlanningsToBeUpdated: PublicRoutePlanningRequest[] = [];

      planningToBeRendered?.forEach((driver) => {
        driver?.forEach((element) => {
          if (element.id !== null) {
            newPlanningsToBeUpdated.push({
              id: element.id,
              name: element.name,
              driverId: element.driverId,
              vehicleId: 0,
              publicRouteId: 0,
              planningStatusEnum: "ON_DUTY",
              date: element.date,
              shiftTimeRangeId: 0,
            } as PublicRoutePlanningRequest);
          }
        });
      });

      // Utilizziamo un aggiornamento dello stato basato sul valore precedente
      setPlanningsToBeUpdated((prevPlannings) => [
        ...prevPlannings,
        ...newPlanningsToBeUpdated,
      ]);

      setPlanningToBeRendered(planningToBeRendered);
      planningStatus("TO_COMPILE");
    }
  }, [cleanPlannings]);

  //FUNCTION SECTION

  function initializePlanningToBeRendered() {
    const daysUntilSunday = (7 - startDate.getDay()) % 7;
    // Creazione della struttura dati per driver e periodo [Lunedì/Domenica]
    let newPlanning: PublicRoutePlanningRequest[][] = drivers
      .sort((a, b) =>
        sorted
          ? a.firstName.localeCompare(b.firstName)
          : b.firstName.localeCompare(a.firstName)
      )
      .map((driver) => {
        return shifts.flatMap((shift: any) => {
          return Array.from({ length: daysUntilSunday + 1 }, (_, j) => {
            let newDate = new Date(startDate);
            newDate.setDate(startDate.getDate() + j);
            let elLoaded = plannings?.find(
              (e) =>
                e.driverId === driver.id &&
                e.name === shift.name &&
                new Date(e.date).toDateString() === newDate.toDateString()
            );

            let elem = !!elLoaded
              ? (elLoaded as any)
              : {
                  id: null,
                  name: shift.name,
                  driverId: driver.id,
                  vehicleId: 0,
                  publicRouteId: 0,
                  planningStatusEnum: "ON_DUTY",
                  date: newDate,
                  shiftTimeRangeId: 0,
                };
            return elem;
          });
        });
      });
    return newPlanning;
  }
  function tableHeaderBuilder() {
    const daysUntilSunday = (7 - startDate.getDay()) % 7;
    const header = [];

    header.push(
      <div
        className="public-route-plan-header-element"
        style={{
          minWidth: driverWidth,
        }}
      >
        <span
          className="public-route-plan-header-element-text"
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "10px",
          }}
        >
          Autista{sorter()}
        </span>
      </div>
    );

    for (let j = 0; j <= daysUntilSunday; j++) {
      let newDate = new Date(startDate);
      newDate.setDate(startDate.getDate() + j);

      header.push(
        <div
          className="public-route-plan-header-element"
          style={{ width: "100%" }}
        >
          <span className="public-route-plan-header-element-text">
            {t(getDayOfWeek(newDate.getDay())) +
              " " +
              newDate.getDate() +
              "/" +
              (newDate.getMonth() + 1)}{" "}
          </span>
        </div>
      );
    }

    header.push(
      <div
        className="public-route-plan-header-element"
        style={{ minWidth: componentWidth, marginRight: "10px" }}
      >
        <span className="public-route-plan-header-element-text">
          {t("publicRoutePlanning.totalWorkHours")}
        </span>
      </div>
    );

    false &&
      header.push(
        <div
          className="public-route-plan-header-element"
          style={{ width: componentWidth }}
        >
          <span className="public-route-plan-header-element-text">
            {t("publicRoutePlanning.totalWorkHoursPlanned")}
          </span>
        </div>
      );
    return header;
  }
  function sorter() {
    return sorted ? (
      <>
        <span style={{ cursor: "pointer" }} onClick={() => setSorted(!sorted)}>
          <IconArrowUp size={10} color={"#687484da"} />
        </span>
      </>
    ) : (
      <>
        <span style={{ cursor: "pointer" }} onClick={() => setSorted(!sorted)}>
          <IconArrowDown size={10} color={"#687484da"} />
        </span>
      </>
    );
  }

  return (
    <>
      <div className="public-route-plan-container" style={{ height: "100%" }}>
        {/*Add here header table section*/}
        {<div className="public-route-plan-row">{tableHeaderBuilder()}</div>}
        <div
          style={{ width: "100%", overflow: "scroll", paddingBottom: "400px" }}
        >
          {planningToBeRendered &&
            planningToBeRendered?.map((driverElements, driverIndex) => (
              <div className="public-route-plan-row">
                <div
                  id="element-planner-component-driver"
                  className="public-route-plan-row-shift-driver"
                >
                  <div className="public-route-plan-row-shift-driver-textone">
                    {" "}
                    {driverElements[0]?.driverId}
                  </div>
                  <div className="public-route-plan-row-shift-driver-texttwo">
                    {drivers
                      .filter((e) => e.id === driverElements[0]?.driverId)[0]
                      ?.lastName.toUpperCase()}
                  </div>
                  <div className="public-route-plan-row-shift-driver-texttwo">
                    {
                      drivers.filter(
                        (e) => e.id === driverElements[0]?.driverId
                      )[0]?.firstName
                    }
                  </div>
                </div>
                <div
                  className="public-route-plan-row-shifts"
                  style={{ width: "100%" }}
                >
                  {shifts?.map((shift: any, index: any) => (
                    <div
                      className="public-route-plan-row-shift"
                      style={{
                        borderLeft: "3px solid ",
                        borderColor: colors[index % 2],
                      }}
                    >
                      {driverElements
                        .filter((e) => e.name === shift.name)
                        .map((element) => (
                          <PublicRoutePlanner
                            id={element.id}
                            shift={shift}
                            routes={routes}
                            vehicles={vehicles}
                            driverId={driverElements[0]?.driverId}
                            route={element.publicRouteId}
                            vehicle={element.vehicleId}
                            name={element.name}
                            date={element.date}
                            status={element.planningStatusEnum}
                            duration={0}
                            elementsLoaded={planningsToBeUpdated}
                            elementsAlreadyLoaded={plannings}
                            onlyDuration={false}
                            responseDuration={(value) => {
                              if (value !== undefined && !Number.isNaN(value)) {
                                setDurations((prevDurations) => {
                                  const newDurations = [...prevDurations];
                                  newDurations[driverIndex] = newDurations[
                                    driverIndex
                                  ]
                                    ? newDurations[driverIndex] + value
                                    : 0 + value;
                                  return newDurations;
                                });
                              }
                            }}
                            response={(el: PublicRoutePlanningRequest) => {
                              setPlanningsToBeUpdated([
                                ...planningsToBeUpdated.filter(
                                  (e) =>
                                    !(
                                      e.driverId === el.driverId &&
                                      e.name === el.name &&
                                      new Date(e.date).getDate() ===
                                        new Date(el.date).getDate() &&
                                      new Date(e.date).getMonth() ===
                                        new Date(el.date).getMonth() &&
                                      new Date(e.date).getFullYear() ===
                                        new Date(el.date).getFullYear()
                                    )
                                ),
                                el,
                              ]);
                            }}
                          />
                        ))}
                    </div>
                  ))}
                </div>
                <div
                  id="element-planner-component-duration"
                  style={{ width: "min-content" }}
                >
                  <PublicRoutePlanner
                    responseDuration={() => {}}
                    shift={null}
                    id={null}
                    routes={[]}
                    driverId={0}
                    vehicles={[]}
                    route={0}
                    vehicle={0}
                    name={undefined}
                    date={new Date()}
                    status={undefined}
                    duration={durations[driverIndex]}
                    onlyDuration={true}
                    response={() => {}}
                  />
                </div>
              </div>
            ))}
        </div>
      </div>
    </>
  );
};
