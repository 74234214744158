import React from "react";
import PropTypes from "prop-types";
import "./RadialTime.css";

export const RadialTime = ({
  title,
  data,
  max,
  threshold,
  size,
  color,
  ...props
}) => {
  const chartColor = () => {
    if (threshold)
      return data > threshold
        ? window
          .getComputedStyle(document.documentElement)
          .getPropertyValue(color)
        : window
          .getComputedStyle(document.documentElement)
          .getPropertyValue("--global-colors-feedback-danger")
    else return window.getComputedStyle(document.documentElement).getPropertyValue(color);
  }

  const getSecond = (time) => {
    let a = time.split(":");
    return Number(a[0]) * 60 * 60 + Number(a[1]) * 60;
  };

  const percent = () => {
    if (max) {
      return (100 * Number(getSecond(data))) / Number(getSecond(max))
    }
    else if (getSecond(data) === 0)
      return 0;
    else return 100;
  }

  const style = {
    width: Number(size.width),
  };

  return (
    <div className="mn-chart-time" style={style} {...props}>
      <div className="mn-chart-radial-time">
        <svg
          viewBox="0 0 36 36"
          width={size.width}
          height={size.height ? size.height : size.width}
        >
          <path
            fill="none"
            stroke={"#F7F7F8"}
            strokeDasharray={`100, 100`}
            d="M18 2.084a15.915 15.915 0 010 31.831 15.915 15.915 0 010-31.83"
          ></path>
          {percent ? (
            <path
              fill="none"
              transform-origin="center"
              transform="scale(-1,1)"
              stroke={chartColor()}
              strokeDasharray={`${percent}, 100`}
              d="M18 2.084a15.915 15.915 0 010 31.831 15.915 15.915 0 010-31.83"
            ></path>
          ) : (
            ""
          )}
          {/* <text id="title" x="50%" y="40%">
            {title}
          </text>
          <text id="data" x="50%" y="60%">
            {data}
          </text> */}
        </svg>
        <div className="mn-chart-time-title">{title}</div>
        <div className="mn-chart-time-val">{data}</div>
      </div>
    </div>
  );
};

RadialTime.propTypes = {
  /**
   * Data of the cart:
   *  value:  value
   */
  data: PropTypes.string.isRequired,
  /**
   * Max value for the chart
   */
  max: PropTypes.string,
  /**
   *  Threshold: condition for changing chartColor
   */
  title: PropTypes.string,
  /**
   *  Title: title of the chart
   */
  threshold: PropTypes.string,
  /**
   * Size of the chart. If height is not defined it'll be used the width.
   */
  size: PropTypes.shape({
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  /**
   *  Color: color of the chart
   */
  color: PropTypes.string,
};

RadialTime.defaultProps = {
  data: "5:00",
  threshold: "1:30",
  size: {
    width: 106,
    height: 106,
  },
  title: "AVAILABLE",
  color: "--global-colors-ui-secondary",
};
