import { t } from "i18next";
import _ from "lodash";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { store } from "../../../app/store";
import { Button } from "../../../ui/Button/Button";
import { DropdownButton } from "../../../ui/Button/DropdownButton";
import { Dropdown } from "../../../ui/Dropdown/Dropdown";
import Form from "../../../ui/Forms/Form";
import SearchField from "../../../ui/Forms/SearchField";
import { IconAdd } from "../../../ui/Icon/Line/Add";
import { IconGroup } from "../../../ui/Icon/Line/Group";
import { IconUser } from "../../../ui/Icon/Line/User";
import { getQueryString } from "../../../utils/Utils";
import "./AdminNotificationFilterBar.css";
import {
  NotificationStatusEnum,
  getFilteredNotificationsRuleAggregationsAsync,
} from "./notificationRuleAggregationSlice";

interface AdminNotificationFilterBarProps {
  callback: (buildQueryParam: string) => any;
  presets: any[];
}

interface QueryParams {
  [paramName: string]: any;
}

interface DropDownItem {
  key: string;
  label: string;
}

interface StatusItem {
  key: string;
  label: string;
}

export const AdminNotificationsFilterBar: React.FC<AdminNotificationFilterBarProps> =
  ({ callback }) => {
    const queryParamsRef = useRef<QueryParams>({});
    const [searchParams] = useSearchParams();
    let queryParams: QueryParams = queryParamsRef.current;
    const navigate = useNavigate();
    const location = useLocation();
    const { pathname } = location;
    const [notificationStringSearch, setNotificationStringSearch] =
      useState<string>("");
    const [checkedStatusesValues, setCheckedStatusesValues] = useState<
      DropDownItem[]
    >([]);

    useEffect(() => {
      const map = new Map();
      const currentSearchParams =
        searchParams.toString() !== "" ? searchParams : null;
      if (!!currentSearchParams && _.isEmpty(queryParams)) {
        const size = currentSearchParams.get("size");
        map.set("size", size ?? "10");

        //SearchBar
        const nameSearch = currentSearchParams.get("name");
        if (nameSearch) {
          setNotificationStringSearch(nameSearch);
          map.set("name", nameSearch);
        }

        // Statuses Dropdown
        const notificationStatus = currentSearchParams?.getAll("status");
        if (!!notificationStatus) {
          const selectedStatuses: DropDownItem[] = [];
          notificationStatus.forEach((notificationStatus) => {
            selectedStatuses.push({
              key: notificationStatus,
              label: t(
                "admin.notifications.statuses." +
                  notificationStatus.toLowerCase()
              ),
            } as DropDownItem);
          });

          if (selectedStatuses && selectedStatuses.length > 0) {
            setCheckedStatusesValues(selectedStatuses);
            map.set(
              "status",
              selectedStatuses.map((x) => x.key)
            );
          }
        }
      }
      if (map.size === 0 && _.isEmpty(queryParams)) {
        map.set("size", "10");
      }
      if (map.size > 0) {
        handleChanges(map);
      }
    }, [location]);

    const handleChanges = (params: Map<string, string[] | string>): void => {
      let queryString = "";
      let executeQuery = false;
      if (!!params) {
        params.forEach((value, key) => {
          if (!!value && value.length > 0) {
            queryParams[key] = value;
            executeQuery = true;
          } else {
            if (queryParams.hasOwnProperty(key)) {
              delete queryParams[key];
              executeQuery = true;
            }
          }
        });
        queryString = getQueryString(queryParams);
      }
      // effettuare chiamata API con parametri in query string
      if (executeQuery) {
        store.dispatch(
          getFilteredNotificationsRuleAggregationsAsync({
            queryParams: queryString,
          })
        );
        navigate({
          pathname: pathname,
          search: queryString,
        });
        callback(queryString);
      }
    };

    const statusesValues: StatusItem[] =
      Object.values(NotificationStatusEnum)
        .filter((value) => typeof value === "string" && value !== "DELETED")
        .map((value) => {
          return {
            label: t("admin.notifications.statuses." + value.toLowerCase()),
            key: value,
          };
        }) ?? [];

    const addressBookList = [
      {
        id: 0,
        title: t("admin.notifications.addressBook.addContact"),
        icon: <IconAdd size={14} color="--global-colors-ink-light" />,
        onClick: () =>
          navigate({
            pathname: "/admin/notifications/add-contact",
            search: searchParams.toString(),
          }),
      },
      {
        id: 1,
        title: t("admin.notifications.addressBook.contactList"),
        icon: <IconGroup size={14} color="--global-colors-ink-light" />,
        onClick: () =>
          navigate({
            pathname: "/admin/notifications/contacts-list",
            search: searchParams.toString(),
          }),
      },
    ];

    const debouncedSearch = useRef(
      _.debounce(async (val) => {
        const searchNotification = new Map();
        searchNotification.set("name", val);
        handleChanges(searchNotification);
        setNotificationStringSearch(val);
      }, 1000)
    ).current;

    useEffect(() => {
      return () => {
        debouncedSearch.cancel();
      };
    }, [debouncedSearch]);

    async function handleSearch(val: string) {
      await debouncedSearch(val);
    }

    return (
      <>
        <div className="adminNotification-filterbar-row">
          <div className="notification-filterbar-search">
            <Form>
              <SearchField
                name="search"
                id="notifications"
                size="small"
                placeholder={t("admin.notifications.searchField.searchByName")}
                value={notificationStringSearch}
                onChange={(val: string) => {
                  handleSearch(val);
                }}
              />
            </Form>
          </div>
          <div className="notification-filterbar-statuses">
            <Dropdown
              placeholderInValue={t(
                "fleetControl.filterBar.placeholderInValStatus"
              )}
              itemAttribute="label"
              hasCheckbox={true}
              placeholder={t("fleetControl.filterBar.orderByStatus")}
              size={"small"}
              onChange={(val: DropDownItem[]) => {
                const map = new Map();
                const statusValues: string[] = [];
                val.forEach((v) => {
                  const statusValue = statusesValues.find(
                    (statusValue) => statusValue.key === v.key
                  )?.key;
                  if (statusValue) {
                    statusValues.push(statusValue);
                  }
                });
                map.set("status", statusValues);
                handleChanges(map);
              }}
              options={statusesValues}
              value={checkedStatusesValues}
            />
          </div>
          <div className="notification-filterbar-addressBook">
            <DropdownButton
              aspect="secondary"
              size="small"
              list={addressBookList}
              label={t("admin.notifications.addressBook.addressBook")}
            >
              <IconUser size={14} color="--global-colors-ink-ink" />
            </DropdownButton>
          </div>
          <div className="notification-filterbar-addRule">
            <Button
              aspect="primary"
              size="small"
              label={t("admin.notifications.actions.create.addRule")}
              onClick={() => navigate("/admin/notifications/add-rule")}
            >
              <IconAdd size={14} color="--global-colors-ink-white" />
            </Button>
          </div>
        </div>
      </>
    );
  };
