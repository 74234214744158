import React from "react";
import { IconBack } from "../Icon/Line/Back";
import "./BackToSummary.css";

export const BackToSummary = ({ text, ...props }) => {
  return (
    <div className="mn-back-to-summary" {...props}>
      <div className="mn-back-to-summary__icon">
        <IconBack size={16} color="--global-colors-ink-dark" />
      </div>
      <div className="mn-back-to-summary__text">
        {text}
      </div>
    </div>
  );
};
