import { t } from "i18next";
import _ from "lodash";
import { useEffect, useRef, useState } from "react";
import { useAppSelector } from "../../app/hooks";
import { store } from "../../app/store";
import { GTFleetErrorCodes } from "../../config/GTfleetErrorCodes";
import { UserBox } from "../../ui/Chat/UserBox";
import { Tooltip } from "../../ui/Forms/Tooltip";
import { getFullDate } from "../../utils/DateAndTimeUtils";
import { ToastNotification } from "../../utils/ToastNotification";
import { UserInfo, userInfoSelectors } from "../users/usersInfoSlice";
import { ChatSelected, UserListDropdownElement } from "./DashboardMessages";
import "./DashboardMessagesSidebar.css";
import {
  ChatType,
  chatSelector,
  getExistentChatAsync,
  selectChatsSliceReasonCode,
  selectChatsSliceStatus,
} from "./chatSlice";
import { selectMessagesSliceStatus } from "./messagesSlice";

interface DashboardMessagesSidebarProps {
  callback?: (driveId: number, buildQueryParam: string) => any;
  deactive: boolean;
  userList: UserListDropdownElement[];
  selectedChat: (e: any) => any;
}

export const DashboardMessagesSidebar: React.FC<DashboardMessagesSidebarProps> =
  ({ selectedChat, userList, deactive }) => {
    const searchbarRef = useRef<HTMLHeadingElement>(null);
    const [activeChat, setActiveChat] = useState(-1);

    const chatsSliceStatus = useAppSelector(selectChatsSliceStatus);
    const chatsSliceReasonCode = useAppSelector(selectChatsSliceReasonCode);
    const messagesSliceStatus = useAppSelector(selectMessagesSliceStatus);
    let users: UserInfo[] =
      useAppSelector(userInfoSelectors.selectAll) ?? ({} as UserInfo[]);

    let chats: ChatType[] = useAppSelector((state) =>
      chatSelector.selectAll(state)
    );
    // Make a copy of the original chats array, since sort() modifies it in place
    const sortedChats = [...chats];

    // Sort the new array using the provided comparison function
    sortedChats.sort(
      (a, b) =>
        new Date(b.dateLastMessage).getTime() -
        new Date(a.dateLastMessage).getTime()
    );

    useEffect(() => {
      deactive && setActiveChat(-1);
    }, [deactive]);

    useEffect(() => {
      if (
        chatsSliceStatus === "failed" &&
        chatsSliceReasonCode === GTFleetErrorCodes.INTERNAL_SERVER_ERROR
      ) {
        ToastNotification({
          toastId: "serverError",
          status: "error",
          description: t("common.serverError"),
        });
      } else if (chatsSliceStatus === "failed" && chatsSliceReasonCode === "") {
        ToastNotification({
          toastId: "networkError",
          status: "error",
          description: t("common.networkError"),
        });
      }
    }, [chatsSliceStatus, chatsSliceReasonCode]);

    useEffect(() => {
      if (users.length > 0) {
        const currentUser = Number(localStorage.getItem("customerId"));
        if (currentUser) {
          store.dispatch(getExistentChatAsync(currentUser));
        }
      }
    }, [users]);

    useEffect(() => {
      if (sortedChats.length > 0 && userList.length > 0) {
        const chatId = sortedChats[0]?.id;
        const title = userList.filter(
          (el) =>
            el.id === sortedChats[0].recipient &&
            el.type === sortedChats[0].recipientType
        )[0]?.title;
        const type = userList.filter(
          (el) => el.type === sortedChats[0].recipientType
        )[0]?.type;
        const id = userList.filter(
          (el) => el.id === sortedChats[0].recipient
        )[0]?.id;
        const selectedChatRecipient: ChatSelected = {
          selectedChatId: chatId,
          selectedRecipientId: id,
          selectedType: type,
          selectedUsername: title ? `${title} ` : title,
        } as ChatSelected;
        if (chatId && id && type && title) {
          selectedChat(selectedChatRecipient);
          setActiveChat(0);
        }
      }
    }, [chats, userList]);

    return (
      <div className="chats-list-container" ref={searchbarRef}>
        {sortedChats.length > 0 &&
          sortedChats.map((chat: ChatType, index: number) => {
            const user = userList.filter(
              (user) =>
                user.id === chat.recipient && user.type === chat.recipientType
            )[0];
            if (!_.isEmpty(user)) {
              const dataTooltip =
                chat.recipientType === "USER"
                  ? t("common.user")
                  : chat.recipientType === "DRIVER"
                  ? t("common.driver")
                  : t("common.addressBook");
              return (
                user.phone && (
                  <div key={index}>
                    <Tooltip />
                    <span
                      data-for="tooltip"
                      data-tooltip-delay-hide={1000}
                      data-tip={dataTooltip}
                    >
                      <UserBox
                        key={index}
                        title={user.title}
                        iconTitle={user.iconTitle}
                        subTitle={user.subTitle}
                        phone={user.phone}
                        iconSubTitle={user.iconSubTitle}
                        icon={user.icon}
                        onClicked={() => {
                          if (
                            index !== activeChat &&
                            messagesSliceStatus !== "loading"
                          ) {
                            selectedChat({
                              selectedChatId: chat.id,
                              selectedRecipientId: user.id,
                              selectedType: chat.recipientType,
                              selectedUsername: user.title,
                            });
                            setActiveChat(index);
                          }
                        }}
                        active={!deactive && index === activeChat}
                        data={getFullDate(chat.dateLastMessage, false, false)}
                      />
                    </span>
                  </div>
                )
              );
            }
          })}
        {sortedChats.length === 0 && (
          <span className="chat-list-no-value">
            {t("messages.sidebar.noValue")}
          </span>
        )}
      </div>
    );
  };
