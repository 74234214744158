import dayjs from "dayjs";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { store } from "../../../app/store";
import { ModalBody } from "../../../ui/Modal/ModalBody";
import { ModalFooter } from "../../../ui/Modal/ModalFooter";
import { ModalWrapper } from "../../../ui/Modal/ModalWrapper";
import { ScheduleCost } from "../../../ui/Schedules/ScheduleCost";
import { ScheduleDate } from "../../../ui/Schedules/ScheduleDate";
import { ScheduleInsurance } from "../../../ui/Schedules/ScheduleInsurance";
import { ScheduleNote } from "../../../ui/Schedules/ScheduleNote";
import { ScheduleType } from "../../../ui/Schedules/ScheduleType";
import { DataCommon } from "../../../utils/Common";
import { Preferences } from "../../users/preference/preferencesSlice";
import UserContext from "../../users/userContext";
import { Vehicle } from "../../vehicle/vehiclesSlice";
import { Deadline, updateDeadlineAsync } from "./../deadlinesSlice";
import "./EditDeadline.css";

interface EditDeadlineModalProps {
  open: boolean;
  onClose: () => any;
  defaultValues: Deadline;
  vehicleSelected?: Vehicle;
  deadlineId: number;
}
export const EditDeadlineModal: React.FC<EditDeadlineModalProps> = ({
  open,
  onClose,
  defaultValues,
  vehicleSelected,
  deadlineId,
}) => {
  const { t } = useTranslation();

  const [editDeadlineInfo, setEditDeadlineInfo] = useState({} as Deadline);

  const [isOpened, setIsOpened] = useState(-1);
  const [isEditedNote, setIsEditedNote] = useState(false);
  const [isEditedType, setIsEditedType] = useState(false);
  const [isEditedCost, setIsEditedCost] = useState(false);
  const [isEditedDate, setIsEditedDate] = useState(false);
  const [isEditedAdditionalInfo, setIsEditedAdditionInfo] = useState(false);

  const [preferencesContext]: [Preferences] = useContext(UserContext);

  const types: any = DataCommon()?.deadlineTypes;
  const companies: any = DataCommon()?.companies;

  const [buttonLoading, setButtonLoading] = useState(false);
  const [typeIsEmpty, getTypeIsEmpty] = useState(false);
  /**
   * The edited hook should be equal to the default value.
   */
  useEffect(() => {
    if (defaultValues && !_.isEmpty(defaultValues)) {
      setEditDeadlineInfo(defaultValues);
    }
  }, [defaultValues]);

  const handleSubmit = () => {
    setButtonLoading(true);
    if (isOpened !== -1) {
      setIsOpened(-1);
      setButtonLoading(false);
    }
    if (!buttonLoading) {
      const expirationDate =
        editDeadlineInfo?.expirationDate &&
        new Date(editDeadlineInfo.expirationDate);
      expirationDate && expirationDate.setHours(8);
      store.dispatch(
        updateDeadlineAsync({
          id: deadlineId,
          deadline: {
            ...editDeadlineInfo,
            expirationDate: expirationDate,
            isMaintenance: false,
            status: null,
            complete: false,
          },
        })
      );
      onClose();
    }
  };

  const submitModalRef = useRef<any>();
  useEffect(() => {
    const keyUp = (event: any) => {
      if (event.keyCode === 13) {
        event.preventDefault();
        handleSubmit();
      }
    };

    if (submitModalRef && submitModalRef.current) {
      submitModalRef.current.addEventListener("keydown", keyUp);
      let parentRef = submitModalRef;
      return () => {
        parentRef.current &&
          parentRef.current.removeEventListener("keydown", keyUp);
      };
    }
  });

  const localeFormatDate = preferencesContext.localeFormat ?? "DD / MM / YYYY";

  return (
    <div className="edit-deadline-container" ref={submitModalRef}>
      <ModalWrapper open={open} closeAction={onClose}>
        <div className="set-edit-deadline">
          <ModalBody
            title={
              defaultValues.complete
                ? t("customModals.addDeadlines.resumeViewTitle")
                : t("customModals.addDeadlines.viewTitle")
            }
            desc={
              defaultValues.complete
                ? `${t("customModals.addDeadlines.resumeDescConfFirst")} ${
                    vehicleSelected?.alias
                  } - ${vehicleSelected?.plate} ${t(
                    "customModals.addDeadlines.resumeDescConfLast"
                  )}`
                : vehicleSelected &&
                  `${t("customModals.addDeadlines.descConf")} ${
                    vehicleSelected?.alias
                  } - ${vehicleSelected?.plate}`
            }
          >
            <div className="split-line-edit-deadline" />
            <ScheduleType
              defaultType={{
                typeName: defaultValues?.type,
                customType:
                  (defaultValues?.additionalInfoJson &&
                    JSON.parse(defaultValues?.additionalInfoJson)
                      .customTypeName) ??
                  "",
              }}
              types={types}
              getTypeIsEmpty={getTypeIsEmpty}
              open={isOpened === 0}
              getCustomType={(data) => {
                const defaultCustomTypeName =
                  (defaultValues?.additionalInfoJson &&
                    JSON.parse(defaultValues?.additionalInfoJson)
                      .customTypeName) ??
                  "";
                if (data !== defaultCustomTypeName) {
                  setIsEditedAdditionInfo(true);
                  setEditDeadlineInfo({
                    // setting the updated value
                    ...editDeadlineInfo,
                    additionalInfoJson: JSON.stringify({
                      customTypeName: data,
                    }),
                  });
                } else {
                  setIsEditedAdditionInfo(false);
                  setEditDeadlineInfo({
                    // restoring the initial value
                    ...editDeadlineInfo,
                    additionalInfoJson: defaultValues?.additionalInfoJson,
                  });
                }
              }}
              getValue={(data) => {
                if (data !== defaultValues?.type) {
                  setIsEditedType(true);
                  setEditDeadlineInfo({
                    // setting the updated value
                    ...editDeadlineInfo,
                    type: data,
                  });
                } else {
                  setIsEditedType(false);
                  setEditDeadlineInfo({
                    // restoring the initial value
                    ...editDeadlineInfo,
                    type: defaultValues.type,
                  });
                }
              }}
              setIsOpened={setIsOpened}
            />
            <div className="split-line-edit-deadline" />
            <ScheduleDate
              defaultDate={
                defaultValues?.expirationDate
                  ? {
                      value:
                        defaultValues?.expirationDate.toString() ??
                        new Date().toString(),
                      repeat: defaultValues?.rescheduleMonths ?? 0,
                    }
                  : undefined
              }
              getValue={(data) => {
                const defaultCustomDate = defaultValues?.expirationDate
                  ? {
                      value:
                        defaultValues?.expirationDate.toString() ??
                        new Date().toString(),
                      repeat: defaultValues?.rescheduleMonths ?? 0,
                      isRepeated: Boolean(defaultValues?.rescheduleMonths),
                    }
                  : undefined;
                if (
                  (defaultCustomDate &&
                    data.date !==
                      dayjs(defaultCustomDate.value).format("YYYY-MM-DD")) ||
                  data.repeat !== defaultCustomDate?.repeat ||
                  data.isRepeated !== defaultCustomDate?.isRepeated
                ) {
                  setIsEditedDate(true);
                  setEditDeadlineInfo({
                    // setting the updated value
                    ...editDeadlineInfo,
                    rescheduleMonths: data.repeat,
                    expirationDate: data.date,
                  });
                } else {
                  setIsEditedDate(false);
                  setEditDeadlineInfo({
                    // restoring the initial value
                    ...editDeadlineInfo,
                    rescheduleMonths: defaultValues.rescheduleMonths,
                    expirationDate: defaultValues.expirationDate,
                  });
                }
              }}
              typeIsEmpty={typeIsEmpty}
              open={isOpened === 2}
              language={preferencesContext.language ?? "it"}
              localeFormat={localeFormatDate}
              setIsOpened={setIsOpened}
            />
            <div className="split-line-edit-deadline" />
            <ScheduleCost
              defaultCost={defaultValues?.cost ?? 0}
              open={isOpened === 1}
              setIsOpened={setIsOpened}
              typeIsEmpty={typeIsEmpty}
              getValue={(data) => {
                const defaultCost = defaultValues?.cost
                  ? defaultValues.cost
                  : 0;
                if (data !== defaultCost.toString) {
                  setIsEditedCost(true);
                  setEditDeadlineInfo({
                    ...editDeadlineInfo,
                    cost: data, // setting the updated value
                  });
                } else {
                  setIsEditedCost(false);
                  setEditDeadlineInfo({
                    ...editDeadlineInfo,
                    cost: defaultValues.cost, // restoring the initial value
                  });
                }
              }}
            />
            <div className="split-line-edit-deadline" />
            {((defaultValues?.type === "INSURANCE" && !isEditedType) ||
              editDeadlineInfo?.type === "INSURANCE") && (
              <>
                <ScheduleInsurance
                  defaultCompany={
                    (defaultValues?.additionalInfoJson &&
                      JSON.parse(defaultValues?.additionalInfoJson)
                        .insuranceName) ??
                    ""
                  }
                  companies={companies}
                  typeIsEmpty={typeIsEmpty}
                  open={isOpened === 5}
                  setIsOpened={setIsOpened}
                  getValue={(data) => {
                    const defaultCustomCompanyName =
                      defaultValues?.additionalInfoJson &&
                      JSON.parse(defaultValues?.additionalInfoJson)
                        .insuranceName;
                    if (
                      defaultValues?.additionalInfoJson &&
                      defaultCustomCompanyName &&
                      data !== defaultCustomCompanyName
                    ) {
                      setIsEditedAdditionInfo(true);
                      setEditDeadlineInfo({
                        ...editDeadlineInfo,
                        additionalInfoJson: JSON.stringify({
                          insuranceName: data, // setting the updated value
                        }),
                      });
                    } else {
                      setIsEditedAdditionInfo(false);
                      setEditDeadlineInfo({
                        ...editDeadlineInfo,
                        additionalInfoJson: defaultValues.additionalInfoJson, // restoring the initial value
                      });
                    }
                  }}
                />
                <div className="split-line-edit-deadline" />
              </>
            )}
            <ScheduleNote
              defaultNote={defaultValues?.note}
              getValue={(data) => {
                if (data !== defaultValues.note) {
                  setIsEditedNote(true);
                  setEditDeadlineInfo({
                    ...editDeadlineInfo,
                    note: data, // setting the updated value
                  });
                } else {
                  setIsEditedNote(false);
                  setEditDeadlineInfo({
                    ...editDeadlineInfo,
                    note: defaultValues.note, // restoring the initial value
                  });
                }
              }}
              typeIsEmpty={typeIsEmpty}
              open={isOpened === 3}
              setIsOpened={setIsOpened}
            />
            <div className="split-line-edit-deadline" />
          </ModalBody>
        </div>
        <ModalFooter
          hasBack={true}
          primaryLabel={t("common.confirm")}
          primaryAction={handleSubmit}
          loadingPrimaryButton={buttonLoading}
          disablePrimaryButton={
            !defaultValues.complete
              ? !(
                  isEditedAdditionalInfo ||
                  isEditedCost ||
                  isEditedNote ||
                  isEditedType ||
                  isEditedDate
                )
              : false || typeIsEmpty
          }
          secondaryLabel={t("common.cancel")}
          secondaryAction={() => {
            onClose();
          }}
        />
      </ModalWrapper>
    </div>
  );
};
EditDeadlineModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  vehicleSelected: PropTypes.any,
  deadlineId: PropTypes.number.isRequired,
};

EditDeadlineModal.defaultProps = {
  open: false,
};
