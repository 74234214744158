import PropTypes from "prop-types";

import { IconAdd } from "../Icon/Line/Add";
import { IconMinus } from "../Icon/Line/Minus";

import i18next from "i18next";
import { Tooltip } from "../Forms/Tooltip";
import "./Zoom.css";

export const Zoom = ({
  plusEnable,
  minusEnable,
  handleMinus,
  handlePlus,
  className,
  ...props
}) => {
  return (
    <>
      <Tooltip />
      <div
        className={["zoom-control", className].join(" ")}
        {...props}
        data-for="tooltip"
        data-tip={i18next.t("tooltip.zoom")}
      >
        <button
          className={
            minusEnable ? "zoom-control__plus" : "zoom-control__plus-disabled"
          }
          onClick={handlePlus}
        >
          <IconAdd color="--global-colors-ui-white" size={14} />
        </button>
        <button
          className={
            minusEnable ? "zoom-control__minus" : "zoom-control__minus-disabled"
          }
          onClick={handleMinus}
        >
          <IconMinus color="--global-colors-ui-white" size={14} />
        </button>
      </div>
    </>
  );
};

Zoom.propTypes = {
  plusEnable: PropTypes.bool,
  minusEnable: PropTypes.bool,
};

Zoom.defaultProps = {
  plusEnable: true,
  minusEnable: true,
};
