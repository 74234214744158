import React from "react";
import PropTypes from "prop-types";
import { LocationInformation } from "../Map/LocationInformation";
import { StatusBadge } from "../Map/StatusBadge";
import { IconClose } from "../Icon/Line/Close";
import { Button } from "../Button/Button";
import "./VehicleMap.css";
import { BaseMap } from "../Map/BaseMap";

export const VehicleMap = ({
  vehicleId,
  status,
  location,
  latitude,
  longitude,
  time,
  onClose,
  actionsProps,
  ...props
}) => {
  return (
    <div className="mn-mw-vehicle-map" {...props}>
      <BaseMap id={vehicleId} googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY} zoom={12} hasStreetView={false} latitude={latitude} longitude={longitude} />
      <StatusBadge id={vehicleId} status={status} />
      <LocationInformation location={location} time={time} />
      <Button
        label=""
        onClick={() => {
          onClose();
        }}
        onlyIcon
        size="small"
        aspect="danger"
      >
        <IconClose size={11} color="currentColor" />
      </Button>
    </div>
  );
};

VehicleMap.propTypes = {
  status: PropTypes.oneOf(["NO_SIGNAL", "ONLINE", "OFFLINE", "UNKNOWN"]),
  vehicleId: PropTypes.string,
  location: PropTypes.string,
  time: PropTypes.string,
  onClose: PropTypes.func,
  actionsProps: PropTypes.object,
};

VehicleMap.defaultProps = {
  status: "ONLINE",
  vehicleId: "A01",
  location: "Roma, Autostrada Roma L'Aquila Teramo",
  latitude: 42.30714523594324,
  longitude: 13.302415701962245,
  time: "10:58",
};
