import PropTypes from "prop-types";

import "./ButtonList.css";

// Icons
import { IconAlertSimple } from "../Icon/Line/AlertSimple";
import { IconArrowRight } from "../Icon/Line/ArrowRight";
import { IconCheck } from "../Icon/Line/Check";
import { IconClose } from "../Icon/Line/Close";
import { IconCloudUpdate } from "../Icon/Line/CloudUpdate";
import { IconTimer } from "../Icon/Line/Timer";

export const ButtonList = ({
  status,
  className,
  buttonText,
  size = 12,
  ...props
}) => {
  const statusClass = `button-list--${status}`;
  const content = () => {
    switch (status) {
      case "enabled":
        return (
          <span>
            <span
              className="enabled"
              style={{
                height: size + 9,
                justifyContent: "center",
                display: "flex",
              }}
            >
              {buttonText}
            </span>
            <IconCheck size={size} color="--global-colors-ui-white" />
          </span>
        );
      case "disabled":
        return (
          <span>
            <span
              className="text"
              style={{
                width: size + 10,
                height: size + 10,
                justifyContent: "center",
                display: "flex",
              }}
            >
              {buttonText}
            </span>
            <IconArrowRight size={size} color="--global-colors-ui-secondary" />
          </span>
        );
      case "failure":
        return (
          <span
            className="failure"
            style={{
              width: size + 10,
              height: size + 10,
              justifyContent: "center",
              display: "flex",
            }}
          >
            <IconAlertSimple size={size} color="--global-colors-ui-white" />
          </span>
        );
      case "deleted":
        return (
          <span
            className="deleted"
            style={{
              width: size + 10,
              height: size + 10,
              justifyContent: "center",
              display: "flex",
              paddingBottom: "2px",
            }}
          >
            <IconClose size={size - 2} color="#35465B" />
          </span>
        );
      case "pending":
        return (
          <span
            className="pending"
            style={{
              width: size + 10,
              height: size + 10,
              justifyContent: "center",
              display: "flex",
            }}
          >
            <IconTimer size={size} color="--global-colors-ui-white" />
          </span>
        );
      case "update":
        return (
          <span
            className="update"
            style={{
              width: size + 10,
              height: size + 10,
              justifyContent: "center",
              display: "flex",
            }}
          >
            <IconCloudUpdate size={size} color="--global-colors-ui-white" />
          </span>
        );
      case "empty":
        return (
          <span
            className="empty"
            style={{
              width: size + 10,
              height: size + 10,
              justifyContent: "center",
              display: "flex",
            }}
          ></span>
        );
      default:
        return (
          <span>
            <span
              className="text"
              style={{
                width: size + 10,
                height: size + 10,
                justifyContent: "center",
                display: "flex",
              }}
            >
              {buttonText}
            </span>
            <IconArrowRight size={size} />
          </span>
        );
    }
  };
  return (
    <button
      className={["button-list", statusClass, className].join(" ")}
      {...props}
    >
      {content()}
    </button>
  );
};

ButtonList.propTypes = {
  status: PropTypes.oneOf([
    "enabled",
    "disabled",
    "failure",
    "pending",
    "update",
    "empty",
    "deleted",
  ]),
};
