import { schema } from "normalizr";

//#region TACHOGRAPH_FILE_STATUS_SUMMARY
export const tachographFileStatusSummarySchema: schema.Entity<any> =
  new schema.Entity("tachographFileStatusSummary", {}, {});
//#endregion TACHOGRAPH_FILE_STATUS_SUMMARY

//#region TACHOGRAPH_FILE_STATUS
export const tachographFileStatusSchema: schema.Entity<any> = new schema.Entity(
  "tachographFileStatus",
  {},
  { idAttribute: "fileName" }
);
export const tachographFilesStatusSchema = new schema.Array(
  tachographFileStatusSchema
);
//#endregion TACHOGRAPH_FILE_STATUS

//#region TACHOGRAPH_FILE
export const tachographFileSchema: schema.Entity<any> = new schema.Entity(
  "tachographFile",
  {},
  { idAttribute: "fileName" }
);
export const tachographFilesSchema = new schema.Array(tachographFileSchema);
//#endregion TACHOGRAPH_FILE
