import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import _ from "lodash";
import { normalize } from "normalizr";
import { RootState } from "../../../app/store";
import { Config } from "../../../config/Config";
import { GTFleetSuccessCodes } from "../../../config/GTFleetSuccessCodes";
import { refuelReportSummarySchema } from "./RefuellingReportNormalization";
import RefuellingReportRepository from "./refuellingReportRepository";

export type RefuellingReportSummary = {
  totalTraveled: number;
  refueled: number;
  refuels: number;
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const getRefuellingReportSummaryAsync = createAsyncThunk(
  "RefuellingReport/getRefuellingReportSummary",
  async (data: { vehicleId: number; queryParams: string }) => {
    const refuellingReportRepository = new RefuellingReportRepository();
    const response =
      await refuellingReportRepository.getRefuellingReportSummary(
        data.vehicleId,
        data.queryParams
      );
    const reports = _.get(response, Config.REFUELLING_REPORT_RESPONSE_PATH);
    const normalizedData = normalize(reports, refuelReportSummarySchema);
    return normalizedData.entities;
  }
);

const refuellingReportSummaryAdapter =
  createEntityAdapter<RefuellingReportSummary>({
    selectId: (RefuellingReport) => RefuellingReport.totalTraveled,
  });

export const refuellingReportSummarySlice = createSlice({
  name: "refuellingReportSummary",
  initialState: refuellingReportSummaryAdapter.getInitialState({
    status: "idle",
    reasonCode: "",
  }),
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    refuellingReportSummaryEmptyState: (state) => {
      refuellingReportSummaryAdapter.setAll(state, []);
      state.reasonCode = "";
      state.status = "idle";
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      // getRefuellingReportAsync extra reducers
      .addCase(
        getRefuellingReportSummaryAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          refuellingReportSummaryAdapter.setAll(
            state,
            action.payload.refuelReportsSummary
          );
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.GET;
        }
      )
      .addCase(getRefuellingReportSummaryAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(getRefuellingReportSummaryAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = "";
      });
  },
});

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const refuellingReportSummarySelectors =
  refuellingReportSummaryAdapter.getSelectors<RootState>(
    (state) => state.refuellingReportSummary
  );

export const selectRefuellingReportSummarySliceStatus = (state: any) =>
  state.refuellingReportSummary.status;
export const selectRefuellingReportSummarySliceReasonCode = (state: any) =>
  state.refuellingReportSummary.reasonCode;
export const { refuellingReportSummaryEmptyState } =
  refuellingReportSummarySlice.actions;

export default refuellingReportSummarySlice.reducer;
