import { useContext, useEffect, useRef, useState } from "react";
import { useAppSelector } from "../../../app/hooks";
import { store } from "../../../app/store";
import PageContent from "../../../layout/PageContent";
import PageFilters from "../../../layout/PageFilters";
import { Table } from "../../../ui/Table/Table";
import { ToastNotification } from "../../../utils/ToastNotification";
import { getQueryString } from "../../../utils/Utils";
import {
  Preset,
  presetsSelectors,
  selectpresetsSliceStatus,
} from "../../users/preset/presetsSlice";
import {
  getVehicleTravelsSummaryAsync,
  selectVehicleTravelsSummarySliceReasonCode,
  selectVehicleTravelsSummarySliceStatus,
  vehicleTravelsSummarySelectors,
  VehicleTravelSummary,
} from "./vehicleTravelsSummarySlice";

import { t } from "i18next";
import _ from "lodash";
import { Checkbox } from "../../../ui/Forms/Checkbox";
import { MapMarkerTruckNumber } from "../../../ui/Marker/TruckNumber";
import { PageCounter } from "../../../ui/Table/PageCounter";
import {
  Driver,
  driversEmptyState,
  driversSelectors,
} from "../../driver/driversSlice";
import { FleetView, fleetViewsSelectors } from "../../fleet/fleetViewsSlice";
import UserContext from "../../users/userContext";
import { Vehicle, vehiclesSelectors } from "../../vehicle/vehiclesSlice";
import { ReportMap } from "../ReportMap";
import { VehicleFilterBarReport } from "./VehicleReportFilterBar";
import "./VehicleTravelReport.css";
import { VehicleTravelReportTableBuilder } from "./VehicleTravelReportTableBuilder";
import {
  VehicleTravelsMacroSummary,
  vehicleTravelsMacroSummarySelectors,
} from "./vehicleTravelsMacroSummarySlice";

import dayjs from "dayjs";
import { Button } from "../../../ui/Button/Button";
import { IconMap } from "../../../ui/Icon/Line/Map";
import { getFilteredDriversStatusAndDetailsAsync } from "../../driver/driversStatusSlice";
import { RouteStateType } from "../../route/routesHistorySlice";
import { Preferences } from "../../users/preference/preferencesSlice";
import { UserPermissions } from "../../users/privilege/privilegesSlice";
import {
  getGPSDatasTravelAsync,
  GPSDataReport,
  gpsDataReportsSelectors,
} from "../gpsData/gpsDataReportSlice";
import { TravelDetailModal } from "../TravelDetailModal";
import { TravelDetail } from "../TravelDetailTypes";
import { VehicleTravelReportDetails } from "./VehicleTravelReportDetails";
import { VehicleStopReportDetails } from "./VehicleTravelReportStopsDetails";

interface VehicleTravelReportProps {
  permissions: UserPermissions;
}

interface TableData {
  columns: { label: string; field: string; sort: boolean }[] | undefined;
  rows: { [key: string]: any };
}

const tableData: TableData = {} as TableData;

export const VehicleTravelReport: React.FC<VehicleTravelReportProps> = ({
  permissions,
}) => {
  const tableBuilderRef = useRef<VehicleTravelReportTableBuilder>(
    new VehicleTravelReportTableBuilder()
  );
  let tableBuilder = tableBuilderRef.current;

  const vehicleTravelsMacroSummary: VehicleTravelsMacroSummary[] =
    useAppSelector(vehicleTravelsMacroSummarySelectors.selectAll);

  const [resetPage, setResetPage] = useState<boolean>(false);

  const [dateRange, setDateRange] = useState<string[]>();

  let presets: Preset[] = useAppSelector(presetsSelectors.selectAll);

  const presetsSliceStatus = useAppSelector(selectpresetsSliceStatus);

  const [queryParamsFromFilterBar, setQueryParamsFromFilterBar] =
    useState<string>("");

  const [routeStateType, setRouteStateType] = useState<RouteStateType>("TRACK");

  const vehicleTravelsSummary: VehicleTravelSummary[] = useAppSelector(
    vehicleTravelsSummarySelectors.selectAll
  );

  const vehicleTravelsSummarySliceStatus = useAppSelector(
    selectVehicleTravelsSummarySliceStatus
  );
  const vehicleTravelsSummarySliceReasonCode = useAppSelector(
    selectVehicleTravelsSummarySliceReasonCode
  );
  const [preferencesContext]: [Preferences] = useContext(UserContext);

  const [currentSelectedTravel, setCurrentSelectedTravel] = useState(-1);
  const [vehicleId, setVehicleId] = useState(-1);

  const isReportsIdle = vehicleTravelsSummarySliceStatus === "idle";

  const trackColors = [
    "--global-colors-ui-primary",
    "--global-colors-palette-violet",
    "--global-colors-palette-green",
    "--global-colors-palette-orange",
    "--global-colors-palette-bordeaux",
    "--global-colors-palette-yellow",
    "--global-colors-palette-light-bordeaux",
    "--global-colors-palette-light-blue",
    "--global-colors-palette-light-violet",
    "--global-colors-palette-light-red",
  ];

  interface CheckedProps {
    id: number;
    color: string;
    number: number;
    vehicleId: number;
    queryParams: string;
  }

  const [viewMap, setViewMap] = useState(false);
  const [checkedList, setCheckedList] = useState<CheckedProps[]>([]);
  const [removedIndexes, setRemovedIndexes] = useState<number[]>([]);

  useEffect(() => {
    if (isReportsIdle) {
      if (resetPage) {
        setCheckedList([]);
        setResetPage(false);
      }
    }
  }, [isReportsIdle]);

  const [tableHeaderHeight, setTableHeaderHeight] = useState<string>("0");
  const [tableRowHeight, setTableRowHeight] = useState<string>("0");

  useEffect(() => {
    const handleResize = () => {
      const tableBody = document.getElementById("vtr-table-body");
      const tableHeader = document.getElementById("vtr-table-header");
      if (tableHeader && tableBody) {
        setTableRowHeight(tableBody.clientHeight + "px");
        setTableHeaderHeight(tableHeader.clientHeight + "px");
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [viewMap]);

  useEffect(() => {
    if (
      vehicleTravelsSummarySliceStatus === "failed" &&
      vehicleTravelsSummarySliceReasonCode === ""
    ) {
      console.error("common.networkError");
      ToastNotification({
        toastId: "networkError",
        status: "error",
        description: t("common.networkError"),
      });
    }
  }, [vehicleTravelsSummarySliceStatus, vehicleTravelsSummarySliceReasonCode]);

  const selectedTravel: GPSDataReport[] = useAppSelector(
    gpsDataReportsSelectors.selectAll
  );

  const selectVehicle: Vehicle =
    vehiclesSelectors.selectById(store.getState(), vehicleId) ??
    ({} as Vehicle);

  useEffect(() => {
    if (currentSelectedTravel !== -1) {
      store.dispatch(
        getGPSDatasTravelAsync({
          routeId: currentSelectedTravel,
        })
      );
    }
  }, [currentSelectedTravel]);

  let context =
    routeStateType === "TRACK" ? "vehicleTravelReport" : "vehicleStopReport";

  if (presetsSliceStatus === "idle" && presets.length > 0 && resetPage) {
    let preset: Preset | undefined = undefined;
    const undefinedPreset = presets.find(
      (element) => element.name === undefined
    );
    const definedPreset = presets.find(
      (element) => element.lastSelected === true
    );
    preset = undefinedPreset
      ? undefinedPreset
      : definedPreset
      ? definedPreset
      : preset;
    if (!preset) {
      const contextPreset = presets.find(
        (element) => element.name === "Default" && element.context === context
      );
      preset = contextPreset ?? preset;
    }

    tableData.columns = tableBuilder.setColumns(
      preset?.columns
        ? preset?.columns.map((element) => {
            return {
              name: element,
              sort: element === "travelVehicle" ? true : false,
            };
          })
        : [],
      preferencesContext
    );
  }

  tableData.rows =
    isReportsIdle &&
    presetsSliceStatus === "idle" &&
    tableData.columns &&
    tableData?.columns?.length > 0
      ? vehicleTravelsSummary.map(
          (vehicleTravelSummary: VehicleTravelSummary) => {
            let drivers: Driver[] = [] as Driver[];
            if (vehicleTravelSummary.driverIds?.length > 0) {
              drivers = vehicleTravelSummary.driverIds
                .map((driverId: number) => {
                  return (
                    driversSelectors.selectById(
                      store.getState(),
                      driverId ?? -1
                    ) ?? ({} as Driver)
                  );
                })
                .filter((driver) => !_.isEmpty(driver));
            }

            let fleetView: FleetView =
              fleetViewsSelectors.selectById(
                store.getState(),
                selectVehicle.fleet
              ) ?? ({} as FleetView);
            return tableBuilder.rowsBuilder(
              tableData.columns,
              {
                travelDetail: vehicleTravelSummary as TravelDetail,
                vehicle: selectVehicle,
                drivers: drivers,
                fleetView,
                setCurrentSelectedTravel,
                dateRange:
                  dateRange !== undefined && !_.isEmpty(dateRange)
                    ? dateRange
                    : [
                        dayjs(new Date().setHours(0, 0, 0, 0)).format(
                          "YYYY-MM-DDTHH:mm"
                        ),
                        dayjs().format("YYYY-MM-DDTHH:mm"),
                      ],
              },
              preferencesContext
            );
          }
        )
      : tableData.rows;

  const onCheckboxChange = (
    el: any,
    id: number,
    color: string,
    vehicleId: number,
    queryParams: string
  ) => {
    let updatedCheckedList = [...checkedList];
    let updatedRemovedIndexes = [...removedIndexes];
    if (el.target.checked) {
      let indexToAssign = 0;
      if (updatedRemovedIndexes.length > 0) {
        indexToAssign = updatedRemovedIndexes[0];
        updatedRemovedIndexes.shift();
      } else {
        indexToAssign = updatedCheckedList.length;
      }
      updatedCheckedList.push({
        id: id,
        color: color,
        number: indexToAssign,
        vehicleId: vehicleId,
        queryParams: queryParams,
      });
    } else {
      updatedCheckedList = checkedList.filter(
        (Checked: any) => Checked.id !== id
      );
      const currentRemovedElement = checkedList.find(
        (Checked: any) => Checked.id === id
      );
      if (currentRemovedElement) {
        if (!updatedRemovedIndexes.includes(currentRemovedElement?.number)) {
          updatedRemovedIndexes.push(currentRemovedElement?.number);
        }
      }
    }
    setRemovedIndexes(_.sortBy(updatedRemovedIndexes));
    setCheckedList(updatedCheckedList);
  };

  function isSelected(option: any) {
    return checkedList ? checkedList.some((el) => el.id === option) : false;
  }

  useEffect(() => {
    currentSelectedTravel !== -1 &&
      selectedTravel.length === 0 &&
      ToastNotification({
        toastId: "DetailNotFound",
        status: "error",
        description: t("report.travel.vehicle.DetailNotFound"),
      });
  }, [selectedTravel]);

  useEffect(() => {
    document.title =
      t("breadcrumbs.vehicleTravelReport") + " - " + t("breadcrumbs.reports");
    store.dispatch(getFilteredDriversStatusAndDetailsAsync(""));
    return () => {
      store.dispatch(driversEmptyState());
    };
  }, []);
  const isLoading = vehicleTravelsSummarySliceStatus === "loading";

  return (
    <>
      <PageFilters>
        <div className="col col-16">
          <VehicleFilterBarReport
            presets={presets}
            callback={(vehicleId, queryParams, routeType, dateRange) => {
              if (dateRange) {
                setDateRange(dateRange);
              }
              if (routeType) {
                setRouteStateType(routeType as RouteStateType);
              }
              setVehicleId(vehicleId);
              setQueryParamsFromFilterBar(queryParams);
              setResetPage(true);
            }}
            disableButton={tableData.rows?.length === 0}
          />
        </div>
        {queryParamsFromFilterBar !== "" && (
          <div className="col col-16">
            <div className="details-report-wrapper">
              <div className="details-report-title">
                {t("report.details.title")}
              </div>
              <div className="details-report-container">
                <div className="details-report-icons">
                  {routeStateType === "TRACK" ? (
                    <VehicleTravelReportDetails
                      travels={vehicleTravelsMacroSummary[0]?.totalTravels}
                      kmTot={vehicleTravelsMacroSummary[0]?.totalTraveled}
                      consTot={
                        vehicleTravelsMacroSummary[0]?.totalFuelConsumption
                      }
                      speedMax={vehicleTravelsMacroSummary[0]?.macroMaxSpeed}
                      speedAvg={
                        vehicleTravelsMacroSummary[0]?.macroAverageSpeed
                      }
                      driveTime={
                        vehicleTravelsMacroSummary[0]?.totalDrivingTime ?? 0
                      }
                      idleTime={
                        vehicleTravelsMacroSummary[0]?.totalIdleTime ?? 0
                      }
                    />
                  ) : (
                    <VehicleStopReportDetails
                      stops={vehicleTravelsMacroSummary[0]?.totalTravels}
                      parkTimeAvg={
                        vehicleTravelsMacroSummary[0]?.averageParkingTime ?? 0
                      }
                      parkTimeTotal={
                        vehicleTravelsMacroSummary[0]?.totalParkingTime ?? 0
                      }
                    />
                  )}
                </div>
                <div className="details-report-button">
                  <Button
                    aspect="secondary"
                    size="small"
                    label={
                      viewMap
                        ? t("report.details.hideMap")
                        : t("report.details.viewMap")
                    }
                    onClick={() => {
                      setViewMap(!viewMap);
                      checkedList.length = 0;
                    }}
                    disabled={tableData.rows?.length === 0}
                  >
                    <IconMap size={14} color="--global-colors-ink-light" />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}
      </PageFilters>
      {queryParamsFromFilterBar !== "" && tableData.rows?.length > 0 && (
        <div className="vtr-container">
          <PageContent>
            <div
              style={
                !viewMap
                  ? {
                      display: "inline-flex",
                    }
                  : {
                      display: "inline-flex",
                      overflow: "hidden",
                    }
              }
            >
              <div
                className={
                  viewMap
                    ? "vtr-table-container-collapsed"
                    : "vtr-table-container"
                }
              >
                <div style={{ display: "inline-flex", width: "100%" }}>
                  <div
                    className="vtr-checkbox-list"
                    style={{ marginTop: tableHeaderHeight }}
                  >
                    {viewMap &&
                      tableData.rows.map((_elem: any, idx: number) => (
                        <div
                          key={idx}
                          className="vtr-checkbox"
                          style={{
                            height: tableRowHeight,
                          }}
                        >
                          <Checkbox
                            checked={isSelected(
                              vehicleTravelsSummary[idx].routeId
                            )}
                            onChange={(e: any) => {
                              onCheckboxChange(
                                e,
                                vehicleTravelsSummary[idx].routeId,
                                trackColors[idx],
                                vehicleTravelsSummary[idx].vehicleId,
                                queryParamsFromFilterBar
                              );
                            }}
                            label=""
                            disabled={
                              checkedList.length >= 10 &&
                              idx !== checkedList[idx].id &&
                              !isSelected(vehicleTravelsSummary[idx].routeId)
                            }
                          />
                        </div>
                      ))}
                  </div>
                  <div>
                    <PageCounter
                      isActionPerforming={isLoading}
                      totalElements={
                        vehicleTravelsMacroSummary[0]
                          ? vehicleTravelsMacroSummary[0]?.totalTravels
                          : 0
                      }
                      resetPage={resetPage} // every time query params changes resets number of page
                      disabled={!isReportsIdle}
                      progressiveArrowMode={true}
                      numOfPages={
                        !vehicleTravelsMacroSummary[0]?.totalTravels
                          ? 1
                          : Math.ceil(
                              vehicleTravelsMacroSummary[0]?.totalTravels / 10
                            )
                      }
                      numSelected={0}
                      onClick={(id) => {
                        const pageAndSize = getQueryString({
                          page: id - 1,
                          size: "10",
                        });
                        const finalQueryParams = queryParamsFromFilterBar
                          ? queryParamsFromFilterBar +
                            pageAndSize.replace("?", "&")
                          : pageAndSize;
                        store.dispatch(
                          getVehicleTravelsSummaryAsync({
                            vehicleId: vehicleId,
                            queryParams: finalQueryParams,
                            page: id - 1,
                          })
                        );
                      }}
                    />
                  </div>
                  <div
                    className={
                      checkedList.length > 0
                        ? "vtr-rows-container-collapsed-numbers"
                        : viewMap
                        ? "vtr-rows-container-collapsed"
                        : "vtr-rows-container"
                    }
                  >
                    <Table data={tableData}>
                      <Table.Head id="vtr-table-header" hasTableSpace={true} />
                      <Table.Body id="vtr-table-body" />
                    </Table>
                  </div>
                  <div
                    className="vtr-marker-list"
                    style={{ marginTop: tableHeaderHeight }}
                  >
                    {viewMap &&
                      checkedList.length > 0 &&
                      tableData.rows.map((_el: any, index: number) => (
                        <div
                          key={index}
                          className="vtr-marker"
                          style={{
                            height: tableRowHeight,
                          }}
                        >
                          {checkedList.map(
                            (element) =>
                              element.id ===
                                vehicleTravelsSummary[index].routeId && (
                                <div key={index}>
                                  <MapMarkerTruckNumber
                                    number={element.number + 1}
                                    color={trackColors[index]}
                                  />
                                </div>
                              )
                          )}
                        </div>
                      ))}
                  </div>
                </div>
              </div>
              {viewMap && (
                <div className="vtr-map-container">
                  <ReportMap
                    id={0}
                    googleMapsApiKey={
                      process.env.REACT_APP_GOOGLE_MAPS_API_KEY!
                    }
                    zoom={9}
                    latitude={41.9109}
                    longitude={12.4818}
                    checkedList={checkedList}
                    limit={10}
                  />
                </div>
              )}
            </div>
          </PageContent>
          {currentSelectedTravel !== -1 && selectedTravel.length > 0 && (
            <TravelDetailModal
              open={currentSelectedTravel !== -1 ? true : false}
              data={{
                gpsData: selectedTravel,
                travel:
                  (vehicleTravelsSummarySelectors.selectById(
                    store.getState(),
                    currentSelectedTravel
                  ) as TravelDetail) ?? ({} as TravelDetail),
                routeStateType: routeStateType,
              }}
              onClose={() => {
                setCurrentSelectedTravel(-1);
              }}
            />
          )}
        </div>
      )}
    </>
  );
};
