import {
  PayloadAction,
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import _ from "lodash";
import { normalize } from "normalizr";
import { RootState } from "../../../app/store";
import { Config } from "../../../config/Config";
import { GTFleetSuccessCodes } from "../../../config/GTFleetSuccessCodes";
import GPSDataRepository from "../../route/gpsDataRepository";
import { gpsDataReportsSummarySchema } from "../reportsNormalization";

export type GPSDataReportsSummary = {
  id: number;
  totalTraveled: number;
  fuelConsumption: number;
  maxSpeed: number;
  averageSpeed: number;
  stopsNumber: number;
  totalElements: number;
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const getGPSDataReportsSummaryAsync = createAsyncThunk(
  "gpsDataReports/getGPSDataReportsSummary",
  async (queryParams: string) => {
    const gpsDataRepository = new GPSDataRepository();
    const response = await gpsDataRepository.getGPSDataReportsSummary(
      queryParams
    );
    // The value we return becomes the `fulfilled` action payload
    const reports = _.get(
      response,
      Config.GPS_DATA_REPORTS_SUMMARY_RESPONSE_PATH
    );
    const pageInfo = _.get(response, "page");
    const normalizedData = normalize(reports, gpsDataReportsSummarySchema);
    _.assign(normalizedData.entities, pageInfo);
    return normalizedData.entities;
  }
);

const gpsDataReportsSummaryAdapter = createEntityAdapter<GPSDataReportsSummary>(
  {
    selectId: (gpsDataReportsSummary) => gpsDataReportsSummary.id,
  }
);

export const gpsDataReportsSummarySlice = createSlice({
  name: "gpsDataReportsSummary",
  initialState: gpsDataReportsSummaryAdapter.getInitialState({
    status: "idle",
    reasonCode: "",
  }),
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    gpsDataReportsSummaryEmptyState: (state) => {
      gpsDataReportsSummaryAdapter.setAll(state, []);
      state.reasonCode = "";
      state.status = "idle";
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      // getGPSDataReportsSummaryAsync extra reducers
      .addCase(
        getGPSDataReportsSummaryAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          gpsDataReportsSummaryAdapter.setAll(
            state,
            action.payload.gpsDataReportsSummary
          );
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.GET;
        }
      )
      .addCase(getGPSDataReportsSummaryAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(getGPSDataReportsSummaryAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = "";
      });
  },
});

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const gpsDataReportsSummarySelectors =
  gpsDataReportsSummaryAdapter.getSelectors<RootState>(
    (state) => state.gpsDataReportsSummary
  );

export const selectGPSDataReportsSummarySliceStatus = (state: any) =>
  state.gpsDataReportsSummary.status;
export const selectGPSDataReportsSummarySliceReasonCode = (state: any) =>
  state.gpsDataReportsSummary.reasonCode;
export const { gpsDataReportsSummaryEmptyState } =
  gpsDataReportsSummarySlice.actions;

export default gpsDataReportsSummarySlice.reducer;
