import { t } from "i18next";
import PropTypes from "prop-types";
import { useState } from "react";
import { Check } from "../Indicators/Check";
import { DataIssuesModal } from "../Modal/CustomModals/DataIssuesModal";
import { Tag } from "../Tags/Tag";
import "./DataLabel.css";

export const DataLabel = ({
  icon,
  label,
  dataText,
  size,
  className,
  isLoading,
  isScheduled,
  graph,
  hasIssue = false,
  ...props
}) => {
  let classes =
    size === "small" ? "mn-data-label mn-data-label--small" : "mn-data-label";

  const [showIssueModal, setShowIssueModal] = useState(false);

  classes += graph ? " mn-data-label--has-graph" : "";
  return (
    <>
      <div
        className={`${classes} ${className ? className : ""}`}
        {...props}
        onClick={() => hasIssue && setShowIssueModal(true)}
      >
        {isLoading ? (
          <div>
            <span className="mn-data-label__text-label--placeholder"></span>
            <span className="mn-data-label__text-data--placeholder"></span>
          </div>
        ) : (
          <>
            {icon && (
              <div className="mn-data-label__icon">
                {" "}
                {icon}
                {hasIssue && <Check status="failed" size="xsmall" />}
              </div>
            )}
            <div className="mn-data-label__text">
              {label && (
                <>
                  <span className="mn-data-label__text-label">{label}</span>
                  {isScheduled && (
                    <Tag
                      hasIcon
                      mode="outline"
                      size="small"
                      text="SCHEDULED"
                      type="neutro"
                    />
                  )}
                </>
              )}
              <span className="mn-data-label__text-data">
                {graph && !hasIssue && (
                  <div className="mn-data-label__graph">{graph}</div>
                )}
                <span>
                  {!hasIssue ? (
                    dataText
                  ) : (
                    <div className="mn-data-label-error">
                      <span>{t("common.na")}</span>
                    </div>
                  )}
                </span>
              </span>
            </div>
          </>
        )}
      </div>
      {showIssueModal && (
        <DataIssuesModal
          open={showIssueModal}
          onClose={() => setShowIssueModal(false)}
          dataText={label}
        />
      )}
    </>
  );
};

DataLabel.propTypes = {
  label: PropTypes.string,
  dataText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  size: PropTypes.oneOf(["normal", "small"]),
  isLoading: PropTypes.bool,
  isScheduled: PropTypes.bool,
};

DataLabel.defaultProps = {
  size: "normal",
  isLoading: false,
  isScheduled: false,
};
