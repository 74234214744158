import {
  createSlice,
  createEntityAdapter,
  PayloadAction,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import _ from "lodash";
import { normalize } from "normalizr";
import { RootState } from "../../../app/store";
import { Config } from "../../../config/Config";
import { GTFleetSuccessCodes } from "../../../config/GTFleetSuccessCodes";
import { vehicleTravelsSummarySchema } from "./vehicleTravelNormalization";
import VehicleTravelsRepository from "./vehicleTravelRepository";

export type VehicleTravelSummaryUtilization = {
  uuid: number;
  routeId: number;
  vehicleId: number;
  drivingTime: number;
  idleTime: number;
  movingConsumption: number;
  idleConsumption: number;
};

type PagingStateType = {
  pagingState?: string;
};

type PagingStateMapping = {
  page: number;
  pagingState: string;
};

//#region API
// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const getVehicleUtilizationAsync = createAsyncThunk(
  "vehicleUtilization/getVehicleUtilization",
  async (
    data: { vehicleId: number; queryParams?: string; page: number },
    thunkAPI
  ) => {
    const vehicleTravelsRepository = new VehicleTravelsRepository();
    const pagingStates: PagingStateMapping[] =
      selectVehicleUtilizationSlicePagingStates(thunkAPI.getState());
    const pagingStateMap = pagingStates.find((x) => x.page === data.page);
    const pagingState = {
      pagingState: pagingStateMap?.pagingState,
    } as PagingStateType;
    const response = await vehicleTravelsRepository.getVehicleTravelsSummary(
      data.vehicleId,
      data.queryParams,
      pagingState
    );
    // The value we return becomes the `fulfilled` action payload
    const vehicleUtilization = _.get(
      response,
      Config.VEHICLE_TRAVELS_SUMMARY_RESPONSE_PATH
    );
    const currentPagingState = _.get(
      response,
      Config.VEHICLE_TRAVELS_SUMMARY_PAGING_STATE_RESPONSE_PATH
    );
    thunkAPI.dispatch(
      setNewPagingState({
        page: data.page + 1,
        pagingState: currentPagingState,
      })
    );
    const normalizedData = normalize(
      vehicleUtilization,
      vehicleTravelsSummarySchema
    );
    return normalizedData.entities;
  }
);
//#endregion API

//#region Custom Functions
function setFilteredData(state: any, action: PayloadAction<any>) {
  action.payload.vehicleTravelSummary
    ? vehicleUtilizationAdapter.setAll(
        state,
        action.payload.vehicleTravelSummary
      )
    : vehicleUtilizationAdapter.setAll(state, []);
}
//#endregiorn Custom Functions

//#region Slice
const vehicleUtilizationAdapter =
  createEntityAdapter<VehicleTravelSummaryUtilization>({
    selectId: (vehicleTravelSummary) => vehicleTravelSummary.routeId,
    sortComparer: (a, b) =>
      a.routeId.toString().localeCompare(b.routeId.toString()),
  });

export const vehicleUtilizationSlice = createSlice({
  name: "vehicleUtilization",
  initialState: vehicleUtilizationAdapter.getInitialState({
    status: "idle",
    reasonCode: "",
    totalPages: 0,
    pagingStates: [] as PagingStateMapping[],
  }),
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setNumberOfPages: (state, action: PayloadAction<number>) => {
      state.totalPages = action.payload;
    },
    setNewPagingState: (
      state,
      action: PayloadAction<{
        page: number;
        pagingState: string;
      }>
    ) => {
      const pagingStates = state.pagingStates;
      const newPagingState = [...pagingStates];
      const pagingStateMap = newPagingState.find(
        (x) => x.page === action.payload.page
      );
      if (!pagingStateMap) {
        newPagingState.push({
          page: action.payload.page,
          pagingState: action.payload.pagingState,
        } as PagingStateMapping);
      }
      state.pagingStates = newPagingState;
    },
    setEmptyVehicleUtilizationState: (state) => {
      vehicleUtilizationAdapter.setAll(state, []);
      state.status = "idle";
      state.reasonCode = "";
      state.pagingStates = [] as PagingStateMapping[];
      state.totalPages = 0;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      //#region Entity Reducers
      // getRoutesHistoryAsync extra reducers
      .addCase(
        getVehicleUtilizationAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          setFilteredData(state, action);
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.GET;
        }
      )
      .addCase(getVehicleUtilizationAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(getVehicleUtilizationAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = "";
      });
    //#endregion Entity Reducers
  },
});
//#endregion Slice

//#region Status
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const vehicleUtilizationSelectors =
  vehicleUtilizationAdapter.getSelectors<RootState>(
    (state) => state.vehicleUtilization
  );

export const selectVehicleUtilizationSliceStatus = (state: any) =>
  state.vehicleUtilization.status;
export const selectVehicleUtilizationSliceReasonCode = (state: any) =>
  state.vehicleUtilization.reasonCode;
export const selectVehicleUtilizationSlicePage = (state: any) =>
  state.vehicleUtilization.totalPages;
export const selectVehicleUtilizationSlicePagingStates = (state: any) =>
  state.vehicleUtilization.pagingStates;
export const {
  setNumberOfPages,
  setEmptyVehicleUtilizationState,
  setNewPagingState,
} = vehicleUtilizationSlice.actions;
export default vehicleUtilizationSlice.reducer;
//#endregion Status
