import { renderToString } from "react-dom/server";
import { getIconFromVehicleType } from "../../utils/Utils";

// #region Tooltip ArrowMarker
export const ArrowInfoTooltipStyle = () => {
  return `
    .gm-style .gm-style-iw-c,
    .gm-style .gm-style-iw-c:hover {
        max-width: 260px !important;
        width: fit-content;
        background-color: var(--global-colors-ink-dark) !important;
        z-index: 1;
        position: absolute;
        color: var(--global-colors-ui-white);
        padding: 18px !important;
        border-radius: 4px;
        font-size: 12px !important;
        margin-top: 30px;
        font-family: var(--global-font-regular);
        cursor: var(--global-cursor-default);
        left: 0px;
        top: 0px;
        opacity: 0.9;
        min-width: 0px !important;
    }

    .gm-style .gm-style-iw-t::after {
        background: var(--global-colors-ink-dark);
        box-shadow: none !important;
        margin-top: 30px;
    }

    .gm-style .gm-style-iw-d {
        overflow: hidden !important;
        font-size: 12px;
    }

    .gm-style .gm-style-iw-a:hover {
        display: block;
    }

    .gm-style .gm-style-iw-tc {
        opacity: 0.9;
        cursor: var(--global-cursor-default);
    }

    .gm-style .gm-style-iw-tc::after {
        margin-top: 30px;
        background-color: var(--global-colors-ink-dark);
        opacity: 0.9;
    }

    .gm-style .gm-style-iw-tc:hover {
        display: block;
        opacity: 0.9;
    }
  `;
};
// #endregion Tooltip ArrowMarker

// #region Tooltip EventMarker
export const EventInfoTooltipStyle = () => {
  return `
    .gm-style .gm-style-iw-c,
    .gm-style .gm-style-iw-c:hover {
        background-color: #FFF !important;
        position: absolute;
        z-index: 1;
        font-family: var(--global-font-regular);
        color: var(--global-colors-ink-dark);
        margin-top: 0px;
        max-width: 300px !important;
        width: 200px;
        padding: 5px !important;
        border-radius: 4px;
        font-size: 12px !important;
        left: 95px;
        top: 62px;
        opacity: 1;
        min-width: fit-content !important;
    }

    .gm-style .gm-style-iw-t {
        bottom: 55px !important;
    }

    .gm-style .gm-style-iw-t::after {
        margin-top: 0px;
        font-size: 18px;
    }

    .gm-style .gm-style-iw-tc::after {
        background-color: transparent;
        margin-top: 0px;
    }

    .gm-style .gm-style-iw-tc:hover {
        background-color: transparent;
    }

    .gm-style .gm-style-iw-d {
        overflow: hidden !important;
        font-size: 15px;
    }
  `;
};
// #endregion Tooltip EventMarker

// #region Tooltip Cluster
export const ClusterEventInfoTooltipStyle = (number) => {
  return `
    .gm-style .gm-style-iw-c,
    .gm-style .gm-style-iw-c:hover {
        background-color: transparent !important;
        position: absolute;
        z-index: 1;
        font-family: var(--global-font-regular);
        color: var(--global-colors-ink-dark);
        margin-top: 0px;
        max-width: 300px !important;
        width: 200px;
        padding: 5px !important;
        border-radius: 4px;
        font-size: 12px !important;
        left: ${number.length > 2 ? "180px" : "155px"};
        top: 80px;
        opacity: 1;
        min-width: 250px !important;
        box-shadow: none !important;
    }

    .gm-style .gm-style-iw-t {
        bottom: 48px !important;
    }

    .gm-style .gm-style-iw-t::after {
        margin-top: 0px;
        font-size: 18px;
    }

    .gm-style .gm-style-iw-tc::after {
        background-color: transparent;
        margin-top: 0px;
    }

    .gm-style .gm-style-iw-tc:hover {
        background-color: transparent;
    }

    .gm-style .gm-style-iw-d {
        overflow: hidden !important;
        font-size: 15px;
    }

    .gm-style .gm-style-iw-c .button {
        transition: none;
    }
  `;
};

export const ClusterEventInfoTwoTooltipStyle = (number) => {
  return `
    .gm-style .gm-style-iw-c,
    .gm-style .gm-style-iw-c:hover {
        background-color: transparent !important;
        position: absolute;
        z-index: 1;
        font-family: var(--global-font-regular);
        color: var(--global-colors-ink-dark);
        margin-top: 0px;
        max-width: 300px !important;
        width: 200px;
        padding: 5px !important;
        border-radius: 4px;
        font-size: 12px !important;
        left: ${number.length > 2 ? "180px" : "155px"};
        top: 110px;
        opacity: 1;
        min-width: 250px !important;
        box-shadow: none !important;
    }

    .gm-style .gm-style-iw-t::after {
        margin-top: 0px;
        font-size: 18px;
    }

    .gm-style .gm-style-iw-tc::after {
        background-color: transparent;
        margin-top: 0px;
    }

    .gm-style .gm-style-iw-tc:hover {
        background-color: transparent;
    }

    .gm-style .gm-style-iw-d {
        overflow: hidden !important;
        font-size: 15px;
    }
  `;
};

export const ClusterEventInfoThreeTooltipStyle = (number) => {
  return `
    .gm-style .gm-style-iw-c,
    .gm-style .gm-style-iw-c:hover {
        background-color: transparent !important;
        position: absolute;
        z-index: 1;
        font-family: var(--global-font-regular);
        color: var(--global-colors-ink-dark);
        margin-top: 0px;
        max-width: 300px !important;
        width: 200px;
        padding: 5px !important;
        border-radius: 4px;
        font-size: 12px !important;
        left: ${number.length > 2 ? "180px" : "155px"};
        top: 130px;
        opacity: 1;
        min-width: 250px !important;
        box-shadow: none !important;
    }

    .gm-style .gm-style-iw-t::after {
        margin-top: 0px;
        font-size: 18px;
    }

    .gm-style .gm-style-iw-tc::after {
        background-color: transparent;
        margin-top: 0px;
    }

    .gm-style .gm-style-iw-tc:hover {
        background-color: transparent;
    }

    .gm-style .gm-style-iw-d {
        overflow: hidden !important;
        font-size: 15px;
    }
  `;
};
// #endregion Tooltip Cluster

// #region Tooltip RouteInfoMarker
export const RouteInfoTooltipStyle = () => {
  return `
    .gm-style .gm-style-iw-c,
    .gm-style .gm-style-iw-c:hover {
        position: absolute;
        z-index: 1;
        font-family: var(--global-font-regular);
        font-size: 12px !important;
        margin: 0px;
        max-width: 260px !important;
        min-width: 260px !important;
        width: fit-content;
        padding: 18px !important;
        border-radius: 4px;
        color: var(--global-colors-ui-white);
        cursor: var(--global-cursor-default);
        background-color: var(--global-colors-ink-dark) !important;
        left: 0px;
        top: 0px;
        opacity: 0.9;
    }

    .gm-style .gm-style-iw-d {
        overflow: hidden !important;
        font-size: 12px;
    }

    .gm-style .gm-style-iw-a:hover {
        display: block;
    }

    .gm-style .gm-style-iw-t {
        bottom: 46px !important;
    }

    .gm-style .gm-style-iw-tc {
        opacity: 0.9;
        cursor: var(--global-cursor-default);
    }

    .gm-style .gm-style-iw-tc::after {
        background-color: var(--global-colors-ink-dark);
        margin-top: 0px;
        width: 22px;
        height: 8px;
        opacity: 0.9;
    }

    .gm-style .gm-style-iw-tc:hover {
        display: block;
        opacity: 0.9;
    }

    .gm-style .gm-style-iw-c .button {
        transition: none;
    }

    .gm-style .gm-style-iw-c .button:last-child {
        margin-left: auto;
    }
  `;
};
// #endregion Tooltip RouteInfoMarker

export const FleetControlClusterInfoStyle = () => {
  return `
    .gm-style .gm-style-iw-c,
    .gm-style .gm-style-iw-c:hover {
        position: absolute;
        z-index: 1;
        font-family: var(--global-font-regular);
        font-size: 12px !important;
        margin: 0px;
        max-width: 300px !important;
        width: 200px;
        padding: 0px !important;
        border-radius: 4px;
        color: var(--global-colors-ui-white);
        background-color: var(--global-colors-ink-dark) !important;
        opacity: 0.9;
        left: 0px;
        top: 0px;
        cursor: var(--global-cursor-default);
        min-width: fit-content !important;
    }

    .gm-style .gm-style-iw-d {
        overflow: hidden !important;
        font-size: 12px;
    }

    .gm-style .gm-style-iw-a:hover {
        display: block;
    }

    .gm-style .gm-style-iw-t {
        bottom: 46px !important;
    }

    .gm-style .gm-style-iw-tc {
        opacity: 0.9;
        cursor: var(--global-cursor-default);
    }

    .gm-style .gm-style-iw-tc::after {
        margin-top: 0px;
        background-color: var(--global-colors-ink-dark);
        width: 22px;
        height: 8px;
        opacity: 0.9;
    }

    .gm-style .gm-style-iw-tc:hover {
        display: block;
        opacity: 0.9;
    }

    .gm-style .gm-style-iw-c .button {
        transition: none;
    }

    .gm-style .gm-style-iw-c .button:last-child {
        margin-left: auto;
    }
  `;
};

export const renderClusterInfo = (fleetControlClusterInfo) => {
  return `
  <style>
  body {
    font-family: Arial, sans-serif;
  }

  .dashboard-container {
    background-color: var(--global-colors-ink-dark);
    margin: 15px;
    margin-right: 0px;
  }

  .dashboard {
    overflow-y: scroll;
    overflow-x: hidden;
    width: auto;
    max-width: 720px;
    display: flex;
    gap: 25px;
    background-color: var(--global-colors-ink-dark);
    width: 100%;
    max-height: 120px;
    padding-bottom: 15px;
  }

  .dashboard-header {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 25px;
    padding-bottom: 15px;
    padding-right: 15px;
  }

  .fleettooltip-vehicles-elements-container {
    min-width: 120px;
    display: flex;
    flex-direction: column;
    gap: 9px;
    width: 100%;
    cursor: var(--global-cursor-pointer) !important;
  }
  
  .fleettooltip-vehicles-elements-container > div{
    cursor: var(--global-cursor-pointer) !important;
  }

  .fleettooltip-vehicles-text-counter {
    color: var(--global-colors-ui-white);
    font-size: 10px;
    font-weight: 550;
    letter-spacing: 0;
    line-height: 16px;
    text-align: center;
  }

  .fleettooltip-vehicles-counter-online {
    display: flex;
    align-items: center;
    padding-top: 2px;
    padding-bottom: 2px;
    justify-content: center;
    width: 100%;
    background-color: var(--global-colors-status-online);
    border-radius: 4px;
    height: 17px;
  }

  .fleettooltip-vehicles-counter-stop {
    display: flex;
    align-items: center;
    padding-top: 2px;
    padding-bottom: 2px;
    justify-content: center;
    width: 100%;
    background-color: var(--global-colors-ui-secondary);
    border-radius: 4px;
    height: 17px;
  }

  .fleettooltip-vehicles-counter-park {
    display: flex;
    align-items: center;
    padding-top: 2px;
    padding-bottom: 2px;
    justify-content: center;
    width: 100%;
    background-color: var(--global-colors-status-offline);
    border-radius: 4px;
    height: 17px;
  }

  .fleettooltip-vehicles-counter-offline {
    display: flex;
    align-items: center;
    padding-top: 2px;
    padding-bottom: 2px;
    justify-content: center;
    width: 100%;
    background-color: var(--global-colors-ink-light);
    border-radius: 4px;
    height: 17px;
  }
</style>

<div class="dashboard-container">
<div class="dashboard-header">
  ${
    fleetControlClusterInfo?.MOVING?.length > 0
      ? renderToString(
          <div class="fleettooltip-vehicles-counter-online">
            <div class="fleettooltip-vehicles-text-counter">
              {fleetControlClusterInfo?.MOVING?.length}
            </div>
          </div>
        )
      : renderToString(<></>)
  }
  ${
    fleetControlClusterInfo?.STOP?.length > 0
      ? renderToString(
          <div class="fleettooltip-vehicles-counter-stop">
            <div class="fleettooltip-vehicles-text-counter">
              {fleetControlClusterInfo?.STOP?.length}
            </div>
          </div>
        )
      : renderToString(<></>)
  }
  ${
    fleetControlClusterInfo?.PARKING?.length > 0
      ? renderToString(
          <div class="fleettooltip-vehicles-counter-park">
            <div class="fleettooltip-vehicles-text-counter">
              {fleetControlClusterInfo?.PARKING?.length}
            </div>
          </div>
        )
      : renderToString(<></>)
  }
  ${
    fleetControlClusterInfo?.OFFLINE?.length > 0
      ? renderToString(
          <div class="fleettooltip-vehicles-counter-offline">
            <div class="fleettooltip-vehicles-text-counter">
              {fleetControlClusterInfo?.OFFLINE?.length}
            </div>
          </div>
        )
      : renderToString(<></>)
  }
</div>
<div  class="dashboard">
${
  fleetControlClusterInfo?.MOVING?.length > 0
    ? renderToString(
        <div
          id="fleettooltip-vehicles-elements-container-moving"
          class="fleettooltip-vehicles-elements-container"
        >
          {fleetControlClusterInfo?.MOVING?.map((e) => {
            return (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  color: "white",
                  whiteSpace: "nowrap",
                  gap: "10px",
                  fontSize: "10px",
                  marginRight: "5px",
                  cursor: "pointer",
                }}
                class={`fleettooltip-cluster-vehicle-${e?.vehicleInfo?.id}`}
                id={`fleettooltip-cluster-vehicle-${e?.vehicleInfo?.id}`}
              >
                {getIconFromVehicleType(e?.vehicleInfo?.type, 10, "white")}
                <span>
                  {e?.vehicleInfo?.alias} • {e?.vehicleInfo?.plate}
                </span>
              </div>
            );
          })}
        </div>
      )
    : renderToString(<></>)
}
${
  fleetControlClusterInfo?.STOP?.length > 0
    ? renderToString(
        <div
          id="fleettooltip-vehicles-elements-container-stop"
          class="fleettooltip-vehicles-elements-container"
        >
          {fleetControlClusterInfo?.STOP?.map((e) => {
            return (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  color: "white",
                  whiteSpace: "nowrap",
                  gap: "10px",
                  fontSize: "10px",
                  marginRight: "5px",
                  cursor: "pointer",
                }}
                class={`fleettooltip-cluster-vehicle-${e?.vehicleInfo?.id}`}
                id={`fleettooltip-cluster-vehicle-${e?.vehicleInfo?.id}`}
              >
                {getIconFromVehicleType(e?.vehicleInfo?.type, 10, "white")}
                <span>
                  {e?.vehicleInfo?.alias} • {e?.vehicleInfo?.plate}
                </span>
              </div>
            );
          })}
        </div>
      )
    : renderToString(<></>)
}
${
  fleetControlClusterInfo?.PARKING?.length > 0
    ? renderToString(
        <div
          id="fleettooltip-vehicles-elements-container-parking"
          class="fleettooltip-vehicles-elements-container"
        >
          {fleetControlClusterInfo?.PARKING?.map((e) => {
            return (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  color: "white",
                  whiteSpace: "nowrap",
                  gap: "10px",
                  fontSize: "10px",
                  marginRight: "5px",
                  cursor: "pointer",
                }}
                class={`fleettooltip-cluster-vehicle-${e?.vehicleInfo?.id}`}
                id={`fleettooltip-cluster-vehicle-${e?.vehicleInfo?.id}`}
              >
                {getIconFromVehicleType(e?.vehicleInfo?.type, 10, "white")}
                <span>
                  {e?.vehicleInfo?.alias} • {e?.vehicleInfo?.plate}
                </span>
              </div>
            );
          })}
        </div>
      )
    : renderToString(<></>)
}
${
  fleetControlClusterInfo?.OFFLINE?.length > 0
    ? renderToString(
        <div
          id="fleettooltip-vehicles-elements-container-offline"
          class="fleettooltip-vehicles-elements-container"
        >
          {fleetControlClusterInfo?.OFFLINE?.map((e) => {
            return (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  color: "white",
                  whiteSpace: "nowrap",
                  gap: "10px",
                  fontSize: "10px",
                  marginRight: "1px",
                  cursor: "pointer",
                }}
                class={`fleettooltip-cluster-vehicle-${e?.vehicleInfo?.id}`}
                id={`fleettooltip-cluster-vehicle-${e?.vehicleInfo?.id}`}
              >
                {getIconFromVehicleType(e?.vehicleInfo?.type, 10, "white")}
                <span>
                  {e?.vehicleInfo?.alias} • {e?.vehicleInfo?.plate}
                </span>
              </div>
            );
          })}
        </div>
      )
    : renderToString(<></>)
}
</div>
</div>`;
};

export const PublicRoutePointInfoStyle = () => {
  return `
    .gm-style .gm-style-iw-c,
    .gm-style .gm-style-iw-c:hover {
        width: fit-content;
        position: absolute;
        z-index: 1;
        font-family: var(--global-font-regular);
        font-size: 12px !important;
        margin: 0px;
        padding: 0px !important;
        border-radius: 4px;
        color: var(--global-colors-ui-white);
        background-color: var(--global-colors-ink-dark) !important;
        cursor: var(--global-cursor-default);
        opacity: 0.9;
        left: 0px;
        top: 0px;
        min-width: fit-content !important;
    }

    .gm-style .gm-style-iw-d {
        overflow: hidden !important;
        font-size: 12px;
        display: flex;
        justify-content: center;
        width: -webkit-fill-available;
        margin-left: 8px;
        margin-right: 8px;
        margin-top: 5px;
        color: white;
    }

    .gm-style .gm-style-iw-a:hover {
        display: block;
    }

    .gm-style .gm-style-iw-t {
        bottom: 16px !important;
    }

    .gm-style .gm-style-iw-tc {
        opacity: 0.9;
        cursor: var(--global-cursor-default);
    }

    .gm-style .gm-style-iw-tc::after {
        margin-top: 0px;
        background-color: var(--global-colors-ink-dark);
        width: 22px;
        height: 8px;
        opacity: 0.9;
    }

    .gm-style .gm-style-iw-tc:hover {
        display: block;
        opacity: 0.9;
    }
  `;
};
