export class RefreshTokenManager {
  private static instance: RefreshTokenManager;
  private static busy: boolean = false;
  private static instant: Date | undefined;

  private constructor() {}

  public static getInstance(): RefreshTokenManager {
    if (!RefreshTokenManager.instance) {
      RefreshTokenManager.instance = new RefreshTokenManager();
    }

    return RefreshTokenManager.instance;
  }

  public getNewAccessToken() {
    return RefreshTokenManager.instant
      ? this.getMinutesFromDateToNow(RefreshTokenManager.instant) === 0
      : false;
  }

  public isRefreshTokenManagerBusy() {
    return RefreshTokenManager.busy;
  }

  public setRefreshTokenManagerBusy(state: boolean) {
    RefreshTokenManager.busy = state;
  }

  public setInstant(date: Date | undefined) {
    RefreshTokenManager.instant = date;
  }

  private getMinutesFromDateToNow(firstDate: Date) {
    let differenceValue = (+firstDate - +new Date()) / 1000;
    differenceValue /= 60;
    return Math.abs(Math.round(differenceValue));
  }
}
