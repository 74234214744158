import HttpClient from "../../utils/HttpClient";

export default class VehiclesFuelsRepository extends HttpClient {
  constructor() {
    super(true);
  }

  getFuelsConsumption(vehicleId: number, queryParams: string) {
    const tenantId = localStorage.getItem("tenantId");
    let path =
      "/tenants/" +
      tenantId +
      "/fleets/vehicles/" +
      vehicleId +
      "/fuels/consumption";
    return this.instance.get(path + (queryParams ?? ""));
  }

  getFuelCosts(vehicleId: number, queryParams: string) {
    const tenantId = localStorage.getItem("tenantId");
    let path =
      "/tenants/" + tenantId + "/fleets/vehicles/" + vehicleId + "/fuels/costs";
    return this.instance.get(path + (queryParams ?? ""));
  }

  getVehiclesFuelCosts(queryParams: string) {
    const tenantId = localStorage.getItem("tenantId");
    return this.instance.get(
      "/tenants/" + tenantId + "/fleets/fuels/costs" + (queryParams ?? "")
    );
  }

  getVehiclesFuelsConsumption(queryParams: string) {
    const tenantId = localStorage.getItem("tenantId");
    return this.instance.get(
      "/tenants/" + tenantId + "/fleets/fuels/consumption" + (queryParams ?? "")
    );
  }
}
