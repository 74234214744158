import { schema } from "normalizr";
import { tenantSchema } from "../tenants/tenantNormalization";
import { userInfoSchema } from "../users/userInfoNormalization";
import { messageSchema, messagesSchema } from "./messagesNormalization";

// Definizione dello schema per l'entità Chat
const chatSchema = new schema.Entity<any>("chats", {
  user: userInfoSchema,
  sendMessageResponse: messageSchema,
});
const chatAliasSchema = new schema.Entity<any>("chatAlias", {
  tenant: tenantSchema,
});
// Normalizzazione dell'oggetto JSON
export const chatsSchema = new schema.Array(chatSchema);
export const chatAliasesSchema = new schema.Array(chatAliasSchema);
