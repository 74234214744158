import HttpClient from "../../utils/HttpClient";

export default class VehiclesStatusRepository extends HttpClient {
  constructor() {
    super(true);
  }

  /**
   * Gets a list of vehicles statuses by tenant id.
   * @returns
   */
  getVehicles() {
    const tenantId = localStorage.getItem("tenantId");
    let path = "/tenants/" + tenantId + "/fleets/vehicles/status";
    return this.instance.get(path);
  }

  /**
   * Gets a list of vehicles status views by tenant id and eventually filtered by params.
   * @returns
   */
  getVehiclesViews(queryParams?: string) {
    const tenantId = localStorage.getItem("tenantId");
    let path = "/tenants/" + tenantId + "/fleets/vehicles/status/view";
    return this.instance.get(path + (queryParams ?? ""));
  }

  /**
   * Reads a vehicle statuse by tenant id and fleet id.
   * @returns
   */
  getVehicle(fleetId: number, id: number) {
    const tenantId = localStorage.getItem("tenantId");

    return this.instance.get(
      "/tenants/" +
        tenantId +
        "/fleets/" +
        fleetId +
        "/vehicles/" +
        id +
        "/status"
    );
  }

  /**
   * Gets a list of vehicles statuses by tenant id and eventually filtered by params.
   * @returns
   */
  getFilteredVehiclesStatus(queryParams?: string) {
    const tenantId = localStorage.getItem("tenantId");
    let path = "/tenants/" + tenantId + "/fleets/vehicles/status";
    return this.instance.get(path + (queryParams ?? ""));
  }

  /**
   * Gets a list of vehicles statuses by tenant id and fleet id.
   * @returns
   */
  getVehiclesStatusByFleetId(fleetId: number) {
    const tenantId = localStorage.getItem("tenantId");
    return this.instance.get(
      "/tenants/" + tenantId + "/fleets/" + fleetId + "/vehicles/status"
    );
  }
}
