import _ from "lodash";
import PropTypes from "prop-types";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ToastNotification } from "../../utils/ToastNotification";
import {
  getIllustrationFromVehicleType,
  getLinearDistance,
} from "../../utils/Utils";
import { Button } from "../Button/Button";
import { VehicleBox } from "../FleetControl/VehicleBox";
import Form from "../Forms/Form";
import SearchField from "../Forms/SearchField";
import { TabSection } from "../Tabs/TabSection";
import { Tabs } from "../Tabs/Tabs";
import { Geofence, GeofenceOption, Position } from "./GeofenceDataStructure";
import { GeofenceDropdown } from "./GeofenceDropdown";
import "./SearchActionMenu.css";
import { Slider, SliderValue } from "./Slider";

//#region models declaration

export interface DriverInfo {
  id: number;
  firstName: string;
  lastName: string;
}

export interface VehicleStatusInfo {
  id: number;
  dynamicFields?: {
    vehicleStatus: VehicleStatus;
    latitude: number;
    longitude: number;
  };
}

type VehiclesGroup = { [key: string]: Vehicle[] };

interface Vehicle {
  id: number;
  alias: string;
  plate: string;
  name: string;
  imageUrl: string;
  driver: number;
  tenant: number;
  fleet: number;
  vehicleStatus: number;
}

interface VehicleFound {
  vehicleInfo: Vehicle;
  vehicleStatus: VehicleStatus;
  duration: string;
  distance: string | undefined;
  driverName: string;
  distanceMeter: number;
  visible: boolean;
}

type VehicleStatus = "MOVING" | "STOP" | "PARKING" | "UNKNOWN" | "OFFLINE";

interface SelectedTabProps {
  sliderValue: number;
  setVehicles: (e: any) => any;
  position: Position;
  card: (e: any) => any;
}

interface SelectedTabResetProps {
  setSliderValue?: (e: any) => any;
  setLocationValue?: (e: any) => any;
  setGeofenceValue?: (e: any) => any;
  setCard?: (e: any) => any;
  setAddress?: (e: any) => any;
}

export interface SearchActionMenuProps {
  vehicles?: Vehicle[];
  vehiclesStatus?: VehicleStatusInfo[];
  drivers?: DriverInfo[];
  geofenceOptions?: GeofenceOption[];
  buttonLabel?: string;
  locationSliderLimits: SliderValue;
  geofenceSliderLimits: SliderValue;
  drawCircle?: (
    center: Position,
    name: string,
    radius: string,
    markerColor?: string
  ) => any;
  buttonClick?: (e: any, t?: any) => any;
  onRadiusChange?: (e: any) => any;
  removeCircle?: () => any;
  onGoogleSearchOpen?: (e: any) => any;
  isMetric?: boolean;
}
//#endregion

export const SearchActionMenu = ({
  geofenceOptions,
  vehicles,
  vehiclesStatus,
  drivers,
  buttonLabel,
  buttonClick,
  locationSliderLimits,
  geofenceSliderLimits,
  drawCircle,
  removeCircle,
  onRadiusChange,
  onGoogleSearchOpen,
  isMetric,
}: SearchActionMenuProps) => {
  const { t } = useTranslation();
  const [location, setLocation] = useState<string>("");
  const [geofence, setGeofence] = useState<Geofence[]>([]);
  const [loader, setLoader] = useState(false);
  const [locationCard, setLocationCard] = useState(false);
  const [geofenceCard, setGeofenceCard] = useState(false);
  const [locationSliderValue, setLocationSliderValue] = useState<number>(0);
  const [geofenceSliderValue, setGeofenceSliderValue] = useState<number>(0);
  const [selectedTab, setSelectedTab] = useState<"Location" | "Geofence">(
    t("map.actions.searchActionMenu.locationTab")
  );
  const [locationVehicles, setLocationVehicles] = useState<VehicleFound[]>([]);
  const [geofenceVehicles, setGeofenceVehicles] = useState<VehicleFound[]>([]);
  const [findAddress, setFindAddress] = useState<any>({});
  const [disableLocation, setDisableLocation] = useState<boolean>(false);
  const [disableGeofence, setDisableGeofence] = useState<boolean>(false);
  const searchActionMenu = useRef(null);
  const navigate = useNavigate();

  //#region METHODS
  async function findVehicles(type: SelectedTabProps) {
    type.setVehicles([]);
    setDisableLocation(true);

    let googleMap = window.google;
    let service = new googleMap.maps.DistanceMatrixService();
    //getDistanceMatrix has limit of 25 origins for request, so has to be divided by group of 25
    const vehiclesGroupBy25: VehiclesGroup =
      getVehiclesGroup(25) ?? ({} as VehiclesGroup);
    const vehiclesKeys = Object.keys(vehiclesGroupBy25);
    let infoVehicles: any = {},
      origins: any = {};
    const vehiclesFound: VehicleFound[] = [];
    for (const key of vehiclesKeys) {
      infoVehicles[key] = [];
      vehiclesGroupBy25[key].forEach((vehicle: Vehicle, index: number) => {
        infoVehicles[key].push(
          _.find(vehiclesStatus, { id: vehicle.vehicleStatus })
        );
        if (infoVehicles[key][index]?.dynamicFields?.latitude) {
          origins[key] = origins[key] ?? [];
          origins[key].push({
            lat: infoVehicles[key][index].dynamicFields.latitude,
            lng: infoVehicles[key][index].dynamicFields.longitude,
          });
        }
      });
      await service
        .getDistanceMatrix({
          origins: origins[key],
          destinations: [type.position],
          travelMode: google.maps.TravelMode.DRIVING,
          unitSystem: isMetric
            ? window.google.maps.UnitSystem.METRIC
            : window.google.maps.UnitSystem.IMPERIAL,
        })
        .then((response) => {
          response.rows.forEach((row, index) => {
            const distance = row.elements[0]?.distance.text;
            const duration = getDuration(row.elements[0]);
            const driver = _.find(drivers, {
              id: vehiclesGroupBy25[key][index].driver,
            });
            vehiclesFound.push({
              vehicleInfo: vehiclesGroupBy25[key][index],
              vehicleStatus:
                infoVehicles[key][index]?.dynamicFields.vehicleStatus,
              duration: duration,
              distance: distance,
              driverName: driver?.firstName
                ? driver?.firstName + " " + driver?.lastName
                : t("common.noDriver"),
              distanceMeter: getLinearDistance(
                type.position?.lat,
                type.position?.lng,
                infoVehicles[key][index]?.dynamicFields?.latitude,
                infoVehicles[key][index]?.dynamicFields?.longitude
              ),
              visible:
                getLinearDistance(
                  type.position?.lat,
                  type.position?.lng,
                  infoVehicles[key][index]?.dynamicFields?.latitude,
                  infoVehicles[key][index]?.dynamicFields?.longitude
                ) <
                type?.sliderValue * 1000,
            });
          });
          type.setVehicles(vehiclesFound);
          type.card(true);
        })
        .catch((error) => {
          setLoader(false);
          resetSearch();
          console.error(error.message);
          ToastNotification({
            toastId: "distanceMatrixError",
            status: "error",
            description: t("map.actions.searchActionMenu.searchError"),
          });
        });
    }
  }

  /**
   * Renders VehicleBoxes of vehicles found sorted by distance from circle center
   * @param vehiclesFound Vehicles with distance, position and time information;
   * visible property means that vehicle is in the area and will be shown
   * @returns {VehicleBox[]}
   */
  function renderVehicles(vehiclesFound: VehicleFound[]) {
    return [...vehiclesFound]
      .sort((a, b) => a.distanceMeter - b.distanceMeter)
      .map((vehicle, index) => {
        if (vehicle.visible) {
          return (
            <div
              key={index}
              className="vehiclebox-container"
              onClick={() =>
                navigate(
                  `/dashboard/fleet-control/vehicle/${vehicle?.vehicleInfo?.id}`
                )
              }
            >
              <VehicleBox
                hasSubtitleIcon={true}
                vehicle={{
                  alias: vehicle?.vehicleInfo?.alias,
                  id: vehicle?.vehicleInfo?.id,
                  plate: vehicle?.vehicleInfo?.plate,
                }}
                driverName={vehicle?.driverName}
                distance={vehicle?.distance}
                time={vehicle?.duration}
                signalState={vehicle?.vehicleStatus}
                active={true}
                clickCallback={() => null}
                city={""}
                icon={getIllustrationFromVehicleType(vehicle?.vehicleInfo)}
              />
            </div>
          );
        } else return null;
      });
  }

  function resetSearch(type?: SelectedTabResetProps) {
    if (type) {
      type.setSliderValue && type.setSliderValue(0);
      type.setLocationValue && type.setLocationValue("");
      type.setGeofenceValue && type.setGeofenceValue([]);
      type.setCard && type.setCard(false);
      type.setAddress && type.setAddress({});
    } else {
      setLocationSliderValue(0);
      setGeofenceSliderValue(0);
      setLocation("");
      setGeofence([]);
      setLocationCard(false);
      setGeofenceCard(false);
      setFindAddress({});
    }
    setDisableLocation(false);
    setDisableGeofence(false);
    removeCircle && removeCircle();
  }

  function getVisibleVehiclesCount(item: VehicleFound[]) {
    let count = 0;
    item.forEach((vehicle) => {
      vehicle.visible && count++;
      return null;
    });
    return count;
  }

  /**
   * @returns
   */
  function getVehiclesGroup(maxNumberOfVehiclesForGroup: number) {
    let key = "1";
    return vehicles?.reduce(
      (group: VehiclesGroup, vehicle: Vehicle, index: number) => {
        key = Math.floor(index / maxNumberOfVehiclesForGroup).toString();
        group[key] = group[key] ?? [];
        group[key].push(vehicle);
        return group;
      },
      {}
    );
  }

  function getDuration(elements: any) {
    if (elements?.status === "OK") {
      let seconds = elements?.duration.value;
      let hours: string | number = Math.floor(seconds / 3600);
      let minutes: string | number = Math.floor((seconds - hours * 3600) / 60);
      if (hours === 0) {
        hours = "";
        if (minutes === 0) minutes = "1m";
        else minutes = minutes + "m";
      } else {
        hours = hours + "h ";
        if (minutes === 0) minutes = "";
        else minutes = minutes + "m";
      }
      return hours + minutes;
    } else return "";
  }
  //#endregion

  return (
    <>
      {
        <div
          className={
            buttonClick ? "mn-menu-search-report" : "mn-menu-search-action"
          }
          ref={searchActionMenu}
        >
          <Tabs onClick={setSelectedTab}>
            <TabSection label={t("map.actions.searchActionMenu.locationTab")}>
              <Form>
                <SearchField
                  id="searchAddress"
                  size="small"
                  placeholder={t(
                    "map.actions.searchActionMenu.locationPlaceholder"
                  )}
                  onChange={(data) => {
                    setLocation(data);
                    findAddress.formatted_address !== data &&
                      setFindAddress({});
                    onGoogleSearchOpen && onGoogleSearchOpen(data !== "");
                  }}
                  value={location}
                  searchAddress={true}
                  onAddressClick={(place: any) => {
                    setFindAddress(place);
                    setLocation(place.formatted_address);
                  }}
                  disabled={disableLocation}
                />
              </Form>
              <Slider
                data={locationSliderLimits}
                valueLabel={isMetric ? "km" : "mi"}
                label={t("map.actions.searchActionMenu.radiusLabel")}
                val={locationSliderValue}
                onChange={setLocationSliderValue}
                disabled={disableLocation}
              />
              {!locationCard && (
                <div className="find-button">
                  <Button
                    aspect="primary"
                    label={
                      buttonLabel ??
                      t("map.actions.searchActionMenu.buttonLabelFind")
                    }
                    size="small"
                    disabled={
                      location === "" ||
                      locationSliderValue < 1 ||
                      Object.keys(findAddress).length === 0
                    }
                    isLoading={loader}
                    onClick={() => {
                      if (buttonClick)
                        buttonClick(
                          {
                            lat: findAddress.geometry.location.lat(),
                            lng: findAddress.geometry.location.lng(),
                            radius: locationSliderValue,
                          },
                          location
                        );
                      else {
                        setLoader(true);
                        navigate("/dashboard/fleet-control");
                        setTimeout(async () => {
                          setDisableGeofence(true);
                          drawCircle &&
                            drawCircle(
                              {
                                lat: findAddress?.geometry?.location.lat(),
                                lng: findAddress?.geometry?.location.lng(),
                              },
                              geofence[0]?.name,
                              locationSliderValue.toString()
                            );
                          await findVehicles({
                            sliderValue: locationSliderValue,
                            setVehicles: setLocationVehicles,
                            position: {
                              lat: findAddress?.geometry?.location.lat(),
                              lng: findAddress?.geometry?.location.lng(),
                            },
                            card: setLocationCard,
                          });
                          setLoader(false);
                        }, 2000);
                      }
                    }}
                  />
                </div>
              )}
            </TabSection>
            <TabSection label={t("map.actions.searchActionMenu.geofenceTab")}>
              <Form>
                <GeofenceDropdown
                  setValue={setGeofence}
                  value={geofence}
                  options={geofenceOptions}
                  reset={() => {
                    setGeofenceCard(false);
                    setGeofenceSliderValue(0);
                    removeCircle && removeCircle();
                  }}
                  hasMultiSelection={buttonClick ? true : false}
                  disabled={disableGeofence || geofenceOptions?.length === 0}
                />
              </Form>
              {!buttonClick && (
                <Slider
                  data={geofenceSliderLimits}
                  valueLabel={isMetric ? "km" : "mi"}
                  label={t("map.actions.searchActionMenu.radiusLabel")}
                  val={geofenceSliderValue}
                  onChange={(value) => {
                    setGeofenceSliderValue(value);
                    onRadiusChange && onRadiusChange(value);
                    if (geofenceCard) {
                      let newVehicles: VehicleFound[] = geofenceVehicles.map(
                        (vehicle: VehicleFound) => {
                          return {
                            vehicleInfo: vehicle?.vehicleInfo,
                            vehicleStatus: vehicle?.vehicleStatus,
                            driverName: vehicle?.driverName,
                            duration: vehicle?.duration,
                            distance: vehicle?.distance,
                            distanceMeter: vehicle?.distanceMeter,
                            visible:
                              vehicle?.distanceMeter <
                              geofenceSliderValue * 1000,
                          };
                        }
                      );
                      setGeofenceVehicles(newVehicles);
                    }
                  }}
                  disabled={disableGeofence}
                />
              )}
              {!geofenceCard && (
                <div className="find-button">
                  <Button
                    aspect="primary"
                    label={
                      buttonLabel ??
                      t("map.actions.searchActionMenu.buttonLabelFind")
                    }
                    size="small"
                    disabled={geofence.length === 0}
                    isLoading={loader}
                    onClick={() => {
                      if (buttonClick) buttonClick(geofence);
                      else {
                        setLoader(true);
                        navigate("/dashboard/fleet-control");
                        setTimeout(async () => {
                          setDisableLocation(true);
                          drawCircle &&
                            drawCircle(
                              geofence[0]?.shape?.center,
                              geofence[0]?.name,
                              geofenceSliderValue.toString(),
                              _.find(geofenceOptions, {
                                id: geofence[0]?.geofenceCategory,
                              })?.color
                            );
                          await findVehicles({
                            sliderValue: geofenceSliderValue,
                            setVehicles: setGeofenceVehicles,
                            position: geofence[0].shape.center,
                            card: setGeofenceCard,
                          });
                          setLoader(false);
                        }, 2000);
                      }
                    }}
                  />
                </div>
              )}
            </TabSection>
          </Tabs>
          {locationCard &&
            selectedTab === t("map.actions.searchActionMenu.locationTab") && (
              <>
                <div className="vehicles-found">
                  <div className="result-label">
                    {getVisibleVehiclesCount(locationVehicles)}{" "}
                    {getVisibleVehiclesCount(locationVehicles) === 1
                      ? t("map.actions.searchActionMenu.vehicleFoundLabel")
                      : t("map.actions.searchActionMenu.vehiclesFoundLabel")}
                  </div>
                  {renderVehicles(locationVehicles)}
                </div>
                <div className="reset-button-card">
                  <div className="reset-button">
                    <Button
                      aspect="ghost"
                      label="Reset"
                      size="small"
                      onClick={() =>
                        resetSearch({
                          setSliderValue: setLocationSliderValue,
                          setLocationValue: setLocation,
                          setCard: setLocationCard,
                          setAddress: setFindAddress,
                        })
                      }
                    />
                  </div>
                </div>
              </>
            )}
          {geofenceCard &&
            selectedTab === t("map.actions.searchActionMenu.geofenceTab") && (
              <React.Fragment>
                <div className="vehicles-found">
                  <div className="result-label">
                    {getVisibleVehiclesCount(geofenceVehicles)}{" "}
                    {getVisibleVehiclesCount(geofenceVehicles) === 1
                      ? t("map.actions.searchActionMenu.vehicleFoundLabel")
                      : t("map.actions.searchActionMenu.vehiclesFoundLabel")}
                  </div>
                  {renderVehicles(geofenceVehicles)}
                </div>
                <div className="reset-button-card">
                  <div className="reset-button">
                    <Button
                      aspect="ghost"
                      label="Reset"
                      size="small"
                      onClick={() =>
                        resetSearch({
                          setSliderValue: setGeofenceSliderValue,
                          setGeofenceValue: setGeofence,
                          setCard: setGeofenceCard,
                        })
                      }
                    />
                  </div>
                </div>
              </React.Fragment>
            )}
        </div>
      }
    </>
  );
};

SearchActionMenu.propTypes = {
  vehicles: PropTypes.array,
  vehiclesStatus: PropTypes.array,
  drivers: PropTypes.array,
  geofenceOptions: PropTypes.array,
  buttonLabel: PropTypes.string,
  locationSliderLimits: PropTypes.object,
  geofenceSliderLimits: PropTypes.object,
  drawCircle: PropTypes.func,
  removeCircle: PropTypes.func,
  buttonClick: PropTypes.func,
  onRadiusChange: PropTypes.func,
  onGoogleSearchOpen: PropTypes.func,
  isMetric: PropTypes.bool,
};

export default SearchActionMenu;
