import { t } from "i18next";
import { FeedbackModal } from "../../../ui/Modal/CustomModals/FeedbackModal";
import { FleetView } from "../fleetViewsSlice";

interface DeleteFleetModalProps {
  openDeleteFleetModal: boolean;
  selectedFleet: FleetView;
  closeDeleteFleetModal: (e: any) => any;
}

const DeleteFleetModal: React.FC<DeleteFleetModalProps> = ({
  openDeleteFleetModal,
  selectedFleet,
  closeDeleteFleetModal,
}) => {
  return (
    <FeedbackModal
      open={openDeleteFleetModal}
      title={`${t("admin.groups.deleteGroup.title")}`}
      desc={
        `${t("admin.groups.deleteGroup.desc").replace(
          "*name",
          selectedFleet?.name?.split(" ")[0]
        )}` +
        " " +
        `${t("admin.groups.deleteGroup.actionPermanent")}`
      }
      primaryLabel={`${t("common.confirm")}`}
      isDanger={true}
      primaryAction={() => {
        closeDeleteFleetModal(selectedFleet?.id);
      }}
      secondaryLabel={`${t("common.cancel")}`}
      secondaryAction={closeDeleteFleetModal}
      closeAction={closeDeleteFleetModal}
    />
  );
};
export default DeleteFleetModal;
