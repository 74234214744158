import React from "react";
import "./pending.css";
import { IconTimer } from "../Icon/Line/Timer";

export const Pending = ({ ...props }) => {
	return (
		<span className="pending" {...props}>
			<IconTimer color="--global-colors-ui-white" size={12} />
			<span className="screen-reader">Pending</span>
		</span>
	)
}