export default function buildCalendar(value) {
  const startDay = value.startOf("month").startOf("week");
  const endDay = value.endOf("month").endOf("week");
  let day = startDay.subtract(1, "day");
  const calendar = [];

  function incrementDay(i) {
    return day.add(i, 'day')
  }

  while (day.isBefore(endDay, "day")) {
    calendar.push(
      Array(7)
        .fill(0)
        .map((_, i) => i + 1)
        .map((i) => incrementDay(i)));
    day = day.add(7, 'day');
  }

  return calendar;
}
