import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import _ from "lodash";
import { normalize } from "normalizr";
import { RootState } from "../../../app/store";
import { Config } from "../../../config/Config";
import { GTFleetSuccessCodes } from "../../../config/GTFleetSuccessCodes";
import PublicRoutePlanningRepository from "./publicRoutePlanningRepository";
import {
  publicRoutePlanningsSummarysSchema,
  publicRoutePlanningSummarySchema,
} from "./publicRoutePlanningSummaryNormalization";

export interface PublicRoutePlanningSummary {
  id: number;
  startDate: Date;
  endDate: Date;
  status: string;
  lastUpdateDate: Date;
  lastUpdateUserId: number;
}

const publicRoutePlanningSummaryAdapter =
  createEntityAdapter<PublicRoutePlanningSummary>({
    selectId: (publicRoutePlanningSummary) => publicRoutePlanningSummary.id,
  });

export const getPublicRoutePlanningSummaryAsync = createAsyncThunk(
  "publicRoutePlanningSummaries/getPublicRoutePlanningSummaryAsync",
  async (data: { queryParams: string }, { rejectWithValue }) => {
    try {
      const publicRoutePlanningRepository = new PublicRoutePlanningRepository();
      const response =
        await publicRoutePlanningRepository.getPublicRoutePlannings(
          data.queryParams
        );
      const normalizedData = normalize(
        response.data,
        publicRoutePlanningSummarySchema
      );
      return normalizedData.entities;
    } catch (err: any) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data.message);
    }
  }
);

export const getPublicRoutePlanningSummariesAsync = createAsyncThunk(
  "publicRoutePlanningSummaries/getPublicRoutePlanningSummariesAsync",
  async () => {
    try {
      const publicRoutePlanningRepository = new PublicRoutePlanningRepository();
      const response =
        await publicRoutePlanningRepository.getPublicRoutePlanningsView();
      const publicRoutePlanningSummaries = _.get(
        response,
        Config.PUBLIC_ROUTE_PLANNING_VIEW
      );
      const normalizedData = normalize(
        publicRoutePlanningSummaries,
        publicRoutePlanningsSummarysSchema
      );
      return normalizedData.entities;
    } catch (err: any) {
      if (!err.response) throw err;
      return null;
    }
  }
);

//ASYNC CALLS ENDREGION

export const publicRoutePlanningSummarySlice = createSlice({
  name: "publicRoutePlanningSummary",
  initialState: publicRoutePlanningSummaryAdapter.getInitialState({
    status: "idle",
    reasonCode: "",
  }),
  reducers: {
    publicRoutePlanningSummaryEmptyState: (state: any) => {
      publicRoutePlanningSummaryAdapter.setAll(state, []);
      state.reasonCode = "";
      state.status = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPublicRoutePlanningSummariesAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(
        getPublicRoutePlanningSummariesAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          publicRoutePlanningSummaryAdapter.upsertMany(
            state,
            action.payload?.publicRoutePlanningSummarySummary ?? []
          );
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.GET;
        }
      )
      .addCase(getPublicRoutePlanningSummariesAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = "";
      })
      .addCase(getPublicRoutePlanningSummaryAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(
        getPublicRoutePlanningSummaryAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          publicRoutePlanningSummaryAdapter.upsertMany(
            state,
            action.payload?.publicRoutePlanningSummary ?? []
          );
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.GET;
        }
      )
      .addCase(getPublicRoutePlanningSummaryAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = "";
      });
  },
});

export const publicRoutePlanningSummarySelectors =
  publicRoutePlanningSummaryAdapter.getSelectors<RootState>(
    (state) => state.publicRoutePlanningSummaries
  );

export const selectPublicRoutePlanningSummaryStatus = (state: any) =>
  state.publicRoutePlannings.status;
export const selectPublicRoutePlanningSummarySliceReasonCode = (state: any) =>
  state.publicRoutePlannings.reasonCode;
export const { publicRoutePlanningSummaryEmptyState } =
  publicRoutePlanningSummarySlice.actions;
//#endregion Status

export default publicRoutePlanningSummarySlice.reducer;
