import propTypes from "prop-types";

/**
 * Logo of the various app of the company.
 */
export const Logo = ({ type, mode, width, isCompressed, onClick }) => {
  if (!width) {
    width = "initial";
  }
  if (!type || type === "") {
    type = "fleet";
  }

  if (type === "macnil-powered") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="110"
        height="36"
        viewBox="0 0 319.5 135.7"
        style={{ width: width, height: "auto" }}
        onClick={onClick}
      >
        <g>
          <g>
            <g>
              <path
                d="M113.1,90.2c3.2,0,5.7,1.1,7.5,2.9c0.4,0.4,1.2,0.4,1.6,0l6.5-7.6c0.4-0.4,0.3-1.2-0.1-1.4c-4-3.2-9.2-4.9-15.3-4.9c-6.2,0-11.5,1.7-15.4,4.9c-0.4,0.4-0.5,1.1-0.1,1.4l6.5,7.6c0.4,0.5,1.1,0.4,1.6,0C107.5,91.3,110,90.2,113.1,90.2"
                fill="#DF643A"
              />
              <path
                d="M113.1,56c-11.9,0-22.4,3.6-30.7,9.9c-0.5,0.4-0.5,1.1-0.1,1.4l6.2,7.5c0.4,0.4,0.9,0.5,1.4,0.1c6.3-4.6,14.2-7.1,23.2-7.1c8.7,0,16.6,2.6,22.9,7.2c0.4,0.3,1.1,0.3,1.4-0.1l6.2-7.4c0.4-0.4,0.3-1.2-0.1-1.4C135.3,59.5,124.7,56,113.1,56"
                fill="#DF643A"
              />
              <path
                d="M135.4,93.9c-0.4-1.3-0.9-2.6-1.6-3.8c-0.4-0.7-1.2-0.8-1.7-0.1l-7.9,9.4c-0.3,0.3-0.3,0.5-0.3,0.8c0,0.1,0,0.3,0.1,0.5c0.1,0.7-0.4,1.2-1.1,1.2h-9.5c-13.4,0-21.7,5.4-21.7,17.3c0,11.3,8.3,16.6,18.2,16.6c7.6,0,11.6-2.6,14.2-6.3h0.1v2.9c0,1.2,0.9,2.1,2.1,2.1h8.4c1.2,0,2.1-0.9,2.1-2.1v-25C137.1,101.3,136.6,97.7,135.4,93.9M124.3,116.3c0,6.2-3.4,9.2-11.2,9.2c-6.1,0-9.4-2.2-9.4-6.7c0-5,3.6-7.2,11.7-7.2h7.1c0.9,0,1.7,0.8,1.7,1.7V116.3z"
                fill="#DF643A"
              />
            </g>
            <path
              d="M68.1,134.6c-0.7,0-1.2-0.5-1.2-1.3v-31.2c0-7-3.4-11.9-10.3-11.9c-6.6,0-10.4,4.9-10.4,11.9v31.2c0,0.7-0.4,1.2-1.2,1.2H34.6c-0.7,0-1.2-0.4-1.2-1.2v-31.2c0-7-3.4-11.9-10.3-11.9c-6.6,0-10.4,4.9-10.4,11.9v31.2c0,0.7-0.4,1.2-1.2,1.2H1.2c-0.7,0-1.2-0.4-1.2-1.2V81.4c0-0.7,0.4-1.2,1.2-1.2h10.4c0.7,0,1.2,0.4,1.2,1.2v4h0.1C15.3,81.9,20,79,27.1,79c6.5,0,11.6,2.6,14.9,7.2h0.1c4.2-4.5,9.6-7.2,17.3-7.2C72,79,79,87.6,79,99.2v34.2
               c0,0.7-0.4,1.2-1.2,1.2H68.1L68.1,134.6z"
              fill="#013878"
            />
            <path
              d="M152.4,120.8c-1.2-3.4-1.7-7.6-1.7-13.4c0-5.8,0.5-10,1.7-13.4c3.2-9.6,11.5-15,22.4-15c8,0,14.5,3.3,18.8,8.4c0.4,0.4,0.4,1.2-0.1,1.6l-7.1,6.2c-0.5,0.4-1.2,0.4-1.6-0.3c-2.8-3-5.5-4.7-10-4.7c-4.9,0-8.6,2.4-10.1,7.1c-0.9,2.6-1.2,5.8-1.2,10c0,4.2,0.3,7.4,1.2,10.1c1.6,4.6,5.3,7,10.1,7c4.5,0,7.4-1.7,10-4.7c0.4-0.5,1.1-0.5,1.6-0.1l7.1,6.2c0.5,0.4,0.4,1.1,0.1,1.6c-4.2,5-10.7,8.3-18.8,8.3C163.8,135.7,155.5,130.4,152.4,120.8"
              fill="#013878"
            />
            <path
              d="M241.7,134.6c-0.7,0-1.2-0.5-1.2-1.3v-31.2c0-7-3.4-11.9-10.3-11.9c-6.6,0-10.4,4.9-10.4,11.9v31.2c0,0.7-0.4,1.2-1.2,1.2h-10.4c-0.7,0-1.2-0.4-1.2-1.2V81.4c0-0.7,0.4-1.2,1.2-1.2h10.4c0.7,0,1.2,0.4,1.2,1.2v4h0.1c2.4-3.4,7.1-6.3,14.2-6.3c11.6,0,18.8,8.6,18.8,20.2v34.2c0,0.7-0.4,1.2-1.2,1.2H241.7L241.7,134.6z"
              fill="#013878"
            />
            <path
              d="M270.4,134.5c-0.7,0-1.2-0.4-1.2-1.2V81.4c0-0.7,0.4-1.2,1.2-1.2h10.4c0.7,0,1.2,0.4,1.2,1.2v51.9c0,0.7-0.4,1.2-1.2,1.2H270.4z"
              fill="#013878"
            />
            <path
              d="M313.2,135.4c-10.5,0-14.9-5.3-14.9-15.8V64.5c0-0.7,0.4-1.2,1.2-1.2h10.4c0.7,0,1.2,0.4,1.2,1.2v54.5c0,3.7,1.4,5,4.7,5h2.5c0.7,0,1.2,0.4,1.2,1.2v9c0,0.7-0.4,1.2-1.2,1.2H313.2L313.2,135.4z"
              fill="#013878"
            />
            <path
              d="M270.4,76c-0.7,0-1.2-0.4-1.2-1.2v-9.7c0-0.7,0.4-1.2,1.2-1.2h10.4c0.7,0,1.2,0.4,1.2,1.2v9.7c0,0.7-0.4,1.2-1.2,1.2H270.4z"
              fill="#013878"
            />
          </g>
          <g>
            <path
              d="M82.6,15.7c0,5.7-2.7,9.2-7.2,9.2c-2.6,0-4.5-1.1-5.4-3h-0.3v8.6H65V6.8h4.6v2.9h0.3c1-2,3-3.2,5.4-3.2C79.8,6.5,82.6,9.9,82.6,15.7z M77.7,15.7c0-3.3-1.5-5.2-4-5.2c-2.5,0-4.1,2-4.1,5.3s1.5,5.2,4,5.2C76.2,21,77.7,19,77.7,15.7z"
              fill="#013878"
            />
            <path
              d="M85.5,15.7c0-5.8,3.3-9.3,8.8-9.3s8.8,3.5,8.8,9.3c0,5.8-3.3,9.3-8.8,9.3S85.5,21.5,85.5,15.7z M98.2,15.7c0-3.4-1.5-5.4-3.9-5.4c-2.5,0-3.9,2-3.9,5.4c0,3.5,1.4,5.5,3.9,5.5C96.8,21.1,98.2,19.1,98.2,15.7z"
              fill="#013878"
            />
            <path
              d="M126.1,24.6h-5l-3.2-12.3h-0.3l-3.1,12.3h-4.9l-4.7-17.8h4.8l2.6,12.8h0.3l3.1-12.8h4.5l3.1,12.8h0.3l2.6-12.8h4.7L126.1,24.6z"
              fill="#013878"
            />
            <path
              d="M149.3,19.5c-0.7,3.3-3.8,5.5-8.1,5.5c-5.4,0-8.6-3.4-8.6-9.2c0-5.8,3.3-9.4,8.6-9.4c5.2,0,8.3,3.3,8.3,9v1.4h-12.2v0.2c0.1,2.6,1.6,4.3,4.1,4.3c1.9,0,3.1-0.7,3.6-1.8H149.3z M137.3,13.8h7.6c-0.1-2.4-1.5-3.8-3.7-3.8C139,10,137.4,11.5,137.3,13.8z"
              fill="#013878"
            />
            <path
              d="M153.3,6.8h4.6v2.8h0.3c0.5-1.8,2.3-3.1,4.6-3.1c0.6,0,1.4,0.1,1.8,0.2V11c-0.4-0.1-1.5-0.3-2.2-0.3c-2.6,0-4.3,1.5-4.3,4v9.9h-4.7V6.8z"
              fill="#013878"
            />
            <path
              d="M183.2,19.5c-0.7,3.3-3.8,5.5-8.1,5.5c-5.4,0-8.6-3.4-8.6-9.2c0-5.8,3.3-9.4,8.6-9.4c5.2,0,8.3,3.3,8.3,9v1.4h-12.2v0.2c0.1,2.6,1.6,4.3,4.1,4.3c1.9,0,3.1-0.7,3.6-1.8H183.2z M171.1,13.8h7.6c-0.1-2.4-1.5-3.8-3.7-3.8C172.8,10,171.3,11.5,171.1,13.8z"
              fill="#013878"
            />
            <path
              d="M186.3,15.7c0-5.7,2.8-9.2,7.2-9.2c2.4,0,4.4,1.2,5.3,3.1h0.3V0h4.7v24.6h-4.6v-2.8H199c-1,1.9-3,3.1-5.4,3.1C189,24.9,186.3,21.4,186.3,15.7z M191.1,15.7c0,3.3,1.5,5.3,4,5.3c2.5,0,4-2,4-5.2c0-3.2-1.5-5.3-4-5.3S191.1,12.4,191.1,15.7z"
              fill="#013878"
            />
            <path
              d="M222.2,21.8h-0.3v2.8h-4.6V0h4.7v9.6h0.3c0.9-2,2.9-3.1,5.3-3.1c4.5,0,7.2,3.4,7.2,9.2c0,5.7-2.7,9.2-7.2,9.2C225.2,24.9,223.2,23.7,222.2,21.8z M222,15.7c0,3.2,1.6,5.2,4.1,5.2c2.5,0,4-2,4-5.3c0-3.2-1.5-5.2-4-5.2C223.6,10.4,222,12.5,222,15.7z"
              fill="#013878"
            />
            <path
              d="M238.5,31v-3.8c0.2,0,0.9,0,1.1,0c1.8,0,2.8-0.6,3.1-1.9l0.2-0.6l-6.2-17.9h5.2l3.7,13.5h0.3l3.7-13.5h5l-6.1,18.1c-1.5,4.6-3.7,6.2-8.1,6.2C240.1,31.1,238.6,31.1,238.5,31z"
              fill="#013878"
            />
          </g>
        </g>
      </svg>
    );
  } else if (type === "macnil") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="110"
        height="36"
        viewBox="0 0 100 36"
        style={{ width: width, height: "auto" }}
        onClick={onClick}
      >
        <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
          <g fillRule="nonzero">
            <g>
              <path
                d="M36.3,13.79a3,3,0,0,1,2.21.85.34.34,0,0,0,.46,0l1.9-2.24a.31.31,0,0,0,0-.43,7.08,7.08,0,0,0-4.49-1.43A7.11,7.11,0,0,0,31.81,12a.32.32,0,0,0,0,.43l1.9,2.24c.12.16.31.12.46,0a2.87,2.87,0,0,1,2.17-.85"
                fill="#DF643A"
              />
              <path
                d="M36.3,3.73a14.65,14.65,0,0,0-9,2.9.27.27,0,0,0,0,.43l1.82,2.2a.29.29,0,0,0,.42,0A11.92,11.92,0,0,1,43,9.34a.35.35,0,0,0,.43,0l1.82-2.17a.29.29,0,0,0,0-.42,14.18,14.18,0,0,0-8.94-3"
                fill="#DF643A"
              />
              <path
                d="M42.84,14.87a5.74,5.74,0,0,0-.46-1.12.3.3,0,0,0-.51,0l-2.32,2.75a.28.28,0,0,0-.08.23.3.3,0,0,0,0,.16.3.3,0,0,1-.31.35H36.42c-4,0-6.39,1.58-6.39,5.06,0,3.33,2.44,4.88,5.34,4.88,2.25,0,3.41-.77,4.18-1.86h0v.85a.61.61,0,0,0,.62.62h2.48a.62.62,0,0,0,.62-.62V18.78a11.44,11.44,0,0,0-.47-3.91m-3.25,6.58c0,1.82-1,2.71-3.29,2.71-1.78,0-2.75-.66-2.75-2,0-1.47,1.05-2.13,3.45-2.13h2.09a.51.51,0,0,1,.5.5Z"
                fill="#DF643A"
              />
              <path
                d="M23.07,26.79a.32.32,0,0,1-.35-.35V17.27c0-2-1-3.48-3-3.48s-3.06,1.43-3.06,3.48v9.17a.32.32,0,0,1-.34.35H13.24a.33.33,0,0,1-.35-.35V17.27c0-2-1-3.48-3-3.48s-3,1.43-3,3.48v9.17a.33.33,0,0,1-.35.35H3.41a.33.33,0,0,1-.35-.35V11.2a.33.33,0,0,1,.35-.35H6.47a.33.33,0,0,1,.35.35v1.16h0A4.89,4.89,0,0,1,11,10.5a5.18,5.18,0,0,1,4.38,2.13h0a6.53,6.53,0,0,1,5.06-2.13c3.72,0,5.77,2.52,5.77,5.92V26.48a.33.33,0,0,1-.35.35H23.07Z"
                fill="#013878"
              />
              <path
                d="M47.83,22.77a12.09,12.09,0,0,1-.5-3.95,12.09,12.09,0,0,1,.5-4c.93-2.82,3.37-4.41,6.58-4.41a7,7,0,0,1,5.53,2.48.31.31,0,0,1,0,.46l-2.09,1.82a.3.3,0,0,1-.47-.08,3.64,3.64,0,0,0-2.94-1.39,2.93,2.93,0,0,0-3,2.09,9,9,0,0,0-.35,2.94,9.4,9.4,0,0,0,.35,3,2.93,2.93,0,0,0,3,2,3.58,3.58,0,0,0,2.94-1.39.32.32,0,0,1,.47,0l2.09,1.82a.35.35,0,0,1,0,.46,7,7,0,0,1-5.53,2.44c-3.21,0-5.65-1.51-6.58-4.33"
                fill="#013878"
              />
              <path
                d="M74.07,26.79a.33.33,0,0,1-.35-.35V17.27c0-2-1-3.48-3-3.48s-3,1.43-3,3.48v9.17a.33.33,0,0,1-.35.35H64.24a.33.33,0,0,1-.35-.35V11.2a.33.33,0,0,1,.35-.35H67.3a.33.33,0,0,1,.35.35v1.16h0a4.89,4.89,0,0,1,4.18-1.86c3.41,0,5.54,2.52,5.54,5.92V26.48a.33.33,0,0,1-.35.35h-3Z"
                fill="#013878"
              />
              <path
                d="M82.5,26.79a.32.32,0,0,1-.34-.35V11.2a.32.32,0,0,1,.34-.35h3.06a.33.33,0,0,1,.35.35V26.44a.33.33,0,0,1-.35.35Z"
                fill="#013878"
              />
              <path
                d="M95.08,27.06c-3.1,0-4.37-1.54-4.37-4.64V6.24a.32.32,0,0,1,.35-.34h3a.32.32,0,0,1,.35.34v16c0,1.09.43,1.48,1.39,1.48h.74a.32.32,0,0,1,.35.34v2.63a.33.33,0,0,1-.35.35Z"
                fill="#013878"
              />
              <path
                d="M82.5,9.61a.32.32,0,0,1-.34-.35V6.4a.32.32,0,0,1,.34-.35h3.06a.33.33,0,0,1,.35.35V9.26a.33.33,0,0,1-.35.35Z"
                fill="#013878"
              />
            </g>
          </g>
        </g>
      </svg>
    );
  } else if (type === "alarm" && mode === "colors") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="512"
        height="512"
        viewBox="0 0 512 512"
        style={{ width: width, height: "auto" }}
        onClick={onClick}
      >
        <defs>
          <radialGradient
            id="radialGradient-1"
            cx="26.356%"
            cy="0%"
            r="100%"
            fx="26.356%"
            fy="0%"
          >
            <stop offset="0%" stopColor="#0AF"></stop>
            <stop offset="100%" stopColor="#0052BD"></stop>
          </radialGradient>
          <path id="path-2" d="M0 0H512V512H0z"></path>
          <linearGradient
            id="linearGradient-4"
            x1="16.025%"
            x2="70.195%"
            y1="17.902%"
            y2="56.437%"
          >
            <stop offset="0%" stopColor="#004297"></stop>
            <stop offset="100%" stopColor="#0050B7"></stop>
          </linearGradient>
        </defs>
        <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
          <g>
            <mask id="mask-3" fill="#fff">
              <use xlinkHref="#path-2"></use>
            </mask>
            <use fill="url(#radialGradient-1)" xlinkHref="#path-2"></use>
            <path
              fill="#004CAE"
              d="M142 339.55l56.14-36.164c4.158-2.679 25.395 8.17 31.167 4.452 4.84-3.117-6.024-20.648-.051-24.496C254.989 266.766 273.903 254.652 286 247l286 208.5L463.327 573 142 339.55z"
              mask="url(#mask-3)"
            ></path>
            <path
              fill="url(#linearGradient-4)"
              d="M295 351.5L359.5 213 416.5 163 720.5 361.5 572 557.5z"
              mask="url(#mask-3)"
            ></path>
            <g mask="url(#mask-3)">
              <g transform="translate(95.5 157) translate(0 .103)">
                <path
                  fill="#F7F7F8"
                  d="M63.93 101L7.46 137.412c6.657 15.896 17.474 29.233 31.229 39.266l53.136-34.26c-16.775-6.125-27.613-21.342-27.896-41.42"
                ></path>
                <path
                  fill="#F7F7F8"
                  d="M312.64538 0.00418064533L220.535312 0.00418064533 135.312857 54.9587635 198.75415 54.9587635 198.75415 73.458119z"
                ></path>
                <path
                  fill="#F7F7F8"
                  d="M98.2754749 84.1020421L98.2754749 122.093656 123.31754 122.093656 182.233285 84.1020421z"
                ></path>
                <path
                  fill="#FFF"
                  d="M198.759376 83.4185065L198.759376 193.484446 263.423507 193.484446 263.423507 54.9587635 320.374348 54.9587635 320.374348 4.9896002z"
                ></path>
                <path
                  fill="#FFF"
                  d="M130.753 127.271v13.776c-4.87 2.707-10.828 4.327-21.645 4.327-2.028 0-3.972-.136-5.895-.345l-56.899 36.695c17.078 10.232 37.73 15.897 60.076 15.897 34.375 0 63.076-11.643 83.644-28.418V89.04l-59.281 38.232z"
                ></path>
                <path
                  fill="#FFF"
                  d="M64.675 90.553c3.679-20.977 20.088-35.588 41.712-35.588l.669-.23v.23h12.803L205.082 0h-98.026v.063c-.857-.021-1.693-.053-2.56-.053C44.67.01 0 42.235 0 98.82v.543c0 10.672 1.589 20.705 4.494 29.997l60.18-38.807z"
                ></path>
                <path
                  fill="#FFF"
                  d="M263.439185 193.481311L263.439185 54.9660796 320.379574 54.9660796 320.379574 4.98646471 198.764601 83.4153711 198.764601 193.481311"
                ></path>
                <path
                  fill="#FFF"
                  d="M64.675 90.553c3.679-20.977 20.088-35.588 41.712-35.588l.669-.23v.23h12.803L205.082 0h-98.026v.063c-.857-.021-1.693-.053-2.56-.053C44.67.01 0 42.235 0 98.82v.543c0 10.672 1.589 20.705 4.494 29.997l60.18-38.807z"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </svg>
    );
  } else if (type === "alarm" && mode === "white") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="512"
        height="512"
        viewBox="0 0 512 512"
        style={{ width: width, height: "auto" }}
        onClick={onClick}
      >
        <defs>
          <path id="path-1" d="M0 0H512V512H0z"></path>
          <linearGradient
            id="linearGradient-3"
            x1="29.326%"
            x2="88.338%"
            y1="30.393%"
            y2="65.442%"
          >
            <stop offset="0%" stopColor="#7E7E7E"></stop>
            <stop offset="100%" stopColor="#BEBEBE" stopOpacity="0"></stop>
          </linearGradient>
          <linearGradient
            id="linearGradient-4"
            x1="16.025%"
            x2="54.77%"
            y1="17.902%"
            y2="43.258%"
          >
            <stop offset="0%" stopColor="#E5E3E3"></stop>
            <stop offset="100%" stopColor="#F7F6F6"></stop>
          </linearGradient>
        </defs>
        <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
          <g>
            <mask id="mask-2" fill="#fff">
              <use xlinkHref="#path-1"></use>
            </mask>
            <use fill="#FFF" xlinkHref="#path-1"></use>
            <path
              fill="url(#linearGradient-3)"
              d="M141.5 338.55l56.14-36.164c4.158-2.679 25.395 8.17 31.167 4.452 4.84-3.117-6.024-20.648-.051-24.496 25.733-16.576 44.647-28.69 56.744-36.342l286 208.5L462.827 572 141.5 338.55z"
              mask="url(#mask-2)"
              opacity="0.235"
            ></path>
            <path
              fill="url(#linearGradient-4)"
              d="M294.5 350.5L359 212 416 162 720 360.5 571.5 556.5z"
              mask="url(#mask-2)"
            ></path>
            <g mask="url(#mask-2)">
              <g transform="translate(95.5 157)">
                <path
                  fill="#003877"
                  d="M63.93 101.102L7.46 137.515c6.657 15.897 17.474 29.233 31.229 39.267l53.136-34.26c-16.775-6.125-27.613-21.343-27.896-41.42"
                ></path>
                <path
                  fill="#003877"
                  d="M312.64538 0.106838714L220.535312 0.106838714 135.312857 55.0614215 198.75415 55.0614215 198.75415 73.5607771z"
                ></path>
                <path
                  fill="#003877"
                  d="M98.2754749 84.2047001L98.2754749 122.196315 123.31754 122.196315 182.233285 84.2047001z"
                ></path>
                <path
                  fill="#003877"
                  d="M198.759376 83.5211646L198.759376 193.587104 263.423507 193.587104 263.423507 55.0614215 320.374348 55.0614215 320.374348 5.09225827z"
                ></path>
                <path
                  fill="#0052BD"
                  d="M130.753 127.374v13.775c-4.87 2.707-10.828 4.327-21.645 4.327-2.028 0-3.972-.136-5.895-.345l-56.899 36.696c17.078 10.232 37.73 15.897 60.076 15.897 34.375 0 63.076-11.643 83.644-28.418V89.142l-59.281 38.232z"
                ></path>
                <path
                  fill="#FEFEFE"
                  d="M64.675 90.655c3.679-20.976 20.088-35.587 41.712-35.587l.669-.23v.23h12.803L205.082.103h-98.026v.062c-.857-.02-1.693-.052-2.56-.052C44.67.113 0 42.338 0 98.923v.543c0 10.671 1.589 20.705 4.494 29.996l60.18-38.807z"
                ></path>
                <path
                  fill="#0AF"
                  d="M38.678 176.79l53.136-34.272c-16.764-6.114-27.602-21.342-27.895-41.409l-56.46 36.414c6.658 15.896 17.475 29.233 31.22 39.266"
                ></path>
                <path
                  fill="#0AF"
                  d="M312.64538 0.106838714L220.535312 0.106838714 135.312857 55.0614215 198.75415 55.0614215 198.75415 73.5607771z"
                ></path>
                <path
                  fill="#0AF"
                  d="M98.2754749 84.2047001L98.2754749 122.196315 123.31754 122.196315 182.233285 84.2047001z"
                ></path>
                <path
                  fill="#0052BD"
                  d="M263.439185 193.583969L263.439185 55.0687377 320.379574 55.0687377 320.379574 5.08912278 198.764601 83.5180291 198.764601 193.583969"
                ></path>
                <path
                  fill="#0052BD"
                  d="M64.675 90.655c3.679-20.976 20.088-35.587 41.712-35.587l.669-.23v.23h12.803L205.082.103h-98.026v.062c-.857-.02-1.693-.052-2.56-.052C44.67.113 0 42.338 0 98.923v.543c0 10.671 1.589 20.705 4.494 29.996l60.18-38.807z"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </svg>
    );
  } else if (type === "alarm-payoff" && mode === "colors") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="190"
        height="42"
        viewBox="0 0 190 42"
        style={{ width: width, height: "auto" }}
        onClick={onClick}
      >
        <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
          <g transform="translate(.001 .548)">
            <path
              fill="#0052BD"
              d="M59.451 0L48.15 26.832h7.726l1.904-4.797h10.313l1.903 4.797h7.915L66.57 0H59.45zm3.502 8.754l2.97 7.536h-5.975l3.005-7.536z"
            ></path>
            <path
              fill="#0052BD"
              d="M80.4991022 0.190073558L80.4991022 26.8329823 100.784485 26.8329823 100.784485 20.3620958 87.8815344 20.3620958 87.8815344 0.190073558z"
            ></path>
            <path
              fill="#0052BD"
              d="M113.19 0l-11.302 26.832h7.725l1.902-4.797h10.316l1.902 4.797h7.915L120.308 0h-7.118zm3.502 8.754l2.97 7.536h-5.976l3.006-7.536z"
            ></path>
            <path
              fill="#0052BD"
              d="M134.236.19h12.597c4.072 0 6.89 1.066 8.678 2.855 1.56 1.56 2.362 3.577 2.362 6.203v.076c0 4.111-2.173 6.815-5.482 8.221l6.356 9.287h-8.488l-5.367-8.07h-3.272v8.07h-7.384V.19zm12.293 12.788c2.475 0 3.958-1.219 3.958-3.158v-.076c0-2.132-1.56-3.2-3.994-3.2h-4.873v6.434h4.909z"
            ></path>
            <path
              fill="#0052BD"
              d="M161.599794 0.190384136L169.401506 0.190384136 175.757478 10.5046698 182.113451 0.190384136 189.915163 0.190384136 189.915163 26.83174 182.57 26.83174 182.57 11.5326821 175.757478 21.9603286 175.606848 21.9603286 168.831595 11.5699514 168.831595 26.83174 161.599794 26.83174z"
            ></path>
            <path
              fill="#0052BD"
              d="M8.801 14.307L1.026 19.32c.918 2.19 2.405 4.025 4.3 5.407l7.317-4.718c-2.31-.843-3.801-2.94-3.842-5.703"
            ></path>
            <path
              fill="#0052BD"
              d="M43.0494868 0.399713511L30.3654932 0.399713511 18.6303144 7.96693939 27.3668654 7.96693939 27.3668654 10.5136766z"
            ></path>
            <path
              fill="#0052BD"
              d="M13.530939 11.9803798L13.530939 17.2120613 16.9799045 17.2120613 25.0921943 11.9803798z"
            ></path>
            <path
              fill="#0052BD"
              d="M27.3674866 11.8854983L27.3674866 27.0416905 36.2717495 27.0416905 36.2717495 7.96756055 44.1122837 7.96756055 44.1122837 1.0867114z"
            ></path>
            <path
              fill="#0052BD"
              d="M18.003 17.925v1.896c-.67.373-1.49.596-2.981.596-.278 0-.546-.018-.81-.048l-7.835 5.053c2.35 1.409 5.194 2.19 8.272 2.19 4.733 0 8.685-1.603 11.518-3.913V12.66l-8.164 5.264z"
            ></path>
            <path
              fill="#0052BD"
              d="M8.904 12.868c.507-2.888 2.766-4.9 5.744-4.9l.092-.032v.031h1.764L28.238.4H14.74v.009C14.624.405 14.507.4 14.388.4 6.15.4 0 6.214 0 14.007v.074c0 1.47.217 2.851.618 4.13l8.286-5.343z"
            ></path>
            <path
              fill="#0AF"
              d="M5.325 24.729l7.318-4.72c-2.308-.841-3.8-2.939-3.842-5.702l-7.777 5.015c.92 2.19 2.407 4.025 4.301 5.407"
            ></path>
            <path
              fill="#0AF"
              d="M43.0494868 0.399713511L30.3654932 0.399713511 18.6303144 7.96693939 27.3668654 7.96693939 27.3668654 10.5136766z"
            ></path>
            <path
              fill="#0AF"
              d="M13.530939 11.9803798L13.530939 17.2120613 16.9799045 17.2120613 25.0921943 11.9803798z"
            ></path>
            <path
              fill="#0052BD"
              d="M36.2739235 27.04138L36.2739235 7.96724997 44.1144578 7.96724997 44.1144578 1.08640083 27.3681077 11.8851878 27.3681077 27.04138"
            ></path>
            <path
              fill="#0052BD"
              d="M26.166 23.698V12.66l-8.161 5.265v1.896c-.673.372-1.493.598-2.984.598-.276 0-.545-.02-.809-.05l-7.834 5.053"
            ></path>
            <path
              fill="#0052BD"
              d="M8.904 12.868c.507-2.888 2.766-4.9 5.744-4.9l.092-.032v.031h1.764L28.238.4H14.74v.009C14.624.405 14.507.4 14.388.4 6.15.4 0 6.214 0 14.007v.074c0 1.47.217 2.851.618 4.13l8.286-5.343z"
            ></path>
            <path
              fill="#0052BD"
              d="M58.1371966 33.7868172L56.5827552 38.4936224 55.0733475 33.8054519 53.8729647 33.8054519 56.0780664 40.1754007 57.0346458 40.1754007 58.5890872 35.6300959 60.1435286 40.1754007 61.1094253 40.1754007 63.3052097 33.8054519 62.1405433 33.8054519 60.6295827 38.4936224 59.0766942 33.7868172z"
            ></path>
            <path
              fill="#0052BD"
              d="M64.4458063 33.804986L64.4458063 40.1299011 69.1805635 40.1299011 69.1805635 39.1360524 65.5576745 39.1360524 65.5576745 37.4371923 68.7286729 37.4371923 68.7286729 36.4433437 65.5576745 36.4433437 65.5576745 34.7988347 69.1355297 34.7988347 69.1355297 33.804986z"
            ></path>
            <path
              fill="#0052BD"
              d="M73.2110857 33.804986L73.2110857 40.1299011 74.3229539 40.1299011 74.3229539 37.5552119 77.512587 37.5552119 77.512587 36.5427285 74.3229539 36.5427285 74.3229539 34.8174694 77.9178909 34.8174694 77.9178909 33.804986z"
            ></path>
            <path
              fill="#0052BD"
              d="M78.858 36.985v-.017c0-1.781 1.375-3.272 3.317-3.272 1.943 0 3.299 1.474 3.299 3.253v.019c0 1.78-1.375 3.27-3.317 3.27-1.943 0-3.299-1.473-3.299-3.253m5.45 0v-.017c0-1.23-.895-2.25-2.151-2.25-1.256 0-2.132 1.003-2.132 2.231v.019c0 1.228.894 2.24 2.15 2.24 1.255 0 2.133-.993 2.133-2.223"
            ></path>
            <path
              fill="#0052BD"
              d="M86.8201351 33.804986L86.8201351 40.1299011 91.2551848 40.1299011 91.2551848 39.1174177 87.9320033 39.1174177 87.9320033 33.804986z"
            ></path>
            <path
              fill="#0052BD"
              d="M92.4144161 33.804986L92.4144161 40.1299011 96.8494657 40.1299011 96.8494657 39.1174177 93.5262843 39.1174177 93.5262843 33.804986z"
            ></path>
            <path
              fill="#0052BD"
              d="M97.728 36.985v-.017c0-1.781 1.373-3.272 3.316-3.272 1.942 0 3.298 1.474 3.298 3.253v.019c0 1.78-1.374 3.27-3.317 3.27-1.941 0-3.297-1.473-3.297-3.253m5.448 0v-.017c0-1.23-.893-2.25-2.151-2.25-1.256 0-2.132 1.003-2.132 2.231v.019c0 1.228.894 2.24 2.15 2.24 1.255 0 2.133-.993 2.133-2.223"
            ></path>
            <path
              fill="#0052BD"
              d="M109.465909 33.7868172L107.911467 38.4936224 106.40206 33.8054519 105.201677 33.8054519 107.406779 40.1754007 108.363358 40.1754007 109.917799 35.6300959 111.472241 40.1754007 112.438137 40.1754007 114.633922 33.8054519 113.469255 33.8054519 111.959848 38.4936224 110.405406 33.7868172z"
            ></path>
            <path
              fill="#0052BD"
              d="M122.704905 33.804986L120.915977 36.5970796 119.153449 33.804986 117.835046 33.804986 120.346067 37.6359621 120.346067 40.1299011 121.4657 40.1299011 121.4657 37.6080101 123.978273 33.804986z"
            ></path>
            <path
              fill="#0052BD"
              d="M124.675 36.985v-.017c0-1.781 1.374-3.272 3.317-3.272 1.942 0 3.298 1.474 3.298 3.253v.019c0 1.78-1.374 3.27-3.317 3.27s-3.298-1.473-3.298-3.253m5.449 0v-.017c0-1.23-.895-2.25-2.15-2.25-1.257 0-2.133 1.003-2.133 2.231v.019c0 1.228.894 2.24 2.15 2.24 1.255 0 2.133-.993 2.133-2.223"
            ></path>
            <path
              fill="#0052BD"
              d="M132.538 37.446v-3.641h1.11V37.4c0 1.176.604 1.798 1.6 1.798.986 0 1.591-.587 1.591-1.753v-3.641h1.11v3.587c0 1.888-1.065 2.837-2.72 2.837-1.643 0-2.691-.949-2.691-2.783"
            ></path>
          </g>
        </g>
      </svg>
    );
  } else if (type === "alarm-payoff" && mode === "white") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="190"
        height="42"
        viewBox="0 0 190 42"
        style={{ width: width, height: "auto" }}
        onClick={onClick}
      >
        <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
          <g>
            <path
              fill="#FFF"
              d="M59.451 0L48.15 27.487h7.726l1.904-4.914h10.313l1.903 4.914h7.915L66.57 0H59.45zm3.502 8.967l2.97 7.72h-5.975l3.005-7.72z"
            ></path>
            <path
              fill="#FFF"
              d="M80.4991022 0.194709498L80.4991022 27.4874453 100.784485 27.4874453 100.784485 20.8587322 87.8815344 20.8587322 87.8815344 0.194709498z"
            ></path>
            <path
              fill="#FFF"
              d="M113.19 0l-11.302 27.487h7.725l1.902-4.914h10.316l1.902 4.914h7.915L120.308 0h-7.118zm3.502 8.967l2.97 7.72h-5.976l3.006-7.72z"
            ></path>
            <path
              fill="#FFF"
              d="M134.236.195h12.597c4.072 0 6.89 1.091 8.678 2.924 1.56 1.599 2.362 3.665 2.362 6.355v.078c0 4.21-2.173 6.98-5.482 8.421l6.356 9.513h-8.488l-5.367-8.265h-3.272v8.265h-7.384V.195zm12.293 13.1c2.475 0 3.958-1.249 3.958-3.236v-.078c0-2.184-1.56-3.277-3.994-3.277h-4.873v6.591h4.909z"
            ></path>
            <path
              fill="#FFF"
              d="M161.599794 0.195027651L169.401506 0.195027651 175.757478 10.7608813 182.113451 0.195027651 189.915163 0.195027651 189.915163 27.4861727 182.57 27.4861727 182.57 11.813967 175.757478 22.4959464 175.606848 22.4959464 168.831595 11.8521453 168.831595 27.4861727 161.599794 27.4861727z"
            ></path>
            <path
              fill="#FFF"
              d="M18.003 18.362v1.943c-.67.381-1.49.61-2.981.61-.278 0-.546-.019-.81-.049l-7.835 5.176c2.35 1.443 5.194 2.243 8.272 2.243 4.733 0 8.685-1.641 11.518-4.008V12.969l-8.164 5.393z"
            ></path>
            <path
              fill="#0AF"
              d="M5.325 25.332l7.318-4.834c-2.308-.863-3.8-3.012-3.842-5.842l-7.777 5.137a13.072 13.072 0 004.301 5.539"
            ></path>
            <path
              fill="#0AF"
              d="M43.0494868 0.409462621L30.3654932 0.409462621 18.6303144 8.16125498 27.3668654 8.16125498 27.3668654 10.7701077z"
            ></path>
            <path
              fill="#0AF"
              d="M13.530939 12.2725842L13.530939 17.6318677 16.9799045 17.6318677 25.0921943 12.2725842z"
            ></path>
            <path
              fill="#FFF"
              d="M36.2739235 27.7009258L36.2739235 8.16157314 44.1144578 8.16157314 44.1144578 1.11289841 27.3681077 12.1750704 27.3681077 27.7009258"
            ></path>
            <path
              fill="#FFF"
              d="M26.166 24.276V12.97l-8.161 5.393v1.942c-.673.382-1.493.613-2.984.613-.276 0-.545-.021-.809-.051l-7.834 5.176"
            ></path>
            <path
              fill="#FFF"
              d="M8.904 13.182c.507-2.959 2.766-5.02 5.744-5.02l.092-.032v.032h1.764L28.238.408H14.74v.01c-.116-.003-.233-.008-.352-.008C6.15.41 0 6.366 0 14.348v.077c0 1.506.217 2.92.618 4.231l8.286-5.474z"
            ></path>
            <path
              fill="#FFF"
              d="M58.1371966 34.6108859L56.5827552 39.4324912 55.0733475 34.6299751 53.8729647 34.6299751 56.0780664 41.1552885 57.0346458 41.1552885 58.5890872 36.4991227 60.1435286 41.1552885 61.1094253 41.1552885 63.3052097 34.6299751 62.1405433 34.6299751 60.6295827 39.4324912 59.0766942 34.6108859z"
            ></path>
            <path
              fill="#FFF"
              d="M64.4458063 34.6294979L64.4458063 41.1086791 69.1805635 41.1086791 69.1805635 40.0905903 65.5576745 40.0905903 65.5576745 38.3502946 68.7286729 38.3502946 68.7286729 37.3322057 65.5576745 37.3322057 65.5576745 35.6475868 69.1355297 35.6475868 69.1355297 34.6294979z"
            ></path>
            <path
              fill="#FFF"
              d="M73.2110857 34.6294979L73.2110857 41.1086791 74.3229539 41.1086791 74.3229539 38.4711926 77.512587 38.4711926 77.512587 37.4340146 74.3229539 37.4340146 74.3229539 35.6666759 77.9178909 35.6666759 77.9178909 34.6294979z"
            ></path>
            <path
              fill="#FFF"
              d="M78.858 37.887v-.017c0-1.825 1.375-3.352 3.317-3.352 1.943 0 3.299 1.51 3.299 3.332v.02c0 1.823-1.375 3.35-3.317 3.35-1.943 0-3.299-1.51-3.299-3.333m5.45 0v-.017c0-1.26-.895-2.305-2.151-2.305-1.256 0-2.132 1.027-2.132 2.285v.02c0 1.258.894 2.295 2.15 2.295 1.255 0 2.133-1.018 2.133-2.278"
            ></path>
            <path
              fill="#FFF"
              d="M86.8201351 34.6294979L86.8201351 41.1086791 91.2551848 41.1086791 91.2551848 40.0715011 87.9320033 40.0715011 87.9320033 34.6294979z"
            ></path>
            <path
              fill="#FFF"
              d="M92.4144161 34.6294979L92.4144161 41.1086791 96.8494657 41.1086791 96.8494657 40.0715011 93.5262843 40.0715011 93.5262843 34.6294979z"
            ></path>
            <path
              fill="#FFF"
              d="M97.728 37.887v-.017c0-1.825 1.373-3.352 3.316-3.352 1.942 0 3.298 1.51 3.298 3.332v.02c0 1.823-1.374 3.35-3.317 3.35-1.941 0-3.297-1.51-3.297-3.333m5.448 0v-.017c0-1.26-.893-2.305-2.151-2.305-1.256 0-2.132 1.027-2.132 2.285v.02c0 1.258.894 2.295 2.15 2.295 1.255 0 2.133-1.018 2.133-2.278"
            ></path>
            <path
              fill="#FFF"
              d="M109.465909 34.6108859L107.911467 39.4324912 106.40206 34.6299751 105.201677 34.6299751 107.406779 41.1552885 108.363358 41.1552885 109.917799 36.4991227 111.472241 41.1552885 112.438137 41.1552885 114.633922 34.6299751 113.469255 34.6299751 111.959848 39.4324912 110.405406 34.6108859z"
            ></path>
            <path
              fill="#FFF"
              d="M122.704905 34.6294979L120.915977 37.4896913 119.153449 34.6294979 117.835046 34.6294979 120.346067 38.5539124 120.346067 41.1086791 121.4657 41.1086791 121.4657 38.5252786 123.978273 34.6294979z"
            ></path>
            <path
              fill="#FFF"
              d="M124.675 37.887v-.017c0-1.825 1.374-3.352 3.317-3.352 1.942 0 3.298 1.51 3.298 3.332v.02c0 1.823-1.374 3.35-3.317 3.35s-3.298-1.51-3.298-3.333m5.449 0v-.017c0-1.26-.895-2.305-2.15-2.305-1.257 0-2.133 1.027-2.133 2.285v.02c0 1.258.894 2.295 2.15 2.295 1.255 0 2.133-1.018 2.133-2.278"
            ></path>
            <path
              fill="#FFF"
              d="M132.538 38.36v-3.731h1.11v3.684c0 1.204.604 1.842 1.6 1.842.986 0 1.591-.601 1.591-1.796v-3.73h1.11v3.675c0 1.934-1.065 2.906-2.72 2.906-1.643 0-2.691-.972-2.691-2.85"
            ></path>
          </g>
        </g>
      </svg>
    );
  } else if (type === "fleet" && mode === "white" && isCompressed) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="29"
        height="22"
        overflow="visible"
        onClick={onClick}
      >
        <g>
          <path
            className="st0"
            fill="#00AAFF"
            d="M6.5,16.2c0,2,1.1,3.5,2.8,4.1l-5.2,3.4c-1.4-1-2.4-2.3-3.1-3.9L6.5,16.2"
          />
          <polygon
            className="st0"
            fill="#00AAFF"
            points="31,6.2 21.9,6.2 13.5,11.7 19.8,11.7 19.8,13.5 	"
          />
          <polygon
            className="st0"
            fill="#00AAFF"
            points="9.9,14.6 9.9,18.3 12.4,18.3 18.2,14.5 	"
          />
          <polygon
            className="st2"
            fill="#FFFFFF"
            points="19.8,14.5 19.8,25.4 26.2,25.3 26.1,11.6 31.7,11.6 31.7,6.7 	"
          />
          <path
            className="st2"
            fill="#FFFFFF"
            d="M6.6,15.2c0.4-2.1,2-3.5,4.1-3.5h0.1H12l8.4-5.5l-9.6,0v0c-0.1,0-0.2,0-0.3,0C4.6,6.3,0.2,10.4,0.3,16v0.1
		c0,1.1,0.2,2,0.4,3L6.6,15.2z"
          />
          <g>
            <path
              className="st2"
              fill="#FFFFFF"
              d="M13.1,18.8v1.4c-0.5,0.3-1.1,0.4-2.1,0.4c-0.2,0-0.4,0-0.6,0l-5.6,3.7c1.7,1,3.7,1.6,5.9,1.6
			c3.4,0,6.2-1.2,8.2-2.8l0-7.9L13.1,18.8z"
            />
          </g>
        </g>
      </svg>
    );
  } else if (type === "fleet" && mode === "colors" && isCompressed) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="29"
        height="22"
        overflow="visible"
        onClick={onClick}
      >
        <g>
          <path
            className="st0"
            fill="#00AAFF"
            d="M6.5,16.2c0,2,1.1,3.5,2.8,4.1l-5.2,3.4c-1.4-1-2.4-2.3-3.1-3.9L6.5,16.2"
          />
          <polygon
            className="st0"
            fill="#00AAFF"
            points="31,6.2 21.9,6.2 13.5,11.7 19.8,11.7 19.8,13.5 	"
          />
          <polygon
            className="st0"
            fill="#00AAFF"
            points="9.9,14.6 9.9,18.3 12.4,18.3 18.2,14.5 	"
          />
          <polygon
            className="st1"
            fill="#0052BD"
            points="19.8,14.5 19.8,25.4 26.2,25.3 26.1,11.6 31.7,11.6 31.7,6.7 	"
          />
          <path
            className="st1"
            fill="#0052BD"
            d="M6.6,15.2c0.4-2.1,2-3.5,4.1-3.5h0.1H12l8.4-5.5l-9.6,0v0c-0.1,0-0.2,0-0.3,0C4.6,6.3,0.2,10.4,0.3,16v0.1
       c0,1.1,0.2,2,0.4,3L6.6,15.2z"
          />
          <g>
            <path
              className="st1"
              fill="#0052BD"
              d="M13.1,18.8v1.4c-0.5,0.3-1.1,0.4-2.1,0.4c-0.2,0-0.4,0-0.6,0l-5.6,3.7c1.7,1,3.7,1.6,5.9,1.6
         c3.4,0,6.2-1.2,8.2-2.8l0-7.9L13.1,18.8z"
            />
          </g>
        </g>
      </svg>
    );
  } else if (type === "fleet" && mode === "colors") {
    return (
      <svg
        version="1.1"
        id="Livello_1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1272.8 420"
        onClick={onClick}
      >
        <g>
          <g>
            <g>
              <path
                fill="#00AAFF"
                d="M340.1,352.4H102.2c-0.5,0-0.9-0.4-0.9-0.9v-10.9c0-0.5,0.4-0.9,0.9-0.9h237.9c0.5,0,0.9,0.4,0.9,0.9v10.9
				C341,352,340.6,352.4,340.1,352.4z"
              />
              <path
                fill="#00AAFF"
                d="M1152.2,352.4H914.2c-0.5,0-0.9-0.4-0.9-0.9v-10.9c0-0.5,0.4-0.9,0.9-0.9h237.9c0.5,0,0.9,0.4,0.9,0.9v10.9
				C1153.1,352,1152.6,352.4,1152.2,352.4z"
              />
              <path
                fill="#00AAFF"
                d="M460.9,414.5c-8.8-3.7-15.8-8.9-21-15.5s-8.1-14.4-8.8-23.3l-0.1-1.3h40.1l0.2,1.2c0.4,2.1,1.5,4.1,3.2,5.9
				c1.7,1.8,4,3.3,6.9,4.4c2.9,1.1,6.4,1.6,10.3,1.6c4,0,7.4-0.6,10.2-1.8c2.8-1.2,5-2.9,6.5-5c1.5-2.1,2.3-4.6,2.3-7.4v-0.2
				c0-4.9-1.9-8.6-5.8-11.1c-3.8-2.5-9.4-3.8-16.7-3.8h-14.8v-27.6h14.8c4.4,0,8.1-0.6,11.1-1.7c3-1.1,5.4-2.8,7-4.9
				c1.6-2.1,2.4-4.7,2.4-7.6v-0.2c0-2.8-0.7-5.2-2.1-7.2c-1.4-2-3.4-3.6-6-4.6c-2.6-1.1-5.7-1.6-9.3-1.6c-3.7,0-6.9,0.6-9.7,1.7
				c-2.7,1.1-4.9,2.7-6.5,4.6s-2.5,4.3-2.9,6.8l-0.1,0.9h-37.9l0.1-1.5c0.6-8.8,3.3-16.4,8.1-22.8c4.8-6.4,11.4-11.4,19.7-15
				c8.3-3.6,18.1-5.3,29.2-5.3c11.4,0,21.4,1.6,29.8,4.7c8.4,3.1,14.9,7.6,19.4,13.3c4.6,5.7,6.8,12.4,6.8,20.2v0.2
				c0,6-1.4,11.2-4.1,15.7c-2.7,4.5-6.3,8.1-10.8,10.8c-4.5,2.8-9.3,4.7-14.5,5.7v0.8c10.8,1.1,19.4,4.5,25.8,10.1
				c6.4,5.6,9.5,13,9.5,22.3v0.2c0,9-2.5,16.8-7.5,23.4c-5,6.6-12.1,11.6-21.3,15.2c-9.2,3.6-20.2,5.3-32.9,5.3
				C480.1,420,469.8,418.2,460.9,414.5z"
              />
              <path
                fill="#00AAFF"
                d="M686,345c-3.7-6.8-9-12.2-15.8-16.1c-6.8-3.9-14.9-5.8-24.3-5.8c-6.4,0-12.2,1-17.3,2.9
				c-5.1,1.9-9.4,4.6-12.9,8c-3.5,3.4-6.2,7.5-8.1,12.1h-0.8c0-1.3,0-2.6,0-3.9c0-1.4,0.1-2.7,0.1-3.9c0.5-6.9,1.7-12.9,3.6-18
				c1.9-5.1,4.5-9.2,8-12.1c3.4-2.9,7.7-4.4,12.7-4.4c3,0,5.6,0.4,7.8,1.3c2.2,0.9,4.1,2.1,5.7,3.5c1.6,1.5,2.8,3.1,3.8,4.9l0.3,0.6
				h41l-0.1-0.6c-1.3-7.8-4.5-14.8-9.9-21.1c-5.3-6.3-12.2-11.2-20.5-14.9c-8.3-3.7-17.7-5.5-28.1-5.5c-13.2,0-24.6,3-34.2,9
				c-9.7,6-17.1,14.5-22.4,25.5c-5.3,11-7.9,24.1-7.9,39.3v0.2c0,10.9,1.4,21,4.2,30c2.8,9.1,6.9,16.9,12.4,23.4
				c5.5,6.6,12.3,11.6,20.4,15.2c8.1,3.6,17.5,5.3,28.2,5.3c11.4,0,21.7-2.2,30.7-6.5c9-4.4,16.1-10.4,21.2-18.1
				c5.2-7.7,7.7-16.6,7.7-26.7v-0.2C691.6,359.7,689.7,351.8,686,345z M651.3,370.4c0,3.4-0.9,6.4-2.7,9.1c-1.8,2.7-4.2,4.9-7.2,6.5
				c-3,1.6-6.3,2.4-10,2.4c-3.7,0-7.1-0.8-10.1-2.4c-3-1.6-5.4-3.7-7.1-6.5c-1.8-2.7-2.6-5.9-2.6-9.3V370c0-3.6,0.9-6.8,2.7-9.6
				c1.8-2.8,4.2-4.9,7.2-6.5c3-1.5,6.5-2.3,10.2-2.3c3.6,0,6.9,0.8,9.9,2.3c2.9,1.6,5.3,3.7,7.1,6.5c1.8,2.8,2.7,6.1,2.7,9.7V370.4z
				"
              />
              <path
                fill="#00AAFF"
                d="M738.3,414.2c-8.5-3.9-15.2-9.1-20-15.7c-4.8-6.6-7.4-14-7.9-22.2l-0.1-1.8h37.8l0.2,0.6
				c0.9,2.4,2.2,4.6,4.1,6.6c1.9,2,4.2,3.6,6.9,4.7c2.7,1.2,5.8,1.8,9.3,1.8c4,0,7.5-0.8,10.5-2.4c3-1.6,5.4-3.9,7.2-6.8
				c1.8-2.9,2.6-6.2,2.6-10V369c0-3.8-0.9-7.1-2.6-9.9c-1.8-2.8-4.2-5-7.2-6.6c-3.1-1.6-6.6-2.4-10.6-2.4c-2.4,0-4.7,0.3-6.8,0.9
				c-2.1,0.6-4,1.5-5.8,2.5c-1.5,1-2.9,2.1-4.1,3.4c-1.2,1.3-2.2,2.6-2.9,4h-36.3l5.8-86.3h100.7v31.7h-67.8l-2.2,32.4h0.8
				c1.8-3.2,4.2-6,7.3-8.5c3.1-2.4,6.7-4.3,10.9-5.7c4.2-1.4,8.8-2.1,13.8-2.1c9,0,17,2,23.9,5.9c6.9,3.9,12.4,9.3,16.4,16.2
				c4,6.9,6,14.7,6,23.5v0.2c0,10.4-2.5,19.4-7.5,27.2c-5,7.7-12,13.8-20.9,18.1c-9,4.3-19.4,6.4-31.3,6.4
				C756.9,420,746.9,418.1,738.3,414.2z"
              />
            </g>
            <g>
              <path
                fill="#0052BD"
                d="M401.2,0.6h150.4v47.7h-87.5v39.5h79.2V133h-79.2v72.6h-62.9V0.6z"
              />
              <path
                fill="#0052BD"
                d="M573.9,0.6h62.9v157.2h84v47.7H573.9V0.6z"
              />
              <path
                fill="#0052BD"
                d="M744.5,0.6h151v47.7h-88.1V81h82.6v44.3h-82.6v32.5h88.1v47.7h-151V0.6z"
              />
              <path
                fill="#0052BD"
                d="M924.9,0.6h151v47.7h-88.1V81h82.6v44.3h-82.6v32.5h88.1v47.7h-151V0.6z"
              />
              <path
                fill="#0052BD"
                d="M1153.6,48.3h-56.4V0.6h175.7v47.7h-56.4v157.2h-62.9V48.3z"
              />
            </g>
            <g>
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                fill="#0052BD"
                d="M139.1,135.3v14.6c-5.2,2.9-11.5,4.6-23,4.6c-2.1,0-4.2-0.1-6.3-0.4l-60.5,39C67.4,204,89.4,210,113.2,210
				c36.6,0,67.1-12.4,89-30.2V94.6L139.1,135.3z"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                fill="#00AAFF"
                d="M41.1,187.8l56.5-36.4c-17.8-6.5-29.4-22.7-29.7-44L7.9,146.1C15,163,26.5,177.1,41.1,187.8"
              />
              <polygon
                fillRule="evenodd"
                clipRule="evenodd"
                fill="#00AAFF"
                points="332.6,0 234.6,0 143.9,58.4 211.4,58.4 211.4,78.1 			"
              />
              <polygon
                fillRule="evenodd"
                clipRule="evenodd"
                fill="#00AAFF"
                points="104.5,89.4 104.5,129.8 131.2,129.8 193.9,89.4 			"
              />
              <polygon
                fillRule="evenodd"
                clipRule="evenodd"
                fill="#0052BD"
                points="280.2,205.6 280.2,58.4 340.8,58.4 340.8,5.3 211.4,88.7 211.4,205.6 			"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                fill="#0052BD"
                d="M68.8,96.2c3.9-22.3,21.4-37.8,44.4-37.8l0.7-0.2v0.2h13.6L218.2,0H113.9v0.1C113,0,112.1,0,111.2,0
				C47.5,0,0,44.9,0,105v0.6c0,11.4,1.7,22,4.8,31.9L68.8,96.2z"
              />
            </g>
          </g>
        </g>
      </svg>
    );
  } else if (type === "fleet" && mode === "white") {
    return (
      <svg
        version="1.1"
        id="Livello_1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1280 424"
        onClick={onClick}
      >
        <g>
          <g>
            <path
              fill="#00AAFF"
              d="M343.7,354.4H105.7c-0.5,0-0.9-0.4-0.9-0.9v-10.9c0-0.5,0.4-0.9,0.9-0.9h237.9c0.5,0,0.9,0.4,0.9,0.9v10.9
         C344.6,354,344.2,354.4,343.7,354.4z"
            />
            <path
              fill="#00AAFF"
              d="M1155.7,354.4H917.8c-0.5,0-0.9-0.4-0.9-0.9v-10.9c0-0.5,0.4-0.9,0.9-0.9h237.9c0.5,0,0.9,0.4,0.9,0.9v10.9
         C1156.6,354,1156.2,354.4,1155.7,354.4z"
            />
            <path
              fill="#FFFFFF"
              d="M464.5,416.5c-8.8-3.7-15.8-8.9-21-15.5s-8.1-14.4-8.8-23.3l-0.1-1.3h40.1l0.2,1.2c0.4,2.1,1.5,4.1,3.2,5.9
         c1.7,1.8,4,3.3,6.9,4.4c2.9,1.1,6.4,1.6,10.3,1.6s7.4-0.6,10.2-1.8c2.8-1.2,5-2.9,6.5-5c1.5-2.1,2.3-4.6,2.3-7.4V375
         c0-4.9-1.9-8.6-5.8-11.1c-3.8-2.5-9.4-3.8-16.7-3.8h-14.8v-27.6h14.8c4.4,0,8.1-0.6,11.1-1.7c3-1.1,5.4-2.8,7-4.9
         c1.6-2.1,2.4-4.7,2.4-7.6v-0.2c0-2.8-0.7-5.2-2.1-7.2c-1.4-2-3.4-3.6-6-4.6c-2.6-1.1-5.7-1.6-9.3-1.6c-3.7,0-6.9,0.6-9.7,1.7
         c-2.7,1.1-4.9,2.7-6.5,4.6c-1.6,2-2.5,4.3-2.9,6.8l-0.1,0.9h-37.9l0.1-1.5c0.6-8.8,3.3-16.4,8.1-22.8c4.8-6.4,11.4-11.4,19.7-15
         c8.3-3.6,18.1-5.3,29.2-5.3c11.4,0,21.4,1.6,29.8,4.7c8.4,3.1,14.9,7.6,19.4,13.3c4.6,5.7,6.8,12.4,6.8,20.2v0.2
         c0,6-1.4,11.2-4.1,15.7c-2.7,4.5-6.3,8.1-10.8,10.8c-4.5,2.8-9.3,4.7-14.5,5.7v0.8c10.8,1.1,19.4,4.5,25.8,10.1
         c6.4,5.6,9.5,13,9.5,22.3v0.2c0,9-2.5,16.8-7.5,23.4c-5,6.6-12.1,11.6-21.3,15.2c-9.2,3.6-20.2,5.3-32.9,5.3
         C483.6,422,473.3,420.2,464.5,416.5z"
            />
            <path
              fill="#FFFFFF"
              d="M689.6,347c-3.7-6.8-9-12.2-15.8-16.1c-6.8-3.9-14.9-5.8-24.3-5.8c-6.4,0-12.2,1-17.3,2.9
         c-5.1,1.9-9.4,4.6-12.9,8c-3.5,3.4-6.2,7.5-8.1,12.1h-0.8c0-1.3,0-2.6,0-3.9c0-1.4,0.1-2.7,0.1-3.9c0.5-6.9,1.7-12.9,3.6-18
         c1.9-5.1,4.5-9.2,8-12.1c3.4-2.9,7.7-4.4,12.7-4.4c3,0,5.6,0.4,7.8,1.3c2.2,0.9,4.1,2.1,5.7,3.5c1.6,1.5,2.8,3.1,3.8,4.9l0.3,0.6
         h41l-0.1-0.6c-1.3-7.8-4.5-14.8-9.9-21.1c-5.3-6.3-12.2-11.2-20.5-14.9c-8.3-3.7-17.7-5.5-28.1-5.5c-13.2,0-24.6,3-34.2,9
         c-9.7,6-17.1,14.5-22.4,25.5c-5.3,11-7.9,24.1-7.9,39.3v0.2c0,10.9,1.4,21,4.2,30c2.8,9.1,6.9,16.9,12.4,23.4
         c5.5,6.6,12.3,11.6,20.4,15.2c8.1,3.6,17.5,5.3,28.2,5.3c11.4,0,21.7-2.2,30.7-6.5c9-4.4,16.1-10.4,21.2-18.1
         c5.2-7.7,7.7-16.6,7.7-26.7v-0.2C695.2,361.6,693.3,353.8,689.6,347z M654.9,372.4c0,3.4-0.9,6.4-2.7,9.1
         c-1.8,2.7-4.2,4.9-7.2,6.5c-3,1.6-6.3,2.4-10,2.4c-3.7,0-7.1-0.8-10.1-2.4c-3-1.6-5.4-3.7-7.1-6.5c-1.8-2.7-2.6-5.9-2.6-9.3V372
         c0-3.6,0.9-6.8,2.7-9.6s4.2-4.9,7.2-6.5c3-1.5,6.5-2.3,10.2-2.3c3.6,0,6.9,0.8,9.9,2.3c2.9,1.6,5.3,3.7,7.1,6.5
         c1.8,2.8,2.7,6.1,2.7,9.7V372.4z"
            />
            <path
              fill="#FFFFFF"
              d="M741.9,416.2c-8.5-3.9-15.2-9.1-20-15.7c-4.8-6.6-7.4-14-7.9-22.2l-0.1-1.8h37.8l0.2,0.6
         c0.9,2.4,2.2,4.6,4.1,6.6c1.9,2,4.1,3.6,6.9,4.7c2.7,1.2,5.8,1.8,9.3,1.8c4,0,7.5-0.8,10.5-2.4c3-1.6,5.4-3.9,7.2-6.8
         c1.8-2.9,2.6-6.2,2.6-10V371c0-3.8-0.9-7.1-2.6-9.9c-1.8-2.8-4.2-5-7.2-6.6s-6.6-2.4-10.6-2.4c-2.4,0-4.7,0.3-6.8,0.9
         c-2.1,0.6-4,1.5-5.8,2.5c-1.5,1-2.9,2.1-4.1,3.4s-2.2,2.6-2.9,4h-36.3l5.8-86.3h100.7v31.7h-67.8l-2.2,32.4h0.8
         c1.8-3.2,4.2-6,7.3-8.5c3.1-2.4,6.7-4.3,10.9-5.7c4.2-1.4,8.8-2.1,13.8-2.1c9,0,17,2,23.9,5.9c6.9,3.9,12.4,9.3,16.4,16.2
         c4,6.9,6,14.7,6,23.5v0.2c0,10.4-2.5,19.4-7.5,27.2c-5,7.7-12,13.8-20.9,18.1c-9,4.3-19.4,6.4-31.3,6.4
         C760.5,422,750.4,420.1,741.9,416.2z"
            />
            <g>
              <path
                fill="#FFFFFF"
                d="M404.8,2.6h150.4v47.7h-87.5v39.5h79.2V135h-79.2v72.6h-62.9V2.6z"
              />
              <path
                fill="#FFFFFF"
                d="M577.5,2.6h62.9v157.2h84v47.7H577.5V2.6z"
              />
              <path
                fill="#FFFFFF"
                d="M748.1,2.6h151v47.7H811V83h82.6v44.3H811v32.5h88.1v47.7h-151V2.6z"
              />
              <path
                fill="#FFFFFF"
                d="M928.5,2.6h151v47.7h-88.1V83h82.6v44.3h-82.6v32.5h88.1v47.7h-151V2.6z"
              />
              <path
                fill="#FFFFFF"
                d="M1157.1,50.3h-56.4V2.6h175.7v47.7H1220v157.2h-62.9V50.3z"
              />
            </g>
            <g>
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                fill="#FFFFFF"
                d="M142.7,137.3v14.6c-5.2,2.9-11.5,4.6-23,4.6c-2.1,0-4.2-0.1-6.3-0.4l-60.5,39C71,206,93,212,116.8,212
           c36.6,0,67.1-12.4,89-30.2V96.6L142.7,137.3z"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                fill="#00AAFF"
                d="M44.7,189.8l56.5-36.4c-17.8-6.5-29.4-22.7-29.7-44L11.5,148C18.6,164.9,30.1,179.1,44.7,189.8"
              />
              <polygon
                fillRule="evenodd"
                clipRule="evenodd"
                fill="#00AAFF"
                points="336.2,2 238.2,2 147.5,60.4 215,60.4 215,80.1 			"
              />
              <polygon
                fillRule="evenodd"
                clipRule="evenodd"
                fill="#00AAFF"
                points="108.1,91.4 108.1,131.8 134.8,131.8 197.4,91.4 			"
              />
              <polygon
                fillRule="evenodd"
                clipRule="evenodd"
                fill="#FFFFFF"
                points="283.8,207.6 283.8,60.4 344.4,60.4 344.4,7.3 215,90.6 215,207.6 			"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                fill="#FFFFFF"
                d="M72.4,98.2c3.9-22.3,21.4-37.8,44.4-37.8l0.7-0.2v0.2h13.6L221.7,2H117.5v0.1c-0.9,0-1.8-0.1-2.7-0.1
           C51.1,2,3.6,46.9,3.6,107v0.6c0,11.4,1.7,22,4.8,31.9L72.4,98.2z"
              />
            </g>
          </g>
        </g>
      </svg>
    );
  } else if (type === "go" && mode === "colors") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="512"
        height="512"
        viewBox="0 0 512 512"
        style={{ width: width, height: "auto" }}
        onClick={onClick}
      >
        <defs>
          <radialGradient
            id="radialGradient-1"
            cx="100%"
            cy="0%"
            r="141.421%"
            fx="100%"
            fy="0%"
          >
            <stop offset="0%" stopColor="#35465B"></stop>
            <stop offset="100%" stopColor="#031832"></stop>
          </radialGradient>
          <path id="path-2" d="M0 0H512V512H0z"></path>
          <linearGradient
            id="linearGradient-4"
            x1="16.025%"
            x2="70.195%"
            y1="27.962%"
            y2="54.419%"
          >
            <stop offset="0%" stopColor="#031832"></stop>
            <stop offset="100%" stopColor="#10243D"></stop>
          </linearGradient>
          <linearGradient
            id="linearGradient-5"
            x1="16.025%"
            x2="70.195%"
            y1="31.182%"
            y2="53.773%"
          >
            <stop offset="0%" stopColor="#031832"></stop>
            <stop offset="100%" stopColor="#10243D"></stop>
          </linearGradient>
        </defs>
        <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
          <g>
            <mask id="mask-3" fill="#fff">
              <use xlinkHref="#path-2"></use>
            </mask>
            <use fill="url(#radialGradient-1)" xlinkHref="#path-2"></use>
            <g mask="url(#mask-3)">
              <g transform="translate(106.47 161.367)">
                <path
                  fill="#031832"
                  d="M0 177.666c12.83-7.692 31.719-19.574 56.67-35.647 4.159-2.679 25.396 8.17 31.168 4.452 4.84-3.117-6.025-20.648-.052-24.496 25.733-16.576 44.162-28.985 55.287-37.227L470.53 324.133l-108.672 117.5L0 177.666z"
                  opacity="0.34"
                ></path>
                <path
                  fill="url(#linearGradient-4)"
                  d="M195.685951 50.6807117L274.861656 -1.13686838e-13 536.464555 193.633007 478.868333 261.794632z"
                  opacity="0.9"
                ></path>
                <path
                  fill="url(#linearGradient-5)"
                  d="M146.671233 82.3088743L204.625712 82.3088743 454.937402 267.143401 416.120322 301.143206z"
                  opacity="0.9"
                ></path>
              </g>
            </g>
            <g fill="#FFF" mask="url(#mask-3)">
              <path
                d="M242.155 55.048h50.593c24.49 0 44.338 19.725 44.338 44.06 0 24.337-19.848 44.06-44.338 44.06-24.489 0-44.337-19.723-44.337-44.06 0-5.317.813-8.638 2.684-12.432h-57.954v12.13c0 54.503 44.46 98.684 99.303 98.684 54.845 0 99.304-44.18 99.304-98.684 0-44.519-29.663-82.145-70.417-94.439l-79.176 50.68zm-178.378 46.4L7.562 137.95c6.702 15.835 17.525 29.104 31.278 39.071l52.894-34.347c-16.742-6.053-27.6-21.2-27.957-41.228zm66.75 25.995l.043 13.74c-4.85 2.714-10.788 4.354-21.587 4.385a53.71 53.71 0 01-5.873-.326l-56.64 36.79c17.058 10.15 37.686 15.735 59.977 15.668 34.291-.11 62.882-11.819 83.347-28.618l-.252-79.967-59.015 38.328zm96.905-72.076l83.209-53.606A99.738 99.738 0 00292.444.097l.304-.097-73.065.2-84.849 55.167h92.598zM98.106 122.38l24.991-.08 58.65-37.818H97.985l.122 37.898zm7.883-66.99l.666-.23v.224l12.77-.037L204.264.246l-97.786.312V.62c-.855-.02-1.685-.05-2.55-.044C44.248.765-.178 43.026 0 99.473v.545c.037 10.645 1.654 20.648 4.581 29.907l59.91-38.904c3.604-20.937 19.923-35.563 41.498-35.631z"
                transform="translate(60 157)"
              ></path>
            </g>
          </g>
        </g>
      </svg>
    );
  } else if (type === "go" && mode === "white") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="512"
        height="512"
        viewBox="0 0 512 512"
        style={{ width: width, height: "auto" }}
        onClick={onClick}
      >
        <defs>
          <path id="path-1" d="M0 0H512V512H0z"></path>
          <linearGradient
            id="linearGradient-3"
            x1="29.326%"
            x2="88.338%"
            y1="30.376%"
            y2="65.456%"
          >
            <stop offset="0%" stopColor="#7E7E7E"></stop>
            <stop offset="100%" stopColor="#BEBEBE" stopOpacity="0"></stop>
          </linearGradient>
          <linearGradient
            id="linearGradient-4"
            x1="16.025%"
            x2="54.77%"
            y1="27.962%"
            y2="45.371%"
          >
            <stop offset="0%" stopColor="#E5E3E3"></stop>
            <stop offset="100%" stopColor="#F7F6F6"></stop>
          </linearGradient>
          <linearGradient
            id="linearGradient-5"
            x1="16.025%"
            x2="54.77%"
            y1="31.182%"
            y2="46.047%"
          >
            <stop offset="0%" stopColor="#E5E3E3"></stop>
            <stop offset="100%" stopColor="#F7F6F6"></stop>
          </linearGradient>
        </defs>
        <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
          <g>
            <mask id="mask-2" fill="#fff">
              <use xlinkHref="#path-1"></use>
            </mask>
            <use fill="#FFF" xlinkHref="#path-1"></use>
            <g mask="url(#mask-2)">
              <g transform="translate(106.47 161.367)">
                <path
                  fill="url(#linearGradient-3)"
                  d="M0 177.666c12.83-7.692 31.719-19.574 56.67-35.647 4.159-2.679 25.396 8.17 31.168 4.452 4.84-3.117-6.025-20.648-.052-24.496 25.733-16.576 44.162-28.985 55.287-37.227L470.53 324.133l-108.672 117.5L0 177.666z"
                  opacity="0.235"
                ></path>
                <path
                  fill="url(#linearGradient-4)"
                  d="M195.685951 50.6807117L274.861656 -1.13686838e-13 536.464555 193.633007 478.868333 261.794632z"
                ></path>
                <path
                  fill="url(#linearGradient-5)"
                  d="M146.671233 82.3088743L204.625712 82.3088743 454.937402 267.143401 416.120322 301.143206z"
                ></path>
              </g>
            </g>
            <g mask="url(#mask-2)">
              <g transform="translate(60 157)">
                <path
                  fill="#35465B"
                  d="M242.155 55.048h50.593c24.49 0 44.338 19.725 44.338 44.06 0 24.337-19.848 44.06-44.338 44.06-24.489 0-44.337-19.723-44.337-44.06 0-5.317.813-8.638 2.684-12.432h-57.954v12.13c0 54.503 44.46 98.684 99.303 98.684 54.845 0 99.304-44.18 99.304-98.684 0-44.519-29.663-82.145-70.417-94.439l-79.176 50.68z"
                ></path>
                <path
                  fill="#0AF"
                  d="M63.777 101.447L7.562 137.951c6.702 15.835 17.525 29.104 31.278 39.071l52.894-34.347c-16.742-6.053-27.6-21.2-27.957-41.228z"
                ></path>
                <path
                  fill="#35465B"
                  d="M130.527 127.443l.043 13.74c-4.85 2.714-10.788 4.354-21.587 4.385a53.71 53.71 0 01-5.873-.326l-56.64 36.79c17.058 10.15 37.686 15.735 59.977 15.668 34.291-.11 62.882-11.819 83.347-28.618l-.252-79.967-59.015 38.328z"
                ></path>
                <path
                  fill="#0AF"
                  d="M227.432 55.367l83.209-53.606A99.738 99.738 0 00292.444.097l.304-.097-73.065.2-84.849 55.167h92.598z"
                ></path>
                <path
                  fill="#0AF"
                  d="M98.1055988 122.379852L123.097329 122.29968 181.747844 84.4816804 97.983519 84.4816804z"
                ></path>
                <path
                  fill="#35465B"
                  d="M105.99 55.39l.665-.23v.224l12.77-.037L204.264.246l-97.786.312V.62c-.855-.02-1.685-.05-2.55-.044C44.248.765-.178 43.026 0 99.473v.545c.037 10.645 1.654 20.648 4.581 29.907l59.91-38.904c3.604-20.937 19.923-35.563 41.498-35.631z"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </svg>
    );
  } else if (type === "military" && mode === "white") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 120 31.38"
        onClick={onClick}
      >
        <g>
          <path
            d="M68.17,13.83c-.48,0-.93-.01-1.38-.02-.12-.28-.05-.56-.06-.83,0-.26-.04-.52,.04-.79,.11,0,.22-.02,.33-.02,1.63,0,3.25,0,4.88,.01,.6,0,1.2,.07,1.78,.25,.23,.07,.47,.15,.69,.26,.97,.47,1.59,1.22,1.85,2.27,.19,.74,.24,1.49,.04,2.23-.36,1.39-1.27,2.26-2.66,2.62-.23,.06-.48,.09-.71,.13,0,.04-.02,.07,0,.09,.72,1.03,1.48,2.02,2.23,3.05h1.41c.11,.29,.05,.56,.05,.83,0,.27,.02,.54-.02,.8-.33,.06-1.93,.07-2.51,.02-.32-.46-.66-.93-1-1.41-.34-.48-.68-.96-1.02-1.44-.33-.47-.66-.94-.99-1.41-.34-.48-.67-.96-1.02-1.43-.04,.04-.07,.06-.07,.08-.04,.54-.02,3.6,.02,3.91,.45,.09,.91,0,1.38,.06,.09,.27,.03,.54,.04,.81,0,.26,0,.52,0,.79-.08,.02-.14,.05-.21,.05-1.45,0-2.9,0-4.34,0-.05,0-.1-.01-.15-.02-.07-.29-.08-1.06-.03-1.62,.08-.01,.17-.04,.26-.04,.27,0,.55,0,.82,0,.11,0,.21-.02,.33-.03,.01-.07,.03-.12,.03-.17,0-2.99,0-5.98,0-9.03Zm1.86,4.85c.11,.01,.2,.03,.3,.03,.44,0,.88,0,1.31-.02,.38-.03,.76-.09,1.13-.17,.36-.08,.7-.22,1.01-.44,.41-.29,.67-.67,.8-1.15,.1-.38,.11-.76,.05-1.14-.12-.8-.56-1.35-1.33-1.62-.36-.13-.73-.22-1.12-.24-.63-.03-1.26-.03-1.88-.05-.08,0-.16,.02-.23,.03-.07,.37-.11,2.53-.07,4.48,0,.09,.02,.18,.03,.29Z"
            fill="#fff"
          />
          <path
            d="M113.61,25.13c-3.46,0-5.67-2.45-6.25-4.79-.39-1.59-.33-3.15,.41-4.63,.87-1.74,2.22-2.98,4.14-3.46,4.05-1.01,7.15,1.7,7.85,4.6,.47,1.96,.23,3.84-.95,5.51-1.03,1.47-2.45,2.38-4.24,2.68-.11,.02-.22,.03-.33,.03-.24,.02-.49,.04-.64,.05Zm4.49-6.77c0-.19-.04-.55-.13-.91-.27-1.07-.87-1.93-1.71-2.63-1.66-1.38-4.17-1.28-5.72,.24-1.24,1.21-1.74,2.68-1.45,4.4,.21,1.22,.8,2.22,1.8,2.97,1.85,1.38,4.48,1.15,6.02-.64,.8-.93,1.16-2.02,1.18-3.42Z"
            fill="#fff"
          />
          <path
            d="M59.46,13.83c-.05,.33-.07,3.31-.02,3.7,.12,.1,.26,.06,.39,.06,1.09,0,2.19,0,3.28,.01,.13,0,.27,.01,.39,.02,.07,.35,.07,1.2,0,1.58-.1,.07-.23,.04-.35,.04-1.08,0-2.16,0-3.24,0-.15,0-.3,0-.47,0-.02,.1-.05,.17-.05,.25,0,1.11,0,2.21,0,3.32,0,.05,0,.11,.01,.18,.08,.02,.15,.05,.23,.05,1.22,0,2.43,0,3.65,.01,.07,0,.13-.01,.22-.02,.02-.08,.04-.16,.05-.23,0-.44,0-.87,0-1.31,0-.14,0-.27,0-.41,.24-.12,.48-.05,.7-.06,.23,0,.46,0,.71,0,.02,.1,.06,.17,.05,.25,0,1.08,0,2.16-.01,3.24,0,.07-.01,.13-.03,.22-.1,0-.19,.03-.28,.03-2.75,0-5.49-.01-8.24-.02-.11,0-.21-.02-.31-.03-.06-.31-.07-1.14-.02-1.63,.07-.01,.15-.04,.22-.04,.3,0,.6,0,.9,0,.09,0,.18-.02,.29-.03,.01-.07,.03-.12,.03-.18,0-2.99,0-5.98,0-8.99-.08-.02-.15-.05-.23-.05-.29,0-.57,0-.86,0-.11,0-.21-.02-.31-.03-.07-.33-.07-1.2,0-1.56,.12-.09,.26-.05,.39-.05,1.07,0,2.13,0,3.2,0,1.6,0,3.2,.01,4.79,.02,.15,0,.3,0,.42,.01,.03,.04,.04,.05,.05,.06,0,.01,.02,.02,.02,.04,0,1.13,0,2.27,0,3.4,0,.05-.01,.11-.02,.18-.23,.07-.46,.03-.69,.03-.23,0-.46,0-.71,0-.02-.1-.04-.17-.04-.25,0-.45,0-.9,0-1.35,0-.14,0-.27,0-.43-.09-.02-.17-.05-.24-.05-1.22,0-2.43-.02-3.65-.02-.08,0-.16,.02-.23,.04Z"
            fill="#fff"
          />
          <path
            d="M38.93,17.53c.49,0,.94,0,1.39,0,.44,0,.87,0,1.31,0,.45,0,.9,0,1.33,0,.08,.32,.09,1.03,.04,1.58-.1,.09-.23,.06-.35,.06-1.08,0-2.16,0-3.24,0-.15,0-.3,0-.46,0-.02,.08-.05,.14-.05,.21,0,1.13,0,2.27,0,3.4,0,.05,.02,.11,.03,.17,.08,.01,.16,.03,.24,.04,1.16,.04,3.46,.03,3.83-.03,.01-.08,.03-.17,.04-.26,0-.42,0-.85,0-1.27,0-.13,.01-.27,.02-.43,.24-.08,.47-.03,.69-.04,.23,0,.46,0,.71,0,.02,.09,.04,.15,.04,.21,0,1.12,0,2.24,0,3.36,0,.03-.01,.05-.02,.1-.07,.01-.14,.04-.22,.04-.08,0-.16,0-.25,0-2.64,0-5.27-.01-7.91-.02-.15,0-.3,0-.44-.01-.07-.32-.08-1.15-.02-1.63,.21-.1,.45-.03,.67-.05,.23-.01,.46,0,.71,0,.02-.1,.04-.18,.05-.25,0-.1,0-.19,0-.29,0-2.73,.02-5.47,.02-8.2,0-.15,0-.29-.01-.46-.48-.08-.95,0-1.39-.05-.07-.3-.09-1.06-.02-1.59,.1-.01,.2-.03,.3-.03,.59,0,1.18,0,1.76,0,2.1,0,4.21,0,6.31,.01,.15,0,.3,0,.46,0,.02,.09,.04,.15,.04,.21,0,1.09,0,2.19,0,3.28,0,.05-.01,.11-.02,.18-.23,.09-.46,.04-.69,.05-.23,0-.46,0-.71,0-.02-.11-.04-.2-.04-.29,0-.44,0-.87,0-1.31,0-.14,0-.27,0-.43-.08-.02-.15-.05-.21-.05-1.24,0-2.49,0-3.73,0-.05,0-.11,.01-.17,.02-.01,.05-.04,.1-.04,.15,0,1.13,0,2.27,0,3.4,0,.05,.01,.1,.03,.21Z"
            fill="#fff"
          />
          <path
            d="M87.72,13.07c.07-.3-.01-.56,.07-.8,.37-.06,1.12-.06,1.43,0,.06,.32,.06,2.74,0,3.09-.3,.06-1.21,.07-1.76,.02-.08-.08-.16-.17-.24-.26-.7-.78-1.57-1.2-2.61-1.3-.61-.06-1.2-.03-1.78,.14-1.25,.37-2.15,1.18-2.74,2.32-.81,1.58-.75,3.18,.12,4.7,.81,1.41,2.09,2.18,3.73,2.25,1.3,.05,2.44-.37,3.33-1.37,.05-.05,.09-.1,.14-.15h2.15c-.01,.07,0,.12-.03,.16-.88,1.53-2.11,2.6-3.86,2.99-1.17,.26-2.34,.28-3.48-.09-2.14-.69-3.57-2.14-4.23-4.28-.52-1.69-.44-3.38,.39-4.98,.83-1.59,2.08-2.73,3.81-3.28,.84-.27,1.71-.33,2.59-.25,1.01,.1,1.95,.4,2.79,.98,.04,.03,.09,.05,.18,.09Z"
            fill="#fff"
          />
          <path
            d="M52.48,12.71c.01-.2,.03-.36,.04-.51,.27-.08,1.07-.09,1.5-.02,0,0,.02,.02,.03,.03,0,.01,.01,.03,.01,.04,0,.87,0,1.75,.01,2.62,0,.11,0,.22,0,.32-.05,.03-.09,.07-.12,.07-.55,0-1.09,0-1.65,0-.05-.09-.09-.17-.13-.25-.13-.23-.23-.47-.39-.67-.35-.48-.84-.73-1.44-.74-.28,0-.55,.01-.81,.1-.98,.33-1.43,1.53-.93,2.43,.15,.26,.37,.44,.63,.57,.36,.19,.75,.32,1.15,.43,.41,.11,.82,.2,1.23,.33,.36,.12,.72,.25,1.07,.41,1.11,.52,1.76,1.4,1.93,2.62,.09,.65,.05,1.28-.18,1.9-.44,1.2-1.31,1.97-2.49,2.37-1.23,.42-2.4,.24-3.49-.46-.11-.07-.23-.14-.35-.21,0,0-.02,0-.06,.01-.07,.17,0,.37-.05,.55-.28,.08-1.23,.09-1.57,0-.06-.33-.08-1.8-.04-3.35,0-.09,.02-.18,.04-.3,.6-.01,1.18,0,1.79,0,.03,.12,.06,.22,.08,.32,.23,1.02,.86,1.62,1.89,1.77,.69,.1,1.34-.04,1.88-.5,.63-.54,.81-1.24,.66-2.04-.08-.39-.3-.71-.65-.91-.25-.14-.51-.28-.77-.37-.46-.16-.94-.28-1.41-.41-.38-.11-.77-.2-1.14-.34-1.46-.55-2.2-1.66-2.16-3.22,.04-1.54,1.08-2.88,2.57-3.28,1.02-.27,1.97-.11,2.87,.44,.14,.08,.27,.16,.45,.27Z"
            fill="#fff"
          />
          <path
            d="M100.4,23.13c0-3,.02-5.96,.02-8.93,0-.08-.01-.16-.02-.23-.31-.07-1.49-.08-1.95-.02-.01,.08-.03,.18-.03,.27,0,.49,0,.98,0,1.48,0,.13-.01,.27-.02,.4-.32,.08-1.07,.08-1.44,0,0-.05-.02-.1-.02-.15,0-1.18,0-2.35,.01-3.53,0-.04,.02-.07,.04-.13,.06,0,.13-.02,.2-.02,2.85,0,5.71,.01,8.58,.02,.02,.08,.04,.15,.04,.21,0,1.16,0,2.32,0,3.49,0,.04-.01,.08-.02,.11-.26,.08-.9,.1-1.4,.03-.01-.09-.03-.18-.03-.27,0-.49,0-.98,0-1.48,0-.13,0-.27-.01-.4-.3-.07-1.54-.09-2.02-.03-.08,.12-.04,.26-.05,.39,0,1.12,0,2.24,0,3.36,0,1.65,0,3.31-.01,4.96,0,.15,0,.3,0,.44,.25,.1,.48,.04,.71,.06,.23,.01,.46-.02,.68,.02,.07,.34,.07,1.21,0,1.62-.38,.06-.78,.02-1.17,.02-.4,0-.79,0-1.19,0-.38,0-.77,0-1.15,0-.39,0-.79,.01-1.17-.02-.07-.35-.07-1.25,0-1.63,.45-.1,.91,.01,1.42-.07Z"
            fill="#fff"
          />
          <path
            d="M95.61,12.26c.06,.47,.05,1.3-.01,1.62-.45,.08-.91-.03-1.38,.06-.01,.06-.03,.11-.03,.16-.02,2.99-.02,5.98-.02,8.99,.1,.02,.18,.04,.25,.04,.29,0,.57,0,.86,0,.11,0,.21,.02,.31,.03,.06,.34,.06,1.23,0,1.63-.06,0-.12,.03-.18,.03-1.45,0-2.89,0-4.34,0-.04,0-.08-.01-.13-.02-.02-.03-.05-.07-.05-.1,0-.5,0-1.01,0-1.53,.09-.02,.17-.04,.24-.05,.3,0,.6,0,.9,0,.09,0,.18-.02,.29-.03,.01-.08,.02-.15,.03-.21,.04-1.1,.03-8.58-.02-8.95-.07-.01-.14-.04-.22-.04-.29,0-.57,0-.86,0-.11,0-.21-.02-.31-.03-.06-.36-.06-1.24,0-1.58,.06-.01,.12-.03,.19-.03,1.49,0,2.97,0,4.48,.02Z"
            fill="#fff"
          />
        </g>
        <g>
          <path
            d="M26.45,31.26c-.15-.12-.3-.24-.46-.35-2.22-1.62-4.44-3.24-6.66-4.86-.95-.69-1.9-1.39-2.85-2.09-.06-.05-.11-.05-.17,0-2.39,1.73-4.78,3.46-7.17,5.18-.93,.67-1.87,1.35-2.8,2.02-.02,.02-.05,.03-.09,.07,.04-.14,.08-.26,.12-.37,.43-1.33,.87-2.66,1.3-3.98,.34-1.05,.69-2.09,1.03-3.14,.37-1.14,.75-2.28,1.12-3.42,.09-.29,.18-.57,.29-.86,.03-.09,.01-.13-.06-.19-1.65-1.2-3.3-2.41-4.95-3.61-1.66-1.21-3.31-2.42-4.97-3.63-.02-.02-.04-.04-.08-.07,.06,0,.1,0,.14,0,1.57,0,3.14,0,4.71,0,2.48,0,4.97,.01,7.45,.02,.19,0,.19,0,.25-.18,.47-1.43,.94-2.85,1.4-4.28,.44-1.35,.89-2.7,1.33-4.05,.36-1.09,.72-2.18,1.07-3.27,0-.03,.02-.05,.04-.1,.11,.33,.21,.64,.31,.95,.26,.79,.51,1.59,.77,2.38,.26,.8,.52,1.61,.78,2.41,.22,.69,.45,1.38,.67,2.07,.32,1,.64,2,.96,3,.11,.33,.21,.65,.32,.98,.03,.09,.07,.11,.16,.11,2.97,0,5.93,.01,8.9,.02,1.13,0,2.27,0,3.4,.01,.02,0,.05,0,.1,0-.1,.07-.17,.13-.25,.19-2.34,1.69-4.68,3.38-7.01,5.07-.92,.66-1.83,1.32-2.75,1.98-.09,.07-.12,.12-.08,.23,.53,1.63,1.05,3.25,1.58,4.88,.58,1.81,1.17,3.63,1.75,5.44,.15,.46,.29,.91,.44,1.37,0,.02,.02,.04,.03,.06h-.06Z"
            fill="#d59e0f"
          />
          <polygon
            points="16.43 17.33 16.43 .12 12.55 12 16.43 17.33"
            fill="#b08400"
          />
          <polygon
            points="32.81 12.05 16.41 17.33 20.33 11.99 32.81 12.05"
            fill="#b08400"
          />
          <polygon
            points="26.48 31.29 16.41 17.33 22.66 19.42 26.48 31.29"
            fill="#b08400"
          />
          <polygon
            points="16.41 17.33 6.2 31.29 16.41 23.93 16.41 17.33"
            fill="#b08400"
          />
          <polygon
            points="0 11.99 10.1 19.35 16.41 17.33 0 11.99"
            fill="#b08400"
          />
        </g>
      </svg>
    );
  } else if (type === "military" && mode === "colors") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 120 31.38"
        onClick={onClick}
      >
        <g>
          <path d="M68.17,13.83c-.48,0-.93-.01-1.38-.02-.12-.28-.05-.56-.06-.83,0-.26-.04-.52,.04-.79,.11,0,.22-.02,.33-.02,1.63,0,3.25,0,4.88,.01,.6,0,1.2,.07,1.78,.25,.23,.07,.47,.15,.69,.26,.97,.47,1.59,1.22,1.85,2.27,.19,.74,.24,1.49,.04,2.23-.36,1.39-1.27,2.26-2.66,2.62-.23,.06-.48,.09-.71,.13,0,.04-.02,.07,0,.09,.72,1.03,1.48,2.02,2.23,3.05h1.41c.11,.29,.05,.56,.05,.83,0,.27,.02,.54-.02,.8-.33,.06-1.93,.07-2.51,.02-.32-.46-.66-.93-1-1.41-.34-.48-.68-.96-1.02-1.44-.33-.47-.66-.94-.99-1.41-.34-.48-.67-.96-1.02-1.43-.04,.04-.07,.06-.07,.08-.04,.54-.02,3.6,.02,3.91,.45,.09,.91,0,1.38,.06,.09,.27,.03,.54,.04,.81,0,.26,0,.52,0,.79-.08,.02-.14,.05-.21,.05-1.45,0-2.9,0-4.34,0-.05,0-.1-.01-.15-.02-.07-.29-.08-1.06-.03-1.62,.08-.01,.17-.04,.26-.04,.27,0,.55,0,.82,0,.11,0,.21-.02,.33-.03,.01-.07,.03-.12,.03-.17,0-2.99,0-5.98,0-9.03Zm1.86,4.85c.11,.01,.2,.03,.3,.03,.44,0,.88,0,1.31-.02,.38-.03,.76-.09,1.13-.17,.36-.08,.7-.22,1.01-.44,.41-.29,.67-.67,.8-1.15,.1-.38,.11-.76,.05-1.14-.12-.8-.56-1.35-1.33-1.62-.36-.13-.73-.22-1.12-.24-.63-.03-1.26-.03-1.88-.05-.08,0-.16,.02-.23,.03-.07,.37-.11,2.53-.07,4.48,0,.09,.02,.18,.03,.29Z" />
          <path d="M113.61,25.13c-3.46,0-5.67-2.45-6.25-4.79-.39-1.59-.33-3.15,.41-4.63,.87-1.74,2.22-2.98,4.14-3.46,4.05-1.01,7.15,1.7,7.85,4.6,.47,1.96,.23,3.84-.95,5.51-1.03,1.47-2.45,2.38-4.24,2.68-.11,.02-.22,.03-.33,.03-.24,.02-.49,.04-.64,.05Zm4.49-6.77c0-.19-.04-.55-.13-.91-.27-1.07-.87-1.93-1.71-2.63-1.66-1.38-4.17-1.28-5.72,.24-1.24,1.21-1.74,2.68-1.45,4.4,.21,1.22,.8,2.22,1.8,2.97,1.85,1.38,4.48,1.15,6.02-.64,.8-.93,1.16-2.02,1.18-3.42Z" />
          <path d="M59.46,13.83c-.05,.33-.07,3.31-.02,3.7,.12,.1,.26,.06,.39,.06,1.09,0,2.19,0,3.28,.01,.13,0,.27,.01,.39,.02,.07,.35,.07,1.2,0,1.58-.1,.07-.23,.04-.35,.04-1.08,0-2.16,0-3.24,0-.15,0-.3,0-.47,0-.02,.1-.05,.17-.05,.25,0,1.11,0,2.21,0,3.32,0,.05,0,.11,.01,.18,.08,.02,.15,.05,.23,.05,1.22,0,2.43,0,3.65,.01,.07,0,.13-.01,.22-.02,.02-.08,.04-.16,.05-.23,0-.44,0-.87,0-1.31,0-.14,0-.27,0-.41,.24-.12,.48-.05,.7-.06,.23,0,.46,0,.71,0,.02,.1,.06,.17,.05,.25,0,1.08,0,2.16-.01,3.24,0,.07-.01,.13-.03,.22-.1,0-.19,.03-.28,.03-2.75,0-5.49-.01-8.24-.02-.11,0-.21-.02-.31-.03-.06-.31-.07-1.14-.02-1.63,.07-.01,.15-.04,.22-.04,.3,0,.6,0,.9,0,.09,0,.18-.02,.29-.03,.01-.07,.03-.12,.03-.18,0-2.99,0-5.98,0-8.99-.08-.02-.15-.05-.23-.05-.29,0-.57,0-.86,0-.11,0-.21-.02-.31-.03-.07-.33-.07-1.2,0-1.56,.12-.09,.26-.05,.39-.05,1.07,0,2.13,0,3.2,0,1.6,0,3.2,.01,4.79,.02,.15,0,.3,0,.42,.01,.03,.04,.04,.05,.05,.06,0,.01,.02,.02,.02,.04,0,1.13,0,2.27,0,3.4,0,.05-.01,.11-.02,.18-.23,.07-.46,.03-.69,.03-.23,0-.46,0-.71,0-.02-.1-.04-.17-.04-.25,0-.45,0-.9,0-1.35,0-.14,0-.27,0-.43-.09-.02-.17-.05-.24-.05-1.22,0-2.43-.02-3.65-.02-.08,0-.16,.02-.23,.04Z" />
          <path d="M38.93,17.53c.49,0,.94,0,1.39,0,.44,0,.87,0,1.31,0,.45,0,.9,0,1.33,0,.08,.32,.09,1.03,.04,1.58-.1,.09-.23,.06-.35,.06-1.08,0-2.16,0-3.24,0-.15,0-.3,0-.46,0-.02,.08-.05,.14-.05,.21,0,1.13,0,2.27,0,3.4,0,.05,.02,.11,.03,.17,.08,.01,.16,.03,.24,.04,1.16,.04,3.46,.03,3.83-.03,.01-.08,.03-.17,.04-.26,0-.42,0-.85,0-1.27,0-.13,.01-.27,.02-.43,.24-.08,.47-.03,.69-.04,.23,0,.46,0,.71,0,.02,.09,.04,.15,.04,.21,0,1.12,0,2.24,0,3.36,0,.03-.01,.05-.02,.1-.07,.01-.14,.04-.22,.04-.08,0-.16,0-.25,0-2.64,0-5.27-.01-7.91-.02-.15,0-.3,0-.44-.01-.07-.32-.08-1.15-.02-1.63,.21-.1,.45-.03,.67-.05,.23-.01,.46,0,.71,0,.02-.1,.04-.18,.05-.25,0-.1,0-.19,0-.29,0-2.73,.02-5.47,.02-8.2,0-.15,0-.29-.01-.46-.48-.08-.95,0-1.39-.05-.07-.3-.09-1.06-.02-1.59,.1-.01,.2-.03,.3-.03,.59,0,1.18,0,1.76,0,2.1,0,4.21,0,6.31,.01,.15,0,.3,0,.46,0,.02,.09,.04,.15,.04,.21,0,1.09,0,2.19,0,3.28,0,.05-.01,.11-.02,.18-.23,.09-.46,.04-.69,.05-.23,0-.46,0-.71,0-.02-.11-.04-.2-.04-.29,0-.44,0-.87,0-1.31,0-.14,0-.27,0-.43-.08-.02-.15-.05-.21-.05-1.24,0-2.49,0-3.73,0-.05,0-.11,.01-.17,.02-.01,.05-.04,.1-.04,.15,0,1.13,0,2.27,0,3.4,0,.05,.01,.1,.03,.21Z" />
          <path d="M87.72,13.07c.07-.3-.01-.56,.07-.8,.37-.06,1.12-.06,1.43,0,.06,.32,.06,2.74,0,3.09-.3,.06-1.21,.07-1.76,.02-.08-.08-.16-.17-.24-.26-.7-.78-1.57-1.2-2.61-1.3-.61-.06-1.2-.03-1.78,.14-1.25,.37-2.15,1.18-2.74,2.32-.81,1.58-.75,3.18,.12,4.7,.81,1.41,2.09,2.18,3.73,2.25,1.3,.05,2.44-.37,3.33-1.37,.05-.05,.09-.1,.14-.15h2.15c-.01,.07,0,.12-.03,.16-.88,1.53-2.11,2.6-3.86,2.99-1.17,.26-2.34,.28-3.48-.09-2.14-.69-3.57-2.14-4.23-4.28-.52-1.69-.44-3.38,.39-4.98,.83-1.59,2.08-2.73,3.81-3.28,.84-.27,1.71-.33,2.59-.25,1.01,.1,1.95,.4,2.79,.98,.04,.03,.09,.05,.18,.09Z" />
          <path d="M52.48,12.71c.01-.2,.03-.36,.04-.51,.27-.08,1.07-.09,1.5-.02,0,0,.02,.02,.03,.03,0,.01,.01,.03,.01,.04,0,.87,0,1.75,.01,2.62,0,.11,0,.22,0,.32-.05,.03-.09,.07-.12,.07-.55,0-1.09,0-1.65,0-.05-.09-.09-.17-.13-.25-.13-.23-.23-.47-.39-.67-.35-.48-.84-.73-1.44-.74-.28,0-.55,.01-.81,.1-.98,.33-1.43,1.53-.93,2.43,.15,.26,.37,.44,.63,.57,.36,.19,.75,.32,1.15,.43,.41,.11,.82,.2,1.23,.33,.36,.12,.72,.25,1.07,.41,1.11,.52,1.76,1.4,1.93,2.62,.09,.65,.05,1.28-.18,1.9-.44,1.2-1.31,1.97-2.49,2.37-1.23,.42-2.4,.24-3.49-.46-.11-.07-.23-.14-.35-.21,0,0-.02,0-.06,.01-.07,.17,0,.37-.05,.55-.28,.08-1.23,.09-1.57,0-.06-.33-.08-1.8-.04-3.35,0-.09,.02-.18,.04-.3,.6-.01,1.18,0,1.79,0,.03,.12,.06,.22,.08,.32,.23,1.02,.86,1.62,1.89,1.77,.69,.1,1.34-.04,1.88-.5,.63-.54,.81-1.24,.66-2.04-.08-.39-.3-.71-.65-.91-.25-.14-.51-.28-.77-.37-.46-.16-.94-.28-1.41-.41-.38-.11-.77-.2-1.14-.34-1.46-.55-2.2-1.66-2.16-3.22,.04-1.54,1.08-2.88,2.57-3.28,1.02-.27,1.97-.11,2.87,.44,.14,.08,.27,.16,.45,.27Z" />
          <path d="M100.4,23.13c0-3,.02-5.96,.02-8.93,0-.08-.01-.16-.02-.23-.31-.07-1.49-.08-1.95-.02-.01,.08-.03,.18-.03,.27,0,.49,0,.98,0,1.48,0,.13-.01,.27-.02,.4-.32,.08-1.07,.08-1.44,0,0-.05-.02-.1-.02-.15,0-1.18,0-2.35,.01-3.53,0-.04,.02-.07,.04-.13,.06,0,.13-.02,.2-.02,2.85,0,5.71,.01,8.58,.02,.02,.08,.04,.15,.04,.21,0,1.16,0,2.32,0,3.49,0,.04-.01,.08-.02,.11-.26,.08-.9,.1-1.4,.03-.01-.09-.03-.18-.03-.27,0-.49,0-.98,0-1.48,0-.13,0-.27-.01-.4-.3-.07-1.54-.09-2.02-.03-.08,.12-.04,.26-.05,.39,0,1.12,0,2.24,0,3.36,0,1.65,0,3.31-.01,4.96,0,.15,0,.3,0,.44,.25,.1,.48,.04,.71,.06,.23,.01,.46-.02,.68,.02,.07,.34,.07,1.21,0,1.62-.38,.06-.78,.02-1.17,.02-.4,0-.79,0-1.19,0-.38,0-.77,0-1.15,0-.39,0-.79,.01-1.17-.02-.07-.35-.07-1.25,0-1.63,.45-.1,.91,.01,1.42-.07Z" />
          <path d="M95.61,12.26c.06,.47,.05,1.3-.01,1.62-.45,.08-.91-.03-1.38,.06-.01,.06-.03,.11-.03,.16-.02,2.99-.02,5.98-.02,8.99,.1,.02,.18,.04,.25,.04,.29,0,.57,0,.86,0,.11,0,.21,.02,.31,.03,.06,.34,.06,1.23,0,1.63-.06,0-.12,.03-.18,.03-1.45,0-2.89,0-4.34,0-.04,0-.08-.01-.13-.02-.02-.03-.05-.07-.05-.1,0-.5,0-1.01,0-1.53,.09-.02,.17-.04,.24-.05,.3,0,.6,0,.9,0,.09,0,.18-.02,.29-.03,.01-.08,.02-.15,.03-.21,.04-1.1,.03-8.58-.02-8.95-.07-.01-.14-.04-.22-.04-.29,0-.57,0-.86,0-.11,0-.21-.02-.31-.03-.06-.36-.06-1.24,0-1.58,.06-.01,.12-.03,.19-.03,1.49,0,2.97,0,4.48,.02Z" />
        </g>
        <g>
          <path
            d="M26.45,31.26c-.15-.12-.3-.24-.46-.35-2.22-1.62-4.44-3.24-6.66-4.86-.95-.69-1.9-1.39-2.85-2.09-.06-.05-.11-.05-.17,0-2.39,1.73-4.78,3.46-7.17,5.18-.93,.67-1.87,1.35-2.8,2.02-.02,.02-.05,.03-.09,.07,.04-.14,.08-.26,.12-.37,.43-1.33,.87-2.66,1.3-3.98,.34-1.05,.69-2.09,1.03-3.14,.37-1.14,.75-2.28,1.12-3.42,.09-.29,.18-.57,.29-.86,.03-.09,.01-.13-.06-.19-1.65-1.2-3.3-2.41-4.95-3.61-1.66-1.21-3.31-2.42-4.97-3.63-.02-.02-.04-.04-.08-.07,.06,0,.1,0,.14,0,1.57,0,3.14,0,4.71,0,2.48,0,4.97,.01,7.45,.02,.19,0,.19,0,.25-.18,.47-1.43,.94-2.85,1.4-4.28,.44-1.35,.89-2.7,1.33-4.05,.36-1.09,.72-2.18,1.07-3.27,0-.03,.02-.05,.04-.1,.11,.33,.21,.64,.31,.95,.26,.79,.51,1.59,.77,2.38,.26,.8,.52,1.61,.78,2.41,.22,.69,.45,1.38,.67,2.07,.32,1,.64,2,.96,3,.11,.33,.21,.65,.32,.98,.03,.09,.07,.11,.16,.11,2.97,0,5.93,.01,8.9,.02,1.13,0,2.27,0,3.4,.01,.02,0,.05,0,.1,0-.1,.07-.17,.13-.25,.19-2.34,1.69-4.68,3.38-7.01,5.07-.92,.66-1.83,1.32-2.75,1.98-.09,.07-.12,.12-.08,.23,.53,1.63,1.05,3.25,1.58,4.88,.58,1.81,1.17,3.63,1.75,5.44,.15,.46,.29,.91,.44,1.37,0,.02,.02,.04,.03,.06h-.06Z"
            fill="#d59e0f"
          />
          <polygon
            points="16.43 17.33 16.43 .12 12.55 12 16.43 17.33"
            fill="#b08400"
          />
          <polygon
            points="32.81 12.05 16.41 17.33 20.33 11.99 32.81 12.05"
            fill="#b08400"
          />
          <polygon
            points="26.48 31.29 16.41 17.33 22.66 19.42 26.48 31.29"
            fill="#b08400"
          />
          <polygon
            points="16.41 17.33 6.2 31.29 16.41 23.93 16.41 17.33"
            fill="#b08400"
          />
          <polygon
            points="0 11.99 10.1 19.35 16.41 17.33 0 11.99"
            fill="#b08400"
          />
        </g>
      </svg>
    );
  }
};

Logo.propTypes = {
  /**
   * What type of logo you want to display?
   */
  type: propTypes.oneOf([
    "macnil",
    "macnil-powered",
    "alarm",
    "alarm-payoff",
    "fleet",
    "go",
  ]),
  /**
   * Color mode
   */
  mode: propTypes.oneOf(["colors", "white"]),
  /**
   * Size of the logo.
   */
  width: propTypes.number,
  /**
   * Certain logo has a compressed version of itself like GT Fleet.
   */
  isCompressed: propTypes.bool,
  /**
   * onClick function
   */
  onClick: propTypes.func,
};

Logo.defaultProps = {
  mode: "colors",
};
