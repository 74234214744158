import { useEffect, useState } from "react";
import { Button } from "../../ui/Button/Button";
import { ElementType } from "../../ui/Group/ElementTypeEnum";
import { GroupElement } from "../../ui/Group/GroupElement";
import { GroupWidget } from "../../ui/Group/GroupWidget";

//#region types
export interface ResourceProps {
  key: number;
  id: number;
  firstTitle: string;
  secondTitle: string;
  status:
    | "NO_SIGNAL"
    | "ONLINE"
    | "MOVING"
    | "PARKING"
    | "OFFLINE"
    | "UNKNOWN"
    | "STOP";
  firstSubtitle: string;
  secondSubtitle: string;
  type: ElementType;
  icon: JSX.Element;
  categoryColor: string;
}

interface AdminPrivilegesResourceTabProps {
  tabType?: "drivers" | "vehicles" | "geofences";
  customTitleFirstGroup?: string;
  customTitleSecondGroup?: string;
  rightGroupProp: ResourceProps[];
  leftGroupProp: ResourceProps[];
  rightButtonText: String;
  leftButtonText: String;
  disabled?: boolean;
  output: (
    leftResources: ResourceProps[],
    rightResources: ResourceProps[]
  ) => void;
}
//#endregion

export const AdminPrivilegesResourceTab: React.FC<AdminPrivilegesResourceTabProps> =
  ({
    tabType,
    customTitleFirstGroup,
    customTitleSecondGroup,
    rightGroupProp,
    leftGroupProp,
    leftButtonText,
    rightButtonText,
    disabled,
    output,
  }) => {
    //#region variables to manage groups between cards
    // These hooks keep the search string for selected items
    const [rightSearchString, setRightSearchString] = useState("");
    const [leftSearchString, setLeftSearchString] = useState("");

    const [rightGroupList, setRightGroupList] = useState<ResourceProps[]>([]);
    const [leftGroupList, setLeftGroupList] = useState<ResourceProps[]>([]);
    //#endregion

    useEffect(() => {
      if (leftGroupProp.length > 0) {
        setLeftGroupList(leftGroupProp);
      }
    }, [leftGroupProp]);

    useEffect(() => {
      if (rightGroupProp.length > 0) {
        setRightGroupList(rightGroupProp);
      }
    }, [rightGroupProp]);

    //#region filter selected items
    const filterResources = (
      resources: ResourceProps[],
      searchString: string
    ) => {
      if (searchString === "") return resources;
      else {
        return resources.filter(
          (resource: ResourceProps) =>
            (resource?.firstTitle &&
              resource?.firstTitle
                ?.toLowerCase()
                .includes(searchString.toLowerCase())) ||
            (resource?.secondTitle &&
              resource?.secondTitle
                ?.toLowerCase()
                .includes(searchString.toLowerCase()))
        );
      }
    };
    //#endregion

    return (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <GroupWidget
            title={
              customTitleFirstGroup && customTitleFirstGroup != ""
                ? customTitleFirstGroup
                : ""
            }
            counter={leftGroupList.length}
            type={tabType}
            hasNoChildIcon={leftGroupList.length === 0}
            titleButton={
              <Button
                size="small"
                aspect="outline"
                label={leftButtonText}
                onClick={() => {
                  // Remove all elements from right group
                  setLeftGroupList([]);

                  // Add removed elements into left group
                  const newResourcesList = [
                    ...rightGroupList,
                    ...leftGroupList,
                  ];

                  // Update left group list
                  setRightGroupList(newResourcesList);

                  // Get out updated groups
                  if (output) {
                    output(newResourcesList, []);
                  }
                }}
                disabled={leftGroupList.length === 0 || disabled}
              />
            }
            filterCallback={(val: string) => {
              setLeftSearchString(val);
            }}
          >
            {filterResources(leftGroupList, leftSearchString).map(
              (resource: ResourceProps) => (
                <GroupElement
                  key={resource.id}
                  id={resource.id}
                  disableClick={disabled}
                  firstTitle={resource.firstTitle}
                  secondTitle={resource.secondTitle}
                  status={resource.status}
                  firstSubtitle={resource.firstSubtitle}
                  secondSubtitle={resource.secondSubtitle}
                  type={resource.type}
                  isAdded={true}
                  icon={resource.icon}
                  categoryColor={resource.categoryColor}
                  onClicked={(id) => {
                    // Find the index of element clicked
                    const clickedIndex = leftGroupList.findIndex(
                      (item) => item.id === id
                    );

                    // If element exists
                    if (clickedIndex !== -1) {
                      const clickedItem = leftGroupList[clickedIndex];

                      // Check whether item is already present or no
                      const isAlreadyInResourcesList = rightGroupList.some(
                        (item) => item.id === id
                      );
                      if (!isAlreadyInResourcesList) {
                        rightGroupList.unshift(clickedItem);
                        setRightGroupList(rightGroupList);
                      }

                      // Remove element from right group
                      const updatedSelectedResourcesList = [...leftGroupList];
                      updatedSelectedResourcesList.splice(clickedIndex, 1);
                      setLeftGroupList(updatedSelectedResourcesList);

                      // Get out updated groups
                      if (output) {
                        output(rightGroupList, updatedSelectedResourcesList);
                      }
                    }
                  }}
                />
              )
            )}
          </GroupWidget>
          <GroupWidget
            counter={
              rightGroupList.filter(
                (x) => !leftGroupList.some((y) => y.id === x.id)
              ).length
            }
            title={
              customTitleSecondGroup && customTitleSecondGroup != ""
                ? customTitleSecondGroup
                : ""
            }
            type={tabType}
            titleButton={
              <Button
                size="small"
                aspect="outline"
                label={rightButtonText}
                onClick={() => {
                  // Remove all elements from right group
                  setRightGroupList([]);

                  // Add removed elements into left group
                  const newResourcesList = [
                    ...rightGroupList,
                    ...leftGroupList,
                  ];

                  // Update left group list
                  setLeftGroupList(newResourcesList);

                  // Get out updated groups
                  if (output) {
                    output([], newResourcesList);
                  }
                }}
                disabled={rightGroupList.length === 0 || disabled}
              />
            }
            filterCallback={(val: string) => {
              setRightSearchString(val);
            }}
          >
            {filterResources(rightGroupList, rightSearchString)
              .filter((x) => !leftGroupList.some((y) => y.id === x.id))
              .map((resource: ResourceProps) => (
                <GroupElement
                  key={resource.id}
                  id={resource.id}
                  disableClick={disabled}
                  firstTitle={resource.firstTitle}
                  secondTitle={resource.secondTitle}
                  status={resource.status}
                  firstSubtitle={resource.firstSubtitle}
                  secondSubtitle={resource.secondSubtitle}
                  type={resource.type}
                  isAdded={false}
                  icon={resource.icon}
                  categoryColor={resource.categoryColor}
                  onClicked={(id) => {
                    // Find the index of element clicked
                    const clickedIndex = rightGroupList.findIndex(
                      (item) => item.id === id
                    );

                    // If element exists
                    if (clickedIndex !== -1) {
                      const clickedItem = rightGroupList[clickedIndex];

                      // Remove element from left group
                      const updatedResourcesList = [...rightGroupList];
                      updatedResourcesList.splice(clickedIndex, 1);
                      setRightGroupList(updatedResourcesList);

                      // Add element to the right group
                      leftGroupList.unshift(clickedItem);
                      setLeftGroupList(leftGroupList);

                      // Get out updated groups
                      if (output) {
                        output(updatedResourcesList, leftGroupList);
                      }
                    }
                  }}
                />
              ))}
          </GroupWidget>
        </div>
      </>
    );
  };
