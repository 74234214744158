import { t } from "i18next";
import React, { useContext, useState } from "react";
import { ConvertTimeZone } from "../../utils/DateAndTimeUtils";
import { Preferences } from "../users/preference/preferencesSlice";
import UserContext from "../users/userContext";
import "./FleetPolling.css";

export interface FleetPolling {
  date: number;
}
interface FleetPollingProps {
  pollingDate: FleetPolling;
}

export const FleetPolling: React.FC<FleetPollingProps> = ({
  pollingDate,
}: FleetPollingProps) => {
  const [preferencesContext]: [Preferences] = useContext(UserContext);

  let [counter, setCounter] = useState(0);
  let [prevDate, setPrevDate] = useState(pollingDate.date);
  let convertedDate = new Date(pollingDate.date);

  setTimeout(() => {
    prevDate == pollingDate.date ? setCounter(counter + 1) : setCounter(0);
    setPrevDate(pollingDate.date);
  }, 1000);

  return (
    <div className="polling-container">
      <span className="mn-chart__label">
        <progress
          value={counter}
          max="60"
          className="progress-bar--blue progress-bar"
        />
      </span>
      <span className="mn-chart__data">
        {t("fleetControl.polling.lastUpdate") + ": "}
        {pollingDate.date
          ? ConvertTimeZone(
              convertedDate,
              preferencesContext.timeZone,
              preferencesContext.localeFormat
            ).replace(",", " |")
          : t("common.na")}
      </span>
    </div>
  );
};
