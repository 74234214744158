import { t } from "i18next";
import { useEffect, useState } from "react";
import PageContent from "../../layout/PageContent";
import PageFilters from "../../layout/PageFilters";
import "./CreateGeofence.css";
import { CreateGeofenceSaveBar } from "./CreateGeofenceSaveBar";
import { GeofenceMap } from "./GeofenceMap";
import { Circle, GeofenceShapeEnum, Polygon } from "./geofenceSlice";

const CreateGeofence = () => {
  const [drawedPoints, setDrawedPoints] = useState({} as Polygon | Circle);
  const [shapeType, setShapeType] = useState({} as GeofenceShapeEnum);

  useEffect(() => {
    document.title =
      t("admin.geofences.editViewCreate.createGeofence") + " - Admin";
  }, []);

  return (
    <>
      <PageFilters>
        <div className="col col-16">
          <CreateGeofenceSaveBar
            shapeType={shapeType}
            drawedPoints={drawedPoints}
          />
        </div>
      </PageFilters>
      <PageContent>
        <div className="col">
          <div className="cg-map-functionalities">
            <GeofenceMap
              id={0}
              googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY!}
              zoom={6}
              latitude={41.9027835}
              longitude={12.4963655}
              getDrawedPoints={setDrawedPoints}
              getShapeType={setShapeType}
            />
          </div>
        </div>
      </PageContent>
    </>
  );
};
export default CreateGeofence;
