import dayjs from "dayjs";
import { t } from "i18next";
import _ from "lodash";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useMatch, useSearchParams } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { store } from "../../app/store";
import { DropdownButton } from "../../ui/Button/DropdownButton";
import { Dropdown } from "../../ui/Dropdown/Dropdown";
import { Option } from "../../ui/Forms/ChildDropdown";
import { DatePickerDropdown } from "../../ui/Forms/DatePickerDropdown";
import { EventsDropdown } from "../../ui/Forms/EventsDropdown";
import Form from "../../ui/Forms/Form";
import SearchField from "../../ui/Forms/SearchField";
import { IconCalendar } from "../../ui/Icon/Line/Calendar";
import { IconGasStation } from "../../ui/Icon/Line/GasStation";
import { IconLogs } from "../../ui/Icon/Line/Logs";
import { IconSpeed } from "../../ui/Icon/Line/Speed";
import { getQueryString } from "../../utils/Utils";
import { Event, eventsSelectors, getEventsAsync } from "../event/eventsSlice";
import {
  fleetViewsSelectors,
  getFleetViewsAsync,
} from "../fleet/fleetViewsSlice";
import {
  RouteHistoryView,
  routesHistoryViewSelectors,
} from "../route/routesHistoryViewSlice";
import { Preferences } from "../users/preference/preferencesSlice";
import UserContext from "../users/userContext";
import "./VehiclesLocationHistoryFilterBar.css";
import { VehicleView, vehiclesViewSelectors } from "./vehiclesViewSlice";

interface QueryParams {
  [paramName: string]: any;
}

interface RouteType {
  key: "" | "STOP" | "TRACK";
  label: string;
}

const routeTypeValues: RouteType[] = [
  {
    key: "",
    label: t("locationHistory.tracksStopDropdown.tracks/stops"),
  },
  {
    key: "TRACK",
    label: t("locationHistory.tracksStopDropdown.tracks"),
  },
  {
    key: "STOP",
    label: t("locationHistory.tracksStopDropdown.stops"),
  },
];

const eventsSeverity = [
  { id: 0, severity: "Warning" },
  { id: 1, severity: "Info" },
  { id: 2, severity: "Alarm" },
];

interface DropDownItem {
  label: string;
  value: string;
  checked: boolean;
}

interface VehiclesLocationHistoryFilterBarProps {
  aliasFromLocation: string;
  callback: (buildQueryParam: string) => any;
  vehicleSearch: (searchKey: string) => any;
  selectedEventsFromDropdown?: (events: any) => any;
}

export const VehiclesLocationHistoryFilterBar: React.FC<VehiclesLocationHistoryFilterBarProps> =
  ({
    callback,
    aliasFromLocation,
    vehicleSearch,
    selectedEventsFromDropdown,
  }) => {
    const [selectedRouteTypeValues, setSelectedRouteTypeValues] =
      useState<RouteType>(routeTypeValues[0]);
    const [justReloaded, setJustReloaded] = useState(true);
    const [dateRange, setDateRange] = useState([] as string[]);
    const [alias, setAlias] = useState("");
    const [executeQuery, setExecuteQuery] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [vehicleId, setVehicleId] = useState(-1);
    const [isDateSelected, setIsDateSelected] = useState(false);
    const searchbarRef = useRef<HTMLHeadingElement>(null);
    const parentRef = useRef<HTMLHeadingElement>(null);
    const vehicles: VehicleView[] = useAppSelector(
      vehiclesViewSelectors.selectAll
    );

    const [isAliasFromOutside, setIsAliasFromOutside] = useState(false);

    const [preferencesContext]: [Preferences] = useContext(UserContext);

    const [eventList, setEventList] = useState<Event[]>([]);
    const eventsFromRedux = eventsSelectors.selectAll(store.getState());
    const [selectedEvents, setSelectedEvents] = useState<Event[]>([]);
    const [eventsOptions, setEventsOptions] = useState<any>();

    const queryParamsRef = useRef<QueryParams>({});
    let queryParams: QueryParams = queryParamsRef.current;

    const tracksRoute = useMatch(`/dashboard/vehicles/location-history/tracks`);
    const trackRoute = useMatch(
      "/dashboard/vehicles/location-history/track/:trackId"
    );
    const stopRoute = useMatch(
      "/dashboard/vehicles/location-history/stop/:stopId"
    );
    let isTracksRoute = tracksRoute !== null;
    let isTrackRoute = trackRoute !== null;
    let isStopRoute = stopRoute !== null;
    const isTrackRouteOrTracksRoute = trackRoute || stopRoute || isTracksRoute;

    const trackOrStopIdFromRoute =
      trackRoute?.params?.trackId ?? stopRoute?.params?.stopId;
    const routesHistoryView: RouteHistoryView =
      useAppSelector((state) =>
        routesHistoryViewSelectors.selectById(
          state,
          Number(trackOrStopIdFromRoute) ?? -1
        )
      ) ?? ({} as RouteHistoryView);

    // In order to pre-load events and fleets this dispatch must be initially invoked.
    useEffect(() => {
      store.dispatch(getFleetViewsAsync());
      store.dispatch(getEventsAsync());
    }, []);

    useEffect(() => {
      if (aliasFromLocation !== undefined) {
        setAlias(aliasFromLocation);
        setIsAliasFromOutside(true);
      }
    }, [aliasFromLocation]);

    let vehiclesGroupByFleet = vehicles.reduce(
      (group: any, vehicle: VehicleView) => {
        const { fleet } = vehicle;
        const fleetName = fleetViewsSelectors.selectById(
          store.getState(),
          fleet
        )?.name;
        if (fleetName) {
          group[fleetName] = group[fleetName] ?? [];
          group[fleetName].push(vehicle);
        }
        return group;
      },
      {}
    );

    let fleetNames = Object.keys(vehiclesGroupByFleet);

    /**
     * When the page is reloaded, the requested vehicle alias is populated.
     */
    useEffect(() => {
      if (vehicleId !== -1 && vehicles.length && justReloaded) {
        const aliasVehicle = vehicles.find(
          (vehicle) => vehicle.id === Number(vehicleId)
        );
        setAlias(aliasVehicle?.alias ?? "");
        setJustReloaded(false);
      }
    }, [vehicleId, vehicles, justReloaded]);

    /**
     * This useEffect listens to isOpen state in order to call the handleChanges
     * with the selected vehicle id.
     */
    useEffect(() => {
      const map = new Map();
      let filteredVehicle: VehicleView[];
      if (!isOpen || isAliasFromOutside) {
        setIsAliasFromOutside(false);
        filteredVehicle = vehicles?.filter(
          (vehicle: VehicleView) => alias === vehicle.alias
        );
        if (filteredVehicle?.length > 0) {
          if (vehicleId !== filteredVehicle[0].id) {
            setVehicleId(filteredVehicle[0].id);
            map.set("vehicle.id", String(filteredVehicle[0].id));
            handleChanges(map);
          }
        }
      }
    }, [isOpen, isAliasFromOutside]);

    // Handle routes
    const location = useLocation();
    const [searchParams] = useSearchParams();

    /**
     * This use effect executes every time the location changes.
     * It retrieves the query params from the location (from route or from state).
     */
    useEffect(() => {
      const map = new Map();
      const currentSearchParams =
        searchParams.toString() !== "" ? searchParams : null;
      if (!!currentSearchParams && _.isEmpty(queryParams)) {
        // Retrieving the selected range
        const currentSearchParamsPeriod: string[] =
          currentSearchParams.getAll("firstPointDate");
        const periods: string[] = [];
        let startPeriod: string = "";
        let endPeriod: string = "";
        // If there are two selected dates then the range is already defined
        if (currentSearchParamsPeriod && currentSearchParamsPeriod.length > 1) {
          setIsDateSelected(true);
          startPeriod = currentSearchParamsPeriod[0];
          endPeriod = currentSearchParamsPeriod[1];
        }
        // If there is one selected date then add as start date the midnight of the already defined date
        if (
          currentSearchParamsPeriod &&
          currentSearchParamsPeriod.length === 1
        ) {
          setIsDateSelected(true);
          endPeriod = dayjs(new Date(currentSearchParamsPeriod[0])).format(
            "YYYY/MM/DD HH:mm"
          );
          const startPeriodDate = new Date(endPeriod);
          startPeriodDate.setHours(0, 0, 0, 0);
          startPeriod = dayjs(startPeriodDate).format("YYYY/MM/DD HH:mm");
        }
        // If there are no selected dates then add as range the current date from the midnight
        if (
          !currentSearchParamsPeriod ||
          currentSearchParamsPeriod.length === 0
        ) {
          setIsDateSelected(true);
          endPeriod = dayjs().format("YYYY/MM/DD HH:mm");
          const startPeriodDate = new Date(endPeriod);
          startPeriodDate.setHours(0, 0, 0, 0);
          startPeriod = dayjs(startPeriodDate).format("YYYY/MM/DD HH:mm");
        }
        periods.push(startPeriod);
        periods.push(endPeriod);
        map.set("firstPointDate", periods);
        setDateRange(periods);

        // Retrieving the route state type
        const routeStateTypes =
          currentSearchParams.getAll("routeStateTypeEnum");
        if (!!routeStateTypes) {
          let routeTypeValuesFromRoute: RouteType[] = [];
          routeStateTypes.forEach((routeStateType, index) => {
            const routeTypeValue = routeTypeValues.find(
              (routeTypeValue) => routeTypeValue.key === routeStateType
            );
            if (routeTypeValue) {
              routeTypeValuesFromRoute.push(routeTypeValue);
            }
          });
          if (routeTypeValuesFromRoute) {
            routeTypeValuesFromRoute = _.uniqBy(
              routeTypeValuesFromRoute,
              (x) => x.key
            );
            if (routeTypeValuesFromRoute.length === 1) {
              setSelectedRouteTypeValues(routeTypeValuesFromRoute[0]);
              map.set(
                "routeStateTypeEnum",
                routeTypeValuesFromRoute.map((x) => x.key)
              );
            } else {
              setSelectedRouteTypeValues(routeTypeValues[0]);
            }
          }
        }

        // Retrieving the vehicle identifier
        const vehicle = currentSearchParams.get("vehicle.id");
        if (!!vehicle) {
          setJustReloaded(true);
          map.set("vehicle.id", vehicle);
          setVehicleId(Number(vehicle));
        }
      }
      // This "if" is useful to put the current date when no dates are selected (it works when the user starts the navigation from the location-history)
      if (map.size === 0 && _.isEmpty(queryParams)) {
        setIsDateSelected(true);
        const endPeriod = dayjs().format("YYYY/MM/DD HH:mm");
        const startPeriodDate = new Date(endPeriod);
        startPeriodDate.setHours(0, 0, 0, 0);
        const startPeriod = dayjs(startPeriodDate).format("YYYY/MM/DD HH:mm");
        map.set("firstPointDate", [startPeriod, endPeriod]);
        setDateRange([startPeriod, endPeriod]);
      }

      // This if will be enabled when there are no params and the internal state should be cleaned
      if (!currentSearchParams && !_.isEmpty(queryParams)) {
        map.set("vehicle.id", "");
        setVehicleId(-1);
        map.set("routeStateTypeEnum", []);
      }

      if (map.size > 0) {
        handleChanges(map);
      }
    }, [location]);

    useEffect(() => {
      function handleClickOutside(event: any) {
        if (
          searchbarRef.current &&
          !searchbarRef.current.contains(event.target) &&
          parentRef.current &&
          !parentRef.current.contains(event.target)
        )
          setIsOpen(false);
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [searchbarRef]);

    const handleChanges = (params: Map<string, string[] | string>): void => {
      if (!!params) {
        params.forEach((value, key) => {
          if (value != undefined && value != null && value.length > 0) {
            queryParams[key] = value;
            setExecuteQuery(true);
          } else {
            if (queryParams.hasOwnProperty(key)) {
              delete queryParams[key];
              setExecuteQuery(true);
            }
          }
        });
      }
    };

    useEffect(() => {
      if (executeQuery && vehicleId !== -1 && isDateSelected) {
        const queryString = getQueryString(queryParams);
        callback(queryString);
        setExecuteQuery(false);
      }
    }, [vehicleId, isDateSelected, executeQuery]);

    // This useEffect set as initial checked value in Events dropdown filter the STOP event and create the Option structure as well.
    useEffect(() => {
      if (!_.isEmpty(eventsFromRedux)) {
        if (selectedEvents.length === 0) {
          setSelectedEvents(eventsFromRedux ? eventsFromRedux : []);
        }
        const eventsOptions: Option[] = eventsSeverity?.map(
          (eventSeverity: { id: number; severity: string }) => {
            const { severity } = eventSeverity;
            let eventsFromReduxBySeverity = eventsFromRedux.filter(
              (event) =>
                event.severity === severity.toUpperCase() &&
                event.name !== "IGNITION_KEY_ON" &&
                event.name !== "IGNITION_KEY_OFF" &&
                event.name !== "START" &&
                event.name !== "END"
            );

            let color = "";
            if (eventSeverity.id === 0) {
              color = "#fbcd03";
            }
            if (eventSeverity.id === 1) {
              color = "#0052bd";
            }
            if (eventSeverity.id === 2) {
              color = "#ff4f48";
            }
            const eventOptions = {
              label: eventSeverity.severity,
              hasCheckbox: true,
              hasDropdown: true,
              hasCount: true,
              color: color,
              events: eventsFromReduxBySeverity,
            } as Option;
            return eventOptions;
          }
        );
        setEventsOptions(eventsOptions);
      }
    }, [eventsFromRedux]);

    const reportDropDownList = [
      {
        id: 0,
        title: t("locationHistory.filterBar.buttonDropdown.fuel"),
        icon: <IconGasStation size={14} color="--global-colors-ink-light" />,
        onClick: () => {
          const startPeriod = queryParams["firstPointDate"]?.[0] ?? "";
          const endPeriod = queryParams["firstPointDate"]?.[1] ?? "";
          const vehicleIds = queryParams["vehicle.id"] ?? "";
          let genericStartPeriod = dayjs
            .utc(new Date(startPeriod))
            .format("YYYY-MM-DDTHH:mm");
          let genericEndPeriod = dayjs
            .utc(new Date(endPeriod))
            .format("YYYY-MM-DDTHH:mm");
          if (trackRoute || stopRoute) {
            genericStartPeriod = dayjs
              .utc(
                new Date(routesHistoryView.firstPosition.gpsPositionTimestamp)
              )
              .format("YYYY-MM-DDTHH:mm");
            genericEndPeriod = dayjs
              .utc(
                new Date(routesHistoryView.lastPosition.gpsPositionTimestamp)
              )
              .format("YYYY-MM-DDTHH:mm");
          }
          window.open(
            `/reports/refuelling-report/${vehicleIds}${getQueryString({
              startPeriod: genericStartPeriod,
              endPeriod: genericEndPeriod,
            })}`,
            "_blank",
            "noopener,noreferrer"
          );
        },
      },
      {
        id: 1,
        title: t("locationHistory.filterBar.buttonDropdown.speed"),
        icon: <IconSpeed size={14} color="--global-colors-ink-light" />,
        onClick: () => {
          const startPeriod = queryParams["firstPointDate"]?.[0] ?? "";
          const endPeriod = queryParams["firstPointDate"]?.[1] ?? "";
          const vehicleIds = queryParams["vehicle.id"] ?? "";
          let genericStartPeriod = dayjs
            .utc(new Date(startPeriod))
            .format("YYYY-MM-DDTHH:mm");
          let genericEndPeriod = dayjs
            .utc(new Date(endPeriod))
            .format("YYYY-MM-DDTHH:mm");
          if (trackRoute || stopRoute) {
            genericStartPeriod = dayjs
              .utc(
                new Date(routesHistoryView.firstPosition.gpsPositionTimestamp)
              )
              .format("YYYY-MM-DDTHH:mm");
            genericEndPeriod = dayjs
              .utc(
                new Date(routesHistoryView.lastPosition.gpsPositionTimestamp)
              )
              .format("YYYY-MM-DDTHH:mm");
          }
          window.open(
            `/reports/gps-data${getQueryString({
              startPeriod: genericStartPeriod,
              endPeriod: genericEndPeriod,
              vehicleIds: vehicleIds,
              routeType: stopRoute ? "STOP" : "TRACK",
              openSpeedGraph: true,
            })}`,
            "_blank",
            "noopener,noreferrer"
          );
        },
      },
    ];

    const filterVehicle = (vehicle: VehicleView) => {
      const filterVehicle =
        alias == "" ||
        (vehicle?.alias &&
          vehicle?.alias?.toLowerCase().includes(alias.toLowerCase())) ||
        (vehicle?.plate &&
          vehicle?.plate?.toLowerCase().includes(alias.toLowerCase())) ||
        (vehicle?.id &&
          vehicle?.id?.toString()?.toLowerCase().includes(alias.toLowerCase()));
      return filterVehicle;
    };

    return (
      <div className="vehicle-lh-filter-row">
        <div className="vehicle-lh-search">
          <div onClick={() => setIsOpen(true)} ref={parentRef}>
            <Form>
              <SearchField
                name="search"
                id="search-field"
                size="small"
                placeholder={t(
                  "locationHistory.searchDropdown.placeholderVehicle"
                )}
                value={alias}
                onChange={(val: string) => {
                  vehicleSearch && vehicleSearch(val);
                  setAlias(val);
                }}
              />
            </Form>
          </div>
          {isOpen && alias.length >= 1 && vehicles.length > 0 && (
            <div className="lh-search-results" ref={searchbarRef}>
              {fleetNames?.map((element: string, idx: number) => {
                return (
                  <React.Fragment key={idx}>
                    <div className="lh-fleet-label">
                      {element}
                      {` (${
                        vehiclesGroupByFleet[element].filter(filterVehicle)
                          .length
                      })`}{" "}
                    </div>
                    {vehiclesGroupByFleet[element]
                      .filter(filterVehicle)
                      .map((vehicle: VehicleView, index: number) => (
                        <div
                          key={index}
                          className={
                            alias === vehicle.alias
                              ? "lh-vehicle-alias-selected"
                              : "lh-vehicle-alias"
                          }
                          onClick={() => {
                            setIsOpen(false);
                            setAlias(vehicle.alias);
                          }}
                        >
                          {vehicle.alias}
                        </div>
                      ))}
                  </React.Fragment>
                );
              })}
            </div>
          )}
        </div>
        <div className="vehicle-lh-date-picker">
          <Form>
            <DatePickerDropdown
              setDate={(val: any[]) => {
                const map = new Map();
                const period = [];
                if (val[1]) {
                  val[0] && period.push(val[0].format("YYYY/MM/DD HH:mm"));
                  val[1] && period.push(val[1].format("YYYY/MM/DD HH:mm"));
                } else {
                  period.push(
                    val[0]
                      ?.set("hour", 0)
                      ?.set("minute", 0)
                      .format("YYYY/MM/DD HH:mm")
                  );
                  period.push(
                    val[0]
                      ?.set("hour", 23)
                      ?.set("minute", 59)
                      .format("YYYY/MM/DD HH:mm")
                  );
                }
                map.set("firstPointDate", period);
                setDateRange(period);
                handleChanges(map);
              }}
              localeFormat={preferencesContext.localeFormat ?? "DD / MM / YYYY"}
              initialValue={dateRange.map((date) => new Date(date))}
              defaultValue={new Date()}
              language={preferencesContext.language ?? "it"}
              icon={
                <IconCalendar size={12} color="--global-colors-ink-light" />
              }
              dateRange={[dayjs().subtract(1, "year"), dayjs()]} //From today to past 1 year
              limitDaysRange={7}
              hasTime={true}
            />
          </Form>
        </div>
        <div className="vehicle-lh-tracks-stops">
          <Dropdown
            placeholder={""}
            size={"small"}
            onChange={(values: RouteType[]) => {
              const map = new Map();
              const routeTypes: string[] = [];
              values.forEach((value) => {
                const routeTypeValue = routeTypeValues.find(
                  (routeTypeValue) => routeTypeValue.key === value.key
                )?.key;
                if (routeTypeValue) {
                  routeTypes.push(routeTypeValue);
                }
              });
              map.set("routeStateTypeEnum", routeTypes);
              handleChanges(map);
            }}
            value={selectedRouteTypeValues}
            itemAttribute="label"
            options={routeTypeValues}
          />
        </div>
        {(isTracksRoute || isTrackRoute || isStopRoute) && (
          <div className="dropdown-events-filter-row">
            <div className="dropdown-events-filter-vehicles-dropdown">
              {eventsFromRedux && eventsOptions && (
                <EventsDropdown
                  options={eventsOptions}
                  loading={false}
                  getValueSelected={(values: Event[]) => {
                    if (!_.isEqual(values, eventList)) {
                      const map = new Map();
                      if (values && values?.length > 0) {
                        setEventList(values);
                        selectedEventsFromDropdown &&
                          selectedEventsFromDropdown(values);
                      } else {
                        setEventList([]);
                        selectedEventsFromDropdown &&
                          selectedEventsFromDropdown([]);
                      }
                      handleChanges(map);
                    }
                  }}
                  valueSelected={selectedEvents}
                />
              )}
            </div>
          </div>
        )}
        {isTrackRouteOrTracksRoute && (
          <div className="vehicle-lh-speed-btn">
            <DropdownButton
              aspect="secondary"
              size="small"
              label={t("locationHistory.filterBar.buttonDropdown.report")}
              list={reportDropDownList}
            >
              <IconLogs size={14} color="--global-colors-ink-ink" />
            </DropdownButton>
          </div>
        )}
      </div>
    );
  };
