import dayjs from "dayjs";
import html2canvas from "html2canvas";
import { t } from "i18next";
import jsPDF from "jspdf";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../../app/hooks";
import { store } from "../../../app/store";
import PageFilters from "../../../layout/PageFilters";
import { Button } from "../../../ui/Button/Button";
import { ButtonList } from "../../../ui/Button/ButtonList";
import { DropdownButton } from "../../../ui/Button/DropdownButton";
import { DatePickerDropdown } from "../../../ui/Forms/DatePickerDropdown";
import { Tooltip } from "../../../ui/Forms/Tooltip";
import { IconArrowLeft } from "../../../ui/Icon/Line/ArrowLeft";
import { IconArrowRight } from "../../../ui/Icon/Line/ArrowRight";
import { IconCalendar } from "../../../ui/Icon/Line/Calendar";
import { IconCheck } from "../../../ui/Icon/Line/Check";
import { IconClose } from "../../../ui/Icon/Line/Close";
import { IconDown } from "../../../ui/Icon/Line/Down";
import { IconDownload } from "../../../ui/Icon/Line/Download";
import { IconEdit } from "../../../ui/Icon/Line/Edit";
import { IconGeofence } from "../../../ui/Icon/Line/Geofence";
import { IconGroup } from "../../../ui/Icon/Line/Group";
import { IconMenuRoutes } from "../../../ui/Icon/Line/MenuRoutes";
import { IconSync } from "../../../ui/Icon/Line/Sync";
import { IconBus } from "../../../ui/Icon/Solid/Bus";
import { IconDriver } from "../../../ui/Icon/Solid/Driver";
import { ModalBody } from "../../../ui/Modal/ModalBody";
import { ModalFooter } from "../../../ui/Modal/ModalFooter";
import { ModalWrapper } from "../../../ui/Modal/ModalWrapper";
import { ToastNotification } from "../../../utils/ToastNotification";
import { getMonthStringified, getQueryString } from "../../../utils/Utils";
import {
  Driver,
  driversSelectors,
  getFilteredDriversDetailsPaginationAsync,
} from "../../driver/driversSlice";
import {
  UserInfo,
  getUserInfoAsync,
  userInfoSelectors,
} from "../../users/usersInfoSlice";
import { Vehicle, vehiclesSelectors } from "../../vehicle/vehiclesSlice";
import { getVehiclesAsync } from "../../vehicle/vehiclesStatusSlice";
import {
  PublicRouteListView,
  getPublicRouteListViewAsync,
  publicRoutesListViewSelectors,
} from "../route/publicRouteViewSlice";
import {
  PublicRouteShift,
  getPublicRouteShiftsAsync,
  publicRouteShiftSelectors,
} from "../shift/publicRouteShiftSlice";
import { PublicRoutePlan } from "./PublicRoutePlan";
import "./PublicRoutePlanning.css";
import {
  PublicRoutePlanningRequest,
  PublicRoutePlanningType,
  publicRoutePlanningSelectors,
  selectPublicRoutePlanningStatus,
  updatePublicRoutePlanningsAsync,
} from "./publicRoutePlanningSlice";
import {
  PublicRoutePlanningSummary,
  getPublicRoutePlanningSummariesAsync,
  getPublicRoutePlanningSummaryAsync,
  publicRoutePlanningSummarySelectors,
} from "./publicRoutePlanningSummarySlice";
import {
  PublicRoutePreset,
  getPublicRoutePresetsAsync,
  publicRoutePresetEmptyState,
  publicRoutePresetSelectors,
} from "./publicRoutePresetSlice";

export const PublicRoutePlanning = () => {
  /* SLICE REGION */

  const drivers: Driver[] = useAppSelector(driversSelectors.selectAll);
  const vehicles: Vehicle[] = useAppSelector(vehiclesSelectors.selectAll);

  const routes: PublicRouteListView[] = useAppSelector(
    publicRoutesListViewSelectors.selectAll
  );
  const shifts: PublicRouteShift[] = useAppSelector(
    publicRouteShiftSelectors.selectAll
  );

  const publicRoutePreset: PublicRoutePreset[] = useAppSelector((state: any) =>
    publicRoutePresetSelectors.selectAll(state).map((e: PublicRoutePreset) => {
      const newEndRange = new Date(e.endRange);
      newEndRange.setUTCHours(newEndRange.getUTCHours() - 2);
      return { ...e, endRange: newEndRange };
    })
  );

  /* END SLICE REGION */

  const downloadPdf = () => {
    const element = document.querySelector(
      ".public-route-plan-container"
    ) as HTMLElement;

    if (!element) {
      console.error(
        "Element with class 'public-route-plan-container' not found."
      );
      return;
    }

    // Salva l'altezza originale e rimuovi temporaneamente il limite di altezza
    const originalHeight = element.style.height;
    const originalOverflow = element.style.overflow;
    element.style.height = "auto";
    element.style.overflow = "visible";

    html2canvas(element, { scrollY: -window.scrollY }).then((canvas) => {
      const pdf = new jsPDF("p", "mm", "a4");
      const imgData = canvas.toDataURL("image/png");
      const imgWidth = 210; // larghezza A4 in mm
      const pageHeight = pdf.internal.pageSize.getHeight();
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      let position = 0;

      pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft > 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      // Salva il PDF con il nome desiderato
      pdf.save(
        t("publicRoutePlanning.export.pdf")
          .replace("{1}", "mtm")
          .replace("{2}", dateRange[0])
          .replace("{3}", dateRange[1]) + ".pdf"
      );

      // Ripristina l'altezza originale e il limite di overflow
      element.style.height = originalHeight;
      element.style.overflow = originalOverflow;
    });
  };

  /* HEADER VARIABLES REGION */

  const list = [
    // {
    //   id: 0,
    //   title: t("report.filterBar.downloadExcel"),
    //   icon: true ? (
    //     <IconDownload size={14} color="--global-colors-ink-light" />
    //   ) : (
    //     <div className="details-driverReport-download-spinner"></div>
    //   ),
    //   onClick: () => {
    //     // downloadResult(false);
    //   },
    // },
    {
      id: 0,
      title: t("report.filterBar.downloadPDF"),
      icon: true ? (
        <IconDownload size={14} color="--global-colors-ink-light" />
      ) : (
        <div className="details-driverReport-download-spinner"></div>
      ),
      onClick: () => {
        downloadPdf();
      },
    },
    // {
    //   id: 2,
    //   title: t("report.filterBar.sendReportEmail"),
    //   icon: <IconLocatorOff size={14} color="--global-colors-ink-light" />,
    //   onClick: () => {
    //     // sendReportEmail();
    //   },
    // },
  ];

  const today = dayjs().day(1).format("YYYY/MM/DD");
  const endToday = dayjs().day(7).format("YYYY/MM/DD");
  const [dateRange, setDateRange] = useState<string[]>([today, endToday]);
  const [dateRangeFromCopy, setDateRangeFromCopy] = useState<string[]>([
    "",
    "",
  ]);
  const [
    planningsInitializedWithDbPlannings,
    setPlanningsInitializedWithDbPlannings,
  ] = useState<PublicRoutePlanningRequest[][]>([]);

  const summaries: PublicRoutePlanningSummary[] = useAppSelector(
    publicRoutePlanningSummarySelectors.selectAll
  );
  const [summary, setSummary] = useState<PublicRoutePlanningSummary>(
    {} as PublicRoutePlanningSummary
  );
  const plannings: PublicRoutePlanningType[] = useAppSelector(
    publicRoutePlanningSelectors.selectAll
  ).filter(
    (planning: PublicRoutePlanningType) =>
      planning.publicRoutePlanningSummaryId === summary?.id
  );
  const publicRoutePlanningStatus = useAppSelector(
    selectPublicRoutePlanningStatus
  );
  let account: UserInfo =
    useAppSelector((state) =>
      userInfoSelectors.selectById(state, summary?.lastUpdateUserId)
    ) ?? ({} as UserInfo);

  const [copyModal, setCopyModal] = useState(false);
  const [elementsCopied, setElementsCopied] = useState<
    PublicRoutePlanningType[]
  >([]);

  const [summaryCopied, setSummaryCopied] =
    useState<PublicRoutePlanningSummary>({} as PublicRoutePlanningSummary);
  /* END HEADER VARIABLES REGION */

  /* FILTERBAR VARIABLES REGION */

  const [undoButton, setUndoButton] = useState(false);
  const [cleanButton, setCleanButton] = useState(0);
  const [cleaned, setCleaned] = useState(false);

  const [statusPlanning, setStatusPlanning] = useState(
    summary?.status ?? "TO_COMPILE"
  );
  /* END FILTERBAR VARIABLES REGION */

  const [planningsToUpdate, setPlanningsToUpdate] = useState<
    PublicRoutePlanningRequest[]
  >([]);

  /* FUNCTION REGION */

  const formatDate = (date: Date): string => {
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2); // Mesi da 0 a 11
    const day = ("0" + date.getDate()).slice(-2);
    const hours = ("0" + date.getHours()).slice(-2);
    const minutes = ("0" + date.getMinutes()).slice(-2);
    const seconds = ("0" + date.getSeconds()).slice(-2);

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.000Z`;
  };

  function paramDateBuilderAndCall() {
    let stDate = new Date(dateRange[0]);
    stDate.setHours(0, 0, 0, 0);
    let enDate = new Date(dateRange[1]);
    enDate.setHours(23, 59, 59, 999);
    let params =
      "?startPeriod=" +
      encodeURIComponent(formatDate(stDate)) +
      "&endPeriod=" +
      encodeURIComponent(formatDate(enDate));
    store.dispatch(getPublicRoutePlanningSummaryAsync({ queryParams: params }));
  }

  function copier() {
    let planningsWithElementsCopied =
      planningsInitializedWithDbPlannings?.flatMap((driverElements) =>
        driverElements.map((element) => {
          let temp = elementsCopied?.find((e) => {
            try {
              return (
                e.driverId === element?.driverId &&
                e.name === element?.name &&
                new Date(e.date).getDay() === element?.date.getDay()
              );
            } catch (error) {}
            return false;
          });
          return {
            id: element.id,
            name: element.name,
            driverId: element.driverId,
            vehicleId: temp?.vehicleId ?? 0,
            publicRouteId: temp?.publicRouteId ?? 0,
            planningStatusEnum: temp?.planningStatusEnum ?? "ON_DUTY",
            date: element.date,
            shiftTimeRangeId: temp?.shiftTimeRangeId ?? 0,
          } as PublicRoutePlanningRequest;
        })
      );

    let tempStartDate = new Date(dateRange[0]);
    let tempEndDate = new Date(dateRange[1]);

    store
      .dispatch(
        updatePublicRoutePlanningsAsync({
          objs: planningsWithElementsCopied.map((e) => {
            let date = new Date(e.date);
            date.setUTCHours(date.getUTCHours() + 2);
            return { ...e, date: date };
          }),
          status: summaryCopied.status,
          startDate: encodeURIComponent(formatDate(tempStartDate)),
          endDate: encodeURIComponent(formatDate(tempEndDate)),
        })
      )
      .then((response) => {
        if (response.meta.requestStatus === "fulfilled") {
          ToastNotification({
            toastId: "notificationPlanningSaved",
            status: "success",
            title: t(
              "publicRoutePlanning.responses.copySavedSuccessfully.title"
            ),
            description: t(
              "publicRoutePlanning.responses.copySavedSuccessfully.description"
            )
              .replace("*week1.0", dateRangeFromCopy[0])
              .replace("*week1.1", dateRangeFromCopy[1])
              .replace("*week2.0", dateRange[0])
              .replace("*week2.1", dateRange[1]),
          });
          setCleaned(true);
          setDateRangeFromCopy(["", ""]);
          setElementsCopied([]);
          setSummaryCopied({} as PublicRoutePlanningSummary);
          paramDateBuilderAndCall();
          store.dispatch(getPublicRoutePlanningSummariesAsync());
        }
      });
  }

  /* END FUNCTION REGION */

  /* USE EFFECT REGION */

  useEffect(() => {
    store.dispatch(
      getFilteredDriversDetailsPaginationAsync({
        queryParams: getQueryString({ status: "ACTIVE" }),
      })
    );
    store.dispatch(getVehiclesAsync());
    store.dispatch(getPublicRouteShiftsAsync());
    store.dispatch(getPublicRouteListViewAsync());
    store.dispatch(getPublicRoutePlanningSummariesAsync());
  }, []);

  useEffect(() => {
    paramDateBuilderAndCall();
  }, [dateRange]);

  useEffect(() => {
    if (dateRange.length > 0 && summaries.length > 0) {
      const tempSummary = summaries.find(
        (summary) =>
          new Date(summary.startDate).getDate() ===
            new Date(dateRange[0]).getDate() &&
          new Date(summary.startDate).getMonth() ===
            new Date(dateRange[0]).getMonth() &&
          new Date(summary.startDate).getFullYear() ===
            new Date(dateRange[0]).getFullYear()
      );
      if (tempSummary) {
        setSummary(tempSummary);
        setStatusPlanning(tempSummary.status);
      } else {
        setSummary({} as PublicRoutePlanningSummary);
        setStatusPlanning("TO_COMPILE");
      }
    }
  }, [dateRange, summaries]);

  useEffect(() => {
    if (summary?.lastUpdateUserId && summary?.lastUpdateUserId !== account.id) {
      if (
        !userInfoSelectors.selectById(
          store.getState(),
          summary?.lastUpdateUserId
        )
      ) {
        store.dispatch(getUserInfoAsync({ id: summary?.lastUpdateUserId }));
      }
    }
  }, [summary]);

  useEffect(() => {
    document.title = t("publicRoutePlanning.planning") + " - Admin";
    store.dispatch(getPublicRoutePresetsAsync());
    return () => {
      store.dispatch(publicRoutePresetEmptyState());
    };
  }, []);

  /* END USE EFFECT REGION */

  return (
    <>
      {/* HEADER REGION */}
      <PageFilters>
        <div className="col col-16">
          <div className="public-route-planning-subtopbar">
            <div className="public-route-planning-subsection-name">
              {t("publicRoutePlanning.planning")}
            </div>
            <div
              className="public-route-planning-export"
              style={{
                display: "flex",
                marginLeft: "auto",
                width: "fit-content",
              }}
            >
              {!_.isEqual(dateRange, dateRangeFromCopy) &&
              elementsCopied.length > 0 ? (
                <>
                  <Button
                    size="small"
                    aspect="primary"
                    isLoading={publicRoutePlanningStatus === "loading"}
                    label={t("common.paste")}
                    onClick={() => {
                      if (plannings.length > 0) {
                        setCopyModal(!copyModal);
                      } else {
                        copier();
                      }
                    }}
                  >
                    <IconGroup size={14} color="white" />
                  </Button>
                  <Button
                    size="small"
                    aspect="secondary"
                    label={t("common.deleteCopy")}
                    onClick={() => (
                      setDateRangeFromCopy(["", ""]),
                      setElementsCopied([]),
                      setSummaryCopied({} as PublicRoutePlanningSummary)
                    )}
                  >
                    <IconClose size={14} color="--global-colors-ink-ink" />
                  </Button>
                </>
              ) : (
                <Button
                  size="small"
                  aspect="secondary"
                  disabled={
                    elementsCopied.length === 0 &&
                    summary.status === "TO_COMPILE"
                  }
                  label={
                    elementsCopied.length > 0
                      ? t("common.copied")
                      : t("common.copy")
                  }
                  onClick={() =>
                    elementsCopied.length === 0 &&
                    (setElementsCopied(plannings),
                    setSummaryCopied(summary),
                    setDateRangeFromCopy(dateRange),
                    ToastNotification({
                      toastId: "notificationPlanningCopied",
                      status: "default",
                      title: t(
                        "navigation.publicRoutePlanning.responses.copiedSuccessfully.title"
                      ),
                      description: t(
                        "navigation.publicRoutePlanning.responses.copiedSuccessfully.description"
                      ),
                    }))
                  }
                >
                  {elementsCopied.length > 0 ? (
                    <IconCheck size={14} color="--global-colors-ink-ink" />
                  ) : (
                    <IconGeofence size={14} color="--global-colors-ink-ink" />
                  )}
                </Button>
              )}
              <div style={{ marginLeft: "10px" }}>
                <DropdownButton
                  size="small"
                  aspect="secondary"
                  disabled={false}
                  label={t("common.export")}
                  list={list}
                >
                  <IconDown size={14} color="--global-colors-ink-ink" />
                </DropdownButton>
              </div>
            </div>
          </div>
        </div>
      </PageFilters>
      {/* END HEADER REGION */}
      {/* SUBHEADER REGION */}

      <div className="public-route-planning-plan-selector-container">
        <div className="public-route-planning-plan-selector-right-section">
          <div style={{ display: "flex", height: "100%" }}>
            <div className="public-route-planning-switch-element-selected">
              Calendario
              <IconEdit size={12} color={"black"} />
            </div>
          </div>
          <div
            style={{ display: "flex", height: "100%", alignItems: "center" }}
          >
            <div className="public-route-planning-plan-selector-right-section-status">
              {!!summary?.lastUpdateDate && (
                <>
                  <Tooltip />
                  <span
                    style={{ whiteSpace: "nowrap", height: "20px" }}
                    data-for="tooltip"
                    data-tip={t("publicRoutePlanning.lastUpdateFrom")
                      .replace(
                        "*el1",
                        summary?.lastUpdateDate
                          ? new Date(summary?.lastUpdateDate)
                              .getDate()
                              .toString() +
                              " " +
                              t(
                                getMonthStringified(
                                  new Date(summary?.lastUpdateDate).getMonth()
                                )
                              )
                          : "--/--/--"
                      )
                      .replace(
                        "*el2",
                        summary?.lastUpdateDate
                          ? new Date(summary?.lastUpdateDate)
                              .getHours()
                              .toString() +
                              ":" +
                              new Date(summary?.lastUpdateDate).getMinutes()
                          : "--:--"
                      )
                      .replace(
                        "*el3",
                        account?.firstName && account?.secondName
                          ? account?.firstName + " " + account?.secondName
                          : "**** ****"
                      )}
                  >
                    <ButtonList status="update" buttonText={""} />
                  </span>
                  <Tooltip />
                </>
              )}
              <span
                style={{ height: "20px" }}
                data-for="tooltip"
                data-tip={t(
                  "publicRoutePlanning.summaryStatuses." + statusPlanning
                )}
              >
                <ButtonList
                  status={
                    statusPlanning === "TO_COMPILE"
                      ? "empty"
                      : statusPlanning === "TO_COMPLETE"
                      ? "pending"
                      : "enabled"
                  }
                  buttonText={""}
                />
              </span>
            </div>
            <span>
              <Button
                aspect="outline"
                onClick={() => {
                  if (dateRange.length === 2) {
                    const startDate = dayjs(dateRange[0]);
                    const endDate = dayjs(dateRange[1]);

                    const previousStartDate = startDate
                      .subtract(1, "week")
                      .format("YYYY/MM/DD");
                    const previousEndDate = endDate
                      .subtract(1, "week")
                      .format("YYYY/MM/DD");

                    setDateRange([previousStartDate, previousEndDate]);
                  }
                }}
                onlyIcon
                size="small"
                label={""}
              >
                <IconArrowLeft size={14} color="--global-colors-ink-light" />
              </Button>
              <Button
                aspect="outline"
                onClick={() => {
                  if (dateRange.length === 2) {
                    const startDate = dayjs(dateRange[0]);
                    const endDate = dayjs(dateRange[1]);
                    const nextStartDate = startDate
                      .add(1, "week")
                      .format("YYYY/MM/DD");
                    const nextEndDate = endDate
                      .add(1, "week")
                      .format("YYYY/MM/DD");

                    setDateRange([nextStartDate, nextEndDate]);
                  }
                }}
                onlyIcon
                size="small"
                label={""}
              >
                <IconArrowRight size={14} color="--global-colors-ink-light" />
              </Button>
            </span>
            <span style={{ marginRight: "10px" }}>
              <DatePickerDropdown
                open={elementsCopied.length > 0}
                initialValue={dateRange.map((date) => new Date(date))}
                styledTransparent={true}
                weekPresets={_.sortBy(publicRoutePreset, "startRange")}
                icon={
                  <div className="public-route-planning-calendar-icon-background">
                    <IconCalendar size={14} color="black" />
                  </div>
                }
                setDate={(val) => {
                  const map = new Map();
                  map.set("firstPointDate", [
                    val[0].format("YYYY/MM/DD"),
                    val[1]?.format("YYYY/MM/DD") ?? "",
                  ]);
                  const period = [];
                  if (val[0]) {
                    period.push(val[0].format("YYYY/MM/DD"));
                  }
                  if (val[1]) {
                    period.push(val[1].format("YYYY/MM/DD"));
                  }
                  setDateRange(period);
                }}
                hasDatePresets={true}
              />
            </span>
          </div>
        </div>
      </div>
      {/* END SUBHEADER REGION */}
      {shifts?.length > 0 &&
        vehicles?.length > 0 &&
        drivers?.length > 0 &&
        routes?.length > 0 && (
          <div className="public-route-planning-plan-summary-container">
            <span className="public-route-planning-plan-summary-title">
              {t("publicRoutePlanning.summary.details")}
            </span>
            <div className="public-route-planning-plan-summary-elements-container">
              <div className="public-route-planning-plan-summary-elements">
                <div className="public-route-planning-plan-summary-element">
                  <IconBus size={14} />
                  <div className="public-route-planning-plan-summary-element-formatted">
                    <span className="public-route-planning-plan-summary-element-formatted-textone">
                      {t("publicRoutePlanning.summary.vehicles")}
                    </span>
                    <span className="public-route-planning-plan-summary-element-formatted-texttwo">
                      {vehicles?.length}
                    </span>
                  </div>
                </div>
                <div className="public-route-planning-plan-summary-element">
                  <IconDriver size={14} />
                  <div className="public-route-planning-plan-summary-element-formatted">
                    <span className="public-route-planning-plan-summary-element-formatted-textone">
                      {t("publicRoutePlanning.summary.drivers")}
                    </span>
                    <span className="public-route-planning-plan-summary-element-formatted-texttwo">
                      {drivers?.length}
                    </span>
                  </div>
                </div>
                <div className="public-route-planning-plan-summary-element">
                  <IconMenuRoutes size={14} />
                  <div className="public-route-planning-plan-summary-element-formatted">
                    <span className="public-route-planning-plan-summary-element-formatted-textone">
                      {t("publicRoutePlanning.summary.routes")}
                    </span>
                    <span className="public-route-planning-plan-summary-element-formatted-texttwo">
                      {routes?.length}
                    </span>
                  </div>
                </div>
                <div className="public-route-planning-plan-summary-element">
                  <IconSync size={14} />
                  <div className="public-route-planning-plan-summary-element-formatted">
                    <span className="public-route-planning-plan-summary-element-formatted-textone">
                      {t("publicRoutePlanning.summary.shifts")}
                    </span>
                    <span className="public-route-planning-plan-summary-element-formatted-texttwo">
                      {shifts?.length}
                    </span>
                  </div>
                </div>
              </div>
              <div className="public-route-planning-plan-summary-buttons">
                {statusPlanning !== "TO_COMPILE" && (
                  <Button
                    aspect="outline"
                    size={"small"}
                    label={t("common.empty")}
                    onClick={() => setCleanButton(cleanButton + 1)}
                  />
                )}
                {planningsToUpdate.filter(
                  (e) =>
                    new Date(e.date) >=
                      new Date(new Date(dateRange[0]).setHours(0, 0, 0, 0)) &&
                    new Date(e.date) <=
                      new Date(new Date(dateRange[1]).setHours(23, 59, 59, 999))
                ).length > 0 &&
                  plannings?.length > 0 &&
                  !!summary && (
                    <Button
                      aspect="secondary"
                      size={"small"}
                      label={t("common.cancel")}
                      onClick={() => (
                        setUndoButton(true),
                        setStatusPlanning(summary.status ?? "TO_COMPILE")
                      )}
                    />
                  )}
                {planningsToUpdate.filter(
                  (e) =>
                    new Date(e.date) >=
                      new Date(new Date(dateRange[0]).setHours(0, 0, 0, 0)) &&
                    new Date(e.date) <=
                      new Date(new Date(dateRange[1]).setHours(23, 59, 59, 999))
                ).length > 0 && (
                  <Button
                    aspect="primary"
                    size={"small"}
                    label={t("common.save")}
                    onClick={() => {
                      let tempStartDate = new Date(dateRange[0]);
                      let tempEndDate = new Date(dateRange[1]);
                      tempStartDate.setHours(0, 0, 0, 0);
                      tempEndDate.setHours(23, 59, 59, 999);
                      store
                        .dispatch(
                          updatePublicRoutePlanningsAsync({
                            objs: planningsToUpdate
                              .filter(
                                (e) =>
                                  new Date(e.date) >= tempStartDate &&
                                  new Date(e.date) <= tempEndDate
                              )
                              .map((e) => {
                                const date = new Date(e.date);
                                date.setUTCHours(date.getUTCHours() + 2); // Aggiungi 2 ore
                                return {
                                  ...e,
                                  date: date,
                                };
                              }),
                            status: statusPlanning,
                            startDate: encodeURIComponent(
                              formatDate(tempStartDate)
                            ),
                            endDate: encodeURIComponent(
                              formatDate(tempEndDate)
                            ),
                          })
                        )
                        .then((response) => {
                          if (response.meta.requestStatus === "fulfilled") {
                            ToastNotification({
                              toastId: "notificationPlanningSaved",
                              status: "success",
                              title: t(
                                "publicRoutePlanning.responses.savedSuccessfully"
                              ),
                              description: "",
                            });
                            setCleaned(true);
                            _.isEmpty(summary) && window.location.reload();
                            if (
                              plannings.filter(
                                (e) =>
                                  (e.publicRouteId !== 0 &&
                                    e.publicRouteId !== null) ||
                                  e.planningStatusEnum !== "ON_DUTY"
                              ).length > 0
                            ) {
                              setStatusPlanning("TO_COMPLETE");
                            }
                          }
                        });
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        )}
      <div
        className="public-route-planning-plan-container"
        style={{ height: "100%" }}
      >
        {shifts?.length > 0 &&
          vehicles?.length > 0 &&
          drivers?.length > 0 &&
          routes?.length > 0 && (
            <PublicRoutePlan
              undoPlannings={undoButton || cleaned}
              undoPlanningsPostAction={() => {
                setUndoButton(false);
                setCleaned(false);
              }}
              startDate={new Date(dateRange[0])}
              endDate={new Date(dateRange[1])}
              shifts={shifts}
              routes={routes}
              drivers={drivers}
              vehicles={vehicles}
              plannings={plannings}
              previousStatus={statusPlanning}
              planningToUpdate={(e: PublicRoutePlanningRequest[]) => {
                setPlanningsToUpdate(e);
              }}
              cleanPlannings={cleanButton}
              planningStatus={(e) => setStatusPlanning(e)}
              planningsInitializedWithDbPlannings={(e) =>
                setPlanningsInitializedWithDbPlannings(e)
              }
            />
          )}
      </div>
      <ModalWrapper
        closeAction={() => setCopyModal(!copyModal)}
        open={copyModal}
      >
        <ModalBody
          title={t("publicRoutePlanning.copyModal.title")}
          desc={t("publicRoutePlanning.copyModal.description")}
        />
        <ModalFooter
          primaryLabel={t("common.confirm")}
          secondaryLabel={t("common.cancel")}
          primaryAction={() => {
            copier();
            setCopyModal(!copyModal);
          }}
          secondaryAction={() => {
            setCopyModal(!copyModal);
          }}
        />
      </ModalWrapper>
    </>
  );
};
