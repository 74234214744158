import dayjs from "dayjs";
import { t } from "i18next";
import _ from "lodash";
import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { store } from "../../app/store";
import { GTFleetErrorCodes } from "../../config/GTfleetErrorCodes";
import { Button } from "../../ui/Button/Button";
import { DropdownButton } from "../../ui/Button/DropdownButton";
import { Dropdown } from "../../ui/Dropdown/Dropdown";
import { Option } from "../../ui/Forms/ChildDropdown";
import { DatePickerDropdown } from "../../ui/Forms/DatePickerDropdown";
import { EventsDropdown } from "../../ui/Forms/EventsDropdown";
import Form from "../../ui/Forms/Form";
import SearchField from "../../ui/Forms/SearchField";
import { IconCalendar } from "../../ui/Icon/Line/Calendar";
import { IconDown } from "../../ui/Icon/Line/Down";
import { IconDownload } from "../../ui/Icon/Line/Download";
import { IconLocatorOff } from "../../ui/Icon/Line/LocatorOff";
import { ToastNotification } from "../../utils/ToastNotification";
import { getQueryString } from "../../utils/Utils";
import { Event, eventsSelectors, getEventsAsync } from "../event/eventsSlice";
import { getFleetViewsAsync } from "../fleet/fleetViewsSlice";
import ReportsRepository from "../report/reportRepository";
import { Preferences } from "../users/preference/preferencesSlice";
import { Preset, getPresetsAsync } from "../users/preset/presetsSlice";
import UserContext from "../users/userContext";
import "./LogFilterBar.css";
import {
  eventOutcomeEnumValues,
  getFilteredLogEventsPaginationAsync,
  logEventAreasValues,
  logEventsEmptyState,
  selectLogEventsSliceReasonCode,
  selectLogEventsSliceStatus,
} from "./logSlice";

interface QueryParams {
  [paramName: string]: any;
}
interface DropDownItem {
  label: string;
  value: string;
  checked: boolean;
}
interface TableColumnEntry {
  id: number;
  name: string;
  value: string;
}

interface LogEventFilterBarProps {
  callback: (buildQueryParam: string) => any;
  presets: any[];
  disabledButton: boolean;
}

const eventsSeverity = [
  { id: 0, severity: "Warning" },
  { id: 1, severity: "Info" },
  { id: 2, severity: "Alarm" },
];

const context = "logEvent";

export const LogFilterBar: React.FC<LogEventFilterBarProps> = ({
  callback,
  presets,
  disabledButton,
}) => {
  const navigate = useNavigate();
  const [actionLogEvent, setActionLogEvent] = useState("");
  const queryParamsRef = useRef<QueryParams>({});
  let queryParams: QueryParams = queryParamsRef.current;
  const eventsFromRedux = eventsSelectors.selectAll(store.getState());
  const [eventList, setEventList] = useState<Event[]>([]);
  const [selectedEvents, setSelectedEvents] = useState<Event[]>([]);

  const [preferencesContext]: [Preferences] = useContext(UserContext);
  const [eventsOptions, setEventsOptions] = useState<any>();

  const [isDisabledGenerate, setDisabledGenerate] = useState(true);
  const [isSelectedPeriod, setIsSelectedPeriod] = useState(false);
  const [isPdfGenerated, setIsPdfGenerated] = useState<boolean>(true);
  const [isExcelGenerated, setIsExcelGenerated] = useState<boolean>(true);
  const [queryString, setQueryString] = useState("");

  let defaultColumns: Preset = {} as Preset;
  let customColumns: TableColumnEntry[] = [];

  const logEventsStatus = useAppSelector(selectLogEventsSliceStatus);
  const logEventsReasonCode = useAppSelector(selectLogEventsSliceReasonCode);
  const reportsRepository = new ReportsRepository();

  if (!!presets && presets.length > 0) {
    defaultColumns = presets.filter((x) => x.name === "Default")[0];
    if (!!defaultColumns) {
      defaultColumns?.columns.forEach((column, index) =>
        customColumns.push({
          id: index,
          name: t("table.columns." + column),
          value: column,
        })
      );
    }
  }

  if (logEventsStatus === "failed" && logEventsReasonCode === "") {
    ToastNotification({
      toastId: "networkError",
      status: "error",
      description: t("common.networkError"),
    });
  }

  // This useEffect set as initial checked value in Events dropdown filter the STOP event and create the Option structure as well.
  useEffect(() => {
    if (!_.isEmpty(eventsFromRedux)) {
      if (selectedEvents.length === 0) {
        setSelectedEvents(eventsFromRedux ? eventsFromRedux : []);
      }
      const eventsOptions: Option[] = eventsSeverity?.map(
        (eventSeverity: { id: number; severity: string }) => {
          const { severity } = eventSeverity;
          let eventsFromReduxBySeverity = eventsFromRedux.filter(
            (event) => event.severity === severity.toUpperCase()
          );

          let color = "";
          if (eventSeverity.id === 0) {
            color = "#fbcd03";
          }
          if (eventSeverity.id === 1) {
            color = "#0052bd";
          }
          if (eventSeverity.id === 2) {
            color = "#ff4f48";
          }
          const eventOptions = {
            label: eventSeverity.severity,
            hasCheckbox: true,
            hasDropdown: true,
            hasCount: true,
            color: color,
            events: eventsFromReduxBySeverity,
          } as Option;
          return eventOptions;
        }
      );
      setEventsOptions(eventsOptions);
    }
  }, [eventsFromRedux]);

  const [eventAreasValues, setEventAreasValues] = useState<DropDownItem[]>(
    _.keys(logEventAreasValues).map((key) => {
      const labelAreasType = t("logEvent.table.eventAreas." + key);

      return {
        label: labelAreasType,
        value: key,
        checked: false,
      };
    })
  );

  const [eventStatusValues, setEventStatusValues] = useState<DropDownItem[]>(
    _.keys(eventOutcomeEnumValues).map((key) => {
      const labelStatusType = t("logEvent.table.eventStatus." + key);

      return {
        label: labelStatusType,
        value: key,
        checked: false,
      };
    })
  );

  //#region handle queryparams
  useEffect(() => {
    store.dispatch(getPresetsAsync(getQueryString({ context: context })));
    store.dispatch(getFleetViewsAsync());
    store.dispatch(getEventsAsync());
    const map = new Map();
    map.set("sort", ["timestamp,DESC"]);
    handleChanges(map);
    return () => {
      store.dispatch(logEventsEmptyState());
    };
  }, []);

  const handleChanges = (params: Map<string, string[] | string>): void => {
    if (!!params) {
      params.forEach((value, key) => {
        if (!!value && value.length > 0) {
          queryParams[key] = value;
        } else {
          if (queryParams.hasOwnProperty(key)) {
            delete queryParams[key];
          }
        }
      });
      const stringifiedQueryString = getQueryString(queryParams);
      navigate(`/dashboard/logs${stringifiedQueryString}`);
      setQueryString(stringifiedQueryString);
    }
    queryParams && isSelectedPeriod && setDisabledGenerate(false);
  };

  const GenerateResult = () => {
    if (queryString !== "" && isSelectedPeriod) {
      store.dispatch(
        getFilteredLogEventsPaginationAsync({
          queryParams: queryString,
        })
      );
      callback(queryString);
    }
  };
  //#endregion handle queryparams

  const itemsList = [
    {
      id: 0,
      title: t("report.filterBar.downloadExcel"),
      icon: isExcelGenerated ? (
        <IconDownload size={14} color="--global-colors-ink-light" />
      ) : (
        <div className="details-driverReport-download-spinner"></div>
      ),
      onClick: () => {
        downloadResult(false);
      },
    },
    {
      id: 1,
      title: t("report.filterBar.downloadPDF"),
      icon: isPdfGenerated ? (
        <IconDownload size={14} color="--global-colors-ink-light" />
      ) : (
        <div className="details-driverReport-download-spinner"></div>
      ),
      onClick: () => {
        downloadResult(true);
      },
    },
    {
      id: 2,
      title: t("report.filterBar.sendReportEmail"),
      icon: <IconLocatorOff size={14} color="--global-colors-ink-light" />,
      onClick: () => {
        sendReportEmail();
      },
    },
  ];

  const downloadResult = (pdf: boolean) => {
    if (queryString !== "" || isSelectedPeriod) {
      const queryParam = new URLSearchParams(queryString);
      const period = queryParam.getAll("timestamp");
      queryParam.delete("timestamp");
      queryParam.append("startPeriod", period[0]);
      queryParam.append("endPeriod", period[1]);
      const params =
        "?" + queryParam.toString() + (pdf ? "&isPdf=true" : "&isPdf=false");
      pdf ? setIsPdfGenerated(false) : setIsExcelGenerated(false);
      reportsRepository
        .getEventLogsDownload(params.replaceAll("name", "eventNames"))
        .then((response) => {
          return response.data;
        })
        .then((data) => {
          const fileName = data.split("/").pop();
          const attachmentDownloadLink = document.createElement("a");
          attachmentDownloadLink.href = process.env.REACT_APP_BUCKET_URL + data;
          attachmentDownloadLink.download = fileName;
          attachmentDownloadLink.target = "_blank";
          attachmentDownloadLink.style.display = "none";
          document.body.appendChild(attachmentDownloadLink);
          attachmentDownloadLink.click();
          document.body.removeChild(attachmentDownloadLink);
          pdf ? setIsPdfGenerated(true) : setIsExcelGenerated(true);
        })
        .catch((error) => {
          pdf ? setIsPdfGenerated(true) : setIsExcelGenerated(true);
          if (
            error.response &&
            error.response.data.message ==
              GTFleetErrorCodes.REPORT_TIMEOUT_EXCEPTION
          ) {
            console.log(error?.message || "Report Timeout Exception");
            ToastNotification({
              toastId: "reportTimeoutError",
              status: "default",
              title: t("common.reportTimeoutErrorTitle"),
              description: t("common.reportTimeoutErrorDesc"),
            });
          } else {
            console.log(error.response?.data?.error || "Unknown Error");
            ToastNotification({
              toastId: "networkError",
              status: "error",
              description: t("common.networkError"),
            });
          }
        });
    }
  };

  const sendReportEmail = () => {
    if (queryString !== "" || isSelectedPeriod) {
      const queryParam = new URLSearchParams(queryString);
      const period = queryParam.getAll("timestamp");
      queryParam.delete("timestamp");
      queryParam.append("startPeriod", period[0]);
      queryParam.append("endPeriod", period[1]);
      const params = "?" + queryParam.toString();
      reportsRepository
        .getEventLogsReportEmail(params.replaceAll("name", "eventNames"))
        .then((response) => {
          return response.data;
        })
        .then((data) => {
          ToastNotification({
            toastId: "requestSendingMailSuccess",
            status: "success",
            description: t(
              "report.toastNotification.requestSendingMailSuccess"
            ),
          });
        })
        .catch((error) => {
          if (!error.response) console.log(error?.message || "Unknown Error");
          else console.log(error.response?.data?.error || "Unknown Error");
          ToastNotification({
            toastId: "networkError",
            status: "error",
            description: t("common.networkError"),
          });
        });
    }
    return null;
  };

  return (
    <div className="log-filterbar-row">
      <div className="log-filterbar-search">
        <Form>
          <SearchField
            name="search"
            id="action"
            size="small"
            placeholder={t("logEvent.filterBar.searchField")}
            value={actionLogEvent}
            onChange={(val) => {
              setActionLogEvent(val);
              const map = new Map();
              map.set("action", val);
              handleChanges(map);
            }}
          />
        </Form>
      </div>
      <div className="log-filterbar-date-picker">
        <Form>
          <DatePickerDropdown
            setDate={(val) => {
              const map = new Map();
              map.set(
                "timestamp",
                [
                  new Date(val[0])?.toISOString(),
                  new Date(val[1])?.toISOString(),
                ] ?? ""
              );
              const period = [];
              if (val[0]) {
                period.push(val[0].format("YYYY-MM-DDTHH:mm"));
              }
              if (val[1]) {
                period.push(val[1].format("YYYY-MM-DDTHH:mm"));
              }
              setIsSelectedPeriod(true);
              setDisabledGenerate(false);
              handleChanges(map);
            }}
            hasTime={true}
            localeFormat={preferencesContext.localeFormat ?? "DD / MM / YYYY"}
            language={preferencesContext.language ?? "it"}
            icon={<IconCalendar size={12} color="--global-colors-ink-light" />}
            defaultValue={new Date()}
            dateRange={[dayjs().subtract(1, "year"), dayjs()]} //From today to past 1 year
            limitDaysRange={31}
            clearCallback={() => {
              setDisabledGenerate(true);
            }}
          />
        </Form>
      </div>
      <div className="dropdown-events-filter-row">
        <div className="dropdown-events-filter-vehicles-dropdown">
          {eventsFromRedux && eventsOptions && (
            <EventsDropdown
              options={eventsOptions}
              loading={false}
              getValueSelected={(values: Event[]) => {
                if (!_.isEqual(values, eventList)) {
                  const map = new Map();
                  if (values && values?.length > 0) {
                    setEventList(values);
                  } else {
                    setEventList([]);
                  }
                  map.set(
                    "name",
                    values.map((x) => x.name)
                  );
                  handleChanges(map);
                }
              }}
              valueSelected={selectedEvents}
            />
          )}
        </div>
      </div>
      <div className="log-filterbar-dropdown-groups">
        <Dropdown
          itemAttribute="label"
          hasCheckbox={true}
          placeholder={t("logEvent.filterBar.searchGroupsAreas")}
          size={"small"}
          onChange={(val: DropDownItem[]) => {
            setEventAreasValues(
              eventAreasValues.map((eventAreasValues) => {
                if (val.includes(eventAreasValues)) {
                  eventAreasValues.checked = true;
                } else {
                  eventAreasValues.checked = false;
                }
                return eventAreasValues;
              })
            );
            const map = new Map();
            map.set(
              "eventSourceEnum",
              val.map((x) => x.value)
            );
            handleChanges(map);
          }}
          options={eventAreasValues}
          value={eventAreasValues.filter((x) => x.checked)}
          hasSelectAll={true}
        />
      </div>

      <div className="log-filterbar-dropdown-groups">
        <Dropdown
          placeholderInValue={t(
            "fleetControl.filterBar.placeholderInValStatus"
          )}
          itemAttribute="label"
          hasCheckbox={true}
          placeholder={t("logEvent.filterBar.searchGroupsStatus")}
          size={"small"}
          onChange={(val: DropDownItem[]) => {
            setEventStatusValues(
              eventStatusValues.map((eventStatusValues) => {
                if (val.includes(eventStatusValues)) {
                  eventStatusValues.checked = true;
                } else {
                  eventStatusValues.checked = false;
                }
                return eventStatusValues;
              })
            );
            const map = new Map();
            map.set(
              "eventOutcomeEnum",
              val.map((x) => x.value)
            );
            handleChanges(map);
          }}
          options={eventStatusValues}
          value={eventStatusValues.filter((x) => x.checked)}
        />
      </div>

      <div className="log-filterbar-btn-generate">
        <Button
          aspect="primary"
          size="small"
          label={t("report.filterBar.buttonGenerate")}
          onlyIcon={false}
          onClick={() => GenerateResult()}
          isLoading={logEventsStatus === "loading"}
          disabled={isDisabledGenerate}
        ></Button>
      </div>

      <div className="log-filterbar-btn ">
        <DropdownButton
          aspect="secondary"
          size="small"
          list={itemsList}
          label={t("common.export")}
          disabled={disabledButton}
        >
          <IconDown size={14} color="--global-colors-ink-ink" />
        </DropdownButton>
      </div>
    </div>
  );
};
