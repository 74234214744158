import { schema } from "normalizr";

//#region VEHICLE_TRAVELS
export const vehicleTravelSummarySchema: schema.Entity<any> = new schema.Entity(
  "vehicleTravelSummary",
  {},
  { idAttribute: "routeId" }
);
export const vehicleTravelsSummarySchema = new schema.Array(
  vehicleTravelSummarySchema
);

export const vehicleTravelsMacroSummarySchema: schema.Entity<any> =
  new schema.Entity(
    "vehicleTravelsMacroSummary",
    {},
    { idAttribute: "vehicleId" }
  );

//#endregion VEHICLE_TRAVELS
