import i18next, { t } from "i18next";
import { useContext, useEffect, useRef, useState } from "react";
import { useAppSelector } from "../../../app/hooks";
import { store } from "../../../app/store";
import { GTFleetErrorCodes } from "../../../config/GTfleetErrorCodes";
import PageContent from "../../../layout/PageContent";
import PageFilters from "../../../layout/PageFilters";
import { PageCounter } from "../../../ui/Table/PageCounter";
import { Table } from "../../../ui/Table/Table";
import { ToastNotification } from "../../../utils/ToastNotification";
import {
  routeHistoryEmptyRoutes,
  selectRoutesHistorySliceReasonCode,
  selectRoutesHistorySliceStatus,
} from "../../route/routesHistorySlice";
import { Preferences } from "../../users/preference/preferencesSlice";
import {
  Preset,
  presetsSelectors,
  selectpresetsSliceReasonCode,
  selectpresetsSliceStatus,
} from "../../users/preset/presetsSlice";
import UserContext from "../../users/userContext";
import "./ReportUtilization.css";
import { ReportUtilizationDetails } from "./ReportUtilizationDetails";
import { UtilizationReportFilterBar } from "./ReportUtilizationFilterBar";
import {
  ReportUtilization,
  ReportsUtilizationSummary,
  reportsUtilizationEmptyState,
  reportsUtilizationSelectors,
  selectReportsUtilizationSliceReasonCode,
  selectReportsUtilizationSliceStatus,
} from "./ReportUtilizationSlice";
import {
  reportsUtilizationSummaryEmptyState,
  reportsUtilizationSummarySelectors,
  selectReportsUtilizationSummarySliceReasonCode,
  selectReportsUtilizationSummarySliceStatus,
} from "./ReportUtilizationSummarySlice";
import { ReportUtilizationTableBuilder } from "./ReportUtilizationTableBuilder";
interface TableData {
  columns: { label: string; field: string; sort: boolean }[] | undefined;
  rows: { [key: string]: any };
}

const tableData: TableData = {} as TableData;

const context = "reportUtilization";

export const UtilizationReport = () => {
  const [preferencesContext]: [Preferences] = useContext(UserContext);

  const tableBuilderRef = useRef<ReportUtilizationTableBuilder>(
    new ReportUtilizationTableBuilder()
  );
  let tableBuilder = tableBuilderRef.current;

  let presets: Preset[] = useAppSelector(presetsSelectors.selectAll);
  const presetsUtilizationSliceStatus = useAppSelector(
    selectpresetsSliceStatus
  );
  const presetsUtilizationSliceReasonCode = useAppSelector(
    selectpresetsSliceReasonCode
  );

  const [queryParamsFromFilterBar, setQueryParamsFromFilterBar] =
    useState<string>("");

  const reportsUtilization: ReportUtilization[] = useAppSelector(
    reportsUtilizationSelectors.selectAll
  );

  const reportsSliceStatus = useAppSelector(
    selectReportsUtilizationSliceStatus
  );
  const reportsSliceReasonCode = useAppSelector(
    selectReportsUtilizationSliceReasonCode
  );
  const reportsUtilizationSummary: ReportsUtilizationSummary[] = useAppSelector(
    reportsUtilizationSummarySelectors.selectAll
  );

  const reportsSummarySliceStatus = useAppSelector(
    selectReportsUtilizationSummarySliceStatus
  );

  const reportsSummarySliceReasonCode = useAppSelector(
    selectReportsUtilizationSummarySliceReasonCode
  );

  const routesHistorySliceStatus = useAppSelector(
    selectRoutesHistorySliceStatus
  );
  const routesHistorySliceReasonCode = useAppSelector(
    selectRoutesHistorySliceReasonCode
  );

  const [queryParamsChanged, setQueryParamsChanged] = useState<boolean>(false);
  const [resetPage, setResetPage] = useState<boolean>(false);
  const [numPages, setNumPages] = useState(0);

  const [pagedReportsUtilization, setPagedReportsUtilization] = useState<
    ReportUtilization[]
  >(reportsUtilization.slice(0, 9));

  useEffect(() => {
    if (reportsUtilization.length > 0) {
      setPagedReportsUtilization(reportsUtilization.slice(0, 9));
      let numPageDivided = reportsUtilization.length / 10;
      setNumPages(
        Math.floor(numPageDivided) === numPageDivided
          ? numPageDivided
          : Math.floor(numPageDivided) + 1
      );
    } else {
      setPagedReportsUtilization([]);
      setNumPages(0);
    }
  }, [reportsUtilization]);

  useEffect(() => {
    if (reportsSummarySliceStatus === "idle" && queryParamsChanged) {
      setQueryParamsChanged(false);
      setResetPage(true);
    } else {
      setResetPage(false);
    }
  }, [reportsSummarySliceStatus, queryParamsChanged]);

  //#region error handling
  useEffect(() => {
    if (reportsSliceStatus === "failed" && reportsSliceReasonCode === "") {
      console.error("common.networkError");
      ToastNotification({
        toastId: "networkError",
        status: "error",
        description: t("common.networkError"),
      });
    }
  }, [reportsSliceStatus, reportsSliceReasonCode]);

  useEffect(() => {
    if (
      reportsSummarySliceStatus === "failed" &&
      reportsSummarySliceReasonCode === ""
    ) {
      console.error(i18next.t("common.networkError"));
      ToastNotification({
        toastId: "reportsSummaryNetworkError",
        status: "error",
        description: i18next.t("common.networkError"),
      });
    }
  }, [reportsSummarySliceStatus, reportsSummarySliceReasonCode]);

  useEffect(() => {
    if (
      presetsUtilizationSliceStatus === "failed" &&
      presetsUtilizationSliceReasonCode === ""
    ) {
      console.error(i18next.t("common.networkError"));
      ToastNotification({
        toastId: "presetsUtilizationNetworkError",
        status: "error",
        description: i18next.t("common.networkError"),
      });
    }
  }, [presetsUtilizationSliceStatus, presetsUtilizationSliceReasonCode]);

  useEffect(() => {
    if (
      routesHistorySliceStatus === "failed" &&
      routesHistorySliceReasonCode === ""
    ) {
      console.error(t("common.networkError"));
      ToastNotification({
        toastId: "networkError",
        status: "error",
        description: t("common.networkError"),
      });
    }
    if (
      routesHistorySliceStatus === "failed" &&
      routesHistorySliceReasonCode ===
        GTFleetErrorCodes.ROUTE_HISTORY_DOES_NOT_EXIST
    ) {
      console.error(t("report.io.routeNotFound"));
      ToastNotification({
        toastId: "networkError",
        status: "error",
        description: t("report.io.routeNotFound"),
      });
    }
  }, [routesHistorySliceStatus, routesHistorySliceReasonCode]);
  //#endregion error handling

  if (presetsUtilizationSliceStatus === "idle" && presets.length > 0) {
    let preset =
      presets.find((element) => element.name === undefined) ??
      presets.find((element) => element.lastSelected === true);
    if (!preset) {
      preset = presets.find(
        (element) => element.name === "Default" && element.context === context
      );
    }

    tableData.columns = tableBuilder.setColumns(
      preset?.columns
        ? preset?.columns.map((element) => {
            return {
              name: element,
            };
          })
        : []
    );

    tableData.rows =
      reportsSliceStatus === "idle" &&
      presetsUtilizationSliceStatus === "idle" &&
      tableData.columns &&
      tableData?.columns?.length > 0
        ? pagedReportsUtilization.map(
            (reportUtilization: ReportUtilization) => {
              return tableBuilder.rowsBuilder(
                tableData.columns,

                {
                  reportUtilization: reportUtilization,
                },
                preferencesContext
              );
            }
          )
        : tableData.rows;
  }

  interface CheckedProps {
    id: number;
    color: string;
    number: number;
    vehicleId: number;
    queryParams: string;
  }

  const [checkedList, setCheckedList] = useState<CheckedProps[]>([]);

  function isSelected(option: any) {
    return checkedList ? checkedList.some((el) => el.id === option) : false;
  }

  useEffect(() => {
    document.title =
      t("breadcrumbs.utilizationReport") + " - " + t("breadcrumbs.reports");
    return () => {
      store.dispatch(reportsUtilizationEmptyState());
      store.dispatch(reportsUtilizationSummaryEmptyState());
      store.dispatch(routeHistoryEmptyRoutes());
    };
  }, []);

  return (
    <>
      <PageFilters>
        <div className="col col-16">
          <UtilizationReportFilterBar
            presets={presets}
            callback={(queryParams) => {
              setQueryParamsChanged(true);
              setQueryParamsFromFilterBar(queryParams);
            }}
            disableButton={tableData.rows?.length === 0}
          />
        </div>
        {queryParamsFromFilterBar !== "" && (
          <div className="col col-16">
            <div className="details-reportUtilization-wrapper">
              <div className="details-report-title">
                {t("report.details.title")}
              </div>
              <div className="details-report-container">
                <div className="details-report-icons">
                  <ReportUtilizationDetails
                    startDate={reportsUtilizationSummary[0]?.startDate}
                    endDate={reportsUtilizationSummary[0]?.endDate}
                    workingTime={reportsUtilizationSummary[0]?.totalWorkingTime}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </PageFilters>
      {queryParamsFromFilterBar !== "" && tableData.rows?.length > 0 && (
        <div className="ior-container">
          <PageContent>
            <div style={{ display: "inline-flex" }}>
              <div className="ior-table-container">
                <div style={{ display: "inline-flex", width: "100%" }}>
                  <div className="ior-checkbox-list"></div>
                  <div className="ior-rows-container">
                    <Table data={tableData}>
                      <Table.Head />
                      <Table.Body id="ior-table-body" />
                    </Table>
                  </div>
                  <div className="ior-marker-list"></div>
                </div>
                <div>
                  <PageCounter
                    isActionPerforming={reportsSliceStatus === "loading"}
                    totalElements={
                      reportsUtilization ? reportsUtilization.length : 0
                    }
                    numOfPages={numPages}
                    numSelected={checkedList.length}
                    resetPage={resetPage}
                    onClick={(id) => {
                      // Restore dispatch once Backend Pagination is completed
                      const paginatedElements = reportsUtilization.slice(
                        10 * (id - 1),
                        10 * id - 1
                      );
                      setPagedReportsUtilization(paginatedElements);
                    }}
                  />
                </div>
              </div>
            </div>
          </PageContent>
        </div>
      )}
    </>
  );
};
