import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";

//#region Type
export interface EntityPermission {
  read: boolean;
  write?: boolean;
}
export interface UserPermissions {
  drivers: EntityPermission;
  fleets: EntityPermission;
  users: EntityPermission;
  vehicles: EntityPermission;
  deadlines: EntityPermission;
  notifications: EntityPermission;
  geofences: EntityPermission;
  logs: EntityPermission;
  reports: EntityPermission;
  events: EntityPermission;
  notificationRules: EntityPermission;
  tachograph: EntityPermission;
  liveTrack: EntityPermission;
  chats: EntityPermission;
  publicTransport: EntityPermission;
}

export type Privileges = {
  entityId: number;
  entityType: string;
  permission: string;
};
//#endregion Type

//#region Slice
const privilegesAdapter = createEntityAdapter<Privileges>({
  selectId: (privileges) =>
    privileges.entityId + privileges.permission + privileges.entityType,
});

export const privilegesSlice = createSlice({
  name: "privileges",
  initialState: privilegesAdapter.getInitialState({
    status: "idle",
    reasonCode: "",
  }),
  reducers: {
    privilegesEmptyState: (state: any) => {
      privilegesAdapter.setAll(state, []);
      state.reasonCode = "";
      state.status = "idle";
    },
  },
});
//#endregion Slice

//#region Status
export const privilegesSelectors = privilegesAdapter.getSelectors<RootState>(
  (state) => state.privileges
);

export const selectPrivilegesSliceStatus = (state: any) =>
  state.privileges.status;
export const selectPrivilegesSliceReasonCode = (state: any) =>
  state.privileges.reasonCode;

export const { privilegesEmptyState } = privilegesSlice.actions;

//#endregion Status

export default privilegesSlice.reducer;
