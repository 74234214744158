import propTypes from "prop-types";

import "./mapMarker.css";

export const MapMarkerToggle = ({ color, number, letter, ...props }) => {
  return (
    <div className="map-marker-toggle" {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="38"
        height="44"
      >
        <defs>
          <filter
            id="MapMarkerToggleA"
            width="116.7%"
            height="117.2%"
            x="-8.3%"
            y="-4.9%"
            filterUnits="objectBoundingBox"
          >
            <feMorphology
              in="SourceAlpha"
              radius="0.5"
              result="shadowSpreadOuter1"
            ></feMorphology>
            <feOffset
              dy="3"
              in="shadowSpreadOuter1"
              result="shadowOffsetOuter1"
            ></feOffset>
            <feGaussianBlur
              in="shadowOffsetOuter1"
              result="shadowBlurOuter1"
              stdDeviation="1"
            ></feGaussianBlur>
            <feComposite
              in="shadowBlurOuter1"
              in2="SourceAlpha"
              operator="out"
              result="shadowBlurOuter1"
            ></feComposite>
            <feColorMatrix
              in="shadowBlurOuter1"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
            ></feColorMatrix>
          </filter>
          <filter
            id="MapMarkerToggleD"
            width="103.1%"
            height="103.1%"
            x="-1.6%"
            y="-1.6%"
            filterUnits="objectBoundingBox"
          >
            <feMorphology
              in="SourceAlpha"
              radius="1"
              result="shadowSpreadInner1"
            ></feMorphology>
            <feOffset
              in="shadowSpreadInner1"
              result="shadowOffsetInner1"
            ></feOffset>
            <feComposite
              in="shadowOffsetInner1"
              in2="SourceAlpha"
              k2="-1"
              k3="1"
              operator="arithmetic"
              result="shadowInnerInner1"
            ></feComposite>
            <feColorMatrix
              in="shadowInnerInner1"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
            ></feColorMatrix>
          </filter>
          <path
            id="MapMarkerToggleB"
            d="M18 41s2.71-5.154 3.994-5.445C30.013 33.74 36 26.57 36 18c0-9.941-8.059-18-18-18S0 8.059 0 18c0 8.564 5.981 15.732 13.995 17.553C15.283 35.845 18 41 18 41z"
          ></path>
          <path
            id="MapMarkerToggleE"
            d="M8.333 0a4.333 4.333 0 011 8.55V15a1 1 0 01-2 0V8.55a4.335 4.335 0 011-8.55z"
          ></path>
          <circle id="MapMarkerToggleC" cx="18" cy="18" r="16"></circle>
        </defs>
        <g fill="none" fillRule="evenodd">
          <path
            fill="#FFF"
            fillRule="nonzero"
            d="M20.487 17.503v4.062C19.457 22.41 18.025 23 16.31 23c-1.117 0-2.146-.287-3-.806l2.842-1.854c.098.011.19.017.294.017.544 0 .839-.083 1.084-.221v-.696l2.957-1.937zM27 13.253v2.528h-2.843v7.01h-3.23v-5.57L27 13.255zM14.191 18.11c.017 1.016.556 1.788 1.394 2.098l-2.652 1.732a4.735 4.735 0 01-1.563-1.986zM21.238 13l-4.253 2.781h-.637v-.01l-.033.01c-1.084 0-1.9.74-2.086 1.8l-3.006 1.97A5.236 5.236 0 0111 18.033v-.027c0-2.865 2.227-5 5.217-5h.13V13h4.891zM20.1 17.255l-2.94 1.92h-1.253v-1.92H20.1zM26.613 13l-5.68 3.72v-.939h-3.17L22.018 13h4.596z"
          ></path>
          <g transform="translate(1)">
            <use
              fill="#000"
              filter="url(#MapMarkerToggleA)"
              xlinkHref="#MapMarkerToggleB"
            ></use>
            <path
              fill="#FFF"
              stroke="#FFF"
              strokeLinejoin="square"
              strokeWidth="2"
              d="M18 1c4.694 0 8.944 1.903 12.02 4.98A16.947 16.947 0 0135 18a16.93 16.93 0 01-3.703 10.594 17.001 17.001 0 01-9.524 5.986c-.956.217-2.712 2.8-3.774 4.491-1.064-1.692-2.825-4.276-3.783-4.493A17.002 17.002 0 014.7 28.589 16.927 16.927 0 011 18c0-4.694 1.903-8.944 4.98-12.02A16.947 16.947 0 0118 1z"
            ></path>
          </g>
          <g transform="translate(1)">
            <use
              fill={window
                .getComputedStyle(document.documentElement)
                .getPropertyValue(color)}
              xlinkHref="#MapMarkerToggleC"
            ></use>
            <use
              filter="url(#MapMarkerToggleD)"
              xlinkHref="#MapMarkerToggleC"
            ></use>
          </g>
          <text
            fill={
              color === "--global-colors-feedback-warning"
                ? "var(--global-colors-ink-dark)"
                : "var(--global-colors-ui-white)"
            }
            fontFamily="Helvetica"
            fontSize="16"
          >
            <tspan
              x="50%"
              y="45%"
              dominantBaseline="middle"
              textAnchor="middle"
            >
              {number}
            </tspan>
          </text>
        </g>
      </svg>
      <span className="label-led" style={{ color: `var(${color})` }}>
        {letter}
      </span>
    </div>
  );
};

MapMarkerToggle.propTypes = {
  /**
   * The status of the marker.
   */
  color: propTypes.string,
  number: propTypes.string,
  letter: propTypes.string,
};

MapMarkerToggle.defaultProps = {
  color: "--global-colors-ui-primary",
  number: "2",
  letter: "A",
};
