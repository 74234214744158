import { useState, useRef, useEffect } from "react";
import "./TimeDropdown.css";
import { IconArrowDown } from "../Icon/Line/ArrowDown";
import { Slider } from "../Map/Slider";
import { Button } from "../Button/Button";
import { useTranslation } from "react-i18next";

export const TimeDropdown = ({ setTime, defaultTime }) => {
  const { t } = useTranslation();
  const wrapperRef = useRef(null);
  const parentRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [sliderValue, setSliderValue] = useState(0);
  const [timeSelected, setTimeSelected] = useState(0);

  useEffect(() => {
    if (defaultTime) {
      let minutes = (defaultTime / 60000).toString();
      setSliderValue(minutes);
      setTimeSelected(minutes);
    }
  }, [defaultTime]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target) &&
        !parentRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  function getInputClass() {
    if (isOpen && isActive) return "timeDropdown-field active";
    else if (timeSelected !== 0) return "timeDropdown-field selected";
    else return "timeDropdown-field";
  }

  return (
    <>
      <div
        className={getInputClass()}
        onClick={() => {
          setIsOpen(!isOpen);
          setIsActive(true);
        }}
        ref={parentRef}
      >
        <span className="timeDropdown-span">
          <input
            readOnly
            type="text"
            placeholder={
              !(timeSelected && timeSelected === 0)
                ? t("report.filterBar.timeDropdownPlaceholder")
                : "> " + timeSelected + "min"
            }
            value={timeSelected < 1 ? "" : "> " + timeSelected + "min"}
          ></input>
        </span>
        <div className={`timeDropdown-icon ${isOpen ? "open" : null}`}>
          <IconArrowDown size={12} color="--global-colors-ink-light" />
        </div>
      </div>
      {isOpen && (
        <div className="timeDropdown-container" ref={wrapperRef}>
          <div className="timeDropdown-list">
            <Slider
              data={{ min: "0", max: "60" }}
              label={t("report.filterBar.timeDropdownLabel")}
              valueLabel="min"
              val={sliderValue}
              onChange={setSliderValue}
            />
          </div>
          <div className="timeDropdown-button">
            <Button
              aspect="primary"
              label={t("map.actions.searchActionMenu.buttonLabelApply")}
              size="small"
              disabled={sliderValue < 1}
              onClick={() => {
                setIsOpen(false);
                setTimeSelected(sliderValue);
                setTime && setTime((sliderValue * 60000).toString());
              }}
            />
          </div>
        </div>
      )}
    </>
  );
};
