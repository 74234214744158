import HttpClient from "../../utils/HttpClient";
export default class LogsRepository extends HttpClient {
  constructor() {
    super(true);
  }

  getLogEvents(customQueryParams?: string) {
    const tenantId = localStorage.getItem("tenantId");
    let queryParams = "";
    if (!!customQueryParams) {
      queryParams = customQueryParams + "&";
    } else {
      queryParams = "?";
    }
    return this.instance.get("/tenants/" + tenantId + "/logs" + queryParams);
  }
}
