import dayjs from "dayjs";

const isBetween = require("dayjs/plugin/isBetween");

dayjs.extend(isBetween);

function isStartDate(day, startDate) {
  return startDate !== null && startDate.isSame(day, "day");
}

function isEndDate(day, endDate) {
  return endDate !== null && endDate.isSame(day, "day");
}

function isNotCurrentMonth(day, value) {
  return value.month() !== day.month();
}

function isOutsideTheRange(day, dateRange) {
  if (dateRange) {
    return !day.isAfter(dateRange[0]) || day.isAfter(dateRange[1]);
  } else return false;
}

function isBetweenDates(day, startDate, endDate) {
  return (
    startDate !== null &&
    endDate !== null &&
    day.isBetween(startDate, endDate, "day")
  );
}

export function beforeToday(day) {
  return day.isBefore(new Date(), "day");
}

function isToday(day) {
  return day.isSame(new Date(), "day");
}

export function hoverWeekByDayStyles(day, hoveredDay) {
  if (!hoveredDay) return "";

  const startOfWeek = hoveredDay.startOf("week");

  const endOfWeek = hoveredDay.endOf("week");

  if (day.isBetween(startOfWeek, endOfWeek, null, "[]")) {
    return "hovered-week";
  }

  return "";
}

export default function dayStyles(
  day,
  value,
  startDate,
  endDate,
  secondStartDate,
  secondEndDate,
  isSingleDate,
  dateRange,
  hasDatePresets
) {
  const className = [];

  if (isNotCurrentMonth(day, value)) {
    className.push("not-selectable");
  } else if (isStartDate(day, startDate)) {
    if (isSingleDate || !endDate || startDate.isSame(endDate, "day"))
      className.push("single-date");
    else className.push("start-date");
  }

  if (isStartDate(day, secondStartDate)) {
    className.push("second-start-date");
  }

  if (isEndDate(day, endDate) && !startDate.isSame(endDate, "day")) {
    className.push("end-date");
  }

  if (isEndDate(day, secondEndDate)) {
    className.push("second-end-date");
  }

  if (isBetweenDates(day, startDate, endDate)) {
    className.push("between-dates");
  }

  if (isBetweenDates(day, secondStartDate, secondEndDate)) {
    className.push("second-between-dates");
  }

  if (isOutsideTheRange(day, dateRange) && !hasDatePresets) {
    className.push("not-selectable");
  }

  if (isToday(day)) {
    className.push("today");
  }

  return className.join(" ");
}
