import { ReactElement } from "react";
import { Event } from "../../features/event/eventsSlice";
import { GpsDataEvent } from "../../features/route/routeEventsSlice";
import { GpsData } from "../../features/route/routesSlice";
import { MarkerAsComponent } from "../Map/MarkerAsComponent";
import { MapMarkerLocator } from "../Marker/MapMarkerLocator";
import { ClassPolylineProps } from "./Polyline";
import "./Polyline.css";

/**
 * @todo RouteState type belongs to GTFleet project.
 * The GTFleet project uses the Design System (UI components).
 * Therefore, UI cannot use types of the target project. Remove
 * the RouteState type in favor of an internal type that serves the GTFleet project.
 * Problem: cyclic reference.
 */
export interface SummaryClassPolylineProps {
  hasMarker: boolean;
  googleMap: any;
  map: any;
  gpsData: GpsData[];
  gpsDataEvent: GpsDataEvent[];
  eventsType: Event[];
  initialCurrentPoint?: {
    start: number;
    stop: number;
    indexPoint: number;
  };
  viewPointMarkers?: boolean;
  viewEventMarkers?: boolean;
  hideEventColors?: boolean;
  color?: string;
  polylineMarkerStart?: ReactElement;
  polylineMarkerEnd?: ReactElement;
  routeId: number;
}

export const SummaryClassPolyline = ({
  hasMarker,
  googleMap,
  map,
  gpsData,
  gpsDataEvent,
  eventsType,
  initialCurrentPoint,
  routeId,
}: SummaryClassPolylineProps) => {
  class SummaryClassPolyline extends google.maps.Polyline {
    polyline: any = [];
    incrementalPolyline: any = [];
    gpsPointMarkers: any = [];
    currentPoint: SummaryClassPolylineProps["initialCurrentPoint"] | undefined;
    markersList: any = [];
    cluster: any;
    arrows: any = [];
    id: number = 0;

    constructor() {
      super();
      this.currentPoint = initialCurrentPoint;
      this.polyline = this.settingPolyline(
        gpsData,
        gpsDataEvent,
        eventsType,
        map
        // id
      );
      this.id = routeId;
    }

    settingPolyline(
      gpsDatas: GpsData[],
      eventStates: GpsDataEvent[],
      eventsType: Event[],
      map: google.maps.Map,
      id?: number
    ) {
      let polylines: any[] = [];

      if (!gpsDatas || gpsDatas.length === 0) {
        return polylines;
      }

      let tempPolyline: any[] = [];

      gpsDatas.forEach((showPoint, g) => {
        const currentGpsData = showPoint;

        const addPoint = new google.maps.LatLng(
          currentGpsData?.dynamicFields?.latitude,
          currentGpsData?.dynamicFields?.longitude
        );

        if (showPoint) {
          if (
            eventStates &&
            eventStates.some(
              (eventState) =>
                eventState?.dynamicFields?.latitude ===
                  currentGpsData?.dynamicFields?.latitude &&
                eventState?.dynamicFields?.longitude ===
                  currentGpsData?.dynamicFields?.longitude &&
                eventState?.eventLogByVehicleIdKey?.timestamp ===
                  currentGpsData?.gpsdataByRouteTypeKey?.timestamp
            )
          ) {
            tempPolyline.length &&
              tempPolyline[tempPolyline.length - 1].point.push(addPoint);

            const prevGpsData = gpsDatas[g - 1];
            const nextGpsData = gpsData[g + 1];

            const currentEvent = eventStates.find(
              (eventState) =>
                eventState.dynamicFields.latitude ===
                  currentGpsData?.dynamicFields?.latitude &&
                eventState.dynamicFields.longitude ===
                  currentGpsData?.dynamicFields?.longitude &&
                eventState.eventLogByVehicleIdKey.timestamp ===
                  currentGpsData?.gpsdataByRouteTypeKey?.timestamp
            );

            const prevEvent = eventStates.find(
              (eventState) =>
                eventState.dynamicFields.latitude ===
                  prevGpsData?.dynamicFields?.latitude &&
                eventState.dynamicFields.longitude ===
                  prevGpsData?.dynamicFields?.longitude &&
                eventState.eventLogByVehicleIdKey.timestamp ===
                  prevGpsData?.gpsdataByRouteTypeKey?.timestamp
            );

            const nextEvent = eventStates.find(
              (eventState) =>
                eventState.dynamicFields.latitude ===
                  nextGpsData?.dynamicFields?.latitude &&
                eventState.dynamicFields.longitude ===
                  nextGpsData?.dynamicFields?.longitude &&
                eventState.eventLogByVehicleIdKey.timestamp ===
                  nextGpsData?.gpsdataByRouteTypeKey?.timestamp
            );

            const eventTypeCurr = eventsType.find(
              (type) => type.id === currentEvent?.typeId
            );

            const eventTypePrev = eventsType.find(
              (type) => type.id === prevEvent?.typeId
            );

            const eventTypeNext = eventsType.find(
              (type) => type.id === nextEvent?.typeId
            );

            const findColor = (
              prev: Event | undefined,
              curr: Event | undefined,
              next: Event | undefined
            ) => {
              if (
                curr &&
                curr.severity === "WARNING" &&
                (!next || next.severity !== "WARNING")
              ) {
                return "#0052BD";
              } else if (curr && curr.severity === "WARNING") {
                return "#fbcd03";
              } else if (curr && curr.severity === "INFO") {
                return "#0052BD";
              } else if (curr && curr.severity === "ALARM") {
                return "#ff4f48";
              } else {
                return "#0052BD";
              }
            };

            const tempPoly = {
              point: [addPoint],
              color: findColor(eventTypePrev, eventTypeCurr, eventTypeNext),
            };
            tempPolyline = [...tempPolyline, tempPoly];
          } else {
            tempPolyline.length &&
              tempPolyline[tempPolyline.length - 1].point.push(addPoint);
            const tempPoly = {
              point: [addPoint],
              color: "#0052BD",
            };
            tempPolyline = [...tempPolyline, tempPoly];
          }
        }
      });

      tempPolyline.forEach((element: any) => {
        polylines = [
          ...polylines,
          new google.maps.Polyline({
            path: element.point,
            geodesic: true,
            strokeColor: element.color,
            strokeOpacity: 1.0,
            strokeWeight: 5,
            map: map,
          }),
        ];
      });

      return polylines;
    }

    onRemove() {
      if (this.markersList.length > 0) {
        this.cluster && this.cluster.clearMarkers();
      }

      this.markersList.map((e: any) => e.setMap(null));
      let _polyline = this.polyline;
      _polyline.forEach((item: any) => {
        !item.id && item.getPath().clear();
        item.setMap(null);
      });
      _polyline = [];

      let _incrementalPolyline = this.incrementalPolyline;
      _incrementalPolyline.forEach((item: any) => {
        !item.id && item.getPath().clear();
        item.setMap(null);
      });
      _incrementalPolyline = [];

      let _viewMarkerOnPolyline = this.gpsPointMarkers;
      _viewMarkerOnPolyline.forEach((item: any) => {
        item.setMap(null);
      });
      _viewMarkerOnPolyline = [];
    }

    settingIncrementalPolyline(
      newCurrentPoint: ClassPolylineProps["initialCurrentPoint"],
      hasMarker: boolean
    ) {
      // console.log("routeId: ", gpsData[0]?.routeId);
      // console.log("newCurrentPoint: ", newCurrentPoint);

      if (!gpsData) return;

      if (!newCurrentPoint) return;

      if (!this.incrementalPolyline.length) {
        if (newCurrentPoint.indexPoint > 0) {
          this.incrementalPolyline = [
            ...this.incrementalPolyline,
            new google.maps.Polyline({
              path: this.drawingIncremental(newCurrentPoint.indexPoint),
              geodesic: true,
              strokeColor: "#00AAFF",
              strokeOpacity: 1.0,
              strokeWeight: 5,
              map: map,
              zIndex: 2800,
            }),
          ];
        }
      }
      if (newCurrentPoint === this.currentPoint) return;
      this.currentPoint = newCurrentPoint;

      this.incrementalPolyline.forEach((item: any) => {
        if (!item.id) {
          item.getPath().clear();
          item.setMap(null);
        }
      });
      this.incrementalPolyline.splice(1, this.incrementalPolyline.length - 1);

      const markerPolylinePrec = this.returnMarker(this.incrementalPolyline);
      if (markerPolylinePrec) {
        markerPolylinePrec.setMap(null);
      }

      if (hasMarker) {
        let firstDirection: number = 0;
        gpsData.forEach((point) => {
          if (
            point?.dynamicFields?.direction &&
            point?.dynamicFields?.direction > 0
          ) {
            firstDirection = point?.dynamicFields?.direction;
          }
        });
        const tempMarkerPolyline = this.addMarker(
          <MapMarkerLocator rotate={firstDirection} />,
          "batchPolyLineMarker",
          gpsData[newCurrentPoint.indexPoint]?.dynamicFields.latitude,
          gpsData[newCurrentPoint.indexPoint]?.dynamicFields.longitude
        );

        this.incrementalPolyline = [
          ...this.incrementalPolyline,
          tempMarkerPolyline,
        ];

        const markerPolylineTemp = this.returnMarker(this.incrementalPolyline);
        markerPolylineTemp.setMap(null);

        markerPolylineTemp.setPosition(
          new google.maps.LatLng(
            gpsData[newCurrentPoint.indexPoint]?.dynamicFields.latitude || 0,
            gpsData[newCurrentPoint.indexPoint]?.dynamicFields.longitude || 0
          )
        );
        if (markerPolylineTemp) {
          markerPolylineTemp.setComponent(
            <MapMarkerLocator
              rotate={
                gpsData[newCurrentPoint.indexPoint]?.dynamicFields.direction
              }
            />
          );
        }

        markerPolylineTemp.setMap(map);
      }
      this.incrementalPolyline = [
        ...this.incrementalPolyline,
        new google.maps.Polyline({
          path: this.drawingIncremental(newCurrentPoint.indexPoint),
          geodesic: true,
          strokeColor: "#00AAFF",
          strokeOpacity: 1.0,
          strokeWeight: 5,
          map: map,
          zIndex: 2800,
        }),
      ];
    }

    returnMarker(object: any): any {
      return object.find((element: any) => {
        if (element.id && element.id === "batchPolyLineMarker") {
          return element;
        }
        return null;
      });
    }

    drawingIncremental(indexPoint: number) {
      let gpsPoints = [] as any;
      if (gpsData) {
        for (let i = 0; i <= indexPoint; i++) {
          gpsPoints = [
            ...gpsPoints,
            {
              lat: Number(gpsData[i]?.dynamicFields.latitude),
              lng: Number(gpsData[i]?.dynamicFields.longitude),
            },
          ];
        }
      }
      return gpsPoints;
    }

    addMarker(
      image: JSX.Element,
      id: string,
      lat: number = 0,
      lng: number = 0,
      viewOnMap: any = map
    ) {
      return MarkerAsComponent({
        googleMap: googleMap,
        id: id,
        lat: lat,
        lng: lng,
        map: viewOnMap,
        component: image,
        onClick: () => {},
        showTooltip: false,
        isGeofence: false,
      });
    }

    onRemoveMarker(marker: any) {
      if (marker) {
        marker.setMap(null);
      }
    }
  }

  return new SummaryClassPolyline();
};
