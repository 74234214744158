import i18next, { t } from "i18next";
import _ from "lodash";
import { renderToString } from "react-dom/server";
import { ConvertTimeZone } from "../../utils/DateAndTimeUtils";
import {
  formatTimestamp,
  getIconFromVehicleType,
  kmToMiles,
  numberAnnotation,
} from "../../utils/Utils";
import { Button } from "../Button/Button";
import { IconArrowLeft } from "../Icon/Line/ArrowLeft";
import { IconArrowRight } from "../Icon/Line/ArrowRight";
import { IconClock } from "../Icon/Line/Clock";
import { IconSpeed } from "../Icon/Line/Speed";
import { IconDriver } from "../Icon/Solid/Driver";
import { IconLocator } from "../Icon/Solid/Locator";
import { IconPin } from "../Icon/Solid/Pin";
import {
  PublicRoutePointInfoStyle,
  renderClusterInfo,
} from "../Map/TooltipStyles";
import { ComponentPetals } from "../Marker/ComponentPetals";
import { MapMarkerEventLocation } from "../Marker/MapMarkerEventLocation";
import {
  ArrowInfoTooltipStyle,
  ClusterEventInfoThreeTooltipStyle,
  ClusterEventInfoTooltipStyle,
  ClusterEventInfoTwoTooltipStyle,
  EventInfoTooltipStyle,
  FleetControlClusterInfoStyle,
  RouteInfoTooltipStyle,
} from "./TooltipStyles";

let previousClusterInfoWindow = null;
let petalElement = null;
let prevElementCluster = null;
let currElementCluster = null;
let numberOfElement = null;
let events = [];
let eventElement = null;
let tooltipId = null;

export const MarkerAsComponent = ({
  googleMap,
  id,
  lat,
  lng,
  address,
  map,
  component,
  eventInfo,
  vehicleInfo,
  fleetControlClusterInfo,
  routeInfo,
  geofence,
  arrowInfo,
  onClick,
  showTooltip,
  pointPublicTransportInfo,
  clusterPublicTransportInfo,
  infoWindow,
  clusterInfo,
  status,
  eventType,
  driver,
  toolTipDetailsClick,
  toolTipTrackingClick,
  show = true,
  preferences,
  navigate,
  event,
  hasArrowCluster = false,
}) => {
  const getOrderedObjectsByPropertyName = (array, propertyName) => {
    let uniqueObjects;
    if (!_.isEmpty(array)) {
      uniqueObjects = _.sortBy(array, propertyName);
    }
    return uniqueObjects;
  };

  let alarmEvent = [];
  let warningEvent = [];
  let infoEvent = [];

  if (clusterInfo) {
    if (!_.isEmpty(clusterInfo.ALARM)) {
      alarmEvent = getOrderedObjectsByPropertyName(
        clusterInfo.ALARM,
        "timestamp"
      );
    }

    if (!_.isEmpty(clusterInfo.WARNING)) {
      warningEvent = getOrderedObjectsByPropertyName(
        clusterInfo.WARNING,
        "timestamp"
      );
    }

    if (!_.isEmpty(clusterInfo.INFO)) {
      infoEvent = getOrderedObjectsByPropertyName(
        clusterInfo.INFO,
        "timestamp"
      );
    }
  }

  function checkEvents(eventList, hasParking) {
    const populatedCategories = [];

    if (eventList.ALARM && eventList.ALARM.length > 0) {
      populatedCategories.push("ALARM");
    }
    if (eventList.INFO && eventList.INFO.length > 0) {
      populatedCategories.push("INFO");
    }
    if (eventList.WARNING && eventList.WARNING.length > 0) {
      populatedCategories.push("WARNING");
    }
    if (hasParking) {
      if (eventList.PARKING && eventList.PARKING.length > 0) {
        populatedCategories.push("PARKING");
      }
    }

    return populatedCategories;
  }

  if (googleMap) {
    class CustomMarker extends googleMap.maps.OverlayView {
      constructor() {
        super();
        this.id = id;
        this.title = id;
        this.latlng_ = new googleMap.maps.LatLng(lat, lng);
        this.component = component;
        this.eventInfo = eventInfo;
        this.showTooltip = showTooltip;
        this.status = status;
        this.eventType = eventType;
        this.event = event;
        this.clusterInfo = clusterInfo;
        this.currentEventIndexAlarm = 0;
        this.currentEventIndexWarning = 0;
        this.currentEventIndexInfo = 0;
        this.hasArrowCluster = hasArrowCluster;
        this.setMarkerToolTip();

        this.changeInfowindowContent = (events, eventType) => {
          if (eventType === "Info") {
            tooltipId = null;
            // Change Text in tooltip
            let infowindowText = document.getElementById(
              "infowindow-text-info"
            );
            if (infowindowText) {
              infowindowText.textContent = t(
                "eventType." + events[this.currentEventIndexInfo].name
              );
            }

            let infowindowTimestamp = document.getElementById(
              "infowindow-timestamp-info"
            );

            if (infowindowTimestamp) {
              infowindowTimestamp.textContent = ConvertTimeZone(
                new Date(events[this.currentEventIndexInfo].timestamp),
                preferences.timeZone,
                preferences.localeFormat
              ).replace(",", " |");
            }

            // Change Icon in tooltip
            let infowindowIcon = document.getElementById(
              "infowindow-icon-info"
            );
            if (infowindowIcon) {
              infowindowIcon.innerHTML = `${renderToString(
                <MapMarkerEventLocation
                  type={infoEvent[this.currentEventIndexInfo].name}
                  hasTooltip={true}
                />
              )}`;
            }

            let infoWindowTooltipId =
              document.getElementById("event-info-tooltip");

            if (infoWindowTooltipId) {
              infoWindowTooltipId.childNodes.forEach((node) => {
                if (node && node.id) {
                  tooltipId = `eventTooltipCluster-${
                    infoEvent[this.currentEventIndexInfo].id
                  }/${infoEvent[this.currentEventIndexInfo].timestamp}`;
                }
              });
            }

            // console.log(infoWindowTooltipId);
          } else if (eventType === "Warning") {
            tooltipId = null;
            // Change Text in tooltip
            let infowindowText = document.getElementById(
              "infowindow-text-warning"
            );
            if (infowindowText) {
              infowindowText.textContent = t(
                "eventType." + events[this.currentEventIndexWarning].name
              );
            }

            let infowindowTimestamp = document.getElementById(
              "infowindow-timestamp-warning"
            );

            if (infowindowTimestamp) {
              infowindowTimestamp.textContent = ConvertTimeZone(
                new Date(events[this.currentEventIndexWarning].timestamp),
                preferences.timeZone,
                preferences.localeFormat
              ).replace(",", " |");
            }

            // Change Icon in tooltip
            let infowindowIcon = document.getElementById(
              "infowindow-icon-warning"
            );
            if (infowindowIcon) {
              infowindowIcon.innerHTML = `${renderToString(
                <MapMarkerEventLocation
                  type={warningEvent[this.currentEventIndexWarning].name}
                  hasTooltip={true}
                />
              )}`;
            }

            let infoWindowTooltipId = document.getElementById(
              "event-warning-tooltip"
            );

            if (infoWindowTooltipId) {
              infoWindowTooltipId.childNodes.forEach((node) => {
                if (node && node.id) {
                  tooltipId = `eventTooltipCluster-${
                    warningEvent[this.currentEventIndexWarning].id
                  }/${warningEvent[this.currentEventIndexWarning].timestamp}`;
                }
              });
            }
          } else if (eventType === "Alarm") {
            tooltipId = null;
            // Change Text in tooltip
            let infowindowText = document.getElementById(
              "infowindow-text-alarm"
            );
            if (infowindowText) {
              infowindowText.textContent = t(
                "eventType." + events[this.currentEventIndexAlarm].name
              );
            }

            let infowindowTimestamp = document.getElementById(
              "infowindow-timestamp-alarm"
            );

            if (infowindowTimestamp) {
              infowindowTimestamp.textContent = ConvertTimeZone(
                new Date(events[this.currentEventIndexAlarm].timestamp),
                preferences.timeZone,
                preferences.localeFormat
              ).replace(",", " |");
            }

            // Change Icon in tooltip
            let infowindowIcon = document.getElementById(
              "infowindow-icon-alarm"
            );
            if (infowindowIcon) {
              infowindowIcon.innerHTML = `${renderToString(
                <MapMarkerEventLocation
                  type={alarmEvent[this.currentEventIndexAlarm].name}
                  hasTooltip={true}
                />
              )}`;
            }

            let infoWindowTooltipId = document.getElementById(
              "event-alarm-tooltip"
            );

            if (infoWindowTooltipId) {
              infoWindowTooltipId.childNodes.forEach((node) => {
                if (node && node.id) {
                  tooltipId = `eventTooltipCluster-${
                    alarmEvent[this.currentEventIndexAlarm].id
                  }/${alarmEvent[this.currentEventIndexAlarm].timestamp}`;
                }
              });
            }
          }
        };

        this.previousInfowindowText = (events, eventCategory) => {
          if (eventCategory === "Alarm") {
            this.currentEventIndexAlarm--;
            if (this.currentEventIndexAlarm < 0) {
              this.currentEventIndexAlarm = 0;
            }
            this.changeInfowindowContent(events, eventCategory);
            this.updateButtonStates(events, eventCategory);
          } else if (eventCategory === "Warning") {
            this.currentEventIndexWarning--;
            if (this.currentEventIndexWarning < 0) {
              this.currentEventIndexWarning = 0;
            }
            this.changeInfowindowContent(events, eventCategory);
            this.updateButtonStates(events, eventCategory);
          } else if (eventCategory === "Info") this.currentEventIndexInfo--;
          if (this.currentEventIndexInfo < 0) {
            this.currentEventIndexInfo = 0;
          }
          this.changeInfowindowContent(events, eventCategory);
          this.updateButtonStates(events, eventCategory);
        };

        this.nextInfowindowText = (events, eventCategory) => {
          if (eventCategory === "Alarm") {
            this.currentEventIndexAlarm++;
            if (this.currentEventIndexAlarm >= events.length) {
              this.currentEventIndexAlarm = events.length - 1;
            }
            this.changeInfowindowContent(events, eventCategory);
            this.updateButtonStates(events, eventCategory);
          } else if (eventCategory === "Warning") {
            this.currentEventIndexWarning++;
            if (this.currentEventIndexWarning >= events.length) {
              this.currentEventIndexWarning = events.length - 1;
            }
            this.changeInfowindowContent(events, eventCategory);
            this.updateButtonStates(events, eventCategory);
          } else if (eventCategory === "Info") {
            this.currentEventIndexInfo++;
            if (this.currentEventIndexInfo >= events.length) {
              this.currentEventIndexInfo = events.length - 1;
            }
            this.changeInfowindowContent(events, eventCategory);
            this.updateButtonStates(events, eventCategory);
          }
        };

        this.updateButtonStates = (events, eventType) => {
          if (eventType === "Info") {
            let prevButton = document.getElementById("prev-button-info");
            let nextButton = document.getElementById("next-button-info");

            if (this.currentEventIndexInfo !== 0) {
              prevButton.style.display = "inline-block";
              prevButton.style.marginRight = "10px";
            } else {
              prevButton.style.display = "none";
            }

            if (this.currentEventIndexInfo === events.length - 1) {
              nextButton.style.display = "none";
            } else {
              if (prevButton.style.display === "inline-block") {
                nextButton.style.width = "15px";
              }
              nextButton.style.display = "inline-block";
            }
          } else if (eventType === "Warning") {
            let prevButton = document.getElementById("prev-button-warning");
            let nextButton = document.getElementById("next-button-warning");

            if (this.currentEventIndexWarning !== 0) {
              prevButton.style.display = "inline-block";
              prevButton.style.marginRight = "10px";
            } else {
              prevButton.style.display = "none";
            }

            if (this.currentEventIndexWarning === events.length - 1) {
              nextButton.style.display = "none";
            } else {
              if (prevButton.style.display === "inline-block") {
                nextButton.style.width = "15px";
              }
              nextButton.style.display = "inline-block";
            }
          } else if (eventType === "Alarm") {
            let prevButton = document.getElementById("prev-button-alarm");
            let nextButton = document.getElementById("next-button-alarm");

            if (this.currentEventIndexAlarm !== 0) {
              prevButton.style.display = "inline-block";
              prevButton.style.marginRight = "10px";
            } else {
              prevButton.style.display = "none";
            }
            if (this.currentEventIndexAlarm === events.length - 1) {
              nextButton.style.display = "none";
            } else {
              if (prevButton.style.display === "inline-block") {
                nextButton.style.width = "15px";
              }
              nextButton.style.display = "inline-block";
            }
          }
        };

        this.previousInfowindowText = this.previousInfowindowText.bind(this);
        this.nextInfowindowText = this.nextInfowindowText.bind(this);
        this.updateButtonStates = this.updateButtonStates.bind(this);
        this.changeInfowindowContent = this.changeInfowindowContent.bind(this);

        // Once the LatLng and text are set, add the overlay to the map.  This will
        // trigger a call to panes_changed which should in turn call draw.
        this.setMap(map);
      }

      setMarkerToolTip() {
        if (vehicleInfo) {
          if (previousClusterInfoWindow !== null) {
            previousClusterInfoWindow.close();
          }
          this.infoWindowTemplate = `
          <button 
            style='float: right; background: transparent; border: none; padding: 1px;' 
            onClick='this.closest(".gm-style-iw-a").remove(); return false;'
          >
          <div class="svg" style='float: right;'>
            <span style='font-size: 12px;color: var(--global-colors-ui-white);top: 12px !important;position: absolute;right: 12px !important;'>
              &#xe81d;
            </span> 
          </div>
          </button>
          <div style='margin-bottom: 10px; display: flex; flex-direction: row; align-items: center'>
          <b style='font-family: var(--global-font-semi-bold); font-size: 12px;'> <div style='font-size: 14px; color:white'>${renderToString(
            getIconFromVehicleType(
              vehicleInfo?.type,
              14,
              "--global-colors-ui-white"
            )
          )}</div> </b>
          <span style='padding-left: 10px;'> ${
            " " + vehicleInfo?.alias + " | " + vehicleInfo?.plate
          } </span></div>
          <div style='margin-bottom: 10px; display: flex; flex-direction: row; align-items: center'>
          <b style='font-family: var(--global-font-semi-bold); font-size: 12px; padding-left: 1px;'> ${
            driver
              ? renderToString(<IconDriver size={14} color="white" />)
              : i18next.t("common.status")
          }  </b>
          <span style='padding-left: 9px;'> ${
            driver
              ? driver
              : " " +
                vehicleInfo?.status?.charAt(0) +
                vehicleInfo?.status?.toLowerCase().slice(1)
          }
          </span></div>
          <div style='margin-bottom: 10px; display: flex; flex-direction: row; align-items: center'>
          <b style='font-family: var(--global-font-semi-bold); font-size: 12px;'>${
            " " + renderToString(<IconPin size={14} color="white" />)
          } </b><div style='padding-left: 10px;'>${address} </div></div>
          <div style='margin-bottom: 10px; display: flex; flex-direction: row; align-items: center'>
          <b style='font-family: var(--global-font-semi-bold); font-size: 12px; padding-left: 1px;'> ${renderToString(
            <IconClock size={14} color="white" />
          )} </b>
          <span style='padding-left: 9px;'> ${
            " " + vehicleInfo?.lastUpdate.replace(/,/g, " | ")
          } </span></div> 
          
          ${
            vehicleInfo?.status === "MOVING" && vehicleInfo?.speed !== undefined
              ? `
          <div style='margin-bottom: 10px; display: flex; flex-direction: row; align-items: center'>
          <b style='font-family: var(--global-font-semi-bold); font-size: 12px; padding-left: 1px;'> ${renderToString(
            <IconSpeed size={14} color="white" />
          )} </b>
          <span style='padding-left: 9px;'> ${
            " " + vehicleInfo?.speed + " " + "Km/h"
          } </span>
          </div>`
              : ""
          }
          </div>
          <div style='display: flex; margin-top:20px;'>
          ${renderToString(
            <Button
              id="details-button"
              aspect="secondary"
              size="small"
              label={i18next.t("common.detailsTooltip")}
            />
          )}
          ${renderToString(
            <Button
              id="tracking-button"
              aspect="primary"
              size="small"
              disabled={
                vehicleInfo?.status === "PARKING" ||
                vehicleInfo?.status === "UNKNOWN" ||
                vehicleInfo?.status === "OFFLINE"
              }
              label={i18next.t("common.liveTrackTooltip")}
            >
              <IconLocator size={14} color={"--global-colors-ui-white"} />
            </Button>
          )}
            </div>`;
        } else if (geofence) {
          this.infoWindowTemplate = geofence;
        } else if (routeInfo) {
          this.infoWindowTemplate = `
          <button 
            style='float: right; background: transparent; border: none; padding: 1px;' 
            onClick='this.closest(".gm-style-iw-a").remove(); return false;'
          >
          <div class="svg" style='float: right;'>
            <span style='font-size: 12px;color: var(--global-colors-ui-white);top: 12px !important;position: absolute;right: 12px !important;'>
              &#xe81d;
            </span> 
          </div>
          </button>
          ${
            routeInfo?.start
              ? `<div style="margin-bottom: 9px;">
                <b style="font-family: var(--global-font-semi-bold); font-size: 12px;">
                  ${i18next.t("common.start")}:
                </b>
                <span>
                  ${" " + routeInfo?.start.replace(/,/g, " | ")}
                </span>
              </div>`
              : ""
          }
          <div style='margin-bottom: 9px;'>          
          <b style='font-family: var(--global-font-semi-bold); font-size: 12px;'> ${
            routeInfo?.start
              ? i18next.t("common.end")
              : i18next.t("common.date")
          }: </b>
          <span> ${" " + routeInfo?.end.replace(/,/g, " | ")} </span></div>
         ${
           routeInfo?.duration
             ? `<div style='margin-bottom: 9px;'>
          <b style='font-family: var(--global-font-semi-bold); font-size: 12px;'> ${i18next.t(
            "common.duration"
          )}: </b>
          <span> ${" " + routeInfo?.duration}
          </span></div>`
             : ""
         }
          <div>
          <b style='font-family: var(--global-font-semi-bold); font-size: 12px;'>${
            " " + i18next.t("common.location")
          }: </b><span> ${routeInfo?.address} </span></div>`;
        } else if (eventInfo) {
          this.infoWindowTemplate = `
          <div id='event-style' style='display:flex; cursor:pointer;'  >
            <div style='top:2px; position:relative;'> 
          ${renderToString(
            <MapMarkerEventLocation
              type={eventInfo?.name}
              hasTooltip={true}
              preferences={preferences}
            />
          )}
          </div>
             <div style='margin: 0px 0px 0px 5px; font-size: 12px; display: flex;min-width: 164px;'>
             <div style='width: 180px; height: 30px; position: relative; margin: 7px 0px 0px 6px;'>
             <span> ${t(`eventType.${eventInfo?.name}`)} 
             </span>
             <span> ${
               eventInfo?.name === "SPEED_LIMIT"
                 ? eventInfo?.speed
                   ? preferences?.isMetric
                     ? ":" + " " + numberAnnotation(eventInfo?.speed) + " km/h"
                     : ":" +
                       " " +
                       numberAnnotation(kmToMiles(eventInfo?.speed)) +
                       " mph"
                   : ""
                 : ""
             }
           </span>
             <div  style='font-size: 10px; color: var(--global-colors-ink-light); width: fit-content; height: fit-content;'>
             ${
               eventInfo?.timestamp &&
               ConvertTimeZone(
                 new Date(eventInfo?.timestamp),
                 preferences.timeZone,
                 preferences.localeFormat
               ).replace(",", " |")
             }
             </div>
             </div>
            </div></div>`;
        } else if (clusterInfo) {
          this.currentEventIndexInfo = 0;
          this.currentEventIndexAlarm = 0;
          this.currentEventIndexWarning = 0;
          this.infoWindowTemplate = `
          ${
            !_.isEmpty(alarmEvent)
              ? `
              <div style='display:flex; cursor:pointer; background: white; border-radius:6px; margin-bottom:5px; padding: 3px;'>
                <div style='font-size:12px; display:flex;'>
                  <div id="prev-button-alarm" style='display:none'>
                    ${
                      alarmEvent.length > 1 && this.currentEventIndexAlarm !== 0
                        ? renderToString(
                            <div className="prev-button">
                              <Button
                                id="prev-button-alarm"
                                aspect="secondary"
                                size="small"
                              >
                                <IconArrowLeft
                                  size={14}
                                  color="--global-colors-ink-light"
                                />
                              </Button>
                            </div>
                          )
                        : ""
                    }
                  </div>
                  <div id='event-alarm-tooltip' style='display:flex;'>
                  <div id='eventTooltipCluster-${
                    alarmEvent[this.currentEventIndexAlarm].id
                  }/${
                  alarmEvent[this.currentEventIndexAlarm].timestamp
                }' style='top:2px; position:relative;'> 
                    ${renderToString(
                      <div id="infowindow-icon-alarm">
                        <MapMarkerEventLocation
                          type={alarmEvent[this.currentEventIndexAlarm].name}
                          hasTooltip={true}
                        />
                      </div>
                    )}
                  </div>
                  <div id='eventTooltipCluster-${
                    alarmEvent[this.currentEventIndexAlarm].id
                  }/${
                  alarmEvent[this.currentEventIndexAlarm].timestamp
                }'  style='width: 155px; height: 30px; position: relative; margin: 7px 0px 0px 6px;'>
                    <span id="infowindow-text-alarm" style='font-size: 12px; width: fit-content;'> 
                      ${t(
                        `eventType.${
                          alarmEvent[this.currentEventIndexAlarm].name
                        }`
                      )}
                    </span>
                    <div id="infowindow-timestamp-alarm" style='font-size: 10px; color: var(--global-colors-ink-light); width: fit-content; height: fit-content;'>
                      ${
                        alarmEvent[this.currentEventIndexAlarm].timestamp &&
                        ConvertTimeZone(
                          new Date(
                            alarmEvent[this.currentEventIndexAlarm].timestamp
                          ),
                          preferences.timeZone,
                          preferences.localeFormat
                        ).replace(",", " |")
                      }
                    </div>
                  </div>
                </div>
                  <div id="next-button-alarm" style='width: 32px; text-align: end;'>
                    ${
                      alarmEvent.length > 1
                        ? renderToString(
                            <div className="next-button">
                              <Button
                                id="next-button-alarm"
                                aspect="secondary"
                                size="small"
                              >
                                <IconArrowRight
                                  size={14}
                                  color="--global-colors-ink-light"
                                />
                              </Button>
                            </div>
                          )
                        : ""
                    }
                  </div>
                </div>
              </div>`
              : ""
          }
          ${
            !_.isEmpty(warningEvent)
              ? `
              <div style='display:flex; cursor:pointer; background: white; border-radius:6px; margin-bottom:5px; padding: 3px;'>
                <div style='font-size:12px; display:flex;'>
                  <div id="prev-button-warning" style='display:none'>
                    ${
                      warningEvent.length > 1
                        ? renderToString(
                            <div className="prev-button">
                              <Button
                                id="prev-button-warning"
                                aspect="secondary"
                                size="small"
                              >
                                <IconArrowLeft
                                  size={14}
                                  color="--global-colors-ink-light"
                                />
                              </Button>
                            </div>
                          )
                        : ""
                    }
                  </div>
                  <div id='event-warning-tooltip' style='display:flex;'>
                  <div id='eventTooltipCluster-${
                    warningEvent[this.currentEventIndexWarning].id
                  }/${
                  warningEvent[this.currentEventIndexWarning].timestamp
                }' style='top:2px; position:relative;'> 
                    ${renderToString(
                      <div id="infowindow-icon-warning">
                        <MapMarkerEventLocation
                          type={
                            warningEvent[this.currentEventIndexWarning].name
                          }
                          hasTooltip={true}
                        />
                      </div>
                    )}
                  </div>
                  <div id='eventTooltipCluster-${
                    warningEvent[this.currentEventIndexWarning].id
                  }/${warningEvent[this.currentEventIndexWarning].timestamp}'
                   style='width: 155px; height: 30px; position: relative; margin: 7px 0px 0px 6px;'>
                    <span id="infowindow-text-warning" style='font-size: 12px; width: fit-content;'> 
                      ${t(
                        `eventType.${
                          warningEvent[this.currentEventIndexWarning].name
                        }`
                      )}
                    </span>
                    <div id="infowindow-timestamp-warning" style='font-size: 10px; color: var(--global-colors-ink-light); width: fit-content; height: fit-content;'>
                      ${
                        warningEvent[this.currentEventIndexWarning].timestamp &&
                        ConvertTimeZone(
                          new Date(
                            warningEvent[
                              this.currentEventIndexWarning
                            ].timestamp
                          ),
                          preferences.timeZone,
                          preferences.localeFormat
                        ).replace(",", " |")
                      }
                    </div>
                  </div>
                </div>
                  <div id="next-button-warning" style='width: 32px; text-align: end;'>
                    ${
                      warningEvent.length > 1
                        ? renderToString(
                            <div className="next-button">
                              <Button
                                id="next-button-warning"
                                aspect="secondary"
                                size="small"
                              >
                                <IconArrowRight
                                  size={14}
                                  color="--global-colors-ink-light"
                                />
                              </Button>
                            </div>
                          )
                        : ""
                    }
                  </div>
                </div>
              </div>`
              : ""
          }
          ${
            !_.isEmpty(infoEvent)
              ? `
              <div style='display:flex; cursor:pointer; background: white; border-radius:6px; margin-bottom:5px; padding: 3px;'>
                <div style='font-size:12px; display:flex;'>
                  <div id="prev-button-info" style='display:none'>
                    ${
                      infoEvent.length > 1
                        ? renderToString(
                            <div className="prev-button">
                              <Button
                                id="prev-button-info"
                                aspect="secondary"
                                size="small"
                              >
                                <IconArrowLeft
                                  size={14}
                                  color="--global-colors-ink-light"
                                />
                              </Button>
                            </div>
                          )
                        : ""
                    }
                  </div>
                  <div id='event-info-tooltip' style='display:flex;'>
                  <div id='eventTooltipCluster-${
                    infoEvent[this.currentEventIndexInfo].id
                  }/${
                  infoEvent[this.currentEventIndexInfo].timestamp
                }' style='top:2px; position:relative;'> 
                    ${renderToString(
                      <div id="infowindow-icon-info">
                        <MapMarkerEventLocation
                          type={infoEvent[this.currentEventIndexInfo].name}
                          hasTooltip={true}
                        />
                      </div>
                    )}
                  </div>
                  <div id='eventTooltipCluster-${
                    infoEvent[this.currentEventIndexInfo].id
                  }/${
                  infoEvent[this.currentEventIndexInfo].timestamp
                }'style='width: 155px; height: 30px; position: relative; margin: 7px 0px 0px 6px;'>
                    <span id="infowindow-text-info" style='font-size: 12px; width: fit-content;'> 
                      ${t(
                        `eventType.${
                          infoEvent[this.currentEventIndexInfo].name
                        }`
                      )}
                    </span>
                    <div id="infowindow-timestamp-info" style='font-size: 10px; color: var(--global-colors-ink-light); width: fit-content; height: fit-content;'>
                      ${
                        infoEvent[this.currentEventIndexInfo].timestamp &&
                        ConvertTimeZone(
                          new Date(
                            infoEvent[this.currentEventIndexInfo].timestamp
                          ),
                          preferences.timeZone,
                          preferences.localeFormat
                        ).replace(",", " |")
                      }
                    </div>
                  </div>
                </div>
                  <div id="next-button-info" style='width: 32px; text-align: end;'>
                    ${
                      infoEvent.length > 1
                        ? renderToString(
                            <div className="next-button">
                              <Button
                                id="next-button-info"
                                aspect="secondary"
                                size="small"
                              >
                                <IconArrowRight
                                  size={14}
                                  color="--global-colors-ink-light"
                                />
                              </Button>
                            </div>
                          )
                        : ""
                    }
                  </div>
                </div>
              </div>`
              : ""
          }
        `;
        } else if (arrowInfo) {
          this.infoWindowTemplate = `
          <div style='margin-bottom: 9px;'>
          <b style='font-family: var(--global-font-semi-bold); font-size: 12px;'> ${i18next.t(
            "common.date"
          )}: </b>
          <span> ${" " + formatTimestamp(arrowInfo?.date)} </span></div>
          <div style='margin-bottom: 9px;'>
          <b style='font-family: var(--global-font-semi-bold); font-size: 12px;'> ${i18next.t(
            "common.speed"
          )}: </b>
          <span> ${" " + arrowInfo?.speed + " " + "km/h"}
          </span></div>
          <div>
          <b style='font-family: var(--global-font-semi-bold); font-size: 12px;'>${
            " " + i18next.t("common.address")
          }: </b><span> ${arrowInfo?.address} </span></div>`;
        } else if (fleetControlClusterInfo) {
          this.infoWindowTemplate = renderClusterInfo(fleetControlClusterInfo);
        } else if (pointPublicTransportInfo) {
          this.infoWindowTemplate = `
         <div style="margin-bottom: 9px;">
          <b style="font-family: var(--global-font-semi-bold); font-size: 12px;">
          </b>
          <span>${pointPublicTransportInfo?.address}</span>
        </div>
          `;
        } else if (clusterPublicTransportInfo) {
          this.infoWindowTemplate = `
          <div style="margin-bottom: 9px;">
           <b style="font-family: var(--global-font-semi-bold); font-size: 12px;">
           </b>
           <span>${
             t("common.numberOfStops") + ": " + clusterPublicTransportInfo
           }</span>
         </div>
           `;
        }
      }

      onAdd() {
        if (!this.div_) {
          // Create a overlay text DIV
          this.div_ = document.createElement("div");
          // Create the DIV representing our CustomMarker

          this.div_.id = this.id;
          if (hasArrowCluster) {
            this.div_.className = "custom-cluster-arrow";
          } else if (
            clusterInfo &&
            checkEvents(clusterInfo, false).length > 1
          ) {
            this.div_.className = "custom-cluster-petals";
          } else if (this.div_.id.includes("marker-public-stop-id")) {
            this.div_.className = "marker-public-stop";
          } else if (this.div_.id.includes("marker-public-stop-start-end-id")) {
            this.div_.className = "marker-public-stop-start-end";
          } else if (clusterInfo && checkEvents(clusterInfo).length > 1) {
            this.div_.className = "custom-cluster-petals";
          } else if (
            clusterInfo &&
            checkEvents(clusterInfo, false).length === 1
          ) {
            this.div_.className = "custom-cluster-single-event";
          } else {
            this.div_.className = "customMarker";
          }
          this.div_.style.display = show ? "flex" : "none";
          let mark = document.createElement("div");
          // Method used by server side rendering to convert a React component into HTML
          mark.innerHTML = renderToString(this.component);
          this.div_.appendChild(mark);
          const father = this;

          let tooltipPetals = document.createElement("div");
          mark.appendChild(tooltipPetals);
          mark.id = this.div_.id;
          tooltipPetals.className = "petals";

          mark?.addEventListener("mouseover", (event) => {
            if (father.showTooltip) {
              if (previousClusterInfoWindow) {
                previousClusterInfoWindow.close();
              }
              const style = document.createElement("style");
              if (arrowInfo) {
                style.innerHTML = ArrowInfoTooltipStyle();
                document.head.appendChild(style);
              } else if (clusterInfo) {
                // if (
                //   checkEvents(clusterInfo, true).length < 2 &&
                //   clusterInfo.PARKING.length === 0
                // ) {
                //   return;
                // }

                let totalLength = Object.values(clusterInfo).reduce(
                  (sum, arr) => sum + arr.length,
                  0
                );

                // #region render tooltip
                this.currentEventIndexAlarm =
                  this.currentEventIndexInfo =
                  this.currentEventIndexWarning =
                    0;

                const alarmListNotEmpty = !_.isEmpty(clusterInfo.ALARM);
                const warningListNotEmpty = !_.isEmpty(clusterInfo.WARNING);
                const infoListNotEmpty = !_.isEmpty(clusterInfo.INFO);

                const populatedListsCount = [
                  alarmListNotEmpty,
                  warningListNotEmpty,
                  infoListNotEmpty,
                ].filter(Boolean).length;

                const tooltipStyleFunctions = [
                  null,
                  ClusterEventInfoTooltipStyle,
                  ClusterEventInfoTwoTooltipStyle,
                  ClusterEventInfoThreeTooltipStyle,
                ];
                if (tooltipStyleFunctions[populatedListsCount]) {
                  style.innerHTML = tooltipStyleFunctions[populatedListsCount](
                    totalLength.toString()
                  );
                }

                if (petalElement) {
                  petalElement.innerHTML = "";
                }

                if (
                  clusterInfo.PARKING.length >= 0 &&
                  prevElementCluster &&
                  numberOfElement
                ) {
                  const prevLabelLedSpan =
                    prevElementCluster.querySelector(".parking-icon");
                  if (
                    prevLabelLedSpan &&
                    prevLabelLedSpan.innerHTML !==
                      '<span class="label-led">P</span>'
                  ) {
                    prevLabelLedSpan.innerHTML =
                      '<span class="label-led">P</span>';
                  }

                  const prevTargetDiv = prevElementCluster.querySelector(
                    'div[style="top:11px;position:relative"]'
                  );
                  if (prevTargetDiv) {
                    prevTargetDiv.textContent = numberOfElement;
                  }
                }

                if (event?.currentTarget?.childNodes) {
                  event.currentTarget.childNodes.forEach((element) => {
                    if (element.className === "petals") {
                      petalElement = element;
                    } else if (element.className === "cluster-event-marker") {
                      currElementCluster = element;
                    }
                  });

                  if (
                    currElementCluster &&
                    checkEvents(clusterInfo, false).length > 1
                  ) {
                    prevElementCluster = currElementCluster;
                  }

                  if (
                    petalElement &&
                    checkEvents(clusterInfo, false).length > 1
                  ) {
                    if (
                      clusterInfo &&
                      clusterInfo.PARKING.length > 0 &&
                      currElementCluster
                    ) {
                      const labelLedSpan =
                        currElementCluster?.querySelector(".parking-icon");
                      if (labelLedSpan) {
                        labelLedSpan.innerHTML = "";
                      }

                      const targetDiv = currElementCluster?.querySelector(
                        'div[style="top:11px;position:relative"]'
                      );
                      if (targetDiv) {
                        numberOfElement = targetDiv.innerText;
                        targetDiv.textContent = "P";
                      }
                    }

                    petalElement.innerHTML = renderToString(
                      <ComponentPetals
                        numberEvent={checkEvents(clusterInfo, false)}
                        clusterInfo={clusterInfo}
                        isLarge={
                          totalLength.toString().length > 2 ? "-large" : ""
                        }
                      />
                    );
                  }
                }

                document.head.appendChild(style);
                // #endregion render tooltip
              } else if (eventInfo) {
                style.innerHTML = EventInfoTooltipStyle();
                document.head.append(style);
              } else if (fleetControlClusterInfo) {
                this.setMarkerToolTip();
                style.innerHTML = FleetControlClusterInfoStyle();
                document.head.append(style);
              } else if (pointPublicTransportInfo) {
                style.innerHTML = PublicRoutePointInfoStyle();
                document.head.appendChild(style);
              } else if (clusterPublicTransportInfo) {
                style.innerHTML = PublicRoutePointInfoStyle();
                document.head.appendChild(style);
              } else {
                style.innerHTML = RouteInfoTooltipStyle();
                document.head.append(style);
              }

              infoWindow?.setContent(
                "<div className='scrollFix'>" +
                  this.infoWindowTemplate +
                  "</div>"
              );

              infoWindow?.open({
                anchor: this,
                map,
                shouldFocus: false,
              });

              previousClusterInfoWindow = infoWindow;

              if (pointPublicTransportInfo || clusterPublicTransportInfo) {
                mark?.addEventListener("mouseout", (event) => {
                  infoWindow.close();
                });
              }

              document
                .getElementById("event-style")
                ?.addEventListener("click", navigate);

              // #region OnClick on tooltip Cluster (petals)

              events.push(
                document.getElementById("event-alarm-tooltip")?.childNodes
              );
              events.push(
                document.getElementById("event-warning-tooltip")?.childNodes
              );
              events.push(
                document.getElementById("event-info-tooltip")?.childNodes
              );

              events = events.filter((e) => e !== undefined);

              tooltipId = null;

              for (let i = 0; i < events.length; i++) {
                for (let j = 0; j < events[i]?.length; j++) {
                  eventElement = events[i][j];
                  if (eventElement) {
                    eventElement.addEventListener(
                      "click",
                      (function (id) {
                        return function () {
                          if (tooltipId !== null) {
                            navigate(
                              tooltipId.replace("eventTooltipCluster-", "")
                            );
                          } else {
                            navigate(id.replace("eventTooltipCluster-", ""));
                          }
                        };
                      })(eventElement.id)
                    );
                  }
                }
              }

              // #endregion OnClick on tooltip Cluster (petals)
              // #region FleetContol

              document
                .getElementById("tracking-button")
                ?.addEventListener("click", toolTipTrackingClick);

              document
                .getElementById("details-button")
                ?.addEventListener("click", function () {
                  toolTipDetailsClick();
                  infoWindow?.close();
                });

              let vehicles = [];
              vehicles.push(
                document.getElementById(
                  "fleettooltip-vehicles-elements-container-moving"
                )?.childNodes
              );
              vehicles.push(
                document.getElementById(
                  "fleettooltip-vehicles-elements-container-stop"
                )?.childNodes
              );
              vehicles.push(
                document.getElementById(
                  "fleettooltip-vehicles-elements-container-parking"
                )?.childNodes
              );
              vehicles.push(
                document.getElementById(
                  "fleettooltip-vehicles-elements-container-offline"
                )?.childNodes
              );

              vehicles = vehicles.filter((e) => e !== undefined);

              for (let i = 0; i < vehicles.length; i++) {
                for (let j = 0; j < vehicles[i]?.length; j++) {
                  let vehicleElement = vehicles[i][j];
                  if (vehicleElement) {
                    vehicleElement.addEventListener(
                      "click",
                      (function (id) {
                        return function () {
                          previousClusterInfoWindow.close();
                          style.innerHTML = RouteInfoTooltipStyle();
                          navigate(
                            id.replace("fleettooltip-cluster-vehicle-", "")
                          );
                        };
                      })(vehicleElement.id)
                    );
                  }
                }
              }

              // #endregion FleetContol
              // #region button cluster event

              const content = this;
              // ALARM
              document
                .getElementById("prev-button-alarm")
                ?.addEventListener("click", function () {
                  content.previousInfowindowText(
                    getOrderedObjectsByPropertyName(
                      clusterInfo.ALARM,
                      "timestamp"
                    ),
                    "Alarm"
                  );
                });
              document
                .getElementById("next-button-alarm")
                ?.addEventListener("click", function () {
                  content.nextInfowindowText(
                    getOrderedObjectsByPropertyName(
                      clusterInfo.ALARM,
                      "timestamp"
                    ),
                    "Alarm"
                  );
                });

              // WARNING
              document
                .getElementById("prev-button-warning")
                ?.addEventListener("click", function () {
                  content.previousInfowindowText(
                    getOrderedObjectsByPropertyName(
                      clusterInfo.WARNING,
                      "timestamp"
                    ),
                    "Warning"
                  );
                });
              document
                .getElementById("next-button-warning")
                ?.addEventListener("click", function () {
                  content.nextInfowindowText(
                    getOrderedObjectsByPropertyName(
                      clusterInfo.WARNING,
                      "timestamp"
                    ),
                    "Warning"
                  );
                });

              // INFO
              document
                .getElementById("next-button-info")
                ?.addEventListener("click", function () {
                  content.nextInfowindowText(
                    getOrderedObjectsByPropertyName(
                      clusterInfo.INFO,
                      "timestamp"
                    ),
                    "Info"
                  );
                });
              document
                .getElementById("prev-button-info")
                ?.addEventListener("click", function () {
                  content.previousInfowindowText(
                    getOrderedObjectsByPropertyName(
                      clusterInfo.INFO,
                      "timestamp"
                    ),
                    "Info"
                  );
                });

              // #endregion button cluster event
            }
          });

          googleMap.maps.event.addListener(map, "click", function () {
            infoWindow?.close();

            // #region clusterEvent onClick
            mark?.addEventListener("click", function () {
              infoWindow?.close();
              if (onClick) {
                onClick();
              }
            });

            if (clusterInfo) {
              if (petalElement) {
                petalElement.innerHTML = "";
              }
              if (
                clusterInfo.PARKING.length > 0 &&
                prevElementCluster &&
                numberOfElement
              ) {
                const prevLabelLedSpan =
                  prevElementCluster.querySelector(".parking-icon");
                if (
                  prevLabelLedSpan &&
                  prevLabelLedSpan.innerHTML !==
                    '<span class="label-led">P</span>'
                ) {
                  prevLabelLedSpan.innerHTML =
                    '<span class="label-led">P</span>';
                }
                const prevTargetDiv = prevElementCluster.querySelector(
                  'div[style="top:11px;position:relative"]'
                );
                if (prevTargetDiv) {
                  prevTargetDiv.textContent = numberOfElement;
                }
              }
            }

            // #endregion clusterEvent onClick
          });
        }
        // add the overlay to the DOM
        let panes = this.getPanes();
        panes.overlayImage.appendChild(this.div_);
      }

      draw() {
        // Position the overlay
        let point = this.getProjection().fromLatLngToDivPixel(this.latlng_);
        if (point) {
          this.div_.style.left = point.x + "px";
          this.div_.style.top = point.y + "px";
        }
        if (arrowInfo) {
          this.div_.style.left = point.x + "px";
          this.div_.style.top = point.y + 27 + "px";
        }
      }

      onRemove() {
        if (this.div_) {
          if (this.div_.parentNode && this.div_.parentNode.removeChild) {
            this.div_.parentNode.removeChild(this.div_);
          }
        }
      }

      getPosition() {
        return this.latlng_;
      }

      setPosition(latitude, longitude) {
        this.latlng_ = new googleMap.maps.LatLng(latitude, longitude);
      }

      setComponent(newComponent) {
        this.component = newComponent;
        if (this.div_) {
          this.div_.firstChild.innerHTML = renderToString(this.component);
        }
      }

      setVisible(visible) {
        this.div_.style.display = visible ? "flex" : "none";
      }

      getVisible() {
        if (this.div_) {
          return this.div_.style.display;
        }
      }

      getDraggable() {}
    }
    return new CustomMarker();
  }
};
