import React, { useState, useEffect } from "react";
import { IconAdd } from "../Icon/Line/Add";
import { IconMinus } from "../Icon/Line/Minus";
import "./SliderZoom.css";

interface SliderZoomProps {
  data: {
    min: number;
    max: number;
  };
  val: number;
  step: number;
  visibled?: boolean;
  onChange: (e: any) => any;
}

export const SliderZoom = ({
  data,
  val,
  step,
  visibled,
  onChange,
}: SliderZoomProps) => {
  const [value, setValue] = useState(0);
  const [backgroundStyle, setBackgroundStyle] = useState({
    backgroundSize: "0% 100%",
  });

  useEffect(() => {
    setValue(val);
    setBackgroundStyle({
      backgroundSize: (val - 1) * (100 / (data.max - 1)) + "% 100%",
    });
  }, [val]);

  return (
    <div>
      <div className={"slider-zoom-container" + (visibled ? " visibled" : "")}>
        <label
          onClick={() => {
            value > data.min && onChange(value - step);
          }}
        >
          <IconMinus
            className={""}
            size={14}
            color="--global-colors-status-stopped"
          />
        </label>
        <div className="slider-zoom">
          <input
            type="range"
            min={data.min}
            max={data.max}
            value={value}
            style={backgroundStyle}
            step={step}
            onChange={(e) => {
              onChange(e.target.value);
            }}
          />
        </div>
        <label
          onClick={() => {
            value < data.max && onChange(value + step);
          }}
        >
          <IconAdd
            size={14}
            className={""}
            color="--global-colors-status-stopped"
          />
        </label>
      </div>
    </div>
  );
};
