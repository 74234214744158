import { t } from "i18next";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Button } from "../Button/Button";
import { Dropdown } from "../Dropdown/Dropdown";
import { Tooltip } from "../Forms/Tooltip";
import { IconArrowToLeft } from "../Icon/Line/ArrowToLeft";
import { IconArrowToRight } from "../Icon/Line/ArrowToRight";
import "./Card.css";

export const Card = ({
  title,
  children,
  linkAction,
  linkAnchor,
  className,
  valueChange,
  hasDropdown,
  titleColumn,
  linkDisabled,
  hasCollapse,
  titleTooltip,
  ...props
}) => {
  const [value, setValue] = useState([]);
  const [IsCollapsed, setIsCollapsed] = useState(false);

  useEffect(() => {
    if (value.length) {
      valueChange(value[0]);
    }
  }, [value]);

  const handleLinkClick = (e) => {
    e.preventDefault();
    linkAction();
  };

  const classnames = "mn-card " + className;

  const period = [
    {
      label: t("card.today"),
      value: "today",
    },
    {
      label: t("card.yesterday"),
      value: "yesterday",
    },
    {
      label: t("card.lastWeek"),
      value: "lastWeek",
    },
    {
      label: t("card.lastMonth"),
      value: "lastMonth",
    },
  ];

  return (
    <div className={classnames} {...props}>
      <div className="mn-card__header">
        {titleTooltip ? (
          <div key="lastTracks" data-for="tooltip" data-tip={titleTooltip}>
            {title}
          </div>
        ) : (
          <div>{title}</div>
        )}
        {hasCollapse && (
          <Button
            label=""
            size="small"
            aspect="ghost"
            onlyIcon={true}
            onClick={() => {
              setIsCollapsed((collapse) => !collapse);
            }}
          >
            {IsCollapsed ? (
              <IconArrowToLeft size={14} color="--global-colors-ink-dark" />
            ) : (
              <IconArrowToRight size={14} color="--global-colors-ink-dark" />
            )}
          </Button>
        )}
        {linkAnchor &&
          typeof linkAction === "function" &&
          (linkDisabled ? (
            <>
              <Tooltip />
              <span
                key="underDevelopment"
                className="underDevelopment"
                data-for="tooltip"
                data-tip={t("common.underdevelopment")}
              >
                <a href={"#" + classnames} onClick={() => {}}>
                  {linkAnchor}
                </a>
              </span>
            </>
          ) : (
            <a href={"#" + classnames} onClick={handleLinkClick}>
              {linkAnchor}
            </a>
          ))}
      </div>
      {hasDropdown ? (
        <div className="mn-card__dropdown">
          <Dropdown
            options={period}
            itemAttribute="label"
            size="small"
            onChange={(checkedValue) => {
              setValue(checkedValue);
            }}
            value={value && value.length > 0 ? value : period[2]}
            placeholder=""
          />
        </div>
      ) : (
        titleColumn && (
          <div className="mn-card__columns">
            <div className="mn-card__service-column">
              {t("pages.dashboardVehicles.vehicleDetails.service")}
            </div>
            <div className="mn-card__vehicle-column">
              {t("pages.dashboardVehicles.vehicleDetails.vehicle")}
            </div>
          </div>
        )
      )}
      {!IsCollapsed && <div className="mn-card__body">{children}</div>}
    </div>
  );
};

Card.propTypes = {
  title: PropTypes.string,
  linkAction: PropTypes.func,
  linkAnchor: PropTypes.string,
  linkDisabled: PropTypes.bool,
};
