import { useContext } from "react";
import { IconBack } from "../../Icon/Line/Back";
import { IconForward } from "../../Icon/Line/Forward";
import { DatePickerContext } from "../DatePickerContext";

export default function DatePickerHeader({ value }) {
  const picker = useContext(DatePickerContext);
  function currMonthName() {
    let month = value.format("MMMM").slice(1);
    return value.format("MMMM").charAt(0).toUpperCase() + month;
  }

  function currYear() {
    return value.format("YYYY");
  }

  function prevMonth() {
    return picker.value.subtract(1, "month");
  }

  function nextMonth() {
    return picker.value.add(1, "month");
  }

  function displayPrevArrow() {
    if (picker.dateRange) {
      if (!picker.value.subtract(1, "month").isAfter(picker.dateRange[0]))
        return (
          <div className="previous-disabled" onClick={() => null}>
            {<IconBack size={14} color="--global-colors-ink-lighter" />}
          </div>
        )
      else return (
        <div className="previous" onClick={() => picker.setValue(prevMonth())}>
          {<IconBack size={14} color="--global-colors-ink-dark" />}
        </div>
      )
    } else return (
      <div className="previous" onClick={() => picker.setValue(prevMonth())}>
        {<IconBack size={14} color="--global-colors-ink-dark" />}
      </div>
    )
  }

  function displayNextArrow() {
    if (picker.dateRange) {
      if (picker.value.add(1, "month").endOf('month').isAfter(picker.dateRange[1].endOf('month')))
        return (
          <div className="next-disabled" onClick={() => null}>
            {<IconForward size={14} color="--global-colors-ink-lighter" />}
          </div>
        )
      else return (
        <div className="next" onClick={() => picker.setValue(nextMonth())}>
          {<IconForward size={14} color="--global-colors-ink-dark" />}
        </div>
      )
    } else return (
      <div className="next" onClick={() => picker.setValue(nextMonth())}>
        {<IconForward size={14} color="--global-colors-ink-dark" />}
      </div>
    )
  }

  return (
    <div className="date-picker-header">
      <div className="header">
        {displayPrevArrow()}
        <div className="current">
          {currMonthName()} {currYear()}
        </div>
        {displayNextArrow()}
      </div>
    </div>
  );
}
