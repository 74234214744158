import i18next from "i18next";
import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { GTFleetErrorCodes } from "../../../config/GTfleetErrorCodes";
import { ToastNotification } from "../../../utils/ToastNotification";
import { Button } from "../../Button/Button";
import Form from "../../Forms/Form";
import TextField from "../../Forms/TextField";
import { IconEye } from "../../Icon/Line/Eye";
import { IconEyeSlash } from "../../Icon/Line/EyeSlash";
import { IconLoader } from "../../Icon/Line/Loader";
import { Check } from "../../Indicators/Check";
import { LabelText } from "../../LabelText/LabelText";
import { ModalBody } from "../ModalBody";
import { ModalFooter } from "../ModalFooter";
import { ModalWrapper } from "../ModalWrapper";
import { FeedbackModal } from "./FeedbackModal";
import "./VerifyCodeModal.css";

export const VerifyCodeModal = ({
  open,
  onClose,
  handleValidateCode,
  handleResetPassword,
  setShowResetPsw,
}) => {
  const { t } = useTranslation();
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [isValid, setIsValid] = useState();
  const [enteredCode, setEnteredCode] = useState("&&&&&&");
  const [newPassword, setNewPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [showNewPassword, setShowNewPassword] = useState(true);
  const [showRepeatPassword, setShowRepeatPassword] = useState(true);
  const [passwordEquals, setPasswordEquals] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [checkCode, setCheckCode] = useState(false);
  const [allPswValidation, setAllPswValidation] = useState(false);
  const [invalidNewPassword, setInvalidNewPassword] = useState(false);
  const [allPswValidationArray, setAllPswValidationArray] = useState({
    minLenght: false,
    lowUp: false,
    number: false,
    specChar: false,
  });

  const clearFields = () => {
    setIsValid();
    setEnteredCode("&&&&&&");
    setNewPassword("");
    setRepeatPassword("");
    setShowNewPassword(true);
    setShowRepeatPassword(true);
    setPasswordEquals(true);
    setAllPswValidation(false);
    setShowFeedbackModal(false);
  };

  const primaryAction = () => {
    clearFields();
    setShowFeedbackModal(true);
    onClose();
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!enteredCode.includes("&")) {
        setCheckCode(true);
        try {
          const result = await handleValidateCode(enteredCode);
          if (result.data) {
            setCheckCode(false);
            setIsValid(true);
            return result;
          } else {
            setCheckCode(true);
            setIsValid(false);
          }
        } catch (error) {
          if (error.response.status === 404) {
            setIsValid(false);
            setCheckCode(false);
            console.log("Code not found: " + error);
          } else if (error.response && error.response.status === 500) {
            ToastNotification({
              toastId: "serverError",
              status: "error",
              description: i18next.t("common.serverError"),
            });
          }
        }
      } else {
        setIsValid(undefined);
      }
    };
    fetchData();
  }, [enteredCode]);

  const validateChecklist = (value) => {
    if (!isValid) return "disabled-constraint";
    else if (newPassword === "") return "default-constraint";
    else {
      return value ? "success-constraint" : "error-constraint";
    }
  };

  const resetPassword = async () => {
    setIsLoading(true);
    try {
      const result = await handleResetPassword(newPassword, enteredCode);
      return setIsLoading(false), primaryAction(), result;
    } catch (error) {
      if (
        error.response.status === 409 &&
        error.response.data.message === GTFleetErrorCodes.NEW_PASSWORD_CONFLICT
      ) {
        setIsLoading(false);
        setInvalidNewPassword(true);
        console.log("Conflict: " + error);
      }
      if (
        error.response.status === 404 &&
        error.response.data.message === GTFleetErrorCodes.CODE_NOT_FOUND
      ) {
        setIsLoading(false);
        console.log("Code not found: " + error);
      } else if (error.response.status === 500) {
        ToastNotification({
          toastId: "serverError",
          status: "error",
          description: i18next.t("common.serverError"),
        });
      } else if (
        error.response.status === 404 &&
        error.response.data.message === GTFleetErrorCodes.USERNAME_NOT_FOUND
      ) {
        ToastNotification({
          toastId: "idError",
          status: "error",
          description: i18next.t("common.idError"),
        });
      }
    }
  };

  const submitModalRef = useRef();
  useEffect(() => {
    const keyUp = async (event) => {
      if (event.keyCode === 13) {
        event.preventDefault();
        if (allPswValidation) await resetPassword();
      }
    };

    if (submitModalRef && submitModalRef.current) {
      submitModalRef.current.addEventListener("keydown", keyUp);
      let parentRef = submitModalRef;
      return () => {
        parentRef.current &&
          parentRef.current.removeEventListener("keydown", keyUp);
      };
    }
  });

  document.addEventListener("paste", function (e) {
    if (e.target.type === "text") {
      var data = e.clipboardData.getData("Text");
      data = data.split("");
      [].forEach.call(
        document.querySelectorAll(".forms-container input[type=text]"),
        (node, index) => {
          node.value = data[index] === undefined ? "" : data[index];
        }
      );
    }
  });

  useEffect(() => {
    setTimeout(() => {
      if (open) {
        const form = document.querySelector('[name="codeForm"]');
        const inputs = document.querySelectorAll(
          "[name='codeForm'] .single-form"
        );

        function handleInput(e) {
          const input = e.target;
          const next =
            input.parentElement.parentElement.parentElement.nextElementSibling;
          if (next && input.value) {
            next.children[0].children[1].children[0].focus();
          }
        }

        function handleDelete(e) {
          const input = e.target;
          if (e.key === "Backspace") {
            const prev =
              input.parentElement.parentElement.parentElement
                .previousElementSibling;
            if (prev) {
              prev.children[0].children[1].children[0].focus();
              prev.children[0].children[1].children[0].select();
            }
          }
        }

        function handlePaste(e) {
          const paste = e.clipboardData.getData("text");
          inputs.forEach((input, i) => {
            input.children[0].children[1].children[0].value = paste[i] || "";
            setEnteredCode((prev) => {
              let prevItem = prev.split("");
              prevItem[i] = paste[i] === undefined ? "&" : paste[i];
              return prevItem.join("");
            });
          });
        }

        inputs[0].addEventListener("paste", handlePaste);
        form.addEventListener("input", handleInput);
        form.addEventListener("keyup", handleDelete);
      }
    });
  }, [open]);

  useEffect(() => {
    setAllPswValidationArray({
      minLenght: newPassword.length >= 8,
      lowUp:
        newPassword.toUpperCase() !== newPassword &&
        newPassword.toLowerCase() !== newPassword,
      number: newPassword.match(/\d+/g) ? true : false,
      specChar: newPassword.match(/[ `!@#$%^&*()_+\-=\\[\]{};':"\\|,.<>\\/?~]/)
        ? true
        : false,
    });
    setAllPswValidation(
      newPassword.length >= 8 &&
        newPassword.toUpperCase() !== newPassword &&
        newPassword.toLowerCase() !== newPassword &&
        newPassword.match(/\d+/g) &&
        newPassword.match(/[ `!@#$%^&*()_+\-=\\[\]{};':"\\|,.<>\\/?~]/)
    );

    newPassword === repeatPassword || !allPswValidation
      ? setPasswordEquals(true)
      : setPasswordEquals(false);
  }, [newPassword, repeatPassword]);

  return (
    <div className="verify-code-container" ref={submitModalRef}>
      <ModalWrapper open={open} closeAction={onClose}>
        <div className="verify-code">
          <ModalBody
            title={t("customModals.verifyCode.title")}
            desc={t("customModals.verifyCode.desc")}
          >
            <div
              className={
                isValid || isValid === undefined
                  ? "central-forms"
                  : "central-forms-error"
              }
            >
              <LabelText
                text={
                  checkCode
                    ? t("customModals.verifyCode.expiredCode")
                    : isValid === undefined && enteredCode.includes("&")
                    ? t("customModals.verifyCode.codeTitle")
                    : isValid
                    ? t("customModals.verifyCode.validCode")
                    : t("customModals.verifyCode.invalidCode")
                }
              />
              <div className="forms-container">
                <Form name="codeForm">
                  {enteredCode.split("").map((el, index) => {
                    return (
                      <div key={index} className="single-form" name={index}>
                        <TextField
                          id={`code${index}`}
                          maxLength="1"
                          placeholder=""
                          disabled={isValid && isValid !== undefined}
                          events={{
                            onChange: (data) => {
                              setEnteredCode((prev) => {
                                let prevItem = prev.split("");
                                prevItem[index] =
                                  data.value === "" ? "&" : data.value;
                                return prevItem.join("");
                              });
                            },
                          }}
                          value={el === "&" ? "" : el}
                        />
                      </div>
                    );
                  })}
                  {checkCode && isValid === undefined && (
                    <div className="forms-loader">
                      <IconLoader
                        size={14}
                        color="--global-colors-ui-primary"
                      />
                    </div>
                  )}
                </Form>
                <div className="resend-button">
                  {!isValid && isValid !== undefined && (
                    <Button
                      aspect="primary"
                      size="small"
                      label={t("customModals.verifyCode.resendButton")}
                      onClick={() => {
                        clearFields();
                        setShowResetPsw(true);
                        onClose();
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
            <div
              className={
                !isValid
                  ? "new-password-container-disabled"
                  : "new-password-container"
              }
            >
              <div className="new-password-title">
                {t("customModals.verifyCode.passwordTitle")}
              </div>
              <div
                className={() => {
                  if (passwordEquals || repeatPassword === "")
                    return !invalidNewPassword ? "" : "new-password-conflict";
                  else return "password-not-equals";
                }}
              >
                <LabelText
                  text={t("customModals.verifyCode.newPasswordTitle")}
                />
                <Form>
                  <TextField
                    id="new-password"
                    placeholder={t(
                      "customModals.verifyCode.newPasswordPlaceholder"
                    )}
                    type={showNewPassword ? "password" : "text"}
                    events={{
                      onChange: (data) => {
                        setNewPassword(data.value);
                        setInvalidNewPassword(false);
                      },
                    }}
                    disabled={!isValid}
                    value={newPassword}
                  >
                    {showNewPassword ? (
                      <IconEyeSlash
                        onClick={() => setShowNewPassword(false)}
                        size={14}
                        color="--global-colors-ui-sky-sky--hover"
                      />
                    ) : (
                      <IconEye
                        onClick={() => setShowNewPassword(true)}
                        size={14}
                        color="--global-colors-ui-sky-sky--hover"
                      />
                    )}
                  </TextField>
                </Form>
                {passwordEquals &&
                  repeatPassword !== "" &&
                  invalidNewPassword && (
                    <div className="new-password-conflict-label">
                      {t("customModals.verifyCode.newPasswordConflict")}
                    </div>
                  )}
              </div>
              <div className="constraint-container">
                <div className="constraint-title">
                  {t("customModals.verifyCode.passwordConstraintTitle")}
                </div>
                <div
                  className={
                    newPassword === ""
                      ? "hide-constraint-check"
                      : "constraint-check"
                  }
                >
                  {allPswValidationArray.minLenght ? (
                    <Check status="success" size="xsmall" />
                  ) : (
                    <Check status="failed" size="xsmall" />
                  )}
                </div>
                <div
                  className={validateChecklist(allPswValidationArray.minLenght)}
                >
                  {t("customModals.verifyCode.passwordConstraintLength")}
                </div>
                <div
                  className={
                    newPassword === ""
                      ? "hide-constraint-check"
                      : "constraint-check"
                  }
                >
                  {allPswValidationArray.lowUp ? (
                    <Check status="success" size="xsmall" />
                  ) : (
                    <Check status="failed" size="xsmall" />
                  )}
                </div>
                <div className={validateChecklist(allPswValidationArray.lowUp)}>
                  {t("customModals.verifyCode.passwordConstraintLowUp")}
                </div>
                <div
                  className={
                    newPassword === ""
                      ? "hide-constraint-check"
                      : "constraint-check"
                  }
                >
                  {allPswValidationArray.number ? (
                    <Check status="success" size="xsmall" />
                  ) : (
                    <Check status="failed" size="xsmall" />
                  )}
                </div>
                <div
                  className={validateChecklist(allPswValidationArray.number)}
                >
                  {t("customModals.verifyCode.passwordConstraintNumber")}
                </div>
                <div
                  className={
                    newPassword === ""
                      ? "hide-constraint-check"
                      : "constraint-check"
                  }
                >
                  {allPswValidationArray.specChar ? (
                    <Check status="success" size="xsmall" />
                  ) : (
                    <Check status="failed" size="xsmall" />
                  )}
                </div>
                <div
                  className={validateChecklist(allPswValidationArray.specChar)}
                >
                  {t("customModals.verifyCode.passwordConstraintChar")}
                </div>
              </div>
              <div
                className={
                  passwordEquals || !allPswValidation || repeatPassword === ""
                    ? ""
                    : "password-not-equals"
                }
              >
                <LabelText
                  text={t("customModals.verifyCode.RepeatPasswordTitle")}
                />
                <Form>
                  <TextField
                    id="repeat-password"
                    placeholder={t(
                      "customModals.verifyCode.RepeatPasswordPlaceholder"
                    )}
                    type={showRepeatPassword ? "password" : "text"}
                    events={{
                      onChange: (data) => setRepeatPassword(data.value),
                    }}
                    disabled={!allPswValidation}
                    value={repeatPassword}
                  >
                    {showRepeatPassword ? (
                      <IconEyeSlash
                        onClick={() => setShowRepeatPassword(false)}
                        size={14}
                        color="--global-colors-ui-sky-sky--hover"
                      />
                    ) : (
                      <IconEye
                        onClick={() => setShowRepeatPassword(true)}
                        size={14}
                        color="--global-colors-ui-sky-sky--hover"
                      />
                    )}
                  </TextField>
                </Form>
                {!passwordEquals &&
                  repeatPassword !== "" &&
                  allPswValidation && (
                    <div className="password-not-equals-label">
                      {t("customModals.verifyCode.PasswordNotEquals")}
                    </div>
                  )}
              </div>
            </div>
          </ModalBody>
          <ModalFooter
            primaryLabel={t("customModals.verifyCode.primaryButton")}
            primaryAction={async () => resetPassword()}
            secondaryLabel={t("customModals.verifyCode.secondaryButton")}
            secondaryAction={() => {
              onClose();
              clearFields();
            }}
            disablePrimaryButton={
              !(passwordEquals && newPassword !== "" && repeatPassword !== "")
            }
            loadingPrimaryButton={isLoading}
          />
        </div>
      </ModalWrapper>
      <div>
        <FeedbackModal
          open={showFeedbackModal}
          closeAction={onClose}
          title={t("customModals.feedbackPassword.title")}
          desc={t("customModals.feedbackPassword.desc")}
          primaryLabel={t("customModals.feedbackPassword.primaryButton")}
          primaryAction={setShowFeedbackModal}
        />
      </div>
    </div>
  );
};
VerifyCodeModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  validationCode: PropTypes.string,
  id: PropTypes.number,
  handleValidateCode: PropTypes.func,
  setShowResetPsw: PropTypes.func,
  handleResetPassword: PropTypes.func,
};

VerifyCodeModal.defaultProps = {
  open: false,
};
