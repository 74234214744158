import dayjs from "dayjs";
import i18next from "i18next";

export const formatPresetDate = (startDateStr, endDateStr) => {
  const startDate = dayjs(startDateStr);
  const endDate = dayjs(endDateStr);
  const today = dayjs();
  const startOfWeek = today.startOf("week");
  const endOfWeek = today.endOf("week");
  const lastWeekStart = today.subtract(1, "week").startOf("week");
  const lastWeekEnd = today.subtract(1, "week").endOf("week");
  const nextWeekStart = today.add(1, "week").startOf("week");
  const nextWeekEnd = today.add(1, "week").endOf("week");

  if (
    startDate.isSame(startOfWeek, "day") &&
    endDate.isSame(endOfWeek, "day")
  ) {
    return i18next.t("common.datePicker.datePresets.thisWeek");
  } else if (
    startDate.isSame(lastWeekStart, "day") &&
    endDate.isSame(lastWeekEnd, "day")
  ) {
    return i18next.t("common.datePicker.datePresets.lastWeek");
  } else if (
    startDate.isSame(nextWeekStart, "day") &&
    endDate.isSame(nextWeekEnd, "day")
  ) {
    return i18next.t("common.datePicker.datePresets.nextWeek");
  } else {
    return `${startDate.format("DD/MM/YY")} - ${endDate.format("DD/MM/YY")}`;
  }
};
