import PropTypes from "prop-types";

export const IllustrationBike = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 50 43"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <linearGradient
        x1="0%"
        y1="49.9807462%"
        x2="99.5495495%"
        y2="49.9807462%"
        id="bike-linearGradient-1"
      >
        <stop stopColor="#D0D2D4" offset="0%"></stop>
        <stop stopColor="#86898B" offset="100%"></stop>
      </linearGradient>
      <linearGradient
        x1="46.4660845%"
        y1="92.7237673%"
        x2="54.5774879%"
        y2="-8.70402343%"
        id="bike-linearGradient-2"
      >
        <stop stopColor="#D0D2D4" offset="0%"></stop>
        <stop stopColor="#86898B" offset="100%"></stop>
      </linearGradient>
      <linearGradient
        x1="0.812898633%"
        y1="50.1883248%"
        x2="99.4249254%"
        y2="50.1883248%"
        id="bike-linearGradient-3"
      >
        <stop stopColor="#D0D2D4" offset="0%"></stop>
        <stop stopColor="#86898B" offset="100%"></stop>
      </linearGradient>
      <linearGradient
        x1="60.8951257%"
        y1="36.1440095%"
        x2="39.3797414%"
        y2="64.3256851%"
        id="bike-linearGradient-4"
      >
        <stop stopColor="#9EA5A9" offset="0%"></stop>
        <stop stopColor="#AAB1B5" offset="2%"></stop>
        <stop stopColor="#C1C8CC" offset="6%"></stop>
        <stop stopColor="#D1D8DC" offset="11%"></stop>
        <stop stopColor="#DBE2E6" offset="16%"></stop>
        <stop stopColor="#DEE5E9" offset="22%"></stop>
        <stop stopColor="#DBE2E6" offset="40%"></stop>
        <stop stopColor="#D1D8DC" offset="54%"></stop>
        <stop stopColor="#C0C7CB" offset="67%"></stop>
        <stop stopColor="#A8AFB3" offset="80%"></stop>
        <stop stopColor="#9EA5A9" offset="84%"></stop>
        <stop stopColor="#6F7980" offset="100%"></stop>
      </linearGradient>
      <linearGradient
        x1="58.5383754%"
        y1="14.2663043%"
        x2="32.6757021%"
        y2="102.581522%"
        id="bike-linearGradient-5"
      >
        <stop stopColor="#C7D5DC" offset="0%"></stop>
        <stop stopColor="#586770" offset="100%"></stop>
      </linearGradient>
      <linearGradient
        x1="48.0753703%"
        y1="39.7510099%"
        x2="5.9492963%"
        y2="95.0528523%"
        id="bike-linearGradient-6"
      >
        <stop stopColor="#C7D5DC" offset="0%"></stop>
        <stop stopColor="#586770" offset="100%"></stop>
      </linearGradient>
      <linearGradient
        x1="46.0201186%"
        y1="42.3812587%"
        x2="57.7881869%"
        y2="55.9811118%"
        id="bike-linearGradient-7"
      >
        <stop stopColor="#9EA5A9" offset="0%"></stop>
        <stop stopColor="#AAB1B5" offset="2%"></stop>
        <stop stopColor="#C1C8CC" offset="6%"></stop>
        <stop stopColor="#D1D8DC" offset="11%"></stop>
        <stop stopColor="#DBE2E6" offset="16%"></stop>
        <stop stopColor="#DEE5E9" offset="22%"></stop>
        <stop stopColor="#DBE2E6" offset="40%"></stop>
        <stop stopColor="#D1D8DC" offset="54%"></stop>
        <stop stopColor="#C0C7CB" offset="67%"></stop>
        <stop stopColor="#A8AFB3" offset="80%"></stop>
        <stop stopColor="#9EA5A9" offset="84%"></stop>
        <stop stopColor="#6F7980" offset="100%"></stop>
      </linearGradient>
      <linearGradient
        x1="33.0204235%"
        y1="1.59458116%"
        x2="81.2137187%"
        y2="73.8012303%"
        id="bike-linearGradient-8"
      >
        <stop stopColor="#858C90" offset="0%"></stop>
        <stop stopColor="#91989C" offset="2%"></stop>
        <stop stopColor="#A8AFB3" offset="6%"></stop>
        <stop stopColor="#B8BFC3" offset="11%"></stop>
        <stop stopColor="#C2C9CD" offset="16%"></stop>
        <stop stopColor="#C5CCD0" offset="22%"></stop>
        <stop stopColor="#C2C9CD" offset="40%"></stop>
        <stop stopColor="#B8BFC3" offset="54%"></stop>
        <stop stopColor="#A7AEB2" offset="67%"></stop>
        <stop stopColor="#8F969A" offset="80%"></stop>
        <stop stopColor="#858C90" offset="84%"></stop>
        <stop stopColor="#566067" offset="100%"></stop>
      </linearGradient>
      <linearGradient
        x1="-54.3414867%"
        y1="-677.936875%"
        x2="-14.3661402%"
        y2="-612.011541%"
        id="bike-linearGradient-9"
      >
        <stop stopColor="#858C90" offset="0%"></stop>
        <stop stopColor="#91989C" offset="2%"></stop>
        <stop stopColor="#A8AFB3" offset="6%"></stop>
        <stop stopColor="#B8BFC3" offset="11%"></stop>
        <stop stopColor="#C2C9CD" offset="16%"></stop>
        <stop stopColor="#C5CCD0" offset="22%"></stop>
        <stop stopColor="#C2C9CD" offset="40%"></stop>
        <stop stopColor="#B8BFC3" offset="54%"></stop>
        <stop stopColor="#A7AEB2" offset="67%"></stop>
        <stop stopColor="#8F969A" offset="80%"></stop>
        <stop stopColor="#858C90" offset="84%"></stop>
        <stop stopColor="#566067" offset="100%"></stop>
      </linearGradient>
      <linearGradient
        x1="46.2743299%"
        y1="42.8612366%"
        x2="56.9929169%"
        y2="55.6761494%"
        id="bike-linearGradient-10"
      >
        <stop stopColor="#9EA5A9" offset="0%"></stop>
        <stop stopColor="#AAB1B5" offset="2%"></stop>
        <stop stopColor="#C1C8CC" offset="6%"></stop>
        <stop stopColor="#D1D8DC" offset="11%"></stop>
        <stop stopColor="#DBE2E6" offset="16%"></stop>
        <stop stopColor="#DEE5E9" offset="22%"></stop>
        <stop stopColor="#DBE2E6" offset="40%"></stop>
        <stop stopColor="#D1D8DC" offset="54%"></stop>
        <stop stopColor="#C0C7CB" offset="67%"></stop>
        <stop stopColor="#A8AFB3" offset="80%"></stop>
        <stop stopColor="#9EA5A9" offset="84%"></stop>
        <stop stopColor="#6F7980" offset="100%"></stop>
      </linearGradient>
      <linearGradient
        x1="33.9588016%"
        y1="0.471766246%"
        x2="78.7555186%"
        y2="74.5819579%"
        id="bike-linearGradient-11"
      >
        <stop stopColor="#858C90" offset="0%"></stop>
        <stop stopColor="#91989C" offset="2%"></stop>
        <stop stopColor="#A8AFB3" offset="6%"></stop>
        <stop stopColor="#B8BFC3" offset="11%"></stop>
        <stop stopColor="#C2C9CD" offset="16%"></stop>
        <stop stopColor="#C5CCD0" offset="22%"></stop>
        <stop stopColor="#C2C9CD" offset="40%"></stop>
        <stop stopColor="#B8BFC3" offset="54%"></stop>
        <stop stopColor="#A7AEB2" offset="67%"></stop>
        <stop stopColor="#8F969A" offset="80%"></stop>
        <stop stopColor="#858C90" offset="84%"></stop>
        <stop stopColor="#566067" offset="100%"></stop>
      </linearGradient>
      <linearGradient
        x1="30.3092784%"
        y1="20.7106534%"
        x2="70.1030928%"
        y2="84.5110082%"
        id="bike-linearGradient-12"
      >
        <stop stopColor="#858C90" offset="0%"></stop>
        <stop stopColor="#91989C" offset="2%"></stop>
        <stop stopColor="#A8AFB3" offset="6%"></stop>
        <stop stopColor="#B8BFC3" offset="11%"></stop>
        <stop stopColor="#C2C9CD" offset="16%"></stop>
        <stop stopColor="#C5CCD0" offset="22%"></stop>
        <stop stopColor="#C2C9CD" offset="40%"></stop>
        <stop stopColor="#B8BFC3" offset="54%"></stop>
        <stop stopColor="#A7AEB2" offset="67%"></stop>
        <stop stopColor="#8F969A" offset="80%"></stop>
        <stop stopColor="#858C90" offset="84%"></stop>
        <stop stopColor="#566067" offset="100%"></stop>
      </linearGradient>
      <linearGradient
        x1="40.6562603%"
        y1="63.3919814%"
        x2="31.5587635%"
        y2="75.5739785%"
        id="bike-linearGradient-13"
      >
        <stop stopColor="#414E52" offset="0%"></stop>
        <stop stopColor="#2E383B" offset="100%"></stop>
      </linearGradient>
      <linearGradient
        x1="28.337576%"
        y1="-6.75702445%"
        x2="71.407246%"
        y2="128.196097%"
        id="bike-linearGradient-14"
      >
        <stop stopColor="#D0D2D4" offset="0%"></stop>
        <stop stopColor="#F0F2F5" offset="28%"></stop>
        <stop stopColor="#86898B" offset="100%"></stop>
      </linearGradient>
      <linearGradient
        x1="30.4454687%"
        y1="-7.83389795%"
        x2="68.4134649%"
        y2="121.577425%"
        id="bike-linearGradient-15"
      >
        <stop stopColor="#D0D2D4" offset="0%"></stop>
        <stop stopColor="#F0F2F5" offset="28%"></stop>
        <stop stopColor="#86898B" offset="100%"></stop>
      </linearGradient>
      <linearGradient
        x1="94.8400529%"
        y1="32.525368%"
        x2="20.1888795%"
        y2="81.8163896%"
        id="bike-linearGradient-16"
      >
        <stop stopColor="#9EA5A9" offset="0%"></stop>
        <stop stopColor="#AAB1B5" offset="2%"></stop>
        <stop stopColor="#C1C8CC" offset="6%"></stop>
        <stop stopColor="#D1D8DC" offset="11%"></stop>
        <stop stopColor="#DBE2E6" offset="16%"></stop>
        <stop stopColor="#DEE5E9" offset="22%"></stop>
        <stop stopColor="#DBE2E6" offset="40%"></stop>
        <stop stopColor="#D1D8DC" offset="54%"></stop>
        <stop stopColor="#C0C7CB" offset="67%"></stop>
        <stop stopColor="#A8AFB3" offset="80%"></stop>
        <stop stopColor="#9EA5A9" offset="84%"></stop>
        <stop stopColor="#6F7980" offset="100%"></stop>
      </linearGradient>
      <linearGradient
        x1="98.7618406%"
        y1="23.5401305%"
        x2="9.82033514%"
        y2="75.9655473%"
        id="bike-linearGradient-17"
      >
        <stop stopColor="#B8BFC3" offset="0%"></stop>
        <stop stopColor="#C4CBCE" offset="2%"></stop>
        <stop stopColor="#DBE2E4" offset="6%"></stop>
        <stop stopColor="#EBF2F3" offset="11%"></stop>
        <stop stopColor="#F5FCFC" offset="16%"></stop>
        <stop stopColor="#F8FFFF" offset="22%"></stop>
        <stop stopColor="#F5FCFC" offset="40%"></stop>
        <stop stopColor="#EBF2F2" offset="54%"></stop>
        <stop stopColor="#DAE1E2" offset="67%"></stop>
        <stop stopColor="#C2C9CC" offset="80%"></stop>
        <stop stopColor="#B8BFC3" offset="84%"></stop>
        <stop stopColor="#89939A" offset="100%"></stop>
      </linearGradient>
      <linearGradient
        x1="67.8607219%"
        y1="39.6517398%"
        x2="37.1610917%"
        y2="68.9030233%"
        id="bike-linearGradient-18"
      >
        <stop stopColor="#535A5E" offset="0%"></stop>
        <stop stopColor="#5F666A" offset="2%"></stop>
        <stop stopColor="#767D81" offset="6%"></stop>
        <stop stopColor="#868D91" offset="11%"></stop>
        <stop stopColor="#90979B" offset="16%"></stop>
        <stop stopColor="#939A9E" offset="22%"></stop>
        <stop stopColor="#90979B" offset="40%"></stop>
        <stop stopColor="#868D91" offset="54%"></stop>
        <stop stopColor="#757C80" offset="67%"></stop>
        <stop stopColor="#5D6468" offset="80%"></stop>
        <stop stopColor="#535A5E" offset="84%"></stop>
        <stop stopColor="#242E35" offset="100%"></stop>
      </linearGradient>
      <linearGradient
        x1="101.042406%"
        y1="29.6195979%"
        x2="12.4793409%"
        y2="92.3642503%"
        id="bike-linearGradient-19"
      >
        <stop stopColor="#9EA5A9" offset="0%"></stop>
        <stop stopColor="#AAB1B5" offset="2%"></stop>
        <stop stopColor="#C1C8CC" offset="6%"></stop>
        <stop stopColor="#D1D8DC" offset="11%"></stop>
        <stop stopColor="#DBE2E6" offset="16%"></stop>
        <stop stopColor="#DEE5E9" offset="22%"></stop>
        <stop stopColor="#DBE2E6" offset="40%"></stop>
        <stop stopColor="#D1D8DC" offset="54%"></stop>
        <stop stopColor="#C0C7CB" offset="67%"></stop>
        <stop stopColor="#A8AFB3" offset="80%"></stop>
        <stop stopColor="#9EA5A9" offset="84%"></stop>
        <stop stopColor="#6F7980" offset="100%"></stop>
      </linearGradient>
      <linearGradient
        x1="100.5321%"
        y1="29.3338781%"
        x2="11.3503984%"
        y2="92.958169%"
        id="bike-linearGradient-20"
      >
        <stop stopColor="#9EA5A9" offset="0%"></stop>
        <stop stopColor="#AAB1B5" offset="2%"></stop>
        <stop stopColor="#C1C8CC" offset="6%"></stop>
        <stop stopColor="#D1D8DC" offset="11%"></stop>
        <stop stopColor="#DBE2E6" offset="16%"></stop>
        <stop stopColor="#DEE5E9" offset="22%"></stop>
        <stop stopColor="#DBE2E6" offset="40%"></stop>
        <stop stopColor="#D1D8DC" offset="54%"></stop>
        <stop stopColor="#C0C7CB" offset="67%"></stop>
        <stop stopColor="#A8AFB3" offset="80%"></stop>
        <stop stopColor="#9EA5A9" offset="84%"></stop>
        <stop stopColor="#6F7980" offset="100%"></stop>
      </linearGradient>
      <linearGradient
        x1="101.042406%"
        y1="29.8544809%"
        x2="12.4793409%"
        y2="92.7390724%"
        id="bike-linearGradient-21"
      >
        <stop stopColor="#9EA5A9" offset="0%"></stop>
        <stop stopColor="#AAB1B5" offset="2%"></stop>
        <stop stopColor="#C1C8CC" offset="6%"></stop>
        <stop stopColor="#D1D8DC" offset="11%"></stop>
        <stop stopColor="#DBE2E6" offset="16%"></stop>
        <stop stopColor="#DEE5E9" offset="22%"></stop>
        <stop stopColor="#DBE2E6" offset="40%"></stop>
        <stop stopColor="#D1D8DC" offset="54%"></stop>
        <stop stopColor="#C0C7CB" offset="67%"></stop>
        <stop stopColor="#A8AFB3" offset="80%"></stop>
        <stop stopColor="#9EA5A9" offset="84%"></stop>
        <stop stopColor="#6F7980" offset="100%"></stop>
      </linearGradient>
      <linearGradient
        x1="101.042406%"
        y1="29.8544809%"
        x2="12.4793409%"
        y2="92.3647593%"
        id="bike-linearGradient-22"
      >
        <stop stopColor="#9EA5A9" offset="0%"></stop>
        <stop stopColor="#AAB1B5" offset="2%"></stop>
        <stop stopColor="#C1C8CC" offset="6%"></stop>
        <stop stopColor="#D1D8DC" offset="11%"></stop>
        <stop stopColor="#DBE2E6" offset="16%"></stop>
        <stop stopColor="#DEE5E9" offset="22%"></stop>
        <stop stopColor="#DBE2E6" offset="40%"></stop>
        <stop stopColor="#D1D8DC" offset="54%"></stop>
        <stop stopColor="#C0C7CB" offset="67%"></stop>
        <stop stopColor="#A8AFB3" offset="80%"></stop>
        <stop stopColor="#9EA5A9" offset="84%"></stop>
        <stop stopColor="#6F7980" offset="100%"></stop>
      </linearGradient>
      <linearGradient
        x1="101.042406%"
        y1="29.5263553%"
        x2="12.4793409%"
        y2="92.3646372%"
        id="bike-linearGradient-23"
      >
        <stop stopColor="#9EA5A9" offset="0%"></stop>
        <stop stopColor="#AAB1B5" offset="2%"></stop>
        <stop stopColor="#C1C8CC" offset="6%"></stop>
        <stop stopColor="#D1D8DC" offset="11%"></stop>
        <stop stopColor="#DBE2E6" offset="16%"></stop>
        <stop stopColor="#DEE5E9" offset="22%"></stop>
        <stop stopColor="#DBE2E6" offset="40%"></stop>
        <stop stopColor="#D1D8DC" offset="54%"></stop>
        <stop stopColor="#C0C7CB" offset="67%"></stop>
        <stop stopColor="#A8AFB3" offset="80%"></stop>
        <stop stopColor="#9EA5A9" offset="84%"></stop>
        <stop stopColor="#6F7980" offset="100%"></stop>
      </linearGradient>
      <linearGradient
        x1="108.955644%"
        y1="23.1592547%"
        x2="34.8007579%"
        y2="70.9539976%"
        id="bike-linearGradient-24"
      >
        <stop stopColor="#9EA5A9" offset="0%"></stop>
        <stop stopColor="#AAB1B5" offset="2%"></stop>
        <stop stopColor="#C1C8CC" offset="6%"></stop>
        <stop stopColor="#D1D8DC" offset="11%"></stop>
        <stop stopColor="#DBE2E6" offset="16%"></stop>
        <stop stopColor="#DEE5E9" offset="22%"></stop>
        <stop stopColor="#DBE2E6" offset="40%"></stop>
        <stop stopColor="#D1D8DC" offset="54%"></stop>
        <stop stopColor="#C0C7CB" offset="67%"></stop>
        <stop stopColor="#A8AFB3" offset="80%"></stop>
        <stop stopColor="#9EA5A9" offset="84%"></stop>
        <stop stopColor="#6F7980" offset="100%"></stop>
      </linearGradient>
      <linearGradient
        x1="46.146632%"
        y1="46.5664998%"
        x2="53.8482873%"
        y2="52.9579004%"
        id="bike-linearGradient-25"
      >
        <stop stopColor="#9EA5A9" offset="0%"></stop>
        <stop stopColor="#AAB1B5" offset="2%"></stop>
        <stop stopColor="#C1C8CC" offset="6%"></stop>
        <stop stopColor="#D1D8DC" offset="11%"></stop>
        <stop stopColor="#DBE2E6" offset="16%"></stop>
        <stop stopColor="#DEE5E9" offset="22%"></stop>
        <stop stopColor="#DBE2E6" offset="40%"></stop>
        <stop stopColor="#D1D8DC" offset="54%"></stop>
        <stop stopColor="#C0C7CB" offset="67%"></stop>
        <stop stopColor="#A8AFB3" offset="80%"></stop>
        <stop stopColor="#9EA5A9" offset="84%"></stop>
        <stop stopColor="#6F7980" offset="100%"></stop>
      </linearGradient>
      <linearGradient
        x1="44.4660799%"
        y1="47.9343513%"
        x2="52.951424%"
        y2="54.9723315%"
        id="bike-linearGradient-26"
      >
        <stop stopColor="#9EA5A9" offset="0%"></stop>
        <stop stopColor="#AAB1B5" offset="2%"></stop>
        <stop stopColor="#C1C8CC" offset="6%"></stop>
        <stop stopColor="#D1D8DC" offset="11%"></stop>
        <stop stopColor="#DBE2E6" offset="16%"></stop>
        <stop stopColor="#DEE5E9" offset="22%"></stop>
        <stop stopColor="#DBE2E6" offset="40%"></stop>
        <stop stopColor="#D1D8DC" offset="54%"></stop>
        <stop stopColor="#C0C7CB" offset="67%"></stop>
        <stop stopColor="#A8AFB3" offset="80%"></stop>
        <stop stopColor="#9EA5A9" offset="84%"></stop>
        <stop stopColor="#6F7980" offset="100%"></stop>
      </linearGradient>
      <linearGradient
        x1="-2.40701942e-14%"
        y1="49.8033531%"
        x2="99.8206515%"
        y2="49.8033531%"
        id="bike-linearGradient-27"
      >
        <stop stopColor="#D0D2D4" offset="0%"></stop>
        <stop stopColor="#576169" offset="100%"></stop>
      </linearGradient>
      <linearGradient
        x1="46.5993912%"
        y1="92.8873094%"
        x2="54.7889714%"
        y2="-8.90307409%"
        id="bike-linearGradient-28"
      >
        <stop stopColor="#D0D2D4" offset="0%"></stop>
        <stop stopColor="#86898B" offset="100%"></stop>
      </linearGradient>
      <linearGradient
        x1="0.184248264%"
        y1="50.0886724%"
        x2="99.8157517%"
        y2="50.0886724%"
        id="bike-linearGradient-29"
      >
        <stop stopColor="#D0D2D4" offset="0%"></stop>
        <stop stopColor="#86898B" offset="100%"></stop>
      </linearGradient>
      <linearGradient
        x1="84.773679%"
        y1="-32.9555827%"
        x2="12.5888172%"
        y2="135.313711%"
        id="bike-linearGradient-30"
      >
        <stop stopColor="#DEE5E9" offset="22%"></stop>
        <stop stopColor="#DBE2E6" offset="40%"></stop>
        <stop stopColor="#D1D8DC" offset="54%"></stop>
        <stop stopColor="#C0C7CB" offset="67%"></stop>
        <stop stopColor="#A8AFB3" offset="80%"></stop>
        <stop stopColor="#9EA5A9" offset="84%"></stop>
        <stop stopColor="#6F7980" offset="100%"></stop>
      </linearGradient>
      <linearGradient
        x1="2.80891341e-14%"
        y1="49.7260779%"
        x2="100%"
        y2="49.7260779%"
        id="bike-linearGradient-31"
      >
        <stop stopColor="#FFFFFF" offset="0%"></stop>
        <stop stopColor="#000000" offset="100%"></stop>
      </linearGradient>
      <linearGradient
        x1="60.79243%"
        y1="36.292936%"
        x2="39.5316959%"
        y2="64.6523788%"
        id="bike-linearGradient-32"
      >
        <stop stopColor="#9EA5A9" offset="0%"></stop>
        <stop stopColor="#AAB1B5" offset="2%"></stop>
        <stop stopColor="#C1C8CC" offset="6%"></stop>
        <stop stopColor="#D1D8DC" offset="11%"></stop>
        <stop stopColor="#DBE2E6" offset="16%"></stop>
        <stop stopColor="#DEE5E9" offset="22%"></stop>
        <stop stopColor="#DBE2E6" offset="40%"></stop>
        <stop stopColor="#D1D8DC" offset="54%"></stop>
        <stop stopColor="#C0C7CB" offset="67%"></stop>
        <stop stopColor="#A8AFB3" offset="80%"></stop>
        <stop stopColor="#9EA5A9" offset="84%"></stop>
        <stop stopColor="#6F7980" offset="100%"></stop>
      </linearGradient>
      <linearGradient
        x1="46.1685286%"
        y1="46.6287935%"
        x2="53.8264195%"
        y2="53.0020087%"
        id="bike-linearGradient-33"
      >
        <stop stopColor="#9EA5A9" offset="0%"></stop>
        <stop stopColor="#AAB1B5" offset="2%"></stop>
        <stop stopColor="#C1C8CC" offset="6%"></stop>
        <stop stopColor="#D1D8DC" offset="11%"></stop>
        <stop stopColor="#DBE2E6" offset="16%"></stop>
        <stop stopColor="#DEE5E9" offset="22%"></stop>
        <stop stopColor="#DBE2E6" offset="40%"></stop>
        <stop stopColor="#D1D8DC" offset="54%"></stop>
        <stop stopColor="#C0C7CB" offset="67%"></stop>
        <stop stopColor="#A8AFB3" offset="80%"></stop>
        <stop stopColor="#9EA5A9" offset="84%"></stop>
        <stop stopColor="#6F7980" offset="100%"></stop>
      </linearGradient>
      <linearGradient
        x1="44.5424175%"
        y1="47.8101159%"
        x2="53.0788812%"
        y2="54.9307714%"
        id="bike-linearGradient-34"
      >
        <stop stopColor="#9EA5A9" offset="0%"></stop>
        <stop stopColor="#AAB1B5" offset="2%"></stop>
        <stop stopColor="#C1C8CC" offset="6%"></stop>
        <stop stopColor="#D1D8DC" offset="11%"></stop>
        <stop stopColor="#DBE2E6" offset="16%"></stop>
        <stop stopColor="#DEE5E9" offset="22%"></stop>
        <stop stopColor="#DBE2E6" offset="40%"></stop>
        <stop stopColor="#D1D8DC" offset="54%"></stop>
        <stop stopColor="#C0C7CB" offset="67%"></stop>
        <stop stopColor="#A8AFB3" offset="80%"></stop>
        <stop stopColor="#9EA5A9" offset="84%"></stop>
        <stop stopColor="#6F7980" offset="100%"></stop>
      </linearGradient>
      <linearGradient
        x1="-0.0283423058%"
        y1="49.8678701%"
        x2="99.1008688%"
        y2="49.8678701%"
        id="bike-linearGradient-35"
      >
        <stop stopColor="#9EA5A9" offset="0%"></stop>
        <stop stopColor="#AAB1B5" offset="2%"></stop>
        <stop stopColor="#C1C8CC" offset="6%"></stop>
        <stop stopColor="#D1D8DC" offset="11%"></stop>
        <stop stopColor="#DBE2E6" offset="16%"></stop>
        <stop stopColor="#DEE5E9" offset="22%"></stop>
        <stop stopColor="#DBE2E6" offset="40%"></stop>
        <stop stopColor="#D1D8DC" offset="54%"></stop>
        <stop stopColor="#C0C7CB" offset="67%"></stop>
        <stop stopColor="#A8AFB3" offset="80%"></stop>
        <stop stopColor="#9EA5A9" offset="84%"></stop>
        <stop stopColor="#6F7980" offset="100%"></stop>
      </linearGradient>
      <linearGradient
        x1="69.1847227%"
        y1="18.5149508%"
        x2="30.9807513%"
        y2="82.9164735%"
        id="bike-linearGradient-36"
      >
        <stop stopColor="#9EA5A9" offset="0%"></stop>
        <stop stopColor="#AAB1B5" offset="2%"></stop>
        <stop stopColor="#C1C8CC" offset="6%"></stop>
        <stop stopColor="#D1D8DC" offset="11%"></stop>
        <stop stopColor="#DBE2E6" offset="16%"></stop>
        <stop stopColor="#DEE5E9" offset="22%"></stop>
        <stop stopColor="#DBE2E6" offset="40%"></stop>
        <stop stopColor="#D1D8DC" offset="54%"></stop>
        <stop stopColor="#C0C7CB" offset="67%"></stop>
        <stop stopColor="#A8AFB3" offset="80%"></stop>
        <stop stopColor="#9EA5A9" offset="84%"></stop>
        <stop stopColor="#6F7980" offset="100%"></stop>
      </linearGradient>
      <linearGradient
        x1="41.8469722%"
        y1="-0.133447437%"
        x2="73.1831646%"
        y2="82.613485%"
        id="bike-linearGradient-37"
      >
        <stop stopColor="#D0D2D4" offset="0%"></stop>
        <stop stopColor="#F0F2F5" offset="28%"></stop>
        <stop stopColor="#86898B" offset="100%"></stop>
      </linearGradient>
      <linearGradient
        x1="49.7777778%"
        y1="14.900552%"
        x2="49.7777778%"
        y2="103.269999%"
        id="bike-linearGradient-38"
      >
        <stop stopColor="#C8CACD" offset="0%"></stop>
        <stop stopColor="#6D7072" offset="100%"></stop>
      </linearGradient>
      <linearGradient
        x1="41.0620505%"
        y1="-0.133447437%"
        x2="72.178448%"
        y2="82.613485%"
        id="bike-linearGradient-39"
      >
        <stop stopColor="#D0D2D4" offset="0%"></stop>
        <stop stopColor="#F0F2F5" offset="28%"></stop>
        <stop stopColor="#86898B" offset="100%"></stop>
      </linearGradient>
      <linearGradient
        x1="49.7777778%"
        y1="13.3997311%"
        x2="49.7777778%"
        y2="103.443561%"
        id="bike-linearGradient-40"
      >
        <stop stopColor="#C8CACD" offset="0%"></stop>
        <stop stopColor="#6D7072" offset="100%"></stop>
      </linearGradient>
      <linearGradient
        x1="41.1798077%"
        y1="-0.216511033%"
        x2="72.4017813%"
        y2="82.9252492%"
        id="bike-linearGradient-41"
      >
        <stop stopColor="#D0D2D4" offset="0%"></stop>
        <stop stopColor="#F0F2F5" offset="28%"></stop>
        <stop stopColor="#86898B" offset="100%"></stop>
      </linearGradient>
      <linearGradient
        x1="49.9231951%"
        y1="13.5606525%"
        x2="49.9231951%"
        y2="103.258505%"
        id="bike-linearGradient-42"
      >
        <stop stopColor="#C8CACD" offset="0%"></stop>
        <stop stopColor="#6D7072" offset="100%"></stop>
      </linearGradient>
      <linearGradient
        x1="0%"
        y1="50%"
        x2="100%"
        y2="50%"
        id="bike-linearGradient-43"
      >
        <stop stopColor="#FFFFFF" offset="0%"></stop>
        <stop stopColor="#FFFFFF" offset="100%"></stop>
      </linearGradient>
    </defs>
    <g
      id="🕹-[02]:-Image-and-Icons"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="02.-Illustration"
        transform="translate(-581.000000, -323.000000)"
        fillRule="nonzero"
      >
        <g id="Group" transform="translate(571.000000, 314.000000)">
          <g id="motorbike" transform="translate(10.000000, 9.000000)">
            <path
              d="M17.0375,19.3626471 C16.9486473,19.814684 16.7723514,20.2444911 16.51875,20.6273529 C15.8882486,21.2793961 15.1658387,21.8334378 14.375,22.2714706 C13.70625,22.6635294 12.6125,23.1314706 12.70625,24.3202941 C12.70625,24.3202941 12.525,24.9526471 13.775,25.0032353 C15.025,25.0538235 15.65,24.8830882 16.275,24.2570588 C16.7670061,23.7511914 17.425152,23.4452425 18.125,23.3970588 C18.4883021,23.3332834 18.8473293,23.2466945 19.2,23.1377941 C19.3836985,22.8082163 19.4765164,22.434761 19.46875,22.0564706 C19.425,21.4936765 19,20.9055882 19.2,20.2352941 C19.4,19.565 19.825,18.4583824 19.825,18.4583824 L17.3875,18.0094118 L17.0375,19.3626471 Z"
              id="bike-Path"
              fill="#492B17"
            ></path>
            <path
              d="M19.2,23.1061765 C18.8480239,23.2257613 18.488987,23.3229124 18.125,23.3970588 C17.4252892,23.4567181 16.7715051,23.7739565 16.2875,24.2886765 C15.6625,24.9210294 15.0375,25.0917647 13.7875,25.0348529 C13.1625,25.0348529 12.8875,24.8261765 12.775,24.6617647 C12.775,24.7313235 12.68125,25.2941176 13.85,25.3320588 C15.01875,25.37 15.725,25.2182353 16.35,24.5922059 C16.8357195,24.0798764 17.4885585,23.7630967 18.1875,23.7005882 C18.5517803,23.6223633 18.9108254,23.5209858 19.2625,23.3970588 C19.2625,23.3970588 19.23125,23.2705882 19.2,23.0872059 L19.2,23.1061765 Z"
              id="bike-Path"
              fill="#222C2F"
            ></path>
            <path
              d="M16.5125,20.4755882 C16.5125,20.4755882 16.6375,16.4727941 17.29375,14.7844118 C17.95,13.0960294 18.125,9.33985294 18.6375,8.44823529 C19.15,7.55661765 21.38125,6.82941176 23.425,6.67132353 C25.46875,6.51323529 29.10625,7.30367647 29.10625,7.30367647 C29.10625,7.30367647 29.5125,10.5160294 29.5125,10.7247059 C29.5125,10.9333824 25.1375,11.0914706 24.08125,11.0725 C23.1629826,11.1386479 22.2583794,11.3344679 21.39375,11.6542647 C21.6166377,12.8817653 21.495395,14.1478856 21.04375,15.3092647 C20.3959713,16.9503731 19.8817617,18.6422728 19.50625,20.3680882 C19.50625,20.3680882 18.75,21.6075 16.5125,20.4755882 Z"
              id="bike-Path"
              fill="#222C2F"
            ></path>
            <path
              d="M13.975,28.3547059 L12.15,29.6763235 L13.53125,30.4857353 C13.53125,30.4857353 11.90625,31.4026471 13.1,32.1045588 L4.375,37.2139706 L5.4125,37.8463235 L0.90625,40.4705882 C0.714487052,40.5324786 0.584305538,40.7127459 0.584305538,40.9163971 C0.584305538,41.1200482 0.714487052,41.3003155 0.90625,41.3622059 L2.33125,42.1969118 C2.81253168,42.4436744 3.38121832,42.4436744 3.8625,42.1969118 L8.35,39.5789706 L9.23125,40.0911765 L18.18125,34.8616176 C18.8583368,34.8725066 19.5257028,34.697693 20.1125,34.3557353 L21.5,35.1651471 L23.75,34.0711765 L31.15625,34.7794118 L31.15625,34.3177941 L29.45625,33.9510294 L29.14375,33.3186765 L24.83125,33.3186765 L26.59375,32.2942647 L25.55625,31.6619118 L32.66875,27.5073529 L38.20625,24.7882353 L47.16875,19.2930882 L48.5875,18.2054412 L47.70625,17.7058824 L45.375,18.3382353 L42.95625,19.7547059 L42.85,19.6977941 L49.30625,15.9036765 C49.73125,15.6570588 49.6375,15.2017647 49.10625,14.8919118 L47.3125,13.8485294 C46.7847977,13.5386402 46.1441531,13.4965201 45.58125,13.7347059 L39.1125,17.5288235 L38.95625,17.4213235 L41.38125,16.0111765 L42.45,14.6516176 L41.5375,14.1141176 L39.6625,14.9425 L30.275,20.1594118 L25.625,23.3970588 L18.5125,27.5516176 L17.025,26.6852941 L15.2625,27.7097059 L15.2625,25.1802941 L14.1625,24.9969118 L13.5375,24.0041176 L12.75,24.0041176 L13.975,28.3547059 Z"
              id="bike-Path"
              fill="#000000"
              opacity="0.1"
              style={{ mixBlendMode: "multiply" }}
            ></path>
            <path
              d="M39.8125,13.2794118 C39.7607538,14.2536125 39.2521327,15.1441844 38.44375,15.6760294 C38.1758938,15.9129675 37.7886967,15.951157 37.4806553,15.7710201 C37.1726138,15.5908832 37.0120392,15.2323669 37.08125,14.8792647 C37.1315833,13.9062383 37.6377485,13.015901 38.44375,12.4826471 C38.711057,12.2402928 39.1019219,12.1989018 39.4130344,12.3800039 C39.7241469,12.5611059 39.8852318,12.9237931 39.8125,13.2794118 L39.8125,13.2794118 Z"
              id="bike-Path"
              fill="#6D7072"
            ></path>
            <path
              d="M42.5,10.4275 C42.1995431,10.2625343 41.8602285,10.1838144 41.51875,10.1998529 C41.6453633,10.5872181 41.7066357,10.9933811 41.7,11.4013235 C41.5599615,13.9310289 40.2307723,16.2398047 38.125,17.6110294 C37.6662988,17.8978236 37.1444911,18.0651154 36.60625,18.0979412 C36.7567042,18.5718537 37.0750283,18.9727508 37.5,19.2235294 C38.3177264,19.5997804 39.272105,19.5032198 40,18.9705882 C42.0923474,17.5908371 43.4087582,15.2840931 43.54375,12.7608824 C43.5125,11.61 43.125,10.8005882 42.5,10.4275 Z"
              id="bike-Path"
              fill="url(#bike-linearGradient-1)"
            ></path>
            <path
              d="M39.13125,17.9082353 C39.0898286,17.9082353 39.0562492,17.8742616 39.0562492,17.8323529 L39.0562492,15.1385294 C39.0561121,15.1085412 39.0734427,15.0812838 39.1004718,15.0689775 C39.127501,15.0566713 39.1591581,15.061625 39.18125,15.0816176 L40.55,16.4411765 C40.5641216,16.4554147 40.5720591,16.4747556 40.5720591,16.4949265 C40.5720591,16.5150974 40.5641216,16.5344383 40.55,16.5486765 C40.5359274,16.5629642 40.5168114,16.5709951 40.496875,16.5709951 C40.4769386,16.5709951 40.4578226,16.5629642 40.44375,16.5486765 L39.19375,15.3282353 L39.19375,17.8576471 C39.1842943,17.8852878 39.1599787,17.9049692 39.13125,17.9082353 L39.13125,17.9082353 Z"
              id="bike-Path"
              fill="#6D7072"
            ></path>
            <path
              d="M41.1625,15.4041176 L41.125,15.4041176 L39.4625,14.405 C39.4370687,14.3886901 39.4226374,14.359488 39.425,14.3291176 C39.4328319,14.3002367 39.4536847,14.2767943 39.48125,14.2658824 L41.7125,13.6335294 C41.7535783,13.6252095 41.7941747,13.6498539 41.80625,13.6904412 C41.8140585,13.7304496 41.7892161,13.7695479 41.75,13.7789706 L39.6875,14.3607353 L41.2,15.2713235 C41.2254644,15.3001285 41.2254644,15.343695 41.2,15.3725 C41.1907175,15.386425 41.1777113,15.397391 41.1625,15.4041176 Z"
              id="bike-Path"
              fill="#6D7072"
            ></path>
            <path
              d="M39.8125,13.5892647 C39.796424,13.5956139 39.778576,13.5956139 39.7625,13.5892647 C39.7405625,13.5615424 39.7405625,13.5221341 39.7625,13.4944118 L41.6375,10.2947059 C41.6659701,10.2689419 41.7090299,10.2689419 41.7375,10.2947059 C41.7516216,10.3089441 41.7595591,10.328285 41.7595591,10.3484559 C41.7595591,10.3686268 41.7516216,10.3879677 41.7375,10.4022059 L40.04375,13.2794118 L41.9875,11.9451471 C42.0015726,11.9308593 42.0206886,11.9228284 42.040625,11.9228284 C42.0605614,11.9228284 42.0796774,11.9308593 42.09375,11.9451471 C42.1192144,11.9739521 42.1192144,12.0175185 42.09375,12.0463235 L39.88125,13.5766176 L39.8125,13.5892647 Z"
              id="bike-Path"
              fill="#6D7072"
            ></path>
            <path
              d="M39.3125,13.4185294 C39.296424,13.4248787 39.278576,13.4248787 39.2625,13.4185294 L37.71875,12.1538235 C37.7046284,12.1395853 37.6966909,12.1202444 37.6966909,12.1000735 C37.6966909,12.0799026 37.7046284,12.0605617 37.71875,12.0463235 C37.7318089,12.0302386 37.7513013,12.0209163 37.771875,12.0209163 C37.7924487,12.0209163 37.8119411,12.0302386 37.825,12.0463235 L39.275,13.2351471 L39.75,10.6235294 C39.7592379,10.5831457 39.7967473,10.5560381 39.8375,10.5602941 C39.8575213,10.5633817 39.8754573,10.5745303 39.8872289,10.5912043 C39.8990004,10.6078784 39.9036067,10.6286603 39.9,10.6488235 L39.375,13.3552941 C39.3735811,13.3816421 39.3561411,13.4043288 39.33125,13.4122059 L39.3125,13.4185294 Z"
              id="bike-Path"
              fill="#6D7072"
            ></path>
            <path
              d="M36.04375,15.5685294 C36.0120406,15.56974 35.9836316,15.5488359 35.975,15.5179412 C35.9691622,15.4986544 35.9711542,15.4778097 35.9805361,15.4600118 C35.9899179,15.4422139 36.0059174,15.4289277 36.025,15.4230882 L38.75,14.7338235 L36.8125,13.4058824 C36.7885358,13.3741505 36.7885358,13.3301142 36.8125,13.2983824 C36.8301364,13.2841419 36.8525284,13.2773453 36.875,13.2794118 L38.9125,14.7022059 C38.936729,14.7191062 38.9489499,14.7487816 38.94375,14.7780882 C38.9380762,14.8081073 38.9164105,14.8324635 38.8875,14.8413235 L36.0625,15.5685294 L36.04375,15.5685294 Z"
              id="bike-Path"
              fill="#6D7072"
            ></path>
            <path
              d="M35.8375,17.9082353 C35.8194601,17.9156187 35.7992899,17.9156187 35.78125,17.9082353 C35.7671284,17.8939971 35.7591909,17.8746562 35.7591909,17.8544853 C35.7591909,17.8343144 35.7671284,17.8149735 35.78125,17.8007353 L38.5375,15.2713235 C38.568566,15.2433844 38.6161358,15.2462156 38.64375,15.2776471 C38.6713642,15.3090786 38.668566,15.3572079 38.6375,15.3851471 L35.88125,17.9145588 C35.8663763,17.9173801 35.850998,17.9151573 35.8375,17.9082353 L35.8375,17.9082353 Z"
              id="bike-Path"
              fill="#6D7072"
            ></path>
            <path
              d="M37.59375,18.4014706 L37.59375,18.4014706 C37.5758454,18.394536 37.5616159,18.3803522 37.5544957,18.3623423 C37.5473755,18.3443325 37.5480114,18.3241326 37.55625,18.3066176 L38.75,15.2270588 C38.7538857,15.1961376 38.7760654,15.1707656 38.8059235,15.1630863 C38.8357816,15.155407 38.8672443,15.1669826 38.8852529,15.1922728 C38.9032615,15.2175629 38.9041527,15.251423 38.8875,15.2776471 L37.6625,18.3382353 C37.6568012,18.372867 37.6283657,18.3990216 37.59375,18.4014706 L37.59375,18.4014706 Z"
              id="bike-Path"
              fill="#6D7072"
            ></path>
            <path
              d="M43.6,8.04985294 L41.53125,6.78514706 C40.5125,6.19705882 39.10625,6.28558824 37.55625,7.18985294 C34.43125,9.01102941 31.93125,13.4311765 31.93125,17.0672059 C31.93125,18.9010294 32.55625,20.1783824 33.6,20.7727941 L35.66875,22.0375 C36.6875,22.6192647 38.09375,22.5307353 39.6375,21.6264706 C42.7625,19.8052941 45.2625455,15.3851471 45.2625455,11.7491176 C45.26875,9.92161765 44.63125,8.64426471 43.6,8.04985294 Z M40.09375,19.6977941 C39.04375,20.3301471 38.0875,20.3744118 37.39375,19.9760294 C36.7,19.5776471 36.25625,18.7113235 36.25625,17.4466176 C36.4121154,14.7246029 37.8363586,12.2392316 40.09375,10.75 C41.15,10.1176471 42.10625,10.0733824 42.8,10.4717647 C43.49375,10.8701471 43.9375,11.7364706 43.9375,13.0011765 C43.7792325,15.7240621 42.3527758,18.2092484 40.09375,19.6977941 L40.09375,19.6977941 Z"
              id="bike-Shape"
              fill="#3B4447"
            ></path>
            <path
              d="M43.6,8.04985294 C42.58125,7.46176471 41.18125,7.55029412 39.63125,8.45455882 C36.50625,10.2757353 34.00625,14.6958824 34.00625,18.3319118 C34.00625,20.1657353 34.63125,21.4430882 35.675,22.0375 C36.71875,22.6319118 38.1,22.5307353 39.64375,21.6264706 C42.76875,19.8052941 45.26875,15.3851471 45.26875,11.7491176 C45.26875,9.92161765 44.63125,8.64426471 43.6,8.04985294 Z M40.09375,19.6977941 C39.04375,20.3301471 38.0875,20.3744118 37.39375,19.9760294 C36.7,19.5776471 36.25625,18.7113235 36.25625,17.4466176 C36.4121154,14.7246029 37.8363586,12.2392316 40.09375,10.75 C41.15,10.1176471 42.10625,10.0733824 42.8,10.4717647 C43.49375,10.8701471 43.9375,11.7364706 43.9375,13.0011765 C43.7792325,15.7240621 42.3527758,18.2092484 40.09375,19.6977941 L40.09375,19.6977941 Z"
              id="bike-Shape"
              fill="#555E61"
            ></path>
            <path
              d="M42.8,10.4464706 C42.4576824,10.2587556 42.0697231,10.1732338 41.68125,10.1998529 C41.9571231,10.2160941 42.2260377,10.2938307 42.46875,10.4275 C43.09375,10.8005882 43.5125,11.61 43.5125,12.7545588 C43.3865379,15.2741857 42.0821654,17.5825181 40,18.9705882 C39.2732763,19.5053026 38.3188276,19.6042845 37.5,19.2298529 C36.7595446,18.703592 36.3579589,17.813579 36.45,16.9027941 C36.4595738,16.2287181 36.5756222,15.5605274 36.79375,14.9235294 C36.4721217,15.7280614 36.2984384,16.5852648 36.28125,17.4529412 C36.28125,18.7176471 36.71875,19.5713235 37.41875,19.9823529 C38.11875,20.3933824 39.06875,20.3175 40.11875,19.7041176 C42.3785334,18.2021666 43.7965577,15.7014091 43.9375,12.9695588 C43.9375,11.7238235 43.5,10.8511765 42.8,10.4464706 Z"
              id="bike-Path"
              fill="url(#bike-linearGradient-2)"
            ></path>
            <path
              d="M39.63125,13.9750006 L39.63125,13.9750006 C39.5955931,13.9751494 39.5657413,13.9476921 39.5625,13.9117647 L40.625,10.3136765 C40.6293413,10.2948346 40.6412426,10.2786733 40.6578452,10.2690745 C40.6744479,10.2594756 40.6942517,10.2573066 40.7125,10.2630882 C40.7326729,10.267177 40.7499757,10.2801916 40.7596574,10.2985584 C40.7693392,10.3169252 40.7703822,10.3387137 40.7625,10.3579412 L39.7,13.9117647 C39.6943012,13.9463964 39.6658657,13.972551 39.63125,13.9750006 Z"
              id="bike-Path"
              fill="#86898B"
            ></path>
            <path
              d="M36.25,16.3273529 C36.2212766,16.3249543 36.1955629,16.3083984 36.18125,16.2830882 C36.1670096,16.2446201 36.183021,16.2014209 36.21875,16.1819118 L38.625,14.9867647 L36.6375,14.3227941 C36.6069383,14.3188627 36.5818614,14.296422 36.5742714,14.2662127 C36.5666814,14.2360033 36.5781223,14.2041705 36.6031184,14.18595 C36.6281145,14.1677295 36.6615809,14.1668278 36.6875,14.1836765 L38.84375,14.8982353 C38.872032,14.9100469 38.8913576,14.9369322 38.8937522,14.9677941 C38.8939743,14.9976731 38.8768471,15.0249039 38.85,15.0373529 L36.2875,16.3020588 L36.25,16.3273529 Z"
              id="bike-Path"
              fill="#86898B"
            ></path>
            <path
              d="M39,14.3291176 C38.9837477,14.3344352 38.9662523,14.3344352 38.95,14.3291176 L37.35625,12.9 C37.3421284,12.8857618 37.3341909,12.8664209 37.3341909,12.84625 C37.3341909,12.8260791 37.3421284,12.8067382 37.35625,12.7925 C37.3703226,12.7782123 37.3894386,12.7701813 37.409375,12.7701813 C37.4293114,12.7701813 37.4484274,12.7782123 37.4625,12.7925 L38.9375,14.1141176 L39.0625,10.8448529 C39.0624198,10.825255 39.0705145,10.8065322 39.0847933,10.7932893 C39.0990722,10.7800464 39.11821,10.7735123 39.1375,10.7752941 C39.1789214,10.7752941 39.2125,10.8092678 39.2125,10.8511765 L39.08125,14.2785294 C39.0814743,14.3084084 39.0643471,14.3356391 39.0375,14.3480882 L39,14.3291176 Z"
              id="bike-Path"
              fill="#86898B"
            ></path>
            <path
              d="M40.20625,18.2244118 C40.1745406,18.2256224 40.1461316,18.2047182 40.1375,18.1738235 L39.25,15.2333824 C39.2351629,15.2103155 39.2351629,15.1805668 39.25,15.1575 C39.2735046,15.1451275 39.3014954,15.1451275 39.325,15.1575 L41.9,16.2008824 C41.9179046,16.207817 41.9321341,16.2220007 41.9392543,16.2400106 C41.9463745,16.2580205 41.9457386,16.2782204 41.9375,16.2957353 C41.9229189,16.3334256 41.8816668,16.3529031 41.84375,16.34 L39.4375,15.3408824 L40.275,18.1295588 C40.2830899,18.1479567 40.2820358,18.1691529 40.2721615,18.1866362 C40.2622872,18.2041195 40.2447733,18.2157995 40.225,18.2180882 L40.20625,18.2244118 Z"
              id="bike-Path"
              fill="#86898B"
            ></path>
            <path
              d="M42.43125,14.6389706 L42.43125,14.6389706 L39.375,14.4745588 C39.3412571,14.4701249 39.3138188,14.4448876 39.30625,14.4113235 C39.3000552,14.380111 39.3122294,14.3480857 39.3375,14.3291176 L42.5625,12.4004412 C42.5955406,12.3900094 42.6313458,12.4038087 42.6491535,12.4338374 C42.6669612,12.4638661 42.662146,12.5023244 42.6375,12.5269118 L39.625,14.3354412 L42.43125,14.4872059 C42.4639999,14.4889288 42.4918457,14.5119798 42.5,14.5441176 C42.5035831,14.5659585 42.4983074,14.5883384 42.485367,14.6061919 C42.4724267,14.6240455 42.4529157,14.6358633 42.43125,14.6389706 Z"
              id="bike-Path"
              fill="#86898B"
            ></path>
            <path
              d="M38.1625,19.0717647 L38.1625,19.0717647 C38.1424787,19.0686771 38.1245427,19.0575285 38.1127711,19.0408545 C38.1009996,19.0241805 38.0963933,19.0033985 38.1,18.9832353 L38.75,15.5052941 L36.925,18.4014706 C36.8944901,18.4281461 36.8492599,18.4281461 36.81875,18.4014706 C36.7947858,18.3697387 36.7947858,18.3257025 36.81875,18.2939706 L38.85,15.0626471 C38.8780988,15.0425667 38.9156512,15.0425667 38.94375,15.0626471 C38.9770434,15.0746054 38.9959592,15.1101479 38.9875,15.1448529 L38.2625,19.0148529 C38.2478267,19.0563732 38.2051902,19.0806383 38.1625,19.0717647 Z"
              id="bike-Path"
              fill="#86898B"
            ></path>
            <path
              d="M39.7625,13.7030882 C39.7462477,13.7084058 39.7287523,13.7084058 39.7125,13.7030882 C39.698737,13.6899469 39.6909365,13.6716468 39.6909365,13.6525 C39.6909365,13.6333532 39.698737,13.6150531 39.7125,13.6019118 L42.175,10.4844118 C42.1879886,10.4704868 42.2060759,10.4625945 42.225,10.4625945 C42.2439241,10.4625945 42.2620114,10.4704868 42.275,10.4844118 C42.2891216,10.49865 42.2970591,10.5179909 42.2970591,10.5381618 C42.2970591,10.5583327 42.2891216,10.5776736 42.275,10.5919118 L39.81875,13.7030882 C39.8007101,13.7104716 39.7805399,13.7104716 39.7625,13.7030882 L39.7625,13.7030882 Z"
              id="bike-Path"
              fill="#86898B"
            ></path>
            <path
              d="M40.49375,13.5955882 C40.4444173,14.5689133 39.9380097,15.4596769 39.13125,15.9922059 C38.8617434,16.2407386 38.4636064,16.2825362 38.1494073,16.0952827 C37.8352083,15.9080292 37.6783175,15.5354511 37.7625,15.1764706 C37.8142462,14.2022698 38.3228673,13.3116979 39.13125,12.7798529 C39.3991062,12.5429148 39.7863033,12.5047253 40.0943447,12.6848622 C40.4023862,12.8649992 40.5629608,13.2235154 40.49375,13.5766176 L40.49375,13.5955882 Z"
              id="bike-Path"
              fill="url(#bike-linearGradient-3)"
            ></path>
            <path
              d="M43.40625,8.78970588 C42.475,8.25220588 41.18125,8.33441176 39.7625,9.16279412 C36.9,10.8322059 34.58125,14.8919118 34.58125,18.2370588 C34.58125,19.9127941 35.16875,21.0952941 36.11875,21.6391176 C37.06875,22.1829412 38.3375,22.0880882 39.7625,21.2597059 C42.61875,19.6029412 44.9375,15.5242647 44.9375,12.1854412 C44.9375,10.5097059 44.375,9.33352941 43.40625,8.78970588 Z M40.09375,19.6977941 C39.04375,20.3301471 38.0875,20.3744118 37.39375,19.9760294 C36.7,19.5776471 36.25625,18.7113235 36.25625,17.4466176 C36.4121154,14.7246029 37.8363586,12.2392316 40.09375,10.75 C41.15,10.1176471 42.10625,10.0733824 42.8,10.4717647 C43.49375,10.8701471 43.9375,11.7364706 43.9375,13.0011765 C43.7792325,15.7240621 42.3527758,18.2092484 40.09375,19.6977941 L40.09375,19.6977941 Z"
              id="bike-Shape"
              fill="#3B4447"
            ></path>
            <path
              d="M40.6875,14.9108824 L39.25,14.1457353 C39.186186,14.1084588 39.107564,14.1084588 39.04375,14.1457353 C38.8637939,14.2564039 38.7510215,14.4513232 38.74375,14.6642647 C38.737463,14.7405181 38.7705517,14.814648 38.83125,14.8602941 L40.275,15.6254412 C40.338814,15.6627176 40.417436,15.6627176 40.48125,15.6254412 C40.6588135,15.5132756 40.7690871,15.3186199 40.775,15.1069118 C40.7839283,15.0302217 40.7502684,14.9548123 40.6875,14.9108824 L40.6875,14.9108824 Z"
              id="bike-Path"
              fill="url(#bike-linearGradient-4)"
            ></path>
            <path
              d="M40,16.34 L39.25625,15.7076471 C39.4448402,15.2688427 39.7190712,14.8730896 40.0625,14.5441176 C40.4375,14.3164706 40.30625,13.1592647 40.30625,13.1592647 C40.6538595,12.7226226 41.0938197,12.3704032 41.59375,12.1285294 C42.1625,11.9767647 42.7125,12.5648529 42.7125,12.5648529 L43.125,14.7654412 L40,16.34 Z"
              id="bike-Path"
              fill="#DEE5E9"
            ></path>
            <path
              d="M40,16.34 L43.125,14.7654412 L42.725,12.6470588 C42.522843,12.5352006 42.2873951,12.5014906 42.0625,12.5522059 C41.5631939,12.7922545 41.1232858,13.1422716 40.775,13.5766176 C40.775,13.5766176 40.90625,14.7401471 40.5375,14.9614706 C40.1939729,15.2879002 39.919662,15.6816171 39.73125,16.1186765 L40,16.34 Z"
              id="bike-Path"
              fill="#A2B4BF"
            ></path>
            <path
              d="M48.2875,8.13205882 C47.9427822,7.56340982 47.5584769,7.02032047 47.1375,6.50691176 L47.1375,6.50691176 C47.1375,6.50691176 45.05625,2.37132353 41.3125,2.99735294 C37.56875,3.62338235 34.94375,9.21970588 34.94375,9.21970588 L33.61875,14.5061765 L34.86875,17.1620588 L36.00625,16.77 C36.00625,16.7004412 36.00625,16.6372059 36.00625,16.5739706 C36.00625,13.8927941 38.30625,9.75720588 40.575,8.35338235 C44.65625,5.86823529 45.8875,11.0725 49.11875,8.98573529 C49.0125,8.80867647 48.43125,8.33441176 48.2875,8.13205882 Z"
              id="bike-Path"
              fill="#B61617"
            ></path>
            <path
              d="M35.975,16.5739706 C35.975,13.8927941 38.275,9.75720588 40.54375,8.35338235 C44.625,5.86823529 45.85625,11.0725 49.0875,8.98573529 C49.0125,8.75808824 48.43125,8.28382353 48.2875,8.08147059 C47.9427822,7.51282159 47.5584769,6.96973224 47.1375,6.45632353 L47.1375,6.45632353 C45.5420611,4.70208594 42.9349077,4.35339253 40.94375,5.62794118 C37.51875,7.525 34.8062302,12.4257353 34.8062302,16.3779412 C34.8056612,16.6376826 34.8181775,16.8972851 34.84375,17.1557353 L35.98125,16.7636765 C35.975,16.7004412 35.975,16.6372059 35.975,16.5739706 Z"
              id="bike-Path"
              fill="#000000"
              opacity="0.2"
              style={{ mixBlendMode: "multiply" }}
            ></path>
            <polygon
              id="bike-Path"
              fill="#546671"
              points="28.575 22.5623529 31.5125 20.9308824 33.08125 21.6454412 27.69375 24.7313235"
            ></polygon>
            <polygon
              id="bike-Path"
              fill="#939A9E"
              points="12.96875 24.7882353 13.00625 24.5036765 20.85625 24.7123529 20.85625 24.9969118 15.80625 26.5208824"
            ></polygon>
            <polygon
              id="bike-Path"
              fill="#DEE5E9"
              points="12.96875 24.5036765 18.23125 23.22 20.85625 24.7123529 15.80625 26.2363235"
            ></polygon>
            <path
              d="M31.2,20.3933824 L28.46875,21.9742647 L26.475,25.4016176 L23.825,26.9445588 C23.825,26.9445588 21.74375,27.6338235 20.7375,26.9445588 L19.31875,27.5010294 C19.31875,27.5010294 16.875,26.4513235 16.70625,25.9264706 C16.5375,25.4016176 16.25,24.4404412 16.25,24.4404412 L17.16875,22.0880882 L15.70625,18.2939706 L18.3625,16.1692647 L27.075,12.9695588 L30.5,13.8485294 L31.2,20.3933824 Z"
              id="bike-Path"
              fill="#889AA5"
            ></path>
            <polygon
              id="bike-Path"
              fill="#6E808B"
              points="19.0625 21.3419118 19.00625 21.2913235 22.4875 17.3707353 22.54375 17.415"
            ></polygon>
            <polygon
              id="bike-Path"
              fill="#6E808B"
              points="19.08125 21.5316176 19.025 21.4810294 22.50625 17.5604412 22.5625 17.6110294"
            ></polygon>
            <polygon
              id="bike-Path"
              fill="#6E808B"
              points="19.1 21.7213235 19.04375 21.6707353 22.525 17.7501471 22.58125 17.8007353"
            ></polygon>
            <polygon
              id="Rectangle"
              fill="#6E808B"
              transform="translate(20.826928, 19.938814) rotate(-47.930000) translate(-20.826928, -19.938814) "
              points="18.204074 19.9038002 23.4503663 19.8732986 23.4497812 19.9738268 18.2034889 20.0043284"
            ></polygon>
            <polygon
              id="Rectangle"
              fill="#6E808B"
              transform="translate(20.847241, 20.133878) rotate(-48.110000) translate(-20.847241, -20.133878) "
              points="18.224291 20.0988564 23.4707749 20.0683751 23.4701901 20.1688996 18.2237063 20.1993809"
            ></polygon>
            <polygon
              id="Rectangle"
              fill="#6E808B"
              transform="translate(20.861840, 20.322305) rotate(-48.110000) translate(-20.861840, -20.322305) "
              points="18.2388907 20.2872836 23.4853746 20.2568024 23.4847898 20.3573269 18.238306 20.3878081"
            ></polygon>
            <polygon
              id="bike-Path"
              fill="#6E808B"
              points="19.16875 22.4864706 19.1125 22.4358824 22.59375 18.5152941 22.65 18.5658824"
            ></polygon>
            <polygon
              id="Rectangle"
              fill="#6E808B"
              transform="translate(20.897953, 20.703141) rotate(-48.090000) translate(-20.897953, -20.703141) "
              points="18.2750136 20.6681207 23.5214762 20.6376371 23.5208914 20.738162 18.2744288 20.7686456"
            ></polygon>
            <polygon
              id="Rectangle"
              fill="#6E808B"
              transform="translate(20.918139, 20.887587) rotate(-48.110000) translate(-20.918139, -20.887587) "
              points="18.2951898 20.8525653 23.5416737 20.8220841 23.5410889 20.9226086 18.294605 20.9530898"
            ></polygon>
            <polygon
              id="bike-Path"
              fill="#6E808B"
              points="19.21875 23.0619118 19.1625 23.0113235 22.65 19.0844118 22.7 19.135"
            ></polygon>
            <polygon
              id="Rectangle"
              fill="#6E808B"
              transform="translate(20.954068, 21.279695) rotate(-48.110000) translate(-20.954068, -21.279695) "
              points="18.3311187 21.244673 23.5776026 21.2141917 23.5770178 21.3147162 18.330534 21.3451975"
            ></polygon>
            <polygon
              id="Rectangle"
              fill="#6E808B"
              transform="translate(20.968668, 21.468122) rotate(-48.110000) translate(-20.968668, -21.468122) "
              points="18.3457184 21.4331002 23.5922023 21.4026189 23.5916175 21.5031434 18.3451336 21.5336247"
            ></polygon>
            <polygon
              id="Rectangle"
              fill="#6E808B"
              transform="translate(20.989518, 21.656549) rotate(-48.110000) translate(-20.989518, -21.656549) "
              points="18.3665681 21.6215274 23.613052 21.5910462 23.6124672 21.6915707 18.3659833 21.7220519"
            ></polygon>
            <polygon
              id="Rectangle"
              fill="#6E808B"
              transform="translate(21.007730, 21.850773) rotate(-48.230000) translate(-21.007730, -21.850773) "
              points="18.3847164 21.8157452 23.6313279 21.7852781 23.6307434 21.8858002 18.3841319 21.9162672"
            ></polygon>
            <polygon
              id="bike-Path"
              fill="#6E808B"
              points="27.83125 15.6444118 24.525 15.3345588 24.53125 15.2586765 27.8375 15.5685294"
            ></polygon>
            <polygon
              id="bike-Path"
              fill="#6E808B"
              points="27.75625 15.8088235 24.45 15.4926471 24.45625 15.4230882 27.7625 15.7329412"
            ></polygon>
            <polygon
              id="bike-Path"
              fill="#6E808B"
              points="27.6875 15.9669118 24.375 15.6570588 24.3875 15.5811765 27.69375 15.8973529"
            ></polygon>
            <polygon
              id="bike-Path"
              fill="#6E808B"
              points="27.6125 16.1313235 24.30625 15.8214706 24.3125 15.7455882 27.61875 16.0554412"
            ></polygon>
            <polygon
              id="bike-Path"
              fill="#6E808B"
              points="27.5375 16.2957353 24.23125 15.9795588 24.2375 15.9036765 27.54375 16.2198529"
            ></polygon>
            <polygon
              id="bike-Path"
              fill="#6E808B"
              points="27.4625 16.4538235 24.15625 16.1439706 24.1625 16.0680882 27.46875 16.3779412"
            ></polygon>
            <polygon
              id="bike-Path"
              fill="#6E808B"
              points="27.39375 16.6182353 24.08125 16.3020588 24.09375 16.2261765 27.4 16.5423529"
            ></polygon>
            <polygon
              id="bike-Path"
              fill="#6E808B"
              points="27.31875 16.7763235 24.0125 16.4664706 24.01875 16.3905882 27.325 16.7004412"
            ></polygon>
            <polygon
              id="Rectangle"
              fill="#6E808B"
              transform="translate(25.607964, 16.745701) rotate(-84.680000) translate(-25.607964, -16.745701) "
              points="25.5591869 15.0830869 25.6603532 15.0829783 25.6567419 18.4083145 25.5555756 18.4084232"
            ></polygon>
            <polygon
              id="Rectangle"
              fill="#6E808B"
              transform="translate(25.535725, 16.909416) rotate(-84.670000) translate(-25.535725, -16.909416) "
              points="25.4869503 15.2468013 25.5881167 15.2466925 25.5844987 18.57203 25.4833323 18.5721388"
            ></polygon>
            <polygon
              id="bike-Path"
              fill="#6E808B"
              points="27.1 17.2632353 23.7875 16.9470588 23.8 16.8775 27.10625 17.1873529"
            ></polygon>
            <polygon
              id="bike-Path"
              fill="#6E808B"
              points="27.025 17.4213235 23.71875 17.1114706 23.725 17.0355882 27.03125 17.3454412"
            ></polygon>
            <polygon
              id="bike-Path"
              fill="#6E808B"
              points="26.95 17.5857353 23.64375 17.2758824 23.65 17.2 26.95625 17.5098529"
            ></polygon>
            <polygon
              id="bike-Path"
              fill="#6E808B"
              points="26.88125 17.7438235 23.56875 17.4339706 23.575 17.3580882 26.8875 17.6742647"
            ></polygon>
            <polygon
              id="bike-Path"
              fill="#6E808B"
              points="26.80625 17.9082353 23.49375 17.5983824 23.50625 17.5225 26.8125 17.8323529"
            ></polygon>
            <polygon
              id="bike-Path"
              fill="#6E808B"
              points="26.73125 18.0663235 23.425 17.7564706 23.43125 17.6805882 26.7375 17.9967647"
            ></polygon>
            <polygon
              id="bike-Path"
              fill="#6E808B"
              points="26.65625 18.2307353 23.35 17.9208824 23.35625 17.845 26.6625 18.1548529"
            ></polygon>
            <polygon
              id="bike-Path"
              fill="#6E808B"
              points="26.58125 18.3951471 23.275 18.0789706 23.28125 18.0030882 26.59375 18.3192647"
            ></polygon>
            <polygon
              id="bike-Path"
              fill="#6E808B"
              points="26.5125 18.5532353 23.20625 18.2433824 23.2125 18.1675 26.51875 18.4773529"
            ></polygon>
            <polygon
              id="bike-Path"
              fill="#6E808B"
              points="26.4375 18.7176471 23.13125 18.4014706 23.1375 18.3255882 26.44375 18.6417647"
            ></polygon>
            <polygon
              id="bike-Path"
              fill="#6E808B"
              points="26.3625 18.8757353 23.05625 18.5658824 23.0625 18.49 26.36875 18.7998529"
            ></polygon>
            <polygon
              id="bike-Path"
              fill="#6E808B"
              points="26.2875 19.0401471 22.98125 18.7239706 22.9875 18.6544118 26.3 18.9642647"
            ></polygon>
            <polygon
              id="bike-Path"
              fill="#6E808B"
              points="26.21875 19.1982353 22.9125 18.8883824 22.91875 18.8125 26.225 19.1286765"
            ></polygon>
            <polygon
              id="bike-Path"
              fill="#6E808B"
              points="26.14375 19.3626471 22.8375 19.0527941 22.84375 18.9769118 26.15 19.2867647"
            ></polygon>
            <polygon
              id="bike-Path"
              fill="#6E808B"
              points="26.06875 19.5207353 22.7625 19.2108824 22.76875 19.135 26.075 19.4511765"
            ></polygon>
            <polygon
              id="bike-Path"
              fill="#6E808B"
              points="25.99375 19.6851471 22.6875 19.3752941 22.69375 19.2994118 26.00625 19.6092647"
            ></polygon>
            <path
              d="M20.05,27.1911765 L19.51875,24.4151471 L19.375,21.2407353 L19.075,20.6589706 L18.45,18.0916176 C18.45,18.0916176 18.0375,17.0925 17.825,16.5739706 L15.69375,18.2813235 L17.15625,22.0754412 L16.25,24.4404412 C16.25,24.4404412 16.56875,25.3763235 16.7,25.9138235 C16.83125,26.4513235 19.3125,27.5010294 19.3125,27.5010294 L20.05,27.1911765 Z"
              id="bike-Path"
              fill="#6E808B"
            ></path>
            <path
              d="M25.7625,17.1620588 C25.6879639,18.5415478 24.9684356,19.8026202 23.825,20.5577941 C22.75625,21.1901471 21.8875,20.6779412 21.8875,19.4258824 C21.9620361,18.0463934 22.6815644,16.785321 23.825,16.0301471 C24.89375,15.4041176 25.7625,15.9163235 25.7625,17.1620588 Z"
              id="bike-Path"
              fill="#A2B4BF"
            ></path>
            <path
              d="M25.06875,18.0030882 C25.0328967,18.5980761 24.7227214,19.1414139 24.23125,19.4701471 C23.76875,19.7357353 23.39375,19.5207353 23.39375,18.9769118 C23.4296033,18.3819239 23.7397786,17.8385861 24.23125,17.5098529 C24.69375,17.2442647 25.06875,17.4592647 25.06875,18.0030882 Z"
              id="bike-Path"
              fill="#889AA5"
            ></path>
            <path
              d="M27.45,20.6969118 C26.9169226,20.7024654 26.4099912,20.9314365 26.05,21.3292647 C25.5063839,22.2090051 25.2343797,23.2327649 25.26875,24.2697059 C25.325,25.2308824 25.96875,25.3510294 25.96875,25.3510294 L26.30625,25.2751471 L27.08125,24.5858824 L27.38125,24.6427941 L28.325,21.2154412 L27.45,20.6969118 Z"
              id="bike-Path"
              fill="url(#bike-linearGradient-5)"
            ></path>
            <path
              d="M30.79375,16.5739706 L28.48125,16.6941176 L26.25,19.9254412 L27.5,20.6969118 L28.375,21.2027941 C28.6168514,21.3066379 28.8017795,21.512221 28.88125,21.7655882 L31.25,20.3933824 L30.79375,16.5739706 Z"
              id="bike-Path"
              fill="#6E808B"
            ></path>
            <path
              d="M27.08125,20.4629412 L26.25,19.9254412 C26.25,19.9254412 23.18125,20.9814706 21.45,22.8279412 L20.4125,22.6192647 C19.7421495,23.2912086 19.2517711,24.1246494 18.9875,25.0411765 C18.929505,25.8859662 19.237455,26.7145195 19.83125,27.3113235 L20.75625,26.9445588 C20.75625,26.9445588 20.18125,26.0592647 21.2125,24.5605882 C22.24375,23.0619118 24.48125,21.8477941 24.73125,21.6580882 C24.98125,21.4683824 27.08125,20.4629412 27.08125,20.4629412 Z"
              id="bike-Path"
              fill="url(#bike-linearGradient-6)"
            ></path>
            <polygon
              id="bike-Path"
              fill="#A2B4BF"
              points="18.48125 18.0916176 17.29375 19.6788235 19.08125 20.6589706 21.8875 17.3897059"
            ></polygon>
            <polygon
              id="bike-Path"
              fill="#6E808B"
              points="25.025 14.6579412 28.7125 14.8286765 28.66875 13.76 27.10625 13.4691176"
            ></polygon>
            <path
              d="M28.93125,22.3536765 C28.8703447,23.5486001 28.2476262,24.6417745 27.25625,25.2941176 C26.33125,25.8316176 25.58125,25.3952941 25.58125,24.3139706 C25.6460656,23.1235949 26.2683188,22.0359355 27.25625,21.3861765 C28.18125,20.8676471 28.93125,21.2723529 28.93125,22.3536765 Z"
              id="bike-Path"
              fill="#A2B4BF"
            ></path>
            <path
              d="M35.43125,12.3941176 L34.6875,16.1566176 L34.6875,16.4854412 C35.2635414,16.7281623 35.8107386,17.0357372 36.31875,17.4023529 C36.36875,17.5983824 35.99375,18.2939706 35.99375,18.2939706 L32.1,20.4629412 L28.04375,17.6047059 L29.13125,17.2063235 C28.7284218,16.9309643 28.3628346,16.6035919 28.04375,16.2325 C27.825,15.8847059 27.8375,13.5639706 27.8375,13.5639706 L35.43125,12.3941176 Z"
              id="bike-Path"
              fill="#B61617"
            ></path>
            <path
              d="M30.1375,19.0844118 L34.6875,16.4854412 L34.6875,16.1566176 L35.18125,13.6588235 C33.84375,14.1647059 30.1,15.5558824 29.8375,15.5558824 C29.136979,15.2099843 28.4695568,14.7993159 27.84375,14.3291176 C27.8232601,14.9677903 27.8905702,15.6062465 28.04375,16.2261765 C28.3628346,16.5972684 28.7284218,16.9246407 29.13125,17.2 L29.46875,17.1367647 L30.25625,17.6236765 L28.9,18.2054412 L30.1375,19.0844118 Z"
              id="bike-Path"
              fill="#000000"
              opacity="0.2"
              style={{ mixBlendMode: "multiply" }}
            ></path>
            <path
              d="M35.99375,18.2939706 C35.99375,18.2939706 36.36875,17.5983824 36.31875,17.4023529 C36.31875,17.4023529 36.31875,17.3644118 36.2625,17.3327941 L31.2125,19.8622059 L32.1,20.4945588 L35.99375,18.2939706 Z"
              id="bike-Path"
              fill="#000000"
              opacity="0.2"
              style={{ mixBlendMode: "multiply" }}
            ></path>
            <path
              d="M31.0875,18.1864706 C30.025,17.6995588 29.8375,15.5558824 29.8375,15.5558824 C29.136979,15.2099843 28.4695568,14.7993159 27.84375,14.3291176 C27.8232601,14.9677903 27.8905702,15.6062465 28.04375,16.2261765 C28.3628346,16.5972684 28.7284218,16.9246407 29.13125,17.2 L29.46875,17.1367647 L30.25625,17.6236765 L30.31875,17.9777941 L31.14375,18.5026471 L34.6875,16.4791176 L34.6875,16.1502941 L31.0875,18.1864706 Z"
              id="bike-Path"
              fill="#000000"
              opacity="0.2"
              style={{ mixBlendMode: "multiply" }}
            ></path>
            <path
              d="M31.70625,24.8704412 C31.70625,25.155 31.525,25.2877941 31.275,25.155 C31.0004255,24.99402 30.819046,24.709339 30.7875,24.3898529 C30.7875,24.0989706 30.96875,23.9725 31.225,24.1052941 C31.4964562,24.2684992 31.6750942,24.5525186 31.70625,24.8704412 Z"
              id="bike-Path"
              fill="#889AA5"
            ></path>
            <path
              d="M26.5,27.7286765 C25.9311915,27.7187099 25.390398,27.4770494 25,27.0583824 C24.375,26.4260294 23.3625,25.4964706 22.5375,24.7439706 C22.0625,24.3139706 21.65625,23.9408824 21.39375,23.6879412 C20.3788085,22.9564175 19.2992174,22.3213639 18.16875,21.7908824 C17.9098665,21.6651564 17.8007359,21.3508998 17.925,21.0889706 C18.0492641,20.8270414 18.3598665,20.7166269 18.61875,20.8423529 C19.8448217,21.417966 21.0127948,22.1125936 22.10625,22.9164706 C22.3625,23.1630882 22.76875,23.5488235 23.2375,23.9661765 C24.06875,24.725 25.1125,25.6672059 25.7375,26.3122059 C26.0925233,26.7079871 26.6763985,26.7904785 27.125,26.5082353 L27.125,26.5082353 L31.15625,24.0863235 C31.4012173,23.9706814 31.6929327,24.0621425 31.8303434,24.2976712 C31.9677542,24.5331999 31.9059384,24.8357978 31.6875,24.9969118 L27.6625,27.4061765 C27.3119698,27.6204631 26.9095327,27.732107 26.5,27.7286765 L26.5,27.7286765 Z"
              id="bike-Path"
              fill="#889AA5"
            ></path>
            <g
              id="Group"
              opacity="0.3"
              style={{ mixBlendMode: "screen" }}
              transform="translate(17.500000, 20.867647)"
              fill="#889AA5"
            >
              <path
                d="M9.00625,6.74720888 C8.45750154,6.74729591 7.932977,6.51854788 7.55625,6.11485294 C6.93125,5.4825 5.9125,4.55294118 5.05625,3.80044118 L3.9125,2.74441176 C2.88930372,2.00853221 1.79892852,1.37329047 0.65625,0.847352941 C0.473548649,0.735209873 0.405744129,0.500463021 0.500008539,0.306427477 C0.594272949,0.112391933 0.81939255,0.0233186119 1.01875,0.101176471 C2.22987984,0.679084792 3.38670271,1.36695203 4.475,2.15632353 C4.73125,2.40294118 5.1375,2.78867647 5.60625,3.20602941 C6.4375,3.96485294 7.48125,4.90705882 8.10625,5.55205882 C8.49818129,5.98599727 9.14197868,6.07142303 9.63125,5.75441176 L9.63125,5.75441176 L13.6625,3.33882353 C13.8557997,3.2218285 14.1062404,3.28552917 14.221875,3.48110294 C14.3375096,3.67667672 14.2745497,3.9300638 14.08125,4.04705882 L10.05625,6.46264706 C9.73722884,6.64965252 9.37494582,6.74783426 9.00625,6.74720888 Z"
                id="bike-Path"
              ></path>
            </g>
            <g
              id="Group"
              opacity="0.3"
              style={{ mixBlendMode: "screen" }}
              transform="translate(17.500000, 20.867647)"
              fill="#889AA5"
            >
              <path
                d="M9.00625,6.63338235 C8.48157937,6.62239217 7.9844229,6.39375337 7.63125,6.00102941 C7.00625,5.36867647 5.9875,4.43911765 5.13125,3.68661765 L4.01875,2.65588235 C2.98357593,1.9141852 1.88554048,1.266658 0.7375,0.720882353 C0.632614616,0.678955021 0.559830115,0.581056123 0.549098153,0.467472179 C0.538366192,0.353888234 0.591500042,0.243810376 0.686634515,0.182536905 C0.781768987,0.121263433 0.902830171,0.119147109 1,0.177058824 C2.20034154,0.749279491 3.34673403,1.4308145 4.425,2.21323529 L5.5625,3.26294118 C6.39375,4.02176471 7.4375,4.96397059 8.0625,5.60264706 C8.49264597,6.07503535 9.19529802,6.16822692 9.73125,5.82397059 L9.73125,5.82397059 L13.75,3.40205882 C13.8177525,3.36092578 13.8990262,3.34914288 13.975469,3.36937081 C14.0519118,3.38959874 14.1170771,3.44013167 14.15625,3.50955882 C14.1969048,3.57810837 14.2085506,3.66033831 14.1885579,3.73768039 C14.1685652,3.81502248 14.1186199,3.88095443 14.05,3.92058824 L10.04375,6.32352941 C9.734595,6.52567999 9.37434895,6.63326871 9.00625,6.63338235 Z"
                id="bike-Path"
              ></path>
            </g>
            <g
              id="Group"
              opacity="0.3"
              style={{ mixBlendMode: "screen" }}
              transform="translate(18.125000, 20.867647)"
              fill="#889AA5"
            >
              <path
                d="M8.3625,6.51955882 C7.88716386,6.50755676 7.4366221,6.30242775 7.1125,5.95044118 C6.4875,5.31808824 5.4625,4.38220588 4.6125,3.62338235 L3.46875,2.57367647 C2.42589596,1.82841516 1.31943015,1.17873407 0.1625,0.632352941 C0.0744421602,0.58415617 0.0393436937,0.474466592 0.0827759918,0.383199808 C0.12620829,0.291933024 0.232847324,0.251290297 0.325,0.290882353 C0.43125,0.347794118 2.9875,1.59985294 3.725,2.30808824 L4.8625,3.35779412 C5.69375,4.11661765 6.7375,5.05882353 7.3625,5.6975 C7.83366272,6.20392201 8.59325446,6.30197559 9.175,5.93147059 L9.175,5.93147059 L13.20625,3.50955882 C13.2643057,3.47567112 13.33582,3.47569431 13.3938541,3.50961964 C13.4518883,3.54354497 13.4876042,3.60621838 13.4876042,3.67403141 C13.4876042,3.74184444 13.4518057,3.80449466 13.39375,3.83838235 L9.375,6.24132353 C9.06589241,6.41848517 8.71771775,6.51416367 8.3625,6.51955882 Z"
                id="bike-Path"
              ></path>
            </g>
            <g
              id="Group"
              opacity="0.3"
              style={{ mixBlendMode: "screen" }}
              transform="translate(18.125000, 20.867647)"
              fill="#889AA5"
            >
              <path
                d="M8.36875,6.40573529 C7.91234183,6.40289962 7.47764705,6.20819258 7.16875,5.86823529 C6.54375,5.23588235 5.51875,4.3 4.66875,3.54117647 C4.2,3.11117647 3.7875,2.73808824 3.53125,2.49147059 C2.47972187,1.73350943 1.36268487,1.07318035 0.19375,0.518529412 C0.169187987,0.507233517 0.152447967,0.48353784 0.149835744,0.456368314 C0.147223522,0.429198788 0.159135956,0.402683111 0.181085747,0.386809484 C0.203035538,0.370935858 0.23168799,0.368115863 0.25625,0.379411765 C1.43842683,0.941306143 2.5680173,1.61010245 3.63125,2.37764706 C3.8875,2.63058824 4.3,3.01 4.76875,3.43367647 C5.6,4.18617647 6.64375,5.12838235 7.26875,5.76073529 C7.7771663,6.30235277 8.59173485,6.40801324 9.21875,6.01367647 L13.25,3.59808824 C13.2830406,3.58765648 13.3188458,3.60145578 13.3366535,3.63148447 C13.3544612,3.66151316 13.349646,3.69997141 13.325,3.72455882 L9.29375,6.14014706 C9.01454788,6.31102937 8.69504868,6.40276475 8.36875,6.40573529 Z"
                id="bike-Path"
              ></path>
            </g>
            <path
              d="M42.13125,16.7320588 C41.9435079,16.6091565 41.7051728,16.5970996 41.50625,16.7004412 L32.375,22.3916176 L33.625,24.4594118 L42.8,18.7682353 C42.9646144,18.6290802 43.0471941,18.4142343 43.01875,18.1991176 C42.9716198,17.5947056 42.6417927,17.0494935 42.13125,16.7320588 L42.13125,16.7320588 Z"
              id="bike-Path"
              fill="url(#bike-linearGradient-7)"
            ></path>
            <path
              d="M44.3375,16.9723529 C44.3063442,16.6544304 44.1277062,16.370411 43.85625,16.2072059 C43.7803588,16.1639965 43.6921343,16.1483755 43.60625,16.1629412 L43.60625,16.1629412 L41.73125,16.6308824 C41.8670163,16.6290995 42.0007906,16.6640281 42.11875,16.7320588 C42.6345387,17.0371046 42.9757688,17.572586 43.0375,18.1738235 C43.058148,18.3433733 43.0158292,18.51464 42.91875,18.6544118 L44.16875,17.3075 L44.16875,17.3075 C44.2984242,17.252555 44.3698059,17.1107869 44.3375,16.9723529 Z"
              id="bike-Path"
              fill="url(#bike-linearGradient-8)"
            ></path>
            <path
              d="M32.98125,22.4358824 C32.775623,22.2888503 32.4975704,22.3018745 32.30625,22.4675 L30.9,24.0863235 L30.9,24.0863235 C31.0005553,24.0275849 31.1244447,24.0275849 31.225,24.0863235 C31.4964562,24.2495286 31.6750942,24.533548 31.70625,24.8514706 C31.7292863,24.9704335 31.6769881,25.0913787 31.575,25.155 L33.58125,24.4783824 C33.79375,24.4088235 33.91875,24.1938235 33.9,23.8460294 C33.8288589,23.2563667 33.488826,22.7344657 32.98125,22.4358824 L32.98125,22.4358824 Z"
              id="bike-Path"
              fill="url(#bike-linearGradient-9)"
            ></path>
            <path
              d="M46.3875,12.7419118 C46.2043594,12.6108293 45.9658306,12.5891091 45.7625,12.685 L36.3875,17.9208824 L37.54375,20.0519118 L46.98125,14.7907353 C47.1535427,14.6610215 47.2479452,14.4508927 47.23125,14.2342647 C47.201724,13.6290194 46.8881888,13.0744651 46.3875,12.7419118 Z"
              id="bike-Path"
              fill="url(#bike-linearGradient-10)"
            ></path>
            <path
              d="M48.5875,13.0960294 C48.565303,12.7751295 48.3936038,12.4840324 48.125,12.3119118 C48.051528,12.2622249 47.9624257,12.241941 47.875,12.255 L47.875,12.255 L46,12.6280882 C46.1345242,12.6329726 46.2656554,12.6721222 46.38125,12.7419118 C46.8843198,13.0730554 47.2003113,13.6278446 47.23125,14.2342647 C47.2428028,14.4029383 47.19152,14.5698762 47.0875,14.7022059 L48.4,13.4375 L48.4,13.4375 C48.5399779,13.3893808 48.6210697,13.2416983 48.5875,13.0960294 Z"
              id="bike-Path"
              fill="url(#bike-linearGradient-11)"
            ></path>
            <path
              d="M35.5875,20.3554412 C35.5875,20.6463235 35.3875,20.7664706 35.1375,20.6210294 C34.8712016,20.4455136 34.7039413,20.1517149 34.6875,19.8305882 C34.6875,19.5397059 34.8875,19.4258824 35.1375,19.5713235 C35.4035968,19.744082 35.5711164,20.0359821 35.5875,20.3554412 L35.5875,20.3554412 Z"
              id="bike-Path"
              fill="#889AA5"
            ></path>
            <path
              d="M32.85625,21.6580882 C32.068228,21.5940951 31.3334629,21.230299 30.8,20.64 C29.625,19.565 26.86875,17.4782353 26.84375,17.4339706 C26.6145251,17.2609051 26.5669964,16.9327767 26.7375,16.7004412 C26.8211125,16.5792265 26.9508656,16.4987445 27.0955646,16.4783443 C27.2402636,16.4579441 27.3868202,16.499471 27.5,16.5929412 C27.6125,16.6814706 30.325,18.7492647 31.53125,19.8558824 C32.56875,20.8044118 32.99375,20.64 33.45,20.4882353 C33.9476131,20.2613779 34.4244636,19.9905229 34.875,19.6788235 C35.1135567,19.5165612 35.4367288,19.5785735 35.6,19.8179412 C35.6784113,19.9324029 35.7080187,20.0739659 35.6821551,20.2107526 C35.6562915,20.3475392 35.5771313,20.4680497 35.4625,20.5451471 C34.9423249,20.9063339 34.3899225,21.2175402 33.8125,21.4747059 C33.5077943,21.5954654 33.1834977,21.6576565 32.85625,21.6580882 Z"
              id="bike-Path"
              fill="#889AA5"
            ></path>
            <g
              id="Group"
              opacity="0.3"
              style={{ mixBlendMode: "screen" }}
              transform="translate(26.250000, 16.441176)"
              fill="#889AA5"
            >
              <path
                d="M6.60625,5.10308824 C5.84464997,5.04068039 5.13563199,4.68539515 4.625,4.11029412 C3.45,3.03529412 0.6875,0.948529412 0.65625,0.904264706 C0.478024114,0.763833829 0.444589513,0.504486214 0.58125,0.3225 C0.645788105,0.234810421 0.742646632,0.177270227 0.849778624,0.162976728 C0.956910617,0.148683229 1.06520075,0.178852587 1.15,0.246617647 C1.26875,0.328823529 3.96875,2.39661765 5.175,3.49691176 C6.2625,4.49602941 6.71875,4.31897059 7.20625,4.12926471 C7.7123211,3.89848854 8.19749877,3.62342331 8.65625,3.30720588 C8.74551787,3.24437422 8.8559565,3.22037524 8.9628514,3.24057955 C9.06974629,3.26078386 9.16417291,3.32350463 9.225,3.41470588 C9.35076964,3.60370631 9.30347797,3.85983409 9.11875,3.99014706 C8.6069106,4.34342405 8.06522107,4.65025432 7.5,4.90705882 C7.2170675,5.02916063 6.91375842,5.09568651 6.60625,5.10308824 Z"
                id="bike-Path"
              ></path>
            </g>
            <g
              id="Group"
              opacity="0.3"
              style={{ mixBlendMode: "screen" }}
              transform="translate(26.875000, 16.441176)"
              fill="#889AA5"
            >
              <path
                d="M5.975,4.98926471 C5.25267034,4.91919066 4.58307228,4.57593631 4.1,4.02808824 C2.91875,2.94676471 0.15625,0.866323529 0.125,0.815735294 C0.0107357739,0.709578244 -0.00469119028,0.532791101 0.0894215771,0.408020097 C0.183534344,0.283249093 0.356142939,0.251651016 0.4875,0.335147059 C0.6,0.423676471 3.3,2.48514706 4.5,3.58544118 C5.7,4.68573529 6.15,4.42647059 6.64375,4.21779412 C7.16052725,3.99106041 7.65624814,3.71806327 8.125,3.40205882 C8.26074622,3.32351272 8.43335738,3.36364032 8.52160257,3.49425859 C8.60984777,3.62487686 8.58412488,3.8021695 8.4625,3.90161765 C7.95590382,4.2491018 7.42057957,4.55164856 6.8625,4.80588235 C6.58080859,4.92330407 6.27964,4.98553393 5.975,4.98926471 Z"
                id="bike-Path"
              ></path>
            </g>
            <g
              id="Group"
              opacity="0.3"
              style={{ mixBlendMode: "screen" }}
              transform="translate(26.875000, 16.441176)"
              fill="#889AA5"
            >
              <path
                d="M5.98125,4.87544118 C5.27633813,4.8120171 4.62165857,4.47969592 4.15,3.94588235 C2.96875,2.85823529 0.19375,0.746176471 0.16875,0.727205882 C0.0859072875,0.664342883 0.0691179656,0.54543498 0.13125,0.461617647 C0.193382034,0.377800314 0.310907288,0.360813471 0.39375,0.423676471 C0.39375,0.423676471 3.20625,2.57367647 4.4,3.66764706 C5.59375,4.76161765 6.15625,4.54029412 6.6625,4.34426471 C7.1846145,4.1078886 7.68456192,3.82428565 8.15625,3.49691176 C8.24029961,3.45077524 8.34519905,3.47602322 8.39971179,3.55550996 C8.45422453,3.6349967 8.44088414,3.74325387 8.36875,3.80676471 C7.87036636,4.15331702 7.34096636,4.4518292 6.7875,4.69838235 C6.5317042,4.80676137 6.25843681,4.86677303 5.98125,4.87544118 Z"
                id="bike-Path"
              ></path>
            </g>
            <g
              id="Group"
              opacity="0.3"
              style={{ mixBlendMode: "screen" }}
              transform="translate(26.875000, 16.441176)"
              fill="#889AA5"
            >
              <path
                d="M5.975,4.76161765 C5.30065954,4.69615737 4.67562867,4.37544825 4.225,3.86367647 C3.0375,2.77602941 0.2625,0.632352941 0.2375,0.632352941 C0.223736996,0.619211584 0.215936465,0.600911474 0.215936465,0.581764706 C0.215936465,0.562617938 0.223736996,0.544317827 0.2375,0.531176471 C0.25157264,0.516888745 0.270688639,0.508857809 0.290625,0.508857809 C0.310561361,0.508857809 0.32967736,0.516888745 0.34375,0.531176471 C0.34375,0.531176471 3.15,2.67485294 4.34375,3.76882353 C5.5375,4.86279412 6.075,4.71735294 6.71875,4.47073529 C7.24923694,4.23026847 7.75749588,3.94246423 8.2375,3.61073529 C8.26800985,3.58405976 8.31324015,3.58405976 8.34375,3.61073529 C8.3692144,3.6395403 8.3692144,3.68310676 8.34375,3.71191176 C7.85427668,4.05506975 7.33309152,4.34943533 6.7875,4.59088235 C6.52996049,4.69987429 6.25413653,4.75783476 5.975,4.76161765 Z"
                id="bike-Path"
              ></path>
            </g>
            <path
              d="M36.975,17.9904412 C36.7757105,17.8365351 36.4992895,17.8365351 36.3,17.9904412 L34.81875,19.5397059 L34.81875,19.5397059 C34.9221376,19.4871801 35.0461634,19.4994826 35.1375,19.5713235 C35.4035968,19.744082 35.5711164,20.0359821 35.5875,20.3554412 C35.6015729,20.4777892 35.542684,20.5969526 35.4375,20.6589706 L37.5,20.0772059 C37.7125,20.0202941 37.85,19.8116176 37.85,19.4827941 C37.8134562,18.8716251 37.4876084,18.3158765 36.975,17.9904412 L36.975,17.9904412 Z"
              id="bike-Path"
              fill="url(#bike-linearGradient-12)"
            ></path>
            <path
              d="M25.58125,11.3127941 C25.58125,11.0535294 25.85625,10.6298529 26.74375,10.2125 C28.0412887,9.55829799 29.2475866,8.73329803 30.33125,7.75897059 C30.8259348,7.10679627 31.436342,6.55353996 32.13125,6.1275 C33.0316843,5.5984885 34.1433157,5.5984885 35.04375,6.1275 C36.29375,6.87367647 37.28125,7.59455882 37.15625,8.51779412 C37.03125,9.44102941 36.64375,11.8186765 35.54375,12.5079412 C34.44375,13.1972059 31.4125,13.8548529 30.75,14.0319118 C29.567915,14.145103 28.3756507,13.9962049 27.25625,13.5955882 C26.6209855,12.8956517 26.0596147,12.1305806 25.58125,11.3127941 L25.58125,11.3127941 Z"
              id="bike-Path"
              fill="#414E52"
            ></path>
            <path
              d="M37.03125,7.80955882 C36.3368747,7.96907691 35.7043094,8.33196763 35.2125,8.85294118 C34.0372765,10.3533146 32.4045703,11.418277 30.56875,11.8819118 C29.1709811,12.2889834 27.7135455,12.4452163 26.2625,12.3435294 C26.5375759,12.7978026 26.8718258,13.2125544 27.25625,13.5766176 C28.3756507,13.9772343 29.567915,14.1261325 30.75,14.0129412 C31.4125,13.8358824 34.44375,13.1782353 35.54375,12.4889706 C36.64375,11.7997059 37.0375,9.42205882 37.15625,8.49882353 C37.185995,8.26151945 37.1423254,8.02072031 37.03125,7.80955882 Z"
              id="bike-Path"
              fill="#2E383B"
            ></path>
            <path
              d="M41.925,4.6225 C42.4813625,4.18730359 42.803128,3.51278065 42.79375,2.80132353 C42.75625,2.0425 42.4375,1.69470588 41.325,1.02441176 C40.2125,0.354117647 39.35,-0.240294118 38.825,0.101176471 C38.3,0.442647059 36.0625,2.11838235 35.03125,2.75073529 C34,3.38308824 32.9125,3.72455882 32.53125,4.48970588 C32.15,5.25485294 32.1625,5.85558824 32.8875,6.38676471 C33.6125,6.91794118 35.3875,8.04352941 36.18125,8.14470588 C36.975,8.24588235 37.59375,7.68308824 38.1375,7.15191176 C39.3368846,6.21580073 40.6028117,5.37037475 41.925,4.6225 L41.925,4.6225 Z"
              id="bike-Path"
              fill="url(#bike-linearGradient-13)"
            ></path>
            <path
              d="M38.16875,7.12661765 C39.3560989,6.19206612 40.6094454,5.34667164 41.91875,4.59720588 C42.4751125,4.16200947 42.796878,3.48748653 42.7875,2.77602941 C42.7926648,2.45857543 42.6913168,2.14872514 42.5,1.89705882 C42.132754,2.37668945 41.6815082,2.78394478 41.16875,3.09852941 C40.2,3.79411765 37.71875,5.28014706 37.08125,5.72279412 C36.54375,6.09588235 35.59375,6.9875 35.9125,8.04352941 C36.0100816,8.07776253 36.1104762,8.10315647 36.2125,8.11941176 C37.00625,8.22058824 37.625,7.65779412 38.16875,7.12661765 Z"
              id="bike-Path"
              fill="#2E383B"
            ></path>
            <path
              d="M14.7625,12.1538235 C14.7625,12.1538235 15.68125,9.94691176 17.81875,9.78882353 C19.95625,9.63073529 25.86875,10.2630882 26.96875,11.3127941 C28.06875,12.3625 28.48125,13.3426471 26.725,14.6389706 C24.96875,15.9352941 21.1,18.1485294 19.575,18.2813235 C18.05,18.4141176 15.34375,17.6489706 14.575,16.5802941 C13.80625,15.5116176 14.375,13.2794118 14.7625,12.1538235 Z"
              id="bike-Path"
              fill="#B61617"
            ></path>
            <path
              d="M19.575,18.2813235 C21.1,18.1485294 24.96875,15.9289706 26.725,14.6389706 C28.00625,13.6904412 28.1375,12.9063235 27.675,12.1475 C27.0716184,11.8109137 26.4093468,11.5961499 25.725,11.5151471 C24.84375,11.5467647 23.59375,11.9577941 20.4875,12.7482353 C18.561391,13.1695499 16.5566573,13.0292122 14.70625,12.3435294 C14.2875,13.5323529 13.80625,15.6001471 14.55625,16.5992647 C15.30625,17.5983824 18.04375,18.4141176 19.575,18.2813235 Z"
              id="bike-Path"
              fill="#000000"
              opacity="0.2"
              style={{ mixBlendMode: "multiply" }}
            ></path>
            <path
              d="M20.95,10.9460294 C20.8875,11.2116176 20.26875,11.2811765 19.56875,11.1167647 C18.86875,10.9523529 18.35625,10.5919118 18.41875,10.3326471 C18.48125,10.0733824 19.09375,9.9975 19.79375,10.1682353 C20.49375,10.3389706 21.00625,10.6867647 20.95,10.9460294 Z"
              id="bike-Path"
              fill="#414E52"
            ></path>
            <path
              d="M18.24375,26.4513235 C17.882011,26.3858881 17.5423941,26.2293052 17.25625,25.9960294 C17.16875,25.9201471 16.7,25.3636765 15.95,21.9805882 C15.54375,20.1530882 15.25625,18.4077941 15.25625,18.3888235 C15.2022319,18.0913778 14.9277161,17.8886237 14.63125,17.9272059 C14.3354329,17.9788949 14.133518,18.2587447 14.175,18.5595588 C14.175,18.5595588 14.4625,20.3491176 14.875,22.2082353 C15.625,25.7304412 16.175,26.5588235 16.5125,26.8433824 C17.1186301,27.3333691 17.8624792,27.616701 18.6375,27.6527941 L18.24375,26.4513235 Z"
              id="bike-Path"
              fill="#151F22"
            ></path>
            <path
              d="M20.79375,28.4558824 C19.8103971,28.5142525 18.8390013,28.2135712 18.05625,27.6085294 C17.71875,27.3113235 17.175,26.5019118 16.4,22.9923529 C15.9875,21.1332353 15.69375,19.3626471 15.69375,19.3436765 C15.6613728,19.1448687 15.7362844,18.9436865 15.8902664,18.8159132 C16.0442484,18.68814 16.2539073,18.6531875 16.4402665,18.724222 C16.6266256,18.7952566 16.7613728,18.9614863 16.79375,19.1602941 C16.79375,19.1602941 17.08125,20.8676471 17.5,22.7204412 C18.25,26.1035294 18.71875,26.66 18.80625,26.7358824 C19.5625,27.4125 21.25625,27.3682353 22.5125,27.09 C22.7067632,27.0436868 22.9106902,27.1057491 23.0474638,27.2528086 C23.1842374,27.3998681 23.2330784,27.609583 23.1755888,27.8029557 C23.1180992,27.9963284 22.9630132,28.1439809 22.76875,28.1902941 C22.122406,28.3538093 21.4598694,28.442904 20.79375,28.4558824 Z"
              id="bike-Path"
              fill="#2E383B"
            ></path>
            <polygon
              id="bike-Path"
              fill="#939A9E"
              points="18.5625 28.2535294 18.60625 27.9689706 26.45625 28.1776471 26.45625 28.4622059 21.4 29.9861765"
            ></polygon>
            <polygon
              id="bike-Path"
              fill="#DEE5E9"
              points="18.5625 27.9689706 23.825 26.6852941 26.45625 28.1776471 21.4 29.7016176"
            ></polygon>
            <path
              d="M45.375,3.00367647 C45.339663,2.35887371 45.0031813,1.76955991 44.46875,1.41647059 C44.2856094,1.2853881 44.0470806,1.26366794 43.84375,1.35955882 C43.84375,1.35955882 42.5,2.14367647 43.125,3.26294118 C43.6625,4.1925 45.06875,3.61705882 45.06875,3.61705882 C45.281313,3.4914568 45.401211,3.25131533 45.375,3.00367647 L45.375,3.00367647 Z"
              id="bike-Path"
              fill="url(#bike-linearGradient-14)"
            ></path>
            <path
              d="M44.575,1.37852941 C44.3921925,1.24941074 44.1578107,1.22095403 43.95,1.30264706 C44.1247062,1.28000945 44.3017138,1.32030471 44.45,1.41647059 C44.9915799,1.76531726 45.3354354,2.35533831 45.375,3.00367647 C45.3873701,3.19815461 45.3220722,3.38951857 45.19375,3.53485294 C45.38545,3.40255647 45.4889257,3.17369246 45.4625,2.94044118 C45.4276044,2.30705003 45.0984397,1.72775285 44.575,1.37852941 L44.575,1.37852941 Z"
              id="bike-Path"
              fill="#B61617"
            ></path>
            <path
              d="M47.9125,6.03897059 C47.9048457,5.73329216 47.7510538,5.45038085 47.5,5.28014706 C47.4068661,5.21898471 47.2868839,5.21898471 47.19375,5.28014706 C47.19375,5.28014706 46.56875,5.64058824 46.84375,6.17808824 C47.11875,6.71558824 47.76875,6.36147059 47.76875,6.36147059 C47.8849176,6.30083122 47.9444787,6.16720706 47.9125,6.03897059 Z"
              id="bike-Path"
              fill="url(#bike-linearGradient-15)"
            ></path>
            <path
              d="M47.54375,5.26117647 C47.4598649,5.20159326 47.3524343,5.18741572 47.25625,5.22323529 C47.339718,5.21026701 47.4248977,5.23067848 47.49375,5.28014706 C47.7471829,5.44896085 47.9034372,5.73211167 47.9125,6.03897059 C47.9201547,6.13203001 47.8883417,6.22399342 47.825,6.29191176 C47.9177248,6.23217972 47.96867,6.12418167 47.95625,6.01367647 C47.9465974,5.71025493 47.7930388,5.43012692 47.54375,5.26117647 L47.54375,5.26117647 Z"
              id="bike-Path"
              fill="#FFBC45"
            ></path>
            <path
              d="M39.76875,15.5811765 L39.29375,14.9867647 C39.3358555,15.0352417 39.3842042,15.0777787 39.4375,15.1132353 C39.8371078,15.3001244 40.3116611,15.1694206 40.5625,14.8033824 C40.7805732,14.5548235 40.8062182,14.1889753 40.625,13.9117647 L40.625,13.9433824 L40.675,14.0066176 L40.675,14.0066176 L40.675,14.0382353 L41.03125,14.4872059 C41.2216346,14.7718928 41.1931531,15.1517473 40.9625,15.4041176 C40.7079309,15.7689192 40.2329429,15.8990199 39.83125,15.7139706 C39.8050062,15.6725266 39.7840087,15.627913 39.76875,15.5811765 Z"
              id="bike-Path"
              fill="url(#bike-linearGradient-16)"
            ></path>
            <path
              d="M39.18125,14.4619118 C39.18125,14.405 39.18125,14.405 39.21875,14.2595588 C39.1750605,14.4469866 39.2526415,14.6419548 39.4125,14.7464706 C39.7069299,14.8957597 40.0647553,14.810575 40.2625,14.5441176 C40.3683299,14.4375815 40.4229855,14.289652 40.4121783,14.1390019 C40.4013712,13.9883519 40.3261757,13.8499579 40.20625,13.76 L40.49375,13.76 C40.6545674,13.8792026 40.7550795,14.0642334 40.7683404,14.2654884 C40.7816014,14.4667434 40.7062549,14.6636361 40.5625,14.8033824 C40.3116611,15.1694206 39.8371078,15.3001244 39.4375,15.1132353 C39.2300713,14.9682835 39.1290208,14.7114385 39.18125,14.4619118 Z"
              id="bike-Path"
              fill="url(#bike-linearGradient-17)"
            ></path>
            <path
              d="M39.30625,14.6516176 C38.8,14.0192647 38.29375,13.3869118 37.79375,12.7545588 C37.8229432,12.7944041 37.8589226,12.8286655 37.9,12.8557353 C38.15,13.0075 38.46875,12.8557353 38.6875,12.5522059 C38.90625,12.2486765 38.94375,12.1095588 38.80625,11.9198529 L40.3125,13.8169118 C40.4797269,14.0373087 40.4582776,14.3492703 40.2625,14.5441176 C40.071971,14.8196028 39.7127871,14.9157995 39.4125,14.7717647 C39.367417,14.7416778 39.3308103,14.700283 39.30625,14.6516176 Z"
              id="bike-Path"
              fill="url(#bike-linearGradient-18)"
            ></path>
            <path
              d="M40.31875,13.5007353 L40.23125,13.3869118 L40.23125,13.3869118 C40.4216346,13.6715987 40.3931531,14.0514532 40.1625,14.3038235 C39.9061254,14.6663455 39.4314877,14.7936971 39.03125,14.6073529 C38.9751786,14.5757452 38.9262236,14.5326748 38.8875,14.4808824 C39.0375,14.6705882 38.81875,14.405 38.96875,14.5947059 C39.0114071,14.6468039 39.062051,14.6916386 39.11875,14.7275 C39.5185782,14.911681 39.9913644,14.7814639 40.24375,14.4176471 C40.4741698,14.1658225 40.5051185,13.7874585 40.31875,13.5007353 Z"
              id="bike-Path"
              fill="url(#bike-linearGradient-19)"
            ></path>
            <path
              d="M40.0875,13.2794118 C39.9953712,13.2274064 39.8928159,13.1971425 39.7875,13.1908824 L39.90625,13.3363235 L40.00625,13.3869118 C40.1425879,13.4878774 40.2281366,13.6444017 40.2401743,13.8149118 C40.252212,13.9854218 40.1895202,14.1526594 40.06875,14.2722059 C39.8531947,14.5801868 39.4513834,14.6891285 39.1125,14.5314706 C38.979407,14.4511298 38.8949326,14.3086829 38.8875,14.1520588 L38.75,13.9813235 C38.7071068,14.2259811 38.8098057,14.4733791 39.0125,14.6136765 C39.4127377,14.8000207 39.8873754,14.6726691 40.14375,14.3101471 C40.2806317,14.1704163 40.3526291,13.9785742 40.341909,13.7821382 C40.331189,13.5857022 40.2387542,13.403046 40.0875,13.2794118 L40.0875,13.2794118 Z"
              id="bike-Path"
              fill="#DEE5E9"
            ></path>
            <path
              d="M40.06875,13.1908824 L40,13.0770588 L40,13.0770588 C40.1911,13.3625445 40.1598858,13.7441537 39.925,13.9939706 C39.6723294,14.3577264 39.1981381,14.485665 38.8,14.2975 C38.7453251,14.2639187 38.6967114,14.2211486 38.65625,14.1710294 C38.80625,14.3607353 38.5875,14.0951471 38.7375,14.2848529 C38.7801571,14.336951 38.830801,14.3817857 38.8875,14.4176471 C39.2873282,14.601828 39.7601144,14.471611 40.0125,14.1077941 C40.2370279,13.8514451 40.2602226,13.4733558 40.06875,13.1908824 L40.06875,13.1908824 Z"
              id="bike-Path"
              fill="url(#bike-linearGradient-20)"
            ></path>
            <path
              d="M39.8375,12.9442647 C39.7453712,12.8922594 39.6428159,12.8619955 39.5375,12.8557353 L39.65625,13.0011765 C39.6918945,13.0128887 39.7256024,13.029941 39.75625,13.0517647 C39.8925879,13.1527303 39.9781366,13.3092547 39.9901743,13.4797647 C40.002212,13.6502748 39.9395202,13.8175123 39.81875,13.9370588 C39.6031947,14.2450397 39.2013834,14.3539814 38.8625,14.1963235 C38.7309959,14.1143824 38.647076,13.9728705 38.6375,13.8169118 L38.51875,13.6714706 C38.4758568,13.9161282 38.5785557,14.1635262 38.78125,14.3038235 C39.1793881,14.4919886 39.6535794,14.3640499 39.90625,14.0002941 C40.0524804,13.859307 40.1293329,13.6599338 40.1160567,13.4560064 C40.1027806,13.252079 40.0007449,13.0646274 39.8375,12.9442647 L39.8375,12.9442647 Z"
              id="bike-Path"
              fill="#DEE5E9"
            ></path>
            <path
              d="M39.81875,12.8810294 L39.73125,12.7608824 L39.73125,12.7608824 C39.92235,13.046368 39.8911358,13.4279772 39.65625,13.6777941 C39.4054111,14.0438324 38.9308578,14.1745361 38.53125,13.9876471 C38.4779542,13.9521904 38.4296055,13.9096535 38.3875,13.8611765 C38.5375,14.0508824 38.31875,13.7852941 38.46875,13.975 C38.5098087,14.0269601 38.560832,14.0699797 38.61875,14.1014706 C39.0168881,14.2896356 39.4910794,14.161697 39.74375,13.7979412 C39.9741698,13.5461166 40.0051185,13.1677526 39.81875,12.8810294 Z"
              id="bike-Path"
              fill="url(#bike-linearGradient-21)"
            ></path>
            <path
              d="M39.5875,12.6470588 C39.4965226,12.5923229 39.393296,12.5618609 39.2875,12.5585294 L39.40625,12.7039706 C39.4418945,12.7156829 39.4756024,12.7327351 39.50625,12.7545588 C39.6425879,12.8555244 39.7281366,13.0120488 39.7401743,13.1825588 C39.752212,13.3530689 39.6895202,13.5203064 39.56875,13.6398529 C39.3531947,13.9478338 38.9513834,14.0567755 38.6125,13.8991176 C38.4817562,13.8164186 38.3980948,13.6753424 38.3875,13.5197059 L38.26875,13.3742647 C38.2258568,13.6189223 38.3285557,13.8663203 38.53125,14.0066176 C38.9308578,14.1935067 39.4054111,14.0628029 39.65625,13.6967647 C39.8022888,13.5569453 39.8791206,13.3585356 39.8658266,13.1555561 C39.8525325,12.9525766 39.7504967,12.7661591 39.5875,12.6470588 L39.5875,12.6470588 Z"
              id="bike-Path"
              fill="#DEE5E9"
            ></path>
            <path
              d="M39.56875,12.5711765 L39.48125,12.4510294 L39.48125,12.4510294 C39.67235,12.7365151 39.6411358,13.1181243 39.40625,13.3679412 C39.1538644,13.7317581 38.6810782,13.8619751 38.28125,13.6777941 C38.2279542,13.6423375 38.1796055,13.5998005 38.1375,13.5513235 C38.2875,13.7410294 38.06875,13.4754412 38.21875,13.6651471 C38.2598087,13.7171072 38.310832,13.7601268 38.36875,13.7916176 C38.7668881,13.9797827 39.2410794,13.851844 39.49375,13.4880882 C39.7241698,13.2362636 39.7551185,12.8578997 39.56875,12.5711765 L39.56875,12.5711765 Z"
              id="bike-Path"
              fill="url(#bike-linearGradient-22)"
            ></path>
            <path
              d="M39.3375,12.3245588 C39.2458115,12.2705431 39.1432725,12.2381227 39.0375,12.2297059 L39.15625,12.3814706 L39.25625,12.4257353 C39.3907451,12.5279922 39.4748521,12.6841928 39.4868385,12.8539764 C39.4988249,13.02376 39.4375147,13.1904697 39.31875,13.3110294 C39.1034966,13.6191143 38.7030485,13.7303345 38.3625,13.5766176 C38.2317562,13.4939186 38.1480948,13.3528424 38.1375,13.1972059 L38.01875,13.0517647 C37.9758568,13.2964223 38.0785557,13.5438203 38.28125,13.6841176 C38.6810782,13.8682986 39.1538644,13.7380816 39.40625,13.3742647 C39.5522888,13.2344453 39.6291206,13.0360356 39.6158266,12.8330561 C39.6025325,12.6300766 39.5004967,12.4436591 39.3375,12.3245588 L39.3375,12.3245588 Z"
              id="bike-Path"
              fill="#DEE5E9"
            ></path>
            <path
              d="M39.31875,12.2613235 L39.23125,12.1411765 L39.23125,12.1411765 C39.4216346,12.4258634 39.3931531,12.8057179 39.1625,13.0580882 C38.9079309,13.4228898 38.4329429,13.5529905 38.03125,13.3679412 C37.9762962,13.3321667 37.9276982,13.2872726 37.8875,13.2351471 C38.0375,13.4311765 37.81875,13.1592647 37.96875,13.3552941 C38.0089377,13.405681 38.0576011,13.4484947 38.1125,13.4817647 C38.5127377,13.6681089 38.9873754,13.5407573 39.24375,13.1782353 C39.4741698,12.9264107 39.5051185,12.5480468 39.31875,12.2613235 L39.31875,12.2613235 Z"
              id="bike-Path"
              fill="url(#bike-linearGradient-23)"
            ></path>
            <path
              d="M39.0875,12.0147059 C38.9958115,11.9606902 38.8932725,11.9282698 38.7875,11.9198529 L38.90625,12.0716176 L39.00625,12.1158824 C39.1407451,12.2181393 39.2248521,12.3743398 39.2368385,12.5441234 C39.2488249,12.713907 39.1875147,12.8806167 39.06875,13.0011765 C38.8615258,13.3118844 38.4647507,13.4288612 38.125,13.2794118 C37.993679,13.2003018 37.9094651,13.0606609 37.9,12.9063235 L37.78125,12.7608824 C37.7383568,13.00554 37.8410557,13.2529379 38.04375,13.3932353 C38.4454429,13.5782846 38.9204309,13.4481839 39.175,13.0833824 C39.3238765,12.9395987 39.4005252,12.7354248 39.3835336,12.527899 C39.3665421,12.3203732 39.2577317,12.1317435 39.0875,12.0147059 Z"
              id="bike-Path"
              fill="#DEE5E9"
            ></path>
            <path
              d="M39.06875,11.9514706 L38.98125,11.8313235 L38.98125,11.8313235 C39.17235,12.1168092 39.1411358,12.4984184 38.90625,12.7482353 C38.6538644,13.1120522 38.1810782,13.2422692 37.78125,13.0580882 C37.7262962,13.0223138 37.6776982,12.9774197 37.6375,12.9252941 C37.7875,13.1213235 37.56875,12.8494118 37.71875,13.0454412 C37.7606339,13.094143 37.8090149,13.1367083 37.8625,13.1719118 C38.2627377,13.3582559 38.7373754,13.2309044 38.99375,12.8683824 C39.2241698,12.6165577 39.2551185,12.2381938 39.06875,11.9514706 Z"
              id="bike-Path"
              fill="url(#bike-linearGradient-23)"
            ></path>
            <path
              d="M38.8375,11.7048529 C38.7471186,11.6478957 38.6438533,11.6152457 38.5375,11.61 L38.65625,11.7554412 L38.75625,11.8060294 C38.8925879,11.906995 38.9781366,12.0635194 38.9901743,12.2340294 C39.002212,12.4045395 38.9395202,12.571777 38.81875,12.6913235 C38.6047348,13.0015342 38.2011517,13.1109563 37.8625,12.9505882 C37.731179,12.8714783 37.6469651,12.7318374 37.6375,12.5775 L37.5,12.4320588 C37.4571068,12.6767164 37.5598057,12.9241144 37.7625,13.0644118 C38.1623282,13.2485927 38.6351144,13.1183757 38.8875,12.7545588 C39.035619,12.6174373 39.1157222,12.4207221 39.1060579,12.2178301 C39.0963937,12.014938 38.9979651,11.826928 38.8375,11.7048529 Z"
              id="bike-Path"
              fill="#DEE5E9"
            ></path>
            <path
              d="M38.24375,10.9270588 C38.4048295,11.0475094 38.5053558,11.2335777 38.5185995,11.4357892 C38.5318433,11.6380007 38.4564623,11.8358627 38.3125,11.9767647 C38.058302,12.3382999 37.5858698,12.465764 37.1875,12.2802941 C37.0276927,12.1607617 36.9274423,11.9765846 36.9130268,11.7760401 C36.8986114,11.5754956 36.9714683,11.3785808 37.1125,11.2369118 C37.3670691,10.8721102 37.8420571,10.7420095 38.24375,10.9270588 Z"
              id="bike-Path"
              fill="#DEE5E9"
            ></path>
            <path
              d="M37.5,12.7292647 L37.0375,12.1538235 C37.0785587,12.2057836 37.129582,12.2488032 37.1875,12.2802941 C37.5858698,12.465764 38.058302,12.3382999 38.3125,11.9767647 C38.5473858,11.7269478 38.5786,11.3453386 38.3875,11.0598529 L38.3875,11.0598529 L38.59375,11.3191176 L38.84375,11.6352941 C39.0274976,11.9256926 38.9886115,12.3060137 38.75,12.5522059 C38.4954309,12.9170074 38.0204429,13.0471082 37.61875,12.8620588 C37.5726985,12.8241871 37.532651,12.7794033 37.5,12.7292647 L37.5,12.7292647 Z"
              id="bike-Path"
              fill="url(#bike-linearGradient-24)"
            ></path>
            <path
              d="M11.6375,29.1261765 L11.56875,28.9933824 C11.60625,28.9933824 15.39375,26.7801471 15.4125,24.6933824 C15.3969424,23.8441834 15.3091178,22.9979184 15.15,22.1639706 C14.9375,20.8170588 14.7375,19.5460294 15.10625,18.3698529 L15.24375,18.4204412 C14.8875,19.5333824 15.0875,20.7854412 15.3,22.1133824 C15.4549864,22.9479466 15.5427746,23.7938604 15.5625,24.6427941 C15.54375,26.8686765 11.8,29.0376471 11.6375,29.1261765 Z"
              id="bike-Path"
              fill="#2E383B"
            ></path>
            <path
              d="M9.0375,24.6617647 L8.9375,24.5479412 C8.98125,24.51 13.26875,20.2416176 13.7375,17.2126471 L13.88125,17.2126471 C13.40625,20.3301471 9.21875,24.5036765 9.0375,24.6617647 Z"
              id="bike-Path"
              fill="#2E383B"
            ></path>
            <path
              d="M13.46875,11.3823529 C12.99375,11.1736765 12.5625,11.1041176 12.5125,11.2242647 L7.85625,22.1829412 C7.80625,22.3094118 8.14375,22.5813235 8.61875,22.8152941 C9.09375,23.0492647 9.525,23.0935294 9.58125,22.9733824 L14.23125,11.9894118 C14.2875,11.8376471 13.94375,11.5657353 13.46875,11.3823529 Z"
              id="bike-Path"
              fill="url(#bike-linearGradient-25)"
            ></path>
            <path
              d="M8.61875,22.7647059 C8.14375,22.5560294 7.80625,22.2841176 7.85625,22.1323529 L7.3375,24.1748529 L4.23125,31.6176471 C4.23125,31.6872059 4.40625,31.8516176 4.6875,31.9780882 C4.96875,32.1045588 5.225,32.1425 5.25625,32.0666176 L8.38125,24.6491176 L9.56875,22.9544118 L9.56875,22.9544118 C9.2401696,22.9958975 8.90711232,22.9293891 8.61875,22.7647059 L8.61875,22.7647059 Z"
              id="bike-Path"
              fill="url(#bike-linearGradient-26)"
            ></path>
            <path
              d="M9.55,22.9797059 C9.22759176,23.0170745 8.90174324,22.9506956 8.61875,22.79 C8.14375,22.5813235 7.80625,22.3094118 7.85625,22.1576471 L7.3375,24.2001471 C7.925,24.7882353 8.3625,24.6491176 8.3625,24.6491176 L9.55,22.9544118 L9.55,22.9797059 Z"
              id="bike-Path"
              fill="#000000"
              opacity="0.1"
              style={{ mixBlendMode: "multiply" }}
            ></path>
            <path
              d="M14.23125,11.9641176 C14.18125,12.0842647 13.75,12.0147059 13.275,11.8060294 C12.8,11.5973529 12.45625,11.3254412 12.5125,11.1736765 C12.56875,11.0219118 12.99375,11.1230882 13.46875,11.3317647 C13.94375,11.5404412 14.2875,11.8376471 14.23125,11.9641176 Z"
              id="bike-Path"
              fill="#DEE5E9"
            ></path>
            <path
              d="M7.89375,31.4848529 C7.84001358,32.4584543 7.33187553,33.3481804 6.525,33.8814706 C6.24405313,34.0840396 5.87860487,34.1224549 5.5625,33.9826471 C5.27497577,33.7781092 5.11950496,33.4320494 5.15625,33.0783824 C5.20794671,32.1058525 5.71682345,31.2171839 6.525,30.6880882 C6.80639024,30.4742489 7.18156513,30.4353167 7.5,30.5869118 C7.78177724,30.7924132 7.93225763,31.1355815 7.89375,31.4848529 L7.89375,31.4848529 Z"
              id="bike-Path"
              fill="#6D7072"
            ></path>
            <path
              d="M10.54375,28.6645588 C10.2446382,28.503545 9.90668405,28.4312136 9.56875,28.4558824 C9.69258549,28.8439164 9.75378721,29.2496105 9.75,29.6573529 C9.61301854,32.179965 8.29709023,34.4858635 6.20625,35.8670588 C5.74711552,36.1528965 5.22555078,36.3201104 4.6875,36.3539706 C4.82908583,36.825194 5.13871339,37.2263602 5.55625,37.4795588 C6.37507757,37.8539904 7.32952632,37.7550085 8.05625,37.2202941 C10.1531082,35.8339606 11.4679908,33.516422 11.59375,30.9852941 C11.6834447,30.0766659 11.2821273,29.1896645 10.54375,28.6645588 Z"
              id="bike-Path"
              fill="url(#bike-linearGradient-27)"
            ></path>
            <path
              d="M7.20625,36.1452941 C7.16612953,36.1422751 7.13423391,36.1100042 7.13125,36.0694118 L7.13125,33.3755882 C7.13005347,33.3435058 7.15071453,33.3147626 7.18125,33.3060294 C7.2040486,33.2910177 7.2334514,33.2910177 7.25625,33.3060294 L8.625,34.6529412 C8.63912159,34.6671794 8.64705914,34.6865203 8.64705914,34.7066912 C8.64705914,34.7268621 8.63912159,34.746203 8.625,34.7604412 C8.6131966,34.7763642 8.59467233,34.7857353 8.575,34.7857353 C8.55532767,34.7857353 8.5368034,34.7763642 8.525,34.7604412 L7.275,33.54 L7.275,36.0694118 C7.27513598,36.1089702 7.24521273,36.1419978 7.20625,36.1452941 Z"
              id="bike-Path"
              fill="#6D7072"
            ></path>
            <path
              d="M9.24375,33.6411765 L9.20625,33.6411765 L7.54375,32.6294118 C7.51384593,32.6176127 7.49763703,32.5848135 7.50625,32.5535294 C7.50947813,32.5244628 7.52893065,32.499861 7.55625,32.4902941 L9.7875,31.8579412 C9.82857832,31.8496213 9.86917473,31.8742657 9.88125,31.9148529 C9.88696445,31.9333159 9.88482059,31.9533527 9.87533336,31.9701507 C9.86584613,31.9869487 9.84987279,31.99899 9.83125,32.0033824 L7.76875,32.5851471 L9.28125,33.5147059 C9.30761535,33.5455747 9.30761535,33.5913371 9.28125,33.6222059 C9.27100712,33.6323256 9.25789733,33.6389576 9.24375,33.6411765 L9.24375,33.6411765 Z"
              id="bike-Path"
              fill="#6D7072"
            ></path>
            <path
              d="M7.89375,31.8263235 C7.87538622,31.8317689 7.85586378,31.8317689 7.8375,31.8263235 C7.81556247,31.7986012 7.81556247,31.7591929 7.8375,31.7314706 L9.7125,28.5317647 C9.74188579,28.5085527 9.78311421,28.5085527 9.8125,28.5317647 C9.84833099,28.5513193 9.86220057,28.5962241 9.84375,28.6329412 L8.125,31.4975 L10.0625,30.1632353 C10.0765726,30.1489476 10.0956886,30.1409166 10.115625,30.1409166 C10.1355614,30.1409166 10.1546774,30.1489476 10.16875,30.1632353 C10.182513,30.1763767 10.1903135,30.1946768 10.1903135,30.2138235 C10.1903135,30.2329703 10.182513,30.2512704 10.16875,30.2644118 L7.95,31.7947059 L7.89375,31.8263235 Z"
              id="bike-Path"
              fill="#6D7072"
            ></path>
            <path
              d="M7.3875,31.6555882 C7.37351415,31.6615613 7.35773585,31.6615613 7.34375,31.6555882 L5.79375,30.3529412 C5.77253735,30.3359973 5.76187038,30.3088906 5.76576723,30.2818319 C5.76966409,30.2547731 5.78753274,30.2318732 5.81264224,30.2217583 C5.83775174,30.2116435 5.86628736,30.2158503 5.8875,30.2327941 L7.3375,31.4279412 L7.81875,28.8163235 C7.80959723,28.7852557 7.82096253,28.7517362 7.84703753,28.7328957 C7.87311252,28.7140551 7.90813748,28.7140551 7.93421247,28.7328957 C7.96028747,28.7517362 7.97165277,28.7852557 7.9625,28.8163235 L7.4625,31.6176471 C7.4571279,31.6443176 7.43804879,31.6660341 7.4125,31.6745588 L7.3875,31.6555882 Z"
              id="bike-Path"
              fill="#6D7072"
            ></path>
            <path
              d="M4.125,33.8055882 C4.08911003,33.8094791 4.05635736,33.7846256 4.05,33.7486765 C4.04219153,33.7086681 4.06703391,33.6695697 4.10625,33.6601471 L6.79375,32.9708824 L4.91875,31.6429412 C4.8891949,31.6184198 4.88259356,31.5753336 4.90342396,31.5429098 C4.92425435,31.5104861 4.96585223,31.499098 5,31.5164706 L7.0375,32.9392647 C7.05233713,32.9623315 7.05233713,32.9920802 7.0375,33.0151471 C7.03260278,33.0442811 7.01004532,33.067104 6.98125,33.0720588 L4.14375,33.8055882 L4.125,33.8055882 Z"
              id="bike-Path"
              fill="#6D7072"
            ></path>
            <path
              d="M3.91875,36.1452941 C3.90059807,36.1520703 3.88065193,36.1520703 3.8625,36.1452941 C3.84837841,36.1310559 3.84044086,36.111715 3.84044086,36.0915441 C3.84044086,36.0713732 3.84837841,36.0520323 3.8625,36.0377941 L6.61875,33.5083824 C6.6305534,33.4924593 6.64907767,33.4830882 6.66875,33.4830882 C6.68842233,33.4830882 6.7069466,33.4924593 6.71875,33.5083824 C6.73287159,33.5226206 6.74080914,33.5419614 6.74080914,33.5621324 C6.74080914,33.5823033 6.73287159,33.6016442 6.71875,33.6158824 L3.9625,36.1452941 C3.94819097,36.1493413 3.93305903,36.1493413 3.91875,36.1452941 L3.91875,36.1452941 Z"
              id="bike-Path"
              fill="#6D7072"
            ></path>
            <path
              d="M5.66875,36.6385316 L5.625,36.6385316 C5.5995356,36.6097244 5.5995356,36.5661579 5.625,36.5373529 L6.81875,33.4577941 C6.82692867,33.4392486 6.84211331,33.4247937 6.86090711,33.4176631 C6.87970091,33.4105325 6.90053544,33.4113213 6.91875,33.4198529 C6.95600207,33.4346056 6.97525302,33.476343 6.9625,33.5147059 L5.7375,36.5942647 C5.72519567,36.6214277 5.69828158,36.6387563 5.66875,36.6385316 L5.66875,36.6385316 Z"
              id="bike-Path"
              fill="#6D7072"
            ></path>
            <path
              d="M11.68125,26.2869118 L9.60625,25.0222059 C8.5875,24.4341176 7.1875,24.5226471 5.6375,25.4269118 C2.5,27.2227941 0,31.6176471 0,35.2789706 C0,37.1064706 0.625,38.3901471 1.66875,38.9845588 L3.74375,40.2492647 C4.7625,40.8310294 6.1625,40.7425 7.70625,39.8382353 C10.83125,38.0170588 13.33125,33.5969118 13.33125,29.9608824 C13.34375,28.1586765 12.70625,26.875 11.68125,26.2869118 Z M8.175,37.9411765 C7.11875,38.5735294 6.1625,38.6177941 5.46875,38.2194118 C4.775,37.8210294 4.3375,36.9547059 4.3375,35.69 C4.48364388,32.9566108 5.90903938,30.4574847 8.175,28.9617647 C9.23125,28.3294118 10.18125,28.2851471 10.875,28.6835294 C11.56875,29.0819118 12.0125,29.9482353 12.0125,31.2129412 C11.8643681,33.9457326 10.4394543,36.4440141 8.175,37.9411765 Z"
              id="bike-Shape"
              fill="#3B4447"
            ></path>
            <path
              d="M11.68125,26.2869118 C10.6625,25.6988235 9.25625,25.7873529 7.70625,26.6916176 C4.58125,28.5127941 2.08125,32.9329412 2.08125,36.5689706 C2.08125,38.4027941 2.70625,39.6801471 3.75,40.2745588 C4.79375,40.8689706 6.16875,40.7677941 7.7125,39.8635294 C10.8375,38.0423529 13.3375455,33.6222059 13.3375455,29.9861765 C13.34375,28.1586765 12.70625,26.875 11.68125,26.2869118 Z M8.175,37.9411765 C7.11875,38.5735294 6.1625,38.6177941 5.46875,38.2194118 C4.775,37.8210294 4.3375,36.9547059 4.3375,35.69 C4.48364388,32.9566108 5.90903938,30.4574847 8.175,28.9617647 C9.23125,28.3294118 10.18125,28.2851471 10.875,28.6835294 C11.56875,29.0819118 12.0125,29.9482353 12.0125,31.2129412 C11.8643681,33.9457326 10.4394543,36.4440141 8.175,37.9411765 Z"
              id="bike-Shape"
              fill="#555E61"
            ></path>
            <path
              d="M10.875,28.6835294 C10.5328487,28.5028942 10.1471391,28.4239678 9.7625,28.4558824 C10.0366313,28.4707112 10.3038153,28.5485655 10.54375,28.6835294 C11.2759353,29.2051476 11.6764544,30.0831483 11.59375,30.9852941 C11.4587582,33.5085049 10.1423474,35.8152488 8.05,37.195 C7.32327632,37.7297144 6.36882757,37.8286963 5.55,37.4542647 C4.925,37.0811765 4.5,36.2780882 4.5,35.1272059 C4.50726546,34.4508493 4.62336894,33.7802059 4.84375,33.1416176 C4.52332488,33.9465168 4.34968725,34.8034945 4.33125,35.6710294 C4.33125,36.9357353 4.7625,37.7894118 5.4625,38.2004412 C6.1625,38.6114706 7.1125,38.5355882 8.16875,37.9222059 C10.4332043,36.4250435 11.8581181,33.926762 12.006317,31.1939706 C12.0125,29.9608824 11.58125,29.0882353 10.875,28.6835294 Z"
              id="bike-Path"
              fill="url(#bike-linearGradient-28)"
            ></path>
            <path
              d="M7.70625,32.2120588 L7.70625,32.2120588 C7.68716736,32.2062193 7.67116787,32.1929331 7.66178605,32.1751353 C7.65240423,32.1573374 7.65041219,32.1364926 7.65625,32.1172059 L8.69375,28.5570588 C8.70810744,28.5220999 8.7457354,28.5031952 8.78194043,28.5127511 C8.81814545,28.5223071 8.8418509,28.5573999 8.8375,28.595 L7.8,32.1614706 C7.78548676,32.1995343 7.74557768,32.2210696 7.70625,32.2120588 L7.70625,32.2120588 Z"
              id="bike-Path"
              fill="#86898B"
            ></path>
            <path
              d="M4.3375,34.5644118 C4.30796842,34.5646387 4.28105433,34.54731 4.26875,34.5201471 C4.2432856,34.4913421 4.2432856,34.4477756 4.26875,34.4189706 L6.66875,33.2238235 L4.6875,32.5914706 C4.66841736,32.5856311 4.65241787,32.5723449 4.64303605,32.554547 C4.63365423,32.5367492 4.63166219,32.5159044 4.6375,32.4966176 C4.64302388,32.4773604 4.65649282,32.4614517 4.67445223,32.4529721 C4.69241163,32.4444924 4.7131142,32.4442667 4.73125,32.4523529 L6.8875,33.1669118 C6.91679897,33.1773802 6.93668391,33.2050436 6.9375,33.2364706 C6.94054895,33.2652981 6.92560599,33.2930158 6.9,33.3060294 L4.3375,34.5707353 L4.3375,34.5644118 Z"
              id="bike-Path"
              fill="#86898B"
            ></path>
            <path
              d="M7.08125,32.5661765 C7.06517404,32.5725257 7.04732596,32.5725257 7.03125,32.5661765 L5.43125,31.1117647 C5.417487,31.0986233 5.40968647,31.0803232 5.40968647,31.0611765 C5.40968647,31.0420297 5.417487,31.0237296 5.43125,31.0105882 C5.44532264,30.9963005 5.46443864,30.9882696 5.484375,30.9882696 C5.50431136,30.9882696 5.52342736,30.9963005 5.5375,31.0105882 L7.01875,32.3322059 L7.13125,29.0882353 C7.13284022,29.0680828 7.14231284,29.0494056 7.15756644,29.0363468 C7.17282004,29.0232881 7.19259341,29.0169276 7.2125,29.0186765 C7.25146273,29.0219728 7.28138598,29.0550004 7.28125046,29.0945588 L7.15625,32.5219118 C7.15385756,32.5527737 7.13453198,32.579659 7.10625,32.5914706 L7.08125,32.5661765 Z"
              id="bike-Path"
              fill="#86898B"
            ></path>
            <path
              d="M8.28125,36.4614803 C8.24767042,36.4620361 8.21860157,36.4379727 8.2125,36.4045588 L7.33125,33.4704412 C7.31902135,33.44666 7.31902135,33.41834 7.33125,33.3945588 C7.35692639,33.3820678 7.38682361,33.3820678 7.4125,33.3945588 L10,34.4189706 C10.0372521,34.4337233 10.056503,34.4754606 10.04375,34.5138235 C10.0247127,34.5503835 9.98320882,34.5687551 9.94375,34.5580882 L7.5,33.5779412 L8.3375,36.3602941 C8.34333781,36.3795809 8.34134577,36.4004256 8.33196395,36.4182235 C8.32258213,36.4360213 8.30658264,36.4493076 8.2875,36.4551471 L8.28125,36.4614803 Z"
              id="bike-Path"
              fill="#86898B"
            ></path>
            <path
              d="M10.50625,32.8823529 L10.50625,32.8823529 L7.45625,32.7116176 C7.42420503,32.7081227 7.39723147,32.6857938 7.3875,32.6547059 C7.38070376,32.621934 7.39594214,32.5885291 7.425,32.5725 L10.65,30.6754412 C10.6629886,30.6615163 10.6810759,30.653624 10.7,30.653624 C10.7189241,30.653624 10.7370114,30.6615163 10.75,30.6754412 C10.7729421,30.7051727 10.7729421,30.7468861 10.75,30.7766176 L7.7375,32.5851471 L10.5375,32.7369118 C10.55687,32.7368306 10.5753752,32.7450206 10.5884641,32.7594674 C10.601553,32.7739142 10.6080111,32.7932772 10.60625,32.8127941 C10.6056252,32.8370764 10.5935487,32.8595885 10.5737651,32.8733497 C10.5539814,32.887111 10.5288821,32.890458 10.50625,32.8823529 L10.50625,32.8823529 Z"
              id="bike-Path"
              fill="#86898B"
            ></path>
            <path
              d="M6.25,37.3088235 L6.25,37.3088235 C6.23023064,37.3059744 6.21266248,37.2945693 6.20186414,37.2775743 C6.1910658,37.2605793 6.18810438,37.2396737 6.19375,37.2202941 L6.81875,33.7423529 L5,36.6385294 C4.96863709,36.6627755 4.92511291,36.6627755 4.89375,36.6385294 C4.86738465,36.6076606 4.86738465,36.5618982 4.89375,36.5310294 L6.925,33.2997059 C6.95114468,33.2807083 6.98635532,33.2807083 7.0125,33.2997059 C7.04579344,33.3116642 7.06470919,33.3472068 7.05625,33.3819118 L6.3125,37.2519118 C6.30512449,37.2820255 6.28037337,37.3045636 6.25,37.3088235 Z"
              id="bike-Path"
              fill="#86898B"
            ></path>
            <path
              d="M7.8375,31.9401471 C7.82330573,31.944967 7.80794427,31.944967 7.79375,31.9401471 C7.77962841,31.9259089 7.77169086,31.906568 7.77169086,31.8863971 C7.77169086,31.8662262 7.77962841,31.8468853 7.79375,31.8326471 L10.25,28.7214706 C10.2758883,28.6882929 10.3234581,28.6826306 10.35625,28.7088235 C10.3890419,28.7350164 10.3946383,28.7831458 10.36875,28.8163235 L7.90625,31.9275 C7.88731717,31.9440631 7.86098353,31.9489073 7.8375,31.9401471 L7.8375,31.9401471 Z"
              id="bike-Path"
              fill="#86898B"
            ></path>
            <path
              d="M8.575,31.8326471 C8.52325378,32.8068478 8.0146327,33.6974197 7.20625,34.2292647 C6.92623333,34.4343102 6.55944771,34.4728661 6.24375,34.3304412 C5.95725104,34.1279466 5.80174933,33.7842386 5.8375,33.4325 C5.88924622,32.4582992 6.3978673,31.5677274 7.20625,31.0358824 C7.48626667,30.8308369 7.85305229,30.792281 8.16875,30.9347059 C8.45524896,31.1372004 8.61075067,31.4809085 8.575,31.8326471 L8.575,31.8326471 Z"
              id="bike-Path"
              fill="url(#bike-linearGradient-29)"
            ></path>
            <path
              d="M8,32.25 C7.97593567,32.7293422 7.7245304,33.1674117 7.325,33.4261765 C7.20160534,33.5353234 7.02982792,33.5691175 6.875,33.5147059 C6.73177086,33.4166021 6.65461487,33.2458377 6.675,33.0720588 C6.70067417,32.5948014 6.94888452,32.1582569 7.34375,31.8958824 C7.482058,31.795106 7.66273384,31.7758638 7.81875,31.8452941 C7.94002547,31.9428176 8.00748509,32.0934455 8,32.25 L8,32.25 Z"
              id="bike-Path"
              fill="url(#bike-linearGradient-30)"
            ></path>
            <path
              d="M11.4875,27.0267647 C10.55,26.4829412 9.2625,26.5651471 7.8375,27.3998529 C5,29.0882353 2.6625,33.1289706 2.6625,36.4741176 C2.6625,38.1498529 3.25,39.3323529 4.19375,39.8698529 C5.1375,40.4073529 6.41875,40.3251471 7.8375,39.4967647 C10.7,37.8273529 13.0125,33.7613235 13.0125,30.4225 C13.0125,28.7467647 12.43125,27.5705882 11.4875,27.0267647 Z M8.175,37.9411765 C7.11875,38.5735294 6.1625,38.6177941 5.46875,38.2194118 C4.775,37.8210294 4.3375,36.9547059 4.3375,35.69 C4.48364388,32.9566108 5.90903938,30.4574847 8.175,28.9617647 C9.23125,28.3294118 10.18125,28.2851471 10.875,28.6835294 C11.56875,29.0819118 12.0125,29.9482353 12.0125,31.2129412 C11.8643681,33.9457326 10.4394543,36.4440141 8.175,37.9411765 Z"
              id="bike-Shape"
              fill="#3B4447"
            ></path>
            <path
              d="M15.45,30.9852941 C14.9970806,30.5202323 14.7549486,29.8860303 14.78125,29.2336765 C14.78125,27.3872059 14.19375,26.1098529 13.2,25.4395588 L13.375,25.5723529 C12.2978862,24.5909726 11.0580375,23.8096265 9.7125,23.2642647 C8.50282277,23.0859227 7.26809308,23.2798107 6.16875,23.8207353 C4.31030185,24.6460101 2.70143944,25.9552247 1.50625,27.6148529 L4.00625,29.0692647 C4.00625,29.0692647 5.64375,29.9292647 6.725,29.2083824 C7.53758543,28.5468398 8.4102258,27.9645872 9.33125,27.4694118 C9.83125,27.2607353 11.33125,26.7169118 12.13125,27.6338235 C12.56875,28.1270588 12.5375,28.4179412 12.6875,29.2589706 C12.89375,30.3592647 13.65,32.4207353 15.8125,31.4975 C15.7236177,31.305787 15.600754,31.1321827 15.45,30.9852941 L15.45,30.9852941 Z"
              id="bike-Path"
              fill="#B61617"
            ></path>
            <path
              d="M9.3125,27.4630882 C9.8125,27.2544118 11.3125,26.7105882 12.1125,27.6275 C12.55,28.1207353 12.51875,28.4116176 12.66875,29.2526471 C12.875,30.3529412 13.63125,32.4144118 15.79375,31.4911765 C15.7023073,31.3084728 15.5795749,31.143618 15.43125,31.0042647 C14.9783306,30.5392029 14.7361986,29.9050009 14.7625,29.2526471 C14.7625,25.2941176 12.05625,23.9408824 8.4625,25.5027941 C6.69558462,26.2965454 5.15607804,27.5300226 3.9875,29.0882353 L3.9875,29.0882353 C3.9875,29.0882353 5.625,29.9482353 6.70625,29.2273529 C7.5177846,28.5570394 8.39045968,27.9662942 9.3125,27.4630882 L9.3125,27.4630882 Z"
              id="bike-Path"
              fill="#000000"
              opacity="0.2"
              style={{ mixBlendMode: "multiply" }}
            ></path>
            <path
              d="M12.4,24.7945588 C10.9420015,24.3751171 9.38173805,24.5217032 8.025,25.2055882 C6.24101059,25.9879585 4.6820719,27.2149729 3.49375,28.7720588 L3.9875,29.0629412 C5.16438428,27.5114691 6.71032852,26.2867038 8.48125,25.5027941 C10.5,24.6238235 12.23125,24.6680882 13.375,25.5786765 C13.0375,25.2941176 12.7125,25.0222059 12.4,24.7945588 Z"
              id="bike-Path"
              fill="url(#bike-linearGradient-31)"
              opacity="0.5"
              style={{ mixBlendMode: "screen" }}
            ></path>
            <path
              d="M8.9875,33.0720588 L7.54375,32.3005882 C7.48030156,32.2619562 7.40094844,32.2619562 7.3375,32.3005882 C7.1617356,32.4115181 7.05171718,32.603355 7.04375,32.8127941 C7.03333514,32.8897096 7.06733154,32.9658729 7.13125,33.0088235 L8.56875,33.7802941 C8.63219844,33.8189262 8.71155156,33.8189262 8.775,33.7802941 C8.9507644,33.6693643 9.06078282,33.4775274 9.06875,33.2680882 C9.0777202,33.1928217 9.04684852,33.1183385 8.9875,33.0720588 L8.9875,33.0720588 Z"
              id="bike-Path"
              fill="url(#bike-linearGradient-32)"
            ></path>
            <path
              d="M12.56875,3.61705882 C12.5220647,3.41961876 12.3917254,3.25304277 12.2125,3.16176471 C12.1995349,3.01805515 12.0801278,2.9082261 11.9375,2.9088211 C11.7890735,2.9088211 11.66875,3.03056257 11.66875,3.18073529 C11.6687114,3.33196441 11.788068,3.45553357 11.9375,3.45897059 C12.0071296,3.45803368 12.0739399,3.43099514 12.125,3.38308824 C12.2375,3.60441176 12.20625,4.16720588 12.4375,4.73 C12.6503044,5.1376599 13.0576921,5.40366007 13.5125,5.43191176 L13.5875,4.89441176 C12.975,4.8375 12.6875,4.10397059 12.56875,3.61705882 Z"
              id="bike-Path"
              fill="#AEBFC8"
            ></path>
            <polygon
              id="bike-Path"
              fill="#939A9E"
              points="17.175 19.2551471 10.43125 15.2966176 9.40625 15.5305882 16.15625 19.4891176"
            ></polygon>
            <path
              d="M13.525,11.4519118 L15.3125,12.0210294 L16.9625,12.8367647 C16.9625,12.8367647 17.95625,13.6588235 18.00625,13.9307353 C18.05625,14.2026471 16.38125,13.6967647 16.38125,13.6967647 L13.525,12.1664706 L13.525,11.4519118 Z"
              id="bike-Path"
              fill="#DEE5E9"
            ></path>
            <path
              d="M16.43125,12.9822059 C16.1271619,13.0127303 15.8225556,12.9294965 15.575,12.7482353 C15.1625,12.5142647 14.89375,12.3372059 14.90625,12.1158824 L14.90625,11.3317647 L17.04375,12.4636765 L16.43125,12.9822059 Z"
              id="bike-Path"
              fill="#939A9E"
            ></path>
            <path
              d="M13.8625,4.52132353 L13.3625,3.37676471 C13.3625,3.37676471 13.16875,2.84558824 13.8625,2.58632353 C14.0271747,2.49405917 14.2241793,2.48180212 14.3987864,2.55295736 C14.5733934,2.6241126 14.7070035,2.77110053 14.7625,2.95308824 L15.25,4.16720588 L13.8625,4.52132353 Z"
              id="bike-Path"
              fill="#2E383B"
            ></path>
            <path
              d="M14.49375,6.60808824 C14.3515799,6.38034682 14.2618653,6.12316514 14.23125,5.85558824 L13.48125,5.85558824 L13.2875,4.98926471 L13.95625,4.02808824 L15.26875,3.90161765 L15.40625,4.79955882 C15.40625,4.79955882 16.25,5.05882353 16.25,5.54573529 C16.2242677,5.85440332 16.0696738,6.13732624 15.825,6.32352941 C15.825,6.32352941 15,6.84205882 14.49375,6.60808824 Z"
              id="bike-Path"
              fill="#2E383B"
            ></path>
            <path
              d="M18.88125,13.1845588 C18.6497686,13.1860638 18.4203077,13.1409215 18.20625,13.0517647 L18.175,13.0517647 C18.025,12.9695588 14.525,11.0598529 13.58125,10.3705882 C13.2515294,10.1446518 13.0261123,9.79342548 12.95625,9.39676471 C12.9267873,8.80835457 13.1264915,8.23170719 13.5125,7.79058824 C13.75,7.45544118 14.3625,6.29191176 14.575,5.89352941 C14.6837867,5.69626847 14.9279485,5.62238124 15.125921,5.72681199 C15.3238935,5.83124275 15.4038133,6.0760834 15.30625,6.27926471 C15.21875,6.43735294 14.4875,7.86014706 14.175,8.30279412 C13.9343932,8.56076766 13.7849541,8.89206739 13.75,9.245 C13.7941501,9.42778961 13.9089904,9.58498974 14.06875,9.68132353 C14.9375,10.3136765 18.275,12.1411765 18.55625,12.2929412 C18.8840735,12.4309264 19.2622665,12.3447685 19.5,12.0779412 C19.5875,11.9704412 19.69375,11.8376471 19.8125,11.6795588 C20.525,10.75 21.51875,9.48529412 22.59375,9.66867647 C23.66875,9.85205882 25.825,10.4085294 25.91875,10.4275 C26.1395965,10.4834702 26.2738666,10.7098863 26.21875,10.9333824 C26.1922284,11.0390399 26.1253087,11.1297104 26.0327126,11.1854473 C25.9401165,11.2411842 25.829429,11.2574219 25.725,11.2305882 C25.725,11.2305882 23.425,10.6551471 22.45,10.4844118 C21.825,10.3769118 20.9375,11.5594118 20.44375,12.1917647 L20.10625,12.6154412 C19.8039518,12.9827631 19.353623,13.1919795 18.88125,13.1845588 L18.88125,13.1845588 Z"
              id="bike-Path"
              fill="#889AA5"
            ></path>
            <g
              id="Group"
              opacity="0.3"
              style={{ mixBlendMode: "screen" }}
              transform="translate(12.500000, 5.691176)"
              fill="#889AA5"
            >
              <path
                d="M6.38125,7.4175 C6.16696072,7.414989 5.95501003,7.37210015 5.75625,7.29102941 L5.75625,7.29102941 C5.60625,7.20882353 2.1125,5.29911765 1.1625,4.60985294 C0.837231052,4.4024934 0.611064616,4.06840566 0.5375,3.68661765 C0.509083201,3.11888491 0.702095176,2.56255628 1.075,2.13735294 C1.31875,1.80220588 1.93125,0.632352941 2.15,0.240294118 C2.19004837,0.160078224 2.26026009,0.0994962182 2.34487177,0.0721495004 C2.42948346,0.0448027825 2.5214054,0.0529827648 2.6,0.0948529412 C2.67928315,0.135372466 2.73916071,0.206410207 2.76618945,0.292017324 C2.79321818,0.377624441 2.78513331,0.470627817 2.74375,0.550147059 C2.7125,0.613382353 1.9375,2.11838235 1.61875,2.56735294 C1.36076742,2.85411015 1.21037043,3.22333738 1.19375,3.61073529 C1.24062129,3.81258389 1.36708744,3.98623573 1.54375,4.09132353 C2.425,4.72367647 5.7875,6.57647059 6.04375,6.71558824 C6.40282655,6.86795683 6.81796739,6.77122384 7.075,6.47529412 L7.39375,6.07691176 C8.10625,5.16632353 9.08125,3.90794118 10.1125,4.09764706 C11.14375,4.28735294 13.3375,4.83117647 13.43125,4.85647059 C13.5671038,4.9280887 13.6366997,5.08461369 13.599546,5.23497708 C13.5623923,5.38534046 13.4281817,5.49031439 13.275,5.48882353 C13.275,5.48882353 10.96875,4.90705882 9.99375,4.73632353 C9.36875,4.6225 8.45625,5.77970588 7.91875,6.47529412 L7.58125,6.89264706 C7.28217199,7.24481084 6.83969887,7.4383386 6.38125,7.4175 Z"
                id="bike-Path"
              ></path>
            </g>
            <g
              id="Group"
              opacity="0.3"
              style={{ mixBlendMode: "screen" }}
              transform="translate(12.500000, 5.691176)"
              fill="#889AA5"
            >
              <path
                d="M6.38125,7.34161765 C6.16711274,7.34379057 5.95467756,7.30295303 5.75625,7.22147059 L5.75625,7.22147059 C5.6125,7.14558824 2.11875,5.23588235 1.175,4.55294118 C0.87208346,4.34998722 0.660868729,4.0339846 0.5875,3.67397059 C0.568186887,3.12588815 0.758464776,2.59137188 1.11875,2.18161765 C1.4125,1.77058824 2.1875,0.284558824 2.2,0.240294118 C2.26654727,0.112230014 2.42216587,0.0616212749 2.55,0.126470588 C2.67478568,0.198451913 2.72379581,0.35547654 2.6625,0.486911765 C2.625,0.550147059 1.85625,2.04882353 1.5375,2.49147059 C1.26617013,2.79037803 1.11271607,3.1796516 1.10625,3.58544118 C1.15184752,3.80961198 1.2877476,4.00440209 1.48125,4.12294118 C2.36875,4.75529412 5.76875,6.65235294 5.99375,6.75352941 C6.38328627,6.91574611 6.83158574,6.81185496 7.1125,6.49426471 C7.20625,6.38676471 7.30625,6.24764706 7.43125,6.09588235 C8.13125,5.19161765 9.09375,3.95852941 10.08125,4.13558824 C11.06875,4.31264706 13.3,4.87544118 13.39375,4.89441176 C13.5335589,4.93168187 13.6191701,5.07418304 13.5875,5.21691176 C13.5507797,5.35798898 13.4084637,5.44268916 13.26875,5.40661765 C13.26875,5.40661765 10.96875,4.83117647 9.9875,4.65411765 C9.30625,4.53397059 8.3875,5.71647059 7.8375,6.41838235 C7.71875,6.57647059 7.60625,6.72191176 7.50625,6.83573529 C7.22686288,7.16876929 6.81279424,7.35496486 6.38125,7.34161765 Z"
                id="bike-Path"
              ></path>
            </g>
            <g
              id="Group"
              opacity="0.3"
              style={{ mixBlendMode: "screen" }}
              transform="translate(12.500000, 5.691176)"
              fill="#889AA5"
            >
              <path
                d="M6.38125,7.26573529 C6.1798192,7.26843541 5.9799452,7.22971138 5.79375,7.15191176 L5.79375,7.15191176 C5.64375,7.06970588 2.15625,5.16632353 1.21875,4.48338235 C0.912911244,4.30279777 0.69712591,3.99944234 0.625,3.64867647 C0.609096691,3.12051515 0.794873318,2.60644976 1.14375,2.21323529 C1.4375,1.79588235 2.21875,0.278235294 2.225,0.265588235 C2.27831587,0.187208152 2.38077114,0.160913052 2.46456666,0.204103554 C2.54836219,0.247294056 2.58757232,0.346607368 2.55625,0.436323529 C2.525,0.499558824 1.75625,1.99823529 1.44375,2.43455882 C1.16032761,2.75000078 1.00033224,3.15919157 0.99375,3.58544118 C1.0459225,3.82857824 1.19225296,4.04040648 1.4,4.17352941 C2.3,4.80588235 5.73125,6.70294118 5.925,6.81044118 C6.3446634,6.98652429 6.8284874,6.87492477 7.13125,6.53220588 C7.225,6.41838235 7.33125,6.28558824 7.45,6.1275 C8.075,5.29279412 9.0875,4.02808824 10.03125,4.19882353 C10.975,4.36955882 13.24375,4.93235294 13.34375,4.95764706 C13.4385815,4.98821038 13.493288,5.08841296 13.4683682,5.18590289 C13.4434485,5.28339283 13.3475865,5.34419717 13.25,5.32441176 C13.25,5.32441176 10.96875,4.74264706 10,4.57191176 C9.26875,4.43911765 8.3375,5.64058824 7.775,6.36147059 C7.65625,6.51323529 7.54375,6.65867647 7.44375,6.7725 C7.18252373,7.09233087 6.79124961,7.27396871 6.38125,7.26573529 Z"
                id="bike-Path"
              ></path>
            </g>
            <g
              id="Group"
              opacity="0.3"
              style={{ mixBlendMode: "screen" }}
              transform="translate(13.125000, 5.691176)"
              fill="#889AA5"
            >
              <path
                d="M5.76875,7.18985294 C5.57429822,7.18914654 5.38158913,7.1527224 5.2,7.08235294 L5.2,7.08235294 C5.04375,7.00014706 1.5625,5.09676471 0.625,4.42647059 C0.35266666,4.25030441 0.163079639,3.96942885 0.1,3.64867647 C0.0864361161,3.14140498 0.265349552,2.64813012 0.6,2.27014706 C0.9,1.85279412 1.68125,0.328823529 1.69375,0.309852941 C1.70766346,0.266218455 1.74609427,0.235289849 1.79124591,0.231389531 C1.83639756,0.227489213 1.87945422,0.251378717 1.90047724,0.291995298 C1.92150025,0.332611879 1.9163849,0.382025194 1.8875,0.417352941 C1.85625,0.480588235 1.09375,1.96661765 0.78125,2.40294118 C0.483956715,2.73331397 0.319055243,3.16394538 0.31875,3.61073529 C0.373639175,3.87289277 0.531579326,4.10117601 0.75625,4.24308824 C1.6625,4.87544118 5.13125,6.81044118 5.29375,6.89264706 C5.7435382,7.08260122 6.26289077,6.96329328 6.5875,6.59544118 L6.9125,6.18441176 C7.5375,5.36867647 8.51875,4.12294118 9.4125,4.28735294 C10.30625,4.45176471 12.7,5.05882353 12.725,5.05882353 C12.7825609,5.07434283 12.818187,5.13256974 12.80625,5.19161765 C12.798894,5.22103073 12.7802362,5.24625524 12.7544193,5.26169013 C12.7286025,5.27712501 12.6977634,5.28149276 12.66875,5.27382353 C12.66875,5.27382353 10.3625,4.69838235 9.38125,4.52132353 C8.60625,4.38220588 7.69375,5.55838235 7.0875,6.33617647 C6.96875,6.49426471 6.85625,6.63970588 6.75625,6.75352941 C6.50912197,7.04242111 6.14615885,7.20279514 5.76875,7.18985294 Z"
                id="bike-Path"
              ></path>
            </g>
            <g
              id="Group"
              opacity="0.3"
              style={{ mixBlendMode: "screen" }}
              transform="translate(13.125000, 5.691176)"
              fill="#889AA5"
            >
              <path
                d="M5.7625,7.11402511 C5.58076836,7.11364525 5.40066084,7.0793438 5.23125,7.01279412 C5.1875,7.01279412 1.6,5.03352941 0.66875,4.35691176 C0.413358514,4.18953943 0.235378779,3.92512754 0.175,3.62338235 C0.163551996,3.13581976 0.338134309,2.66252526 0.6625,2.30176471 C0.9625,1.87808824 1.75,0.347794118 1.75625,0.335147059 C1.76183227,0.322721569 1.77354235,0.314253088 1.78696915,0.31293161 C1.80039595,0.311610133 1.81349963,0.317636423 1.82134415,0.328740436 C1.82918868,0.339844448 1.83058228,0.354339218 1.825,0.366764706 C1.79375,0.43 1.025,1.91602941 0.725,2.34602941 C0.416151891,2.69326946 0.244792239,3.14359581 0.24375,3.61073529 C0.302476463,3.89306934 0.471538774,4.13938319 0.7125,4.29367647 C1.6375,4.96397059 5.225,6.95588235 5.2625,6.95588235 C5.74185783,7.16589512 6.30042581,7.03803554 6.64375,6.63970588 C6.7375,6.53220588 6.85,6.38676471 6.96875,6.235 C7.59375,5.42558824 8.55,4.20514706 9.40625,4.33794118 C10.39375,4.515 12.68125,5.09044118 12.70625,5.09676471 C12.7156384,5.10999084 12.7156384,5.12780328 12.70625,5.14102941 C12.6989845,5.15927977 12.6819195,5.17161247 12.6625,5.17264706 C12.6625,5.17264706 10.375,4.59720588 9.375,4.42647059 C8.5625,4.28102941 7.625,5.4825 7.00625,6.27294118 L6.68125,6.68397059 C6.45429434,6.95953479 6.11701006,7.11739301 5.7625,7.11402511 L5.7625,7.11402511 Z"
                id="bike-Path"
              ></path>
            </g>
            <path
              d="M17.6,13.6145588 C17.125,13.3995588 16.69375,13.33 16.64375,13.4564706 L11.9875,24.4404412 C11.9375,24.5605882 12.275,24.8325 12.75,25.0727941 C13.225,25.3130882 13.65625,25.3573529 13.7125,25.2308824 L18.3625,14.2469118 C18.41875,14.0951471 18.075,13.8232353 17.6,13.6145588 Z"
              id="bike-Path"
              fill="url(#bike-linearGradient-33)"
            ></path>
            <path
              d="M12.75,25.0411765 C12.275,24.8325 11.9375,24.5605882 11.9875,24.4088235 L11.46875,26.445 L8.34375,33.8625 C8.3125,33.9383824 8.51875,34.0964706 8.8,34.2229412 C9.08125,34.3494118 9.3375,34.3936765 9.36875,34.3177941 L12.49375,26.9002941 L13.68125,25.1992647 L13.68125,25.1992647 C13.3620946,25.2531729 13.0342877,25.1975247 12.75,25.0411765 L12.75,25.0411765 Z"
              id="bike-Path"
              fill="url(#bike-linearGradient-34)"
            ></path>
            <path
              d="M13.68125,25.2308824 C13.3585857,25.2752373 13.0305571,25.2084142 12.75,25.0411765 C12.275,24.8325 11.9375,24.5605882 11.9875,24.4088235 L11.46875,26.445 C12.05625,27.0394118 12.49375,26.9002941 12.49375,26.9002941 L13.68125,25.1992647 L13.68125,25.2308824 Z"
              id="bike-Path"
              fill="#000000"
              opacity="0.1"
              style={{ mixBlendMode: "multiply" }}
            ></path>
            <path
              d="M18.3625,14.2152941 C18.3125,14.3417647 17.88125,14.2722059 17.40625,14.0635294 C16.93125,13.8548529 16.5875,13.5829412 16.64375,13.4311765 C16.7,13.2794118 17.125,13.3742647 17.6,13.5892647 C18.075,13.8042647 18.41875,14.0951471 18.3625,14.2152941 Z"
              id="bike-Path"
              fill="#DEE5E9"
            ></path>
            <path
              d="M14.8875,11.2242647 C14.8,11.3823529 15.2125,11.7807353 15.8,12.1158824 C16.3875,12.4510294 16.9375,12.6027941 17.01875,12.4447059 L17.06875,12.3372059 C17.0009721,12.2087768 17.0009721,12.0546056 17.06875,11.9261765 C17.1506226,11.7800673 17.3027685,11.6886679 17.46875,11.6858824 L17.65625,11.4961765 L15.5125,10.2883824 L14.8875,11.2242647 Z"
              id="bike-Path"
              fill="url(#bike-linearGradient-35)"
            ></path>
            <path
              d="M9.79375,33.7802941 L9.49375,33.6032353 C9.45475842,33.5818718 9.40774158,33.5818718 9.36875,33.6032353 C9.2594564,33.6747802 9.18995417,33.794324 9.18125,33.9257353 C9.17344603,33.9754624 9.19553933,34.0251363 9.2375,34.0522059 L9.53125,34.2229412 C9.57148087,34.2480494 9.62226913,34.2480494 9.6625,34.2229412 C9.77107176,34.150699 9.84034275,34.0315529 9.85,33.9004412 C9.85384012,33.8530955 9.83239069,33.8072806 9.79375,33.7802941 L9.79375,33.7802941 Z"
              id="bike-Path"
              fill="url(#bike-linearGradient-36)"
            ></path>
            <path
              d="M9.85,33.9004412 C9.84034275,34.0315529 9.77107176,34.150699 9.6625,34.2229412 C9.62226913,34.2480494 9.57148087,34.2480494 9.53125,34.2229412 C9.49496393,34.1944943 9.47600372,34.148934 9.48125,34.1027941 C9.48654148,33.9719571 9.55407385,33.851796 9.6625,33.7802941 C9.69838047,33.7455265 9.75255319,33.7386879 9.79577716,33.7634697 C9.83900113,33.7882514 9.8610409,33.8387852 9.85,33.8877941 L9.85,33.9004412 Z"
              id="bike-Path"
              fill="#B8BFC3"
            ></path>
            <path
              d="M25.3375,10.0733824 L29.225,11.2179412 C29.225,11.2179412 29.38125,11.3064706 29.2875,11.8060294 C29.2572995,12.0643992 29.0737739,12.2776857 28.825,12.3435294 L25,11.2938235 L25.3375,10.0733824 Z"
              id="bike-Path"
              fill="#2E383B"
            ></path>
            <polygon
              id="bike-Path"
              fill="#2E383B"
              points="22.34375 9.71926471 22.94375 9.45367647 24.65625 9.58647059 24.65625 10.5982353 24.51875 11.3444118 23.8125 11.6795588 22.775 11.5025 22.61875 11.0219118 22.16875 10.9080882"
            ></polygon>
            <polygon
              id="bike-Path"
              fill="#414E52"
              points="23.46875 9.49161765 22.85 10.0227941 22.76875 10.9207353 22.86875 11.2116176 23.78125 11.3823529 24.65625 10.5666176 24.65625 9.58647059"
            ></polygon>
            <path
              d="M28.75,12.8241176 C28.6569891,12.8249915 28.5704209,12.8723357 28.51875,12.9505882 C28.023958,12.6907611 27.4960466,12.5013711 26.95,12.3877941 C26.2020991,12.2952834 25.4832315,12.0384536 24.84375,11.6352941 C24.46875,11.3570588 24.40625,11.2242647 24.21875,11.2116176 C24.03125,11.1989706 23.90625,11.4582353 23.90625,11.4582353 C23.90625,12.3814706 26.68125,12.7545588 27.525,12.8557353 C27.8587047,12.8939721 28.1862982,12.9747094 28.5,13.0960294 L28.5,13.0960294 C28.5,13.2496945 28.6231217,13.3742647 28.775,13.3742647 C28.9268783,13.3742647 29.05,13.2496945 29.05,13.0960294 C29.05,12.9423643 28.9268783,12.8177941 28.775,12.8177941 L28.75,12.8241176 Z"
              id="bike-Path"
              fill="#AEBFC8"
            ></path>
            <polygon
              id="bike-Path"
              fill="#414E52"
              points="14.11875 4.91970588 15.40625 4.79955882 15.26875 3.90161765 13.95625 4.02808824 13.2875 4.98926471 13.48125 5.85558824 13.61875 5.43191176"
            ></polygon>
            <path
              d="M16.25,5.54573529 C16.25,5.23588235 15.89375,5.01455882 15.625,4.91338235 C15.31875,5.00823529 15.1125,5.17264706 15.1125,5.35602941 C15.1125,5.63426471 15.58125,5.86191176 16.16875,5.86823529 C16.2188927,5.76806906 16.2466321,5.6579648 16.25,5.54573529 Z"
              id="bike-Path"
              fill="#414E52"
            ></path>
            <path
              d="M17.63125,11.4961765 C17.55,11.6479412 17.00625,11.4961765 16.4125,11.1610294 C15.81875,10.8258824 15.4125,10.4275 15.5,10.2757353 C15.5875,10.1239706 16.125,10.2757353 16.75,10.6045588 C17.375,10.9333824 17.71875,11.3444118 17.63125,11.4961765 Z"
              id="bike-Path"
              fill="#DEE5E9"
            ></path>
            <path
              d="M17.2875,11.3001471 C17.23125,11.4013235 16.8625,11.3001471 16.4625,11.0725 C16.0625,10.8448529 15.7875,10.5792647 15.8375,10.4401471 C15.8875,10.3010294 16.26875,10.4401471 16.6625,10.6677941 C17.05625,10.8954412 17.34375,11.1926471 17.2875,11.3001471 Z"
              id="bike-Path"
              fill="#414E52"
            ></path>
            <path
              d="M13.425,12.0147059 C13.6657943,12.0892889 13.9170364,12.1234667 14.16875,12.1158824 L16.775,13.545 L16.28125,14.4682353 L13.15625,12.7482353 L13.425,12.0147059 Z"
              id="bike-Path"
              fill="#C5CCD0"
            ></path>
            <polygon
              id="bike-Path"
              fill="#C5CCD0"
              points="16.15625 20.8297059 9.40625 16.8711765 9.40625 15.5305882 16.15625 19.4891176"
            ></polygon>
            <path
              d="M10.625,15.3788235 C10.41875,14.8097059 9.6625,14.6389706 8.96875,15.0310294 L9.94375,16.7826471 C9.94375,16.7826471 10.96875,16.3020588 10.625,15.3788235 Z"
              id="bike-Path"
              fill="url(#bike-linearGradient-37)"
            ></path>
            <path
              d="M10.19375,16.3083824 C10.19375,16.7636765 9.875,16.9407353 9.4875,16.7257353 C9.06563899,16.4436114 8.80481061,15.9723668 8.7875,15.4610294 C8.7875,15.0120588 9.1,14.8286765 9.4875,15.05 C9.90921071,15.3301613 10.1720177,15.7984256 10.19375,16.3083824 L10.19375,16.3083824 Z"
              id="bike-Path"
              fill="url(#bike-linearGradient-38)"
            ></path>
            <path
              d="M10,16.2767647 C10,16.6498529 9.74375,16.8016176 9.425,16.6119118 C9.07940677,16.3910912 8.86235065,16.0132681 8.84375,15.6001471 C8.84375,15.2270588 9.1,15.0752941 9.425,15.265 C9.76820689,15.4872679 9.9827887,15.8648441 10,16.2767647 Z"
              id="bike-Path"
              fill="#F09041"
            ></path>
            <path
              d="M17.3625,19.2551471 C17.1625,18.6860294 16.4,18.5152941 15.7125,18.9073529 L16.68125,20.6589706 C16.68125,20.6589706 17.6875,20.1783824 17.3625,19.2551471 Z"
              id="bike-Path"
              fill="url(#bike-linearGradient-39)"
            ></path>
            <path
              d="M16.9125,20.1847059 C16.9125,20.64 16.6,20.8170588 16.20625,20.5957353 C15.7907929,20.322238 15.5303989,19.8635398 15.50625,19.3626471 C15.50625,18.9136765 15.81875,18.7302941 16.20625,18.9516176 C16.6233258,19.2244313 16.8859333,19.682935 16.9125,20.1847059 L16.9125,20.1847059 Z"
              id="bike-Path"
              fill="url(#bike-linearGradient-40)"
            ></path>
            <path
              d="M16.70625,20.1530882 C16.70625,20.5261765 16.45,20.6779412 16.13125,20.4882353 C15.7856568,20.2674148 15.5686006,19.8895916 15.55,19.4764706 C15.55,19.1033824 15.8125,18.9516176 16.13125,19.1413235 C16.4744569,19.3635914 16.6890387,19.7411676 16.70625,20.1530882 Z"
              id="bike-Path"
              fill="#F09041"
            ></path>
            <path
              d="M15.31875,14.1077941 C14.7375,12.4636765 12.5375,11.9830882 10.5375,13.1023529 L13.35,18.1611765 C13.35,18.1611765 16.25,16.7826471 15.31875,14.1077941 Z"
              id="bike-Path"
              fill="url(#bike-linearGradient-41)"
            ></path>
            <path
              d="M14.01875,16.8079412 C14.01875,18.1232353 13.10625,18.6544118 11.98125,17.9967647 C10.7821022,17.2024314 10.0279578,15.8783041 9.95,14.4302941 C9.95,13.1213235 10.8625,12.5901471 11.98125,13.2414706 C13.1827785,14.0343995 13.9393765,15.3587599 14.01875,16.8079412 Z"
              id="bike-Path"
              fill="url(#bike-linearGradient-42)"
            ></path>
            <path
              d="M13.425,16.7067647 C13.425,17.7880882 12.68125,18.2244118 11.75625,17.6869118 C10.7699446,17.0364681 10.1499048,15.9486187 10.0875,14.7591176 C10.0875,13.6777941 10.83125,13.2414706 11.75625,13.7789706 C12.7417999,14.4301377 13.3615978,15.5175629 13.425,16.7067647 Z"
              id="bike-Path"
              fill="#E3E3E4"
            ></path>
            <path
              d="M13.34375,16.3969118 C13.34375,17.1241176 12.8375,17.4213235 12.2125,17.0608824 C11.5419583,16.6184565 11.1213778,15.8778959 11.08125,15.0689706 C11.08125,14.3417647 11.5875,14.0445588 12.2125,14.4366176 C12.8744775,14.8725547 13.2939055,15.5993632 13.34375,16.3969118 Z"
              id="bike-Path"
              fill="#FFFFFF"
            ></path>
            <path
              d="M13.425,16.7067647 C13.425,17.7880882 12.68125,18.2244118 11.75625,17.6869118 C10.7699446,17.0364681 10.1499048,15.9486187 10.0875,14.7591176 C10.0875,13.6777941 10.83125,13.2414706 11.75625,13.7789706 C12.7417999,14.4301377 13.3615978,15.5175629 13.425,16.7067647 Z"
              id="bike-Path"
              fill="url(#bike-linearGradient-43)"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

IllustrationBike.propTypes = {
  /**
   * Size of the illustration.
   */
  size: PropTypes.number,
};

IllustrationBike.defaultProps = {
  size: 70,
};

export { IllustrationBike as default } from "./Bike";
