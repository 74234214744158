import _ from "lodash";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../app/hooks";
import { store } from "../../app/store";
import { GTFleetErrorCodes } from "../../config/GTfleetErrorCodes";
import { Button } from "../../ui/Button/Button";
import { ChatMessage } from "../../ui/Chat/ChatMessage";
import { MessageDivider } from "../../ui/Chat/MessageDivider";
import {
  DropdownList,
  TextFieldDropdown,
} from "../../ui/Chat/TextFieldDropdown";
import { IconMessages } from "../../ui/Icon/Line/Messages";
import { IconTitle } from "../../ui/IconTitle/IconTitle";
import { ConvertTimeZone, getFullDate } from "../../utils/DateAndTimeUtils";
import { ToastNotification } from "../../utils/ToastNotification";
import { getQueryString } from "../../utils/Utils";
import { Preferences } from "../users/preference/preferencesSlice";
import UserContext from "../users/userContext";
import "./Chat.css";
import { ChatSelected } from "./DashboardMessages";
import {
  MessageRecipients,
  Recipient,
  selectChatsSliceReasonCode,
  selectChatsSliceStatus,
  sendMessageAsync,
} from "./chatSlice";
import {
  MessageStatus,
  MessageStatusEnum,
  getMessageStatusRequestAsync,
  selectMessagesSliceReasonCode,
  selectMessagesSliceStatus,
} from "./messagesSlice";

export interface ChatMessageType {
  id: number;
  messageText: string;
  position: "left" | "right";
  status: MessageStatus;
  time: string;
  userPic: string;
}

interface ChatProps {
  selectedChat?: ChatSelected;
  userlistAvailable: DropdownList[];
  messages?: ChatMessageType[];
  openDropdown?: boolean;
  sender?: number;
  messagesPage: number;
  setOpenDropdown?: (e: any) => any;
  getMessagesPage?: (e: any) => any;
}

let fetchMessages: (queryParams: string) => any;
let messageRequestPolling: any[] = [];

export const Chat: React.FC<ChatProps> = ({
  selectedChat,
  userlistAvailable,
  messages,
  sender,
  openDropdown,
  messagesPage,
  setOpenDropdown,
  getMessagesPage,
}) => {
  const messagesSliceStatus = useAppSelector(selectMessagesSliceStatus);
  const messagesSliceReasonCode = useAppSelector(selectMessagesSliceReasonCode);
  const chatsSliceStatus = useAppSelector(selectChatsSliceStatus);
  const chatsSliceReasonCode = useAppSelector(selectChatsSliceReasonCode);
  const [preferencesContext]: [Preferences] = useContext(UserContext);
  const [message, setMessage] = useState<string>("");
  const [offsetHeight, setOffsetHeight] = useState(0);
  const [zoomLevel, setZoomLevel] = useState(1);
  const [recipients, setRecipients] = useState<Recipient[]>([]);
  const [currentRecipient, setCurrentRecipient] = useState<Recipient>(
    {} as Recipient
  );
  const { t } = useTranslation();
  const [isScrollingUp, setIsScrollingUp] = useState(false);
  const chatRef = useRef<any>(null);
  const messageschatRef = useRef<any>(null);

  const [currentSelectedChat, setCurrentSelectedChat] = useState<number>(-1);

  /**
   * Given a selected chat persists the current chat id and refresh polling state.
   */
  useEffect(() => {
    if (selectedChat && selectedChat?.selectedChatId) {
      if (selectedChat.selectedChatId !== currentSelectedChat) {
        setCurrentSelectedChat(selectedChat.selectedChatId);
        if (messageRequestPolling && messageRequestPolling.length) {
          messageRequestPolling.forEach((x: any) => clearTimeout(x));
          messageRequestPolling = [];
        }
      }
    }
  }, [selectedChat]);

  /**
   * Given a selected chat and the available users set the single current recipient.
   */
  useEffect(() => {
    if (!openDropdown) {
      if (
        selectedChat &&
        selectedChat?.selectedRecipientId &&
        selectedChat?.selectedType &&
        userlistAvailable.length > 0
      ) {
        const currentAvailableRecipient = userlistAvailable
          .filter(
            (el) =>
              el.id === selectedChat?.selectedRecipientId &&
              el.type === selectedChat?.selectedType
          )
          .map((user: any) => {
            return {
              recipient: user.id,
              type: user.type,
              phone: user.phone,
            };
          });
        if (currentAvailableRecipient.length > 0) {
          if (
            !(
              currentAvailableRecipient[0].recipient ===
                currentRecipient.recipient &&
              currentRecipient.type === currentAvailableRecipient[0].type
            )
          ) {
            setCurrentRecipient(currentAvailableRecipient[0]);
          }
        }
      }
    } else {
      setCurrentRecipient({} as Recipient);
    }
  }, [selectedChat, userlistAvailable, openDropdown]);

  /**
   * Initial useEffect with clean up logic.
   */
  useEffect(() => {
    const handleWheel = (e: any) => {
      setIsScrollingUp(e.deltaY < 0);
    };
    chatRef.current?.addEventListener("wheel", handleWheel);
    return function cleanUp() {
      if (messageRequestPolling && messageRequestPolling.length) {
        messageRequestPolling.forEach((x: any) => clearTimeout(x));
        messageRequestPolling = [];
      }
      chatRef.current?.removeEventListener("wheel", handleWheel);
    };
  }, []);

  useEffect(() => {
    if (
      messagesSliceStatus === "failed" &&
      messagesSliceReasonCode === GTFleetErrorCodes.INTERNAL_SERVER_ERROR
    ) {
      ToastNotification({
        toastId: "serverError",
        status: "error",
        description: t("common.serverError"),
      });
    }
    if (messagesSliceStatus === "failed" && messagesSliceReasonCode === "") {
      ToastNotification({
        toastId: "networkError",
        status: "error",
        description: t("common.networkError"),
      });
    }
  }, [messagesSliceStatus, messagesSliceReasonCode]);

  useEffect(() => {
    if (
      chatsSliceStatus === "failed" &&
      chatsSliceReasonCode === GTFleetErrorCodes.INTERNAL_SERVER_ERROR
    ) {
      ToastNotification({
        toastId: "serverError",
        status: "error",
        description: t("common.serverError"),
      });
    }
    if (chatsSliceStatus === "failed" && chatsSliceReasonCode === "") {
      ToastNotification({
        toastId: "networkError",
        status: "error",
        description: t("common.networkError"),
      });
    }
    if (
      chatsSliceStatus === "failed" &&
      chatsSliceReasonCode === GTFleetErrorCodes.CHAT_INSUFFICIENT_SMS
    ) {
      ToastNotification({
        toastId: "networkError",
        status: "error",
        description: t("messages.error.insufficientSMS"),
      });
    }
  }, [chatsSliceStatus, chatsSliceReasonCode]);

  /**
   * This function is in charge if sending written message
   */
  const sendMessage = () => {
    let smsRecipients: Recipient[] = [];

    if (recipients.length > 0) {
      smsRecipients = recipients.map((user: any) => {
        return {
          recipient: user.id,
          type: user.type,
          phone: user.phone,
        };
      });
    } else {
      smsRecipients = [currentRecipient];
    }

    let bodyMessageRequest: MessageRecipients = {
      smsRecipients: smsRecipients,
      message: message ?? "",
    };
    if (sender) {
      store.dispatch(
        sendMessageAsync({
          messageRecipients: bodyMessageRequest,
          userId: sender,
        })
      );
      setMessage("");
      setRecipients([]);
    } else {
      ToastNotification({
        toastId: "clientError",
        status: "error",
        description: t("common.clientError"),
      });
    }
    setOpenDropdown && setOpenDropdown(false);
  };

  /**
   * This function dispacth a call to get all status message from id
   * and set timer for polling
   */
  fetchMessages = (queryParams: string) => {
    store.dispatch(getMessageStatusRequestAsync(queryParams));
    messageRequestPolling.push(
      setTimeout(() => fetchMessages(queryParams), 60000)
    );
  };

  // #region scrollBar management
  useEffect(() => {
    const handleScroll = async () => {
      if (isScrollingUp && getMessagesPage && chatRef.current.scrollTop === 0) {
        getMessagesPage((prevPage: any) => prevPage + 1);
      }
    };
    chatRef.current?.addEventListener("scroll", handleScroll);
    return () => {
      chatRef.current?.removeEventListener("scroll", handleScroll);
    };
  }, [isScrollingUp, messages]);

  function scrollToBottom() {
    if (messageschatRef.current) {
      messageschatRef.current.scrollIntoView({
        block: "end",
        inline: "nearest",
      });
    }
  }
  useEffect(() => {
    !isScrollingUp && scrollToBottom();
  });

  // this useEffect allows to re-calculate all "position absolute" of the element positions
  useEffect(() => {
    function handleResize() {
      const newZoomLevel = window.devicePixelRatio;
      if (newZoomLevel !== zoomLevel) {
        setZoomLevel(newZoomLevel);
        scrollToBottom();
      }
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [zoomLevel]);
  // #endregion scrollBar management

  /**
   * This useEffect starts a polling on commands requests if a device
   * id exists, and there is at least one pending request and there is not
   * already a polling.
   */
  useEffect(() => {
    const somethingIsPending =
      messages &&
      messages
        .filter((x) => x.status === MessageStatusEnum.DISPATCHED)
        .map((x) => x.id);
    if (
      somethingIsPending?.length !== 0 &&
      messageRequestPolling.length === 0
    ) {
      let queryParams = getQueryString({ id: somethingIsPending });
      fetchMessages(queryParams);
    } else if (!somethingIsPending && messageRequestPolling.length > 0) {
      messageRequestPolling.forEach((x) => clearTimeout(x));
      messageRequestPolling = [];
    }
  }, [messages]);

  // Send message on submit press
  useEffect(() => {
    // Send the message in the textarea to the sendMessage props function.
    const listener = (event: any) => {
      if (
        (event.code === "Enter" || event.code === "NumpadEnter") &&
        event.target.type === "textarea"
      ) {
        event.preventDefault();
        // if a message is typed and exists at least one recipient
        if (
          !!message &&
          message !== "" &&
          (recipients.length > 0 || !_.isEmpty(currentRecipient))
        ) {
          event.target.value = ""; // Reset the field when message is sent.
          sendMessage();
        }
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      // Cleanup the event listener called during mount.
      document.removeEventListener("keydown", listener);
    };
  });

  return (
    <div className="mn-chat mn-chat--open">
      <div className="mn-chat__body">
        <div className="mn-chat__top-bar">
          <span className="mn-chat__top-bar-to">
            {t("messages.chat.topBarTitle") + ": "}
          </span>
          {openDropdown && (
            <div className="mn-chat__dropdown">
              <TextFieldDropdown
                hasDropdown={false}
                hasUserBox={true}
                values={userlistAvailable}
                getValues={setRecipients}
                openDropdown={openDropdown}
                setOpenDropdown={setOpenDropdown}
                getOffsetHeight={setOffsetHeight}
              />
            </div>
          )}
          {!openDropdown &&
            !_.isEmpty(selectedChat) &&
            selectedChat?.selectedUsername !== "" && (
              <span>{selectedChat?.selectedUsername}</span>
            )}
        </div>
        {messagesSliceStatus === "loading" && messagesPage !== 0 && (
          <div className="messages-loader-container">
            <div className="retrive-messages-loader" />
          </div>
        )}
        <div
          ref={chatRef}
          className="mn-chat__chat"
          style={{
            height: `calc(100% - (${offsetHeight + 182}px))`,
          }}
        >
          {messages && messages.length > 0 && (
            <div className="mn-chat_messages" ref={messageschatRef}>
              {messages.map((msg, index) => {
                let sameDay = false;
                if (index > 0) {
                  sameDay =
                    JSON.stringify(getFullDate(messages[index - 1].time)) ===
                    JSON.stringify(getFullDate(messages[index].time));
                }
                let messageStatus;
                if (messages.length === index + 1) {
                  messageStatus =
                    chatsSliceStatus === "loading" ? "PROCESSING" : msg.status;
                } else {
                  messageStatus = msg.status;
                }
                return (
                  <React.Fragment key={msg.id}>
                    {!sameDay && (
                      <MessageDivider
                        hasData={true}
                        date={getFullDate(msg.time, true, false)}
                      />
                    )}
                    <ChatMessage
                      userPic={msg.userPic}
                      id={msg.id}
                      messageText={msg.messageText}
                      position={msg.position}
                      status={messageStatus}
                      time={ConvertTimeZone(
                        Number(msg.time),
                        preferencesContext.timeZone,
                        preferencesContext.localeFormat,
                        true
                      )}
                    />
                  </React.Fragment>
                );
              })}
            </div>
          )}
          {messages?.length === 0 && messagesSliceStatus === "idle" && (
            <div className="mn-chat-icon">
              <IconTitle
                icon={<IconMessages color="currentColor" size={48} />}
                text={t("messages.chat.chatIconText")}
                title={t("messages.chat.chatIconTitle")}
              />
            </div>
          )}
        </div>
        <div className="mn-chat__textarea">
          <textarea
            maxLength={255}
            id="textarea_message"
            placeholder={t("messages.chat.textareaPlaceholder")}
            onChange={(event) => {
              setMessage(event.target.value);
            }}
            value={message}
          />
          <Button
            size="regular"
            id="button_send"
            aspect="primary"
            label={t("messages.chat.sendButton")}
            isLoading={chatsSliceStatus === "loading"}
            onClick={() => {
              sendMessage();
            }}
            disabled={
              !(
                !!message &&
                message !== "" &&
                (recipients.length > 0 || !_.isEmpty(currentRecipient))
              )
            }
          />
        </div>
      </div>
    </div>
  );
};
