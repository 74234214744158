import { t } from "i18next";
import { FeedbackModal } from "../../../ui/Modal/CustomModals/FeedbackModal";
import { GeofenceCategory } from "../../geofenceCategory/geofenceCategoriesSlice";

interface DeleteGeofenceCategoryModalProps {
  geofenceCategory: GeofenceCategory;
  open: boolean;
  onClose: (id?: number) => void;
}

const DeleteGeofenceCategoryModal: React.FC<DeleteGeofenceCategoryModalProps> =
  ({ geofenceCategory, open, onClose }) => {
    const nameGeofenceCategory = geofenceCategory.name;

    function deleteGeofenceCategory() {
      onClose(geofenceCategory.id);
    }
    function close() {
      onClose();
    }

    return (
      <div className="delete-geofence-modal">
        <FeedbackModal
          open={open}
          title={`${t("admin.geofences.deleteGeofenceCategory.title")}`}
          desc={
            <>
              <span>
                {`${t("admin.geofences.deleteGeofenceCategory.desc").replace(
                  "*name",
                  nameGeofenceCategory
                )}`}
              </span>
              <br />
              <span>
                {t("admin.geofences.deleteGeofenceCategory.actionPermanent")}
              </span>
            </>
          }
          primaryLabel={t("common.confirm")}
          isDanger={true}
          primaryAction={deleteGeofenceCategory}
          secondaryLabel={t("common.cancel")}
          secondaryAction={close}
          closeAction={close}
        />
      </div>
    );
  };

export default DeleteGeofenceCategoryModal;
