import HttpClient from "../../../utils/HttpClient";
import { Preferences } from "./preferencesSlice";

export default class PreferencesRepository extends HttpClient {
  constructor() {
    super(true);
  }

  getPreferences() {
    const preferencesId = localStorage.getItem("preferencesId");
    return this.instance.get("preferences/" + preferencesId);
  }

  partiallyUpdatePreferences(preferencesId: number, preferences: Preferences) {
    return this.instance.patch("preferences/" + preferencesId, preferences);
  }
}
