import { schema } from "normalizr";
import { tenantSchema } from "../tenants/tenantNormalization";
import { geofenceCategorySchema } from "../geofenceCategory/geofenceCategoryNormalization";

export const geofenceSchema: schema.Entity<any> = new schema.Entity(
  "geofence",
  {
    tenant: tenantSchema,
    geofenceCategory: geofenceCategorySchema,
  },
  { idAttribute: "id" }
);

export const geofencesSchema = new schema.Array(geofenceSchema);
