import React from "react";

import { IconForward } from "../Icon/Line/Forward";
import "./SingleItem.css";

export const SingleItemContainer = ({ children, label, onClick, element, ...props }) => {
  return (
    <div className="mn-single-item-container" {...props}>
      {children}
      <span className="mn-widget-help__item-forward" onClick={() => {
        onClick(element)
      }}>
        {label ? <span>{label}</span> : null}
        <IconForward size={14} />
      </span>
    </div>
  );
};
