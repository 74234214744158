import HttpClient from "../../utils/HttpClient";

export default class VehiclesRepository extends HttpClient {
  constructor() {
    super(true);
  }

  partiallyUpdateVehicle(
    fleetId: number,
    id: number,
    vehicle: any,
    removeDriver: boolean
  ) {
    const removeDriverParam = removeDriver ? "true" : "false";
    const tenantId = localStorage.getItem("tenantId");
    const path =
      "/tenants/" +
      tenantId +
      "/fleets/" +
      fleetId +
      "/vehicles/" +
      id +
      "/details" +
      "?removeDriver=" +
      removeDriverParam;
    return this.instance.patch(path, vehicle);
  }

  getFilteredVehiclesDetails(queryParams?: string) {
    const tenantId = localStorage.getItem("tenantId");
    let path = "/tenants/" + tenantId + "/fleets/vehicles/details";
    return this.instance.get(path + (queryParams ?? ""));
  }

  getVehiclesDetails() {
    const tenantId = localStorage.getItem("tenantId");
    return this.instance.get(
      "/tenants/" + tenantId + "/fleets/vehicles/details"
    );
  }

  getVehicleDetails(fleetId: number, id: number) {
    const tenantId = localStorage.getItem("tenantId");

    return this.instance.get(
      "/tenants/" +
        tenantId +
        "/fleets/" +
        fleetId +
        "/vehicles/" +
        id +
        "/details"
    );
  }

  getVehiclesDetailsViews(queryParams?: string) {
    const tenantId = localStorage.getItem("tenantId");
    let path = "/tenants/" + tenantId + "/fleets/vehicles/details/view";
    return this.instance.get(path + (queryParams ?? ""));
  }

  getVehicleMaintenanceCosts(queryParams: string, vehicleId: number) {
    const tenantId = localStorage.getItem("tenantId");

    const url =
      "/tenants/" +
      tenantId +
      "/vehicles/" +
      vehicleId +
      "/deadlines/costs" +
      (queryParams ?? "");
    return this.instance.get(url);
  }
}
