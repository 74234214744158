import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import _ from "lodash";
import { normalize } from "normalizr";
import { RootState } from "../../app/store";
import { Config } from "../../config/Config";
import { GTFleetSuccessCodes } from "../../config/GTFleetSuccessCodes";
import { getErrorCodes } from "../../utils/Utils";
import { vehicleFuelsConsumptionSchema } from "../vehicle/vehicleNormalization";
import VehiclesFuelsRepository from "../vehicle/vehiclesFuelsRepository";

//#region Type
export interface VehiclesFuelsConsumption {
  tenantId: number;
  summary: {
    avgConsumption: number;
    totalConsumption: number;
    zeroConsumption: number;
    refillsFrequences: number;
  };
  fuelsConsumption: { data: number; date: string }[];
  fuelsLevel: { data: number; date: string }[];
}
//#endregion Type

//#region API
export const getVehiclesFuelsConsumptionAsync = createAsyncThunk(
  "vehicles/getVehiclesFuelsConsumption",
  async (data: { queryParams: string; isActive?: boolean }) => {
    const vehiclesFuelsRepository = new VehiclesFuelsRepository();
    fleetFuelsConsumptionEmptyState();
    if (data.isActive != undefined) {
      data.queryParams =
        (_.isEmpty(data.queryParams)
          ? "?isActive="
          : data.queryParams + "&isActive=") + data.isActive;
    }
    const response = await vehiclesFuelsRepository.getVehiclesFuelsConsumption(
      data.queryParams
    );
    // The value we return becomes the `fulfilled` action payload
    const fuels = _.get(response, Config.VEHICLES_FUELS_RESPONSE_PATH);
    const normalizedData = normalize(fuels, vehicleFuelsConsumptionSchema);
    return normalizedData.entities;
  }
);
//#region API

//#region Slice
const fleetFuelsConsumptionAdapter =
  createEntityAdapter<VehiclesFuelsConsumption>({
    selectId: (vehiclesFuelsConsumption) => vehiclesFuelsConsumption.tenantId,
  });

export const fleetFuelsConsumptionSlice = createSlice({
  name: "vehiclesFuelsConsumption",
  initialState: fleetFuelsConsumptionAdapter.getInitialState({
    status: "idle",
    reasonCode: "",
  }),
  reducers: {
    fleetFuelsConsumptionEmptyState: (state: any) => {
      fleetFuelsConsumptionAdapter.setAll(state, []);
    },
  },
  extraReducers: (builder) => {
    builder
      //#region Entity Reducers
      .addCase(
        getVehiclesFuelsConsumptionAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          fleetFuelsConsumptionAdapter.setAll(
            state,
            action.payload.vehicleFuelsConsumption ?? []
          );
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.GET;
        }
      )
      .addCase(
        getVehiclesFuelsConsumptionAsync.rejected,
        (state: any, action: PayloadAction<any>) => {
          state.status = "failed";
          state.reasonCode = getErrorCodes(action.payload);
        }
      )
      .addCase(getVehiclesFuelsConsumptionAsync.pending, (state: any) => {
        state.status = "loading";
      });
    //#endregion Entity Reducers
  },
});
//#endregion Slice

//#region Status
export const fleetFuelsConsumptionSelectors =
  fleetFuelsConsumptionAdapter.getSelectors<RootState>(
    (state) => state.fleetFuelsConsumption
  );

export const selectFleetFuelConsumptionsSliceStatus = (state: any) =>
  state.fleetFuelsConsumption.status;
export const selectFleetFuelConsumptionsSliceReasonCode = (state: any) =>
  state.fleetFuelsConsumption.reasonCode;
//#endregion Status

export const { fleetFuelsConsumptionEmptyState } =
  fleetFuelsConsumptionSlice.actions;

export default fleetFuelsConsumptionSlice.reducer;
