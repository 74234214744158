import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import imageFile from "../../assets/login-background.jpg";
import { GTFleetErrorCodes } from "../../config/GTfleetErrorCodes";
import ContainerWrapper from "../../layout/ContainerWrapper";
import { Alert } from "../../ui/Alert/Alert";
import { Button } from "../../ui/Button/Button";
import Form from "../../ui/Forms/Form";
import TextField from "../../ui/Forms/TextField";
import { IconBuild } from "../../ui/Icon/Line/Build";
import { IconEye } from "../../ui/Icon/Line/Eye";
import { IconEyeSlash } from "../../ui/Icon/Line/EyeSlash";
import { IconHelp } from "../../ui/Icon/Line/Help";
import { IconVehicle } from "../../ui/Icon/Line/Vehicle";
import { IconUser } from "../../ui/Icon/Solid/User";
import { LoginSidebar } from "../../ui/Login/LoginSidebar";
import { WidgetHelp } from "../../ui/Login/WidgetHelp";
import { WidgetHelpItem } from "../../ui/Login/WidgetHelpItem";
import { Logo } from "../../ui/Logo/Logo";
import { DemoRequestModal } from "../../ui/Modal/CustomModals/DemoRequestModal";
import { HelpRequestModal } from "../../ui/Modal/CustomModals/HelpRequestModal";
import { InstallerRequestModal } from "../../ui/Modal/CustomModals/InstallerRequestModal";
import { ResetPasswordModal } from "../../ui/Modal/CustomModals/ResetPasswordModal";
import { ResetPasswordSupportModal } from "../../ui/Modal/CustomModals/ResetPasswordSupportModal";
import { VerifyCodeModal } from "../../ui/Modal/CustomModals/VerifyCodeModal";
import AuthenticationService from "../../utils/AuthenticationService";
import "./LoginPage.css";

interface LoginPageProps {
  loginDone: () => void;
}
function LoginPage(Props: LoginPageProps) {
  const { t } = useTranslation();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isAlert, setIsAlert] = useState(false);
  const [loginErrorMessage, setLoginErrorMessage] = useState<string>(
    t("pages.login.alert")
  );
  const [isLoading, setIsLoading] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState<boolean>(true);
  const [showResetPsw, setShowResetPsw] = useState(false);
  const openResetPassword = () => setShowResetPsw(true);
  const [showVerifyCode, setShowVerifyCode] = useState(false);
  const [showResetPswSupport, setShowResetPswSupport] = useState(false);
  const [getEmail, setGetEmail] = useState("");

  const [demoModal, setDemoModal] = useState(false);
  const [helpModal, setHelpModal] = useState(false);
  const [installerModal, setInstallerModal] = useState(false);
  const [key, setKey] = useState("");

  const authenticationService = new AuthenticationService();

  const handleSubmit = (event: any) => {
    event.preventDefault();
    setIsLoading(true);

    authenticationService.login(
      username,
      password,
      (result: any) => {
        setIsLoading(false);
        Props.loginDone();
      },
      (error: any) => {
        console.log("LOGIN ERROR: " + error);
        if (error.response === undefined || error.response.status === 500) {
          setLoginErrorMessage(t("common.networkError"));
        } else if (error.response.status === 409) {
          setLoginErrorMessage(t("pages.login.alertExpiredDisabled"));
        } else {
          setLoginErrorMessage(t("pages.login.alert"));
        }
        setIsAlert(true);
        setIsLoading(false);
      }
    );
  };

  const loginFromHS = (key: any) => {
    setIsLoading(true);

    authenticationService.loginFromHS(
      key,
      (result: any) => {
        setIsLoading(false);
        Props.loginDone();
      },
      (error: any) => {
        console.log("LOGIN ERROR: " + error);
        if (error.response === undefined || error.response.status === 500) {
          setLoginErrorMessage(t("common.networkError"));
        } else if (error.response.status === 409) {
          setLoginErrorMessage(t("pages.login.alertExpiredDisabled"));
        } else if (
          error.response.status === 401 &&
          error.response.data.message == GTFleetErrorCodes.EXPIRED_TOKEN
        ) {
          setLoginErrorMessage(t("pages.login.hubspot"));
        } else {
          setLoginErrorMessage(t("common.unauthorizedError"));
        }

        setIsAlert(true);
        setIsLoading(false);
        setKey("");
      }
    );
  };

  /* Generate code request */
  const handleGenerateCode = async (eMail: string) => {
    try {
      await authenticationService.generateCode(eMail);
    } catch (error) {
      throw error;
    }
  };

  /* Validate code request */
  const handleValidateCode = (generatedCode: string) => {
    return authenticationService.validateCode(generatedCode);
  };

  /* Reset password request */
  const handleResetPassword = (newPassword: string, generatedCode: string) => {
    return authenticationService.resetPassword(newPassword, generatedCode);
  };

  const submitRef = useRef<any>();
  useEffect(() => {
    const location = window.location;

    let queryParams = new URLSearchParams(location.search);
    let keyParam = queryParams.get("key");

    if (keyParam !== null && keyParam !== "") {
      setKey(keyParam);
    } else {
      const keyUp = (event: any) => {
        if (event.keyCode === 13 && password !== "" && username !== "") {
          handleSubmit(event);
        }
      };

      if (
        submitRef &&
        submitRef.current &&
        !showResetPsw &&
        !showVerifyCode &&
        !showResetPswSupport
      ) {
        submitRef.current.addEventListener("keydown", keyUp);
        let parentRef = submitRef;
        return () => {
          parentRef.current &&
            parentRef.current.removeEventListener("keydown", keyUp);
        };
      }
    }
  });

  useEffect(() => {
    if (key !== null && key !== "") {
      loginFromHS(key);
    } else {
      const keyUp = (event: any) => {
        if (event.keyCode === 13 && password !== "" && username !== "") {
          handleSubmit(event);
        }
      };

      if (
        submitRef &&
        submitRef.current &&
        !showResetPsw &&
        !showVerifyCode &&
        !showResetPswSupport
      ) {
        submitRef.current.addEventListener("keydown", keyUp);
        let parentRef = submitRef;
        return () => {
          parentRef.current &&
            parentRef.current.removeEventListener("keydown", keyUp);
        };
      }
    }
  }, [key]);

  return (
    <ContainerWrapper>
      <div className="container-fluid" ref={submitRef}>
        <div className="login-container row">
          <div className="login-first-column col-xxl-7">
            <div className="form-request-demo">
              <div className="gt-alarm-logo">
                <div
                  style={{
                    width: "190px",
                    textAlign: "center",
                  }}
                >
                  <Logo type="fleet" mode="colors" />
                </div>
              </div>
              <div className="login-form">
                <div
                  style={{
                    width: "280px",
                    height: "220px",
                  }}
                >
                  <Form>
                    <TextField
                      id="username-field"
                      validate="alphaNumeric"
                      placeholder={t("pages.login.usernamePlaceholder")}
                      type="text"
                      value={username}
                      events={{
                        onChange: (data) => setUsername(data.value),
                      }}
                    >
                      <IconUser size={14} />
                    </TextField>

                    <div className="password-field">
                      <TextField
                        id="password-field"
                        validate="alphaNumeric"
                        placeholder={t("pages.login.passwordPlaceholder")}
                        type={showOldPassword ? "password" : "text"}
                        value={password}
                        events={{
                          onChange: (data) => setPassword(data.value),
                        }}
                      >
                        {showOldPassword ? (
                          <IconEyeSlash
                            onClick={() => setShowOldPassword(false)}
                            size={14}
                          />
                        ) : (
                          <IconEye
                            onClick={() => setShowOldPassword(true)}
                            size={14}
                          />
                        )}
                      </TextField>
                    </div>
                  </Form>
                  <div className="password-label" onClick={openResetPassword}>
                    {t("pages.login.passwordReset")}
                  </div>
                  <ResetPasswordModal
                    handleGenerateCode={handleGenerateCode}
                    open={showResetPsw}
                    onClose={() => setShowResetPsw(false)}
                    setShowResetPswSupport={setShowResetPswSupport}
                    setShowVerifyCode={setShowVerifyCode}
                    getEmail={setGetEmail}
                  />
                  <ResetPasswordSupportModal
                    open={showResetPswSupport}
                    onClose={() => setShowResetPswSupport(false)}
                    eMailSelected={getEmail}
                    setShowResetPsw={setShowResetPsw}
                  />
                  <VerifyCodeModal
                    open={showVerifyCode}
                    onClose={() => {
                      setShowVerifyCode(false);
                      setShowResetPsw(true);
                      setShowResetPswSupport(false);
                    }}
                    setShowResetPsw={setShowResetPsw}
                    handleValidateCode={handleValidateCode}
                    handleResetPassword={handleResetPassword}
                  />
                  <div className="login-button">
                    <Button
                      aspect="primary"
                      size="regular"
                      label={t("pages.login.loginButton")}
                      disabled={username === "" || password === ""}
                      onClick={handleSubmit}
                      isLoading={isLoading}
                    />
                  </div>
                </div>
              </div>
              <div className="alert-container">
                {isAlert && (
                  <Alert
                    status="error"
                    message={loginErrorMessage}
                    onClose={() => setIsAlert(false)}
                    hasCloseIcon={true}
                  />
                )}
              </div>
              <div className="request-demo">
                <div
                  style={{
                    width: "429px",
                    height: "156px",
                  }}
                >
                  <WidgetHelp>
                    <WidgetHelpItem
                      icon={
                        <IconVehicle
                          color="--global-colors-ink-dark"
                          size={16}
                        />
                      }
                      label={t("login.widgetDemo")}
                      onClick={() => setDemoModal(true)}
                    />
                    <div className="demo-installer">
                      <DemoRequestModal
                        open={demoModal}
                        onClose={() => setDemoModal(false)}
                      />
                    </div>
                    <WidgetHelpItem
                      icon={
                        <IconHelp color="--global-colors-ink-dark" size={16} />
                      }
                      label={t("login.widgetHelp")}
                      onClick={() => setHelpModal(true)}
                    />
                    <div className="help">
                      <HelpRequestModal
                        open={helpModal}
                        onClose={() => setHelpModal(false)}
                      />
                    </div>
                    <WidgetHelpItem
                      icon={
                        <IconBuild color="--global-colors-ink-dark" size={16} />
                      }
                      label={t("login.widgetInstaller")}
                      onClick={() => setInstallerModal(true)}
                    />
                    <div className="demo-installer">
                      <InstallerRequestModal
                        open={installerModal}
                        onClose={() => setInstallerModal(false)}
                      />
                    </div>
                  </WidgetHelp>
                </div>
              </div>
            </div>
            <div className="copyright-macnil-logo">
              <div className="copyright-label">
                {t("pages.login.copyright").replace(
                  "$$",
                  new Date().getFullYear().toString()
                )}
                <br />
                {t("pages.login.rights")}
              </div>
              <div className="mini-logo-container">
                <div className="mini-logo-img">
                  <Logo type="macnil-powered" width={84} />
                </div>
              </div>
            </div>
          </div>
          <div className="login-second-column d-none d-xxl-block col-xxl-9">
            <LoginSidebar bgImg={imageFile} />
          </div>
        </div>
      </div>
    </ContainerWrapper>
  );
}

export default LoginPage;

LoginPage.propTypes = {
  loginDone: PropTypes.func.isRequired,
};

LoginPage.defaultProps = {
  loginDone: () => {},
};
