import html2canvas from "html2canvas";
import { t } from "i18next";
import jsPDF from "jspdf";
import _ from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useAppSelector } from "../../../app/hooks";
import { store } from "../../../app/store";
import PageFilters from "../../../layout/PageFilters";
import { DropdownButton } from "../../../ui/Button/DropdownButton";
import { IconArrowDown } from "../../../ui/Icon/Line/ArrowDown";
import { IconArrowUp } from "../../../ui/Icon/Line/ArrowUp";
import { IconClose } from "../../../ui/Icon/Line/Close";
import { IconDown } from "../../../ui/Icon/Line/Down";
import { IconDownload } from "../../../ui/Icon/Line/Download";
import { IconExchange } from "../../../ui/Icon/Line/Exchange";
import { IconStopPoint } from "../../../ui/Icon/Line/StopPoint";
import { IconSync } from "../../../ui/Icon/Line/Sync";
import { IconTimer } from "../../../ui/Icon/Line/Timer";
import {
  PublicRouteShift,
  getPublicRouteShiftsAsync,
  publicRouteShiftSelectors,
} from "../shift/publicRouteShiftSlice";
import { PublicRouteStopComponent } from "./PublicRouteStopComponent";
import "./PublicRoutesStop.css";
import {
  PublicRouteAndStops,
  getPublicRouteStopsAsync,
  publicRouteStopSelector,
  publicStopsTime,
} from "./publicRouteStopSlice";

export const PublicRouteStop = () => {
  const routesAndStops: PublicRouteAndStops[] = useAppSelector(
    publicRouteStopSelector.selectAll
  ).sort((a, b) => a.longName.localeCompare(b.longName));

  const shifts: PublicRouteShift[] = useAppSelector(
    publicRouteShiftSelectors.selectAll
  );
  const [routeAndStopSelected, setRouteAndStopSelected] =
    useState<PublicRouteAndStops>();
  const [times, setTimes] = useState<publicStopsTime[]>();
  const [sorted, setSorted] = useState(false);

  const [stopsHeaderWidth, setStopsHeaderWidth] = useState(0);
  const [stopsHeaderElementWidth, setStopsHeaderElementWidth] = useState(0);

  const [shiftDivider, setShiftDivider] = useState<String | undefined>("");

  const list = [
    {
      id: 0,
      title: t("report.filterBar.downloadPDF"),
      icon: true ? (
        <IconDownload size={14} color="--global-colors-ink-light" />
      ) : (
        <div className="details-driverReport-download-spinner"></div>
      ),
      onClick: () => {
        downloadPdf();
      },
    },
  ];

  //Functions REGION

  const downloadPdf = () => {
    const element = document.querySelector(
      "#public-route-stop-table"
    ) as HTMLElement;

    if (!element) {
      console.error("Element with id 'public-route-stop-table' not found.");
      return;
    }

    // Temporaneamente rimuovi il limite di altezza per catturare tutto il contenuto
    const originalStyle = element.getAttribute("style");
    element.style.height = "auto";

    html2canvas(element, { scrollY: -window.scrollY }).then((canvas) => {
      const pdf = new jsPDF("p", "mm", "a4");
      const imgData = canvas.toDataURL("image/png");
      const imgWidth = 210; // larghezza A4 in mm
      const pageHeight = pdf.internal.pageSize.getHeight();
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      let position = 0;

      pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft > 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      pdf.save(t("publicRouteStop.export.pdf"));

      // Ripristina lo stile originale
      element.setAttribute("style", originalStyle ?? "");
    });
  };

  function sorter() {
    return sorted ? (
      <>
        <span style={{ cursor: "pointer" }} onClick={() => setSorted(!sorted)}>
          <IconArrowUp size={10} color={"#687484da"} />
        </span>
      </>
    ) : (
      <>
        <span style={{ cursor: "pointer" }} onClick={() => setSorted(!sorted)}>
          <IconArrowDown size={10} color={"#687484da"} />
        </span>
      </>
    );
  }

  function tableHeaderBuilder() {
    if (times && times?.length > 0) {
      const header = [];

      header.push(
        <div
          className="public-route-stop-header-element"
          style={{
            minWidth: stopsHeaderWidth,
          }}
        >
          <span
            className="public-route-plan-header-element-text"
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "10px",
            }}
          >
            Fermate{sorter()}
          </span>
        </div>
      );

      for (let j = 0; j < times.length; j++) {
        let color;
        const date1 = new Date(`1970-01-01T${times[j].arrivalTime}Z`);
        const date2 = new Date(`1970-01-01T${shiftDivider}Z`);
        if (shiftDivider && date1 < date2) {
          color = "#FED005";
        } else {
          color = "#66A3D2";
        }
        if (j === times.length - 1) {
          header.push(
            <div
              className="public-route-stop-header-element-cell"
              style={{
                width: stopsHeaderElementWidth,
                borderBottom: "3px solid " + color,
              }}
            >
              <span className="public-route-plan-header-element-text">
                {times[j]?.publicTripCode}
              </span>
            </div>
          );
        } else {
          header.push(
            <div
              className="public-route-stop-header-element-cell"
              style={{
                width: stopsHeaderElementWidth,
                borderBottom: "3px solid " + color,
              }}
            >
              <span className="public-route-plan-header-element-text">
                {times[j]?.publicTripCode}
              </span>
            </div>
          );
        }
      }

      return header;
    }
  }

  //Functions ENDREGION

  //UseEffects REGION

  useEffect(() => {
    store.dispatch(getPublicRouteStopsAsync());
    store.dispatch(getPublicRouteShiftsAsync());
  }, []);

  useEffect(() => {
    if (routesAndStops.length > 0) {
      setRouteAndStopSelected(routesAndStops[0]);
    }
  }, [routesAndStops]);

  useEffect(() => {
    setShiftDivider(
      shifts[0]?.shiftTimeRanges?.[shifts[0].shiftTimeRanges?.length - 1]
        ?.endDate
    );
  }, [shifts]);

  const updateWidth = useCallback(() => {
    const element1 = document.getElementById(
      "public-route-stop-component-cell"
    );
    const element2 = document.getElementById("element-stop-component-info");
    if (element1) {
      setStopsHeaderElementWidth(element1.offsetWidth + 0.1);
    }
    if (element2) {
      setStopsHeaderWidth(element2.offsetWidth + 0.1);
    }
  }, [routeAndStopSelected]);

  useEffect(() => {
    let times = routeAndStopSelected?.publicStops?.reduce(
      (longest, current) => {
        return current.publicStopsTimes.length > longest.length
          ? current.publicStopsTimes
          : longest;
      },
      routeAndStopSelected.publicStops[0].publicStopsTimes
    );
    if (times) {
      times = _.sortBy(times, "tripIndex");
      setTimes(times);
    }
  }, [routeAndStopSelected]);

  useEffect(() => {
    // Funzione per aggiornare le dimensioni dell'elemento
    const updateElementWidth = () => {
      requestAnimationFrame(updateWidth);
    };

    // Esegui subito l'aggiornamento delle dimensioni
    updateElementWidth();

    // Aggiungi l'evento di resize
    window.addEventListener("resize", updateElementWidth);
    window.addEventListener("mousemove", updateElementWidth);

    // Rimuovi l'evento di resize al momento della pulizia dell'uso dell'effetto
    return () => {
      window.removeEventListener("resize", updateElementWidth);
      window.removeEventListener("mousemove", updateElementWidth);
    };
  }, [updateWidth]);

  //UseEffects ENDREGION

  return (
    <>
      {/* HEADER REGION */}
      <PageFilters>
        <div className="col col-16">
          <div className="public-route-planning-subtopbar">
            <div className="public-route-planning-subsection-name">
              {t("navigation.publicRouteStops.publicRouteStops")}
            </div>
            <div
              className="public-route-planning-export"
              style={{
                display: "flex",
                marginLeft: "auto",
                width: "fit-content",
              }}
            >
              <div style={{ marginLeft: "10px" }}>
                <DropdownButton
                  size="small"
                  aspect="secondary"
                  disabled={false}
                  label={t("common.export")}
                  list={list}
                >
                  <IconDown size={14} color="--global-colors-ink-ink" />
                </DropdownButton>
              </div>
            </div>
          </div>
        </div>
      </PageFilters>
      {/* END HEADER REGION */}

      <div className="public-route-stop-switch-element-container">
        {routesAndStops?.map((routeAndStop: PublicRouteAndStops) => {
          return (
            <div
              className={
                routeAndStop === routeAndStopSelected
                  ? "public-route-stop-switch-element-selected"
                  : "public-route-stop-switch-element"
              }
              onClick={() => {
                setRouteAndStopSelected(routeAndStop);
              }}
            >
              {routeAndStop.longName}
            </div>
          );
        })}
      </div>
      {routeAndStopSelected && (
        <>
          <div className="public-route-stop-summary-wrapper">
            <div className="public-route-stop-summary-title">Dettagli</div>
            <div className="public-route-stop-summary-elements-container">
              <div className="public-route-stop-summary-element">
                <IconExchange size={14} />
                <div className="public-route-stop-summary-element-formatted">
                  <span className="public-route-planning-plan-summary-element-formatted-textone">
                    {t("navigation.publicRouteStops.summary.nStopTimes")}
                  </span>
                  <span className="public-route-planning-plan-summary-element-formatted-texttwo">
                    {routesAndStops?.length}
                  </span>
                </div>
              </div>
              <div className="public-route-stop-summary-element">
                <IconStopPoint size={14} />
                <div className="public-route-stop-summary-element-formatted">
                  <span className="public-route-planning-plan-summary-element-formatted-textone">
                    {t("navigation.publicRouteStops.summary.stops")}
                  </span>
                  <span className="public-route-planning-plan-summary-element-formatted-texttwo">
                    {routeAndStopSelected?.publicStops?.length}
                  </span>
                </div>
              </div>
              <div className="public-route-stop-summary-element">
                <IconClose size={14} />
                <div className="public-route-stop-summary-element-formatted">
                  <span className="public-route-planning-plan-summary-element-formatted-textone">
                    {t("navigation.publicRouteStops.summary.suppressedStops")}
                  </span>
                  <span className="public-route-planning-plan-summary-element-formatted-texttwo">
                    {
                      routeAndStopSelected?.publicStops?.filter(
                        (publicStop) =>
                          publicStop.publicStopsTimes.filter(
                            (publicStopTime) =>
                              publicStopTime.status === "SUPPRESSED"
                          ).length > 0
                      ).length
                    }
                  </span>
                </div>
              </div>
              <div className="public-route-stop-summary-element">
                <IconTimer size={14} />
                <div className="public-route-stop-summary-element-formatted">
                  <span className="public-route-planning-plan-summary-element-formatted-textone">
                    {t("navigation.publicRouteStops.summary.limitedStops")}
                  </span>
                  <span className="public-route-planning-plan-summary-element-formatted-texttwo">
                    {
                      routeAndStopSelected?.publicStops?.filter(
                        (publicStop) =>
                          publicStop.publicStopsTimes.filter(
                            (publicStopTime) =>
                              publicStopTime.monthsSeasonalExecution.length > 0
                          ).length > 0
                      ).length
                    }
                  </span>
                </div>
              </div>
              <div className="public-route-stop-summary-element">
                <IconSync size={14} />
                <div className="public-route-stop-summary-element-formatted">
                  <span className="public-route-planning-plan-summary-element-formatted-textone">
                    {t("navigation.publicRouteStops.summary.shifts")}
                  </span>
                  <span className="public-route-planning-plan-summary-element-formatted-texttwo">
                    {shifts.length}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div id="public-route-stop-table" style={{ height: "100%" }}>
            <div className="public-route-stop-table-header">
              {tableHeaderBuilder()}
            </div>
            <div className="public-route-stop-table-body">
              {routeAndStopSelected?.publicStops
                ?.slice()
                .sort((a: any, b: any) =>
                  sorted
                    ? b.routeStopId - a.routeStopId
                    : a.routeStopId - b.routeStopId
                )
                .map((publicStop: any) => {
                  return (
                    <div
                      className="public-stop-row"
                      id="public-stop-row-elements"
                    >
                      <div
                        id="element-stop-component-info"
                        className="public-route-plan-row-shift-driver"
                        style={{ paddingLeft: "10px", minWidth: "none" }}
                      >
                        <div className="public-route-plan-row-shift-driver-textone">
                          {" "}
                          {publicStop?.code}
                        </div>
                        <div
                          className="public-route-plan-row-shift-driver-texttwo"
                          style={{
                            fontSize: "10px",
                            width: "130px",
                            marginRight: "20px",
                          }}
                        >
                          {publicStop?.name}
                        </div>
                      </div>
                      <div
                        className="public-route-stop-row-stops"
                        style={{ width: "100%" }}
                      >
                        {times?.map((tripByTime, index) => {
                          publicStop.publicStopsTimes.find(
                            (e: any) =>
                              e.publicTripCode === tripByTime.publicTripCode
                          );
                          return (
                            <PublicRouteStopComponent
                              id={index}
                              date={
                                publicStop.publicStopsTimes.find(
                                  (e: any) =>
                                    e.publicTripCode ===
                                    tripByTime.publicTripCode
                                )?.arrivalTime ?? ""
                              }
                              seasonalMonths={
                                publicStop.publicStopsTimes.find(
                                  (e: any) =>
                                    e.publicTripCode ===
                                    tripByTime.publicTripCode
                                )?.monthsSeasonalExecution ?? []
                              }
                              status={
                                publicStop.publicStopsTimes.find(
                                  (e: any) =>
                                    e.publicTripCode ===
                                    tripByTime.publicTripCode
                                )?.status === "DELETED"
                                  ? null
                                  : publicStop.publicStopsTimes.find(
                                      (e: any) =>
                                        e.publicTripCode ===
                                        tripByTime.publicTripCode
                                    )?.status
                              }
                              publicTripCode={
                                publicStop.publicStopsTimes.find(
                                  (e: any) =>
                                    e.publicTripCode ===
                                    tripByTime.publicTripCode
                                )?.publicTripCode
                              }
                            />
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </>
      )}
    </>
  );
};
