import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import _ from "lodash";
import { normalize } from "normalizr";
import { RootState } from "../../app/store";
import { Config } from "../../config/Config";
import { GTFleetErrorCodes } from "../../config/GTfleetErrorCodes";
import { GTFleetSuccessCodes } from "../../config/GTFleetSuccessCodes";
import { getErrorCodes } from "../../utils/Utils";
import {
  getFilteredGeofencesAsync,
  getGeofenceAsync,
  getGeofencesAsync,
} from "../geofence/geofenceSlice";
import GeofenceCategoriesRepository from "./geofenceCategoriesRepository";
import {
  geofenceCategoriesSchema,
  geofenceCategorySchema,
} from "./geofenceCategoryNormalization";

//#region Type
export type GeofenceCategory = {
  id: number;
  name: string;
  color: string;
  deleted: boolean;
  numberOfGeofences: number;
};
//#endregion Type

//#region Slice
const geofenceCategoriesAdapter = createEntityAdapter<GeofenceCategory>({
  selectId: (geofenceCategory) => geofenceCategory.id,
  sortComparer: (a, b) => a.name.localeCompare(b.name),
});

export const getGeofenceCategoriesAsync = createAsyncThunk(
  "geofenceCategories/getGeofencesCategories",
  async (_data, { rejectWithValue }) => {
    try {
      const geofencesCategoriesRepository = new GeofenceCategoriesRepository();
      const response =
        await geofencesCategoriesRepository.getGeofenceCategories();
      const geofencesCategories = _.get(
        response,
        Config.GEOFENCE_CATEGORIES_RESPONSE_PATH
      );
      const normalizedData = normalize(
        geofencesCategories,
        geofenceCategoriesSchema
      );
      return normalizedData.entities;
    } catch (error: any) {
      if (!error.response) throw error;
      return rejectWithValue(error.response.status);
    }
  }
);

export const createGeofenceCategoryAsync = createAsyncThunk(
  "geofenceCategories/createGeofencesCategory",
  async (data: { geofenceCategory: GeofenceCategory }, { rejectWithValue }) => {
    try {
      const geofencesCategoriesRepository = new GeofenceCategoriesRepository();
      const response =
        await geofencesCategoriesRepository.createGeofenceCategory(
          data.geofenceCategory
        );
      const geofenceCategory = _.get(
        response,
        Config.GEOFENCE_CATEGORY_RESPONSE_PATH
      );
      const normalizedData = normalize(
        geofenceCategory,
        geofenceCategorySchema
      );
      return normalizedData.entities;
    } catch (error: any) {
      if (!error.response) throw error;
      return rejectWithValue(error.response.status);
    }
  }
);

export const updateGeofenceCategoryAsync = createAsyncThunk(
  "geofenceCategories/updateGeofencesCategory",
  async (
    data: { geofenceCategory: GeofenceCategory; id: number },
    { rejectWithValue }
  ) => {
    try {
      const geofencesCategoriesRepository = new GeofenceCategoriesRepository();
      const response =
        await geofencesCategoriesRepository.updateGeofenceCategory(
          data.id,
          data.geofenceCategory
        );
      const geofenceCategory = _.get(
        response,
        Config.GEOFENCE_CATEGORY_RESPONSE_PATH
      );
      const normalizedData = normalize(
        geofenceCategory,
        geofenceCategorySchema
      );
      return normalizedData.entities;
    } catch (err: any) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data.message);
    }
  }
);

export const deleteGeofenceCategoryAsync = createAsyncThunk(
  "geofenceCategories/deleteGeofencesCategory",
  async (geofenceCategoryId: number, { rejectWithValue }) => {
    try {
      const geofencesCategoriesRepository = new GeofenceCategoriesRepository();
      const response =
        await geofencesCategoriesRepository.updateGeofenceCategory(
          geofenceCategoryId,
          {
            deleted: true,
          }
        );
      const geofenceCategory = _.get(
        response,
        Config.GEOFENCE_CATEGORY_RESPONSE_PATH
      );
      const normalizedData = normalize(
        geofenceCategory,
        geofenceCategorySchema
      );
      return normalizedData.entities;
    } catch (err: any) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data.message);
    }
  }
);

export const geofenceCategoriesSlice = createSlice({
  name: "geofenceCategories",
  initialState: geofenceCategoriesAdapter.getInitialState({
    status: "idle",
    reasonCode: "",
  }),
  reducers: {
    geofenceCategoriesEmptyState: (state: any) => {
      geofenceCategoriesAdapter.setAll(state, []);
      state.reasonCode = "";
      state.status = "idle";
    },
    geofenceCategoriesRestoreStatusAndReasonCode: (state) => {
      state.reasonCode = "";
      state.status = "idle";
    },
  },
  extraReducers: (builder) => {
    builder
      //#region get reducers
      .addCase(
        getGeofenceCategoriesAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          geofenceCategoriesAdapter.upsertMany(
            state,
            action.payload.geofenceCategory ?? []
          );
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.GET;
        }
      )
      //TODO check code error
      .addCase(
        getGeofenceCategoriesAsync.rejected,
        (state: any, action: PayloadAction<any>) => {
          state.status = "failed";
          state.reasonCode = getErrorCodes(action.payload);
        }
      )
      .addCase(getGeofenceCategoriesAsync.pending, (state: any) => {
        state.status = "loading";
      })
      //#endregion get reducers
      //#region create reducers
      .addCase(
        createGeofenceCategoryAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          geofenceCategoriesAdapter.upsertMany(
            state,
            action.payload.geofenceCategory
          );
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.POST;
        }
      )
      //TODO check code error
      .addCase(
        createGeofenceCategoryAsync.rejected,
        (state: any, action: PayloadAction<any>) => {
          state.status = "failed";
          if (action.payload === 409) {
            state.reasonCode =
              GTFleetErrorCodes.GEOFENCE_CATEGORIES_NAME_ALREADY_EXISTS;
          } else state.reasonCode = getErrorCodes(action.payload);
        }
      )
      .addCase(createGeofenceCategoryAsync.pending, (state: any) => {
        state.status = "loading";
      })
      //#endregion create reducers
      //#region update reducers
      .addCase(
        updateGeofenceCategoryAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          geofenceCategoriesAdapter.upsertMany(
            state,
            action.payload.geofenceCategory
          );
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.PATCH;
        }
      )
      //TODO check code error
      .addCase(
        updateGeofenceCategoryAsync.rejected,
        (state: any, action: PayloadAction<any>) => {
          state.status = "failed";
          state.reasonCode = getErrorCodes(action.payload);
        }
      )
      .addCase(updateGeofenceCategoryAsync.pending, (state: any) => {
        state.status = "loading";
      })
      //#endregion update reducers
      //#region delete reducers
      .addCase(
        deleteGeofenceCategoryAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          let id = _.keys(action.payload.geofenceCategory)[0];
          id && geofenceCategoriesAdapter.removeOne(state, id);
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.PATCH;
        }
      )
      //TODO check code error
      .addCase(
        deleteGeofenceCategoryAsync.rejected,
        (state: any, action: PayloadAction<any>) => {
          state.status = "failed";
          if (action.payload && action.payload === 404) {
            state.reasonCode =
              GTFleetErrorCodes.GEOFENCE_CATEGORIES_TO_BE_DELETED_NOT_FOUND;
          } else state.reasonCode = getErrorCodes(action.payload);
        }
      )
      .addCase(deleteGeofenceCategoryAsync.pending, (state: any) => {
        state.status = "loading";
      })
      //#endregion delete reducers
      //#region External Entity Reducers
      .addCase(
        getGeofencesAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          geofenceCategoriesAdapter.upsertMany(
            state,
            action.payload?.geofenceCategory ?? []
          );
        }
      )
      .addCase(
        getFilteredGeofencesAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          geofenceCategoriesAdapter.upsertMany(
            state,
            action.payload?.geofenceCategory ?? []
          );
        }
      )
      .addCase(
        getGeofenceAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          geofenceCategoriesAdapter.upsertMany(
            state,
            action.payload?.geofenceCategory ?? []
          );
        }
      );
    //#endregion External Entity Reducers
  },
});
//#endregion Slice

//#region Status
export const geofenceCategoriesSelectors =
  geofenceCategoriesAdapter.getSelectors<RootState>(
    (state) => state.geofenceCategories
  );

export const selectGeofenceCategoriesSliceStatus = (state: any) =>
  state.geofenceCategories.status;
export const selectGeofenceCategoriesSliceReasonCode = (state: any) =>
  state.geofenceCategories.reasonCode;
export const {
  geofenceCategoriesEmptyState,
  geofenceCategoriesRestoreStatusAndReasonCode,
} = geofenceCategoriesSlice.actions;
//#endregion Status

export default geofenceCategoriesSlice.reducer;
