import { useTranslation } from "react-i18next";
import { DataLabel } from "../../../ui/DataLabel/DataLabel";
import { IconTruck } from "../../../ui/Icon/Line/Truck";
import { IconParking } from "../../../ui/Icon/Line/Parking";

interface GPSDataReportStopsDetailsProps {
  vehicles?: number;
  stopsNumber?: number;
}

export const GPSDataReportStopsDetails: React.FC<GPSDataReportStopsDetailsProps> =
  ({ vehicles, stopsNumber }) => {
    const { t } = useTranslation();

    return (
      <>
        {vehicles != null && (
          <div className="details-report-item" key={0}>
            <DataLabel
              dataText={vehicles}
              icon={<IconTruck size={14} />}
              label={t("report.details.vehicles")}
            />
          </div>
        )}
        {stopsNumber != null && (
          <div className="details-report-item" key={1}>
            <DataLabel
              dataText={stopsNumber}
              icon={<IconParking size={14} />}
              label={t("report.details.stopsNumber")}
            />
          </div>
        )}
      </>
    );
  };
