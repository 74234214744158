import dayjs from "dayjs";
import { t } from "i18next";
import _ from "lodash";
import { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { store } from "../../app/store";
import { Button } from "../../ui/Button/Button";
import { Dropdown } from "../../ui/Dropdown/Dropdown";
import { DatePickerDropdown } from "../../ui/Forms/DatePickerDropdown";
import Form from "../../ui/Forms/Form";
import SearchField from "../../ui/Forms/SearchField";
import { IconCalendar } from "../../ui/Icon/Line/Calendar";
import { IconCheck } from "../../ui/Icon/Line/Check";
import { getQueryString } from "../../utils/Utils";
import { Preferences } from "../users/preference/preferencesSlice";
import UserContext from "../users/userContext";
import "./NotificationFilterBar.css";
import {
  NotificationCategory,
  getNotificationCategoryAsync,
  notificationCategorySelectors,
} from "./notificationCategorySlice";
import {
  NotificationEvent,
  getFilteredNotificationsAsync,
  notificationEventsSelectors,
  selectNotificationEventsSliceUnreadNotifications,
} from "./notificationSlice";
interface NotificationFilterBarProps {
  callback: (buildQueryParam: string) => any;
  write?: boolean;
}

interface QueryParams {
  [paramName: string]: any;
}

interface DropDownItem {
  label: string;
  value: string;
  checked: boolean;
}

interface ReadUnread {
  key: "UNREAD" | "READ" | "READ/UNREAD";
  label: string;
}

const readUnreadTypeValues: ReadUnread[] = [
  {
    key: "UNREAD",
    label: t("notificationEvent.notificationReadUnread.UNREAD"),
  },
  {
    key: "READ",
    label: t("notificationEvent.notificationReadUnread.READ"),
  },
  {
    key: "READ/UNREAD",
    label: t("notificationEvent.notificationReadUnread.READ/UNREAD"),
  },
];

export const NotificationsFilterBar: React.FC<NotificationFilterBarProps> = ({
  callback,
  write,
}) => {
  const navigate = useNavigate();
  const queryParamsRef = useRef<QueryParams>({});
  let queryParams: QueryParams = queryParamsRef.current;

  const [notificationStringSearch, setNotificationStringSearch] =
    useState<string>("");
  const [preferencesContext]: [Preferences] = useContext(UserContext);
  const [dateRange, setDateRange] = useState<string[]>([]);
  const [selectedReUnTypeValues, setSelectedReUnTypeValues] = useState(
    [] as ReadUnread[]
  );
  const notifications: NotificationEvent[] = useAppSelector((state) =>
    notificationEventsSelectors.selectAll(state)
  );
  const notificationCategories: NotificationCategory[] = useAppSelector(
    (state) => notificationCategorySelectors.selectAll(state)
  );
  const [notificationCategoryValues, setNotificationCategoryValues] = useState<
    DropDownItem[]
  >([] as DropDownItem[]);

  const location = useLocation();
  const [searchParams] = useSearchParams();

  const notificationsRead = useAppSelector(
    selectNotificationEventsSliceUnreadNotifications
  );

  useEffect(() => {
    store.dispatch(getNotificationCategoryAsync({}));
  }, []);

  useEffect(() => {
    const map = new Map();
    const currentSearchParams =
      searchParams.toString() !== "" ? searchParams : null;
    if (!!currentSearchParams && _.isEmpty(queryParams)) {
      const size = currentSearchParams.get("size");
      map.set("size", size ?? "10");
      map.set("sort", "sendDate,DESC");
      //SearchBar
      const messageSearch = currentSearchParams.get("message");
      const titleSearch = currentSearchParams.get("title");
      if (messageSearch) {
        map.set("message", messageSearch);
      }
      if (titleSearch) {
        map.set("title", titleSearch);
      }

      let searchString = "";
      if (messageSearch || titleSearch) {
        searchString = messageSearch ?? titleSearch ?? searchString;
        setNotificationStringSearch(searchString);
      }

      // Calendar
      const currentSearchParamsPeriod: string[] =
        currentSearchParams.getAll("sendDate");
      const periods: string[] = [];
      let startPeriod: string = "";
      let endPeriod: string = "";
      // If there are two selected dates then the range is already defined
      if (currentSearchParamsPeriod && currentSearchParamsPeriod.length > 1) {
        startPeriod = currentSearchParamsPeriod[0];
        endPeriod = currentSearchParamsPeriod[1];
      }
      // If there is one selected date then add as start date the midnight of the already defined date
      if (
        !currentSearchParamsPeriod ||
        currentSearchParamsPeriod.length === 1
      ) {
        endPeriod = dayjs(new Date(currentSearchParamsPeriod[0])).format(
          "YYYY-MM-DDTHH:mm:ssZ"
        );
        const startPeriodDate = new Date(endPeriod);
        startPeriodDate.setHours(0, 0, 0, 0);
        startPeriod = dayjs(startPeriodDate).toISOString();
      }
      // If there are no selected dates then add as range the current date from the midnight
      if (
        !currentSearchParamsPeriod ||
        currentSearchParamsPeriod.length === 0
      ) {
        endPeriod = dayjs().toISOString();
        const startPeriodDate = new Date(endPeriod);
        startPeriodDate.setHours(0, 0, 0, 0);
        startPeriod = dayjs(startPeriodDate).toISOString();
      }
      periods.push(startPeriod);
      periods.push(endPeriod);
      map.set("sendDate", periods);
      setDateRange(periods);

      // Retrieving the read/undread options
      const readUnreadOptions = currentSearchParams.getAll("read");
      if (!!readUnreadOptions) {
        const readUnreadOptionVal: ReadUnread[] = [];
        readUnreadOptions.forEach((ReUnStateType) => {
          const routeTypeValue = readUnreadTypeValues.find(
            (ReUnStateTypes) => ReUnStateTypes.key === ReUnStateType
          );
          if (routeTypeValue) {
            readUnreadOptionVal.push(routeTypeValue);
          }
        });
        if (readUnreadOptionVal && readUnreadOptionVal.length > 0) {
          setSelectedReUnTypeValues(readUnreadOptionVal);
          map.set(
            "read",
            readUnreadOptionVal.map((x) => x.key)
          );
        } else {
          setSelectedReUnTypeValues([readUnreadTypeValues[2]]);
        }
      }
    }
    // This "if" is useful to put the current date when no dates are selected (it works when the user starts the navigation from the location-history)
    if (map.size === 0 && _.isEmpty(queryParams)) {
      const endPeriod = dayjs().toISOString();
      const startPeriodDate = new Date(
        new Date(endPeriod).setDate(new Date(endPeriod).getDate() - 90)
      );
      startPeriodDate.setHours(0, 0, 0, 0);
      const startPeriod = dayjs(startPeriodDate).toISOString();
      map.set("sendDate", [startPeriod, endPeriod]);
      setDateRange([startPeriod, endPeriod]);

      map.set("read", false);
      map.set("sort", "sendDate,DESC");

      setSelectedReUnTypeValues([readUnreadTypeValues[0]]);
      map.set("size", "10");
    }
    if (map.size > 0) {
      handleChanges(map, false);
    }
  }, [location]);

  // This useEffect only handles notification categories dropdown because the filter bar needs to wait for they are downloaded.
  useEffect(() => {
    if (notificationCategories.length > 0) {
      const map = new Map();
      const currentSearchParams =
        searchParams.toString() !== "" ? searchParams : null;
      const tempNotificationCategoriesValues = notificationCategories.map(
        (notificationCategory) => {
          return {
            label: notificationCategory.name,
            value: notificationCategory.name,
            checked: true,
          };
        }
      );
      if (
        !!currentSearchParams &&
        !queryParams.hasOwnProperty("notificationCategory.name")
      ) {
        // Retrieving selected categories
        const categoryTypes = currentSearchParams.getAll(
          "notificationCategory.name"
        );
        if (categoryTypes && categoryTypes?.length > 0) {
          const temp = tempNotificationCategoriesValues.map((categoryType) => {
            if (categoryTypes.includes(categoryType.value)) {
              categoryType.checked = true;
            } else {
              categoryType.checked = false;
            }
            return categoryType;
          });
          setNotificationCategoryValues(temp);
          map.set(
            "notificationCategory.name",
            categoryTypes.map((x) => x)
          );
        }
      }
      // This "if" is useful to put the current date when no dates are selected (it works when the user starts the navigation from the location-history)
      if (
        !currentSearchParams ||
        !currentSearchParams?.has("notificationCategory.name")
      ) {
        setNotificationCategoryValues(tempNotificationCategoriesValues);
        const filteredCategories = tempNotificationCategoriesValues
          .filter((x) => x.checked)
          .map((x) => x.value);
        if (filteredCategories) {
          map.set("notificationCategory.name", filteredCategories);
        }
      }
      if (map.size > 0) {
        handleChanges(map, true);
      }
    }
  }, [notificationCategories]);

  const handleChanges = (
    params: Map<string, string[] | string>,
    executeImmediatly: boolean = true
  ): void => {
    let queryString = "";
    let executeQuery = false;
    if (!!params) {
      params.forEach((value, key) => {
        if (
          (!!value &&
            (_.isArray(value) || _.isString(value)) &&
            value.length > 0) ||
          _.isBoolean(value)
        ) {
          queryParams[key] = value;
          executeQuery = true;
        } else {
          if (queryParams.hasOwnProperty(key)) {
            delete queryParams[key];
            executeQuery = true;
          }
        }
      });
      queryString = getQueryString(queryParams);
    }
    if (executeQuery && executeImmediatly) {
      store.dispatch(
        getFilteredNotificationsAsync({ queryParams: queryString })
      );
      navigate({
        pathname: "/dashboard/notifications",
        search: queryString,
      });
      callback(queryString);
    }
  };

  const debouncedSearch = useRef(
    _.debounce(async (val) => {
      const searchNotification = new Map();
      searchNotification.set("message", val);
      searchNotification.set("title", val);
      handleChanges(searchNotification);
      setNotificationStringSearch(val);
    }, 1000)
  ).current;

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  async function handleSearch(val: string) {
    await debouncedSearch(val);
  }

  return (
    <div className="log-filterbar-row">
      <div className="log-filterbar-search">
        <Form>
          <SearchField
            name="search"
            id="notifications"
            size="small"
            placeholder={t("notificationEvent.filterBar.searchField")}
            value={notificationStringSearch}
            onChange={(val: string) => {
              handleSearch(val);
            }}
          />
        </Form>
      </div>
      <div className="notification-date-picker">
        <Form>
          <DatePickerDropdown
            setDate={(val: any[]) => {
              const map = new Map();
              const period = [];
              if (val[1]) {
                val[0] && period.push(val[0].toISOString());
                val[1] && period.push(val[1].toISOString());
              } else {
                period.push(
                  val[0]?.set("hour", 0)?.set("minute", 0).toISOString()
                );
                period.push(
                  val[0]?.set("hour", 23)?.set("minute", 59).toISOString()
                );
              }
              map.set("sendDate", period);
              setDateRange(period);
              handleChanges(map);
            }}
            localeFormat={preferencesContext.localeFormat ?? "DD / MM / YYYY"}
            initialValue={dateRange.map((date) => new Date(date))}
            defaultValue={new Date()}
            language={preferencesContext.language ?? "it"}
            icon={<IconCalendar size={12} color="--global-colors-ink-light" />}
            dateRange={[dayjs().subtract(1, "year"), dayjs()]} //From today to past 1 year
            limitDaysRange={90}
            hasTime={true}
          />
        </Form>
      </div>
      <div className="notification-filterbar-dropdown-allType">
        <Dropdown
          itemAttribute="label"
          hasCheckbox={true}
          placeholder={t("notificationEvent.filterBar.searchGroupsAllType")}
          size={"small"}
          onChange={(val: DropDownItem[]) => {
            setNotificationCategoryValues(
              notificationCategoryValues.map((notificationCategoryValues) => {
                if (val.includes(notificationCategoryValues)) {
                  notificationCategoryValues.checked = true;
                } else {
                  notificationCategoryValues.checked = false;
                }
                return notificationCategoryValues;
              })
            );
            const map = new Map();
            map.set(
              "notificationCategory.name",
              val.map((x) => x.value)
            );
            handleChanges(map);
          }}
          options={notificationCategoryValues}
          value={notificationCategoryValues.filter((x) => x.checked)}
          hasSelectAll={true}
        />
      </div>
      <div>
        <Dropdown
          size={"small"}
          options={readUnreadTypeValues}
          value={selectedReUnTypeValues}
          placeholder=""
          itemAttribute="label"
          onChange={(val: ReadUnread[]) => {
            const map = new Map();
            const readUnreadTypes: string[] = [];
            val.forEach((val) => {
              const readUnreadValue = readUnreadTypeValues.find(
                (ReUnTypeValue) => ReUnTypeValue.key === val.key
              )?.key;
              if (readUnreadValue) {
                readUnreadTypes.push(readUnreadValue);
              }
            });
            if (!!val && val.length > 0) {
              const currentValue = val[0];
              let readParameterValue = undefined;
              if (currentValue.key !== "READ/UNREAD") {
                if (currentValue.key === "READ") {
                  readParameterValue = true;
                }
                if (currentValue.key === "UNREAD") {
                  readParameterValue = false;
                }
                map.set("read", readParameterValue);
                handleChanges(map);
              } else {
                map.set("read", "");
                handleChanges(map);
              }
            }
          }}
        />
      </div>
      {write ? (
        <div className="notification-filterbar-button">
          <Button
            aspect="primary"
            size="small"
            label={
              t("notificationEvent.filterBar.markAllAsRead") +
              (notificationsRead > 0 ? "(" + notificationsRead + ")" : "")
            }
            disabled={!notifications.some((x: NotificationEvent) => !x.read)}
            onClick={() => {
              navigate("/dashboard/notifications/markallasread");
            }}
          >
            {notificationsRead > 0 && (
              <IconCheck size={14} color="--global-colors-ink-white" />
            )}
          </Button>
        </div>
      ) : null}
    </div>
  );
};
