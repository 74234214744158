import "./PageFilters.css";

interface PageFiltersProps {
  hidden?: string;
  children: object;
}

function PageFilters({ hidden, children }: PageFiltersProps) {
  const style = { display: !hidden ? "" : hidden };

  return (
    <div className="row page-filters" style={style}>
      {children}
    </div>
  );
}

export default PageFilters;
