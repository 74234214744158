import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import {
  createNotificationRuleAsync,
  editNotificationRuleAsync,
  getFilteredNotificationsRuleAggregationsAsync,
  getNotificationRule,
} from "./notificationRuleAggregationSlice";

export type NotificationRuleDestination = {
  id: number;
  channel: ChannelType;
  address: number;
  contacts: string[];
  last_execution: Date;
  rule: number;
};

export const NotificationChannelEnum = {
  SMS: "SMS",
  EMAIL: "EMAIL",
  NOTIFICATION: "NOTIFICATION",
};
export type ChannelType = keyof typeof NotificationChannelEnum;

const notificationRuleDestinationsAdapter =
  createEntityAdapter<NotificationRuleDestination>({
    selectId: (notitficationRuleDestination) => notitficationRuleDestination.id,
  });

export const notificationRuleDestinationsSlice = createSlice({
  name: "notificationRuleDestinations",
  initialState: notificationRuleDestinationsAdapter.getInitialState({
    status: "idle",
    reasonCode: "",
    totalPages: 0,
  }),
  reducers: {
    setNumberOfPages: (state, action: PayloadAction<number>) => {
      state.totalPages = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      //#region Entity Reducers
      //#endregion

      .addCase(
        getFilteredNotificationsRuleAggregationsAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          action.payload?.notificationRuleDestination &&
            notificationRuleDestinationsAdapter.upsertMany(
              state,
              action.payload?.notificationRuleDestination
            );
        }
      )
      .addCase(
        getNotificationRule.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          action.payload?.notificationRuleDestination &&
            notificationRuleDestinationsAdapter.upsertMany(
              state,
              action.payload?.notificationRuleDestination
            );
        }
      )
      .addCase(
        editNotificationRuleAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          notificationRuleDestinationsAdapter.upsertMany(
            state,
            action.payload.notificationRuleDestination ?? []
          );
        }
      )
      .addCase(
        createNotificationRuleAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          notificationRuleDestinationsAdapter.upsertMany(
            state,
            action.payload.notificationRuleDestination ?? []
          );
        }
      );
  },
});

export const notificationRuleDestinationsSelectors =
  notificationRuleDestinationsAdapter.getSelectors<RootState>(
    (state) => state.notificationRuleDestinations
  );
export const selectNotificationRuleSliceStatus = (state: any) =>
  state.notificationRuleDestinations.status;

export const selectNotificationRuleSliceReasonCode = (state: any) =>
  state.notificationRuleDestinations.reasonCode;

export const selectNotificationRuleSlicePage = (state: any) =>
  state.notificationRuleDestinations.totalPages;

export const { setNumberOfPages } = notificationRuleDestinationsSlice.actions;

export default notificationRuleDestinationsSlice.reducer;
