import dayjs from "dayjs";
import { t } from "i18next";
import _ from "lodash";
import { useContext, useEffect, useMemo, useState } from "react";
import { useMatch, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { store } from "../../app/store";
import { GTFleetSuccessCodes } from "../../config/GTFleetSuccessCodes";
import { GTFleetErrorCodes } from "../../config/GTfleetErrorCodes";
import PageContent from "../../layout/PageContent";
import PageFilters from "../../layout/PageFilters";
import { Button } from "../../ui/Button/Button";
import { Dropdown } from "../../ui/Dropdown/Dropdown";
import Form from "../../ui/Forms/Form";
import { SingleDatePicker } from "../../ui/Forms/SingleDatePicker";
import { Switch } from "../../ui/Forms/Switch";
import TextField from "../../ui/Forms/TextField";
import { Tooltip } from "../../ui/Forms/Tooltip";
import { ElementType } from "../../ui/Group/ElementTypeEnum";
import { IconEye } from "../../ui/Icon/Line/Eye";
import { IconEyeSlash } from "../../ui/Icon/Line/EyeSlash";
import { Check } from "../../ui/Indicators/Check";
import { TabSection } from "../../ui/Tabs/TabSection";
import { Tabs } from "../../ui/Tabs/Tabs";
import { Tag } from "../../ui/Tags/Tag";
import { ThumbProfile } from "../../ui/ThumbProfile/ThumbProfile";
import { DataCommon } from "../../utils/Common";
import { getFullDate } from "../../utils/DateAndTimeUtils";
import { ToastNotification } from "../../utils/ToastNotification";
import {
  getIllustrationFromVehicleType,
  getQueryString,
} from "../../utils/Utils";
import {
  AdminPrivilegesResourceTab,
  ResourceProps,
} from "../components/AdminPrivilegesResourceTab";
import { Driver, driversSelectors } from "../driver/driversSlice";
import {
  DriverStatus,
  driversStatusSelectors,
  getFilteredDriversStatusAndDetailsAsync,
  selectDriverStatusSliceReasonCode,
  selectDriverStatusSliceStatus,
} from "../driver/driversStatusSlice";
import {
  Geofence,
  geofencesSelectors,
  getGeofencesAsync,
  selectGeofencesSliceReasonCode,
  selectGeofencesSliceStatus,
} from "../geofence/geofenceSlice";
import {
  GeofenceCategory,
  geofenceCategoriesSelectors,
  getGeofenceCategoriesAsync,
  selectGeofenceCategoriesSliceReasonCode,
  selectGeofenceCategoriesSliceStatus,
} from "../geofenceCategory/geofenceCategoriesSlice";
import { Vehicle, vehiclesSelectors } from "../vehicle/vehiclesSlice";
import {
  VehicleStatus,
  getVehiclesAsync,
  selectVehiclesStatusSliceReasonCode,
  selectVehiclesStatusSliceStatus,
  vehiclesStatusSelectors,
} from "../vehicle/vehiclesStatusSlice";
import "./EditUser.css";
import "./User.css";
import { Preferences } from "./preference/preferencesSlice";
import {
  PrivilegesDefault,
  getPrivilegesDefaultAsync,
  privilegesDefaultEmptyState,
  privilegesDefaultSelectors,
} from "./privilege/privilegesDefaultSlice";
import {
  Privileges,
  UserPermissions,
  privilegesEmptyState,
  selectPrivilegesSliceReasonCode,
  selectPrivilegesSliceStatus,
} from "./privilege/privilegesSlice";
import {
  SecurityProfiles,
  getSecurityProfilesAsync,
  securityProfilesEmptyState,
  securityProfilesSelectors,
  selectSecurityProfilesSliceReasonCode,
  selectSecurityProfilesSliceStatus,
} from "./securityProfile/securityProfilesSlice";
import UserContext from "./userContext";
import {
  UserInfo,
  getUserInfoAsync,
  selectUsersInfoSliceReasonCode,
  selectUsersInfoSliceStatus,
  updateUserInfoAsync,
  userInfoSelectors,
  usersInfoEmptyState,
} from "./usersInfoSlice";

interface EditUserProps {
  permissions: UserPermissions;
}

interface DataCommonProps {
  label: string;
}

export type SelectedPrivileges = {
  vehicles: number[];
  drivers: number[];
  geofences: number[];
};

const EditUser: React.FC<EditUserProps> = ({ permissions }) => {
  const navigate = useNavigate();
  const editUserRoute = useMatch("/admin/users/edit/:userId");
  const prefixList: DataCommonProps[] =
    DataCommon()?.prefixList ?? ([] as DataCommonProps[]);
  const [editUser, setEditUser] = useState<UserInfo>({} as UserInfo);
  const [errorList, setErrorList] = useState<string[]>([]);
  const [privilegesGroup, setPrivilegesGroup] = useState<boolean>(false);
  const [modifiedPrivilege, setModifiedPrivilege] =
    useState<SelectedPrivileges>({} as SelectedPrivileges);
  const [privilegesHasChanged, setPrivilegesHasChanged] = useState(false);
  const [vehiclesCanSee, setVehiclesCanSee] = useState<ResourceProps[]>(
    [] as ResourceProps[]
  );
  const [driversCanSee, setDriversCanSee] = useState<ResourceProps[]>(
    [] as ResourceProps[]
  );
  const [geofencesCanSee, setGeofencesCanSee] = useState<ResourceProps[]>(
    [] as ResourceProps[]
  );

  useEffect(() => {
    document.title = t("admin.user.editViewCreate.editAccount") + " - Admin";
    store.dispatch(getSecurityProfilesAsync());

    return () => {
      store.dispatch(usersInfoEmptyState());
      store.dispatch(privilegesEmptyState());
      store.dispatch(privilegesDefaultEmptyState());
      store.dispatch(securityProfilesEmptyState());
    };
  }, []);

  //#region Upload Data
  const [id, setId] = useState<number>(-1);

  const userInfo: UserInfo =
    useAppSelector((state) => userInfoSelectors.selectById(state, id)) ??
    ({} as UserInfo);
  const userSliceStatus = useAppSelector(selectUsersInfoSliceStatus);
  const userSliceReasonCode = useAppSelector(selectUsersInfoSliceReasonCode);

  let privilegesDefault: PrivilegesDefault[] =
    useAppSelector((state: any) =>
      privilegesDefaultSelectors.selectAll(state)
    ) ?? ({} as PrivilegesDefault);

  const securityProfiles: SecurityProfiles[] = useAppSelector(
    securityProfilesSelectors.selectAll
  ).filter(
    (x) =>
      x.name != "HS_INTEGRATION" &&
      x.name != "SYSTEM" &&
      x.name != "TENANT_MANAGER"
  );

  const securityProfileSliceStatus = useAppSelector(
    selectSecurityProfilesSliceStatus
  );
  const securityProfileSliceReasonCode = useAppSelector(
    selectSecurityProfilesSliceReasonCode
  );
  const privilegesSliceStatus = useAppSelector(selectPrivilegesSliceStatus);
  const privilegesSliceReasonCode = useAppSelector(
    selectPrivilegesSliceReasonCode
  );

  document.title = useMemo(() => {
    if (userInfo?.username) {
      return (
        `${userInfo?.username} - ` +
        t("admin.user.editViewCreate.editAccount") +
        " - Admin"
      );
    } else {
      return t("admin.user.editViewCreate.editAccount") + " - Admin";
    }
  }, [userInfo]);

  const [preferencesContext]: [Preferences] = useContext(UserContext);

  useEffect(() => {
    if (editUserRoute !== null) {
      if (editUserRoute.params.userId) {
        setId(parseInt(editUserRoute.params.userId));
        store.dispatch(
          getUserInfoAsync({
            id: Number(editUserRoute.params.userId),
          })
        );
        return;
      }
    }
  }, [navigate, editUserRoute]);
  //#endregion

  //#region "Password validation"
  const [newPassword, setNewPassword] = useState<string>("");
  const [viewNewPassword, setViewNewPassword] = useState<boolean>(true);
  const [repeatPassword, setRepeatPassword] = useState<string>("");
  const [viewRepeatPassword, setViewRepeatPassword] = useState<boolean>(true);
  const [allValidationArray, setAllValidationArray] = useState({
    minLenght: false,
    lowUp: false,
    number: false,
    specChar: false,
  });
  const [hiddenValidation, setHiddenValidation] = useState(true);

  useEffect(() => {
    if (newPassword) {
      const controlPassword: any = newPassword;
      setAllValidationArray({
        minLenght: controlPassword?.length >= 8,
        lowUp:
          controlPassword?.toUpperCase() !== controlPassword &&
          controlPassword?.toLowerCase() !== controlPassword,
        number: controlPassword?.match(/\d+/g),
        specChar: controlPassword?.match(
          /[ `!@#$%^&*()_+\-=[\]{};':"|,.<>\\/?~]/
        ),
      });
      const findPassword = errorList.findIndex((item) => item === "password");
      controlPassword.length > 1 &&
      controlPassword === repeatPassword &&
      allValidationArray
        ? setErrorList(errorList.filter((item) => item !== "password"))
        : findPassword < 0 && setErrorList([...errorList, "password"]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newPassword, repeatPassword]);
  //#endregion

  //#region security profile - fuction default
  let write: string[] = [];
  let read: string[] = [];

  useEffect(() => {
    if (
      editUser.securityProfile?.id !== undefined ||
      userInfo.securityProfile?.id !== undefined
    ) {
      store.dispatch(
        getPrivilegesDefaultAsync({
          id: editUser.securityProfile?.id ?? userInfo.securityProfile?.id,
        })
      );
    }
  }, [userInfo.securityProfile?.id, editUser.securityProfile?.id]);

  privilegesDefault.forEach((privilegeDefault) => {
    if (
      privilegeDefault.permission === "WRITE" &&
      permissions[
        privilegeDefault.entityType.toLowerCase() as keyof UserPermissions
      ]?.write
    ) {
      write.push(t("admin.user.editViewCreate." + privilegeDefault.entityType));
    } else if (
      permissions[
        privilegeDefault.entityType.toLowerCase() as keyof UserPermissions
      ]?.read
    ) {
      read.push(t("admin.user.editViewCreate." + privilegeDefault.entityType));
    }
  });
  //#endregion

  //#region Render
  const displayValidator = (condition: string) => {
    if (editUser.password === "") {
      return "default-constraint";
    } else {
      switch (condition) {
        case "minLenght":
          return allValidationArray.minLenght
            ? "success-constraint"
            : "error-constraint";
        case "lowUp":
          return allValidationArray.lowUp
            ? "success-constraint"
            : "error-constraint";
        case "number":
          return allValidationArray.number
            ? "success-constraint"
            : "error-constraint";
        case "specChar":
          return allValidationArray.specChar
            ? "success-constraint"
            : "error-constraint";
        case "password":
          return repeatPassword !== "" && newPassword === repeatPassword
            ? "success-constraint"
            : "error-constraint";
      }
    }
  };

  const translateSecurityProfiles = (securityProfile: string) => {
    switch (securityProfile) {
      case "FLEET_MANAGER":
        return t("admin.user.profile.fleet");
      case "MAINTENANCE_MANAGER":
        return t("admin.user.profile.maintenance");
      case "DRIVER":
        return t("admin.user.profile.driver");
      case "BASIC":
        return t("admin.user.profile.basic");
      default:
        return t("admin.user.profile.unknown");
    }
  };

  const handleSecurityProfileChange = (val: any) => {
    if (val[0].id) {
      const securityProfile: SecurityProfiles =
        securityProfiles.find(
          (securityProfile) => securityProfile.id === val[0].id
        ) ?? ({} as SecurityProfiles);
      setEditUser({
        ...editUser,
        securityProfile: {
          name: securityProfile.name,
          id: securityProfile.id,
        },
      });
    }
  };
  //#endregion

  //#region vehicles variables
  const [vehiclesCannotSee, setVehiclesCannotSee] = useState(
    [] as ResourceProps[]
  );

  let vehicles: Vehicle[] = useAppSelector((state) =>
    vehiclesSelectors.selectAll(state)
  );

  let vehiclesStatus: VehicleStatus[] = useAppSelector((state) =>
    vehiclesStatusSelectors.selectAll(state)
  );
  const vehiclesStatusSliceStatus = useAppSelector(
    selectVehiclesStatusSliceStatus
  );
  const vehiclesStatusSliceReasonCode = useAppSelector(
    selectVehiclesStatusSliceReasonCode
  );

  /**
   * This useEffect separates into two list (can see and cannot se) the downloaded vehicles
   * on the basis of the current editing user privileges.
   */
  useEffect(() => {
    if (
      vehicles.length > 0 &&
      vehiclesStatus.length > 0 &&
      !_.isEmpty(userInfo)
    ) {
      const vehiclesForPrivilegesTab = vehicles.map((vehicle) => {
        const vehicleStatus = vehiclesStatus.find((x) => x.id === vehicle.id);
        return {
          key: vehicle.id,
          id: vehicle.id,
          firstTitle: vehicle.alias,
          secondTitle: vehicle.plate,
          firstSubtitle: vehicle.status,
          secondSubtitle: getFullDate(
            vehicleStatus?.dynamicFields?.lastUpdate,
            false
          ),
          type: ElementType.vehicle,
          icon: getIllustrationFromVehicleType(vehicle),
        } as ResourceProps;
      });
      if (vehiclesForPrivilegesTab.length > 0 && permissions.vehicles.write) {
        const vehiclesPrivileges = userInfo.privileges
          .filter((x) => x.entityType === "Vehicles")
          .map((x) => x.entityId);
        const vehiclesCannotSeeTemp = vehiclesForPrivilegesTab.filter((el) => {
          return !vehiclesPrivileges.includes(el.id);
        });

        setVehiclesCannotSee(
          vehiclesCannotSeeTemp.length > 0
            ? vehiclesCannotSeeTemp
            : vehiclesForPrivilegesTab
        );

        let vehiclesCanSeeTemp = vehiclesForPrivilegesTab.filter((el) => {
          return userInfo.privileges.some(
            (privilege) =>
              privilege.entityType === "Vehicles" &&
              el.id === privilege.entityId
          );
        });

        setModifiedPrivilege((prevState) => ({
          ...prevState,
          vehicles: vehiclesCanSeeTemp.map(
            (associatedValue) => associatedValue.id
          ),
        }));

        setVehiclesCanSee(vehiclesCanSeeTemp);
      }
    }
  }, [vehicles, vehiclesStatus, userInfo]);
  //#endregion

  //#region drivers variables
  const [driversCannotSee, setDriversCannotSee] = useState(
    [] as ResourceProps[]
  );
  let drivers: Driver[] = useAppSelector((state) =>
    driversSelectors.selectAll(state)
  );

  let driversStatus: DriverStatus[] = useAppSelector((state) =>
    driversStatusSelectors.selectAll(state)
  );
  const driversStatusSliceStatus = useAppSelector(
    selectDriverStatusSliceStatus
  );
  const driversStatusSliceReasonCode = useAppSelector(
    selectDriverStatusSliceReasonCode
  );

  /**
   * This useEffect separates into two list (can see and cannot se) the downloaded drivers
   * on the basis of the current editing user privileges.
   */
  useEffect(() => {
    if (
      drivers.length > 0 &&
      driversStatus.length > 0 &&
      !_.isEmpty(userInfo)
    ) {
      const driversForPrivilegesTab = drivers.map((driver) => {
        const driverStatus = driversStatus.find((x) => x.id === driver.id);
        return {
          key: driver.id,
          id: driver.id,
          firstTitle: `${driver.firstName} ${driver.lastName}`,
          firstSubtitle: driver.status,
          secondSubtitle: getFullDate(
            driverStatus?.dynamicFields?.lastUpdate,
            false
          ),
          type: ElementType.driver,
          icon: (
            <ThumbProfile
              size="small"
              alt="Driver Thumbnail"
              imgUrl={driver.avatar}
            />
          ),
        } as ResourceProps;
      });
      if (driversForPrivilegesTab.length > 0 && permissions.drivers.write) {
        const driversPrivileges = userInfo.privileges
          .filter((x) => x.entityType === "Drivers")
          .map((x) => x.entityId);
        const driversCannotSeeTemp = driversForPrivilegesTab.filter((el) => {
          return !driversPrivileges.includes(el.id);
        });

        setDriversCannotSee(
          driversCannotSeeTemp.length > 0
            ? driversCannotSeeTemp
            : driversForPrivilegesTab
        );

        let driverCanSeeTemp = driversForPrivilegesTab.filter((el) => {
          return userInfo.privileges.some(
            (privilege) =>
              privilege.entityType === "Drivers" && el.id === privilege.entityId
          );
        });

        setModifiedPrivilege((prevState) => ({
          ...prevState,
          drivers: driverCanSeeTemp.map(
            (associatedValue) => associatedValue.id
          ),
        }));

        setDriversCanSee(driverCanSeeTemp);
      }
    }
  }, [drivers, driversStatus, userInfo]);
  //#endregion

  //#region geofences variables
  const [geofencesCannotSee, setGeofencesCannotSee] = useState(
    [] as ResourceProps[]
  );
  let geofences: Geofence[] = useAppSelector(geofencesSelectors.selectAll);
  let geofenceCategories: GeofenceCategory[] = useAppSelector(
    geofenceCategoriesSelectors.selectAll
  );
  const geofenceCategoriesSliceStatus = useAppSelector(
    selectGeofenceCategoriesSliceStatus
  );
  const geofenceCategoriesSliceReasonCode = useAppSelector(
    selectGeofenceCategoriesSliceReasonCode
  );

  const geofencesSliceStatus = useAppSelector(selectGeofencesSliceStatus);
  const geofencesSliceReasonCode = useAppSelector(
    selectGeofencesSliceReasonCode
  );

  /**
   * This useEffect separates into two list (can see and cannot se) the downloaded geofences
   * on the basis of the current editing user privileges.
   */
  useEffect(() => {
    if (
      geofences.length > 0 &&
      geofenceCategories.length > 0 &&
      !_.isEmpty(userInfo)
    ) {
      const geofencesForPrivilegesTab = geofences.map((geofence) => {
        const geofenceCategory = geofenceCategories.find(
          (x) => x.id === geofence.geofenceCategory
        );
        const resourceProp = {
          key: geofence.id,
          id: geofence.id,
          firstTitle: geofence.name,
          secondTitle: geofenceCategory?.name,
          type: ElementType.geofence,
          icon: getIllustrationFromVehicleType(geofence),
          categoryColor: geofenceCategory?.color,
        } as ResourceProps;
        return resourceProp;
      });
      if (geofencesForPrivilegesTab.length > 0 && permissions.geofences.write) {
        const geofencesPrivileges = userInfo.privileges
          .filter((x) => x.entityType === "Geofences")
          .map((x) => x.entityId);
        const geofencesCannotSeeTemp = geofencesForPrivilegesTab.filter(
          (el) => {
            return !geofencesPrivileges.includes(el.id);
          }
        );

        setGeofencesCannotSee(
          geofencesCannotSeeTemp.length > 0
            ? geofencesCannotSeeTemp
            : geofencesForPrivilegesTab
        );
        let geofencesCanSeeTemp = geofencesForPrivilegesTab.filter((el) => {
          return userInfo.privileges.some(
            (privilege) =>
              privilege.entityType === "Geofences" &&
              el.id === privilege.entityId
          );
        });
        setModifiedPrivilege((prevState) => ({
          ...prevState,
          geofences: geofencesCanSeeTemp.map(
            (associatedValue) => associatedValue.id
          ),
        }));
        setGeofencesCanSee(geofencesCanSeeTemp);
      }
    }
  }, [geofences, geofenceCategories, userInfo]);
  //#endregion

  /**
   * This useEffects downloads entities iff the current user has privileges to handle entities.
   */
  useEffect(() => {
    if (privilegesGroup) {
      if (
        privilegesDefault.some((x) => x.entityType === "Vehicles") &&
        permissions.vehicles.read
      ) {
        store.dispatch(getVehiclesAsync());
      }
      if (
        privilegesDefault.some((x) => x.entityType === "Drivers") &&
        permissions.drivers.read
      ) {
        store.dispatch(getFilteredDriversStatusAndDetailsAsync(""));
      }
      if (
        privilegesDefault.some((x) => x.entityType === "Geofences") &&
        permissions.geofences.read
      ) {
        store.dispatch(
          getGeofencesAsync({
            queryParams: getQueryString({ status: "ACTIVE" }),
          })
        );
        store.dispatch(getGeofenceCategoriesAsync());
      }
    }
  }, [privilegesDefault, privilegesGroup]);

  useEffect(() => {
    if (!privilegesGroup && userInfo.privileges?.length > 0) {
      setPrivilegesGroup(true);
    }
  }, [privilegesDefault]);

  //#region toast notifications
  useEffect(() => {
    if (
      userSliceStatus === "idle" &&
      userSliceReasonCode === GTFleetSuccessCodes.PATCH
    ) {
      ToastNotification({
        toastId: "userUpdateSuccess",
        status: "success",
        description: t("common.userUpdateSuccess"),
      });
      navigate("/admin/users/view/" + id);
    } else if (userSliceStatus === "failed") {
      switch (userSliceReasonCode) {
        case GTFleetErrorCodes.USER_ALREADY_EXISTS:
          ToastNotification({
            toastId: "usernameAlreadyExists",
            status: "error",
            description:
              editUser && editUser?.username + " " + t("common.alreadyExists"),
          });
          break;
        case GTFleetErrorCodes.USER_UNAUTHORIZED:
          ToastNotification({
            toastId: "unauthorizedError",
            status: "error",
            description: t("common.unauthorizedError"),
          });
          break;
        default:
          ToastNotification({
            toastId: "networkError",
            status: "error",
            description: t("common.networkError"),
          });
          break;
      }
      navigate("/admin/users");
    }
  }, [userSliceStatus, userSliceReasonCode, navigate]);

  useEffect(() => {
    if (
      privilegesSliceStatus === "failed" &&
      privilegesSliceReasonCode === ""
    ) {
      ToastNotification({
        toastId: "networkError",
        status: "error",
        description: t("common.networkError"),
      });
    }
  }, [privilegesSliceStatus, privilegesSliceReasonCode]);

  useEffect(() => {
    if (
      securityProfileSliceStatus === "failed" &&
      securityProfileSliceReasonCode === ""
    ) {
      ToastNotification({
        toastId: "networkError",
        status: "error",
        description: t("common.networkError"),
      });
    }
  }, [securityProfileSliceStatus, securityProfileSliceReasonCode, navigate]);

  useEffect(() => {
    if (
      driversStatusSliceStatus === "failed" &&
      driversStatusSliceReasonCode === ""
    ) {
      ToastNotification({
        toastId: "networkError",
        status: "error",
        description: t("common.networkError"),
      });
    }
  }, [driversStatusSliceStatus, driversStatusSliceReasonCode]);

  useEffect(() => {
    if (
      vehiclesStatusSliceStatus === "failed" &&
      vehiclesStatusSliceReasonCode === ""
    ) {
      ToastNotification({
        toastId: "networkError",
        status: "error",
        description: t("common.networkError"),
      });
    }
  }, [vehiclesStatusSliceStatus, vehiclesStatusSliceReasonCode]);

  useEffect(() => {
    if (geofenceCategoriesSliceStatus === "failed") {
      if (geofenceCategoriesSliceReasonCode === "") {
        ToastNotification({
          toastId: "networkError",
          status: "error",
          description: t("common.networkError"),
        });
      }
    }
  }, [geofenceCategoriesSliceStatus, geofenceCategoriesSliceReasonCode]);

  useEffect(() => {
    if (geofencesSliceStatus === "failed") {
      //gestire not found
      if (geofencesSliceReasonCode === "") {
        ToastNotification({
          toastId: "networkError",
          status: "error",
          description: t("common.networkError"),
        });
      }
    }
  }, [geofencesSliceStatus, geofencesSliceReasonCode]);
  //#endregion toast notifications

  const somethingHasChanged =
    Object.values(editUser).length > 0 || privilegesHasChanged;

  const vehiclesPrivilegesCompliant = privilegesDefault.some(
    (privilegeDefault) =>
      privilegeDefault.entityType === "Vehicles" &&
      privilegeDefault.permission === "WRITE"
  )
    ? vehiclesCanSee.length > 0
    : true;
  const driversPrivilegesCompliant = privilegesDefault.some(
    (privilegeDefault) =>
      privilegeDefault.entityType === "Drivers" &&
      privilegeDefault.permission === "WRITE"
  )
    ? driversCanSee.length > 0
    : true;
  const geofencesPrivilegesCompliant = privilegesDefault.some(
    (privilegeDefault) =>
      privilegeDefault.entityType === "Geofences" &&
      privilegeDefault.permission === "WRITE"
  )
    ? geofencesCanSee.length > 0
    : true;

  const privilegesCompliant = privilegesGroup
    ? vehiclesPrivilegesCompliant &&
      driversPrivilegesCompliant &&
      geofencesPrivilegesCompliant
    : true;
  const disableButton =
    !somethingHasChanged || errorList.length > 0 || !privilegesCompliant;

  return (
    <>
      <PageFilters>
        <div className="col col-16">
          <div className="drvr-subtopbar">
            <div className="drvr-subsection-name">
              <div className="drvr-no-avatar">
                {t("admin.user.editViewCreate.editUser")}
              </div>
            </div>
            <div className="button-save-group drvr-buttons">
              <Button
                size="small"
                aspect="secondary"
                label={t("common.cancel")}
                onClick={() => navigate(-1)}
              />
              <Button
                size="small"
                aspect="primary"
                label={t("common.save")}
                disabled={disableButton}
                onClick={() => {
                  const privilegesBody: Privileges[] = [];
                  Object.entries(modifiedPrivilege).forEach((privileges) => {
                    const [key, value] = privileges;
                    privilegesBody.push(
                      ...value.map((val) => {
                        return {
                          entityId: val,
                          entityType:
                            key.charAt(0).toUpperCase() + key.slice(1),
                          permission: "READ",
                        };
                      })
                    );
                    privilegesBody.push(
                      ...value.map((val) => {
                        return {
                          entityId: val,
                          entityType:
                            key.charAt(0).toUpperCase() + key.slice(1),
                          permission: "WRITE",
                        };
                      })
                    );
                  });
                  privilegesBody.length > 0 &&
                    id !== -1 &&
                    store.dispatch(
                      updateUserInfoAsync({
                        id: id,
                        userInfo: {
                          ...editUser,
                          privileges: privilegesBody,
                        },
                      })
                    );
                }}
              ></Button>
            </div>
          </div>
        </div>
      </PageFilters>
      <PageContent>
        <div className="ed-form-container">
          {
            //#region General
          }
          <div className="drvr-info-form">
            <div className="drvr-forms-title">
              {t("admin.driver.editViewCreate.general")}
            </div>
            <div className="drvr-forms-container">
              <div className="drvr-half-form">
                <div className="drvr-forms-label">{t("common.name")} *</div>
                <Form>
                  <div className="drvr-forms">
                    <div className="drvr-left-form-name ">
                      <TextField
                        id={t("common.name")}
                        validate="alphabetic|isNotEmpty"
                        placeholder={
                          editUser.firstName || userInfo.firstName
                            ? t("common.name")
                            : t("common.na")
                        }
                        value={editUser.firstName ?? userInfo.firstName}
                        errorList={setErrorList}
                        events={{
                          onChange: (data) =>
                            setEditUser({
                              ...editUser,
                              firstName: data.value,
                            }),
                        }}
                      ></TextField>
                    </div>
                  </div>
                </Form>
              </div>
              <div className="drvr-half-form">
                <div className="drvr-right-forms-label">
                  {t("common.surname")} *
                </div>
                <Form>
                  <div className="drvr-forms">
                    <div className="drvr-right-form-item ">
                      <TextField
                        id={t("common.surname")}
                        validate="alphabetic|isNotEmpty"
                        placeholder={
                          editUser.secondName || userInfo.secondName
                            ? t("common.surname")
                            : t("common.na")
                        }
                        value={editUser.secondName ?? userInfo.secondName}
                        errorList={setErrorList}
                        events={{
                          onChange: (data) =>
                            setEditUser({
                              ...editUser,
                              secondName: data.value,
                            }),
                        }}
                      ></TextField>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
            <div className="drvr-forms-container">
              <div className="drvr-forms-label">{t("common.username")} *</div>
              <Form>
                <div className="drvr-forms">
                  <div className="left-form ">
                    <TextField
                      id={t("common.username")}
                      placeholder={
                        editUser.username || userInfo.username
                          ? t("common.username")
                          : t("common.na")
                      }
                      validate="isNotEmpty"
                      value={editUser.username ?? userInfo.username}
                      errorList={setErrorList}
                      events={{
                        onChange: (data) =>
                          setEditUser({
                            ...editUser,
                            username: data.value,
                          }),
                      }}
                    ></TextField>
                  </div>
                </div>
              </Form>
            </div>
            <div className="drvr-forms-container">
              <div className="drvr-forms-label">{t("common.email")} *</div>
              <Form>
                <div className="drvr-forms">
                  <div className="drvr-left-form ">
                    <TextField
                      id={t("common.email")}
                      validate="email|isNotEmpty"
                      type="email"
                      placeholder={
                        editUser.email || userInfo.email
                          ? t("common.email")
                          : t("common.na")
                      }
                      value={editUser.email ?? userInfo.email}
                      errorList={setErrorList}
                      events={{
                        onChange: (data) =>
                          setEditUser({
                            ...editUser,
                            email: data.value,
                          }),
                      }}
                    ></TextField>
                  </div>
                </div>
              </Form>
            </div>
            <div className="drvr-forms-container">
              <div className="drvr-forms-label">{t("common.phone")}</div>
              <Form>
                <div className="drvr-forms">
                  <div className="user-left-form-phone">
                    <div className="drvr-drpdwn-small ">
                      <Dropdown
                        isClearable={false}
                        placeholder={
                          editUser.prefix ?? userInfo.prefix
                            ? t("common.phone")
                            : t("common.na")
                        }
                        value={{
                          label: editUser.prefix ?? userInfo.prefix,
                        }}
                        size="normal"
                        itemAttribute="label"
                        onChange={(val) => {
                          ((!editUser.prefix &&
                            val[0].label !== userInfo.prefix) ||
                            (editUser.prefix &&
                              val[0].label !== editUser.prefix)) &&
                            setEditUser({
                              ...editUser,
                              prefix: val[0].label,
                            });
                        }}
                        options={prefixList}
                      />
                    </div>
                  </div>
                  <div className="user-right-form-phone ">
                    <TextField
                      id={t("common.phone")}
                      validate="numeric"
                      placeholder={
                        editUser.telephone || userInfo.telephone
                          ? t("common.phone")
                          : t("common.na")
                      }
                      errorList={setErrorList}
                      value={editUser.telephone ?? userInfo.telephone}
                      events={{
                        onChange: (data) =>
                          setEditUser({
                            ...editUser,
                            telephone: data.value,
                          }),
                      }}
                      maxLength="10"
                      minLength="9"
                    ></TextField>
                  </div>
                </div>
              </Form>
            </div>
          </div>
          {
            //#endregion
          }

          {
            //#region Security
          }
          <div className="drvr-info-form">
            <div className="drvr-forms-title">
              {t("admin.user.editViewCreate.security")}
            </div>
            <div className="drvr-forms-container">
              <div className="dvr-half-permission">
                <div className="drvr-forms-label">{t("common.password")} *</div>
              </div>
              <div className="drvr-half-form drvr-half-form-permission">
                <Form className="form-permission">
                  <div className="drvr-forms drvr-forms-permission">
                    <div className="drvr-left-form-name ">
                      <TextField
                        id="Password"
                        placeholder={t(
                          "account.settings.changePassword.newPassword"
                        )}
                        value={newPassword ?? null}
                        errorList={(errors: string[]) => {
                          setErrorList(errors);
                        }}
                        events={{
                          onChange: (data) => {
                            setNewPassword(data.value);
                            setEditUser({
                              ...editUser,
                              password: data.value,
                            });
                          },
                          onFocus: () => {
                            setHiddenValidation(false);
                          },
                        }}
                        type={viewNewPassword ? "password" : "text"}
                      >
                        {viewNewPassword ? (
                          <IconEyeSlash
                            onClick={() => setViewNewPassword(false)}
                            color={"--global-colors-ink-light"}
                            size={14}
                          />
                        ) : (
                          <IconEye
                            onClick={() => setViewNewPassword(true)}
                            color={"--global-colors-ink-light"}
                            size={14}
                          />
                        )}
                      </TextField>
                    </div>
                  </div>
                </Form>
                <Form className="form-permission">
                  <div className="drvr-forms drvr-forms-permission">
                    <div className="drvr-right-form-item ">
                      <TextField
                        id="RepeatPassowrd"
                        placeholder={t(
                          "account.settings.changePassword.confirmPassword"
                        )}
                        value={repeatPassword ?? null}
                        errorList={(errors: string[]) => {
                          setErrorList(errors);
                        }}
                        events={{
                          onChange: (data) => setRepeatPassword(data.value),
                        }}
                        type={viewRepeatPassword ? "password" : "text"}
                      >
                        {viewRepeatPassword ? (
                          <IconEyeSlash
                            onClick={() => setViewRepeatPassword(false)}
                            color={"--global-colors-ink-light"}
                            size={14}
                          />
                        ) : (
                          <IconEye
                            onClick={() => setViewRepeatPassword(true)}
                            color={"--global-colors-ink-light"}
                            size={14}
                          />
                        )}
                      </TextField>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
            <div
              className={`constraint-container ${"invalidPassword"}`}
              style={{
                display: hiddenValidation ? "none" : "block",
              }}
            >
              <div className="constraint-title">
                {t("customModals.verifyCode.passwordConstraintTitle")}
              </div>
              <div className="main-check">
                <div
                  className={
                    editUser.password === ""
                      ? "hide-constraint-check"
                      : "constraint-check"
                  }
                >
                  {allValidationArray.minLenght ? (
                    <Check status="success" size="xsmall" />
                  ) : (
                    <Check status="failed" size="xsmall" />
                  )}
                </div>
                <div className={displayValidator("minLenght")}>
                  {t("customModals.verifyCode.passwordConstraintLength")}
                </div>
              </div>
              <div className="main-check">
                <div
                  className={
                    editUser.password === ""
                      ? "hide-constraint-check"
                      : "constraint-check"
                  }
                >
                  {allValidationArray.lowUp ? (
                    <Check status="success" size="xsmall" />
                  ) : (
                    <Check status="failed" size="xsmall" />
                  )}
                </div>
                <div className={displayValidator("lowUp")}>
                  {t("customModals.verifyCode.passwordConstraintLowUp")}
                </div>
              </div>
              <div className="main-check">
                <div
                  className={
                    editUser.password === ""
                      ? "hide-constraint-check"
                      : "constraint-check"
                  }
                >
                  {allValidationArray.number ? (
                    <Check status="success" size="xsmall" />
                  ) : (
                    <Check status="failed" size="xsmall" />
                  )}
                </div>
                <div className={displayValidator("number")}>
                  {t("customModals.verifyCode.passwordConstraintNumber")}
                </div>
              </div>
              <div className="main-check">
                <div
                  className={
                    editUser.password === ""
                      ? "hide-constraint-check"
                      : "constraint-check"
                  }
                >
                  {allValidationArray.specChar ? (
                    <Check status="success" size="xsmall" />
                  ) : (
                    <Check status="failed" size="xsmall" />
                  )}
                </div>
                <div className={displayValidator("specChar")}>
                  {t("customModals.verifyCode.passwordConstraintChar")}
                </div>
              </div>
              <div className="main-check main-check-right">
                <div
                  className={
                    editUser.password === ""
                      ? "hide-constraint-check"
                      : "constraint-check"
                  }
                >
                  {repeatPassword !== "" && newPassword === repeatPassword ? (
                    <Check status="success" size="xsmall" />
                  ) : (
                    <Check status="failed" size="xsmall" />
                  )}
                </div>
                <div className={displayValidator("password")}>
                  {t("customModals.verifyCode.equalPasswords")}
                </div>
              </div>
            </div>
            <div className="drvr-forms-container">
              <Tooltip />
              <span
                data-for="tooltip"
                data-tip={t("common.warningActivationDate")}
              >
                <div className="drvr-forms-label">
                  {t("admin.user.editViewCreate.activationDate")}
                </div>
              </span>
              <Form>
                <div className="drvr-forms">
                  <div className="drvr-left-form-name ">
                    <SingleDatePicker
                      id="ActivationDate"
                      setErrorList={setErrorList}
                      errorList={errorList}
                      dateRange={[dayjs(), dayjs().add(5, "year")]}
                      oldDate={
                        editUser.activationDate
                          ? editUser.activationDate.toString()
                          : userInfo.activationDate?.toString()
                      }
                      setDate={(data) => {
                        setEditUser({
                          ...editUser,
                          activationDate: data,
                        });
                      }}
                      placeholder={`${t(
                        "admin.user.editViewCreate.activationDate"
                      )}`}
                      localeFormat={
                        preferencesContext.localeFormat ?? "DD / MM / YYYY"
                      }
                      language={preferencesContext.language ?? "it"}
                    />
                  </div>
                </div>
              </Form>
            </div>
            <div className="drvr-forms-container">
              <div className="drvr-forms-label">
                {t("admin.user.editViewCreate.expirationDate")}
              </div>
              <Form>
                <div className="drvr-forms">
                  <div className="drvr-left-form-name ">
                    <SingleDatePicker
                      id="ExpirationDate"
                      setErrorList={setErrorList}
                      errorList={errorList}
                      dateRange={[dayjs(), dayjs().add(10, "year")]}
                      oldDate={
                        editUser.expirationDate
                          ? editUser.expirationDate.toString()
                          : userInfo.expirationDate?.toString()
                      }
                      setDate={(data) => {
                        setEditUser({
                          ...editUser,
                          expirationDate: data,
                        });
                      }}
                      placeholder={
                        editUser.expirationDate
                          ? ""
                          : `${t("admin.user.editViewCreate.never")}`
                      }
                      localeFormat={
                        preferencesContext.localeFormat ?? "DD / MM / YYYY"
                      }
                      language={preferencesContext.language ?? "it"}
                      notExpirationDate={(_val) => {
                        setEditUser({
                          ...editUser,
                          expirationDate: null,
                        });
                      }}
                    />
                  </div>
                </div>
              </Form>
            </div>
          </div>
          {
            //#endregion
          }

          {
            //#region Profile and privileges
          }
          <div className="drvr-info-form">
            <div className="drvr-forms-title">
              {t("admin.user.editViewCreate.profile")}
            </div>

            <div className="drvr-forms-container">
              <div className="drvr-forms-label">
                {t("admin.user.editViewCreate.role")}
              </div>
              <Form>
                <div className="drvr-forms">
                  <div className="drvr-left-form ">
                    <Dropdown
                      isClearable={false}
                      placeholder={
                        editUser.securityProfile?.name ||
                        userInfo.securityProfile?.name
                          ? t("admin.user.editViewCreate.role")
                          : t("common.na")
                      }
                      value={{
                        name: translateSecurityProfiles(
                          editUser.securityProfile?.name ??
                            userInfo.securityProfile?.name
                        ),
                      }}
                      size="normal"
                      itemAttribute="name"
                      onChange={(val) => {
                        setPrivilegesGroup(false);
                        handleSecurityProfileChange(val);
                      }}
                      options={securityProfiles.map((securityProfile) => {
                        return {
                          ...securityProfile,
                          name: translateSecurityProfiles(securityProfile.name),
                        };
                      })}
                    />
                  </div>
                </div>
              </Form>
            </div>
            <div className="drvr-forms-container drvr-forms-function">
              <div className="drvr-forms-label">
                {t("admin.user.editViewCreate.functions")}
              </div>
              <Form className="form-function">
                <div className="drvr-forms">
                  <div className="drvr-left-form drvr-user">
                    {write.length > 0 && (
                      <div className="drvr-onblock">
                        <div
                          className="mn-label-text__text mn-label-text__text-function"
                          key={"write"}
                        >
                          {t("admin.user.editViewCreate.read&Write")}
                        </div>
                        <div className="drvr-user-fuction drvr-user-fuction-first">
                          {write.map((privilege) => {
                            return (
                              <Tag
                                key={"write_" + privilege}
                                text={privilege}
                                mode="outline"
                                type="neutro"
                              />
                            );
                          })}
                        </div>
                      </div>
                    )}
                    {read.length > 0 && (
                      <div className="drvr-onblock">
                        <div
                          className="mn-label-text__text mn-label-text__text-function"
                          key={"read"}
                        >
                          {t("admin.user.editViewCreate.read")}
                        </div>
                        <div className="drvr-user-fuction drvr-user-fuction-second">
                          {read.map((privilege) => {
                            return (
                              <Tag
                                key={"read_" + privilege}
                                text={privilege}
                                mode="outline"
                                type="neutro"
                              />
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </Form>
            </div>
            {privilegesDefault.length > 0 && (
              <div className="drvr-forms-container drvr-forms-function">
                <div className="drvr-forms-label">
                  {t("admin.user.editViewCreate.managePermissions")}
                </div>
                <Form className="form-function">
                  <div className="drvr-forms">
                    <div className="drvr-left-form drvr-user">
                      <Switch
                        checked={privilegesGroup}
                        onChange={() => setPrivilegesGroup(!privilegesGroup)}
                        label=""
                      />
                    </div>
                  </div>
                </Form>
              </div>
            )}
            {privilegesGroup && (
              <div className="privileges-tabs-container">
                <Tabs>
                  {privilegesDefault.some(
                    (privilegeDefault) =>
                      privilegeDefault.entityType === "Vehicles"
                  ) && permissions.vehicles.read ? (
                    <TabSection label="Vehicles">
                      <AdminPrivilegesResourceTab
                        tabType="vehicles"
                        disabled={!permissions.vehicles.write}
                        customTitleFirstGroup={t(
                          "admin.user.editViewCreate.customTitleFirstGroup"
                        ).replace("$$", editUser.username ?? userInfo.username)}
                        customTitleSecondGroup={t(
                          "admin.user.editViewCreate.customTitleSecondGroup"
                        ).replace("$$", editUser.username ?? userInfo.username)}
                        leftButtonText={t(
                          "admin.groups.editGroups.widgetButtonLabelRemove"
                        )}
                        rightButtonText={t(
                          "admin.groups.editGroups.widgetButtonLabelAdd"
                        )}
                        rightGroupProp={vehiclesCannotSee}
                        leftGroupProp={vehiclesCanSee}
                        output={(
                          leftResources: ResourceProps[],
                          rightResources: ResourceProps[]
                        ) => {
                          setPrivilegesHasChanged(true);
                          // Updating setModifiedPrivilege with selected elements
                          setModifiedPrivilege((prevState) => ({
                            ...prevState,
                            vehicles: rightResources.map(
                              (resource) => resource.id
                            ),
                          }));
                          setVehiclesCanSee(rightResources);
                          setVehiclesCannotSee(leftResources);
                        }}
                      />
                    </TabSection>
                  ) : null}
                  {privilegesDefault.some(
                    (privilegeDefault) =>
                      privilegeDefault.entityType === "Drivers"
                  ) && permissions.drivers.read ? (
                    <TabSection label="Drivers">
                      <AdminPrivilegesResourceTab
                        tabType="drivers"
                        disabled={!permissions.drivers.write}
                        customTitleFirstGroup={t(
                          "admin.user.editViewCreate.customTitleFirstGroup"
                        ).replace("$$", editUser.username ?? userInfo.username)}
                        customTitleSecondGroup={t(
                          "admin.user.editViewCreate.customTitleSecondGroup"
                        ).replace("$$", editUser.username ?? userInfo.username)}
                        leftButtonText={t(
                          "admin.groups.editGroups.widgetButtonLabelRemove"
                        )}
                        rightButtonText={t(
                          "admin.groups.editGroups.widgetButtonLabelAdd"
                        )}
                        rightGroupProp={driversCannotSee}
                        leftGroupProp={driversCanSee}
                        output={(
                          leftResources: ResourceProps[],
                          rightResources: ResourceProps[]
                        ) => {
                          setPrivilegesHasChanged(true);
                          // Updating setModifiedPrivilege with selected elements
                          setModifiedPrivilege((prevState) => ({
                            ...prevState,
                            drivers: rightResources.map(
                              (resource) => resource.id
                            ),
                          }));
                          setDriversCanSee(rightResources);
                          setDriversCannotSee(leftResources);
                        }}
                      />
                    </TabSection>
                  ) : null}
                  {privilegesDefault.some(
                    (privilegeDefault) =>
                      privilegeDefault.entityType === "Geofences"
                  ) && permissions.geofences.read ? (
                    <TabSection label="Geofences">
                      <AdminPrivilegesResourceTab
                        tabType="geofences"
                        disabled={!permissions.geofences.write}
                        customTitleFirstGroup={t(
                          "admin.user.editViewCreate.customTitleFirstGroup"
                        ).replace("$$", editUser.username ?? userInfo.username)}
                        customTitleSecondGroup={t(
                          "admin.user.editViewCreate.customTitleSecondGroup"
                        ).replace("$$", editUser.username ?? userInfo.username)}
                        leftButtonText={t(
                          "admin.groups.editGroups.widgetButtonLabelRemove"
                        )}
                        rightButtonText={t(
                          "admin.groups.editGroups.widgetButtonLabelAdd"
                        )}
                        rightGroupProp={geofencesCannotSee}
                        leftGroupProp={geofencesCanSee}
                        output={(
                          leftResources: ResourceProps[],
                          rightResources: ResourceProps[]
                        ) => {
                          setPrivilegesHasChanged(true);
                          // Updating setModifiedPrivilege with selected elements
                          setModifiedPrivilege((prevState) => ({
                            ...prevState,
                            geofences: rightResources.map(
                              (resource) => resource.id
                            ),
                          }));
                          setGeofencesCanSee(rightResources);
                          setGeofencesCannotSee(leftResources);
                        }}
                      />
                    </TabSection>
                  ) : null}
                </Tabs>
              </div>
            )}
          </div>
          {
            //#endregion
          }
        </div>
      </PageContent>
    </>
  );
};
export default EditUser;
