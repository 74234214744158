import React, { useState, useEffect } from "react";
import "./Slider.css";
import "./SearchActionMenu.css";

export const Slider = ({
  data,
  label,
  valueLabel,
  val,
  onChange,
  disabled,
}) => {
  const [value, setValue] = useState(0);
  const [backgroundStyle, setBackgroundStyle] = useState({
    backgroundSize: "0% 100%",
  });

  useEffect(() => {
    setValue(val);
    setBackgroundStyle({ backgroundSize: val * (100 / data.max) + "% 100%" });
  }, [val]);

  return (
    <div>
      {label && <div className="slider-radius-label">{label}</div>}
      <div className="slider-container">
        <div className="value-label-container">
          <div className="value-label">{value}</div>
          <div className="value-label">{valueLabel}</div>
        </div>
        <div className="slider">
          <input
            type="range"
            min={data.min}
            max={data.max}
            value={value}
            style={backgroundStyle}
            disabled={disabled}
            onChange={(e) => {
              onChange(e.target.value);
              setBackgroundStyle({
                backgroundSize: e.target.value * (100 / data.max) + "% 100%",
              });
            }}
          />
        </div>
      </div>
    </div>
  );
};

Slider.propTypes = {};
Slider.defaultProps = {};
