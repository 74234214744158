import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import { GTFleetSuccessCodes } from "../../../config/GTFleetSuccessCodes";
import { getGeofencesReportsAsync } from "./GeofencesReportsSlice";

export type ReportGeofenceSummary = {
  id: number;
  vehicles: number;
  geofences: number;
  inEvents: number;
  outEvents: number;
  stopDuration: number;
  trackDuration: number;
};

const reportsGeofenceSummaryAdapter =
  createEntityAdapter<ReportGeofenceSummary>({
    selectId: (reportsGeofenceSummary) => reportsGeofenceSummary.id,
  });

export const reportsGeofenceSummarySlice = createSlice({
  name: "reportsGeofenceSummary",
  initialState: reportsGeofenceSummaryAdapter.getInitialState({
    status: "idle",
    reasonCode: "",
  }),
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    reportsGeofenceSummaryEmptyState: (state) => {
      reportsGeofenceSummaryAdapter.setAll(state, []);
      state.reasonCode = "";
      state.status = "idle";
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      // reportsGeofenceSummaryAdapter extra reducers
      .addCase(
        getGeofencesReportsAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          action.payload.reportsGeofenceSummary &&
            reportsGeofenceSummaryAdapter.setAll(
              state,
              action.payload.reportsGeofenceSummary
            );
        }
      );
  },
});

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const reportGeofenceSummarySelectors =
  reportsGeofenceSummaryAdapter.getSelectors<RootState>(
    (state) => state.reportsGeofenceSummary
  );

export const { reportsGeofenceSummaryEmptyState } =
  reportsGeofenceSummarySlice.actions;

export default reportsGeofenceSummarySlice.reducer;
