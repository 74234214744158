import { t } from "i18next";
import { useEffect, useRef, useState } from "react";
import {
  Location,
  useLocation,
  useMatch,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { store } from "../../app/store";
import { GTFleetSuccessCodes } from "../../config/GTFleetSuccessCodes";
import { GTFleetErrorCodes } from "../../config/GTfleetErrorCodes";
import PageContent from "../../layout/PageContent";
import PageFilters from "../../layout/PageFilters";
import { Button } from "../../ui/Button/Button";
import { IconAdd } from "../../ui/Icon/Line/Add";
import { IconGroup } from "../../ui/Icon/Line/Group";
import { IconTitle } from "../../ui/IconTitle/IconTitle";
import { AddGroupModal } from "../../ui/Modal/CustomModals/AddGroupModal";
import { PageCounter } from "../../ui/Table/PageCounter";
import { Table } from "../../ui/Table/Table";
import { ToastNotification } from "../../utils/ToastNotification";
import { getQueryString } from "../../utils/Utils";
import {
  Preset,
  getPresetsAsync,
  presetsSelectors,
  selectpresetsSliceStatus,
} from "../users/preset/presetsSlice";
import { UserPermissions } from "../users/privilege/privilegesSlice";
import { userInfoSelectors } from "../users/usersInfoSlice";
import { AdminFleetTableBuilder } from "./AdminFleetTableBuilder";
import "./AdminFleets.css";
import { AdminFleetsFilterBar } from "./AdminFleetsFilterBar";
import {
  FleetView,
  fleetViewsSelectors,
  getFilteredFleetsAsync,
  selectFleetViewsSliceReasonCode,
  selectFleetViewsSliceStatus,
  selectFleetsSlicePage,
  selectFleetsSliceTotalElements,
} from "./fleetViewsSlice";
import {
  Fleet,
  createFleetAsync,
  deleteFleetAsync,
  fleetsEmptyState,
  fleetsSelectors,
  getFleetAsync,
  selectFleetsSliceReasonCode,
  selectFleetsSliceStatus,
} from "./fleetsSlice";
import DeleteFleetModal from "./modals/DeleteFleetModal";

interface AdminFleetsProps {
  permissions: UserPermissions;
}

const context = "fleetsAdmin";

interface TableData {
  columns: { label: string; field: string; sort: boolean }[] | undefined;
  rows: { [key: string]: any };
}

const tableData: TableData = {} as TableData;

export const AdminFleets: React.FC<AdminFleetsProps> = ({ permissions }) => {
  //#region Initial variables
  const navigate = useNavigate();
  const tableBuilderRef = useRef<AdminFleetTableBuilder>(
    new AdminFleetTableBuilder()
  );
  let tableBuilder = tableBuilderRef.current;

  const [queryParamsFromFilterBar, setQueryParamsFromFilterBar] =
    useState<string>("");
  const presetsSliceStatus = useAppSelector(selectpresetsSliceStatus);
  let presets: Preset[] = useAppSelector(presetsSelectors.selectAll);

  const [searchParams] = useSearchParams();
  const [queryParamsChanged, setQueryParamsChanged] = useState<boolean>(false);
  const noQueryParamsFromFilterBar =
    (searchParams.toString().split("&").length === 1 &&
      !!searchParams.get("size")) ||
    searchParams.toString() === "";
  const [resetPage, setResetPage] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState(true);
  const [openAddFleetModal, setOpenAddFleetModal] = useState(false);
  const [openDeleteFleetModal, setOpenDeleteFleetModal] = useState(false);
  const [newFleet, setNewFleet] = useState({} as Partial<Fleet>);
  //#region Route
  const location: Location = useLocation();
  const fleetsRoute = useMatch("/admin/fleets/:action");
  const fleetRouteIdWithActionOnFleet = useMatch(
    "/admin/fleets/:action/:fleetId"
  );
  //#endregion Route

  //#region status slices
  const fleetViews: FleetView[] = useAppSelector(fleetViewsSelectors.selectAll);
  const creators = userInfoSelectors.selectAll(store.getState());
  const fleets: Fleet[] = useAppSelector(fleetsSelectors.selectAll);
  const fleetViewsSliceStatus = useAppSelector(selectFleetViewsSliceStatus);
  const fleetViewsSliceReasonCode = useAppSelector(
    selectFleetViewsSliceReasonCode
  );
  const [selectedFleetId, setSelectedFleetId] = useState(-1);
  const selectedFleet: FleetView =
    fleetViewsSelectors.selectById(store.getState(), selectedFleetId) ??
    ({} as FleetView);
  const fleetViewsSlicePage = useAppSelector(selectFleetsSlicePage);
  const fleetViewsSliceTotalElements = useAppSelector(
    selectFleetsSliceTotalElements
  );
  const fleetsSliceStatus = useAppSelector(selectFleetsSliceStatus);
  const fleetsSliceReasonCode = useAppSelector(selectFleetsSliceReasonCode);
  const isFleetsIdle = fleetViewsSliceStatus === "idle";
  const inLoading = fleetViewsSliceStatus === "loading";
  const IsfleetsStatusIdle = fleetsSliceStatus === "idle";
  //#endregion status slices

  //#region filterBar query params
  useEffect(() => {
    if (!!queryParamsFromFilterBar) {
      setQueryParamsChanged(true);
    }
  }, [queryParamsFromFilterBar]);

  useEffect(() => {
    if (queryParamsChanged && isFleetsIdle) {
      setQueryParamsChanged(false);
      setResetPage(true);
    } else {
      setResetPage(false);
    }
  }, [isFleetsIdle, queryParamsChanged]);
  //#endregion filterBar query params

  //#region route

  useEffect(() => {
    if (fleetRouteIdWithActionOnFleet !== null) {
      if (fleetRouteIdWithActionOnFleet?.params?.fleetId) {
        if (fleetRouteIdWithActionOnFleet.params.action === "delete") {
          store.dispatch(
            getFleetAsync(
              parseInt(fleetRouteIdWithActionOnFleet.params.fleetId)
            )
          );
          setSelectedFleetId(
            Number(fleetRouteIdWithActionOnFleet.params.fleetId)
          );
          setOpenDeleteFleetModal(true);
        }
      }
    }
    if (fleetsRoute) {
      if (fleetsRoute.params.action === "add-fleet") {
        setOpenAddFleetModal(true);
      }
    }
  }, [navigate, location]);
  //#endregion route

  //#region toast notifications
  useEffect(() => {
    if (fleetsSliceStatus === "idle") {
      switch (fleetsSliceReasonCode) {
        case GTFleetSuccessCodes.POST:
          ToastNotification({
            toastId: "fleetCreateSuccess",
            status: "success",
            description: t("admin.groups.success.POST"),
          });
          break;
        case GTFleetSuccessCodes.DELETE:
          ToastNotification({
            toastId: "fleetDeleteSuccess",
            status: "success",
            description: t("admin.groups.success.DELETE"),
          });
          break;
        case GTFleetSuccessCodes.PATCH:
          ToastNotification({
            toastId: "fleetPatchSuccess",
            status: "success",
            description: t("admin.groups.success.PATCH"),
          });
          break;
      }
    }

    if (fleetsSliceStatus === "failed") {
      switch (fleetsSliceReasonCode) {
        case GTFleetErrorCodes.FLEET_NOT_FOUND:
          ToastNotification({
            toastId: "fleetNotFoundError",
            status: "error",
            description: t("admin.groups.error.FLEET_NOT_FOUND"),
          });
          break;
        case GTFleetErrorCodes.FLEET_ALREADY_EXISTS:
          ToastNotification({
            toastId: "fleetExistsError",
            status: "error",
            description: t("admin.groups.error.FLEET_ALREADY_EXISTS"),
          });
          break;
        case GTFleetErrorCodes.FLEET_BAD_REQUEST:
          ToastNotification({
            toastId: "fleetBadRequest",
            status: "error",
            description: t("admin.groups.error.FLEET_BAD_REQUEST"),
          });
          break;
        case GTFleetErrorCodes.FLEET_DEFAULT_NOT_FOUND:
          ToastNotification({
            toastId: "defaultFleetError",
            status: "error",
            description: t("admin.groups.error.FLEET_DEFAULT_NOT_FOUND"),
          });
          break;
        case GTFleetErrorCodes.INTERNAL_SERVER_ERROR:
        case "":
          ToastNotification({
            toastId: "networkError",
            status: "error",
            description: t("common.networkError"),
          });
          break;
      }
    }
  }, [fleetsSliceStatus, fleetsSliceReasonCode]);

  useEffect(() => {
    if (
      fleetViewsSliceStatus === "failed" &&
      fleetViewsSliceReasonCode === ""
    ) {
      ToastNotification({
        toastId: "networkError",
        status: "error",
        description: t("common.networkError"),
      });
      navigate(-1);
    }
  }, [fleetViewsSliceStatus, fleetViewsSliceReasonCode, navigate]);
  //#endregion toast notifications

  //#region Table
  useEffect(() => {
    document.title = t("navigation.adminMenu.groups") + " - Admin";
    /**
     * When the component is unmountend the related redux state should be cleaned.
     */
    store.dispatch(getPresetsAsync(getQueryString({ context: context })));

    return function cleanUp() {
      store.dispatch(fleetsEmptyState());
    };
  }, []);

  if (presetsSliceStatus === "idle" && presets.length > 0) {
    let preset =
      presets.find((element) => element.name === undefined) ??
      presets.find((element) => element.lastSelected === true);
    if (!preset) {
      preset = presets.find(
        (element) => element.name === "Default" && element.context === context
      );
    }

    tableData.columns = tableBuilder.setColumns(
      preset?.columns
        ? preset?.columns.map((element) => {
            return {
              name: element,
              sort: element === "name" ? true : false,
            };
          })
        : []
    );

    if (
      fleetViewsSliceStatus === "idle" &&
      creators.length > 0 &&
      tableData.columns &&
      tableData?.columns?.length > 0
    ) {
      tableData.rows = fleetViews.map((fleet: FleetView) => {
        return tableBuilder.rowsBuilder(
          tableData.columns,
          {
            fleetView: fleet,
            creators,
          },
          navigate,
          permissions.fleets.write
        );
      });
    } else if (!fleetViews.length) {
      tableData.rows = [];
    }
  }

  useEffect(() => {
    if (
      fleetsSliceStatus === "idle" &&
      fleetsSliceReasonCode === GTFleetSuccessCodes.POST
    ) {
      if (newFleet && newFleet?.name && IsfleetsStatusIdle) {
        let fleet = fleets.find((item) => item.name === newFleet.name);
        if (fleet) {
          setOpenAddFleetModal(false);
          navigate(`/admin/fleets/edit-fleet/${fleet?.id}`);
          setNewFleet({} as Fleet);
        }
      }
    }
  }, [fleets, fleetsSliceStatus, fleetsSliceReasonCode]);

  //#endregion Table

  //#region Modal methods
  function closeAddFleetModal(fleetName: string) {
    if (fleetName !== "" && fleetName) {
      let fleet: Partial<Fleet> = {
        name: fleetName,
        user: Number(localStorage.getItem("customerId")),
        vehicles: [] as number[],
        drivers: [] as number[],
        geofences: [] as number[],
        fleetDeleted: false,
      };
      setNewFleet(fleet);
      store.dispatch(createFleetAsync({ fleet }));
    } else {
      setOpenAddFleetModal(false);
      navigate({
        pathname: "/admin/fleets",
        search: queryParamsFromFilterBar,
      });
    }
  }

  function closeDeleteFleetModal(id?: number) {
    if (id) {
      store.dispatch(deleteFleetAsync(id));
    }
    setOpenDeleteFleetModal(false);
    navigate({
      pathname: "/admin/fleets",
      search: queryParamsFromFilterBar,
    });
  }
  //#endregion Modal methods

  return (
    <>
      <PageFilters>
        <div className="col col-16">
          <AdminFleetsFilterBar
            callback={(queryString: string) => {
              setQueryParamsFromFilterBar(queryString);
              isLoading && setIsLoading(false);
            }}
          />
        </div>
      </PageFilters>
      {openAddFleetModal && (
        <AddGroupModal
          open={openAddFleetModal}
          onClose={(fleetName) => {
            closeAddFleetModal(fleetName);
          }}
          isLoading={fleetsSliceStatus === "loading"}
        />
      )}
      {openDeleteFleetModal && (
        <DeleteFleetModal
          openDeleteFleetModal={openDeleteFleetModal}
          selectedFleet={selectedFleet}
          closeDeleteFleetModal={closeDeleteFleetModal}
        />
      )}
      {tableData?.rows?.length > 0 ? (
        <PageContent>
          <div className="fleet-table">
            <Table data={tableData}>
              <Table.Head hasTableSpace={true} />
              <Table.Body />
            </Table>
          </div>
          <div className="fleet-pagination">
            <PageCounter
              isActionPerforming={inLoading}
              totalElements={fleetViewsSliceTotalElements}
              resetPage={resetPage}
              disabled={!isFleetsIdle}
              numOfPages={fleetViewsSlicePage}
              onClick={(id) => {
                const pageAndSize = getQueryString({
                  page: id - 1,
                  size: "10",
                });
                const finalQueryParams = queryParamsFromFilterBar
                  ? queryParamsFromFilterBar + pageAndSize.replace("?", "&")
                  : pageAndSize;
                store.dispatch(
                  getFilteredFleetsAsync({
                    queryParams: finalQueryParams,
                    isActive: false,
                  })
                );
              }}
            />
          </div>
        </PageContent>
      ) : null}
      {/**This component must be showed only when there are no server-side fleets and not when the search gets not results */}
      {!isLoading &&
      isFleetsIdle &&
      fleetViews.length === 0 &&
      noQueryParamsFromFilterBar ? (
        <PageContent>
          <div className="col">
            <div className="fleet-page-withoutTable">
              <div className="fleet-page-withoutTable-item">
                <IconTitle
                  icon={<IconGroup color="currentColor" size={48} />}
                  title={t("admin.groups.withoutTableComponent.title")}
                  text={t("admin.groups.withoutTableComponent.desc")}
                />
                <div className="fleet-page-withoutTable-addFleet">
                  <Button
                    aspect="primary"
                    size="small"
                    label={t("admin.groups.groupFilterBar.addGroup")}
                    onClick={() => navigate("/admin/fleets/edit-fleet")}
                  >
                    <IconAdd size={14} color="--global-colors-ink-white" />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </PageContent>
      ) : null}
    </>
  );
};

export default AdminFleets;
