import propTypes from "prop-types";

import "./mapMarker.css";

export const MapMarkerEventToggle = ({
  color,
  number,
  status,
  letter,
  ...props
}) => {
  return (
    <div
      className="map-marker-event-toggle"
      style={{ position: "relative" }}
      {...props}
    >
      <svg
        width="38px"
        height="40px"
        viewBox="0 0 38 40"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs>
          <circle id="path-1" cx="16" cy="16" r="16"></circle>
          <filter
            x="-15.6%"
            y="-12.5%"
            width="131.2%"
            height="137.5%"
            filterUnits="objectBoundingBox"
            id="filter-2"
          >
            <feMorphology
              radius="1.5"
              operator="dilate"
              in="SourceAlpha"
              result="shadowSpreadOuter1"
            ></feMorphology>
            <feOffset
              dx="0"
              dy="3"
              in="shadowSpreadOuter1"
              result="shadowOffsetOuter1"
            ></feOffset>
            <feGaussianBlur
              stdDeviation="1"
              in="shadowOffsetOuter1"
              result="shadowBlurOuter1"
            ></feGaussianBlur>
            <feComposite
              in="shadowBlurOuter1"
              in2="SourceAlpha"
              operator="out"
              result="shadowBlurOuter1"
            ></feComposite>
            <feColorMatrix
              values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.2 0"
              type="matrix"
              in="shadowBlurOuter1"
            ></feColorMatrix>
          </filter>
          <filter
            x="-10.9%"
            y="-7.8%"
            width="121.9%"
            height="128.1%"
            filterUnits="objectBoundingBox"
            id="filter-3"
          >
            <feMorphology
              radius="1"
              operator="erode"
              in="SourceAlpha"
              result="shadowSpreadInner1"
            ></feMorphology>
            <feOffset
              dx="0"
              dy="0"
              in="shadowSpreadInner1"
              result="shadowOffsetInner1"
            ></feOffset>
            <feComposite
              in="shadowOffsetInner1"
              in2="SourceAlpha"
              operator="arithmetic"
              k2="-1"
              k3="1"
              result="shadowInnerInner1"
            ></feComposite>
            <feColorMatrix
              values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.1 0"
              type="matrix"
              in="shadowInnerInner1"
            ></feColorMatrix>
          </filter>
        </defs>
        <g
          id="15-20-(Paola)-Vers.3"
          stroke="none"
          strokeWidth="1"
          fill={color}
          fillRule="evenodd"
        >
          <g
            id="15.-Select-Track---12"
            transform="translate(-305.000000, -322.000000)"
          >
            <g id="Group-14-Copy" transform="translate(308.000000, 324.000000)">
              <g id="IMAGES-AND-ICONS/Marker/Map-marker-overview/Start-Copy-2">
                <g id="Oval">
                  <use
                    fill="var(--global-colors-ink-dark)"
                    fillOpacity="1"
                    filter="url(#filter-2)"
                  ></use>
                  <use fill="#FED005" fillRule="evenodd"></use>
                  <use
                    fill="var(--global-colors-ink-dark)"
                    fillOpacity="1"
                    filter="url(#filter-3)"
                  ></use>
                  <circle
                    stroke="#FFFFFF"
                    strokeWidth="2"
                    cx="16"
                    cy="16"
                    r="17"
                  ></circle>
                  {/* border shadow */}
                  <use
                    x="-2"
                    y="-2"
                    r="14"
                    fill="#000"
                    filter="url(#MapMarkerStartD)"
                    xlinkHref="#MapMarkerStartC"
                  ></use>
                </g>
              </g>
              <text
                id="Truck-Number-Copy"
                fontSize={number.length > 2 ? "var(--global-font-size-xs)" : "var(--global-font-size-sm)"}
                fontWeight="500"
                fill={
                  color === "var(--global-colors-feedback-warning)"
                    ? "var(--global-colors-ink-dark)"
                    : "#FFF"
                }
              >
                <tspan
                  x="43%"
                  y="45%"
                  dominantBaseline="middle"
                  textAnchor="middle"
                >
                  {number.length > 2 ? number.length > 3 ? "1k+" : "99+" : number}
                </tspan>
              </text>
            </g>
          </g>
        </g>
      </svg>
      {status === "PARKING" && (
        <span className="label-led" style={{ color: `var(${color})` }}>
          {letter}
        </span>
      )}
    </div>
  );
};

MapMarkerEventToggle.propTypes = {
  /**
   * The status of the marker.
   */
  color: propTypes.string,
  status: propTypes.string,
  number: propTypes.string,
  letter: propTypes.string,
};

MapMarkerEventToggle.defaultProps = {
  color: "--global-colors-ui-primary",
  number: "2",
  letter: "A",
};
