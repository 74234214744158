import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";

import _ from "lodash";
import { normalize } from "normalizr";
import { RootState } from "../../../app/store";
import { Config } from "../../../config/Config";
import { privilegesDefaultSchema } from "./privilegesDefaultNormalization";
import PrivilegesDefaultRepository from "./privilegesDefaultRepository";

//#region Type
export type PrivilegesDefault = {
  entityId: number;
  entityType: string;
  permission: string;
};

//#endregion Type

//#region API
export const getPrivilegesDefaultAsync = createAsyncThunk(
  "privilegesDefault/getPrivilegesDefault",
  async (data: { id: number }) => {
    const privilegesDefaultRepository = new PrivilegesDefaultRepository();
    const response = await privilegesDefaultRepository.getPrivilegesDefault(
      data.id
    );
    const privilegesDefault = _.get(
      response,
      Config.PRIVILEGES_DEFAULT_RESPONSE_PATH
    );
    const normalizedData = normalize(
      privilegesDefault,
      privilegesDefaultSchema
    );
    return normalizedData.entities;
  }
);
//#endregion API

//#region Slice
const privilegesDefaultAdapter = createEntityAdapter<PrivilegesDefault>({
  selectId: (privilegesDefault) =>
    privilegesDefault.entityType + privilegesDefault.permission,
});

export const privilegesDefaultSlice = createSlice({
  name: "privilegesDefault",
  initialState: privilegesDefaultAdapter.getInitialState({
    status: "idle",
    reasonCode: "",
  }),
  reducers: {
    privilegesDefaultEmptyState: (state: any) => {
      privilegesDefaultAdapter.setAll(state, []);
      state.reasonCode = "";
      state.status = "idle";
    },
  },
  extraReducers: (builder) => {
    builder
      //#region Entity Reducers
      .addCase(getPrivilegesDefaultAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(
        getPrivilegesDefaultAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          privilegesDefaultAdapter.setAll(
            state,
            action.payload.privilegesDefault
          );
          state.status = "idle";
        }
      )
      .addCase(getPrivilegesDefaultAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = "";
      });
    //#endregion Entity Reducers
  },
});
//#endregion Slice

//#region Status
export const privilegesDefaultSelectors =
  privilegesDefaultAdapter.getSelectors<RootState>(
    (state) => state.privilegesDefault
  );

export const selectPrivilegesDefaultSliceStatus = (state: any) =>
  state.privilegesDefault.status;
export const selectPrivilegesDefaultSliceReasonCode = (state: any) =>
  state.privilegesDefault.reasonCode;

export const { privilegesDefaultEmptyState } = privilegesDefaultSlice.actions;

//#endregion Status

export default privilegesDefaultSlice.reducer;
