import PropTypes from "prop-types";
import "./SingleNotification.css";

export const SingleNotification = ({ notification }) => {
  const getTitleFromNotification = (text) => {
    const httpsIndex = text.indexOf("https");
    const title = httpsIndex !== -1 ? text.slice(0, httpsIndex) : text;
    return title;
  };

  const getUrlFromNotification = (text) => {
    const pattern = /https?:\/\/\w+(\/?[\w#:.?+=&%@!\-/]*)/;
    const match = pattern.exec(text);
    const url = match && new URL(match[0]);
    const stringUrl = url?.href;
    return stringUrl ?? "";
  };

  return (
    <div className="mn-single-notification" key={notification.id}>
      <div className="mn-single-notification__message">
        {notification.message.includes("https") ? (
          <a
            href={getUrlFromNotification(notification.message)}
            rel="noreferrer"
          >
            {getTitleFromNotification(notification.message)}
          </a>
        ) : (
          <span>{notification.message}</span>
        )}
        <div className="date">{notification.time}</div>
      </div>
    </div>
  );
};

SingleNotification.propTypes = {
  /**
   * The information to render in the notification.
   */
  notifications: PropTypes.arrayOf(
    PropTypes.shape({
      message: PropTypes.string,
      time: PropTypes.string,
    })
  ),
};
