import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./App";
import { store } from "./app/store";
import "./i18n/i18nextConf";
import "./index.css";
import "./layout/layout.css";
import * as serviceWorker from "./serviceWorker";
import "./style.scss";

let isFirefox = false;

const userAgent = navigator.userAgent.toLowerCase();
if (userAgent.includes("firefox")) {
  isFirefox = true;
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <div
        className={isFirefox ? "firefoxStyle" : ""}
        style={{ height: "100%", overflow: "hidden" }}
      >
        <App />
      </div>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
