import { t } from "i18next";
import React from "react";
import { ButtonList } from "../../../ui/Button/ButtonList";
import { Tooltip } from "../../../ui/Forms/Tooltip";
import "./PublicRouteStopComponent.css";
import { PublicStopStatusEnum } from "./publicRouteStopSlice";

interface PublicRouteStopComponentProps {
  id: number;
  date: string;
  seasonalMonths: number[];
  status?: PublicStopStatusEnum;
  publicTripCode?: string;
}

export const PublicRouteStopComponent: React.FC<PublicRouteStopComponentProps> =
  ({ id, date, seasonalMonths, status, publicTripCode }) => {
    if (status !== "ACTIVE") {
      return (
        <div
          className="public-route-stop-component-wrapper"
          id="public-route-stop-component-cell"
        >
          {!!status && status !== null && (
            <div className="public-route-stop-component-icon">
              <Tooltip />
              <span
                style={{ whiteSpace: "nowrap", height: "20px" }}
                data-for="tooltip"
                data-tip={t("navigation.publicRouteStops.statuses." + status)}
              >
                <ButtonList status={"deleted"} buttonText={""} size={10} />
              </span>
              <Tooltip />
            </div>
          )}
        </div>
      );
    } else {
      return (
        <>
          <div
            className="public-route-stop-component-wrapper"
            id="public-route-stop-component-cell"
          >
            {seasonalMonths.length > 0 && (
              <div className="public-route-stop-component-date-icon">
                <Tooltip />
                <span
                  style={{ whiteSpace: "nowrap", height: "20px" }}
                  data-for="tooltip"
                  data-tip={
                    t("navigation.publicRouteStops.seasonalMonths") +
                    " " +
                    seasonalMonths?.toString()
                  }
                >
                  <ButtonList status="pending" buttonText={""} size={10} />
                </span>
                <Tooltip />
              </div>
            )}
            <div className="public-route-stop-component-date">
              <div className="public-route-stop-component-date-text">
                {date.slice(0, -3)}
              </div>
            </div>
          </div>
        </>
      );
    }
  };
