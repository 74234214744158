import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import _ from "lodash";
import { normalize } from "normalizr";
import { RootState } from "../../app/store";
import { Config } from "../../config/Config";
import { GTFleetSuccessCodes } from "../../config/GTFleetSuccessCodes";
import { getErrorCodes } from "../../utils/Utils";
import { fleetObjSchema, fleetSchema } from "./fleetNormalization";
import FleetsRepository from "./fleetsRepository";

//#region Type
export type Fleet = {
  id: number;
  name: string;
  user: number;
  vehicles: number[];
  drivers: number[];
  geofences: number[];
  fleetDeleted: boolean;
};

interface ElementsAddOrRemove {
  VEHICLE?: number[];
  DRIVER?: number[];
  GEOFENCE?: number[];
}

export type FleetUpdate = {
  elementsToBeAdded: ElementsAddOrRemove;
  elementsToBeRemoved: ElementsAddOrRemove;
};
//#endregion Type

//#region API

export const getFleetAsync = createAsyncThunk(
  "fleets/getFleet",
  async (fleetId: number, { rejectWithValue }) => {
    try {
      const fleetsRepository = new FleetsRepository();
      const response = await fleetsRepository.getFleet(fleetId);
      const fleet = _.get(response, Config.FLEET_VIEW_RESPONSE_PATH);
      const normalizedData = normalize(fleet, fleetObjSchema);
      return normalizedData.entities;
    } catch (error: any) {
      if (!error.response) throw error;
      return rejectWithValue(error.response);
    }
  }
);

export const createFleetAsync = createAsyncThunk(
  "fleets/createFleet",
  async (data: { fleet: Partial<Fleet> }, { rejectWithValue }) => {
    try {
      const fleetsRepository = new FleetsRepository();
      const response = await fleetsRepository.createFleet(data.fleet);
      const fleet = _.get(response, Config.FLEET_RESPONSE_PATH);
      const normalizedData = normalize(fleet, fleetSchema);
      return normalizedData.entities;
    } catch (error: any) {
      if (!error.response) throw error;
      return rejectWithValue(error.response);
    }
  }
);

export const deleteFleetAsync = createAsyncThunk(
  "fleets/deleteFleet",
  async (fleetId: number, { rejectWithValue }) => {
    try {
      const fleetsRepository = new FleetsRepository();
      await fleetsRepository.deleteFleet(fleetId);
      return fleetId;
    } catch (error: any) {
      if (!error.response) throw error;
      return rejectWithValue(error.response);
    }
  }
);

export const updateFleetAsync = createAsyncThunk(
  "fleets/updateFleet",
  async (
    data: { fleetId: number; fleet: FleetUpdate },
    { rejectWithValue }
  ) => {
    try {
      const fleetsRepository = new FleetsRepository();
      const response = await fleetsRepository.updateFleet(
        data.fleetId,
        data.fleet
      );
      const fleets = _.get(response, Config.FLEET_RESPONSE_PATH);
      const normalizedData = normalize(fleets, fleetObjSchema);
      return normalizedData.entities;
    } catch (error: any) {
      if (!error.response) throw error;
      return rejectWithValue(error.response);
    }
  }
);
//#endregion API

//#region Slice
const fleetsAdapter = createEntityAdapter<Fleet>({
  selectId: (fleet) => fleet.id,
  sortComparer: (a, b) => a.name.localeCompare(b.name),
});

export const fleetSlice = createSlice({
  name: "fleets",
  initialState: fleetsAdapter.getInitialState({
    status: "idle",
    reasonCode: "",
    totalPages: 0,
  }),
  reducers: {
    fleetsEmptyState: (state) => {
      fleetsAdapter.setAll(state, []);
      state.reasonCode = "";
      state.status = "idle";
    },
    setNumberOfPages: (state, action: PayloadAction<number>) => {
      state.totalPages = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      //#region Entity Reducers
      .addCase(deleteFleetAsync.fulfilled, (state: any) => {
        state.status = "idle";
        state.reasonCode = GTFleetSuccessCodes.DELETE;
      })
      .addCase(
        deleteFleetAsync.rejected,
        (state: any, action: PayloadAction<any>) => {
          state.status = "failed";
          state.reasonCode = getErrorCodes(action.payload);
        }
      )
      .addCase(deleteFleetAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(
        getFleetAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          fleetsAdapter.upsertMany(state, action.payload.fleet ?? []);
          state.status = "idle";
        }
      )
      .addCase(getFleetAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(
        getFleetAsync.rejected,
        (state: any, action: PayloadAction<any>) => {
          state.status = "failed";
          state.reasonCode = getErrorCodes(action.payload);
        }
      )
      .addCase(
        createFleetAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          fleetsAdapter.upsertMany(state, action.payload?.fleet ?? []);
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.POST;
        }
      )
      .addCase(createFleetAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(
        createFleetAsync.rejected,
        (state: any, action: PayloadAction<any>) => {
          state.status = "failed";
          state.reasonCode = getErrorCodes(action.payload);
        }
      )
      .addCase(
        updateFleetAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          fleetsAdapter.upsertMany(state, action.payload?.fleet ?? []);
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.PATCH;
        }
      )
      .addCase(updateFleetAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(
        updateFleetAsync.rejected,
        (state: any, action: PayloadAction<any>) => {
          state.status = "failed";
          state.reasonCode = getErrorCodes(action.payload);
        }
      );
    //#endregion Entity Reducers
    //#region External Entity Reducers
    //#endregion External Entity Reducers
  },
});
//#endregion Slice

//#region Status
export const fleetsSelectors = fleetsAdapter.getSelectors<RootState>(
  (state) => state.fleets
);

export const selectFleetsSlicePage = (state: any) => state.fleets.totalPages;

export const { fleetsEmptyState, setNumberOfPages } = fleetSlice.actions;
export const selectFleetsSliceStatus = (state: any) => state.fleets.status;
export const selectFleetsSliceReasonCode = (state: any) =>
  state.fleets.reasonCode;

//#endregion Status

export default fleetSlice.reducer;
