import {
  PayloadAction,
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import _ from "lodash";
import { RootState } from "../../../app/store";
import { Config } from "../../../config/Config";
import { GTFleetSuccessCodes } from "../../../config/GTFleetSuccessCodes";
import PublicRouteShiftRepository from "./publicRouteShiftRepository";

export interface ShiftTimeRange {
  id: number;
  startDate: String;
  endDate: String;
  routeId: number;
  terminusKm: number;
  terminusTime: number;
  preparationTime: number;
  refuellingTime: number;
  preparationTotalTime: number;
}

export interface PublicRouteShift {
  id: number;
  name: string;
  shiftTimeRanges: ShiftTimeRange[];
}

const publicRouteShiftAdapter = createEntityAdapter<PublicRouteShift>({
  selectId: (publicRouteShift) => publicRouteShift.id,
});

export const getPublicRouteShiftsAsync = createAsyncThunk(
  "publicRouteShift/getPublicRouteShifts",
  async () => {
    try {
      const publicRouteShiftRepository = new PublicRouteShiftRepository();
      const response = await publicRouteShiftRepository.getPublicRouteShifts();
      const shifts = _.get(response, Config.PUBLIC_ROUTE_SHIFT);
      return shifts;
    } catch (err: any) {
      if (!err.response) throw err;
      return null;
    }
  }
);

export const publicRouteShiftSlice = createSlice({
  name: "publicRouteShift",
  initialState: publicRouteShiftAdapter.getInitialState({
    status: "idle",
    reasonCode: "",
  }),
  reducers: {
    publicRouteShiftEmptyState: (state: any) => {
      publicRouteShiftAdapter.setAll(state, []);
      state.reasonCode = "";
      state.status = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPublicRouteShiftsAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(
        getPublicRouteShiftsAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          publicRouteShiftAdapter.setAll(state, action.payload ?? []);
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.GET;
        }
      )
      .addCase(getPublicRouteShiftsAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = "";
      });
  },
});

export const publicRouteShiftSelectors =
  publicRouteShiftAdapter.getSelectors<RootState>(
    (state) => state.publicRouteShifts
  );

export const selectPublicRouteShiftStatus = (state: any) =>
  state.publicRouteShifts.status;
export const selectPublicRouteShiftSliceReasonCode = (state: any) =>
  state.publicRouteShifts.reasonCode;
export const { publicRouteShiftEmptyState } = publicRouteShiftSlice.actions;
//#endregion Status

export default publicRouteShiftSlice.reducer;
