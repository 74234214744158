import React from "react";
import PropTypes from "prop-types";
import "../IconFont/icons.css";

export const IconLicense = ({ size, ...props }) => (
  <div className="svg" onClick={props.onClick}>
    <span style={props.color ? { fontSize: `${size}px`, color: `var(${props.color})` } : { fontSize: `${size}px` }}>
      &#xe83a;
    </span>
  </div>
);

IconLicense.propTypes = {
  /**
   * Size of the icon.
   */
  size: PropTypes.number,
  /**
   * Color of the icon.
   */
  color: PropTypes.string,
};

IconLicense.defaultProps = {
  color: '--global-colors-ui-primary',
  size: 24,
};
