import { t } from "i18next";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppSelector } from "../../../app/hooks";
import { store } from "../../../app/store";
import { GTFleetErrorCodes } from "../../../config/GTfleetErrorCodes";
import { ToastNotification } from "../../../utils/ToastNotification";
import { getFleetViewsAsync } from "../../fleet/fleetViewsSlice";
import { Vehicle, vehiclesSelectors } from "../../vehicle/vehiclesSlice";
import {
  getVehicleAsync,
  selectVehiclesStatusSliceReasonCode,
  selectVehiclesStatusSliceStatus,
  vehiclesStatusSelectors,
  VehicleStatus,
} from "../../vehicle/vehiclesStatusSlice";
import { Deadline } from "../deadlinesSlice";
import { EditMaintenanceModal } from "./EditMaintenance";

interface MaintenanceEditModalProps {
  deadlineStatus: Deadline;
  defaultMaintenanceId: number;
  defaultFleetId: number;
  defaultVehicleId: number;
}

const MaintenanceEditModal: React.FC<MaintenanceEditModalProps> = ({
  deadlineStatus,
  defaultMaintenanceId,
  defaultFleetId,
  defaultVehicleId,
}) => {
  const [addMaintenanceModal, setAddMaintenanceModal] = useState(true);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const vehicleStatusSliceStatus = useAppSelector(
    selectVehiclesStatusSliceStatus
  );
  const vehicleStatusSliceReasonCode = useAppSelector(
    selectVehiclesStatusSliceReasonCode
  );

  useEffect(() => {
    store.dispatch(getFleetViewsAsync());
    if (defaultVehicleId !== undefined) {
      store.dispatch(
        getVehicleAsync({ id: defaultVehicleId, fleetId: defaultFleetId })
      );
    }
  }, []);

  const vehicle: Vehicle =
    useAppSelector((state: any) =>
      vehiclesSelectors.selectById(state, defaultVehicleId)
    ) ?? ({} as Vehicle);

  const selectedVehicleStatus: VehicleStatus =
    useAppSelector((state) =>
      vehiclesStatusSelectors.selectById(state, vehicle.id ?? -1)
    ) ?? ({} as VehicleStatus);

  useEffect(() => {
    if (
      vehicleStatusSliceStatus === "failed" &&
      vehicleStatusSliceReasonCode ===
        GTFleetErrorCodes.VEHICLE_STATUS_NOT_FOUND
    ) {
      console.error(t("common.vehicleStatusError"));
      ToastNotification({
        toastId: "vehicleStatusError",
        status: "error",
        description: t("common.vehicleStatusError"),
      });
    } else if (
      vehicleStatusSliceStatus === "failed" &&
      vehicleStatusSliceReasonCode === ""
    ) {
      ToastNotification({
        toastId: "networkError",
        status: "error",
        description: t("common.networkError"),
      });
    }
  }, [vehicleStatusSliceStatus, vehicleStatusSliceReasonCode]);

  return (
    <EditMaintenanceModal
      open={addMaintenanceModal}
      maintenanceId={defaultMaintenanceId}
      vehicleSelected={vehicle}
      selectedVehicleStatus={selectedVehicleStatus}
      defaultValues={deadlineStatus}
      onClose={() => {
        setAddMaintenanceModal(false);
        navigate({
          pathname: "/dashboard/maintenance",
          search: searchParams.toString(),
        });
      }}
    />
  );
};
export default MaintenanceEditModal;
