import { t } from "i18next";
import { NavigateFunction } from "react-router-dom";
import { DropdownButton } from "../../ui/Button/DropdownButton";
import { LabelText } from "../../ui/LabelText/LabelText";
import { Table } from "../../ui/Table/Table";
import {
  AbstractTableBuilder,
  Row,
} from "../../ui/Table/utils/AbstractTableBuilder";
import "../../ui/Table/utils/TableBuilder.css";
import { Tag } from "../../ui/Tags/Tag";
import { GeofenceCategory } from "../geofenceCategory/geofenceCategoriesSlice";
import { Geofence } from "./geofenceSlice";

export class GeofenceTableBuilder extends AbstractTableBuilder {
  rowsBuilder = (
    columns: { label: string; field: string; sort: boolean }[] | undefined,
    entities: {
      geofence?: Geofence;
      geofenceCategory?: GeofenceCategory;
    },
    navigate: NavigateFunction,
    writePermission: boolean = false
  ) => {
    let row: Row = { render: {} as Row };

    let geofence: Geofence = entities.geofence ?? ({} as Geofence);
    let geofenceCategory: GeofenceCategory =
      entities.geofenceCategory ?? ({} as GeofenceCategory);

    columns &&
      columns.forEach((column) => {
        switch (column.field) {
          case "geofence":
            row.geofence = geofence.id ?? t("common.na");
            row.render.geofence = (
              <LabelText
                label={
                  geofence.id
                    ? geofence.name?.split(",")[0] + ""
                    : t("common.na")
                }
                text={
                  geofence.name
                    ? geofence.name?.split(",").filter((v, i) => i !== 0)
                    : t("common.na")
                }
              />
            );
            break;
          case "category":
            row.category = geofenceCategory.id ?? t("common.na");
            row.render.category = (
              <Table.TextIcon
                text={geofenceCategory.name ?? t("common.na")}
                icon={
                  <div
                    className="circle"
                    style={{
                      width: "15px",
                      height: "15px",
                      backgroundColor: geofenceCategory.color ?? "",
                      border: `1px solid ${geofenceCategory.color ?? ""}`,
                      borderRadius: "50%",
                    }}
                  ></div>
                }
              />
            );
            break;
          case "geofenceStatus":
            let geofenceStatusLabel = geofence?.geofenceStatus ?? "unknown";
            row.render.status = (
              <Tag
                text={
                  geofenceStatusLabel === "ACTIVE"
                    ? t("admin.geofences.geofenceFilterBar.statuses.active")
                    : t("admin.geofences.geofenceFilterBar.statuses.inactive")
                }
                size="small"
                mode="fill"
                type={
                  `${geofenceStatusLabel}` === "ACTIVE" ? "success" : "neutro"
                }
                hasIcon={false}
              />
            );
            break;
          default:
            break;
        }
      });
    const geofenceDropDownList = [
      {
        id: 0,
        title: t("common.edit"),
        onClick: () =>
          navigate("/admin/geofences/edit-geofence/" + geofence.id),
      },
      {
        id: 1,
        title:
          geofence.geofenceStatus === "ACTIVE"
            ? t("common.actionOption.deactivate")
            : t("common.actionOption.activate"),
        onClick: () => {
          navigate(
            "/admin/geofences/" +
              (geofence.geofenceStatus === "ACTIVE"
                ? "deactivate"
                : "activate") +
              "/" +
              geofence.id
          );
        },
      },
      {
        id: 2,
        title: t("common.delete"),
        onClick: () => {
          navigate("/admin/geofences/delete/" + geofence.id);
        },
      },
    ];
    if (!writePermission) {
      // hide edit and activate/deactivate
      geofenceDropDownList.splice(1, 2);
    }
    row.render.action = (
      <DropdownButton aspect="ghost" size="small" list={geofenceDropDownList} />
    );
    return row;
  };
}
