import { EntitySelectors } from "@reduxjs/toolkit";
import { ReactElement, useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../app/hooks";
import { store } from "../../app/store";
import { Tooltip } from "../../ui/Forms/Tooltip";
import { IconTitle } from "../../ui/IconTitle/IconTitle";
import { ModalBody } from "../../ui/Modal/ModalBody";
import { ModalWrapper } from "../../ui/Modal/ModalWrapper";
import { PageCounter } from "../../ui/Table/PageCounter";
import { Table } from "../../ui/Table/Table";
import { ToastNotification } from "../../utils/ToastNotification";
import { getQueryString } from "../../utils/Utils";
import { driversSelectors } from "../driver/driversSlice";
import { Preferences } from "../users/preference/preferencesSlice";
import UserContext from "../users/userContext";
import { vehiclesSelectors } from "../vehicle/vehiclesSlice";
import "./FileArchiveModal.css";
import { FileArchiveModalFilterBar } from "./FileArchiveModalFilterBar";
import {
  FileArchiveModalTableBuilder,
  FileArchiveModalTableBuilderObjectPath,
} from "./FileArchiveModalTableBuilder";

interface TableSchema {
  id: number;
  name: string;
}

interface TableData {
  columns: { label: string; field: string; sort: boolean }[] | undefined;
  rows: { [key: string]: any };
}

interface FileArchiveModalData {
  selector: EntitySelectors<any, any>;
  status: (state: any) => any;
  valuePath: FileArchiveModalTableBuilderObjectPath;
  api: (data: any) => any;
  bucket?: string;
  queryParamsName: {
    vehicle?: string;
    driver?: string;
    date: [string, string];
  };
}

interface ArchiveFileNotFoundTexts {
  title: string;
  desc: string;
}

interface FileArchiveModalProps {
  open: boolean;
  title: string;
  desc?: string;
  tableSchema: TableSchema[];
  data: FileArchiveModalData;
  iconSection: ReactElement<any>;
  filesNotFoundTexts: ArchiveFileNotFoundTexts;
  onClose: (e: any) => any;
}

export const FileArchiveModal: React.FC<FileArchiveModalProps> = ({
  title,
  desc,
  tableSchema,
  data,
  open,
  iconSection,
  filesNotFoundTexts,
  onClose,
}) => {
  const { t } = useTranslation();

  const [preferencesContext]: [Preferences] = useContext(UserContext);

  const [resetPage, setResetPage] = useState<boolean>(false);

  const entities: any = useAppSelector(data.selector.selectAll);
  //#region Status
  const status: any = useAppSelector(data.status);

  const isLoading = status === "loading";

  const isIdle = status === "idle";

  useEffect(() => {
    if (status === "failed") {
      ToastNotification({
        toastId: "networkError",
        status: "error",
        description: t("common.networkError"),
      });
    }
  }, [status]);
  //#endregion Status

  //#region Search
  const [queryParamsFromFilterBar, setQueryParamsFromFilterBar] =
    useState<string>("");

  const [queryParamsChanged, setQueryParamsChanged] = useState<boolean>(false);
  useEffect(() => {
    if (!!queryParamsFromFilterBar) {
      setQueryParamsChanged(true);
    }
  }, [queryParamsFromFilterBar]);

  useEffect(() => {
    if (queryParamsChanged && status === "idle") {
      setQueryParamsChanged(false);
      setResetPage(true);
    } else {
      setResetPage(false);
    }
  }, [status, queryParamsChanged]);
  //#endregion Search

  //#region Table
  const tableBuilderRef = useRef<FileArchiveModalTableBuilder>(
    new FileArchiveModalTableBuilder()
  );
  let tableBuilder = tableBuilderRef.current;

  let tableData: TableData = {} as TableData;

  tableData.columns = tableBuilder.setColumns(tableSchema);
  tableData.rows =
    tableData?.columns && tableData?.columns?.length > 0 && entities.length > 0
      ? entities.map((entity: any) => {
          let vehicle = undefined;
          let driver = undefined;
          if (entity.vehicleId) {
            vehicle = vehiclesSelectors.selectById(
              store.getState(),
              entity.vehicleId
            );
          }
          if (entity.driverId) {
            driver = driversSelectors.selectById(
              store.getState(),
              entity.driverId
            );
          }
          return tableBuilder.rowsBuilder(
            tableData.columns,
            {
              entity: {
                downloadDate: data.valuePath.downloadDate
                  ? entity[data.valuePath.downloadDate]
                  : undefined,
                generationDate: data.valuePath.generationDate
                  ? entity[data.valuePath.generationDate]
                  : undefined,
                fileName: data.valuePath.fileName
                  ? entity[data.valuePath.fileName]
                  : undefined,
                path: data.valuePath.path
                  ? entity[data.valuePath.path]
                  : undefined,
                period: data.valuePath.period
                  ? [
                      entity[data.valuePath.period[0]],
                      entity[data.valuePath.period[1]],
                    ]
                  : undefined,
              },
              vehicle,
              driver,
            },
            preferencesContext,
            data.bucket
          );
        })
      : tableData.rows;
  //#endregion Table

  return (
    <div className="archive-file-detail-modal">
      <ModalWrapper open={open} closeAction={onClose}>
        <ModalBody title={`${title ?? ""}`} desc={`${desc ?? ""}`}>
          <FileArchiveModalFilterBar
            api={data.api}
            callback={setQueryParamsFromFilterBar}
            queryParamsName={data.queryParamsName}
          />

          {tableData.rows?.length > 0 && isIdle ? (
            <div className="archive-file-table-container">
              <div className="archive-file-container">
                <Table data={tableData}>
                  <Table.Head />
                  <Tooltip />
                  <Table.Body />
                </Table>
              </div>
            </div>
          ) : (
            <div className="file-not-found">
              <IconTitle
                icon={iconSection}
                title={filesNotFoundTexts.title}
                text={filesNotFoundTexts.desc}
              />
            </div>
          )}
        </ModalBody>
        <div className="archive-file-detail-modal-footer">
          <PageCounter
            isActionPerforming={isLoading}
            totalElements={entities ? entities.length : 0}
            resetPage={resetPage}
            disabled={!isIdle}
            progressiveArrowMode={true}
            numOfPages={entities.length ? Math.ceil(entities.length / 10) : 1}
            numSelected={0}
            onClick={(id, currentPage) => {
              if (id !== currentPage) {
                const pageAndSize = getQueryString({
                  page: id - 1,
                  size: "10",
                });
                const finalQueryParams = queryParamsFromFilterBar
                  ? queryParamsFromFilterBar + pageAndSize.replace("?", "&")
                  : pageAndSize;
                store.dispatch(
                  data.api({
                    queryParams: finalQueryParams,
                    page: id - 1,
                  })
                );
              }
            }}
          />
        </div>
      </ModalWrapper>
    </div>
  );
};
