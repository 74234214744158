import HttpClient from "../../../utils/HttpClient";
import { PublicRoutePlanningRequest } from "./publicRoutePlanningSlice";

export default class PublicRoutePlanningRepository extends HttpClient {
  constructor() {
    super(true);
  }

  public getPublicRoutePlannings(queryParams: string) {
    const tenantId = localStorage.getItem("tenantId");
    return this.instance.get(
      `/tenants/${tenantId}/publicRoutePlannings` + queryParams
    );
  }
  public updatePublicRoutePlanning(obj: PublicRoutePlanningRequest) {
    const tenantId = localStorage.getItem("tenantId");
    const userIdPath = "userId=" + localStorage.getItem("customerId");
    return this.instance.post(
      `/tenants/${tenantId}/publicRoutePlanning` + "?" + userIdPath,
      obj
    );
  }
  public updatePublicRoutePlannings(
    objs: PublicRoutePlanningRequest[],
    status: string,
    startDate: String,
    endDate: String
  ) {
    const tenantId = localStorage.getItem("tenantId");
    const userIdPath = "userId=" + localStorage.getItem("customerId");
    return this.instance.post(
      `/tenants/${tenantId}/publicRoutePlannings` +
        "?" +
        userIdPath +
        "&status=" +
        status +
        "&startPeriod=" +
        startDate +
        "&endPeriod=" +
        endDate,
      objs
    );
  }
  public getPublicRoutePlanningsView() {
    const tenantId = localStorage.getItem("tenantId");
    return this.instance.get(`/tenants/${tenantId}/publicRoutePlannings/view`);
  }
}
