import HttpClient from "../../utils/HttpClient";

export default class VehicleStatusAmountRepository extends HttpClient {
  constructor() {
    super(true);
  }

  public getVehicleStatusAmount() {
    const tenantId = localStorage.getItem("tenantId");
    let path = "/tenants/" + tenantId + "/fleets/vehicles/status/count/";
    return this.instance.get(path);
  }
}
