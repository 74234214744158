import PropTypes from "prop-types";
import { Card } from "../Card/Card";
import { DataLabel } from "../DataLabel/DataLabel";
import { IconDown } from "../Icon/Line/Down";
import { IconTimer } from "../Icon/Line/Timer";
import { IconUp } from "../Icon/Line/Up";
import { IconMarker } from "../Icon/Solid/Marker";

import { t } from "i18next";
import i18n from "../../i18n/i18nextConf";
import { IconCalendar } from "../Icon/Line/Calendar";
import "./StopDetails.css";

/**
 * Transform timestamp in HH:mm (DD:MM) into a formatted string.
 *
 * @param {String} time The time to format.
 * @returns String
 */
const formatDateTime = (time) => {
  const formattedTime = new Date(time);
  const timeFormatOption = { hour: "2-digit", minute: "2-digit" };

  const formattedDate = new Date(time);
  const dateFormatOption = { month: "short", day: "numeric" };

  return (
    formattedTime.toLocaleTimeString(i18n.language, timeFormatOption) +
    " (" +
    formattedDate.toLocaleString(i18n.language, dateFormatOption) +
    ")"
  );
};

export const StopDetails = ({ open, title, address, coords, details }) => {
  // Fetch Maps API to get Street View Placeholder
  const mapsPlaceholder = `https://maps.googleapis.com/maps/api/streetview?size=400x200&location=${coords.lat},${coords.long}&fov=80&heading=70&pitch=0&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`;
  // Link for open Street View with coordinates
  const openStreetView = `http://maps.google.com/maps?q=&layer=c&cbll=${coords.lat},${coords.long}`;

  return (
    <Card className="mn-lh-stop" title={title} hasCollapse={true}>
      <div className="mn-lh-stop__details">
        <div>
          <IconMarker size={14} /> {address}
        </div>
        <a href={openStreetView} target="_blank" rel="noreferrer">
          <img alt="StreetViewImage" src={mapsPlaceholder} id="StreetViewImg" />
        </a>
        <div className="mn-lh-stop__details-data">
          {details.start && (
            <div className="start">
              <DataLabel
                dataText={details.start ? formatDateTime(details.start) : "N/A"}
                icon={<IconDown size={14} />}
                label={t("locationHistory.stopDetails.start")}
              />
            </div>
          )}
          {details.end && (
            <div className="end">
              <DataLabel
                dataText={
                  open
                    ? t("common.inProgress")
                    : details.end
                    ? formatDateTime(details.end)
                    : "N/A"
                }
                icon={
                  details.start ? (
                    <IconUp size={14} />
                  ) : (
                    <IconCalendar size={14} />
                  )
                }
                label={
                  details.start
                    ? t("locationHistory.stopDetails.end")
                    : t("locationHistory.onlyStopDetails.end")
                }
              />
            </div>
          )}
          {details.total && (
            <div className="total">
              <DataLabel
                dataText={details.total ? details.total : "N/A"}
                icon={<IconTimer size={14} />}
                label={t("locationHistory.stopDetails.total")}
              />
            </div>
          )}
        </div>
      </div>
    </Card>
  );
};

StopDetails.propTypes = {
  title: PropTypes.string,
  address: PropTypes.string,
  coords: PropTypes.exact({
    lat: PropTypes.number,
    long: PropTypes.number,
  }),
  details: PropTypes.exact({
    start: PropTypes.string,
    end: PropTypes.string,
    total: PropTypes.string,
  }),
  open: PropTypes.bool,
};

StopDetails.defaultProps = {
  title: "Stop",
};
