import { t } from "i18next";
import { NavigateFunction } from "react-router-dom";
import { Contract } from "../../features/contract/contractsSlice";
import { Device } from "../../features/device/devicesSlice";
import { Vehicle } from "../../features/vehicle/vehiclesSlice";
import { DropdownButton } from "../../ui/Button/DropdownButton";
import { LabelText } from "../../ui/LabelText/LabelText";
import { Table } from "../../ui/Table/Table";
import {
  AbstractTableBuilder,
  Row,
} from "../../ui/Table/utils/AbstractTableBuilder";
import "../../ui/Table/utils/TableBuilder.css";
import { Tag } from "../../ui/Tags/Tag";
import { vehicleStatusValues } from "../../ui/Vehicles/VehicleTypes";
import { FleetView } from "../fleet/fleetViewsSlice";

export class AdminVehiclesTableBuilder extends AbstractTableBuilder {
  rowsBuilder = (
    columns: { label: string; field: string; sort: boolean }[] | undefined,
    context: string,
    entities: {
      vehicle?: Vehicle;
      fleetView?: FleetView;
      device?: Device;
      contract?: Contract;
    },
    navigate: NavigateFunction,
    writePermission: boolean = false
  ) => {
    let row: Row = { render: {} as Row };

    let vehicle: Vehicle = entities.vehicle ?? ({} as Vehicle);
    let fleetView: FleetView = entities.fleetView ?? ({} as FleetView);
    let device: Device = entities.device ?? ({} as Device);
    let contract: Contract = entities.contract ?? ({} as Contract);

    columns?.forEach((column) => {
      switch (column.field) {
        case "vehicle":
          row.vehicle = vehicle.alias ?? t("common.na");
          row.render.vehicle = (
            <LabelText
              label={vehicle.alias ?? t("common.na")}
              text={
                vehicle.brand
                  ? vehicle.brand + " " + vehicle.model
                  : t("common.na")
              }
            />
          );
          break;
        case "group":
          row.group = fleetView.name ?? t("common.na");
          row.render.group = fleetView.name ?? t("common.na");
          break;
        case "license":
          row.render.license = vehicle.plate ?? t("common.na");
          break;
        case "vehicleStatus":
          row.render.vehicleStatus = (
            <Table.TextStatus
              text={t(`table.status.${vehicle.status}`) ?? t("common.na")}
            />
          );
          break;
        case "imei":
          row.render.imei = device.imei ?? t("common.na");
          break;
        case "functionalities":
          const leftFunctionalitiesCount = contract?.options?.length - 5;
          row.render.functionalities =
            contract?.service &&
            contract?.options?.map((option, index) => {
              return (
                index < 6 && (
                  <Tag
                    key={option.name}
                    className="table-tags"
                    text={
                      index < 5
                        ? t(`serviceOption.${option.name}.name`)
                        : `+ ${leftFunctionalitiesCount}`
                    }
                    size="small"
                    mode="outline"
                    type="neutro"
                  ></Tag>
                )
              );
            });
          break;
        default:
          break;
      }
    });

    const vehicleDropDownList = [
      {
        id: 0,
        title: t("common.view"),
        onClick: () =>
          navigate("/admin/vehicles/view/" + fleetView.id + "/" + +vehicle.id),
      },
      {
        id: 1,
        title: t("common.edit"),
        onClick: () =>
          navigate("/admin/vehicles/edit/" + fleetView.id + "/" + vehicle.id),
      },

      {
        id: 2,
        title: t("common.actionOption.deactivate"),
        onClick: () => {
          /*initial state is empty*/
        },
      },
    ];

    if (vehicle.status && vehicle.status === vehicleStatusValues.ACTIVE) {
      vehicleDropDownList[2].title = t("common.actionOption.deactivate");
      vehicleDropDownList[2].onClick = () => {
        navigate(
          "/admin/vehicles/deactivate/" + vehicle.fleet + "/" + vehicle.id
        );
      };
    } else {
      vehicleDropDownList[2].title = t("common.actionOption.activate");
      vehicleDropDownList[2].onClick = () => {
        navigate(
          "/admin/vehicles/activate/" + vehicle.fleet + "/" + vehicle.id
        );
      };
    }
    if (!writePermission) {
      // hide edit and activate/deactivate
      vehicleDropDownList.splice(1, 2);
    }
    row.render.action = (
      <DropdownButton aspect="ghost" size="small" list={vehicleDropDownList} />
    );
    return row;
  };
}
