import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { IconMap } from "../Icon/Line/Map";
import { IconSatellite } from "../Icon/Solid/Satellite";

import "./MapType.css";
import { Tooltip } from "../Forms/Tooltip";
import i18next from "i18next";

export const MapType = ({
  mapEnable,
  satelliteEnable,
  activeSatellite,
  handleMap,
  handleSatellite,
  className,
  ...props
}) => {
  const [isSatellite, setIsSatellite] = useState(false);
  const [satelliteStyle, setSatelliteStyle] = useState("");
  const [mapStyle, setMapStyle] = useState("");

  useEffect(() => {
    setIsSatellite(activeSatellite);
  }, [activeSatellite]);

  useEffect(() => {
    if (isSatellite) {
      setSatelliteStyle(
        "maptype-control-satellite maptype-control-is-satellite"
      );
      setMapStyle("");
    } else {
      setMapStyle("maptype-control-map maptype-control-is-map");
      setSatelliteStyle("");
    }
  }, [isSatellite]);

  return (
    <>
      <Tooltip />
      <div className={["maptype-control", className].join(" ")} {...props}>
        <button
          className={mapStyle}
          data-for="tooltip"
          data-tip={i18next.t("tooltip.map")}
          onClick={() => {
            handleMap();
            setIsSatellite(false);
          }}
          disabled={!mapEnable ? "disabled" : null}
        >
          <IconMap color="--global-colors-ui-white" size={14} />
        </button>
        <button
          className={satelliteStyle}
          data-for="tooltip"
          data-tip={i18next.t("tooltip.satellite")}
          onClick={() => {
            handleSatellite();
            setIsSatellite(true);
          }}
          disabled={!satelliteEnable ? "disabled" : null}
        >
          <IconSatellite color="--global-colors-ui-white" size={14} />
        </button>
      </div>
    </>
  );
};

MapType.propTypes = {
  mapEnable: PropTypes.bool,
  satelliteEnable: PropTypes.bool,
};

MapType.defaultProps = {
  mapEnable: true,
  satelliteEnable: true,
};
