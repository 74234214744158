import dayjs from "dayjs";
import i18next, { t } from "i18next";
import _ from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";
import { useAppSelector } from "../../../app/hooks";
import { store } from "../../../app/store";
import { GTFleetErrorCodes } from "../../../config/GTfleetErrorCodes";
import Form from "../../../ui/Forms/Form";
import { SingleDatePicker } from "../../../ui/Forms/SingleDatePicker";
import TextField from "../../../ui/Forms/TextField";
import { IconDollar } from "../../../ui/Icon/Line/Dollar";
import { IconEdit } from "../../../ui/Icon/Line/Edit";
import { IconEuro } from "../../../ui/Icon/Line/Euro";
import { IconPound } from "../../../ui/Icon/Line/Pound";
import { IconUpload } from "../../../ui/Icon/Line/Upload";
import { LabelText } from "../../../ui/LabelText/LabelText";
import { ModalBody } from "../../../ui/Modal/ModalBody";
import { ModalFooter } from "../../../ui/Modal/ModalFooter";
import { ModalWrapper } from "../../../ui/Modal/ModalWrapper";
import { ToastNotification } from "../../../utils/ToastNotification";
import {
  addZeroes,
  convertEuroOnCurrency,
  kmToMt,
  mtToKm,
  numberAnnotation,
} from "../../../utils/Utils";
import { Preferences } from "../../users/preference/preferencesSlice";
import UserContext from "../../users/userContext";
import { Vehicle, vehiclesSelectors } from "../../vehicle/vehiclesSlice";
import {
  getVehicleAsync,
  selectVehiclesStatusSliceReasonCode,
  selectVehiclesStatusSliceStatus,
} from "../../vehicle/vehiclesStatusSlice";

import {
  Deadline,
  updateDeadlineAsync,
  uploadFileDeadlineAsync,
} from "../deadlinesSlice";

import "./CompletedMaintenanceModal.css";

interface ViewEditCompletedMaintenanceModalProps {
  type: string;
  deadlineState: Deadline;
  open: boolean;
  onClose: () => any;
  deadlineId: number;
  deadlineType: string;
  vehicleId: number;
  fleetId: number;
}

export const ViewEditCompletedMaintenanceModal: React.FC<ViewEditCompletedMaintenanceModalProps> =
  ({
    type,
    deadlineState,
    open,
    onClose,
    deadlineId,
    deadlineType,
    vehicleId,
    fleetId,
  }) => {
    //#region const

    const [isEditedDistance, setIsEditedDistance] = useState<boolean>(false);
    const [isEditedDate, setIsEditedDate] = useState<boolean>(false);
    const [isEditedAmount, setIsEditedAmount] = useState<boolean>(false);
    const [isEditedUploadFile, setIsEditedUploadFile] =
      useState<boolean>(false);
    const [isEditedNote, setIsEditedNote] = useState<boolean>(false);
    const [fileUpload, setFileUpload] = useState({} as File);
    const [errorList, setErrorList] = useState<string[]>([]);
    const [disabled, setDisabled] = useState<boolean>(true);

    const [editCompletedMaintenance, setEditCompletedMaintenance] = useState(
      {} as Partial<Deadline>
    );

    const vehicle: Vehicle = vehiclesSelectors.selectById(
      store.getState(),
      vehicleId
    ) as Vehicle;

    const vehicleStatusSliceStatus = useAppSelector(
      selectVehiclesStatusSliceStatus
    );
    const vehicleStatusSliceReasonCode = useAppSelector(
      selectVehiclesStatusSliceReasonCode
    );

    const valueSelectedInitialKM = mtToKm(deadlineState.currentOdometer) ?? 0;
    const valueSelectedInitialUtilization =
      deadlineState.currentUtilizationMinutes ?? 0;

    const [value, setValue] = useState<string>("");
    const MAX_VAL = 1000000;
    const withValueLimit = ({ floatValue }: { floatValue: any }) =>
      floatValue <= MAX_VAL;
    const [preferencesContext]: [Preferences] = useContext(UserContext);
    dayjs.locale(preferencesContext.language ?? "en");
    //#endregion const

    //#region NumberFormat
    if (editCompletedMaintenance?.amount && value === "") {
      addZeroes(
        editCompletedMaintenance.amount.toString(),
        setValue,
        preferencesContext
      );
    }
    //#endregion NumberFormat

    useEffect(() => {
      if (
        vehicleStatusSliceStatus === "failed" &&
        vehicleStatusSliceReasonCode ===
          GTFleetErrorCodes.VEHICLE_STATUS_NOT_FOUND
      ) {
        console.error(t("common.vehicleStatusError"));
        ToastNotification({
          toastId: "vehicleStatusError",
          status: "error",
          description: t("common.vehicleStatusError"),
        });
      } else if (
        vehicleStatusSliceStatus === "failed" &&
        vehicleStatusSliceReasonCode === ""
      ) {
        ToastNotification({
          toastId: "networkError",
          status: "error",
          description: t("common.networkError"),
        });
      }
    }, [vehicleStatusSliceStatus, vehicleStatusSliceReasonCode]);

    //#region methods for updateDeadline after confirm
    useEffect(() => {
      setEditCompletedMaintenance({
        currentOdometer: mtToKm(deadlineState.currentOdometer) ?? 0,
        expirationDate: deadlineState.expirationDate,
        amount: deadlineState.amount ?? 0,
        uploadFile: deadlineState.uploadFile ?? "",
        note: deadlineState.note ?? "",
        isMaintenance: deadlineState.isMaintenance,
      } as Partial<Deadline>);
      store.dispatch(getVehicleAsync({ id: vehicleId, fleetId: fleetId }));
    }, []);

    const handleSubmit = () => {
      const expirationDate =
        editCompletedMaintenance?.expirationDate &&
        new Date(editCompletedMaintenance.expirationDate);
      expirationDate?.setHours(8);
      const deadlineToUpdate: Partial<Deadline> = {
        ...editCompletedMaintenance,
        currentOdometer: editCompletedMaintenance.currentOdometer
          ? kmToMt(editCompletedMaintenance.currentOdometer)
          : undefined,
        uploadFile: fileUpload.name,
        complete: true,
        status: null,
        note: editCompletedMaintenance.note,
        expirationDate: expirationDate,
        amount: editCompletedMaintenance.amount,
      };
      store.dispatch(
        updateDeadlineAsync({
          id: deadlineId,
          deadline: deadlineToUpdate,
        })
      );
      !!fileUpload.name &&
        store.dispatch(
          uploadFileDeadlineAsync({
            file: fileUpload,
            type: `Maintenance/Vehicles/${vehicleId}`,
          })
        );
      onClose();
    };
    //#endregion methods for updateDeadline after confirm

    //#region convert km or miles with decimal sign
    function convertNumber(num: string) {
      const { format } = new Intl.NumberFormat(i18next.language);
      const [, decimalSign]: any = /^0(.)1$/.exec(format(0.1));
      return +num
        .replace(new RegExp(`[^${decimalSign}\\d]`, "g"), "")
        .replace(decimalSign, ".");
    }
    //#endregion

    //#region methods for uploadFile
    const [originalFileName, setOriginalFileName] = useState("");
    const change = (event: any) => {
      setOriginalFileName(event.target.files[0].name);

      let renameFile = event.target.files[0].slice(
        0,
        event.target.files[0].size,
        event.target.files[0].type
      );
      let newFileUpload = new File(
        [renameFile],
        `${vehicleId}_${deadlineType}_${new Date().toISOString()}${event.target.files[0].name.slice(
          event.target.files[0].name.lastIndexOf(".")
        )}`, //rename the file upload with vehicleID, type of maintenance, today date and format of file
        { type: event.target.files[0].type }
      );
      setFileUpload(newFileUpload);

      setIsEditedUploadFile(true);
      setEditCompletedMaintenance({
        ...editCompletedMaintenance,
        uploadFile: event.target.files[0].name,
      });
    };

    const click = (ev: any) => {
      ev.target.value = null;
    };
    //#endregion methods for uploadFile

    const classNameAmount =
      deadlineState.isMaintenance === true && disabled === false
        ? "right-forms-maintenance"
        : deadlineState.isMaintenance === false && disabled === false
        ? "right-forms-deadline"
        : deadlineState.isMaintenance === true && disabled === true
        ? "right-forms-maintenance view-edit-completed-maintenance_view"
        : "right-forms-deadline view-edit-completed-maintenance_view";

    return (
      <div className="completed-maintenance-modal">
        <ModalWrapper open={open} closeAction={onClose}>
          <ModalBody
            title={`${type} ${t("customModals.completedMaintenance.title")}`}
            desc={`${t(
              "customModals.completedMaintenance.descViewEdit"
            )} ${vehicleId} - ${vehicle?.plate}`}
          >
            {deadlineState.isMaintenance && (
              <div className="completed-maintenance-odometer">
                {deadlineState.currentUtilizationMinutes ? (
                  <Form>
                    <div className="left-forms-first">
                      <LabelText
                        text={`${t(
                          "customModals.completedMaintenance.utilization"
                        )} ${"(h)"}`}
                        label={""}
                      />
                    </div>
                    <div className="right-forms">
                      <TextField
                        id="utilizationTime"
                        type="text"
                        placeholder="0"
                        maxLength="6"
                        formattedNumber={true}
                        events={{
                          onChange: (data) => {
                            if (
                              data.value !==
                              deadlineState.currentUtilizationMinutes.toString()
                            ) {
                              setIsEditedDistance(true);
                              setEditCompletedMaintenance({
                                ...editCompletedMaintenance,
                                currentUtilizationMinutes: data.value,
                              });
                            } else {
                              setIsEditedDistance(false);
                              setEditCompletedMaintenance({
                                ...editCompletedMaintenance,
                                currentUtilizationMinutes:
                                  deadlineState.currentUtilizationMinutes, // restoring the initial value
                              });
                            }
                          },
                        }}
                        value={numberAnnotation(
                          editCompletedMaintenance.currentUtilizationMinutes ??
                            valueSelectedInitialUtilization
                        )}
                        errorList={setErrorList}
                      />
                    </div>
                  </Form>
                ) : (
                  <Form>
                    <div className="left-forms-first">
                      <LabelText
                        text={`${t(
                          "customModals.completedMaintenance.odometer"
                        )} ${preferencesContext.isMetric ? "(km)" : "(mil)"}`}
                        label={""}
                      />
                    </div>
                    <div
                      className={
                        disabled === true
                          ? "right-forms view-edit-completed-maintenance_view"
                          : "right-forms"
                      }
                    >
                      <TextField
                        id="kmTraveled"
                        type="text"
                        placeholder="0"
                        maxLength="8"
                        formattedNumber={true}
                        events={{
                          onChange: (data) => {
                            if (
                              data.value !==
                              deadlineState.currentOdometer.toString()
                            ) {
                              setIsEditedDistance(true);
                            } else {
                              setIsEditedDistance(false);
                            }
                            setEditCompletedMaintenance({
                              ...editCompletedMaintenance,
                              currentOdometer: data.value, // setting the updated value
                            });
                          },
                        }}
                        value={
                          (isEditedDistance
                            ? convertNumber(
                                editCompletedMaintenance.currentOdometer?.toString() ??
                                  ""
                              ).toLocaleString(i18next.language)
                            : convertNumber(
                                valueSelectedInitialKM.toString()
                              ).toLocaleString(i18next.language)) ?? ""
                        }
                        errorList={setErrorList}
                      />
                    </div>
                  </Form>
                )}
              </div>
            )}
            <div className="completed-maintenance-date">
              <Form>
                <div className="left-forms-second">
                  <LabelText
                    text={
                      deadlineState.isMaintenance
                        ? t("customModals.completedMaintenance.secondRowMaint")
                        : t("customModals.completedMaintenance.secondRowDead")
                    }
                    label={""}
                  />
                </div>
                <div
                  className={
                    disabled === true
                      ? "right-forms view-edit-completed-maintenance_view"
                      : "right-forms"
                  }
                >
                  <SingleDatePicker
                    id="maintenanceDeadline"
                    setErrorList={setErrorList}
                    errorList={errorList}
                    dateRange={[
                      dayjs().subtract(1, "year"),
                      dayjs().add(10, "year"),
                    ]}
                    setDate={(data) => {
                      if (
                        data !==
                        dayjs(deadlineState.expirationDate).format("YYYY-MM-DD")
                      ) {
                        setIsEditedDate(true);
                      } else {
                        setIsEditedDate(false);
                      }
                      setEditCompletedMaintenance({
                        ...editCompletedMaintenance,
                        expirationDate: data, // setting the updated value
                      });
                    }}
                    placeholder=""
                    oldDate={
                      deadlineState.expirationDate
                        ? deadlineState.expirationDate.toString()
                        : ""
                    }
                    localeFormat={
                      preferencesContext.localeFormat ?? "DD / MM / YYYY"
                    }
                    language={preferencesContext.language ?? "it"}
                  />
                </div>
              </Form>
            </div>
            <div className="completed-maintenance-amount">
              <Form>
                <div className="left-forms-first">
                  <LabelText
                    text={t("customModals.completedMaintenance.amount")}
                    label={""}
                  />
                </div>
                <div className={classNameAmount}>
                  <NumericFormat
                    id="amount"
                    placeholder={
                      preferencesContext.language === "it" ? "0,00" : "0.00"
                    }
                    value={
                      value != null
                        ? numberAnnotation(
                            convertEuroOnCurrency(
                              preferencesContext.currency,
                              Number.parseFloat(value)
                            ),
                            2,
                            2
                          )
                        : numberAnnotation(0, 2, 2)
                    }
                    thousandSeparator={
                      preferencesContext.language === "it" ? "." : ","
                    }
                    decimalSeparator={
                      preferencesContext.language === "it" ? "," : "."
                    }
                    isAllowed={withValueLimit}
                    decimalScale={2}
                    valueIsNumericString={true}
                    onValueChange={(values) => {
                      setValue(values.value);
                      if (
                        Number(values.value) !== editCompletedMaintenance.amount
                      ) {
                        setIsEditedAmount(true);
                      } else {
                        setIsEditedAmount(false);
                      }
                      setEditCompletedMaintenance({
                        ...editCompletedMaintenance,
                        amount: Number(values.value), // setting the updated value
                      });
                    }}
                    onBlur={() => {
                      addZeroes(value, setValue, preferencesContext);
                    }}
                  />
                  {preferencesContext.currency === "EURO" ? (
                    <IconEuro
                      size={14}
                      color="--global-colors-ink-light"
                      id="iconEuro"
                    />
                  ) : preferencesContext.currency === "DOLLAR" ? (
                    <IconDollar
                      size={14}
                      color="--global-colors-ink-light"
                      id="iconDollar"
                    />
                  ) : (
                    <IconPound
                      size={14}
                      color="--global-colors-ink-light"
                      id="iconPound"
                    />
                  )}
                </div>
              </Form>
            </div>
            <div className="completed-maintenance-upload">
              <Form>
                <div className="left-forms-first">
                  <LabelText
                    text={t(
                      "customModals.completedMaintenance.uploadedInvoice"
                    )}
                    label={""}
                  />
                </div>
                <>
                  <div
                    className={
                      disabled === true
                        ? "right-forms view-edit-completed-maintenance_view"
                        : "right-forms"
                    }
                  >
                    <TextField
                      id="upload"
                      autoComplete="off"
                      type="text"
                      placeholder={t(
                        "customModals.completedMaintenance.uploadFile"
                      )}
                      events={{
                        onClick: () => {
                          document.getElementById("inputFile")?.click();
                        },
                      }}
                      value={
                        editCompletedMaintenance.uploadFile
                          ? editCompletedMaintenance.uploadFile
                          : originalFileName
                      }
                    >
                      <IconUpload size={14} color="--global-colors-ink-light" />
                    </TextField>
                    {!_.isEmpty(editCompletedMaintenance.uploadFile) && (
                      <span className="tooltiptext">
                        {originalFileName !== ""
                          ? originalFileName
                          : editCompletedMaintenance.uploadFile}
                      </span>
                    )}
                  </div>
                  <input
                    id="inputFile"
                    type="file"
                    autoComplete="off"
                    accept="image/png, image/jpeg, image/jpg, image/gif, application/pdf"
                    style={{ display: "none" }}
                    onChange={change}
                    onClick={click}
                  />
                </>
              </Form>
            </div>
            <div className="completed-maintenance-note">
              <Form>
                <div className="left-forms">
                  <LabelText text="Note" label={""} />
                </div>
                <div
                  className={
                    disabled === true
                      ? "right-forms view-edit-completed-maintenance_view"
                      : "right-forms"
                  }
                >
                  <TextField
                    id="notes"
                    type="textarea"
                    placeholder={t("customModals.completedMaintenance.notes")}
                    events={{
                      onChange: (data) => {
                        if (data.value !== deadlineState.note) {
                          setIsEditedNote(true);
                        } else {
                          setIsEditedNote(false);
                        }
                        setEditCompletedMaintenance({
                          ...editCompletedMaintenance,
                          note: data.value, // setting the updated value
                        });
                      },
                    }}
                    value={editCompletedMaintenance.note ?? ""}
                    errorList={setErrorList}
                  />
                </div>
              </Form>
            </div>
          </ModalBody>
          <ModalFooter
            primaryLabel={
              disabled === true ? t("common.edit") : t("common.save")
            }
            primaryAction={() => {
              setDisabled(false);
              disabled === false && handleSubmit();
              disabled === false && onClose();
            }}
            disablePrimaryButton={
              disabled === false &&
              !(
                isEditedDistance ||
                isEditedDate ||
                isEditedAmount ||
                isEditedUploadFile ||
                isEditedNote
              )
            }
            iconFooter={
              disabled === true ? <IconEdit color="" size={14} /> : ""
            }
            secondaryLabel={t(
              "customModals.completedMaintenance.secondaryLabel"
            )}
            secondaryAction={() => {
              onClose();
            }}
          />
        </ModalWrapper>
      </div>
    );
  };
