import React, { useEffect, useState } from "react";
import { IconVehicle } from "../Icon/Line/Vehicle";
import { BaseMap, ComponentOnMap } from "../Map/BaseMap";
import { LocationInformation } from "../Map/LocationInformation";
import { MarkerAsComponent } from "../Map/MarkerAsComponent";
import { MapMarkerCustomIcon } from "../Marker/MapMarkerCustomIcon";
import "./LocationMap.css";

export interface PositionProps {
  lat: number;
  long: number;
  location?: string;
  time: string;
  vehicleStatus: string;
}

interface LocationMapProps {
  position: PositionProps | undefined;
}

export const LocationMap: React.FC<LocationMapProps> = ({ position }) => {
  let googleMap = window.google;
  const [map, setMap] = useState<google.maps.Map>();
  const [componentsOnMap, setComponentsOnMap] = useState<ComponentOnMap[]>([]);
  const [isMapIdle, setIsMapIdle] = useState(false);
  const [currentPositionMarker, setCurrentPositionMarker] = useState<any>();
  //#region this method is in charge to add components on map
  useEffect(() => {
    if (googleMap && map && position) {
      if (currentPositionMarker) {
        // marker already exists -> update only location and status
        currentPositionMarker.setPosition(position.lat, position.long);
        currentPositionMarker.setComponent(
          <MapMarkerCustomIcon
            status={position.vehicleStatus}
            iconSelected={
              <IconVehicle size={17} color="--global-colors-ui-white" />
            }
          />
        );
      } else {
        // create marker
        setCurrentPositionMarker(
          MarkerAsComponent({
            id: "locationMapMarker",
            googleMap: googleMap,
            lat: position.lat,
            lng: position.long,
            map: map,
            component: (
              <MapMarkerCustomIcon
                status={position.vehicleStatus}
                iconSelected={
                  <IconVehicle size={17} color="--global-colors-ui-white" />
                }
              />
            ),
          })
        );
      }

      if (isMapIdle) {
        setComponentsOnMap([
          {
            selectorName: ".locationInformation",
            selectorPosition: googleMap.maps.ControlPosition.BOTTOM_LEFT,
            component: (
              <div className="locationInformation">
                <LocationInformation
                  location={position.location ?? ""}
                  time={position.time}
                />
              </div>
            ),
          },
        ]);
      }
    } else if (!position && currentPositionMarker) {
      setCurrentPositionMarker(undefined);
      setMap(undefined);
      setIsMapIdle(false);
      setComponentsOnMap([]); // Clear components on map when position is undefined
    }
  }, [map, googleMap, position, isMapIdle]);

  return (
    <>
      {position ? (
        <div className="mn-location-map">
          <BaseMap
            id={0}
            googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY!}
            zoom={17}
            latitude={position.lat}
            longitude={position.long}
            getMap={setMap}
            draggable={false}
            getIsMapIdle={setIsMapIdle}
          >
            {componentsOnMap}
          </BaseMap>
        </div>
      ) : (
        <div className="mn-location-map-empty">
          <BaseMap
            id={0}
            googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY!}
            zoom={17}
            latitude={41.9027835}
            longitude={12.4963655}
            getMap={setMap}
            draggable={false}
            getIsMapIdle={setIsMapIdle}
          />
        </div>
      )}
    </>
  );
};
