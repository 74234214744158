import { t } from "i18next";
import { IconDownload } from "../../ui/Icon/Line/Download";
import { LabelText } from "../../ui/LabelText/LabelText";
import {
  AbstractTableBuilder,
  Row,
} from "../../ui/Table/utils/AbstractTableBuilder";
import "../../ui/Table/utils/TableBuilder.css";
import { ConvertTimeZone } from "../../utils/DateAndTimeUtils";
import { downloadFileFromS3 } from "../../utils/S3Client";
import { Driver } from "../driver/driversSlice";
import { Preferences } from "../users/preference/preferencesSlice";
import { Vehicle } from "../vehicle/vehiclesSlice";

export interface FileArchiveModalTableBuilderObject {
  downloadDate?: Date;
  generationDate?: Date;
  fileName?: string;
  path?: string;
  period?: [Date, Date];
}

export interface FileArchiveModalTableBuilderObjectPath {
  downloadDate?: string;
  generationDate?: string;
  fileName?: string;
  path?: string;
  period?: [string, string];
}

export class FileArchiveModalTableBuilder extends AbstractTableBuilder {
  rowsBuilder = (
    columns: { label: string; field: string; sort: boolean }[] | undefined,
    entities: {
      entity: FileArchiveModalTableBuilderObject;
      vehicle?: Vehicle;
      driver?: Driver;
    },
    preferencesContext: Preferences,
    bucket?: string
  ) => {
    let row: Row = { render: {} as Row };

    let entity: any = entities.entity ?? {};

    let vehicle: Vehicle = entities.vehicle ?? ({} as Vehicle);

    let driver: Driver = entities.driver ?? ({} as Driver);

    columns?.forEach((column) => {
      switch (column.field) {
        case "vehicle":
          row.vehicle = vehicle.alias ?? t("common.na");
          row.render.vehicle = (
            <LabelText
              label={vehicle.alias ?? t("common.na")}
              text={
                vehicle.brand
                  ? vehicle.brand + " " + vehicle.model
                  : t("common.na")
              }
            />
          );
          break;
        case "driver":
          row.render.driver = (
            <LabelText
              label={driver.id ? driver.id + "" : t("common.na")}
              text={
                driver.firstName
                  ? driver.firstName + " " + driver.lastName
                  : t("common.na")
              }
            />
          );
          break;
        case "vehicle_driver":
          row.render.vehicle_driver = t("common.all");
          break;
        case "period":
          row.render.generationDate = entity.period
            ? entity.period[0] + " - " + entity.period[1]
            : t("common.na");
          break;
        case "downloadDate":
          row.render.downloadDate = entity.downloadDate
            ? ConvertTimeZone(
                entity.downloadDate,
                preferencesContext.timeZone ?? undefined,
                preferencesContext.localeFormat ?? undefined
              )
            : t("common.na");
          break;
        case "generationDate":
          row.render.generationDate = entity.generationDate
            ? ConvertTimeZone(
                entity.generationDate,
                preferencesContext.timeZone ?? undefined,
                preferencesContext.localeFormat ?? undefined
              )
            : t("common.na");
          break;
        case "fileName":
          row.render.fileName = entity.fileName
            ? entity.fileName
            : t("common.na");
          break;
        case "download":
          row.render.download = (
            <span data-for="tooltip" data-tip={entity.fileName}>
              <button
                onClick={() => {
                  if (
                    entity.path &&
                    entity.path !== "" &&
                    (entity.path.includes(".pdf") ||
                      entity.path.includes(".DDD")) &&
                    entity.fileName &&
                    entity.fileName != "" &&
                    (entity.fileName.includes(".pdf") ||
                      entity.fileName.includes(".DDD")) &&
                    bucket
                  ) {
                    downloadFileFromS3(entity.path, entity.fileName, bucket);
                  }
                }}
              >
                <IconDownload color="--global-colors-ink-ink" size={14} />
              </button>
            </span>
          );
          break;
        default:
          break;
      }
    });

    return row;
  };
}
