import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Card } from "../Card/Card";
import "./VehicleActivity.css";
import { Pie } from "../Chart/Pie";

export const VehicleActivity = ({ data, labels, series, size }) => {
  const { t } = useTranslation();

  const labelsTranslated = labels.map((item) =>
    t("report.overview.vehicleActivity." + item.toLowerCase())
  );

  return (
    <Card
      title={t("report.overview.vehicleActivity.title")}
      linkAction={() => {
        window.open(`/dashboard/fleet-control`, "_blank").focus();
      }}
      linkAnchor={t("report.overview.vehicleActivity.linkAnchor")}
      className="mn-vehicle-activity"
    >
      <div>
        <div className="mn-vehicle-activity__inner">
          <Pie labels={labelsTranslated} series={series} size={size} />
        </div>
        <div className="marker-list">
          {data.map((element, index) => {
            return (
              <ul key={index}>
                <div className="marker-list-title-overview">
                  {t(
                    "report.overview.vehicleActivity." +
                      element.title.toLowerCase()
                  )}
                </div>
                {element.vehicles.length <= 2 ? (
                  <>
                    {element.vehicles.map((item, index) => {
                      return (
                        <li key={item + index}>
                          <div
                            className={`marker-${element.title.toLowerCase()}-circle`}
                          />
                          {item}
                        </li>
                      );
                    })}
                  </>
                ) : (
                  <>
                    <li>
                      <div
                        className={`marker-${element.title.toLowerCase()}-circle`}
                      />
                      {element.vehicles[0]}
                    </li>
                    <li>
                      <div
                        className={`marker-${element.title.toLowerCase()}-circle`}
                      />
                      {element.vehicles[1]}
                    </li>
                    <li>
                      <div
                        className={`marker-${element.title.toLowerCase()}-circle`}
                      />
                      <span>...</span>
                    </li>
                  </>
                )}
              </ul>
            );
          })}
        </div>
      </div>
    </Card>
  );
};

VehicleActivity.propTypes = {
  /**
   * Series of info for pie chart and plates.
   *
   */
  data: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      vehicles: PropTypes.arrayOf(PropTypes.string),
    })
  ).isRequired,
  /**
   * Series of labels to render.
   *
   */
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,
  /**
   * Series of data to render.
   *
   */
  series: PropTypes.arrayOf(PropTypes.number).isRequired,
  /**
   * Size of the chart.
   */
  size: PropTypes.shape({
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
};

VehicleActivity.defaultProps = {
  size: { width: "162", height: "162" },
};
