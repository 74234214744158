import {
  PayloadAction,
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import _ from "lodash";
import { RootState } from "../../../app/store";
import { Config } from "../../../config/Config";
import { GTFleetSuccessCodes } from "../../../config/GTFleetSuccessCodes";
import PublicRoutePresetRepository from "./publicRoutePresetRepository";

//ENUM REGION

export const PublicRoutePlanningStatusEnum = {
  TO_COMPILE: "TO_COMPILE",
  TO_COMPLETE: "TO_COMPLETE",
  COMPLETED: "COMPLETED",
};

export type PublicRoutePlanningStatusEnum =
  keyof typeof PublicRoutePlanningStatusEnum;

//ENUM ENDREGION

export interface PublicRoutePreset {
  id: number;
  startRange: Date;
  endRange: Date;
  status: PublicRoutePlanningStatusEnum;
}

export const getPublicRoutePresetsAsync = createAsyncThunk(
  "publicRoutePresets/getPublicRoutePresets",
  async (_data, { rejectWithValue }) => {
    try {
      const publicRoutePresetRepository = new PublicRoutePresetRepository();
      const response =
        await publicRoutePresetRepository.getPublicRoutePresets();
      const PublicRoutePreset = _.get(
        response,
        Config.PUBLIC_ROUTE_PLANNING_SUMMARY
      );
      return PublicRoutePreset;
    } catch (err: any) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data.message);
    }
  }
);

const publicRoutePresetAdapter = createEntityAdapter<PublicRoutePreset>({
  selectId: (publicRoutePreset) => publicRoutePreset.id,
});

export const publicRoutePresetSlice = createSlice({
  name: "publicRoutePreset",
  initialState: publicRoutePresetAdapter.getInitialState({
    status: "idle",
    reasonCode: "",
  }),
  reducers: {
    publicRoutePresetEmptyState: (state: any) => {
      publicRoutePresetAdapter.setAll(state, []);
      state.reasonCode = "";
      state.status = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPublicRoutePresetsAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(
        getPublicRoutePresetsAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          publicRoutePresetAdapter.upsertMany(state, action.payload ?? []);
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.GET;
        }
      )
      .addCase(getPublicRoutePresetsAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = "";
      });
  },
});

export const publicRoutePresetSelectors =
  publicRoutePresetAdapter.getSelectors<RootState>(
    (state) => state.publicRoutePresets
  );

export const selectPublicRoutePresetStatus = (state: any) =>
  state.publicRoutePresets.status;
export const selectPublicRoutePresetReasonCode = (state: any) =>
  state.publicRoutePresets.reasonCode;
export const { publicRoutePresetEmptyState } = publicRoutePresetSlice.actions;
//#endregion Status

export default publicRoutePresetSlice.reducer;
