import dayjs from "dayjs";
import HttpClient from "../../utils/HttpClient";
export default class RoutesHistoryRepository extends HttpClient {
  constructor() {
    super(true);
  }

  getRoutesHistory(queryParams?: string) {
    const tenantId = localStorage.getItem("tenantId");
    return this.instance.get(
      "tenants/" + tenantId + "/routesHistory" + (queryParams ?? "")
    );
  }

  getRoutesHistoryView(queryParams: string) {
    const queryParamsObj = new URLSearchParams(queryParams);
    const vehicleId = queryParamsObj.get("vehicle.id");
    const dateRange = queryParamsObj.getAll("firstPointDate");
    const routeTypes = queryParamsObj.getAll("routeStateTypeEnum");
    if (dateRange[0]) {
      queryParamsObj.set(
        "startPeriod",
        dayjs(new Date(dateRange[0])).utc().format("YYYY-MM-DDTHH:mm")
      );
    }
    if (dateRange[1]) {
      queryParamsObj.set(
        "endPeriod",
        dayjs(new Date(dateRange[1])).utc().format("YYYY-MM-DDTHH:mm")
      );
    }
    if (routeTypes.length === 1) {
      queryParamsObj.set("routeType", routeTypes[0]);
    }
    const tenantId = localStorage.getItem("tenantId");
    return this.instance.get(
      "tenants/" +
        tenantId +
        "/vehicles/" +
        vehicleId +
        "/travels/view" +
        (queryParamsObj.toString() ? "?" + queryParamsObj.toString() : "")
    );
  }

  readRouteHistoryAsync(
    vehicleId: number,
    routeHistoryId: number,
    queryParams?: string
  ) {
    const tenantId = localStorage.getItem("tenantId");
    const queryParamsObj = new URLSearchParams(queryParams);
    const dateRange = queryParamsObj.getAll("firstPointDate");
    if (dateRange[0]) {
      queryParamsObj.set(
        "startPeriod",
        dayjs(new Date(dateRange[0])).utc().format("YYYY-MM-DDTHH:mm")
      );
    }
    if (dateRange[1]) {
      queryParamsObj.set(
        "endPeriod",
        dayjs(new Date(dateRange[1])).utc().format("YYYY-MM-DDTHH:mm")
      );
    }
    return this.instance.get(
      "tenants/" +
        tenantId +
        "/vehicles/" +
        vehicleId +
        "/travels/" +
        routeHistoryId +
        (queryParams ?? "")
    );
  }
}
