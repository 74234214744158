import dayjs from "dayjs";
import { t } from "i18next";
import React, { useContext, useEffect, useState } from "react";
import { Link, useMatch, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../app/hooks";
import { store } from "../../../app/store";
import PageFilters from "../../../layout/PageFilters";
import { Tooltip } from "../../../ui/Forms/Tooltip";
import { IconClock } from "../../../ui/Icon/Line/Clock";
import { IconDown } from "../../../ui/Icon/Line/Down";
import { IconExternalLink } from "../../../ui/Icon/Line/ExternalLink";
import { IconUp } from "../../../ui/Icon/Line/Up";
import { PageCounter } from "../../../ui/Table/PageCounter";
import { Table } from "../../../ui/Table/Table";
import { ConvertTimeZone, formatTime } from "../../../utils/DateAndTimeUtils";
import { getQueryString } from "../../../utils/Utils";
import { fleetViewsSelectors } from "../../fleet/fleetViewsSlice";
import { Preferences } from "../../users/preference/preferencesSlice";
import { Preset, presetsSelectors } from "../../users/preset/presetsSlice";
import { UserPermissions } from "../../users/privilege/privilegesSlice";
import UserContext from "../../users/userContext";
import { vehiclesSelectors } from "../../vehicle/vehiclesSlice";
import "./GeofenceReport.css";
import { GeofenceReportDetails } from "./GeofenceReportDetails";
import { GeofenceReportFilterBar } from "./GeofenceReportFilterBar";
import {
  ReportGeofenceSummary,
  reportGeofenceSummarySelectors,
  reportsGeofenceSummaryEmptyState,
} from "./GeofenceReportsSummarySlice";
import {
  GeofenceReport as GeofenceReportType,
  ReportGeofenceData,
  geofencesReportsEmptyState,
  geofencesReportsSelectors,
  selectGeofencesReportsSliceStatus,
} from "./GeofencesReportsSlice";

interface GeofenceReportProps {
  permissions: UserPermissions;
}

export const GeofenceReport: React.FC<GeofenceReportProps> = ({
  permissions,
}) => {
  const navigate = useNavigate();

  const [queryParamsFromFilterBar, setQueryParamsFromFilterBar] =
    useState<string>("");

  const [preferencesContext]: [Preferences] = useContext(UserContext);

  //#region slices and statuses
  let presets: Preset[] = useAppSelector(presetsSelectors.selectAll);

  const reportsGeofenceSummary: ReportGeofenceSummary[] = useAppSelector(
    reportGeofenceSummarySelectors.selectAll
  );

  const geofencesReports: ReportGeofenceData[] = useAppSelector(
    geofencesReportsSelectors.selectAll
  );

  const geofencesReportsSliceStatus = useAppSelector(
    selectGeofencesReportsSliceStatus
  );
  //#endregion

  //#region pagination
  const [numPages, setNumPages] = useState(0);

  const [pagedGeofencesReports, setPagedGeofencesReports] = useState<
    ReportGeofenceData[]
  >(geofencesReports.slice(0, 9));

  const getGeofenceType = (eventRow: GeofenceReportType) => {
    if (!!eventRow.inEvent && !!eventRow.outEvent) {
      return t("report.details.trackEventType");
    }
    if (!eventRow.inEvent && !!eventRow.outEvent) {
      return t("report.details.outEventType");
    }
    if (!!eventRow.inEvent && !eventRow.outEvent) {
      return t("report.details.inEventType");
    }
    if (!eventRow.inEvent && !eventRow.outEvent) {
      return t("report.details.stopEventType");
    }
    return t("common.na");
  };

  useEffect(() => {
    if (geofencesReports.length > 0) {
      setPagedGeofencesReports(geofencesReports.slice(0, 9));
      let numPageDivided = geofencesReports.length / 10;
      setNumPages(
        Math.floor(numPageDivided) === numPageDivided
          ? numPageDivided
          : Math.floor(numPageDivided) + 1
      );
    } else {
      setPagedGeofencesReports([]);
      setNumPages(0);
    }
  }, [geofencesReports]);
  //#endregion pagination

  const geofenceRoute = useMatch(`/reports/geofence-report/`);
  let isGeofenceRoute = geofenceRoute !== null;

  useEffect(() => {
    if (queryParamsFromFilterBar !== "") {
      if (isGeofenceRoute) {
        navigate({
          pathname: "/reports/geofence-report",
          search: queryParamsFromFilterBar,
        });
      }
    }
  }, [queryParamsFromFilterBar]);

  //#region Table Builder
  const handleEventTime = (eventRow: GeofenceReportType) => {
    if (eventRow.stopDate !== null) return eventRow.stopDate;
    else if (eventRow.inEvent !== null) return eventRow.inEvent;
    else return eventRow.outEvent;
  };

  function buildGeofenceReportTable() {
    return {
      columns: [
        {
          label: t("table.columns.vehicle"),
          field: "vehicle",
        },
        {
          label: t("table.columns.type"),
          field: "type",
        },
        {
          label: t("table.columns.gpsDataTimestamp"),
          field: "date",
        },
        {
          label: t("table.columns.geofence"),
          field: "geofence",
        },
        {
          label: t("table.columns.in"),
          field: "inEvent",
        },
        {
          label: t("table.columns.out"),
          field: "outEvent",
        },
        {
          label: t("table.columns.parkingTime"),
          field: "stopTime",
        },
        {
          label: t("table.columns.driveTime"),
          field: "trackTime",
        },
      ],
      rows: pagedGeofencesReports.map((reportRow) => {
        const vehicle = vehiclesSelectors.selectById(
          store.getState(),
          reportRow.id
        );
        const rowsGrouped = reportRow.geofences.map(
          (eventRow: GeofenceReportType) => {
            return {
              render: [
                <div className="show-child" key={"vehicle"}></div>,
                <div className="show-child" key={"routeId"}>
                  {getGeofenceType(eventRow)}
                </div>,
                <div className="show-child" key={"date"}>
                  {ConvertTimeZone(
                    handleEventTime(eventRow),
                    preferencesContext.timeZone,
                    preferencesContext.localeFormat,
                    false,
                    true
                  )}
                </div>,
                <div className="show-child" key={"geofence"}>
                  {eventRow.geofence}
                </div>,
                <div className="show-child" key={"in"}>
                  <div className="inout-row-data">
                    <IconUp size={14} color="--global-colors-ink-light" />
                    {ConvertTimeZone(
                      eventRow.inEvent !== null && eventRow.totalStop === 0
                        ? eventRow.inEvent
                        : "-",
                      preferencesContext.timeZone,
                      preferencesContext.localeFormat,
                      false,
                      false,
                      true
                    )}
                  </div>
                </div>,
                <div className="show-child" key={"out"}>
                  <div className="inout-row-data">
                    <IconDown size={14} color="--global-colors-ink-light" />
                    {ConvertTimeZone(
                      eventRow.outEvent !== null && eventRow.totalStop === 0
                        ? eventRow.outEvent
                        : "-",
                      preferencesContext.timeZone,
                      preferencesContext.localeFormat,
                      false,
                      false,
                      true
                    )}
                  </div>
                </div>,
                <div className="show-child" key={"stopTime"}>
                  <div className="inout-row-data">
                    <IconClock size={14} color="--global-colors-ink-light" />
                    {formatTime(
                      eventRow.totalStop,
                      preferencesContext.language,
                      "-",
                      true,
                      true,
                      true,
                      true,
                      0
                    )}
                  </div>
                </div>,
                <div className="show-child" key={"trackTime"}>
                  <div className="inout-row-data">
                    <IconClock size={14} color="--global-colors-ink-light" />
                    {formatTime(
                      eventRow.totalTrack,
                      preferencesContext.language,
                      "-",
                      true,
                      true,
                      true,
                      true,
                      0
                    )}
                  </div>
                </div>,
                <div className="show-child" key={"action"}>
                  <Tooltip />
                  <div className="inout-row-data">
                    <span data-for="tooltip" data-tip={t("common.showOnMap")}>
                      <Link
                        to={
                          `/dashboard/vehicles/location-history/${
                            eventRow.outEvent !== null ||
                            eventRow.inEvent !== null
                              ? "track"
                              : "stop"
                          }/` +
                          eventRow.routeId +
                          getQueryString({
                            firstPointDate: [
                              dayjs(
                                new Date(
                                  eventRow.inEvent
                                    ? eventRow.inEvent
                                    : eventRow.outEvent
                                    ? eventRow.outEvent
                                    : reportRow.in
                                )
                              )
                                .subtract(1, "day")
                                .format("YYYY/MM/DD HH:mm"),
                              dayjs(
                                new Date(
                                  eventRow.outEvent
                                    ? eventRow.outEvent
                                    : eventRow.inEvent
                                    ? eventRow.inEvent
                                    : reportRow.in
                                )
                              )
                                .add(1, "day")
                                .format("YYYY/MM/DD HH:mm"),
                            ],
                            "vehicle.id": eventRow.vehicleId,
                          })
                        }
                        target="_blank"
                        rel="noreferrer"
                      >
                        <IconExternalLink
                          size={14}
                          color="--global-colors-ink-ink"
                        />
                      </Link>
                    </span>
                  </div>
                </div>,
              ],
            };
          }
        );

        let fleetName = t("common.na");
        if (vehicle) {
          const fleetView = fleetViewsSelectors.selectById(
            store.getState(),
            vehicle?.fleet
          );
          if (fleetView) {
            fleetName = fleetView.name;
          }
        }
        return {
          data: {
            vehicle: { alias: vehicle?.alias, fleet: fleetName },
            routeId: "",
            date:
              ConvertTimeZone(
                reportRow.in,
                preferencesContext.timeZone,
                preferencesContext.localeFormat,
                false,
                true
              ) ===
              ConvertTimeZone(
                reportRow.out,
                preferencesContext.timeZone,
                preferencesContext.localeFormat,
                false,
                true
              )
                ? ConvertTimeZone(
                    reportRow.in,
                    preferencesContext.timeZone,
                    preferencesContext.localeFormat,
                    false,
                    true
                  )
                : ConvertTimeZone(
                    reportRow.in,
                    preferencesContext.timeZone,
                    preferencesContext.localeFormat,
                    false,
                    true
                  ) +
                  " - " +
                  ConvertTimeZone(
                    reportRow.out,
                    preferencesContext.timeZone,
                    preferencesContext.localeFormat,
                    false,
                    true
                  ),
            geofence:
              reportRow.events === 1
                ? reportRow.events + " " + t("common.event")
                : reportRow.events + " " + t("common.events"),
            in: "",
            out: "",
            stopTime: formatTime(
              reportRow.totalStop,
              preferencesContext.language,
              "-",
              true,
              true,
              true,
              true,
              0
            ),
            trackTime: formatTime(
              reportRow.totalTrack,
              preferencesContext.language,
              "-",
              true,
              true,
              true,
              true,
              0
            ),
          },
          rowsGrouped: rowsGrouped,
        };
      }),
    };
  }
  //#endregion Table Builder
  useEffect(() => {
    document.title =
      t("breadcrumbs.geofenceReport") + " - " + t("breadcrumbs.reports");
    return () => {
      store.dispatch(reportsGeofenceSummaryEmptyState());
      store.dispatch(geofencesReportsEmptyState());
    };
  }, []);

  return (
    <React.Fragment>
      <PageFilters>
        <div className="col col-16">
          <GeofenceReportFilterBar
            presets={presets}
            callback={setQueryParamsFromFilterBar}
            disableButton={buildGeofenceReportTable().rows?.length === 0}
          />
        </div>
        {queryParamsFromFilterBar !== "" && (
          <div className="col col-16">
            <div className="details-geofenceReport-wrapper">
              <div className="details-report-title">
                {t("report.details.title")}
                {geofencesReportsSliceStatus === "loading" && (
                  <div className="details-report-loader" />
                )}
              </div>
              <div className="details-report-container">
                <div className="details-report-icons">
                  <GeofenceReportDetails
                    geofenceVehicles={reportsGeofenceSummary[0]?.vehicles}
                    geofences={reportsGeofenceSummary[0]?.geofences}
                    inEvents={reportsGeofenceSummary[0]?.inEvents}
                    outEvents={reportsGeofenceSummary[0]?.outEvents}
                    stopTime={reportsGeofenceSummary[0]?.stopDuration}
                    trackTime={reportsGeofenceSummary[0]?.trackDuration}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </PageFilters>
      {queryParamsFromFilterBar !== "" &&
        buildGeofenceReportTable().rows?.length > 0 && (
          <div className="geofence-report-container">
            <div className="geofence-report-table-container">
              <Table data={buildGeofenceReportTable()} isShowTable={true}>
                <Table.Head hasTableSpace={true} />
                <Table.Body />
              </Table>
            </div>
            <PageCounter
              isActionPerforming={geofencesReportsSliceStatus === "loading"}
              totalElements={geofencesReports ? geofencesReports.length : 0}
              numOfPages={numPages}
              resetPage={true}
              onClick={(id) => {
                // Restore dispatch once Backend Pagination is completed
                const paginatedElements = geofencesReports.slice(
                  10 * (id - 1),
                  10 * id - 1
                );
                setPagedGeofencesReports(paginatedElements);
              }}
            />
          </div>
        )}
    </React.Fragment>
  );
};
