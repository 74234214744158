export const MapMarkerLocator = ({ rotate, ...props }) => {
  const providedOrDefaultDirection = rotate && rotate > 0 ? rotate : 70;
  const style = providedOrDefaultDirection && {
    transform: ` rotate(${providedOrDefaultDirection}deg)`,
  };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="27"
      height="25"
      style={style}
      {...props}
    >
      <defs>
        <path
          d="M3.15490025,1.73564543 L22.5818965,12.4232661 C23.065788,12.6894755 23.2422542,13.2975525 22.9760448,13.781444 C22.8846747,13.9475284 22.7479809,14.0842222 22.5818965,14.1755923 L3.15490025,24.8632131 C2.67100873,25.1294225 2.06293179,24.9529562 1.79672239,24.4690647 C1.62764176,24.1617251 1.63195797,23.7882642 1.80809654,23.4849144 L7.43068668,13.8015648 C7.61096808,13.4910801 7.61096808,13.1077784 7.43068668,12.7972937 L1.80809654,3.11394404 C1.53077475,2.6363343 1.69314022,2.02434137 2.17074997,1.74701958 C2.47409973,1.57088101 2.84756068,1.5665648 3.15490025,1.73564543 Z"
          id="mapMarkerLocator-path-1"
        ></path>

        <filter
          x="-2.6%"
          y="-2.6%"
          width="107.7%"
          height="105.2%"
          filterUnits="objectBoundingBox"
          id="mapMarkerLocator-filter-2"
        >
          <feMorphology
            radius="0.8"
            operator="dilate"
            in="SourceAlpha"
            result="shadowSpreadOuter1"
          ></feMorphology>

          <feOffset
            dx="0"
            dy="0"
            in="shadowSpreadOuter1"
            result="shadowOffsetOuter1"
          ></feOffset>

          <feGaussianBlur
            stdDeviation="0.5"
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          ></feGaussianBlur>

          <feComposite
            in="shadowBlurOuter1"
            in2="SourceAlpha"
            operator="out"
            result="shadowBlurOuter1"
          ></feComposite>

          <feColorMatrix
            values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.2 0"
            type="matrix"
            in="shadowBlurOuter1"
          ></feColorMatrix>
        </filter>
      </defs>

      <g
        id="Image-and-Icons"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Marker" transform="translate(-301.000000, -964.000000)">
          <g id="Group" transform="translate(302.000000, 963.000000)">
            <g
              id="Rectangle"
              transform="translate(12.500000, 13.500000) rotate(-90.000000) translate(-12.500000, -13.500000) "
            >
              <use
                fill="var(--global-colors-ink-dark)"
                fillOpacity="1"
                filter="url(#mapMarkerLocator-filter-2)"
                xlinkHref="#mapMarkerLocator-path-1"
              ></use>

              <use
                stroke="#FFFFFF"
                strokeWidth="1.6"
                fill="#00AAFF"
                fillRule="evenodd"
                xlinkHref="#mapMarkerLocator-path-1"
              ></use>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
