import html2canvas from "html2canvas";
import i18next from "i18next";
import { useEffect } from "react";
import { Preferences } from "../../../features/users/preference/preferencesSlice";
import { SpeedGraph } from "../../Chart/SpeedGraph";
import { ModalBody } from "../ModalBody";
import { ModalFooter } from "../ModalFooter";
import { ModalWrapper } from "../ModalWrapper";
import "./SpeedReportModal.css";

declare global {
  interface Window {
    Apex: any;
  }
}

interface SeriesItem {
  timestamp: Date;
  speed: number;
}

interface VehicleType {
  id: number;
  label: string;
  speedLimit: number;
}

interface SpeedReportModalProps {
  open: boolean;
  getVehicleSelected: (e: any) => any;
  onClose: () => any;
  title: string;
  desc: string;
  isLoading: boolean;
  vehicleList: VehicleType[];
  series: SeriesItem[];
  preferences: Preferences;
}

export const SpeedReportModal: React.FC<SpeedReportModalProps> = ({
  title,
  desc,
  open,
  onClose,
  series,
  getVehicleSelected,
  vehicleList,
  isLoading,
  preferences,
}) => {
  useEffect(() => {
    return () => {
      onClose && onClose();
    };
  }, []);

  async function downloadPNGModal() {
    const modalElement = document.querySelector(
      ".speed-report-modal-body"
    ) as HTMLElement;
    if (modalElement) {
      const canvas = await html2canvas(modalElement, {
        useCORS: true,
        scale: 6,
      });

      const image = canvas.toDataURL("image/png");
      const link = document.createElement("a");
      link.href = image;
      link.download = `${(title + "_" + desc).replaceAll(" ", "")}.png`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  async function downloadCSVChart() {
    const chartInstance = window.Apex._chartInstances.find(
      (chart: any) => chart.id === (title + "_" + desc).replaceAll(" ", "")
    );
    chartInstance.chart.exports.exportToCSV({
      series: chartInstance.chart.w.config.series,
      columnDelimiter: ",",
      fileName: (title + "_" + desc).replaceAll(" ", ""),
    });
  }

  const downloadButtonList = [
    {
      id: 0,
      title: "PNG",
      onClick: () => {
        downloadPNGModal();
      },
    },
    {
      id: 1,
      title: "CSV",
      onClick: () => {
        downloadCSVChart();
      },
    },
  ];

  return (
    <div className="speed-report-modal">
      <ModalWrapper open={open} closeAction={onClose}>
        <div className="speed-report-modal-body">
          <ModalBody title={title} desc={desc}>
            <SpeedGraph
              exportId={title + "_" + desc}
              series={series}
              preferences={preferences}
              isLoading={isLoading}
              vehicleList={vehicleList}
              getVehicleSelected={getVehicleSelected}
            />
          </ModalBody>
        </div>
        <div className="speed-report-modal-footer">
          <ModalFooter
            secondaryLabel={i18next.t("common.close")}
            secondaryAction={onClose}
            downloadButtonList={downloadButtonList}
          />
        </div>
      </ModalWrapper>
    </div>
  );
};
