import PropTypes from "prop-types";

import { Signal } from "../Indicators/IndicatorSignal";

import "./statusBadge.css";

export const StatusBadge = ({ status, id, size, className, ...props }) => {
  const statusBadgeSizeClass = `status-badge--${size}`;
  return (
    <div
      className={["status-badge", statusBadgeSizeClass, className].join(" ")}
      {...props}
    >
      <Signal mode={status} size={size} />
      <span>{id}</span>
    </div>
  );
};

StatusBadge.propTypes = {
  status: PropTypes.oneOf(["NO_SIGNAL", "ONLINE", "OFFLINE", "UNKNOWN"]),
  id: PropTypes.string,
  size: PropTypes.oneOf(["normal", "small"]),
};

StatusBadge.defaultProps = {
  status: "online",
  id: "A01",
  size: "normal",
};
