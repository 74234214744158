import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../Button/Button";
import { Tooltip } from "../Forms/Tooltip";
import { IconCheck } from "../Icon/Line/Check";
import { Signal } from "../Indicators/IndicatorSignal";
import "./VehicleSummary.css";

export const VehicleSummary = ({
  icon,
  status,
  details,
  accessories,
  className,
  onToggle,
  ...props
}) => {
  const [show, setShow] = useState(false);
  const { t } = useTranslation();

  const vehicleSummaryMarkup = details.map((detail, index) => {
    return (
      <div key={index} className="mn-vehicle-summary__details-item-container">
        <Tooltip />
        <span
          className="mn-vehicle-summary__details-item"
          data-for="tooltip"
          data-tip={detail.description}
        >
          {detail.icon} {detail.label}
        </span>
      </div>
    );
  });
  const classnames =
    (show
      ? "mn-vehicle-summary mn-vehicle-summary--show "
      : "mn-vehicle-summary ") + className ?? "";
  return (
    <div className={classnames} {...props}>
      <div className="mn-vehicle-summary__left-col">
        <div className="mn-vehicle-summary__icon-status">
          <div className="mn-vehicle-summary__icon">{icon}</div>
          <Signal mode={status} />
        </div>
        <div className="mn-vehicle-summary__details">
          {vehicleSummaryMarkup}
        </div>
        <div className="mn-vehicle-summary__accessories">
          <span>
            <IconCheck size={14} color="--global-colors-ui-primary" />
            {accessories.length} {t("common.accessories")}
          </span>
          {accessories.map((accessory, index) => {
            return <span key={index}>{accessory}</span>;
          })}
        </div>
      </div>
      <div className="mn-vehicle-summary__right-col">
        <div className="mn-vehicle-summary__toggle-details">
          <Button
            aspect="outline"
            label={show ? t("common.hideButton") : t("common.showButton")}
            size="small"
            disabled={accessories.length < 2}
            onClick={() => {
              if (onToggle) {
                onToggle(!show);
              }
              setShow((prevState) => !prevState);
            }}
          />
        </div>
      </div>
    </div>
  );
};

VehicleSummary.propTypes = {
  icon: PropTypes.object.isRequired,
  status: PropTypes.oneOf(["MOVING", "STOP", "PARKING", "UNKNOWN", "OFFLINE"]),
  details: PropTypes.array,
  accessories: PropTypes.arrayOf(String),
};
