import {
  EntityAdapter,
  PayloadAction,
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import _ from "lodash";
import { normalize } from "normalizr";
import { RootState } from "../../app/store";
import { Config } from "../../config/Config";
import { GTFleetSuccessCodes } from "../../config/GTFleetSuccessCodes";
import { GTFleetErrorCodes } from "../../config/GTfleetErrorCodes";
import { getErrorCodes } from "../../utils/Utils";
import {
  commandRequestSchema,
  commandRequestsSchema,
} from "./commandNormalization";
import CommandRepository from "./commandRepository";

//#region Type

export interface CommandRequest {
  id: CommandRequestsNameEnum;
  command: CommandRequestsNameEnum;
  device: number;
  type: CommandRequestsTypeEnum;
  status: CommandRequestsStatusEnum;
}

export const CommandRequestsNameValues = {
  LOCATE: "LOCATE",
  ENGINE_LOCK: "ENGINE_LOCK",
  SERVICE: "SERVICE"
};
export type CommandRequestsNameEnum = keyof typeof CommandRequestsNameValues;

export const CommandRequestsTypeValues = {
  ACTIVE: "ACTIVE",
  DEACTIVE: "DEACTIVE",
};
export type CommandRequestsTypeEnum = keyof typeof CommandRequestsTypeValues;

export const CommandRequestsStatusValues = {
  PENDING: "PENDING",
  SUCCESS: "SUCCESS",
  FAILED: "FAILED",
};
export type CommandRequestsStatusEnum =
  keyof typeof CommandRequestsStatusValues;
//#endregion Type

//#region API
// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const getCommandRequestAsync = createAsyncThunk(
  "devices/getCommand",
  async (data: { deviceId: number; command: string }, { rejectWithValue }) => {
    try {
      const commandRepository = new CommandRepository();
      const response = await commandRepository.getCommandRequest(
        data.deviceId,
        data.command
      );
      // The value we return becomes the `fulfilled` action payload
      let command = _.get(response, Config.COMMAND_REQUEST_RESPONSE_PATH);
      if (command) {
        command = { ...command, id: command.command };
      }
      const normalizedData = normalize(command, commandRequestSchema);
      return normalizedData.entities;
    } catch (error: any) {
      if (!error.response) throw error;
      return rejectWithValue(error.response);
    }
  }
);

export const getCommandsRequestAsync = createAsyncThunk(
  "devices/getCommands",
  async (data: { deviceId: number }, { rejectWithValue }) => {
    try {
      const commandRepository = new CommandRepository();
      const response = await commandRepository.getCommandsRequest(
        data.deviceId
      );
      // The value we return becomes the `fulfilled` action payload
      let commands = _.get(response, Config.COMMAND_REQUEST_RESPONSE_PATH);
      if (commands) {
        commands = commands.map((command: any) => {
          return { ...command, id: command.command };
        });
      }
      const normalizedData = normalize(commands, commandRequestsSchema);
      return normalizedData.entities;
    } catch (error: any) {
      if (!error.response) throw error;
      return rejectWithValue(error.response);
    }
  }
);

export const createCommandRequestAsync = createAsyncThunk(
  "devices/createCommand",
  async (
    data: { deviceId: number; command: string; type: string },
    { rejectWithValue }
  ) => {
    try {
      const commandRepository = new CommandRepository();
      const response = await commandRepository.createCommandRequest(
        data.deviceId,
        data.command,
        data.type
      );
      // The value we return becomes the `fulfilled` action payload
      let command = _.get(response, Config.COMMAND_REQUEST_RESPONSE_PATH);
      if (command) {
        command = { ...command, id: command.command };
      }
      const normalizedData = normalize(command, commandRequestSchema);
      return normalizedData.entities;
    } catch (error: any) {
      if (!error.response) throw error;
      return rejectWithValue(error.response.status);
    }
  }
);

//#endregion API

//#region Custom Functions
function setFilteredData(state: any, action: PayloadAction<any>) {
  action.payload.commandRequest
    ? commandRequestAdapter.setAll(state, action.payload.commandRequest)
    : commandRequestAdapter.setAll(state, []);
}
//#endregiorn Custom Functions

//#region Slice
const commandRequestAdapter: EntityAdapter<CommandRequest> =
  createEntityAdapter<CommandRequest>({
    selectId: (commandRequest: CommandRequest) => commandRequest.id,
  });

export const commandRequestSlice = createSlice({
  name: "commandRequests",
  initialState: commandRequestAdapter.getInitialState({
    status: "idle",
    reasonCode: "",
  }),
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setCommandRequests: (state, action: PayloadAction<any>) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      commandRequestAdapter.setMany(state, action.payload.commandRequest);
    },
    setCommandRequest: (state, action: PayloadAction<any>) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      commandRequestAdapter.setOne(state, action.payload.commandRequest);
    },
    setEmptyState: (state) => {
      commandRequestAdapter.setAll(state, []);
      state.reasonCode = "";
      state.status = "idle";
    },
  },

  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      //#region Entity Reducers
      // get commandRequest async extra reducers
      .addCase(
        getCommandRequestAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          setFilteredData(state, action);
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.GET;
        }
      )
      .addCase(
        getCommandRequestAsync.rejected,
        (state: any, action: PayloadAction<any>) => {
          state.status = "failed";
          let castAction: any = action;
          if (castAction.payload && castAction.payload === 500) {
            state.reasonCode = GTFleetErrorCodes.COMMAND_REQUESTS_NOT_FOUND;
          } else state.reasonCode = getErrorCodes(action.payload);
        }
      )
      .addCase(getCommandRequestAsync.pending, (state: any) => {
        state.status = "loading";
      })
      //
      .addCase(
        getCommandsRequestAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          setFilteredData(state, action);
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.GET;
        }
      )
      .addCase(
        getCommandsRequestAsync.rejected,
        (state: any, action: PayloadAction<any>) => {
          state.status = "failed";
          let castAction: any = action;
          if (castAction.payload && castAction.payload === 500) {
            state.reasonCode = GTFleetErrorCodes.COMMAND_REQUESTS_NOT_FOUND;
          } else state.reasonCode = getErrorCodes(action.payload);
        }
      )
      .addCase(getCommandsRequestAsync.pending, (state: any) => {
        state.status = "loading";
      })
      //
      .addCase(
        createCommandRequestAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          setFilteredData(state, action);
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.POST;
        }
      )
      .addCase(
        createCommandRequestAsync.rejected,
        (state: any, action: PayloadAction<any>) => {
          state.status = "failed";
          let castAction: any = action;
          if (castAction.payload && castAction.payload === 500) {
            state.reasonCode = GTFleetErrorCodes.DEVICE_NOT_FOUND;
          } else state.reasonCode = getErrorCodes(action.payload);
        }
      )
      .addCase(createCommandRequestAsync.pending, (state: any) => {
        state.status = "loading";
      });
    // extra reducers when API is called

    //#endregion External Entity Reducers
  },
});
//#endregion Slice

//#region Status
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const commandRequestsSelectors =
  commandRequestAdapter.getSelectors<RootState>(
    (state) => state.commandRequests
  );

export const selectCommandRequestsSliceStatus = (state: any) =>
  state.commandRequests.status;
export const selectCommandRequestsSliceReasonCode = (state: any) =>
  state.commandRequests.reasonCode;
export const { setEmptyState: setCommandsRequestsEmptyState } =
  commandRequestSlice.actions;
//#endregion Status
export default commandRequestSlice.reducer;
