import { t } from "i18next";
import { useEffect, useState } from "react";
import { timeElapsed } from "../../utils/DateAndTimeUtils";
import { IconArrowRight } from "../Icon/Line/ArrowRight";
import { IconBack } from "../Icon/Line/Back";
import "./NotificationsMenu.css";
import { SingleNotification } from "./SingleNotification";

export const NotificationsMenu = ({ open, notifications }) => {
  const [isOpen, setIsOpen] = useState(open);
  const [currentCategory, setCurrentCategory] = useState(-1);

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  return (
    isOpen && (
      <div className="notification-menu">
        {currentCategory === -1 ? (
          <>
            {notifications.notificationsList.length > 0 ? (
              notifications.notificationsList.map((notification) => (
                <div
                  key={notification.categoryId}
                  className="mn-notification-category"
                  onClick={(e) => {
                    setCurrentCategory(notification.categoryId);
                    e.stopPropagation();
                  }}
                >
                  <div key={notification.categoryId}>
                    {notification.categoryName} (
                    {notification.notifications.length})
                  </div>
                  <IconArrowRight size={8} color="--global-colors-ink-ink" />
                </div>
              ))
            ) : (
              <div className="mn-notification-category">
                {t("common.noNotifications")}
              </div>
            )}
          </>
        ) : (
          <div>
            <div
              className="mn-notification-category-items"
              onClick={(e) => {
                setCurrentCategory(-1);
                e.stopPropagation();
              }}
            >
              <IconBack size={8} color="--global-colors-ink-dark" />
              <div>
                {
                  notifications.notificationsList.find(
                    (el) => el.categoryId === currentCategory
                  )?.categoryName
                }
                {` (${
                  notifications.notificationsList.find(
                    (el) => el.categoryId === currentCategory
                  )?.notifications.length
                })`}
              </div>
            </div>
            <div className="notification-list">
              {notifications.notificationsList
                .filter((el) => el.categoryId === currentCategory)
                ?.map((notifications) =>
                  notifications.notifications.map((notification, index) => (
                    <SingleNotification
                      key={index}
                      notification={{
                        message: notification.message,
                        time: timeElapsed(notification.time),
                      }}
                    />
                  ))
                )}
            </div>
          </div>
        )}
        <div className="notification-link" onClick={notifications.buttonClick}>
          {notifications.buttonLabel}
        </div>
      </div>
    )
  );
};
