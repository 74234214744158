import i18next, { t } from "i18next";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useAppSelector } from "../../../app/hooks";
import { store } from "../../../app/store";
import PageContent from "../../../layout/PageContent";
import PageFilters from "../../../layout/PageFilters";
import { PageCounter } from "../../../ui/Table/PageCounter";
import { Table } from "../../../ui/Table/Table";
import { TabSection } from "../../../ui/Tabs/TabSection";
import { Tabs } from "../../../ui/Tabs/Tabs";
import { ToastNotification } from "../../../utils/ToastNotification";
import { getQueryString } from "../../../utils/Utils";
import { driversSelectors } from "../../driver/driversSlice";
import { RouteStateType } from "../../route/routesHistorySlice";
import { Preferences } from "../../users/preference/preferencesSlice";
import {
  Preset,
  presetsSelectors,
  selectpresetsSliceReasonCode,
  selectpresetsSliceStatus,
} from "../../users/preset/presetsSlice";
import { UserPermissions } from "../../users/privilege/privilegesSlice";
import UserContext from "../../users/userContext";
import { vehiclesSelectors } from "../../vehicle/vehiclesSlice";
import GPSDataReportSpeed from "../speed/SpeedReport";
import { speedDataReportsEmptyState } from "../speed/speedReportSlice";
import "./GPSDataReport.css";
import { GPSDataReportFilterBar } from "./GPSDataReportFilterBar";
import { GPSDataReportRoutePointsDetails } from "./GPSDataReportRoutePointsDetails";
import { GPSDataReportStopsDetails } from "./GPSDataReportStopsDetails";
import { GPSDataReportTableBuilder } from "./GPSDataReportTableBuilder";
import {
  GPSDataReport,
  getGPSDataReportsAsync,
  gpsDataReportsEmptyState,
  gpsDataReportsSelectors,
  selectGPSDataReportsSliceReasonCode,
  selectGPSDataReportsSliceStatus,
} from "./gpsDataReportSlice";
import {
  GPSDataReportsSummary,
  gpsDataReportsSummaryEmptyState,
  gpsDataReportsSummarySelectors,
  selectGPSDataReportsSummarySliceReasonCode,
  selectGPSDataReportsSummarySliceStatus,
} from "./gpsDataReportsSummarySlice";

interface ReportGPSDataProps {
  permissions: UserPermissions;
}

export type RoutePointsSummary = {
  vehicles: number;
  kmTot: number;
  consTot: number;
  speedMax: number;
  speedAvg: number;
  driveTime: string;
};

export type StopsSummary = {
  vehicles: number;
  stopsNumber: number;
};

interface TableData {
  columns: { label: string; field: string; sort: boolean }[] | undefined;
  rows: { [key: string]: any };
}
const routePointTableData: TableData = {} as TableData;
const stopTableData: TableData = {} as TableData;
const routePointContext = "gpsDataReportRoutePoint";
const stopContext = "gpsDataReportStop";
const reportContext = "gpsDataReport";

export const ReportGPSData: React.FC<ReportGPSDataProps> = ({
  permissions,
}) => {
  const tableBuilderRef = useRef<GPSDataReportTableBuilder>(
    new GPSDataReportTableBuilder()
  );
  let tableBuilder = tableBuilderRef.current;

  const [selectedTab, setSelectedTab] = useState<string>(
    t("report.gpsData.routePoints")
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [vehiclesNumber, setVehiclesNumber] = useState(0);
  const [routeStateType, setRouteStateType] = useState<RouteStateType>("TRACK");
  const [resetPage, setResetPage] = useState<boolean>(false);
  const gpsDataReports: GPSDataReport[] = useAppSelector(
    gpsDataReportsSelectors.selectAll
  );

  const reportsSliceStatus = useAppSelector(selectGPSDataReportsSliceStatus);
  const reportsSliceReasonCode = useAppSelector(
    selectGPSDataReportsSliceReasonCode
  );
  const reportsSummarySliceStatus = useAppSelector(
    selectGPSDataReportsSummarySliceStatus
  );
  const reportsSummarySliceReasonCode = useAppSelector(
    selectGPSDataReportsSummarySliceReasonCode
  );
  const gpsDataReportsSummary: GPSDataReportsSummary[] = useAppSelector(
    gpsDataReportsSummarySelectors.selectAll
  );
  const [queryParamsFromFilterBar, setQueryParamsFromFilterBar] =
    useState<string>("");

  let presets: Preset[] = useAppSelector(presetsSelectors.selectAll);
  const presetsGpsDataSliceStatus = useAppSelector(selectpresetsSliceStatus);
  const presetsGpsDataSliceReasonCode = useAppSelector(
    selectpresetsSliceReasonCode
  );

  const [preferencesContext]: [Preferences] = useContext(UserContext);

  const isReportsIdle = reportsSliceStatus === "idle";

  useEffect(() => {
    isReportsIdle && setResetPage(false);
  }, [isReportsIdle]);

  //#region error handling
  useEffect(() => {
    if (reportsSliceStatus === "failed" && reportsSliceReasonCode === "") {
      console.error("common.networkError");
      ToastNotification({
        toastId: "networkError",
        status: "error",
        description: t("common.networkError"),
      });
    }
  }, [reportsSliceStatus, reportsSliceReasonCode]);

  useEffect(() => {
    if (
      reportsSummarySliceStatus === "failed" &&
      reportsSummarySliceReasonCode === ""
    ) {
      console.error(i18next.t("common.networkError"));
      ToastNotification({
        toastId: "reportsSummaryNetworkError",
        status: "error",
        description: i18next.t("common.networkError"),
      });
    }
  }, [reportsSummarySliceStatus, reportsSummarySliceReasonCode]);

  useEffect(() => {
    if (
      presetsGpsDataSliceStatus === "failed" &&
      presetsGpsDataSliceReasonCode === ""
    ) {
      console.error(i18next.t("common.networkError"));
      ToastNotification({
        toastId: "presetsGpsDataNetworkError",
        status: "error",
        description: i18next.t("common.networkError"),
      });
    }
  }, [presetsGpsDataSliceStatus, presetsGpsDataSliceReasonCode]);
  //#endregion error handling

  if (presetsGpsDataSliceStatus === "idle" && presets.length > 0) {
    let preset =
      presets.find((element) => element.name === undefined) ??
      presets.find((element) => element.lastSelected === true);

    if (!preset) {
      preset = presets.find(
        (element) =>
          element.name === "Default" && element.context === routePointContext
      );
    }

    routePointTableData.columns = tableBuilder.setColumns(
      preset?.columns
        ? preset?.columns.map((element) => {
            switch (element) {
              case "gpsDataSpeed":
                return {
                  name: element,
                  unit: preferencesContext.isMetric ? "km/h" : "mi/h",
                };
              case "gpsDataCovered":
                return {
                  name: element,
                  unit: preferencesContext.isMetric ? "m" : "mi",
                };
              case "gpsDataConsumption":
                return {
                  name: element,
                  unit: preferencesContext.isMetric ? "lt" : "gal",
                };
              default:
                return {
                  name: element,
                };
            }
          })
        : [],
      preferencesContext
    );

    routePointTableData.rows =
      reportsSliceStatus === "idle" &&
      presetsGpsDataSliceStatus === "idle" &&
      routePointTableData.columns &&
      routePointTableData?.columns?.length > 0
        ? gpsDataReports.map((gpsDataReport: GPSDataReport) => {
            const currentDriver = driversSelectors.selectById(
              store.getState(),
              gpsDataReport.driverId
            );
            const currentVehicle = vehiclesSelectors.selectById(
              store.getState(),
              gpsDataReport.vehicleId
            );

            return tableBuilder.rowsBuilder(
              routePointTableData.columns,
              reportContext,
              {
                vehicle: currentVehicle,
                driver: currentDriver,
                gpsDataReport: gpsDataReport,
              },
              preferencesContext
            );
          })
        : routePointTableData.rows;

    preset = presets.find(
      (element) => element.name === "Default" && element.context === stopContext
    );

    stopTableData.columns = tableBuilder.setColumns(
      preset?.columns
        ? preset?.columns.map((element) => {
            return {
              name: element,
            };
          })
        : []
    );

    stopTableData.rows =
      reportsSliceStatus === "idle" &&
      presetsGpsDataSliceStatus === "idle" &&
      stopTableData.columns &&
      stopTableData?.columns?.length > 0
        ? gpsDataReports.map((gpsDataReport: GPSDataReport) => {
            const currentDriver = driversSelectors.selectById(
              store.getState(),
              gpsDataReport.driverId
            );
            const currentVehicle = vehiclesSelectors.selectById(
              store.getState(),
              gpsDataReport.vehicleId
            );

            return tableBuilder.rowsBuilder(
              stopTableData.columns,
              reportContext,
              {
                vehicle: currentVehicle,
                driver: currentDriver,
                gpsDataReport: gpsDataReport,
              },
              preferencesContext
            );
          })
        : stopTableData.rows;
  }

  useEffect(() => {
    document.title =
      t("breadcrumbs.gpsData") + " - " + t("breadcrumbs.reports");
    return () => {
      store.dispatch(gpsDataReportsSummaryEmptyState());
    };
  }, []);

  const isLoading = reportsSliceStatus === "loading";
  const isDisableButton = () => {
    if (routeStateType === "STOP" && stopTableData.rows?.length === 0) {
      return true;
    } else if (
      routeStateType === "TRACK" &&
      routePointTableData.rows?.length === 0
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <React.Fragment>
      <GPSDataReportSpeed
        open={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          store.dispatch(speedDataReportsEmptyState());
        }}
      />
      <PageFilters>
        <div className="col col-16">
          <GPSDataReportFilterBar
            callback={(queryString) => {
              setResetPage(true);
              setQueryParamsFromFilterBar(queryString);
            }}
            setVehiclesNumber={setVehiclesNumber}
            routeType={routeStateType}
            setRouteState={(value) => {
              setRouteStateType(value);
              if (value === "STOP") {
                setSelectedTab(t("report.gpsData.stops"));
              } else {
                setSelectedTab(t("report.gpsData.routePoints"));
              }
            }}
            setOpenSpeedGraph={setIsModalOpen}
            disableButton={isDisableButton()}
          />
        </div>
      </PageFilters>
      {queryParamsFromFilterBar !== "" && (
        <div className="gps-report-tabs">
          <Tabs
            onClick={(label) => {
              if (label === t("report.gpsData.stops")) {
                setRouteStateType("STOP");
              } else {
                setRouteStateType("TRACK");
              }
              setSelectedTab(label);
              store.dispatch(gpsDataReportsEmptyState());
            }}
            enableTab={selectedTab}
          >
            <TabSection label={t("report.gpsData.routePoints")}>
              <div className="col col-16">
                <div className="details-gpsDataReport-wrapper">
                  <div className="details-report-title">
                    {t("report.details.title")}
                  </div>
                  <div className="details-report-container">
                    <div className="details-report-icons">
                      <GPSDataReportRoutePointsDetails
                        vehicles={vehiclesNumber}
                        kmTot={gpsDataReportsSummary[0]?.totalTraveled}
                        consTot={gpsDataReportsSummary[0]?.fuelConsumption}
                        speedMax={gpsDataReportsSummary[0]?.maxSpeed}
                        speedAvg={gpsDataReportsSummary[0]?.averageSpeed}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {queryParamsFromFilterBar !== "" && routePointTableData?.rows && (
                <PageContent>
                  <div className="gpsr-table-container">
                    <div className="gpsr-rows-container">
                      <Table data={routePointTableData}>
                        <Table.Head hasTableSpace={true} />
                        <Table.Body />
                      </Table>
                    </div>
                    <div>
                      <PageCounter
                        isActionPerforming={isLoading}
                        totalElements={
                          gpsDataReportsSummary[0]
                            ? gpsDataReportsSummary[0]?.totalElements
                            : 0
                        }
                        resetPage={resetPage} // every time query params changes resets number of page
                        disabled={!isReportsIdle}
                        progressiveArrowMode={true}
                        numOfPages={
                          !gpsDataReportsSummary[0]?.totalElements
                            ? 1
                            : Math.ceil(
                                gpsDataReportsSummary[0]?.totalElements / 10
                              )
                        }
                        numSelected={0}
                        onClick={(id) => {
                          const pageAndSize = getQueryString({
                            page: id,
                            size: "10",
                          });
                          const finalQueryParams = queryParamsFromFilterBar
                            ? queryParamsFromFilterBar +
                              pageAndSize.replace("?", "&")
                            : pageAndSize;
                          store.dispatch(
                            getGPSDataReportsAsync({
                              queryParams: finalQueryParams,
                              page: id,
                              routeType: "TRACK",
                            })
                          );
                        }}
                      />
                    </div>
                  </div>
                </PageContent>
              )}
            </TabSection>
            <TabSection label={t("report.gpsData.stops")}>
              <div className="details-gpsDataReport-wrapper">
                <div className="details-report-title">
                  {t("report.details.title")}
                </div>
                <div className="details-report-container">
                  <div className="details-report-icons">
                    <GPSDataReportStopsDetails
                      vehicles={vehiclesNumber}
                      stopsNumber={gpsDataReportsSummary[0]?.totalElements}
                    />
                  </div>
                </div>
              </div>
              {queryParamsFromFilterBar !== "" && stopTableData?.rows && (
                <PageContent>
                  <div className="gpsr-table-container">
                    <div className="gpsr-rows-container">
                      <Table data={stopTableData}>
                        <Table.Head hasTableSpace={true} />
                        <Table.Body />
                      </Table>
                    </div>
                    <div>
                      <PageCounter
                        isActionPerforming={isLoading}
                        totalElements={
                          gpsDataReportsSummary[0]
                            ? gpsDataReportsSummary[0]?.totalElements
                            : 0
                        }
                        resetPage={resetPage} // every time query params changes resets number of page
                        disabled={!isReportsIdle}
                        progressiveArrowMode={true}
                        numOfPages={
                          !gpsDataReportsSummary[0]?.totalElements
                            ? 1
                            : Math.ceil(
                                gpsDataReportsSummary[0]?.totalElements / 10
                              )
                        }
                        numSelected={0}
                        onClick={(id) => {
                          const pageAndSize = getQueryString({
                            page: id,
                            size: "10",
                          });
                          const finalQueryParams = queryParamsFromFilterBar
                            ? queryParamsFromFilterBar +
                              pageAndSize.replace("?", "&")
                            : pageAndSize;
                          store.dispatch(
                            getGPSDataReportsAsync({
                              queryParams: finalQueryParams,
                              page: id,
                              routeType: "STOP",
                            })
                          );
                        }}
                      />
                    </div>
                  </div>
                </PageContent>
              )}
            </TabSection>
          </Tabs>
        </div>
      )}
    </React.Fragment>
  );
};
