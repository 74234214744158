import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Deadline } from "../deadlinesSlice";
import { AddMaintenanceModal } from "./AddMaintenance";

interface DropDownItem {
  label: string;
}

interface MaintenanceNewModalProps {
  deadlineDefaultValues?: Deadline;
  vehicleDefaultValue?: number;
}

const MaintenanceNewModal: React.FC<MaintenanceNewModalProps> = ({
  deadlineDefaultValues,
  vehicleDefaultValue,
}) => {
  const [modalView, setModalView] = useState<boolean>(true);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  return (
    <AddMaintenanceModal
      open={modalView}
      deadlineDefaultValues={deadlineDefaultValues}
      vehicleDefaultValue={vehicleDefaultValue}
      onClose={() => {
        setModalView(false);
        navigate({
          pathname: "/dashboard/maintenance",
          search: searchParams.toString(),
        });
      }}
    />
  );
};
export default MaintenanceNewModal;
