import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import _ from "lodash";
import { normalize } from "normalizr";
import { RootState } from "../../app/store";
import { Config } from "../../config/Config";
import { GTFleetSuccessCodes } from "../../config/GTFleetSuccessCodes";
import { tachographFileStatusSummarySchema } from "./tachographFileNormalization";
import TachographFileRepository from "./tachographFilesRepository";

//#region Type
export interface TachographFileStatusSummary {
  vehiclesCount: number;
  driversCount: number;
  expiringCount: number;
  expiredCount: number;
  okCount: number;
}

//#endregion Type

//#region API
export const getTachographFilesStatusSummaryAsync = createAsyncThunk(
  "tachographFile/getTachographFilesStatusSummary",
  async (data?: { queryParams?: string }) => {
    const tachographFileRepository = new TachographFileRepository();
    const response =
      await tachographFileRepository.getTachographFilesStatusSummary(
        data?.queryParams
      );
    // The value we return becomes the `fulfilled` action payload
    const tachographFilesStatusSummary = _.get(
      response,
      Config.TACHOGRAPH_FILES_STATUS_SUMMARY_RESPONSE_PATH
    );
    const normalizedData = normalize(
      tachographFilesStatusSummary,
      tachographFileStatusSummarySchema
    );
    return normalizedData.entities;
  }
);

//#endregion API

//#region Custom Functions
function setFilteredData(state: any, action: PayloadAction<any>) {
  action.payload.tachographFileStatusSummary
    ? tachographFilesStatusSummaryAdapter.setAll(
        state,
        action.payload.tachographFileStatusSummary
      )
    : tachographFilesStatusSummaryAdapter.setAll(state, []);
}
//#endregiorn Custom Functions

//#region Slice
const tachographFilesStatusSummaryAdapter =
  createEntityAdapter<TachographFileStatusSummary>({ selectId: () => 0 });

export const tachographFileStatusSummarySlice = createSlice({
  name: "tachographFilesStatusSummary",
  initialState: tachographFilesStatusSummaryAdapter.getInitialState({
    status: "idle",
    reasonCode: "",
  }),
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    tachographFilesStatusSummaryEmptyState: (state) => {
      tachographFilesStatusSummaryAdapter.setAll(state, []);
      state.status = "idle";
      state.reasonCode = "";
    },
  },

  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      //#region Entity Reducers
      .addCase(
        getTachographFilesStatusSummaryAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          setFilteredData(state, action);
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.GET;
        }
      )
      .addCase(getTachographFilesStatusSummaryAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(getTachographFilesStatusSummaryAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = "";
      });
    //#endregion Entity Reducers
  },
});
//#endregion Slice

//#region Status
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const tachographFilesStatusSummarySelectors =
  tachographFilesStatusSummaryAdapter.getSelectors<RootState>(
    (state) => state.tachographFilesStatusSummary
  );

export const selectTachographFilesStatusSummarySliceStatus = (state: any) =>
  state.tachographFilesStatusSummary.status;
export const selectTachographFilesStatusSummarySliceReasonCode = (state: any) =>
  state.tachographFilesStatusSummary.reasonCode;
//#endregion Status
export const { tachographFilesStatusSummaryEmptyState } =
  tachographFileStatusSummarySlice.actions;

export default tachographFileStatusSummarySlice.reducer;
