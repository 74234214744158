import HttpClient from "./HttpClient";

export default class FirebaseService extends HttpClient {
  constructor() {
    super(true);
  }

  firebaseTokenRegister(token: string) {
    const body: any = { token: token };
    return this.instance.post("/pushnotification/register", body);
  }

  firebaseTokenUnregister(token: string) {
    const body: any = { token: token };
    return this.instance.post("/pushnotification/unregister", body);
  }
}
