import React from "react";
import propTypes from "prop-types";

import "./signal.css";

export const Signal = ({ mode, size, ...props }) => {
  const signalClass = `signal-status--${mode.toLowerCase()}`;
  const signalSizeClass = `signal-status--${size}`;
  return (
    <span
      className={["signal-status", signalClass, signalSizeClass].join(" ")}
      {...props}
    >
      <span className="screen-reader">{mode}</span>
    </span>
  );
};

Signal.propTypes = {
  /**
   * Status of the signal.
   */
  mode: propTypes.oneOf([
    "NO_SIGNAL",
    "ONLINE",
    "MOVING",
    "PARKING",
    "OFFLINE",
    "UNKNOWN",
    "STOP",
  ]),
  size: propTypes.oneOf(["normal", "small"]),
};

Signal.defaultProps = {
  mode: "NO_SIGNAL",
  size: "normal",
};
