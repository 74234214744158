import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import _ from "lodash";
import { normalize } from "normalizr";
import { RootState } from "../../app/store";
import { Config } from "../../config/Config";
import { GTFleetSuccessCodes } from "../../config/GTFleetSuccessCodes";
import {
  getFilteredVehiclesDetailsAsync,
  getVehicleDetailsAsync,
  getVehiclesDetailsAsync,
  getVehiclesDetailsPaginationAsync,
  updateVehicleAsync,
} from "../vehicle/vehiclesSlice";
import {
  getFilteredVehiclesStatusAsync,
  getFilteredVehiclesStatusPaginationAsync,
  getVehicleAsync,
  getVehiclesAsync,
} from "../vehicle/vehiclesStatusSlice";
import { getVehiclesViewAsync } from "../vehicle/vehiclesStatusViewSlice";
import { deviceSchema } from "./deviceNormalization";
import DevicesRepository from "./devicesRepository";

//#region Type
export type Device = {
  id: number;
  modelName: string;
  imei: string;
  fineServiceEnabled: boolean;
  speakerVolumePercentage: number;
};
//#endregion Type

//#region API
export const updateDeviceAsync = createAsyncThunk(
  "devices/updateDevice",
  async (data: { id: number; device: Device }) => {
    const devicesRepository = new DevicesRepository();
    const response = await devicesRepository.partiallyUpdateDevice(
      data.id,
      data.device
    );
    const device = _.get(response, Config.DEVICE_RESPONSE_PATH);
    const normalizedData = normalize(device, deviceSchema);
    return normalizedData.entities;
  }
);
//#endregion API

//#region Custom Functions
function setFilteredData(state: any, action: PayloadAction<any>) {
  action.payload.device
    ? devicesAdapter.setAll(state, action.payload.device)
    : devicesAdapter.setAll(state, []);
}
//#endregiorn Custom Functions

//#region Slice
const devicesAdapter = createEntityAdapter<Device>({
  selectId: (device) => device.id,
  sortComparer: (a, b) => a.modelName.localeCompare(b.modelName),
});

export const deviceSlice = createSlice({
  name: "devices",
  initialState: devicesAdapter.getInitialState({
    status: "idle",
    reasonCode: "",
  }),
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    devicesEmptyState: (state) => {
      devicesAdapter.setAll(state, []);
      state.reasonCode = "";
      state.status = "idle";
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      //#region Entity Reducers
      .addCase(
        updateDeviceAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          devicesAdapter.upsertMany(state, action.payload.device);
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.PATCH;
        }
      )
      .addCase(updateDeviceAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = "";
      })
      .addCase(updateDeviceAsync.pending, (state: any) => {
        state.status = "loading";
      })
      //#endregion Entity Reducers

      //#region External Entity Reducers
      .addCase(
        getVehiclesViewAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          action.payload.device &&
            devicesAdapter.upsertMany(state, action.payload.device);
        }
      )
      .addCase(
        getVehicleAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          action.payload.device &&
            devicesAdapter.upsertMany(state, action.payload.device);
        }
      )
      .addCase(
        getVehiclesAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          action.payload.device &&
            devicesAdapter.upsertMany(state, action.payload.device);
        }
      )
      .addCase(
        getVehiclesDetailsAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          action.payload.device &&
            devicesAdapter.upsertMany(state, action.payload.device);
        }
      )
      .addCase(
        getVehicleDetailsAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          action.payload.device &&
            devicesAdapter.upsertMany(state, action.payload.device);
        }
      )
      .addCase(
        updateVehicleAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          action.payload.device &&
            devicesAdapter.upsertMany(state, action.payload.device);
        }
      )
      .addCase(
        getFilteredVehiclesStatusAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          setFilteredData(state, action);
        }
      )
      .addCase(
        getFilteredVehiclesDetailsAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          action.payload.device &&
            devicesAdapter.upsertMany(state, action.payload.device);
        }
      )
      .addCase(
        getVehiclesDetailsPaginationAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          setFilteredData(state, action);
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.GET;
        }
      )
      .addCase(
        getFilteredVehiclesStatusPaginationAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          action.payload.device &&
            devicesAdapter.upsertMany(state, action.payload.device);
        }
      );
    //#endregion External Entity Reducers
  },
});
//#endregion Slice

//#region Status
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const devicesSelectors = devicesAdapter.getSelectors<RootState>(
  (state) => state.devices
);

export const selectDevicesSliceReasonCode = (state: any) =>
  state.devices.reasonCode;
export const selectDevicesSliceStatus = (state: any) => state.devices.status;
export const { devicesEmptyState } = deviceSlice.actions;
//#endregion Status

export default deviceSlice.reducer;
