import { t } from "i18next";
import { Dispatch, SetStateAction, useContext, useEffect } from "react";
import { useAppSelector } from "../../../app/hooks";
import { store } from "../../../app/store";
import i18n from "../../../i18n/i18nextConf";
import { ToastNotification } from "../../../utils/ToastNotification";
import UserContext from "../userContext";
import {
  getPreferencesAsync,
  Preferences,
  preferencesSelectors,
  selectPreferencesSliceStatus,
} from "./preferencesSlice";

interface PreferencesManagerProps {
  children: any;
}
const PreferencesManager = (props: PreferencesManagerProps) => {
  const [preferencesContext, setPreferencesContext]: [
    Preferences,
    Dispatch<SetStateAction<Preferences>>
  ] = useContext(UserContext);

  const preferencesSliceStatus = useAppSelector(selectPreferencesSliceStatus);

  let preferences: Preferences =
    useAppSelector((state) =>
      preferencesSelectors.selectById(
        state,
        String(localStorage.getItem("preferencesId"))
      )
    ) ?? ({} as Preferences);

  useEffect(() => {
    store.dispatch(getPreferencesAsync());
  }, []);

  useEffect(() => {
    if (preferencesSliceStatus === "failed") {
      ToastNotification({
        toastId: "error_load_preferences",
        status: "error",
        description: t("unable to load preferences"),
      });
    } else if (preferencesSliceStatus === "idle") {
      setPreferencesContext(preferences);
      preferencesContext.id > 0 &&
        i18n.changeLanguage(preferencesContext.language);
    }
  }, [preferencesSliceStatus]);

  return <>{props.children}</>;
};

export default PreferencesManager;
