import "../Icon/IconFont/icons.css";

const getType = (type) => {
  switch (type) {
    case "START":
      return <>&#xe876;</>;
    case "END":
      return <>&#xe903;</>;
    case "PARKING":
      return <>&#xe851;</>;
    case "POSITION":
      return <>&#xe869;</>;
    default:
      break;
  }
};

export const MapMarkerLocation = ({ type, ...props }) => (
  <div style={type === "END" ? { zIndex: "100" } : { zIndex: "99" }}>
    <div
      className="svg"
      style={{
        position: "absolute",
        marginLeft: "11px",
        marginTop: "8px",
      }}
    >
      <span
        style={{ fontSize: `16px`, color: `var(--global-colors-ui-white)` }}
      >
        {getType(type)}
      </span>
    </div>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="38"
      height="44"
      {...props}
    >
      <defs>
        <filter
          id="MapMarkerStartA"
          width="116.7%"
          height="117.2%"
          x="-8.3%"
          y="-4.9%"
          filterUnits="objectBoundingBox"
        >
          <feMorphology
            in="SourceAlpha"
            radius="0.5"
            result="shadowSpreadOuter1"
          ></feMorphology>
          <feOffset
            dy="3"
            in="shadowSpreadOuter1"
            result="shadowOffsetOuter1"
          ></feOffset>
          <feGaussianBlur
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
            stdDeviation="1"
          ></feGaussianBlur>
          <feComposite
            in="shadowBlurOuter1"
            in2="SourceAlpha"
            operator="out"
            result="shadowBlurOuter1"
          ></feComposite>
          <feColorMatrix
            in="shadowBlurOuter1"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
          ></feColorMatrix>
        </filter>
        <filter
          id="MapMarkerStartD"
          width="103.1%"
          height="103.1%"
          x="-1.6%"
          y="-1.6%"
          filterUnits="objectBoundingBox"
        >
          <feMorphology
            in="SourceAlpha"
            radius="1"
            result="shadowSpreadInner1"
          ></feMorphology>
          <feOffset
            in="shadowSpreadInner1"
            result="shadowOffsetInner1"
          ></feOffset>
          <feComposite
            in="shadowOffsetInner1"
            in2="SourceAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
            result="shadowInnerInner1"
          ></feComposite>
          <feColorMatrix
            in="shadowInnerInner1"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
          ></feColorMatrix>
        </filter>
        <path
          id="MapMarkerStartB"
          d="M18 41s2.71-5.154 3.994-5.445C30.013 33.74 36 26.57 36 18c0-9.941-8.059-18-18-18S0 8.059 0 18c0 8.564 5.981 15.732 13.995 17.553C15.283 35.845 18 41 18 41z"
        ></path>
        <path
          id="MapMarkerStartE"
          d="M10 0c4.045 0 7.692 2.484 9.239 6.293 1.5 3.694.74 7.929-1.915 10.84l-.255.268-6.023 6.155a1.455 1.455 0 01-1.967.114l-.125-.114-6.023-6.155C.07 14.487-.786 10.103.761 6.293 2.308 2.483 5.955 0 10 0zm0 7.179c-1.627 0-2.945 1.344-2.945 3.002 0 1.658 1.318 3.002 2.945 3.002 1.627 0 2.945-1.344 2.945-3.002 0-1.658-1.318-3.002-2.945-3.002z"
        ></path>
        <circle id="MapMarkerStartC" cx="18" cy="18" r="16"></circle>
      </defs>
      <g fill="none" fillRule="evenodd">
        <g transform="translate(1)">
          <use
            fill="#000"
            filter="url(#MapMarkerStartA)"
            xlinkHref="#MapMarkerStartB"
          ></use>
          <path
            fill="#FFF"
            stroke="#FFF"
            strokeLinejoin="square"
            strokeWidth="2"
            d="M18 1c4.694 0 8.944 1.903 12.02 4.98A16.947 16.947 0 0135 18a16.93 16.93 0 01-3.703 10.594 17.001 17.001 0 01-9.524 5.986c-.956.217-2.712 2.8-3.774 4.491-1.064-1.692-2.825-4.276-3.783-4.493A17.002 17.002 0 014.7 28.589 16.927 16.927 0 011 18c0-4.694 1.903-8.944 4.98-12.02A16.947 16.947 0 0118 1z"
          ></path>
        </g>
        <g transform="translate(1)">
          <use fill="#0052BD" xlinkHref="#MapMarkerStartC"></use>
          <use
            fill="#000"
            filter="url(#MapMarkerStartD)"
            xlinkHref="#MapMarkerStartC"
          ></use>
        </g>
        {/* <StartA /> */}
        {/* <g transform="translate(12 10)">
        <path
          d="M6.73 0A6.73 6.73 0 00.01 6.73c0 3.71 6 9.27 6.73 9.27s6.72-5.56 6.72-9.27A6.72 6.72 0 006.73 0zm0 8.76a2 2 0 112-2 2 2 0 01-2 2z"
          fill="#fff"
        />
      </g> */}
      </g>
    </svg>
  </div>
);
