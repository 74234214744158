import React from "react";
import PropTypes from "prop-types";
import { Button } from "../Button/Button";
import { IconSettings } from "../Icon/Solid/Settings";
import { IconArrowLeft } from "../Icon/Line/ArrowLeft";
import { IconArrowRight } from "../Icon/Line/ArrowRight";
import "./Toolbar.css";
import { useTranslation } from "react-i18next";
import { Tooltip } from "../Forms/Tooltip";

export const Toolbar = ({
  name,
  handleSettings,
  handleSendMsg,
  current,
  total,
  handlePrev,
  handleNext,
  isActionPerforming,
  ...props
}) => {
  const { t } = useTranslation();
  return (
    <div className="mn-toolbar" {...props}>
      <div className="mn-toolbar__left-col">
        <div>
          {name[0]}
          {name[1] && <div className="name-plate-pointer" />}
          {name[1]}
        </div>
        {handleSettings && (
          <Button
            aspect="outline"
            onClick={() => {
              handleSettings();
            }}
            onlyIcon
            size="small"
          >
            <IconSettings size={14} color="--global-colors-ink-light" />
          </Button>
        )}
        <Tooltip />
        {(process.env.REACT_APP_DEPLOY_TARGET === "local" ||
          process.env.REACT_APP_DEPLOY_TARGET === "stage") && (
          <span
            key="SendMsgButton"
            className="button-send-msg"
            data-for="tooltip"
            data-tip={t("common.underdevelopment")}
          >
            <Button
              id="sendMsg"
              aspect="outline"
              onClick={() => {
                handleSendMsg();
              }}
              label={t("common.sendMessage")}
              size="small"
            ></Button>
          </span>
        )}
      </div>
      <div className="mn-toolbar__right-col">
        {isActionPerforming && <div className="mn-toolbar__nav-loader" />}
        <span className="mn-toolbar__nav-index">
          {current} {t("common.of")} {total}
        </span>
        <span>
          <Button
            aspect="outline"
            onClick={() => {
              handlePrev();
            }}
            onlyIcon
            size="small"
            disabled={isActionPerforming || current === 1 ? true : false}
          >
            <IconArrowLeft size={14} color="--global-colors-ink-light" />
          </Button>
          <Button
            aspect="outline"
            onClick={() => {
              handleNext();
            }}
            onlyIcon
            size="small"
            disabled={isActionPerforming || current === total ? true : false}
          >
            <IconArrowRight size={14} color="--global-colors-ink-light" />
          </Button>
        </span>
      </div>
    </div>
  );
};

Toolbar.propTypes = {
  /**
   * Vehicle ID shown has title.
   */
  name: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  /**
   * Handle click on the gear icon.
   */
  handleSettings: PropTypes.func,
  /**
   * Current vehicles of the list that use is seeing.
   */
  current: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * Total vehicles that user can see.
   */
  total: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * Handle click on the prev button.
   */
  handlePrev: PropTypes.func,
  /**
   * Handle click on the next button.
   */
  handleNext: PropTypes.func,
};
