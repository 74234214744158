import i18next from "i18next";
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { store } from "../../app/store";
import { FeedbackModal } from "../../ui/Modal/CustomModals/FeedbackModal";
import { Deadline, updateDeadlineAsync } from "./deadlinesSlice";

interface DeleteRecurrenceModalProps {
  deadlineStatus: Partial<Deadline>;
  vehicleId: number;
  fleetId: number;
}

const DeleteRecurrenceModal: React.FC<DeleteRecurrenceModalProps> = ({
  deadlineStatus,
  vehicleId,
  fleetId,
}) => {
  const [recurrenceModal, setRecurrenceModal] = useState(true);
  const [deleteRecurrenceModal, setDeleteRecurrenceModal] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const closeModal = () => {
    setRecurrenceModal(false);
    navigate("/dashboard/maintenance");
  };

  return (
    <>
      {recurrenceModal && (
        <FeedbackModal
          open={recurrenceModal}
          title={i18next.t("customModals.recurrentDeadline.title")}
          desc={i18next.t("customModals.recurrentDeadline.desc")}
          primaryLabel={i18next.t("common.yes")}
          primaryAction={() => {
            const pathToNewDeadlineOrMaintenace = deadlineStatus.isMaintenance
              ? "/dashboard/maintenance/new-maintenance"
              : "/dashboard/maintenance/new-deadline";
            navigate(
              {
                pathname: pathToNewDeadlineOrMaintenace,
                search: searchParams.toString(),
              },
              {
                state: {
                  deadlineStatus: deadlineStatus,
                  vehicleId: vehicleId,
                },
              }
            );
          }}
          secondaryLabel={i18next.t("common.no")}
          secondaryAction={() => {
            setDeleteRecurrenceModal(true);
            setRecurrenceModal(false);
          }}
          closeAction={() => {
            closeModal();
          }}
        />
      )}
      {deleteRecurrenceModal && (
        <FeedbackModal
          open={deleteRecurrenceModal}
          title={i18next.t("customModals.deleteRecurrentDeadline.title")}
          desc={i18next.t("customModals.deleteRecurrentDeadline.desc")}
          primaryLabel={i18next.t("common.confirm")}
          isDanger={true}
          primaryAction={() => {
            if (deadlineStatus && deadlineStatus.id) {
              store.dispatch(
                updateDeadlineAsync({
                  id: deadlineStatus.id,
                  deadline: {
                    rescheduleMonths: 0,
                    rescheduleMeters: 0,
                    complete: true,
                  },
                })
              );
              closeModal();
            }
          }}
          secondaryLabel={i18next.t("common.cancel")}
          secondaryAction={() => {
            closeModal();
          }}
          closeAction={() => {
            closeModal();
          }}
        />
      )}
    </>
  );
};
export default DeleteRecurrenceModal;
