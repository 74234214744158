import { Route, Routes } from "react-router";
import ContainerWrapper from "../../layout/ContainerWrapper";
import FilterMap from "./FilterMap";

function Multiview() {
  return (
    <ContainerWrapper>
      <Routes>
        <Route path="/" element={<FilterMap />} />
      </Routes>
    </ContainerWrapper>
  );
}

export default Multiview;
