import HttpClient from "../../../utils/HttpClient";

export default class GeofencesReportsRepository extends HttpClient {
  constructor() {
    super(true);
  }

  getReportsGeofence(queryParams?: string) {
    const tenantId = localStorage.getItem("tenantId");
    return this.instance.get(
      "tenants/" + tenantId + "/geofences/positions" + (queryParams ?? "")
    );
  }
}
