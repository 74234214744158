import { t } from "i18next";
import { Preferences } from "../../../features/users/preference/preferencesSlice";

export interface TableColumnInfo {
  name: string;
  sort?: boolean;
  unit?: string; //unità di misura opzionale
}

export interface Row {
  [key: string]: Row | any;
}

export class AbstractTableBuilder {
  protected getUoM(name: string, preferences: Preferences): string {
    switch (name) {
      case "gpsDataSpeed":
      case "avgSpeed":
      case "maxSpeed":
        return preferences.isMetric ? "(km/h)" : "(mph)";
      case "traveled":
        return preferences.isMetric ? "(km)" : "(mil)";
      case "gpsDataCovered":
        return preferences.isMetric ? "(km)" : "(mil)";
      case "gpsDataConsumption":
        return preferences.isMetric ? "(l)" : "(gal)";
      case "speedTravelDetail":
        return preferences.isMetric ? "(km/h)" : "(mph)";
      case "coveredTravelDetail":
        return preferences.isMetric ? "(m)" : "(ft)";
      case "consumptionTravelDetail":
        return preferences.isMetric ? "(l)" : "(gal)";
      default:
        return "";
    }
  }

  public setColumns(
    columns: TableColumnInfo[],
    preferences?: Preferences
  ): any {
    if (columns && columns?.length > 0) {
      return columns.map((column) => {
        return {
          label:
            t("table.columns." + column.name) +
            (preferences !== undefined
              ? " " + this.getUoM(column.name, preferences)
              : ""),
          field: column.name,
          sort: !!column?.sort,
        };
      });
    }
  }
}
