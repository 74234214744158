import React from "react";
import { ModalWrapper } from "../ModalWrapper";
import { ModalFooter } from "../ModalFooter";
import { ModalBody } from "../ModalBody";
import "./FeedbackModal.css";

export const FeedbackModal = ({
  open,
  closeAction,
  title,
  desc,
  primaryLabel,
  isDanger,
  secondaryLabel,
  secondaryAction,
  primaryAction,
  text,
}) => {
  const style = text ? "help-feedback" : "feedback";
  return (
    <div className={style}>
      <ModalWrapper open={open} closeAction={closeAction}>
        <ModalBody title={title} desc={desc}>
          {text}
        </ModalBody>
        <ModalFooter
          isDanger={isDanger}
          primaryLabel={primaryLabel}
          primaryAction={primaryAction}
          secondaryLabel={secondaryLabel}
          secondaryAction={secondaryAction}
        />
      </ModalWrapper>
    </div>
  );
};
