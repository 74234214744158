import { Loader } from "@googlemaps/js-api-loader";
import { t } from "i18next";
import { useContext, useEffect, useRef, useState } from "react";
import { } from "react-i18next";
import { useMatch, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { store } from "../../app/store";
import { GTFleetSuccessCodes } from "../../config/GTFleetSuccessCodes";
import { GTFleetErrorCodes } from "../../config/GTfleetErrorCodes";
import PageContent from "../../layout/PageContent";
import PageFilters from "../../layout/PageFilters";
import { ModalBody } from "../../ui/Modal/ModalBody";
import { ModalFooter } from "../../ui/Modal/ModalFooter";
import { ModalWrapper } from "../../ui/Modal/ModalWrapper";
import { PageCounter } from "../../ui/Table/PageCounter";
import { Table } from "../../ui/Table/Table";
import { ToastNotification } from "../../utils/ToastNotification";
import { getQueryString } from "../../utils/Utils";
import { FleetView, fleetViewsSelectors } from "../fleet/fleetViewsSlice";
import { Preferences } from "../users/preference/preferencesSlice";
import { UserPermissions } from "../users/privilege/privilegesSlice";
import UserContext from "../users/userContext";
import { Vehicle, vehiclesSelectors } from "../vehicle/vehiclesSlice";
import { AdminDriverFilterBar } from "./AdminDriverFilterBar";
import "./AdminDrivers.css";
import { AdminDriversTableBuilder } from "./AdminDriversTableBuilder";
import {
  Driver,
  activateDriverAsync,
  deactivateDriverAsync,
  driverStatusValues,
  driversEmptyState,
  driversSelectors,
  getDriverDetailsAsync,
  selectDriversSliceReasonCode,
  selectDriversSliceStatus,
} from "./driversSlice";
import {
  DriverStatus,
  driversStatusEmptyState,
  driversStatusSelectors,
  getFilteredDriversStatusAndDetailsPaginationAsync,
  selectDriverStatusSlicePage,
  selectDriverStatusSliceReasonCode,
  selectDriverStatusSliceStatus,
  selectDriverStatusSliceTotalElements,
} from "./driversStatusSlice";

interface AdminDriversProps {
  permissions: UserPermissions;
}

interface TableData {
  columns: { label: string; field: string; sort: boolean }[] | undefined;
  rows: { [key: string]: any };
}

const tableData: TableData = {} as TableData;

const tableSchemaFix = [
  { name: "driver", sort: true },
  { name: "group" },
  { name: "driverStatus" },
  { name: "lastActivity" },
  { name: "driverId" },
  { name: "tachograph" },
  { name: "vehicle" },
];

/**
 * Table component of the Drivers
 * @returns
 */
export const AdminDrivers: React.FC<AdminDriversProps> = ({ permissions }) => {
  const navigate = useNavigate();
  const tableBuilderRef = useRef<AdminDriversTableBuilder>(
    new AdminDriversTableBuilder()
  );
  let tableBuilder = tableBuilderRef.current;

  const [preferencesContext]: [Preferences] = useContext(UserContext);

  const [queryParamsFromFilterBar, setQueryParamsFromFilterBar] =
    useState<string>("");
  const [resetPage, setResetPage] = useState<boolean>(false);
  const driverRoute = useMatch("/admin/drivers/:action/:driverId");

  const AdminDriversStatusSlicePages = useAppSelector(
    selectDriverStatusSlicePage
  );

  const AdminDriversStatusSliceTotalElements = useAppSelector(
    selectDriverStatusSliceTotalElements
  );
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [currentSelectedDriver, setCurrentSelectedDriver] = useState(-1);

  const drivers: Driver[] = useAppSelector(driversSelectors.selectAll);
  const selectedDriver: Driver =
    useAppSelector((state) =>
      driversSelectors.selectById(state, currentSelectedDriver)
    ) ?? ({} as Driver);
  const driverSliceStatus = useAppSelector(selectDriversSliceStatus);
  const driverSliceReasonCode = useAppSelector(selectDriversSliceReasonCode);
  const driversStatusSliceStatus = useAppSelector(
    selectDriverStatusSliceStatus
  );
  const driversStatusSliceReasonCode = useAppSelector(
    selectDriverStatusSliceReasonCode
  );

  const isActive =
    selectedDriver.status?.toString() === driverStatusValues.ACTIVE;

  const isDriversStatusIdle = driversStatusSliceStatus === "idle";
  const [queryParamsChanged, setQueryParamsChanged] = useState<boolean>(false);

  useEffect(() => {
    const loader = new Loader({
      apiKey: process.env?.REACT_APP_GOOGLE_MAPS_API_KEY ?? "",
      libraries: ["places", "drawing", "geometry"],
      version: "beta",
    });
    loader.load().then();
    return () => {
      loader.deleteScript();
    };
  }, []);

  useEffect(() => {
    if (queryParamsFromFilterBar) {
      setQueryParamsChanged(true);
    }
  }, [queryParamsFromFilterBar]);

  useEffect(() => {
    if (queryParamsChanged && isDriversStatusIdle) {
      setQueryParamsChanged(false);
      setResetPage(true);
    } else {
      setResetPage(false);
    }
  }, [isDriversStatusIdle, queryParamsChanged]);

  useEffect(() => {
    if (driverRoute !== null) {
      if (driverRoute.params.driverId) {
        if (
          driverRoute.params.action === "activate" ||
          driverRoute.params.action === "deactivate"
        ) {
          store.dispatch(
            getDriverDetailsAsync(parseInt(driverRoute.params.driverId))
          );
          setCurrentSelectedDriver(parseInt(driverRoute.params.driverId));
          setShowConfirmation(true);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, driverRoute]);

  useEffect(() => {
    if (
      driversStatusSliceStatus === "failed" &&
      driversStatusSliceReasonCode === ""
    ) {
      ToastNotification({
        toastId: "networkError",
        status: "error",
        description: t("common.networkError"),
      });
    }
  }, [driversStatusSliceStatus, driversStatusSliceReasonCode]);

  useEffect(() => {
    if (driverSliceStatus === "idle") {
      if (driverSliceReasonCode === GTFleetSuccessCodes.PATCH) {
        if (driverRoute?.params?.action === "activate") {
          ToastNotification({
            toastId: "driverActivateSuccess",
            status: "success",
            description: t("admin.driver.activateDriver.success"),
          });
          onClose();
        } else if (driverRoute?.params?.action === "deactivate") {
          ToastNotification({
            toastId: "driverDeactivateSuccess",
            status: "success",
            description: t("admin.driver.deactivateDriver.success"),
          });
          onClose();
        }
      }
    }
    if (driverSliceStatus === "failed") {
      switch (driverSliceReasonCode) {
        case GTFleetErrorCodes.DRIVER_NOT_FOUND:
          if (driverRoute?.params?.action === "activate") {
            ToastNotification({
              toastId: "driverActivateError",
              status: "error",
              description: t("admin.driver.activateDriver.error"),
            });
            onClose();
          } else if (driverRoute?.params?.action === "deactivate") {
            ToastNotification({
              toastId: "driverDeactivateError",
              status: "error",
              description: t("admin.driver.deactivateDriver.error"),
            });
            onClose();
          } else {
            ToastNotification({
              toastId: "driverNotFoundError",
              status: "error",
              description: t("common.driverNotFoundError"),
            });
          }
          break;
        case GTFleetErrorCodes.USER_UNAUTHORIZED:
          ToastNotification({
            toastId: "unauthorizedError",
            status: "error",
            description: t("common.unauthorizedError"),
          });
          break;
        default:
          ToastNotification({
            toastId: "networkError",
            status: "error",
            description: t("common.networkError"),
          });
          break;
      }
    }
  }, [driverSliceReasonCode, driverSliceStatus]);

  if (drivers.length > 0) {
    if (drivers.some((d) => d?.mac)) {
      tableData.columns = tableBuilder.setColumns([
        ...tableSchemaFix,
        { name: "mac" },
      ]);
    }
  } else {
    tableData.columns = tableBuilder.setColumns([...tableSchemaFix]);
  }

  tableData.rows =
    driversStatusSliceStatus === "idle" &&
    tableData.columns &&
    tableData?.columns?.length > 0
      ? drivers.map((driver: Driver) => {
          let vehicle: Vehicle =
            vehiclesSelectors.selectById(store.getState(), driver.vehicle) ??
            ({} as Vehicle);
          let driverStatus: DriverStatus =
            driversStatusSelectors.selectById(store.getState(), driver.id) ??
            ({} as DriverStatus);
          let fleetView: FleetView =
            fleetViewsSelectors.selectById(store.getState(), driver.fleet) ??
            ({} as FleetView);
          return tableBuilder.rowsBuilder(
            tableData.columns,
            {
              vehicle,
              driver,
              driverStatus,
              fleetView,
            },
            preferencesContext,
            navigate,
            permissions.drivers.write
          );
        })
      : tableData.rows ?? [];

  // loader for PageCount
  const isLoading = driversStatusSliceStatus === "loading";

  useEffect(() => {
    document.title = t("navigation.adminMenu.drivers") + " - Admin";
    return () => {
      store.dispatch(driversEmptyState());
      store.dispatch(driversStatusEmptyState());
    };
  }, []);

  function onClose() {
    setShowConfirmation(false);
    setCurrentSelectedDriver(-1);
    navigate({
      pathname: "/admin/drivers",
      search: queryParamsFromFilterBar,
    });
  }

  return (
    <>
      <PageFilters>
        <div className="col col-16">
          <AdminDriverFilterBar
            entityPermissions={{
              read: permissions.drivers.read,
              write: permissions.drivers.write,
            }}
            callback={setQueryParamsFromFilterBar}
          />
        </div>
      </PageFilters>
      <PageContent>
        <div className="col">
          {driverSliceStatus === "idle" ? (
            <ModalWrapper open={showConfirmation} closeAction={onClose}>
              <ModalBody
                title={
                  isActive
                    ? t("admin.driver.deactivateDriver.title")
                    : t("admin.driver.activateDriver.title")
                }
                desc={
                  isActive
                    ? t("admin.driver.deactivateDriver.desc").replace(
                        "*name",
                        `${selectedDriver.firstName} ${selectedDriver.lastName}`
                      )
                    : t("admin.driver.activateDriver.desc").replace(
                        "*name",
                        `${selectedDriver.firstName} ${selectedDriver.lastName}`
                      )
                }
                isScrollable={false}
              />
              <ModalFooter
                primaryAction={() => {
                  isActive
                    ? store.dispatch(deactivateDriverAsync(selectedDriver.id))
                    : store.dispatch(activateDriverAsync(selectedDriver.id));
                }}
                primaryLabel={t("common.confirm")}
                secondaryAction={onClose}
                secondaryLabel={t("common.cancel")}
              />
            </ModalWrapper>
          ) : null}
          {tableData?.rows?.length > 0 ? (
            <>
              <div className="admin-drivers-table">
                <Table data={tableData}>
                  <Table.Head hasTableSpace={true} />
                  <Table.Body />
                </Table>
              </div>
              <div className="admin-drivers-pagination">
                <PageCounter
                  isActionPerforming={isLoading}
                  resetPage={resetPage}
                  totalElements={AdminDriversStatusSliceTotalElements}
                  disabled={!isDriversStatusIdle}
                  numOfPages={AdminDriversStatusSlicePages}
                  onClick={(id, currentPage) => {
                    if (id !== currentPage) {
                      const pageAndSize = getQueryString({
                        page: id - 1,
                        size: "10",
                      });
                      const finalQueryParams = queryParamsFromFilterBar
                        ? queryParamsFromFilterBar +
                          pageAndSize.replace("?", "&")
                        : pageAndSize;
                      store.dispatch(
                        getFilteredDriversStatusAndDetailsPaginationAsync({
                          queryParams: finalQueryParams,
                          isActive: false,
                        })
                      );
                    }
                  }}
                />
              </div>
            </>
          ) : null}
        </div>
      </PageContent>
    </>
  );
};
