import { schema } from "normalizr";

// Definizione dello schema per un singolo planning
const publicRoutePlanningWithoutSummarySchema = new schema.Entity(
  "publicRoutePlanning"
);

// Definizione dello schema per un array di plannings
const publicRoutePlanningsWithoutSummarySchema = new schema.Array(
  publicRoutePlanningWithoutSummarySchema
);

// Definizione dello schema per il riepilogo che include un array di plannings
export const publicRoutePlanningSummarySchema = new schema.Entity(
  "publicRoutePlanningSummary",
  {
    plannings: publicRoutePlanningsWithoutSummarySchema,
  }
);

// Definizione dello schema per un array di riepiloghi
export const publicRoutePlanningsSummarysSchema = new schema.Array(
  publicRoutePlanningSummarySchema
);
