import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import _ from "lodash";
import { normalize } from "normalizr";
import { RootState } from "../../app/store";
import { Config } from "../../config/Config";
import { GTFleetSuccessCodes } from "../../config/GTFleetSuccessCodes";
import NotificationCategoryRepository from "./NotificationCategoryRepository";
import { notificationCategorysSchema } from "./notificationNormalization";
import { markOneAsReadAsync } from "./notificationSlice";

export type NotificationCategory = {
  id: number;
  name: string;
};

const notificationCategoryAdapter = createEntityAdapter<NotificationCategory>({
  selectId: (notification) => notification.id,
});

export const getNotificationCategoryAsync = createAsyncThunk(
  "notifications/notificationCategories",
  async (data: { queryParams?: string }, thunkAPI) => {
    const notificationCategoryRepository = new NotificationCategoryRepository();
    const response =
      await notificationCategoryRepository.getNotificationCategory(
        data.queryParams
      );
    const notificationsCategory = _.get(
      response,
      Config.NOTIFICATION_CATEGORY_RESPONSE_PATH
    );

    const totalPages = _.get(
      response,
      Config.DEADLINES_TOTAL_PAGES_RESPONSE_PATH
    );
    if (totalPages) {
      thunkAPI.dispatch(setNumberOfPages(totalPages));
    }
    if (notificationsCategory) {
      const normalizedData = normalize(
        notificationsCategory,
        notificationCategorysSchema
      );
      return normalizedData.entities;
    } else {
      return [];
    }
  }
);

function setFilteredData(state: any, action: PayloadAction<any>) {
  action.payload.notificationCategories
    ? notificationCategoryAdapter.setAll(
        state,
        action.payload.notificationCategories
      )
    : notificationCategoryAdapter.setAll(state, []);
}

export const notificationCategorySlice = createSlice({
  name: "notificationCategorySlice",
  initialState: notificationCategoryAdapter.getInitialState({
    status: "idle",
    reasonCode: "",
    totalPages: 0,
  }),
  reducers: {
    notificationCategoryEmptyState: (state) => {
      notificationCategoryAdapter.setAll(state, []);
      state.reasonCode = "";
      state.status = "idle";
    },
    setNumberOfPages: (state, action: PayloadAction<number>) => {
      state.totalPages = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      //#region Entity Reducers
      .addCase(
        getNotificationCategoryAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          setFilteredData(state, action);
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.GET;
        }
      )
      .addCase(getNotificationCategoryAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(getNotificationCategoryAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = "";
      })

      .addCase(
        markOneAsReadAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          action.payload.notificationCategories &&
            notificationCategoryAdapter.upsertMany(
              state,
              action.payload.notificationCategories
            );
        }
      );
  },
});

export const notificationCategorySelectors =
  notificationCategoryAdapter.getSelectors<RootState>(
    (state) => state.notificationCategory
  );

export const selectNotificationCategorySliceStatus = (state: any) =>
  state.notificationCategory.status;
export const selectNotificationCategorySliceReasonCode = (state: any) =>
  state.notificationCategory.reasonCode;
export const { notificationCategoryEmptyState, setNumberOfPages } =
  notificationCategorySlice.actions;

export default notificationCategorySlice.reducer;
