import PropTypes from "prop-types";
import "./LoginSidebar.css";

export const LoginSidebar = ({ bgImg, style, ...props }) => {
  let inlineStyle = {
    backgroundImage: bgImg
      ? `linear-gradient(rgba(0,82,189, 0.6), rgba(0,82,189, 0.6)), url(${bgImg})`
      : "none",
  };

  if (style && typeof style === "object") {
    inlineStyle = {
      ...inlineStyle,
      ...style,
    };
  }

  return (
    <div className="mn-login-sidebar" style={inlineStyle} {...props}></div>
  );
};

LoginSidebar.propTypes = {
  /**
   * The background of the sidebar.
   */
  bgImg: PropTypes.string,
};
