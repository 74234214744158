import { t } from "i18next";
import { useMemo } from "react";
import { formatTime } from "../../utils/DateAndTimeUtils";
import {
  convertEuroOnCurrency,
  kmToMiles,
  ltToGal,
  mtToKm,
  numberAnnotation,
} from "../../utils/Utils";
import { Button } from "../Button/Button";
import { Card } from "../Card/Card";
import { DataLabel } from "../DataLabel/DataLabel";
import { IconClock } from "../Icon/Line/Clock";
import { IconDollar } from "../Icon/Line/Dollar";
import { IconEuro } from "../Icon/Line/Euro";
import { IconGasStation } from "../Icon/Line/GasStation";
import { IconMenuRoutes } from "../Icon/Line/MenuRoutes";
import { IconParking } from "../Icon/Line/Parking";
import { IconPound } from "../Icon/Line/Pound";
import { IconTimer } from "../Icon/Line/Timer";
import "./TrackSummary.css";

export const TrackSummary = ({
  details,
  buttonClick,
  shortType,
  preference,
}) => {
  const currency =
    preference.currency === "EURO"
      ? "€"
      : preference.currency === "DOLLAR"
      ? "$"
      : "£";

  const tracksOrStopsCounterString = useMemo(() => {
    if (details?.routeType == "TRACK") {
      return `${t("locationHistory.trackSummary.title.track")} (${
        details?.trackCounter !== undefined ? details?.trackCounter : 0
      })`;
    }
    if (details?.routeType == "STOP") {
      return `${t("locationHistory.trackSummary.title.stop")} (${
        details?.stopCounter !== undefined ? details?.stopCounter : 0
      })`;
    }
    return `${t("locationHistory.trackSummary.title.stopTrack")
      .replace(
        "*name1",
        details?.trackCounter !== undefined ? details?.trackCounter : 0
      )
      .replace(
        "*name2",
        details?.stopCounter !== undefined ? details?.stopCounter : 0
      )}`;
  }, [details]);

  return shortType ||
    details.totalTravels === 0 ||
    details.totalTravels == undefined ? (
    <Card
      className="mn-lh-track-summary-short"
      title={`${t("locationHistory.trackSummary.title.driver")} (0)`}
    />
  ) : (
    <Card className="mn-lh-track-summary" title={tracksOrStopsCounterString}>
      <div className="mn-lh-track-summary__details">
        {details?.totalKm !== undefined && (
          <div className="traveled-km">
            <DataLabel
              dataText={
                preference?.isMetric
                  ? numberAnnotation(mtToKm(details?.totalKm)) + " km"
                  : numberAnnotation(kmToMiles(mtToKm(details?.totalKm))) +
                    " mil"
              }
              icon={<IconMenuRoutes size={14} />}
              label={t("locationHistory.trackSummary.traveledKm")}
            />
          </div>
        )}
        {details?.stopTime !== undefined && (
          <div className="stop-time">
            <DataLabel
              dataText={formatTime(
                details.stopTime * 1000,
                preference.language
              )}
              icon={<IconParking size={14} />}
              label={t("locationHistory.trackSummary.stopTime")}
            />
          </div>
        )}
        {details?.drivingTime !== undefined && (
          <div className="driving-time">
            <DataLabel
              dataText={formatTime(
                details.drivingTime * 1000,
                preference.language
              )}
              icon={<IconTimer size={14} />}
              label={t("locationHistory.trackSummary.drivingTime")}
            />
          </div>
        )}
        {false && details?.cost !== undefined && (
          <div className="cost">
            <DataLabel
              dataText={
                numberAnnotation(
                  convertEuroOnCurrency(preference.currency, details.cost)
                ) +
                " " +
                currency
              }
              icon={
                preference.currency === "EURO" ? (
                  <IconEuro size={14} />
                ) : preference.currency === "DOLLAR" ? (
                  <IconDollar size={14} />
                ) : (
                  <IconPound size={14} />
                )
              }
              label={t("locationHistory.trackSummary.cost")}
            />
          </div>
        )}
        {details?.utilization !== undefined && (
          <div className="utilization">
            <DataLabel
              dataText={
                details.utilization
                  ? numberAnnotation(details.utilization) + " %"
                  : t("common.na")
              }
              icon={<IconClock size={14} />}
              label={t("locationHistory.trackSummary.utilization")}
              graph={
                details.utilization && (
                  <div className="mn-chart mn-chart--simple-line">
                    <progress
                      value={parseInt(details.utilization)}
                      max="100"
                      className="progress-bar progress-bar--green"
                    ></progress>
                  </div>
                )
              }
            />
          </div>
        )}
        {details?.fuelCons !== undefined && (
          <div className="fuel-consumption">
            <DataLabel
              dataText={
                preference.isMetric
                  ? details.fuelCons + " lt"
                  : ltToGal(details.fuelCons).toFixed(2) + " gal"
              }
              icon={<IconGasStation size={14} />}
              label={t("locationHistory.trackSummary.fuelCons")}
            />
          </div>
        )}
        <div className="mn-lh-track-summary__button">
          <Button
            aspect="primary"
            label={t("locationHistory.trackSummary.button")}
            onClick={buttonClick}
            size="small"
          />
        </div>
      </div>
    </Card>
  );
};
