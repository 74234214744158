import { t } from "i18next";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useMatch, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { store } from "../../app/store";
import { GTFleetSuccessCodes } from "../../config/GTFleetSuccessCodes";
import { GTFleetErrorCodes } from "../../config/GTfleetErrorCodes";
import PageContent from "../../layout/PageContent";
import PageFilters from "../../layout/PageFilters";
import { Button } from "../../ui/Button/Button";
import { ElementType } from "../../ui/Group/ElementTypeEnum";
import { TabSection } from "../../ui/Tabs/TabSection";
import { Tabs } from "../../ui/Tabs/Tabs";
import { ThumbProfile } from "../../ui/ThumbProfile/ThumbProfile";
import { getFullDate } from "../../utils/DateAndTimeUtils";
import { ToastNotification } from "../../utils/ToastNotification";
import {
  getIllustrationFromVehicleType,
  getQueryString,
} from "../../utils/Utils";
import { Driver, driversSelectors } from "../driver/driversSlice";
import {
  DriverStatus,
  driversStatusSelectors,
  getDriversFleetAsync,
  selectDriverStatusSliceReasonCode,
  selectDriverStatusSliceStatus,
} from "../driver/driversStatusSlice";
import {
  Geofence,
  geofencesSelectors,
  getGeofencesAsync,
  selectGeofencesSliceReasonCode,
  selectGeofencesSliceStatus,
} from "../geofence/geofenceSlice";
import {
  GeofenceCategory,
  geofenceCategoriesSelectors,
  getGeofenceCategoriesAsync,
  selectGeofenceCategoriesSliceReasonCode,
  selectGeofenceCategoriesSliceStatus,
} from "../geofenceCategory/geofenceCategoriesSlice";
import { UserPermissions } from "../users/privilege/privilegesSlice";
import { Vehicle, vehiclesSelectors } from "../vehicle/vehiclesSlice";
import {
  VehicleStatus,
  getVehiclesStatusByFleetAsync,
  selectVehiclesStatusSliceReasonCode,
  selectVehiclesStatusSliceStatus,
  vehiclesStatusSelectors,
} from "../vehicle/vehiclesStatusSlice";
import "./AdminEditFleets.css";
import {
  AdminFleetsResourcesTab,
  ResourceProps,
} from "./AdminFleetsResourceTab";
import {
  FleetView,
  fleetViewsSelectors,
  getFilteredFleetsAsync,
} from "./fleetViewsSlice";
import {
  Fleet,
  fleetsSelectors,
  getFleetAsync,
  selectFleetsSliceReasonCode,
  selectFleetsSliceStatus,
  updateFleetAsync,
} from "./fleetsSlice";

interface AdminEditFleetsProps {
  permissions: UserPermissions;
}

export const AdminEditFleets: React.FC<AdminEditFleetsProps> = ({
  permissions,
}) => {
  const fleetRoute = useMatch("/admin/fleets/edit-fleet/:fleetId");
  const navigate = useNavigate();
  //#region manage entities to built item components
  //this hooks keep the current associations for fleet
  const [modifiedFleet, setModifiedFleet] = useState<Fleet>({} as Fleet);

  const fleetsSliceStatus = useAppSelector(selectFleetsSliceStatus);
  const fleetsSliceReasonCode = useAppSelector(selectFleetsSliceReasonCode);

  const selectedFleet: Fleet =
    fleetsSelectors.selectById(
      store.getState(),
      fleetRoute?.params.fleetId ?? -1
    ) ?? ({} as Fleet);

  let fleets: FleetView[] = useAppSelector((state) =>
    fleetViewsSelectors.selectAll(state)
  );
  let defaultFleet =
    fleets.find((x) => x.name === "Default") ?? ({} as FleetView);

  useEffect(() => {
    store.dispatch(getFilteredFleetsAsync({ isActive: false }));
    store.dispatch(
      getGeofencesAsync({ queryParams: getQueryString({ status: "ACTIVE" }) })
    );
    store.dispatch(getGeofenceCategoriesAsync());
  }, []);

  useEffect(() => {
    if (selectedFleet?.id) {
      store.dispatch(getVehiclesStatusByFleetAsync(selectedFleet.id));
      store.dispatch(getDriversFleetAsync(selectedFleet.id));
    }
  }, [selectedFleet]);

  useEffect(() => {
    if (defaultFleet?.id) {
      store.dispatch(getVehiclesStatusByFleetAsync(defaultFleet.id));
      store.dispatch(getDriversFleetAsync(defaultFleet.id));
    }
  }, [defaultFleet]);

  useEffect(() => {
    if (!_.isEmpty(selectedFleet)) {
      setModifiedFleet(selectedFleet);
    }
  }, [selectedFleet]);

  useEffect(() => {
    if (fleetRoute?.params.fleetId) {
      store.dispatch(getFleetAsync(Number(fleetRoute?.params.fleetId)));
    }
  }, [fleetRoute]);
  //#endregion

  //#region vehicles variables
  const [vehiclesTabResources, setVehiclesTabResources] = useState(
    [] as ResourceProps[]
  );
  let vehicles: Vehicle[] = useAppSelector((state) =>
    vehiclesSelectors.selectAll(state)
  );

  let vehiclesStatus: VehicleStatus[] = useAppSelector((state) =>
    vehiclesStatusSelectors.selectAll(state)
  );
  const vehiclesStatusSliceStatus = useAppSelector(
    selectVehiclesStatusSliceStatus
  );
  const vehiclesStatusSliceReasonCode = useAppSelector(
    selectVehiclesStatusSliceReasonCode
  );
  useEffect(() => {
    if (vehicles.length > 0 && vehiclesStatus.length > 0) {
      const newVehiclesTabResources = vehicles.map((vehicle) => {
        const vehicleStatus = vehiclesStatus.find((x) => x.id === vehicle.id);
        return {
          key: vehicle.id,
          id: vehicle.id,
          firstTitle: vehicle.alias,
          secondTitle: vehicle.plate,
          status: vehicleStatus?.dynamicFields?.vehicleStatus ?? "UNKNOWN",
          firstSubtitle: vehicle.status,
          secondSubtitle: getFullDate(
            vehicleStatus?.dynamicFields?.lastUpdate,
            false
          ),
          type: ElementType.vehicle,
          icon: getIllustrationFromVehicleType(vehicle),
          fleet: vehicle.fleet,
        } as ResourceProps;
      });
      if (newVehiclesTabResources.length > 0) {
        setVehiclesTabResources(newVehiclesTabResources);
      }
    }
  }, [vehicles, vehiclesStatus]);
  //#endregion

  //#region drivers variables
  const [driversTabResources, setDriversTabResources] = useState(
    [] as ResourceProps[]
  );
  let drivers: Driver[] = useAppSelector((state) =>
    driversSelectors.selectAll(state)
  );

  let driversStatus: DriverStatus[] = useAppSelector((state) =>
    driversStatusSelectors.selectAll(state)
  );
  const driversStatusSliceStatus = useAppSelector(
    selectDriverStatusSliceStatus
  );
  const driversStatusSliceReasonCode = useAppSelector(
    selectDriverStatusSliceReasonCode
  );
  useEffect(() => {
    if (drivers.length > 0 && driversStatus.length > 0) {
      const newdriversTabResources = drivers.map((driver) => {
        const driverStatus = driversStatus.find((x) => x.id === driver.id);
        return {
          key: driver.id,
          id: driver.id,
          firstTitle: `${driver.firstName} ${driver.lastName}`,
          status: driverStatus?.driverStatus ?? "UNKNOWN",
          firstSubtitle: driver.status,
          secondSubtitle: getFullDate(
            driverStatus?.dynamicFields?.lastUpdate,
            false
          ),
          type: ElementType.driver,
          icon: (
            <ThumbProfile
              size="small"
              alt="Driver Thumbnail"
              imgUrl={driver.avatar}
            />
          ),
          fleet: driver.fleet,
        } as ResourceProps;
      });
      if (newdriversTabResources.length > 0) {
        setDriversTabResources(newdriversTabResources);
      }
    }
  }, [drivers, driversStatus]);
  //#endregion

  //#region geofences variables
  const [geofencesTabResources, setGeofencesTabResources] = useState(
    [] as ResourceProps[]
  );
  let geofences: Geofence[] = useAppSelector(geofencesSelectors.selectAll);
  let geofenceCategories: GeofenceCategory[] = useAppSelector(
    geofenceCategoriesSelectors.selectAll
  );
  const geofenceCategoriesSliceStatus = useAppSelector(
    selectGeofenceCategoriesSliceStatus
  );
  const geofenceCategoriesSliceReasonCode = useAppSelector(
    selectGeofenceCategoriesSliceReasonCode
  );

  const geofencesSliceStatus = useAppSelector(selectGeofencesSliceStatus);
  const geofencesSliceReasonCode = useAppSelector(
    selectGeofencesSliceReasonCode
  );
  useEffect(() => {
    if (geofences.length > 0 && geofenceCategories.length > 0) {
      const newGeofencesTabResources = geofences.map((geofence) => {
        const geofenceCategory = geofenceCategories.find(
          (x) => x.id === geofence.geofenceCategory
        );
        const resourceProp = {
          key: geofence.id,
          id: geofence.id,
          firstTitle: geofence.name,
          secondTitle: geofenceCategory?.name,
          type: ElementType.geofence,
          icon: getIllustrationFromVehicleType(geofence),
          categoryColor: geofenceCategory?.color,
        } as ResourceProps;
        return resourceProp;
      });
      if (newGeofencesTabResources.length > 0) {
        setGeofencesTabResources(newGeofencesTabResources);
      }
    }
  }, [geofences, geofenceCategories]);
  //#endregion

  //#region toast notifications
  useEffect(() => {
    if (fleetsSliceStatus === "idle") {
      if (fleetsSliceReasonCode === GTFleetSuccessCodes.PATCH) {
        navigate("/admin/fleets");
      }
    }
    if (fleetsSliceStatus === "failed") {
      switch (fleetsSliceReasonCode) {
        case GTFleetErrorCodes.FLEET_BAD_REQUEST:
          ToastNotification({
            toastId: "fleetNotFoundError",
            status: "error",
            description: t("admin.groups.error.FLEET_BAD_REQUEST"),
          });
          break;
        case GTFleetErrorCodes.FLEET_NOT_FOUND:
          ToastNotification({
            toastId: "fleetNotFoundError",
            status: "error",
            description: t("admin.groups.error.FLEET_NOT_FOUND"),
          });
          break;
        case GTFleetErrorCodes.FLEET_DEFAULT_NOT_FOUND:
          ToastNotification({
            toastId: "defaultFleetError",
            status: "error",
            description: t("admin.groups.error.FLEET_DEFAULT_NOT_FOUND"),
          });
          break;
        case GTFleetErrorCodes.FLEET_ALREADY_DELETED:
          ToastNotification({
            toastId: "fleetExistsError",
            status: "error",
            description: t("admin.groups.error.FLEET_ALREADY_DELETED"),
          });
          break;
        case GTFleetErrorCodes.RESOURCE_ALREADY_ASSOCIATED_TO_A_FLEET:
          ToastNotification({
            toastId: "fleetBadRequest",
            status: "error",
            description: t(
              "admin.groups.error.RESOURCE_ALREADY_ASSOCIATED_TO_A_FLEET"
            ),
          });
          break;
        case GTFleetErrorCodes.DEFAULT_FLEET_CANNOT_BE_MODIFIED:
          ToastNotification({
            toastId: "defaultFleetNotModified",
            status: "error",
            description: t(
              "admin.groups.error.DEFAULT_FLEET_CANNOT_BE_MODIFIED"
            ),
          });
          break;
        case GTFleetErrorCodes.USER_UNAUTHORIZED:
          ToastNotification({
            toastId: "unauthorizedError",
            status: "error",
            description: t("common.unauthorizedError"),
          });
          navigate("/admin/fleets");
          break;
        case GTFleetErrorCodes.INTERNAL_SERVER_ERROR:
        case "":
          ToastNotification({
            toastId: "networkError",
            status: "error",
            description: t("common.networkError"),
          });
          break;
      }
    }
  }, [fleetsSliceStatus, fleetsSliceReasonCode]);

  useEffect(() => {
    if (
      driversStatusSliceStatus === "failed" &&
      driversStatusSliceReasonCode === ""
    ) {
      ToastNotification({
        toastId: "networkError",
        status: "error",
        description: t("common.networkError"),
      });
    }
  }, [driversStatusSliceStatus, driversStatusSliceReasonCode]);

  useEffect(() => {
    if (
      vehiclesStatusSliceStatus === "failed" &&
      vehiclesStatusSliceReasonCode === ""
    ) {
      ToastNotification({
        toastId: "networkError",
        status: "error",
        description: t("common.networkError"),
      });
    }
  }, [vehiclesStatusSliceStatus, vehiclesStatusSliceReasonCode]);

  useEffect(() => {
    if (geofenceCategoriesSliceStatus === "failed") {
      if (geofenceCategoriesSliceReasonCode === "") {
        ToastNotification({
          toastId: "networkError",
          status: "error",
          description: t("common.networkError"),
        });
      }
    }
  }, [geofenceCategoriesSliceStatus, geofenceCategoriesSliceReasonCode]);

  useEffect(() => {
    if (geofencesSliceStatus === "failed") {
      //gestire not found
      if (geofencesSliceReasonCode === "") {
        ToastNotification({
          toastId: "networkError",
          status: "error",
          description: t("common.networkError"),
        });
      }
    }
  }, [geofencesSliceStatus, geofencesSliceReasonCode]);
  //#endregion toast notifications

  return (
    <>
      <PageFilters>
        <div className="col col-16">
          <div className="ag-subtopbar">
            <div className="ag-subsection-name">{selectedFleet.name}</div>
            <div className="ag-button-save-group">
              <Button
                size="small"
                aspect="secondary"
                label={t("common.cancel")}
                onClick={() => navigate("/admin/fleets")}
              />
              {permissions.fleets.write && (
                <Button
                  size="small"
                  aspect="primary"
                  onlyIcon={false}
                  label={t("common.save")}
                  disabled={_.isEqual(modifiedFleet, selectedFleet)}
                  isLoading={fleetsSliceStatus === "loading"}
                  onClick={() => {
                    let elementsToBeAdded = {
                      VEHICLE: modifiedFleet.vehicles.filter(
                        (elem) => !selectedFleet.vehicles.includes(elem)
                      ),
                      DRIVER: modifiedFleet.drivers.filter(
                        (elem) => !selectedFleet.drivers.includes(elem)
                      ),
                      GEOFENCE: modifiedFleet.geofences.filter(
                        (elem) => !selectedFleet.geofences.includes(elem)
                      ),
                    };

                    let elementsToBeRemoved = {
                      VEHICLE: selectedFleet.vehicles.filter(
                        (elem) => !modifiedFleet.vehicles.includes(elem)
                      ),
                      DRIVER: selectedFleet.drivers.filter(
                        (elem) => !modifiedFleet.drivers.includes(elem)
                      ),
                      GEOFENCE: selectedFleet.geofences.filter(
                        (elem) => !modifiedFleet.geofences.includes(elem)
                      ),
                    };
                    store.dispatch(
                      updateFleetAsync({
                        fleetId: Number(fleetRoute?.params.fleetId),
                        fleet: {
                          elementsToBeAdded: elementsToBeAdded,
                          elementsToBeRemoved: elementsToBeRemoved,
                        },
                      })
                    );
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </PageFilters>
      <PageContent>
        <div className="group-tabs-container">
          <Tabs>
            <TabSection label="Vehicles">
              <AdminFleetsResourcesTab
                tabType="vehicles"
                resources={vehiclesTabResources}
                selectedFleet={modifiedFleet}
                fleets={fleets}
                defaultFleet={defaultFleet}
                onlyDefaultFleetNewResources={true}
                output={(selection: ResourceProps[]) => {
                  const tempModifiedFleet = { ...modifiedFleet };
                  tempModifiedFleet.vehicles = selection.map((x) => x.id);
                  setModifiedFleet(tempModifiedFleet);
                }}
              />
            </TabSection>
            <TabSection label="Drivers">
              <AdminFleetsResourcesTab
                tabType="drivers"
                resources={driversTabResources}
                selectedFleet={modifiedFleet}
                fleets={fleets}
                defaultFleet={defaultFleet}
                onlyDefaultFleetNewResources={true}
                output={(selection: ResourceProps[]) => {
                  const tempModifiedFleet = { ...modifiedFleet };
                  tempModifiedFleet.drivers = selection.map((x) => x.id);
                  setModifiedFleet(tempModifiedFleet);
                }}
              />
            </TabSection>
            <TabSection label="Geofences">
              <AdminFleetsResourcesTab
                tabType="geofences"
                resources={geofencesTabResources}
                selectedFleet={modifiedFleet}
                fleets={fleets}
                onlyDefaultFleetNewResources={false}
                output={(selection: ResourceProps[]) => {
                  const tempModifiedFleet = { ...modifiedFleet };
                  tempModifiedFleet.geofences = selection.map((x) => x.id);
                  setModifiedFleet(tempModifiedFleet);
                }}
              />
            </TabSection>
          </Tabs>
        </div>
      </PageContent>
    </>
  );
};
