import { Balloon } from "../Balloon/Balloon";
import { IconAdd } from "../Icon/Line/Add";
import { IconMinus } from "../Icon/Line/Minus";
import { IconRefresh } from "../Icon/Line/Refresh";
import "./ZoomTrackbar.css";

export const ZoomTrackbar = ({
  isInitial,
  isFinal,
  zoom,
  zoomIn,
  zoomOut,
  refresh,
}) => {
  return (
    <div>
      <div className="zoom-button-track">
        <div
          className={isFinal === true ? "zoom-button-disabled" : "zoom-button"}
        >
          <div className="iconAdd">
            <IconAdd
              size={14}
              onClick={() => {
                zoomIn();
              }}
            />
          </div>
          <div className="iconAddBalloon">
            <Balloon align="bottom" text="zoom in" />
          </div>
        </div>
        <div
          className={
            isInitial === true ? "zoom-button-disabled" : "zoom-button"
          }
        >
          <div className="iconMinus">
            <IconMinus
              size={14}
              onClick={() => {
                zoomOut();
              }}
            />
          </div>
          <div className="iconMinusBalloon">
            <Balloon align="bottom" text="zoom out" />
          </div>
        </div>
        <div
          className={
            isInitial === true ? "zoom-button-disabled" : "zoom-button"
          }
        >
          <div className="iconRefresh">
            <IconRefresh
              size={14}
              onClick={() => {
                refresh();
              }}
            />
          </div>
          <div className="iconRefreshBalloon">
            <Balloon align="bottom" text="reset trackbar" />
          </div>
        </div>
      </div>
      <div className="zoom-number">{zoom} %</div>
    </div>
  );
};
