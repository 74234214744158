//#region Slice
import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  EntityId,
  PayloadAction,
} from "@reduxjs/toolkit";
import _ from "lodash";
import { normalize } from "normalizr";
import { RootState } from "../../app/store";
import { Config } from "../../config/Config";
import { GTFleetSuccessCodes } from "../../config/GTFleetSuccessCodes";
import { notificationEventsSchema } from "./notificationNormalization";
import NotificationsRepository from "./NotificationRepository";
import {
  markAllAsReadAsync,
  markOneAsReadAsync,
  NotificationEvent,
} from "./notificationSlice";

export const getFilteredNotificationsPreviewAsync = createAsyncThunk(
  "notificationsPreview/getFilteredNotificationsPreviewAsync",
  async (data: { queryParams?: string }, { rejectWithValue }) => {
    try {
      const notificationsPreviewRepository = new NotificationsRepository();
      const response =
        await notificationsPreviewRepository.getFilteredNotifications(
          data.queryParams
        );
      const previewNotification = _.get(
        response,
        Config.NOTIFICATION_EVENTS_RESPONSE_PATH
      );

      const normalizedData = normalize(
        previewNotification ?? [],
        notificationEventsSchema
      );
      return normalizedData.entities;
    } catch (error: any) {
      if (!error.response) throw error;
      return rejectWithValue(error.response.status);
    }
  }
);

function setFilteredData(state: any, action: PayloadAction<any>) {
  action.payload.notification
    ? notificationPreviewEventsAdapter.setAll(
        state,
        action.payload.notification
      )
    : notificationPreviewEventsAdapter.setAll(state, []);
}

const notificationPreviewEventsAdapter = createEntityAdapter<NotificationEvent>(
  {
    selectId: (notification) => notification.id,
  }
);

export const notificationPreviewEventsSlice = createSlice({
  name: "notificationPreviewSlice",
  initialState: notificationPreviewEventsAdapter.getInitialState({
    status: "idle",
    reasonCode: "",
    totalPages: 0,
  }),
  reducers: {
    notificationPreviewEventsEmptyState: (state) => {
      notificationPreviewEventsAdapter.setAll(state, []);
      state.reasonCode = "";
      state.status = "idle";
    },
  },
  extraReducers: (builder) => {
    builder
      //#region Entity Reducers
      .addCase(
        getFilteredNotificationsPreviewAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          setFilteredData(state, action);
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.GET;
        }
      )
      .addCase(getFilteredNotificationsPreviewAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(getFilteredNotificationsPreviewAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = "";
      })
      .addCase(
        markOneAsReadAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          let toRemove: EntityId[] = [];
          if (action.payload?.notification) {
            toRemove = Object.keys(action.payload?.notification);
          }
          notificationPreviewEventsAdapter.removeMany(state, toRemove);
        }
      )
      .addCase(
        markAllAsReadAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          notificationPreviewEventsAdapter.removeAll(state);
        }
      );
  },
});

export const notificationPreviewEventsSelectors =
  notificationPreviewEventsAdapter.getSelectors<RootState>(
    (state) => state.previewNotificationEvents
  );

export const selectNotificationPreviewEventsSliceStatus = (state: any) =>
  state.previewNotificationEvents.status;
export const selectNotificationPreviewEventsSliceReasonCode = (state: any) =>
  state.previewNotificationEvents.reasonCode;
export const { notificationPreviewEventsEmptyState } =
  notificationPreviewEventsSlice.actions;

export default notificationPreviewEventsSlice.reducer;
