import PropTypes from "prop-types";

export const IllustrationTrailerTruck = ({ size }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={size}
      height={size}
      viewBox="0 0 70 60" vv
    >
      <defs>
        <path
          id="path-1"
          d="M3.079.477C1.379 1.458 0 3.632 0 5.332c0 .728.252 1.25.674 1.534.565.38 1.775 1.035 1.775 1.035 1.7-.982 5.04-4.392 5.04-6.092 0-.74-1.592-1.299-2.027-1.579A1.545 1.545 0 004.616 0c-.452 0-.977.153-1.537.477"
        ></path>
        <path
          id="path-3"
          d="M2.044.316C.915.968 0 2.411 0 3.539c0 1.129.915 1.516 2.044.864 1.128-.651 2.043-2.094 2.043-3.223C4.087.423 3.675 0 3.064 0c-.301 0-.649.102-1.02.316"
        ></path>
        <path
          id="path-5"
          d="M2.051.172c-.07.032-.143.07-.217.113C.936.807.183 1.89.031 2.833.011 2.95 0 3.065 0 3.177c0 1.01.824 1.357 1.834.775 1.014-.586 1.833-1.879 1.833-2.892C3.667.38 3.299 0 2.751 0c-.211 0-.447.056-.7.172"
        ></path>
        <path
          id="path-7"
          d="M1.803.095a2.834 2.834 0 00-.259.172C.888.74.341 1.493.102 2.226c-.045.14-.08.281-.102.417a.63.63 0 00.046.137c.256.144.526.25.691.309.014.007.028.01.038.013a.27.27 0 00.098.032.53.53 0 01.032.011c.004-.004.007-.004.011-.007.754-.445 1.703-1.817 1.703-2.566 0 0-.027-.032-.076-.085L2.539.483A4.781 4.781 0 001.985 0c-.06.028-.119.06-.182.095"
        ></path>
        <path
          id="path-9"
          d="M.651.054L.583.09C.297.257.057.6.009.901A.668.668 0 000 1.01c0 .321.261.431.583.247.322-.187.582-.598.582-.92C1.165.121 1.048 0 .873 0a.533.533 0 00-.222.054"
        ></path>
        <path
          id="path-11"
          d="M3.079.476C1.378 1.458 0 3.632 0 5.332c0 .728.252 1.25.674 1.534.564.379 1.774 1.035 1.774 1.035 1.701-.982 5.041-4.392 5.041-6.092 0-.74-1.592-1.299-2.027-1.579A1.55 1.55 0 004.615 0c-.452 0-.977.153-1.536.476"
        ></path>
        <path
          id="path-13"
          d="M2.043.316C.914.968 0 2.411 0 3.539c0 1.129.914 1.516 2.043.864 1.128-.651 2.044-2.094 2.044-3.223C4.087.423 3.675 0 3.063 0c-.3 0-.649.102-1.02.316"
        ></path>
        <path
          id="path-15"
          d="M2.051.173c-.071.032-.144.07-.218.113C.935.808.182 1.891.031 2.834A1.938 1.938 0 000 3.178c0 1.01.823 1.357 1.833.775 1.013-.586 1.834-1.88 1.834-2.892C3.667.381 3.299 0 2.75 0c-.21 0-.447.056-.699.173"
        ></path>
        <path
          id="path-17"
          d="M1.802.096a2.641 2.641 0 00-.259.172C.887.74.34 1.494.102 2.227c-.046.14-.081.281-.102.417a.552.552 0 00.046.137c.255.143.526.249.69.309l.039.013c.031.019.063.025.098.032a.632.632 0 01.031.011c.004-.004.008-.004.011-.007.754-.446 1.704-1.817 1.704-2.567L2.542.488 2.539.484A4.808 4.808 0 001.984 0a4.1 4.1 0 00-.182.096"
        ></path>
        <path
          id="path-19"
          d="M.653.054C.63.064.607.077.583.09.298.256.059.6.011.9A.595.595 0 000 1.01c0 .32.263.431.583.246.323-.186.584-.597.584-.919C1.167.121 1.049 0 .875 0a.533.533 0 00-.222.054"
        ></path>
        <path id="path-21" d="M0 0L65.351 0 65.351 37.6796 0 37.6796z"></path>
      </defs>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-621 -465)">
          <g transform="translate(621 465)">
            <g transform="translate(0 1)">
              <path
                fill="#031832"
                d="M0 46.2915L18.2138 57.2469 69.217 29.2385 49.691 17.7135z"
                opacity="0.1"
              ></path>
              <path
                fill="#5A5C54"
                d="M56.4805 20.4244L67.8335 26.9794 67.8335 25.3844 56.4805 18.8304z"
              ></path>
              <path
                fill="#888"
                d="M67.8339 25.3845L67.8339 26.9795 68.1119 26.8195 68.1119 25.2255z"
              ></path>
              <path
                fill="#BFBFBF"
                d="M56.7585 18.6699L68.1115 25.2249 67.8335 25.3849 56.4805 18.8299z"
              ></path>
              <path
                fill="#949494"
                d="M67.6134 24.0669L67.6134 25.2249 67.7954 25.1189 67.7954 23.9619z"
              ></path>
              <path
                fill="#757575"
                d="M67.4082 25.1064L67.6132 25.2254 67.6132 24.0664 67.4082 23.9494z"
              ></path>
              <path
                fill="#C4C4C4"
                d="M67.4082 23.949L67.6132 24.067 67.7952 23.962 67.5902 23.843z"
              ></path>
              <path
                fill="#949494"
                d="M64.5768 22.3074L64.5768 23.4654 64.7588 23.3594 64.7588 22.2024z"
              ></path>
              <path
                fill="#757575"
                d="M64.3725 23.3469L64.5765 23.4659 64.5765 22.3069 64.3725 22.1899z"
              ></path>
              <path
                fill="#C4C4C4"
                d="M64.3725 22.1895L64.5765 22.3075 64.7585 22.2025 64.5545 22.0835z"
              ></path>
              <path
                fill="#949494"
                d="M61.5402 20.5479L61.5402 21.7059 61.7222 21.5999 61.7222 20.4429z"
              ></path>
              <path
                fill="#757575"
                d="M61.3359 21.5874L61.5399 21.7064 61.5399 20.5484 61.3359 20.4304z"
              ></path>
              <path
                fill="#C4C4C4"
                d="M61.3359 20.43L61.5399 20.548 61.7219 20.443 61.5179 20.325z"
              ></path>
              <path
                fill="#949494"
                d="M58.5036 18.7884L58.5036 19.9464 58.6856 19.8404 58.6856 18.6834z"
              ></path>
              <path
                fill="#757575"
                d="M58.2993 19.8279L58.5033 19.9469 58.5033 18.7879 58.2993 18.6709z"
              ></path>
              <path
                fill="#C4C4C4"
                d="M58.2993 18.6705L58.5033 18.7885 58.6853 18.6835 58.4813 18.5655z"
              ></path>
              <path
                fill="#BFBFBF"
                d="M67.5452 22.5732L68.5802 21.9752 57.2272 15.4212 56.1922 16.0182z"
              ></path>
              <path
                fill="#888"
                d="M67.5452 24.3734L68.5802 23.7754 68.5802 21.9754 67.5452 22.5734z"
              ></path>
              <path
                fill="#5A5C54"
                d="M56.192 16.0184L67.545 22.5734 67.545 24.3734 56.192 17.8184z"
              ></path>
              <path
                fill="#5A5C54"
                d="M3.5377 45.5116L15.3137 52.2826 15.3137 50.4206 3.5377 43.6486z"
              ></path>
              <path
                fill="#AE59A1"
                d="M57.2272 17.8336L56.1922 18.4316 56.1922 15.4476 57.2272 14.8496z"
              ></path>
              <path
                fill="#AE59A1"
                d="M20.9512 37.3555L54.7472 17.8465 54.7472 16.4105 20.9512 35.9205z"
              ></path>
              <path
                fill="#AE6800"
                d="M38.1718 30.2416L55.0838 20.4796 53.3678 17.7156 39.9328 25.4706z"
              ></path>
              <g transform="translate(21.73 17.932)">
                <path
                  fill="#424852"
                  d="M18.252 12.03c0 1.7 1.378 2.284 3.079 1.303 1.7-.982 3.079-3.156 3.079-4.857 0-1.7-1.38-2.284-3.08-1.302-1.7.981-3.078 3.157-3.078 4.856"
                ></path>
                <path
                  fill="#424852"
                  d="M25.323 7.949c0 1.7 1.378 2.283 3.079 1.302 1.7-.982 3.079-3.156 3.079-4.857 0-1.7-1.38-2.283-3.08-1.301-1.7.98-3.078 3.156-3.078 4.856"
                ></path>
                <path
                  fill="#EAEF2F"
                  d="M17.202 12.812l-.524-.049c.299-3.23 3.143-5.642 3.264-5.743l.037-.026 9.627-5.551c.06-.018 1.464-.454 2.5.224.58.38.92 1.021 1.012 1.906l-.523.054c-.075-.72-.336-1.231-.777-1.52-.763-.499-1.85-.22-2.027-.17l-9.528 5.5c-.23.199-2.794 2.484-3.061 5.375"
                ></path>
                <path
                  fill="#ADD1B9"
                  d="M26.655 7.18c0 .965.782 1.295 1.746.738.965-.557 1.747-1.79 1.747-2.754 0-.965-.782-1.295-1.747-.738-.964.556-1.746 1.79-1.746 2.754"
                ></path>
                <path
                  fill="#ADD1B9"
                  d="M19.584 11.261c0 .965.782 1.295 1.746.738.965-.557 1.747-1.79 1.747-2.754 0-.965-.782-1.295-1.747-.738-.964.556-1.746 1.79-1.746 2.754"
                ></path>
                <path
                  fill="#725986"
                  d="M35.4978 3.0576L34.4628 3.6556 34.4628 2.0886 35.4978 1.4906z"
                ></path>
                <path
                  fill="#725986"
                  d="M35.3287 2.191L34.8347 2.476 34.8347 0.285 35.3287 0z"
                ></path>
                <path
                  fill="#4CE786"
                  d="M0.3433 19.0084L16.0773 9.9254 16.0773 9.1334 0.3433 18.2154z"
                ></path>
                <path
                  fill="#4CE786"
                  d="M0.3433 21.0184L16.0773 11.9354 16.0773 11.1434 0.3433 20.2254z"
                ></path>
                <path
                  fill="#424852"
                  d="M15.4782 13.7718L16.0772 13.4258 16.0772 8.2628 15.4782 8.6088z"
                ></path>
                <path
                  fill="#424852"
                  d="M0.2848 23.3743L0.8838 23.0283 0.8838 17.0923 0.2848 17.4383z"
                ></path>
                <path
                  fill="#424852"
                  d="M3.55271368e-15 23.6076L1.169 22.9326 1.169 22.5826 3.55271368e-15 23.2566z"
                ></path>
                <path
                  fill="#3A3B36"
                  d="M10.575 25.9787L44.371 6.4697 44.371 5.0337 10.575 24.5437z"
                ></path>
                <path
                  fill="#4A4D46"
                  d="M25.4172 17.4891L42.9912 6.3381 41.3692 5.3961 27.9342 13.1511 26.7012 16.4951z"
                ></path>
                <path
                  fill="#3A3B36"
                  d="M27.7956 18.8647L43.7056 9.6807 40.8426 7.5187 27.9346 13.1517 25.4176 17.4887z"
                ></path>
              </g>
              <g transform="translate(56.752 26.173)">
                <mask id="mask-2" fill="#fff">
                  <use xlinkHref="#path-1"></use>
                </mask>
                <path
                  d="M3.079.477C1.379 1.458 0 3.632 0 5.332c0 .728.252 1.25.674 1.534.565.38 1.775 1.035 1.775 1.035 1.7-.982 5.04-4.392 5.04-6.092 0-.74-1.592-1.299-2.027-1.579A1.545 1.545 0 004.616 0c-.452 0-.977.153-1.537.477"
                  mask="url(#mask-2)"
                ></path>
              </g>
              <path
                fill="#424852"
                d="M58.418 32.476c0 1.7 1.378 2.283 3.079 1.302 1.7-.982 3.079-3.156 3.079-4.857 0-1.7-1.38-2.283-3.08-1.3-1.7.98-3.078 3.155-3.078 4.855"
              ></path>
              <g transform="translate(59.327 28.247)">
                <mask id="mask-4" fill="#fff">
                  <use xlinkHref="#path-3"></use>
                </mask>
                <path
                  d="M2.044.316C.915.968 0 2.411 0 3.539c0 1.129.915 1.516 2.044.864 1.128-.651 2.043-2.094 2.043-3.223C4.087.423 3.675 0 3.064 0c-.301 0-.649.102-1.02.316"
                  mask="url(#mask-4)"
                ></path>
              </g>
              <path
                fill="#D7D9DB"
                d="M59.453 31.878c0 1.128.915 1.515 2.043.864 1.13-.652 2.044-2.095 2.044-3.223 0-1.129-.915-1.515-2.044-.864-1.128.652-2.043 2.094-2.043 3.223"
              ></path>
              <g transform="translate(59.662 28.581)">
                <mask id="mask-6" fill="#fff">
                  <use xlinkHref="#path-5"></use>
                </mask>
                <path
                  d="M2.051.172c-.07.032-.143.07-.217.113C.936.807.183 1.89.031 2.833.011 2.95 0 3.065 0 3.177c0 1.01.824 1.357 1.834.775 1.014-.586 1.833-1.879 1.833-2.892C3.667.38 3.299 0 2.751 0c-.211 0-.447.056-.7.172"
                  mask="url(#mask-6)"
                ></path>
              </g>
              <path
                fill="#E3E5E6"
                d="M62.44 29.535v.014c0 .768-.622 1.746-1.389 2.188-.14.084-.277.14-.407.175-.428.116-.768-.017-.905-.36a.55.55 0 01-.045-.138c.021-.136.056-.277.101-.417.24-.733.786-1.487 1.441-1.96.085-.063.172-.119.26-.172a3.19 3.19 0 01.182-.094c.014-.007.025-.014.035-.018.428-.017.72.263.726.782"
              ></path>
              <g transform="translate(59.693 28.771)">
                <mask id="mask-8" fill="#fff">
                  <use xlinkHref="#path-7"></use>
                </mask>
                <path
                  d="M1.803.095a2.834 2.834 0 00-.259.172C.888.74.341 1.493.102 2.226c-.045.14-.08.281-.102.417a.63.63 0 00.046.137c.256.144.526.25.691.309.014.007.028.01.038.013a.27.27 0 00.098.032.53.53 0 01.032.011c.004-.004.007-.004.011-.007.754-.445 1.703-1.817 1.703-2.566 0 0-.027-.032-.076-.085L2.539.483A4.781 4.781 0 001.985 0c-.06.028-.119.06-.182.095"
                  mask="url(#mask-8)"
                ></path>
              </g>
              <path
                fill="#D9DBDD"
                d="M62.488 29.847c0 .66-.535 1.505-1.196 1.887-.659.38-1.197.153-1.197-.505 0-.074.007-.15.021-.225.098-.615.59-1.322 1.176-1.663.048-.027.096-.052.141-.073.595-.274 1.055-.034 1.055.579"
              ></path>
              <g transform="translate(60.755 29.905)">
                <mask id="mask-10" fill="#fff">
                  <use xlinkHref="#path-9"></use>
                </mask>
                <path
                  d="M.651.054L.583.09C.297.257.057.6.009.901A.668.668 0 000 1.01c0 .321.261.431.583.247.322-.187.582-.598.582-.92C1.165.121 1.048 0 .873 0a.533.533 0 00-.222.054"
                  mask="url(#mask-10)"
                ></path>
              </g>
              <path
                fill="#E3E5E6"
                d="M61.422 30.32c0 .129-.104.294-.233.368-.13.074-.234.03-.234-.098 0-.015.002-.03.004-.044a.505.505 0 01.257-.34c.116-.052.206-.006.206.114"
              ></path>
              <g transform="translate(49.682 30.214)">
                <mask id="mask-12" fill="#fff">
                  <use xlinkHref="#path-11"></use>
                </mask>
                <path
                  d="M3.079.476C1.378 1.458 0 3.632 0 5.332c0 .728.252 1.25.674 1.534.564.379 1.774 1.035 1.774 1.035 1.701-.982 5.041-4.392 5.041-6.092 0-.74-1.592-1.299-2.027-1.579A1.55 1.55 0 004.615 0c-.452 0-.977.153-1.536.476"
                  mask="url(#mask-12)"
                ></path>
              </g>
              <path
                fill="#424852"
                d="M51.347 36.517c0 1.7 1.378 2.283 3.08 1.302 1.7-.982 3.078-3.156 3.078-4.857 0-1.7-1.379-2.283-3.079-1.301-1.7.981-3.079 3.156-3.079 4.856"
              ></path>
              <g transform="translate(52.257 32.288)">
                <mask id="mask-14" fill="#fff">
                  <use xlinkHref="#path-13"></use>
                </mask>
                <path
                  d="M2.043.316C.914.968 0 2.411 0 3.539c0 1.129.914 1.516 2.043.864 1.128-.651 2.044-2.094 2.044-3.223C4.087.423 3.675 0 3.063 0c-.3 0-.649.102-1.02.316"
                  mask="url(#mask-14)"
                ></path>
              </g>
              <path
                fill="#D7D9DB"
                d="M52.383 35.92c0 1.128.915 1.514 2.043.863 1.129-.652 2.044-2.095 2.044-3.223 0-1.129-.915-1.515-2.044-.864-1.128.652-2.043 2.094-2.043 3.223"
              ></path>
              <g transform="translate(52.592 32.621)">
                <mask id="mask-16" fill="#fff">
                  <use xlinkHref="#path-15"></use>
                </mask>
                <path
                  d="M2.051.173c-.071.032-.144.07-.218.113C.935.808.182 1.891.031 2.834A1.938 1.938 0 000 3.178c0 1.01.823 1.357 1.833.775 1.013-.586 1.834-1.88 1.834-2.892C3.667.381 3.299 0 2.75 0c-.21 0-.447.056-.699.173"
                  mask="url(#mask-16)"
                ></path>
              </g>
              <path
                fill="#E3E5E6"
                d="M55.368 33.576v.014c0 .768-.62 1.746-1.388 2.188-.14.084-.277.14-.407.175-.428.116-.768-.017-.905-.361a.55.55 0 01-.045-.137c.021-.136.056-.277.101-.417.24-.733.786-1.487 1.441-1.96.085-.063.172-.119.26-.172a3.19 3.19 0 01.182-.094c.014-.007.025-.014.035-.018.428-.017.72.263.726.782"
              ></path>
              <g transform="translate(52.623 32.811)">
                <mask id="mask-18" fill="#fff">
                  <use xlinkHref="#path-17"></use>
                </mask>
                <path
                  d="M1.802.096a2.641 2.641 0 00-.259.172C.887.74.34 1.494.102 2.227c-.046.14-.081.281-.102.417a.552.552 0 00.046.137c.255.143.526.249.69.309l.039.013c.031.019.063.025.098.032a.632.632 0 01.031.011c.004-.004.008-.004.011-.007.754-.446 1.704-1.817 1.704-2.567L2.542.488 2.539.484A4.808 4.808 0 001.984 0a4.1 4.1 0 00-.182.096"
                  mask="url(#mask-18)"
                ></path>
              </g>
              <path
                fill="#D9DBDD"
                d="M55.418 33.888c0 .66-.535 1.505-1.196 1.887-.66.38-1.197.153-1.197-.505 0-.075.007-.15.02-.226.099-.614.59-1.322 1.177-1.662.048-.027.096-.052.14-.073.596-.274 1.056-.034 1.056.579"
              ></path>
              <g transform="translate(53.683 33.946)">
                <mask id="mask-20" fill="#fff">
                  <use xlinkHref="#path-19"></use>
                </mask>
                <path
                  d="M.653.054C.63.064.607.077.583.09.298.256.059.6.011.9A.595.595 0 000 1.01c0 .32.263.431.583.246.323-.186.584-.597.584-.919C1.167.121 1.049 0 .875 0a.533.533 0 00-.222.054"
                  mask="url(#mask-20)"
                ></path>
              </g>
              <g transform="translate(3.537)">
                <path
                  fill="#E3E5E6"
                  d="M50.814 34.36c0 .13-.104.294-.233.368-.13.074-.234.03-.234-.098 0-.015.002-.029.004-.044a.505.505 0 01.23-.325l.027-.014c.116-.053.206-.007.206.113"
                ></path>
                <path
                  fill="#21221C"
                  d="M45.9877 36.7963L47.9627 31.9293 46.1267 31.0833 43.6097 35.4203z"
                ></path>
                <path
                  fill="#6E6E6E"
                  d="M46.224 37.25l-2.513-1.402c.299-3.231 3.142-5.643 3.263-5.744l.038-.026 9.627-5.551c.059-.02 1.464-.454 2.5.224.28.184 2.345 1.098 2.513 1.403.178.324-1.55.047-1.501.503l-.524.054c-.074-.72-.116-.552-.39-.51-.9.146-.68.463-.858.513l-8.706 5.128c-.23.199-3.182 2.517-3.45 5.408"
                ></path>
                <path
                  fill="#192535"
                  d="M59.627 26.71l2.513 1.403s.276-.775 0-1.011c-.277-.235-1.015-1.206-1.709-1.155-.694.05-.905.277-.905.277l.101.487z"
                ></path>
                <path
                  fill="#44474F"
                  d="M46.747 37.298l-.524-.049c.3-3.23 3.143-5.642 3.264-5.743l.037-.026 9.627-5.55c.06-.02 1.464-.454 2.5.224.58.38.92 1.02 1.012 1.905l-.523.054c-.075-.72-.336-1.23-.777-1.52-.763-.498-1.849-.22-2.027-.17l-9.528 5.5c-.23.2-2.794 2.485-3.06 5.375"
                ></path>
                <path
                  fill="#888"
                  d="M45.6225 37.9125L45.0235 38.2585 45.0235 33.0955 45.6225 32.7495z"
                ></path>
                <path
                  fill="#5A5C54"
                  d="M45.0235 38.1994L44.6075 38.0114 44.6075 32.8484 45.0235 33.0954z"
                ></path>
                <path
                  fill="#BFBFBF"
                  d="M29.691 42.915v.686l15.247-9.174-.323-.214-14.932 8.62c.001.028.008.053.008.082"
                ></path>
                <path
                  fill="#BFBFBF"
                  d="M29.6914 45.6111L44.9384 36.4371 44.6154 36.2231 29.6914 44.8381z"
                ></path>
                <path
                  fill="#888"
                  d="M29.6914 43.2286L29.6914 44.0216 44.9384 35.2196 44.9384 34.4276z"
                ></path>
                <path
                  fill="#BFBFBF"
                  d="M30.714 47.0759L29.545 47.7509 28.782 47.2979 29.95 46.6229z"
                ></path>
                <path
                  fill="#5A5C54"
                  d="M29.6508 47.4707L29.2348 47.2237 29.2348 41.2877 29.6508 41.5347z"
                ></path>
                <path
                  fill="#888"
                  d="M30.2498 47.125L29.6508 47.471 29.6508 41.534 30.2498 41.189z"
                ></path>
                <path
                  fill="#888"
                  d="M30.714 47.4265L29.545 48.1015 29.545 47.7505 30.714 47.0755z"
                ></path>
                <path
                  fill="#5A5C54"
                  d="M28.7816 47.2977L29.5456 47.7507 29.5456 48.1007 28.7816 47.6487z"
                ></path>
                <path
                  fill="#888"
                  d="M29.6914 45.2386L29.6914 46.0316 44.9384 37.2296 44.9384 36.4376z"
                ></path>
                <path
                  fill="#ECC52B"
                  d="M11.7767 52.2829L64.7257 21.7179 64.7257 19.8549 11.7767 50.4199z"
                ></path>
                <path
                  fill="#BFBFBF"
                  d="M11.7767 52.2829L65.1347 20.0669 64.7257 19.8549 11.7767 50.4199z"
                ></path>
                <path
                  fill="#888"
                  d="M12.1857 52.4947L65.1347 21.9297 65.1347 20.0667 12.1857 50.6317z"
                ></path>
                <path
                  fill="#DADED5"
                  d="M12.723 51.7335L64.693 21.7335 64.693 20.7725 12.723 50.7725z"
                ></path>
                <path
                  fill="#BFBFBF"
                  d="M64.7254 19.855L53.3724 13.3 0.0004 43.649 11.7764 50.42z"
                ></path>
                <path
                  fill="#5A5C54"
                  d="M12.1857 50.6321L11.7767 50.4201 11.7767 52.2831 12.1857 52.4951z"
                ></path>
                <path
                  fill="#FFA633"
                  d="M14.579 50.6689L13.774 51.1389 13.774 50.1759 14.579 49.7059z"
                ></path>
                <path
                  fill="#FFA633"
                  d="M17.6148 48.9158L16.8098 49.3858 16.8098 48.4228 17.6148 47.9528z"
                ></path>
                <path
                  fill="#FFA633"
                  d="M20.6505 47.1627L19.8455 47.6327 19.8455 46.6697 20.6505 46.1997z"
                ></path>
                <path
                  fill="#FFA633"
                  d="M23.6862 45.4096L22.8812 45.8796 22.8812 44.9166 23.6862 44.4466z"
                ></path>
                <path
                  fill="#FFA633"
                  d="M26.7219 43.6566L25.9169 44.1266 25.9169 43.1636 26.7219 42.6936z"
                ></path>
                <path
                  fill="#FFA633"
                  d="M29.7576 41.9035L28.9526 42.3735 28.9526 41.4105 29.7576 40.9405z"
                ></path>
                <path
                  fill="#FFA633"
                  d="M32.7933 40.1504L31.9883 40.6204 31.9883 39.6574 32.7933 39.1874z"
                ></path>
                <path
                  fill="#FFA633"
                  d="M35.829 38.3973L35.024 38.8673 35.024 37.9043 35.829 37.4343z"
                ></path>
                <path
                  fill="#FFA633"
                  d="M38.8647 36.6442L38.0597 37.1142 38.0597 36.1512 38.8647 35.6812z"
                ></path>
                <path
                  fill="#FFA633"
                  d="M41.9004 34.8911L41.0954 35.3611 41.0954 34.3981 41.9004 33.9281z"
                ></path>
                <path
                  fill="#FFA633"
                  d="M44.9361 33.1381L44.1311 33.6081 44.1311 32.6451 44.9361 32.1751z"
                ></path>
                <path
                  fill="#FFA633"
                  d="M47.9718 31.385L47.1668 31.855 47.1668 30.892 47.9718 30.422z"
                ></path>
                <path
                  fill="#FFA633"
                  d="M51.0075 29.6319L50.2025 30.1019 50.2025 29.1389 51.0075 28.6689z"
                ></path>
                <path
                  fill="#FFA633"
                  d="M54.0433 27.8788L53.2383 28.3488 53.2383 27.3858 54.0433 26.9158z"
                ></path>
                <path
                  fill="#FFA633"
                  d="M57.079 26.1257L56.274 26.5957 56.274 25.6327 57.079 25.1627z"
                ></path>
                <path
                  fill="#FFA633"
                  d="M60.1147 24.3727L59.3097 24.8427 59.3097 23.8797 60.1147 23.4097z"
                ></path>
                <path
                  fill="#FFA633"
                  d="M63.1504 22.6196L62.3454 23.0896 62.3454 22.1266 63.1504 21.6566z"
                ></path>
                <path
                  fill="#FFF"
                  d="M53.166 12.9522L65.351 19.9332 12.186 50.6322 0 43.6512z"
                ></path>
                <path
                  fill="#A8B3BA"
                  d="M65.3512 6.9804L65.3512 19.9334 12.1852 50.6324 12.1852 37.6794z"
                ></path>
                <g>
                  <mask id="mask-22" fill="#fff">
                    <use xlinkHref="#path-21"></use>
                  </mask>
                  <path
                    fill="#EDF2F6"
                    d="M53.166 -0.0004L65.351 6.9806 12.186 37.6796 0 30.6986z"
                    mask="url(#mask-22)"
                  ></path>
                </g>
                <path
                  fill="#B6C2C9"
                  d="M64.3637 6.9803L53.1657 0.5703 0.9877 30.6983 12.1857 37.1093z"
                ></path>
                <path
                  fill="#8A9399"
                  d="M0.0004 30.6987L12.1854 37.6797 12.1854 50.6317 0.0004 43.6517z"
                ></path>
                <path
                  fill="#7C848A"
                  d="M10.7043 38.5348L1.4813 33.2648 1.4813 42.7958 10.7043 48.0658z"
                ></path>
                <path
                  fill="#C9C9C9"
                  d="M2.0189 33.5747L2.0189 42.4907 10.7039 47.4507 10.7039 48.0657 1.4819 42.7957 1.4819 33.2647z"
                ></path>
                <path
                  fill="#9CA7AD"
                  d="M53.166 0.57L64.364 6.98 53.166 13.446z"
                ></path>
                <path
                  fill="#CECECE"
                  d="M12.1857 37.1093L7.6767 34.5283 53.1657 8.0193 64.3637 6.9803z"
                ></path>
                <path
                  fill="#CCC"
                  d="M2.0189 42.4909L1.4819 42.7959 10.7039 48.0659 10.7039 47.4509z"
                ></path>
                <path
                  fill="#CCC"
                  d="M2.0189 34.528L1.4819 34.833 10.7039 40.103 10.7039 39.488z"
                ></path>
                <path
                  fill="#8A9399"
                  d="M1.4817 35.7032L10.7047 40.9732 10.7047 40.1032 1.4817 34.8332z"
                ></path>
                <path
                  fill="#CCC"
                  d="M2.0189 37.3636L1.4819 37.6686 10.7039 42.9386 10.7039 42.3236z"
                ></path>
                <path
                  fill="#8A9399"
                  d="M1.4817 38.5389L10.7047 43.8089 10.7047 42.9389 1.4817 37.6689z"
                ></path>
                <path
                  fill="#CCC"
                  d="M2.0189 40.1992L1.4819 40.5042 10.7039 45.7742 10.7039 45.1592z"
                ></path>
                <path
                  fill="#8A9399"
                  d="M1.4817 41.3744L10.7047 46.6444 10.7047 45.7744 1.4817 40.5044z"
                ></path>
                <path
                  fill="#9FA9B0"
                  d="M27.5766 30.4998L27.5766 40.0308 31.3146 37.8728 31.3146 28.3418 30.7766 28.6528z"
                ></path>
                <path
                  fill="#8A9399"
                  d="M27.5766 39.4183L27.5766 40.0303 31.3146 37.8723 31.3146 28.3423 30.7766 28.6533 30.7766 37.5713z"
                ></path>
                <path
                  fill="#EDF2F6"
                  d="M27.5766 39.4183L27.5766 40.0303 31.3146 37.8723 30.7766 37.5713z"
                ></path>
                <path
                  fill="#9FA9B0"
                  d="M50.6641 17.1703L50.6641 26.7013 63.8691 19.0763 63.8691 9.5453 63.3311 9.8563z"
                ></path>
                <path
                  fill="#8A9399"
                  d="M50.6641 26.0888L50.6641 26.7008 63.8691 19.0768 63.8691 9.5458 63.3311 9.8568 63.3311 18.7748z"
                ></path>
                <path
                  fill="#EDF2F6"
                  d="M50.6641 18.4962L50.6641 19.1082 63.8691 11.4842 63.3311 11.1822z"
                ></path>
                <path
                  fill="#A8B3BA"
                  d="M50.6641 19.9789L63.8691 12.3539 63.8691 11.4839 50.6641 19.1089z"
                ></path>
                <path
                  fill="#EDF2F6"
                  d="M50.6641 21.233L50.6641 21.845 63.8691 14.221 63.3311 13.919z"
                ></path>
                <path
                  fill="#A8B3BA"
                  d="M50.6641 22.7157L63.8691 15.0907 63.8691 14.2207 50.6641 21.8457z"
                ></path>
                <path
                  fill="#EDF2F6"
                  d="M50.6641 23.9697L50.6641 24.5817 63.8691 16.9577 63.3311 16.6557z"
                ></path>
                <path
                  fill="#A8B3BA"
                  d="M50.6641 25.4525L63.8691 17.8275 63.8691 16.9575 50.6641 24.5825z"
                ></path>
                <path
                  fill="#EDF2F6"
                  d="M50.6641 26.0888L50.6641 26.7008 63.8691 19.0768 63.3311 18.7748z"
                ></path>
                <path
                  fill="#9FA9B0"
                  d="M32.0921 27.8929L32.0921 37.4239 45.2971 29.7989 45.2971 20.2679 44.7591 20.5789z"
                ></path>
                <path
                  fill="#8A9399"
                  d="M32.0921 36.8113L32.0921 37.4233 45.2971 29.7993 45.2971 20.2683 44.7591 20.5793 44.7591 29.4983z"
                ></path>
                <path
                  fill="#EDF2F6"
                  d="M32.0921 29.2188L32.0921 29.8308 45.2971 22.2068 44.7591 21.9048z"
                ></path>
                <path
                  fill="#A8B3BA"
                  d="M32.0921 30.7016L45.2971 23.0766 45.2971 22.2066 32.0921 29.8316z"
                ></path>
                <path
                  fill="#EDF2F6"
                  d="M32.0921 31.9556L32.0921 32.5676 45.2971 24.9436 44.7591 24.6416z"
                ></path>
                <path
                  fill="#A8B3BA"
                  d="M32.0921 33.4383L45.2971 25.8133 45.2971 24.9433 32.0921 32.5683z"
                ></path>
                <path
                  fill="#EDF2F6"
                  d="M32.0921 34.6923L32.0921 35.3043 45.2971 27.6803 44.7591 27.3783z"
                ></path>
                <path
                  fill="#A8B3BA"
                  d="M32.0921 36.1751L45.2971 28.5501 45.2971 27.6801 32.0921 35.3051z"
                ></path>
                <path
                  fill="#EDF2F6"
                  d="M32.0921 36.8113L32.0921 37.4233 45.2971 29.7993 44.7591 29.4983z"
                ></path>
                <path
                  fill="#9FA9B0"
                  d="M13.5203 38.6155L13.5203 48.1465 26.7253 40.5215 26.7253 30.9905 26.1873 31.3015z"
                ></path>
                <path
                  fill="#8A9399"
                  d="M13.5203 47.5339L13.5203 48.1459 26.7253 40.5219 26.7253 30.9909 26.1873 31.3019 26.1873 40.2209z"
                ></path>
                <path
                  fill="#EDF2F6"
                  d="M13.5203 39.9414L13.5203 40.5534 26.7253 32.9294 26.1873 32.6274z"
                ></path>
                <path
                  fill="#A8B3BA"
                  d="M13.5203 41.4242L26.7253 33.7992 26.7253 32.9292 13.5203 40.5542z"
                ></path>
                <path
                  fill="#EDF2F6"
                  d="M13.5203 42.6782L13.5203 43.2902 26.7253 35.6662 26.1873 35.3642z"
                ></path>
                <path
                  fill="#A8B3BA"
                  d="M13.5203 44.1609L26.7253 36.5359 26.7253 35.6659 13.5203 43.2909z"
                ></path>
                <path
                  fill="#EDF2F6"
                  d="M13.5203 45.4149L13.5203 46.0269 26.7253 38.4029 26.1873 38.1009z"
                ></path>
                <path
                  fill="#A8B3BA"
                  d="M13.5203 46.8977L26.7253 39.2727 26.7253 38.4027 13.5203 46.0277z"
                ></path>
                <path
                  fill="#EDF2F6"
                  d="M13.5203 47.5339L13.5203 48.1459 26.7253 40.5219 26.1873 40.2209z"
                ></path>
                <path
                  fill="#A8B3BA"
                  d="M27.5766 35.5437L31.3146 37.8727 31.3146 37.2087 27.5766 34.8797z"
                ></path>
                <path
                  fill="#EDF2F6"
                  d="M27.5766 33.6388L27.5766 34.2488 31.3146 28.3418 30.7766 28.6428z"
                ></path>
                <path
                  fill="#A8B3BA"
                  d="M27.5766 34.249L31.3146 28.342 31.3146 29.006 27.5766 34.913z"
                ></path>
                <path
                  fill="#9FA9B0"
                  d="M46.2866 19.7945L46.2866 29.3255 50.0246 27.1675 50.0246 17.6365 49.4866 17.9475z"
                ></path>
                <path
                  fill="#8A9399"
                  d="M46.2866 28.7129L46.2866 29.3249 50.0246 27.1669 50.0246 17.6359 49.4866 17.9479 49.4866 26.8659z"
                ></path>
                <path
                  fill="#EDF2F6"
                  d="M46.2866 28.7129L46.2866 29.3249 50.0246 27.1669 49.4866 26.8659z"
                ></path>
                <path
                  fill="#A8B3BA"
                  d="M46.2866 24.8383L50.0246 27.1673 50.0246 26.5033 46.2866 24.1743z"
                ></path>
                <path
                  fill="#EDF2F6"
                  d="M46.2866 22.9332L46.2866 23.5432 50.0246 17.6362 49.4866 17.9372z"
                ></path>
                <path
                  fill="#A8B3BA"
                  d="M46.2866 23.5435L50.0246 17.6365 50.0246 18.3005 46.2866 24.2075z"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

IllustrationTrailerTruck.propType = {
  /**
   * Size of the illustration.
   */
  size: PropTypes.number,
};

IllustrationTrailerTruck.defaultProps = {
  size: 70,
};

export { IllustrationTrailerTruck as default } from "./TrailerTruck";
