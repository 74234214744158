import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { IconDraggable } from "../Icon/Line/Draggable";
import "./DraggableList.css";

const MovableItem = ({ id, name, index, moveCardHandler, children }) => {
  const ref = useRef(null);

  const [, drop] = useDrop({
    accept: "card",
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveCardHandler(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: "card",
    item: { index, name },

    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const className = isDragging ? "draggable-item--dragging" : "draggable-item";

  drag(drop(ref));

  return (
    <div key={id} ref={ref} className={className}>
      {children}
      <div className="draggable-item-label">{name}</div>
    </div>
  );
};

export const DraggableList = ({ columns, modifiedColumns }) => {
  const [items, setItems] = useState(columns);

  useEffect(() => {
    setItems(columns);
  }, [columns]);

  const moveCardHandler = (dragIndex, hoverIndex) => {
    const dragItem = items[dragIndex];

    if (dragItem) {
      const coppiedStateArray = [...items];

      // remove item by "hoverIndex" and put "dragItem" instead
      const prevItem = coppiedStateArray.splice(hoverIndex, 1, dragItem);

      // remove item by "dragIndex" and put "prevItem" instead
      coppiedStateArray.splice(dragIndex, 1, prevItem[0]);

      setItems(coppiedStateArray);

      coppiedStateArray.length > 0 &&
        modifiedColumns &&
        modifiedColumns(coppiedStateArray);
    }
  };

  return (
    <div className="draggable-container">
      <DndProvider backend={HTML5Backend}>
        {items.map((item, index) => (
          <MovableItem
            key={item.id}
            name={item.name}
            index={index}
            type="card"
            moveCardHandler={(elements) => {
              moveCardHandler(elements);
            }}
          >
            <IconDraggable size={14} color="--global-colors-ink-light" />
          </MovableItem>
        ))}
      </DndProvider>
    </div>
  );
};

DraggableList.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
};
