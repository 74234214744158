import React, { useState } from "react";
import PropTypes from "prop-types";
import { ModalWrapper } from "../ModalWrapper";
import { ModalBody } from "../ModalBody";
import { ModalFooter } from "../ModalFooter";
import { Check } from "../../Indicators/Check";
import { HelpRequestModal } from "./HelpRequestModal";
import "./DataIssuesModal.css";
import i18next from "i18next";

export const DataIssuesModal = ({ open, onClose, dataText }) => {
  const [openHelp, setOpenHelp] = useState(false);
  return (
    <>
      <div className="data-issues-modal">
        <ModalWrapper open={open} closeAction={onClose}>
          <div className="data-issues-body">
            <ModalBody
              title={
                <div className="data-issues-title">
                  <Check status="failed" size="regular" />
                  {`${i18next.t("customModals.dataIssues.title")} ${dataText}`}
                </div>
              }
            >
              <div className="data-issues-text">
                {`${i18next.t("customModals.dataIssues.text")} ${dataText}.`}
              </div>
            </ModalBody>
          </div>
          <div className="data-issues-footer">
            <ModalFooter
              primaryLabel={i18next.t("customModals.dataIssues.primaryButton")}
              primaryAction={() => {
                setOpenHelp(true);
                onClose();
              }}
              secondaryLabel={i18next.t(
                "customModals.dataIssues.secondaryButton"
              )}
              secondaryAction={() => {
                onClose();
              }}
            />
          </div>
        </ModalWrapper>
      </div>
      <HelpRequestModal open={openHelp} onClose={() => setOpenHelp(false)} />
    </>
  );
};

DataIssuesModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  dataText: PropTypes.string,
};

DataIssuesModal.defaultValue = {
  open: true,
};
