import { useEffect, useState } from "react";

import i18n from "../../i18n/i18nextConf";
import { OnlyStopPoint } from "../Icon/Line/OnlyStopPoint";
import "./OnlyStopRow.css";

export const OnlyStopRow = ({ id, point, active, clickCallback }) => {
  const [isSelected, setIsSelected] = useState(active);
  let className = "mn-only-stop-row";

  if (isSelected) {
    className += " mn-only-stop-row--focused";
  }

  const onClick = () => {
    if (clickCallback !== undefined) {
      clickCallback && clickCallback(!isSelected);
    }
    setIsSelected(!isSelected);
  };

  useEffect(() => {
    setIsSelected(active);
  }, [active]);

  /**
   * Transform a time sting into a formatted string.
   *
   * @param {String} time The date to format.
   * @returns String
   */
  const formatTime = (time) => {
    const dateObj = new Date(time);
    const dateFormatOption = { hour: "2-digit", minute: "2-digit" };
    return dateObj.toLocaleTimeString(i18n.language, dateFormatOption);
  };

  return (
    <div
      key={id}
      id={`only-stop-row-${id}`}
      className={className}
      onClick={onClick}
    >
      <div className="mn-only-stop-row-point">
        <span>
          <OnlyStopPoint size={20} />
        </span>
        <div>
          <span>{point.location}</span>
          <span>{formatTime(point.time)}</span>
        </div>
      </div>
    </div>
  );
};
