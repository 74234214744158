import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ToastNotification } from "../../utils/ToastNotification";
import { Tooltip } from "../Forms/Tooltip";
import { IconExternalLink } from "../Icon/Line/ExternalLink";
import { IconFullScreen } from "../Icon/Line/FullScreen";
import { IconRefresh } from "../Icon/Line/Refresh";
import { IconScaleDown } from "../Icon/Line/ScaleDown";
import { IconSearch } from "../Icon/Line/Search";
import { IconMarker } from "../Icon/Solid/Marker";
import { IconSettings } from "../Icon/Solid/Settings";
import "./Action.css";
import { GeofenceActionMenu } from "./GeofenceActionMenu";
import { SearchActionMenu } from "./SearchActionMenu";
import { SettingsActionMenu } from "./SettingsActionMenu";

export const Actions = ({
  className,
  isFull,
  handleFullScreen,
  handleRefresh,
  handleScaleDown,
  searchMenuProps,
  geofenceMenuProps,
  settingsMenuProps,
  isVehicleDetailsOpen,
  enabledTracking,
  geofencesProp,
  searchDisabled,
  geofenceDisabled,
  settingsDisabled,
  hasRefresh,
  ...props
}) => {
  const { t } = useTranslation();
  const [isFulled, setIsFulled] = useState(isFull);
  const [isWindowed, setIsWindowed] = useState(false);
  const [search, setSearch] = useState(false);
  const [geofence, setGeofence] = useState(false);
  const [geofenceOptions, setGeofenceOptions] = useState(null);
  const [settings, setSettings] = useState(false);
  const [clickButton, setClickButton] = useState(false);
  const [animation, setAnimation] = useState(false);

  useEffect(() => {
    if (geofencesProp?.geofences) {
      let newGeofenceOptions =
        geofencesProp.geofenceCategories &&
        geofencesProp.geofenceCategories.map((category) => {
          return {
            id: category.id,
            label: category.name,
            color: category.color,
            geofences: geofencesProp.geofences.filter(
              (item) => item.geofenceCategory === category.id
            ),
          };
        });
      setGeofenceOptions(newGeofenceOptions);
    }
  }, [geofencesProp?.geofences, geofencesProp?.geofenceCategories]);

  useEffect(() => {
    if (enabledTracking) {
      setGeofence(false);
      setSearch(false);
      setSettings(false);
    }
  }, [enabledTracking]);

  useEffect(() => {
    if (isVehicleDetailsOpen && clickButton) {
      setClickButton(false);
    }
  }, [search, clickButton]);

  useEffect(() => {
    if (settingsMenuProps && settingsMenuProps?.settingData.open) {
      setSearch(false);
      setGeofence(false);
      setSettings(true);
      setAnimation(true);
      setTimeout(() => {
        setAnimation(false);
      }, 1500);
    }
  }, [settingsMenuProps?.settingData.open]);

  function setClassName(isActive, trueCase, falseCase) {
    return isActive ? trueCase : falseCase;
  }

  function renderNotWindowed() {
    return !isWindowed ? (
      <button
        data-for="tooltip"
        data-tip={t("tooltip.fullScreen")}
        className="map-actions__external fullScreen"
        onClick={() => {
          setIsWindowed(!isWindowed);
          handleFullScreen();
        }}
      >
        <IconFullScreen color="--global-colors-ui-white" size={14} />
      </button>
    ) : (
      <button
        data-for="tooltip"
        data-tip={t("tooltip.exitFullScreen")}
        className="map-actions__external"
        onClick={() => {
          setIsWindowed(!isWindowed);
          handleScaleDown();
        }}
      >
        <IconScaleDown color="--global-colors-ui-white" size={14} />
      </button>
    );
  }

  return (
    <div className={`map-actions-container ${className}`}>
      {search && (
        <SearchActionMenu
          vehicles={searchMenuProps.vehicles}
          vehiclesStatus={searchMenuProps.vehiclesStatus}
          drivers={searchMenuProps.drivers}
          geofenceOptions={geofenceOptions}
          buttonLabel={t("map.actions.searchActionMenu.buttonLabelFind")}
          locationSliderLimits={searchMenuProps.locationSliderLimits}
          geofenceSliderLimits={searchMenuProps.geofenceSliderLimits}
          drawCircle={searchMenuProps.drawCircle}
          removeCircle={searchMenuProps.removeCircle}
          onRadiusChange={searchMenuProps.onRadiusChange}
          isMetric={searchMenuProps.isMetric}
        />
      )}
      {geofence && (
        <GeofenceActionMenu
          options={geofenceOptions}
          setGeofence={geofenceMenuProps.setGeofences}
          enabledTracking={enabledTracking}
        />
      )}
      {settings && (
        <SettingsActionMenu
          options={[
            { code: "alias", label: t("map.actions.settingsActionMenu.alias") },
            { code: "lock", label: t("map.actions.settingsActionMenu.lock") },
            {
              code: "cluster",
              label: t("map.actions.settingsActionMenu.cluster"),
            },
            {
              code: "mouseover",
              label: t("map.actions.settingsActionMenu.mouseover"),
            },
            {
              code: "traffic",
              label: t("map.actions.settingsActionMenu.traffic"),
            },
            {
              code: "satellite",
              label: t("map.actions.settingsActionMenu.satellite"),
            },
          ]}
          settingActionChange={settingsMenuProps?.settingActionChange}
          settingData={settingsMenuProps?.settingData}
          openSettings={animation}
        />
      )}
      <div className={["map-actions", className].join(" ")} {...props}>
        {!isFulled ? (
          <button
            className="map-actions__external"
            onClick={() => {
              setIsFulled(!isFulled);
            }}
          >
            <IconExternalLink color="--global-colors-ui-white" size={14} />
          </button>
        ) : (
          <>
            <Tooltip />
            <button
              className={setClassName(
                search,
                "map-actions open",
                searchDisabled
                  ? "map-actions__search disabled"
                  : "map-actions__search"
              )}
              data-for="tooltip"
              data-tip={t("tooltip.search")}
              onClick={() => {
                setSearch(!search);
                setGeofence(false);
                setSettings(false);
                isVehicleDetailsOpen && setClickButton(true);
                searchMenuProps.removeCircle();
                let newGeofenceOptions =
                  geofenceOptions &&
                  geofenceOptions.map((option) => {
                    option.checked = false;
                    return option;
                  });
                setGeofenceOptions(newGeofenceOptions);
              }}
            >
              <IconSearch
                color={setClassName(
                  search,
                  "--global-colors-ink-ink",
                  "--global-colors-ui-white"
                )}
                size={14}
              />
            </button>
            <button
              data-for="tooltip"
              data-tip={t("tooltip.geofence")}
              className={setClassName(
                geofence,
                "map-actions open",
                geofenceDisabled
                  ? "map-actions__external disabled"
                  : "map-actions__external"
              )}
              onClick={() => {
                if (geofencesProp.geofences.length === 0) {
                  ToastNotification({
                    toastId: "noGeofenceDefined",
                    description: t(
                      "map.actions.searchActionMenu.noGeofencesError"
                    ),
                  });
                  return;
                }
                setSearch(false);
                setGeofence(!geofence);
                setSettings(false);
                isVehicleDetailsOpen && setClickButton(true);
              }}
            >
              <IconMarker
                color={setClassName(
                  geofence,
                  "--global-colors-ink-ink",
                  "--global-colors-ui-white"
                )}
                size={14}
              />
            </button>
            {renderNotWindowed()}
          </>
        )}
        <button
          data-for="tooltip"
          data-tip={t("tooltip.settings")}
          style={!hasRefresh ? { borderRadius: "0px 0px 4px 4px" } : {}}
          className={
            settings
              ? "map-actions open"
              : settingsDisabled
              ? "map-actions__settings disabled"
              : "map-actions__settings"
          }
          onClick={() => {
            setSearch(false);
            setGeofence(false);
            setSettings(!settings);
            isVehicleDetailsOpen && setClickButton(true);
          }}
        >
          <IconSettings
            color={setClassName(
              settings,
              "--global-colors-ink-ink",
              "--global-colors-ui-white"
            )}
            size={14}
          />
        </button>
        {hasRefresh && (
          <button
            data-for="tooltip"
            data-tip={t("tooltip.refresh")}
            style={{ borderRadius: "0px 0px 4px 4px" }}
            className={"map-actions__refresh"}
            onClick={() => {
              handleRefresh();
            }}
          >
            <IconRefresh color={"--global-colors-ui-white"} size={14} />
          </button>
        )}
      </div>
    </div>
  );
};

Actions.propTypes = {
  className: PropTypes.string,
  isFull: PropTypes.bool,
  handleFullScreen: PropTypes.func,
  handleScaleDown: PropTypes.func,
  searchMenuProps: PropTypes.object,
  geofenceMenuProps: PropTypes.object,
  settingsMenuProps: PropTypes.object,
  isVehicleDetailsOpen: PropTypes.bool,
  enabledTracking: PropTypes.bool,
  geofencesProp: PropTypes.object,
};

Actions.defaultProps = {
  isFull: false,
};
