import { t } from "i18next";
import _ from "lodash";
import { useEffect, useRef, useState } from "react";
import {
  Location,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { store } from "../../app/store";
import { Button } from "../../ui/Button/Button";
import Form from "../../ui/Forms/Form";
import SearchField from "../../ui/Forms/SearchField";
import { IconAdd } from "../../ui/Icon/Line/Add";
import { getQueryString } from "../../utils/Utils";
import "./AdminUserFilterBar.css";
import { EntityPermission } from "./privilege/privilegesSlice";
import { getFilteredUsersInfoPaginationAsync } from "./usersInfoSlice";
interface QueryParams {
  [paramName: string]: any;
}

interface AdminUserFilterBarProps {
  callback: (buildQueryParam: string) => any;
  entityPermissions: EntityPermission;
}

export const AdminUserFilterBar: React.FC<AdminUserFilterBarProps> = ({
  callback,
  entityPermissions,
}) => {
  const navigate = useNavigate();
  const [idDriverVehicle, setIdDriverVehicle] = useState("");
  const queryParamsRef = useRef<QueryParams>({});
  let queryParams: QueryParams = queryParamsRef.current;

  // Handle routes
  const [searchParams] = useSearchParams();
  const location: Location = useLocation();

  useEffect(() => {
    const map = new Map();
    const currentSearchParams =
      searchParams.toString() !== "" ? searchParams : null;
    if (!!currentSearchParams && _.isEmpty(queryParams)) {
      const size = currentSearchParams.get("size");
      map.set("size", size ?? "10");

      const username = currentSearchParams.getAll("username");
      if (username.length) {
        setIdDriverVehicle(username[0]);
        map.set("username", username);
      }
    }

    if (map.size === 0 && _.isEmpty(queryParams)) {
      map.set("size", "10");
    }
    if (map.size > 0) {
      handleChanges(map);
    }
  }, [location]);

  const handleChanges = (params: Map<string, string[] | string>): void => {
    let queryString = "";
    let executeQuery = false;
    if (!!params) {
      params.forEach((value, key) => {
        if (!!value && value.length > 0) {
          queryParams[key] = value;
        } else {
          if (queryParams.hasOwnProperty(key)) {
            delete queryParams[key];
            executeQuery = true;
          }
        }
      });
      queryString = getQueryString(queryParams);
      executeQuery = true;
    }
    // effettuare chiamata API con parametri in query string
    if (executeQuery) {
      store.dispatch(
        getFilteredUsersInfoPaginationAsync({ queryParams: queryString })
      );
      navigate({
        pathname: "/admin/users",
        search: queryString,
      });
      callback(queryString);
    }
  };

  const debouncedSearch = useRef(
    _.debounce(async (val) => {
      setIdDriverVehicle(val);
      const map = new Map();
      const splittedVal = val.split(" ");
      map.set("firstName", splittedVal);
      map.set("lastName", splittedVal);
      handleChanges(map);
    }, 1000)
  ).current;

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  async function handleSearch(val: string) {
    await debouncedSearch(val);
  }

  return (
    <div className="admin-filterbar-row-splitter">
      <>
        <div className="admin-filterbar-search">
          <Form>
            <SearchField
              name="search"
              id="userName"
              size="small"
              placeholder={t("admin.adminFilterBar.searchName")}
              value={idDriverVehicle}
              onChange={(val) => {
                handleSearch(val);
              }}
            />
          </Form>
        </div>
        {entityPermissions.write && (
          <div className="admin-filterbar-btn">
            <Button
              aspect="primary"
              size="small"
              label={t("admin.adminFilterBar.addUser")}
              onlyIcon={false}
              onClick={() => navigate("/admin/users/new")}
            >
              <IconAdd className="" size={14} color="#fff" />
            </Button>
          </div>
        )}
      </>
    </div>
  );
};
