import _ from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useMatch,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { RootState, store } from "../../app/store";
import { GTFleetErrorCodes } from "../../config/GTfleetErrorCodes";
import ContainerWrapper from "../../layout/ContainerWrapper";
import GridContainer from "../../layout/GridContainer";
import HeaderMenu from "../../layout/HeaderMenu";
import PageContainer from "../../layout/PageContainer";
import Sidebar from "../../layout/Sidebar";
import { IconSettings } from "../../ui/Icon/Solid/Settings";
import { InfoModal } from "../../ui/Modal/CustomModals/InfoModal";

import dayjs from "dayjs";
import { TicketModal } from "../../ui/Modal/CustomModals/TicketModal";
import { NotificationsItems, TopBar } from "../../ui/TopBar/TopBar";
import AuthenticationService from "../../utils/AuthenticationService";
import FirebaseService from "../../utils/FirebaseService";
import { ToastNotification } from "../../utils/ToastNotification";
import { selectaddressBookSliceStatus } from "../address/addressBookSlice";
import { AdminDrivers } from "../driver/AdminDrivers";
import CreateDriver from "../driver/CreateDriver";
import { DashboardDrivers } from "../driver/DashboardDrivers";
import { DriverDetails } from "../driver/DriverDetails";
import { DriversLocationHistory } from "../driver/DriversLocationHistory";
import EditDriver from "../driver/EditDriver";
import ViewDriver from "../driver/ViewDriver";
import {
  driversEmptyState,
  selectDriversSliceStatus,
} from "../driver/driversSlice";
import {
  driversStatusEmptyState,
  selectDriverStatusSliceStatus,
} from "../driver/driversStatusSlice";
import { selecteventsSliceStatus } from "../event/eventsSlice";
import { AdminEditFleets } from "../fleet/AdminEditFleets";
import AdminFleets from "../fleet/AdminFleets";
import FleetControl from "../fleet/FleetControl";
import { selectFleetFuelCostsSliceStatus } from "../fleet/fleetFuelCostsSlice";
import { selectFleetFuelConsumptionsSliceStatus } from "../fleet/fleetFuelsConsumptionsSlice";
import { selectFleetViewsSliceStatus } from "../fleet/fleetViewsSlice";
import CreateGeofence from "../geofence/CreateGeofence";
import EditGeofence from "../geofence/EditGeofence";
import Geofences from "../geofence/Geofence";
import { selectGeofencesSliceStatus } from "../geofence/geofenceSlice";
import { selectGeofenceCategoriesSliceStatus } from "../geofenceCategory/geofenceCategoriesSlice";
import { Logs } from "../log/Logs";
import { selectLogEventsSliceStatus } from "../log/logSlice";
import { DeleteAttachmentModal } from "../maintenance-deadline/DeleteAttachmentModal";
import DeleteRecurrenceModal from "../maintenance-deadline/DeleteRecurrenceModal";
import { Maintenance } from "../maintenance-deadline/Maintenance";
import { PreviewAttachmentModal } from "../maintenance-deadline/PreviewAttachmentModal";
import DeadlineDeleteModal from "../maintenance-deadline/deadline/DeadlineDeleteModal";
import DeadlineEditModal from "../maintenance-deadline/deadline/DeadlineEditModal";
import DeadlineNewModal from "../maintenance-deadline/deadline/DeadlineNewModal";
import {
  Deadline,
  selectDeadlinesSliceStatus,
} from "../maintenance-deadline/deadlinesSlice";
import { CompletedMaintenanceModal } from "../maintenance-deadline/maintenance/CompletedMaintenanceModal";
import MaintenanceEditModal from "../maintenance-deadline/maintenance/MaintenanceEditModal";
import MaintenanceNewModal from "../maintenance-deadline/maintenance/MaintenanceNewModal";
import { ViewEditCompletedMaintenanceModal } from "../maintenance-deadline/maintenance/ViewEditCompletedMaintenanceModal";
import { DashboardMessages } from "../messages/DashboardMessages";
import { selectMessagesSliceStatus } from "../messages/messagesSlice";
import { NotificationsList } from "../notification/Notifications";
import {
  NotificationCategory,
  getNotificationCategoryAsync,
  notificationCategorySelectors,
  selectNotificationCategorySliceStatus,
} from "../notification/notificationCategorySlice";
import {
  getFilteredNotificationsPreviewAsync,
  notificationPreviewEventsSelectors,
} from "../notification/notificationPreviewSlice";
import {
  NotificationEvent,
  selectNotificationEventsSliceStatus,
} from "../notification/notificationSlice";
import AdminNotification from "../notification/rule/AdminNotification";
import CreateEditOrViewNotificationRule from "../notification/rule/CreateEditOrViewNotificationRule";
import { selectNotificationRuleAggregationSliceStatus } from "../notification/rule/notificationRuleAggregationSlice";
import { PublicRoutePlanning } from "../publicTransport/planning/PublicRoutePlanning";
import { selectPublicRoutePlanningStatus } from "../publicTransport/planning/publicRoutePlanningSlice";
import { AdminPublicRoutes } from "../publicTransport/route/AdminPublicRoutes";
import { PublicRouteStop } from "../publicTransport/stop/PublicRoutesStop";
import { selectPublicRouteStopStatus } from "../publicTransport/stop/publicRouteStopSlice";
import { Overview } from "../report/Overview";
import { ReportView } from "../report/ReportView";
import { DriverTravelReport } from "../report/driver/DriverTravelReport";
import { GeofenceReport } from "../report/geofence/GeofenceReport";
import { ReportGPSData } from "../report/gpsData/GPSDataReport";
import { RefuellingReport } from "../report/refuelling/RefuellingReport";
import { UtilizationReport } from "../report/utilization/ReportUtilization";
import { IoReport } from "../report/vehicle/ReportIO";
import { VehicleTravelReport } from "../report/vehicle/VehicleTravelReport";
import { selectVehicleTravelsMacroSummarySliceStatus } from "../report/vehicle/vehicleTravelsMacroSummarySlice";
import {
  routeEventEmptyState,
  selectRouteEventSliceStatus,
} from "../route/routeEventsSlice";
import {
  routeHistoryEmptyRoutes,
  selectRoutesHistorySliceStatus,
} from "../route/routesHistorySlice";
import {
  routeHistoryViewEmptyState,
  selectRoutesHistoryViewSliceStatus,
} from "../route/routesHistoryViewSlice";
import { routesEmptyState, selectRouteSliceStatus } from "../route/routesSlice";
import { DashboardTachographFilesStatus } from "../tachograph/DashboardTachographFilesStatus";
import { selectTachographFilesStatusSliceStatus } from "../tachograph/tachographFilesStatusSlice";
import { Tenant, tenantsSelectors } from "../tenants/tenantsSlice";
import { AdminUsers } from "../users/AdminUsers";
import ChangePassword from "../users/ChangePassword";
import CreateUser from "../users/CreateUser";
import { EditAccount } from "../users/EditAccount";
import EditUser from "../users/EditUser";
import ViewUser from "../users/ViewUser";
import { selectPreferencesSliceStatus } from "../users/preference/preferencesSlice";
import { selectpresetsSliceStatus } from "../users/preset/presetsSlice";
import { selectPrivilegesDefaultSliceStatus } from "../users/privilege/privilegesDefaultSlice";
import { UserPermissions } from "../users/privilege/privilegesSlice";
import { selectSecurityProfilesSliceStatus } from "../users/securityProfile/securityProfilesSlice";
import {
  UserInfo,
  getUserInfoAsync,
  selectUsersInfoSliceReasonCode,
  selectUsersInfoSliceStatus,
  userInfoSelectors,
  usersInfoEmptyState,
} from "../users/usersInfoSlice";
import { AdminVehicles } from "../vehicle/AdminVehicles";
import { DashboardVehicles } from "../vehicle/DashboardVehicles";
import EditVehicle from "../vehicle/EditVehicle";
import { VehicleDetails } from "../vehicle/VehicleDetails";
import { VehiclesLocationHistory } from "../vehicle/VehiclesLocationHistory";
import ViewVehicle from "../vehicle/ViewVehicle";
import {
  selectVehiclesSliceStatus,
  vehiclesEmptyState,
} from "../vehicle/vehiclesSlice";
import {
  selectVehiclesStatusSliceStatus,
  vehiclesStatusEmptyState,
} from "../vehicle/vehiclesStatusSlice";
import {
  selectVehiclesStatusViewSliceStatus,
  vehiclesStatusViewEmptyState,
} from "../vehicle/vehiclesStatusViewSlice";
import "./SidebarPage.css";

interface DeleteGeofenceState {
  geofenceId: number;
  name: string;
}

interface DeleteDeadlineState {
  vehicle: string;
  date: string;
  meters: string;
  utilization: string;
  type: string;
  deadlineId: number;
}

interface ViewDeadlineState {
  deadlineStatus: Deadline;
  vehicleId: number;
  fleetId: number;
}

interface CompleteDeadlineState {
  deadlineStatus: Deadline;
  vehicleId: number;
}

interface PreviewAttachmentDeadlineState {
  vehicleId: number;
  fileName: string;
}

interface DeleteAttachmentDeadlineState {
  vehicleId: number;
  fileName: string;
  deadlineStatus: Partial<Deadline>;
}

interface SidebarPageProps {
  logoutDone: () => void;
}
let notificationRequestPolling: any;

function SidebarPage(Props: SidebarPageProps) {
  const match = useMatch("/*");
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  // for Maintenance
  let deleteDeadlineState = location.state as DeleteDeadlineState;
  let viewDeadlineState = location.state as ViewDeadlineState;
  let completeDeadlineState = location.state as CompleteDeadlineState;
  let previewAttachmentState = location.state as PreviewAttachmentDeadlineState;
  let deleteAttachmentState = location.state as DeleteAttachmentDeadlineState;
  const authenticationService = new AuthenticationService();

  const [isLoading, setIsLoading] = useState(true);
  const [notificationsItems, setNotificationsItems] =
    useState<NotificationsItems>({
      notificationsList: [],
      buttonLabel: "",
    } as NotificationsItems);
  const [ticketModal, setTicketModal] = useState(false);

  const [infoModal, setInfoModal] = useState(false);
  const [infoModalType, setInfoModalType] = useState("");
  const [userPermissions, setUserPermissions] = useState<UserPermissions>({
    drivers: { read: false, write: false },
    fleets: { read: false, write: false },
    users: { read: false, write: false },
    vehicles: { read: false, write: false },
    deadlines: { read: false, write: false },
    notifications: { read: false, write: false },
    geofences: { read: false, write: false },
    logs: { read: false, write: false },
    reports: { read: false, write: false },
    events: { read: false, write: false },
    notificationRules: { read: false, write: false },
    tachograph: { read: false },
    liveTrack: { read: false },
    chats: { read: false, write: false },
    publicTransport: { read: false, write: false },
  });
  const [expanded, setExpanded] = useState(true);
  const { t } = useTranslation();
  const userInfo: UserInfo =
    useAppSelector((state) =>
      userInfoSelectors.selectById(
        state,
        Number(localStorage.getItem("customerId") ?? -1)
      )
    ) ?? ({} as UserInfo);
  const userInfoSlice = useAppSelector(selectUsersInfoSliceStatus);
  const userInfoSliceReasonCode = useAppSelector(
    selectUsersInfoSliceReasonCode
  );
  const privilegesDefaultSliceStatus = useAppSelector(
    selectPrivilegesDefaultSliceStatus
  );

  let company: Tenant =
    useAppSelector((state) =>
      tenantsSelectors.selectById(state, userInfo.tenant)
    ) ?? ({} as Tenant);

  const firebaseService = new FirebaseService();

  /**
   * This function dispacth a call to get all notification not readed
   * and set timer for polling
   */
  const fetchNotifications = (queryParams: string) => {
    store.dispatch(
      getFilteredNotificationsPreviewAsync({ queryParams: queryParams })
    );
    notificationRequestPolling = setTimeout(
      () => fetchNotifications(queryParams),
      60000
    );
  };

  const today = dayjs().utcOffset(0, true);
  const threeMonthsAgo = today.subtract(3, "months");

  const formattedToday = today.toISOString();
  const formattedThreeMonthsAgo = threeMonthsAgo.toISOString();

  const queryParams = `?sendDate=${encodeURIComponent(
    formattedThreeMonthsAgo
  )}&sendDate=${encodeURIComponent(formattedToday)}&size=1000&read=false`;

  // API load account details
  useEffect(() => {
    const customerId = localStorage.getItem("customerId");
    if (customerId) {
      let currentUsername = customerId;
      let oldUsername = localStorage.getItem("oldUserId");
      if (oldUsername !== null && oldUsername !== currentUsername) {
        // Build the new URL
        const baseUrl = `${window.location.host}`;
        // Replace the URL
        navigate(baseUrl, { replace: true });
      }
      localStorage.setItem("oldUserId", currentUsername ?? "0");
      store.dispatch(getNotificationCategoryAsync({}));
      fetchNotifications(queryParams);
      store.dispatch(getUserInfoAsync({ id: Number(customerId) }));
      setNotificationsItems({
        buttonLabel: t("common.notificationLinkLabel"),
        notificationsList: [],
        buttonClick: () => {
          navigate("dashboard/notifications");
        },
      });
    } else {
      logout(true);
    }

    return () => {
      clearTimeout(notificationRequestPolling);
      store.dispatch(driversEmptyState());
      store.dispatch(driversStatusEmptyState());
      store.dispatch(routeHistoryEmptyRoutes());
      store.dispatch(routeHistoryViewEmptyState());
      store.dispatch(routeEventEmptyState());
      store.dispatch(routesEmptyState());
      store.dispatch(usersInfoEmptyState());
      store.dispatch(vehiclesStatusEmptyState());
      store.dispatch(vehiclesEmptyState());
      store.dispatch(vehiclesStatusViewEmptyState());
    };
  }, []);

  const isAllowed = (
    userInfoToCheck: UserInfo | undefined,
    entityType: string,
    permission: string
  ): boolean => {
    if (!userInfoToCheck) return false;
    if (!userInfoToCheck.securityProfile.privileges) return false;
    for (const p of [
      ...userInfoToCheck.securityProfile.privileges,
      ...userInfoToCheck.privileges,
    ]) {
      if (p.entityType === entityType && permission === p.permission)
        return true;
    }
    return false;
  };

  function logout(unauthorized?: boolean) {
    if (unauthorized) {
      ToastNotification({
        toastId: "unauthorizedError",
        status: "error",
        description: t("common.unauthorizedError"),
      });
    }
    authenticationService.logout().finally(() => {
      Props.logoutDone();
    });
  }

  useEffect(() => {
    if (userInfo?.securityProfile?.privileges || userInfo?.privileges) {
      setUserPermissions({
        drivers: {
          read: isAllowed(userInfo, "Drivers", "READ"),
          write: isAllowed(userInfo, "Drivers", "WRITE"),
        },
        fleets: {
          read: isAllowed(userInfo, "Fleets", "READ"),
          write: isAllowed(userInfo, "Fleets", "WRITE"),
        },
        users: {
          read: isAllowed(userInfo, "UserInfos", "READ"),
          write: isAllowed(userInfo, "UserInfos", "WRITE"),
        },
        vehicles: {
          read: isAllowed(userInfo, "Vehicles", "READ"),
          write: isAllowed(userInfo, "Vehicles", "WRITE"),
        },
        deadlines: {
          read: isAllowed(userInfo, "Deadlines", "READ"),
          write: isAllowed(userInfo, "Deadlines", "WRITE"),
        },
        notifications: {
          read: isAllowed(userInfo, "Notifications", "READ"),
          write: isAllowed(userInfo, "Notifications", "WRITE"),
        },
        geofences: {
          read: isAllowed(userInfo, "Geofences", "READ"),
          write: isAllowed(userInfo, "Geofences", "WRITE"),
        },
        logs: {
          read: isAllowed(userInfo, "Logs", "READ"),
          write: isAllowed(userInfo, "Logs", "WRITE"),
        },
        events: {
          read: isAllowed(userInfo, "Events", "READ"),
          write: isAllowed(userInfo, "Events", "WRITE"),
        },
        notificationRules: {
          read: isAllowed(userInfo, "NotificationsRules", "READ"),
          write: isAllowed(userInfo, "NotificationsRules", "WRITE"),
        },
        tachograph: {
          read: isAllowed(userInfo, "TachographFile", "READ"),
        },
        liveTrack: {
          read: isAllowed(userInfo, "LiveTrack", "READ"),
        },
        chats: {
          read: isAllowed(userInfo, "Chats", "READ"),
          write: isAllowed(userInfo, "Chats", "WRITE"),
        },
        reports: {
          read: isAllowed(userInfo, "Reports", "READ"),
          write: isAllowed(userInfo, "Reports", "WRITE"),
        },
        publicTransport: {
          read: isAllowed(userInfo, "PublicTransport", "READ"),
          write: isAllowed(userInfo, "PublicTransport", "WRITE"),
        },
      });

      setIsLoading(false);
    } else if (
      userInfoSlice === "failed" &&
      userInfoSliceReasonCode == GTFleetErrorCodes.USERNAME_NOT_FOUND
    ) {
      logout();
    }
  }, [userInfo]);

  //#region Notifications
  let notifications: NotificationEvent[] = useAppSelector((state: RootState) =>
    notificationPreviewEventsSelectors.selectAll(state)
  );

  let notificationCategories: NotificationCategory[] = useAppSelector(
    (state: RootState) => notificationCategorySelectors.selectAll(state)
  );

  useEffect(() => {
    if (notifications?.length > 0) {
      const sortedNotifications = _.orderBy(notifications, "sendDate", "desc");
      const groupedNotificationsByCategoryId: {
        id: string;
        notifications: NotificationEvent[];
      }[] = sortedNotifications.reduce((result: any, current: any) => {
        const category = current.notificationCategory;
        if (!result.hasOwnProperty(category)) {
          result[category] = {
            id: category,
            notifications: [],
          };
        }
        result[category].notifications.push(current);
        return result;
      }, {});

      const tempNotificationsItems: NotificationsItems = {
        buttonLabel: t("common.notificationLinkLabel"),
        buttonClick: () => {
          navigate("dashboard/notifications");
        },
        notificationsList: Object.values(groupedNotificationsByCategoryId).map(
          (groupedNotification: {
            id: string;
            notifications: NotificationEvent[];
          }) => ({
            categoryId: groupedNotification.id,
            categoryName:
              notificationCategorySelectors.selectById(
                store.getState(),
                groupedNotification.id
              )?.name ?? "",
            notifications: groupedNotification.notifications.map(
              (notification: any) => ({
                message: notification.message,
                time: notification.sendDate,
              })
            ),
          })
        ),
      };
      if (!_.isEqual(tempNotificationsItems, notificationsItems)) {
        setNotificationsItems(tempNotificationsItems);
      }
    } else {
      setNotificationsItems({
        buttonLabel: t("common.notificationLinkLabel"),
        notificationsList: [],
        buttonClick: () => {
          navigate("dashboard/notifications");
        },
      });
    }
  }, [notifications, notificationCategories]);

  //#endregion Notifications

  //#region MenuItems
  const menuItems = [
    {
      label: "Dashboard",
      onClick: () => {
        window.open("/dashboard/overview");
      },
      isActive: match?.pathname.includes("dashboard") ?? false,
    },
    userPermissions.reports.read
      ? {
          label: "Reports",
          onClick: () => {
            window.open("/reports");
          },
          isActive: match?.pathname.includes("reports") ?? false,
        }
      : null,
    userPermissions.fleets.write ||
    userPermissions.drivers.write ||
    userPermissions.vehicles.write ||
    userPermissions.users.write
      ? {
          label: "Admin",
          onClick: () => {
            if (userPermissions.vehicles.write) window.open("/admin/vehicles");
            else if (userPermissions.drivers.write)
              window.open("/admin/drivers");
            else if (userPermissions.fleets.write) window.open("/admin/fleets");
            else if (userPermissions.users.write) window.open("/admin/users");
            else
              ToastNotification({
                toastId: "unauthorizedError",
                status: "error",
                description: t("common.unauthorizedError"),
              });
          },
          isActive: match?.pathname.includes("admin") ?? false,
        }
      : null,
  ];

  const userHelpItems = {
    sectionTitle: t("common.userHelpTitle"),
    label: t("common.userHelpLabel"),

    onClick: () => {
      setTicketModal(true);
    },
  };
  //#endregion MenuItems

  //#region UserMenuItems
  const userMenuItems = [
    {
      label: t("navigation.menuBalloon.userMenu.accountSettings"),
      onClick: () => {
        navigate("/account-settings/edit-account/view");
      },
      icon: <IconSettings size={14} color="--global-colors-ink-light" />,
    },
    {
      label: t("navigation.menuBalloon.userMenu.privacy"),
      onClick: () => {
        setInfoModal(true);
        setInfoModalType("privacy");
      },
    },
    {
      label: t("navigation.menuBalloon.userMenu.conditions"),
      onClick: () => {
        setInfoModal(true);
        setInfoModalType("conditions");
      },
    },
    {
      label: t("navigation.menuBalloon.userMenu.credits"),
      onClick: () => {
        setInfoModal(true);
        setInfoModalType("credits");
      },
    },
    {
      label: t("navigation.menuBalloon.userMenu.logout"),
      onClick: () => {
        if (
          process.env.REACT_APP_DEPLOY_TARGET === "local" ||
          process.env.REACT_APP_DEPLOY_TARGET === "prod" ||
          (process.env.REACT_APP_DEPLOY_TARGET === "pre-prod" &&
            !_.isEmpty(localStorage.getItem("firebaseToken")))
        ) {
          firebaseService
            .firebaseTokenUnregister(localStorage.getItem("firebaseToken")!)
            .finally(logout);
        } else {
          logout();
        }
      },
    },
  ];
  //#endregion UserMenuList

  //#region const for slices

  const vehicleTravelsMacroSummarySliceStatus = useAppSelector(
    selectVehicleTravelsMacroSummarySliceStatus
  );

  const driverStatusSliceStatus = useAppSelector(selectDriverStatusSliceStatus);
  const vehiclesSliceStatus = useAppSelector(selectVehiclesSliceStatus);
  const deadlinesSliceStatus = useAppSelector(selectDeadlinesSliceStatus);
  const vehiclesStatusSliceStatus = useAppSelector(
    selectVehiclesStatusSliceStatus
  );
  const vehiclesStatusViewSliceStatus = useAppSelector(
    selectVehiclesStatusViewSliceStatus
  );

  const gpsDataSliceStatus = useAppSelector(selectRouteSliceStatus);
  const eventSliceStatus = useAppSelector(selectRouteEventSliceStatus);

  const messagesSliceStatus = useAppSelector(selectMessagesSliceStatus);
  const presetsSliceStatus = useAppSelector(selectpresetsSliceStatus);
  const routesHistoryViewSliceStatus = useAppSelector(
    selectRoutesHistoryViewSliceStatus
  );
  const routesHistorySliceStatus = useAppSelector(
    selectRoutesHistorySliceStatus
  );
  const driversSliceStatus = useAppSelector(selectDriversSliceStatus);
  const userSliceStatus = useAppSelector(selectUsersInfoSliceStatus);
  const fleetSliceStatus = useAppSelector(selectFleetViewsSliceStatus);
  const preferenceSliceStatus = useAppSelector(selectPreferencesSliceStatus);
  const securityProfileStatus = useAppSelector(
    selectSecurityProfilesSliceStatus
  );
  const fleetFuelCostsSliceStatus = useAppSelector(
    selectFleetFuelCostsSliceStatus
  );
  const fleetFuelsConsumptionSliceStatus = useAppSelector(
    selectFleetFuelConsumptionsSliceStatus
  );
  const geofencesSliceStatus = useAppSelector(selectGeofencesSliceStatus);
  const geofencesCategorySliceStatus = useAppSelector(
    selectGeofenceCategoriesSliceStatus
  );
  const notificationRuleAggregationSliceStatus = useAppSelector(
    selectNotificationRuleAggregationSliceStatus
  );
  const notificationSliceStatus = useAppSelector(
    selectNotificationEventsSliceStatus
  );
  const notificationCategoryStatus = useAppSelector(
    selectNotificationCategorySliceStatus
  );

  const tachographFilesStatusSliceStatus = useAppSelector(
    selectTachographFilesStatusSliceStatus
  );

  const addressBookStatus = useAppSelector(selectaddressBookSliceStatus);
  const eventNamesStatus = useAppSelector(selecteventsSliceStatus);

  const logEventsSliceStatus = useAppSelector(selectLogEventsSliceStatus);
  const publicRoutePlanningSliceStatus = useAppSelector(
    selectPublicRoutePlanningStatus
  );
  const publicRoutesStopSliceStatus = useAppSelector(
    selectPublicRouteStopStatus
  );

  //#endregion const for slices

  const dashboardOverviewPathMatch = useMatch("/dashboard/overview/*");
  const dashboardFleetPathMatch = useMatch("/dashboard/fleet-control/*");
  const dashboardVehiclePathMatch = useMatch("/dashboard/vehicles/*");
  const dashboardMessagesPathMatch = useMatch("/dashboard/messages/*");
  const dashboardVehicleDetailPathMatch = useMatch("/dashboard/fleets/*");
  const dashboarDriverPathMatch = useMatch("/dashboard/drivers/*");
  const dashboardLhVehiclePathMatch = useMatch(
    "/dashboard/vehicles/location-history/*"
  );
  const dashboardLhDriverPathMatch = useMatch(
    "/dashboard/drivers/location-history/*"
  );
  const dashboardNotificationsPathMatch = useMatch("/dashboard/notifications");
  const dashboardMaintenancePathMatch = useMatch("/dashboard/maintenance/*");
  const dashboardLogPathMatch = useMatch("/dashboard/logs");
  const dashboardTachographPathMatch = useMatch(
    "/dashboard/tachograph-files/*"
  );
  const adminVehiclePathMatch = useMatch("/admin/vehicles/*");
  const adminDriverPathMatch = useMatch("/admin/drivers/*");
  const adminUserPathMatch = useMatch("/admin/users/*");
  const adminGeofencePathMatch = useMatch("/admin/geofences/*");
  const adminFleetPathMatch = useMatch("/admin/fleets/*");
  const adminNotificationPathMatch = useMatch("/admin/notifications/*");
  const adminPublicRoutePlanningPathMatch = useMatch(
    "/admin/publicRoutePlanning/*"
  );
  const adminPublicRoutesPathMatch = useMatch("/admin/publicRoutes/*");
  const adminPublicRouteStopsPathMatch = useMatch("/admin/publicRouteStops/*");
  const pathMatches = [
    dashboardOverviewPathMatch,
    dashboardFleetPathMatch,
    dashboardVehiclePathMatch,
    dashboardMessagesPathMatch,
    dashboardVehicleDetailPathMatch,
    dashboarDriverPathMatch,
    dashboardLhVehiclePathMatch,
    dashboardLhDriverPathMatch,
    dashboardNotificationsPathMatch,
    dashboardMaintenancePathMatch,
    dashboardLogPathMatch,
    dashboardTachographPathMatch,
    adminVehiclePathMatch,
    adminDriverPathMatch,
    adminUserPathMatch,
    adminGeofencePathMatch,
    adminFleetPathMatch,
    adminNotificationPathMatch,
    adminPublicRoutePlanningPathMatch,
    adminPublicRouteStopsPathMatch,
  ];

  function getHomepage() {
    if (userPermissions.vehicles.read && userPermissions.fleets.read) {
      return "/dashboard/fleet-control";
    } else if (userPermissions.vehicles.read) {
      return "/dashboard/vehicles";
    } else if (userPermissions.drivers.read) {
      return "/dashboard/drivers";
    } else if (userPermissions.deadlines.read) {
      return "/dashboard/maintenance";
    } else if (userPermissions.tachograph.read) {
      return "/dashboard/tachograph-files";
    } else if (userPermissions.notifications.read) {
      return "/dashboard/notifications";
    } else if (userPermissions.logs.read || userPermissions.events.read) {
      return "/dashboard/logs";
    }
    return "/dashboard/overview";
  }

  return !isLoading ? (
    <ContainerWrapper>
      <Sidebar
        dashboardFleetControl={{
          loading:
            vehiclesSliceStatus === "loading" ||
            vehiclesStatusViewSliceStatus === "loading" ||
            vehiclesStatusSliceStatus === "loading",
          active: dashboardFleetPathMatch != null,
          enabled: userPermissions.vehicles.read && userPermissions.fleets.read,
        }}
        dashboardOverview={{
          loading:
            vehiclesStatusSliceStatus === "loading" ||
            deadlinesSliceStatus === "loading" ||
            fleetFuelCostsSliceStatus === "loading" ||
            fleetFuelsConsumptionSliceStatus === "loading",
          active: dashboardOverviewPathMatch != null,
          enabled: userPermissions.vehicles.read && userPermissions.fleets.read,
        }}
        dashboardVehicle={{
          loading:
            vehiclesStatusSliceStatus === "loading" ||
            presetsSliceStatus === "loading",
          active:
            (dashboardVehiclePathMatch != null &&
              dashboardLhVehiclePathMatch == null) ||
            dashboardVehicleDetailPathMatch != null,
          enabled: userPermissions.vehicles.read,
        }}
        dashboardMessages={{
          loading: messagesSliceStatus === "loading",
          active: dashboardMessagesPathMatch != null,
          enabled: userPermissions.chats.read,
        }}
        dashboardLocationHistoryVehicle={{
          loading:
            vehicleTravelsMacroSummarySliceStatus === "loading" ||
            vehiclesStatusViewSliceStatus === "loading" ||
            routesHistoryViewSliceStatus === "loading" ||
            routesHistorySliceStatus === "loading" ||
            gpsDataSliceStatus === "loading" ||
            eventSliceStatus === "loading",
          active: dashboardLhVehiclePathMatch != null,
          enabled: userPermissions.vehicles.read,
        }}
        dashboardLocationHistoryDriver={{
          loading: false, //todo
          active: dashboardLhDriverPathMatch != null,
          enabled: userPermissions.drivers.read,
        }}
        dashboardDriver={{
          loading:
            driverStatusSliceStatus === "loading" ||
            presetsSliceStatus === "loading",
          active: dashboarDriverPathMatch != null,
          enabled: userPermissions.drivers.read,
        }}
        dashboardNotification={{
          loading:
            notificationSliceStatus === "loading" ||
            notificationCategoryStatus === "loading",
          active: dashboardNotificationsPathMatch != null,
          enabled: userPermissions.notifications.read,
        }}
        dashboardLogs={{
          loading: logEventsSliceStatus === "loading",
          active: dashboardLogPathMatch != null,
          enabled: userPermissions.logs.read || userPermissions.events.read,
        }}
        dashboardTachograph={{
          loading: tachographFilesStatusSliceStatus === "loading",
          active: dashboardTachographPathMatch != null,
          enabled: userPermissions.tachograph.read,
        }}
        dashboardMaintenance={{
          loading:
            vehiclesStatusSliceStatus === "loading" ||
            deadlinesSliceStatus === "loading",
          active: dashboardMaintenancePathMatch != null,
          enabled: userPermissions.deadlines.read,
        }}
        adminVehicle={{
          loading: vehiclesSliceStatus === "loading",
          active: adminVehiclePathMatch != null,
          enabled: userPermissions.vehicles.write ?? false,
        }}
        adminDriver={{
          loading:
            driversSliceStatus === "loading" ||
            driverStatusSliceStatus === "loading" ||
            vehiclesSliceStatus === "loading" ||
            fleetSliceStatus === "loading" ||
            preferenceSliceStatus === "loading",
          active: adminDriverPathMatch != null,
          enabled: userPermissions.drivers.write ?? false,
        }}
        adminUser={{
          loading:
            geofencesSliceStatus === "loading" ||
            driverStatusSliceStatus === "loading" ||
            vehiclesStatusSliceStatus === "loading" ||
            privilegesDefaultSliceStatus === "loading" ||
            userSliceStatus === "loading" ||
            securityProfileStatus === "loading" ||
            userSliceStatus === "loading",
          active: adminUserPathMatch != null,
          enabled: userPermissions.users.write ?? false,
        }}
        adminGeofences={{
          loading:
            geofencesSliceStatus === "loading" ||
            geofencesCategorySliceStatus === "loading",
          active: adminGeofencePathMatch != null,
          enabled: userPermissions.geofences.write ?? false,
        }}
        adminFleets={{
          loading: fleetSliceStatus === "loading",
          active: adminFleetPathMatch != null,
          enabled: userPermissions.fleets.write ?? false,
        }}
        adminNotification={{
          loading:
            notificationRuleAggregationSliceStatus === "loading" ||
            addressBookStatus === "loading" ||
            eventNamesStatus === "loading" ||
            vehiclesSliceStatus === "loading" ||
            driverStatusSliceStatus === "loading" ||
            geofencesSliceStatus === "loading" ||
            geofencesCategorySliceStatus === "loading" ||
            vehiclesStatusSliceStatus === "laoding",
          active: adminNotificationPathMatch != null,
          enabled: userPermissions.notificationRules.read,
        }}
        adminPublicRoutePlanning={{
          loading: publicRoutePlanningSliceStatus === "loading",
          active: adminPublicRoutePlanningPathMatch != null,
          enabled: userPermissions.publicTransport.read,
        }}
        adminPublicRoutes={{
          loading: false,
          active: adminPublicRoutesPathMatch != null,
          enabled: userPermissions.publicTransport.read,
        }}
        adminPublicRoutesStop={{
          loading: publicRoutesStopSliceStatus === "loading",
          active: adminPublicRouteStopsPathMatch != null,
          enabled: userPermissions.publicTransport.read,
        }}
        expandedUpdated={setExpanded}
      />
      <GridContainer>
        <HeaderMenu>
          <TopBar
            tenant={company}
            menuItems={menuItems}
            userInfo={{
              fullName:
                userInfo.firstName && userInfo.secondName
                  ? userInfo.firstName + " " + userInfo.secondName
                  : String(localStorage.getItem("fullName")),
              email: userInfo.email ?? String(localStorage.getItem("email")),
              profilePic:
                userInfo.profilePicture ??
                String(localStorage.getItem("profilePic")),
            }}
            userMenuItems={userMenuItems}
            notificationsItems={notificationsItems}
            userHelpItems={userHelpItems}
          />
        </HeaderMenu>
        <PageContainer>
          {
            <Routes>
              {
                //#region Dashboard Fleet
              }
              {userPermissions.vehicles.read && userPermissions.fleets.read ? (
                <Route
                  path="/dashboard/overview/*"
                  element={<Overview permissions={userPermissions} />}
                />
              ) : null}
              {userPermissions.vehicles.read && userPermissions.fleets.read ? (
                <Route
                  path="/dashboard/fleet-control/*"
                  element={<FleetControl permissions={userPermissions} />}
                />
              ) : null}
              {
                //#endregion Dashboard Fleet
              }
              {
                //#region Dashboard Vehicle
              }
              {userPermissions.vehicles.read ? (
                <Route
                  path="/dashboard/vehicles"
                  element={<DashboardVehicles permissions={userPermissions} />}
                />
              ) : null}
              {userPermissions.vehicles.read ? (
                <Route
                  path="/dashboard/fleets/:fleetId/vehicles/:vehicleId/details"
                  element={<VehicleDetails permissions={userPermissions} />}
                />
              ) : null}
              {userPermissions.vehicles.read ? (
                <Route
                  path="/dashboard/vehicles/location-history/*"
                  element={
                    <VehiclesLocationHistory
                      expandedMain={expanded}
                      permissions={userPermissions}
                    />
                  }
                />
              ) : null}
              {
                //#endregion Dashboard Vehicle
              }
              {
                //#region Dashboard Driver
              }{" "}
              {userPermissions.drivers.read ? (
                <Route
                  path="/dashboard/drivers/*"
                  element={<DashboardDrivers permissions={userPermissions} />}
                />
              ) : null}
              {userPermissions.drivers.read ? (
                <Route
                  path="/dashboard/drivers/details/:driverId"
                  element={<DriverDetails permissions={userPermissions} />}
                />
              ) : null}
              {
                //#endregion Dashboard Driver
              }
              {
                //#region Dashboard Message
              }
              {userPermissions.chats.read ? (
                <Route
                  path="/dashboard/messages"
                  element={<DashboardMessages permissions={userPermissions} />}
                />
              ) : null}
              {
                //#region Dashboard Message
              }
              {
                //#region Dashboard Maintenance
              }
              {userPermissions.deadlines.read ? (
                <Route
                  path="/dashboard/maintenance/*"
                  element={<Maintenance permissions={userPermissions} />}
                />
              ) : null}
              {userPermissions.deadlines.write &&
              completeDeadlineState?.deadlineStatus ? (
                <Route
                  path="/dashboard/maintenance/completed-deadline"
                  element={
                    <>
                      <Maintenance permissions={userPermissions} />
                      <CompletedMaintenanceModal
                        type={t(
                          "maintenance.table.maintenanceDeadlines." +
                            completeDeadlineState.deadlineStatus.type
                        )}
                        deadlineState={completeDeadlineState.deadlineStatus}
                        open={true}
                        onClose={() => {
                          navigate({
                            pathname: "/dashboard/maintenance",
                            search: searchParams.toString(),
                          });
                        }}
                        deadlineId={completeDeadlineState.deadlineStatus.id}
                        deadlineStatusType={
                          completeDeadlineState.deadlineStatus.status!
                        }
                        deadlineType={completeDeadlineState.deadlineStatus.type}
                        fleetId={
                          viewDeadlineState.fleetId !== undefined
                            ? viewDeadlineState?.fleetId
                            : 0
                        }
                        vehicleId={viewDeadlineState.vehicleId}
                      />
                    </>
                  }
                />
              ) : null}
              {userPermissions.deadlines.write &&
              completeDeadlineState?.deadlineStatus ? (
                <Route
                  path="/dashboard/maintenance/view-edit-completed-deadline"
                  element={
                    <>
                      <Maintenance permissions={userPermissions} />
                      <ViewEditCompletedMaintenanceModal
                        type={t(
                          "maintenance.table.maintenanceDeadlines." +
                            completeDeadlineState.deadlineStatus.type
                        )}
                        deadlineState={completeDeadlineState.deadlineStatus}
                        open={true}
                        onClose={() => {
                          navigate({
                            pathname: "/dashboard/maintenance",
                            search: searchParams.toString(),
                          });
                        }}
                        deadlineId={completeDeadlineState.deadlineStatus.id}
                        deadlineType={completeDeadlineState.deadlineStatus.type}
                        fleetId={
                          viewDeadlineState.fleetId !== undefined
                            ? viewDeadlineState?.fleetId
                            : 0
                        }
                        vehicleId={viewDeadlineState.vehicleId}
                      />
                    </>
                  }
                />
              ) : null}
              {userPermissions.deadlines.write ? (
                <Route
                  path="/dashboard/maintenance/new-maintenance"
                  element={
                    <>
                      <Maintenance permissions={userPermissions} />
                      <MaintenanceNewModal
                        deadlineDefaultValues={
                          completeDeadlineState &&
                          completeDeadlineState.deadlineStatus
                            ? completeDeadlineState.deadlineStatus
                            : undefined
                        }
                        vehicleDefaultValue={
                          completeDeadlineState
                            ? completeDeadlineState.vehicleId
                            : undefined
                        }
                      />
                    </>
                  }
                />
              ) : null}
              {userPermissions.deadlines.write ? (
                <Route
                  path="/dashboard/maintenance/new-deadline"
                  element={
                    <>
                      <Maintenance permissions={userPermissions} />
                      <DeadlineNewModal
                        deadlineDefaultValues={
                          completeDeadlineState &&
                          completeDeadlineState.deadlineStatus
                            ? completeDeadlineState.deadlineStatus
                            : undefined
                        }
                        vehicleDefaultValue={
                          completeDeadlineState
                            ? completeDeadlineState.vehicleId
                            : undefined
                        }
                      />
                    </>
                  }
                />
              ) : null}
              {userPermissions.deadlines.write && deleteDeadlineState ? (
                <Route
                  path="/dashboard/maintenance/delete"
                  element={
                    <>
                      <Maintenance permissions={userPermissions} />
                      <DeadlineDeleteModal
                        vehicle={deleteDeadlineState.vehicle}
                        date={deleteDeadlineState.date}
                        meters={deleteDeadlineState.meters}
                        utilization={deleteDeadlineState.utilization}
                        type={deleteDeadlineState.type}
                        deadlineId={deleteDeadlineState.deadlineId}
                      />
                    </>
                  }
                />
              ) : null}
              {userPermissions.deadlines.write ? (
                <Route
                  path="/dashboard/maintenance/recurrence-deadline"
                  element={
                    <>
                      <Maintenance permissions={userPermissions} />
                      <DeleteRecurrenceModal
                        deadlineStatus={
                          viewDeadlineState && viewDeadlineState.deadlineStatus
                        }
                        vehicleId={
                          viewDeadlineState && viewDeadlineState.vehicleId
                        }
                        fleetId={viewDeadlineState && viewDeadlineState.fleetId}
                      />
                    </>
                  }
                />
              ) : null}
              {userPermissions.deadlines.write && previewAttachmentState ? (
                <Route
                  path="/dashboard/maintenance/preview-attachment"
                  element={
                    <>
                      <Maintenance permissions={userPermissions} />
                      <PreviewAttachmentModal
                        open={true}
                        onClose={() => {
                          navigate({
                            pathname: "/dashboard/maintenance",
                            search: searchParams.toString(),
                          });
                        }}
                        vehicleId={previewAttachmentState.vehicleId}
                        fileName={previewAttachmentState.fileName}
                      />
                    </>
                  }
                />
              ) : null}
              {userPermissions.deadlines.write && deleteAttachmentState ? (
                <Route
                  path="/dashboard/maintenance/delete-attachment"
                  element={
                    <>
                      <Maintenance permissions={userPermissions} />
                      <DeleteAttachmentModal
                        open={true}
                        onClose={() => {
                          navigate({
                            pathname: "/dashboard/maintenance",
                            search: searchParams.toString(),
                          });
                        }}
                        vehicleId={
                          deleteAttachmentState &&
                          deleteAttachmentState.vehicleId
                        }
                        deadlineStatus={
                          deleteAttachmentState &&
                          deleteAttachmentState.deadlineStatus
                        }
                      />
                    </>
                  }
                />
              ) : null}
              {userPermissions.deadlines.write && viewDeadlineState ? (
                <Route
                  path="/dashboard/maintenance/view-edit"
                  element={
                    <>
                      <Maintenance permissions={userPermissions} />
                      {viewDeadlineState.deadlineStatus &&
                      viewDeadlineState.deadlineStatus.isMaintenance ? (
                        <MaintenanceEditModal
                          deadlineStatus={viewDeadlineState.deadlineStatus}
                          defaultMaintenanceId={
                            viewDeadlineState.deadlineStatus.id
                          }
                          defaultFleetId={viewDeadlineState.fleetId}
                          defaultVehicleId={viewDeadlineState.vehicleId}
                        />
                      ) : (
                        viewDeadlineState.deadlineStatus && (
                          <DeadlineEditModal
                            deadlineStatus={viewDeadlineState.deadlineStatus}
                            defaultVehicleId={viewDeadlineState.vehicleId}
                            defaultFleetId={viewDeadlineState.fleetId}
                            defaultDeadlineId={
                              viewDeadlineState.deadlineStatus.id
                            }
                          />
                        )
                      )}
                    </>
                  }
                />
              ) : null}
              {
                //#endregion Dashboard Maintenance
              }
              {
                //#region Dashboard Tachograph
              }
              {userPermissions.tachograph.read ? (
                <Route
                  path="/dashboard/tachograph-files/*"
                  element={
                    <DashboardTachographFilesStatus
                      permissions={userPermissions}
                    />
                  }
                />
              ) : null}
              {
                //#endregion Dashboard Tachograph
              }
              {userPermissions.drivers.read ? (
                <Route
                  path="/dashboard/drivers/location-history/*"
                  element={
                    <DriversLocationHistory permissions={userPermissions} />
                  }
                />
              ) : null}
              {
                //#region Dashboard Notification
              }
              {userPermissions.notifications.read ? (
                <Route
                  path="/dashboard/notifications/*"
                  element={<NotificationsList permissions={userPermissions} />}
                />
              ) : null}
              {
                //#endregion Dashboard Notification
              }
              {
                //#region Dashboard Logs
              }
              {userPermissions.logs.read ? (
                <Route
                  path="/dashboard/logs"
                  element={<Logs permissions={userPermissions} />}
                />
              ) : null}
              {
                //#endregion Dashboard Logs
              }
              {
                //#region Report
              }
              {userPermissions.reports.read ? (
                <Route
                  path="/reports"
                  element={<ReportView permissions={userPermissions} />}
                />
              ) : null}
              {userPermissions.reports.read ? (
                <Route
                  path="/reports/archive/*"
                  element={<ReportView permissions={userPermissions} />}
                />
              ) : null}
              {userPermissions.reports.read && userPermissions.drivers.read ? (
                <Route
                  path="reports/driver-travel-report/*"
                  element={<DriverTravelReport permissions={userPermissions} />}
                />
              ) : null}
              {userPermissions.reports.read && userPermissions.vehicles.read ? (
                <Route
                  path="reports/vehicle-travel-report/*"
                  element={
                    <VehicleTravelReport permissions={userPermissions} />
                  }
                />
              ) : null}
              {userPermissions.reports.read && userPermissions.vehicles.read ? (
                <Route
                  path="reports/gps-data/"
                  element={<ReportGPSData permissions={userPermissions} />}
                />
              ) : null}
              {userPermissions.reports.read && userPermissions.vehicles.read ? (
                <Route
                  path="reports/io-report/"
                  element={<IoReport permissions={userPermissions} />}
                />
              ) : null}
              {userPermissions.reports.read && userPermissions.vehicles.read ? (
                <Route
                  path="reports/utilization-report/"
                  element={<UtilizationReport />}
                />
              ) : null}
              {userPermissions.reports.read && userPermissions.vehicles.read ? (
                <Route
                  path="reports/refuelling-report/*"
                  element={<RefuellingReport permissions={userPermissions} />}
                />
              ) : null}
              {userPermissions.reports.read && userPermissions.vehicles.read ? (
                <Route
                  path="/reports/geofence-report/"
                  element={<GeofenceReport permissions={userPermissions} />}
                />
              ) : null}
              {
                //#endregion Report
              }
              {
                //#region Admin Vehicle
              }
              <Route
                path="/admin/vehicles/*"
                element={<AdminVehiclesLinks {...userPermissions} />}
              />
              {
                //#endregion Admin Vehicle
              }
              {
                //#region Admin Driver
              }
              <Route
                path="/admin/drivers/*"
                element={<AdminDriversLinks {...userPermissions} />}
              />
              {
                //#endregion Admin Driver
              }
              {
                //#region Admin User
              }
              <Route
                path="/admin/users/*"
                element={<AdminUsersLinks {...userPermissions} />}
              />
              {
                //#endregion Admin Driver
              }
              {
                //#region Admin Geofence
              }
              <Route
                path="/admin/geofences/*"
                element={<AdminGeofencesLinks {...userPermissions} />}
              />
              {
                //#endregion Admin Geofence
              }
              {
                //#region Admin Fleet
              }
              <Route
                path="/admin/fleets/*"
                element={<AdminFleetsLinks {...userPermissions} />}
              />
              {
                //#endregion Admin Fleet
              }
              {
                //#region Admin Notification
              }
              <Route
                path="/admin/notifications/*"
                element={<AdminNotificationLinks {...userPermissions} />}
              />
              {
                //#endregion Admin Notification
              }
              {
                //#region Admin publicRoutePlanning
              }
              {userPermissions.publicTransport.read &&
              userPermissions.publicTransport.write ? (
                <Route
                  path="/admin/publicRoutePlanning/*"
                  element={<PublicRoutePlanning />}
                />
              ) : null}
              {
                //#endregion Admin publicRoutePlanning
                //#reguib Admin Public Routes Stop
              }
              {userPermissions.publicTransport.read ? (
                <Route
                  path="/admin/publicRouteStops/*"
                  element={<PublicRouteStop />}
                />
              ) : null}
              {
                //#endregion Admin Public Routes Stop
                //#region Admin publicRoutePlanning
              }
              {userPermissions.publicTransport.read &&
              userPermissions.publicTransport.write ? (
                <Route
                  path="/admin/publicRoutes/*"
                  element={<AdminPublicRoutesLinks {...userPermissions} />}
                />
              ) : null}
              {
                //#endregion Admin Public Routes
              }
              {
                //#region Account Settings
              }
              <Route path="/account-settings/*" element={<EditAccount />} />
              <Route
                path="/account-settings/change-password"
                element={<ChangePassword />}
              />
              {
                //#endregion Account Settings
              }
              {
                //#region Default
              }
              {/* default route */}
              <Route
                path="/"
                element={<Navigate replace to={getHomepage()} />}
              />
              {/* default bad route */}
              <Route
                path="*"
                element={
                  <Navigate
                    replace
                    to={getHomepage()}
                    state={{
                      cause: pathMatches.some((pathMatch) => pathMatch !== null)
                        ? "unauthorizedError"
                        : "pageNotFound",
                    }}
                  />
                }
              />
              {
                //#endregion Default
              }
            </Routes>
          }
          <div className="ticket-modal">
            <TicketModal
              showTicketModal={ticketModal}
              onClose={() => setTicketModal(false)}
            />
          </div>
          <div className="info-modal">
            <InfoModal
              showInfoModal={infoModal}
              onClose={() => setInfoModal(false)}
              infoModalType={infoModalType}
            />
          </div>
        </PageContainer>
      </GridContainer>
    </ContainerWrapper>
  ) : (
    <></>
  );
}

function AdminVehiclesLinks(userPermissions: UserPermissions) {
  return (
    <Routes>
      {userPermissions.vehicles.read ? (
        <Route
          path="/"
          element={<AdminVehicles permissions={userPermissions} />}
        />
      ) : null}
      {userPermissions.vehicles.write ? (
        <Route
          path="/:action/:fleetId/:vehicleId"
          element={<AdminVehicles permissions={userPermissions} />}
        />
      ) : null}
      {userPermissions.vehicles.read ? (
        <Route
          path="/view/:fleetId/:vehicleId"
          element={<ViewVehicle permissions={userPermissions} />}
        />
      ) : null}
      {userPermissions.vehicles.write ? (
        <Route
          path="/edit/:fleetId/:vehicleId"
          element={<EditVehicle permissions={userPermissions} />}
        />
      ) : null}
    </Routes>
  );
}

function AdminDriversLinks(userPermissions: UserPermissions) {
  return (
    <Routes>
      {userPermissions.drivers.read ? (
        <Route
          path="/"
          element={<AdminDrivers permissions={userPermissions} />}
        />
      ) : null}
      {userPermissions.drivers.write ? (
        <Route
          path="/:action/:driverId"
          element={<AdminDrivers permissions={userPermissions} />}
        />
      ) : null}
      {userPermissions.drivers.write ? (
        <Route
          path="/new"
          element={<CreateDriver permissions={userPermissions} />}
        />
      ) : null}
      {userPermissions.drivers.write ? (
        <Route
          path="/edit/:driverId"
          element={<EditDriver permissions={userPermissions} />}
        />
      ) : null}
      {userPermissions.drivers.read ? (
        <Route
          path="/view/:driverId"
          element={<ViewDriver permissions={userPermissions} />}
        />
      ) : null}
    </Routes>
  );
}

function AdminUsersLinks(userPermissions: UserPermissions) {
  return (
    <Routes>
      {userPermissions.users.read ? (
        <Route
          path="/"
          element={<AdminUsers permissions={userPermissions} />}
        />
      ) : null}
      {userPermissions.users.write ? (
        <Route
          path="/:action/:userId"
          element={<AdminUsers permissions={userPermissions} />}
        />
      ) : null}
      {userPermissions.users.read ? (
        <Route
          path="/view/:userId"
          element={<ViewUser permissions={userPermissions} />}
        />
      ) : null}
      {userPermissions.users.write ? (
        <Route
          path="/edit/:userId"
          element={<EditUser permissions={userPermissions} />}
        />
      ) : null}
      {userPermissions.users.write ? (
        <Route
          path="/new"
          element={<CreateUser permissions={userPermissions} />}
        />
      ) : null}
    </Routes>
  );
}

function AdminGeofencesLinks(userPermissions: UserPermissions) {
  return (
    <Routes>
      {userPermissions.geofences.read ? (
        <Route path="/" element={<Geofences permissions={userPermissions} />} />
      ) : null}
      {userPermissions.geofences.write ? (
        <Route
          path="/:action"
          element={<Geofences permissions={userPermissions} />}
        />
      ) : null}
      {userPermissions.geofences.write ? (
        <Route
          path="/:action/:geofenceId"
          element={<Geofences permissions={userPermissions} />}
        />
      ) : null}
      {userPermissions.geofences.write ? (
        <Route
          path="/add-category"
          element={<Geofences permissions={userPermissions} />}
        />
      ) : null}
      {userPermissions.geofences.write ? (
        <Route
          path="/edit-categories"
          element={<Geofences permissions={userPermissions} />}
        />
      ) : null}
      {userPermissions.geofences.write ? (
        <Route path="/create-geofence" element={<CreateGeofence />} />
      ) : null}
      {userPermissions.geofences.write ? (
        <Route path="/edit-geofence/:geofenceId" element={<EditGeofence />} />
      ) : null}
    </Routes>
  );
}

function AdminFleetsLinks(userPermissions: UserPermissions) {
  return (
    <Routes>
      {userPermissions.fleets.read ? (
        <Route
          path="/"
          element={<AdminFleets permissions={userPermissions} />}
        />
      ) : null}
      {userPermissions.fleets.write ? (
        <Route
          path="/add-fleet"
          element={<AdminFleets permissions={userPermissions} />}
        />
      ) : null}
      {userPermissions.fleets.write ? (
        <Route
          path="/delete/:fleetId"
          element={<AdminFleets permissions={userPermissions} />}
        />
      ) : null}
      {userPermissions.fleets.write ? (
        <Route
          path="/edit-fleet/:fleetId"
          element={<AdminEditFleets permissions={userPermissions} />}
        />
      ) : null}
    </Routes>
  );
}

function AdminNotificationLinks(userPermissions: UserPermissions) {
  return (
    <Routes>
      {userPermissions.notificationRules.read ? (
        <Route
          path="/:status/:NotificationAggregationId"
          element={<AdminNotification permissions={userPermissions} />}
        />
      ) : null}
      {userPermissions.notificationRules.read ? (
        <Route
          path="/"
          element={<AdminNotification permissions={userPermissions} />}
        />
      ) : null}
      {userPermissions.notificationRules.write ? (
        <Route
          path="/delete/:notificationAggregationRuleId"
          element={<AdminNotification permissions={userPermissions} />}
        />
      ) : null}
      {userPermissions.notificationRules.write ? (
        <Route
          path="/add-contact"
          element={<AdminNotification permissions={userPermissions} />}
        />
      ) : null}
      {userPermissions.notificationRules.read ? (
        <Route
          path="/contacts-list"
          element={<AdminNotification permissions={userPermissions} />}
        />
      ) : null}
      {userPermissions.notificationRules.write ? (
        <Route
          path="/add-rule"
          element={
            <CreateEditOrViewNotificationRule permissions={userPermissions} />
          }
        />
      ) : null}
      {userPermissions.notificationRules.read ? (
        <Route
          path="/view-rule/:notificationRuleAggregationId"
          element={
            <CreateEditOrViewNotificationRule permissions={userPermissions} />
          }
        />
      ) : null}
      {userPermissions.notificationRules.write ? (
        <Route
          path="/edit-rule/:notificationRuleAggregationId"
          element={
            <CreateEditOrViewNotificationRule permissions={userPermissions} />
          }
        />
      ) : null}
      {userPermissions.notificationRules.write ? (
        <Route
          path="/edit-contact/:addressBookId"
          element={<AdminNotification permissions={userPermissions} />}
        />
      ) : null}
      {userPermissions.notificationRules.write ? (
        <Route
          path="/delete-contact/:addressBookId"
          element={<AdminNotification permissions={userPermissions} />}
        />
      ) : null}
      {userPermissions.notificationRules.write ? (
        <Route
          path="/validate-contact/:addressBookId"
          element={<AdminNotification permissions={userPermissions} />}
        />
      ) : null}
      {userPermissions.notificationRules.write ? (
        <Route
          path="/add-rule/add-contact"
          element={
            <CreateEditOrViewNotificationRule permissions={userPermissions} />
          }
        />
      ) : null}
      {userPermissions.notificationRules.write ? (
        <Route
          path="/edit-rule/:notificationRuleAggregationId/add-contact"
          element={
            <CreateEditOrViewNotificationRule permissions={userPermissions} />
          }
        />
      ) : null}
    </Routes>
  );
}

function AdminPublicRoutesLinks(userPermissions: UserPermissions) {
  return (
    <Routes>
      {userPermissions.publicTransport.read ? (
        <Route
          path="/"
          element={<AdminPublicRoutes permissions={userPermissions} />}
        />
      ) : null}
    </Routes>
  );
}

export default SidebarPage;
