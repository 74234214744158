import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../app/hooks";
import { store } from "../../../app/store";
import PageContent from "../../../layout/PageContent";
import PageFilters from "../../../layout/PageFilters";
import {
  DriverTravelSummary,
  driverTravelsSummarySelectors,
  getDriverTravelsSummaryAsync,
  selectDriverTravelsSummarySliceReasonCode,
  selectDriverTravelsSummarySliceStatus,
} from "./driverTravelsSummarySlice";

import i18next, { t } from "i18next";
import _ from "lodash";
import { Button } from "../../../ui/Button/Button";
import { Checkbox } from "../../../ui/Forms/Checkbox";
import { IconMap } from "../../../ui/Icon/Line/Map";
import { MapMarkerTruckNumber } from "../../../ui/Marker/TruckNumber";
import { PageCounter } from "../../../ui/Table/PageCounter";
import { Table } from "../../../ui/Table/Table";
import { ToastNotification } from "../../../utils/ToastNotification";
import { getQueryString } from "../../../utils/Utils";
import { Driver, driversSelectors } from "../../driver/driversSlice";
import { FleetView, fleetViewsSelectors } from "../../fleet/fleetViewsSlice";
import { Preferences } from "../../users/preference/preferencesSlice";
import {
  Preset,
  presetsSelectors,
  selectpresetsSliceStatus,
} from "../../users/preset/presetsSlice";
import { UserPermissions } from "../../users/privilege/privilegesSlice";
import UserContext from "../../users/userContext";
import {
  Vehicle,
  getFilteredVehiclesDetailsAsync,
  vehiclesEmptyState,
  vehiclesSelectors,
} from "../../vehicle/vehiclesSlice";
import { ReportMap } from "../ReportMap";
import { TravelDetailModal } from "../TravelDetailModal";
import { TravelDetail } from "../TravelDetailTypes";
import {
  GPSDataReport,
  getGPSDatasTravelAsync,
  gpsDataReportsSelectors,
} from "../gpsData/gpsDataReportSlice";
import { DriverFilterBarReport } from "./DriverFilterBarReport";
import "./DriverTravelReport.css";
import { DriverTravelReportDetails } from "./DriverTravelReportDetails";
import { DriverTravelReportTableBuilder } from "./DriverTravelReportTableBuilder";
import {
  DriverTravelsMacroSummary,
  driverTravelsMacroSummarySelectors,
  selectDriverTravelsMacroSummarySliceReasonCode,
  selectDriverTravelsMacroSummarySliceStatus,
} from "./driverTravelsMacroSummarySlice";

interface DriverTravelReporttProps {
  permissions: UserPermissions;
}

interface TableData {
  columns: { label: string; field: string; sort: boolean }[] | undefined;
  rows: { [key: string]: any };
}

const tableData: TableData = {} as TableData;

const context = "driverTravelReport";

export const DriverTravelReport: React.FC<DriverTravelReporttProps> = ({
  permissions,
}) => {
  const navigate = useNavigate();
  const tableBuilderRef = useRef<DriverTravelReportTableBuilder>(
    new DriverTravelReportTableBuilder()
  );
  let tableBuilder = tableBuilderRef.current;
  let presets: Preset[] = useAppSelector(presetsSelectors.selectAll);
  const presetsSliceStatus = useAppSelector(selectpresetsSliceStatus);
  const [queryParamsFromFilterBar, setQueryParamsFromFilterBar] =
    useState<string>("");
  const [currentSelectedTravel, setCurrentSelectedTravel] = useState(-1);
  const [resetPage, setResetPage] = useState<boolean>(false);
  const [driverId, setDriverId] = useState<number>(-1);
  const [preferencesContext]: [Preferences] = useContext(UserContext);

  const driverTravelsSummary: DriverTravelSummary[] = useAppSelector(
    driverTravelsSummarySelectors.selectAll
  );

  const driverTravelsSummarySliceStatus = useAppSelector(
    selectDriverTravelsSummarySliceStatus
  );
  const driverTravelsSummarySliceReasonCode = useAppSelector(
    selectDriverTravelsSummarySliceReasonCode
  );

  const driverTravelsMacroSummary: DriverTravelsMacroSummary[] = useAppSelector(
    driverTravelsMacroSummarySelectors.selectAll
  );
  const driverTravelsMacroSummarySliceStatus = useAppSelector(
    selectDriverTravelsMacroSummarySliceStatus
  );
  const driverTravelsMacroSummarySliceReasonCode = useAppSelector(
    selectDriverTravelsMacroSummarySliceReasonCode
  );

  const selectedTravel: GPSDataReport[] = useAppSelector(
    gpsDataReportsSelectors.selectAll
  );

  const isReportsIdle = driverTravelsSummarySliceStatus === "idle";

  const trackColors = [
    "--global-colors-ui-primary",
    "--global-colors-palette-violet",
    "--global-colors-palette-green",
    "--global-colors-palette-orange",
    "--global-colors-palette-bordeaux",
    "--global-colors-palette-yellow",
    "--global-colors-palette-light-bordeaux",
    "--global-colors-palette-light-blue",
    "--global-colors-palette-light-violet",
    "--global-colors-palette-light-red",
  ];

  interface CheckedProps {
    id: number;
    color: string;
    number: number;
    vehicleId: number;
    queryParams: string;
  }

  const [viewMap, setViewMap] = useState(false);
  const [checkedList, setCheckedList] = useState<CheckedProps[]>([]);
  const [removedIndexes, setRemovedIndexes] = useState<number[]>([]);

  useEffect(() => {
    if (isReportsIdle) {
      if (resetPage) {
        setCheckedList([]);
        setResetPage(false);
      }
    }
  }, [isReportsIdle]);

  const [tableHeaderHeight, setTableHeaderHeight] = useState<number>(0);
  const [tableRowHeight, setTableRowHeight] = useState<number>(0);

  useEffect(() => {
    const handleResize = () => {
      const tableBody = document.getElementById("dtr-table-body");
      const tableHeader = document.getElementById("dtr-table-header");
      if (tableHeader && tableBody) {
        setTableRowHeight(tableBody.clientHeight);
        setTableHeaderHeight(tableHeader.clientHeight);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [viewMap]);

  useEffect(() => {
    if (currentSelectedTravel !== -1) {
      store.dispatch(
        getGPSDatasTravelAsync({
          routeId: currentSelectedTravel,
        })
      );
    }
  }, [currentSelectedTravel]);

  useEffect(() => {
    if (
      driverTravelsSummarySliceStatus === "failed" &&
      driverTravelsSummarySliceReasonCode === ""
    ) {
      console.error(t("common.networkError"));
      ToastNotification({
        toastId: "networkError",
        status: "error",
        description: t("common.networkError"),
      });
    }
  }, [driverTravelsSummarySliceStatus, driverTravelsSummarySliceReasonCode]);

  useEffect(() => {
    currentSelectedTravel !== -1 &&
      selectedTravel.length === 0 &&
      ToastNotification({
        toastId: "DetailNotFound",
        status: "error",
        description: t("report.travel.driver.DetailNotFound"),
      });
  }, [selectedTravel]);

  useEffect(() => {
    if (
      driverTravelsMacroSummarySliceStatus === "failed" &&
      driverTravelsMacroSummarySliceReasonCode === ""
    ) {
      console.error(t("common.networkError"));
      ToastNotification({
        toastId: "networkError",
        status: "error",
        description: t("common.networkError"),
      });
    }
  }, [
    driverTravelsMacroSummarySliceStatus,
    driverTravelsMacroSummarySliceReasonCode,
  ]);

  if (presetsSliceStatus === "idle" && presets.length > 0) {
    let preset =
      presets.find((element) => element.name === undefined) ??
      presets.find((element) => element.lastSelected === true);
    if (!preset) {
      preset = presets.find(
        (element) => element.name === "Default" && element.context === context
      );
    }

    tableData.columns = tableBuilder.setColumns(
      preset?.columns
        ? preset?.columns.map((element) => {
            return {
              name: element,
              sort: element === "travelVehicle" ? true : false,
            };
          })
        : [],
      preferencesContext
    );

    tableData.rows =
      isReportsIdle &&
      presetsSliceStatus === "idle" &&
      tableData.columns &&
      tableData?.columns?.length > 0
        ? driverTravelsSummary.map((driverTravel: DriverTravelSummary) => {
            const vehicle: Vehicle =
              vehiclesSelectors.selectById(
                store.getState(),
                driverTravel.vehicleId
              ) || ({} as Vehicle);
            const driver: Driver =
              driversSelectors.selectById(store.getState(), driverId) ??
              ({} as Driver);
            let fleetView: FleetView =
              fleetViewsSelectors.selectById(store.getState(), driver.fleet) ||
              ({} as FleetView);
            return tableBuilder.rowsBuilder(
              tableData.columns,
              context,
              {
                travelDetail: driverTravel as TravelDetail,
                vehicle,
                fleetView,
                setCurrentSelectedTravel,
              },
              preferencesContext
            );
          })
        : tableData.rows;
  }
  const onCheckboxChange = (
    el: any,
    id: number,
    color: string,
    vehicleId: number,
    queryParams: string
  ) => {
    let updatedCheckedList = [...checkedList];
    let updatedRemovedIndexes = [...removedIndexes];
    if (el.target.checked) {
      let indexToAssign = 0;
      if (updatedRemovedIndexes.length > 0) {
        indexToAssign = updatedRemovedIndexes[0];
        updatedRemovedIndexes.shift();
      } else {
        indexToAssign = updatedCheckedList.length;
      }
      updatedCheckedList.push({
        id: id,
        color: color,
        number: indexToAssign,
        vehicleId: vehicleId,
        queryParams: queryParams,
      });
    } else {
      updatedCheckedList = checkedList.filter(
        (Checked: any) => Checked.id !== id
      );
      const currentRemovedElement = checkedList.find(
        (Checked: any) => Checked.id === id
      );
      if (currentRemovedElement) {
        if (!updatedRemovedIndexes.includes(currentRemovedElement?.number)) {
          updatedRemovedIndexes.push(currentRemovedElement?.number);
        }
      }
    }
    setRemovedIndexes(_.sortBy(updatedRemovedIndexes));
    setCheckedList(updatedCheckedList);
  };

  function isSelected(routeId: number) {
    return routeId && checkedList
      ? checkedList.some((el) => el.id === routeId)
      : false;
  }

  useEffect(() => {
    document.title =
      t("breadcrumbs.driverTravelReport") + " - " + t("breadcrumbs.reports");
    store.dispatch(getFilteredVehiclesDetailsAsync(""));
    return () => {
      store.dispatch(vehiclesEmptyState());
    };
  }, []);
  const isLoading = driverTravelsSummarySliceStatus === "loading";

  return (
    <>
      <PageFilters>
        <div className="col col-16">
          <DriverFilterBarReport
            presets={presets}
            callback={(driverId, queryParams) => {
              setDriverId(driverId);
              setResetPage(true);
              setQueryParamsFromFilterBar(queryParams);
            }}
            disableButton={tableData.rows?.length === 0}
          />
        </div>
        {queryParamsFromFilterBar !== "" && (
          <div className="col col-16">
            <div className="details-driverReport-wrapper">
              <div className="details-report-title">
                {t("report.details.title")}
              </div>
              <div className="details-report-container">
                <div className="details-report-icons">
                  <DriverTravelReportDetails
                    vehicles={driverTravelsMacroSummary[0]?.droveVehicles}
                    kmTot={driverTravelsMacroSummary[0]?.totalTraveled}
                    consTot={driverTravelsMacroSummary[0]?.totalfuelConsumption}
                    speedMax={driverTravelsMacroSummary[0]?.macroMaxSpeed}
                    speedAvg={driverTravelsMacroSummary[0]?.macroAverageSpeed}
                    driveTime={driverTravelsMacroSummary[0]?.totalDrivingTime}
                    parkTimeAvg={
                      driverTravelsMacroSummary[0]?.averageParkingTime
                    }
                  />
                </div>
                <div className="details-report-button">
                  <Button
                    aspect="secondary"
                    size="small"
                    label={
                      viewMap
                        ? t("report.details.hideMap")
                        : t("report.details.viewMap")
                    }
                    onClick={() => {
                      setViewMap(!viewMap);
                      checkedList.length = 0;
                    }}
                    disabled={tableData.rows?.length === 0}
                  >
                    <IconMap size={14} color="--global-colors-ink-light" />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}
      </PageFilters>
      {tableData.rows?.length > 0 && (
        <>
          <PageContent>
            <div
              style={
                !viewMap
                  ? {
                      display: "inline-flex",
                    }
                  : {
                      display: "inline-flex",
                      overflow: "hidden",
                    }
              }
            >
              <div
                className={
                  viewMap
                    ? "dtr-table-container-collapsed"
                    : "dtr-table-container"
                }
              >
                <div style={{ display: "inline-flex", width: "100%" }}>
                  <div
                    className="dtr-checkbox-list"
                    style={{ marginTop: tableHeaderHeight }}
                  >
                    {viewMap &&
                      tableData.rows.map((_elem: any, idx: number) => (
                        <div
                          key={idx}
                          className="dtr-checkbox"
                          style={{
                            height: tableRowHeight,
                          }}
                        >
                          <Checkbox
                            checked={isSelected(
                              driverTravelsSummary[idx].routeId
                            )}
                            onChange={(e: any) => {
                              if (driverTravelsSummary[idx].routeId) {
                                onCheckboxChange(
                                  e,
                                  driverTravelsSummary[idx].routeId,
                                  trackColors[idx],
                                  driverTravelsSummary[idx].vehicleId,
                                  queryParamsFromFilterBar
                                );
                              } else {
                                ToastNotification({
                                  toastId: "routeNotAvailable",
                                  status: "error",
                                  description: i18next.t(
                                    "report.toastNotification.routeNotAvailable"
                                  ),
                                });
                              }
                            }}
                            label=""
                            disabled={
                              checkedList.length >= 10 &&
                              idx !== checkedList[idx].id &&
                              !isSelected(driverTravelsSummary[idx].routeId)
                            }
                          />
                        </div>
                      ))}
                  </div>
                  <div
                    className={
                      viewMap
                        ? "dtr-rows-container-collapsed"
                        : "dtr-rows-container"
                    }
                  >
                    <Table data={tableData}>
                      <Table.Head id="dtr-table-header" hasTableSpace={true} />
                      <Table.Body id="dtr-table-body" />
                    </Table>
                  </div>
                  <div
                    className="dtr-marker-list"
                    style={{ marginTop: tableHeaderHeight }}
                  >
                    {viewMap &&
                      checkedList.length > 0 &&
                      tableData.rows.map((_el: any, index: number) => (
                        <div
                          key={index}
                          className="dtr-marker"
                          style={{
                            height: tableRowHeight,
                          }}
                        >
                          {checkedList.map(
                            (element) =>
                              element.id ===
                                driverTravelsSummary[index].routeId && (
                                <div key={index}>
                                  <MapMarkerTruckNumber
                                    number={element.number + 1}
                                    color={trackColors[index]}
                                  />
                                </div>
                              )
                          )}
                        </div>
                      ))}
                  </div>
                </div>
                <div>
                  <PageCounter
                    isActionPerforming={isLoading}
                    totalElements={driverTravelsMacroSummary[0]?.totalTravels}
                    resetPage={resetPage} // every time query params changes resets number of page
                    disabled={!isReportsIdle}
                    progressiveArrowMode={true}
                    numOfPages={
                      !driverTravelsMacroSummary[0]?.totalTravels
                        ? 1
                        : Math.ceil(
                            driverTravelsMacroSummary[0]?.totalTravels / 10
                          )
                    }
                    numSelected={0}
                    onClick={(id) => {
                      const pageAndSize = getQueryString({
                        page: id - 1,
                        size: "10",
                      });
                      const finalQueryParams = queryParamsFromFilterBar
                        ? queryParamsFromFilterBar +
                          pageAndSize.replace("?", "&")
                        : pageAndSize;
                      store.dispatch(
                        getDriverTravelsSummaryAsync({
                          driverId: driverId,
                          queryParams: finalQueryParams,
                          page: id - 1,
                        })
                      );
                    }}
                  />
                </div>
              </div>

              {viewMap && (
                <div className="dtr-map-container">
                  <ReportMap
                    id={0}
                    googleMapsApiKey={
                      process.env.REACT_APP_GOOGLE_MAPS_API_KEY!
                    }
                    zoom={9}
                    latitude={41.9109}
                    longitude={12.4818}
                    checkedList={checkedList}
                    limit={10}
                  />
                </div>
              )}
            </div>
          </PageContent>
          {currentSelectedTravel !== -1 && selectedTravel.length > 0 && (
            <TravelDetailModal
              open={currentSelectedTravel !== -1 ? true : false}
              data={{
                gpsData: selectedTravel,
                travel:
                  (driverTravelsSummarySelectors.selectById(
                    store.getState(),
                    currentSelectedTravel
                  ) as TravelDetail) ?? ({} as TravelDetail),
                routeStateType: "TRACK",
              }}
              onClose={() => {
                setCurrentSelectedTravel(-1);
              }}
            />
          )}
        </>
      )}
    </>
  );
};
