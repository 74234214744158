import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import _ from "lodash";
import { normalize } from "normalizr";
import { RootState } from "../../../app/store";
import { Config } from "../../../config/Config";
import { GTFleetSuccessCodes } from "../../../config/GTFleetSuccessCodes";
import { vehicleTravelsMacroSummarySchema } from "./vehicleTravelNormalization";
import VehicleTravelsRepository from "./vehicleTravelRepository";

export type VehicleTravelsMacroSummary = {
  vehicleId: number;
  totalTravels: number;
  totalCosts: number;
  utilization: number;
  totalTraveled: number;
  totalParkingTime: number;
  totalIdleTime: number;
  totalDrivingTime: number;
  totalFuelConsumption: number;
  fuelsConsumption: { data: number; date: string }[];
  fuelsLevel: { data: number; date: string }[];
  macroAverageSpeed: number;
  macroMaxSpeed: number;
  averageParkingTime: number;
  firstTimestamp: Date;
  lastTimestamp: Date;
  trackCounter: number;
  stopCounter: number;
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const getVehicleTravelsMacroSummaryAsync = createAsyncThunk(
  "vehicleTravelsMacroSummary/getVehicleTravelsMacroSummary", //dp: rinominare
  async (data: { vehicleId: number; queryParams?: string }) => {
    const vehicleTravelsRepository = new VehicleTravelsRepository();
    const response =
      await vehicleTravelsRepository.getVehicleTravelsMacroSummary(
        data.vehicleId,
        data.queryParams
      );
    // // The value we return becomes the `fulfilled` action payload
    const vehicleTravelsMacroSummary = _.get(
      response,
      Config.VEHICLE_TRAVELS_MACRO_SUMMARY_RESPONSE_PATH
    );
    const normalizedData = normalize(
      vehicleTravelsMacroSummary,
      vehicleTravelsMacroSummarySchema
    );
    return normalizedData.entities;
  }
);

const vehicleTravelsMacroSummaryAdapter =
  createEntityAdapter<VehicleTravelsMacroSummary>({
    selectId: (vehicleTravelMacroSummary) =>
      vehicleTravelMacroSummary.vehicleId,
  });

//#region Custom Functions
function setFilteredData(state: any, action: PayloadAction<any>) {
  action.payload.vehicleTravelsMacroSummary
    ? vehicleTravelsMacroSummaryAdapter.setAll(
        state,
        action.payload.vehicleTravelsMacroSummary
      )
    : vehicleTravelsMacroSummaryAdapter.setAll(state, []);
}
//#endregiorn Custom Functions

//#region Slice
export const vehicleTravelsMacroSummarySlice = createSlice({
  name: "vehicleTravelsMacroSummary",
  initialState: vehicleTravelsMacroSummaryAdapter.getInitialState({
    status: "idle",
    reasonCode: "",
  }),
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    vehicleTravelsMacroSummaryEmptyState: (state: any) => {
      vehicleTravelsMacroSummaryAdapter.setAll(state, []);
      state.reasonCode = "";
      state.status = "idle";
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      // getvehicleTravelsMacroSummaryAsync extra reducers
      .addCase(
        getVehicleTravelsMacroSummaryAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          setFilteredData(state, action);
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.GET;
        }
      )
      .addCase(getVehicleTravelsMacroSummaryAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(getVehicleTravelsMacroSummaryAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = "";
      });
  },
});
//#endregion Slice

//#region Status
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const vehicleTravelsMacroSummarySelectors =
  vehicleTravelsMacroSummaryAdapter.getSelectors<RootState>(
    (state) => state.vehicleTravelsMacroSummary
  );

export const selectVehicleTravelsMacroSummarySliceStatus = (state: any) =>
  state.vehicleTravelsMacroSummary.status;
export const selectVehicleTravelsMacroSummarySliceReasonCode = (state: any) =>
  state.vehicleTravelsMacroSummary.reasonCode;
export const { vehicleTravelsMacroSummaryEmptyState } =
  vehicleTravelsMacroSummarySlice.actions;

export default vehicleTravelsMacroSummarySlice.reducer;
//#endregion Status
