import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import _ from "lodash";
import { normalize } from "normalizr";
import { RootState } from "../../../app/store";
import { Config } from "../../../config/Config";
import { GTFleetSuccessCodes } from "../../../config/GTFleetSuccessCodes";
import { reportGeofencesAndSummary } from "./GeofencesReportsNormalization";
import GeofencesReportsRepository from "./GeofencesReportsRepository";

export type GeofenceReport = {
  geofence: string;
  inEvent: Date;
  outEvent: Date;
  stopDate: Date;
  totalStop: number;
  totalTrack: number;
  routeId: number;
  vehicleId: number;
};

export type ReportGeofenceData = {
  id: number;
  vehicle: number;
  in: Date;
  out: Date;
  events: number;
  totalStop: number;
  totalTrack: number;
  routeId: number;
  geofences: GeofenceReport[];
};

export const geofencePassStopValues = {
  STOP: "STOP",
  TRACK: "TRACK",
};
export type GeofenceType = keyof typeof geofencePassStopValues;

//#endregion Type

//#region API
// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const getGeofencesReportsAsync = createAsyncThunk(
  "geofence/positions",
  async (data: { queryParams?: string }, { rejectWithValue }) => {
    try {
      const geofencesReportsRepository = new GeofencesReportsRepository();
      const response = await geofencesReportsRepository.getReportsGeofence(
        data?.queryParams
      );
      // The value we return becomes the `fulfilled` action payload
      const geofencesReports = _.get(
        response,
        Config.GEOFENCES_REPORTS_RESPONSE_PATH
      );
      const geofencesReportsExtended = { ...geofencesReports, id: 1 };
      const normalizedData = normalize(
        geofencesReportsExtended,
        reportGeofencesAndSummary
      );
      return normalizedData.entities;
    } catch (err: any) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data.message);
    }
  }
);
//#endregion API

//#region Custom Functions
function setFilteredData(state: any, action: PayloadAction<any>) {
  action.payload?.reportGeofenceData
    ? geofencesReportsAdapter.setAll(state, action.payload.reportGeofenceData)
    : geofencesReportsAdapter.setAll(state, []);
}

//#region Slice
const geofencesReportsAdapter = createEntityAdapter<ReportGeofenceData>({
  selectId: (report) => report.id,
  sortComparer: (a, b) => a.id.toString().localeCompare(b.id.toString()),
});

export const geofencesReportsSlice = createSlice({
  name: "geofencesReportsSlice",
  initialState: geofencesReportsAdapter.getInitialState({
    status: "idle",
    reasonCode: "",
  }),
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    geofencesReportsEmptyState: (state) => {
      geofencesReportsAdapter.setAll(state, []);
      state.reasonCode = "";
      state.status = "idle";
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      //#region Entity Reducers
      // getRoutesHistoryAsync extra reducers
      .addCase(
        getGeofencesReportsAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          setFilteredData(state, action);
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.GET;
        }
      )
      .addCase(getGeofencesReportsAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(getGeofencesReportsAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = "";
      });
    //#endregion Entity Reducers
  },
});
//#endregion Slice

//#region Status
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const geofencesReportsSelectors =
  geofencesReportsAdapter.getSelectors<RootState>(
    (state) => state.geofencesReports
  );

export const selectGeofencesReportsSliceStatus = (state: any) =>
  state.geofencesReports.status;
export const selectGeofencesReportsSliceReasonCode = (state: any) =>
  state.geofencesReports.reasonCode;
export const { geofencesReportsEmptyState } = geofencesReportsSlice.actions;
//#endregion Status

export default geofencesReportsSlice.reducer;
