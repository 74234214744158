import propTypes from "prop-types";
export const PartnerLogo = ({ isNegative }) => {
  if (!isNegative) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34.14 8.63">
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M3.98 4.14c0-.23-.03-.46-.09-.68s-.15-.41-.27-.59c-.12-.17-.27-.31-.45-.41-.18-.1-.39-.15-.62-.15-.48 0-.84.17-1.09.5-.24.33-.36.78-.36 1.33 0 .26.03.5.1.73.06.22.16.41.28.57.13.16.28.29.45.38.18.09.38.14.61.14.26 0 .48-.05.66-.16.18-.11.33-.25.45-.41.12-.17.2-.36.25-.58s.08-.44.08-.66zM0 1.55h1.08v.7h.02c.16-.3.38-.51.67-.64s.6-.19.93-.19c.41 0 .76.07 1.06.21s.56.34.76.59c.2.25.35.54.45.88.1.33.15.69.15 1.07 0 .35-.05.68-.13 1.01-.09.33-.23.62-.41.87s-.41.45-.7.6c-.28.15-.61.22-.99.22-.17 0-.33-.01-.5-.05-.17-.03-.33-.08-.48-.14-.15-.07-.29-.15-.42-.26-.13-.1-.24-.22-.33-.36h-.02v2.58H0V1.55z"
        ></path>
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M9.49 4.13c-.17.11-.39.18-.64.2s-.51.06-.76.11c-.12.02-.24.05-.35.09s-.21.09-.3.15-.16.15-.2.25c-.05.1-.08.23-.08.38 0 .13.04.23.11.32s.16.15.26.2c.1.05.22.09.34.11s.24.03.33.03c.13 0 .26-.02.41-.05s.28-.09.41-.17.24-.18.32-.3c.09-.12.13-.28.13-.45v-.85l.02-.02zm1.14 1.44c0 .14.02.24.06.3.04.06.11.09.22.09h.12s.1 0 .16-.02v.79s-.09.03-.15.04c-.06.02-.13.03-.19.04l-.2.03h-.17c-.23 0-.43-.05-.58-.14-.15-.09-.25-.26-.3-.49-.23.22-.51.38-.84.48s-.65.15-.96.15c-.23 0-.46-.03-.67-.09s-.4-.16-.57-.28c-.16-.12-.29-.28-.39-.47-.1-.19-.14-.41-.14-.66 0-.32.06-.58.17-.78.12-.2.27-.36.46-.47.19-.11.4-.2.64-.25s.47-.09.71-.12c.21-.04.4-.07.59-.09.19-.02.35-.05.49-.09.14-.04.26-.1.34-.18.08-.08.12-.21.12-.38a.57.57 0 00-.1-.36c-.07-.09-.16-.17-.26-.22s-.22-.08-.35-.1-.25-.03-.36-.03c-.32 0-.58.07-.79.2-.21.13-.32.34-.35.62H6.2c.02-.33.1-.61.24-.83.14-.22.32-.4.54-.53s.46-.23.73-.28.55-.08.84-.08c.25 0 .5.03.75.08s.47.14.66.26.36.28.48.46c.12.19.18.42.18.7v2.7z"
        ></path>
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M12.08 1.55h1.07v1h.02c.03-.14.1-.28.19-.41a1.979 1.979 0 01.8-.63c.17-.07.34-.1.51-.1h.27c.05 0 .1 0 .15.02v1.1c-.08 0-.16-.03-.24-.04-.08 0-.16-.02-.24-.02-.19 0-.38.04-.55.12s-.32.19-.44.34c-.13.15-.23.33-.3.56-.07.22-.11.47-.11.76v2.46h-1.14V1.55z"
        ></path>
        <path
          id="e"
          fill="#fff"
          fillRule="evenodd"
          d="M19.37 1.55h1.08v.76l.02.02c.17-.29.4-.51.68-.68.28-.16.59-.24.93-.24.57 0 1.01.15 1.34.44.33.29.49.73.49 1.32v3.55h-1.14V3.47c-.01-.41-.1-.7-.26-.88-.16-.18-.41-.28-.75-.28-.19 0-.37.04-.52.11s-.28.17-.39.29c-.11.12-.19.27-.25.43-.06.17-.09.34-.09.53v3.05h-1.14V1.55z"
        ></path>
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M28.88 3.68c-.01-.18-.05-.35-.11-.52-.06-.17-.15-.31-.26-.43-.11-.12-.24-.22-.4-.3s-.33-.11-.53-.11-.38.03-.55.1c-.16.07-.3.17-.42.29s-.21.27-.28.43c-.07.17-.11.35-.11.54h2.66zm-2.66.75c0 .2.03.39.08.58.06.19.14.35.26.49.11.14.26.25.43.34.17.08.38.12.62.12.33 0 .6-.07.81-.22.2-.14.36-.36.45-.64h1.08a2.178 2.178 0 01-.84 1.31c-.21.15-.44.26-.69.34-.26.08-.52.12-.81.12-.41 0-.77-.07-1.08-.2-.31-.13-.58-.32-.8-.56s-.38-.53-.49-.86c-.11-.33-.17-.7-.17-1.1 0-.37.06-.71.18-1.04s.28-.62.5-.87.48-.45.78-.6c.31-.15.65-.22 1.04-.22.41 0 .77.09 1.1.25.32.17.59.4.81.68.21.28.37.6.46.96.1.36.12.74.08 1.12h-3.8z"
        ></path>
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M31.11 1.55h1.07v1h.02c.03-.14.1-.28.19-.41a1.918 1.918 0 01.81-.63c.17-.07.34-.1.51-.1h.27s.1 0 .16.02v1.1c-.08 0-.16-.03-.24-.04-.08 0-.17-.02-.25-.02-.19 0-.38.04-.54.12-.17.08-.32.19-.44.34-.13.15-.23.33-.3.56-.07.22-.11.47-.11.76v2.46h-1.14V1.55z"
        ></path>
        <g mask="url(#e)">
          <path
            fill="#fff"
            fillRule="evenodd"
            d="M15.3 1.59h.86V.04h1.14v1.55h1.03v.85H17.3V5.2c0 .12 0 .22.02.31 0 .09.03.16.07.22.04.06.09.11.16.13.07.03.17.05.3.05h.24c.08 0 .16-.01.24-.03v.88c-.13.01-.25.03-.37.04-.12.01-.24.02-.37.02-.3 0-.54-.03-.73-.09a1 1 0 01-.43-.25.942.942 0 01-.21-.41c-.04-.17-.06-.36-.06-.57V2.44h-.86v-.85z"
          ></path>
        </g>
      </svg>
    );
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34.14 8.63">
        <path
          fill="#031832"
          fillRule="evenodd"
          d="M3.98 4.14c0-.23-.03-.46-.09-.68s-.15-.41-.27-.59c-.12-.17-.27-.31-.45-.41-.18-.1-.39-.15-.62-.15-.48 0-.84.17-1.09.5-.24.33-.36.78-.36 1.33 0 .26.03.5.1.73.06.22.16.41.28.57.13.16.28.29.45.38.18.09.38.14.61.14.26 0 .48-.05.66-.16.18-.11.33-.25.45-.41.12-.17.2-.36.25-.58s.08-.44.08-.66zM0 1.55h1.08v.7h.02c.16-.3.38-.51.67-.64s.6-.19.93-.19c.41 0 .76.07 1.06.21s.56.34.76.59c.2.25.35.54.45.88.1.33.15.69.15 1.07 0 .35-.05.68-.13 1.01-.09.33-.23.62-.41.87s-.41.45-.7.6c-.28.15-.61.22-.99.22-.17 0-.33-.01-.5-.05-.17-.03-.33-.08-.48-.14-.15-.07-.29-.15-.42-.26-.13-.1-.24-.22-.33-.36h-.02v2.58H0V1.55z"
        ></path>
        <path
          fill="#031832"
          fillRule="evenodd"
          d="M9.49 4.13c-.17.11-.39.18-.64.2s-.51.06-.76.11c-.12.02-.24.05-.35.09s-.21.09-.3.15-.16.15-.2.25c-.05.1-.08.23-.08.38 0 .13.04.23.11.32s.16.15.26.2c.1.05.22.09.34.11s.24.03.33.03c.13 0 .26-.02.41-.05s.28-.09.41-.17.24-.18.32-.3c.09-.12.13-.28.13-.45v-.85l.02-.02zm1.14 1.44c0 .14.02.24.06.3.04.06.11.09.22.09h.12s.1 0 .16-.02v.79s-.09.03-.15.04c-.06.02-.13.03-.19.04l-.2.03h-.17c-.23 0-.43-.05-.58-.14-.15-.09-.25-.26-.3-.49-.23.22-.51.38-.84.48s-.65.15-.96.15c-.23 0-.46-.03-.67-.09s-.4-.16-.57-.28c-.16-.12-.29-.28-.39-.47-.1-.19-.14-.41-.14-.66 0-.32.06-.58.17-.78.12-.2.27-.36.46-.47.19-.11.4-.2.64-.25s.47-.09.71-.12c.21-.04.4-.07.59-.09.19-.02.35-.05.49-.09.14-.04.26-.1.34-.18.08-.08.12-.21.12-.38a.57.57 0 00-.1-.36c-.07-.09-.16-.17-.26-.22s-.22-.08-.35-.1-.25-.03-.36-.03c-.32 0-.58.07-.79.2-.21.13-.32.34-.35.62H6.2c.02-.33.1-.61.24-.83.14-.22.32-.4.54-.53s.46-.23.73-.28.55-.08.84-.08c.25 0 .5.03.75.08s.47.14.66.26.36.28.48.46c.12.19.18.42.18.7v2.7z"
        ></path>
        <path
          fill="#031832"
          fillRule="evenodd"
          d="M12.08 1.55h1.07v1h.02c.03-.14.1-.28.19-.41a1.979 1.979 0 01.8-.63c.17-.07.34-.1.51-.1h.27c.05 0 .1 0 .15.02v1.1c-.08 0-.16-.03-.24-.04-.08 0-.16-.02-.24-.02-.19 0-.38.04-.55.12s-.32.19-.44.34c-.13.15-.23.33-.3.56-.07.22-.11.47-.11.76v2.46h-1.14V1.55z"
        ></path>
        <path
          id="e"
          fill="#031832"
          fillRule="evenodd"
          d="M19.37 1.55h1.08v.76l.02.02c.17-.29.4-.51.68-.68.28-.16.59-.24.93-.24.57 0 1.01.15 1.34.44.33.29.49.73.49 1.32v3.55h-1.14V3.47c-.01-.41-.1-.7-.26-.88-.16-.18-.41-.28-.75-.28-.19 0-.37.04-.52.11s-.28.17-.39.29c-.11.12-.19.27-.25.43-.06.17-.09.34-.09.53v3.05h-1.14V1.55z"
        ></path>
        <path
          fill="#031832"
          fillRule="evenodd"
          d="M28.88 3.68c-.01-.18-.05-.35-.11-.52-.06-.17-.15-.31-.26-.43-.11-.12-.24-.22-.4-.3s-.33-.11-.53-.11-.38.03-.55.1c-.16.07-.3.17-.42.29s-.21.27-.28.43c-.07.17-.11.35-.11.54h2.66zm-2.66.75c0 .2.03.39.08.58.06.19.14.35.26.49.11.14.26.25.43.34.17.08.38.12.62.12.33 0 .6-.07.81-.22.2-.14.36-.36.45-.64h1.08a2.178 2.178 0 01-.84 1.31c-.21.15-.44.26-.69.34-.26.08-.52.12-.81.12-.41 0-.77-.07-1.08-.2-.31-.13-.58-.32-.8-.56s-.38-.53-.49-.86c-.11-.33-.17-.7-.17-1.1 0-.37.06-.71.18-1.04s.28-.62.5-.87.48-.45.78-.6c.31-.15.65-.22 1.04-.22.41 0 .77.09 1.1.25.32.17.59.4.81.68.21.28.37.6.46.96.1.36.12.74.08 1.12h-3.8z"
        ></path>
        <path
          fill="#031832"
          fillRule="evenodd"
          d="M31.11 1.55h1.07v1h.02c.03-.14.1-.28.19-.41a1.918 1.918 0 01.81-.63c.17-.07.34-.1.51-.1h.27s.1 0 .16.02v1.1c-.08 0-.16-.03-.24-.04-.08 0-.17-.02-.25-.02-.19 0-.38.04-.54.12-.17.08-.32.19-.44.34-.13.15-.23.33-.3.56-.07.22-.11.47-.11.76v2.46h-1.14V1.55z"
        ></path>
        <g mask="url(#e)">
          <path
            fill="#031832"
            fillRule="evenodd"
            d="M15.3 1.59h.86V.04h1.14v1.55h1.03v.85H17.3V5.2c0 .12 0 .22.02.31 0 .09.03.16.07.22.04.06.09.11.16.13.07.03.17.05.3.05h.24c.08 0 .16-.01.24-.03v.88c-.13.01-.25.03-.37.04-.12.01-.24.02-.37.02-.3 0-.54-.03-.73-.09a1 1 0 01-.43-.25.942.942 0 01-.21-.41c-.04-.17-.06-.36-.06-.57V2.44h-.86v-.85z"
          ></path>
        </g>
      </svg>
    );
  }
};

PartnerLogo.propTypes = {
  isNegative: propTypes.bool,
};
