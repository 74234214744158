import PropTypes from "prop-types";
import { useEffect, useState } from "react";

import { t } from "i18next";
import "./MenuBalloon.css";

export const MenuBalloon = ({ open, children, ...props }) => {
  const [isOpen, setIsOpen] = useState(open);

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  return (
    <>
      {isOpen && (
        <div className="mn-menu-balloon" {...props}>
          <div className="mn-menu-balloon-inner">{children}</div>
        </div>
      )}
    </>
  );
};

MenuBalloon.propTypes = {
  /**
   * The state of the balloon.
   */
  open: PropTypes.bool,
};
MenuBalloon.defaultProps = {
  open: false,
};

const Header = ({ userInfo, notifications, userHelp, ...props }) => {
  const w = window;

  return (
    <>
      {userInfo && (
        <header {...props}>
          <div>{userInfo.fullName}</div>
          <p>{userInfo.email}</p>
        </header>
      )}
      {userHelp && (
        <header className="help-header" {...props}>
          <div>{userHelp.sectionTitle}</div>
          <div className="link" onClick={userHelp.onClick}>
            {userHelp.label}
          </div>
          <div
            className="get-help-text"
            onClick={() => {
              w.HubSpotConversations?.widget.open() ||
                w.HubSpotConversations?.widget.close();
            }}
            href="#hs-chat-open"
          >
            {t("common.getHelp")}
          </div>
        </header>
      )}
    </>
  );
};

Header.propTypes = {
  userInfo: PropTypes.shape({
    fullName: PropTypes.string,
    email: PropTypes.string,
  }),
  notifications: PropTypes.arrayOf(
    PropTypes.shape({
      categoryName: PropTypes.string.isRequired,
      notifications: PropTypes.arrayOf(
        PropTypes.shape({
          message: PropTypes.string,
          time: PropTypes.string,
        })
      ).isRequired,
    })
  ),
  userHelp: PropTypes.shape({
    sectionTitle: PropTypes.string,
    label: PropTypes.string,
  }),
};

const Item = ({ label, onClick, icon }) => {
  return (
    <li onClick={() => onClick()}>
      {icon !== undefined && icon}
      {label}
    </li>
  );
};

Item.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func,
  icon: PropTypes.object,
};

MenuBalloon.Header = Header;
MenuBalloon.Item = Item;
