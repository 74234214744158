import i18next, { t } from "i18next";
import _ from "lodash";
import React, { useEffect } from "react";
import { Vehicle } from "../../features/vehicle/vehiclesSlice";
import { ToastNotification } from "../../utils/ToastNotification";
import { IconArrowDown } from "../Icon/Line/ArrowDown";
import { ChildDropdown, Option } from "./ChildDropdown";
import "./VehiclesDropdown.css";

interface VehiclesDropdownProps {
  options: Option[];
  valueSelected?: Vehicle[];
  getValueSelected?: (e: any) => any;
  loading: boolean;
  vehiclesLimit?: number;
}

export const VehiclesDropdown: React.FC<VehiclesDropdownProps> = ({
  options,
  getValueSelected,
  valueSelected,
  loading,
  vehiclesLimit,
}: VehiclesDropdownProps) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [isActive, setIsActive] = React.useState(true);
  const [noVehicles, setNoVehicles] = React.useState(true);
  const [value, setValue] = React.useState<Vehicle[]>([]);
  const parentRef = React.useRef<any>(null);
  const wrapperRef = React.useRef<any>(null);

  const optionChildDropdown = () => {
    if (options.length > 0) {
      const option = {
        label: i18next.t("report.filterBar.vehiclesDropdownSelectAll"),
        hasCheckbox: true,
        hasDropdown: false,
        hasCount: true,
        vehicles: [],
      } as Option;
      const selectAll: Option[] = [];
      selectAll.push(option);
      options.forEach((x) => selectAll.push(x));

      return selectAll;
    }

    return [];
  };

  useEffect(() => {
    if (loading) {
      setIsActive(true);
    } else {
      if (!loading && options.length === 0) {
        setIsActive(false);
        setNoVehicles(true);
        setIsOpen(false);
      } else {
        setNoVehicles(false);
        setIsActive(true);
      }
    }
  }, [loading, options]);

  useEffect(() => {
    if (!_.isEqual(value, valueSelected)) {
      valueSelected !== undefined && setValue(valueSelected);
    }
  }, [valueSelected]);

  useEffect(() => {
    if (getValueSelected != undefined) {
      getValueSelected(value ?? []);
    }
  }, [value]);

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (
        wrapperRef.current &&
        !wrapperRef.current?.contains(event.target) &&
        !parentRef.current?.contains(event.target)
      ) {
        setIsOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  function calculateWidth() {
    let text = displayValue();
    if (text === "") {
      return "180px";
    } else if (value.length === 1) {
      return text.length + 6 + "ch";
    } else {
      return "220px";
    }
  }

  function displayValue() {
    let vehiclesCount = value.length;
    if (vehiclesCount === 0) {
      return "";
    } else if (vehiclesCount === 1) {
      return `${value[0].alias} - ${value[0].plate}`;
    } else {
      return `${vehiclesCount} ${i18next.t("common.vehicles")}`;
    }
  }

  function getClassName() {
    if (isOpen && isActive) {
      return "vehicles-dropdown-btn active";
    } else if (value.length !== 0) {
      return "vehicles-dropdown-btn selected";
    }
    return "vehicles-dropdown-btn";
  }

  return (
    <React.Fragment>
      <div
        ref={parentRef}
        style={{ width: calculateWidth() }}
        data-testid="dropdown-btn"
        className={getClassName()}
        onClick={() => {
          if (!isActive) {
            ToastNotification({
              toastId: "noVehicleDefined",
              status: "default",
              description: t("report.filterBar.noVehiclesDropdown"),
            });
          } else {
            setIsOpen(!isOpen);
          }
        }}
      >
        <span className="vehicles-dropdown-span">
          <input
            type="text"
            readOnly
            disabled={!(isActive || loading)}
            placeholder={i18next.t("report.filterBar.vehiclesDropdownLabel")}
            value={displayValue()}
          />
        </span>
        <div
          className={`vehicles-dropdown-arrow ${isOpen ? "open" : null} ${
            !isActive ? "iconDisabled" : null
          }`}
        >
          <IconArrowDown size={12} color="--global-colors-ink-light" />
        </div>
      </div>
      <div ref={wrapperRef}>
        <ChildDropdown
          loading={loading}
          visible={isOpen}
          hasSearch
          options={optionChildDropdown()}
          value={value}
          setValue={setValue}
          type={"vehicles"}
          onlyCheckbox={true}
          elementsLimit={vehiclesLimit}
        />
      </div>
    </React.Fragment>
  );
};
