import { t } from "i18next";
import _ from "lodash";
import { useEffect, useRef, useState } from "react";
import {
  Location,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { store } from "../../app/store";
import { Dropdown } from "../../ui/Dropdown/Dropdown";
import Form from "../../ui/Forms/Form";
import SearchField from "../../ui/Forms/SearchField";
import {
  VehicleStatusType,
  vehicleStatusValues,
} from "../../ui/Vehicles/VehicleTypes";
import {
  fleetViewsEmptyState,
  fleetViewsSelectors,
  getFleetViewsAsync,
} from "../fleet/fleetViewsSlice";
import {
  getVehiclesDetailsPaginationAsync,
  vehiclesEmptyState,
  vehiclesSelectors,
} from "./vehiclesSlice";
interface QueryParams {
  [paramName: string]: any;
}

interface AdminVehicleFilterBarProps {
  presets: any[];
  callback: (buildQueryParam: string) => any;
}

interface DropDownItem {
  label: string;
}

interface StatusType {
  key: "ACTIVE" | "INACTIVE";
  label: string;
}

interface GroupType {
  key: string;
  label: string;
}

export const AdminVehicleFilterBar: React.FC<AdminVehicleFilterBarProps> = ({
  callback,
}) => {
  const [idDriverVehicle, setIdDriverVehicle] = useState("");
  const queryParamsRef = useRef<QueryParams>({});
  let queryParams: QueryParams = queryParamsRef.current;
  const vehicles = useAppSelector(vehiclesSelectors.selectAll);
  const navigate = useNavigate();
  const [selectedStatuses, setSelectedStatuses] = useState([] as StatusType[]);
  const [selectedGroups, setSelectedGroups] = useState([] as GroupType[]);

  // Handle routes
  const [searchParams, setSearchParams] = useSearchParams();
  const location: Location = useLocation();

  useEffect(() => {
    store.dispatch(getFleetViewsAsync());
    return function cleanUp() {
      store.dispatch(vehiclesEmptyState());
      store.dispatch(fleetViewsEmptyState());
    };
  }, []);

  useEffect(() => {
    const map = new Map();
    const currentSearchParams =
      searchParams.toString() !== "" ? searchParams : null;
    if (!!currentSearchParams && _.isEmpty(queryParams)) {
      const size = currentSearchParams.get("size");
      map.set("size", size ?? "10");

      const aliasSearch = currentSearchParams.get("alias");
      const plateSearch = currentSearchParams.get("plate");
      if (plateSearch) {
        setIdDriverVehicle(plateSearch);
        map.set("plate", plateSearch);
        map.set("alias", aliasSearch);
      }

      const fleetNames = currentSearchParams.getAll("fleet.name");
      if (!!fleetNames && fleetNames.length > 0) {
        setSelectedGroups(
          fleetNames.map((x) => {
            return { key: x, label: x } as GroupType;
          })
        );
        map.set("fleet.name", fleetNames);
      }
      const vehicleStatus = currentSearchParams.getAll("status");
      if (vehicleStatus && vehicleStatus.length > 0) {
        setSelectedStatuses(
          vehicleStatus.map((x) => {
            return { key: x, label: _.capitalize(x) } as StatusType;
          })
        );
        map.set("status", vehicleStatus);
      }
    }
    if (map.size === 0 && _.isEmpty(queryParams)) {
      map.set("size", "10");
      const vehicleStatus = ["ACTIVE"];
      if (vehicleStatus && vehicleStatus.length > 0) {
        setSelectedStatuses(
          vehicleStatus.map((x) => {
            return { key: x, label: _.capitalize(x) } as StatusType;
          })
        );
        map.set("status", vehicleStatus);
      }
    }
    if (map.size > 0) {
      handleChanges(map);
    }
  }, [location]);

  const getQueryString = (queryparams: QueryParams): string => {
    let queryString = "";
    for (const queryParamName in queryparams) {
      const queryParamValue = queryparams[queryParamName];
      let queryParamValueArray: string[];
      if (Array.isArray(queryParamValue)) {
        queryParamValueArray = queryParamValue;
      } else {
        queryParamValueArray = queryParamValue.split(" ");
      }
      for (const queryparamValue of queryParamValueArray) {
        const operator = queryString.length === 0 ? "?" : "&";
        if (!!queryparamValue) {
          let modifiedQueryParamValue = queryparamValue;
          queryString += `${operator}${queryParamName}=${modifiedQueryParamValue}`;
        }
      }
    }
    return queryString;
  };

  const handleChanges = (params: Map<string, string[] | string>): void => {
    let queryString = "";
    let executeQuery = false;
    if (!!params) {
      params.forEach((value, key) => {
        if (!!value && value.length > 0) {
          queryParams[key] = value;
          executeQuery = true;
        } else {
          if (queryParams.hasOwnProperty(key)) {
            delete queryParams[key];
            executeQuery = true;
          }
        }
      });
      queryString = getQueryString(queryParams);
    }
    // effettuare chiamata API con parametri in query string
    if (executeQuery) {
      store.dispatch(
        getVehiclesDetailsPaginationAsync({ queryParams: queryString })
      );
      navigate({
        pathname: "/admin/vehicles",
        search: queryString,
      });
      callback(queryString);
    }
  };

  const fleets = fleetViewsSelectors.selectAll(store.getState());

  const groupsValues: DropDownItem[] = !!fleets
    ? fleets.map((value) => {
        return {
          label: value.name,
          count: value.vehiclesSize,
        };
      })
    : [];

  const statusType: { type: VehicleStatusType; label: string }[] =
    _.keys(vehicleStatusValues).map((typeKey) => {
      return {
        type: _.get(vehicleStatusValues, typeKey),
        label:
          typeKey.charAt(0).toUpperCase() +
          typeKey.slice(1).toLocaleLowerCase(),
      };
    }) ?? [];

  const debouncedSearch = useRef(
    _.debounce(async (val) => {
      setIdDriverVehicle(val);
      const map = new Map();
      map.set("alias", val);
      map.set("plate", val);
      handleChanges(map);
    }, 1000)
  ).current;

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  async function handleSearch(val: string) {
    await debouncedSearch(val);
  }

  return (
    <div className="vehicle-filterbar-row-splitter">
      <>
        <div className="vehicle-filterbar-search">
          <Form>
            <SearchField
              name="search"
              id="driverName"
              size="small"
              placeholder={t("admin.adminFilterBar.searchField")}
              value={idDriverVehicle}
              onChange={(val: string) => {
                handleSearch(val);
              }}
            />
          </Form>
        </div>
        <div className="vehicle-dropdown-groups">
          <Dropdown
            placeholderInValue={t(
              "fleetControl.filterBar.placeholderInValGroups"
            )}
            hasCheckbox={true}
            itemAttribute="label"
            placeholder={t("fleetControl.filterBar.searchGroups")}
            size={"small"}
            onChange={(val: DropDownItem[]) => {
              const map = new Map();
              map.set(
                "fleet.name",
                val.map((x) => x.label)
              );
              handleChanges(map);
            }}
            options={groupsValues}
            value={selectedGroups}
          />
        </div>
        <div className="vehicle-dropdown-statuses">
          <Dropdown
            placeholderInValue={t(
              "fleetControl.filterBar.placeholderInValStatus"
            )}
            itemAttribute="label"
            hasCheckbox={true}
            placeholder={t("fleetControl.filterBar.status")}
            size={"small"}
            onChange={(val: DropDownItem[]) => {
              const map = new Map();
              map.set(
                "status",
                val.map((x) => x.label.toUpperCase())
              );
              handleChanges(map);
            }}
            value={selectedStatuses}
            options={statusType}
          />
        </div>
      </>
    </div>
  );
};
