import React from "react";
import PropTypes from "prop-types";

import { IconClose } from "../Icon/Line/Close";
import { IconAlert } from "../Icon/Line/Alert";
import { IconCheck } from "../Icon/Line/Check";
import { IconWarning } from "../Icon/Line/Warning";
import { IconHelp } from "../Icon/Line/Help";

import "./Alert.css";

// Component should appear when
// status: error, info, warning
// message: string
// onClick: function (for closing)

export const Alert = ({ message, status, onClose, hasCloseIcon }) => {
  const handleOnClick = () => {
    onClose();
  };

  let Icon = null;

  switch (status) {
    case "error":
      Icon = <IconAlert color="--global-colors-ui-white" size={14} />;
      break;
    case "warning":
      Icon = <IconWarning color="--global-colors-ui-white" size={14} />;
      break;
    case "info":
      Icon = <IconHelp color="--global-colors-ui-white" size={14} />;
      break;
    case "success":
      Icon = <IconCheck color="--global-colors-ui-white" size={14} />;
      break;
    default:
      Icon = <IconHelp color="--global-colors-ui-white" size={14} />;
      break;
  }

  return (
    <div className={`mc-alert mc-alert--${status}`}>
      {Icon && <span className="mc-alert__icon">{Icon}</span>}
      <span className="mc-alert__message">{message}</span>
      {onClose && hasCloseIcon && (
        <button className="mc-alert__close" onClick={handleOnClick}>
          <IconClose color="--global-colors-ui-white" size={14} />
        </button>
      )}
    </div>
  );
};

Alert.propTypes = {
  message: PropTypes.string.isRequired,
  status: PropTypes.oneOf(["error", "info", "warning", "success"]).isRequired,
  onClose: PropTypes.func,
};
