import HttpClient from "../../utils/HttpClient";

export default class DriversStatusRepository extends HttpClient {
  constructor() {
    super(true);
  }

  getDriverStatus(driverId: number) {
    const tenantId = localStorage.getItem("tenantId");
    return this.instance.get(
      "/tenants/" + tenantId + "/drivers/" + driverId + "/status"
    );
  }

  getDriversStatuses() {
    const tenantId = localStorage.getItem("tenantId");
    return this.instance.get("/tenants/" + tenantId + "/drivers/status");
  }

  // data rest API
  getFilteredDriversStatusAndDetails(customQueryParams?: string) {
    const tenantId = localStorage.getItem("tenantId");
    let path = "tenants/" + tenantId + "/drivers";
    let queryParams = "";
    if (!!customQueryParams) {
      queryParams = customQueryParams + "&";
    } else {
      queryParams = "?";
    }
    return this.instance.get(path + queryParams);
  }

  getFilteredDriversStatusAndDetailsByFleetId(
    fleetId: number,
    customQueryParams?: string
  ) {
    const tenantId = localStorage.getItem("tenantId");
    let path = "tenants/" + tenantId + "/fleets/" + fleetId + "/drivers";
    let queryParams = "";
    if (!!customQueryParams) {
      queryParams = customQueryParams + "&";
    } else {
      queryParams = "?";
    }
    return this.instance.get(path + queryParams);
  }
}
