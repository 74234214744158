import "./MessageDivider.css";

export const MessageDivider = ({ hasData, date, ...props }) => {
  let className = "mn-message-divider";
  className += hasData ? " mn-message-divider--has-data" : "";
  return (
    <div className={className} {...props}>
      <div className="mn-message-divider-data">
        <span>{date}</span>
      </div>
    </div>
  );
};
