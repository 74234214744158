import { t } from "i18next";
import { useContext, useEffect, useRef, useState } from "react";
import {} from "react-i18next";
import { useMatch, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { store } from "../../app/store";
import { GTFleetSuccessCodes } from "../../config/GTFleetSuccessCodes";
import { GTFleetErrorCodes } from "../../config/GTfleetErrorCodes";
import PageContent from "../../layout/PageContent";
import PageFilters from "../../layout/PageFilters";
import { ModalBody } from "../../ui/Modal/ModalBody";
import { ModalFooter } from "../../ui/Modal/ModalFooter";
import { ModalWrapper } from "../../ui/Modal/ModalWrapper";
import { PageCounter } from "../../ui/Table/PageCounter";
import { Table } from "../../ui/Table/Table";
import { ToastNotification } from "../../utils/ToastNotification";
import { getQueryString } from "../../utils/Utils";
import { AdminUserFilterBar } from "./AdminUserFilterBar";
import "./AdminUsers.css";
import { AdminUsersTableBuilder } from "./AdminUsersTableBuilder";
import { Preferences } from "./preference/preferencesSlice";
import { UserPermissions } from "./privilege/privilegesSlice";
import UserContext from "./userContext";
import {
  UserInfo,
  getFilteredUsersInfoPaginationAsync,
  getUserInfoAsync,
  selectUsersInfoSlicePage,
  selectUsersInfoSliceReasonCode,
  selectUsersInfoSliceStatus,
  selectUsersInfoSliceTotalElements,
  updateUserInfoAsync,
  userInfoSelectors,
  usersInfoEmptyState,
} from "./usersInfoSlice";

interface AdminUsersProps {
  permissions: UserPermissions;
}

const context = "userAdmin";

interface TableData {
  columns: { label: string; field: string; sort: boolean }[] | undefined;
  rows: { [key: string]: any };
}

const tableData: TableData = {} as TableData;

const tableSchemaFix = [
  "user",
  "profile",
  "lastaccess",
  "status",
  "expiration",
];

/**
 * Table component of the Users
 * @returns
 */
export const AdminUsers: React.FC<AdminUsersProps> = ({ permissions }) => {
  const navigate = useNavigate();
  const tableBuilderRef = useRef<AdminUsersTableBuilder>(
    new AdminUsersTableBuilder()
  );

  const [preferencesContext]: [Preferences] = useContext(UserContext);

  let tableBuilder = tableBuilderRef.current;

  const [queryParamsFromFilterBar, setQueryParamsFromFilterBar] =
    useState<string>("");
  const [resetPage, setResetPage] = useState<boolean>(false);

  const userRoute = useMatch("/admin/users/:action/:userId");

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [currentselectedUser, setCurrentselectedUser] = useState(-1);

  let users: UserInfo[] = useAppSelector(userInfoSelectors.selectAll).filter(
    (userInfo) => userInfo.id.toString() != localStorage.getItem("customerId")
  );

  const selectedUser: UserInfo =
    useAppSelector((state) =>
      userInfoSelectors.selectById(state, currentselectedUser)
    ) ?? ({} as UserInfo);
  const userSliceStatus = useAppSelector(selectUsersInfoSliceStatus);
  const userSliceReasonCode = useAppSelector(selectUsersInfoSliceReasonCode);
  const isActive = selectedUser.active;

  const usersSlicePages = useAppSelector(selectUsersInfoSlicePage);

  const usersSliceTotalElements = useAppSelector(
    selectUsersInfoSliceTotalElements
  );

  // loader for PageCount
  const isLoading = userSliceStatus === "loading";

  const isUsersIdle = userSliceStatus === "idle";
  const [queryParamsChanged, setQueryParamsChanged] = useState<boolean>(false);

  useEffect(() => {
    if (queryParamsFromFilterBar) {
      setQueryParamsChanged(true);
    }
  }, [queryParamsFromFilterBar]);

  useEffect(() => {
    if (queryParamsChanged && isUsersIdle) {
      setQueryParamsChanged(false);
      setResetPage(true);
    } else {
      setResetPage(false);
    }
  }, [isUsersIdle, queryParamsChanged]);

  useEffect(() => {
    if (userRoute !== null) {
      if (userRoute.params.userId) {
        if (
          userRoute.params.action === "activate" ||
          userRoute.params.action === "deactivate"
        ) {
          store.dispatch(
            getUserInfoAsync({ id: parseInt(userRoute.params.userId) })
          );
          setCurrentselectedUser(Number(userRoute.params.userId));
          setShowConfirmation(true);
        }
      }
    }
  }, [navigate, userRoute]);

  useEffect(() => {
    if (userSliceStatus === "idle") {
      if (userSliceReasonCode === GTFleetSuccessCodes.PATCH) {
        if (userRoute?.params?.action === "activate") {
          ToastNotification({
            toastId: "userActivateSuccess",
            status: "success",
            description: t("admin.user.activateUser.success"),
          });
          onClose();
        } else if (userRoute?.params?.action === "deactivate") {
          ToastNotification({
            toastId: "userDeactivateSuccess",
            status: "success",
            description: t("admin.user.deactivateUser.success"),
          });
          onClose();
        }
      }
    }
    if (userSliceStatus === "failed") {
      switch (userSliceReasonCode) {
        case GTFleetErrorCodes.USERNAME_NOT_FOUND:
          if (userRoute?.params?.action === "activate") {
            ToastNotification({
              toastId: "userActivateError",
              status: "error",
              description: t("admin.user.activateUser.error"),
            });
            onClose();
          } else if (userRoute?.params?.action === "deactivate") {
            ToastNotification({
              toastId: "userDeactivateError",
              status: "error",
              description: t("admin.user.deactivateUser.error"),
            });
            onClose();
          } else {
            ToastNotification({
              toastId: "userNotFoundError",
              status: "error",
              description: t("common.userNotFoundError"),
            });
          }
          break;
        case GTFleetErrorCodes.USER_UNAUTHORIZED:
          ToastNotification({
            toastId: "unauthorizedError",
            status: "error",
            description: t("common.unauthorizedError"),
          });
          break;
        default:
          ToastNotification({
            toastId: "networkError",
            status: "error",
            description: t("common.networkError"),
          });
          break;
      }
    }
  }, [userSliceStatus, userSliceReasonCode, navigate]);

  tableData.columns = tableBuilder.setColumns(
    tableSchemaFix
      ? tableSchemaFix?.map((element) => {
          return {
            name: element,
            sort: element === "user",
          };
        })
      : []
  );

  tableData.rows =
    userSliceStatus === "idle" &&
    tableData.columns &&
    tableData?.columns?.length > 0
      ? users
          .filter(
            (user) =>
              user.securityProfile.name != "HS_INTEGRATION" &&
              user.securityProfile.name != "TENANT_MANAGER"
          )
          .map((user: UserInfo) => {
            return tableBuilder.rowsBuilder(
              tableData.columns,
              {
                user,
              },
              preferencesContext,
              navigate,
              permissions.users.write
            );
          })
      : tableData.rows ?? [];

  useEffect(() => {
    document.title = t("navigation.adminMenu.permissions") + " - Admin";
    return () => {
      store.dispatch(usersInfoEmptyState());
    };
  }, []);

  function onClose() {
    setShowConfirmation(false);
    setCurrentselectedUser(-1);
    navigate({
      pathname: "/admin/users",
      search: queryParamsFromFilterBar,
    });
  }

  return (
    <>
      <PageFilters>
        <div className="col col-16">
          <AdminUserFilterBar
            entityPermissions={{
              read: permissions.users.read,
              write: permissions.users.write,
            }}
            callback={setQueryParamsFromFilterBar}
          />
        </div>
      </PageFilters>
      <PageContent>
        <div className="col">
          <ModalWrapper open={showConfirmation} closeAction={onClose}>
            <ModalBody
              title={
                isActive
                  ? t("admin.user.deactivateUser.title")
                  : t("admin.user.activateUser.title")
              }
              desc={
                isActive
                  ? t("admin.user.deactivateUser.desc").replace(
                      "*name",
                      `${selectedUser.firstName} ${selectedUser.secondName}`
                    )
                  : t("admin.user.activateUser.desc").replace(
                      "*name",
                      `${selectedUser.firstName} ${selectedUser.secondName}`
                    )
              }
              isScrollable={false}
            />
            <ModalFooter
              primaryAction={() => {
                store.dispatch(
                  updateUserInfoAsync({
                    id: selectedUser.id,
                    userInfo: { active: !isActive } as UserInfo,
                  })
                );
              }}
              primaryLabel={t("common.confirm")}
              secondaryAction={onClose}
              secondaryLabel={t("common.cancel")}
            />
          </ModalWrapper>
          {tableData?.rows?.length > 0 && (
            <>
              <div className="users-table">
                <Table data={tableData}>
                  <Table.Head hasTableSpace={true} />
                  <Table.Body />
                </Table>
              </div>
              <div className="users-pagination">
                <PageCounter
                  isActionPerforming={isLoading}
                  totalElements={usersSliceTotalElements}
                  resetPage={resetPage}
                  disabled={!isUsersIdle}
                  numOfPages={usersSlicePages}
                  onClick={(id, currentPage) => {
                    if (id !== currentPage) {
                      const pageAndSize = getQueryString({
                        page: id - 1,
                        size: "10",
                      });
                      const finalQueryParams = queryParamsFromFilterBar
                        ? queryParamsFromFilterBar +
                          pageAndSize.replace("?", "&")
                        : pageAndSize;
                      store.dispatch(
                        getFilteredUsersInfoPaginationAsync({
                          queryParams: finalQueryParams,
                        })
                      );
                    }
                  }}
                />
              </div>
            </>
          )}
        </div>
      </PageContent>
    </>
  );
};
