import {
  PayloadAction,
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import _ from "lodash";
import { normalize } from "normalizr";
import { RootState } from "../../app/store";
import { Config } from "../../config/Config";
import { GTFleetSuccessCodes } from "../../config/GTFleetSuccessCodes";
import { routeSchema } from "../report/gpsData/GPSDataNormalization";
import RouteRepository from "./routeRepository";

//#region Type

export type Route = {
  id: number;
  gpsData: GpsData[];
};

export type GpsData = {
  dynamicFields: {
    address: string;
    speed: number;
    odometer: number;
    direction: number;
    longitude: number;
    latitude: number;
  };
  routeId: number;
  driverId: number | null;
  gpsdataByRouteTypeKey: {
    vehicleId: number;
    routeType: string;
    timestamp: string;
  };
  fuel: number | null;
};

//#endregion Type

//#region API

export const getRoutesAsync = createAsyncThunk(
  "routes/getRoutesAsync",
  async (
    data: { vehicleId: number; queryParams?: string },
    { rejectWithValue }
  ) => {
    try {
      const travelsRepository = new RouteRepository();
      const response = await travelsRepository.getRoutes(
        data.vehicleId,
        data.queryParams
      );
      const gpsDatas = _.get(response, Config.GPS_DATA_RESPONSE_PATH);
      const normalizedData = normalize(gpsDatas, routeSchema);

      return normalizedData.entities;
    } catch (error: any) {
      if (!error.response) throw error;
      return rejectWithValue(error.response.status);
    }
  }
);

//#endregion API

const routesAdapter = createEntityAdapter<Route>({
  selectId: (route) => route.id,
  sortComparer: (a, b) => a.id.toString().localeCompare(b.id.toString()),
});

//#region Custom Functions
function setFilteredData(state: any, action: PayloadAction<any>) {
  action.payload.gpsDatas
    ? routesAdapter.setAll(state, action.payload.gpsDatas)
    : routesAdapter.setAll(state, []);
}
//#endregiorn Custom Functions

//#region Slice

export const routesSlice = createSlice({
  name: "routes",
  initialState: routesAdapter.getInitialState({
    status: "idle",
    reasonCode: "",
  }),
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    routesEmptyState: (state: any) => {
      routesAdapter.setAll(state, []);
      state.reasonCode = "";
      state.status = "idle";
    },
  },
  extraReducers: (builder) => {
    builder
      //#region Entity Reducers
      // getRoutesHistoryAsync extra reducers
      .addCase(
        getRoutesAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          setFilteredData(state, action);
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.GET;
        }
      )
      .addCase(getRoutesAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(getRoutesAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = "";
      });
  },
});
//#endregion Slice

//#region Status
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const gpsDataSelectors = routesAdapter.getSelectors<RootState>(
  (state) => state.routes
);

export const selectRouteSliceStatus = (state: any) => state.routes.status;
export const selectRouteSliceReasonCode = (state: any) =>
  state.routes.reasonCode;
//#endregion Status

export const { routesEmptyState } = routesSlice.actions;

export default routesSlice.reducer;
