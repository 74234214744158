import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import {
  Location,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { store } from "../../app/store";
import { getQueryString } from "../../utils/Utils";
import { fleetViewsSelectors } from "../fleet/fleetViewsSlice";
import {
  Vehicle,
  getFilteredVehiclesDetailsAsync,
  selectVehiclesSliceReasonCode,
  selectVehiclesSliceStatus,
  vehiclesEmptyState,
  vehiclesSelectors,
} from "../vehicle/vehiclesSlice";
import "./DashboardTachographFilesStatusFilterBar.css";

import { t } from "i18next";
import { Button } from "../../ui/Button/Button";
import { DriversDropdown } from "../../ui/Forms/DriversDropdown";
import { VehiclesDropdown } from "../../ui/Forms/VehiclesDropdown";
import { ToastNotification } from "../../utils/ToastNotification";
import {
  Driver,
  driversEmptyState,
  driversSelectors,
} from "../driver/driversSlice";
import {
  getFilteredDriversStatusAndDetailsAsync,
  selectDriverStatusSliceReasonCode,
  selectDriverStatusSliceStatus,
} from "../driver/driversStatusSlice";
import { TachographFileType } from "./tachographFilesSlice";
import {
  getTachographFilesStatusAsync,
  selectTachographFilesStatusSliceReasonCode,
  selectTachographFilesStatusSliceStatus,
} from "./tachographFilesStatusSlice";
import {
  getTachographFilesStatusSummaryAsync,
  selectTachographFilesStatusSummarySliceReasonCode,
  selectTachographFilesStatusSummarySliceStatus,
} from "./tachographFilesStatusSummarySlice";

interface QueryParams {
  [paramName: string]: any;
}

interface DashboardTachographFilesStatusFilterBarProps {
  callback: (buildQueryParam: string) => any;
  type: TachographFileType;
}

export const DashboardTachographFilesStatusFilterBar: React.FC<DashboardTachographFilesStatusFilterBarProps> =
  ({ callback, type }) => {
    //#region Initialization
    const navigate = useNavigate();

    useEffect(() => {
      if (type === "DRIVER") {
        store.dispatch(getFilteredDriversStatusAndDetailsAsync(""));
      }
      store.dispatch(getFilteredVehiclesDetailsAsync(""));
      return () => {
        store.dispatch(vehiclesEmptyState());
        store.dispatch(driversEmptyState());
      };
    }, []);
    //#endregion Initialization

    //#region Data
    const vehicles: Vehicle[] = useAppSelector(vehiclesSelectors.selectAll);
    const drivers: Driver[] = useAppSelector(driversSelectors.selectAll);
    //#endregion Data

    //#region Status
    const tachographFilesStatusSummarySliceStatus = useAppSelector(
      selectTachographFilesStatusSummarySliceStatus
    );
    const tachographFilesStatusSummarySliceReasonCode = useAppSelector(
      selectTachographFilesStatusSummarySliceReasonCode
    );

    const tachographFilesStatusSliceStatus = useAppSelector(
      selectTachographFilesStatusSliceStatus
    );
    const tachographFilesStatusSliceReasonCode = useAppSelector(
      selectTachographFilesStatusSliceReasonCode
    );

    const vehicleSliceStatus = useAppSelector(selectVehiclesSliceStatus);
    const vehicleSliceReasonCode = useAppSelector(
      selectVehiclesSliceReasonCode
    );

    const driverStatusSliceStatus = useAppSelector(
      selectDriverStatusSliceStatus
    );
    const driverStatusSliceReasonCode = useAppSelector(
      selectDriverStatusSliceReasonCode
    );

    useEffect(() => {
      if (
        tachographFilesStatusSummarySliceStatus === "failed" &&
        tachographFilesStatusSummarySliceReasonCode === ""
      ) {
        ToastNotification({
          toastId: "networkError",
          status: "error",
          description: t("common.networkError"),
        });
      }
    }, [
      tachographFilesStatusSummarySliceStatus,
      tachographFilesStatusSummarySliceReasonCode,
    ]);

    useEffect(() => {
      if (
        tachographFilesStatusSliceStatus === "failed" &&
        tachographFilesStatusSliceReasonCode === ""
      ) {
        ToastNotification({
          toastId: "networkError",
          status: "error",
          description: t("common.networkError"),
        });
      }
    }, [
      tachographFilesStatusSliceStatus,
      tachographFilesStatusSliceReasonCode,
    ]);

    useEffect(() => {
      if (vehicleSliceStatus === "failed" && vehicleSliceReasonCode === "") {
        ToastNotification({
          toastId: "networkError",
          status: "error",
          description: t("common.networkError"),
        });
      }
    }, [vehicleSliceStatus, vehicleSliceReasonCode]);

    useEffect(() => {
      if (
        driverStatusSliceStatus === "failed" &&
        driverStatusSliceReasonCode === ""
      ) {
        ToastNotification({
          toastId: "networkError",
          status: "error",
          description: t("common.networkError"),
        });
      }
    }, [driverStatusSliceStatus, driverStatusSliceReasonCode]);

    const isIdle =
      tachographFilesStatusSummarySliceStatus === "idle" &&
      tachographFilesStatusSliceStatus === "idle" &&
      vehicleSliceStatus === "idle" &&
      driverStatusSliceStatus === "idle";
    //#endregion Status

    //#region Search
    const [entityIds, setEntityIds] = useState<number[]>([]);
    const [entityList, setEntityList] = useState<Vehicle[] | Driver[]>([]);

    const queryParamsRef = useRef<QueryParams>({});
    let queryParams: QueryParams = queryParamsRef.current;

    const handleChanges = (
      params: Map<string, string[] | string>,
      executeQuery: boolean = true
    ): void => {
      params.forEach((value, key) => {
        if (value && value.length > 0) {
          queryParams[key] = value;
        } else {
          if (queryParams.hasOwnProperty(key)) {
            delete queryParams[key];
          }
        }
      });
      const stringifiedQueryParams = getQueryString(queryParams);
      navigate(`${window.location.pathname}${stringifiedQueryParams}`);
      if (executeQuery) {
        GenerateResult();
      }
    };

    const GenerateResult = () => {
      const stringifiedQueryParams = getQueryString(queryParams);
      GenerateResultWithParams(stringifiedQueryParams);
    };

    const GenerateResultWithParams = (queryString: string) => {
      store.dispatch(
        getTachographFilesStatusSummaryAsync({
          queryParams: queryString,
        })
      );
      store.dispatch(
        getTachographFilesStatusAsync({
          queryParams: queryString,
          page: 0,
        })
      );
      callback(queryString);
    };

    const [searchParams] = useSearchParams();
    const location: Location = useLocation();

    useEffect(() => {
      const map = new Map();
      const currentSearchParams =
        searchParams.toString() !== "" ? searchParams : null;
      if (!!currentSearchParams && _.isEmpty(queryParams)) {
        map.set("type", type);
        const typeParams = currentSearchParams.get("type");
        if (typeParams === type) {
          const entityIds = currentSearchParams.getAll("entityIds");
          if (entityIds) {
            if (entityIds?.length > 0) {
              setEntityIds(entityIds.map((entityId) => parseInt(entityId)));
              map.set("entityIds", entityIds);
            }
          }
        }
      } else if (_.isEmpty(queryParams)) {
        map.set("type", type);
      }
      if (map.size > 0) {
        handleChanges(map);
      }
    }, [location]);

    /**
     * This useEffect takes the vehicles identifiers retrieved from the URL and based
     * on the downloaded vehicles details builds the vehicle list (vehicleList hook).
     */
    useEffect(() => {
      if (entityIds && entityIds.length > 0) {
        if (type === "DRIVER") {
          if (
            driverStatusSliceStatus === "idle" &&
            drivers.length > 0 &&
            entityList.length === 0
          ) {
            const selectedDrivers: Driver[] = [];
            entityIds.forEach((driverId: number) => {
              const selectedDriver =
                driversSelectors.selectById(store.getState(), driverId ?? -1) ??
                ({} as Driver);
              selectedDriver &&
                !_.isEmpty(selectedDriver) &&
                selectedDrivers.push(selectedDriver);
            });
            setEntityList(selectedDrivers);
          }
        } else {
          if (
            vehicleSliceStatus === "idle" &&
            vehicles.length > 0 &&
            entityList.length === 0
          ) {
            const selectedVehicles: Vehicle[] = [];
            entityIds.forEach((vehicleId: number) => {
              const selectedVehicle =
                vehiclesSelectors.selectById(
                  store.getState(),
                  vehicleId ?? -1
                ) ?? ({} as Vehicle);
              selectedVehicle &&
                !_.isEmpty(selectedVehicle) &&
                selectedVehicles.push(selectedVehicle);
            });
            setEntityList(selectedVehicles);
          }
        }
      }
    }, [entityIds, vehicleSliceStatus, driverStatusSliceStatus]);

    function resetParams() {
      const map = new Map();
      map.set("entityId", null);
      map.set("type", type);
      handleChanges(map);
    }
    //#endregion Search

    //#region Vehicle
    let vehiclesGroupByFleet = vehicles.reduce(
      (group: any, vehicle: Vehicle) => {
        const { fleet } = vehicle;
        const fleetName = fleetViewsSelectors.selectById(
          store.getState(),
          fleet
        )?.name;
        if (fleetName) {
          group[fleetName] = group[fleetName] ?? [];
          group[fleetName].push(vehicle);
        }
        return group;
      },
      {}
    );

    let vehiclesfleetNames = Object.keys(vehiclesGroupByFleet);

    let vehiclesOptions = vehiclesfleetNames?.map(
      (vehicleFleetName: string) => ({
        label: vehicleFleetName,
        hasCheckbox: true,
        hasDropdown: true,
        hasCount: true,
        vehicles: vehiclesGroupByFleet[vehicleFleetName],
      })
    );

    //#endregion Vehicle

    //#region Driver
    let driversGroupByFleet = drivers.reduce((group: any, driver: Driver) => {
      const { fleet } = driver;
      const fleetName = fleetViewsSelectors.selectById(
        store.getState(),
        fleet
      )?.name;
      if (fleetName) {
        group[fleetName] = group[fleetName] ?? [];
        group[fleetName].push(driver);
      }
      return group;
    }, {});

    let driversFleetNames = Object.keys(driversGroupByFleet);

    let driversOptions = driversFleetNames?.map((driverFleetName: string) => ({
      label: driverFleetName,
      hasCheckbox: true,
      hasDropdown: true,
      hasCount: true,
      drivers: driversGroupByFleet[driverFleetName],
    }));
    //#endregion Driver

    return (
      <div className="tachograph-file-filterbar-row">
        {type === "DRIVER" ? (
          <div className="tachograph-file-filter-drivers-dropdown">
            <DriversDropdown
              loading={driverStatusSliceStatus === "loading"}
              options={driversOptions}
              driversLimit={20}
              getValueSelected={(values: Driver[]) => {
                if (!_.isEqual(values, entityList)) {
                  const map = new Map();
                  if (values && values?.length > 0) {
                    map.set(
                      "entityIds",
                      values.map((driver) => driver.id)
                    );
                    setEntityList(values);
                  } else {
                    map.set("entityIds", []);
                    setEntityList([]);
                  }
                  handleChanges(map, false);
                }
              }}
              valueSelected={entityList as Driver[]}
            />
          </div>
        ) : (
          <div className="tachograph-file-filter-vehicles-dropdown">
            <VehiclesDropdown
              loading={vehicleSliceStatus === "loading"}
              options={vehiclesOptions}
              vehiclesLimit={20}
              getValueSelected={(values: Vehicle[]) => {
                if (!_.isEqual(values, entityList)) {
                  const map = new Map();
                  if (values && values?.length > 0) {
                    map.set(
                      "entityIds",
                      values.map((vehicle) => vehicle.id)
                    );
                    setEntityList(values);
                  } else {
                    map.set("entityIds", []);
                    setEntityList([]);
                  }
                  handleChanges(map, false);
                }
              }}
              valueSelected={entityList as Vehicle[]}
            />
          </div>
        )}
        <div className="tachograph-file-filter-btn-generate">
          <Button
            aspect="primary"
            size="small"
            label={t("pages.dashboardTachographFile.filterBar.buttonGenerate")}
            onClick={() => {
              GenerateResult();
            }}
            isLoading={!isIdle}
          />
        </div>
      </div>
    );
  };
