import dayjs from "dayjs";
import { t } from "i18next";
import _ from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppSelector } from "../../../app/hooks";
import { store } from "../../../app/store";
import { GTFleetErrorCodes } from "../../../config/GTfleetErrorCodes";
import Form from "../../../ui/Forms/Form";
import { SingleDatePicker } from "../../../ui/Forms/SingleDatePicker";
import TextField from "../../../ui/Forms/TextField";
import { IconDollar } from "../../../ui/Icon/Line/Dollar";
import { IconEuro } from "../../../ui/Icon/Line/Euro";
import { IconPound } from "../../../ui/Icon/Line/Pound";
import { IconUpload } from "../../../ui/Icon/Line/Upload";
import { LabelText } from "../../../ui/LabelText/LabelText";
import { ModalBody } from "../../../ui/Modal/ModalBody";
import { ModalFooter } from "../../../ui/Modal/ModalFooter";
import { ModalWrapper } from "../../../ui/Modal/ModalWrapper";
import { ToastNotification } from "../../../utils/ToastNotification";
import {
  addZeroes,
  kmToMt,
  milesToMt,
  mtToKm,
  mtToMiles,
  numberAnnotation,
} from "../../../utils/Utils";
import { Preferences } from "../../users/preference/preferencesSlice";
import UserContext from "../../users/userContext";
import {
  getVehicleAsync,
  selectVehiclesStatusSliceReasonCode,
  selectVehiclesStatusSliceStatus,
} from "../../vehicle/vehiclesStatusSlice";
import {
  Deadline,
  updateDeadlineAsync,
  uploadFileDeadlineAsync,
} from "./../deadlinesSlice";
import "./CompletedMaintenanceModal.css";

interface CompletedMaintenanceModalProps {
  type: string;
  deadlineState: Deadline;
  open: boolean;
  onClose: () => any;
  deadlineId: number;
  deadlineStatusType: string;
  deadlineType: string;
  vehicleId: number;
  fleetId: number;
}

export const CompletedMaintenanceModal: React.FC<CompletedMaintenanceModalProps> =
  ({
    type,
    deadlineState,
    open,
    onClose,
    deadlineId,
    deadlineStatusType,
    deadlineType,
    vehicleId,
    fleetId,
  }) => {
    //#region const
    const [isEditedDistance, setIsEditedDistance] = useState<boolean>(false);
    const [isEditedDate, setIsEditedDate] = useState<boolean>(false);
    const [isEditedAmount, setIsEditedAmount] = useState<boolean>(false);
    const [isEditedUploadFile, setIsEditedUploadFile] =
      useState<boolean>(false);
    const [isEditedNote, setIsEditedNote] = useState<boolean>(false);
    const [fileUpload, setFileUpload] = useState({} as File);
    const [errorList, setErrorList] = useState<string[]>([]);
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const [editCompletedMaintenance, setEditCompletedMaintenance] = useState(
      {} as Partial<Deadline>
    );

    const vehicleStatusSliceStatus = useAppSelector(
      selectVehiclesStatusSliceStatus
    );
    const vehicleStatusSliceReasonCode = useAppSelector(
      selectVehiclesStatusSliceReasonCode
    );

    const valueSelectedInitialKM = deadlineState.currentOdometer ?? 0;
    const valueSelectedInitialUtilization =
      deadlineState.currentUtilizationMinutes ?? 0;

    const [value, setValue] = useState<string>("");
    const MAX_VAL = 1000000;
    const withValueLimit = ({ floatValue }: { floatValue: any }) =>
      floatValue <= MAX_VAL;
    const [preferencesContext]: [Preferences] = useContext(UserContext);
    dayjs.locale(preferencesContext.language ?? "en");
    //#endregion const

    useEffect(() => {
      if (
        vehicleStatusSliceStatus === "failed" &&
        vehicleStatusSliceReasonCode ===
          GTFleetErrorCodes.VEHICLE_STATUS_NOT_FOUND
      ) {
        console.error(t("common.vehicleStatusError"));
        ToastNotification({
          toastId: "vehicleStatusError",
          status: "error",
          description: t("common.vehicleStatusError"),
        });
      } else if (
        vehicleStatusSliceStatus === "failed" &&
        vehicleStatusSliceReasonCode === ""
      ) {
        ToastNotification({
          toastId: "networkError",
          status: "error",
          description: t("common.networkError"),
        });
      }
    }, [vehicleStatusSliceStatus, vehicleStatusSliceReasonCode]);

    //#region methods for updateDeadline after confirm
    useEffect(() => {
      setEditCompletedMaintenance({
        currentOdometer: deadlineState.currentOdometer ?? 0,
        expirationDate: new Date(),
        amount: deadlineState.amount ?? 0,
        uploadFile: deadlineState.uploadFile ?? "",
        note: deadlineState.note ?? "",
      } as Partial<Deadline>);
      store.dispatch(getVehicleAsync({ id: vehicleId, fleetId: fleetId }));
    }, []);

    const handleSubmit = () => {
      const expirationDate =
        editCompletedMaintenance?.expirationDate &&
        new Date(editCompletedMaintenance.expirationDate);
      expirationDate?.setHours(8);
      const deadlineToUpdate: Partial<Deadline> = {
        ...editCompletedMaintenance,
        uploadFile: fileUpload.name,
        complete: true,
        status: null,
        note: editCompletedMaintenance.note,
        expirationDate: editCompletedMaintenance.expirationDate,
        amount: editCompletedMaintenance.amount,
      };
      store.dispatch(
        updateDeadlineAsync({
          id: deadlineId,
          deadline: deadlineToUpdate,
        })
      );
      !!fileUpload.name &&
        store.dispatch(
          uploadFileDeadlineAsync({
            file: fileUpload,
            type: `Maintenance/Vehicles/${vehicleId}`,
          })
        );
    };
    //#endregion methods for updateDeadline after confirm

    //#region methods for uploadFile
    const [originalFileName, setOriginalFileName] = useState("");
    const change = (event: any) => {
      setOriginalFileName(event.target.files[0].name);

      let renameFile = event.target.files[0].slice(
        0,
        event.target.files[0].size,
        event.target.files[0].type
      );
      let newFileUpload = new File(
        [renameFile],
        `${vehicleId}_${deadlineType}_${new Date().toISOString()}${event.target.files[0].name.slice(
          event.target.files[0].name.lastIndexOf(".")
        )}`, //rename the file upload with vehicleID, type of maintenance, today date and format of file
        { type: event.target.files[0].type }
      );
      setFileUpload(newFileUpload);

      setIsEditedUploadFile(true);
      setEditCompletedMaintenance({
        ...editCompletedMaintenance,
        uploadFile: event.target.files[0].name,
      });
    };

    const click = (ev: any) => {
      ev.target.value = null;
    };
    //#endregion methods for uploadFile

    return (
      <div className="completed-maintenance-modal">
        <ModalWrapper open={open} closeAction={onClose}>
          <ModalBody
            title={`${type} ${t("customModals.completedMaintenance.title")}`}
            desc={
              deadlineState.isMaintenance
                ? t("customModals.completedMaintenance.descMaintenance")
                : t("customModals.completedMaintenance.descDeadline")
            }
          >
            {deadlineState.isMaintenance && (
              <div className="completed-maintenance-odometer">
                {deadlineState.currentUtilizationMinutes ? (
                  <Form>
                    <div className="left-forms-first">
                      <LabelText
                        text={`${t(
                          "customModals.completedMaintenance.utilization"
                        )} ${"(h)"}`}
                        label={""}
                      />
                    </div>
                    <div className="right-forms">
                      <TextField
                        id="utilizationTime"
                        type="text"
                        placeholder="0"
                        maxLength="6"
                        formattedNumber={true}
                        events={{
                          onChange: (data) => {
                            if (
                              data.value !==
                              deadlineState.currentUtilizationMinutes.toString()
                            ) {
                              setIsEditedDistance(true);
                              setEditCompletedMaintenance({
                                ...editCompletedMaintenance,
                                currentUtilizationMinutes: data.value,
                              });
                            } else {
                              setIsEditedDistance(false);
                              setEditCompletedMaintenance({
                                ...editCompletedMaintenance,
                                currentUtilizationMinutes:
                                  deadlineState.currentUtilizationMinutes, // restoring the initial value
                              });
                            }
                          },
                        }}
                        value={numberAnnotation(
                          editCompletedMaintenance.currentUtilizationMinutes ??
                            valueSelectedInitialUtilization
                        )}
                        errorList={setErrorList}
                      />
                    </div>
                  </Form>
                ) : (
                  <Form>
                    <div className="left-forms-first">
                      <LabelText
                        text={`${t(
                          "customModals.completedMaintenance.odometer"
                        )} ${preferencesContext.isMetric ? "(km)" : "(mil)"}`}
                        label={""}
                      />
                    </div>
                    <div className="right-forms">
                      <TextField
                        id="kmTraveled"
                        type="text"
                        placeholder="0"
                        maxLength="8"
                        formattedNumber={true}
                        events={{
                          onChange: (data) => {
                            if (
                              data.value !==
                              deadlineState.currentOdometer.toString()
                            ) {
                              setIsEditedDistance(true);
                              setEditCompletedMaintenance({
                                ...editCompletedMaintenance,
                                currentOdometer: (preferencesContext.isMetric
                                  ? kmToMt(data.value)
                                  : milesToMt(data.value)
                                )
                                  .toLocaleString()
                                  .replace(/\D/g, ""), // setting the updated value
                              });
                            } else {
                              setIsEditedDistance(false);
                              setEditCompletedMaintenance({
                                ...editCompletedMaintenance,
                                currentOdometer: deadlineState.currentOdometer, // restoring the initial value
                              });
                            }
                          },
                        }}
                        value={numberAnnotation(
                          preferencesContext.isMetric
                            ? mtToKm(
                                editCompletedMaintenance.currentOdometer ??
                                  valueSelectedInitialKM
                              )
                            : mtToMiles(
                                editCompletedMaintenance.currentOdometer ??
                                  valueSelectedInitialKM
                              )
                        )}
                        errorList={setErrorList}
                      />
                    </div>
                  </Form>
                )}
              </div>
            )}
            <div className="completed-maintenance-date">
              <Form>
                <div className="left-forms-second">
                  <LabelText
                    text={
                      deadlineState.isMaintenance
                        ? t("customModals.completedMaintenance.secondRowMaint")
                        : t("customModals.completedMaintenance.secondRowDead")
                    }
                    label={""}
                  />
                </div>
                <div className="right-forms">
                  <SingleDatePicker
                    id="maintenanceDeadline"
                    setErrorList={setErrorList}
                    errorList={errorList}
                    dateRange={[dayjs().subtract(1, "year"), dayjs()]}
                    setDate={(data) => {
                      if (
                        data !==
                        dayjs(deadlineState.expirationDate).format("YYYY-MM-DD")
                      ) {
                        setIsEditedDate(true);
                        setEditCompletedMaintenance({
                          ...editCompletedMaintenance,
                          expirationDate: data, // setting the updated value
                        });
                      } else {
                        setIsEditedDate(false);
                        setEditCompletedMaintenance({
                          ...editCompletedMaintenance,
                          expirationDate: deadlineState.expirationDate, // restoring the initial value
                        });
                      }
                    }}
                    placeholder=""
                    oldDate={dayjs(new Date()).format("YYYY-MM-DD")}
                    localeFormat={
                      preferencesContext.localeFormat ?? "DD / MM / YYYY"
                    }
                    language={preferencesContext.language ?? "it"}
                  />
                </div>
              </Form>
            </div>
            <div className="completed-maintenance-amount">
              <Form>
                <div className="left-forms-first">
                  <LabelText
                    text={t("customModals.completedMaintenance.amount")}
                    label={""}
                  />
                </div>
                <div
                  className={
                    deadlineState.isMaintenance === true
                      ? "right-forms-maintenance"
                      : "right-forms-deadline"
                  }
                >
                  <NumericFormat
                    id="amount"
                    placeholder={
                      preferencesContext.language === "it" ? "0,00" : "0.00"
                    }
                    value={value}
                    isAllowed={withValueLimit}
                    thousandSeparator={
                      preferencesContext.language === "it" ? "." : ","
                    }
                    decimalSeparator={
                      preferencesContext.language === "it" ? "," : "."
                    }
                    decimalScale={2}
                    valueIsNumericString={true}
                    onValueChange={(values) => {
                      setValue(values.value);
                      if (
                        Number(values.value) !== editCompletedMaintenance.amount
                      ) {
                        setIsEditedAmount(true);
                        setEditCompletedMaintenance({
                          ...editCompletedMaintenance,
                          amount: Number(values.value), // setting the updated value
                        });
                      } else {
                        setIsEditedAmount(false);
                        setEditCompletedMaintenance({
                          ...editCompletedMaintenance,
                          amount: Number(deadlineState.amount), // restoring the initial value
                        });
                      }
                    }}
                    onBlur={() => {
                      addZeroes(value, setValue, preferencesContext);
                    }}
                  />
                  {preferencesContext.currency === "EURO" ? (
                    <IconEuro
                      size={14}
                      color="--global-colors-ink-light"
                      id="iconEuro"
                    />
                  ) : preferencesContext.currency === "DOLLAR" ? (
                    <IconDollar
                      size={14}
                      color="--global-colors-ink-light"
                      id="iconDollar"
                    />
                  ) : (
                    <IconPound
                      size={14}
                      color="--global-colors-ink-light"
                      id="iconPound"
                    />
                  )}
                </div>
              </Form>
            </div>
            <div className="completed-maintenance-upload">
              <Form>
                <div className="left-forms-first">
                  <LabelText
                    text={t("customModals.completedMaintenance.uploadInvoice")}
                    label={""}
                  />
                </div>
                <>
                  <div className="right-forms">
                    <TextField
                      id="upload"
                      autoComplete="off"
                      type="text"
                      placeholder={t(
                        "customModals.completedMaintenance.uploadFile"
                      )}
                      events={{
                        onClick: () => {
                          document.getElementById("inputFile")?.click();
                        },
                      }}
                      value={
                        editCompletedMaintenance.uploadFile
                          ? editCompletedMaintenance.uploadFile
                          : originalFileName
                      }
                    >
                      <IconUpload size={14} color="--global-colors-ink-light" />
                    </TextField>
                    {!_.isEmpty(editCompletedMaintenance.uploadFile) && (
                      <span className="tooltiptext">
                        {originalFileName !== ""
                          ? originalFileName
                          : editCompletedMaintenance.uploadFile}
                      </span>
                    )}
                  </div>
                  <input
                    id="inputFile"
                    type="file"
                    autoComplete="off"
                    accept="image/png, image/jpeg, image/jpg, image/gif, application/pdf"
                    style={{ display: "none" }}
                    onChange={change}
                    onClick={click}
                  />
                </>
              </Form>
            </div>
            <div className="completed-maintenance-note">
              <Form>
                <div className="left-forms">
                  <LabelText text="Note" label={""} />
                </div>
                <div className="right-forms">
                  <TextField
                    id="notes"
                    type="textarea"
                    placeholder={t("customModals.completedMaintenance.notes")}
                    events={{
                      onChange: (data) => {
                        if (data.value !== deadlineState.note) {
                          setIsEditedNote(true);
                          setEditCompletedMaintenance({
                            ...editCompletedMaintenance,
                            note: data.value, // setting the updated value
                          });
                        } else {
                          setIsEditedNote(false);
                          setEditCompletedMaintenance({
                            ...editCompletedMaintenance,
                            note: deadlineState.note, // restoring the initial value
                          });
                        }
                      },
                    }}
                    value={editCompletedMaintenance.note ?? ""}
                    errorList={setErrorList}
                    disabled={false}
                  />
                </div>
              </Form>
            </div>
          </ModalBody>
          <ModalFooter
            primaryLabel={
              deadlineStatusType === "COMPLETED"
                ? t("common.save")
                : t("customModals.completedMaintenance.primaryLabel")
            }
            primaryAction={() => {
              const isKMRecurring =
                !!editCompletedMaintenance?.rescheduleMeters &&
                editCompletedMaintenance?.rescheduleMeters > 0;
              const isTimeRecurring =
                !!editCompletedMaintenance?.rescheduleMonths &&
                editCompletedMaintenance?.rescheduleMonths > 0;
              if (isKMRecurring || isTimeRecurring) {
                navigate(
                  {
                    pathname: "/dashboard/maintenance/recurrence-deadline",
                    search: searchParams.toString(),
                  },
                  {
                    state: {
                      deadlineStatus: {
                        ...deadlineState,
                        expirationDate: editCompletedMaintenance.expirationDate,
                        expirationMeters: deadlineState.rescheduleMeters,
                      },
                      fleetId: fleetId,
                      vehicleId: vehicleId,
                    },
                  }
                );
              }
              handleSubmit();
              onClose();
            }}
            disablePrimaryButton={
              deadlineStatusType === "COMPLETED" &&
              !(
                isEditedDistance ||
                isEditedDate ||
                isEditedAmount ||
                isEditedUploadFile ||
                isEditedNote
              )
            }
            secondaryLabel={t(
              "customModals.completedMaintenance.secondaryLabel"
            )}
            secondaryAction={() => {
              onClose();
            }}
          />
        </ModalWrapper>
      </div>
    );
  };
