import {
  createSlice,
  createEntityAdapter,
  PayloadAction,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import DriverTravelsRepository from "./driverTravelRepository";
import _ from "lodash";
import { normalize } from "normalizr";
import { Config } from "../../../config/Config";
import { GTFleetSuccessCodes } from "../../../config/GTFleetSuccessCodes";
import { RootState } from "../../../app/store";
import { driverTravelsMacroSummarySchema } from "./driverTravelNormalization";

export type DriverTravelsMacroSummary = {
  driverId: number;
  droveVehicles: number;
  totalTravels: number;
  totalCosts: number;
  totalTraveled: number;
  totalfuelConsumption: number;
  macroMaxSpeed: number;
  macroAverageSpeed: number;
  totalDrivingTime: number;
  averageParkingTime: number;
  firstTimestamp: Date;
  lastTimestamp: Date;
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const getDriverTravelsMacroSummaryAsync = createAsyncThunk(
  "driverTravelsSummary/getDriverTravelsMacroSummary", //dp: rinominare
  async (data: { driverId: number; queryString?: string }) => {
    const driverTravelsRepository = new DriverTravelsRepository();
    const response = await driverTravelsRepository.getDriverTravelsMacroSummary(
      data.driverId,
      data.queryString
    );
    // // The value we return becomes the `fulfilled` action payload
    const driverTravelsMacroSummary = _.get(
      response,
      Config.DRIVER_TRAVELS_SUMMARY_PATH
    );
    const normalizedData = normalize(
      driverTravelsMacroSummary,
      driverTravelsMacroSummarySchema
    );
    return normalizedData.entities;
  }
);

//#region Custom Functions
function setFilteredData(state: any, action: PayloadAction<any>) {
  action.payload.driverTravelsMacroSummary
    ? driverTravelsMacroSummaryAdapter.setAll(
        state,
        action.payload.driverTravelsMacroSummary
      )
    : driverTravelsMacroSummaryAdapter.setAll(state, []);
}
//#endregiorn Custom Functions

const driverTravelsMacroSummaryAdapter =
  createEntityAdapter<DriverTravelsMacroSummary>({
    selectId: (driverTravelsMacroSummary) => driverTravelsMacroSummary.driverId,
  });

export const driverTravelsMacroSummarySlice = createSlice({
  name: "driverTravelsMacroSummary",
  initialState: driverTravelsMacroSummaryAdapter.getInitialState({
    status: "idle",
    reasonCode: "",
  }),
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    driverTravelsMacroSummaryEmptyState: (state) => {
      driverTravelsMacroSummaryAdapter.setAll(state, []);
      state.reasonCode = "";
      state.status = "idle";
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      // getDriverTravelsSummaryAsync extra reducers
      .addCase(
        getDriverTravelsMacroSummaryAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          setFilteredData(state, action);
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.GET;
        }
      )
      .addCase(getDriverTravelsMacroSummaryAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(getDriverTravelsMacroSummaryAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = "";
      });
  },
});

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const driverTravelsMacroSummarySelectors =
  driverTravelsMacroSummaryAdapter.getSelectors<RootState>(
    (state) => state.driverTravelsMacroSummary
  );

export const selectDriverTravelsMacroSummarySliceStatus = (state: any) =>
  state.driverTravelsMacroSummary.status;
export const selectDriverTravelsMacroSummarySliceReasonCode = (state: any) =>
  state.driverTravelsMacroSummary.reasonCode;

export const { driverTravelsMacroSummaryEmptyState } =
  driverTravelsMacroSummarySlice.actions;

export default driverTravelsMacroSummarySlice.reducer;
