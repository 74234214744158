import HttpClient from "../../../utils/HttpClient";

export default class PublicRouteStopRepository extends HttpClient {
  constructor() {
    super(true);
  }

  public getPublicRouteStops() {
    const tenantId = localStorage.getItem("tenantId");
    return this.instance.get(
      `/tenants/${tenantId}/publicRoutes/publicStops?deleted=false`
    );
  }
}
