import { t } from "i18next";
import { FeedbackModal } from "../../../../ui/Modal/CustomModals/FeedbackModal";
import { NotificationRuleAggregation } from "./../notificationRuleAggregationSlice";

interface DeactivateNotificationRuleAggregationModalProps {
  selectedNotificationRuleAggreagtion: NotificationRuleAggregation;
  open: boolean;
  onClose: (id?: number) => void;
}

const DeactivateNotificationRuleAggregationModal: React.FC<DeactivateNotificationRuleAggregationModalProps> =
  ({ selectedNotificationRuleAggreagtion, open, onClose }) => {
    const nameRuleAggregation = selectedNotificationRuleAggreagtion?.name
      ? selectedNotificationRuleAggreagtion?.name.split(",")[0]
      : t("common.na");

    function deactivateNotification() {
      onClose(selectedNotificationRuleAggreagtion?.id);
    }
    function close() {
      onClose();
    }

    return (
      <FeedbackModal
        open={open}
        title={`${t("admin.notifications.actions.deactivate.title")}`}
        desc={`${t("admin.notifications.actions.deactivate.desc").replace(
          "*name",
          nameRuleAggregation
        )}`}
        primaryLabel={`${t("common.confirm")}`}
        isDanger={false}
        primaryAction={deactivateNotification}
        secondaryLabel={`${t("common.cancel")}`}
        secondaryAction={close}
        closeAction={close}
      />
    );
  };

export default DeactivateNotificationRuleAggregationModal;
