import dayjs from "dayjs";
import { useContext, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { store } from "../../app/store";
import { driversSelectors } from "../../features/driver/driversSlice";
import UserContext from "../../features/users/userContext";
import { vehiclesSelectors } from "../../features/vehicle/vehiclesSlice";
import { DataLabel } from "../../ui/DataLabel/DataLabel";
import { IconAverageSpeed } from "../../ui/Icon/Line/AverageSpeed";
import { IconClock } from "../../ui/Icon/Line/Clock";
import { IconGasStation } from "../../ui/Icon/Line/GasStation";
import { IconKilometers } from "../../ui/Icon/Line/Kilometers";
import { IconParking } from "../../ui/Icon/Line/Parking";
import { IconParkingPoint } from "../../ui/Icon/Line/ParkingPoint";
import { IconSpeed } from "../../ui/Icon/Line/Speed";
import { ModalBody } from "../../ui/Modal/ModalBody";
import { ModalFooter } from "../../ui/Modal/ModalFooter";
import { ModalWrapper } from "../../ui/Modal/ModalWrapper";
import { Table } from "../../ui/Table/Table";
import { formatTime } from "../../utils/DateAndTimeUtils";
import { getQueryString, numberAnnotation } from "../../utils/Utils";
import { RouteStateType } from "../route/routesHistorySlice";
import { Preferences } from "../users/preference/preferencesSlice";
import "./TravelDetailModal.css";
import { TravelDetailModalTableBuilder } from "./TravelDetailModalTableBuilder";
import { TravelDetail } from "./TravelDetailTypes";
import { GPSDataReport } from "./gpsData/gpsDataReportSlice";

const context = "travelDetail";
const tableSchemaFix = [
  { name: "vehicleIdTravelDetail", sort: true },
  { name: "driverTravelDetail" },
  { name: "startTimestamp" },
  { name: "speedTravelDetail" },
  { name: "coveredTravelDetail" },
  { name: "xCoordinateTravelDetail" },
  { name: "yCoordinateTravelDetail" },
];

interface TravelDetailModalData {
  gpsData: GPSDataReport[];
  travel: TravelDetail;
  routeStateType: RouteStateType;
}

interface TravelDetailModalProps {
  open: boolean;
  data: TravelDetailModalData;
  onClose: (e: any) => any;
}

interface TableData {
  columns: { label: string; field: string; sort: boolean }[] | undefined;
  rows: { [key: string]: any };
}

export const TravelDetailModal: React.FC<TravelDetailModalProps> = ({
  data,
  open,
  onClose,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [preferencesContext]: [Preferences] = useContext(UserContext);

  const tableBuilderRef = useRef<TravelDetailModalTableBuilder>(
    new TravelDetailModalTableBuilder()
  );
  let tableBuilder = tableBuilderRef.current;

  let tableData: TableData = {} as TableData;

  tableData.columns = tableBuilder.setColumns(
    tableSchemaFix,
    preferencesContext
  );
  tableData.rows =
    tableData?.columns &&
    tableData?.columns?.length > 0 &&
    data.gpsData.length > 0
      ? data.gpsData.map(
          function (gpsData: GPSDataReport) {
            let vehicle = vehiclesSelectors.selectById(
              store.getState(),
              gpsData.vehicleId
            );
            let driver = driversSelectors.selectById(
              store.getState(),
              gpsData.driverId
            );
            return tableBuilder.rowsBuilder(
              tableData.columns,
              {
                gpsDataReport: gpsData,
                vehicle: vehicle,
                driver: driver,
                travelDetail: data.travel,
              },
              preferencesContext
            );
          },
          [data.travel, data.gpsData]
        )
      : tableData.rows;

  return (
    <div className="travel-detail-modal">
      <ModalWrapper open={open} closeAction={onClose}>
        <ModalBody
          title={`${t("customModals.travelDetail.title")} ${
            data.travel.routeId ?? ""
          }`}
        >
          <div className="group-input">
            {t("customModals.travelDetail.details")}
          </div>
          <div className="travel-detail-icons">
            {data?.travel.traveled !== undefined &&
              data?.routeStateType === "TRACK" && (
                <DataLabel
                  dataText={`${numberAnnotation(data.travel.traveled / 1000)} ${
                    preferencesContext.isMetric ? "km" : "mil"
                  }`}
                  icon={<IconKilometers size={14} />}
                  label={t("customModals.travelDetail.km")}
                />
              )}
            {data?.travel.consumption !== undefined &&
              data?.routeStateType === "TRACK" && (
                <DataLabel
                  dataText={`${numberAnnotation(data.travel.consumption)} ${
                    preferencesContext.isMetric ? "lt" : "gal"
                  }`}
                  icon={<IconGasStation size={14} />}
                  label={t("customModals.travelDetail.consumption")}
                />
              )}
            {data?.travel.maxSpeed !== undefined &&
              data?.routeStateType === "TRACK" && (
                <DataLabel
                  dataText={`${numberAnnotation(data.travel.maxSpeed)} ${
                    preferencesContext.isMetric ? "km/h" : "mph"
                  }`}
                  icon={<IconSpeed size={14} />}
                  label={t("customModals.travelDetail.maxSpeed")}
                />
              )}
            {data?.travel.avgSpeed !== undefined &&
              data?.routeStateType === "TRACK" && (
                <DataLabel
                  dataText={`${numberAnnotation(data.travel.avgSpeed)} ${
                    preferencesContext.isMetric ? "km/h" : "mph"
                  }`}
                  icon={<IconAverageSpeed size={14} />}
                  label={t("customModals.travelDetail.averageSpeed")}
                />
              )}
            {data?.travel.drivingTime !== undefined &&
              data?.routeStateType === "TRACK" && (
                <DataLabel
                  dataText={formatTime(
                    data.travel.drivingTime * 1000,
                    preferencesContext.language
                  )}
                  icon={<IconClock size={14} />}
                  label={t("customModals.travelDetail.driveTime")}
                />
              )}
            {data?.travel.parkingTime !== undefined &&
              data?.routeStateType === "STOP" && (
                <DataLabel
                  dataText={formatTime(
                    data.travel.parkingTime * 1000,
                    preferencesContext.language
                  )}
                  icon={<IconParking size={14} />}
                  label={t("customModals.travelDetail.parkingTime")}
                />
              )}
            {data?.travel.idleTime !== undefined &&
              data?.routeStateType === "TRACK" && (
                <DataLabel
                  dataText={formatTime(
                    data.travel.idleTime * 1000,
                    preferencesContext.language
                  )}
                  icon={<IconParkingPoint size={14} />}
                  label={t("customModals.travelDetail.idleTime")}
                />
              )}
          </div>
        </ModalBody>
        <Table data={tableData} isSearchParams={false} isShowTable={false}>
          <Table.Head />
          <Table.Body />
        </Table>
        <div className="travel-detail-modal-buttons">
          <ModalFooter
            primaryLabel={t("customModals.travelDetail.primaryButton")}
            primaryAction={onClose}
            secondaryLabel={t("customModals.travelDetail.secondaryButton")}
            secondaryAction={() => {
              window.open(
                `/dashboard/vehicles/location-history/track/${data?.travel.routeId}` +
                  getQueryString({
                    firstPointDate: [
                      dayjs(
                        new Date(
                          data.travel.firstPosition.gpsPositionTimestamp
                        ).setDate(
                          new Date(
                            data.travel.firstPosition.gpsPositionTimestamp
                          ).getDate() - 1
                        )
                      ).format("YYYY/MM/DD HH:mm"),
                      dayjs(
                        new Date(
                          data.travel.lastPosition.gpsPositionTimestamp
                        ).setDate(
                          new Date(
                            data.travel.lastPosition.gpsPositionTimestamp
                          ).getDate() + 1
                        )
                      ).format("YYYY/MM/DD HH:mm"),
                    ],
                    routeStateTypeEnum: "TRACK",
                    "vehicle.id": data.travel.vehicleId ?? "",
                  }),
                "_blank",
                "noopener,noreferrer"
              );
            }}
          />
        </div>
      </ModalWrapper>
    </div>
  );
};
