import PropTypes from "prop-types";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../app/hooks";
import { store } from "../../../app/store";
import { GTFleetErrorCodes } from "../../../config/GTfleetErrorCodes";
import { Dropdown } from "../../../ui/Dropdown/Dropdown";
import { Checkbox } from "../../../ui/Forms/Checkbox";
import Form from "../../../ui/Forms/Form";
import SearchField from "../../../ui/Forms/SearchField";
import { ModalBody } from "../../../ui/Modal/ModalBody";
import { ModalFooter } from "../../../ui/Modal/ModalFooter";
import { ModalWrapper } from "../../../ui/Modal/ModalWrapper";
import { ScheduleDate } from "../../../ui/Schedules/ScheduleDate";
import { ScheduleDistance } from "../../../ui/Schedules/ScheduleDistance";
import { ScheduleNote } from "../../../ui/Schedules/ScheduleNote";
import { ScheduleType } from "../../../ui/Schedules/ScheduleType";
import { ScheduleWorkingHours } from "../../../ui/Schedules/ScheduleWorkingHours";
import { TypeOfVehicleType } from "../../../ui/Vehicles/VehicleTypes";
import { DataCommon } from "../../../utils/Common";
import { ToastNotification } from "../../../utils/ToastNotification";
import { getMeter, mtToDynamicMeasure } from "../../../utils/Utils";
import { FleetView, fleetViewsSelectors } from "../../fleet/fleetViewsSlice";
import { Preferences } from "../../users/preference/preferencesSlice";
import UserContext from "../../users/userContext";
import {
  getVehicleAsync,
  selectVehiclesStatusSliceReasonCode,
  selectVehiclesStatusSliceStatus,
  vehiclesStatusEmptyState,
  vehiclesStatusSelectors,
} from "../../vehicle/vehiclesStatusSlice";
import {
  VehicleView,
  vehiclesViewSelectors,
} from "../../vehicle/vehiclesViewSlice";
import {
  Deadline,
  DeadlineMultiVehicle,
  createDeadlineAsync,
  getDeadlinesAsync,
} from "../deadlinesSlice";
import "./AddMaintenance.css";
interface AddMaintenanceModalProps {
  open: boolean;
  deadlineDefaultValues?: Deadline;
  vehicleDefaultValue?: number;
  onClose: () => any;
}
interface DropDownItem {
  label: string;
  id: number;
  count?: number;
}
interface QueryParams {
  [paramName: string]: any;
}

interface MaintenanceSearchParams {
  aliasPlate: string;
  vehicleType: string;
  fleetIds: number[];
}

export const AddMaintenanceModal: React.FC<AddMaintenanceModalProps> = ({
  open,
  deadlineDefaultValues,
  vehicleDefaultValue,
  onClose,
}) => {
  const { t } = useTranslation();

  const [filteredVehicle, setFilteredVehicle] = useState<string>("");
  const [page, setPage] = useState<number>(deadlineDefaultValues ? 2 : 1);
  const [maintenanceSearchParams, setMaintenanceSearchParams] = useState({
    aliasPlate: "",
    fleetIds: [],
    vehicleType: "",
  } as MaintenanceSearchParams);
  const [valueSelected, setValueSelected] = useState<number[]>(
    vehicleDefaultValue ? [vehicleDefaultValue] : []
  );

  const vehicles: VehicleView[] = useAppSelector(
    vehiclesViewSelectors.selectAll
  );

  const fleets = fleetViewsSelectors.selectAll(store.getState());

  const groupsValues: DropDownItem[] = !!fleets
    ? fleets.map((value: FleetView) => {
        return {
          label: value.name,
          id: value.id,
          count: vehiclesCount(value.id, vehicles),
        };
      })
    : [];

  function vehiclesCount(fleetId: number, vehicles: VehicleView[]) {
    let counter = 0;
    vehicles.forEach((vehicle) => {
      vehicle.fleet === fleetId && counter++;
    }, []);
    return counter;
  }

  const [isOpened, setIsOpened] = useState(-1);

  const [addMaintenanceInfo, setAddMaintenanceInfo] = useState(
    deadlineDefaultValues ?? ({} as Deadline)
  );

  const vehicleStatusSliceStatus = useAppSelector(
    selectVehiclesStatusSliceStatus
  );
  const vehicleStatusSliceReasonCode = useAppSelector(
    selectVehiclesStatusSliceReasonCode
  );

  const [preferencesContext]: [Preferences] = useContext(UserContext);

  const types: any = DataCommon()?.maintenanceTypes;
  const [buttonLoading, setButtonLoading] = useState(false);
  const [typeIsEmpty, getTypeIsEmpty] = useState(false);

  useEffect(() => {
    if (
      vehicleStatusSliceStatus === "failed" &&
      vehicleStatusSliceReasonCode ===
        GTFleetErrorCodes.VEHICLE_STATUS_NOT_FOUND
    ) {
      console.error(t("common.vehicleStatusError"));
      ToastNotification({
        toastId: "vehicleStatusError",
        status: "error",
        description: t("common.vehicleStatusError"),
      });
    } else if (
      vehicleStatusSliceStatus === "failed" &&
      vehicleStatusSliceReasonCode === ""
    ) {
      ToastNotification({
        toastId: "networkError",
        status: "error",
        description: t("common.networkError"),
      });
    }
  }, [vehicleStatusSliceStatus, vehicleStatusSliceReasonCode]);

  const handleNext = () => {
    setPage(2);
  };

  const handleBack = () => {
    setPage(1);
    setIsOpened(-1);
    setAddMaintenanceInfo({} as Deadline);
  };

  const handleSubmit = () => {
    page !== 1 && setButtonLoading(true);
    if (isOpened !== -1) {
      setIsOpened(-1);
      setButtonLoading(false);
    }
    if (page !== 1 && !buttonLoading) {
      let expirationDate: Date = new Date();
      if (
        deadlineDefaultValues?.rescheduleMonths != undefined &&
        deadlineDefaultValues?.rescheduleMonths > 0
      ) {
        expirationDate = new Date(
          new Date(addMaintenanceInfo.expirationDate).setMonth(
            new Date(addMaintenanceInfo.expirationDate).getMonth() +
              addMaintenanceInfo?.rescheduleMonths
          )
        );
      } else {
        expirationDate =
          addMaintenanceInfo?.expirationDate &&
          new Date(addMaintenanceInfo?.expirationDate);
      }
      expirationDate && expirationDate.setHours(8);
      const updatedAddMaintenanceInfo: DeadlineMultiVehicle = {
        ...addMaintenanceInfo,
        vehicleIds: valueSelected,
        isMaintenance: true,
        expirationDate: expirationDate,
      };
      store
        .dispatch(createDeadlineAsync(updatedAddMaintenanceInfo))
        .then(() => {
          store.dispatch(
            getDeadlinesAsync({
              queryParams:
                "status=SCHEDULED&status=IN_PROGRESS&status=EXPIRED&status=EXPIRING",
            })
          );
        });

      onClose();
    }
    if (page === 1) {
      if (valueSelected.length === 1) {
        const vehicleIdSelected = valueSelected[0];
        const vehicleDetails: VehicleView = vehiclesViewSelectors.selectById(
          store.getState(),
          vehicleIdSelected
        ) as VehicleView;
        if (vehicleDetails) {
          store.dispatch(
            getVehicleAsync({
              id: vehicleDetails.id,
              fleetId: vehicleDetails.fleet,
            })
          );
        }
      }
      handleNext();
    }
  };

  const submitModalRef = useRef<any>();
  useEffect(() => {
    const keyUp = (event: any) => {
      if (event.keyCode === 13 && !buttonLoading) {
        event.preventDefault();
        page === 1 ? handleNext() : handleSubmit();
      }
    };

    if (submitModalRef && submitModalRef.current) {
      submitModalRef.current.addEventListener("keydown", keyUp);
      let parentRef = submitModalRef;
      return () => {
        parentRef.current &&
          parentRef.current.removeEventListener("keydown", keyUp);
      };
    }
  });

  function optionSelected(opt: number) {
    return valueSelected.some((el) => el === opt);
  }

  function selectCheckboxOptions(option: number) {
    let compareElement = valueSelected.find((o) => o === option);
    if (compareElement) {
      setValueSelected(valueSelected.filter((el) => el !== compareElement));
    } else {
      setValueSelected((prevArray) => [...prevArray, option]);
    }
  }

  // This method is in charge to set the list of alias and plates in modal description
  const handleDescription = () => {
    let vehiclesInfo: any[] = [];
    if (valueSelected.length > 0) {
      if (valueSelected.length === 1) {
        vehiclesInfo.push(
          vehicles.filter((el) => el.id === valueSelected[0])[0]
        );
        return (
          vehiclesInfo[0] &&
          `${vehiclesInfo[0].alias} - ${vehiclesInfo[0].plate}`
        );
      } else {
        valueSelected.forEach((element) =>
          vehiclesInfo.push(vehicles.filter((el) => el.id === element)[0])
        );
        let str = "";
        vehiclesInfo.forEach((el, index) => {
          if (index < 2) {
            str = str.concat(
              ` ${el.alias} - ${el.plate}${index === 1 ? "" : ","}`
            );
          } else if (index === 2) {
            str = str.concat(`, ...(+${vehiclesInfo.length - index})`);
          }
        });
        return str;
      }
    }
  };

  const vehicleTypesValues = useRef<Set<TypeOfVehicleType>>(
    new Set(
      vehicles.map((vehicle) => {
        return vehicle.type;
      })
    )
  );

  const [vehicleTypeValue, setVehicleTypeValue] =
    useState<TypeOfVehicleType>("CAR");

  useEffect(() => {
    setMaintenanceSearchParams({
      ...maintenanceSearchParams,
      vehicleType: vehicleTypeValue,
    });
    return () => {
      store.dispatch(vehiclesStatusEmptyState());
    };
  }, []);

  const initialDistanceValue =
    valueSelected.length > 1
      ? 0
      : vehiclesStatusSelectors.selectById(store.getState(), valueSelected[0])
          ?.dynamicFields?.odometer ?? 0;

  const initialUtilizationTime =
    valueSelected.length > 1
      ? 0
      : vehiclesStatusSelectors.selectById(store.getState(), valueSelected[0])
          ?.dynamicFields?.utilizationTime ?? 0;

  useEffect(() => {
    setAddMaintenanceInfo({
      ...addMaintenanceInfo,
      currentOdometer: initialDistanceValue,
    });
  }, [valueSelected, initialDistanceValue]);

  useEffect(() => {
    if (page === 2) {
      setAddMaintenanceInfo({
        ...addMaintenanceInfo,
        currentUtilizationMinutes: initialUtilizationTime,
      });
    }
  }, [valueSelected, initialUtilizationTime, page]);

  return (
    <div className="add-maintenance-container" ref={submitModalRef}>
      <ModalWrapper open={open} closeAction={onClose}>
        {page === 1 ? (
          <div className="add-maintenance">
            <ModalBody
              title={t("customModals.addMaintenance.title")}
              desc={t("customModals.addMaintenance.desc")}
            >
              <div className="splitter">
                <div />
              </div>
              <div className="central-forms">
                <Form>
                  <SearchField
                    name="search"
                    id="search-field"
                    size="small"
                    placeholder="Filter by vehicle"
                    value={filteredVehicle}
                    onChange={(val: string) => {
                      setFilteredVehicle(val);
                      setMaintenanceSearchParams({
                        ...maintenanceSearchParams,
                        aliasPlate: val,
                      });
                    }}
                  />
                </Form>
                <Dropdown
                  hasCheckbox={true}
                  itemAttribute="label"
                  placeholder={t("fleetControl.filterBar.searchGroups")}
                  size={"small"}
                  onChange={(val: DropDownItem[]) => {
                    setMaintenanceSearchParams({
                      ...maintenanceSearchParams,
                      fleetIds: val.map((x) => x.id),
                    });
                  }}
                  options={groupsValues}
                />
                <Dropdown
                  itemAttribute="label"
                  placeholder={t("fleetControl.filterBar.searchVehicleTypes")}
                  size={"small"}
                  onChange={(val: any[]) => {
                    setMaintenanceSearchParams({
                      ...maintenanceSearchParams,
                      vehicleType: val[0].label,
                    });
                    setVehicleTypeValue(val[0].realValue);
                    setValueSelected([]);
                  }}
                  value={{
                    label: t(`common.optionsEnum.type.${vehicleTypeValue}`),
                  }}
                  options={Array.from(vehicleTypesValues.current).map((el) => {
                    return {
                      label: t(`common.optionsEnum.type.${el}`),
                      realValue: el,
                    };
                  })}
                />
              </div>
              <div className="checkboxes-list">
                {maintenanceSearchParams &&
                  vehicles
                    ?.filter((vehicle) => {
                      const aliasPlate =
                        maintenanceSearchParams?.aliasPlate.toLowerCase();
                      const fleetIds = maintenanceSearchParams?.fleetIds;

                      let filterByAliasPlate = true;
                      let filterByFleetId = true;
                      let filterByVehicleType = true;
                      if (aliasPlate != "") {
                        const isAliasIncluding = vehicle?.alias
                          .toLowerCase()
                          .includes(aliasPlate);
                        const isPlateIncluding = vehicle?.plate
                          .toLowerCase()
                          .includes(aliasPlate);
                        filterByAliasPlate =
                          isAliasIncluding || isPlateIncluding;
                      }
                      filterByVehicleType = vehicle?.type === vehicleTypeValue;
                      if (fleetIds.length > 0) {
                        const isFleetIncluded = fleetIds.includes(
                          vehicle?.fleet
                        );
                        filterByFleetId = isFleetIncluded;
                      }
                      return (
                        filterByAliasPlate &&
                        filterByFleetId &&
                        filterByVehicleType
                      );
                    })
                    .map((vehicle) => (
                      <div key={vehicle.id}>
                        <Checkbox
                          name={vehicle.alias}
                          label={vehicle.alias}
                          subLabel={vehicle.plate}
                          checked={optionSelected(vehicle.id)}
                          onChange={() => selectCheckboxOptions(vehicle.id)}
                        />
                      </div>
                    ))}
              </div>
            </ModalBody>
          </div>
        ) : (
          <div className="set-maintenance">
            <ModalBody
              title={t("customModals.addMaintenance.title")}
              desc={
                valueSelected?.length === 1
                  ? t("customModals.addMaintenance.descConf") +
                    handleDescription()
                  : t("customModals.addMaintenance.descConfs") +
                    handleDescription()
              }
            >
              <div className="splitter-full" />
              <ScheduleType
                defaultType={{
                  typeName: addMaintenanceInfo?.type,
                  customType:
                    addMaintenanceInfo?.additionalInfoJson &&
                    JSON.parse(addMaintenanceInfo?.additionalInfoJson)
                      .customTypeName,
                }}
                types={types}
                open={isOpened === 0}
                getValue={(data) =>
                  setAddMaintenanceInfo({
                    ...addMaintenanceInfo,
                    type: data,
                  })
                }
                getCustomType={(data) =>
                  setAddMaintenanceInfo({
                    ...addMaintenanceInfo,
                    additionalInfoJson: JSON.stringify({
                      customTypeName: data,
                    }),
                  })
                }
                getTypeIsEmpty={getTypeIsEmpty}
                setIsOpened={setIsOpened}
              />
              <div className="split-line-add-maintenance" />
              {vehiclesViewSelectors.selectById(
                store.getState(),
                valueSelected[0]
              )?.type === "OPERATING_MACHINE" ? (
                <ScheduleWorkingHours
                  typeIsEmpty={typeIsEmpty}
                  setIsOpened={setIsOpened}
                  defaultHour={{
                    value:
                      addMaintenanceInfo?.rescheduleUtilizationMinutes ?? 0,
                    repeat:
                      addMaintenanceInfo?.rescheduleUtilizationMinutes ?? 0,
                  }}
                  open={isOpened === 6}
                  getValue={(data: any) =>
                    setAddMaintenanceInfo({
                      ...addMaintenanceInfo,
                      rescheduleUtilizationMinutes: data.repeat,
                      expirationUtilizationMinutes: data.hour,
                    })
                  }
                  initialHours={initialUtilizationTime}
                />
              ) : (
                <ScheduleDistance
                  typeIsEmpty={typeIsEmpty}
                  setIsOpened={setIsOpened}
                  defaultDistance={{
                    value: addMaintenanceInfo?.rescheduleMeters
                      ? mtToDynamicMeasure(
                          addMaintenanceInfo?.rescheduleMeters,
                          preferencesContext.isMetric
                        )
                      : 0,
                    repeat: addMaintenanceInfo?.rescheduleMeters
                      ? mtToDynamicMeasure(
                          addMaintenanceInfo?.rescheduleMeters,
                          preferencesContext.isMetric
                        )
                      : 0,
                  }}
                  open={isOpened === 4}
                  getValue={(data: any) =>
                    setAddMaintenanceInfo({
                      ...addMaintenanceInfo,
                      rescheduleMeters: getMeter(
                        data.repeat,
                        preferencesContext.isMetric
                      ),
                      expirationMeters: getMeter(
                        data.distance,
                        preferencesContext.isMetric
                      ),
                    })
                  }
                  isMetric={preferencesContext.isMetric}
                  initialDistance={initialDistanceValue}
                />
              )}
              <div className="split-line-add-maintenance" />
              <ScheduleDate
                defaultDate={{
                  value:
                    addMaintenanceInfo?.rescheduleMonths > 0
                      ? new Date(
                          new Date(addMaintenanceInfo.expirationDate).setMonth(
                            new Date(
                              addMaintenanceInfo.expirationDate
                            ).getMonth() + addMaintenanceInfo?.rescheduleMonths
                          )
                        ).toString()
                      : "",
                  repeat: addMaintenanceInfo?.rescheduleMonths,
                }}
                typeIsEmpty={typeIsEmpty}
                getValue={(data) =>
                  setAddMaintenanceInfo({
                    ...addMaintenanceInfo,
                    rescheduleMonths: data.repeat,
                    expirationDate: data.date,
                  })
                }
                open={isOpened === 2}
                language={preferencesContext.language ?? "it"}
                localeFormat={
                  preferencesContext.localeFormat ?? "DD / MM / YYYY"
                }
                setIsOpened={setIsOpened}
              />
              <div className="split-line-add-maintenance" />
              <ScheduleNote
                defaultNote={addMaintenanceInfo?.note}
                typeIsEmpty={typeIsEmpty}
                getValue={(data) =>
                  setAddMaintenanceInfo({
                    ...addMaintenanceInfo,
                    note: data,
                  })
                }
                open={isOpened === 3}
                setIsOpened={setIsOpened}
              />
              <div className="split-line-add-maintenance" />
            </ModalBody>
          </div>
        )}
        <ModalFooter
          primaryLabel={page !== 1 ? t("common.confirm") : t("common.next")}
          primaryAction={handleSubmit}
          loadingPrimaryButton={page !== 1 ? buttonLoading : false}
          disablePrimaryButton={
            page !== 1
              ? !(
                  (!!addMaintenanceInfo?.type ||
                    (addMaintenanceInfo?.additionalInfoJson &&
                      Object.keys(
                        JSON.parse(addMaintenanceInfo?.additionalInfoJson)
                      )?.length === 0)) &&
                  (!!addMaintenanceInfo.expirationMeters ||
                    !!addMaintenanceInfo.expirationUtilizationMinutes ||
                    (!!addMaintenanceInfo?.expirationDate &&
                      addMaintenanceInfo.expirationDate.toString() !== ""))
                ) || typeIsEmpty
              : !valueSelected.length
          }
          secondaryLabel={t("common.cancel")}
          secondaryAction={() => {
            onClose();
          }}
          hasBack={page !== 1}
          backAction={handleBack}
        />
      </ModalWrapper>
    </div>
  );
};
AddMaintenanceModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

AddMaintenanceModal.defaultProps = {
  open: false,
};
