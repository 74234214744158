import HttpClient from "../../../utils/HttpClient";

export default class PublicRouteShiftRepository extends HttpClient {
  constructor() {
    super(true);
  }

  public getPublicRouteShifts() {
    const tenantId = localStorage.getItem("tenantId");
    return this.instance.get(`/tenants/${tenantId}/publicRouteShift`);
  }
}
