import _ from "lodash";
import { useRef } from "react";
import { datetoLocaleFormat } from "../../utils/DateAndTimeUtils";
import { numberAnnotation } from "../../utils/Utils";
import { Card } from "../Card/Card";
import { ColumnChart } from "../Chart/ColumnChart";
import { DataLabel } from "../DataLabel/DataLabel";
import { IconGasStation } from "../Icon/Line/GasStation";
import { IconPeriodicalInspection } from "../Icon/Line/PeriodicalInspection";
import "./TimeSeriesChartCard.css";

export const TimeSeriesChartCard = ({
  title,
  linkAnchor,
  linkAction,
  graphTitle,
  chartDataSeries,
  chartDateSeries,
  setPeriod,
  firstChartData,
  secondChartData,
  className,
  hasDropdown,
  status,
}) => {
  const viewChart = useRef(true);

  const classCard = "mn-timeSeriesChartCard " + className;

  const valueChange = (val) => {
    let startPeriod = new Date(new Date().setUTCHours(0, 0, 0, 0));
    let endPeriod = new Date(new Date().setUTCHours(23, 59, 59, 999));
    switch (val?.value) {
      case "yesterday":
        startPeriod.setDate(startPeriod.getDate() - 1);
        endPeriod.setDate(endPeriod.getDate() - 1);
        viewChart.current = false;
        break;
      case "lastWeek":
        startPeriod.setDate(startPeriod.getDate() - 6);
        viewChart.current = true;
        break;
      case "lastMonth":
        startPeriod.setDate(startPeriod.getDate() - 30);
        viewChart.current = true;
        break;
      default:
        viewChart.current = false;
        break;
    }
    setPeriod({
      startPeriod: startPeriod.toISOString(),
      endPeriod: endPeriod.toISOString(),
    });
  };

  return (
    <Card
      title={title}
      className={classCard}
      linkAnchor={linkAnchor}
      linkAction={linkAction}
      valueChange={valueChange}
      hasDropdown={hasDropdown}
      linkDisabled={true}
    >
      <div className="mn-timeSeriesChartCard__inner">
        {chartDataSeries !== undefined &&
          !_.isEmpty(chartDataSeries[0]) &&
          viewChart.current && (
            <div className={"mn-timeSeriesChartCard__center-col"}>
              <div className="fuel-chart">
                <ColumnChart
                  title={graphTitle?.first?.title}
                  series={chartDataSeries[0]?.data ? [chartDataSeries[0]] : []}
                  categories={
                    chartDateSeries[0]?.date ? chartDateSeries[0].date : []
                  }
                  size={{ height: 166 }}
                  status={status}
                />
              </div>
              {firstChartData && (
                <div
                  className={
                    "mn-timeSeriesChartCard__container " +
                    `${chartDataSeries[1] !== undefined ? "border-bottom" : ""}`
                  }
                >
                  {firstChartData.first && (
                    <span className="mn-timeSeriesChartCard__data-item">
                      <div>{firstChartData.first?.title}</div>
                      <p>{firstChartData.first?.data}</p>
                    </span>
                  )}
                  {firstChartData.second && (
                    <span className="mn-timeSeriesChartCard__data-item">
                      <div>{firstChartData.second?.title}</div>
                      <p>{firstChartData.second?.data}</p>
                    </span>
                  )}
                  {firstChartData.third && (
                    <span className="mn-timeSeriesChartCard__data-item">
                      <div>{firstChartData.third?.title}</div>
                      <p>{firstChartData.third?.data}</p>
                    </span>
                  )}
                </div>
              )}
            </div>
          )}
        {chartDataSeries !== undefined &&
          !_.isEmpty(chartDataSeries[1]) &&
          viewChart.current && (
            <div className={"mn-timeSeriesChartCard__center-col"}>
              <div className="maintenance-chart">
                <ColumnChart
                  title={graphTitle?.second?.title}
                  series={chartDataSeries[1]?.data ? [chartDataSeries[1]] : []}
                  categories={
                    chartDateSeries[1]?.date ? chartDateSeries[1].date : []
                  }
                  size={{ height: 166 }}
                  status={status}
                />
              </div>
              {secondChartData && (
                <div className="mn-timeSeriesChartCard__container">
                  {secondChartData.first && (
                    <span className="mn-timeSeriesChartCard__data-item">
                      <div>{secondChartData.first?.title}</div>
                      <p>{secondChartData.first?.data}</p>
                    </span>
                  )}
                  {secondChartData.second && (
                    <span className="mn-timeSeriesChartCard__data-item">
                      <>
                        <div>{secondChartData.second?.title}</div>
                        <p>{secondChartData.second?.data}</p>
                      </>
                    </span>
                  )}
                </div>
              )}
            </div>
          )}
        {chartDataSeries !== undefined &&
          !_.isEmpty(chartDataSeries[0]) &&
          !viewChart.current && (
            <div
              className={
                "mn-timeSeriesChartCard__single-value " +
                (!_.isEmpty(chartDataSeries[1])
                  ? "spaceAround"
                  : "spaceBetween")
              }
            >
              <div>
                <div className="mn-timeSeriesChartCard__single-value-title">
                  {graphTitle?.first?.title}
                </div>
                <div className="mn-timeSeriesChartCard__single-value-data">
                  <DataLabel
                    label={
                      <div className="datelabel-gas-maintenance">
                        {datetoLocaleFormat(
                          chartDataSeries[0]?.data[0].x,
                          {
                            year: "2-digit",
                            month: "short",
                            day: "2-digit",
                          },
                          false
                        )}
                      </div>
                    }
                    dataText={
                      <div className="pricelabel-gas-maintenance">
                        {numberAnnotation(chartDataSeries[0]?.data[0].y, 2, 2) +
                          " " +
                          chartDataSeries[0]?.unitMeasure}
                      </div>
                    }
                    icon={
                      <div className="icongasstation">
                        <IconGasStation size={24} />
                      </div>
                    }
                  ></DataLabel>
                </div>
              </div>
              {chartDataSeries !== undefined && !_.isEmpty(chartDataSeries[1]) && (
                <div>
                  <div className="mn-timeSeriesChartCard__single-value-title">
                    {graphTitle?.second?.title}
                  </div>
                  <div className="mn-timeSeriesChartCard__single-value-data">
                    <DataLabel
                      label={
                        <div className="datelabel-gas-maintenance">
                          {datetoLocaleFormat(
                            chartDataSeries[1]?.data[0].x,
                            {
                              year: "2-digit",
                              month: "short",
                              day: "2-digit",
                            },
                            false
                          )}
                        </div>
                      }
                      dataText={
                        <div className="pricelabel-gas-maintenance">
                          {numberAnnotation(
                            chartDataSeries[1]?.data[0].y,
                            2,
                            2
                          ) +
                            " " +
                            chartDataSeries[1]?.unitMeasure}
                        </div>
                      }
                      icon={
                        <div className="iconperiodicalinspection">
                          <IconPeriodicalInspection size={24} />
                        </div>
                      }
                    ></DataLabel>
                  </div>
                </div>
              )}
            </div>
          )}
      </div>
    </Card>
  );
};

TimeSeriesChartCard.propTypes = {};
