import { useEffect, useState } from "react";
import AvatarEditor from "react-avatar-editor";
import { SliderZoom } from "./SliderZoom";
import "./Avatar.css";
import FileService from "../../utils/FileService";

interface SubAvatarProps {
  image: string;
  id: string;
}

interface AvatarProps {
  image: string | File;
  getImage: boolean;
  avatar: SubAvatarProps | null;
  setNameFile: (e: any, b: any) => any;
}

export const Avatar = ({
  image,
  getImage,
  avatar,
  setNameFile,
}: AvatarProps) => {
  const [size, setSize] = useState(1.0);

  useEffect(() => {
    size !== 1 && setSize(1);
  }, [image]);

  let editor: any = "";
  const setEditorRef = (edit: any) => {
    editor = edit;
  };

  useEffect(() => {
    if (image instanceof File && !avatar && getImage) {
      const saveImage = async (file: any) => {
        const fileService = new FileService();
        await fileService
          .uploadFileBase64(file)
          .then((result) => setNameFile(result.data, null))
          .catch((err) => {
            let messageError;
            if (err.request.status === 404) {
              messageError = "errorBadRequest";
            } else {
              messageError = "errorUploadImage";
            }
            setNameFile("", messageError);
          });
      };

      const canvasScaled = editor.getImageScaledToCanvas();
      const croppedImg = canvasScaled.toDataURL();

      saveImage(croppedImg);
    } else if (!image && avatar && getImage) {
      setNameFile("Avatar", null);
    }
  }, [getImage, image]);

  return (
    <>
      {!avatar ? (
        <AvatarEditor
          ref={setEditorRef}
          image={image}
          width={199.14}
          height={199.14}
          scale={size}
          rotate={0}
          border={0}
          borderRadius={200}
          className="borderRadiusCanvas"
          color={[255, 255, 255, 0]}
        />
      ) : (
        <img
          className="borderRadiusCanvas imegeCanvas"
          src={avatar && avatar.image}
          alt={avatar && avatar.id}
        />
      )}

      {typeof image !== "string" && (
        <SliderZoom
          data={{ min: 1, max: 3 }}
          step={0.1}
          val={size}
          onChange={(value) => {
            setSize(parseFloat(value));
          }}
          visibled={!!avatar ? true : false}
        />
      )}
    </>
  );
};
