import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { lengthTimeStringFormatter } from "../../utils/DateAndTimeUtils";
import { IconAlarmSiren } from "../Icon/Line/AlarmSiren";
import { IconClock } from "../Icon/Line/Clock";
import { IconDistance } from "../Icon/Solid/Distance";
import { IconDriver } from "../Icon/Solid/Driver";
import { Signal } from "../Indicators/IndicatorSignal";
import "./VehicleBox.css";

let ripplingPolling = null;
let dbClickPolling = null;
export const VehicleBox = ({
  id,
  vehicle,
  driverName,
  city,
  signalState,
  active,
  clickCallback,
  icon,
  hasSubtitleIcon,
  distance,
  time,
  lastUpdate,
  alarm,
  ...props
}) => {
  const [lastUpdateString, setLastUpdateString] = useState("");
  const [coords, setCoords] = useState({ x: -1, y: -1 });
  const [isRippling, setIsRippling] = useState(false);
  const [isSelected, setIsSelected] = useState(active);
  const [dbclick, setDbClick] = useState(true);

  let nowDate = new Date();
  let lastUpdateDate = new Date(lastUpdate);

  const onClick = (e) => {
    const rect = e.target.getBoundingClientRect();
    setCoords({
      x: rect.width / 2,
      y: rect.height / 2,
    });
    setIsSelected((prevState) => !prevState);
    if (clickCallback !== undefined) {
      clickCallback(vehicle);
    }
    setDbClick(false);
    dbClickPolling = setTimeout(() => setDbClick(true), 1000);
  };

  useEffect(() => {
    return function cleaning() {
      if (ripplingPolling) {
        clearTimeout(ripplingPolling);
      }
      if (dbClickPolling) {
        clearTimeout(dbClickPolling);
      }
    };
  }, []);

  useEffect(() => {
    if (!!lastUpdate && lastUpdate != "") {
      if (
        lastUpdateDate.getDate() === nowDate.getDate() &&
        lastUpdateDate.getMonth() === nowDate.getMonth()
      ) {
        setLastUpdateString(
          lengthTimeStringFormatter(lastUpdateDate.getHours().toString()) +
            ":" +
            lengthTimeStringFormatter(lastUpdateDate.getMinutes().toString())
        );
      } else {
        setLastUpdateString(
          lengthTimeStringFormatter(lastUpdateDate.getHours().toString()) +
            ":" +
            lengthTimeStringFormatter(lastUpdateDate.getMinutes().toString()) +
            " | " +
            lengthTimeStringFormatter(lastUpdateDate.getDate().toString()) +
            "/" +
            lengthTimeStringFormatter(
              (lastUpdateDate.getMonth() + 1).toString()
            )
        );
      }
    }
  }, [lastUpdate]);

  useEffect(() => {
    setIsSelected(active);
  }, [active]);

  useEffect(() => {
    if (coords.x !== -1 && coords.y !== -1) {
      setIsRippling(true);
      ripplingPolling = setTimeout(() => setIsRippling(false), 300);
    } else {
      setIsRippling(false);
    }
  }, [coords]);

  useEffect(() => {
    if (!isRippling) {
      setCoords({ x: -1, y: -1 });
    }
  }, [isRippling]);

  let className = "mn-vehicle-box";

  if (isSelected) {
    className += " mn-vehicle-box--active";
  }

  return (
    <div
      id={`vehicle-box-${id}`}
      key={id}
      className={className}
      {...props}
      onClick={dbclick ? onClick : () => {}}
    >
      <div className="mn-vehicle-box__content">
        <div className="mn-vehicle-box__icon-status">
          <div className="mn-vehicle-box__icon">{icon}</div>
          {signalState && <Signal mode={signalState} />}
        </div>
        <div className="mn-vehicle-box__detail">
          <div
            className={
              driverName
                ? "mn-vehicle-box__upper-row"
                : "mn-vehicle-box__center-row"
            }
          >
            <span className="mn-vehicle-box__id">{vehicle?.alias}</span>
            <span className="mn-vehicle-box__license-plate">
              {vehicle?.plate}
            </span>
            <span className="mn-vehicle-box__alarm">
              {alarm ? (
                <IconAlarmSiren
                  size={15}
                  color="--global-colors-feedback-danger"
                />
              ) : null}
            </span>
            <span className="mn-vehicle-box__lastUpdate">
              {lastUpdateString}
            </span>
          </div>
          {driverName && (
            <div className="mn-vehicle-box__lower-row">
              {hasSubtitleIcon ? (
                <>
                  <span className="mn-vehicle-box__driver-name">
                    <IconDriver size={12} color="--global-colors-ink-light" />
                    <span className="mn-vehicle-box__driver-name-label">
                      {driverName}
                    </span>
                  </span>
                  <span className="mn-vehicle-box__distance">
                    <IconDistance size={12} color="--global-colors-ink-light" />
                    {distance}
                  </span>
                  <span className="mn-vehicle-box__time">
                    <IconClock size={12} color="--global-colors-ink-light" />
                    {time}
                  </span>
                </>
              ) : (
                <>
                  <span>{driverName}</span>
                  <span className="mn-vehicle-box__city">
                    <span className="divider">{driverName ? "|" : ""}</span>
                    {city}
                  </span>
                </>
              )}
            </div>
          )}
        </div>
      </div>
      {isRippling && (
        <span className="ripple" style={{ left: coords.x, top: coords.y }} />
      )}
    </div>
  );
};

VehicleBox.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  vehicle: PropTypes.object,
  driverName: PropTypes.string,
  city: PropTypes.string,
  signalState: PropTypes.oneOf([
    "NO_SIGNAL",
    "ONLINE",
    "OFFLINE",
    "UNKNOWN",
    "MOVING",
    "PARKING",
    "STOP",
  ]),
  active: PropTypes.bool.isRequired,
  icon: PropTypes.object,
  distance: PropTypes.string,
  time: PropTypes.string,
  hasSubtitleIcon: PropTypes.bool,
  clickCallback: PropTypes.func,
  lastUpdate: PropTypes.any,
};

VehicleBox.defaultValue = {
  hasSubtitleIcon: false,
};
