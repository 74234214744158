import React from "react";
import PropTypes from "prop-types";

import { IconClose } from "../Icon/Line/Close";

import "./Tag.css";

export const Tag = ({
  text,
  size,
  mode,
  type,
  hasIcon,
  className,
  onClick,
  ...props
}) => {
  const sizeClass = size === "small" ? "mn-tag--small" : null;
  const modeClass = mode === "outline" ? "mn-tag--outline" : null;
  const typeClass = `mn-tag--${type}`;
  const iconClass = hasIcon ? "tag--has-icon" : null;
  return (
    <span
      className={[
        "mn-tag",
        className,
        sizeClass,
        modeClass,
        typeClass,
        iconClass,
      ].join(" ")}
      {...props}
    >
      {text}
      {hasIcon ? (
        <IconClose size={8} color="currentColor" onClick={onClick} />
      ) : null}
    </span>
  );
};

Tag.propTypes = {
  text: PropTypes.string,
  size: PropTypes.oneOf(["small", "regular"]),
  mode: PropTypes.oneOf(["fill", "outline"]),
  type: PropTypes.oneOf([
    "basic",
    "primary",
    "secondary",
    "success",
    "danger",
    "neutro",
    "neutro2",
  ]),
  hasIcon: PropTypes.bool,
  onClick: PropTypes.func,
};
