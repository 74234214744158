import _ from "lodash";
import React from "react";
import "./ClusterMarker.css";

interface ColorObject {
  color: string;
  deg: number;
}

interface DataObject {
  status: string;
  numbers: number;
  color: string;
}

interface ClusterMarkerProps {
  data?: DataObject[];
  icon?: any;
  text: string;
  hasChart: boolean;
  color?: string;
  onClick?: (e: any) => any;
}

export const ClusterMarker: React.FC<ClusterMarkerProps> = ({
  data,
  icon,
  text,
  color,
  onClick,
  hasChart,
}) => {
  let corners: ColorObject[] = [];
  if (data && data?.length > 0) {
    let totalMarkers = data.reduce(
      (total: number, obj: DataObject) => obj.numbers + total,
      0
    );

    data.forEach(
      (object: DataObject) =>
        object.numbers !== 0 &&
        corners.push({
          deg: _.round((360 * object.numbers) / totalMarkers),
          color: object.color,
        })
    );
  }

  function isLargeNumber(text: string) {
    if (/^\d{3}$/.test(text)) {
      return "-large";
    } else {
      return "";
    }
  }

  return (
    <div onClick={onClick} className={`cluster-marker${isLargeNumber(text)}`}>
      <div
        className={
          hasChart
            ? `cluster-marker-inner${isLargeNumber(text)}`
            : "cluster-marker-inner-simple"
        }
        style={
          hasChart
            ? {
                color: `var(--global-colors-ink-dark)`,
                background: `conic-gradient(${
                  corners[0]
                    ? `${corners[0].color} 0deg ${corners[0].deg}deg`
                    : ""
                } ${
                  corners[1]
                    ? `, ${corners[1].color} ${corners[0].deg}deg ${
                        corners[0].deg + corners[1].deg
                      }deg`
                    : ""
                }
                ${
                  corners[2]
                    ? `, ${corners[2].color} ${
                        corners[0].deg + corners[1].deg
                      }deg ${
                        corners[0].deg + corners[1].deg + corners[2].deg
                      }deg`
                    : ""
                }${
                  corners[3]
                    ? `, ${corners[3].color} ${
                        corners[0].deg + corners[1].deg + corners[2].deg
                      }deg ${
                        corners[0].deg +
                        corners[1].deg +
                        corners[2].deg +
                        corners[3].deg
                      }deg`
                    : ""
                }
                ${
                  corners[4]
                    ? `, ${corners[4].color} ${
                        corners[0].deg +
                        corners[1].deg +
                        corners[2].deg +
                        corners[3].deg
                      }deg ${
                        corners[0].deg +
                        corners[1].deg +
                        corners[2].deg +
                        corners[3].deg +
                        corners[4].deg
                      }deg`
                    : ""
                }
                ${
                  corners[5]
                    ? `,  ${corners[5].color} ${
                        corners[0].deg +
                        corners[1].deg +
                        corners[2].deg +
                        corners[3].deg +
                        corners[4].deg
                      }deg ${
                        corners[0].deg +
                        corners[1].deg +
                        corners[2].deg +
                        corners[3].deg +
                        corners[4].deg +
                        corners[5].deg
                      }deg`
                    : ""
                })`,
              }
            : {
                fontSize: "14px",
                color: `var(--global-colors-ui-white)`,
                background: color ? color : `var(--global-colors-ui-primary)`,
              }
        }
      >
        <div>{icon ? icon : text}</div>
      </div>
    </div>
  );
};
