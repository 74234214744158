import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import _ from "lodash";
import { normalize } from "normalizr";
import { RootState } from "../../app/store";
import { Config } from "../../config/Config";
import { GTFleetErrorCodes } from "../../config/GTfleetErrorCodes";
import { GTFleetSuccessCodes } from "../../config/GTFleetSuccessCodes";
import { getErrorCodes } from "../../utils/Utils";
import { chatsSchema } from "./chatNormalization";
import ChatRepository from "./chatRepository";

//#region Type
export const RecipientTypeEnum = {
  DRIVER: "DRIVER",
  USER: "USER",
  ADDRESS_BOOK: "ADDRESS_BOOK",
};
export type RecipientType = keyof typeof RecipientTypeEnum;

export type ChatType = {
  id: number;
  recipient: number;
  recipientType: RecipientType;
  entityId: number;
  dateCreation: string;
  dateLastMessage: string;
};

export type Recipient = {
  recipient: number;
  type: RecipientType;
  phone: string;
};

export type MessageRecipients = {
  smsRecipients: Recipient[];
  message: string;
};
//#endregion Type

export const getExistentChatAsync = createAsyncThunk(
  "chats/getExistentChat",
  async (userId: number, { rejectWithValue }) => {
    try {
      const chatRepository = new ChatRepository();
      const response = await chatRepository.getExistentChat(userId);
      const chats = _.get(response, Config.EXISTENTCHAT_DETAILS_RESPONSE_PATH);
      const normalizedData = normalize(chats, chatsSchema);
      return normalizedData.entities;
    } catch (error: any) {
      if (!error.response) throw error;
      return rejectWithValue(error.response.data.status);
    }
  }
);

export const sendMessageAsync = createAsyncThunk(
  "chats/sendMessage",
  async (
    data: { messageRecipients: MessageRecipients; userId: number },
    { rejectWithValue }
  ) => {
    try {
      const chatRepository = new ChatRepository();
      const response = await chatRepository.sendMessage(
        data.messageRecipients,
        data.userId
      );
      const chatUsers = _.get(response, Config.SEND_MESSAGE_RESPONSE_PATH);
      const normalizedData = normalize(chatUsers, chatsSchema);
      return normalizedData.entities;
    } catch (error: any) {
      if (!error.response) throw error;
      return rejectWithValue(
        error.response.data.message ?? error.response.data.status
      );
    }
  }
);

const chatsAdapter = createEntityAdapter<ChatType>({
  selectId: (chat) => chat.id,
  sortComparer: (a, b) => a.id - b.id,
});

export const chatsSlice = createSlice({
  name: "chats",
  initialState: chatsAdapter.getInitialState({
    status: "idle",
    reasonCode: "",
  }),
  reducers: {
    chatsEmptyState: (state) => {
      chatsAdapter.setAll(state, []);
      state.reasonCode = "";
      state.status = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getExistentChatAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(
        getExistentChatAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          chatsAdapter.upsertMany(state, action.payload.chats ?? []);
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.GET;
        }
      )
      .addCase(getExistentChatAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = GTFleetErrorCodes.INTERNAL_SERVER_ERROR;
      })
      .addCase(sendMessageAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(
        sendMessageAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          chatsAdapter.upsertMany(state, action.payload.chats ?? []);
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.POST;
        }
      )
      .addCase(
        sendMessageAsync.rejected,
        (state: any, action: PayloadAction<any>) => {
          state.status = "failed";
          state.reasonCode = getErrorCodes(action.payload);
        }
      );
  },
});

export const chatSelector = chatsAdapter.getSelectors<RootState>(
  (state) => state.chats
);

export const selectChatsSliceStatus = (state: any) => state.chats.status;
export const selectChatsSliceReasonCode = (state: any) =>
  state.chats.reasonCode;

export const { chatsEmptyState } = chatsSlice.actions;
export default chatsSlice.reducer;
