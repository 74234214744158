import PropTypes from "prop-types";
import "./RadialBar.css";

export const RadialBarPercent = ({ percentage, size, threshold, ...props }) => {
  const percent = () => {
    if (percentage < 0 || !percentage)
      return 0;
    else return (percentage > 100 ? 100 : percentage)
  };
  const chartColor =
    percent() > threshold
      ? window
        .getComputedStyle(document.documentElement)
        .getPropertyValue("--global-colors-feedback-success")
      : window
        .getComputedStyle(document.documentElement)
        .getPropertyValue("--global-colors-feedback-danger");
  return (
    <div className="mn-chart-radial-bar" {...props}>
      <svg
        viewBox="0 0 36 36"
        width={size.width}
        height={size.height ? size.height : size.width}
      >
        <path
          fill="none"
          stroke={chartColor}
          strokeDasharray={`${percent()}, 100`}
          d="M18 2.084a15.915 15.915 0 010 31.831 15.915 15.915 0 010-31.83"
        ></path>
        <text x="18" y="20.5">
          {percentage ? percent() + "%" : "N/D"}
        </text>
      </svg>
    </div>
  );
};

RadialBarPercent.propTypes = {
  /**
   * Number between 0 and 100 to show in the radial bar. Number less than 0 will
   * be forced to 0 meanwhile the ones greater that 100 will be forced to 100.
   */
  percentage: PropTypes.number,
  /**
   * Size of the chart. If height is not defined it'll be used the width.
   */
  size: PropTypes.shape({
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  /**
   * If the percentage is below the threshold the color of the stroke will change
   * to red.
   */
  threshold: PropTypes.number,
};

RadialBarPercent.defaultProps = {
  size: {
    width: 106,
  },
  threshold: 10,
};
