import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../Button/Button";
import { IconArrowDown } from "../Icon/Line/ArrowDown";
import { Tag } from "../Tags/Tag";
import "./TextFieldDropdown.css";
import { UserBox } from "./UserBox";

export const TextFieldDropdown = ({
  id,
  values,
  isDisabled,
  openDropdown,
  placeholder,
  setOpenDropdown,
  getValues,
  getOffsetHeight,
  hasUserBox,
  defaultValues,
  hasAddContact,
  handleAddContactClick,
}) => {
  const [open, setOpen] = useState(false);
  const [allItemsAdded, setAllItemsAdded] = useState(false);
  const [clickedItems, setClickedItems] = useState([]);
  const [buttonLabel, setButtonLabel] = useState("Add all");
  const [items, setItems] = useState([]);
  const [searchString, setSearchString] = useState("");
  const [zoomLevel, setZoomLevel] = useState(1);
  const wrapperRef = useRef(null);
  const { t } = useTranslation();
  const [removedValues, setRemovedValues] = useState([]);

  useEffect(() => {
    setOpen(openDropdown);
  }, [openDropdown]);

  useEffect(() => {
    if (defaultValues && defaultValues.length > 0) {
      setClickedItems(defaultValues);
    }
  }, [defaultValues]);

  // #region responsiveness methods
  // manage translations for add all label
  useEffect(() => {
    if (clickedItems) {
      getValues && getValues(clickedItems);
      setButtonLabel(() => {
        if (clickedItems.length === values.length) {
          return `${t("messages.textFieldDropdown.removeAll")} (${
            clickedItems.length
          })`;
        } else {
          return `${t("messages.textFieldDropdown.addAll")} (${
            values.length - clickedItems.length
          })`;
        }
      });
    }

    // Get the height of the "textfield-container" element
    if (
      document.getElementById(`textfield-container-${id}`) &&
      getOffsetHeight
    ) {
      getOffsetHeight(
        document.getElementById(`textfield-container-${id}`).offsetHeight - 42
      );
    }
  }, [clickedItems, values]);

  // this allow to re-calculate all position absolute element positions
  useEffect(() => {
    function handleResize() {
      const newZoomLevel = window.devicePixelRatio;
      if (newZoomLevel !== zoomLevel) {
        setZoomLevel(newZoomLevel);
        if (
          document.getElementById(`textfield-container-${id}`) &&
          getOffsetHeight
        ) {
          getOffsetHeight(
            document.getElementById(`textfield-container-${id}`).offsetHeight -
              42
          );
        }
      }
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [zoomLevel]);

  function handleClickOutside(event) {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setOpen(false);
    }
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  // #endregion responsiveness methods

  const onClickTagClose = (tag) => {
    setItems((prevItems) => prevItems.filter((item) => item.id !== tag.id));
  };

  const onClickAdd = (item) => {
    setClickedItems((prevItems) => [...prevItems, item]);
  };

  const onClickRemove = (item) => {
    setClickedItems((prevItems) =>
      prevItems.filter((prevItem) => prevItem.id !== item.id)
    );
  };

  const handleItem = (item) => {
    if (items.includes(item)) {
      setItems((prevItems) =>
        prevItems.filter((prevItem) => prevItem !== item)
      );

      if (defaultValues.some((defaultValue) => defaultValue.id === item.id)) {
        setRemovedValues((prevValues) => [...prevValues, item]);
      }
    } else {
      setItems((prevItems) => [...prevItems, item]);
    }
  };

  const handleButtonClick = () => {
    if (clickedItems.length === values.length) {
      setAllItemsAdded(false);
      setClickedItems([]);
    } else {
      setAllItemsAdded(true);
      setClickedItems(values);
    }
    setRemovedValues([]);
    setItems([]);
  };

  const onAddContactClick = () => {
    setOpen(false);
    handleAddContactClick();
  };
  return (
    <div
      className={
        isDisabled ? "textfield-wrapper-disabled" : "textfield-wrapper"
      }
      ref={wrapperRef}
    >
      <div id={`textfield-container-${id}`} className="textfield-container">
        <div
          className="textfield-inner"
          onClick={() => {
            if (!isDisabled && setOpenDropdown) {
              setOpenDropdown(true);
              setOpen(true);
            }
          }}
        >
          {clickedItems?.map((el, idx) => {
            return (
              <div className="textfield-tags" key={idx}>
                <Tag
                  text={el.title}
                  size="regular"
                  type="neutro"
                  mode="fill"
                  hasIcon={true}
                  onClick={() => {
                    onClickRemove(el);
                    allItemsAdded && handleItem(el);
                    onClickTagClose(el);
                  }}
                />
              </div>
            );
          })}
          {clickedItems.length !== values.length && (
            <input
              className="textfield-input"
              placeholder={
                placeholder ?? t("messages.textFieldDropdown.placeholder")
              }
              type="text"
              value={searchString}
              onChange={(data) => setSearchString(data.target.value)}
              disabled={isDisabled}
              onClick={() => setOpen(true)}
            />
          )}
        </div>
        <div
          className={`textfield-icon ${open ? "open" : null}  ${
            isDisabled ? "textfield-icon-disabled" : null
          }`}
          onClick={() => {
            setOpen((prevState) => !prevState);
          }}
        >
          <IconArrowDown size={14} color="--global-colors-ink-light" />
        </div>
      </div>
      {open === true && (
        <div
          className="textfield-menu-container"
          style={{
            marginTop: `${
              document.getElementById(`textfield-container-${id}`)
                .offsetHeight + 6
            }px`,
            width: `${
              document.getElementById(`textfield-container-${id}`).offsetWidth
            }px`,
          }}
        >
          <div className="add-all-button">
            <Button
              aspect="ghost"
              label={buttonLabel}
              size="small"
              onClick={handleButtonClick}
            />
          </div>
          <div className="textfield-list-container">
            {values
              .filter(
                (val) =>
                  !clickedItems.some(
                    (clickedVal) => clickedVal.id === val.id
                  ) &&
                  !removedValues.some((removedVal) => removedVal.id === val.id)
              )
              .concat(removedValues)
              .filter((val) =>
                val.title.toLowerCase().includes(searchString.toLowerCase())
              )
              .map((el, index) => {
                if (!items.includes(el)) {
                  return (
                    <div
                      key={index}
                      className="textfield-list"
                      onClick={() => {
                        onClickAdd(el);
                        handleItem(el);
                        setSearchString("");
                      }}
                    >
                      {hasUserBox ? (
                        <UserBox
                          id={index}
                          size="large"
                          status={el.status}
                          title={el.title}
                          iconTitle={el.iconTitle}
                          subTitle={el.subTitle}
                          iconSubTitle={el.iconSubTitle}
                          data={el.data}
                          icon={el.icon}
                        />
                      ) : (
                        <div className="textfield-list-item">{el.title}</div>
                      )}
                    </div>
                  );
                }
                return null;
              })}
            {hasAddContact && (
              <div className="add-all-button">
                <Button
                  aspect="ghost"
                  label={"+ " + t("admin.notifications.addressBook.addContact")}
                  size="small"
                  onClick={() => onAddContactClick()}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
