import {
  PayloadAction,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import { GTFleetSuccessCodes } from "../../../config/GTFleetSuccessCodes";
import { getReportsIOAsync } from "./reportsIOSlice";

export type ReportsIOSummary = {
  id: number;
  duration: number;
  eventDistance: number;
  distanceCovered: number;
};

const reportsIOSummaryAdapter = createEntityAdapter<ReportsIOSummary>({
  selectId: (reportsIOSummary) => reportsIOSummary.id,
});

export const reportsIOSummarySlice = createSlice({
  name: "reportsIOSummary",
  initialState: reportsIOSummaryAdapter.getInitialState({
    status: "idle",
    reasonCode: "",
  }),
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    reportsIOSummaryEmptyState: (state) => {
      reportsIOSummaryAdapter.setAll(state, []);
      state.reasonCode = "";
      state.status = "idle";
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      // getReportsIOAsync extra reducers
      .addCase(
        getReportsIOAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          reportsIOSummaryAdapter.setAll(
            state,
            action.payload.reportsIOSummary ?? {}
          );
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.GET;
        }
      )
      .addCase(getReportsIOAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(getReportsIOAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = "";
      });
  },
});

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const reportsIOSummarySelectors =
  reportsIOSummaryAdapter.getSelectors<RootState>(
    (state) => state.reportsIOSummary
  );

export const selectReportsIOSummarySliceStatus = (state: any) =>
  state.reportsIOSummary.status;
export const selectReportsIOSummarySliceReasonCode = (state: any) =>
  state.reportsIOSummary.reasonCode;
export const { reportsIOSummaryEmptyState } = reportsIOSummarySlice.actions;

export default reportsIOSummarySlice.reducer;
