import { t } from "i18next";
import _ from "lodash";
import { useEffect, useRef, useState } from "react";
import {
  Location,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { store } from "../../app/store";
import { Button } from "../../ui/Button/Button";
import Form from "../../ui/Forms/Form";
import SearchField from "../../ui/Forms/SearchField";
import { IconAdd } from "../../ui/Icon/Line/Add";
import { getQueryString } from "../../utils/Utils";
import "./AdminFleetsFilterBar.css";
import { getFilteredFleetsAsync } from "./fleetViewsSlice";

interface QueryParams {
  [paramName: string]: any;
}

interface AdminFleetsFilterBarProps {
  callback: (buildQueryParam: string) => any;
}

export const AdminFleetsFilterBar: React.FC<AdminFleetsFilterBarProps> = ({
  callback,
}) => {
  const queryParamsRef = useRef<QueryParams>({});
  const navigate = useNavigate();
  let queryParams: QueryParams = queryParamsRef.current;

  const [fleetStringSearch, setFleetStringSearch] = useState<string>("");
  // Handle routes
  const [searchParams] = useSearchParams();
  const location: Location = useLocation();

  useEffect(() => {
    const searchFleet = new Map();
    const currentSearchParams =
      searchParams.toString() !== "" ? searchParams : null;
    if (!!currentSearchParams && _.isEmpty(queryParams)) {
      const size = currentSearchParams.get("size");
      searchFleet.set("size", size ?? "10");

      // Search Field
      const nameSearch = currentSearchParams.get("name");
      if (nameSearch) {
        setFleetStringSearch(nameSearch);
        searchFleet.set("name", nameSearch);
      }
    }

    if (searchFleet.size === 0 && _.isEmpty(queryParams)) {
      searchFleet.set("size", "10");
    }
    if (searchFleet.size > 0) {
      handleChanges(searchFleet);
    }
  }, [location]);

  const handleChanges = (params: Map<string, string[] | string>): void => {
    let queryString = "";
    let executeQuery = false;
    if (!!params) {
      params.forEach((value, key) => {
        if (!!value && value.length > 0) {
          queryParams[key] = value;
          executeQuery = true;
        } else {
          if (queryParams.hasOwnProperty(key)) {
            delete queryParams[key];
            executeQuery = true;
          }
        }
      });
      queryString = getQueryString(queryParams);
    }
    // effettuare chiamata API con parametri in query string
    if (executeQuery) {
      store.dispatch(
        getFilteredFleetsAsync({ queryParams: queryString, isActive: false })
      );
      navigate({
        pathname: "/admin/fleets",
        search: queryString,
      });
      callback(queryString);
    }
  };

  const debouncedSearch = useRef(
    _.debounce(async (val) => {
      setFleetStringSearch(val);
      const searchFleetName = new Map();
      searchFleetName.set("name", val);
      handleChanges(searchFleetName);
    }, 1000)
  ).current;

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  async function handleSearch(val: string) {
    await debouncedSearch(val);
  }

  return (
    <div className="fleet-filterbar-row">
      <div className="fleet-filterbar-search">
        <Form>
          <SearchField
            name="search"
            id="fleets"
            size="small"
            placeholder={t("admin.groups.groupFilterBar.search")}
            value={fleetStringSearch}
            onChange={(val: string) => {
              handleSearch(val);
            }}
          />
        </Form>
      </div>
      <div className="fleet-filterbar-addFleet">
        <Button
          aspect="primary"
          size="small"
          label={t("admin.groups.groupFilterBar.addGroup")}
          onClick={() => navigate("/admin/fleets/add-fleet")}
        >
          <IconAdd size={14} color="--global-colors-ink-white" />
        </Button>
      </div>
    </div>
  );
};
