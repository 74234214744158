import "./ContentSidebar.css";

interface ContentSidebarProps {
  children: object;
}

function ContentSidebar({ children }: ContentSidebarProps) {
  return <div className="content-sidebar">{children}</div>;
}

export default ContentSidebar;
