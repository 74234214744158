import HttpClient from "./HttpClient";

export default class FileService extends HttpClient {
  constructor() {
    super(true);
  }

  uploadFile(file: any, type: string) {
    let data = new FormData();
    data.append("file", file ? file : "");
    return this.instance.post("/file/upload?type=" + type, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  deleteFile(file: any, type: string) {
    let data = new FormData();
    data.append("file", file ? file : "");
    return this.instance.delete("/file/delete/" + type + "/" + file);
  }

  uploadFileBase64(file: any) {
    return this.instance.post("/file/uploadBase64File", {
      file: file,
    });
  }

  downloadFile(file: any) {
    return this.instance.get("/file/download/" + file, {
      headers: { Accept: "application/hal+json" },
    });
  }
}
