import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import _ from "lodash";
import { normalize } from "normalizr";
import { RootState } from "../../app/store";
import { Config } from "../../config/Config";
import { GTFleetSuccessCodes } from "../../config/GTFleetSuccessCodes";
import { getErrorCodes } from "../../utils/Utils";
import { getFleetAsync } from "../fleet/fleetsSlice";
import { getReportsIOAsync } from "../report/vehicle/reportsIOSlice";
import { getRoutesHistoryAsync } from "../route/routesHistorySlice";
import {
  getFilteredVehiclesDetailsAsync,
  getVehiclesDetailsAsync,
} from "../vehicle/vehiclesSlice";
import {
  getFilteredVehiclesStatusAsync,
  getFilteredVehiclesStatusPaginationAsync,
  getVehicleAsync,
  getVehiclesAsync,
} from "../vehicle/vehiclesStatusSlice";
import { getVehiclesViewAsync } from "../vehicle/vehiclesStatusViewSlice";
import { driverSchema, driversSchema } from "./driverNormalization";
import DriversRepository from "./driversRepository";
import {
  getDriversFleetAsync,
  getFilteredDriversStatusAndDetailsAsync,
  getFilteredDriversStatusAndDetailsPaginationAsync,
} from "./driversStatusSlice";

//#region Type
export const driverStatusValues = { ACTIVE: "ACTIVE", INACTIVE: "INACTIVE" };
export type DriverStatusType = keyof typeof driverStatusValues;

export type DriverView = {
  id: number;
  firstName: string;
  lastName: string;
};

export type Driver = {
  id: number;
  username: string;
  firstName: string;
  address: string;
  zip: string;
  avatar: string;
  city: string;
  province: string;
  country: string;
  dateOfBirth: string;
  email: string;
  fiscalCode: string;
  fleet: number;
  lastName: string;
  licenseExpirationDate: string;
  licenseId: string;
  licenseType: string;
  phoneNumber: string;
  phoneNumberPrefix: string;
  linkedToAccount: boolean;
  status: DriverStatusType;
  tachographCard: string;
  mac: string;
  tenant: number;
  vehicle: number;
};
//#endregion Type

//#region API

export const getFilteredDriversDetailsPaginationAsync = createAsyncThunk(
  "drivers/getFilteredDriversDetailsPaginationAsync",
  async (data: { queryParams?: string }, { rejectWithValue, dispatch }) => {
    try {
      const driversRepository = new DriversRepository();
      const response = await driversRepository.getDriversDetails(
        data.queryParams
      );
      const drivers = _.get(response, Config.DRIVERS_DETAILS_RESPONSE_PATH);
      const totalPages = _.get(
        response,
        Config.DRIVERS_DETAILS_TOTAL_PAGES_RESPONSE_PATH
      );
      if (totalPages) {
        dispatch(setNumberOfPages(totalPages));
      }
      const normalizedData = normalize(drivers, driversSchema);
      return normalizedData.entities;
    } catch (err: any) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data.message);
    }
  }
);

export const getDriverDetailsAsync = createAsyncThunk(
  "drivers/getDriverDetails",
  async (driverId: number, { rejectWithValue }) => {
    try {
      const driversRepository = new DriversRepository();
      const response = await driversRepository.getDriverDetails(driverId);
      const driver = _.get(response, Config.DRIVER_DETAILS_RESPONSE_PATH);
      const normalizedData = normalize(driver, driverSchema);
      return normalizedData.entities;
    } catch (err: any) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data.message);
    }
  }
);

export const activateDriverAsync = createAsyncThunk(
  "drivers/activateDriver",
  async (driverId: number, { rejectWithValue }) => {
    const driversRepository = new DriversRepository();
    try {
      const response = await driversRepository.partiallyUpdateDriver(
        driverId,
        false,
        {
          status: driverStatusValues.ACTIVE,
        }
      );
      const driver = _.get(response, Config.DRIVER_DETAILS_RESPONSE_PATH);
      const normalizedData = normalize(driver, driverSchema);
      return normalizedData.entities;
    } catch (err: any) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data.message);
    }
  }
);

export const deactivateDriverAsync = createAsyncThunk(
  "drivers/deactivateDriver",
  async (driverId: number, { rejectWithValue }) => {
    const driversRepository = new DriversRepository();
    try {
      const response = await driversRepository.partiallyUpdateDriver(
        driverId,
        false,
        {
          status: driverStatusValues.INACTIVE,
        }
      );
      const driver = _.get(response, Config.DRIVER_DETAILS_RESPONSE_PATH);
      const normalizedData = normalize(driver, driverSchema);
      return normalizedData.entities;
    } catch (err: any) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data.message);
    }
  }
);

export const updateDriverAsync = createAsyncThunk(
  "vehicles/updateDriver",
  async (
    data: { id: number; removedVehicle: boolean; driver: Driver },
    { rejectWithValue }
  ) => {
    try {
      const driversRepository = new DriversRepository();
      const response = await driversRepository.partiallyUpdateDriver(
        data.id,
        data.removedVehicle,
        {
          ...data.driver,
          vehicle: { id: data.driver.vehicle ?? null },
          fleet: { id: data.driver.fleet ?? null },
        }
      );
      const driver = _.get(response, Config.DRIVER_DETAILS_RESPONSE_PATH);
      const normalizedData = normalize(driver, driverSchema);
      return normalizedData.entities;
    } catch (err: any) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data.message);
    }
  }
);

export const createDriverAsync = createAsyncThunk(
  "vehicles/createDriver",
  async (data: { driver: Driver }, { rejectWithValue }) => {
    try {
      const driversRepository = new DriversRepository();
      const response = await driversRepository.createDriver({
        ...data.driver,
        vehicle: { id: data.driver.vehicle ?? null },
        fleet: { id: data.driver.fleet ?? null },
        linkedToAccount:
          data.driver.username !== undefined && data.driver.username !== "",
      });
      const driver = _.get(response, Config.DRIVER_DETAILS_RESPONSE_PATH);
      const normalizedData = normalize(driver, driverSchema);
      return normalizedData.entities;
    } catch (err: any) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data.message);
    }
  }
);
//#endregion API

//#region Custom Functions
function setFilteredData(state: any, action: PayloadAction<any>) {
  action.payload?.driver
    ? driversAdapter.setAll(state, action.payload.driver)
    : driversAdapter.setAll(state, []);
}
//#endregiorn Custom Functions

//#region Slice
const driversAdapter = createEntityAdapter<Driver>({
  selectId: (driver) => driver.id,
  sortComparer: (a, b) => a.id - b.id,
});

export const driversSlice = createSlice({
  name: "drivers",
  initialState: driversAdapter.getInitialState({
    status: "idle",
    reasonCode: "",
    totalPages: 0,
  }),
  reducers: {
    setNumberOfPages: (state, action: PayloadAction<number>) => {
      state.totalPages = action.payload;
    },
    driversEmptyState: (state) => {
      driversAdapter.setAll(state, []);
      state.reasonCode = "";
      state.status = "idle";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getFilteredDriversStatusAndDetailsAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          setFilteredData(state, action);
        }
      )
      .addCase(
        getFilteredDriversDetailsPaginationAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          driversAdapter.upsertMany(state, action.payload.driver ?? []);
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.GET;
        }
      )
      .addCase(
        getFilteredDriversDetailsPaginationAsync.rejected,
        (state: any, action: PayloadAction<any>) => {
          state.status = "failed";
          state.reasonCode = getErrorCodes(action.payload);
        }
      )
      .addCase(
        getFilteredDriversDetailsPaginationAsync.pending,
        (state: any) => {
          state.status = "loading";
        }
      )
      .addCase(getDriverDetailsAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(
        getDriverDetailsAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          driversAdapter.upsertMany(state, action.payload.driver ?? []);
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.GET;
        }
      )
      .addCase(
        getDriverDetailsAsync.rejected,
        (state: any, action: PayloadAction<any>) => {
          state.status = "failed";
          state.reasonCode = getErrorCodes(action.payload);
        }
      )
      .addCase(
        updateDriverAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          driversAdapter.upsertMany(state, action.payload.driver);
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.PATCH;
        }
      )
      .addCase(
        updateDriverAsync.rejected,
        (state: any, action: PayloadAction<any>) => {
          state.status = "failed";
          state.reasonCode = getErrorCodes(action.payload);
        }
      )
      .addCase(updateDriverAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(
        createDriverAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          driversAdapter.upsertMany(state, action.payload.driver);
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.POST;
        }
      )
      .addCase(
        createDriverAsync.rejected,
        (state: any, action: PayloadAction<any>) => {
          state.status = "failed";
          state.reasonCode = getErrorCodes(action.payload);
        }
      )
      .addCase(createDriverAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(activateDriverAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(
        activateDriverAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          driversAdapter.upsertMany(state, action.payload.driver);
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.PATCH;
        }
      )
      .addCase(
        activateDriverAsync.rejected,
        (state: any, action: PayloadAction<any>) => {
          state.status = "failed";
          state.reasonCode = getErrorCodes(action.payload);
        }
      )
      .addCase(deactivateDriverAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(
        deactivateDriverAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          driversAdapter.upsertMany(state, action.payload.driver);
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.PATCH;
        }
      )
      .addCase(
        deactivateDriverAsync.rejected,
        (state: any, action: PayloadAction<any>) => {
          state.status = "failed";
          state.reasonCode = getErrorCodes(action.payload);
        }
      )
      //#endregion Entity Reducers

      //#region External Entity Reducers
      .addCase(
        getVehiclesViewAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          action.payload?.driver &&
            driversAdapter.upsertMany(state, action.payload.driver);
        }
      )
      .addCase(
        getVehiclesAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          action.payload?.driver &&
            driversAdapter.upsertMany(state, action.payload.driver);
        }
      )
      .addCase(
        getVehicleAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          action.payload?.driver &&
            driversAdapter.upsertMany(state, action.payload.driver);
        }
      )
      .addCase(
        getVehiclesDetailsAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          action.payload?.driver &&
            driversAdapter.upsertMany(state, action.payload.driver);
        }
      )
      .addCase(
        getFilteredVehiclesStatusAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          action.payload?.driver &&
            driversAdapter.upsertMany(state, action.payload.driver);
        }
      )
      .addCase(
        getFilteredVehiclesDetailsAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          action.payload?.driver &&
            driversAdapter.upsertMany(state, action.payload.driver);
        }
      )
      // getFilteredVehiclesStatusPaginationAsync extra reducers
      .addCase(
        getFilteredVehiclesStatusPaginationAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          setFilteredData(state, action);
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.GET;
        }
      )
      // getRoutesHistoryAsync extra reducers
      .addCase(
        getRoutesHistoryAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          if (action.payload?.driver) {
            driversAdapter.upsertMany(state, action.payload.driver);
          }
        }
      )
      // getReportsAsync extra reducers
      // getRoutesHistoryAsync extra reducers
      .addCase(
        getReportsIOAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          setFilteredData(state, action);
        }
      )
      // getFilteredDriversStatusAndDetailsAsync extra reducers
      .addCase(
        getFilteredDriversStatusAndDetailsPaginationAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          setFilteredData(state, action);
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.GET;
        }
      ) // getFleetAsync
      .addCase(
        getFleetAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          driversAdapter.upsertMany(state, action.payload?.driver ?? []);
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.GET;
        }
      )
      .addCase(
        getDriversFleetAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          action.payload?.driver &&
            driversAdapter.upsertMany(state, action.payload.driver);
        }
      );
    //#endregion External Entity Reducers
  },
});
//#endregion Slice

//#region Status
export const driversSelectors = driversAdapter.getSelectors<RootState>(
  (state) => state.drivers
);

export const selectDriversSliceStatus = (state: any) => state.drivers.status;
export const selectDriversSliceReasonCode = (state: any) =>
  state.drivers.reasonCode;
export const selectDriversSlicePage = (state: any) => state.drivers.totalPages;

export const { driversEmptyState, setNumberOfPages } = driversSlice.actions;

export default driversSlice.reducer;
