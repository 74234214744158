import { t } from "i18next";
import { FeedbackModal } from "../../../ui/Modal/CustomModals/FeedbackModal";
import { Geofence } from "../geofenceSlice";

interface DeleteGeofenceModalProps {
  geofence: Geofence;
  open: boolean;
  onClose: (id?: number) => void;
}

const DeleteGeofenceModal: React.FC<DeleteGeofenceModalProps> = ({
  geofence,
  open,
  onClose,
}) => {
  const nameGeofence = geofence.name.split(",")[0];
  const addressGeofence =
    geofence.name.split(",")[1] + ", " + geofence.name.split(",")[2];

  function deleteGeofence() {
    onClose(geofence.id);
  }
  function close() {
    onClose();
  }

  return (
    <FeedbackModal
      open={open}
      title={`${t("admin.geofences.deleteGeofence.title")}`}
      desc={
        `${t("admin.geofences.deleteGeofence.desc")
          .replace("*name", nameGeofence)
          .replace("*address", addressGeofence)}` +
        "\n" +
        `${t("admin.geofences.deleteGeofence.actionPermanent")}`
      }
      primaryLabel={`${t("common.confirm")}`}
      isDanger={true}
      primaryAction={deleteGeofence}
      secondaryLabel={`${t("common.cancel")}`}
      secondaryAction={close}
      closeAction={close}
    />
  );
};

export default DeleteGeofenceModal;
