import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../Button/Button";
import Form from "../../Forms/Form";
import TextField from "../../Forms/TextField";
import { IconUpload } from "../../Icon/Line/Upload";
import { LabelText } from "../../LabelText/LabelText";
import { ModalBody } from "../ModalBody";
import { ModalFooter } from "../ModalFooter";
import { ModalWrapper } from "../ModalWrapper";
import { FeedbackModal } from "./FeedbackModal";
import "./TicketModal.css";

export const TicketModal = ({ showTicketModal, onClose }) => {
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const { t } = useTranslation();
  const [user, setUser] = useState("");
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errorList, setErrorList] = useState([]);

  const clearFields = () => {
    setUser("");
    setEmail("");
    setCompany("");
    setPhone("");
    setMessage("");
  };

  return (
    <div className="ticket-container">
      <ModalWrapper open={showTicketModal} closeAction={onClose}>
        <div className="ticket">
          <ModalBody
            title={t("customModals.ticket.title")}
            desc={t("customModals.ticket.desc")}
          >
            <div className="left-forms">
              <Form>
                <LabelText text={t("customModals.ticket.user")} />
                <TextField
                  id="User"
                  placeholder={t("customModals.ticket.userPlaceholder")}
                  validate="alphabetic"
                  errorList={setErrorList}
                  events={{ onChange: (data) => setUser(data.value) }}
                  value={user}
                ></TextField>
              </Form>
            </div>
            <div className="right-forms">
              <Form>
                <LabelText text={t("customModals.ticket.eMail")} />
                <TextField
                  id="E-mail"
                  placeholder={t("customModals.ticket.eMailPlaceholder")}
                  validate="email"
                  errorList={setErrorList}
                  events={{ onChange: (data) => setEmail(data.value) }}
                  value={email}
                ></TextField>
              </Form>
            </div>
            <div className="left-forms">
              <LabelText text={t("customModals.ticket.company")} />
              <Form>
                <TextField
                  id="Company"
                  placeholder={t("customModals.ticket.companyPlaceholder")}
                  validate="alphaNumeric"
                  errorList={setErrorList}
                  events={{ onChange: (data) => setCompany(data.value) }}
                  value={company}
                ></TextField>
              </Form>
            </div>
            <div className="right-forms">
              <LabelText text={t("customModals.ticket.phone")} />
              <Form>
                <TextField
                  id="Phone"
                  placeholder={t("customModals.ticket.phonePlaceholder")}
                  validate="phoneNumber"
                  errorList={setErrorList}
                  events={{ onChange: (data) => setPhone(data.value) }}
                  value={phone}
                ></TextField>
              </Form>
            </div>
            <div className="central-forms">
              <LabelText text={t("customModals.ticket.message")} />
              <Form>
                <TextField
                  id="Message"
                  type="textarea"
                  placeholder={t("customModals.ticket.messagePlaceholder")}
                  events={{ onChange: (data) => setMessage(data.value) }}
                  value={message}
                ></TextField>
              </Form>
            </div>
            {false && (
              <div className="left-forms">
                <Form>
                  <Button
                    aspect="secondary"
                    size="regular"
                    label={t("customModals.ticket.attachFileButton")}
                    onClick={() => {
                      alert("Attach file successed");
                    }}
                  >
                    <IconUpload size={14} color="--global-colors-ink-light" />
                  </Button>
                </Form>
              </div>
            )}
          </ModalBody>
          <ModalFooter
            primaryLabel={t("customModals.ticket.primaryButton")}
            primaryAction={() => {
              setIsLoading(true);
              clearFields();
              onClose();
              setShowFeedbackModal(true);
            }}
            secondaryLabel={t("customModals.ticket.secondaryButton")}
            secondaryAction={onClose}
            disablePrimaryButton={
              user === "" ||
              email === "" ||
              company === "" ||
              phone === "" ||
              message === "" ||
              errorList.length !== 0
            }
            loadingPrimaryButton={isLoading}
          />
        </div>
      </ModalWrapper>
      <div className="ticket-feedback">
        <FeedbackModal
          open={showFeedbackModal}
          closeAction={setShowFeedbackModal}
          title={t("customModals.feedbackTicket.title")}
          desc={t("customModals.feedbackTicket.desc")}
          primaryLabel={t("customModals.feedbackTicket.primaryButton")}
          primaryAction={setShowFeedbackModal}
        />
      </div>
    </div>
  );
};

TicketModal.propTypes = {
  showTicketModal: PropTypes.bool,
  onClose: PropTypes.func,
};
