import { schema } from "normalizr";

export const gpsDataReportsSchema: schema.Entity<any> = new schema.Entity(
  "gpsData",
  {},
  { idAttribute: "uuid" }
);
export const gpsDatasReportSchema = new schema.Array(gpsDataReportsSchema);

export const routesSchema = new schema.Entity(
  "gpsDatas",
  {},
  { idAttribute: "id" }
);

export const routeSchema = [routesSchema];

// Schema for the event entity
const eventSchema = new schema.Entity("event", {}, { idAttribute: "id" });

export const eventsSchema = [eventSchema];
