import { ApexOptions } from "apexcharts";
import React, { useContext } from "react";
import ReactApexChart from "react-apexcharts";
import { Preferences } from "../../features/users/preference/preferencesSlice";
import UserContext from "../../features/users/userContext";
import { numberAnnotation } from "../../utils/Utils";
import "./ColumnChart.css";

interface Coordinates {
  x: string;
  y: string;
}
interface Series {
  name: string;
  data: Coordinates[];
}
interface ColumnChartProps {
  title: string;
  categories: string[];
  series: Series[];
  size: {
    width: number | string;
    height: number | string;
  };
  status?: "loading" | "failed" | "idle";
}

export const ColumnChart: React.FC<ColumnChartProps> = ({
  title,
  categories,
  series,
  size,
  status,
}) => {
  const [preferencesContext]: [Preferences] = useContext(UserContext);
  const chartLanguage: any = require("apexcharts/src/locales/" +
    (preferencesContext.language ?? "en") +
    ".json");

  let graphOption: ApexOptions = {
    chart: {
      id: "chart-id",
      locales: [chartLanguage],
      defaultLocale: preferencesContext.language ?? "en",
      height: size.height,
      width: size.width,
      type: "bar",
      toolbar: {
        show: true,
        tools: {
          download: false,
          selection: false,
          zoom: false,
        },
      },
      zoom: {
        enabled: false,
      },
      events: {
        mounted: function (chartContext, config) {
          // insert color gradient on hover mouse in column
          let svgChart = document.getElementsByTagNameNS(
            "http://www.w3.org/2000/svg",
            "svg"
          )[0];
          let gradient = document.createElementNS(
            "http://www.w3.org/2000/svg",
            "linearGradient"
          );
          gradient.setAttribute("x1", "0");
          gradient.setAttribute("x2", "0");
          gradient.setAttribute("y1", "0");
          gradient.setAttribute("y2", "1");
          let stops = [
            {
              color: "#00aaff",
              offset: "0",
              opacity: "1",
            },
            {
              color: "#00aaff",
              offset: "0.6",
              opacity: "0.4",
            },
            {
              color: "#ffffFF",
              offset: "1",
              opacity: "0.5",
            },
          ];
          for (let i = 0, length = stops.length; i < length; i++) {
            let stop = document.createElementNS(
              "http://www.w3.org/2000/svg",
              "stop"
            );
            stop.setAttribute("offset", stops[i].offset.toString());
            stop.setAttribute("stop-color", stops[i].color);
            stop.setAttribute("stop-opacity", stops[i].opacity.toString());
            gradient.appendChild(stop);
          }
          gradient.id = "hoverGradient";
          svgChart.appendChild(gradient);
        },
      },
    },
    title: {
      text: title,
      style: {
        fontFamily: "var(--global-font-regular)",
        fontWeight: "0px",
        fontSize: "14px",
        color: "var(--global-colors-ink-dark)",
      },
    },
    grid: {
      show: false,
    },
    colors: ["#00CFA2"],
    fill: {
      type: "gradient",
      gradient: {
        shade: "light",
        type: "vertical",
        shadeIntensity: 0.5,
        inverseColors: false,
        opacityFrom: 0.9,
        opacityTo: 0.5,
        colorStops: [
          {
            offset: 0,
            color: "#00CFA2",
            opacity: 1,
          },
          {
            offset: 2,
            color: "#00CFA2",
            opacity: 0.4,
          },
          {
            offset: 100,
            color: "#fff",
            opacity: 0.5,
          },
        ],
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      tickAmount: 14,
      labels: {
        rotate: 0,
        show: true,
        style: {
          colors: "#808A97",
          fontSize: "10px",
          fontFamily: "global-font-regular",
          fontWeight: "normal",
        },
        format: "dd/MM",
        formatter: function (value, timestamp, opts) {
          return new Date(value).toLocaleDateString("it", {
            day: "2-digit",
            month: "2-digit",
          });
        },
      },
      crosshairs: {
        width: 0,
        opacity: 0,
        stroke: {
          color: "transparent",
        },
        fill: {
          color: "transparent",
        },
      },
    },
    yaxis: {
      show: false,
    },
    tooltip: {
      enabled: true,
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        const unitMeasure =
          numberAnnotation(Number(series[seriesIndex][dataPointIndex]), 2, 2) +
          " " +
          (w.config.series[0].unitMeasure || "");

        return (
          '<div class="arrow-box">' +
          "<span> " +
          unitMeasure +
          "</span>" +
          "</div>"
        );
      },
    },
  };

  const classname =
    "graphLoader " + (status === "loading" ? "isActiveCircleAdmin" : "");
  return (
    <div className="mn-graph " style={{ position: "relative" }}>
      <ReactApexChart
        options={graphOption}
        series={series}
        type="bar"
        width={size.width}
        height={size.height}
        className="reactApexChart"
      />
      <div className={classname}></div>
    </div>
  );
};
