import i18next from "i18next";
import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../app/hooks";
import { store } from "../../app/store";
import { ClassPolyline } from "../../ui/LocationHistory/Polyline";
import { BaseMap } from "../../ui/Map/BaseMap";
import { MapMarkerToggle } from "../../ui/Marker/MapMarkerToggle";
import { ToastNotification } from "../../utils/ToastNotification";
import {
  RouteHistory,
  readRouteHistoryAsync,
  routesHistorySelectors,
} from "../route/routesHistorySlice";

interface CheckedProps {
  id: number;
  color: string;
  number: number;
  vehicleId: number;
  queryParams?: string;
}

interface ReportMapProps {
  id: number;
  googleMapsApiKey: string;
  zoom: number;
  latitude: number;
  longitude: number;
  checkedList: CheckedProps[];
  limit: number;
}

declare global {
  interface HTMLElement {
    msRequestFullScreen?: () => Promise<void>;
    mozRequestFullScreen?: () => Promise<void>;
    webkitRequestFullScreen?: () => Promise<void>;
  }
}

export const ReportMap: React.FC<ReportMapProps> = ({
  id,
  googleMapsApiKey,
  zoom,
  latitude,
  longitude,
  checkedList,
  limit,
}) => {
  let googleMap = window.google;
  const [map, setMap] = useState<any>();

  const [multiPolyline, setMultiPolyline] = useState<Map<number, any>>(
    new Map()
  );

  const [bounds, setBounds] = useState<google.maps.LatLngBounds>();

  useEffect(() => {
    return function cleanup() {
      multiPolyline.forEach((_value, key) => {
        const polyline = multiPolyline.get(key);
        polyline.onRemove();
        multiPolyline.delete(key);
      });
      setMultiPolyline(multiPolyline);
    };
  }, []);

  useEffect(() => {
    if (checkedList.length >= limit) {
      ToastNotification({
        toastId: "moreThan10",
        status: "default",
        description: i18next.t("report.toastNotification.moreThan10"),
      });
    }
  }, [checkedList]);

  useEffect(() => {
    if (multiPolyline.size > 0) {
      multiPolyline.forEach((_value, key) => {
        if (!checkedList.some((x) => x.id === key)) {
          const polyline = multiPolyline.get(key);
          polyline.onRemove();
          multiPolyline.delete(key);
        }
      });
      setMultiPolyline(multiPolyline);
      if (multiPolyline.size === 0) {
        const initialBounds = new google.maps.LatLngBounds(
          new google.maps.LatLng(50.4419, 10.1419),
          new google.maps.LatLng(36.4419, 14.1419)
        );
        map.fitBounds(initialBounds, 100);
        setBounds(initialBounds);
      }
    }

    // For each checked element a route history object should be request
    checkedList?.forEach((el) => {
      if (!multiPolyline.has(el.id) && !!el.queryParams) {
        if (!routesHistorySelectors.selectById(store.getState(), el.id)) {
          store.dispatch(
            readRouteHistoryAsync({
              vehicleId: el.vehicleId,
              routeHistoryId: el.id,
              queryParams: el.queryParams,
            })
          );
        }
      }
    });
  }, [checkedList]);

  /**
   * If map icons are loaded or not
   */
  const [mapIconsLoaded, setMapIconsLoaded] = useState(false);
  useEffect(() => {
    if (map && mapIconsLoaded) {
      map.setOptions({
        draggable: false,
        zoomControl: false,
        scrollwheel: false,
        disableDoubleClickZoom: true,
        draggableCursor: null,
      });
      const initialBounds = new google.maps.LatLngBounds(
        new google.maps.LatLng(50.4419, 10.1419),
        new google.maps.LatLng(36.4419, 14.1419)
      );
      map.fitBounds(initialBounds, 100);
      setBounds(initialBounds);
    }
  }, [map, mapIconsLoaded]);

  const routesHistory: RouteHistory[] =
    useAppSelector(routesHistorySelectors.selectAll) ?? ({} as RouteHistory[]);

  /*
   * Gestione polyline
   */
  useEffect(() => {
    if (
      map &&
      mapIconsLoaded &&
      !!routesHistory &&
      routesHistory.length > 0 &&
      checkedList &&
      checkedList.length > 0
    ) {
      const trackBounds = new window.google.maps.LatLngBounds();
      checkedList.forEach((checkedItem) => {
        const lastSelectedTrackId = checkedItem.id;
        const lastSelectedTrackInfo = routesHistorySelectors.selectById(
          store.getState(),
          lastSelectedTrackId
        );
        if (lastSelectedTrackInfo) {
          if (!multiPolyline.has(lastSelectedTrackId)) {
            let polyline = ClassPolyline({
              googleMap: googleMap,
              map: map,
              batchPolyLine: lastSelectedTrackInfo.routeStates,
              color: getComputedStyle(
                document.documentElement
              ).getPropertyValue(checkedItem?.color),
              hideEventColors: true,
              viewPointMarkers: false,
              viewEventMarkers: true,
              polylineMarkerStart: (
                <MapMarkerToggle
                  color={checkedItem?.color}
                  number={`${checkedItem.number + 1}`}
                  letter={"A"}
                />
              ),
              polylineMarkerEnd: (
                <MapMarkerToggle
                  color={checkedItem?.color}
                  number={`${checkedItem.number + 1}`}
                  letter={"B"}
                />
              ),
            });

            multiPolyline.set(lastSelectedTrackId, polyline);
          }

          lastSelectedTrackInfo.routeStates.forEach((routeState) => {
            trackBounds.extend(
              new window.google.maps.LatLng(
                routeState?.dynamicFields.latitude,
                routeState?.dynamicFields.longitude
              )
            );
          });
        }
      });
      if (!trackBounds.isEmpty()) {
        map.fitBounds(trackBounds, 100);
        setBounds(trackBounds);
      } else {
        map.fitBounds(bounds, 100);
      }
    }
  }, [checkedList, routesHistory]);

  return (
    <BaseMap
      id={id}
      googleMapsApiKey={googleMapsApiKey}
      zoom={zoom}
      latitude={latitude}
      longitude={longitude}
      getMap={setMap}
      showMarkers={true}
      getIsMapIdle={setMapIconsLoaded}
    />
  );
};
