import _ from "lodash";
import React from "react";
import "./ClusterMarkerEvent.css";

interface ColorObject {
  color: string;
  deg: number;
}

interface DataObject {
  status: string;
  numbers: any;
  color: string;
}

interface ClusterMarkerEventProps {
  data?: DataObject[];
  icon?: any;
  text: string;
  hasChart: boolean;
  onClick?: (e: any) => any;
  hasParking?: boolean;
  letter?: string;
}

export const ClusterMarkerEvent: React.FC<ClusterMarkerEventProps> = ({
  data,
  icon,
  text,
  onClick,
  hasChart,
  letter,
  hasParking = false,
}) => {
  let corners: ColorObject[] = [];
  if (data && data?.length > 0) {
    let totalMarkers = data.reduce(
      (total: number, obj: DataObject) => obj.numbers + total,
      0
    );

    data.forEach(
      (object: DataObject) =>
        object.numbers !== 0 &&
        corners.push({
          deg: _.round((360 * object.numbers) / totalMarkers),
          color: object.color,
        })
    );
  }

  function isLargeNumber(text: string) {
    if (/^\d{3}$/.test(text)) {
      return "-large";
    } else {
      return "";
    }
  }

  return (
    <div
      onClick={onClick}
      className={`cluster-event-marker${isLargeNumber(text)}`}
    >
      <div
        className={
          hasChart
            ? `cluster-event-marker-inner${isLargeNumber(text)}`
            : "cluster-event-marker-inner-simple"
        }
        style={
          hasChart
            ? {
                color: `var(--global-colors-ink-dark)`,
                background: `conic-gradient(${
                  corners[0]
                    ? `${corners[0].color} 0deg ${corners[0].deg}deg`
                    : ""
                } ${
                  corners[1]
                    ? `, ${corners[1].color} ${corners[0].deg}deg ${
                        corners[0].deg + corners[1].deg
                      }deg`
                    : ""
                }
                ${
                  corners[2]
                    ? `, ${corners[2].color} ${
                        corners[0].deg + corners[1].deg
                      }deg ${
                        corners[0].deg + corners[1].deg + corners[2].deg
                      }deg`
                    : ""
                })`,
              }
            : {
                fontSize: "16px",
                color: `var(--global-colors-ui-white)`,
                background: `var(--global-colors-ui-primary)`,
              }
        }
      >
        <div
          style={{
            top: "10px",
            position: "relative",
            fontFamily: "var(--global-font-semi-bold)",
            fontSize:
              text.length > 2
                ? "var(--global-font-size-xxs)"
                : "var(--global-font-size-sm)",
          }}
        >
          {icon
            ? icon
            : text.length > 2 && !text.includes("/")
            ? text.length > 3 && !text.includes("/")
              ? "1k+"
              : "99+"
            : text}
        </div>
      </div>
      {hasParking && (
        <div className="parking-icon">
          <span className="label-led">{letter}</span>
        </div>
      )}
    </div>
  );
};
