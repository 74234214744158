import { schema } from "normalizr";
import { userInfoSchema } from "../users/userInfoNormalization";

export const notificationCategorySchema: schema.Entity<any> = new schema.Entity(
  "notificationCategories",
  {},
  {
    idAttribute: "id",
  }
);

export const unReadNotificationsSchema: schema.Entity<number> =
  new schema.Entity<number>("unReadNotifications");

export const notificationCategorysSchema = new schema.Array(
  notificationCategorySchema
);

export const notificationEventSchema: schema.Entity<any> = new schema.Entity(
  "notification",
  {
    user: userInfoSchema,
    notificationCategory: notificationCategorySchema,
  },
  {
    idAttribute: "id",
  }
);

export const notificationEventsSchema = new schema.Array(
  notificationEventSchema
);
