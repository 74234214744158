import ReactTooltip from "react-tooltip";
import "./Tooltip.css";

export const Tooltip = (props: { size?: "regular" | "small" }) => {
  const { size = "small" } = props;
  return (
    <ReactTooltip
      id="tooltip"
      delayShow={0}
      delayUpdate={0}
      delayHide={0}
      className={`tooltip-style__${size}`}
    />
  );
};

/******
 * For implementation:
 * declares component <Tooltip /> in the section where you want to use it.
 * Add properties "data-for="tooltip" " and "data-tip=text.." to
 * the div, span, ect. to associate the tooltip.
 *
 * For more info:
 * https://www.npmjs.com/package/react-tooltip
 */
