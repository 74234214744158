import i18next from "i18next";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Dropdown } from "../Dropdown/Dropdown";
import { IconAdd } from "../Icon/Line/Add";
import { IconCarInsurance } from "../Icon/Line/CarInsurance";
import { IconMinus } from "../Icon/Line/Minus";
import "./ScheduleInsurance.css";

interface DropDownItem {
  label: string;
}
interface ScheduleInsuranceProps {
  defaultCompany?: string;
  open?: boolean;
  typeIsEmpty?: boolean;
  companies?: DropDownItem[];
  setIsOpened?: (e: any) => any;
  getValue?: (e: any) => any;
}

export const ScheduleInsurance: React.FC<ScheduleInsuranceProps> = ({
  defaultCompany,
  open,
  typeIsEmpty,
  companies,
  setIsOpened,
  getValue,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(open ?? false);
  const [company, setCompany] = useState(defaultCompany ?? "");
  const [selectedCompany, setSelectedCompany] = useState<string>(
    defaultCompany ?? ""
  );

  useEffect(() => {
    if (company !== "" && getValue && !typeIsEmpty) {
      setIsOpen(open ?? false);
      setSelectedCompany(company);
    }
  }, [open, defaultCompany]);

  const toggleGroup = () => {
    setIsOpened && setIsOpened(5);
    setIsOpen((prevState) => !prevState);
  };

  const handleReset = (e: any) => {
    setCompany("");
    setSelectedCompany("");
    if (getValue) {
      getValue({});
    }
    e.stopPropagation();
  };

  return (
    <>
      <div
        className={isOpen ? "schedule-insurance-simple" : "schedule-insurance"}
        onClick={() => {
          if (company !== "" && getValue && !typeIsEmpty) {
            setSelectedCompany(company);
          }
          toggleGroup();
        }}
      >
        <div className="schedule-insurance-button">
          <div className="schedule-insurance-circle">
            <IconCarInsurance size={14} color="--global-colors-ui-primary" />
          </div>
          <div className="schedule-insurance-label">
            {selectedCompany !== "" && (
              <>
                <span className="schedule-insurance-pretitle">
                  {i18next.t("schedule.insurancePreTitle")}
                </span>
                <br />
              </>
            )}
            <span className="schedule-insurance-title">
              {selectedCompany === ""
                ? i18next.t("schedule.insurancePreTitle")
                : selectedCompany}
            </span>
          </div>

          <span className="schedule-insurance-icon">
            {isOpen ? (
              <>
                <div className="schedule-insurance-reset" onClick={handleReset}>
                  {i18next.t("common.reset")}
                </div>
                <IconMinus size={14} color="--global-colors-ui-primary" />
              </>
            ) : (
              <IconAdd size={14} color="--global-colors-ui-primary" />
            )}
          </span>
        </div>
      </div>
      {isOpen && companies && (
        <div className="schedule-insurance-body">
          <span className="schedule-insurance-selection">
            {i18next.t("schedule.insuranceSelection")}
          </span>
          <div className="schedule-insurance-dropdown">
            <Dropdown
              options={companies}
              placeholder={i18next.t("schedule.insurancePlaceholder")}
              size="small"
              itemAttribute="label"
              onChange={(val) => {
                setCompany(val[0].label);
                if (getValue) {
                  getValue(val[0].label);
                }
              }}
              value={{ label: `${company}` }}
              hasSearch={true}
            />
          </div>
        </div>
      )}
    </>
  );
};

ScheduleInsurance.propTypes = {
  defaultCompany: PropTypes.string,
  open: PropTypes.bool,
  typeIsEmpty: PropTypes.bool,
  setIsOpened: PropTypes.func,
  getValue: PropTypes.func,
};

ScheduleInsurance.defaultProps = {
  open: false,
};
