import i18next, { t } from "i18next";
import _ from "lodash";
import PropTypes from "prop-types";
import { useMemo, useState } from "react";
import { ToastNotification } from "../../../utils/ToastNotification";
import { DraggableList } from "../../DraggableList/DraggableList";
import { Dropdown } from "../../Dropdown/Dropdown";
import { Checkbox } from "../../Forms/Checkbox";
import Form from "../../Forms/Form";
import TextField from "../../Forms/TextField";
import { IconEdit } from "../../Icon/Line/Edit";
import { ModalBody } from "../ModalBody";
import { ModalFooter } from "../ModalFooter";
import { ModalWrapper } from "../ModalWrapper";
import "./CustomizeColumnsModal.css";
import { FeedbackModal } from "./FeedbackModal";

export const CustomizeColumnsModal = ({
  isOpen,
  columns,
  presets,
  onClose,
  handleDelete,
}) => {
  const [open, setOpen] = useState(isOpen);
  const [isChecked, setIsChecked] = useState(false);
  const [checkedList, setCheckedList] = useState([]);
  const [errorList, setErrorList] = useState([]);
  const [itemToRemove, setItemToRemove] = useState("");
  const [presetName, setPresetName] = useState("");

  const clearFields = () => {
    setPresetName("");
    setIsChecked(false);
    setCheckedList([]);
    columns
      .filter((column) => column.checked)
      .map((el) => (el.checked = false));
  };

  const onCheckboxChange = (el, index) => {
    let resultArray = [];
    if (!el.checked) {
      //if checked (true), then add this id into checkedList
      el.checked = true;
      resultArray = checkedList.filter((CheckedId) => CheckedId.id !== index);
      resultArray.push({
        id: index,
        name: el.name,
        value: el.value,
        checked: el.checked,
      });
    } //if not checked (false), then remove this id from checkedList
    else {
      el.checked = false;
      resultArray = checkedList.filter((CheckedId) => CheckedId.id !== index);
    }
    setCheckedList(resultArray);
    if (resultArray.length === 8) {
      ToastNotification({
        toastId: "customColumnsAlert",
        status: "default",
        description: i18next.t("table.customColumnsAlert"),
      });
    }
  };

  const getLastSelected = useMemo(() => {
    let lastSelected = presets.find((element) => element.name === undefined);
    if (lastSelected) {
      return undefined;
    } else {
      lastSelected = presets.find((element) => element.lastSelected === true);
      return (
        lastSelected ?? presets.find((element) => element.name === "Default")
      );
    }
  }, [presets]);

  const changePreset = (selectedPreset) => {
    let newPreset = presets.find(
      (element) => element.name === selectedPreset[0].name
    );
    if (newPreset !== undefined) {
      onClose(newPreset);
      clearFields();
    }
  };

  return (
    <>
      <Dropdown
        placeholderInValue={t("fleetControl.filterBar.placeholderInValCustom")}
        placeholder={t("table.customColumns")}
        itemAttribute="name"
        value={getLastSelected}
        size={"small"}
        onChange={(val) => {
          val && val !== "" && changePreset(val);
        }}
        options={presets}
        hasButton={true}
        buttonText={t("table.customColumns")}
        buttonClick={() => setOpen(true)}
        removeItem={true}
        handleRemoveItem={(item) => {
          setItemToRemove(item);
        }}
      >
        <IconEdit size={14} />
      </Dropdown>
      {!_.isEmpty(itemToRemove) && (
        <FeedbackModal
          open={!_.isEmpty(itemToRemove)}
          title={`${i18next.t("customModals.deletePreset.title")} "${
            itemToRemove.name
          }"`}
          desc={i18next.t("customModals.deletePreset.desc")}
          primaryLabel={i18next.t("common.delete")}
          isDanger={true}
          primaryAction={() => {
            handleDelete(itemToRemove.id);
            setItemToRemove({});
          }}
          closeAction={() => {
            setItemToRemove({});
          }}
        />
      )}
      <div className="customize-columns">
        <ModalWrapper open={open} closeAction={() => setOpen(false)}>
          <div className="customize-columns-body">
            <ModalBody title={t("customModals.customizeColumns.title")}>
              <div className="columns-available">
                <span className="available-title">
                  {t("customModals.customizeColumns.leftColumn")}
                </span>
                <div className="available-container">
                  {columns.map((el, index) => {
                    return (
                      <div key={index} className="checkbox-list">
                        <Checkbox
                          label={el.name}
                          name={el.name}
                          className={"checkbox-custom-columns"}
                          checked={el.checked}
                          disabled={checkedList.length >= 8 && !el.checked}
                          onChange={() => {
                            onCheckboxChange(el, index);
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="columns-selected">
                <span className="selected-title">
                  {t("customModals.customizeColumns.rightColumn")}
                </span>
                <div className="selected-container">
                  <DraggableList
                    columns={checkedList}
                    modifiedColumns={(draggableList) => {
                      setCheckedList(draggableList);
                    }}
                  />
                </div>
                <div className="save-preset">
                  <div className="preset-checkbox">
                    <Checkbox
                      label={t("customModals.customizeColumns.presetCheckbox")}
                      checked={isChecked}
                      onChange={(e) => {
                        setIsChecked(e.target.checked);
                      }}
                    />
                  </div>
                  <div className="preset-input">
                    {isChecked ? (
                      <Form>
                        <TextField
                          id="Preset name"
                          placeholder={t(
                            "customModals.customizeColumns.presetPlaceholder"
                          )}
                          validate="alphabetic"
                          size="small"
                          maxLength="20"
                          errorList={setErrorList}
                          events={{
                            onChange: (data) => {
                              setPresetName(data.value);
                            },
                          }}
                          value={presetName}
                        />
                      </Form>
                    ) : null}
                  </div>
                </div>
              </div>
            </ModalBody>
            <ModalFooter
              primaryLabel={t("customModals.customizeColumns.primaryButton")}
              primaryAction={() => {
                let preset;
                if (presetName !== "") {
                  preset = {
                    name: presetName,
                    columns: checkedList.map((element) => element.value),
                  };
                } else {
                  preset = {
                    columns: checkedList.map((element) => element.value),
                  };
                }
                setOpen(false);
                onClose(preset);
                clearFields();
              }}
              disablePrimaryButton={
                checkedList.length === 0 || errorList.length !== 0
              }
              secondaryLabel={t(
                "customModals.customizeColumns.secondaryButton"
              )}
              secondaryAction={() => {
                setOpen(false);
                clearFields();
              }}
              closeAction={() => {
                clearFields();
              }}
            />
          </div>
        </ModalWrapper>
      </div>
    </>
  );
};
CustomizeColumnsModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  columns: PropTypes.array.isRequired,
  presets: PropTypes.array.isRequired,
};

CustomizeColumnsModal.defaultProps = {
  isOpen: false,
};
