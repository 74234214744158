import dayjs from "dayjs";
import { Link } from "react-router-dom";
import { IconExternalLink } from "../../ui/Icon/Line/ExternalLink";
import { IconUser } from "../../ui/Icon/Solid/User";
import { Table } from "../../ui/Table/Table";
import {
  AbstractTableBuilder,
  Row,
} from "../../ui/Table/utils/AbstractTableBuilder";
import "../../ui/Table/utils/TableBuilder.css";
import { ConvertTimeZone } from "../../utils/DateAndTimeUtils";
import {
  getQueryString,
  kmPerHToMilesPerH,
  mtToFt,
  numberAnnotation,
} from "../../utils/Utils";
import { Driver } from "../driver/driversSlice";
import { Preferences } from "../users/preference/preferencesSlice";
import { Vehicle } from "../vehicle/vehiclesSlice";
import { TravelDetail } from "./TravelDetailTypes";
import { GPSDataReport } from "./gpsData/gpsDataReportSlice";

export class TravelDetailModalTableBuilder extends AbstractTableBuilder {
  getEndNumber = (text: number) => {
    if ((text * 10000000) % 1 === 0) {
      return "";
    } else {
      return "...";
    }
  };

  rowsBuilder = (
    columns: { label: string; field: string; sort: boolean }[] | undefined,
    entities: {
      gpsDataReport?: GPSDataReport;
      vehicle?: Vehicle;
      driver?: Driver;
      travelDetail?: TravelDetail;
    },
    preferencesContext: Preferences,
    writePermission: boolean = false
  ) => {
    let row: Row = { render: {} as Row };

    let travelDetail: TravelDetail =
      entities.travelDetail ?? ({} as TravelDetail);

    let gpsDataReport: GPSDataReport =
      entities.gpsDataReport ?? ({} as GPSDataReport);

    let vehicle: Vehicle = entities.vehicle ?? ({} as Vehicle);

    let driver: Driver = entities.driver ?? ({} as Driver);

    columns &&
      columns.forEach((column) => {
        switch (column.field) {
          case "vehicleIdTravelDetail":
            row.render.vehicleIdTravelDetail = (
              <>
                <p className="reportMarked">
                  {vehicle.alias !== undefined ? vehicle.alias : "---"}
                </p>
                {vehicle.brand && (
                  <p className="reportNormal">{vehicle.brand}</p>
                )}
              </>
            );
            break;
          case "driverTravelDetail":
            row.render.driverTravelDetail = driver.id && (
              <Table.TextIcon
                text={driver.firstName + " " + driver.lastName}
                icon={
                  <IconUser
                    size={14}
                    color="var(--global-colors-ink-light)"
                    class="classTravelDriver"
                  />
                }
              />
            );
            break;
          case "startTimestamp":
            if (gpsDataReport.timestamp !== undefined) {
              const startTime = ConvertTimeZone(
                gpsDataReport.timestamp,
                preferencesContext.timeZone ?? undefined,
                preferencesContext.localeFormat ?? undefined
              );
              row.render.startTimestamp = startTime;
            } else {
              row.render.startTimestamp = "--:--";
            }
            break;
          case "speedTravelDetail":
            if (gpsDataReport.speed !== undefined) {
              row.render.speedTravelDetail = preferencesContext.isMetric
                ? numberAnnotation(gpsDataReport.speed)
                : numberAnnotation(kmPerHToMilesPerH(gpsDataReport.speed));
            } else {
              row.render.speedTravelDetail = "--";
            }
            break;
          case "coveredTravelDetail":
            if (gpsDataReport.covered !== undefined) {
              row.render.coveredTravelDetail = preferencesContext.isMetric
                ? numberAnnotation(gpsDataReport.covered)
                : numberAnnotation(mtToFt(gpsDataReport.covered));
            } else {
              row.render.coveredTravelDetail = "--";
            }
            break;
          case "xCoordinateTravelDetail":
            row.render.xCoordinateTravelDetail =
              gpsDataReport.dynamicFields?.latitude !== undefined
                ? Number(gpsDataReport.dynamicFields?.latitude)
                    .toFixed(7)
                    .toString() +
                  this.getEndNumber(
                    Number(gpsDataReport.dynamicFields?.latitude)
                  )
                : "--";
            break;
          case "yCoordinateTravelDetail":
            row.render.yCoordinateTravelDetail =
              gpsDataReport.dynamicFields?.longitude !== undefined
                ? Number(gpsDataReport.dynamicFields?.longitude)
                    .toFixed(7)
                    .toString() +
                  this.getEndNumber(
                    Number(gpsDataReport.dynamicFields?.longitude)
                  )
                : "--";
            break;
          default:
            break;
        }
      });

    row.render.action = (
      <Link
        to={
          `/dashboard/vehicles/location-history/track/${travelDetail.routeId}` +
          getQueryString({
            firstPointDate: [
              dayjs(
                new Date(
                  travelDetail.firstPosition.gpsPositionTimestamp
                ).setDate(
                  new Date(
                    travelDetail.firstPosition.gpsPositionTimestamp
                  ).getDate() - 1
                )
              ).format("YYYY/MM/DD HH:mm"),
              dayjs(
                new Date(
                  travelDetail.lastPosition.gpsPositionTimestamp
                ).setDate(
                  new Date(
                    travelDetail.lastPosition.gpsPositionTimestamp
                  ).getDate() + 1
                )
              ).format("YYYY/MM/DD HH:mm"),
            ],
            routeStateTypeEnum: "TRACK",
            "vehicle.id": vehicle.id ?? "",
            currentPos: gpsDataReport.timestamp,
          })
        }
        target="_blank"
        rel="noreferrer"
      >
        <IconExternalLink
          className=""
          size={14}
          color="--global-colors-ui-primary"
          index={gpsDataReport.timestamp}
        />
      </Link>
    );
    return row;
  };
}
