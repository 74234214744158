import i18next from "i18next";
import { useEffect, useState } from "react";
import { MenuRoutes } from "../../stories/Icon.line.stories";
import { Tooltip } from "../Forms/Tooltip";
import { IconMarker } from "../Icon/Solid/Marker";
import { IconRoad } from "../Icon/Solid/Road";
import "./PublicTransport.css";

export const PublicTransport = ({
  type,
  className,
  handleStops,
  handleTracks,
  handleTracksAndStops,
  ...props
}) => {
  const [viewType, setViewType] = useState("");
  const [tracksAndStopsStyle, setTracksAndStopsStyle] = useState(
    "public-transport-satellite public-transport-is-satellite"
  );
  const [stopStyle, setStopStyle] = useState("");
  const [trackStyle, setTrackStyle] = useState("");

  useEffect(() => {
    if (viewType == "stops") {
      setStopStyle("public-transport-satellite public-transport-is-satellite");
      setTracksAndStopsStyle("");
      setTrackStyle("");
    } else if (viewType == "tracks") {
      setTrackStyle("public-transport-satellite public-transport-is-satellite");
      setTracksAndStopsStyle("");
      setStopStyle("");
    } else {
      setTracksAndStopsStyle(
        "public-transport-satellite public-transport-is-satellite"
      );
      setStopStyle("");
      setTrackStyle("");
    }
  }, [viewType]);

  return (
    <>
      <Tooltip />
      <div className={["public-transport", className].join(" ")} {...props}>
        <button
          className={tracksAndStopsStyle}
          data-for="tooltip"
          data-tip={i18next.t("admin.publicRoute.trackAndStops")}
          onClick={() => {
            setViewType("tracksAndStops");
            handleTracksAndStops();
          }}
          disabled={null}
        >
          <MenuRoutes color="--global-colors-ui-white" size={14} />
        </button>
        <button
          className={trackStyle}
          data-for="tooltip"
          data-tip={i18next.t("admin.publicRoute.track")}
          onClick={() => {
            setViewType("tracks");
            handleTracks();
          }}
          disabled={null}
        >
          <IconRoad color="--global-colors-ui-white" size={14} />
        </button>
        <button
          className={stopStyle}
          data-for="tooltip"
          data-tip={i18next.t("admin.publicRoute.stops")}
          onClick={() => {
            setViewType("stops");
            handleStops();
          }}
          disabled={null}
        >
          <IconMarker color="--global-colors-ui-white" size={14} />
        </button>
      </div>
    </>
  );
};
