import dayjs from "dayjs";
import { t } from "i18next";
import { Link } from "react-router-dom";
import { IconExternalLink } from "../../../ui/Icon/Line/ExternalLink";
import { IconUser } from "../../../ui/Icon/Line/User";
import { LabelText } from "../../../ui/LabelText/LabelText";
import { Table } from "../../../ui/Table/Table";
import {
  AbstractTableBuilder,
  Row,
} from "../../../ui/Table/utils/AbstractTableBuilder";
import "../../../ui/Table/utils/TableBuilder.css";
import {
  formatData,
  getAddress,
  getCity,
  getQueryString,
  kmPerHToMilesPerH,
  kmPerLtToMilesPerG,
  kmToMiles,
  mtToKm,
} from "../../../utils/Utils";
import { Driver } from "../../driver/driversSlice";
import { Preferences } from "../../users/preference/preferencesSlice";
import { Vehicle } from "../../vehicle/vehiclesSlice";
import { GPSDataReport } from "./gpsDataReportSlice";

export class GPSDataReportTableBuilder extends AbstractTableBuilder {
  rowsBuilder = (
    columns: { label: string; field: string; sort: boolean }[] | undefined,
    context: string,
    entities: {
      vehicle?: Vehicle;
      driver?: Driver;
      gpsDataReport?: GPSDataReport;
    },
    preferencesContext: Preferences,
    writePermission: boolean = false
  ) => {
    let row: Row = { render: {} as Row };

    let vehicle: Vehicle = entities.vehicle ?? ({} as Vehicle);

    let driver: Driver = entities.driver ?? ({} as Driver);

    let gpsDataReport: GPSDataReport =
      entities.gpsDataReport ?? ({} as GPSDataReport);

    columns &&
      columns.forEach((column) => {
        switch (column.field) {
          case "vehicle":
            row.vehicle = vehicle.alias ?? t("common.na");
            row.render.vehicle = (
              <LabelText
                label={vehicle.alias ?? t("common.na")}
                text={
                  vehicle.brand
                    ? vehicle.brand + " " + vehicle.model
                    : t("common.na")
                }
              />
            );
            break;
          case "travelDriver":
            row.render.travelDriver = driver.id && (
              <Table.TextIcon
                text={driver.firstName + " " + driver.lastName}
                icon={
                  <IconUser
                    size={14}
                    color="var(--global-colors-ink-light)"
                    class="classTravelDriver"
                  />
                }
              />
            );
            break;
          case "gpsDataTimestamp":
            if (gpsDataReport?.timestamp)
              row.render.gpsDataTimestamp = dayjs(
                gpsDataReport?.timestamp
              ).format(
                preferencesContext.localeFormat?.replace(/\s/g, "") + " HH:mm"
              );
            else row.render.gpsDataTimestamp = "--";
            break;
          case "gpsDataSpeed":
            if (gpsDataReport?.speed !== undefined)
              row.render.gpsDataSpeed = formatData(
                gpsDataReport?.speed,
                kmPerHToMilesPerH,
                preferencesContext
              );
            else row.render.gpsDataSpeed = "--";
            break;
          case "gpsDataCovered":
            if (gpsDataReport?.covered !== undefined)
              row.render.gpsDataCovered = formatData(
                mtToKm(gpsDataReport?.covered),
                kmToMiles,
                preferencesContext
              );
            else row.render.gpsDataCovered = "--";
            break;
          case "gpsDataConsumption":
            if (gpsDataReport?.consumption !== undefined)
              row.render.gpsDataConsumption = formatData(
                gpsDataReport?.consumption,
                kmPerLtToMilesPerG,
                preferencesContext,
                3
              );
            else row.render.gpsDataConsumption = "--";
            break;
          case "gpsDataAddress":
            if (gpsDataReport?.dynamicFields?.address)
              row.render.gpsDataAddress = (
                <>
                  <p className="reportMarked">
                    {getCity(gpsDataReport?.dynamicFields?.address)}
                  </p>
                  <p className="reportNormal">
                    {getAddress(gpsDataReport?.dynamicFields?.address)}
                  </p>
                </>
              );
            else row.render.gpsDataAddress = "--";
            break;
          case "gpsDataLatitude":
            if (gpsDataReport?.dynamicFields?.latitude)
              row.render.gpsDataLatitude =
                gpsDataReport?.dynamicFields?.latitude.substring(0, 14);
            else row.render.gpsDataLatitude = "--";
            break;
          case "gpsDataLongitude":
            if (gpsDataReport?.dynamicFields?.longitude)
              row.render.gpsDataLongitude =
                gpsDataReport?.dynamicFields?.longitude.substring(0, 14);
            else row.render.gpsDataLongitude = "--";
            break;
          default:
            break;
        }
      });

    if (context === "gpsDataReport") {
      row.render.action = (
        <Link
          to={
            gpsDataReport?.routeId !== null
              ? `/dashboard/vehicles/location-history/${
                  gpsDataReport.routeType === "TRACK" ? "track" : "stop"
                }/${gpsDataReport?.routeId}` +
                getQueryString({
                  firstPointDate: [
                    dayjs(
                      new Date(gpsDataReport.timestamp).setDate(
                        new Date(gpsDataReport.timestamp).getDate() - 1
                      )
                    ).format("YYYY/MM/DD HH:mm"),
                    dayjs(
                      new Date(gpsDataReport.timestamp).setDate(
                        new Date(gpsDataReport.timestamp).getDate() + 1
                      )
                    ).format("YYYY/MM/DD HH:mm"),
                  ],
                  routeStateTypeEnum: "TRACK",
                  "vehicle.id": gpsDataReport.vehicleId,
                  currentPos: gpsDataReport.timestamp,
                })
              : ""
          }
          target={gpsDataReport?.routeId !== null ? "_blank" : ""}
          rel="noreferrer"
          className={
            gpsDataReport?.routeId !== null ? "" : "disabledExternalLink"
          }
        >
          <IconExternalLink
            className=""
            size={14}
            index={gpsDataReport.dynamicFields?.lastUpdate}
            color="--global-colors-ink-ink"
          />
        </Link>
      );
    }

    return row;
  };
}
