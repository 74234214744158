import dayjs from "dayjs";
import { t } from "i18next";
import React, { useContext } from "react";
import { store } from "../../app/store";
import { FeedbackModal } from "../../ui/Modal/CustomModals/FeedbackModal";
import { Preferences } from "../users/preference/preferencesSlice";
import UserContext from "../users/userContext";
import "./PreviewAttachmentModal.css";
import {
  Deadline,
  deleteFileDeadlineAsync,
  updateDeadlineAsync,
} from "./deadlinesSlice";

interface DeleteAttachmentModalProps {
  open: boolean;
  onClose: (e: any) => any;
  vehicleId: number;
  deadlineStatus: Partial<Deadline>;
}

export const DeleteAttachmentModal: React.FC<DeleteAttachmentModalProps> = ({
  open,
  onClose,
  vehicleId,
  // fileName,
  deadlineStatus,
}) => {
  const [preferencesContext]: [Preferences] = useContext(UserContext);
  dayjs.locale(preferencesContext.language ?? "en");

  const maintenanceTypeString = () => {
    if (deadlineStatus.isMaintenance === true) {
      return t("customModals.deleteAttachment.descMaint");
    } else {
      return t("customModals.deleteAttachment.descDead");
    }
  };

  return (
    <div className="delete-attachment-modal">
      <FeedbackModal
        open={open}
        closeAction={onClose}
        title={t("customModals.deleteAttachment.title")}
        desc={
          t("customModals.deleteAttachment.descAttac") +
          (preferencesContext.language === "it"
            ? maintenanceTypeString() +
              " " +
              t("customModals.deleteAttachment.descVehicle")
            : t("customModals.deleteAttachment.descVehicle") +
              " " +
              maintenanceTypeString()) +
          t("customModals.deleteAttachment.descDelete")
        }
        primaryLabel={t("common.confirm")}
        isDanger={true}
        primaryAction={() => {
          store.dispatch(
            deleteFileDeadlineAsync({
              file: deadlineStatus.uploadFile,
              type: `Maintenance/Vehicles/${vehicleId}`,
            })
          );
          if (deadlineStatus.id) {
            store.dispatch(
              updateDeadlineAsync({
                id: deadlineStatus.id,
                deadline: {
                  uploadFile: "",
                },
              })
            );
          }
          onClose("");
        }}
        secondaryLabel={t("common.cancel")}
        secondaryAction={() => {
          onClose("");
        }}
      />
    </div>
  );
};
