import React from "react";
import PropTypes from "prop-types";

import { Check } from "../Indicators/Check";
import "./MiniCard.css";

export const MiniCard = ({
  icon,
  preTitle,
  title,
  clickAction,
  status,
  ...props
}) => {
  const handleClick = () => {
    clickAction();
  };
  return (
    <div className="mn-mini-card" onClick={handleClick} {...props}>
      <div className="mn-mini-card__icon">
        {icon}
        {status && <Check status={status} size="medium" />}
      </div>
      {preTitle && <p className="mn-mini-card__pretitle">{preTitle}</p>}
      <div className="mn-mini-card__title">{title}</div>
    </div>
  );
};

MiniCard.propTypes = {
  preTitle: PropTypes.string,
  title: PropTypes.string.isRequired,
  clickAction: PropTypes.func,
  status: PropTypes.oneOf(["success", "failed"]),
};
