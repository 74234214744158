import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import _ from "lodash";
import { normalize } from "normalizr";
import { RootState } from "../../app/store";
import { Config } from "../../config/Config";
import { GTFleetSuccessCodes } from "../../config/GTFleetSuccessCodes";
import { getErrorCodes } from "../../utils/Utils";
import { logEventsSchema } from "./logNormalization";
import LogsRepository from "./LogRepository";

//#region Type
export const logEventAreasValues = {
  DEVICE: "Device",
  MOBILE_APP: "Mobile App",
  WEB_APP: "Web App",
};
export type EventSourceEnum = keyof typeof logEventAreasValues;

export const eventOutcomeEnumValues = {
  OK: "OK",
  PENDING: "PENDING",
  KO: "KO",
};
export type EventOutcomeEnum = keyof typeof eventOutcomeEnumValues;

export type LogEvent = {
  id: string;
  eventSourceEnum: EventSourceEnum;
  timestamp: Date;
  name: string;
  action: string;
  eventOutcomeEnum: EventOutcomeEnum;
  userId: number;
};
//#endregion Type

//#region API
export const getLogEventsAsync = createAsyncThunk(
  "logs/getLogEvents",
  async (queryParams?: string) => {
    const logEventsRepository = new LogsRepository();
    const response = await logEventsRepository.getLogEvents(queryParams);
    const logEvents = _.get(response, Config.LOG_EVENTS_RESPONSE_PATH);
    if (logEvents) {
      const normalizedData = normalize(logEvents, logEventsSchema);
      return normalizedData.entities;
    } else {
      return [];
    }
  }
);

export const getFilteredLogEventsPaginationAsync = createAsyncThunk(
  "logs/getFilteredLogEventsPagination",
  async (data: { queryParams?: string }, { dispatch, rejectWithValue }) => {
    try {
      const logEventsRepository = new LogsRepository();
      const response = await logEventsRepository.getLogEvents(data.queryParams);
      const logEvents = _.get(response, Config.LOG_EVENTS_RESPONSE_PATH);
      const totalPages = _.get(
        response,
        Config.LOG_EVENTS_TOTAL_PAGES_RESPONSE_PATH
      );
      const totalElements = _.get(
        response,
        Config.LOG_EVENTS_TOTAL_ELEMENTS_RESPONSE_PATH
      );
      if (totalPages) {
        dispatch(setNumberOfPages(totalPages));
      }
      if (totalElements) {
        dispatch(setNumberOfElements(totalElements));
      }
      const normalizedData = normalize(logEvents ?? [], logEventsSchema);
      return normalizedData.entities;
    } catch (error: any) {
      if (!error.response) throw error;
      return rejectWithValue(error.response.status);
    }
  }
);

//#endregion API

//#region Custom Functions
//#endregiorn Custom Functions

//#region Slice
const logEventsAdapter = createEntityAdapter<LogEvent>({
  selectId: (logEvent) => logEvent.id,
});

export const logEventsSlice = createSlice({
  name: "logEvents",
  initialState: logEventsAdapter.getInitialState({
    status: "idle",
    reasonCode: "",
    totalPages: 0,
    totalElement: 0,
  }),
  reducers: {
    setNumberOfPages: (state, action: PayloadAction<number>) => {
      state.totalPages = action.payload;
    },
    setNumberOfElements: (state, action: PayloadAction<number>) => {
      state.totalElement = action.payload;
    },
    logEventsEmptyState: (state) => {
      logEventsAdapter.setAll(state, []);
      state.reasonCode = "";
      state.status = "idle";
    },
  },
  extraReducers: (builder) => {
    builder
      //#region Entity Reducers
      .addCase(getLogEventsAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(
        getLogEventsAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          logEventsAdapter.upsertMany(state, action.payload.logEvent);
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.GET;
        }
      )
      .addCase(
        getLogEventsAsync.rejected,
        (state: any, action: PayloadAction<any>) => {
          state.status = "failed";
          state.reasonCode = getErrorCodes(action.payload);
        }
      )
      .addCase(getFilteredLogEventsPaginationAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(
        getFilteredLogEventsPaginationAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          logEventsAdapter.setAll(state, action?.payload?.logEvent ?? []);
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.GET;
        }
      )
      .addCase(
        getFilteredLogEventsPaginationAsync.rejected,
        (state: any, action: PayloadAction<any>) => {
          state.status = "failed";
          state.reasonCode = getErrorCodes(action.payload);
        }
      );

    //#endregion Entity Reducers

    //#region External Entity Reducers

    //#endregion External Entity Reducers
  },
});
//#endregion Slice

//#region Status
export const logEventsSelectors = logEventsAdapter.getSelectors<RootState>(
  (state) => state.logEvents
);
export const selectLogEventsSliceStatus = (state: any) =>
  state.logEvents.status;
export const selectLogEventsSliceReasonCode = (state: any) =>
  state.logEvents.reasonCode;
export const selectLogEventsSlicePage = (state: any) =>
  state.logEvents.totalPages;
export const selectLogEventsSliceTotalElements = (state: any) =>
  state.logEvents.totalElements;

export const { setNumberOfPages, logEventsEmptyState, setNumberOfElements } =
  logEventsSlice.actions;
//#endregion Status
export default logEventsSlice.reducer;
