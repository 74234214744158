import { t } from "i18next";

export interface TableColumnEntry {
  id: number;
  name: string;
  value: string;
  checked?: boolean;
}
export class TableColumns {
  private vehicleDashboard: TableColumnEntry[] = [
    {
      id: 1,
      name: t("table.columns.vehicleId"),
      value: "vehicle",
    },
    {
      id: 2,
      name: t("table.columns.license"),
      value: "license",
    },
    {
      id: 4,
      name: t("table.columns.currentDriver"),
      value: "currentDriver",
    },
    {
      id: 5,
      name: t("table.columns.currentLocation"),
      value: "currentLocation",
    },
    {
      id: 7,
      name: t("table.columns.consumption"),
      value: "consumption",
    },
    {
      id: 8,
      name: t("table.columns.emissions"),
      value: "emissions",
    },
    {
      id: 9,
      name: t("table.columns.fuelCapacity"),
      value: "fuelCapacity",
    },
    {
      id: 10,
      name: t("table.columns.fuelType"),
      value: "fuelType",
    },
    {
      id: 11,
      name: t("table.columns.engine"),
      value: "engine",
    },
    {
      id: 12,
      name: t("table.columns.driverStatusActivity"),
      value: "driverStatusActivity",
    },
    {
      id: 13,
      name: t("table.columns.vehicleStatusActivity"),
      value: "vehicleStatusActivity",
    },
    {
      id: 14,
      name: t("table.columns.vehicleKey"),
      value: "vehicleKey",
    },
    {
      id: 15,
      name: t("table.columns.currentOdometer"),
      value: "currentOdometer",
    },
    {
      id: 16,
      name: t("table.columns.device"),
      value: "device",
    },
  ];

  private driverDashboard: TableColumnEntry[] = [
    { id: 0, name: t("table.columns.driver"), value: "driver" },
    { id: 1, name: t("table.columns.vehicleId"), value: "vehicleId" },
    { id: 2, name: t("table.columns.license"), value: "license" },
    { id: 3, name: t("table.columns.group"), value: "group" },
    { id: 4, name: t("table.columns.driverStatus"), value: "driverStatus" },
    {
      id: 5,
      name: t("table.columns.currentLocation"),
      value: "currentLocation",
    },
    { id: 6, name: t("table.columns.drive"), value: "drive" },
    { id: 7, name: t("table.columns.totalToday"), value: "totalToday" },
    { id: 8, name: t("table.columns.kmTraveled"), value: "kmTraveled" },
    { id: 9, name: t("table.columns.lastActivity"), value: "lastActivity" },
  ];

  public getVehicleDashboardTableColumns(): TableColumnEntry[] {
    return this.vehicleDashboard;
  }

  public getDriverDashboardTableColumns(): TableColumnEntry[] {
    return this.driverDashboard;
  }
}
