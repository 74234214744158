import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import { IconArrowDown } from "../Icon/Line/ArrowDown";
import { IconArrowUp } from "../Icon/Line/ArrowUp";
import { IconAttach } from "../Icon/Line/Attach";
import { IconMore } from "../Icon/Line/More";
import { Button } from "./Button";
import "./DropdownButton.css";

export const DropdownButton = ({
  aspect,
  size,
  list,
  children,
  isLoading,
  isAttached,
  label,
  disabled,
  closeOnClick,
  ...props
}) => {
  const [isListOpen, setIsListOpen] = useState(false);

  const wrapperRef = useRef(null);
  const parentRef = useRef(null);

  // Method for closed dropdown if u clicked outside
  useEffect(() => {
    function handleClickOutside(event) {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target) &&
        !parentRef.current.contains(event.target)
      ) {
        setIsListOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  function renderDownArrow() {
    if (!isLoading) {
      return (
        <div className="dropdown__button">
          <Button
            aspect={aspect}
            size={size}
            label={label}
            dropdownIcon={
              isListOpen ? (
                <IconArrowUp size={14} color="currentColor" />
              ) : (
                <IconArrowDown size={14} color="currentColor" />
              )
            }
            disabled={disabled}
          >
            {children}
          </Button>
        </div>
      );
    }
  }

  function renderMoreIcon() {
    if (isAttached) {
      return (
        <Button aspect={aspect} size={size} label="" disabled={disabled}>
          <IconAttach size={14} color="currentColor" />
        </Button>
      );
    } else {
      return (
        <Button aspect={aspect} size={size} label="" disabled={disabled}>
          <IconMore size={14} color="currentColor" />
        </Button>
      );
    }
  }

  return (
    <div
      className={["dropdown-btn", `dropdown--${aspect}`].join(" ")}
      {...props}
    >
      <div
        className={
          children ? "dropdown__button" : `dropdown__button--${aspect}`
        }
        ref={parentRef}
        onClick={() => {
          if (!disabled) setIsListOpen(!isListOpen);
        }}
      >
        {children ? renderDownArrow() : renderMoreIcon()}
      </div>
      {isListOpen && (
        <div
          className={
            children
              ? "dropdown-list"
              : Object.keys(list).length > 2
              ? "dropdown-list-only-icon"
              : Object.keys(list).length === 2
              ? "dropdown-list-only-icon list-two"
              : "dropdown-list-only-icon list-one"
          }
          ref={wrapperRef}
        >
          {list.map((listItem, index) => {
            return (
              <div key={index} className="dropdown-elements">
                <Button
                  aspect="ghost"
                  onlyIcon={false}
                  size="regular"
                  disabled={listItem?.disabled}
                  label={listItem.title}
                  onClick={() => {
                    if (listItem?.onClick) {
                      listItem.onClick();
                      if (closeOnClick) {
                        setIsListOpen(!isListOpen);
                      }
                    }
                  }}
                >
                  <div className="dropdown-icon-btn">{listItem.icon}</div>
                </Button>
                <br />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

DropdownButton.propTypes = {
  /**
   * Is the principal call to action on the page?
   */
  aspect: PropTypes.oneOf([
    "primary",
    "secondary",
    "outline",
    "ghost",
    "danger",
  ]),
  /**
   * How large should the button be?
   */
  size: PropTypes.oneOf(["regular", "small"]),

  list: PropTypes.array.isRequired,

  isLoading: PropTypes.bool,

  closeOnClick: PropTypes.bool,
};

DropdownButton.defaultProps = {
  aspect: "secondary",
  size: "regular",
  list: [],
  closeOnClick: false,
};
