import PropTypes from "prop-types";
import { Card } from "../Card/Card";
import "./VehicleUtilization.css";
import { RadialBarPercent } from "../Chart/RadialBarPercent";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

export const VehicleUtilization = ({
  percentage,
  utilization,
  className,
  setPeriod
}) => {
  const { t } = useTranslation();

  const valueChange = (val) => {
    let startPeriod = dayjs().set("hour", 0).set("minute", 0);
    let endPeriod = dayjs();
    switch (val?.value) {
      case "yesterday":
        startPeriod = dayjs().set("hour", 0).set("minute", 0).subtract(1, "day");
        endPeriod = dayjs().set("hour", 23).set("minute", 59).subtract(1, "day");
        break;
      case "lastWeek":
        startPeriod = dayjs().subtract(7, "day");
        endPeriod = dayjs();
        break;
      case "lastMonth":
        startPeriod = dayjs().subtract(1, "month");
        endPeriod = dayjs();
        break;
      default:
        break;
    }
    setPeriod && setPeriod([
      startPeriod.format("YYYY-MM-DDTHH:mm"),
      endPeriod.format("YYYY-MM-DDTHH:mm")
    ]);
  };

  const UtilizationMarkup = utilization.map((util, index) => {
    return (
      <span key={index} className="mn-vehicle-utilization__data-item">
        <div>{util.title}</div>
        <p>{util.data}</p>
      </span>
    );
  });
  return (
    <Card
      title={t("pages.dashboardVehicles.vehicleDetails.utilization")}
      linkAction={() => {
        console.log("Click Link");
      }}
      linkAnchor={t(
        "pages.dashboardVehicles.vehicleDetails.viewUtilizationHistory"
      )}
      className={"mn-vehicle-utilization " + className}
      linkDisabled={true}
      hasDropdown={true}
      valueChange={valueChange}
    >
      <div className="mn-vehicle-utilization__inner">
        <div className="mn-vehicle-utilization__graph">
          <RadialBarPercent percentage={percentage} />
        </div>
        <div className="mn-vehicle-utilization__data">{UtilizationMarkup}</div>
      </div>
    </Card>
  );
};

VehicleUtilization.propTypes = {
  percentage: PropTypes.number,
  utilization: PropTypes.array,
  setPeriod: PropTypes.func
};
