import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import i18next from "i18next";
import { convertToCurrentLocalesFormat } from "../ui/Forms/utils/dateConversion";

dayjs.extend(utc);
dayjs.extend(timezone);

/**
 * Convert time zone by list from https://github.com/formatjs/date-time-format-timezone *
 * @param {*} dateTime
 * @param {*} timeZone
 * @param {*} format
 * @param {*} shortHour if true return only HH:mm else complete date ex.: DD/MM/YYYY  HH:mm:ss
 * @returns
 */
export const ConvertTimeZone = (
  dateTime,
  timeZone = "Europe/London",
  format = "DD / MM / YYYY",
  shortHour = false,
  onlyDate = false,
  onlyTime = false
) => {
  if (dateTime === "-") {
    return "-";
  }

  if (shortHour === true) {
    return dayjs.utc(dateTime).tz(timeZone).format("HH:mm");
  }

  if (onlyDate === true) {
    return dayjs.utc(dateTime).tz(timeZone).format(format);
  }

  if (onlyTime === true) {
    return dayjs.utc(dateTime).tz(timeZone).format("HH:mm:ss");
  }

  return dayjs
    .utc(dateTime)
    .tz(timeZone)
    .format(format.replaceAll(" ", "") + ", HH:mm:ss");
};

/** date format methods */

/**
 * Difference between end timestamp and start timestamp into a formatted string.
 *
 * @param {String} start The time at start point.
 * @param {String} end The time at end point.
 * @returns String
 */
export const formatTotalTime = (start, end, language = "en") => {
  let dateFuture = new Date(end).getTime();
  let dateNow = new Date(start).getTime();

  let seconds = Math.floor((dateFuture - dateNow) / 1000);
  let minutes = Math.floor(seconds / 60);
  let hours = Math.floor(minutes / 60);
  let days = Math.floor(hours / 24);

  hours = hours - days * 24;
  minutes = minutes - days * 24 * 60 - hours * 60;
  if (days > 0) {
    return (
      days + (language === "it" ? "g " : "d ") + hours + "h " + minutes + "min"
    );
  } else if (hours > 0) {
    return hours + "h " + minutes + "min";
  } else {
    return minutes + "min";
  }
};

/**
 * format a number in DD d HH h mm min (es. 12d 31h 34min).
 *
 * @param {Number} time The time to convert.
 * @returns String
 */
export const formatTime = (
  time,
  language = "en",
  noValue = "0 min",
  day = true,
  hour = true,
  min = true,
  sec = false,
  timeThresholdForNoValue = 60000
) => {
  if (!time || time === 0 || time < timeThresholdForNoValue) {
    return noValue;
  } else {
    let dayTime = "";
    let hourTime = "";
    let minTime = "";
    let secTime = "";
    if (day) {
      dayTime =
        dayjs.duration(time).format("DD") + (language === "it" ? " g " : " d ");
    }
    if (hour) {
      hourTime = dayjs.duration(time).format("HH") + " h ";
    }
    if (min) {
      minTime = dayjs.duration(time).format("mm") + " min ";
    }
    if (sec) {
      secTime = dayjs.duration(time).format("ss") + " sec ";
    }
    const timeFormatted = `${dayTime} ${hourTime} ${minTime} ${secTime}`
      .replace(/\b00 g\b/, "")
      .replace(/\b00 d\b/, "")
      .replace(/\b00 h\b/, "")
      .replace(/\b00 min\b/, "")
      .replace(/\b00 sec\b/, "");

    return timeFormatted.replace(/\s+/g, "") !== ""
      ? timeFormatted
      : i18next.t("common.na");
  }
};

export const tableFormatTime = (time, duration) => {
  let timeDuration = dayjs.duration(time, duration ?? "minutes");
  if (timeDuration.minutes() >= 1) {
    return timeDuration
      .format("H[h] mm[min]")
      .replace(/\b0h\b/, "")
      .replace(/\b00min\b/, "0min");
  } else {
    return timeDuration.format("ss[sec]");
  }
};
/**
 * format minutes in HH h mm min (es. 235 -> 3h 55min).
 *
 * @param {Number} minutes The time to convert.
 * @returns String
 */
export const minutestoHours = (minutes) => {
  let hr = minutes / 60;
  let rhours = Math.floor(hr);
  let min = (hr - rhours) * 60;
  let rminutes = Math.round(min);
  return rhours !== 0 ? rhours + "h " + rminutes + "min" : rminutes + "min";
};

/**
 * Convert date to timezone preferences
 */
export const datetoLocaleFormat = (date, localeFormat, isISO = true) => {
  if (localeFormat && isISO) {
    return convertToCurrentLocalesFormat(date, true, localeFormat);
  }
  if (localeFormat && !isISO) {
    return new Date(date).toLocaleDateString(i18next.language, localeFormat);
  } else {
    return new Date(date).toLocaleDateString(i18next.language);
  }
};

/**
 * add 0 number to date and time to adjust dateTime format lenght
 */
export const lengthTimeStringFormatter = (value) => {
  const stringValue = String(value);
  if (stringValue.length !== 2) {
    return "0" + stringValue;
  } else {
    return stringValue;
  }
};

/**
 *
 * @param {*} timestamp
 * @param {*} isToupperCase
 * @param {*} hasYear
 * @returns
 */
export const getFullDate = (date, isToupperCase = true, hasYear = true) => {
  if (date) {
    const numericDate = Number(date);
    const formattedDate = dayjs(
      isNaN(numericDate) ? new Date(date) : numericDate
    );

    const isToday = formattedDate.isSame(dayjs(), "day");
    const isYesterday = formattedDate.isSame(dayjs().subtract(1, "day"), "day");

    if (isToday) {
      return isToupperCase
        ? i18next.t("common.today").toUpperCase()
        : i18next.t("common.today");
    } else if (isYesterday) {
      return isToupperCase
        ? i18next.t("common.yesterday").toUpperCase()
        : i18next.t("common.yesterday");
    }
    return hasYear
      ? formattedDate.format("DD MMM YYYY")
      : formattedDate.format("DD MMM");
  } else {
    return "";
  }
};

/**
 *
 * @param {*} time
 * @returns
 */
export const timeElapsed = (time) => {
  switch (typeof time) {
    case "number":
      break;
    case "string":
      time = +new Date(time);
      break;
    case "object":
      if (time.constructor === Date) time = time.getTime();
      break;
    default:
      time = +new Date();
  }

  let time_formats = [
    [60, i18next.t("timeFormats.seconds"), 1], // 60
    [
      120,
      i18next.t("timeFormats.minuteAgo"),
      i18next.t("timeFormats.minuteFromNow"),
    ], // 60*2
    [3600, i18next.t("timeFormats.minutes"), 60], // 60*60, 60
    [
      7200,
      i18next.t("timeFormats.hourAgo"),
      i18next.t("timeFormats.hourFromNow"),
    ], // 60*60*2
    [86400, i18next.t("timeFormats.hours"), 3600], // 60*60*24, 60*60
    [
      172800,
      i18next.t("timeFormats.yesterday"),
      i18next.t("timeFormats.tomorrow"),
    ], // 60*60*24*2
    [604800, i18next.t("timeFormats.days"), 86400], // 60*60*24*7, 60*60*24
    [
      1209600,
      i18next.t("timeFormats.lastWeek"),
      i18next.t("timeFormats.nextWeek"),
    ], // 60*60*24*7*4*2
    [2419200, i18next.t("timeFormats.weeks"), 604800], // 60*60*24*7*4, 60*60*24*7
    [
      4838400,
      i18next.t("timeFormats.lastMonth"),
      i18next.t("timeFormats.nextMonth"),
    ], // 60*60*24*7*4*2
    [29030400, i18next.t("timeFormats.months"), 2419200], // 60*60*24*7*4*12, 60*60*24*7*4
    [
      58060800,
      i18next.t("timeFormats.lastYear"),
      i18next.t("timeFormats.nextYear"),
    ], // 60*60*24*7*4*12*2
    [2903040000, i18next.t("timeFormats.years"), 29030400], // 60*60*24*7*4*12*100, 60*60*24*7*4*12
    [
      5806080000,
      i18next.t("timeFormats.lastCentury"),
      i18next.t("timeFormats.nextCentury"),
    ], // 60*60*24*7*4*12*100*2
    [58060800000, i18next.t("timeFormats.centuries"), 2903040000], // 60*60*24*7*4*12*100*20, 60*60*24*7*4*12*100
  ];

  let seconds = (+new Date() - time) / 1000,
    token = i18next.t("timeFormats.ago"),
    list_choice = 1;

  if (seconds === 0) {
    return i18next.t("timeFormats.justNow");
  }
  if (seconds < 0) {
    seconds = Math.abs(seconds);
    token = i18next.t("timeFormats.fromNow");
    list_choice = 2;
  }
  let i = 0,
    format;
  while ((format = time_formats[i++]))
    if (seconds < format[0]) {
      if (typeof format[2] == "string") return format[list_choice];
      else
        return Math.floor(seconds / format[2]) + " " + format[1] + " " + token;
    }
  return time;
};
