import { t } from "i18next";
import { useEffect, useMemo, useState } from "react";
import { useMatch, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { store } from "../../app/store";
import PageContent from "../../layout/PageContent";
import PageFilters from "../../layout/PageFilters";
import { ToastNotification } from "../../utils/ToastNotification";
import "./CreateGeofence.css";
import { EditGeofenceFilterBar } from "./EditGeofenceFilterBar";
import { GeofenceMap } from "./GeofenceMap";
import {
  Circle,
  Geofence,
  geofencesEmptyState,
  GeofenceShapeEnum,
  geofencesSelectors,
  getGeofenceAsync,
  Polygon,
  selectGeofencesSliceReasonCode,
  selectGeofencesSliceStatus,
} from "./geofenceSlice";

const EditGeofence = () => {
  const navigate = useNavigate();
  const geofenceRoute = useMatch("admin/geofences/edit-geofence/:geofenceId");

  const [id, setId] = useState<number>(-1);

  const editGeofences: Geofence =
    useAppSelector((state) => geofencesSelectors.selectById(state, id)) ??
    ({} as Geofence);
  const geofencesSliceStatus = useAppSelector(selectGeofencesSliceStatus);
  const geofencesSliceReasonCode = useAppSelector(
    selectGeofencesSliceReasonCode
  );

  document.title = useMemo(() => {
    if (!!editGeofences?.name) {
      return (
        `${editGeofences?.name} - ` +
        t("admin.geofences.editViewCreate.editGeofence") +
        " - Admin"
      );
    } else {
      return t("admin.geofences.editViewCreate.editGeofence") + " - Admin";
    }
  }, [editGeofences]);

  const [drawedPoints, setDrawedPoints] = useState({} as Polygon | Circle);
  const [shapeType, setShapeType] = useState({} as GeofenceShapeEnum);
  const [editedShape, setEditedShape] = useState(false);

  if (geofencesSliceStatus === "idle" && !!editGeofences && !shapeType) {
    setShapeType(editGeofences.geofenceShapeEnum);
    setDrawedPoints(editGeofences.shape);
  }

  useEffect(() => {
    if (geofenceRoute !== null && geofenceRoute.params.geofenceId) {
      setId(parseInt(geofenceRoute.params.geofenceId));

      store.dispatch(geofencesEmptyState());
      store.dispatch(getGeofenceAsync(Number(geofenceRoute.params.geofenceId)));
    } else {
      ToastNotification({
        toastId: "unauthorizedError",
        status: "error",
        description: t("common.unauthorizedError"),
      });
      navigate("/admin/drivers");
    }
  }, [navigate, geofenceRoute]);

  useEffect(() => {
    document.title =
      t("admin.geofences.editViewCreate.editGeofence") + " - Admin";
  }, []);

  return (
    <>
      {"geofenceShapeEnum" in editGeofences && (
        <>
          <PageFilters>
            <div className="col col-16">
              <EditGeofenceFilterBar
                shapeType={shapeType}
                geofenceId={id}
                drawedPoints={drawedPoints}
                setGeofence={editGeofences}
                editedShape={editedShape}
              />
            </div>
          </PageFilters>
          <PageContent>
            <div className="col">
              <div className="cg-map-functionalities">
                <GeofenceMap
                  id={0}
                  googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY!}
                  zoom={6}
                  latitude={41.9027835}
                  longitude={12.4963655}
                  getDrawedPoints={setDrawedPoints}
                  getShapeType={setShapeType}
                  setGeofence={editGeofences}
                  getEditedShape={setEditedShape}
                />
              </div>
            </div>
          </PageContent>
        </>
      )}
    </>
  );
};

export default EditGeofence;
