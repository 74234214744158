import { t } from "i18next";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useLocation, useMatch, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../../app/hooks";
import { store } from "../../../../app/store";
import { Dropdown } from "../../../../ui/Dropdown/Dropdown";
import Form from "../../../../ui/Forms/Form";
import TextField from "../../../../ui/Forms/TextField";
import { IconMail } from "../../../../ui/Icon/Line/Mail";
import { IconPhone } from "../../../../ui/Icon/Solid/Phone";
import { Section } from "../../../../ui/Modal/CustomModals/AddressBookModal";
import { DataCommon } from "../../../../utils/Common";
import {
  AddressBook,
  addressBookSelectors,
  getAddressBookAsync,
} from "../../../address/addressBookSlice";

import {
  getUsersInfoAsync,
  userInfoSelectors,
} from "../../../users/usersInfoSlice";
import "./AddressBookModal.css";

interface AddressBookModalProps {
  open: boolean;
  sections: Section[];
  currentActive: string;
  onClose: (e: any) => any;
  onAddressBookChange: (e: any) => any;
  onAddressBookRetrieve?: (e: any) => any;
  onErrorListChange?: (e: any) => any;
}

interface DataCommonProps {
  label: string;
}

export const AddEditDeleteContactModal: React.FC<AddressBookModalProps> = ({
  currentActive,
  onAddressBookChange,
  onAddressBookRetrieve,
  onErrorListChange,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;

  const isAddContact = pathname.includes("/add-contact");
  const isEditContact = pathname.includes("/edit-contact");
  const isValidateContact = pathname.includes("/validate-contact");
  const isDeleteContact = pathname.includes("/delete-contact");

  const editContactRoute = useMatch(
    "/admin/notifications/edit-contact/:addressBookId"
  );
  const deleteContactRoute = useMatch(
    "/admin/notifications/delete-contact/:addressBookId"
  );
  const validateContactRoute = useMatch(
    "/admin/notifications/validate-contact/:addressBookId"
  );

  const contactRoute =
    editContactRoute ?? deleteContactRoute ?? validateContactRoute;

  const addressBookId = parseInt(contactRoute?.params?.addressBookId ?? "-1");

  const [removedUsername, setRemovedUsername] = useState(false);
  const [sectionsSelected, setSectionsSelected] = useState(currentActive);
  const [errorList, setErrorList] = useState<string[]>([]);
  const [addressBook, setAddressBook] = useState<AddressBook>(
    {} as AddressBook
  );
  const users = useAppSelector((store) => userInfoSelectors.selectAll(store));
  const prefixList: DataCommonProps[] =
    DataCommon()?.prefixList ?? ([] as DataCommonProps[]);

  useEffect(() => {
    setSectionsSelected(currentActive);
  }, [currentActive]);

  //#region Upload Data
  const [id, setId] = useState<number>(-1);

  const retrievedAddressBook: AddressBook =
    useAppSelector((state) => addressBookSelectors.selectById(state, id)) ??
    ({} as AddressBook);

  useEffect(() => {
    store.dispatch(getUsersInfoAsync());
  }, []);

  useEffect(() => {
    if (retrievedAddressBook && !_.isEmpty(retrievedAddressBook)) {
      setAddressBook(retrievedAddressBook);
    }
  }, [retrievedAddressBook]);

  useEffect(() => {
    if (isAddContact) {
      setAddressBook({
        ...addressBook,
        telephonePrefix: prefixList[0].label,
      });
    }
    if (contactRoute !== null) {
      if (addressBookId) {
        setId(addressBookId);
        store.dispatch(getAddressBookAsync(addressBookId));
        return;
      }
    }
  }, [navigate, contactRoute]);

  useEffect(() => {
    onAddressBookChange(addressBook);
    if (onErrorListChange) {
      onErrorListChange(errorList);
    }
    if (
      retrievedAddressBook !== undefined &&
      onAddressBookRetrieve !== undefined
    ) {
      onAddressBookRetrieve(retrievedAddressBook);
    }
  }, [addressBook, retrievedAddressBook, errorList]);

  const getNullInitialValue = () => {
    return Object.values(addressBook).length === 0 ? null : "";
  };

  const [val, setVal] = useState<any>();

  const handleUsername = (username: string) => {
    if (users) {
      const indexUser: number = users.findIndex(
        (el) => el.username === username
      );
      if (indexUser !== -1) {
        const name =
          users[indexUser].firstName.toLowerCase().charAt(0).toUpperCase() +
          users[indexUser].firstName.toLowerCase().slice(1);

        const surname =
          users[indexUser].secondName.toLowerCase().charAt(0).toUpperCase() +
          users[indexUser].secondName.toLowerCase().slice(1);

        return name + " " + surname;
      } else {
        return undefined;
      }
    } else {
      return undefined;
    }
  };

  return (
    <>
      <div className="addressBook-page-content">
        <div className="add-contact-container">
          {isAddContact && (
            <div className="add-contact-title-bold">
              {t("admin.notifications.modal.add-contactText.label")}
              <span className="add-contact-title">
                {t("admin.notifications.modal.add-contactText.text")}
              </span>
            </div>
          )}
          {isEditContact && (
            <div className="add-contact-title-bold">
              {t("admin.notifications.modal.edit-contact.label")}
              <span className="add-contact-title">
                {t("admin.notifications.modal.edit-contact.text")}
              </span>
            </div>
          )}
          {isValidateContact && (
            <div className="add-contact-title-centered">
              {t("admin.notifications.modal.validate-contact")}
            </div>
          )}
          {isDeleteContact && (
            <div className="add-contact-title-centered">
              {t("admin.notifications.modal.delete-contact")}
            </div>
          )}
          <div className="add-contact-row-form">
            <div className="add-contact-left-subtitle">
              {t("common.name")} *
            </div>
            <div className="text-field">
              <Form>
                <TextField
                  id="name"
                  validate="alphabetic|isNotEmpty"
                  placeholder={t("common.name")}
                  errorList={setErrorList}
                  value={addressBook?.firstName}
                  events={{
                    onChange: (data) => {
                      setAddressBook({
                        ...addressBook,
                        firstName: data.value ?? undefined,
                      });
                    },
                  }}
                  disabled={isDeleteContact || isValidateContact ? true : false}
                />
              </Form>
            </div>
          </div>
          <div className="add-contact-row-form">
            <div className="add-contact-left-subtitle">
              {t("common.surname")} *
            </div>
            <div className="text-field">
              <Form>
                <TextField
                  id="name"
                  validate="alphabetic|isNotEmpty"
                  placeholder={t("common.surname")}
                  errorList={setErrorList}
                  value={addressBook?.lastName}
                  events={{
                    onChange: (data) => {
                      setAddressBook({
                        ...addressBook,
                        lastName: data.value ?? undefined,
                      });
                    },
                  }}
                  disabled={isDeleteContact || isValidateContact ? true : false}
                />
              </Form>
            </div>
          </div>
          <div className="add-contact-row-form">
            <div className="add-contact-left-subtitle">
              {t("common.phoneNumber")}
            </div>
            <div className="add-contact-row-form-dropdown-prefix">
              <Dropdown
                isClearable={false}
                placeholder={t("common.phonePrefix")}
                value={{
                  label: addressBook?.telephonePrefix,
                }}
                size="normal"
                itemAttribute="label"
                onChange={(data) => {
                  setAddressBook({
                    ...addressBook,
                    telephonePrefix: data[0].label ?? undefined,
                  });
                }}
                options={prefixList}
                isDisabled={isDeleteContact || isValidateContact ? true : false}
              />
            </div>
            <div className="add-contact-telephone">
              <Form>
                <TextField
                  id="telephone"
                  validate="numeric"
                  placeholder={t("common.phone")}
                  errorList={setErrorList}
                  value={addressBook?.telephone ?? getNullInitialValue()}
                  events={{
                    onChange: (data) =>
                      setAddressBook({
                        ...addressBook,
                        telephone: data.value ?? undefined,
                      }),
                  }}
                  children={
                    <IconPhone size={14} color="--global-colors-ink-light" />
                  }
                  maxLength="10"
                  minLength="9"
                  disabled={isDeleteContact || isValidateContact ? true : false}
                />
              </Form>
            </div>
          </div>
          <div className="add-contact-row-form">
            <div className="add-contact-left-subtitle">{t("common.email")}</div>
            <div className="add-contact-text-fields">
              <Form>
                <TextField
                  id="email"
                  validate="email"
                  placeholder={t("common.email")}
                  errorList={setErrorList}
                  value={addressBook?.email ?? getNullInitialValue()}
                  events={{
                    onChange: (data) =>
                      setAddressBook({
                        ...addressBook,
                        email: data.value ?? undefined,
                      }),
                  }}
                  children={
                    <IconMail size={14} color="--global-colors-ink-light" />
                  }
                  disabled={isDeleteContact || isValidateContact ? true : false}
                />
              </Form>
            </div>
          </div>
          <div className="add-contact-row-form">
            <div className="add-contact-left-subtitle">
              {t("common.user").toLowerCase().charAt(0).toUpperCase() +
                t("common.user")?.toLowerCase().slice(1)}
            </div>
            <div className="add-contact-text-fields-username">
              <Dropdown
                isClearable={
                  isDeleteContact || isValidateContact ? false : true
                }
                itemAttribute="label"
                placeholder={
                  _.isEmpty(addressBook?.username)
                    ? t("admin.driver.editViewCreate.selectUser")
                    : t("common.na")
                }
                value={
                  addressBook && addressBook?.username !== undefined
                    ? {
                        label: handleUsername(addressBook.username),
                        id: removedUsername
                          ? undefined
                          : addressBook.username ?? undefined,
                      }
                    : {
                        label: removedUsername
                          ? undefined
                          : val?.label ?? undefined,
                        id: removedUsername ? undefined : val?.id ?? undefined,
                      }
                }
                size="normal"
                onChange={(val) => {
                  setVal(val[0]);
                  const username =
                    val && val.length > 0
                      ? val[0].id
                      : val && val.length === 0
                      ? null
                      : addressBook.username;

                  if (val && val.length === 0) {
                    setRemovedUsername(true);
                  } else if (val && val.length > 0) {
                    setRemovedUsername(false);
                  }

                  setAddressBook({
                    ...addressBook,
                    username: username,
                  });
                }}
                options={users
                  .filter((y) => y?.securityProfile?.name != "HS_INTEGRATION")
                  .map((y) => ({
                    id: y?.username,
                    label: `${y?.firstName
                      ?.toLowerCase()
                      .charAt(0)
                      .toUpperCase()}${y?.firstName
                      ?.toLowerCase()
                      .slice(1)} ${y?.secondName
                      ?.toLowerCase()
                      .charAt(0)
                      .toUpperCase()}${y?.secondName?.toLowerCase().slice(1)}`,
                  }))}
                isDisabled={isDeleteContact || isValidateContact ? true : false}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddEditDeleteContactModal;
