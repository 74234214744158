import { schema } from "normalizr";
import { tenantSchema } from "../tenants/tenantNormalization";

export const logEventSchema: schema.Entity<any> = new schema.Entity(
  "logEvent",
  {
    tenant: tenantSchema,
  }
);
export const logEventsSchema = new schema.Array(logEventSchema);
