import { t } from "i18next";
import _ from "lodash";
import { useEffect, useState } from "react";
import { Button } from "../../ui/Button/Button";
import { ElementType } from "../../ui/Group/ElementTypeEnum";
import { GroupElement } from "../../ui/Group/GroupElement";
import { GroupWidget } from "../../ui/Group/GroupWidget";
import { FleetView } from "./fleetViewsSlice";
import { Fleet } from "./fleetsSlice";

//#region types
export interface ResourceProps {
  key: number;
  id: number;
  fleet: number;
  firstTitle: string;
  secondTitle: string;
  status:
    | "NO_SIGNAL"
    | "ONLINE"
    | "MOVING"
    | "PARKING"
    | "OFFLINE"
    | "UNKNOWN"
    | "STOP";
  firstSubtitle: string;
  secondSubtitle: string;
  type: ElementType;
  icon: JSX.Element;
  categoryColor: string;
}

interface AdminFleetsResourcesTabProps {
  tabType: "drivers" | "vehicles" | "geofences";
  resources: ResourceProps[];
  selectedFleet: Fleet;
  fleets: FleetView[];
  output: (output: ResourceProps[]) => any;
  defaultFleet?: FleetView;
  onlyDefaultFleetNewResources?: boolean;
}
//#endregion

export const AdminFleetsResourcesTab: React.FC<AdminFleetsResourcesTabProps> =
  ({
    tabType,
    resources,
    selectedFleet,
    fleets,
    output,
    defaultFleet,
    onlyDefaultFleetNewResources,
  }) => {
    //this hooks keep the search string for selected items
    const [leftSearchString, setLeftSearchString] = useState("");
    const [rightSearchString, setRightSearchString] = useState("");

    //#region variables to manage groups between cards
    const [resourcesList, setResourcesList] = useState<ResourceProps[]>([]);
    const [selectedResourcesList, setSelectedResourcesList] = useState<
      ResourceProps[]
    >([]);

    let selectedFleetResources: number[] = [];
    if (!!selectedFleet && !_.isEmpty(selectedFleet)) {
      if (tabType === "vehicles") {
        selectedFleetResources = selectedFleet?.vehicles;
      }
      if (tabType === "drivers") {
        selectedFleetResources = selectedFleet?.drivers;
      }
      if (tabType === "geofences") {
        selectedFleetResources = selectedFleet?.geofences;
      }
    }
    //#endregion

    //#region group slice variables
    //#endregion

    //#region slice statuses
    //#endregion

    //#region handle states associated
    /**
     * Handles the population of the states associated to the right card of the fleet edit
     */
    useEffect(() => {
      const newSelectedResourcesList: ResourceProps[] = [];
      if (selectedFleet && selectedFleetResources && resources.length > 0) {
        for (const resourceId of selectedFleetResources) {
          const resource = resources.find((x) => x.id === resourceId);
          if (resource) {
            if (!selectedResourcesList.some((x) => x.id === resource.id)) {
              newSelectedResourcesList.push(resource);
            }
          }
        }
      }
      if (newSelectedResourcesList.length > 0) {
        setSelectedResourcesList((prev) => [
          ...prev,
          ...newSelectedResourcesList,
        ]);
      }
    }, [resources, selectedFleetResources]);
    //#endregion

    //#region build props for components
    /**
     * This useEffects should populate the left card component with the default fleet resources.
     */
    useEffect(() => {
      const newResourcesList: ResourceProps[] = [];
      if (
        !!defaultFleet &&
        !_.isEmpty(defaultFleet) &&
        onlyDefaultFleetNewResources
      ) {
        for (const resource of resources) {
          const previouslyRemoved =
            resource.fleet === selectedFleet.id &&
            !selectedFleetResources.some((x) => x === resource.id);
          const belongsToDefaultFleet = resource.fleet === defaultFleet?.id;
          if (previouslyRemoved || belongsToDefaultFleet) {
            const isNotSelected =
              selectedFleetResources &&
              !selectedFleetResources.some((x) => x === resource.id);
            if (isNotSelected) {
              const isNotAlreadyPresent = !resourcesList.some(
                (x) => x.id === resource.id
              );
              if (isNotAlreadyPresent) {
                newResourcesList.push(resource);
              }
            }
          }
        }
      }
      if (
        (!defaultFleet || _.isEmpty(defaultFleet)) &&
        !onlyDefaultFleetNewResources
      ) {
        for (const resource of resources) {
          const isNotSelected =
            selectedFleetResources &&
            !selectedFleetResources.some((x) => x === resource.id);
          const isNotAlreadyPresent = !resourcesList.some(
            (x) => x.id === resource.id
          );
          if (isNotSelected && isNotAlreadyPresent) {
            newResourcesList.push(resource);
          }
        }
      }
      if (newResourcesList.length > 0) {
        setResourcesList((prev) => [...prev, ...newResourcesList]);
      }
    }, [resources, fleets, defaultFleet, selectedFleet]);
    //#endregion

    //#region filter selected items
    const filterResources = (
      resources: ResourceProps[],
      searchString: string
    ) => {
      if (searchString === "") return resources;
      else {
        return resources.filter(
          (resource: ResourceProps) =>
            (resource?.firstTitle &&
              resource?.firstTitle
                ?.toLowerCase()
                .includes(searchString.toLowerCase())) ||
            (resource?.secondTitle &&
              resource?.secondTitle
                ?.toLowerCase()
                .includes(searchString.toLowerCase()))
        );
      }
    };
    //#endregion

    //#region toast notifications

    //#endregion

    return (
      <>
        <GroupWidget
          counter={
            resourcesList.filter(
              (x) => !selectedResourcesList.some((y) => y.id === x.id)
            ).length
          }
          title={t("common." + tabType)}
          type={tabType}
          children={filterResources(resourcesList, leftSearchString)
            .filter((x) => !selectedResourcesList.some((y) => y.id === x.id))
            .map((resource: ResourceProps) => (
              <GroupElement
                key={resource?.id}
                id={resource?.id}
                firstTitle={resource?.firstTitle}
                secondTitle={resource?.secondTitle}
                status={resource?.status}
                firstSubtitle={resource?.firstSubtitle}
                secondSubtitle={resource?.secondSubtitle}
                type={resource?.type}
                isAdded={false}
                icon={resource?.icon}
                categoryColor={resource?.categoryColor}
                onClicked={(id) => {
                  // find the index of object into the started list that match with id of clicked element
                  const index = resourcesList.findIndex(
                    (item) => item.id === id
                  );

                  // check wheter or no the object exists and move it into the end list
                  if (index !== -1) {
                    const item = resourcesList.splice(index, 1)[0]; // remove the object from start list
                    // setting the item on the other side list
                    const newSelectedResourcesList = [
                      ...selectedResourcesList,
                      item,
                    ];
                    setSelectedResourcesList(newSelectedResourcesList);
                    if (output) {
                      output(newSelectedResourcesList);
                    }
                  }
                }}
              />
            ))}
          titleButton={
            <Button
              size="small"
              aspect="outline"
              label={t("admin.groups.editGroups.widgetButtonLabelAdd")}
              onClick={() => {
                const newSelectedResourcesList = [
                  ...selectedResourcesList,
                  ...resourcesList,
                ];
                setSelectedResourcesList(newSelectedResourcesList);
                if (output) {
                  output(newSelectedResourcesList);
                }
                setResourcesList([]);
              }}
              disabled={
                resourcesList.filter(
                  (x) => !selectedResourcesList.some((y) => y.id === x.id)
                ).length === 0
              }
            />
          }
          filterCallback={(val: string) => {
            setLeftSearchString(val);
          }}
        />
        <GroupWidget
          title={selectedFleet.name}
          counter={selectedResourcesList.length}
          children={filterResources(
            selectedResourcesList,
            rightSearchString
          ).map((resource: ResourceProps) => (
            <GroupElement
              key={resource?.id}
              id={resource?.id}
              firstTitle={resource?.firstTitle}
              secondTitle={resource?.secondTitle}
              status={resource?.status}
              firstSubtitle={resource?.firstSubtitle}
              secondSubtitle={resource?.secondSubtitle}
              type={resource?.type}
              isAdded={true}
              icon={resource?.icon}
              categoryColor={resource?.categoryColor}
              onClicked={(id) => {
                // find the index of object into the started list that match with id of clicked element
                const index = selectedResourcesList.findIndex(
                  (item) => item.id === id
                );

                // check wheter or no the object exists and move it into the end list
                if (index !== -1) {
                  const item = selectedResourcesList.splice(index, 1)[0]; // remove the object from start list
                  const newSelectedResourcesList = selectedResourcesList.filter(
                    (x) => x.id !== item.id
                  );
                  setSelectedResourcesList(newSelectedResourcesList);
                  if (output) {
                    output(newSelectedResourcesList);
                  }
                  if (!resourcesList.some((x) => x.id === item.id)) {
                    setResourcesList((prev) => [...prev, item]); // setting the item on the other side list
                  }
                }
              }}
            />
          ))}
          type={tabType}
          hasNoChildIcon={selectedResourcesList.length === 0}
          titleButton={
            <Button
              size="small"
              aspect="outline"
              label={t("admin.groups.editGroups.widgetButtonLabelRemove")}
              onClick={() => {
                const newResourcesList = [
                  ...resourcesList,
                  ...selectedResourcesList,
                ];
                setResourcesList(newResourcesList);
                setSelectedResourcesList([]);
                if (output) {
                  output([]);
                }
              }}
              disabled={selectedResourcesList.length === 0}
            />
          }
          filterCallback={(val: string) => {
            setRightSearchString(val);
          }}
        />
      </>
    );
  };
