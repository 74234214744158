import { t } from "i18next";
import _ from "lodash";
import { IconExternalLink } from "../../ui/Icon/Line/ExternalLink";
import { IconUser } from "../../ui/Icon/Line/User";
import { IconKey } from "../../ui/Icon/Solid/Key";
import { LabelText } from "../../ui/LabelText/LabelText";
import { Table } from "../../ui/Table/Table";
import {
  AbstractTableBuilder,
  Row,
} from "../../ui/Table/utils/AbstractTableBuilder";
import "../../ui/Table/utils/TableBuilder.css";
import { Tag } from "../../ui/Tags/Tag";
import {
  getAddress,
  getCity,
  kmPerLtToMilesPerG,
  kmToMiles,
  ltToGal,
  mtToKm,
  numberAnnotation,
} from "../../utils/Utils";
import { Device } from "../device/devicesSlice";
import { Driver } from "../driver/driversSlice";
import { DriverStatus } from "../driver/driversStatusSlice";
import { FleetView } from "../fleet/fleetViewsSlice";
import { Preferences } from "../users/preference/preferencesSlice";
import { Vehicle } from "./vehiclesSlice";
import { VehicleStatus } from "./vehiclesStatusSlice";

export class DashboardVehiclesTableBuilder extends AbstractTableBuilder {
  /**
   * Given data, it builds a table row
   * @param vehicle
   * @returns
   */
  rowsBuilder = (
    columns: { label: string; field: string; sort: boolean }[] | undefined,
    entities: {
      vehicle?: Vehicle;
      vehicleStatus?: VehicleStatus;
      driver?: Driver;
      driverStatus?: DriverStatus;
      fleetView?: FleetView;
      device?: Device;
    },
    preferencesContext: Preferences,
    writePermission: boolean = false
  ) => {
    let row: Row = { render: {} as Row };

    let vehicle: Vehicle = entities.vehicle ?? ({} as Vehicle);

    let vehicleStatus: VehicleStatus =
      entities.vehicleStatus ?? ({} as VehicleStatus);

    let driver: Driver = entities.driver ?? ({} as Driver);

    let driverStatus: DriverStatus =
      entities.driverStatus ?? ({} as DriverStatus);

    let device: Device = entities.device ?? ({} as Device);

    let fleetView: FleetView = entities.fleetView ?? ({} as FleetView);

    columns?.forEach((column) => {
      switch (column.field) {
        case "vehicle":
          row.vehicle = vehicle.alias ?? t("common.na");
          row.render.vehicle = (
            <LabelText
              label={vehicle.alias ?? t("common.na")}
              text={
                vehicle.brand
                  ? vehicle.brand + " " + vehicle.model
                  : t("common.na")
              }
            />
          );
          break;
        case "license":
          row.render.license = vehicle.plate ?? t("common.na");
          break;
        case "group":
          row.group = fleetView.name ?? t("common.na");
          row.render.group = fleetView.name ?? t("common.na");
          break;
        case "currentDriver":
          row.render.currentDriver = (
            <Table.TextIcon
              text={
                !_.isEmpty(driver)
                  ? driver.firstName + " " + driver.lastName
                  : t("common.na")
              }
              icon={
                <IconUser
                  size={14}
                  color="var(--global-colors-ink-light)"
                  class="classTravelDriver"
                />
              }
            />
          );
          break;
        case "currentLocation":
          if (vehicleStatus.dynamicFields?.address) {
            row.render.currentLocation = (
              <>
                <p className="reportMarked">
                  {getCity(vehicleStatus.dynamicFields?.address)}
                </p>
                <p className="reportNormal">
                  {getAddress(vehicleStatus.dynamicFields?.address)}
                </p>
              </>
            );
          } else {
            row.render.currentLocation = t("common.na");
          }
          break;

        case "consumption":
          row.render.consumption = (
            <Table.TextStatus
              text={
                preferencesContext.isMetric
                  ? numberAnnotation(vehicle?.consumption, 1) + " lt/km"
                  : numberAnnotation(
                      kmPerLtToMilesPerG(vehicle?.consumption),
                      1
                    ) + " gal/mil"
              }
            />
          );
          break;
        case "emissions":
          row.render.emissions = (
            <Table.TextStatus
              text={vehicle?.emissions.toString() ?? t("common.na")}
            />
          );
          break;
        case "fuelCapacity":
          row.render.fuelCapacity = (
            <Table.TextStatus
              text={
                preferencesContext.isMetric
                  ? numberAnnotation(vehicle?.fuelCapacity, 1) + " lt"
                  : numberAnnotation(ltToGal(vehicle?.fuelCapacity), 1) + " gal"
              }
            />
          );
          break;
        case "fuelType":
          let fuelTypeValue = vehicle?.fuelType ?? "";
          row.render.fuelType = (
            <Table.TextStatus
              text={
                fuelTypeValue.replace("_", " ").toLowerCase() ?? t("common.na")
              }
            />
          );
          break;
        case "engine":
          let engineStatusLabel = t("common.na");
          if (vehicleStatus.dynamicFields?.engineLock) {
            engineStatusLabel = t("common.engineOption.locked");
          } else if (vehicleStatus.dynamicFields?.engineLock === false) {
            engineStatusLabel = t("common.engineOption.unlocked");
          }
          row.render.engine = engineStatusLabel;
          break;
        case "driverStatusActivity":
          let driverStatusLabel = driverStatus.driverStatus ?? "unknown";
          row.render.driverStatus = (
            <Tag
              text={t(
                "pages.dashboardVehicles.driverStatus." +
                  driverStatusLabel.toLocaleLowerCase()
              )}
              size="small"
              mode={
                `${driverStatusLabel}` === t("common.na") ? "outline" : "fill"
              }
              type={(() => {
                switch (`${driverStatusLabel}`) {
                  case "ONLINE":
                    return "success";
                  case "OFFLINE":
                    return "neutro";
                  default:
                    return "neutro2";
                }
              })()}
              hasIcon={false}
            />
          );
          break;
        case "vehicleStatusActivity":
          row.render.vehicleStatus = (
            <Tag
              text={
                vehicleStatus?.dynamicFields?.vehicleStatus
                  ? t(
                      "fleetControl.filterBar.optionStatusVehicle." +
                        vehicleStatus.dynamicFields.vehicleStatus.toLowerCase()
                    )
                  : t("fleetControl.filterBar.optionStatusVehicle.unknown")
              }
              size="small"
              mode="fill"
              type={(() => {
                switch (`${vehicleStatus?.dynamicFields?.vehicleStatus}`) {
                  case "MOVING":
                    return "success";
                  case "PARKING":
                    return "danger";
                  case "STOP":
                    return "secondary";
                  case "UNKNOWN":
                    return "neutro2";
                  case "OFFLINE":
                    return "neutro";
                  default:
                    return undefined;
                }
              })()}
              hasIcon={false}
            />
          );
          break;
        case "vehicleKey":
          let ignitionStatus;
          ignitionStatus = vehicleStatus?.dynamicFields?.ignitionKey
            ? "on"
            : "off";
          row.render.vehicleKey = (
            <Table.TextIcon
              text={ignitionStatus ?? t("common.na")}
              icon={
                <IconKey
                  size={14}
                  color={
                    vehicleStatus?.dynamicFields?.ignitionKey
                      ? "--global-colors-feedback-success"
                      : "--global-colors-feedback-danger"
                  }
                />
              }
            />
          );
          break;
        case "currentOdometer":
          if (vehicleStatus.dynamicFields?.odometer) {
            row.render.currentOdometer = preferencesContext.isMetric
              ? numberAnnotation(
                  mtToKm(vehicleStatus?.dynamicFields.odometer),
                  1
                ) + " km"
              : numberAnnotation(
                  kmToMiles(mtToKm(vehicleStatus?.dynamicFields.odometer)),
                  1
                ) + " mil";
          } else {
            row.render.currentOdometer = t("common.na");
          }
          break;
        case "device":
          row.render.device = (
            <Table.TextStatus text={device.modelName ?? t("common.na")} />
          );
          break;
        default:
          break;
      }
    });

    row.render.action = (
      <IconExternalLink
        className=""
        size={14}
        color="--global-colors-ink-ink"
        onClick={() => {
          window
            .open(
              "/dashboard/fleets/" +
                vehicle.fleet +
                "/vehicles/" +
                vehicle.id +
                "/details",
              "_blank"
            )
            ?.focus();
        }}
      />
    );

    return row;
  };
}
