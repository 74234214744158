import { schema } from "normalizr";
import { vehicleSchema } from "../vehicle/vehicleNormalization";

//#region REPORT_IO
export const reportIOSchema: schema.Entity<any> = new schema.Entity(
  "reportsIO",
  {
    vehicleDetails: vehicleSchema,
  }
);
export const reportsIOSchema = new schema.Array(reportIOSchema);

export const reportsIOSummarySchema: schema.Entity<any> = new schema.Entity(
  "reportsIOSummary",
  {}
);

export const reportIOAndSummarySchema: schema.Entity<any> = new schema.Entity(
  "reportIOAndSummary",
  {
    summary: reportsIOSummarySchema,
    reportsIO: reportsIOSchema,
  }
);

export const reportUtilizationSchema: schema.Entity<any> = new schema.Entity(
  "reportsUtilization",
  {
    vehicleDetails: vehicleSchema,
  }
);

export const reportsUtilizationSchema = new schema.Array(
  reportUtilizationSchema
);

export const reportsUtilizationSummarySchema: schema.Entity<any> =
  new schema.Entity("summary", {});

export const reportUtilizationAndSummarySchema = {
  summary: reportsUtilizationSummarySchema,
  reportsUtilization: reportsUtilizationSchema,
};

//#endregion REPORT_IO

//#region GPS_DATA_REPORT
export const gpsDataReportSchema: schema.Entity<any> = new schema.Entity(
  "gpsDataReports"
);
export const gpsDataReportsSchema = new schema.Array(gpsDataReportSchema);

export const gpsDataReportsSummarySchema: schema.Entity<any> =
  new schema.Entity("gpsDataReportsSummary");
//#endregion GPS_DATA_REPORT
