import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import i18next, { t } from "i18next";
import _ from "lodash";
import { useCallback, useContext, useEffect, useState } from "react";
import { useMatch, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { store } from "../../app/store";
import { GTFleetErrorCodes } from "../../config/GTfleetErrorCodes";
import i18n from "../../i18n/i18nextConf";
import PageContent from "../../layout/PageContent";
import { Card } from "../../ui/Card/Card";
import { DataLabel } from "../../ui/DataLabel/DataLabel";
import { DriverSummary } from "../../ui/Drivers/DriverSummary";
import { Tooltip } from "../../ui/Forms/Tooltip";
import { IconCalendar } from "../../ui/Icon/Line/Calendar";
import { IconDevices } from "../../ui/Icon/Line/Devices";
import { IconDollar } from "../../ui/Icon/Line/Dollar";
import { IconEuro } from "../../ui/Icon/Line/Euro";
import { IconGroup } from "../../ui/Icon/Line/Group";
import { IconLicense } from "../../ui/Icon/Line/License";
import { IconMail } from "../../ui/Icon/Line/Mail";
import { IconMenuProfile } from "../../ui/Icon/Line/MenuProfile";
import { IconMenuRoutes } from "../../ui/Icon/Line/MenuRoutes";
import { IconPhone } from "../../ui/Icon/Line/Phone";
import { IconPound } from "../../ui/Icon/Line/Pound";
import { IconTimer } from "../../ui/Icon/Line/Timer";
import { IconTruck } from "../../ui/Icon/Line/Truck";
import { IconPin } from "../../ui/Icon/Solid/Pin";
import { IconWheelChange } from "../../ui/Icon/Solid/WheelChange";
import { Toolbar } from "../../ui/Navigation/Toolbar";
import { SingleItemContainer } from "../../ui/SingleItem/SingleItemContainer";
import { ThumbProfile } from "../../ui/ThumbProfile/ThumbProfile";
import { HoursService } from "../../ui/Vehicles/HoursService";
import { LocationMap, PositionProps } from "../../ui/Vehicles/LocationMap";
import { ConvertTimeZone, formatTime } from "../../utils/DateAndTimeUtils";
import { ToastNotification } from "../../utils/ToastNotification";
import {
  convertEuroOnCurrency,
  getQueryString,
  kmToMiles,
  mtToKm,
  numberAnnotation,
} from "../../utils/Utils";
import {
  FleetView,
  fleetViewsEmptyState,
  fleetViewsSelectors,
} from "../fleet/fleetViewsSlice";
import {
  Deadline,
  deadlinesEmptyState,
  deadlinesSelectors,
  getDeadlinesAsync,
  selectDeadlinesSliceReasonCode,
  selectDeadlinesSliceStatus,
} from "../maintenance-deadline/deadlinesSlice";
import {
  DriverTravelSummary,
  driverTravelsSummarySelectors,
  getDriverLastTracksAsync,
  selectDriverTravelsSummarySliceReasonCode,
  selectDriverTravelsSummarySliceStatus,
} from "../report/driver/driverTravelsSummarySlice";
import { Preferences } from "../users/preference/preferencesSlice";
import { UserPermissions } from "../users/privilege/privilegesSlice";
import UserContext from "../users/userContext";
import {
  Vehicle,
  vehiclesEmptyState,
  vehiclesSelectors,
} from "../vehicle/vehiclesSlice";
import {
  VehicleStatus,
  getFilteredVehiclesStatusAsync,
  selectVehiclesStatusSliceReasonCode,
  selectVehiclesStatusSliceStatus,
  vehiclesStatusEmptyState,
  vehiclesStatusSelectors,
} from "../vehicle/vehiclesStatusSlice";
import "./DriverDetails.css";
import {
  Driver,
  driversEmptyState,
  driversSelectors,
  getDriverDetailsAsync,
  selectDriversSliceReasonCode,
  selectDriversSliceStatus,
} from "./driversSlice";
import {
  DriverStatus,
  driversStatusEmptyState,
  driversStatusSelectors,
  getDriverStatusAsync,
  getFilteredDriversStatusAndDetailsAsync,
  selectDriverStatusSliceReasonCode,
  selectDriverStatusSliceStatus,
} from "./driversStatusSlice";

interface DriverDetailsProps {
  permissions: UserPermissions;
}

//#region Details
function getDetails(driver: Driver, fleetView: FleetView, vehicle: Vehicle) {
  return [
    {
      icon: <IconTruck size={14} color="--global-colors-ink-light" />,
      label: vehicle.alias ?? t("common.na"),
      description: t("pages.dashboardDrivers.summaryDetails.vehicleAlias"),
    },
    {
      icon: <IconGroup size={14} color="--global-colors-ink-light" />,
      label: fleetView.name ?? t("common.na"),
      description: t("pages.dashboardDrivers.summaryDetails.group"),
    },
    {
      icon: <IconDevices size={14} color="--global-colors-ink-light" />,
      label: vehicle.driverIdentifiedBy ?? t("common.na"),
      description: t("pages.dashboardDrivers.summaryDetails.detectedBy"),
    },
    {
      icon: <IconLicense size={14} color="--global-colors-ink-light" />,
      label: driver.licenseId ?? t("common.na"),
      description: t("pages.dashboardDrivers.summaryDetails.license"),
    },
    {
      icon: <IconPhone size={14} color="--global-colors-ink-light" />,
      label:
        driver.phoneNumberPrefix && driver.phoneNumber
          ? `${driver.phoneNumberPrefix} ${driver.phoneNumber}`
          : t("common.na"),
      description: t("pages.dashboardDrivers.summaryDetails.phone"),
    },
    {
      icon: <IconMail size={14} color="--global-colors-ink-light" />,
      label: driver.email ?? t("common.na"),
      description: t("pages.dashboardDrivers.summaryDetails.mail"),
    },
    {
      icon: <IconDevices size={14} color="--global-colors-ink-light" />,
      label: driver.tachographCard ?? t("common.na"),
      description: t("pages.dashboardDrivers.summaryDetails.device"),
    },
    {
      icon: <IconCalendar size={14} color="--global-colors-ink-light" />,
      label: driver.dateOfBirth
        ? new Date(driver.dateOfBirth)
            .toLocaleString(i18n.language)
            .split(", ")[0]
        : t("common.na"),
      description: t("pages.dashboardDrivers.summaryDetails.calendar"),
    },
    {
      icon: <IconPin size={14} color="--global-colors-ink-light" />,
      label: driver.address ?? t("common.na"),
      description: t("pages.dashboardDrivers.summaryDetails.address"),
    },
    {
      icon: <IconMenuProfile size={14} color="--global-colors-ink-light" />,
      label: driver.username ?? t("common.na"),
      description: t("pages.dashboardDrivers.summaryDetails.username"),
    },
  ];
}
//#endregion Details

//#region Interface Data for Deadlines
interface DataTextDeadlines {
  dataText: string;
  label: string;
  isExpired: boolean;
  id: number;
}
//#endregion

export const DriverDetails: React.FC<DriverDetailsProps> = ({
  permissions,
}) => {
  //#region Const
  const navigate = useNavigate();
  const driverRoute = useMatch("/dashboard/drivers/details/:driverId");

  const [driverId, setDriverId] = useState(-1);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [vehiclePosition, setVehiclePosition] =
    useState<PositionProps | undefined>(undefined);

  const handleSettingsFunc = permissions.drivers.write
    ? () => navigate("/admin/drivers/edit/" + driver.id)
    : undefined;

  //#endregion Const

  //#region Data
  let drivers: Driver[] = useAppSelector(driversSelectors.selectAll);
  let driver: Driver =
    useAppSelector((state) => driversSelectors.selectById(state, driverId)) ??
    ({} as Driver);
  let driverStatus: DriverStatus =
    useAppSelector((state) =>
      driversStatusSelectors.selectById(state, driver.id)
    ) ?? ({} as DriverStatus);
  let fleetView: FleetView =
    useAppSelector((state) =>
      fleetViewsSelectors.selectById(state, driver.fleet)
    ) ?? ({} as FleetView);
  let vehicle: Vehicle =
    useAppSelector((state) =>
      vehiclesSelectors.selectById(state, driver.vehicle)
    ) ?? ({} as Vehicle);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  let vehicleStatus: VehicleStatus =
    useAppSelector((state) =>
      vehiclesStatusSelectors.selectById(state, vehicle.vehicleStatus)
    ) ?? ({} as VehicleStatus);

  const driverTravelSummary: DriverTravelSummary[] = useAppSelector(
    driverTravelsSummarySelectors.selectAll
  );

  const driverTravelSummarySliceStatus = useAppSelector(
    selectDriverTravelsSummarySliceStatus
  );
  const driverTravelSummarySliceReasonCode = useAppSelector(
    selectDriverTravelsSummarySliceReasonCode
  );

  useEffect(() => {
    document.title = t("navigation.userMenu.drivers");
    store.dispatch(getFilteredDriversStatusAndDetailsAsync(""));

    return () => {
      store.dispatch(deadlinesEmptyState());
      store.dispatch(driversEmptyState());
      store.dispatch(driversStatusEmptyState());
      store.dispatch(fleetViewsEmptyState());
      store.dispatch(vehiclesStatusEmptyState());
      store.dispatch(vehiclesEmptyState());
    };
  }, []);

  useEffect(() => {
    let index: number = drivers.findIndex((element) => element.id === driverId);
    index = index !== -1 ? index : 1;
    setCurrentIndex(index);
  }, [driverId, drivers]);

  useEffect(() => {
    if (driverRoute !== null && driverRoute.params.driverId) {
      setDriverId(Number(driverRoute.params.driverId));
      store.dispatch(
        getDriverDetailsAsync(Number(driverRoute.params.driverId))
      );
      store.dispatch(getDriverStatusAsync(Number(driverRoute.params.driverId)));
      return;
    }
    // own drivers list does not contain desired id --> bad request
    console.error(t("common.unauthorizedError"));
    ToastNotification({
      toastId: "unauthorizedError",
      status: "error",
      description: t("common.unauthorizedError"),
    });
    navigate("/dashboard/drivers");
  }, [driverRoute, navigate]);

  // update current driver info
  useEffect(() => {
    if (driver.id) {
      if (driver.firstName && driver.lastName) {
        document.title =
          `${driver.firstName} ${driver.lastName} - ` +
          t("navigation.userMenu.drivers");
      }
      if (driver?.vehicle && permissions.vehicles.read) {
        // load updated vehicle status
        store.dispatch(
          getFilteredVehiclesStatusAsync(getQueryString({ id: driver.vehicle }))
        );
      }

      // load latest routes
      const endPeriod = dayjs().format("YYYY-MM-DDTHH:mm");
      const startPeriodDate = new Date(
        dayjs().subtract(7, "day").format("YYYY-MM-DDTHH:mm")
      );
      startPeriodDate.setHours(0, 0, 0, 0);
      const startPeriod = dayjs(startPeriodDate).format("YYYY-MM-DDTHH:mm");
      store.dispatch(
        getDriverLastTracksAsync({
          driverId: driverId,
          queryParams: getQueryString({
            "driver.id": driverId,
            startPeriod: startPeriod,
            endPeriod: endPeriod,
            routeStateTypeEnum: "TRACK",
          }),
        })
      );
      // load own deadlines
      if (permissions.deadlines.read) {
        store.dispatch(
          getDeadlinesAsync({
            queryParams: getQueryString({
              "driver.id": driverId,
            }),
          })
        );
      }
    }
  }, [driver.id, driver.vehicle]);

  // update current driver position (from his latest vehicle)
  useEffect(() => {
    let time = vehicleStatus?.dynamicFields?.lastUpdate
      ? ConvertTimeZone(
          new Date(vehicleStatus?.dynamicFields?.lastUpdate),
          preferencesContext.timeZone,
          preferencesContext.localeFormat
        ).replace(",", " |")
      : t("common.na");
    if (!_.isEmpty(driverStatus)) {
      time = driverStatus?.dynamicFields?.lastUpdate
        ? ConvertTimeZone(
            new Date(driverStatus?.dynamicFields?.lastUpdate),
            preferencesContext.timeZone,
            preferencesContext.localeFormat
          ).replace(",", " |")
        : t("common.na");
    }
    if (
      vehicleStatus.dynamicFields?.latitude &&
      vehicleStatus.dynamicFields?.longitude
    ) {
      setVehiclePosition({
        lat: vehicleStatus.dynamicFields?.latitude,
        long: vehicleStatus.dynamicFields?.longitude,
        location: vehicleStatus.dynamicFields?.address ?? t("common.na"),
        time: time,
        vehicleStatus: vehicleStatus.dynamicFields.vehicleStatus,
      });
    }
  }, [driverStatus, vehicleStatus]);

  //#endregion Data

  //#region HoursService
  const getSlots = (driverStatus: DriverStatus) => {
    return [
      {
        drivingSince: driverStatus?.dynamicFields?.drivingSince
          ? dayjs
              .duration(driverStatus?.dynamicFields?.drivingSince)
              .format("HH:mm")
          : "--:--",
        totalToday: driverStatus?.dynamicFields?.totalToday
          ? dayjs
              .duration(driverStatus?.dynamicFields?.totalToday)
              .format("HH:mm")
          : "--:--",
        kmTraveled: driverStatus?.dynamicFields?.kmTraveled
          ? (driverStatus?.dynamicFields?.kmTraveled / 1000).toFixed(1)
          : "0",
        lastUpdate: driverStatus?.dynamicFields?.lastUpdate
          ? new Date(driverStatus?.dynamicFields?.lastUpdate).toLocaleString(
              i18next.language
            )
          : "",
        vehicleType: "Car",
      },
    ];
  };
  //#region HoursService

  //#region Notifications
  const deadlinesSliceStatus = useAppSelector(selectDeadlinesSliceStatus);
  const deadlinesSliceReasonCode = useAppSelector(
    selectDeadlinesSliceReasonCode
  );

  const driverSliceStatus = useAppSelector(selectDriversSliceStatus);
  const driverSliceReasonCode = useAppSelector(selectDriversSliceReasonCode);
  const driversStatusSliceStatus = useAppSelector(
    selectDriverStatusSliceStatus
  );
  const driversStatusSliceReasonCode = useAppSelector(
    selectDriverStatusSliceReasonCode
  );
  const vehicleStatusSliceStatus = useAppSelector(
    selectVehiclesStatusSliceStatus
  );
  const vehicleStatusSliceReasonCode = useAppSelector(
    selectVehiclesStatusSliceReasonCode
  );

  useEffect(() => {
    if (deadlinesSliceStatus === "failed" && deadlinesSliceReasonCode === "") {
      ToastNotification({
        toastId: "networkError",
        status: "error",
        description: t("common.networkError"),
      });
    }
  }, [deadlinesSliceStatus, deadlinesSliceReasonCode]);

  useEffect(() => {
    if (
      driverTravelSummarySliceStatus === "failed" &&
      driverTravelSummarySliceReasonCode === ""
    ) {
      ToastNotification({
        toastId: "networkError",
        status: "error",
        description: t("common.networkError"),
      });
    }
  }, [driverTravelSummarySliceStatus, driverTravelSummarySliceReasonCode]);

  useEffect(() => {
    if (driverSliceStatus === "failed") {
      switch (driverSliceReasonCode) {
        case GTFleetErrorCodes.DRIVER_NOT_FOUND:
          ToastNotification({
            toastId: "driverNotFoundError",
            status: "error",
            description: t("common.driverNotFoundError"),
          });
          break;
        case GTFleetErrorCodes.USER_UNAUTHORIZED:
          ToastNotification({
            toastId: "unauthorizedError",
            status: "error",
            description: t("common.unauthorizedError"),
          });
          break;
        default:
          ToastNotification({
            toastId: "networkError",
            status: "error",
            description: t("common.networkError"),
          });
          break;
      }
      navigate("/dashboard/drivers");
    }
  }, [driverSliceStatus, driverSliceReasonCode, navigate]);

  useEffect(() => {
    if (driversStatusSliceStatus === "failed") {
      switch (driversStatusSliceReasonCode) {
        case GTFleetErrorCodes.DRIVER_STATUS_NOT_FOUND:
          ToastNotification({
            toastId: "driverStatusError",
            status: "error",
            description: t("common.driverStatusError"),
          });
          break;
        case GTFleetErrorCodes.USER_UNAUTHORIZED:
          ToastNotification({
            toastId: "unauthorizedError",
            status: "error",
            description: t("common.unauthorizedError"),
          });
          break;
        default:
          ToastNotification({
            toastId: "networkError",
            status: "error",
            description: t("common.networkError"),
          });
          break;
      }
    }
  }, [driversStatusSliceStatus, driversStatusSliceReasonCode]);

  useEffect(() => {
    if (
      vehicleStatusSliceStatus === "failed" &&
      vehicleStatusSliceReasonCode ===
        GTFleetErrorCodes.VEHICLE_STATUS_NOT_FOUND
    ) {
      console.error(t("common.vehicleStatusError"));
      ToastNotification({
        toastId: "vehicleStatusError",
        status: "error",
        description: t("common.vehicleStatusError"),
      });
    } else if (
      vehicleStatusSliceStatus === "failed" &&
      vehicleStatusSliceReasonCode === ""
    ) {
      ToastNotification({
        toastId: "networkError",
        status: "error",
        description: t("common.networkError"),
      });
    }
  }, [vehicleStatusSliceStatus, vehicleStatusSliceReasonCode]);
  //#endregion Notifications

  //#region event Toolbar
  const isLoading =
    vehicleStatusSliceStatus === "loading" ||
    driversStatusSliceStatus === "loading" ||
    driverSliceStatus === "loading" ||
    deadlinesSliceStatus === "loading" ||
    driverTravelSummarySliceStatus === "loading";

  const handleNext = () => {
    if (drivers[currentIndex + 1]) {
      navigate("/dashboard/drivers/details/" + drivers[currentIndex + 1].id);
    }
  };

  const handlePrev = () => {
    if (drivers[currentIndex - 1]) {
      navigate("/dashboard/drivers/details/" + drivers[currentIndex - 1].id);
    }
  };
  //#endregion event Toolbar

  //#region Deadlines-Maintenaces
  dayjs.extend(relativeTime);
  const [preferencesContext]: [Preferences] = useContext(UserContext);
  dayjs.locale(preferencesContext.language ?? "en");

  const [dataTextsDeadlines, setDataTextsDeadlines] = useState<
    DataTextDeadlines[]
  >([]);

  const deadlines: Deadline[] = useAppSelector(deadlinesSelectors.selectAll);

  const setStateDataTexts = (
    dataText: string,
    label: string,
    isExpired: boolean,
    id: number
  ) => {
    setDataTextsDeadlines((prev) => [
      ...prev,
      {
        dataText: dataText,
        label: label,
        isExpired: isExpired,
        id: id,
      },
    ]);
  };

  useEffect(() => {
    if (dataTextsDeadlines.length) {
      setDataTextsDeadlines([]);
    }
    if (deadlines && deadlines.length > 0) {
      deadlines.forEach((deadline: Deadline) => {
        if (!deadline.complete) {
          let diff = dayjs(deadline.expirationDate).diff(
            new Date(),
            "months",
            true
          );
          setStateDataTexts(
            dayjs().to(dayjs(deadline.expirationDate)),
            t("pages.dashboardDrivers.driverDetails." + deadline.type),
            diff < 0 ? true : false,
            deadline.id
          );
        }
      });
    }
  }, [dataTextsDeadlines.length, deadlines]);

  const returnSinglecontainer = useCallback(
    (arrayContainer: DataTextDeadlines[]) => {
      return arrayContainer.map(
        (dataText: DataTextDeadlines, index: number) => {
          return (
            <SingleItemContainer
              key={index}
              onClick={() => {
                navigate(`/dashboard/maintenance/view/${dataText.id}`);
              }}
              label={t(
                "pages.dashboardDrivers.driverDetails." +
                  (dataText.isExpired ? "expired" : "expiring")
              )}
            >
              <DataLabel
                className={dataText.isExpired ? "viewRed" : ""}
                dataText={dataText.dataText}
                icon={<IconWheelChange size={14} />}
                label={dataText.label}
              />
            </SingleItemContainer>
          );
        }
      );
    },
    [navigate]
  );
  //#endregion

  const reactDeployTarget =
    process.env.REACT_APP_DEPLOY_TARGET === "stage" ||
    process.env.REACT_APP_DEPLOY_TARGET === "local";

  //#region Render
  return (
    <>
      {!!driver && !_.isEmpty(driver) && (
        <Toolbar
          name={[
            driver.firstName && driver.lastName
              ? `${driver.firstName} ${driver.lastName}`
              : `${t("common.na")}`,
          ]}
          handlePrev={handlePrev}
          handleNext={handleNext}
          handleSettings={handleSettingsFunc}
          handleSendMsg={() => {}}
          current={currentIndex + 1}
          total={drivers.length}
          isActionPerforming={isLoading}
        />
      )}
      <DriverSummary
        details={getDetails(driver, fleetView, vehicle)}
        icon={
          driver.avatar ? (
            <ThumbProfile size="small" imgUrl={driver.avatar} />
          ) : undefined
        }
        status={driverStatus?.driverStatus}
        onToggle={(open) => {
          if (open) {
            document
              .querySelector(".page-content")
              ?.classList.replace("withDriverDetails", "withDriverDetailsOpen");
          } else {
            document
              .querySelector(".page-content")
              ?.classList.replace("withDriverDetailsOpen", "withDriverDetails");
          }
        }}
      />
      <PageContent className="withDriverDetails">
        <LocationMap position={vehiclePosition} />
        <div
          style={{
            height: "100%",
            display: "flex",
            justifyContent: "space-evenly",
            paddingTop: "8px",
            background: "#f7f7f8",
          }}
        >
          <div className="card-flex-driver">
            <Card
              title={t("pages.dashboardVehicles.vehicleDetails.lastTracks")}
              titleTooltip={t(
                "pages.dashboardVehicles.vehicleDetails.lastTracksTooltip"
              )}
              linkAnchor={t("common.viewLocationHistory")}
              linkAction={() => {
                if (driver && driver?.vehicle) {
                  window.open(
                    "/dashboard/vehicles/location-history/tracks" +
                      getQueryString({
                        firstPointDate: [
                          dayjs(
                            new Date().setDate(new Date().getDate() - 6)
                          ).format("YYYY/MM/DD HH:mm"),
                          dayjs(new Date()).format("YYYY/MM/DD HH:mm"),
                        ],
                        routeStateTypeEnum: "TRACK",
                        "vehicle.id": driver.vehicle,
                      }),
                    "_blank",
                    "noopener,noreferrer"
                  );
                }
              }}
            >
              {driverTravelSummarySliceStatus === "idle" &&
              driverTravelSummary &&
              driverTravelSummary.length > 0 ? (
                _.orderBy(
                  driverTravelSummary,
                  ["firstPosition.gpsPositionTimestamp"],
                  "desc"
                )
                  .slice(0, 5)
                  .map((route) => (
                    <>
                      <Tooltip />
                      <SingleItemContainer
                        element={route}
                        onClick={(item: DriverTravelSummary) => {
                          window.open(
                            `/dashboard/vehicles/location-history/track/${item.routeId}` +
                              getQueryString({
                                firstPointDate: [
                                  dayjs(
                                    new Date(
                                      item.firstPosition.gpsPositionTimestamp
                                    ).setDate(
                                      new Date(
                                        item.firstPosition.gpsPositionTimestamp
                                      ).getDate() - 1
                                    )
                                  ).format("YYYY/MM/DD HH:mm"),
                                  dayjs(
                                    new Date(
                                      item.lastPosition.gpsPositionTimestamp
                                    ).setDate(
                                      new Date(
                                        item.lastPosition.gpsPositionTimestamp
                                      ).getDate() + 1
                                    )
                                  ).format("YYYY/MM/DD HH:mm"),
                                ],
                                routeStateTypeEnum: "TRACK",
                                "vehicle.id": item.vehicleId ?? "",
                              }),
                            "_blank",
                            "noopener,noreferrer"
                          );
                        }}
                      >
                        <span data-for="tooltip" data-tip={t("common.start")}>
                          <DataLabel
                            dataText={dayjs(
                              route?.firstPosition.gpsPositionTimestamp
                            ).format("DD MMM HH:mm")}
                            icon={<IconCalendar size={14} />}
                          />
                        </span>
                        <span data-for="tooltip" data-tip={t("common.end")}>
                          <DataLabel
                            dataText={dayjs(
                              route?.lastPosition.gpsPositionTimestamp
                            ).format("DD MMM HH:mm")}
                            icon={<IconCalendar size={14} />}
                          />
                        </span>
                        <DataLabel
                          dataText={
                            preferencesContext.isMetric
                              ? numberAnnotation(mtToKm(route.traveled)) + " km"
                              : numberAnnotation(
                                  kmToMiles(mtToKm(route.traveled))
                                ) + " mil"
                          }
                          icon={<IconMenuRoutes size={14} />}
                        />
                        <div
                          style={{
                            width: "100px",
                          }}
                        >
                          <DataLabel
                            dataText={formatTime(
                              route?.drivingTime * 1000,
                              preferencesContext.language
                            )}
                            icon={<IconTimer size={14} />}
                          />
                        </div>
                        <DataLabel
                          dataText={numberAnnotation(
                            convertEuroOnCurrency(
                              preferencesContext.currency,
                              route?.costs
                            ),
                            2
                          )}
                          icon={
                            preferencesContext.currency === "EURO" ? (
                              <IconEuro size={14} />
                            ) : preferencesContext.currency === "DOLLAR" ? (
                              <IconDollar size={14} />
                            ) : (
                              <IconPound size={14} />
                            )
                          }
                        />
                      </SingleItemContainer>
                    </>
                  ))
              ) : (
                <div className="last-tracks-notFound">
                  {t("pages.dashboardVehicles.vehicleDetails.noTracks")}
                </div>
              )}
            </Card>
          </div>
          <div className="card-flex-driver">
            <HoursService slots={getSlots(driverStatus)} />
            {/* Deadlines */}
            {reactDeployTarget && (
              <Card
                title={t("pages.dashboardVehicles.vehicleDetails.deadlines")}
                linkAction={() => {
                  navigate({
                    pathname: `/dashboard/maintenance?status=SCHEDULED&status=IN_PROGRESS&status=EXPIRED&status=EXPIRING&vehicle.alias=${vehicle.alias}&vehicle.plate=${vehicle.plate}&type=INSURANCE&type=TAXES&type=REVISION&type=CUSTOM`,
                  });
                }}
                linkAnchor={
                  process.env.REACT_APP_DEPLOY_TARGET === "local" ||
                  process.env.REACT_APP_DEPLOY_TARGET === "stage"
                    ? t("common.viewAll")
                    : ""
                }
              >
                {dataTextsDeadlines.length
                  ? returnSinglecontainer(dataTextsDeadlines)
                  : null}
              </Card>
            )}
          </div>
        </div>
      </PageContent>
    </>
  );
  //#endregion Render
};
