import { t } from "i18next";
import { FeedbackModal } from "../../../../ui/Modal/CustomModals/FeedbackModal";
import { NotificationRuleAggregation } from "../notificationRuleAggregationSlice";

interface ActivateNotificationRuleAggregationModalProps {
  selectedNotificationRuleAggreagtion: NotificationRuleAggregation;
  open: boolean;
  onClose: (id?: number) => void;
}

const ActivateNotificationRuleAggregationModal: React.FC<ActivateNotificationRuleAggregationModalProps> =
  ({ selectedNotificationRuleAggreagtion, open, onClose }) => {
    const nameRuleAggregation = selectedNotificationRuleAggreagtion?.name
      ? selectedNotificationRuleAggreagtion?.name.split(",")[0]
      : t("common.na");

    function activateNotification() {
      onClose(selectedNotificationRuleAggreagtion?.id);
    }
    function close() {
      onClose();
    }

    return (
      <FeedbackModal
        open={open}
        title={`${t("admin.notifications.actions.activate.title")}`}
        desc={`${t("admin.notifications.actions.activate.desc").replace(
          "*name",
          nameRuleAggregation
        )}`}
        primaryLabel={`${t("common.confirm")}`}
        isDanger={false}
        primaryAction={activateNotification}
        secondaryLabel={`${t("common.cancel")}`}
        secondaryAction={close}
        closeAction={close}
      />
    );
  };

export default ActivateNotificationRuleAggregationModal;
