import propTypes from "prop-types";

export const MapMarkerGps = ({ isSelected, isRed, color, ...props }) => {
  let statusColor;
  if (color) {
    statusColor = color;
  } else if (isRed) {
    statusColor = color || "var(--global-colors-feedback-danger)";
  } else {
    if (isSelected) {
      statusColor = "var(--global-colors-ui-secondary)";
    } else {
      statusColor = "var(--global-colors-ui-primary)";
    }
  }
  const GpsStyle = {
    boxSizing: "border-box",
    display: "inline-block",
    height: "12px",
    width: "12px",
    border: "2px solid var(--global-colors-ui-white)",
    backgroundColor: statusColor,
    borderRadius: isRed || color ? "1px" : "150px",
    transform: "rotate(45deg)",
    boxShadow: "0 0 1px 0 rgba(0, 0, 0, 0.4)",
    marginTop: color ? "10px" : null,
  };
  return <span {...props} style={GpsStyle}></span>;
};

MapMarkerGps.propTypes = {
  isSelected: propTypes.bool,
  isRed: propTypes.bool,
};

MapMarkerGps.defaultProps = {
  isSelected: false,
  isRed: false,
};
