import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import _ from "lodash";
import { normalize } from "normalizr";
import { RootState } from "../../app/store";
import { Config } from "../../config/Config";
import { GTFleetSuccessCodes } from "../../config/GTFleetSuccessCodes";
import { reportsMetadataSchema } from "./reportsMetadataNormalization";
import ReportsMetadataRepository from "./reportsMetadataRepository";

//#region Type
export const reportType = {
  VEHICLE_TRAVEL_REPORT: "VEHICLE_TRAVEL_REPORT",
  VEHICLE_STOP_REPORT: "VEHICLE_STOP_REPORT",
  DRIVER_TRAVEL_REPORT: "DRIVER_TRAVEL_REPORT",
  GPSDATA_ROUTE_REPORT: "GPSDATA_ROUTE_REPORT",
  GPSDATA_STOP_REPORT: "GPSDATA_STOP_REPORT",
  GEOFENCE_ROUTE_REPORT: "GEOFENCE_ROUTE_REPORT",
  GEOFENCE_STOP_REPORT: "GEOFENCE_STOP_REPORT",
  GEOFENCE_ROUTE_AND_STOP_REPORT: "GEOFENCE_ROUTE_AND_STOP_REPORT",
  EVENTS_IO_REPORT: "EVENTS_IO_REPORT",
  UTILIZATION_REPORT: "UTILIZATION_REPORT",
  REFUELLING_REPORT: "REFUELLING_REPORT",
  LOGS_REPORT: "LOGS_REPORT",
  VEHICLE_ACTIVITY_REPORT: "VEHICLE_ACTIVITY_REPORT",
  DAILY_ACTIVITY_REPORT: "DAILY_ACTIVITY_REPORT",
  INFRINGEMENT_REPORT: "INFRINGEMENT_REPORT",
};
export type ReportType = keyof typeof reportType;
export interface ReportMetadata {
  scheduleOptions: any;
  id: number;
  title: string;
  description: string;
  group: string;
  reportTypeEnum: ReportType;
  scheduledReports: number[];
}

export interface ReportSchedule {
  id: number;
  name: string;
  startDate: Date;
  parameters: string;
}

//#endregion Type

//#region Custom Functions
function setFilteredData(state: any, action: PayloadAction<any>) {
  action.payload.reportMetadata
    ? reportsMetadataAdapter.setAll(state, action.payload.reportMetadata)
    : reportsMetadataAdapter.setAll(state, []);
}
//#endregiorn Custom Functions

//#region API
export const getReportsMetadataAsync = createAsyncThunk(
  "reportsMetadata/getReportsMetadata",
  async (queryParams?: string) => {
    const reportsMetadataRepository = new ReportsMetadataRepository();
    const response = await reportsMetadataRepository.getReportsMetadata(
      queryParams
    );
    // The value we return becomes the `fulfilled` action payload
    const reportsMetadata = _.get(response, Config.REPORTS_METADATA_PATH) ?? [];
    const normalizedData = normalize(reportsMetadata, reportsMetadataSchema);
    return normalizedData.entities;
  }
);
//#endregion API
const reportsMetadataAdapter = createEntityAdapter<ReportMetadata>({
  selectId: (report) => report.id,
  sortComparer: (a, b) => a.id - b.id,
});

export const reportMetadataSlice = createSlice({
  name: "reportsMetadata",
  initialState: reportsMetadataAdapter.getInitialState({
    status: "idle",
    reasonCode: "",
  }),
  reducers: {
    reportsMetadataEmptyState: (state) => {
      reportsMetadataAdapter.setAll(state, []);
      state.reasonCode = "";
      state.status = "idle";
    },
  },
  extraReducers: (builder) => {
    builder
      //#region Entity Reducers
      // get reportMetadata details async extra reducers
      .addCase(
        getReportsMetadataAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          setFilteredData(state, action);
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.GET;
        }
      )
      .addCase(getReportsMetadataAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(getReportsMetadataAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = "";
      });
  },
});
//#region Slice

export const reportsMetadataSelectors =
  reportsMetadataAdapter.getSelectors<RootState>(
    (state) => state.reportsMetadata
  );

export const selectReportsMetadataSliceStatus = (state: any) =>
  state.reportsMetadata.status;
export const selectReportsMetadataSliceReasonCode = (state: any) =>
  state.reportsMetadata.reasonCode;
export const { reportsMetadataEmptyState } = reportMetadataSlice.actions;
//#endregion Status

export default reportMetadataSlice.reducer;
