import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { IconAdd } from "../Icon/Line/Add";
import { IconMinus } from "../Icon/Line/Minus";

import "./VehicleBoxGroupButton.css";

export const VehicleBoxGroupButton = ({
  title,
  children,
  vehicleStatusAmount,
  open,
  hasCount,
  onChange,
}) => {
  const [isOpen, setIsOpen] = useState(open);

  useEffect(() => {
    return function cleanUp() {};
  }, []);

  useEffect(() => {
    setIsOpen(open);
    if (onChange) {
      onChange(open);
    }
  }, [open]);

  const toggleGroup = () => {
    setIsOpen((prevState) => !prevState);
    if (onChange) {
      onChange(!isOpen);
    }
  };

  let nElements = 0;
  children.forEach((item) => {
    if (item.props) nElements++;
  });

  const getStatusColor = (status) => {
    switch (status) {
      case "MOVING":
        return "var(--global-colors-feedback-success)";
      case "STOP":
        return "var(--global-colors-ui-secondary)";
      case "PARKING":
        return "var(--global-colors-feedback-danger)";
    }
  };

  return (
    <div className="mn-vehicle-box-group">
      <button className="mn-vehicle-box-group__button" onClick={toggleGroup}>
        <span className="mn-vehicle-box-group__label">
          {title} ({nElements})
        </span>
        <div className="mn-vehicle-box-group__status-square-container">
          {hasCount &&
            vehicleStatusAmount?.map((item) => {
              const { status, vehicles } = item;
              const statusColor = getStatusColor(status);
              return (
                <div
                  key={status}
                  className="mn-vehicle-box-group__status-square"
                  style={{ backgroundColor: statusColor }}
                >
                  {vehicles}
                </div>
              );
            })}
        </div>
        <span className="mn-vehicle-box-group__icon">
          {isOpen ? (
            <IconMinus size={12} color="--global-colors-ink-dark" />
          ) : (
            <IconAdd size={12} color="--global-colors-ink-dark" />
          )}
        </span>
      </button>
      {isOpen && <div className="mn-vehicle-box-group__list">{children}</div>}
    </div>
  );
};

VehicleBoxGroupButton.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  open: PropTypes.bool.isRequired,
};

VehicleBoxGroupButton.defaultProps = {
  title: "My Fleet",
  open: false,
};
