export const MapMarkerAlert = ({ width, color, style, onClick, ...props }) => {
  const alertStyle = {
    ...style,
    boxSizing: "border-box",
    display: "inline-block",
    height: `${width}px`,
    width: `${width}px`,
    border: "3px solid var(--global-colors-ui-white)",
    backgroundColor: `var(${color})`,
    transform: "rotate(45deg)",
    boxShadow:
      "0 2px 10px 0 rgba(0,0,0,0.08), 0 3px 1px -2px rgba(0,0,0,0.06), 0 1px 5px 0 rgba(0,0,0,0.1)",
  };
  return (
    <span {...props} style={alertStyle} onClick={onClick && onClick}></span>
  );
};

MapMarkerAlert.defaultProps = {
  width: 24,
  color: "--global-colors-ui-primary",
};
