import { t } from "i18next";
import { LogEvent } from "../../features/log/logSlice";
import { Preferences } from "../../features/users/preference/preferencesSlice";
import { IconAlert } from "../../ui/Icon/Line/Alert";
import { IconUtilization } from "../../ui/Icon/Line/Utilization";
import { IconSuccess } from "../../ui/Icon/Solid/Success";
import { Table } from "../../ui/Table/Table";
import {
  AbstractTableBuilder,
  Row,
} from "../../ui/Table/utils/AbstractTableBuilder";
import "../../ui/Table/utils/TableBuilder.css";
import { ConvertTimeZone } from "../../utils/DateAndTimeUtils";

export class LogsTableBuilder extends AbstractTableBuilder {
  rowsBuilder = (
    columns: { label: string; field: string; sort: boolean }[] | undefined,
    entities: {
      logEvent?: LogEvent;
    },
    preferencesContext: Preferences,
    writePermission: boolean = false
  ) => {
    let row: Row = { render: {} as Row };

    let logEvent: LogEvent = entities.logEvent ?? ({} as LogEvent);

    columns &&
      columns.forEach((column) => {
        switch (column.field) {
          case "areas":
            row.render.areas =
              logEvent.eventSourceEnum !== undefined
                ? logEvent.eventSourceEnum
                : t("common.na");
            break;
          case "dateTime":
            if (logEvent.timestamp !== undefined) {
              const logEventDateTime = ConvertTimeZone(
                logEvent.timestamp,
                preferencesContext.timeZone,
                preferencesContext.localeFormat
              ).split(" ");
              row.render.dateTime = (
                <>
                  <p className="reportMarked">{logEventDateTime[0]}</p>
                  <p className="reportNormal">{logEventDateTime[1]}</p>
                </>
              );
            } else {
              row.render.dateTime = (
                <>
                  <p className="reportMarked">--/--/--</p>
                  <p className="reportNormal">--:--:--</p>
                </>
              );
            }
            break;
          case "userEvent":
            row.render.userEvent =
              logEvent.userId !== undefined ? logEvent.userId : t("common.na");
            break;
          case "typeEvent":
            row.render.name =
              logEvent.name !== undefined
                ? t(`eventType.${logEvent.name}`)
                : t("common.na");
            break;
          case "action":
            row.render.action =
              logEvent.action !== undefined ? logEvent.action : t("common.na");
            break;
          case "statusEvent":
            const statusEventCase = () => {
              switch (logEvent.eventOutcomeEnum) {
                case "KO": {
                  return (
                    <IconAlert
                      size={16}
                      color="--global-colors-feedback-danger"
                    />
                  );
                }
                case "OK": {
                  return (
                    <IconSuccess
                      size={16}
                      color="--global-colors-feedback-success"
                    />
                  );
                }
                case "PENDING": {
                  return (
                    <IconUtilization
                      size={16}
                      color="--global-colors-ui-secondary"
                    />
                  );
                }
                default: {
                  return <div></div>;
                }
              }
            };
            row.render.statusEvent =
              logEvent.eventOutcomeEnum !== undefined &&
              logEvent.eventOutcomeEnum ? (
                <Table.TextIcon
                  text={t(
                    "logEvent.table.eventStatus." + logEvent.eventOutcomeEnum
                  )}
                  icon={statusEventCase()}
                />
              ) : (
                "-"
              );
            break;
          default:
            break;
        }
      });

    return row;
  };
}
