import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import _ from "lodash";
import { normalize } from "normalizr";
import { RootState } from "../../app/store";
import { Config } from "../../config/Config";
import { GTFleetSuccessCodes } from "../../config/GTFleetSuccessCodes";
import { eventsIOSchema } from "./eventsIONormalization";
import EventsIORepository from "./eventsIORepository";

//#region Type

export type EventIO = {
  id: number;
  name: string;
  description: string;
};

//#endregion Type

//#region API
// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const getEventsIOAsync = createAsyncThunk(
  "events/getEvents",
  async () => {
    const eventsIORepository = new EventsIORepository();
    const response = await eventsIORepository.getEvents();
    const eventList = _.get(response, Config.EVENTSIO_RESPONSE_PATH);
    const normalizedData = normalize(eventList, eventsIOSchema);
    return normalizedData.entities;
  }
);
//#endregion API

//#region Custom Functions
function setFilteredData(state: any, action: PayloadAction<any>) {
  action.payload.eventsIO
    ? eventsIOAdapter.setAll(state, action.payload.eventsIO)
    : eventsIOAdapter.setAll(state, []);
}
//#endregiorn Custom Functions

//#region Slice
const eventsIOAdapter = createEntityAdapter<EventIO>({
  selectId: (Event) => Event.id,
  sortComparer: (a, b) => a.id.toString().localeCompare(b.id.toString()),
});

export const eventsIOSlice = createSlice({
  name: "eventsIOSlice",
  initialState: eventsIOAdapter.getInitialState({
    status: "idle",
    reasonCode: "",
    totalPages: 0,
  }),
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    eventsEmptyState: (state: any) => {
      eventsIOAdapter.setAll(state, []);
      state.reasonCode = "";
      state.status = "idle";
    },
    setNumberOfPages: (state, action: PayloadAction<number>) => {
      state.totalPages = action.payload;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      //#region Entity Reducers
      // getRoutesHistoryAsync extra reducers
      .addCase(
        getEventsIOAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          setFilteredData(state, action);
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.GET;
        }
      )
      .addCase(getEventsIOAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(getEventsIOAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = "";
      });
    //#endregion Entity Reducers
  },
});
//#endregion Slice

//#region Status
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const eventsIOSelectors = eventsIOAdapter.getSelectors<RootState>(
  (state) => state.eventsIO
);

export const selecteventsSliceStatus = (state: any) => state.events.status;
export const selecteventsSliceReasonCode = (state: any) =>
  state.events.reasonCode;

export const { eventsEmptyState } = eventsIOSlice.actions;

//#endregion Status

export default eventsIOSlice.reducer;
