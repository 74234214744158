import PropTypes from "prop-types";
import React, { useContext, useState } from "react";
import { DataLabel } from "../DataLabel/DataLabel";
import Form from "../Forms/Form";
import SearchField from "../Forms/SearchField";
import { IconArrowDown } from "../Icon/Line/ArrowDown";
import { IconClock } from "../Icon/Line/Clock";
import { IconExchange } from "../Icon/Line/Exchange";
import { IconLayers } from "../Icon/Line/Layers";
import { LabelText } from "../LabelText/LabelText";
import "./Table.css";
import TableContext from "./TableContext";
import { useSortableData } from "./useSortableData";

export const Table = ({
  data,
  searchParams,
  isSearchParams,
  isShowTable,
  children,
}) => {
  const initialRecords = [...data.rows];
  const { records, requestSort, sortConfig, setRecords } = useSortableData(
    data.rows,
    {
      key: "id",
      direction: "ascending",
    }
  );

  const handleSearch = (keyword) => {
    if (keyword !== "" && searchParams.length > 0) {
      const filteredRecords = initialRecords.filter((record) => {
        let found = false;
        searchParams.forEach((param) => {
          if (record[param].includes(keyword)) {
            found = true;
          }
        });
        return found;
      });
      setRecords(filteredRecords);
    } else {
      setRecords(initialRecords);
    }
  };

  return (
    <TableContext.Provider
      value={{
        columns: data.columns,
        data: records,
        requestSort,
        sortConfig,
        onSearch: handleSearch,
        isSearchParams: isSearchParams,
        isShowTable: isShowTable,
      }}
    >
      {isSearchParams ? <Table.Search /> : ""}
      <table className="mn-table">{children}</table>
    </TableContext.Provider>
  );
};

const Search = () => {
  const ctx = useContext(TableContext);
  const [keyword, setKeyword] = useState("");

  const handleSearch = (e) => {
    ctx.onSearch(e);
  };

  return (
    <Form>
      <SearchField
        id="mn-table-search-field"
        value={keyword}
        onChange={(event) => {
          handleSearch(event.value);
          setKeyword(event.value);
        }}
      />
    </Form>
  );
};

const ShowTable = ({ title, number, show, colSpan, ...props }) => {
  const changeShow = () => {
    props.onclick(!show);
    if (props?.data && props?.getRow) {
      props.getShownRow(props.data);
    }
  };

  const renderSubTableHeaderRow = () => {
    if (title)
      return (
        <td colSpan={colSpan - 1} className="show">
          <div className="title-section-show-tab">
            <IconLayers size={14} />
            <span className="title-show-tab">
              {title} ({number})
            </span>
          </div>
        </td>
      );
    if (props.data)
      return Object.keys(props.data).map((element, index) => (
        <td key={index} className="show">
          <div className="title-section-show-tab">
            {renderSubTableHeaderRowData(element)}
          </div>
        </td>
      ));
  };

  const renderSubTableHeaderRowData = (element) => {
    switch (element) {
      case "vehicle":
        return (
          <LabelText
            label={props.data[element].alias}
            text={props.data[element].fleet}
          />
        );
      case "date":
        return <div className="date-row-data">{props.data[element]}</div>;
      case "stopTime":
      case "trackTime":
        return (
          <div className="stoptime-row-data">
            <IconClock size={14} color="--global-colors-ink-light" />
            {props.data[element]}
          </div>
        );
      default:
        return props.data[element];
    }
  };

  return (
    <>
      {renderSubTableHeaderRow()}
      <td className="show">
        <div className="switch-show-tab">
          <div
            className={
              !show
                ? "arrow-down-show-tab"
                : "arrow-down-show-tab arrow-up-show-tab"
            }
          >
            <IconArrowDown
              size={14}
              color="currentColor"
              onClick={changeShow}
            />
          </div>
        </div>
      </td>
    </>
  );
};

const Head = ({ hasTableSpace, id, ...props }) => {
  const ctx = useContext(TableContext);

  const getClassNamesFor = (name) => {
    if (!ctx.sortConfig) {
      return;
    }
    return ctx.sortConfig.key === name ? ctx.sortConfig.direction : undefined;
  };

  const renderTableHeader = (columns) => {
    return columns
      ? columns.map((column, index) => {
          const { label, field, sort } = column;

          return (
            <th
              key={index}
              id={id}
              onClick={sort ? () => ctx.requestSort(field) : undefined}
              className={getClassNamesFor(field)}
            >
              <span>
                {label}
                <span>
                  {getClassNamesFor(field) && sort !== undefined ? (
                    <IconArrowDown size={12} color="inherit" />
                  ) : (
                    sort && (
                      <IconExchange
                        size={12}
                        style={{ transform: "rotate(90deg)" }}
                        color="inherit"
                      />
                    )
                  )}
                </span>
              </span>
            </th>
          );
        })
      : null;
  };
  return (
    <thead {...props}>
      <tr>
        {renderTableHeader(ctx.columns)}
        {hasTableSpace && (
          <th id="hasTableSpace">
            <span>
              <span></span>
            </span>
          </th>
        )}
      </tr>
    </thead>
  );
};

const Body = ({ id, ...props }) => {
  const ctx = useContext(TableContext);

  const renderTableContent = (rows, isShowTable) => {
    return rows.map((row, index) => {
      const keys = Object.keys(row.render);

      return (
        <tr
          onClick={row.onClick}
          key={index}
          id={id}
          className={isShowTable ? `show-child ${row.id}` : ""}
        >
          {keys.map((key, idx) => {
            return <td key={idx}>{row.render[key]}</td>;
          })}
        </tr>
      );
    });
  };

  const getNumFields = (rows) => {
    const array = [];
    rows.forEach((row) => {
      array.push(row.render.length);
    });
    return Math.max.apply(null, array);
  };

  const [show, setShow] = useState(-1);
  const renderTableContentShow = (rows, props) => {
    return rows.map((row, index) => {
      return (
        <React.Fragment key={row.id}>
          <tr key={row.id}>
            <Table.Show
              title={row.key}
              show={show === index}
              onclick={() => {
                setShow(show === index ? -1 : index);
                if (row?.data && props && props?.getShownRow) {
                  props.getShownRow(row.data);
                }
              }}
              number={row.rowsGrouped.length}
              colSpan={getNumFields(row.rowsGrouped)}
              data={row.data}
            />
          </tr>
          {show === index && renderTableContent(row.rowsGrouped, true)}
        </React.Fragment>
      );
    });
  };

  return (
    <tbody>
      {ctx.isShowTable
        ? renderTableContentShow(ctx.data, props)
        : renderTableContent(ctx.data)}
    </tbody>
  );
};

const TextStatus = ({ mode, text, ...props }) => {
  return <span {...props}>{text}</span>;
};

TextStatus.propTypes = {
  mode: PropTypes.oneOf(["normal", "danger"]),
  text: PropTypes.string.isRequired,
};

TextStatus.defaultProps = {
  mode: "normal",
};

const TextIcon = ({ text, icon, label, mode, ...props }) => {
  return <DataLabel dataText={text} icon={icon} label={label} {...props} />;
};

TextIcon.propTypes = {
  text: PropTypes.string.isRequired,
  label: PropTypes.string,
  mode: PropTypes.oneOf(["normal", "danger"]),
};

TextIcon.defaultProps = {
  mode: "normal",
};

Table.Head = Head;
Table.Body = Body;
Table.Search = Search;
Table.Show = ShowTable;
Table.TextStatus = TextStatus;
Table.TextIcon = TextIcon;
