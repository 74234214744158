import i18next from "i18next";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Button } from "../Button/Button";
import { IconArrowLeft } from "../Icon/Line/ArrowLeft";
import { IconArrowRight } from "../Icon/Line/ArrowRight";
import "./PageCounter.css";

export const PageCounter = ({
  numOfPages,
  numSelected,
  onClick,
  progressiveArrowMode,
  disabled,
  resetPage,
  isActionPerforming,
  totalElements,
}) => {
  const createArray = (numPag) => {
    return Array(numPag)
      .join()
      .split(",")
      .map((_value, index) => index + 1)
      .filter((id) => (progressiveArrowMode ? id <= 1 : id <= 4));
  };

  const [arrayNumPag, setArrayNumPag] = useState(createArray(numOfPages));
  const [pagCurrent, setPagCurrent] = useState(1);

  useEffect(() => {
    if (numOfPages !== null && numOfPages !== undefined) {
      setArrayNumPag(createArray(numOfPages));
      setPagCurrent(1);
    }
  }, [numOfPages]);

  useEffect(() => {
    if (resetPage) {
      setPagCurrent(1);
      progressiveArrowMode && setArrayNumPag([1]);
    }
  }, [resetPage]);

  const nextPage = () => {
    if (!arrayNumPag.includes(numOfPages)) {
      setArrayNumPag((arrNumPag) => arrNumPag.map((value) => value + 1));
    }
  };

  const prevPage = () => {
    if (!arrayNumPag.includes(1)) {
      setArrayNumPag((arrNumPag) => arrNumPag.map((value) => value - 1));
    }
  };

  const changePag = ({ id }) => {
    setPagCurrent(id);
    onClick(id, pagCurrent);
  };

  return (
    <>
      <div className="page-count">
        {totalElements > 0 && (
          <div className="number-selected">
            {totalElements + " " + i18next.t("table.pageCounter.totalElements")}
          </div>
        )}
        {numSelected > 0 && (
          <div className="number-selected">
            {numSelected + " " + i18next.t("table.pageCounter.selected")}
          </div>
        )}
        <div className="page-list">
          <span id="button_prev_page-count">
            <Button
              aspect="outline"
              size="small"
              onlyIcon={true}
              disabled={arrayNumPag.includes(1) || disabled}
              onClick={() => {
                prevPage();
                progressiveArrowMode && changePag({ id: arrayNumPag[0] - 1 });
              }}
            >
              <IconArrowLeft size={11} color="--global-colors-ink-ink" />
            </Button>
          </span>

          {arrayNumPag
            .filter((id) => id <= numOfPages)
            .map((id) => (
              <span
                key={id}
                id="button_num_page-count"
                className="button-number"
              >
                <Button
                  aspect="ghost"
                  size="small"
                  onClick={() => {
                    !progressiveArrowMode && changePag({ id });
                  }}
                  active={id === pagCurrent}
                >
                  <span className="number">{id}</span>
                </Button>
              </span>
            ))}
          <span id="button_next_page-count">
            <Button
              aspect="outline"
              size="small"
              disabled={arrayNumPag.includes(numOfPages) || disabled}
              onlyIcon={true}
              onClick={() => {
                nextPage();
                progressiveArrowMode && changePag({ id: arrayNumPag[0] + 1 });
              }}
            >
              <IconArrowRight size={11} color="--global-colors-ink-ink" />
            </Button>
          </span>
        </div>
        <div className="page-running">
          {i18next.t("table.pageCounter.runningPage") +
            " " +
            pagCurrent +
            "/" +
            numOfPages}
        </div>
        {isActionPerforming && <div className="page-counter-loader" />}
      </div>
    </>
  );
};

PageCounter.propTypes = {
  numOfPages: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
};
