import { useContext, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../app/hooks";
import { store } from "../../../app/store";
import { GTFleetSuccessCodes } from "../../../config/GTFleetSuccessCodes";
import UserContext from "../../../features/users/userContext";
import { ModalBody } from "../../../ui/Modal/ModalBody";
import { ModalFooter } from "../../../ui/Modal/ModalFooter";
import { ModalWrapper } from "../../../ui/Modal/ModalWrapper";
import { Table } from "../../../ui/Table/Table";
import { ToastNotification } from "../../../utils/ToastNotification";
import {
  geofenceCategoriesSelectors,
  GeofenceCategory,
  getGeofenceCategoriesAsync,
  selectGeofenceCategoriesSliceReasonCode,
  selectGeofenceCategoriesSliceStatus,
} from "../../geofenceCategory/geofenceCategoriesSlice";
import { Preferences } from "../../users/preference/preferencesSlice";
import "./EditCategoriesModal.css";
import { EditCategoriesModalTableBuilder } from "./EditCategoriesTableBuilder";

const tableSchemaFix = [
  { name: "category", sort: true },
  { name: "color" },
  { name: "actions" },
];

interface EditCategoriesModalProps {
  open: boolean;
  onClose: (e: any) => any;
}

interface TableData {
  columns: { label: string; field: string; sort: boolean }[] | undefined;
  rows: { [key: string]: any };
}

export const EditCategoriesModal: React.FC<EditCategoriesModalProps> = ({
  open,
  onClose,
}) => {
  //#region const
  const { t } = useTranslation();
  const [preferencesContext]: [Preferences] = useContext(UserContext);
  const navigate = useNavigate();
  const tableBuilderRef = useRef<EditCategoriesModalTableBuilder>(
    new EditCategoriesModalTableBuilder()
  );

  const geofenceCategoriesSliceStatus = useAppSelector(
    selectGeofenceCategoriesSliceStatus
  );
  const geofenceCategoriesSliceReasonCode = useAppSelector(
    selectGeofenceCategoriesSliceReasonCode
  );

  let geofenceCategoriesEdit: GeofenceCategory[] =
    useAppSelector(geofenceCategoriesSelectors.selectAll) ??
    ({} as GeofenceCategory);
  //#endregion

  // dispatch for see categories
  useEffect(() => {
    store.dispatch(getGeofenceCategoriesAsync());
  }, []);

  // toast notification
  useEffect(() => {
    if (
      geofenceCategoriesSliceStatus === "idle" &&
      geofenceCategoriesSliceReasonCode === GTFleetSuccessCodes.PATCH
    ) {
      ToastNotification({
        toastId: "editCategorySuccess",
        status: "success",
        description: t("common.editCategorySuccess"),
      });
    } else if (geofenceCategoriesSliceStatus === "failed") {
      if (geofenceCategoriesSliceReasonCode === "") {
        ToastNotification({
          toastId: "networkError",
          status: "error",
          description: t("common.networkError"),
        });
      }
    }
  }, [geofenceCategoriesSliceStatus, geofenceCategoriesSliceReasonCode]);

  //#region tableBuilder
  let tableBuilder = tableBuilderRef.current;

  let tableData: TableData = {} as TableData;

  tableData.columns = tableBuilder.setColumns(
    tableSchemaFix,
    preferencesContext
  );

  if (
    tableData.columns &&
    tableData?.columns?.length > 0 &&
    geofenceCategoriesEdit.length
  ) {
    tableData.rows = geofenceCategoriesEdit.map(
      (geofenceCategory: GeofenceCategory) => {
        return tableBuilder.rowsBuilder(
          tableData.columns,
          {
            geofenceCategory: geofenceCategory,
          },
          navigate
        );
      }
    );
  } else if (!geofenceCategoriesEdit.length) {
    tableData.rows = [];
  }
  //#endregion

  return (
    <div className="edit-categories">
      <ModalWrapper open={open} closeAction={onClose}>
        <div className="edit-categories-body">
          <ModalBody
            title={t("customModals.editCategories.title")}
            desc={t("customModals.editCategories.desc")}
          >
            <div className="edit-categories-table">
              <Table
                data={tableData}
                isSearchParams={false}
                isShowTable={false}
              >
                <Table.Head />
                <Table.Body />
              </Table>
            </div>
          </ModalBody>
          <ModalFooter
            secondaryLabel={t("customModals.editCategories.secondaryButton")}
            secondaryAction={onClose}
          />
        </div>
      </ModalWrapper>
    </div>
  );
};
