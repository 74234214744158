import { t } from "i18next";
import _ from "lodash";
import { useEffect, useRef, useState } from "react";
import {
  Location,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { store } from "../../app/store";
import { Button } from "../../ui/Button/Button";
import { Dropdown } from "../../ui/Dropdown/Dropdown";
import Form from "../../ui/Forms/Form";
import SearchField from "../../ui/Forms/SearchField";
import { IconAdd } from "../../ui/Icon/Line/Add";
import { getQueryString } from "../../utils/Utils";
import { EntityPermission } from "../users/privilege/privilegesSlice";
import "./AdminDriverFilterBar.css";
import { DriverStatusType, driverStatusValues } from "./driversSlice";
import {
  getDriversStatusesAsync,
  getFilteredDriversStatusAndDetailsPaginationAsync,
} from "./driversStatusSlice";

interface DropDownItem {
  label: string;
}

interface StatusType {
  key: "ACTIVE" | "INACTIVE";
  label: string;
}

interface QueryParams {
  [paramName: string]: any;
}

interface AdminDriverFilterBarProps {
  entityPermissions: EntityPermission;
  callback: (buildQueryParam: string) => any;
}

export const AdminDriverFilterBar: React.FC<AdminDriverFilterBarProps> = ({
  entityPermissions,
  callback,
}) => {
  const navigate = useNavigate();
  const [idDriverVehicle, setIdDriverVehicle] = useState("");
  const queryParamsRef = useRef<QueryParams>({});
  let queryParams: QueryParams = queryParamsRef.current;
  const [selectedStatuses, setSelectedStatuses] = useState([] as StatusType[]);

  const [searchParams] = useSearchParams();
  const location: Location = useLocation();

  // Chiamata API
  useEffect(() => {
    store.dispatch(getDriversStatusesAsync());
  }, []);

  useEffect(() => {
    const map = new Map();
    const currentSearchParams =
      searchParams.toString() !== "" ? searchParams : null;
    if (!!currentSearchParams && _.isEmpty(queryParams)) {
      const size = currentSearchParams.get("size");
      map.set("size", size ?? "10");
      // Search Field
      const driversFirstNameSearch = currentSearchParams.get("firstName");
      const driversLastNameSearch = currentSearchParams.get("lastName");
      if (driversFirstNameSearch) {
        setIdDriverVehicle(driversFirstNameSearch);
        map.set("firstName", driversFirstNameSearch);
      } else if (driversLastNameSearch) {
        setIdDriverVehicle(driversLastNameSearch);
        map.set("lastName", driversLastNameSearch);
      }
      const driverStatus = currentSearchParams.getAll("status");
      if (driverStatus && driverStatus.length > 0) {
        setSelectedStatuses(
          driverStatus.map((x) => {
            return { key: x, label: _.capitalize(x) } as StatusType;
          })
        );
        map.set("status", driverStatus);
      }
    }

    if (map.size === 0 && _.isEmpty(queryParams)) {
      map.set("size", "10");
      const vehicleStatus = ["ACTIVE"];
      if (vehicleStatus && vehicleStatus.length > 0) {
        setSelectedStatuses(
          vehicleStatus.map((x) => {
            return { key: x, label: _.capitalize(x) } as StatusType;
          })
        );
        map.set("status", vehicleStatus);
      }
    }
    if (map.size > 0) {
      handleChanges(map);
    }
  }, [location]);

  const handleChanges = (params: Map<string, string[] | string>): void => {
    let queryString = "";
    let executeQuery = false;
    if (!!params) {
      params.forEach((value, key) => {
        if (!!value && value.length > 0) {
          queryParams[key] = value;
        } else {
          if (queryParams.hasOwnProperty(key)) {
            delete queryParams[key];
            executeQuery = true;
          }
        }
      });
      queryString = getQueryString(queryParams);
      executeQuery = true;
    }
    // effettuare chiamata API con parametri in query string
    if (executeQuery) {
      store.dispatch(
        getFilteredDriversStatusAndDetailsPaginationAsync({
          queryParams: queryString,
          isActive: false,
        })
      );
    }
    navigate({
      pathname: "/admin/drivers",
      search: queryString,
    });
    callback(queryString);
  };

  const statusType: { type: DriverStatusType; label: string }[] =
    _.keys(driverStatusValues).map((typeKey) => {
      return {
        type: _.get(driverStatusValues, typeKey),
        label:
          typeKey.charAt(0).toUpperCase() +
          typeKey.slice(1).toLocaleLowerCase(),
      };
    }) ?? [];

  const debouncedSearch = useRef(
    _.debounce(async (val) => {
      setIdDriverVehicle(val);
      const map = new Map();
      const splittedVal = val.split(" ");
      map.set("firstName", splittedVal);
      map.set("lastName", splittedVal);
      handleChanges(map);
    }, 1000)
  ).current;

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  async function handleSearch(val: string) {
    await debouncedSearch(val);
  }

  return (
    <div className="admin-filterbar-row-splitter">
      <>
        <div className="admin-filterbar-search">
          <Form>
            <SearchField
              name="search"
              id="driverName"
              size="small"
              placeholder={t("admin.adminFilterBar.searchName")}
              value={idDriverVehicle}
              onChange={(val) => {
                handleSearch(val);
              }}
            />
          </Form>
        </div>
        <div className="vehicle-dropdown-statuses">
          <Dropdown
            placeholderInValue={t(
              "fleetControl.filterBar.placeholderInValStatus"
            )}
            itemAttribute="label"
            hasCheckbox={true}
            placeholder={t("fleetControl.filterBar.status")}
            size={"small"}
            onChange={(val: DropDownItem[]) => {
              const map = new Map();
              map.set(
                "status",
                val.map((x) => x.label.toUpperCase())
              );
              handleChanges(map);
            }}
            value={selectedStatuses}
            options={statusType}
          />
        </div>
        {entityPermissions.write && (
          <div className="admin-filterbar-btn">
            <Button
              aspect="primary"
              size="small"
              label={t("admin.adminFilterBar.addDriver")}
              onlyIcon={false}
              onClick={() => navigate("/admin/drivers/new")}
            >
              <IconAdd
                className=""
                size={14}
                color="--global-colors-ui-white"
              />
            </Button>
          </div>
        )}
      </>
    </div>
  );
};
