import { t } from "i18next";
import _ from "lodash";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useAppSelector } from "../../../../app/hooks";
import { store } from "../../../../app/store";
import PageContent from "../../../../layout/PageContent";
import Form from "../../../../ui/Forms/Form";
import SearchField from "../../../../ui/Forms/SearchField";
import { Section } from "../../../../ui/Modal/CustomModals/AddressBookModal";
import { Table } from "../../../../ui/Table/Table";
import { getQueryString } from "../../../../utils/Utils";
import {
  AddressBook,
  addressBookSelectors,
  getFilteredAddressBookAsync,
  selectaddressBookSliceStatus,
} from "../../../address/addressBookSlice";
import { Preferences } from "../../../users/preference/preferencesSlice";
import UserContext from "../../../users/userContext";
import "./AddressBookModal.css";
import { ContactListModalTableBuilder } from "./ContactListModalTableBuilder";

interface AddressBookModalProps {
  open: boolean;
  sections: Section[];
  currentActive: string;
  onClose: (e: any) => any;
}

interface TableData {
  columns: { label: string; field: string; sort: boolean }[] | undefined;
  rows: { [key: string]: any };
}

interface QueryParams {
  [paramName: string]: any;
}

const context = "addressBook";
const tableSchemaFix = [
  { id: 1, name: "firstName" },
  { id: 2, name: "email" },
  { id: 3, name: "telephone" },
];

export const ContactsListModal: React.FC<AddressBookModalProps> = ({
  currentActive,
}) => {
  const [sectionsSelected, setSectionsSelected] = useState(currentActive);

  let addressBooks: AddressBook[] = useAppSelector((state) =>
    addressBookSelectors.selectAll(state)
  );
  const queryParamsRef = useRef<QueryParams>({});
  const [searchParams] = useSearchParams();
  let queryParams: QueryParams = queryParamsRef.current;
  const navigate = useNavigate();
  const location = useLocation();

  const [openedFirstTime, setOpenedFirstTime] = useState(true);
  const [addressbookStringSearch, setAddressbookStringSearch] =
    useState<string>("");
  const addressBookSliceStatus = useAppSelector(selectaddressBookSliceStatus);

  const tableBuilderRef = useRef<ContactListModalTableBuilder>(
    new ContactListModalTableBuilder()
  );
  let tableBuilder = tableBuilderRef.current;

  let tableData: TableData = {} as TableData;

  useEffect(() => {
    const map = new Map();
    const currentSearchParams =
      searchParams.toString() !== "" ? searchParams : null;
    if (!!currentSearchParams && _.isEmpty(queryParams)) {
      const size = currentSearchParams.get("size");
      map.set("size", size ?? "10");

      //SearchBar
      const firstnameSearch = currentSearchParams.get("firstname");
      const lastNameSearch = currentSearchParams.get("lastName");
      if (firstnameSearch) {
        map.set("firstname", firstnameSearch);
      }
      if (lastNameSearch) {
        map.set("lastName", lastNameSearch);
      }

      let searchString = "";
      if (firstnameSearch || lastNameSearch) {
        searchString = firstnameSearch
          ? firstnameSearch
          : lastNameSearch
          ? lastNameSearch
          : searchString;
        setAddressbookStringSearch(searchString);
      }
    }
    if (map.size === 0 && _.isEmpty(queryParams)) {
      map.set("size", "10");
    }
    if (map.size > 0) {
      handleChanges(map);
    }
  }, [location]);

  const handleChanges = (params: Map<string, string[] | string>): void => {
    let queryString = "";
    let executeQuery = false;
    if (!!params) {
      params.forEach((value, key) => {
        if (!!value && value.length > 0) {
          queryParams[key] = value;
          executeQuery = true;
        } else {
          if (queryParams.hasOwnProperty(key)) {
            delete queryParams[key];
            executeQuery = true;
          }
        }
      });
      queryString = getQueryString(queryParams);
    }
    // effettuare chiamata API con parametri in query string
    if (executeQuery) {
      store.dispatch(
        getFilteredAddressBookAsync({
          queryParams: queryString,
        })
      );
      if (!openedFirstTime) {
        navigate({
          pathname: "/admin/notifications/contacts-list",
          search: queryString,
        });
      }
      setOpenedFirstTime(false);
    }
  };

  useEffect(() => {
    setSectionsSelected(currentActive);
  }, [currentActive]);

  const [preferencesContext]: [Preferences] = useContext(UserContext);

  tableData.columns = tableBuilder.setColumns(
    tableSchemaFix,
    preferencesContext
  );

  if (
    addressBookSliceStatus === "idle" &&
    tableData?.columns &&
    tableData?.columns?.length > 0
  ) {
    tableData.rows = addressBooks.map((addressBook: AddressBook) => {
      return tableBuilder.rowsBuilder(
        tableData.columns,
        context,
        { addressBook },
        navigate,
        false
      );
    });
  } else if (!addressBooks.length) {
    tableData.rows = [];
  }

  return (
    <>
      <div className="addressBook-page-content">
        <div className="addressBook-search-field">
          <Form>
            <SearchField
              name="search"
              id="contacts-list"
              size="small"
              placeholder={t("admin.notifications.modal.findContact")}
              value={addressbookStringSearch}
              onChange={(val: string) => {
                const searchName = new Map();
                searchName.set("firstName", val);
                searchName.set("lastName", val);
                handleChanges(searchName);
                setAddressbookStringSearch(val);
              }}
            />
          </Form>
        </div>
        {tableData.rows?.length > 0 && (
          <PageContent>
            <div className="contact-list-table">
              <Table data={tableData}>
                <Table.Head hasTableSpace={true} />
                <Table.Body />
              </Table>
            </div>
          </PageContent>
        )}
      </div>
    </>
  );
};

export default ContactsListModal;
