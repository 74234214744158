import PropTypes from "prop-types";
import ReactApexChart from "react-apexcharts";
import "./Pie.css";

export const Pie = ({ labels, series, size, ...props }) => {
  const PieOption = {
    colors: [
      "var(--global-colors-status-online)",
      "var(--global-colors-status-maintenance)",
      "var(--global-colors-status-offline)",
      "var(--bs-gray-500)",
      "var(--global-colors-status-stopped)",
      "var(--bs-info)",
    ],
    labels: labels,
    width: size.width,
    height: size.height,
    stroke: {
      width: 0,
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      pie: {
        donut: {
          size: "45%",
        },
        expandOnClick: false,
      },
    },
    legend: {
      show: false,
    },
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        const viewMeasure =
          series[seriesIndex] + " " + w.config.labels[seriesIndex];

        return (
          '<div style="background: #02285a;height: 24px;width: auto; border-radius: 4px; opacity: 0.8; color: #ffffff; font-size: 12px; text-align: center; line-height: 2; padding-left: 5px; padding-right: 5px;">' +
          "<span>" +
          viewMeasure +
          "</span>" +
          "</div>"
        );
      },
      x: {
        show: false,
        format: "dd MMM yy, HH:mm",
      },
    },
    states: {
      hover: {
        filter: {
          type: "darken",
          value: 0.6,
        },
      },
      active: {
        allowMultipleDataPointsSelection: true,
        filter: {
          type: "none",
        },
      },
    },
  };
  return (
    <div className="mn-pie">
      <ReactApexChart
        options={PieOption}
        series={series}
        type="donut"
        width={size.width}
        height={size.height}
        {...props}
      />
    </div>
  );
};

Pie.propTypes = {
  /**
   * Series of labels to render.
   *
   */
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,
  /**
   * Series of data to render.
   *
   */
  series: PropTypes.arrayOf(PropTypes.number).isRequired,
  /**
   * Size of the chart.
   */
  size: PropTypes.shape({
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
};

Pie.defaultProps = {
  labels: ["Online", "Maintenance", "Offline", "Stopped"],
  series: [62, 6, 2, 30],
  size: { width: "170", height: "170" },
};
