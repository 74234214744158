import { t } from "i18next";
import { formatTime } from "../../utils/DateAndTimeUtils";
import {
  kmPerHToMilesPerH,
  kmToMiles,
  ltToGal,
  mtToKm,
  numberAnnotation,
} from "../../utils/Utils";
import { Card } from "../Card/Card";
import { FuelLevelMini } from "../Chart/FuelLevelMini";
import { DataLabel } from "../DataLabel/DataLabel";
import { IconAverageSpeed } from "../Icon/Line/AverageSpeed";
import { IconEuro } from "../Icon/Line/Euro";
import { IconGasStation } from "../Icon/Line/GasStation";
import { IconMenuRoutes } from "../Icon/Line/MenuRoutes";
import { IconSpeed } from "../Icon/Line/Speed";
import { IconTimer } from "../Icon/Line/Timer";
import "./TrackDetails.css";

export const TrackDetails = ({ open, details, preference }) => {
  return (
    <Card
      className="mn-lh-track-details"
      title={t("locationHistory.trackDetails.title")}
      hasCollapse={true}
    >
      <div className="mn-lh-track-details__details">
        {details?.totalKm !== undefined && (
          <div className="total-km">
            <DataLabel
              dataText={
                preference.isMetric
                  ? numberAnnotation(mtToKm(details.totalKm)) + " km"
                  : numberAnnotation(kmToMiles(mtToKm(details.totalKm))) +
                    " mil"
              }
              icon={<IconMenuRoutes size={14} />}
              label={t("locationHistory.trackDetails.totalKm")}
            />
          </div>
        )}
        {false && details?.cost !== undefined && (
          <div className="cost">
            <DataLabel
              dataText={numberAnnotation(details.cost) + " €"}
              icon={<IconEuro size={14} />}
              label={t("locationHistory.trackDetails.cost")}
            />
          </div>
        )}
        {details?.trackTime !== undefined && (
          <div className="track-time">
            <DataLabel
              dataText={formatTime(
                details.trackTime * 1000,
                preference.language
              )}
              icon={<IconTimer size={14} />}
              label={t("locationHistory.trackDetails.trackTime")}
            />
          </div>
        )}
        {details?.avgSpeed !== undefined && (
          <div className="avg-speed">
            <DataLabel
              dataText={
                preference.isMetric
                  ? numberAnnotation(details.avgSpeed) + " km/h"
                  : numberAnnotation(kmPerHToMilesPerH(details.avgSpeed)) +
                    " mph"
              }
              icon={<IconAverageSpeed size={13} />}
              label={t("locationHistory.trackDetails.avgSpeed")}
            />
          </div>
        )}
        {details?.maxSpeed !== undefined && (
          <div className="max-speed">
            <DataLabel
              dataText={
                preference.isMetric
                  ? numberAnnotation(details.maxSpeed) + " km/h"
                  : numberAnnotation(kmPerHToMilesPerH(details.maxSpeed)) +
                    " mph"
              }
              icon={<IconSpeed size={13} />}
              label={t("locationHistory.trackDetails.maxSpeed")}
            />
          </div>
        )}
        {details?.fuelCons !== undefined && (
          <div className="fuel-consumption">
            <DataLabel
              dataText={
                preference.isMetric
                  ? numberAnnotation(details.fuelCons) + " lt"
                  : numberAnnotation(ltToGal(details.fuelCons)) + " gal"
              }
              icon={<IconGasStation size={14} />}
              label={t("locationHistory.trackDetails.fuelCons")}
              graph={
                details.fuelDatas &&
                details.fuelDates && (
                  <FuelLevelMini
                    title=""
                    size={{ width: "105px", height: "20px" }}
                    series={details?.fuelDatas}
                    dates={details?.fuelDates}
                  />
                )
              }
            />
          </div>
        )}
        {!!open && <span>{t("common.notAvailable")}</span>}
      </div>
    </Card>
  );
};
