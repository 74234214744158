import React from "react";
import PropTypes from "prop-types";

export const Reports = ({ icon, title, children, ...args }) => {


  return (
    <div className="mn-reports" {...args}>
      {title && (
        <div>
          {icon && <span className="mn-reports__title-icon">{icon}</span>}
          {title}
        </div>
      )}
      <div className="mn-reports__reports-list">{children}</div>
    </div>
  );
};

Reports.propTypes = {
  title: PropTypes.string,
};
