import dayjs from "dayjs";
import HttpClient from "../../utils/HttpClient";

export default class RouteRepository extends HttpClient {
  constructor() {
    super(true, "/api/v2");
  }

  getRoutes(vehicleId?: number, queryParams?: string) {
    const queryParamsObj = new URLSearchParams(queryParams);
    const dateRange = queryParamsObj.getAll("firstPointDate");
    const routeTypes = queryParamsObj.getAll("routeStateTypeEnum");
    if (dateRange[0]) {
      queryParamsObj.set(
        "startPeriod",
        dayjs(new Date(dateRange[0])).utc().format("YYYY-MM-DDTHH:mm")
      );
    }
    if (dateRange[1]) {
      queryParamsObj.set(
        "endPeriod",
        dayjs(new Date(dateRange[1])).utc().format("YYYY-MM-DDTHH:mm")
      );
    }
    if (routeTypes.length === 1) {
      queryParamsObj.set("routeType", routeTypes[0]);
    }
    const tenantId = localStorage.getItem("tenantId");
    return this.instance.get(
      "tenants/" +
        tenantId +
        "/vehicles/" +
        vehicleId +
        "/travels" +
        (queryParamsObj.toString() ? "?" + queryParamsObj.toString() : "")
    );
  }

  getSingleRoute(vehicleId?: number, routeId?: number, queryParams?: string) {
    const queryParamsObj = new URLSearchParams(queryParams);
    const dateRange = queryParamsObj.getAll("firstPointDate");
    if (dateRange[0]) {
      queryParamsObj.set(
        "startPeriod",
        dayjs(new Date(dateRange[0])).format("YYYY-MM-DDTHH:mm")
      );
    }
    if (dateRange[1]) {
      queryParamsObj.set(
        "endPeriod",
        dayjs(new Date(dateRange[1])).format("YYYY-MM-DDTHH:mm")
      );
    }
    const tenantId = localStorage.getItem("tenantId");
    return this.instance.get(
      "tenants/" +
        tenantId +
        "/vehicles/" +
        vehicleId +
        "/travels" +
        routeId +
        (queryParamsObj.toString() ? "?" + queryParamsObj.toString() : "")
    );
  }
}
