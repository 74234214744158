import React, { useState } from "react";
import PropTypes from "prop-types";
import TextField from "../../Forms/TextField";
import Form from "../../Forms/Form";
import { LabelText } from "../../LabelText/LabelText";
import { Checkbox } from "../../Forms/Checkbox";
import { ModalBody } from "../ModalBody";
import { ModalWrapper } from "../ModalWrapper";
import { ModalFooter } from "../ModalFooter";
import { FeedbackModal } from "./FeedbackModal";
import ReCAPTCHA from "react-google-recaptcha";
import "./InstallerRequestModal.css";
import { useTranslation } from "react-i18next";

export const InstallerRequestModal = ({ open, onClose }) => {
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [city, setCity] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [company, setCompany] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [eMail, setEMail] = useState("");
  const [vehiclesNumbers, setVehiclesNumbers] = useState("");
  const [notes, setNotes] = useState("");
  const [isRecaptched, setIsRecaptched] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorList, setErrorList] = useState([]);
  const { t } = useTranslation();

  const clearFields = () => {
    setFirstName("");
    setLastName("");
    setCompany("");
    setPhoneNumber("");
    setEMail("");
    setVehiclesNumbers("");
    setNotes("");
    setIsChecked(false);
  };

  return (
    <div className="installer-container">
      <ModalWrapper open={open} closeAction={onClose}>
        <div className="installer">
          <ModalBody
            title={t("customModals.installerRequest.title")}
            desc={t("customModals.installerRequest.desc")}
          >
            <div className="left-forms">
              <LabelText text={t("customModals.installerRequest.name")} />
              <Form>
                <TextField
                  id="First-name"
                  placeholder={t(
                    "customModals.installerRequest.namePlaceholder"
                  )}
                  validate="alphabetic"
                  errorList={setErrorList}
                  events={{ onChange: (data) => setFirstName(data.value) }}
                  value={firstName}
                ></TextField>
              </Form>
            </div>
            <div className="right-forms">
              <LabelText text={t("customModals.installerRequest.surname")} />
              <Form>
                <TextField
                  id="Last-name"
                  placeholder={t(
                    "customModals.installerRequest.surnamePlaceholder"
                  )}
                  validate="alphabetic"
                  errorList={setErrorList}
                  events={{ onChange: (data) => setLastName(data.value) }}
                  value={lastName}
                ></TextField>
              </Form>
            </div>
            <div className="left-forms">
              <LabelText text={t("customModals.installerRequest.city")} />
              <Form>
                <TextField
                  id="City"
                  placeholder={t(
                    "customModals.installerRequest.cityPlaceholder"
                  )}
                  validate="alphabetic"
                  errorList={setErrorList}
                  events={{ onChange: (data) => setCity(data.value) }}
                  value={city}
                ></TextField>
              </Form>
            </div>
            <div className="right-forms">
              <LabelText text={t("customModals.installerRequest.postalCode")} />
              <Form>
                <TextField
                  id="Postal-Code"
                  placeholder={t(
                    "customModals.installerRequest.postalCodePlaceholder"
                  )}
                  validate="numeric|minLength.5|maxLength.5"
                  errorList={setErrorList}
                  events={{ onChange: (data) => setPostalCode(data.value) }}
                  value={postalCode}
                ></TextField>
              </Form>
            </div>

            <div className="left-forms">
              <LabelText text={t("customModals.installerRequest.company")} />
              <Form>
                <TextField
                  id="Company"
                  placeholder={t(
                    "customModals.installerRequest.companyPlaceholder"
                  )}
                  validate="alphaNumeric"
                  errorList={setErrorList}
                  events={{ onChange: (data) => setCompany(data.value) }}
                  value={company}
                ></TextField>
              </Form>
            </div>
            <div className="right-forms">
              <LabelText text={t("customModals.installerRequest.phone")} />
              <Form>
                <TextField
                  id="Phone-number"
                  placeholder={t(
                    "customModals.installerRequest.phonePlaceholder"
                  )}
                  validate="phoneNumber"
                  errorList={setErrorList}
                  events={{ onChange: (data) => setPhoneNumber(data.value) }}
                  value={phoneNumber}
                ></TextField>
              </Form>
            </div>
            <div className="left-forms">
              <LabelText text={t("customModals.installerRequest.email")} />
              <Form>
                <TextField
                  id="E-mail"
                  placeholder={t(
                    "customModals.installerRequest.emailPlaceholder"
                  )}
                  validate="email"
                  errorList={setErrorList}
                  events={{ onChange: (data) => setEMail(data.value) }}
                  value={eMail}
                ></TextField>
              </Form>
            </div>
            <div className="right-forms">
              <LabelText
                text={t("customModals.installerRequest.vehiclesNumbers")}
              />
              <Form>
                <TextField
                  id="Vehicles-numbers"
                  placeholder={t(
                    "customModals.installerRequest.vehiclesNumbersPlaceholder"
                  )}
                  validate="numeric"
                  errorList={setErrorList}
                  events={{
                    onChange: (data) => setVehiclesNumbers(data.value),
                  }}
                  value={vehiclesNumbers}
                ></TextField>
              </Form>
            </div>
            <div className="central-forms">
              <LabelText text={t("customModals.installerRequest.notes")} />
              <Form>
                <TextField
                  id="Notes"
                  type="textarea"
                  placeholder={t(
                    "customModals.installerRequest.notesPlaceholder"
                  )}
                  events={{
                    onChange: (data) => setNotes(data.value),
                  }}
                  value={notes}
                ></TextField>
              </Form>
            </div>
            <div className="selection-control">
              <Checkbox
                label={t("customModals.installerRequest.checkbox")}
                checked={isChecked}
                onChange={(e) => setIsChecked(e.target.checked)}
              />
            </div>
            <div className="company-rectangle">
              <div className="company-text">
                <p>{t("customModals.installerRequest.companyParagraphOne")}</p>

                {t("customModals.installerRequest.companyParagraphTwo")}
              </div>
              <div className="captcha">
                <ReCAPTCHA
                  sitekey="6LdyHDEfAAAAAKQpc0DdEoXwhVg3D-hyTZ110_MU"
                  onChange={() => setIsRecaptched(true)}
                  hl={t("common.reCaptcha")}
                />
              </div>
            </div>
          </ModalBody>
          <ModalFooter
            primaryLabel={t("customModals.installerRequest.primaryButton")}
            primaryAction={() => {
              setIsLoading(true);
              clearFields();
              onClose();
              setShowFeedbackModal(true);
            }}
            secondaryLabel={t("customModals.installerRequest.secondaryButton")}
            secondaryAction={onClose}
            disablePrimaryButton={
              firstName === "" ||
              lastName === "" ||
              phoneNumber === "" ||
              eMail === "" ||
              vehiclesNumbers === "" ||
              !isChecked ||
              !isRecaptched ||
              errorList.length !== 0
            }
            loadingPrimaryButton={isLoading}
          />
        </div>
      </ModalWrapper>
      <div>
        <FeedbackModal
          open={showFeedbackModal}
          closeAction={() => setShowFeedbackModal(false)}
          title={t("customModals.feedback.title")}
          desc={t("customModals.feedback.desc")}
          primaryLabel={t("customModals.feedback.primaryButton")}
          primaryAction={() => setShowFeedbackModal(false)}
        />
      </div>
    </div>
  );
};

InstallerRequestModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

InstallerRequestModal.defaultProps = {
  open: false,
};
