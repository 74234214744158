import { useEffect, useRef, useState } from "react";
import { IconArrowDown } from "../Icon/Line/ArrowDown";
import { Radio } from "./Radio";
import "./RadioDropdown.css";

export const RadioDropdown = ({ radioList, widthDropdown, onChange }) => {
  const wrapperRef = useRef(null);
  const parentRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  radioList.forEach((x, index) => (x.index = index));

  let initialState = radioList.find((x) => !x.disabled)
    ? radioList.find((x) => !x.disabled).index
    : -1;
  const [radioChecked, setRadioChecked] = useState(initialState);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target) &&
        !parentRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  return (
    <>
      <div
        className="radioDropdown-field"
        onClick={() => setIsOpen((prevState) => !prevState)}
        ref={parentRef}
        style={{
          width: `${widthDropdown}px`,
          marginLeft: widthDropdown > 160 ? "0px" : "45px",
        }}
      >
        <span className="radioDropdown-span">
          {initialState === -1 ? (
            <input
              style={{ width: `${widthDropdown - 44}px` }}
              readOnly
              type="text"
              placeholder={null}
              value={null}
            ></input>
          ) : (
            <input
              style={{ width: `${widthDropdown - 44}px` }}
              readOnly
              type="text"
              placeholder={radioChecked ? null : radioChecked}
              value={
                radioChecked === radioChecked && radioList[radioChecked].value
              }
            ></input>
          )}
        </span>
        <div className={`radioDropdown-icon ${isOpen ? "open" : null}`}>
          <IconArrowDown size={12} color="--global-colors-ink-light" />
        </div>
      </div>
      {isOpen && (
        <div
          className="radioDropdown-container"
          style={{
            width: widthDropdown > 160 ? `${widthDropdown}px` : "160px",
          }}
          ref={wrapperRef}
        >
          <div className="radioDropdown-list">
            <ul className="radioDropdown-radio">
              {radioList.map((radioLabel, index) => {
                return (
                  <li className="radioDropdown-li" key={index}>
                    <Radio
                      id={index}
                      name="periods"
                      label={radioLabel.value}
                      disabled={radioLabel.disabled}
                      checked={radioChecked === index}
                      onChange={(e) => {
                        setRadioChecked(index);
                        onChange(radioList[index].value);
                        setIsOpen(false);
                      }}
                    />
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      )}
    </>
  );
};
