import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import TextField from "./TextField";
import { IconCalendar } from "../Icon/Line/Calendar";
import { DatePicker } from "./DatePicker";
import { DatePickerProvider } from "./DatePickerContext";
import {
  convertDateToIsoString,
  convertToCurrentLocalesFormat,
} from "./utils/dateConversion";
import "./SingleDatePicker.css";
import dayjs from "dayjs";

export const SingleDatePicker = ({
  id,
  validation,
  oldDate,
  size,
  placeholder,
  setErrorList,
  errorList,
  setDate,
  localeFormat,
  language,
  dateRange,
  notExpirationDate,
}) => {
  const { t } = useTranslation();
  const [value, setValue] = React.useState("");
  const [isOpen, setIsOpen] = React.useState(false);
  const [initialDate] = React.useState(oldDate);
  const inputRef = React.useRef(null);
  const pickerRef = React.useRef(null);
  const [valueValidationRule, setValueValidationRule] = React.useState(
    validation?.replace("isNotEmpty", "") ?? ""
  );

  React.useEffect(() => {
    if (
      isInputValid() &&
      value !==
      convertToCurrentLocalesFormat(getOldDate(), true, localeFormat) &&
      value !== ""
    ) {
      setDate(convertDateToIsoString(value, false, localeFormat))
    }
  }, [value]);

  React.useEffect(() => {
    if (oldDate && oldDate !== "" && oldDate !== "null")
      setValue(convertToCurrentLocalesFormat(getOldDate(), true, localeFormat));
  }, [localeFormat, oldDate]);

  React.useEffect(() => {
    function handleClickOutside(event) {
      if (
        inputRef.current &&
        !inputRef.current.contains(event.target) &&
        pickerRef.current &&
        !pickerRef.current.contains(event.target)
      )
        setIsOpen(false);
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [inputRef, pickerRef]);

  function isInputValid() {
    return !errorList.includes(id ?? t("common.genericField")) || (dayjs(convertDateToIsoString(value, false, localeFormat)).isValid() &&
      value.length === 14);
  }

  function autoFormatDate(data) {
    function checkValue(str, max) {
      if (str.charAt(0) !== "0" || str === "00") {
        var num = parseInt(str);
        if (isNaN(num) || num <= 0 || num > max) num = 1;
        str =
          num > parseInt(max.toString().charAt(0)) &&
            num.toString().length === 1
            ? "0" + num
            : num.toString();
      }
      return str;
    }

    if (data !== "") {
      var input = data;
      if (/\D\/$/.test(input)) input = input.substr(0, input.length - 3);

      var values = input.split("/").map(function (v) {
        return v.replace(/\D/g, "");
      });

      if (localeFormat.split("/")[0].includes("MM")) {
        if (values[0]) values[0] = checkValue(values[0], 12);
        if (values[1]) values[1] = checkValue(values[1], 31);
      } else {
        if (values[0]) values[0] = checkValue(values[0], 31);
        if (values[1]) values[1] = checkValue(values[1], 12);
      }

      var output = values.map(function (v, i) {
        return v.length === 2 && i < 2 ? v + " / " : v;
      });

      var loadedOutput = output.join("").substr(0, 14);
      setValue(loadedOutput);
      isOutsideTheRange(loadedOutput);
    } else setValue(data);
  }

  function onSubmit(selectedDate) {
    setIsOpen(false);
    setValue(selectedDate);
    setDate(convertDateToIsoString(selectedDate, false, localeFormat));
  }

  function getStartDate() {
    if (value === "") return null;
    if (isInputValid()) {
      let convertedValue = convertDateToIsoString(value, false, localeFormat);
      if (convertedValue === "null") return null;
      else return convertedValue;
    } else return null;
  }

  function getOldDate() {
    if (oldDate && oldDate !== "null") return oldDate;
    else return null;
  }

  function isOutsideTheRange(data) {
    let val = "";
    if (validation) val = "|" + validation;
    if (data.length < 14) {
      setValueValidationRule("date" + val);
      return;
    }
    let validateValue = "date" + val;
    let isOutside = false,
      isBefore = false,
      isAfter = false;
    let valueDay = dayjs(convertDateToIsoString(data, false, localeFormat));
    if (dateRange && valueDay.isValid()) {
      isBefore = !valueDay.isAfter(dateRange[0]);
      isAfter = valueDay.isAfter(dateRange[1]);
      isOutside = isBefore || isAfter;
    }
    if (isOutside) validateValue = "wrongDate";
    setValueValidationRule(validateValue);
  }

  const getCointainerDate = () => {
    if (validation?.includes("isNotEmpty")) {
      return (
        <DatePickerProvider
          language={language || "it"}
          startDate={getStartDate() || getOldDate()}
          dateRange={dateRange}
        >
          {notExpirationDate && notExpirationDate.length ? (
            <DatePicker
              isSingleDate={true}
              handleSubmit={onSubmit}
              setInputValue={(data) => {
                setValue(data);
                isOutsideTheRange(data);
              }}
              oldDate={initialDate}
              localeFormat={localeFormat}
              resetCallback={() => {
                setIsOpen(false);
                notExpirationDate();
              }}
              notExpirationDateLabel={true}
            />
          ) : (
            <DatePicker
              isSingleDate={true}
              handleSubmit={onSubmit}
              setInputValue={(data) => {
                setValue(data);
                isOutsideTheRange(data);
              }}
              oldDate={initialDate}
              localeFormat={localeFormat}
            />
          )}
        </DatePickerProvider>
      );
    } else {
      return (
        <DatePickerProvider
          language={language || "it"}
          startDate={getStartDate() || getOldDate()}
          dateRange={dateRange}
        >
          {notExpirationDate && notExpirationDate.length ? (
            <DatePicker
              isSingleDate={true}
              handleSubmit={onSubmit}
              setInputValue={(data) => {
                setValue(data);
                isOutsideTheRange(data);
              }}
              localeFormat={localeFormat}
              resetCallback={() => {
                setValue("");
                setIsOpen(false);
                notExpirationDate();
              }}
              notExpirationDateLabel={true}
            />
          ) : (
            <DatePicker
              isSingleDate={true}
              handleSubmit={onSubmit}
              setInputValue={(data) => {
                setValue(data);
                isOutsideTheRange(data);
              }}
              resetCallback={() => setValue("")}
              localeFormat={localeFormat}
            />
          )}
        </DatePickerProvider>
      );
    }
  };

  return (
    <div className="single-datepicker">
      <div className="datepicker-container" ref={inputRef}>
        <TextField
          size={size}
          id={id || t("common.genericField")}
          placeholder={
            placeholder + (notExpirationDate ? "" : ` (${localeFormat})`)
          }
          errorList={setErrorList}
          validate={valueValidationRule || "date"}
          events={{ onChange: (data) => autoFormatDate(data.value) }}
          value={value}
        >
          <div
            className={size === "small" ? "datepicker-icon-small" : ""}
            onClick={() => {
              setIsOpen(true);
            }}
          >
            <IconCalendar color="--global-colors-status-stopped" size={15} />
          </div>
        </TextField>
      </div>
      {isOpen && (
        <div className="datepicker-menu" ref={pickerRef}>
          {getCointainerDate()}
        </div>
      )}
    </div>
  );
};

SingleDatePicker.propTypes = {
  validation: PropTypes.string,
  oldDate: PropTypes.string,
  size: PropTypes.oneOf(["small", "normal"]),
  placeholder: PropTypes.string,
  language: PropTypes.string,
  localeFormat: PropTypes.string,
  errorList: PropTypes.array,
  setErrorList: PropTypes.func,
  setDate: PropTypes.func,
  dateRange: PropTypes.array,
  notExpartionDate: PropTypes.func,
};
