import {
  PayloadAction,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { getUserInfoAsync } from "../users/usersInfoSlice";

//#region Type

export type Partner = {
  id: number;
  name: string;
  partnerLogoPositive: string;
  partnerLogoNegative: string;
};
//#endregion Type

//#region API

//#endregion API

//#region Slice
const partnersAdapter = createEntityAdapter<Partner>({
  selectId: (partner) => partner.id,
  sortComparer: (a, b) => a.name.localeCompare(b.name),
});

export const partnerSlice = createSlice({
  name: "partners",
  initialState: partnersAdapter.getInitialState({
    status: "idle",
    reasonCode: "",
  }),
  reducers: {
    partnersEmptyState: (state) => {
      partnersAdapter.setAll(state, []);
      state.reasonCode = "";
      state.status = "idle";
    },
  },
  extraReducers: (builder) => {
    builder
      //#region Entity Reducers
      .addCase(
        getUserInfoAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          if (action.payload.partner) {
            partnersAdapter.upsertMany(state, action.payload.partner);
          }
        }
      );

    //#endregion Entity Reducers

    //#region External Entity Reducers
    //#endregion External Entity Reducers
  },
});
//#endregion Slice

//#region Status
export const partnersSelectors = partnersAdapter.getSelectors<RootState>(
  (state) => state.partners
);
export const selectTenantsSliceReasonCode = (state: any) =>
  state.partners.reasonCode;
export const { partnersEmptyState } = partnerSlice.actions;
//#endregion Status

export default partnerSlice.reducer;
