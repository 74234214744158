import { Cluster, Marker, MarkerClusterer } from "@googlemaps/markerclusterer";
import _ from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { ClassPolyline } from "../../ui/LocationHistory/Polyline";
import { BaseMap, ComponentOnMap } from "../../ui/Map/BaseMap";
import { MapType } from "../../ui/Map/MapType";
import { MarkerAsComponent } from "../../ui/Map/MarkerAsComponent";
import { PublicTransport } from "../../ui/Map/PublicTransport";
import { Zoom } from "../../ui/Map/Zoom";
import { ClusterMarker } from "../../ui/Marker/ClusterMarker";
import { MapMarkerGps } from "../../ui/Marker/MapMarkerGps";
import { DriverView } from "../driver/driversSlice";
import { RouteEvent, RouteStateType } from "../route/routesHistorySlice";
import { Preferences } from "../users/preference/preferencesSlice";
import UserContext from "../users/userContext";
import { PublicRoutePoint, PublicStopView } from "./route/publicRoutesSlice";

//#region types&interfaces

interface CheckedProps {
  id: number;
  name: string;
  color: string;
  number: number;
  vehicleId: number;
  queryParams?: string;
}

interface PublicTransportMapProps {
  id: number;
  googleMapsApiKey: string;
  zoom: number;
  latitude: number;
  longitude: number;
  checkedList: CheckedProps[];
  points: PublicRoutePoint[];
  stops: PublicStopView[];
  limit: number;
  highlightedStop: number;
}

interface PointPublicTransportInfo {
  id: number;
  address: string;
}

interface MarkerData {
  id: string;
  latitude: number;
  longitude: number;
  address: string;
  eventInfo: EventInfo;
  routeInfo: RouteInfo;
  pointPublicTransportInfo: PointPublicTransportInfo;
  showTooltip: boolean | undefined;
  component: any;
  eventType: string;
  isFirst?: boolean;
}

interface RouteInfo {
  id: number;
  start: string;
  end: string;
  duration: string;
  address: string;
}

interface EventInfo {
  id: number;
  name: string;
  speed?: number;
  timestamp?: string;
  routeId?: number;
  queryParams?: string;
}

type PointState = {
  id: number;
  routeStateType: RouteStateType;
  driver: DriverView;
  dynamicFields: {
    latitude: number;
    longitude: number;
    lastUpdate: Date;
    address: string;
    direction: number;
    speed: number;
    fuelLevel: number;
    fuelLevelLiters: number;
    odometer: number;
  };
  events: RouteEvent[];
  covered: number;
  routeId: number;
};

declare global {
  interface HTMLElement {
    msRequestFullScreen?: () => Promise<void>;
    mozRequestFullScreen?: () => Promise<void>;
    webkitRequestFullScreen?: () => Promise<void>;
  }
}

//#endregion types&interfaces

export const PublicTransportMap: React.FC<PublicTransportMapProps> = ({
  id,
  googleMapsApiKey,
  zoom,
  latitude,
  longitude,
  checkedList,
  points,
  stops,
  highlightedStop,
}) => {
  const [map, setMap] = useState<any>();
  const [multiPolyline, setMultiPolyline] = useState<Map<number, any>>(
    new Map()
  );
  const [preferencesContext]: [Preferences] = useContext(UserContext);
  const [bounds, setBounds] = useState<google.maps.LatLngBounds>();
  const [markerHighlightClusterList, setMarkerHighlightClusterList] = useState<
    any[]
  >([]);
  const [startMarkerHighlightList, setStartMarkerHighlightList] = useState<
    any[]
  >([]);
  const [userSelectedMapType, setUserSelectedMapType] = useState<string>("");
  const [routeViewType, setRouteViewType] = useState<string>("tracksAndStops");
  const [markersList, setMarkersList] = useState<MarkerClusterer>();
  const [startEndMarkersList, setStartEndMarkersList] =
    useState<MarkerClusterer>();
  const [, setStreetViewEnabled] = useState(false);
  const [isMapIdle, setIsMapIdle] = useState(false);
  const [componentsOnMap, setComponentsOnMap] = useState<ComponentOnMap[]>([]);
  const [hoveredChild, setHoveredChild] = useState<Element | null>();
  const [eventHover, setEventHover] = useState<MouseEvent>();

  let startEndCluster: any[] = [];
  let googleMap = window.google;
  let infoWindow: google.maps.InfoWindow;
  let routesBounds: google.maps.LatLngBounds;
  let markerClusterList: any[] = [];
  let componentsToAdd: ComponentOnMap[] = [];

  if (isMapIdle && googleMap) {
    infoWindow = new googleMap.maps.InfoWindow({
      content: "",
      pixelOffset: new googleMap.maps.Size(0, -40),
    });

    routesBounds = new googleMap.maps.LatLngBounds();
  }

  //#region functions

  const mapClick = () => {
    setUserSelectedMapType("roadmap");
    map?.setMapTypeId("roadmap");
  };

  const satelliteClick = () => {
    setUserSelectedMapType("hybrid");
    map?.setMapTypeId("hybrid");
  };

  const plusClick = () => {
    if (map) {
      const zoomTemp = map && map.getZoom();
      if (zoomTemp) {
        map && map.setZoom(zoomTemp + 1);
      }
    }
  };

  const minusClick = () => {
    if (map) {
      const zoomTemp = map && map.getZoom();
      if (zoomTemp) {
        map && map.setZoom(zoomTemp - 1);
      }
    }
  };

  const createMarker = (properties: MarkerData, index: number): Marker => {
    return MarkerAsComponent({
      id: properties.isFirst
        ? "marker-public-stop-start-end-id-" + index
        : "marker-public-stop-id-" + index,
      googleMap: googleMap,
      lat: properties.latitude,
      lng: properties.longitude,
      show: true,
      map: map,
      infoWindow: infoWindow,
      component: properties.component,
      showTooltip: true,
      pointPublicTransportInfo: properties.pointPublicTransportInfo,
      clusterPublicTransportInfo: 1,
      preferences: preferencesContext,
    }) as google.maps.Marker;
  };

  const addMarkerForStop = (
    point: PointState,
    checkedItem: CheckedProps,
    isFirst: boolean,
    label: string = `A|B`,
    index: number
  ) => {
    const markerData = {} as MarkerData;
    markerData.pointPublicTransportInfo = {} as PointPublicTransportInfo;
    markerData.pointPublicTransportInfo.id = point.id;
    markerData.pointPublicTransportInfo.address = point.dynamicFields.address;
    markerData.eventType = "STOP";
    markerData.id = point.id.toString();
    markerData.address = point.dynamicFields.address;
    markerData.showTooltip = true;
    markerData.component = isFirst ? (
      <ClusterMarker hasChart={false} color={checkedItem.color} text={label} />
    ) : (
      <MapMarkerGps color={checkedItem.color} />
    );
    markerData.latitude = point?.dynamicFields?.latitude;
    markerData.longitude = point?.dynamicFields?.longitude;
    markerData.showTooltip = true;
    markerData.isFirst = isFirst;
    routesBounds.extend(
      new window.google.maps.LatLng(
        point.dynamicFields?.latitude,
        point.dynamicFields?.longitude
      )
    );

    isFirst
      ? startEndCluster.push(createMarker(markerData, index))
      : markerClusterList.push(createMarker(markerData, index));
  };

  const simulateMarkerMouseOver = () => {
    const associatedElement = document.querySelectorAll(
      `#marker-public-stop-id-${highlightedStop}`
    );
    const child = associatedElement[associatedElement.length - 1];
    if (child && highlightedStop) {
      let mouseOverEvent = new MouseEvent("mouseover", {
        view: window,
      });
      setEventHover(mouseOverEvent);
      setHoveredChild(child);
      child.dispatchEvent(mouseOverEvent);
    }
  };

  const cleanMap = () => {
    if (markersList) {
      markerClusterList = [];
      markersList.onRemove();
      setMarkersList(undefined);
    }
    if (startEndMarkersList) {
      startEndCluster = [];
      startEndMarkersList.onRemove();
      setStartEndMarkersList(undefined);
    }
    multiPolyline.forEach((_value, key) => {
      const polyline = multiPolyline.get(key);
      polyline.onRemove();
      multiPolyline.delete(key);
    });
    setMultiPolyline(multiPolyline);
  };

  const buildMarkers = (trackBounds: google.maps.LatLngBounds) => {
    checkedList.forEach((checkedItem) => {
      const routePoints: PointState[] = points.map((point) => {
        const pointState: PointState = {
          id: point.id,
          dynamicFields: {
            latitude: point.latitude,
            longitude: point.longitude,
            address: "",
            direction: 0,
            fuelLevel: 0,
            fuelLevelLiters: 0,
            lastUpdate: new Date(),
            odometer: 0,
            speed: 0,
          },
          covered: 0,
          driver: {
            id: 0,
            firstName: "",
            lastName: "",
          },
          events: [
            {
              type: {
                id: 0,
                category: {
                  id: 0,
                  name: "",
                },
                description: "",
                name: "STOP",
                severity: "INFO",
                hardwareSource: false,
              },
              color: checkedItem.color,
              value: {
                speedLimit: 0,
              },
              name: "STOP",
            },
          ],
          routeStateType: "TRACK",
          routeId: checkedItem.id,
        };
        return pointState;
      });
      const onlyOneTerminus =
        stops.filter((stop) => stop.terminus).length === 1;
      stops.forEach((stop, index) => {
        const isTerminus = stop.terminus;

        const stopState: PointState = {
          id: stop.id,
          dynamicFields: {
            latitude: stop.latitude,
            longitude: stop.longitude,
            address: stop.name,
            direction: 0,
            fuelLevel: 0,
            fuelLevelLiters: 0,
            lastUpdate: new Date(),
            odometer: 0,
            speed: 0,
          },
          covered: 0,
          driver: {
            id: 0,
            firstName: "",
            lastName: "",
          },
          events: [
            {
              type: {
                id: 0,
                category: {
                  id: 0,
                  name: "",
                },
                description: "",
                name: "STOP",
                severity: "INFO",
                hardwareSource: false,
              },
              color: "",
              value: {
                speedLimit: 0,
              },
              name: "STOP",
            },
          ],
          routeStateType: "TRACK",
          routeId: checkedItem.id,
        };

        addMarkerForStop(
          stopState,
          checkedItem,
          isTerminus,
          onlyOneTerminus ? `A|B` : index === 0 ? "A" : "B",
          index
        );
      });
      if (onlyOneTerminus) {
        routePoints.push(routePoints[0]);
      }

      const rendererMarkerPoint = {
        render(__namedParameters: Cluster) {
          return MarkerAsComponent({
            id: "marker-public-stop-id",
            googleMap: googleMap,
            lat: __namedParameters.position.lat(),
            lng: __namedParameters.position.lng(),
            map: map,
            infoWindow: infoWindow,
            show: true,
            clusterPublicTransportInfo: __namedParameters.count,
            showTooltip: true,
            preferences: preferencesContext,
            component: <MapMarkerGps color={checkedItem.color}></MapMarkerGps>,
          });
        },
      };

      const startEndRenderer = {
        render(__namedParameters: Cluster) {
          return MarkerAsComponent({
            id: "marker-public-stop-start-end",
            googleMap: googleMap,
            lat: __namedParameters.position.lat(),
            lng: __namedParameters.position.lng(),
            map: map,
            infoWindow: infoWindow,
            show: true,
            showTooltip: false,
            preferences: preferencesContext,
            component: (
              <ClusterMarker
                hasChart={false}
                color={checkedItem.color}
                text={"A/B"}
              />
            ),
          });
        },
      };

      setStartEndMarkersList(
        new MarkerClusterer({
          map: map,
          markers: startEndCluster as google.maps.Marker[],
          renderer: startEndRenderer,
        })
      );

      setMarkersList(
        new MarkerClusterer({
          map: map,
          markers: markerClusterList as google.maps.Marker[],
          renderer: rendererMarkerPoint,
        })
      );

      routePoints.forEach((routeState) => {
        trackBounds.extend(
          new window.google.maps.LatLng(
            routeState?.dynamicFields.latitude,
            routeState?.dynamicFields.longitude
          )
        );
      });

      stops.forEach((stop) => {
        trackBounds.extend(
          new window.google.maps.LatLng(stop.latitude, stop.longitude)
        );
      });
    });
    return trackBounds;
  };

  const buildPolyline = (trackBounds: google.maps.LatLngBounds) => {
    checkedList.forEach((checkedItem) => {
      const routePoints: PointState[] = points.map((point) => {
        const pointState: PointState = {
          id: point.id,
          dynamicFields: {
            latitude: point.latitude,
            longitude: point.longitude,
            address: "",
            direction: 0,
            fuelLevel: 0,
            fuelLevelLiters: 0,
            lastUpdate: new Date(),
            odometer: 0,
            speed: 0,
          },
          covered: 0,
          driver: {
            id: 0,
            firstName: "",
            lastName: "",
          },
          events: [
            {
              type: {
                id: 0,
                category: {
                  id: 0,
                  name: "",
                },
                description: "",
                name: "STOP",
                severity: "INFO",
                hardwareSource: false,
              },
              color: checkedItem.color,
              value: {
                speedLimit: 0,
              },
              name: "STOP",
            },
          ],
          routeStateType: "TRACK",
          routeId: checkedItem.id,
        };
        return pointState;
      });

      const onlyOneTerminus =
        stops.filter((stop) => stop.terminus).length === 1;

      if (onlyOneTerminus) {
        routePoints.push(routePoints[0]);
      }

      let polyline = ClassPolyline({
        googleMap: googleMap,
        map: map,
        batchPolyLine: routePoints,
        color: checkedItem?.color,
        hideEventColors: false,
        viewPointMarkers: false,
        viewEventMarkers: true,
      });

      multiPolyline.set(checkedItem.id, polyline);

      routePoints.forEach((routeState) => {
        trackBounds.extend(
          new window.google.maps.LatLng(
            routeState?.dynamicFields.latitude,
            routeState?.dynamicFields.longitude
          )
        );
      });

      stops.forEach((stop) => {
        trackBounds.extend(
          new window.google.maps.LatLng(stop.latitude, stop.longitude)
        );
      });
    });
    return trackBounds;
  };

  //#endregion functions

  //#region useEffect

  // components on map
  useEffect(() => {
    if (!_.isEmpty(checkedList) && map && isMapIdle) {
      componentsToAdd.push({
        selectorName: ".zoom-control-container",
        selectorPosition: googleMap.maps.ControlPosition.RIGHT_BOTTOM,
        component: (
          <div
            className="zoom-control-container"
            style={{
              zIndex: 1,
              position: "absolute",
              padding: "0px 16px 16px 16px",
              marginBottom: "-8px",
            }}
          >
            <Zoom
              handleMinus={() => minusClick()}
              handlePlus={() => plusClick()}
            />
          </div>
        ),
      });

      preferencesContext.satelliteOnMap
        ? map.setMapTypeId("hybrid")
        : map.setMapTypeId("roadmap");

      componentsToAdd.push({
        selectorName: ".maptype-control",
        selectorPosition: googleMap.maps.ControlPosition.RIGHT_BOTTOM,
        component: (
          <MapType
            handleMap={() => mapClick()}
            handleSatellite={() => satelliteClick()}
            activeSatellite={preferencesContext.satelliteOnMap}
          />
        ),
      });

      componentsToAdd.push({
        selectorName: ".public-transport",
        selectorPosition: googleMap.maps.ControlPosition.RIGHT_TOP,
        component: (
          <PublicTransport
            handleStops={() => setRouteViewType("stops")}
            handleTracks={() => setRouteViewType("tracks")}
            handleTracksAndStops={() => setRouteViewType("tracksAndStops")}
            type={routeViewType}
          />
        ),
      });

      componentsToAdd.push({
        selectorName: ".public-route-name",
        selectorPosition: googleMap.maps.ControlPosition.TOP_RIGHT,
        component: (
          <div className="public-route-name">
            <div
              className="public-route-name-color"
              style={{ backgroundColor: checkedList[0].color }}
            ></div>
            <div className="public-route-name-text">{checkedList[0].name}</div>
          </div>
        ),
      });

      if (!_.isEqual(componentsToAdd, componentsOnMap)) {
        setComponentsOnMap(componentsToAdd);
      }
      if (userSelectedMapType != "") {
        if (userSelectedMapType == "roadmap") {
          map.setMapTypeId("roadmap");
        } else {
          map.setMapTypeId("hybrid");
        }
      }
    }
  }, [checkedList, map, isMapIdle]);

  // handling polyline and markers
  useEffect(() => {
    cleanMap();
    if (
      !_.isEmpty(checkedList) &&
      map &&
      isMapIdle &&
      (!_.isEmpty(points) || !_.isEmpty(stops))
    ) {
      let trackBounds = new window.google.maps.LatLngBounds();
      if (routeViewType === "tracksAndStops") {
        trackBounds = buildMarkers(trackBounds);
        trackBounds = buildPolyline(trackBounds);
      } else if (routeViewType === "tracks") {
        trackBounds = buildPolyline(trackBounds);
      } else if (routeViewType === "stops") {
        trackBounds = buildMarkers(trackBounds);
      }
      if (!trackBounds.isEmpty()) {
        map && map.fitBounds(trackBounds, 100);
        setBounds(trackBounds);
      } else {
        map && map.fitBounds(bounds, 100);
      }
    }
  }, [checkedList, map, isMapIdle, routeViewType]);

  useEffect(() => {
    if (highlightedStop != -1 && map && id == checkedList[0].id) {
      if (highlightedStop == 0) {
        let startMarker = startMarkerHighlightList[0];
        if (startMarker) {
          map.setZoom(18);
          map.panTo(startMarker.getPosition());
        }
        const genericFirstElement = document.querySelectorAll(
          "#marker-public-stop-start-end-id-0"
        );
        const child = genericFirstElement[genericFirstElement.length - 1];
        if (child && highlightedStop == 0) {
          let mouseOverEvent = new MouseEvent("mouseover", {
            view: window,
          });
          setEventHover(mouseOverEvent);
          setHoveredChild(child);
          child.dispatchEvent(mouseOverEvent);
        }
      } else {
        let marker = markerHighlightClusterList[highlightedStop - 1];
        if (marker) {
          if (map.zoom == 18) {
            simulateMarkerMouseOver();
          } else {
            map.setZoom(18);
            map.panTo(marker.getPosition());
            setTimeout(() => {
              simulateMarkerMouseOver();
            }, 1500);
          }
        }
      }
    } else if (highlightedStop == -1) {
      if (map && isMapIdle) {
        if (hoveredChild && eventHover) {
          let mouseOutEvent = new MouseEvent("mouseout", {
            view: window,
          });
          hoveredChild.dispatchEvent(mouseOutEvent);
          setHoveredChild(null);
        }
        const trackBounds = new window.google.maps.LatLngBounds();
        stops.forEach((stop) => {
          trackBounds.extend(
            new window.google.maps.LatLng(stop.latitude, stop.longitude)
          );
        });
        map && map.fitBounds(trackBounds, 100);
      }
    }
  }, [highlightedStop]);
  // useful for integrity of clusters between renders
  useEffect(() => {
    if (markerClusterList.length == stops.length - 1 && startEndCluster) {
      setStartMarkerHighlightList(startEndCluster);
      setMarkerHighlightClusterList(markerClusterList);
    }
  }, [markerClusterList]);

  //#endregion useEffect

  //#region Render

  return (
    <BaseMap
      id={id}
      googleMapsApiKey={googleMapsApiKey}
      zoom={zoom}
      latitude={latitude}
      longitude={longitude}
      getMap={setMap}
      showMarkers={true}
      getIsMapIdle={setIsMapIdle}
      hasStreetView={true}
      setStreetViewEnabled={setStreetViewEnabled}
      exitFromStreetView={true}
    >
      {componentsOnMap}
    </BaseMap>
  );

  //#endregion Render
};
