import React, { useState, useEffect } from "react";
import { IconSearch } from "../Icon/Line/Search";
import { IconArrowDown } from "../Icon/Line/ArrowDown";
import { Checkbox } from "../Forms/Checkbox";
import { useTranslation } from "react-i18next";

import "./GeofenceDropdown.css";
import { ToastNotification } from "../../utils/ToastNotification";

export const GeofenceDropdown = ({
  options,
  setValue,
  value,
  reset,
  hasMultiSelection,
  disabled,
}) => {
  const { t } = useTranslation();
  const [isActive, setIsActive] = useState(false);
  const [optionOpen, setOptionOpen] = useState(
    Array.from({ length: options?.length ?? 0 })
  );
  const [search, setSearchValue] = useState("");
  const wrapperRef = React.useRef(null);
  const parentRef = React.useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        wrapperRef.current &&
        !wrapperRef.current?.contains(event.target) &&
        !parentRef.current?.contains(event.target)
      ) {
        setIsActive(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  useEffect(() => {
    setOptionOpen(Array.from({ length: options?.length ?? 0 }));
  }, [options]);

  function handleItemClick(index) {
    const updatedValue = optionOpen.map((option, idx) => {
      return idx === index ? !option : option;
    });
    setOptionOpen(updatedValue);
  }

  function isSelected(option) {
    return value ? value.some((el) => el.id === option.id) : null;
  }

  function displayValue() {
    if (value && value.length === 1) {
      return value[0].name;
    } else if (value && value.length > 1) {
      return `${value.length} ${t("common.itemSelected")}`;
    }
    return "";
  }

  //Metodo per la selezione multipla (checkbox)
  function updateChecklist(checkbox) {
    reset && reset();
    let newValue = value;
    let isValueUpdated = false;
    if (setValue) {
      value.forEach((item) => {
        if (item.id === checkbox.id) {
          newValue = value?.filter((el) => el.id !== checkbox.id);
          isValueUpdated = true;
        }
      });
      if (!isValueUpdated) {
        newValue.push(checkbox);
      }
      setValue(newValue);
    }
  }

  //Metodo per la selezione singola(radio button)
  function radioSelect(checkbox) {
    if (setValue) {
      if (value?.includes(checkbox)) {
        setValue([]);
      } else {
        setIsActive(!isActive);
        setValue([checkbox]);
      }
      reset && reset();
    }
  }

  function filter(geofences) {
    if (search === "") return geofences;
    else {
      return geofences.filter((geofence) =>
        geofence?.name?.toLowerCase().includes(search.toLowerCase())
      );
    }
  }

  function getInputClass() {
    if (disabled) return "dropdown-button disabled";
    if (isActive) return "dropdown-button active";
    else if (value?.length > 0) return "dropdown-button selected";
    else return "dropdown-button";
  }

  return (
    <div className="geofence-dropdown">
      <div
        ref={parentRef}
        className={getInputClass()}
        onClick={() =>
          !disabled
            ? setIsActive(!isActive)
            : ToastNotification({
              toastId: "noGeofenceDefined",
              description: t("map.actions.searchActionMenu.noGeofencesError"),
            })
        }
      >
        <input
          className="btn-label"
          readOnly
          placeholder={t("map.actions.searchActionMenu.geofencePlaceholder")}
          value={displayValue()}
        />
        <div className={isActive ? "arrow open" : "arrow"}>
          <IconArrowDown size={14} color="--global-colors-ink-light" />
        </div>
      </div>
      {isActive && (
        <div ref={wrapperRef} className="dropdown-content">
          <div className="drop-search">
            <input
              type="text"
              placeholder={t(
                "map.actions.searchActionMenu.dropdownSearchPlaceholder"
              )}
              value={search}
              onChange={(e) => {
                setSearchValue(e.target.value);
                setOptionOpen(
                  Array.from({ length: options?.length ?? 0 }, () => {
                    return e.target.value === "" ? false : true;
                  })
                );
              }}
            />
            <IconSearch size={14} color="--global-colors-ink-light" />
          </div>
          {options &&
            options.map((option, idx) => {
              return (
                <React.Fragment key={idx}>
                  <div
                    onClick={() => handleItemClick(idx)}
                    className={
                      optionOpen[idx] ? "drop-item active" : "drop-item"
                    }
                  >
                    <div
                      className="color"
                      style={{
                        backgroundColor: option?.color,
                      }}
                    />
                    <div className="option-label">{option?.label}</div>
                    <div
                      className={
                        optionOpen[idx] ? "sub-arrow open" : "sub-arrow"
                      }
                    >
                      <IconArrowDown
                        size={14}
                        color="--global-colors-ink-light"
                      />
                    </div>
                  </div>
                  {optionOpen[idx] && (
                    <div className="option-checklist">
                      {option?.geofences &&
                        filter(option?.geofences).map((item, index) => {
                          return (
                            <div className="option-checkbox" key={index}>
                              <Checkbox
                                label={item?.name}
                                checked={isSelected(item)}
                                onChange={() => {
                                  if (hasMultiSelection) updateChecklist(item);
                                  else radioSelect(item);
                                }}
                              />
                            </div>
                          );
                        })}
                    </div>
                  )}
                </React.Fragment>
              );
            })}
        </div>
      )}
    </div>
  );
};
