import HttpClient from "../../utils/HttpClient";

export default class CommandRepository extends HttpClient {
  constructor() {
    super(true);
  }

  getCommandRequest(deviceId: number, command: string) {
    const tenantId = localStorage.getItem("tenantId");
    let path =
      "/tenants/" + tenantId + "/devices/" + deviceId + "/commands/" + command;
    return this.instance.get(path);
  }

  getCommandsRequest(deviceId: number) {
    const tenantId = localStorage.getItem("tenantId");
    let path = "/tenants/" + tenantId + "/devices/" + deviceId + "/commands";
    return this.instance.get(path);
  }

  createCommandRequest(deviceId: number, command: string, type: string) {
    const tenantId = localStorage.getItem("tenantId");
    let path = "/tenants/" + tenantId + "/devices/" + deviceId + "/commands";
    const body: any = { command: command, type: type };
    return this.instance.post(path, body);
  }
}
