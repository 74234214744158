import HttpClient from "../../../utils/HttpClient";

export default class RefuellingReportRepository extends HttpClient {
  constructor() {
    super(true);
  }

  getRefuellingReportSummary(vehicleId: number, queryParams?: string) {
    const tenantId = localStorage.getItem("tenantId");
    // return {
    //   data: {
    //     travelDistance: 3000,
    //     refueled: 940,
    //     refuels: 2,
    //   },
    // };
    return this.instance.get(
      "tenants/" +
        tenantId +
        "/vehicles/" +
        vehicleId +
        "/refuels/summary" +
        (queryParams ?? "")
    );
  }

  getRefuellingReport(vehicleId: number, queryParams?: string) {
    const tenantId = localStorage.getItem("tenantId");

    return this.instance.get(
      "tenants/" +
        tenantId +
        "/vehicles/" +
        vehicleId +
        "/refuels" +
        (queryParams ?? "")
    );
    // return {
    //   data: [
    //     {
    //       id: 0,
    //       driverId: 12,
    //       refuelDate: "01-02-2023 18:30",
    //       initialLevel: 20,
    //       refuelLevel: 480,
    //       odometer: 256020,
    //       refuelAddress: "IP via bari 5, 70024 Gravina in Puglia BA, Italia",
    //       price: 1.34,
    //     },
    //     {
    //       id: 1,
    //       driverId: 12,
    //       refuelDate: "02-02-2023 11:20",
    //       initialLevel: 40,
    //       refuelLevel: 400,
    //       odometer: 256050,
    //       refuelAddress:
    //         "Eni via Dolcecanto km. 33, 70024 Gravina in Puglia BA, Italia",
    //       price: 1.3,
    //     },
    //     {
    //       id: 2,
    //       driverId: 12,
    //       refuelDate: "03-02-2023 15:20",
    //       initialLevel: 50,
    //       refuelLevel: 410,
    //       odometer: 256150,
    //       refuelAddress:
    //         "Via Pietro Sette, 9, 70024 Gravina in Puglia BA, Italia",
    //       price: 1.83,
    //     },
    //     {
    //       id: 3,
    //       driverId: 23,
    //       refuelDate: "02-02-2023 11:20",
    //       initialLevel: 40,
    //       refuelLevel: 400,
    //       odometer: 256050,
    //       refuelAddress:
    //         "Eni via Dolcecanto km. 33, 70024 Gravina in Puglia BA, Italia",
    //       price: 1.3,
    //     },
    //     {
    //       id: 4,
    //       driverId: 12,
    //       refuelDate: "03-02-2023 15:20",
    //       initialLevel: 50,
    //       refuelLevel: 410,
    //       odometer: 256150,
    //       refuelAddress: "Esso Via Tripoli 5, 70022 Altamura BA, Italia",
    //       price: 1.83,
    //     },
    //   ],
    // };
  }

  getRefuellingChartDataReport(vehicleId: number, queryParams?: string) {
    const tenantId = localStorage.getItem("tenantId");
    return this.instance.get(
      "tenants/" +
        tenantId +
        "/vehicles/" +
        vehicleId +
        "/refuels-chart-data" +
        (queryParams ?? "")
    );
    // return {
    //   data: [
    //     {
    //       id: 0,
    //       fueldate: "2023-10-04T05:39:22Z",
    //       fuelLevel: 16.0,
    //       isRefuel: false,
    //     },
    //     {
    //       id: 1,
    //       fueldate: "2023-10-04T05:45:50Z",
    //       fuelLevel: 50.0,
    //       isRefuel: true,
    //     },
    //     {
    //       id: 2,
    //       fueldate: "2023-10-04T06:55:54Z",
    //       fuelLevel: 44.0,
    //       isRefuel: false,
    //     },
    //     {
    //       id: 3,
    //       fueldate: "2023-10-04T08:22:48Z",
    //       fuelLevel: 38.0,
    //       isRefuel: false,
    //     },
    //     {
    //       id: 4,
    //       fueldate: "2023-10-04T12:16:48.011Z",
    //       fuelLevel: 20.0,
    //       isRefuel: false,
    //     },
    //     {
    //       id: 5,
    //       fueldate: "2023-10-05T05:47:54Z",
    //       fuelLevel: 18.0,
    //       isRefuel: false,
    //     },
    //     {
    //       id: 6,
    //       fueldate: "2023-10-05T05:57:54Z",
    //       fuelLevel: 30.0,
    //       isRefuel: true,
    //     },
    //     {
    //       id: 7,
    //       fueldate: "2023-10-05T08:22:48Z",
    //       fuelLevel: 10.0,
    //       isRefuel: false,
    //     },
    //     {
    //       id: 8,
    //       fueldate: "2023-10-05T12:22:48Z",
    //       fuelLevel: 8.0,
    //       isRefuel: false,
    //     },
    //     {
    //       id: 9,
    //       fueldate: "2023-10-05T16:22:48Z",
    //       fuelLevel: 6.0,
    //       isRefuel: false,
    //     },
    //     {
    //       id: 10,
    //       fueldate: "2023-10-05T21:22:48Z",
    //       fuelLevel: 4.0,
    //       isRefuel: false,
    //     },
    //     {
    //       id: 11,
    //       fueldate: "2023-10-06T10:10:48.011Z",
    //       fuelLevel: 60.0,
    //       isRefuel: true,
    //     },
    //     {
    //       id: 12,
    //       fueldate: "2023-10-06T20:10:48.011Z",
    //       fuelLevel: 65.0,
    //       isRefuel: true,
    //     },
    //     {
    //       id: 13,
    //       fueldate: "2023-10-07T10:10:48.011Z",
    //       fuelLevel: 60.0,
    //       isRefuel: true,
    //     },
    //   ],
    // };
  }
}
