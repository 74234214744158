import HttpClient from "../../utils/HttpClient";

export default class EventsIORepository extends HttpClient {
  constructor() {
    super(true);
  }

  getEvents() {
    return this.instance.get("/events_io");
  }
}
