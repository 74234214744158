import {
  PayloadAction,
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";

import _ from "lodash";
import { normalize } from "normalizr";
import { RootState } from "../../../app/store";
import { Config } from "../../../config/Config";
import { getUserInfoAsync, getUsersInfoAsync } from "../usersInfoSlice";
import { securitiesProfilesSchema } from "./securityProfilesNormalization";
import SecurityProfilesRepository from "./securityProfilesRepository";

//#region Type
export type SecurityProfiles = {
  id: number;
  name: string;
};
//#endregion Type

//#region API
export const getSecurityProfilesAsync = createAsyncThunk(
  "securityProfiles/getSecurityProfiles",
  async () => {
    const securityProfilesRepository = new SecurityProfilesRepository();
    const response = await securityProfilesRepository.getSecurityProfiles();
    const securityProfiles = _.get(
      response,
      Config.SECURITYPROFILES_RESPONSE_PATH
    );
    const normalizedData = normalize(
      securityProfiles,
      securitiesProfilesSchema
    );
    return normalizedData.entities;
  }
);
//#endregion API

//#region Slice
const securityProfilesAdapter = createEntityAdapter<SecurityProfiles>({
  selectId: (securityProfiles) => securityProfiles.id,
});

export const securityProfilesSlice = createSlice({
  name: "securityProfiles",
  initialState: securityProfilesAdapter.getInitialState({
    status: "idle",
    reasonCode: "",
  }),
  reducers: {
    securityProfilesEmptyState: (state: any) => {
      securityProfilesAdapter.setAll(state, []);
      state.reasonCode = "";
      state.status = "idle";
    },
  },
  extraReducers: (builder) => {
    builder
      //#region Entity Reducers
      .addCase(getSecurityProfilesAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(
        getSecurityProfilesAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          securityProfilesAdapter.upsertMany(
            state,
            action.payload.securityProfiles
          );
          state.status = "idle";
        }
      )
      .addCase(getSecurityProfilesAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = "";
      })
      //#endregion Entity Reducers
      //#region External Entity Reducers
      .addCase(
        getUsersInfoAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          action.payload.SecurityProfiles &&
            securityProfilesAdapter.upsertMany(
              state,
              action.payload.securityProfiles
            );
        }
      )
      .addCase(
        getUserInfoAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          action.payload.SecurityProfiles &&
            securityProfilesAdapter.upsertMany(
              state,
              action.payload.securityProfiles
            );
        }
      );
    //#endregion External Entity Reducers
  },
});
//#endregion Slice

//#region Status
export const securityProfilesSelectors =
  securityProfilesAdapter.getSelectors<RootState>(
    (state) => state.securityProfiles
  );

export const selectSecurityProfilesSliceStatus = (state: any) =>
  state.securityProfiles.status;
export const selectSecurityProfilesSliceReasonCode = (state: any) =>
  state.securityProfiles.reasonCode;

export const { securityProfilesEmptyState } = securityProfilesSlice.actions;

//#endregion Status

export default securityProfilesSlice.reducer;
