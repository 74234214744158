import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Signal } from "../Indicators/IndicatorSignal";
import "./UserBox.css";

export const UserBox = ({
  size,
  status,
  title,
  iconTitle,
  subTitle,
  iconSubTitle,
  data,
  active,
  icon,
  clickCallback,
  disableClick,
  onClicked,
  ...props
}) => {
  const [coords, setCoords] = useState({ x: -1, y: -1 });
  const [isRippling, setIsRippling] = useState(false);
  const [isSelected, setIsSelected] = useState(active);

  const onClick = (e) => {
    const rect = e.target.getBoundingClientRect();
    setCoords({
      x: rect.width / 2,
      y: rect.height / 2,
    });

    !onClicked && setIsSelected((prevState) => !prevState);

    if (clickCallback !== undefined) {
      clickCallback && clickCallback(title);
    }
  };

  useEffect(() => {
    setIsSelected(active);
  }, [active]);

  useEffect(() => {
    if (coords.x !== -1 && coords.y !== -1) {
      setIsRippling(true);
      setTimeout(() => setIsRippling(false), 300);
    } else {
      setIsRippling(false);
    }
  }, [coords]);

  useEffect(() => {
    if (!isRippling) {
      setCoords({ x: -1, y: -1 });
    }
  }, [isRippling]);

  let className = `mn-user-box--${size ?? "regular"}`;

  if (isSelected && !disableClick) {
    if (size !== "large") {
      className += " mn-user-box--active";
    }
  }

  return (
    <div
      className={className}
      {...props}
      onClick={(e) => {
        onClicked && onClicked();
        onClick(e);
      }}
    >
      <div className="mn-user-box__content">
        <div className="mn-user-box__icon-status">
          <div className="mn-user-box__icon">{icon}</div>
          {status && <Signal mode={status} />}
        </div>
        <div className="mn-user-box__detail">
          <div className={`mn-user-box__upper-row--${size}`}>
            {iconTitle && (
              <span className="mn-user-box__iconText">{iconTitle}</span>
            )}
            <span className="mn-user-box__id">{title}</span>
            <span className="mn-user-box__data">{data}</span>
          </div>
          <div className="mn-user-box__lower-row">
            <span className="mn-user-box__iconText">{iconSubTitle}</span>
            <span className="mn-user-box__text">{subTitle}</span>
          </div>
        </div>
      </div>
      {isRippling && !disableClick && (
        <span className="ripple" style={{ left: coords.x, top: coords.y }} />
      )}
    </div>
  );
};

UserBox.propTypes = {
  size: PropTypes.oneOf(["regular", "large"]),
  status: PropTypes.string,
  title: PropTypes.string,
  iconTitle: PropTypes.object,
  subTitle: PropTypes.string,
  iconSubTitle: PropTypes.object,
  data: PropTypes.string,
  active: PropTypes.bool,
  icon: PropTypes.object,
  disableClick: PropTypes.bool,
  clickCallback: PropTypes.func,
  onClicked: PropTypes.func,
};
