import React, { useState } from "react";
import PropTypes from "prop-types";
import TextField from "../../Forms/TextField";
import Form from "../../Forms/Form";
import { LabelText } from "../../LabelText/LabelText";
import { ModalBody } from "../ModalBody";
import { ModalWrapper } from "../ModalWrapper";
import { ModalFooter } from "../ModalFooter";
import { Button } from "../../Button/Button";
import { IconPhone } from "../../Icon/Line/Phone";
import { useTranslation } from "react-i18next";
import "./ResetPasswordSupportModal.css";
import { Alert } from "../../Alert/Alert";

export const ResetPasswordSupportModal = ({ open, onClose, eMailSelected, setShowResetPsw }) => {
    const { t } = useTranslation();

    const LeftColumn = ({ onColumnClose }) => {
        const [eMail, setEMail] = useState("");
        const [isLoading, setIsLoading] = useState(false);

        const clearFields = () => {
            setEMail("");
        };

        return (
            <>
                <div className="reset-password-support-container">
                    <ModalBody
                        title={t("customModals.resetPassword.title")}
                        desc={t("customModals.resetPassword.desc")}
                    >
                        <div className="central-forms">
                            <LabelText text={t("customModals.resetPassword.eMail")} />
                            <Form>
                                <TextField
                                    id="E-mail"
                                    placeholder={eMailSelected}
                                    validate="email"
                                    disabled={true}
                                    events={{ onChange: (data) => setEMail(data.value) }}
                                    value={eMail}
                                />
                            </Form>
                            <div className="central-forms-alert" style={{ marginTop: "15px" }}>
                                <Alert
                                    status="error"
                                    message={t("customModals.resetPassword.eMailAlert")}
                                />
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter
                        primaryLabel={t("customModals.resetPassword.primaryButtonTryAgain")}
                        primaryAction={() => {
                            setIsLoading(true);
                            clearFields();
                            onColumnClose();
                            setShowResetPsw(true)
                            
                        }}
                        loadingPrimaryButton={isLoading}
                    />
                </div>
            </>
        );
    };
    const RightColumn = () => {
        return (
            <>
                <div className="right-help-column">
                    <ModalBody
                        isScrollable={false}
                        title={t("customModals.resetPassword.secondTitle")}
                        desc={t("customModals.resetPassword.secondDesc")}
                    >
                        <Button
                            aspect="secondary"
                            size="regular"
                            label="+39 080 246 42 45"
                            onlyIcon={false}
                        >
                            <IconPhone size={14} color="--global-colors-ink-light" />
                        </Button>
                    </ModalBody>
                </div>
            </>
        );
    };

    return (
        <div className="reset-password-support">
            <ModalWrapper
                open={open}
                closeAction={onClose}
                leftColumn={<LeftColumn onColumnClose={onClose} />}
                rightColumn={<RightColumn />}
            ></ModalWrapper>
        </div>
    );
}

ResetPasswordSupportModal.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    eMailSelected: PropTypes.string,
    setShowResetPsw: PropTypes.func,
};

ResetPasswordSupportModal.defaultProps = {
    open: false,
};