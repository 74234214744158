import {
  PayloadAction,
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import _ from "lodash";
import { normalize } from "normalizr";
import { RootState } from "../../app/store";
import { Config } from "../../config/Config";
import { GTFleetSuccessCodes } from "../../config/GTFleetSuccessCodes";
import { routesHistorySchema } from "./routeHistoryNormalization";
import RoutesHistoryRepository from "./routesHistoryRepository";

//#region Type
export type RouteStateType = "TRACK" | "STOP";

export type RoutePosition = {
  latitude: number;
  longitude: number;
  gpsPositionTimestamp: Date;
  address: string;
};

export type RouteHistoryView = {
  id: number;
  routeStateType: RouteStateType;
  firstPosition: RoutePosition;
  lastPosition: RoutePosition;
  vehicleDetails: number;
  driverDetails: number;
  open: boolean;
};
//#endregion Type

//#region API
// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const getRoutesHistoryViewAsync = createAsyncThunk(
  "routesHistoryView/getRoutesHistoryView",
  async (queryParams: string, { rejectWithValue }) => {
    try {
      const routesHistoryRepository = new RoutesHistoryRepository();
      const response = await routesHistoryRepository.getRoutesHistoryView(
        queryParams
      );
      // The value we return becomes the `fulfilled` action payload
      const routesHistory = _.get(response, Config.ROUTES_HISTORY_VIEW_PATH);
      const normalizedData = normalize(routesHistory, routesHistorySchema);
      return normalizedData.entities;
    } catch (err: any) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data.message);
    }
  }
);
//#endregion API

//#region Custom Functions
function setFilteredData(state: any, action: PayloadAction<any>) {
  action.payload.routeHistory
    ? routesHistoryViewAdapter.setAll(state, action.payload.routeHistory)
    : routesHistoryViewAdapter.setAll(state, []);
}
//#endregiorn Custom Functions

//#region Slice
const routesHistoryViewAdapter = createEntityAdapter<RouteHistoryView>({
  selectId: (routeHistoryView) => routeHistoryView.id,
  sortComparer: (a, b) => a.id.toString().localeCompare(b.id.toString()),
});

export const routeHistoryViewSlice = createSlice({
  name: "routesHistoryView",
  initialState: routesHistoryViewAdapter.getInitialState({
    status: "idle",
    reasonCode: "",
  }),
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    routeHistoryViewEmptyState: (state: any) => {
      routesHistoryViewAdapter.setAll(state, []);
      state.reasonCode = "";
      state.status = "idle";
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      //#region Entity Reducers
      // getRoutesHistoryViewAsync extra reducers
      .addCase(
        getRoutesHistoryViewAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          setFilteredData(state, action);
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.GET;
        }
      )
      .addCase(getRoutesHistoryViewAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(getRoutesHistoryViewAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = "";
      });
    //#endregion Entity Reducers
  },
});
//#endregion Slice

//#region Status
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const routesHistoryViewSelectors =
  routesHistoryViewAdapter.getSelectors<RootState>(
    (state) => state.routesHistoryView
  );

export const selectRoutesHistoryViewSliceStatus = (state: any) =>
  state.routesHistoryView.status;
export const selectRoutesHistoryViewSliceReasonCode = (state: any) =>
  state.routesHistoryView.reasonCode;
//#endregion Status

export const { routeHistoryViewEmptyState } = routeHistoryViewSlice.actions;

export default routeHistoryViewSlice.reducer;
