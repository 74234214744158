import React from "react";
import PropTypes from "prop-types";
import { useDrag } from "react-dnd";
import { StatusBadge } from "../Map/StatusBadge";
import "./CardVehicle.css";
import { MapMarkerLocator } from "../Marker/MapMarkerLocator";

export const CardVehicle = ({
  vehicleId,
  status,
  location,
  latitude,
  longitude,
  time,
  style,
  googleMapsApiKey,
  openDropArea,
  closeDropArea,
  markerRotation
}) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: "box",
    item: {
      vehicleId, status, location, latitude, longitude, time
    },
    canDrag: (monitor) => ({
      drag: !monitor.didDrop() ? openDropArea() : closeDropArea(),
    }),
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
  }), [openDropArea]);

  const className = isDragging
    ? "mn-mw-card-vehicle mn-mw-card-vehicle--dragging"
    : "mn-mw-card-vehicle";

  return (
    <div
      ref={drag}
      role="Box"
      className={className}
      data-testid={`vehicle-${vehicleId}`}
      style={{ ...style }}
    >
      <div className="marker-locator" style={{ transform: `rotate(${markerRotation})` }} >
        <MapMarkerLocator />
      </div>
      <img alt="minimap" src={`https://maps.googleapis.com/maps/api/staticmap?center=${latitude},${longitude}&zoom=17&size=222x150&key=${googleMapsApiKey}&style=element%3Alabels.text.fill%7Ccolor%3A0x7c93a3%7Clightness%3A-10&amp&style=feature%3Aadministrative.country%7Celement%3Ageometry%7Cvisibility%3Aon&amp&style=feature%3Aadministrative.country%7Celement%3Ageometry.stroke%7Ccolor%3A0xa0a4a5&amp&style=feature%3Aadministrative.province%7Celement%3Ageometry.stroke%7Ccolor%3A0x62838e&amp&style=feature%3Alandscape%7Celement%3Ageometry.fill%7Ccolor%3A0xdde3e3&amp&style=feature%3Alandscape.man_made%7Celement%3Ageometry.stroke%7Ccolor%3A0x3f4a51%7Cweight%3A0.30&amp&style=feature%3Apoi%7Cvisibility%3Asimplified&amp&style=feature%3Apoi.attraction%7Cvisibility%3Aon&amp&style=feature%3Apoi.business%7Cvisibility%3Aoff&amp&style=feature%3Apoi.government%7Cvisibility%3Aoff&amp&style=feature%3Apoi.park%7Cvisibility%3Aon&amp&style=feature%3Apoi.place_of_worship%7Cvisibility%3Aoff&amp&style=feature%3Apoi.school%7Cvisibility%3Aoff&amp&style=feature%3Apoi.sports_complex%7Cvisibility%3Aoff&amp&style=feature%3Aroad%7Csaturation%3A-100%7Cvisibility%3Aon&amp&style=feature%3Aroad%7Celement%3Ageometry.stroke%7Cvisibility%3Aon&amp&style=feature%3Aroad.highway%7Celement%3Ageometry.fill%7Ccolor%3A0xbbcacf&amp&style=feature%3Aroad.highway%7Celement%3Ageometry.stroke%7Clightness%3A0%7Ccolor%3A0xbbcacf%7Cweight%3A0.50&amp&style=feature%3Aroad.highway%7Celement%3Alabels%7Cvisibility%3Aon&amp&style=feature%3Aroad.highway%7Celement%3Alabels.text%7Cvisibility%3Aon&amp&style=feature%3Aroad.highway.controlled_access%7Celement%3Ageometry.fill%7Ccolor%3A0xffffff&amp&style=feature%3Aroad.highway.controlled_access%7Celement%3Ageometry.stroke%7Ccolor%3A0xa9b4b8&amp&style=feature%3Atransit%7Cvisibility%3Aoff&amp&style=feature%3Awater%7Celement%3Ageometry.fill%7Ccolor%3A0xa3c7df&amp;rnd=1636735607959`} />
      <StatusBadge size="small" id={vehicleId} status={status} />
    </div >
  );
};

CardVehicle.propTypes = {
  status: PropTypes.oneOf(['NO_SIGNAL', 'ONLINE', 'OFFLINE', 'UNKNOWN']),
  vehicleId: PropTypes.string,
  location: PropTypes.string,
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  time: PropTypes.string,
  onClose: PropTypes.func,
};

CardVehicle.defaultProps = {
  status: "online",
  vehicleId: "A01",
  location: "Roma, Autostrada Roma L'Aquila Teramo",
  latitude: 42.30714523594324,
  longitude: 13.302415701962245,
  time: "10:58"
};
