import dayjs from "dayjs";
import i18next, { t } from "i18next";
import _ from "lodash";
import { useEffect, useRef, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { renderToStaticMarkup } from "react-dom/server";
import { Button } from "../Button/Button";
import { Dropdown } from "../Dropdown/Dropdown";
import { IconAdd } from "../Icon/Line/Add";
import { IconMinus } from "../Icon/Line/Minus";
import { IconRefresh } from "../Icon/Line/Refresh";
import { PeriodNav } from "../Navigation/PeriodNav";
import "./SpeedGraph.css";

interface SeriesItem {
  timestamp: Date;
  speed: number;
}

interface VehicleType {
  id: number;
  label: string;
  speedLimit: number;
}

interface SpeedGraphProps {
  exportId: string;
  series: SeriesItem[];
  isLoading: boolean;
  vehicleList: VehicleType[];
  preferences: any;
  getVehicleSelected: (e: any) => any;
}

interface TooltipCustomParams {
  series: number[][];
  seriesIndex: number;
  dataPointIndex: number;
  w: {
    config: {
      xaxis: {
        categories: string[];
      };
    };
  };
}
export const SpeedGraph: React.FC<SpeedGraphProps> = ({
  exportId,
  series,
  getVehicleSelected,
  vehicleList,
  isLoading,
  preferences,
}) => {
  const allPeriodList = [
    { label: i18next.t("common.allPeriod"), key: "allPeriod" },
    { label: i18next.t("common.daily"), key: "daily" },
  ];
  const [graphOption, setGraphOption] = useState<any>({});
  const [vehicleDropdown, setVehicleDropdown] = useState<VehicleType>(
    vehicleList[0]
  );
  const [periodDropdown, setPeriodDropdown] = useState(allPeriodList[0]);
  const [graphTitle, setGraphTitle] = useState<string>("");
  const [periodNavHidden, setPeriodNavHidden] = useState<boolean>(true);
  const [textPeriod, setTextPeriod] = useState<string>();
  const [currentPeriod, setCurrentPeriod] = useState<number>(1);
  const [totalPeriod, setTotalPeriod] = useState<number>();
  const [currentDay, setCurrentDay] = useState<Date>();
  const [isSameDay, setIsSameDay] = useState<boolean>();
  const unit = preferences.isMetric ? " km/h" : " mph";
  const seriesRef = useRef<any>([]);

  useEffect(() => {
    if (
      series.length > 0 &&
      !_.isEmpty(vehicleDropdown) &&
      !_.isEqual(series, seriesRef.current)
    ) {
      seriesRef.current = series; // To avoid re-render on notification update

      const timestamps = series.map((el) => {
        return dayjs
          .utc(el.timestamp)
          .tz(preferences.timeZone)
          .format("YYYY-MM-DDTHH:mm:ss[Z]");
      });

      const speeds = series.map((el) => el.speed);
      const maxSpeedValue = Math.max(...speeds);

      const firstDate: any = new Date(series[0].timestamp);
      const lastDate: any = new Date(series[series.length - 1].timestamp);
      setIsSameDay(firstDate.toDateString() === lastDate.toDateString());
      const differenceInMilliseconds: any = lastDate - firstDate;
      // Manage the difference in days
      const differenceInDays = Math.round(
        differenceInMilliseconds / (1000 * 60 * 60 * 24) + 1
      );

      setTotalPeriod(differenceInDays);
      setCurrentDay(firstDate);

      const firstDateFormatted =
        firstDate.getDate() +
        " " +
        firstDate.toLocaleDateString(preferences.language, { month: "long" }) +
        " " +
        firstDate.getFullYear();

      const lastDateFormatted =
        lastDate.getDate() +
        " " +
        lastDate.toLocaleDateString(preferences.language, { month: "long" }) +
        " " +
        lastDate.getFullYear();

      const graphTitle =
        firstDateFormatted +
        (!(firstDate.toDateString() === lastDate.toDateString())
          ? " - " + lastDateFormatted
          : "");
      setGraphTitle(graphTitle);

      const graphOptionObj = {
        series: [
          {
            name: "Speed",
            data: speeds,
            color: "#00AAFF",
          },
        ],
        chart: {
          id: exportId.replaceAll(" ", ""),
          type: "line",
          toolbar: {
            export: {
              csv: {
                headerCategory: "Date",
                columnDelimiter: ",",
                dateFormatter: function (timestamp: string) {
                  return new Date(timestamp).toLocaleDateString(
                    preferences.language,
                    {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                      hour: "2-digit",
                      minute: "2-digit",
                      second: "2-digit",
                    }
                  );
                },
              },
            },
            show: true,
            tools: {
              download: false,
              selection: false,
              pan: false,
              zoom: true,
              zoomin: renderToStaticMarkup(
                <div className="speed-report-tool-button">
                  <Button label="" aspect="secondary" size="small" onlyIcon>
                    <IconAdd size={14} color="--global-colors-ink-ink" />
                  </Button>
                </div>
              ),
              zoomout: renderToStaticMarkup(
                <div className="speed-report-tool-button">
                  <Button label="" aspect="secondary" size="small" onlyIcon>
                    <IconMinus size={14} color="--global-colors-ink-ink" />
                  </Button>
                </div>
              ),
              reset: renderToStaticMarkup(
                <div className="speed-report-tool-button">
                  <Button label="" aspect="outline" size="small" onlyIcon>
                    <IconRefresh size={14} color="--global-colors-ink-ink" />
                  </Button>
                </div>
              ),
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
          width: 2,
        },
        grid: {
          row: {
            opacity: 0.5,
          },
        },
        xaxis: {
          type: "datetime",
          stepSize: 1,
          categories: timestamps,
          labels: {
            style: {
              fontFamily: "global-font-regular",
              fontSize: "10px",
              colors: ["#031832"],
            },
            datetimeFormatter: {
              day: "dd/MM",
              hour: "HH:mm",
            },
          },
        },
        tooltip: {
          enabled: !isLoading,
          shared: false,
          custom: function ({
            series,
            seriesIndex,
            dataPointIndex,
            w,
          }: TooltipCustomParams): string {
            const timestamp = w.config.xaxis.categories[dataPointIndex];

            let measure =
              series[seriesIndex][dataPointIndex] % 1 === 0
                ? series[seriesIndex][dataPointIndex]
                : series[seriesIndex][dataPointIndex].toFixed(2);
            return measure !== null
              ? '<div class="speed-report-arrow-box">' +
                  "<span>" +
                  measure +
                  unit +
                  "</span>" +
                  "<span>" +
                  dayjs(timestamp)
                    .utc()
                    .format(
                      preferences.language === "it"
                        ? "DD/MM/YY | HH:mm"
                        : "MM/DD/YY | HH:mm"
                    ) +
                  "</span>" +
                  "</div>"
              : "";
          },
        },
        markers: {
          size: 0,
          strokeWidth: 2,
          strokeColors: "#687484",
          colors: ["#ffffff", "#ffffff"],
          hover: {
            size: 5,
          },
        },
        yaxis: {
          max: Math.max(maxSpeedValue, vehicleDropdown.speedLimit + 40),
          labels: {
            style: {
              fontFamily: "global-font-regular",
              fontSize: "10px",
              fontWeight: "normal",
              colors: ["#687484"],
            },
          },
          tickAmount: 10,
        },
        annotations: {
          yaxis: [
            {
              y: vehicleDropdown.speedLimit, // limit speed constant value
              borderColor: "#FF0000",
              borderWidth: isLoading ? 0 : 1,
              strokeDashArray: 0,
            },
          ],
        },
      };
      setGraphOption(graphOptionObj);
    }
  }, [series]);

  const onChange = (valueSelected: string) => {
    let filteredSeries: SeriesItem[] = [];

    const firstDate: any = new Date(series[0].timestamp);
    const lastDate: any = new Date(series[series.length - 1].timestamp);
    const isSameDay: boolean =
      firstDate.toDateString() === lastDate.toDateString();

    switch (valueSelected) {
      case "allPeriod":
        setPeriodNavHidden(true);

        const firstDateFormatted =
          firstDate.getDate() +
          " " +
          firstDate.toLocaleDateString(preferences.language, {
            month: "long",
          }) +
          " " +
          firstDate.getFullYear();

        const lastDateFormatted =
          lastDate.getDate() +
          " " +
          lastDate.toLocaleDateString(preferences.language, { month: "long" }) +
          " " +
          lastDate.getFullYear();

        const graphTitle =
          firstDateFormatted + (!isSameDay ? " - " + lastDateFormatted : "");
        setGraphTitle(graphTitle);
        filteredSeries = series;
        break;

      case "daily":
        setPeriodNavHidden(false);
        setTextPeriod("days");
        setCurrentPeriod(1);
        const differenceInMilliseconds = lastDate - firstDate;
        const differenceInDays = Math.round(
          differenceInMilliseconds / (1000 * 60 * 60 * 24) + 1
        );

        setTotalPeriod(differenceInDays);
        setCurrentDay(firstDate);
        // Filtering data for current day
        const lastDay = firstDate.getDate();
        const lastMonth = firstDate.getMonth();
        const lastYear = firstDate.getFullYear();

        setGraphTitle(
          firstDate.getDate() +
            " " +
            firstDate.toLocaleDateString(preferences.language, {
              month: "long",
            }) +
            " " +
            firstDate.getFullYear()
        );

        filteredSeries = series.filter((data) => {
          const dataDate = new Date(data.timestamp);
          return (
            dataDate.getDate() === lastDay &&
            dataDate.getMonth() === lastMonth &&
            dataDate.getFullYear() === lastYear
          );
        });
        break;
      default:
        break;
    }

    // Update the state with filtered data and correctly formatted x-axis labels
    setGraphOption((prevGraphOption: any) => {
      if (!_.isEmpty(prevGraphOption)) {
        return {
          ...prevGraphOption,
          xaxis: {
            ...prevGraphOption.xaxis,
            categories: filteredSeries.map((el) => el.timestamp),
            labels: {
              ...prevGraphOption.xaxis.labels,
            },
          },
          series: [
            {
              ...prevGraphOption.series,
              data: filteredSeries.map((el) => el.speed),
            },
          ],
        };
      }
    });
  };

  const handlePrev = () => {
    let prevDay: any;
    if (currentDay) {
      prevDay = new Date(currentDay);
    }
    prevDay.setDate(prevDay.getDate() - 1); // Decrement by one day

    // Update current day and graph title
    setCurrentDay(prevDay);
    setGraphTitle(
      prevDay.getDate() +
        " " +
        prevDay.toLocaleDateString(preferences.language, { month: "long" }) +
        " " +
        prevDay.getFullYear()
    );

    // Filter data for the previous day
    const filteredSeries = series.filter((data) => {
      const dataDate = new Date(data.timestamp);
      return (
        dataDate.getDate() === prevDay.getDate() &&
        dataDate.getMonth() === prevDay.getMonth() &&
        dataDate.getFullYear() === prevDay.getFullYear()
      );
    });

    //If there is no data for the next day, add a timestamp with zero velocity for each hour of the day
    if (filteredSeries.length === 0) {
      const startOfDay = new Date(prevDay);
      startOfDay.setHours(0, 0, 0, 0); // Set the time to midnight

      for (let i = 0; i < 24; i++) {
        const hourTimestamp = new Date(startOfDay);
        hourTimestamp.setHours(i); // Set current hour
        filteredSeries.push({
          timestamp: new Date(hourTimestamp.toISOString()),
          speed: 0,
        });
      }
    }
    setCurrentPeriod(currentPeriod - 1);

    // Update status with chart options updated with new filtered data only
    setGraphOption((prevGraphOption: any) => {
      if (!_.isEmpty(prevGraphOption)) {
        return {
          ...prevGraphOption,
          xaxis: {
            ...prevGraphOption.xaxis,
            categories: filteredSeries.map((el) => el.timestamp),
          },
          series: [
            {
              ...prevGraphOption.series,
              data: filteredSeries.map((el) => el.speed),
            },
          ],
        };
      }
    });
  };

  const handleNext = () => {
    let nextDay: any;
    if (currentDay) {
      nextDay = new Date(currentDay);
    }
    nextDay.setDate(nextDay.getDate() + 1); // Increment by one day

    // Update current day and graph title
    setCurrentDay(nextDay);
    setGraphTitle(
      nextDay.getDate() +
        " " +
        nextDay.toLocaleDateString(preferences.language, { month: "long" }) +
        " " +
        nextDay.getFullYear()
    );

    // Filter data for the next day
    const filteredSeries = series.filter((data) => {
      const dataDate = new Date(data.timestamp);
      return (
        dataDate.getDate() === nextDay.getDate() &&
        dataDate.getMonth() === nextDay.getMonth() &&
        dataDate.getFullYear() === nextDay.getFullYear()
      );
    });

    // If there is no data for the next day, add a timestamp with zero velocity for each hour of the day
    if (filteredSeries.length === 0) {
      const startOfDay = new Date(nextDay);
      startOfDay.setHours(0, 0, 0, 0); // Set the time to midnight

      for (let i = 0; i < 24; i++) {
        const hourTimestamp = new Date(startOfDay);
        hourTimestamp.setHours(i); // Set current hour
        filteredSeries.push({
          timestamp: new Date(hourTimestamp.toISOString()),
          speed: 0,
        });
      }
    }

    setCurrentPeriod(currentPeriod + 1);

    // Update status with chart options updated with new filtered data only
    setGraphOption((prevGraphOption: any) => {
      if (!_.isEmpty(prevGraphOption)) {
        return {
          ...prevGraphOption,
          xaxis: {
            ...prevGraphOption.xaxis,
            categories: filteredSeries.map((el) => el.timestamp),
          },
          series: [
            {
              ...prevGraphOption.series,
              data: filteredSeries.map((el) => el.speed),
            },
          ],
        };
      }
    });
  };

  useEffect(() => {
    if (!_.isEmpty(graphOption) && graphTitle !== "") {
      const tempGraphOption = {
        ...graphOption,
        chart: {
          ...graphOption.chart,
          id: exportId.replaceAll(" ", ""),
        },
        title: {
          text: exportId + " | " + graphTitle,
          align: "center",
          margin: 20,
          offsetX: 5,
          offsetY: 0,
          floating: false,
          style: {
            fontSize: "16px",
            fontFamily: "global-font-regular",
            fontWeight: 300,
            color: "#031832",
          },
        },
      };
      setGraphOption(tempGraphOption);
    }
  }, [graphTitle]);

  return (
    <>
      <div className="speed-graph-filter-bar">
        <div className="speed-graph-title">
          <span>{graphTitle}</span>
        </div>
        <div className="speed-graph-legend">
          <div className="speed-graph-legend-item">
            <span
              className="speed-graph-legend-square"
              style={{ backgroundColor: "#FF0000" }}
            />
            <span className="speed-graph-legend-text">{`${t(
              "customModals.speedGraph.legendLimit"
            )}: ${vehicleDropdown.speedLimit} ${unit}`}</span>
          </div>
          <div className="speed-graph-legend-item">
            <span
              className="speed-graph-legend-square"
              style={{ backgroundColor: "#00AAFF" }}
            />
            <span className="speed-graph-legend-text">
              {t("customModals.speedGraph.legendLevel")}
            </span>
          </div>
        </div>
        <div className="speed-graph-dropdown">
          {textPeriod && totalPeriod && (
            <PeriodNav
              textPeriod={textPeriod}
              currentPeriod={currentPeriod}
              totalPeriod={totalPeriod}
              handlePrev={handlePrev}
              handleNext={handleNext}
              hidden={periodNavHidden}
            />
          )}
          {isLoading && <div className="speed-graph-loader" />}
          {vehicleList.length > 1 && (
            <Dropdown
              itemAttribute="label"
              size="small"
              onChange={(val) => {
                getVehicleSelected(val[0]);
                setVehicleDropdown(val[0]);
                setCurrentPeriod(1);
                setPeriodNavHidden(true);
                setPeriodDropdown(allPeriodList[0]);
              }}
              value={vehicleDropdown}
              options={vehicleList}
              isDisabled={isLoading}
            />
          )}
          {!isSameDay && (
            <Dropdown
              itemAttribute="label"
              size="small"
              onChange={(val) => {
                onChange(val[0].key);
                setPeriodDropdown(val[0]);
              }}
              value={periodDropdown}
              options={allPeriodList}
              isDisabled={isLoading}
            />
          )}
        </div>
      </div>
      <div className="speed-graph-container">
        {!_.isEmpty(graphOption) && (
          <ReactApexChart
            options={graphOption}
            series={graphOption.series}
            type="line"
            height={300}
            width={1154}
          />
        )}
      </div>
    </>
  );
};
