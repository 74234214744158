import { t } from "i18next";
import _ from "lodash";
import React, { useContext, useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { renderToStaticMarkup } from "react-dom/server";
import UserContext from "../../features/users/userContext";
import { Button } from "../Button/Button";
import { IconAdd } from "../Icon/Line/Add";
import { IconMinus } from "../Icon/Line/Minus";
import { IconRefresh } from "../Icon/Line/Refresh";
import "./RefuelReport.css";

export interface FuelLevelSeries {
  fuelLevel: number[];
  dates: string[];
}

interface LineData {
  fuelLevelSeries: FuelLevelSeries;
}

interface RefuelReportProps {
  title: string;
  lineData: LineData;
  columnData: number[];
}
interface TooltipCustomParams {
  series: number[][];
  seriesIndex: number;
  dataPointIndex: number;
  w: {
    config: {
      xaxis: {
        categories: string[];
      };
    };
  };
}

export const RefuelReport: React.FC<RefuelReportProps> = ({
  lineData,
  columnData,
  title,
}) => {
  const [options, setOptions] = useState<any>({});
  const [preferencesContext] = useContext(UserContext);
  const chartLanguage = require("apexcharts/src/locales/" +
    (preferencesContext.language ?? "en") +
    ".json");

  useEffect(() => {
    // Manage difference between first and last point date
    const firstDate = new Date(lineData.fuelLevelSeries?.dates[0]);
    const lastDate = new Date(
      lineData.fuelLevelSeries?.dates[
        lineData.fuelLevelSeries?.dates.length - 1
      ]
    );
    const differenceInDays = Math.abs(
      (lastDate.getTime() - firstDate.getTime()) / (1000 * 3600 * 24)
    );

    const tickAmount = Math.min(differenceInDays, 30);
    let optimalColumnWidthPercent = 1000 / (1 + 30 * Math.exp(-tickAmount / 3));

    const graphOptions = {
      title: {
        text: title,
        align: "left",
        style: {
          fontFamily: "global-font-regular",
          fontSize: "16px",
          fontWeight: "normal",
          color: "#031832",
        },
      },
      legend: {
        position: "top",
        offsetY: -32,
        offsetX: 243,
        fontSize: "12px",
        horizontalAlign: "left",
        fontFamily: "global-font-semibold",
        onItemHover: {
          highlightDataSeries: false,
        },
        onItemClick: {
          toggleDataSeries: false,
        },
        fontWeight: 600,
        labels: {
          colors: ["#687484"],
        },
        markers: {
          radius: 2,
        },
      },
      chart: {
        locales: [chartLanguage],
        defaultLocale: preferencesContext.language ?? "en",
        toolbar: {
          show: true,
          tools: {
            download: false,
            selection: false,
            pan: false,
            zoom: true,
            zoomin: renderToStaticMarkup(
              <div className="speed-report-tool-button">
                <Button label="" aspect="secondary" size="small" onlyIcon>
                  <IconAdd size={14} color="--global-colors-ink-ink" />
                </Button>
              </div>
            ),
            zoomout: renderToStaticMarkup(
              <div className="speed-report-tool-button">
                <Button label="" aspect="secondary" size="small" onlyIcon>
                  <IconMinus size={14} color="--global-colors-ink-ink" />
                </Button>
              </div>
            ),
            reset: renderToStaticMarkup(
              <div className="speed-report-tool-button">
                <Button label="" aspect="outline" size="small" onlyIcon>
                  <IconRefresh size={14} color="--global-colors-ink-ink" />
                </Button>
              </div>
            ),
          },
        },
      },
      states: {
        hover: {
          filter: {
            type: "none",
            value: 0,
          },
        },
        active: {
          filter: {
            type: "none",
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      colors: ["#00CFA2", "#00AAFF", "#00AAFF"],
      series: [
        {
          name: t("refuelReport.columnName"),
          type: "column",
          data: columnData,
        },
        {
          name: t("refuelReport.lineName"),
          type: "line",
          data: lineData.fuelLevelSeries?.fuelLevel,
        },
      ],
      stroke: {
        width: [0, 3, 3],
      },
      plotOptions: {
        bar: {
          columnWidth: optimalColumnWidthPercent + "%",
          borderRadius: 2,
        },
      },
      xaxis: {
        categories: lineData.fuelLevelSeries?.dates,
        tickAmount: tickAmount,
        type: "category",
        labels: {
          rotate: tickAmount > 15 ? -45 : 0,
          rotateAlways: false,
          style: {
            fontFamily: "global-font-regular",
            fontSize: "10px",
            fontWeight: "normal",
            colors: "#687484",
          },
          formatter: function (val: any) {
            const date = new Date(val);
            const options: any = {
              year: "numeric",
              month: "short",
              day: "numeric",
              hour: "2-digit",
              minute: "2-digit",
            };
            return date.toLocaleDateString(
              preferencesContext.language,
              options
            );
          },
        },
        crosshairs: {
          width: 0,
          opacity: 0,
          stroke: {
            color: "transparent",
          },
          fill: {
            color: "transparent",
          },
        },
      },
      yaxis: [
        {
          min: 0,
          seriesName: "Refuel",
          labels: {
            formatter: function (value: any) {
              return Math.round(value) + " lt";
            },
            style: {
              fontFamily: "global-font-regular",
              fontSize: "10px",
              fontWeight: "normal",
              colors: "#687484",
            },
          },
        },
      ],
      tooltip: {
        enabled: true,
        followCursor: true,
        intersect: true,
        shared: false,
        custom: ({
          series,
          seriesIndex,
          dataPointIndex,
        }: TooltipCustomParams): string => {
          let measure =
            series[seriesIndex][dataPointIndex] % 1 === 0
              ? series[seriesIndex][dataPointIndex]
              : series[seriesIndex][dataPointIndex].toFixed(2);
          return measure !== null
            ? '<div class="refuel-report-arrow-box">' +
                "<span>" +
                measure +
                " lt</span>" +
                "</div>"
            : "";
        },
      },
      markers: {
        size: 5,
        strokeWidth: 2,
        strokeColors: "#687484",
        colors: ["#ffffff", "#ffffff"],
        hover: {
          size: 5,
        },
      },
    };
    setOptions(graphOptions);
  }, [lineData, columnData]);

  return (
    <div className="refuel-report-graph">
      {(columnData.length === 0 || _.isEmpty(lineData.fuelLevelSeries)) && (
        <div className="refuel-report-graph-loader" />
      )}
      {!_.isEmpty(options) && (
        <ReactApexChart
          options={options}
          series={options.series}
          type="line"
          height={320}
        />
      )}
    </div>
  );
};
