import { t } from "i18next";
import React, { SetStateAction, useState } from "react";
import Form from "../Forms/Form";
import SearchField from "../Forms/SearchField";
import { IconDrivers } from "../Icon/Line/Drivers";
import { IconGroup } from "../Icon/Line/Group";
import { IconTruck } from "../Icon/Line/Truck";
import "./GroupWidget.css";

interface GroupWidgetProps {
  title: string;
  counter: number;
  type?: "drivers" | "vehicles" | "geofences";
  hasNoChildIcon?: boolean;
  children?: JSX.Element[];
  titleButton?: JSX.Element | null;
  filterCallback?: (e: any) => any;
}

export const GroupWidget: React.FC<GroupWidgetProps> = ({
  title,
  counter,
  children,
  type,
  hasNoChildIcon,
  titleButton,
  filterCallback,
}) => {
  const [searchValue, setSearchValue] = useState("");

  const handleIcon = (type: "drivers" | "vehicles" | "geofences") => {
    switch (type) {
      case "vehicles":
        return <IconTruck size={24} color="--global-colors-ink-lighter" />;
      case "drivers":
        return <IconDrivers size={24} color="--global-colors-ink-lighter" />;
      case "geofences":
        return <IconGroup size={24} color="--global-colors-ink-lighter" />;
    }
  };
  return (
    <>
      <div className="widget">
        <div className="widget-top-bar">
          <div className="widget-title">
            {title} ({counter})
          </div>
          {titleButton && (
            <div className="widget-title-button">{titleButton}</div>
          )}
        </div>
        <div className="widget-searchfield">
          <Form>
            <SearchField
              size="small"
              id={"search-" + type}
              value={searchValue}
              placeholder={
                type === "vehicles"
                  ? t("admin.groups.editGroups.searchfieldVehicle")
                  : t("admin.groups.editGroups.searchfield")
              }
              disabled={children && children?.length === 0 && counter === 0}
              onChange={(val: SetStateAction<string>) => {
                setSearchValue(val);
                filterCallback && filterCallback(val);
              }}
            />
          </Form>
        </div>
        <div className="widget-body">
          {children && children?.length > 0
            ? children
            : type &&
              hasNoChildIcon && (
                <div className="widget-no-child-container">
                  <div className="widget-no-child-icon">{handleIcon(type)}</div>
                  <div className="widget-no-child-label">{`${t(
                    "admin.groups.editGroups.noCardContentAdd"
                  )} ${t(`common.${type}`)} ${t(
                    "admin.groups.editGroups.noCardContentGroup"
                  )}`}</div>
                </div>
              )}
        </div>
      </div>
    </>
  );
};
