import React, { useState } from "react";
import { useDrop } from "react-dnd";
import { IconAdd } from "../Icon/Line/Add";
import { IconRepeat } from "../Icon/Line/Repeat";
import "./DropArea.css";
import { VehicleMap } from "./VehicleMap";

export const DropArea = () => {
  const [mapIsVisibile, setMapIsVisible] = useState(false);
  const handleClose = () => {
    setMapIsVisible(false);
    setDroppedComponent();
  };
  const [droppedComponent, setDroppedComponent] = useState();
  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: "box",
    drop: (item) => {
      setDroppedComponent(item);
      setMapIsVisible(true);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }));

  const isActive = canDrop && isOver;
  const className = isActive
    ? "mn-mw-drop-area mn-mw-drop-area--active"
    : "mn-mw-drop-area";

  return (
    <div ref={drop} role={"DropArea"} className={className}>
      {isActive && droppedComponent ? (
        <div className="mn-mw-drop-area__message mn-mw-drop-area__message--replace">
          <IconRepeat size={64} color="currentColor" />
          <p>Release to Drop</p>
        </div>
      ) : (
        <div className="mn-mw-drop-area__message">
          <IconAdd size={64} color="currentColor" />
          <p>Drop Here</p>
        </div>
      )}
      {droppedComponent && mapIsVisibile && (
        <VehicleMap
          vehicleId={droppedComponent.vehicleId}
          status={droppedComponent.status}
          location={droppedComponent.location}
          latitude={droppedComponent.latitude}
          longitude={droppedComponent.longitude}
          time={droppedComponent.time}
          onClose={handleClose}
        />
      )}
    </div>
  );
};
