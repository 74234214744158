import { Easing, Tween } from "@tweenjs/tween.js";
import _ from "lodash";
import { useContext, useEffect, useRef, useState } from "react";
import { IconLocator } from "../../ui/Icon/Solid/Locator";
import { Actions } from "../../ui/Map/Actions";
import { BaseMap, ComponentOnMap } from "../../ui/Map/BaseMap";
import { CenterLocation } from "../../ui/Map/CenterLocation";
import { MapType } from "../../ui/Map/MapType";
import { MarkerAsComponent } from "../../ui/Map/MarkerAsComponent";
import { Zoom } from "../../ui/Map/Zoom";
import { MapMarkerCustomIcon } from "../../ui/Marker/MapMarkerCustomIcon";
import { MapMarkerPositionDirection } from "../../ui/Marker/MapMarkerPositionDirection";
import { Preferences } from "../users/preference/preferencesSlice";
import UserContext from "../users/userContext";
import "./FleetControlMap.css";

interface TrackingDataObj {
  latitude: number;
  longitude: number;
  direction: number;
}

interface LiveTrackingMapProps {
  id: number;
  googleMapsApiKey: string;
  zoom: number;
  hasStreetView: boolean;
  latitude: number;
  longitude: number;
  trackingData?: TrackingDataObj;
}

declare global {
  interface Document {
    mozCancelFullScreen?: () => Promise<void>;
    msExitFullscreen?: () => Promise<void>;
    webkitExitFullscreen?: () => Promise<void>;
    mozFullScreenElement?: Element;
    msFullscreenElement?: Element;
    webkitFullscreenElement?: Element;
    onwebkitfullscreenchange?: any;
    onmsfullscreenchange?: any;
    onmozfullscreenchange?: any;
  }

  interface HTMLElement {
    msRequestFullScreen?: () => Promise<void>;
    mozRequestFullScreen?: () => Promise<void>;
    webkitRequestFullScreen?: () => Promise<void>;
  }
}

let trackingPolyline: any = null;
let currentPosition: any = null;
export const LiveTrackingMap: React.FC<LiveTrackingMapProps> = ({
  id,
  googleMapsApiKey,
  zoom,
  hasStreetView,
  latitude,
  longitude,
  trackingData,
}) => {
  let googleMap = window.google;
  const [map, setMap] = useState<any>();
  /**
   * If map icons are loaded or not
   */
  const [mapIconsLoaded, setMapIconsLoaded] = useState(false);

  const [liveTrackingMarker, setLiveTrackingMarker] = useState<any>();
  const [firstPointMarker, setFirstPointMarker] = useState<any>();
  const [lastPosition, setLastPosition] = useState<google.maps.LatLng>();
  const [lastHeading, setLastHeading] = useState(0);
  const [initialTrackingData, setInitialTrackingData] = useState<any>();
  const [componentsOnMap, setComponentsOnMap] = useState<ComponentOnMap[]>([]);

  const [preferencesContext]: [Preferences] = useContext(UserContext);
  const [isSatellite, setIsSatellite] = useState<boolean>(
    preferencesContext.satelliteOnMap
  );

  // This method is in charge to rempve polyline after map unmounted
  useEffect(() => {
    return function cleanup() {
      trackingPolyline.setMap(null);
      trackingPolyline = null;
      currentPosition = null;
    };
  }, []);

  //#region This methods are used for mapType button
  // this method is used to activate street map view from mapType button
  const mapClick = () => {
    map.setMapTypeId("roadmap");
    setIsSatellite(false);
  };

  // this method is used to activate satellite view from mapType button
  const satelliteClick = () => {
    map.setMapTypeId("hybrid");
    setIsSatellite(true);
  };
  //#endregion

  //#region this method is in charge to add components on map
  const lastTrackingPoint = useRef({ latitude: 0, longitude: 0 });
  useEffect(() => {
    const componentsOnMapArray: ComponentOnMap[] = [];
    if (googleMap && map) {
      // update map type on change from fleetControlMap
      preferencesContext.satelliteOnMap
        ? map.setMapTypeId("hybrid")
        : map.setMapTypeId("roadmap");

      map.setTilt(55);
      // set zoom to 17 (minimum value for 3d). don't use bigger values to avoid
      // unwanted map scrolling without smooth animation
      map.setOptions({ zoom: 17, minZoom: 9, maxZoom: 21 });
      if (document.getElementById("trackingMarker") as HTMLElement) {
        (
          document.getElementById("trackingMarker") as HTMLElement
        ).style.display = "flex";
      }

      componentsOnMapArray.push({
        selectorName: ".action-control",
        selectorPosition: googleMap.maps.ControlPosition.TOP_RIGHT,
        component: (
          <>
            <div
              className="action-control"
              style={{ zIndex: 1, position: "absolute", padding: "16px" }}
            >
              <Actions
                isFull={true}
                enabledTracking={true}
                handleFullScreen={() => fullsc()}
                handleScaleDown={() => fullsc()}
                searchDisabled={true}
                geofenceDisabled={true}
                settingsDisabled={true}
                geofencesProp={{ geofences: [], geofenceCategories: [] }}
              />
            </div>
          </>
        ),
      });
      componentsOnMapArray.push({
        selectorName: ".zoom-control-container",
        selectorPosition: googleMap.maps.ControlPosition.RIGHT_BOTTOM,
        component: (
          <div
            className="zoom-control-container"
            style={{
              zIndex: 1,
              position: "absolute",
              padding: "16px",
              marginBottom: "-21px",
            }}
          >
            <Zoom
              handleMinus={() => minusClick()}
              handlePlus={() => plusClick()}
            />
          </div>
        ),
      });
      componentsOnMapArray.push({
        selectorName: ".center-location",
        selectorPosition: googleMap.maps.ControlPosition.RIGHT_TOP,
        component: (
          <div
            className="center-location"
            style={{
              zIndex: 1,
              position: "absolute",
              padding: "0px 16px",
              display: "none",
            }}
          >
            <CenterLocation
              onClick={() => {
                map?.panTo({
                  lat: lastTrackingPoint.current?.latitude,
                  lng: lastTrackingPoint.current?.longitude,
                });
              }}
            />
          </div>
        ),
      });
      componentsOnMapArray.push({
        selectorName: ".maptype-control",
        selectorPosition: googleMap.maps.ControlPosition.BOTTOM_RIGHT,
        component: (
          <MapType
            handleMap={() => mapClick()}
            handleSatellite={() => satelliteClick()}
            activeSatellite={preferencesContext.satelliteOnMap}
          />
        ),
      });
    }
    setComponentsOnMap(componentsOnMapArray);
  }, [map]);
  //#endregion

  //#region this method is in charge to manage color of the polyline and the start marker
  useEffect(() => {
    if (trackingPolyline && map) {
      let newColor = isSatellite ? "#00AAFF" : "#0052BD";
      trackingPolyline.setOptions({
        strokeColor: newColor,
      });
      liveTrackingMarker.setComponent(
        <MapMarkerPositionDirection
          hasDirection={true}
          isSatellite={isSatellite}
        />
      );
    }
    if (firstPointMarker) {
      firstPointMarker.setComponent(
        <MapMarkerCustomIcon
          backgroundColor={isSatellite ? "#00AAFF" : "#0052BD"}
          iconSelected={
            <IconLocator size={16} color="--global-colors-ui-white" />
          }
        />
      );
    }
  }, [isSatellite]);

  //#region this method is in charge to manage live tracking map animation
  useEffect(() => {
    const animateMap = async () => {
      const googleTrac = window.google;
      let angle = 0;
      if (!_.isEmpty(trackingData)) {
        lastTrackingPoint.current.latitude = trackingData.latitude;
        lastTrackingPoint.current.longitude = trackingData.longitude;

        currentPosition = new googleTrac.maps.LatLng(
          trackingData.latitude,
          trackingData.longitude
        );

        // calculate angle (or set last position when current point is the first one)
        if (lastPosition) {
          angle = googleTrac.maps.geometry?.spherical.computeHeading(
            lastPosition,
            currentPosition
          );
          // correction for points with the same gps coords
          if (angle === 0) {
            let distance =
              googleTrac.maps.geometry?.spherical.computeDistanceBetween(
                lastPosition,
                currentPosition
              );
            if (distance === 0) angle = lastHeading;
          }
          // correction for bad verse rotation
          if (angle * lastHeading < 0) {
            // latest and current angle have different sign
            if (Math.abs(angle - lastHeading) > 180) {
              if (angle < 0) angle = angle + 360;
              else angle = angle - 360;
            }
          }
        } else {
          setLastPosition(currentPosition);
        }

        // configure and start camera animation
        let lat =
          lastPosition !== undefined
            ? lastPosition.lat()
            : currentPosition.lat();
        let lng =
          lastPosition !== undefined
            ? lastPosition.lng()
            : currentPosition.lng();

        const cameraOptions = {
          heading: lastHeading,
          center: {
            lat: lat,
            lng: lng,
          },
        };
        const cameraTween = new Tween(cameraOptions)
          .to(
            {
              heading: angle,
              center: {
                lat: currentPosition.lat,
                lng: currentPosition.lng,
              },
            },
            1600
          ) // Move to destination in 1.6 second.
          .easing(Easing.Quadratic.Out) // Use an easing function to make the animation smooth.
          .onUpdate(() => {
            map?.moveCamera(cameraOptions);
          })
          .start();
        const animateCamera = (time: any) => {
          requestAnimationFrame(animateCamera);
          cameraTween.update(time);
        };
        requestAnimationFrame(animateCamera);

        // draw the latest path
        setTimeout(() => {
          if (liveTrackingMarker === undefined) {
            setLiveTrackingMarker(
              MarkerAsComponent({
                id: "trackingMarker",
                googleMap: googleMap,
                lat: trackingData.latitude,
                lng: trackingData.longitude,
                map: map,
                component: (
                  <MapMarkerPositionDirection
                    hasDirection={true}
                    isSatellite={isSatellite}
                  />
                ),
              })
            );
            setInitialTrackingData(trackingData);
          } else {
            liveTrackingMarker.setPosition(
              trackingData.latitude,
              trackingData.longitude
            );
            if (trackingData && firstPointMarker == undefined) {
              setFirstPointMarker(
                MarkerAsComponent({
                  id: "firstPointMarker",
                  googleMap: googleMap,
                  lat: initialTrackingData.latitude,
                  lng: initialTrackingData.longitude,
                  map: map,
                  component: (
                    <MapMarkerCustomIcon
                      backgroundColor={isSatellite ? "#00AAFF" : "#0052BD"}
                      iconSelected={
                        <IconLocator
                          size={16}
                          color="--global-colors-ui-white"
                        />
                      }
                    />
                  ),
                })
              );
            }
          }

          // set map center (smooth animation when possible(cached map tiles))
          map?.panTo(currentPosition);
          // draw polyline
          if (!trackingPolyline) {
            trackingPolyline = new googleMap.maps.Polyline({
              strokeColor: isSatellite ? "#00AAFF" : "#0052BD",
              strokeOpacity: 1.0,
              strokeWeight: 8,
            });
          }
          const path = trackingPolyline.getPath();
          path.push(currentPosition);
          trackingPolyline.setMap(map);
        }, 1650);

        // save current values for next track data
        setLastPosition(currentPosition);
        setLastHeading(angle);
        console.log("**** lastHeading: " + lastHeading + " heading: " + angle);
      }
    };

    animateMap().catch(console.error);
  }, [trackingData, map]);
  //#endregion

  // this method is in charge to disable components on map
  function disableComponent(component: string) {
    switch (component) {
      case "gm-svpc":
        document
          .getElementsByClassName("gm-svpc")[0]
          ?.classList.add("disabled");
        break;
      case "center-location":
        (
          document.getElementsByClassName("center-location")[0] as HTMLElement
        ).style.display = "";
        break;
    }
  }

  // Disable map icons
  if (mapIconsLoaded) {
    disableComponent("gm-svpc");
    disableComponent("center-location");
  }

  //#region This methods are used by action component for fullScreen button
  function isFullscreen(element: any) {
    return (
      (document.fullscreenElement ||
        document.webkitFullscreenElement ||
        document.mozFullScreenElement ||
        document.msFullscreenElement) === element
    );
  }

  function requestFullscreen(element: any) {
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.webkitRequestFullScreen) {
      element.webkitRequestFullScreen();
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen();
    } else if (element.msRequestFullScreen) {
      element.msRequestFullScreen();
    }
  }

  function exitFullscreen() {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
  }

  const fullsc = () => {
    const elementToSendFullscreen = map?.getDiv().firstChild;
    if (isFullscreen(elementToSendFullscreen)) {
      exitFullscreen();
    } else {
      requestFullscreen(elementToSendFullscreen);
    }
  };
  //#endregion

  //#region This methods are used for zoom button
  // This method is used by zoom + button for zooming map
  const plusClick = () => {
    if (map) {
      const zoomTemp = map.getZoom();
      if (zoomTemp) {
        map.setZoom(zoomTemp + 1);
      }
    }
  };

  // This method is used by zoom - button for zooming map
  const minusClick = () => {
    if (map) {
      const zoomTemp = map.getZoom();
      if (zoomTemp) {
        map.setZoom(zoomTemp - 1);
      }
    }
  };
  //#endregion

  return (
    <>
      <BaseMap
        id={id}
        googleMapsApiKey={googleMapsApiKey}
        zoom={zoom}
        hasStreetView={hasStreetView}
        latitude={latitude}
        longitude={longitude}
        getMap={setMap}
        getMapIconsLoaded={setMapIconsLoaded}
        useDefaultPosition={!liveTrackingMarker}
      >
        {componentsOnMap}
      </BaseMap>
    </>
  );
};
