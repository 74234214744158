import {
  PayloadAction,
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import _ from "lodash";
import { normalize } from "normalizr";
import { RootState } from "../../../app/store";
import { Config } from "../../../config/Config";
import { GTFleetSuccessCodes } from "../../../config/GTFleetSuccessCodes";
import { GTFleetErrorCodes } from "../../../config/GTfleetErrorCodes";
import { getErrorCodes } from "../../../utils/Utils";
import { publicRoutesSchema } from "./publicRouteNormalization";
import PublicRoutesRepository from "./publicRoutesRepository";

export type PublicRouteView = {
  id: number;
  name: string;
  numberOfTrips: number;
  color: string;
  vehicleAlias: string;
  vehiclePlate: string;
  routeLength: number;
  tripDuration: number;
  stops: PublicStopView[];
  points: PublicRoutePoint[];
};

export type PublicStopView = {
  id: number;
  name: string;
  routeStopId: number;
  latitude: number;
  longitude: number;
  address: string;
  terminus: boolean;
  geofenceId: number;
};

export type PublicRoutePoint = {
  id: number;
  latitude: number;
  longitude: number;
};

export const getFilteredPublicRoutesAsync = createAsyncThunk(
  "publicRoutes/getFilteredPublicRoutes",
  async (data: { queryParams?: string }, { dispatch, rejectWithValue }) => {
    try {
      const publicRoutesRepository = new PublicRoutesRepository();
      const response = await publicRoutesRepository.getPublicRoutes(
        data.queryParams
      );
      const filteredRoutes = _.get(
        response,
        Config.PUBLIC_ROUTE_VIEW_RESPONSE_PATH
      );
      const totalPages = _.get(
        response,
        Config.PUBLIC_ROUTE_VIEW_TOTAL_PAGES_RESPONSE_PATH
      );
      const totalElements = _.get(
        response,
        Config.PUBLIC_ROUTE_VIEW_TOTAL_ELEMENTS_RESPONSE_PATH
      );
      if (totalPages) {
        dispatch(setNumberOfPages(totalPages));
      }
      if (totalElements) {
        dispatch(setNumberOfElements(totalElements));
      }
      const normalizedData = normalize(
        filteredRoutes ?? [],
        publicRoutesSchema
      );
      return normalizedData.entities;
    } catch (error: any) {
      if (!error.response) throw error;
      return rejectWithValue(error.response.status);
    }
  }
);

const publicRoutesAdapter = createEntityAdapter<PublicRouteView>({
  selectId: (publicRouteView) => publicRouteView.id,
});

export const publicRouteSlice = createSlice({
  name: "publicRoutes",
  initialState: publicRoutesAdapter.getInitialState({
    status: "idle",
    reasonCode: "",
    totalPages: 0,
    totalElements: 0,
  }),
  reducers: {
    publicRoutesEmptyState: (state) => {
      publicRoutesAdapter.setAll(state, []);
      state.reasonCode = "";
      state.status = "idle";
    },
    setNumberOfPages: (state, action: PayloadAction<number>) => {
      state.totalPages = action.payload;
    },
    setNumberOfElements: (state, action: PayloadAction<number>) => {
      state.totalElements = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      //#region Entity Reducers
      //#endregion
      .addCase(
        getFilteredPublicRoutesAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          setFilteredData(state, action);
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.GET;
        }
      )
      .addCase(
        getFilteredPublicRoutesAsync.rejected,
        (state: any, action: PayloadAction<any>) => {
          state.status = "failed";
          handleFailedData(state, action);
        }
      )
      .addCase(getFilteredPublicRoutesAsync.pending, (state: any) => {
        state.status = "loading";
      });
  },
});

function handleFailedData(state: any, action: PayloadAction<any>) {
  let castAction: any = action;
  if (castAction?.payload?.data?.message) {
    state.reasonCode = getErrorCodes(castAction?.payload?.data?.message);
  }
  if (castAction?.payload?.status && castAction?.payload?.status === 500) {
    state.reasonCode = GTFleetErrorCodes.INTERNAL_SERVER_ERROR;
  }
}

function setFilteredData(state: any, action: PayloadAction<any>) {
  action.payload.publicRoute
    ? publicRoutesAdapter.setAll(state, action.payload.publicRoute)
    : publicRoutesAdapter.setAll(state, []);
}

export const publicRoutesSelectors =
  publicRoutesAdapter.getSelectors<RootState>((state) => state.publicRoutes);

export const selectPublicRoutesSliceStatus = (state: any) =>
  state.publicRoutes.status;

export const selectPublicRoutesSliceReasonCode = (state: any) =>
  state.publicRoutes.reasonCode;

export const selectPublicRoutesSlicePage = (state: any) =>
  state.publicRoutes.totalPages;

export const selectPublicRoutesSliceTotalElements = (state: any) =>
  state.publicRoutes.totalElements;

export const { publicRoutesEmptyState, setNumberOfPages, setNumberOfElements } =
  publicRouteSlice.actions;

export default publicRouteSlice.reducer;
