import { ModalBody } from "../../ui/Modal/ModalBody";
import { ModalFooter } from "../../ui/Modal/ModalFooter";
import { ModalWrapper } from "../../ui/Modal/ModalWrapper";
import { Avatar } from "../../ui/Avatar/Avatar";
import "./UploadImage.css";
import { ListAvatar } from "../../ui/Avatar/ListAvatar";
import { useEffect, useState } from "react";
import { DropFile } from "../../ui/MultiView/DropFile";
import { Alert } from "../../ui/Alert/Alert";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
interface UploadImageProps {
  openModal: boolean;
  setOpenModal: (e: any) => any;
  setUploadNameFile: (e: any) => any;
  imageAvatar: string;
}

interface AvatarProps {
  image: string;
  id: string;
}

export const UploadImage = ({
  openModal,
  setOpenModal,
  setUploadNameFile,
  imageAvatar,
}: UploadImageProps) => {
  const [image, setImage] = useState<any>("");
  const [avatar, setAvatar] = useState<AvatarProps | null>(null);
  const [viewError, setViewError] = useState("");
  const [getImage, setGetImage] = useState(false);
  const [nameFile, setNameFile] = useState("");
  const media = window.innerHeight < 850;
  const { t } = useTranslation();

  useEffect(() => {
    const returnNumber = (strg: string) => {
      const nmr = strg.match(/(\d+)/);
      if (nmr) {
        return nmr[0];
      } else {
        return "";
      }
    };

    if (openModal) {
      if ((!avatar && imageAvatar) || !avatar?.image.includes(imageAvatar)) {
        imageAvatar.includes("Avatar")
          ? setAvatar({
              id: returnNumber(imageAvatar),
              image:
                process.env.REACT_APP_BUCKET_URL + "Images/SVG/" + imageAvatar,
            })
          : setImage(
              process.env.REACT_APP_BUCKET_URL + "Images/" + imageAvatar
            );
      }
    }
  }, [imageAvatar, openModal]);

  useEffect(() => {
    typeof image === "string" && setViewError("");

    if (nameFile) {
      if (image instanceof File) {
        setUploadNameFile(nameFile);
      } else if (!!avatar) {
        setUploadNameFile("SVG/" + nameFile + avatar.id + ".svg");
      }
      setOpenModal(false);
    }
  }, [getImage, image, nameFile, imageAvatar]);

  useEffect(() => {
    if (nameFile) {
      setNameFile("");
    }
  }, [nameFile]);

  const cancelModal = () => {
    setImage("");
    setAvatar(null);
  };

  return (
    <div className="upload-image">
      <div
        className={
          (media ? "marginTop" : "") + (getImage ? " disableCloseButton" : "")
        }
      >
        <ModalWrapper
          open={openModal}
          closeAction={() => {
            setOpenModal(false);
            cancelModal();
          }}
        >
          <div className="customize-columns-body">
            <ModalBody
              title={t("customModals.uploadImage.title")}
              desc={t("customModals.uploadImage.desc")}
            >
              <div className="columns-available image-import">
                <div>
                  <Avatar
                    image={image}
                    getImage={getImage}
                    avatar={avatar}
                    setNameFile={(name, err) => {
                      getImage && setGetImage(!getImage);
                      if (name) {
                        setNameFile(name);
                      } else {
                        const messageError = t(
                          "customModals.uploadImage." + err
                        );
                        toast.error(messageError, {
                          position: "bottom-right",
                          autoClose: 4000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                        });
                      }
                    }}
                  />
                </div>
                <div>
                  <ListAvatar
                    setAvatar={(id) => {
                      setAvatar(id);
                      setImage("");
                      setNameFile("");
                    }}
                  />
                </div>
              </div>
              {viewError && (
                <div className="columns-available image-import dropfileerror">
                  <Alert
                    message={t("customModals.uploadImage." + viewError)}
                    status={"error"}
                    onClose={() => {
                      setViewError("");
                    }}
                    hasCloseIcon={true}
                  />
                </div>
              )}
              <div
                className={
                  "columns-available image-import dropfilecontainer" +
                  (viewError ? " showError" : "")
                }
              >
                <DropFile
                  droppedFiles={(droppedFiles) => {
                    if (!droppedFiles.includes("error")) {
                      setImage(droppedFiles[0]);
                      setViewError("");
                      setAvatar(null);
                    } else {
                      setViewError(droppedFiles);
                    }
                    setNameFile("");
                  }}
                />
              </div>
            </ModalBody>
            <ModalFooter
              primaryLabel={t("common.save")}
              primaryAction={() => {
                setGetImage(!getImage);
              }}
              disablePrimaryButton={
                typeof image === "string" && image.includes("http")
                  ? true
                  : false
              }
              loadingPrimaryButton={getImage}
              secondaryLabel={t("common.cancel")}
              secondaryAction={() => {
                setOpenModal(false);
                cancelModal();
              }}
              disableSecondaryButton={getImage}
            />
          </div>
        </ModalWrapper>
      </div>
    </div>
  );
};
