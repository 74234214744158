import PropTypes from "prop-types";
import "./SignalIndicator.css";

export const SignalIndicator = ({ value, size, isBar }) => {
    let colorFirst = "var(--global-colors-ui-secondary-light)";
    let colorSecond = "var(--global-colors-ui-secondary-light)";
    let colorThird = "var(--global-colors-ui-secondary-light)";

    if (value === "optimal") {
        colorFirst = "var(--global-colors-ui-primary)";
        colorSecond = "var(--global-colors-ui-primary)";
        colorThird = "var(--global-colors-ui-primary)";
    } else if (value === "fair") {
        colorSecond = "var(--global-colors-ui-primary)";
        colorThird = "var(--global-colors-ui-primary)";
    } else if (value === "poor") {
        colorSecond = "var(--global-colors-ui-primary)";
    }

    if (isBar) {
        return (
            <div style={{ width: `${size}px`, height: `${size}px` }}>
                <div className="signal-indicator-bar" style={{ height: `${size * 0.4}px`, width: `${size * 0.2}px`, marginRight: `${size / 5}px`, backgroundColor: colorThird }} />
                <div className="signal-indicator-bar" style={{ height: `${size * 0.7}px`, width: `${size * 0.2}px`, marginRight: `${size / 5}px`, backgroundColor: colorSecond }} />
                <div className="signal-indicator-bar" style={{ height: `${size}px`, width: `${size * 0.2}px`, backgroundColor: colorFirst }} />
            </div>
        );
    } else {
        return (
            <div style={{ width: `${size}px`, height: `${size}px` }}>
                <div className="signal-indicator-circle" style={{ width: `${size}px`, height: `${size}px`, borderWidth: `${size * 0.2}px ${size * 0.2}px 0 0`, borderColor: colorFirst }} />
                <div className="signal-indicator-circle" style={{ width: `${size * 0.6}px`, height: `${size * 0.6}px`, borderWidth: `${size * 0.2}px ${size * 0.2}px 0 0`, borderColor: colorSecond }} />
                <div className="signal-indicator-circle" style={{ width: `${size * 0.2}px`, height: `${size * 0.2}px`, borderWidth: `${size * 0.2}px ${size * 0.2}px 0 0`, borderColor: colorThird }} />
            </div>
        );
    }
};

SignalIndicator.propTypes = {
    value: PropTypes.oneOf(["optimal", "fair", "poor", "absent"]).isRequired,
    size: PropTypes.number.isRequired,
    isBar: PropTypes.bool,
};

SignalIndicator.defaultProps = {
    value: "optimal",
};