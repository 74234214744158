import React from "react";
import PropTypes from "prop-types";
import { Button } from "../Button/Button";
import { IconArrowLeft } from "../Icon/Line/ArrowLeft";
import { IconArrowRight } from "../Icon/Line/ArrowRight";
import "./PeriodNav.css";

export const PeriodNav = ({
    textPeriod,
    currentPeriod,
    totalPeriod,
    handlePrev,
    handleNext,
    hidden
}) => {
    return (
        <div className="mn-periodnav" style={{ display: hidden ? 'none' : 'flex' }}>
            <div className="mn-periodnav-container">
                <span className="mn-periodnav__nav-index">
                    {currentPeriod} / {totalPeriod} {textPeriod}
                </span>
                <span>
                    <Button
                        aspect="outline"
                        onClick={() => {
                            handlePrev();
                        }}
                        onlyIcon
                        size="small"
                        disabled={currentPeriod === 1 ? true : false}
                    >
                        <IconArrowLeft size={14} color="--global-colors-ink-light" />
                    </Button>
                    <Button
                        aspect="outline"
                        onClick={() => {
                            handleNext();
                        }}
                        onlyIcon
                        size="small"
                        disabled={currentPeriod === totalPeriod ? true : false}
                    >
                        <IconArrowRight size={14} color="--global-colors-ink-light" />
                    </Button>
                </span>
            </div>
        </div>
    );
};

PeriodNav.propTypes = {
    textPeriod: PropTypes.string,
    currentPeriod: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    totalPeriod: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    handlePrev: PropTypes.func,
    handleNext: PropTypes.func,
};