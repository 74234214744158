import { schema } from "normalizr";
import { driverSchema } from "../driver/driverNormalization";
import { vehicleSchema } from "../vehicle/vehicleNormalization";

export const routeHistorySchema: schema.Entity<any> = new schema.Entity(
  "routeHistory",
  {
    driverDetails: driverSchema,
    vehicleDetails: vehicleSchema,
  }
);

export const routesHistorySummarySchema: schema.Entity<any> = new schema.Entity(
  "routesHistorySummary"
);

export const routesHistorySchema = new schema.Array(routeHistorySchema);
