import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ColorPicker } from "../../Forms/ColorPicker";
import Form from "../../Forms/Form";
import TextField from "../../Forms/TextField";
import { LabelText } from "../../LabelText/LabelText";
import { ModalBody } from "../ModalBody";
import { ModalFooter } from "../ModalFooter";
import { ModalWrapper } from "../ModalWrapper";
import "./NewCategoryModal.css";

interface NewCategoryModalProps {
  type: string;
  open: boolean;
  primaryAction: (e?: any) => any;
  onClose: (e?: any) => any;
}

export const NewCategoryModal: React.FC<NewCategoryModalProps> = ({
  type,
  open = false,
  primaryAction,
  onClose,
}) => {
  const colors = [
    ["#000000", "#493802", "#7A3CA1", "#B089C6", "#6667B9"],
    ["#0265B3", "#66A3D2", "#007F4D", "#FBCD03", "#FEF305"],
    ["#F57631", "#F7AB82", "#FF6766", "#FFCCCB", "#A8272B"],
  ];
  const [category, setCategory] = useState<{ name: string; color: string }>({
    name: "",
    color: "",
  });
  const [errorList, setErrorList] = useState([]);
  const { t } = useTranslation();

  return (
    <div className="new-category-container">
      <ModalWrapper open={open} closeAction={onClose}>
        <div className="new-category">
          <ModalBody
            title={`${t("customModals.category.new.title")}`}
            desc={`${t("customModals.category.new.desc")} ${type}`}
          >
            <Form>
              <div className="left-forms">
                <LabelText
                  text={`${t("customModals.category.categoryName")}`}
                />
              </div>
              <div className="right-forms">
                <TextField
                  id="Name"
                  placeholder={`${t(
                    "customModals.category.categoryNamePlaceholder"
                  )}`}
                  validate="alphaNumeric"
                  errorList={setErrorList}
                  events={{
                    onChange: (data) =>
                      setCategory({ ...category, name: data.value }),
                  }}
                  value={category.name}
                />
              </div>
            </Form>
            <Form>
              <div className="left-forms">
                <LabelText text={`${t("customModals.category.color")}`} />
              </div>
              <div className="right-forms">
                <ColorPicker
                  colors={colors}
                  onClick={(data) => {
                    setCategory({ ...category, color: data });
                  }}
                  defaultColor=""
                />
              </div>
            </Form>
          </ModalBody>
          <ModalFooter
            primaryLabel={`${t("customModals.category.primaryButton")}`}
            primaryAction={() => {
              primaryAction(category);
            }}
            disablePrimaryButton={
              category.name === "" ||
              category.color === "" ||
              errorList.length !== 0
            }
            secondaryLabel={`${t("customModals.category.secondaryButton")}`}
            secondaryAction={onClose}
          />
        </div>
      </ModalWrapper>
    </div>
  );
};
