import React from "react";
import PropTypes from "prop-types";
import "./LabelText.css";
export const LabelText = ({ label, text }) => {
  return (
    <div className="mn-label-text">
      <div className="mn-label-text__label">{label}</div>
      <div className="mn-label-text__text">{text}</div>
    </div>
  );
};

LabelText.propTypes = {
  label: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
};
