import { t } from "i18next";
import _ from "lodash";
import { NavigateFunction } from "react-router-dom";
import { DropdownButton, ListItem } from "../../ui/Button/DropdownButton";
import {
  AbstractTableBuilder,
  Row,
} from "../../ui/Table/utils/AbstractTableBuilder";
import { UserInfo } from "../users/usersInfoSlice";
import { FleetView } from "./fleetViewsSlice";

export class AdminFleetTableBuilder extends AbstractTableBuilder {
  rowsBuilder = (
    columns: { label: string; field: string; sort: boolean }[] | undefined,
    entities: {
      fleetView?: FleetView;
      creators?: UserInfo[];
    },
    navigate: NavigateFunction,
    writePermission: boolean = false
  ) => {
    let row: Row = { render: {} as Row };

    let fleetView: FleetView = entities.fleetView ?? ({} as FleetView);
    let creators: UserInfo[] = entities.creators ?? ([] as UserInfo[]);

    columns &&
      columns.forEach((column) => {
        switch (column.field) {
          case "name":
            row.name = fleetView.name ?? t("common.na");
            row.render.name = fleetView.name ?? t("common.na");
            break;
          case "creator":
            const creator = fleetView.user
              ? creators.find((x) => x.id === fleetView.user) ??
                ({} as UserInfo)
              : ({} as UserInfo);
            row.creator = !_.isEmpty(creator)
              ? creator.firstName + " " + creator.secondName
              : t("common.na");
            row.render.creator = !_.isEmpty(creator)
              ? creator.firstName + " " + creator.secondName
              : t("common.na");
            break;
          case "vehicles":
            row.vehicles = fleetView.vehiclesSize ?? t("common.na");
            row.render.vehicles = fleetView.vehiclesSize ?? t("common.na");
            break;
          case "drivers":
            row.drivers = fleetView.driversSize ?? t("common.na");
            row.render.drivers = fleetView.driversSize ?? t("common.na");
            break;
          case "geofences":
            row.geofences = fleetView.geofencesSize ?? t("common.na");
            row.render.geofences = fleetView.geofencesSize ?? t("common.na");
            break;
          default:
            break;
        }
      });
    const fleetDropDownList: ListItem[] = [
      {
        id: 0,
        title: t("common.edit"),
        disabled: fleetView?.name === "Default",
        onClick: () => navigate("/admin/fleets/edit-fleet/" + fleetView.id),
      },
      {
        id: 1,
        title: t("common.delete"),
        disabled: fleetView?.name === "Default",
        onClick: () => {
          navigate("/admin/fleets/delete/" + fleetView.id);
        },
      },
    ];

    if (!writePermission) {
      fleetDropDownList.splice(0, 1);
    }
    row.render.action = (
      <DropdownButton aspect="ghost" size="small" list={fleetDropDownList} />
    );
    return row;
  };
}
