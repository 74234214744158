import { t } from "i18next";
import { TypeOptions, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IconAlert } from "../ui/Icon/Line/Alert";
import { IconExternalLinkWithText } from "../ui/Icon/Line/ExternalLinkWithNotificationText";
import { IconSuccess } from "../ui/Icon/Solid/Success";

/**
 * This method is used to check which Toast Notification
 * to choose based on the status.
 */

interface ToastNotificationProps {
  status: TypeOptions;
  description: string;
  toastId?: string;
  title?: string;
  onClose?: (e: any) => any;
  redirectToDashboard?: boolean;
}

export const ToastNotification = ({
  status,
  description,
  toastId,
  title,
  onClose,
  redirectToDashboard,
}: ToastNotificationProps) => {
  const getTitleFromNotification = (text: string): string => {
    const httpsIndex = text.indexOf("https");
    const title = httpsIndex !== -1 ? text.slice(0, httpsIndex) : text;
    return title;
  };
  const options = {
    toastId: toastId ?? undefined,
    position: toast.POSITION.BOTTOM_RIGHT,
    closeButton: true,
    style: { fontSize: "12px" },
    autoClose: 4000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    type: status,
    icon:
      status === "success" ? (
        <IconSuccess color="--global-colors-ui-white" size={16} />
      ) : status === "error" ? (
        <IconAlert color="--global-colors-ui-white" size={16} />
      ) : (
        ""
      ),
    onClose: onClose,
  };

  toast(
    <>
      {title && (
        <div className="toast-title">
          <span>{title}</span>
        </div>
      )}
      {redirectToDashboard ? (
        <IconExternalLinkWithText
          className=""
          size={14}
          text={
            getTitleFromNotification(description) +
            t("common.clickOnNotification")
          }
        />
      ) : (
        <div className="toast-description">
          <span>{description}</span>
        </div>
      )}
    </>,
    options
  );
};
