import { t } from "i18next";
import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { store } from "../../app/store";
import { GTFleetErrorCodes } from "../../config/GTfleetErrorCodes";
import PageContent from "../../layout/PageContent";
import PageFilters from "../../layout/PageFilters";
import { PageCounter } from "../../ui/Table/PageCounter";
import { Table } from "../../ui/Table/Table";
import { ToastNotification } from "../../utils/ToastNotification";
import { getQueryString } from "../../utils/Utils";
import { FleetView, fleetViewsSelectors } from "../fleet/fleetViewsSlice";
import { Preferences } from "../users/preference/preferencesSlice";
import {
  Preset,
  getPresetsAsync,
  presetsSelectors,
  selectpresetsSliceStatus,
} from "../users/preset/presetsSlice";
import { UserPermissions } from "../users/privilege/privilegesSlice";
import UserContext from "../users/userContext";
import { Vehicle, vehiclesSelectors } from "../vehicle/vehiclesSlice";
import {
  VehicleStatus,
  vehiclesStatusSelectors,
} from "../vehicle/vehiclesStatusSlice";
import "./DashboardDrivers.css";
import { DashboardDriversFilterBar } from "./DashboardDriversFilterBar";
import { DashboardDriversTableBuilder } from "./DashboardDriversTableBuilder";
import {
  Driver,
  driversEmptyState,
  driversSelectors,
  selectDriversSliceReasonCode,
  selectDriversSliceStatus,
} from "./driversSlice";
import {
  DriverStatus,
  driversStatusEmptyState,
  driversStatusSelectors,
  getFilteredDriversStatusAndDetailsPaginationAsync,
  selectDriverStatusSlicePage,
  selectDriverStatusSliceStatus,
  selectDriverStatusSliceTotalElements,
} from "./driversStatusSlice";

interface DashboardDriversProps {
  permissions: UserPermissions;
}

const context = "driverDashboard";

interface TableData {
  columns: { label: string; field: string; sort: boolean }[] | undefined;
  rows: { [key: string]: any };
}

const tableData: TableData = {} as TableData;

/**
 * Table component of the Drivers
 * @returns
 */
export const DashboardDrivers: React.FC<DashboardDriversProps> = ({
  permissions,
}) => {
  const navigate = useNavigate();
  const tableBuilderRef = useRef<DashboardDriversTableBuilder>(
    new DashboardDriversTableBuilder()
  );

  const [preferencesContext]: [Preferences] = useContext(UserContext);

  let tableBuilder = tableBuilderRef.current;

  const [queryParamsFromFilterBar, setQueryParamsFromFilterBar] =
    useState<string>("");
  const [resetPage, setResetPage] = useState<boolean>(false);

  let drivers: Driver[] = useAppSelector(driversSelectors.selectAll);
  let presets: Preset[] = useAppSelector(presetsSelectors.selectAll);

  const driverSliceStatus = useAppSelector(selectDriversSliceStatus);
  const driverSliceReasonCode = useAppSelector(selectDriversSliceReasonCode);
  const presetsSliceStatus = useAppSelector(selectpresetsSliceStatus);

  const driversSlicePages = useAppSelector(selectDriverStatusSlicePage);
  const driversSliceTotalElement = useAppSelector(
    selectDriverStatusSliceTotalElements
  );

  // loader for PageCount
  const driverStatusSliceStatus = useAppSelector(selectDriverStatusSliceStatus);
  const isLoading = driverStatusSliceStatus === "loading";

  const isDriverIdle = driverStatusSliceStatus === "idle";
  const [queryParamsChanged, setQueryParamsChanged] = useState<boolean>(false);

  useEffect(() => {
    if (!!queryParamsFromFilterBar) {
      setQueryParamsChanged(true);
    }
  }, [queryParamsFromFilterBar]);

  useEffect(() => {
    if (queryParamsChanged && isDriverIdle) {
      setQueryParamsChanged(false);
      setResetPage(true);
    } else {
      setResetPage(false);
    }
  }, [isDriverIdle, queryParamsChanged]);

  // Chiamate API
  useEffect(() => {
    document.title = t("navigation.userMenu.drivers");
    store.dispatch(getPresetsAsync(getQueryString({ context: context })));
    return () => {
      store.dispatch(driversEmptyState());
      store.dispatch(driversStatusEmptyState());
    };
  }, []);

  useEffect(() => {
    if (
      driverSliceStatus === "failed" &&
      driverSliceReasonCode === GTFleetErrorCodes.DRIVER_NOT_FOUND
    ) {
      console.error(t("common.driverNotFoundError"));
      ToastNotification({
        toastId: "driverNotFoundError",
        status: "error",
        description: t("common.driverNotFoundError"),
      });
    } else if (driverSliceStatus === "failed" && driverSliceReasonCode === "") {
      ToastNotification({
        toastId: "networkError",
        status: "error",
        description: t("common.networkError"),
      });
    }
  }, [driverSliceStatus, driverSliceReasonCode]);

  if (presetsSliceStatus === "idle" && presets.length > 0) {
    let preset =
      presets.find((element) => element.name === undefined) ??
      presets.find((element) => element.lastSelected === true);
    if (!preset) {
      preset = presets.find(
        (element) => element.name === "Default" && element.context === context
      );
    }

    tableData.columns = tableBuilder.setColumns(
      preset?.columns
        ? preset?.columns.map((element) => {
            return {
              name: element,
              sort: element === "driver" ? true : false,
            };
          })
        : []
    );

    tableData.rows =
      driverSliceStatus === "idle" &&
      tableData.columns &&
      tableData?.columns?.length > 0
        ? drivers.map((driver: Driver) => {
            let vehicle: Vehicle =
              vehiclesSelectors.selectById(store.getState(), driver.vehicle) ??
              ({} as Vehicle);
            let driverStatus: DriverStatus =
              driversStatusSelectors.selectById(store.getState(), driver.id) ??
              ({} as DriverStatus);
            let vehicleStatus: VehicleStatus =
              vehiclesStatusSelectors.selectById(
                store.getState(),
                vehicle.id
              ) ?? ({} as VehicleStatus);
            let fleetView: FleetView =
              fleetViewsSelectors.selectById(store.getState(), driver.fleet) ??
              ({} as FleetView);
            return tableBuilder.rowsBuilder(
              tableData.columns,
              context,
              { vehicle, driver, driverStatus, vehicleStatus, fleetView },
              preferencesContext,
              navigate
            );
          })
        : tableData.rows;
  }

  return (
    <>
      <PageFilters>
        <div className="col col-16">
          <DashboardDriversFilterBar
            presets={presets ?? []}
            callback={setQueryParamsFromFilterBar}
          />
        </div>
      </PageFilters>
      {tableData.rows?.length > 0 && (
        <>
          <PageContent>
            <div className="driver-table">
              <Table data={tableData}>
                <Table.Head hasTableSpace={true} />
                <Table.Body />
              </Table>
            </div>
            <div className="drivers-pagination">
              <PageCounter
                isActionPerforming={isLoading}
                totalElements={driversSliceTotalElement}
                resetPage={resetPage}
                disabled={!isDriverIdle}
                numOfPages={driversSlicePages}
                onClick={(id, currentPage) => {
                  if (id !== currentPage) {
                    const pageAndSize = getQueryString({
                      page: id - 1,
                      size: "10",
                    });
                    const finalQueryParams = queryParamsFromFilterBar
                      ? queryParamsFromFilterBar + pageAndSize.replace("?", "&")
                      : pageAndSize;
                    store.dispatch(
                      getFilteredDriversStatusAndDetailsPaginationAsync({
                        queryParams: finalQueryParams,
                      })
                    );
                  }
                }}
              />
            </div>
          </PageContent>
        </>
      )}
    </>
  );
};
