import {
  createSlice,
  createEntityAdapter,
  createAsyncThunk,
  PayloadAction,
} from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import { Config } from "../../../config/Config";
import _ from "lodash";
import { normalize } from "normalizr";
import { presetsSchema } from "./presetNormalization";
import { GTFleetSuccessCodes } from "../../../config/GTFleetSuccessCodes";
import PresetsRepository from "./presetsRepository";
import { getErrorCodes } from "../../../utils/Utils";

//#region Type
export type Preset = {
  id: number;
  name: string;
  context: string;
  columns: string[];
  lastSelected: boolean;
};
//#endregion Type

//#region API
export const getPresetsAsync = createAsyncThunk(
  "presets/getPresets",
  async (customQueryParams: string) => {
    const presetsRepository = new PresetsRepository();
    const response = await presetsRepository.getPresets(customQueryParams);
    const preset = _.get(response, Config.PRESETS_RESPONSE_PATH);
    const normalizedData = normalize(preset, presetsSchema);
    return normalizedData.entities;
  }
);

export const updatePresetAsync = createAsyncThunk(
  "presets/updatePreset",
  async (
    data: {
      id: number;
      preset: { [key: string]: string | boolean | number };
      context: string;
    },
    { rejectWithValue }
  ) => {
    const presetsRepository = new PresetsRepository();
    try {
      const response = await presetsRepository.partiallyUpdatePreset(
        data.id,
        data.preset
      );
      const preset = _.get(response, Config.PRESETS_RESPONSE_PATH);
      const normalizedData = normalize(preset, presetsSchema);
      return normalizedData.entities;
    } catch (err: any) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data.message);
    }
  }
);

export const newPresetAsync = createAsyncThunk(
  "presets/newPreset",
  async (newPreset: Preset, { rejectWithValue }) => {
    const presetsRepository = new PresetsRepository();
    try {
      const response = await presetsRepository.newPreset(newPreset);
      const preset = _.get(response, Config.PRESETS_RESPONSE_PATH);
      const normalizedData = normalize(preset, presetsSchema);
      return normalizedData.entities;
    } catch (err: any) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data.message);
    }
  }
);

export const deletePresetAsync = createAsyncThunk(
  "presets/deletePreset",
  async (presetId: number, { rejectWithValue }) => {
    const presetsRepository = new PresetsRepository();
    try {
      const response = await presetsRepository.deletePreset(presetId);
      const preset = _.get(response, Config.PRESET_RESPONSE_PATH);
      return preset;
    } catch (err: any) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data.message);
    }
  }
);
//#endregion API

//#region Slice
const presetsAdapter = createEntityAdapter<Preset>({
  selectId: (preset) => preset.id,
  sortComparer: (a, b) => a.id.toString().localeCompare(b.id.toString()),
});

export const presetsSlice = createSlice({
  name: "presets",
  initialState: presetsAdapter.getInitialState({
    status: "idle",
    reasonCode: "",
  }),
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    addPreset: (state: any, action: PayloadAction<any>) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      presetsAdapter.upsertOne(state, action.payload);
      state.status = "idle";
    },
    removePreset: (state: any, id: any) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      presetsAdapter.removeOne(state, id);
      state.status = "idle";
    },
    restoreState: (state: any) => {
      state.status = "idle";
      state.reasonCode = "";
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      //#region Entity Reducers
      .addCase(
        getPresetsAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          presetsAdapter.setAll(state, action.payload.preset);
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.GET;
        }
      )
      .addCase(getPresetsAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = "";
      })
      .addCase(getPresetsAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(
        updatePresetAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          presetsAdapter.upsertMany(state, action.payload.preset);
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.PATCH;
        }
      )
      .addCase(
        updatePresetAsync.rejected,
        (state: any, action: PayloadAction<any>) => {
          state.status = "failed";
          state.reasonCode = getErrorCodes(action.payload);
        }
      )
      .addCase(updatePresetAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(
        newPresetAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          presetsAdapter.upsertMany(state, action.payload.preset);
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.POST;
        }
      )
      .addCase(
        newPresetAsync.rejected,
        (state: any, action: PayloadAction<any>) => {
          state.status = "failed";
          state.reasonCode = getErrorCodes(action.payload);
        }
      )
      .addCase(newPresetAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(
        deletePresetAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          action.payload && presetsAdapter.removeOne(state, action.payload.id);
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.DELETE;
        }
      )
      .addCase(
        deletePresetAsync.rejected,
        (state: any, action: PayloadAction<any>) => {
          state.status = "failed";
          state.reasonCode = getErrorCodes(action.payload);
        }
      )
      .addCase(deletePresetAsync.pending, (state: any) => {
        state.status = "loading";
      });
    //#endregion Entity Reducers
  },
});
//#endregion Slice

//#region Status

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const presetsSelectors = presetsAdapter.getSelectors<RootState>(
  (state) => state.presets
);

export const selectpresetsSliceStatus = (state: any) => state.presets.status;
export const selectpresetsSliceReasonCode = (state: any) =>
  state.presets.reasonCode;

export const { addPreset, removePreset, restoreState } = presetsSlice.actions;
export default presetsSlice.reducer;
