import dayjs from "dayjs";
import { t } from "i18next";
import _ from "lodash";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { store } from "../../app/store";
import { UserBox } from "../../ui/Chat/UserBox";
import { Dropdown } from "../../ui/Dropdown/Dropdown";
import { DatePickerDropdown } from "../../ui/Forms/DatePickerDropdown";
import Form from "../../ui/Forms/Form";
import SearchField from "../../ui/Forms/SearchField";
import { IconCalendar } from "../../ui/Icon/Line/Calendar";
import { IconVehicle } from "../../ui/Icon/Line/Vehicle";
import { ThumbProfile } from "../../ui/ThumbProfile/ThumbProfile";
import { getQueryString } from "../../utils/Utils";
import { Preferences } from "../users/preference/preferencesSlice";
import UserContext from "../users/userContext";
import { vehiclesSelectors } from "../vehicle/vehiclesSlice";
import "./DriversLocationHistoryFilterBar.css";
import { Driver, driversEmptyState, driversSelectors } from "./driversSlice";
import {
  driversStatusSelectors,
  getFilteredDriversStatusAndDetailsAsync,
} from "./driversStatusSlice";

interface RouteType {
  key: "STOP" | "TRACK";
  label: string;
}

const routeTypeValues: RouteType[] = [
  {
    key: "TRACK",
    label: t("locationHistory.tracksStopDropdown.tracks"),
  },
  {
    key: "STOP",
    label: t("locationHistory.tracksStopDropdown.stops"),
  },
];
interface QueryParams {
  [paramName: string]: any;
}

interface DriversLocationHistoryFilterBarProps {
  callback: (buildQueryParam: string) => any;
}

export const DriversLocationHistoryFilterBar: React.FC<DriversLocationHistoryFilterBarProps> =
  ({ callback }) => {
    const [selectedRouteTypeValues, setSelectedRouteTypeValues] = useState(
      [] as RouteType[]
    );
    const [executeQuery, setExecuteQuery] = useState(false);
    const [isDriverSelected, setIsDriverSelected] = useState(false);

    const [dateRange, setDateRange] = useState([] as string[]);
    const [driverName, setDriverName] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const searchbarRef = useRef<HTMLHeadingElement>(null);
    const parentRef = useRef<HTMLHeadingElement>(null);
    const drivers: Driver[] = useAppSelector(driversSelectors.selectAll);

    const [preferencesContext]: [Preferences] = useContext(UserContext);

    const queryParamsRef = useRef<QueryParams>({});
    let queryParams: QueryParams = queryParamsRef.current;
    const driversQueryParamsRef = useRef<QueryParams>({});
    let driversQueryParams: QueryParams = driversQueryParamsRef.current;

    useEffect(() => {
      return () => {
        store.dispatch(driversEmptyState());
      };
    }, []);

    /**
     * This useEffect listens to isOpen state in order to call the handleChanges
     * with the selected vehicle id.
     */
    useEffect(() => {
      const map = new Map();
      let filteredDrivers: Driver[];
      if (!isOpen) {
        filteredDrivers = drivers?.filter(
          (driver: Driver) =>
            driverName === driver.firstName + " " + driver.lastName
        );
        if (filteredDrivers.length > 0) {
          setIsDriverSelected(true);
          map.set("driver.id", String(filteredDrivers[0].id));
          handleChanges(map);
        }
      }
    }, [isOpen]);

    useEffect(() => {
      function handleClickOutside(event: any) {
        if (
          searchbarRef.current &&
          !searchbarRef.current.contains(event.target) &&
          parentRef.current &&
          !parentRef.current.contains(event.target)
        )
          setIsOpen(false);
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [searchbarRef]);

    // Handle routes
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const searchParamsFromLocationState =
      location.state && _.has(location.state, "queryParams")
        ? new URLSearchParams(_.get(location.state, "queryParams"))
        : null;

    /**
     * This use effect executes every time the location changes.
     * It retrieves the query params from the location (from route or from state).
     */
    useEffect(() => {
      const map = new Map();
      const currentSearchParams =
        searchParams.toString() !== ""
          ? searchParams
          : searchParamsFromLocationState?.toString() !== ""
          ? searchParamsFromLocationState
          : null;
      if (!!currentSearchParams && _.isEmpty(queryParams)) {
        const startPeriod = currentSearchParams.get("firstPointDate");
        const endPeriod = currentSearchParams.get("lastPointDate");
        const periods = [];
        if (!!startPeriod) {
          periods.push(startPeriod);
          map.set("firstPointDate", startPeriod);
        }
        if (!!endPeriod) {
          periods.push(endPeriod);
          map.set("lastPointDate", endPeriod);
        }
        setDateRange(periods);
        const routeStateTypes =
          currentSearchParams.getAll("routeStateTypeEnum");
        if (!!routeStateTypes) {
          const routeTypeValuesFromRoute: RouteType[] = [];
          routeStateTypes.forEach((routeStateType, index) => {
            const routeTypeValue = routeTypeValues.find(
              (routeTypeValue) => routeTypeValue.key === routeStateType
            );
            if (routeTypeValue) {
              routeTypeValuesFromRoute.push(routeTypeValue);
            }
          });
          if (routeTypeValuesFromRoute && routeTypeValuesFromRoute.length > 0) {
            setSelectedRouteTypeValues(routeTypeValuesFromRoute);
          }
        }
        const driver = currentSearchParams.get("driver.id");
        if (!!driver) {
          map.set("driver.id", driver);
          setIsDriverSelected(true);
          setDriverName(driver);
        }
      }
      if (map.size > 0) {
        handleChanges(map);
      }
    }, [location]);

    const handleSearchBarChanges = (data: any) => {
      if (data !== "") {
        driversQueryParams["firstName"] = data;
        driversQueryParams["lastName"] = data;
      } else {
        delete driversQueryParams.firstName;
        delete driversQueryParams.lastName;
      }
      const queryString = getQueryString(driversQueryParams);
      store.dispatch(getFilteredDriversStatusAndDetailsAsync(queryString));
    };

    const handleChanges = (params: Map<string, string[] | string>): void => {
      if (!!params) {
        params.forEach((value, key) => {
          if (!!value && value.length > 0) {
            if (queryParams[key] !== value) {
              queryParams[key] = value;
              setExecuteQuery(true);
            }
          } else {
            if (queryParams.hasOwnProperty(key)) {
              delete queryParams[key];
              setExecuteQuery(true);
            }
          }
        });
      }
    };

    useEffect(() => {
      if (executeQuery && isDriverSelected) {
        const queryString = getQueryString(queryParams);
        callback(queryString);
        setExecuteQuery(false);
      }
    }, [isDriverSelected, executeQuery]);

    const displayDrivers = (driver: Driver, index: number) => {
      const firstName = driver.firstName ? driver.firstName : t("common.na");
      const lastName = driver.lastName ? driver.lastName : t("common.na");
      const vehicleName = driver.fleet
        ? vehiclesSelectors.selectById(store.getState(), driver.vehicle)
            ?.name ?? "N/A"
        : t("common.na");
      const driverStatus = driversStatusSelectors.selectById(
        store.getState(),
        driver.id
      )?.dynamicFields?.ignitionKey
        ? true
        : false;

      return (
        <div
          key={index}
          className={
            driverName === firstName + " " + lastName
              ? "lh-driverbox-selected"
              : "lh-driverbox"
          }
        >
          <UserBox
            size="regular"
            status={driverStatus === true ? "ONLINE" : "OFFLINE"}
            title={firstName + " " + lastName}
            iconSubTitle={
              <IconVehicle
                size={8}
                color={
                  driverName === firstName + " " + lastName
                    ? "--global-colors-ui-white"
                    : "--global-colors-ink-light"
                }
              />
            }
            subTitle={vehicleName}
            icon={
              <ThumbProfile
                size="small"
                alt="User Thumbnail"
                imgUrl={driver.avatar}
              />
            }
            disableClick={true}
            onClicked={() => {
              setIsOpen(false);
              setDriverName(firstName + " " + lastName);
            }}
          />
        </div>
      );
    };

    return (
      <div className="driver-lh-filter-row">
        <div className="driver-lh-search">
          <div onClick={() => setIsOpen(true)} ref={parentRef}>
            <Form>
              <SearchField
                name="search"
                id="search-field"
                size="small"
                placeholder={t(
                  "locationHistory.searchDropdown.placeholderDriver"
                )}
                value={driverName}
                onChange={(val: string) => {
                  val.replace(/\s+/g, "").length > 2 &&
                    handleSearchBarChanges(val.split(" "));
                  setDriverName(val);
                }}
              />
            </Form>
          </div>
          {isOpen &&
            driverName.replace(/\s+/g, "").length > 2 &&
            drivers.length > 0 && (
              <div className="lh-search-results" ref={searchbarRef}>
                {drivers?.map((driver: Driver, idx: number) =>
                  displayDrivers(driver, idx)
                )}
              </div>
            )}
        </div>

        <div className="driver-lh-date-picker">
          <Form>
            <DatePickerDropdown
              setDate={(val) => {
                const map = new Map();
                map.set("firstPointDate", [
                  val[0].format("YYYY/MM/DD"),
                  val[1]?.format("YYYY/MM/DD") ?? "",
                ]);
                const period = [];
                if (val[0]) {
                  period.push(val[0].format("YYYY/MM/DD"));
                }
                if (val[1]) {
                  period.push(val[1].format("YYYY/MM/DD"));
                }
                setDateRange(period);
                handleChanges(map);
              }}
              localeFormat={preferencesContext.localeFormat ?? "DD / MM / YYYY"}
              initialValue={dateRange.map((date) => new Date(date))}
              defaultValue={new Date()}
              clearCallback={() => {
                setDateRange([]);
              }}
              language={preferencesContext.language ?? "it"}
              icon={
                <IconCalendar size={12} color="--global-colors-ink-light" />
              }
              dateRange={[dayjs().subtract(1, "year"), dayjs()]} //From today to past 1 year
              limitDaysRange={7}
            />
          </Form>
        </div>

        <div className="driver-lh-tracks-stops">
          <Dropdown
            hasCheckbox={true}
            placeholder={t("locationHistory.tracksStopDropdown.tracks/stops")}
            size={"small"}
            itemAttribute="label"
            onChange={(values: RouteType[]) => {
              const map = new Map();
              const routeTypes: string[] = [];
              values.forEach((value) => {
                const routeTypeValue = routeTypeValues.find(
                  (routeTypeValue) => routeTypeValue.key === value.key
                )?.key;
                if (routeTypeValue) {
                  routeTypes.push(routeTypeValue);
                }
              });
              map.set("routeStateTypeEnum", routeTypes);
              handleChanges(map);
            }}
            value={selectedRouteTypeValues}
            options={routeTypeValues}
          />
        </div>
      </div>
    );
  };
