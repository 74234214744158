import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import _ from "lodash";
import { RootState } from "../../../app/store";
import { Config } from "../../../config/Config";
import { GTFleetSuccessCodes } from "../../../config/GTFleetSuccessCodes";
import { default as RefuellingReportRepository } from "./refuellingReportRepository";

//#region Type
export type RefuellingChartDataReports = {
  id: number;
  fueldate: string;
  fuelLevel: number;
  isRefuel: boolean;
};
//#endregion Type

//#region API
// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const getRefuellingChartDataReportAsync = createAsyncThunk(
  "refuellingReport/getRefuellingChartDataReport",
  async (data: { vehicleId: number; queryParams: string }) => {
    const refuellingReportRepository = new RefuellingReportRepository();
    const response =
      await refuellingReportRepository.getRefuellingChartDataReport(
        data.vehicleId,
        data.queryParams
      );
    // The value we return becomes the `fulfilled` action payload
    const reports = _.get(response, Config.REFUELLING_REPORT_RESPONSE_PATH);
    return reports;
  }
);
//#endregion API

//#region Slice
const refuellingChartDataReportAdapter =
  createEntityAdapter<RefuellingChartDataReports>({
    selectId: (report) => report.id,
    sortComparer: (a, b) => a.id - b.id,
  });

export const refuellingChartDataReportSlice = createSlice({
  name: "refuellingChartDataReportSlice",
  initialState: refuellingChartDataReportAdapter.getInitialState({
    status: "idle",
    reasonCode: "",
  }),
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    refuellingChartDataReportEmptyState: (state) => {
      refuellingChartDataReportAdapter.setAll(state, []);
      state.reasonCode = "";
      state.status = "idle";
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      //#region Entity Reducers
      .addCase(
        getRefuellingChartDataReportAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          refuellingChartDataReportAdapter.upsertMany(state, action.payload);
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.GET;
        }
      )
      .addCase(getRefuellingChartDataReportAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(getRefuellingChartDataReportAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = "";
      });
    //#endregion Entity Reducers
  },
});
//#endregion Slice

//#region Status
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const refuellingChartDataReportSelectors =
  refuellingChartDataReportAdapter.getSelectors<RootState>(
    (state) => state.refuelChartDataReport
  );

export const selectRefuellingChartDataReportSliceStatus = (state: any) =>
  state.refuelChartDataReport.status;
export const selectRefuellingChartDataReportSliceReasonCode = (state: any) =>
  state.refuelChartDataReport.reasonCode;
//#endregion Status
export const { refuellingChartDataReportEmptyState } =
  refuellingChartDataReportSlice.actions;

export default refuellingChartDataReportSlice.reducer;
