import dayjs from "dayjs";
import "dayjs/locale/it";
import { createContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ToastNotification } from "../../utils/ToastNotification";
dayjs.extend(require("dayjs/plugin/localizedFormat"));
dayjs.extend(require("dayjs/plugin/isToday"));
export const DatePickerContext = createContext();

export const DatePickerProvider = ({ children, ...props }) => {
  const language = props.language || "en";
  dayjs.locale(language);

  const { t } = useTranslation();
  const [value, setValue] = useState(
    props.startDate ? dayjs(props.startDate) : dayjs()
  );
  const [startDate, setStartDate] = useState(
    props.startDate ? dayjs(props.startDate) : dayjs()
  );
  const [endDate, setEndDate] = useState(
    props.endDate ? dayjs(props.endDate) : null
  );
  const [secondValue, setSecondValue] = useState(null);
  const [secondStartDate, setSecondStartDate] = useState(
    props.secondStartDate ? dayjs(props.secondStartDate) : null
  );
  const [secondEndDate, setSecondEndDate] = useState(
    props.secondEndDate ? dayjs(props.secondEndDate) : null
  );
  const [preset, setPreset] = useState(props.preset || null);
  const [toggleComparison, setToggleComparison] = useState(
    props.toggleComparison ? true : false
  );
  const [activeComparison, setActiveComparison] = useState(
    props.activeComparison || []
  );
  const [customCompareActive, setCustomCompareActive] = useState(false);
  const [fromTime, setFromTime] = useState(
    props.startDate ? dayjs(props.startDate).format("HH:mm") : "00:00"
  );
  const [toTime, setToTime] = useState(
    props.endDate ? dayjs(props.endDate).format("HH:mm") : "00:00"
  );

  /**



   * When receiving initial value of current day, set hour interval from 00:00 to actual time



   */

  useEffect(() => {
    if (startDate && !endDate && startDate?.isToday() && toTime === "00:00") {
      setToTime(dayjs().format("HH:mm"));
      setFromTime("00:00");
    }
    if (startDate && !endDate && !startDate?.isToday() && toTime === "00:00") {
      setToTime("23:59");
      setFromTime("00:00");
    }
  }, [startDate]);

  useEffect(() => {
    if (startDate && startDate.isAfter(endDate)) {
      const prevDate = endDate;
      setEndDate(startDate);
      setStartDate(prevDate);
      return;
    }
    if (!toggleComparison) {
      setSecondStartDate(null);
      setSecondEndDate(null);
    }
    if (
      toggleComparison &&
      activeComparison.length !== 0 &&
      activeComparison[0] !== ""
    )
      calcSecondDateRange(activeComparison[0]);
  }, [endDate, activeComparison, toggleComparison]);

  useEffect(() => {
    if (secondStartDate && secondStartDate.isAfter(secondEndDate)) {
      const prevDate = secondEndDate;
      setSecondEndDate(secondStartDate);
      setSecondStartDate(prevDate);
    }
  }, [secondEndDate]);

  useEffect(() => {
    if (secondEndDate && secondEndDate.isAfter(startDate.subtract(1, "day"))) {
      calcSecondDateRange(activeComparison[0]);
    }
    if (secondStartDate && !secondEndDate) {
      setSecondStartDate(null);
      setSecondEndDate(null);
    }
  }, [preset]);

  function customCompareSelection(day) {
    if (day.isAfter(startDate.subtract(1, "day"))) return;
    if (secondStartDate === null || (secondStartDate && secondEndDate)) {
      setSecondStartDate(day);
      setSecondEndDate(null);
    } else {
      setSecondEndDate(day);
    }
  }

  function handleLimitRange(day) {
    if (props.limitDaysRange) {
      if (startDate.diff(day, "day") >= props.limitDaysRange) {
        ToastNotification({
          toastId: "limitDaysRange",
          title:
            t("report.toastNotification.datePickerLimitTitle") +
            props.limitDaysRange +
            t("report.toastNotification.days"),
        });
        return startDate.subtract(props.limitDaysRange - 1, "days");
      }
      if (startDate.diff(day, "day") <= -props.limitDaysRange) {
        ToastNotification({
          toastId: "limitDaysRange",
          title:
            t("report.toastNotification.datePickerLimitTitle") +
            props.limitDaysRange +
            t("report.toastNotification.days"),
        });
        return startDate.add(props.limitDaysRange - 1, "days");
      }
    }
    return day;
  }

  function handleOnChange(day, isSingleDate) {
    if (secondStartDate && secondEndDate) {
      setSecondStartDate(null);
      setSecondEndDate(null);
    }
    if (customCompareActive && endDate) {
      customCompareSelection(day);
    } else {
      setPreset(null);
      if (isSingleDate) {
        setStartDate(day);
        setValue(day);
      } else if (startDate === null || (startDate && endDate)) {
        setStartDate(day);
        setEndDate(null);
        setFromTime("00:00");
        setToTime("23:59");
      } else if (
        startDate.format("DD/MM/YYYY HH:mm") !== day.format("DD/MM/YYYY HH:mm")
      ) {
        setEndDate(handleLimitRange(day));
      }
    }
  }

  function calcSecondDateRange(option) {
    if (option === t("common.datePicker.custom")) {
      setCustomCompareActive(true);
      return;
    }
    if (startDate && endDate) {
      let daysBetweenStartEndDate = endDate.diff(startDate, "day") + 1;
      switch (option) {
        case t("common.datePicker.previousMonth"):
          setCustomCompareActive(false);
          setSecondStartDate(startDate.subtract(1, "month"));
          setSecondEndDate(endDate.subtract(1, "month"));
          break;
        case t("common.datePicker.previousYear"):
          setCustomCompareActive(false);
          setSecondStartDate(startDate.subtract(1, "year"));
          setSecondEndDate(endDate.subtract(1, "year"));
          break;
        default:
          setCustomCompareActive(false);
          setSecondStartDate(
            startDate.subtract(daysBetweenStartEndDate, "days")
          );
          setSecondEndDate(startDate.subtract(1, "day"));
          break;
      }
    }
  }

  function clearDate(resetDate) {
    if (resetDate) {
      setStartDate(dayjs(resetDate));
      setValue(dayjs(resetDate));
    } else {
      setStartDate(null);
      setValue(dayjs());
    }
    setEndDate(null);
    setSecondEndDate(null);
    setSecondStartDate(null);
    setPreset(null);
    setFromTime("00:00");
    setToTime("00:00");
  }

  function handleDatePreset(range) {
    switch (range) {
      case "lifetime":
        setStartDate(dayjs().subtract(24, "months"));
        setEndDate(dayjs());
        setValue(dayjs());
        break;
      case "yesterday":
        setStartDate(dayjs().subtract(1, "days"));
        setEndDate(dayjs().subtract(1, "days"));
        setValue(dayjs());
        break;
      case "3-days":
        setStartDate(dayjs().subtract(3, "days"));
        setEndDate(dayjs().subtract(1, "days"));
        setValue(dayjs());
        break;
      case "5-days":
        setStartDate(dayjs().subtract(5, "days"));
        setEndDate(dayjs().subtract(1, "days"));
        setValue(dayjs());
        break;
      case "current-week":
        setStartDate(dayjs().startOf("week"));
        setEndDate(dayjs().endOf("week"));
        setValue(dayjs());
        break;
      case "last-week":
        setStartDate(dayjs().subtract(1, "weeks").startOf("week"));
        setEndDate(dayjs().subtract(1, "weeks").endOf("week"));
        setValue(dayjs().subtract(1, "weeks"));
        break;
      case "current-month":
        setStartDate(dayjs().startOf("month"));
        setEndDate(dayjs().endOf("month"));
        setValue(dayjs());
        break;
      case "last-month":
        setStartDate(dayjs().subtract(1, "months").startOf("month"));
        setEndDate(dayjs().subtract(1, "months").endOf("month"));
        setValue(dayjs().subtract(1, "months"));
        break;
      default:
        setStartDate(dayjs());
        setEndDate(dayjs());
        setValue(dayjs());
        break;
    }
  }

  function handleFixedWeekPreset(weekObject) {
    const { startRange: firstDayOfWeek, endRange: lastDayOfWeek } = weekObject;

    setStartDate(dayjs(firstDayOfWeek).startOf("week"));

    setEndDate(dayjs(lastDayOfWeek).endOf("week"));

    setValue(dayjs(firstDayOfWeek));
  }

  function setSelectedYear(year) {
    if (props.dateRange) {
      if (!value.set("year", year).isAfter(props.dateRange[0])) {
        setValue(props.dateRange[0].add(1, "day"));
        setStartDate(props.dateRange[0].add(1, "day"));
        return;
      }
      if (value.set("year", year).isAfter(props.dateRange[1])) {
        setValue(props.dateRange[1]);
        setStartDate(props.dateRange[1]);
        return;
      }
    }
    setValue(value.set("year", year));
    setStartDate(value.set("year", year));
  }

  return (
    <DatePickerContext.Provider
      value={{
        value,
        startDate,
        endDate,
        secondValue,
        secondStartDate,
        secondEndDate,
        preset,
        toggleComparison,
        activeComparison,
        fromTime,
        toTime,
        language,
        dateRange: props.dateRange,
        limitDaysRange: props.limitDaysRange,
        setValue,
        setStartDate,
        setEndDate,
        setSecondValue,
        setSecondStartDate,
        setSecondEndDate,
        setFromTime,
        setToTime,
        handleOnChange,
        clearDate,
        handleDatePreset,
        handleFixedWeekPreset,
        setPreset,
        setToggleComparison,
        setActiveComparison,
        calcSecondDateRange,
        setSelectedYear,
        hasDatePresets: props.hasDatePresets,
      }}
    >
      {children}
    </DatePickerContext.Provider>
  );
};
