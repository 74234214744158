import dayjs from "dayjs";
import { t } from "i18next";
import { useContext, useEffect, useMemo, useState } from "react";
import { useAppSelector } from "../../app/hooks";
import { store } from "../../app/store";
import { Card } from "../../ui/Card/Card";
import { DataLabel } from "../../ui/DataLabel/DataLabel";
import { IconWheelChange } from "../../ui/Icon/Solid/WheelChange";
import { SingleItemContainer } from "../../ui/SingleItem/SingleItemContainer";
import { VehicleActivity } from "../../ui/Vehicles/VehicleActivity";
import { ToastNotification } from "../../utils/ToastNotification";
import {
  InstantPoint,
  VehiclesFuelCosts,
  fleetEmptyState,
  fleetFuelCostsSelectors,
  getVehiclesFuelCostsAsync,
  selectFleetFuelCostsSliceReasonCode,
  selectFleetFuelCostsSliceStatus,
} from "../fleet/fleetFuelCostsSlice";
import {
  VehiclesFuelsConsumption,
  fleetFuelsConsumptionSelectors,
  getVehiclesFuelsConsumptionAsync,
  selectFleetFuelConsumptionsSliceReasonCode,
  selectFleetFuelConsumptionsSliceStatus,
} from "../fleet/fleetFuelsConsumptionsSlice";
import {
  VehiclesMaintenanceCosts,
  fleetMaintenanceCostsSelectors,
  fleetMaintenanceEmptyState,
  getVehiclesMaintenanceCostsAsync,
  selectFleetMaintenanceCostsSliceReasonCode,
  selectFleetMaintenanceCostsSliceStatus,
} from "../fleet/fleetMaintenanceCostsSlice";
import {
  Deadline,
  deadlinesEmptyState,
  deadlinesSelectors,
  getDeadlinesAsync,
  selectDeadlinesSliceReasonCode,
  selectDeadlinesSliceStatus,
} from "../maintenance-deadline/deadlinesSlice";
import {
  Vehicle,
  selectVehiclesSliceReasonCode,
  selectVehiclesSliceStatus,
  vehiclesEmptyState,
  vehiclesSelectors,
} from "../vehicle/vehiclesSlice";
import { vehiclesStatusEmptyState } from "../vehicle/vehiclesStatusSlice";

import _ from "lodash";
import { useLocation } from "react-router-dom";
import { TimeSeriesChartCard } from "../../ui/Vehicles/TimeSeriesChartCard";
import {
  convertEuroOnCurrency,
  getQueryString,
  kmToMiles,
  ltToGal,
  numberAnnotation,
} from "../../utils/Utils";
import { Preferences } from "../users/preference/preferencesSlice";
import { UserPermissions } from "../users/privilege/privilegesSlice";
import UserContext from "../users/userContext";
import {
  VehicleStatusView,
  getVehiclesViewAsync,
  vehiclesStatusViewSelectors,
} from "../vehicle/vehiclesStatusViewSlice";
import {
  VehicleView,
  vehiclesViewSelectors,
} from "../vehicle/vehiclesViewSlice";
import "./Overview.css";

interface OverviewProps {
  permissions: UserPermissions;
}

interface Coordinates {
  x: string;
  y: number;
}

interface Period {
  startPeriod: string;
  endPeriod: string;
}
interface QueryParams {
  [paramName: string]: any;
}
interface DataTextType {
  configuration: {
    label: string;
    expirationType: string;
  };
  vehicles: string[];
  dataText: string;
  labelText?: string;
  typeDataTex: string;
  isMaintenance: boolean;
}

interface VehicleActivityPropType {
  title: string;
  vehicles: string[];
}

export const Overview: React.FC<OverviewProps> = ({ permissions }) => {
  const [dataTexts, setDataTexts] = useState<DataTextType[]>([]);
  const location = useLocation();
  const deadlines: Deadline[] = useAppSelector(deadlinesSelectors.selectAll);
  const deadlinesSliceStatus = useAppSelector(selectDeadlinesSliceStatus);
  const deadlinesSliceReasonCode = useAppSelector(
    selectDeadlinesSliceReasonCode
  );
  const vehiclesStatusView: VehicleStatusView[] = useAppSelector(
    vehiclesStatusViewSelectors.selectAll
  );

  const vehiclesView: VehicleView[] = useAppSelector(
    vehiclesViewSelectors.selectAll
  );
  const vehiclesSliceStatus = useAppSelector(selectVehiclesSliceStatus);
  const vehiclesSliceReasonCode = useAppSelector(selectVehiclesSliceReasonCode);

  const [preferencesContext]: [Preferences] = useContext(UserContext);
  dayjs.locale(preferencesContext.language ?? "en");

  /**
   * This is the initial useEffect that:
   * - on component mount, dispatches the requests to get all deadlines and vehicles (details + status).
   * - on component unmount, dispatches the requests to empty the deadline and vehicle redux states.
   */
  useEffect(() => {
    document.title = t("navigation.userMenu.overview");
    const redirectState: string | undefined = (
      location.state as { cause?: string }
    )?.cause;
    if (redirectState) {
      ToastNotification({
        toastId: redirectState,
        status: "error",
        description: t("common." + redirectState),
      });
      window.history.replaceState({}, document.title);
    }
    if (permissions.deadlines.read) {
      store.dispatch(
        getDeadlinesAsync({
          queryParams: getQueryString({ size: 10000 }),
        })
      );
    }
    store.dispatch(getVehiclesViewAsync());
    return () => {
      store.dispatch(deadlinesEmptyState());
      store.dispatch(vehiclesEmptyState());
      store.dispatch(vehiclesStatusEmptyState());
      store.dispatch(vehiclesEmptyState());
      store.dispatch(fleetEmptyState());
      store.dispatch(fleetMaintenanceEmptyState());
    };
  }, []);

  //#region ERROR_HANDLING
  // One useEffect for each resource state checking.
  // Putting all checks in a single useEffect can provoke the raising of already notified errors.

  useEffect(() => {
    if (deadlinesSliceStatus === "failed" && deadlinesSliceReasonCode === "") {
      console.error("common.networkError");
      ToastNotification({
        toastId: "networkError",
        status: "error",
        description: t("common.networkError"),
      });
    }
  }, [deadlinesSliceStatus, deadlinesSliceReasonCode]);
  useEffect(() => {
    if (vehiclesSliceStatus === "failed" && vehiclesSliceReasonCode === "") {
      console.error("common.networkError");
      ToastNotification({
        toastId: "networkError",
        status: "error",
        description: t("common.networkError"),
      });
    }
  }, [vehiclesSliceStatus, vehiclesSliceReasonCode]);
  //#endregion

  //#region Deadlines/Maintenances
  const insertDataText = (
    dataText: DataTextType[],
    deadline: Deadline,
    typeInsert: string,
    vehiclePlate: string
  ) => {
    let insertVehicle = false;
    dataText.forEach((item: DataTextType) => {
      if (
        item.configuration.label === deadline.type &&
        item.typeDataTex === typeInsert
      ) {
        item.vehicles.push(vehiclePlate);
        insertVehicle = true;
      }
    });

    if (!insertVehicle) {
      dataText.push({
        configuration: {
          label: deadline.type,
          expirationType: deadline.expirationType,
        },
        dataText: t("report.overview." + deadline.type),
        vehicles: [vehiclePlate],
        typeDataTex: typeInsert,
        isMaintenance: deadline.isMaintenance,
      });
    }
  };

  const fillArray = (arrayRedux: Deadline[], dataTextTemp: DataTextType[]) => {
    arrayRedux.forEach((deadline: Deadline) => {
      if (!deadline.complete) {
        const vehicle: Vehicle =
          vehiclesSelectors.selectById(store.getState(), deadline.vehicle) ||
          ({} as Vehicle);
        if (deadline?.status === "EXPIRED" || deadline?.status === "EXPIRING") {
          insertDataText(
            dataTextTemp,
            deadline,
            deadline.status,
            vehicle.plate
          );
        }
      }
    });

    dataTextTemp.forEach((item: DataTextType) => {
      item.labelText = "";
      if (item.vehicles.length > 3) {
        for (let i = 0; i <= 3; i++) {
          item.labelText += item.vehicles[i] + ", ";
        }
        item.labelText = item.labelText.slice(0, -2);
        if (item.vehicles.length - 4 > 0)
          item.labelText += t("report.overview.moreVehicle").replace(
            "%",
            (item.vehicles.length - 4).toString()
          );
      } else {
        item.vehicles.forEach((vehicle: string) => {
          item.labelText += vehicle + ", ";
        });
        item.labelText = item.labelText.slice(0, -2);
      }
    });

    dataTextTemp.sort((x, y) => {
      return x.typeDataTex === "EXPIRED"
        ? -1
        : y.typeDataTex === "EXPIRED"
        ? 1
        : 0;
    });
  };

  useEffect(() => {
    if (
      deadlinesSliceStatus === "idle" &&
      deadlines.length &&
      !_.isEmpty(preferencesContext) &&
      vehiclesStatusView.length
    ) {
      let dataTextsTemp: DataTextType[] = [];
      fillArray(deadlines, dataTextsTemp);
      setDataTexts(dataTextsTemp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deadlines, vehiclesStatusView]);
  //#endregion

  //#region VehicleActivity
  const labels = [
    "Moving",
    "Maintenance",
    "Parking",
    "Offline",
    "Unknown",
    "Stopped",
  ];

  // useMemo to build the maintenance and offline vehicle activity
  const vehicleActivity: VehicleActivityPropType[] = useMemo(() => {
    let vehicleActivity: VehicleActivityPropType[] = [
      {
        title: "Maintenance",
        vehicles: [],
      },
      {
        title: "Offline",
        vehicles: [],
      },
    ];

    // vehiclesView
    vehicleActivity[0].vehicles =
      !!vehiclesView && vehiclesView.length
        ? vehiclesView
            .filter((vehicleView) => vehicleView.maintenance)
            .map((vehicleView) => vehicleView.plate)
        : [];

    // vehiclesStatusView + vehiclesView
    vehicleActivity[1].vehicles =
      !!vehiclesStatusView && vehiclesStatusView.length
        ? vehiclesStatusView
            .filter(
              (vehicleStatusView) =>
                vehicleStatusView.dynamicFields?.vehicleStatus === "OFFLINE"
            )
            .map((vehicleStatusView) => {
              const vehicleView: VehicleView =
                vehiclesViewSelectors.selectById(
                  store.getState(),
                  vehicleStatusView.id
                ) || ({} as VehicleView);
              return vehicleView?.plate;
            })
            .filter(
              (vehicleStatusView) =>
                !!vehicleStatusView && vehicleStatusView != ""
            )
        : [];
    return vehicleActivity;
  }, [vehiclesView, vehiclesStatusView]);

  // useMemo to build the pie chart series data
  const series: number[] = useMemo(() => {
    const series: number[] = [0, 0, 0, 0, 0, 0];

    series[1] =
      vehiclesView && vehiclesView.length
        ? vehiclesView.filter((vehicleView) => vehicleView?.maintenance).length
        : 0;

    if (vehiclesStatusView && vehiclesStatusView.length) {
      vehiclesStatusView.forEach((vehicleStatus) => {
        if (!vehicleStatus.dynamicFields) {
          series[4] += 1;
        } else if (vehicleStatus.dynamicFields.vehicleStatus === "MOVING") {
          series[0] += 1;
        } else if (vehicleStatus.dynamicFields.vehicleStatus === "PARKING") {
          series[2] += 1;
        } else if (vehicleStatus.dynamicFields.vehicleStatus === "OFFLINE") {
          series[3] += 1;
        } else if (vehicleStatus.dynamicFields.vehicleStatus === "UNKNOWN") {
          series[4] += 1;
        } else if (vehicleStatus.dynamicFields.vehicleStatus === "STOP") {
          series[5] += 1;
        }
      });
    }
    return series;
  }, [vehiclesStatusView, vehiclesView]);

  //#endregion

  //#region Single container for deadlines or maintenance
  const viewDeadlineMaintenance = (
    configuration: DataTextType["configuration"],
    status: string,
    vehicles: string[]
  ) => {
    if (vehicles.length === 1) {
      window
        .open(
          `/dashboard/maintenance` +
            getQueryString({
              type: [configuration.label],
              "vehicle.plate": vehicles[0],
              "vehicle.alias": vehicles[0],
              status: status,
            }),
          "_blank"
        )
        ?.focus();
    } else {
      window
        .open(
          `/dashboard/maintenance` +
            getQueryString({
              type: [configuration.label],
              status: status,
            }),
          "_blank"
        )
        ?.focus();
    }
  };

  const returnSinglecontainer = (
    arrayContainer: DataTextType[],
    isMaintenance: boolean
  ) => {
    return arrayContainer.map((dataText: DataTextType, index: number) => {
      if (dataText.isMaintenance === isMaintenance) {
        return (
          <SingleItemContainer
            onClick={() => {
              viewDeadlineMaintenance(
                dataText.configuration,
                dataText.typeDataTex,
                dataText.vehicles
              );
            }}
            key={dataText.configuration.label + index}
            label={t(
              "report.overview." + dataText.typeDataTex.toLocaleLowerCase()
            )}
          >
            <DataLabel
              className={dataText.typeDataTex === "EXPIRED" ? "viewRed" : ""}
              dataText={dataText.dataText}
              icon={<IconWheelChange size={14} />}
              label={dataText.labelText}
            />
          </SingleItemContainer>
        );
      }
      return null;
    });
  };
  //#endregion

  //#region FLEET COSTS
  const fleetFuelCosts: VehiclesFuelCosts =
    useAppSelector(fleetFuelCostsSelectors.selectAll)[0] ??
    ({} as VehiclesFuelCosts);

  const fleetFuelCostsSliceStatus = useAppSelector(
    selectFleetFuelCostsSliceStatus
  );

  const fleetFuelCostsSliceReasonCode = useAppSelector(
    selectFleetFuelCostsSliceReasonCode
  );

  useEffect(() => {
    if (
      fleetFuelCostsSliceStatus === "failed" &&
      fleetFuelCostsSliceReasonCode === ""
    ) {
      ToastNotification({
        toastId: "networkError",
        status: "error",
        description: t("common.networkError"),
      });
    }
  }, [fleetFuelCostsSliceStatus, fleetFuelCostsSliceReasonCode]);

  const [periodFuelCosts, setPeriodFuelCosts] = useState({
    startPeriod: new Date(
      new Date(new Date().setDate(new Date().getDate() - 6)).setUTCHours(
        0,
        0,
        0,
        0
      )
    ).toISOString(),
    endPeriod: new Date(
      new Date(new Date().setUTCHours(23, 59, 59, 999))
    ).toISOString(),
  });

  useEffect(() => {
    store.dispatch(
      getVehiclesFuelCostsAsync({
        queryParams: getQueryString({ ...periodFuelCosts } as QueryParams),
      })
    );
    store.dispatch(
      getVehiclesMaintenanceCostsAsync({
        queryParams: getQueryString({ ...periodFuelCosts } as QueryParams),
      })
    );
  }, [periodFuelCosts]);
  //#endregion

  //#region FLEET MAINTENANCE
  const fleetMaintenanceCosts: VehiclesMaintenanceCosts =
    useAppSelector(fleetMaintenanceCostsSelectors.selectAll)[0] ??
    ({} as VehiclesMaintenanceCosts);

  const fleetMaintenanceCostsSliceStatus = useAppSelector(
    selectFleetMaintenanceCostsSliceStatus
  );

  const fleetMaintenanceCostsSliceReasonCode = useAppSelector(
    selectFleetMaintenanceCostsSliceReasonCode
  );

  useEffect(() => {
    if (
      fleetMaintenanceCostsSliceStatus === "failed" &&
      fleetFuelCostsSliceReasonCode === ""
    ) {
      ToastNotification({
        toastId: "networkError",
        status: "error",
        description: t("common.networkError"),
      });
    }
  }, [fleetMaintenanceCostsSliceStatus, fleetMaintenanceCostsSliceReasonCode]);

  //#endregion

  //#region Fuel STATS
  const fleetFuelsConsumption: VehiclesFuelsConsumption =
    useAppSelector(fleetFuelsConsumptionSelectors.selectAll)[0] ??
    ({} as VehiclesFuelsConsumption);

  const fleetFuelsConsumptionSliceStatus = useAppSelector(
    selectFleetFuelConsumptionsSliceStatus
  );

  const fleetFuelsConsumptionSliceReasonCode = useAppSelector(
    selectFleetFuelConsumptionsSliceReasonCode
  );

  useEffect(() => {
    if (
      fleetFuelsConsumptionSliceStatus === "failed" &&
      fleetFuelsConsumptionSliceReasonCode === ""
    ) {
      ToastNotification({
        toastId: "networkError",
        status: "error",
        description: t("common.networkError"),
      });
    }
  }, [fleetFuelsConsumptionSliceStatus, fleetFuelsConsumptionSliceReasonCode]);

  const [periodFuelConsumption, setPeriodFuelConsumption] = useState({
    startPeriod: new Date(
      new Date(new Date().setDate(new Date().getDate() - 6)).setUTCHours(
        0,
        0,
        0,
        0
      )
    ).toISOString(),
    endPeriod: new Date(
      new Date(new Date().setUTCHours(23, 59, 59, 999))
    ).toISOString(),
  });

  useEffect(() => {
    store.dispatch(
      getVehiclesFuelsConsumptionAsync({
        queryParams: getQueryString({
          ...periodFuelConsumption,
        } as QueryParams),
      })
    );
  }, [periodFuelConsumption]);
  //#endregion

  /**
   * @todo "if code" will have to be removed when we will have maintenance data.
   * @param instantPoints
   * @param period
   * @returns
   */
  const expandPeriod = (
    instantPoints: InstantPoint[],
    period: Period
  ): Coordinates[] => {
    let currentDate = new Date(period.startPeriod);
    let stopDate = new Date(period.endPeriod);
    stopDate.setDate(stopDate.getDate() + 1);

    const dates: Coordinates[] = new Array();
    while (currentDate <= stopDate) {
      dates.push({ x: currentDate.toISOString(), y: 0 } as Coordinates);
      currentDate.setDate(currentDate.getDate() + 1);
    }

    if (instantPoints.length) {
      instantPoints.forEach((instantPoint: InstantPoint) => {
        dates.forEach((data) => {
          const dataXDate = new Date(data.x);
          const instantPointDate = new Date(instantPoint.date);
          const dataXDateDay = dataXDate.getDate();
          const dataXDateMonth = dataXDate.getMonth();
          const dataXDateYear = dataXDate.getFullYear();
          const instantPointDateDay = instantPointDate.getDate();
          const instantPointDateMonth = instantPointDate.getMonth();
          const instantPointDateYear = instantPointDate.getFullYear();
          if (
            dataXDateDay === instantPointDateDay &&
            dataXDateMonth === instantPointDateMonth &&
            dataXDateYear === instantPointDateYear
          ) {
            data.y = instantPoint.data;
          }
        });
      });
    }
    return dates;
  };

  const volumetricUom = preferencesContext.isMetric ? "lt" : "gal";
  const metricUom = preferencesContext.isMetric ? "km" : "mil";
  const volumetricFractionalMetricUom = volumetricUom + "/" + metricUom;
  const currency =
    preferencesContext.currency === "EURO"
      ? "€"
      : preferencesContext.currency === "DOLLAR"
      ? "$"
      : "£";
  const currencyFractionalMetricUom = currency + "/" + metricUom;
  const reactDeployTarget =
    process.env.REACT_APP_DEPLOY_TARGET === "stage" ||
    process.env.REACT_APP_DEPLOY_TARGET === "local";

  return (
    <>
      <div className="overviewContainers">
        <div className="overviewContainer">
          <div className="subContainerOverview">
            <VehicleActivity
              data={vehicleActivity}
              labels={labels}
              series={series}
            />
          </div>
          <div className="subContainerOverview">
            {/* Costs */}
            {!_.isEmpty(preferencesContext) && (
              <TimeSeriesChartCard
                graphTitle={{
                  first: {
                    title: t("card.fuel"),
                  },
                  second: {
                    title: t("card.maintenance"),
                  },
                }}
                chartDataSeries={[
                  {
                    name: "fuel",
                    data: expandPeriod(
                      fleetFuelCosts.fuelsCost?.length > 0
                        ? fleetFuelCosts.fuelsCost.map((item) => {
                            return {
                              ...item,
                              data: convertEuroOnCurrency(
                                preferencesContext.currency,
                                item?.data
                              ),
                            };
                          })
                        : [],
                      periodFuelCosts
                    ),
                    unitMeasure: currency,
                  },
                  {
                    name: "maintenance",
                    data: expandPeriod(
                      fleetMaintenanceCosts?.deadlineMaintenancePoint?.length >
                        0
                        ? fleetMaintenanceCosts.deadlineMaintenancePoint.map(
                            (item) => {
                              return {
                                ...item,
                                data: convertEuroOnCurrency(
                                  preferencesContext.currency,
                                  item?.data
                                ),
                              };
                            }
                          )
                        : [],
                      periodFuelCosts
                    ),
                    unitMeasure: currency,
                  },
                ]}
                chartDateSeries={[
                  {
                    date:
                      fleetFuelCosts.fuelsCost?.length > 0
                        ? fleetFuelCosts.fuelsCost.map(
                            (fuelsCost) => fuelsCost.date
                          )
                        : [
                            periodFuelCosts.startPeriod,
                            periodFuelCosts.endPeriod,
                          ],
                  },
                  {
                    date:
                      fleetMaintenanceCosts.deadlineMaintenancePoint?.length > 0
                        ? fleetMaintenanceCosts.deadlineMaintenancePoint.map(
                            (deadlineMaintenancePoint) =>
                              deadlineMaintenancePoint.date
                          )
                        : [
                            periodFuelCosts.startPeriod,
                            periodFuelCosts.endPeriod,
                          ],
                  },
                ]}
                firstChartData={{
                  title: t("card.fuel"),
                  first: {
                    title: t("card.totalFuelCosts"),
                    data: fleetFuelCosts.summary?.totalFuelCosts
                      ? numberAnnotation(
                          convertEuroOnCurrency(
                            preferencesContext.currency,
                            fleetFuelCosts.summary.totalFuelCosts
                          )
                        ) +
                        " " +
                        currency
                      : `${t("common.na")}`,
                  },
                  second: reactDeployTarget
                    ? {
                        title: t("card.averageFuelCost"),
                        data: fleetFuelCosts.summary?.avgFuelCosts
                          ? numberAnnotation(
                              convertEuroOnCurrency(
                                preferencesContext.currency,
                                fleetFuelCosts.summary.avgFuelCosts
                              )
                            ) +
                            " " +
                            currencyFractionalMetricUom
                          : `${t("common.na")}`,
                      }
                    : undefined,
                }}
                secondChartData={{
                  title: t("card.maintenance"),
                  first: {
                    title: t("card.totalMaintenanceCosts"),
                    data:
                      fleetMaintenanceCosts.summary?.totalDeadlineCosts &&
                      !isNaN(fleetMaintenanceCosts.summary?.totalDeadlineCosts)
                        ? numberAnnotation(
                            convertEuroOnCurrency(
                              preferencesContext.currency,
                              fleetMaintenanceCosts.summary.totalDeadlineCosts
                            )
                          ) +
                          " " +
                          currency
                        : `${t("common.na")}`,
                  },
                  second: reactDeployTarget
                    ? {
                        title: t("card.averageMaintenanceCost"),
                        data:
                          fleetMaintenanceCosts.summary?.avgDeadlineCosts &&
                          !isNaN(
                            fleetMaintenanceCosts.summary?.avgDeadlineCosts
                          )
                            ? numberAnnotation(
                                convertEuroOnCurrency(
                                  preferencesContext.currency,
                                  fleetMaintenanceCosts.summary.avgDeadlineCosts
                                )
                              ) +
                              " " +
                              currency
                            : `${t("common.na")}`,
                      }
                    : undefined,
                }}
                setPeriod={setPeriodFuelCosts}
                title={t("pages.dashboardVehicles.vehicleDetails.costs")}
                linkAnchor={
                  reactDeployTarget
                    ? t("pages.dashboardVehicles.vehicleDetails.viewFuelCosts")
                    : ""
                }
                linkAction={() => {
                  console.log("Click On Card");
                }}
                hasDropdown={true}
                status={
                  fleetFuelCostsSliceStatus || fleetMaintenanceCostsSliceStatus
                }
              />
            )}
          </div>
          <div className="subContainerOverview">
            <Card
              className="mn-deadlines"
              title={t("report.overview.deadlines")}
              linkAction={() => {
                window
                  .open(
                    `/dashboard/maintenance` +
                      getQueryString({
                        type: ["INSURANCE", "TAXES", "REVISION", "CUSTOM"],
                      }),
                    "_blank"
                  )
                  ?.focus();
              }}
              linkAnchor={t("common.viewAll")}
            >
              {dataTexts.length
                ? returnSinglecontainer(dataTexts, false)
                : null}
            </Card>
          </div>
        </div>
        <div className="overviewContainer">
          <div className="subContainerOverview">
            {!_.isEmpty(preferencesContext) && (
              <TimeSeriesChartCard
                graphTitle={{
                  first: {
                    title: t("card.fuelConsumption"),
                  },
                }}
                chartDataSeries={[
                  {
                    name: "fuelsConsumption",
                    data: expandPeriod(
                      fleetFuelsConsumption.fuelsConsumption?.length > 0
                        ? fleetFuelsConsumption.fuelsConsumption.map((item) => {
                            return {
                              ...item,
                              data: preferencesContext.isMetric
                                ? item?.data
                                : ltToGal(item?.data),
                            };
                          })
                        : [],
                      periodFuelConsumption
                    ),
                    unitMeasure: volumetricUom,
                  },
                ]}
                chartDateSeries={[
                  {
                    date:
                      fleetFuelsConsumption.fuelsConsumption?.length > 0
                        ? fleetFuelsConsumption.fuelsConsumption.map(
                            (fuelConsumption) => fuelConsumption.date
                          )
                        : [
                            periodFuelConsumption.startPeriod,
                            periodFuelConsumption.endPeriod,
                          ],
                  },
                ]}
                firstChartData={{
                  title: t("card.fuelConsumption"),
                  first: {
                    title: t("card.averageConsumption"),
                    data: fleetFuelsConsumption.summary?.avgConsumption
                      ? numberAnnotation(
                          preferencesContext.isMetric
                            ? fleetFuelsConsumption.summary.avgConsumption
                            : kmToMiles(
                                fleetFuelsConsumption.summary.avgConsumption
                              )
                        ) +
                        " " +
                        volumetricFractionalMetricUom
                      : `${t("common.na")}`,
                  },
                  second: reactDeployTarget
                    ? {
                        title: t("card.zeroConsumption"),
                        data: fleetFuelsConsumption.summary?.zeroConsumption
                          ? numberAnnotation(
                              preferencesContext.isMetric
                                ? fleetFuelsConsumption.summary.zeroConsumption
                                : kmToMiles(
                                    fleetFuelsConsumption.summary
                                      .zeroConsumption
                                  )
                            ) +
                            " " +
                            metricUom
                          : `${t("common.na")}`,
                      }
                    : undefined,
                  third: {
                    title: t("card.totalFuelConsumption"),
                    data: fleetFuelsConsumption.summary?.totalConsumption
                      ? numberAnnotation(
                          preferencesContext.isMetric
                            ? fleetFuelsConsumption.summary.totalConsumption
                            : ltToGal(
                                fleetFuelsConsumption.summary.totalConsumption
                              )
                        ) +
                        " " +
                        volumetricUom
                      : `${t("common.na")}`,
                  },
                }}
                setPeriod={setPeriodFuelConsumption}
                title={t(
                  "pages.dashboardVehicles.vehicleDetails.fuelConsumption"
                )}
                linkAnchor={
                  reactDeployTarget
                    ? t("pages.dashboardVehicles.vehicleDetails.viewFuelReport")
                    : ""
                }
                linkAction={() => {}}
                hasDropdown={true}
                status={fleetFuelsConsumptionSliceStatus}
              />
            )}
          </div>
          <div className="subContainerOverview">
            <Card
              className="mn-deadlines"
              title={t("report.overview.fleetMaintenance")}
              linkAction={() => {
                window
                  .open(
                    `/dashboard/maintenance` +
                      getQueryString({
                        type: [
                          "MAINTENANCE_CAR_SERVICE",
                          "MAINTENANCE_TIRES",
                          "MAINTENANCE_INSPECTION",
                          "MAINTENANCE_CAR_BODYWORK",
                          "MAINTENANCE_EXTRAORDINARY",
                          "MAINTENANCE_ORDINARY",
                          "MAINTENANCE_CUSTOM",
                        ],
                      }),
                    "_blank"
                  )
                  ?.focus();
              }}
              linkAnchor={t("common.viewAll")}
            >
              {dataTexts.length ? returnSinglecontainer(dataTexts, true) : null}
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};
