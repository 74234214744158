import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import _ from "lodash";
import { normalize } from "normalizr";
import { RootState } from "../../app/store";
import { Config } from "../../config/Config";
import { GTFleetSuccessCodes } from "../../config/GTFleetSuccessCodes";
import { getErrorCodes } from "../../utils/Utils";
import { fleetMaintenanceCostsSchema } from "./fleetNormalization";
import FleetMaintenanceCostsRepository from "./fleetMaintenanceCostsRepository";

//#region Type
export interface InstantPoint {
  data: number;
  date: string;
}

export interface VehiclesMaintenanceCosts {
  tenantId: number;
  summary: {
    totalDeadlineCosts: number;
    avgDeadlineCosts: number;
  };
  deadlineMaintenancePoint: InstantPoint[];
}
//#endregion Type

//#region API
export const getVehiclesMaintenanceCostsAsync = createAsyncThunk(
  "fleet/getFleetMaintenanceCosts",
  async (data: { queryParams: string }) => {
    const fleetMaintenanceCostsRepository =
      new FleetMaintenanceCostsRepository();
    const response =
      await fleetMaintenanceCostsRepository.getFleetMaintenanceCosts(
        data.queryParams
      );
    const fleetCosts = _.get(response, Config.DEADLINE_RESPONSE_PATH);
    const normalizedData = normalize(fleetCosts, fleetMaintenanceCostsSchema);
    return normalizedData.entities;
  }
);
//#region API

//#region Slice
const fleetMaintenanceCostsAdapter =
  createEntityAdapter<VehiclesMaintenanceCosts>({
    selectId: (vehiclesMaintenanceCosts) => vehiclesMaintenanceCosts.tenantId,
  });

export const fleetMaintenanceCostsSlice = createSlice({
  name: "fleetMaintenanceCosts",
  initialState: fleetMaintenanceCostsAdapter.getInitialState({
    status: "idle",
    reasonCode: "",
  }),
  reducers: {
    fleetMaintenanceEmptyState: (state: any) => {
      fleetMaintenanceCostsAdapter.setAll(state, []);
    },
  },
  extraReducers: (builder) => {
    builder
      //#region Entity Reducers
      .addCase(
        getVehiclesMaintenanceCostsAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          fleetMaintenanceCostsAdapter.setAll(
            state,
            action.payload.VehiclesMaintenanceCosts ?? []
          );
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.GET;
        }
      )
      .addCase(
        getVehiclesMaintenanceCostsAsync.rejected,
        (state: any, action: PayloadAction<any>) => {
          state.status = "failed";
          state.reasonCode = getErrorCodes(action.payload);
        }
      )
      .addCase(getVehiclesMaintenanceCostsAsync.pending, (state: any) => {
        state.status = "loading";
      });
    //#endregion Entity Reducers
  },
});
//#endregion Slice

//#region Status
export const fleetMaintenanceCostsSelectors =
  fleetMaintenanceCostsAdapter.getSelectors<RootState>(
    (state) => state.fleetMaintenanceCosts
  );

export const selectFleetMaintenanceCostsSliceStatus = (state: any) =>
  state.fleetMaintenanceCosts.status;
export const selectFleetMaintenanceCostsSliceReasonCode = (state: any) =>
  state.fleetMaintenanceCosts.reasonCode;
//#endregion Status

export const { fleetMaintenanceEmptyState } =
  fleetMaintenanceCostsSlice.actions;

export default fleetMaintenanceCostsSlice.reducer;
