import React, { useState } from 'react';
import "./FilterMap.css";
import { FilterBar } from '../../ui/MultiView/FilterBar';
import { DndProvider } from 'react-dnd';
import Form from '../../ui/Forms/Form';
import SearchField from '../../ui/Forms/SearchField';
import { Dropdown } from '../../ui/Dropdown/Dropdown';
import countries from "./assets/countries.json";
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DropArea } from '../../ui/MultiView/DropArea';
import ContainerWrapper from '../../layout/ContainerWrapper';
import GridContainer from '../../layout/GridContainer';
import i18next from 'i18next';

function FilterMap() {

  const status = [
    { label: "online" },
    { label: "offline" },
    { label: "n.d." },
  ]

  const vehicles = [
    {
      vehicleId: "A01",
      time: "10:58",
      location: "Roma, Piazza Venezia",
      latitude: 41.896101,
      longitude: 12.482715,
      status: "ONLINE",
      markerRotation: "70deg",
    },
    {
      vehicleId: "A02",
      time: "09:20",
      location: "Milano, Via Torino",
      latitude: 45.514161,
      longitude: 9.338173,
      status: "ONLINE",
      markerRotation: "80deg",
    },
    {
      vehicleId: "A03",
      time: "11:05",
      location: "Gravina in Puglia",
      latitude: 40.816442,
      longitude: 16.417048,
      status: "ONLINE",
      markerRotation: "-30deg",
    },
    {
      vehicleId: "A04",
      time: "08:35",
      location: "Macnil, Via Luigi Pasteur",
      latitude: 40.828312,
      longitude: 16.443838,
      status: "ONLINE",
      markerRotation: "0deg",
    },
    {
      vehicleId: "A05",
      time: "09:20",
      location: "Potenza, SP ex SS 35",
      latitude: 40.577614,
      longitude: 15.904951,
      status: "ONLINE",
      markerRotation: "10deg",
    },
    {
      vehicleId: "A06",
      time: "09:20",
      location: "Milano, SP ex SS 35",
      latitude: 45.42117421282652,
      longitude: 9.16765881367012,
      status: "OFFLINE",
      markerRotation: "270deg",
    },
  ];


  const [, setCountries] = useState([]);
  const [, setStatus] = useState([]);
  const [searchString, setSearchString] = useState("");
  const [elements, setElements] = useState([]);
  const [count, setCount] = useState(-1);


  const addItem = (i, item) => {
    if (!elements.some(o => o.vehicleId === item.vehicleId))
      setElements([...elements.slice(0, i), item, ...elements.slice(i + 1)]);
  }

  const removeItem = (i) => {
    const elementsCopy = [...elements];
    elementsCopy.splice(i, 1);
    setElements(elementsCopy);
  }

  let component = null;
  switch (count) {
    case 0:
      component =
        <div key={0} style={{ width: '100%', padding: '16px', float: 'left', height: '100%' }} >
          <DropArea el={elements[0]} i={0} addItem={addItem} removeItem={() => removeItem(0)} closeDropArea={() => count > -1 ? setCount(count - 1) : null} />
        </div>
      break;
    case 1:
      component =
        <>
          <div key={0} style={{ width: '50%', padding: '16px 8px 16px 16px', float: 'left', height: '100%' }} >
            <DropArea el={elements[0]} i={0} addItem={addItem} removeItem={() => removeItem(0)} closeDropArea={() => count > -1 ? setCount(count - 1) : null} />
          </div>
          <div key={1} style={{ width: '50%', padding: '16px 16px 16px 8px', float: 'right', height: '100%' }} >
            <DropArea el={elements[1]} i={1} addItem={addItem} removeItem={() => removeItem(1)} closeDropArea={() => count > -1 ? setCount(count - 1) : null} />
          </div>
        </>
      break;
    case 2:
      component =
        <>
          <div key={0} style={{ width: '50%', padding: '16px 8px 8px 16px', float: 'left', height: '50%' }} >
            <DropArea el={elements[0]} i={0} addItem={addItem} removeItem={() => removeItem(0)} closeDropArea={() => count > -1 ? setCount(count - 1) : null} />
          </div>
          <div key={1} style={{ width: '50%', padding: '16px 16px 8px 8px', float: 'right', height: '50%' }} >
            <DropArea el={elements[1]} i={1} addItem={addItem} removeItem={() => removeItem(1)} closeDropArea={() => count > -1 ? setCount(count - 1) : null} />
          </div>
          <div key={2} style={{ width: '100%', float: 'left', height: '50%' }} >
            <div style={{ width: '50%', margin: '0 auto', padding: '8px 16px 16px 16px', height: '100%' }} >
              <DropArea el={elements[2]} i={2} addItem={addItem} removeItem={() => removeItem(2)} closeDropArea={() => count > -1 ? setCount(count - 1) : null} />
            </div>
          </div>
        </>
      break;
    case 3:
      component =
        <>
          <div key={0} style={{ width: '50%', padding: '16px 8px 8px 16px', float: 'left', height: '50%' }} >
            <DropArea el={elements[0]} i={0} addItem={addItem} removeItem={() => removeItem(0)} closeDropArea={() => count > -1 ? setCount(count - 1) : null} />
          </div>
          <div key={1} style={{ width: '50%', padding: '16px 16px 8px 8px', float: 'right', height: '50%' }} >
            <DropArea el={elements[1]} i={1} addItem={addItem} removeItem={() => removeItem(1)} closeDropArea={() => count > -1 ? setCount(count - 1) : null} />
          </div>
          <div key={2} style={{ width: '50%', float: 'left', height: '50%' }}>
            <div style={{ width: '100%', paddingTop: '0', padding: '8px 8px 16px 16px', float: 'left', height: '100%' }} >
              <DropArea el={elements[2]} i={2} addItem={addItem} removeItem={() => removeItem(2)} closeDropArea={() => count > -1 ? setCount(count - 1) : null} />
            </div>
          </div>
          <div key={3} style={{ width: '50%', paddingTop: '0', padding: '8px 16px 16px 8px', float: 'right', height: '50%' }} >
            <DropArea el={elements[3]} i={3} addItem={addItem} removeItem={() => removeItem(3)} closeDropArea={() => count > -1 ? setCount(count - 1) : null} />
          </div>
        </>
      break;
    default:
      component =
        <>
          <div key={0} style={{ width: '100%', padding: '16px', float: 'left', height: '100%' }} >
            <DropArea el={elements[0]} i={0} addItem={addItem} removeItem={() => removeItem(0)} closeDropArea={() => count > -1 ? setCount(count - 1) : null} />
          </div>
        </>
  }

  return (
    <ContainerWrapper>
      <GridContainer>
        <div className="map-container col-16">
          <DndProvider backend={HTML5Backend} >
            <div className="drop-area" >
              {/* <button onClick={() => count < 3 ? setCount(count + 1) : null}>split</button>
              <button onClick={() => count > 0 ? setCount(count - 1) : null}>reduce</button> */}
              <div style={{ width: '100%', height: '778px' }} >
                {component}
              </div>
              {/* <DropArea /> */}
            </div>
            <FilterBar googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY} closeDropArea={() => count > -1 ? setCount(count - 1) : null} openDropArea={() => count < 3 ? setCount(count + 1) : null} vehicles={vehicles}>
              <Form>
                <SearchField
                  id="search-field"
                  size="small"
                  placeholder={i18next.t("fleetControl.filterBar.search")}
                  value={searchString}
                  onChange={(val) =>
                    setSearchString(val)
                  }
                />
              </Form>
              <Dropdown options={countries}
                placeholder={i18next.t("fleetControl.filterBar.selectCountry")}
                size="small"
                itemAttribute="label"
                onChange={setCountries}
                hasSearch={true}
                hasCheckbox={true}
              />
              <Dropdown options={status}
                placeholder={i18next.t("fleetControl.filterBar.selectStatus")}
                size="small"
                itemAttribute="label"
                onChange={setStatus}
                hasSearch={true}
                hasCheckbox={true}
              />
            </FilterBar>
          </DndProvider>
        </div>
      </GridContainer>
    </ContainerWrapper>
  );
}

export default FilterMap;