import {
  PayloadAction,
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import _ from "lodash";
import { normalize } from "normalizr";
import { RootState } from "../../../app/store";
import { Config } from "../../../config/Config";
import { GTFleetSuccessCodes } from "../../../config/GTFleetSuccessCodes";
import GPSDataRepository from "../../route/gpsDataRepository";
import { RouteStateType } from "../../route/routesHistorySlice";
import { gpsDatasReportSchema } from "./GPSDataNormalization";

//#region Type
export type GPSDataReport = {
  uuid: number;
  vehicleId: number;
  driverId: number;
  timestamp: Date;
  consumption: number;
  dynamicFields: any;
  covered: number;
  routeType: RouteStateType;
  routeId: number;
  speed: number;
};

type PagingStateType = {
  pagingState?: string;
};

type PagingStateMapping = {
  page: number;
  pagingState: string;
  routeType: RouteStateType;
};
//#endregion Type

//#region API
// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const getGPSDataReportsAsync = createAsyncThunk(
  "gpsDataReports/getGPSDataReports",
  async (
    data: { queryParams: string; page: number; routeType: RouteStateType },
    thunkAPI
  ) => {
    const gpsDataRepository = new GPSDataRepository();
    const pagingStates: PagingStateMapping[] =
      selectGPSDataReportsSlicePagingStates(thunkAPI.getState());
    const pagingStateMap = pagingStates.find(
      (x) => x.page === data.page && x.routeType === data.routeType
    );
    const pagingState = {
      pagingState: pagingStateMap?.pagingState,
    } as PagingStateType;
    const response = await gpsDataRepository.getGPSDataReports(
      data.queryParams,
      pagingState
    );
    // The value we return becomes the `fulfilled` action payload
    const gpsData = _.get(response, Config.GPS_DATA_REPORTS_RESPONSE_PATH);
    const normalizedData = normalize(gpsData, gpsDatasReportSchema);

    const currentPagingState = _.get(
      response,
      Config.REPORTS_GPS_DATA_PAGING_STATE_RESPONSE_PATH
    );

    thunkAPI.dispatch(
      setNewPagingState({
        page: data.page + 1,
        pagingState: currentPagingState,
        routeType: data.routeType,
      })
    );
    return normalizedData.entities;
  }
);

export const getGPSDatasTravelAsync = createAsyncThunk(
  "gpsDataReports/getGPSDatasTravelAsync",
  async (data: { routeId: number }) => {
    const gpsDataRepository = new GPSDataRepository();
    const response = await gpsDataRepository.getGPSDataTravel(data.routeId);
    // The value we return becomes the `fulfilled` action payload
    const gpsDataTravel = _.get(response, Config.GPS_DATA_TRAVEL_RESPONSE_PATH);
    const normalizedData = normalize(gpsDataTravel, gpsDatasReportSchema);
    return normalizedData.entities;
  }
);
//#endregion API

//#region Custom Functions
function setFilteredData(state: any, action: PayloadAction<any>) {
  action.payload.gpsData
    ? gpsDataReportsAdapter.setAll(state, action.payload.gpsData)
    : gpsDataReportsAdapter.setAll(state, []);
}
//#endregiorn Custom Functions

//#region Slice
const gpsDataReportsAdapter = createEntityAdapter<GPSDataReport>({
  selectId: (gpsData) => gpsData.uuid,
});

export const gpsDataReportsSlice = createSlice({
  name: "gpsDataReports",
  initialState: gpsDataReportsAdapter.getInitialState({
    status: "idle",
    reasonCode: "",
    totalPages: 0,
    pagingStates: [] as PagingStateMapping[],
  }),
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setNumberOfPages: (state, action: PayloadAction<number>) => {
      state.totalPages = action.payload;
    },
    setNewPagingState: (
      state,
      action: PayloadAction<{
        page: number;
        pagingState: string;
        routeType: RouteStateType;
      }>
    ) => {
      const pagingStates = state.pagingStates;
      const newPagingState = [...pagingStates];
      const pagingStateMap = newPagingState.find(
        (x) =>
          x.page === action.payload.page &&
          x.routeType === action.payload.routeType
      );
      if (!pagingStateMap) {
        newPagingState.push({
          page: action.payload.page,
          pagingState: action.payload.pagingState,
          routeType: action.payload.routeType,
        } as PagingStateMapping);
      }
      state.pagingStates = newPagingState;
    },
    gpsDataReportsEmptyState: (state) => {
      gpsDataReportsAdapter.setAll(state, []);
      state.pagingStates = [] as PagingStateMapping[];
      state.totalPages = 0;
      state.status = "idle";
      state.reasonCode = GTFleetSuccessCodes.GET;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      //#region Entity Reducers
      // getRoutesHistoryAsync extra reducers
      .addCase(
        getGPSDataReportsAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          setFilteredData(state, action);
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.GET;
        }
      )
      .addCase(getGPSDataReportsAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(getGPSDataReportsAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = "";
      })
      .addCase(
        getGPSDatasTravelAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          setFilteredData(state, action);
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.GET;
        }
      )
      .addCase(getGPSDatasTravelAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(getGPSDatasTravelAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = "";
      });
    //#endregion Entity Reducers
  },
});
//#endregion Slice

//#region Status
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const gpsDataReportsSelectors =
  gpsDataReportsAdapter.getSelectors<RootState>(
    (state) => state.gpsDataReports
  );

export const selectGPSDataReportsSliceStatus = (state: any) =>
  state.gpsDataReports.status;
export const selectGPSDataReportsSliceReasonCode = (state: any) =>
  state.gpsDataReports.reasonCode;
export const selectGPSDataReportsSlicePage = (state: any) =>
  state.gpsDataReports.totalPages;
export const selectGPSDataReportsSlicePagingStates = (state: any) =>
  state.gpsDataReports.pagingStates;
//#endregion Status
export const { setNumberOfPages, gpsDataReportsEmptyState, setNewPagingState } =
  gpsDataReportsSlice.actions;

export default gpsDataReportsSlice.reducer;
