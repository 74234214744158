import React from "react";
import PropTypes from "prop-types";

import "./IconTitle.css";

export const IconTitle = ({ icon, title, text, className }) => {

  return (
    <div className={`mc-icon-title ${className}`}>
      <div className="mc-icon-title__icon">{icon}</div>
      <div className="mc-icon-title__title">{title}</div>
      <p className="mc-icon-title__text">{text}</p>
    </div>
  );
}

IconTitle.propTypes = {
  icon: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  /**
   * Use `mc-icon-title--absolute` to position the component absolutely.
   */
  className: PropTypes.string
}
