import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { DataLabel } from "../../../ui/DataLabel/DataLabel";
import { IconParking } from "../../../ui/Icon/Line/Parking";
import { formatTime } from "../../../utils/DateAndTimeUtils";
import { Preferences } from "../../users/preference/preferencesSlice";
import UserContext from "../../users/userContext";

interface VehicleStopReportDetailsProps {
  stops?: number;
  parkTimeTotal?: number;
  parkTimeAvg?: number;
}

export const VehicleStopReportDetails: React.FC<VehicleStopReportDetailsProps> =
  ({ stops, parkTimeTotal, parkTimeAvg }) => {
    const { t } = useTranslation();
    const [preferencesContext]: [Preferences] = useContext(UserContext);

    return (
      <>
        {stops != null && (
          <div className="details-report-item" key={1}>
            <DataLabel
              dataText={stops}
              icon={<IconParking size={14} />}
              label={t("report.details.stops")}
            />
          </div>
        )}
        {parkTimeTotal != null && (
          <div className="details-report-item" key={2}>
            <DataLabel
              dataText={formatTime(
                parkTimeTotal * 1000,
                preferencesContext.language
              )}
              icon={<IconParking size={14} />}
              label={t("report.details.parkTime")}
            />
          </div>
        )}
        {parkTimeAvg != null && (
          <div className="details-report-item" key={3}>
            <DataLabel
              dataText={formatTime(
                parkTimeAvg * 1000,
                preferencesContext.language
              )}
              icon={<IconParking size={14} />}
              label={t("report.details.parkTimeAvg")}
            />
          </div>
        )}
      </>
    );
  };
