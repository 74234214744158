import { useTranslation } from "react-i18next";
import { DataLabel } from "../../ui/DataLabel/DataLabel";
import { IconAlertSimple } from "../../ui/Icon/Line/AlertSimple";
import { IconCheck } from "../../ui/Icon/Line/Check";
import { IconTimer } from "../../ui/Icon/Line/Timer";
import { IconVehicle } from "../../ui/Icon/Line/Vehicle";
import { IconDriver } from "../../ui/Icon/Solid/Driver";

interface DashboardTachographFilesStatusDetailsProps {
  vehiclesCount?: number;
  driversCount?: number;
  expiringCount: number;
  expiredCount: number;
  okCount: number;
}

export const DashboardTachographFilesStatusDetails: React.FC<DashboardTachographFilesStatusDetailsProps> =
  ({ vehiclesCount, driversCount, expiringCount, expiredCount, okCount }) => {
    const { t } = useTranslation();

    return (
      <>
        {vehiclesCount != undefined ? (
          <div className="details-tachograph-file-item" key={0}>
            <DataLabel
              dataText={vehiclesCount}
              icon={<IconVehicle size={14} />}
              label={t("pages.dashboardTachographFile.vehicles")}
            />
          </div>
        ) : undefined}

        {driversCount != undefined ? (
          <div className="details-tachograph-file-item" key={1}>
            <DataLabel
              dataText={driversCount}
              icon={<IconDriver size={14} />}
              label={t("pages.dashboardTachographFile.drivers")}
            />
          </div>
        ) : undefined}
        <div className="details-tachograph-file-item" key={2}>
          <DataLabel
            dataText={expiringCount}
            icon={<IconTimer size={14} />}
            label={t("pages.dashboardTachographFile.details.expiring")}
          />
        </div>
        <div className="details-tachograph-file-item" key={3}>
          <DataLabel
            dataText={expiredCount}
            icon={<IconAlertSimple size={14} />}
            label={t("pages.dashboardTachographFile.details.expired")}
          />
        </div>
        <div className="details-tachograph-file-item" key={4}>
          <DataLabel
            dataText={okCount}
            icon={<IconCheck size={14} />}
            label={t("pages.dashboardTachographFile.details.ok")}
          />
        </div>
      </>
    );
  };
