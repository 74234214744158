import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import _ from "lodash";
import { normalize } from "normalizr";
import { RootState } from "../../app/store";
import { Config } from "../../config/Config";
import { GTFleetSuccessCodes } from "../../config/GTFleetSuccessCodes";
import { SeverityType } from "../route/routesHistorySlice";
import { eventsSchema } from "./eventsNormalization";
import EventsRepository from "./eventsRepository";

//#region Type

export type EventCategory = {
  id: number;
  name: string;
};

export type Event = {
  id: number;
  name: string;
  severity: SeverityType;
  category: EventCategory;
  description: string;
  hardwareSource: boolean;
};
//#endregion Type

//#region API
// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const getEventsAsync = createAsyncThunk("events/getEvents", async () => {
  const eventsRepository = new EventsRepository();
  const response = await eventsRepository.getEvents();
  const eventList = _.get(response, Config.EVENTS_RESPONSE_PATH);
  const normalizedData = normalize(eventList, eventsSchema);
  return normalizedData.entities;
});

/**
 * ATTENZIONE: QUESTO METODO PUO' ESSERE USATO PREVIA APPROVAZIONE DAL RESPONSABILE TECNICO
 *
 */
export const deleteEventAsync = createAsyncThunk(
  "events/deleteEvent",
  async (
    data: { vehicleId: number; timestamp: string; name: string },
    { rejectWithValue }
  ) => {
    try {
      const eventsRepository = new EventsRepository();
      const response = await eventsRepository.deleteEvent(
        data.vehicleId,
        data.timestamp,
        data.name
      );
      return response;
    } catch (error: any) {
      if (!error.response) throw error;
      return rejectWithValue(error.response);
    }
  }
);
//#endregion API

//#region Custom Functions
function setFilteredData(state: any, action: PayloadAction<any>) {
  action.payload.events
    ? eventsAdapter.setAll(state, action.payload.events)
    : eventsAdapter.setAll(state, []);
}
//#endregiorn Custom Functions

//#region Slice
const eventsAdapter = createEntityAdapter<Event>({
  selectId: (Event) => Event.id,
  sortComparer: (a, b) => a.id.toString().localeCompare(b.id.toString()),
});

export const eventsSlice = createSlice({
  name: "eventsSlice",
  initialState: eventsAdapter.getInitialState({
    status: "idle",
    reasonCode: "",
    totalPages: 0,
  }),
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    eventsEmptyState: (state: any) => {
      eventsAdapter.setAll(state, []);
      state.reasonCode = "";
      state.status = "idle";
    },
    setNumberOfPages: (state, action: PayloadAction<number>) => {
      state.totalPages = action.payload;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      //#region Entity Reducers
      // getRoutesHistoryAsync extra reducers
      .addCase(
        getEventsAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          setFilteredData(state, action);
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.GET;
        }
      )
      .addCase(getEventsAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(getEventsAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = "";
      })
      .addCase(
        deleteEventAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          setFilteredData(state, action);
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.GET;
        }
      )
      .addCase(deleteEventAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(deleteEventAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = "";
      });
    //#endregion Entity Reducers
  },
});
//#endregion Slice

//#region Status
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const eventsSelectors = eventsAdapter.getSelectors<RootState>(
  (state) => state.events
);

export const selecteventsSliceStatus = (state: any) => state.events.status;
export const selecteventsSliceReasonCode = (state: any) =>
  state.events.reasonCode;

export const { eventsEmptyState } = eventsSlice.actions;

//#endregion Status

export default eventsSlice.reducer;
