import HttpClient from "../../utils/HttpClient";
import { MessageRecipients } from "./chatSlice";

export default class ChatRepository extends HttpClient {
  constructor() {
    super(true);
  }

  getExistentChat(userId: number) {
    const tenantId = localStorage.getItem("tenantId");

    return this.instance.get(
      "/tenants/" + tenantId + "/users/" + userId + "/chats"
    );
  }

  getChatAliases(externalQueryParams?: string) {
    const tenantId = localStorage.getItem("tenantId");
    let queryParams = "";
    if (!!externalQueryParams) {
      queryParams = externalQueryParams + "&";
    } else {
      queryParams = "?";
    }
    queryParams += "tenant.id=" + tenantId;

    return this.instance.get("/chatAliases" + queryParams);
  }

  getChatMessages(chatId: number, userId: number, customQueryParams?: string) {
    const tenantId = localStorage.getItem("tenantId");
    let path = "/tenants/" + tenantId + "/users/" + userId + "/chats/" + chatId;
    let queryParams = "";
    if (customQueryParams) {
      queryParams = customQueryParams;
    } else {
      queryParams = "?";
    }
    return this.instance.get(path + queryParams);
  }

  sendMessage(messageRecipients: MessageRecipients, userId: number) {
    const tenantId = localStorage.getItem("tenantId");

    return this.instance.post(
      "/tenants/" + tenantId + "/users/" + userId + "/chats",
      messageRecipients
    );
  }

  getMessageStatus(queryParams: string) {
    const tenantId = localStorage.getItem("tenantId");

    return this.instance.get(
      "/tenants/" + tenantId + "/message/status" + queryParams
    );
  }
}
