import PropTypes from "prop-types";
import { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next";
import { Button } from "../../Button/Button";
import { Checkbox } from "../../Forms/Checkbox";
import Form from "../../Forms/Form";
import TextField from "../../Forms/TextField";
import { IconPhone } from "../../Icon/Line/Phone";
import { LabelText } from "../../LabelText/LabelText";
import { ModalBody } from "../ModalBody";
import { ModalFooter } from "../ModalFooter";
import { ModalWrapper } from "../ModalWrapper";
import { FeedbackModal } from "./FeedbackModal";
import "./HelpRequestModal.css";

export const HelpRequestModal = ({ open, onClose }) => {
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);

  const LeftColumn = ({ onColumnClose }) => {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [company, setCompany] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [eMail, setEMail] = useState("");
    const [subject, setSubject] = useState("");
    const [description, setDescription] = useState("");
    const [isRecaptched, setIsRecaptched] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errorList, setErrorList] = useState([]);

    const clearFields = () => {
      setFirstName("");
      setLastName("");
      setCompany("");
      setPhoneNumber("");
      setEMail("");
      setSubject("");
      setDescription("");
      setIsChecked(false);
    };

    return (
      <>
        <div className="left-help-column">
          <ModalBody
            title={t("customModals.helpRequest.title")}
            desc={t("customModals.helpRequest.desc")}
          >
            <div className="left-forms">
              <Form>
                <LabelText text={t("customModals.helpRequest.name")} />
                <TextField
                  id="First-name"
                  placeholder={t("customModals.helpRequest.namePlaceholder")}
                  validate="alphabetic"
                  errorList={setErrorList}
                  events={{ onChange: (data) => setFirstName(data.value) }}
                  value={firstName}
                ></TextField>
              </Form>
            </div>
            <div className="right-forms">
              <Form>
                <LabelText text={t("customModals.helpRequest.surname")} />
                <TextField
                  id="Last-name"
                  placeholder={t("customModals.helpRequest.surnamePlaceholder")}
                  validate="alphabetic"
                  errorList={setErrorList}
                  events={{ onChange: (data) => setLastName(data.value) }}
                  value={lastName}
                ></TextField>
              </Form>
            </div>
            <div className="left-forms">
              <LabelText text={t("customModals.helpRequest.company")} />
              <Form>
                <TextField
                  id="Company"
                  placeholder={t("customModals.helpRequest.companyPlaceholder")}
                  validate="alphaNumeric"
                  errorList={setErrorList}
                  events={{ onChange: (data) => setCompany(data.value) }}
                  value={company}
                ></TextField>
              </Form>
            </div>
            <div className="right-forms">
              <LabelText text={t("customModals.helpRequest.phone")} />
              <Form>
                <TextField
                  id="Phone-number"
                  placeholder={t("customModals.helpRequest.phonePlaceholder")}
                  validate="phoneNumber"
                  errorList={setErrorList}
                  events={{
                    onChange: (data) => setPhoneNumber(data.value),
                  }}
                  value={phoneNumber}
                ></TextField>
              </Form>
            </div>
            <div className="left-forms">
              <LabelText text={t("customModals.helpRequest.email")} />
              <Form>
                <TextField
                  id="E-mail"
                  placeholder={t("customModals.helpRequest.emailPlaceholder")}
                  validate="email"
                  errorList={setErrorList}
                  events={{ onChange: (data) => setEMail(data.value) }}
                  value={eMail}
                ></TextField>
              </Form>
            </div>
            <div className="right-forms">
              <LabelText text={t("customModals.helpRequest.subject")} />
              <Form>
                <TextField
                  id="Subject"
                  placeholder={t("customModals.helpRequest.subjectPlaceholder")}
                  validate="alphabetic"
                  errorList={setErrorList}
                  events={{
                    onChange: (data) => setSubject(data.value),
                  }}
                  value={subject}
                ></TextField>
              </Form>
            </div>
            <div className="central-forms">
              <LabelText text={t("customModals.helpRequest.description")} />
              <Form>
                <TextField
                  id="Description"
                  type="textarea"
                  placeholder={t(
                    "customModals.helpRequest.descriptionPlaceholder"
                  )}
                  events={{
                    onChange: (data) => setDescription(data.value),
                  }}
                  value={description}
                ></TextField>
              </Form>
            </div>
            <div className="selection-control">
              <Checkbox
                label={t("customModals.helpRequest.checkbox")}
                checked={isChecked}
                onChange={(e) => setIsChecked(e.target.checked)}
              />
            </div>
            <div className="company-rectangle">
              <div className="company-text">
                <p>{t("customModals.helpRequest.companyParagraphOne")}</p>

                {t("customModals.helpRequest.companyParagraphTwo")}
              </div>
              <div className="captcha">
                <ReCAPTCHA
                  sitekey="6LdyHDEfAAAAAKQpc0DdEoXwhVg3D-hyTZ110_MU"
                  onChange={() => setIsRecaptched(true)}
                  hl={t("common.reCaptcha")}
                />
              </div>
            </div>
          </ModalBody>
          <ModalFooter
            primaryLabel={t("customModals.helpRequest.primaryButton")}
            primaryAction={() => {
              setIsLoading(true);
              clearFields();
              onClose();
              setShowFeedbackModal(true);
            }}
            secondaryLabel={t("customModals.helpRequest.secondaryButton")}
            secondaryAction={onColumnClose}
            disablePrimaryButton={
              firstName === "" ||
              lastName === "" ||
              phoneNumber === "" ||
              eMail === "" ||
              subject === "" ||
              description === "" ||
              !isChecked ||
              !isRecaptched ||
              errorList.length !== 0
            }
            loadingPrimaryButton={isLoading}
          />
        </div>
      </>
    );
  };
  const RightColumn = () => {
    return (
      <>
        <div className="right-help-column" style={{ fontFamily: "" }}>
          <ModalBody
            isScrollable={false}
            title={t("customModals.helpRequest.titleRight")}
            desc={t("customModals.helpRequest.descRight")}
          >
            <Button
              aspect="secondary"
              size="regular"
              label="+39 080 246 42 45"
              onlyIcon={false}
            >
              <IconPhone size={14} color="--global-colors-ink-light" />
            </Button>
          </ModalBody>
        </div>
      </>
    );
  };

  const { t } = useTranslation();
  return (
    <div className="help">
      <ModalWrapper
        open={open}
        closeAction={onClose}
        leftColumn={<LeftColumn onColumnClose={onClose} />}
        rightColumn={<RightColumn />}
      ></ModalWrapper>
      <FeedbackModal
        open={showFeedbackModal}
        closeAction={() => setShowFeedbackModal(false)}
        title={t("customModals.feedback.title")}
        desc={t("customModals.feedback.desc")}
        primaryLabel={t("customModals.feedback.primaryButton")}
        primaryAction={() => setShowFeedbackModal(false)}
        text={t("customModals.helpRequest.feedbackResult")}
      />
    </div>
  );
};

HelpRequestModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

HelpRequestModal.defaultProps = {
  open: false,
};
