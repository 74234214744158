import i18next from "i18next";
import _ from "lodash";
import { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useAppSelector } from "../../../app/hooks";
import { store } from "../../../app/store";
import { SpeedReportModal } from "../../../ui/Modal/CustomModals/SpeedReportModal";
import { Preferences } from "../../users/preference/preferencesSlice";
import UserContext from "../../users/userContext";
import { vehiclesSelectors } from "../../vehicle/vehiclesSlice";
import {
  SpeedDataReport,
  getSpeedDataReportsAsync,
  selectSpeedDataReportsSliceStatus,
  speedDataReportsEmptyState,
  speedDataReportsSelectors,
} from "./speedReportSlice";

export type VehicleType = {
  id: number;
  label: string;
  speedLimit: number;
};

interface SpeedReportProps {
  open: boolean;
  onClose: () => any;
}

const SpeedReport: React.FC<SpeedReportProps> = ({ open, onClose }) => {
  const [selectedVehicle, setSelectedVehicle] = useState({} as VehicleType);

  const [preferencesContext]: [Preferences] = useContext(UserContext);
  const reportsSliceStatus = useAppSelector(selectSpeedDataReportsSliceStatus);
  const speedDataReports: SpeedDataReport[] = useAppSelector(
    speedDataReportsSelectors.selectAll
  );
  const [searchParams] = useSearchParams();
  const vehicles = useAppSelector(vehiclesSelectors.selectAll);
  const [vehicleTypeList, setVehicleTypeList] = useState<VehicleType[]>([]);
  const [timeRange, setTimeRange] = useState<string>("");

  useEffect(() => {
    return () => {
      store.dispatch(speedDataReportsEmptyState());
    };
  }, []);

  useEffect(() => {
    const newVehicleTypeList: VehicleType[] = [];
    const vehicleIds = searchParams.getAll("vehicleIds");
    const startPeriodTemp = searchParams.get("startPeriod");
    const endPeriodTemp = searchParams.get("endPeriod");

    if (!!startPeriodTemp && !!endPeriodTemp) {
      if (startPeriodTemp + ";" + endPeriodTemp !== timeRange) {
        setTimeRange(startPeriodTemp + ";" + endPeriodTemp);
      }
    }
    // Fetch vehicle details for each vehicle ID in the query parameters
    vehicleIds.forEach((vehicleId: string) => {
      const id = parseInt(vehicleId);
      const vehicle = vehiclesSelectors.selectById(store.getState(), id);
      if (vehicle) {
        newVehicleTypeList.push({
          id: vehicle.id,
          label: `${vehicle.alias} - ${vehicle.plate}`,
          speedLimit: Number(vehicle.maxSpeed),
        });
      }
    });
    setVehicleTypeList(newVehicleTypeList);
    if (newVehicleTypeList.length > 0) {
      setSelectedVehicle(newVehicleTypeList[0]);
    }
  }, [searchParams, vehicles]);

  useEffect(() => {
    if (open) {
      if (!_.isEmpty(selectedVehicle)) {
        if (timeRange !== "") {
          const finalQueryParams = `?startPeriod=${
            timeRange.split(";")[0]
          }&endPeriod=${timeRange.split(";")[1]}&vehicleId=${
            selectedVehicle.id
          }`;
          store.dispatch(
            getSpeedDataReportsAsync({
              queryParams: finalQueryParams,
            })
          );
        }
      }
    }
  }, [selectedVehicle, timeRange, open]);

  return (
    <>
      {!_.isEmpty(selectedVehicle) && (
        <SpeedReportModal
          title={i18next.t("customModals.speedGraph.title")}
          desc={`${i18next.t("common.vehicle")} ${selectedVehicle.label}`}
          open={open}
          series={
            speedDataReports.length > 0 &&
            speedDataReports[0].vehicleSpeedData.length > 0
              ? speedDataReports[0].vehicleSpeedData.map((el) => {
                  return {
                    timestamp: el.timestamp,
                    speed: el.speed,
                  };
                })
              : [
                  { timestamp: new Date(timeRange.split(";")[0]), speed: 0 },
                  { timestamp: new Date(timeRange.split(";")[1]), speed: 0 },
                ]
          }
          vehicleList={vehicleTypeList}
          getVehicleSelected={setSelectedVehicle}
          onClose={onClose}
          preferences={preferencesContext}
          isLoading={reportsSliceStatus === "loading"}
        />
      )}
    </>
  );
};

export default SpeedReport;
