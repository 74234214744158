import i18next from "i18next";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next";
import { ToastNotification } from "../../../utils/ToastNotification";
import Form from "../../Forms/Form";
import TextField from "../../Forms/TextField";
import { LabelText } from "../../LabelText/LabelText";
import { ModalBody } from "../ModalBody";
import { ModalFooter } from "../ModalFooter";
import { ModalWrapper } from "../ModalWrapper";
import "./ResetPasswordModal.css";

export const ResetPasswordModal = ({
  open,
  onClose,
  handleGenerateCode,
  setShowResetPswSupport,
  setShowVerifyCode,
  getEmail,
}) => {
  const { t } = useTranslation();

  const [eMail, setEMail] = useState("");
  const [isRecaptched, setIsRecaptched] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorList, setErrorList] = useState([]);

  const clearFields = () => {
    setEMail("");
    setIsRecaptched(false);
  };

  const primaryAction = async () => {
    setIsLoading(true);
    try {
      await handleGenerateCode(eMail);
      setIsLoading(false);
      clearFields();
      onClose();
      setShowVerifyCode(true);
    } catch (error) {
      console.log("Email not found:", error);
      if (error.response.status === 404) {
        setIsLoading(false);
        onClose();
        setShowResetPswSupport(true);
        getEmail(eMail);
      } else if (error.response.status === 500) {
        ToastNotification({
          toastId: "serverError",
          status: "error",
          description: i18next.t("common.serverError"),
        });
      }
    }
  };

  const submitModalRef = useRef();
  useEffect(() => {
    const keyUp = (event) => {
      if (event.keyCode === 13) {
        event.preventDefault();
        primaryAction();
      }
    };

    if (submitModalRef && submitModalRef.current && eMail) {
      submitModalRef.current.addEventListener("keydown", keyUp);
      let parentRef = submitModalRef;
      return () => {
        parentRef.current &&
          parentRef.current.removeEventListener("keydown", keyUp);
      };
    }
  });

  return (
    <div className="reset-password-container" ref={submitModalRef}>
      <ModalWrapper open={open} closeAction={onClose}>
        <div className="reset-password">
          <ModalBody
            title={t("customModals.resetPassword.title")}
            desc={t("customModals.resetPassword.desc")}
          >
            <div className="central-forms">
              <LabelText text={t("customModals.resetPassword.eMail")} />
              <Form>
                <TextField
                  id="E-mail"
                  placeholder={t("customModals.resetPassword.eMailPlaceholder")}
                  validate="email"
                  errorList={setErrorList}
                  events={{ onChange: (data) => setEMail(data.value) }}
                  value={eMail}
                />
              </Form>
            </div>
            <div className="captcha">
              <ReCAPTCHA
                sitekey="6LdyHDEfAAAAAKQpc0DdEoXwhVg3D-hyTZ110_MU"
                onChange={() => setIsRecaptched(true)}
                hl={t("common.reCaptcha")}
              />
            </div>
          </ModalBody>
          <ModalFooter
            primaryLabel={t("customModals.resetPassword.primaryButton")}
            primaryAction={() => {
              primaryAction();
            }}
            secondaryLabel={t("customModals.resetPassword.secondaryButton")}
            secondaryAction={() => {
              onClose();
              setShowVerifyCode(true);
            }}
            disablePrimaryButton={
              eMail === "" || !isRecaptched || errorList.length !== 0
            }
            loadingPrimaryButton={isLoading}
          />
        </div>
      </ModalWrapper>
    </div>
  );
};
ResetPasswordModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  setShowResetPswSupport: PropTypes.func,
  setShowVerifyCode: PropTypes.func,
  getEmail: PropTypes.func,
  handleGenerateCode: PropTypes.func,
};

ResetPasswordModal.defaultProps = {
  open: false,
};
