import HttpClient from "../../../utils/HttpClient";

export default class PrivilegesDefaultRepository extends HttpClient {
  constructor() {
    super(true);
  }

  getPrivilegesDefault(id: number) {
    return this.instance.get("securityprofiles/" + id + "/privileges");
  }
}
