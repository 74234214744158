import { t } from "i18next";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import {
  Location,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { TypeOptions } from "react-toastify";
import { useAppSelector } from "../../app/hooks";
import { store } from "../../app/store";
import { TableColumnEntry, TableColumns } from "../../config/TableConfig";
import { Dropdown } from "../../ui/Dropdown/Dropdown";
import Form from "../../ui/Forms/Form";
import SearchField from "../../ui/Forms/SearchField";
import { CustomizeColumnsModal } from "../../ui/Modal/CustomModals/CustomizeColumnsModal";
import { ToastNotification } from "../../utils/ToastNotification";
import { getPresetsNotification, getQueryString } from "../../utils/Utils";
import {
  fleetViewsEmptyState,
  fleetViewsSelectors,
  getFleetViewsAsync,
} from "../fleet/fleetViewsSlice";
import {
  Preset,
  addPreset,
  deletePresetAsync,
  newPresetAsync,
  removePreset,
  restoreState,
  selectpresetsSliceReasonCode,
  selectpresetsSliceStatus,
  updatePresetAsync,
} from "../users/preset/presetsSlice";
import "./DashboardDriversFilterBar.css";
import { Driver, driversEmptyState, driversSelectors } from "./driversSlice";
import {
  driverStatusType,
  getFilteredDriversStatusAndDetailsPaginationAsync,
} from "./driversStatusSlice";

interface QueryParams {
  [paramName: string]: any;
}

interface DropDownItem {
  key: string;
  label: string;
}

interface FleetItem {
  key: string;
  label: string;
  count: number;
}

interface StatusItem {
  key: string;
  label: string;
}

interface DashboardDriversFilterBarProps {
  presets: any[];
  callback: (buildQueryParam: string) => any;
}

let context: string = "driverDashboard";

export const DashboardDriversFilterBar: React.FC<DashboardDriversFilterBarProps> =
  ({ presets, callback }) => {
    const navigate = useNavigate();

    const [idDriverVehicle, setIdDriverVehicle] = useState("");
    const [checkedGroupsValues, setCheckedGroupsValues] = useState<
      DropDownItem[]
    >([]);
    const [checkedStatusesValues, setCheckedStatusesValues] = useState<
      DropDownItem[]
    >([]);
    const queryParamsRef = useRef<QueryParams>({});
    let queryParams: QueryParams = queryParamsRef.current;

    const drivers: Driver[] = useAppSelector((state: any) =>
      driversSelectors.selectAll(state)
    );

    const presetsSliceStatus = useAppSelector(selectpresetsSliceStatus);
    const presetsSliceReasonCode = useAppSelector(selectpresetsSliceReasonCode);

    const [searchParams] = useSearchParams();
    const location: Location = useLocation();
    const [driverDashboardAllColumns, setDriverDashboardAllColumns] = useState<
      TableColumnEntry[]
    >([] as TableColumnEntry[]);

    useEffect(() => {
      store.dispatch(getFleetViewsAsync());
      setDriverDashboardAllColumns(
        new TableColumns().getDriverDashboardTableColumns()
      );
      store.dispatch(getFleetViewsAsync());

      return () => {
        store.dispatch(driversEmptyState());
        store.dispatch(fleetViewsEmptyState());
      };
    }, []);

    useEffect(() => {
      let presetNotification: {
        status: string | undefined;
        description: string | undefined;
      } = getPresetsNotification(presetsSliceStatus, presetsSliceReasonCode);
      if (
        presetNotification.status !== undefined &&
        presetNotification.description !== undefined
      ) {
        ToastNotification({
          toastId: t(presetNotification.description),
          status: presetNotification.status as TypeOptions,
          description: t(presetNotification.description),
        });
        store.dispatch(restoreState());
      }
    }, [presetsSliceStatus, presetsSliceReasonCode]);

    const updatePreset = (newPreset: Preset) => {
      if (newPreset?.columns?.length > 0) {
        let temporaryPreset = presets.find(
          (preset) => preset.name === undefined
        );
        if (temporaryPreset) {
          store.dispatch(removePreset(temporaryPreset.id));
        }
        if (newPreset.name) {
          if (newPreset.name !== "Default" && newPreset.context === undefined) {
            newPreset.context = context;
            newPreset.lastSelected = true;
            store.dispatch(newPresetAsync(newPreset));
          } else {
            store.dispatch(
              updatePresetAsync({
                id: newPreset.id,
                preset: {
                  lastSelected: true,
                },
                context: context,
              })
            );
          }
          return true;
        } else {
          newPreset.id = Math.max(...presets.map((preset) => preset.id)) + 1;
          store.dispatch(addPreset(newPreset));
          return false;
        }
      }
    };

    useEffect(() => {
      const map = new Map();
      const currentSearchParams =
        searchParams.toString() !== "" ? searchParams : null;
      if (!!currentSearchParams && _.isEmpty(queryParams)) {
        const size = currentSearchParams.get("size");
        map.set("size", size ?? "10");

        const firstName = currentSearchParams.get("firstName");
        const lastName = currentSearchParams.get("lastName");
        if (firstName) {
          setIdDriverVehicle(firstName);
          map.set("firstName", firstName);
          map.set("lastName", lastName);
        }

        // Fleet Dropdown
        const fleetNames = currentSearchParams.getAll("fleet.name");
        if (!!fleetNames) {
          const selectedFleetNames: DropDownItem[] = [];
          fleetNames.forEach((fleetName) => {
            selectedFleetNames.push({
              key: fleetName,
              label: fleetName,
            } as DropDownItem);
          });
          if (selectedFleetNames && selectedFleetNames.length > 0) {
            setCheckedGroupsValues(selectedFleetNames);
            map.set(
              "fleet.name",
              selectedFleetNames.map((x) => x.key)
            );
          }
        }

        // Statuses Dropdown
        const driversStatuses = currentSearchParams.getAll("driverStatus");
        if (!!driversStatuses) {
          const selectedStatuses: DropDownItem[] = [];
          driversStatuses.forEach((driverStatus) => {
            selectedStatuses.push({
              key: driverStatus,
              label:
                driverStatus.charAt(0).toUpperCase() +
                driverStatus.slice(1).toLocaleLowerCase(),
            } as DropDownItem);
          });
          if (selectedStatuses && selectedStatuses.length > 0) {
            setCheckedStatusesValues(selectedStatuses);
            map.set(
              "driverStatus",
              selectedStatuses.map((x) => x.key)
            );
          }
        }
      }
      if (map.size === 0 && _.isEmpty(queryParams)) {
        map.set("size", "10");
      }
      if (map.size > 0) {
        handleChanges(map);
      }
    }, [location]);

    const handleChanges = (params: Map<string, string[] | string>): void => {
      let queryString = "";
      let executeQuery = false;
      if (!!params) {
        params.forEach((value, key) => {
          if (!!value && value.length > 0) {
            queryParams[key] = value;
            executeQuery = true;
          } else {
            if (queryParams.hasOwnProperty(key)) {
              delete queryParams[key];
              executeQuery = true;
            }
          }
        });
        queryString = getQueryString(queryParams);
      }
      // effettuare chiamata API con parametri in query string
      if (executeQuery) {
        store.dispatch(
          getFilteredDriversStatusAndDetailsPaginationAsync({
            queryParams: queryString,
          })
        );
        navigate({
          pathname: "/dashboard/drivers",
          search: queryString,
        });
        callback(queryString);
      }
    };

    const fleets = fleetViewsSelectors.selectAll(store.getState());

    const groupsValues: FleetItem[] = !!fleets
      ? fleets.map((value) => {
          return {
            label: value.name,
            key: value.name,
            count: value.driversSize,
          };
        })
      : [];

    const statusesValues: StatusItem[] =
      Object.values(driverStatusType)
        .filter((value) => typeof value === "string")
        .map((value) => {
          return {
            key: value,
            label:
              value.charAt(0).toUpperCase() +
              value.slice(1).toLocaleLowerCase(),
          };
        }) ?? [];

    const debouncedSearch = useRef(
      _.debounce(async (val) => {
        setIdDriverVehicle(val);
        const map = new Map();
        const splittedVal = val.split(" ");
        map.set("firstName", splittedVal);
        map.set("lastName", splittedVal);
        handleChanges(map);
      }, 1000)
    ).current;

    useEffect(() => {
      return () => {
        debouncedSearch.cancel();
      };
    }, [debouncedSearch]);

    async function handleSearch(val: string) {
      await debouncedSearch(val);
    }

    return (
      <div className="vehicle-filterbar-row-splitter">
        <>
          <div className="vehicle-filterbar-search">
            <Form>
              <SearchField
                name="search"
                id="driverName"
                size="small"
                placeholder={t(
                  "fleetControl.filterBar.optionOrderBy.driverName"
                )}
                value={idDriverVehicle}
                onChange={(val) => {
                  handleSearch(val);
                }}
              />
            </Form>
          </div>
          <div className="vehicle-dropdown-groups">
            <Dropdown
              placeholderInValue={t(
                "fleetControl.filterBar.placeholderInValGroups"
              )}
              hasCheckbox={true}
              itemAttribute="label"
              placeholder={t("fleetControl.filterBar.searchGroups")}
              size={"small"}
              onChange={(val: DropDownItem[]) => {
                const map = new Map();
                const groupNames: string[] = [];
                val.forEach((v) => {
                  const groupName = groupsValues.find(
                    (groupValue) => groupValue.key === v.key
                  )?.key;
                  if (groupName) {
                    groupNames.push(groupName);
                  }
                });
                map.set("fleet.name", groupNames);
                handleChanges(map);
              }}
              options={groupsValues}
              value={checkedGroupsValues}
            />
          </div>
          <div className="vehicle-dropdown-statuses">
            <Dropdown
              placeholderInValue={t(
                "fleetControl.filterBar.placeholderInValStatus"
              )}
              itemAttribute="label"
              hasCheckbox={true}
              placeholder={t(
                "fleetControl.filterBar.optionOrderBy.signalState"
              )}
              size={"small"}
              onChange={(val: DropDownItem[]) => {
                const map = new Map();
                const statusValues: string[] = [];
                val.forEach((v) => {
                  const statusValue = statusesValues.find(
                    (statusValue) => statusValue.key === v.key
                  )?.key;
                  if (statusValue) {
                    statusValues.push(statusValue);
                  }
                });
                map.set("driverStatus", statusValues);
                handleChanges(map);
              }}
              options={statusesValues}
              value={checkedStatusesValues}
            />
          </div>
          <div className="vehicle-dropdown-customize">
            <CustomizeColumnsModal
              columns={driverDashboardAllColumns}
              presets={presets ?? []}
              onClose={(newPreset) => updatePreset(newPreset)}
              handleDelete={(e: any) => store.dispatch(deletePresetAsync(e))}
            />
          </div>
        </>
      </div>
    );
  };
