import i18next from "i18next";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import Form from "../Forms/Form";
import TextField from "../Forms/TextField";
import { IconAdd } from "../Icon/Line/Add";
import { IconMail } from "../Icon/Line/Mail";
import { IconMinus } from "../Icon/Line/Minus";
import "./ScheduleNote.css";

interface ScheduleNoteProps {
  defaultNote?: string;
  open?: boolean;
  typeIsEmpty?: boolean;
  getValue?: (e: any) => any;
  setIsOpened?: (e: any) => any;
}

export const ScheduleNote: React.FC<ScheduleNoteProps> = ({
  defaultNote,
  open,
  typeIsEmpty,
  getValue,
  setIsOpened,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(open ?? false);
  const [note, setNote] = useState(defaultNote ?? "");
  const [selectedNote, setSelectedNote] = useState<string>(defaultNote ?? "");

  useEffect(() => {
    if (!typeIsEmpty) {
      setIsOpen(open ?? false);
      note !== "" && setSelectedNote(note);
    }
  }, [open]);

  const toggleGroup = () => {
    setIsOpened && setIsOpened(3);
    setIsOpen((prevState) => !prevState);
  };

  const handleReset = (e: any) => {
    setNote("");
    setSelectedNote("");
    if (getValue) {
      getValue("");
    }
    e.stopPropagation();
  };

  return (
    <>
      <div
        className={isOpen ? "schedule-note-simple" : "schedule-note"}
        onClick={() => {
          if (!typeIsEmpty) {
            setSelectedNote(note);
            toggleGroup();
          }
        }}
      >
        <div className="schedule-note-button">
          <div className="schedule-note-circle">
            <IconMail size={14} color="--global-colors-ui-primary" />
          </div>
          <div className="schedule-note-label">
            {selectedNote !== "" && (
              <>
                <span className="schedule-note-pretitle">
                  {i18next.t("schedule.noteTitle")}
                </span>
                <br />
              </>
            )}
            <span className="schedule-note-title">
              {selectedNote === ""
                ? i18next.t("schedule.noteTitle")
                : selectedNote}
            </span>
          </div>
          <span className="schedule-note-icon">
            {isOpen ? (
              <>
                <div className="schedule-note-reset" onClick={handleReset}>
                  {i18next.t("common.reset")}
                </div>
                <IconMinus size={14} color="--global-colors-ui-primary" />
              </>
            ) : (
              <IconAdd size={14} color="--global-colors-ui-primary" />
            )}
          </span>
        </div>
      </div>
      {isOpen && (
        <div className="schedule-note-body">
          <span className="schedule-note-selection">
            {i18next.t("schedule.noteSelection")}
          </span>
          <div className="schedule-note-form">
            <Form>
              <TextField
                id="Description"
                type="textarea"
                placeholder={i18next.t("schedule.notePlaceholder")}
                events={{
                  onChange: (data) => {
                    setNote(data.value);
                    if (getValue) {
                      getValue(data.value);
                    }
                  },
                }}
                value={note}
              ></TextField>
            </Form>
          </div>
        </div>
      )}
    </>
  );
};

ScheduleNote.propTypes = {
  defaultNote: PropTypes.string,
  open: PropTypes.bool,
  typeIsEmpty: PropTypes.bool,
  getValue: PropTypes.func,
  setIsOpened: PropTypes.func,
};

ScheduleNote.defaultProps = {
  open: false,
};
