import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { GTFleetSuccessCodes } from "../../config/GTFleetSuccessCodes";
import { getVehicleAsync } from "../vehicle/vehiclesStatusSlice";

//#region Type
export type Asset = {
  id: number;
  name: string;
  decription: string;
  imageUrl: string;
  sku: string;
};
//#endregion Type

//#region Slice
const assetsAdapter = createEntityAdapter<Asset>({
  selectId: (asset) => asset.id,
  sortComparer: (a, b) => a.id.toString().localeCompare(b.id.toString()),
});

export const assetSlice = createSlice({
  name: "assets",
  initialState: assetsAdapter.getInitialState({
    status: "idle",
    reasonCode: "",
  }),
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      //#region External Entity Reducers
      .addCase(
        getVehicleAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          if (action.payload?.asset) {
            assetsAdapter.upsertMany(state, action.payload.asset);
          }
          state.reasonCode = GTFleetSuccessCodes.GET;
          state.status = "idle";
        }
      )
      .addCase(getVehicleAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = "";
      })
      .addCase(getVehicleAsync.pending, (state: any) => {
        state.status = "loading";
      });

    //#endregion External Entity Reducers
  },
});
//#endregion Slice

//#region Status
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const assetsSelectors = assetsAdapter.getSelectors<RootState>(
  (state) => state.assets
);

export const selectAssetsSliceReasonCode = (state: any) =>
  state.assets.reasonCode;
export const selectAssetsSliceStatus = (state: any) => state.assets.status;
//#endregion Status

export default assetSlice.reducer;
