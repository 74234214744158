import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import { t } from "i18next";
import _ from "lodash";
import React, { useContext, useEffect, useState } from "react";
import {
  Location,
  useLocation,
  useMatch,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { store } from "../../app/store";
import ContentSidebar from "../../layout/ContentSidebar";
import PageContent from "../../layout/PageContent";
import PageFilters from "../../layout/PageFilters";
import { BackToSummary } from "../../ui/LocationHistory/BackToSummary";
import { BreakRow } from "../../ui/LocationHistory/BreakRow";
import { Divider } from "../../ui/LocationHistory/Divider";
import { SingleTrackRow } from "../../ui/LocationHistory/SingleTrackRow";
import { TrackSummary } from "../../ui/LocationHistory/TrackSummary";
import { Sidebar } from "../../ui/Navigation/Sidebar";
import { formatTotalTime } from "../../utils/DateAndTimeUtils";
import { ToastNotification } from "../../utils/ToastNotification";
import { getAddressView } from "../../utils/Utils";
import { LocationHistoryMap } from "../components/LocationHistoryMap";
import {
  RouteHistory,
  readRouteHistoryAsync,
  routeHistoryEmptyRoutes,
  routesHistorySelectors,
  selectRoutesHistorySliceReasonCode,
  selectRoutesHistorySliceStatus,
} from "../route/routesHistorySlice";

import { getRoutesHistoryViewAsync } from "../route/routesHistoryViewSlice";
import { Preferences } from "../users/preference/preferencesSlice";
import { UserPermissions } from "../users/privilege/privilegesSlice";
import UserContext from "../users/userContext";
import { Details as TrackSummaryDetails } from "./../../ui/LocationHistory/TrackSummary";
import "./DriversLocationHistory.css";
import { DriversLocationHistoryFilterBar } from "./DriversLocationHistoryFilterBar";
dayjs.extend(duration);

interface DriversLocationHistoryProps {
  permissions: UserPermissions;
}

export type RoutePoint = {
  location: string;
  time: string;
};
export type RouteHistoryView = {
  start: RoutePoint;
  end: RoutePoint;
};

export const DriversLocationHistory: React.FC<DriversLocationHistoryProps> = ({
  permissions,
}) => {
  let details: TrackSummaryDetails = {} as TrackSummaryDetails;
  const [queryParamsFromFilterBar, setQueryParamsFromFilterBar] =
    useState<string>("");
  const [expanded, setExpanded] = useState(true);
  const [currentActive, setCurrentActive] = useState(null as any);
  const [showSummary, setShowSummary] = useState(false);
  const [enableFakeMap, setEnableFakeMap] = useState(true);
  const [filterBarHasChanged, setFilterBarHasChanged] = useState(false);
  const [openTrackDetails, setOpenTrackDetails] = useState(false);
  const [openStopDetails, setOpenStopDetails] = useState(false);
  const [currentActiveType, setCurrentActiveType] = useState(
    "" as "TRACK" | "STOP" | "NONE"
  );

  const infoTrack: RouteHistory | undefined = useAppSelector((state) =>
    routesHistorySelectors.selectById(state, currentActive ?? -1)
  );

  const navigate = useNavigate();
  const [preferencesContext]: [Preferences] = useContext(UserContext);

  const routesHistory: RouteHistory[] = useAppSelector(
    routesHistorySelectors.selectAll
  );

  const routesHistorySliceStatus = useAppSelector(
    selectRoutesHistorySliceStatus
  );
  const routesHistorySliceReasonCode = useAppSelector(
    selectRoutesHistorySliceReasonCode
  );

  useEffect(() => {
    if (
      routesHistorySliceStatus === "failed" &&
      routesHistorySliceReasonCode === ""
    ) {
      console.error(t("common.networkError"));
      ToastNotification({
        toastId: "networkError",
        status: "error",
        description: t("common.networkError"),
      });
    }
  }, [routesHistorySliceStatus, routesHistorySliceReasonCode]);

  /*
   * this method is in charge of grouping all tracks and stops by date
   */
  let groupedRoutes = routesHistory.reduce((dataObj: any, routes) => {
    const { gpsPositionTimestamp } = routes.firstPosition;
    const stringTimestamp = gpsPositionTimestamp.toString();
    dataObj[stringTimestamp] = dataObj[stringTimestamp] ?? [];
    dataObj[stringTimestamp].push(routes);
    return dataObj;
  }, {});

  let groupedRoutesDate = Object.keys(groupedRoutes).reverse();

  // Handle request for data of a single route
  const [reloaded, setReloaded] = useState(false);
  const [
    dispatchedViewsRequestFromTrackOrStopRoute,
    setDispatchedViewsRequestFromTrackOrStopRoute,
  ] = useState(false);
  const [clickedOnStopOrRoute, setClickedOnStopOrRoute] = useState(false);

  // Handle routes
  const location: Location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const currentQueryParamsFromRoute = searchParams.toString();
  const locationHistoryRoute = useMatch("/dashboard/drivers/location-history");
  let isLocationHistoryRoute = locationHistoryRoute !== null;
  const summaryRoute = useMatch(`/dashboard/drivers/location-history/summary`);
  let isSummaryRoute = summaryRoute !== null;
  const tracksRoute = useMatch(`/dashboard/drivers/location-history/tracks`);
  let isTracksRoute = tracksRoute !== null;
  const trackRoute = useMatch(
    "/dashboard/drivers/location-history/track/:trackId"
  );
  const stopRoute = useMatch(
    "/dashboard/drivers/location-history/stop/:stopId"
  );

  let isTrackRoute = trackRoute !== null;
  let isStopRoute = stopRoute !== null;
  const trackIdFromRoute = trackRoute?.params?.trackId;
  const stopIdFromRoute = stopRoute?.params?.stopId;

  let isTracksOrStopOrTrackRoute = isTrackRoute || isStopRoute || isTracksRoute;

  function gotToPageIf(
    pathname: string,
    queryParams: string,
    condition: boolean
  ) {
    if (condition) {
      // Go to the summary page and update its query parameters
      navigate({
        pathname: pathname,
        search: queryParams,
      });
    }
  }

  /**
   * Generates new locations when the filter bar parameters gets changed.
   * This use effect gets executed every time the filterbar props change
   */
  useEffect(() => {
    // If the filter parameters are changed
    if (filterBarHasChanged) {
      setFilterBarHasChanged(false);
      // If the location is the main page (location history)
      if (isLocationHistoryRoute) {
        setShowSummary(true);
        // If the parameters already contained in the location are different from those coming from the filter bar
        gotToPageIf(
          "/dashboard/drivers/location-history/summary",
          queryParamsFromFilterBar,
          location.search !== queryParamsFromFilterBar
        );
      }
      // If the location is the summary page
      if (isSummaryRoute) {
        // If the parameters already contained in the location are different from those coming from the filter bar
        gotToPageIf(
          "/dashboard/drivers/location-history/summary",
          queryParamsFromFilterBar,
          location.search !== queryParamsFromFilterBar
        );
      }
      // If the location is the tracks page
      if (isTracksRoute) {
        // If the parameters already contained in the location are different from those coming from the filter bar
        gotToPageIf(
          "/dashboard/drivers/location-history/tracks",
          queryParamsFromFilterBar,
          location.search !== queryParamsFromFilterBar
        );
      }
      // If the location is the single track or stop page
      if (isTrackRoute || isStopRoute) {
        // If the parameters already contained in the location are different from those coming from the filter bar
        if (
          _.has(location.state, "queryParams") &&
          queryParamsFromFilterBar !== _.get(location.state, "queryParams")
        )
          // Go back to the tracks location and update its query parameters
          navigate({
            pathname: "/dashboard/drivers/location-history/tracks",
            search: queryParamsFromFilterBar,
          });
      }
    }
  }, [queryParamsFromFilterBar, filterBarHasChanged]);

  /**
   * This use effect gets executed every time the URL change.
   * Theorically, this useEffect happens right just after the filter bar changes one.
   */
  useEffect(() => {
    // If a track/stop is selected and the location is dashboard/vehicles/locaation-history/tracks?
    if (currentActive && isTracksRoute) {
      // Handle the selected track or stop
      selectedTrack(currentActive, currentActiveType);
    }
    // If no track/stop is selected and the location is dashboard/vehicles/locaation-history/tracks?
    if (!currentActive && isTracksRoute) {
      if ("?" + currentQueryParamsFromRoute !== queryParamsFromFilterBar) {
        setQueryParamsFromFilterBar("?" + currentQueryParamsFromRoute);
        setFilterBarHasChanged(false);
      }
    }

    // If the current location is the summary page
    if (isSummaryRoute) {
      // It there are new parameters coming from the location and are different from those coming from the filter bar
      if (currentQueryParamsFromRoute) {
        setShowSummary(true);
        if ("?" + currentQueryParamsFromRoute !== queryParamsFromFilterBar) {
          // Update the filter bar query parameters
          setQueryParamsFromFilterBar("?" + currentQueryParamsFromRoute);
          setFilterBarHasChanged(false);
        }
      }
    }

    // If the current location is the summary page and there are new parameters coming from the location
    // that are different from those coming from the filter bar
    if (
      (isTrackRoute || isStopRoute) &&
      location.state &&
      _.has(location.state, "queryParams")
    ) {
      // Update the filter bar query parameters
      setQueryParamsFromFilterBar(_.get(location.state, "queryParams"));
      setFilterBarHasChanged(false);
    }
    setReloaded(true);
  }, [location]);

  /**
   * If the page has been reloaded, the request for route history views has been completed or
   * a click to open a track or stop has been enacted then handle the single track or stop.
   */
  useEffect(() => {
    if (
      reloaded &&
      routesHistorySliceStatus === "idle" &&
      (dispatchedViewsRequestFromTrackOrStopRoute || clickedOnStopOrRoute)
    ) {
      setReloaded(false);
      setDispatchedViewsRequestFromTrackOrStopRoute(false);
      setClickedOnStopOrRoute(false);
      if (isTrackRoute && trackIdFromRoute) {
        selectedTrack(parseInt(trackIdFromRoute), "TRACK");
        setOpenTrackDetails(true);
        setOpenStopDetails(false);
        setEnableFakeMap(false);
      }

      if (isStopRoute && stopIdFromRoute) {
        selectedTrack(parseInt(stopIdFromRoute), "STOP");
        setOpenStopDetails(true);
        setOpenTrackDetails(false);
        setEnableFakeMap(false);
      }
    }
  }, [
    routesHistorySliceStatus,
    reloaded,
    dispatchedViewsRequestFromTrackOrStopRoute,
    clickedOnStopOrRoute,
  ]);

  /**
   * TODO: implements an api to retrieve a driver travel
   * @param trackId
   * @param type
   */
  function selectedTrack(trackId: number, type: "TRACK" | "STOP" | "NONE") {
    if (currentActive !== trackId) {
      store.dispatch(
        readRouteHistoryAsync({
          vehicleId: -1,
          routeHistoryId: trackId,
          queryParams: queryParamsFromFilterBar,
        })
      );
      setCurrentActive(trackId);
      setCurrentActiveType(type);
    } else {
      closeAllModals();
    }
  }

  const closeAllModals = () => {
    setCurrentActive(null);
    setCurrentActiveType("NONE");
    setOpenStopDetails(false);
    setOpenTrackDetails(false);
  };

  /*
   * trackSummary data definitions
     @TODO: 
   */

  useEffect(() => {
    document.title =
      t("navigation.userMenu.locationHistoryDriver") +
      " - " +
      t("breadcrumbs.drivers");
    return () => {
      store.dispatch(routeHistoryEmptyRoutes());
      // empty route history summary
    };
  }, []);

  return (
    <>
      <PageFilters hidden={expanded ? "" : "none"}>
        <div className="col col-16">
          <DriversLocationHistoryFilterBar
            callback={(queryParams: string) => {
              setQueryParamsFromFilterBar(queryParams);
              setFilterBarHasChanged(true);
              if (isLocationHistoryRoute) {
                // call macro summary
              }
              if (isSummaryRoute) {
                // call macro summary
              }
              if (isTracksRoute) {
                store.dispatch(getRoutesHistoryViewAsync(queryParams));
              }
              if (isStopRoute || isTrackRoute) {
                store.dispatch(getRoutesHistoryViewAsync(queryParams));
                setDispatchedViewsRequestFromTrackOrStopRoute(true);
              }
            }}
          />
        </div>
      </PageFilters>
      {(queryParamsFromFilterBar || trackIdFromRoute || stopIdFromRoute) && (
        <PageContent height={expanded}>
          <ContentSidebar>
            <div className="driver-lh-sidebar">
              <Sidebar expanded={expanded} expandedUpdated={setExpanded}>
                {showSummary /* && route history summary */ && (
                  <div className="driver-lh-trackSummary">
                    <TrackSummary
                      number={-1}
                      details={details}
                      buttonClick={() => {
                        setShowSummary(false);
                        setEnableFakeMap(false);
                        setSearchParams(queryParamsFromFilterBar);
                        navigate({
                          pathname:
                            "/dashboard/drivers/location-history/tracks",
                          search: queryParamsFromFilterBar,
                        });
                        store.dispatch(
                          getRoutesHistoryViewAsync(queryParamsFromFilterBar)
                        );
                      }}
                      preference={preferencesContext}
                    />
                  </div>
                )}
                {!showSummary &&
                  groupedRoutesDate &&
                  isTracksOrStopOrTrackRoute && (
                    <div className="driver-lh-all-tracks">
                      <BackToSummary
                        text={t("locationHistory.backToSummary.backSummary")}
                        onClick={() => {
                          navigate({
                            pathname:
                              "/dashboard/drivers/location-history/summary",
                            search: queryParamsFromFilterBar,
                          });
                          setShowSummary(true);
                          setOpenTrackDetails(false);
                          setOpenStopDetails(false);
                          setCurrentActive(null);
                          setCurrentActiveType("NONE");
                          // call macro summary
                        }}
                      />
                      {groupedRoutesDate.map((date: string, index: number) => {
                        return (
                          <React.Fragment key={"key_" + index}>
                            <Divider hasData={index !== 0} date={date} />
                            {groupedRoutes[date].map((x: any, idx: number) => {
                              if (x.routeStateType === "TRACK") {
                                const point: RouteHistoryView = {
                                  start: {
                                    location: getAddressView(
                                      x.firstPosition.address
                                    ),
                                    time: x.firstPosition.gpsPositionTimestamp,
                                  },
                                  end: {
                                    location: getAddressView(
                                      x.lastPosition.address
                                    ),
                                    time: x.lastPosition.gpsPositionTimestamp,
                                  },
                                };
                                return (
                                  <div key={x.id}>
                                    <SingleTrackRow
                                      id={x.id}
                                      points={point}
                                      clickCallback={(isSelected) => {
                                        if (!isSelected) {
                                          navigate({
                                            pathname:
                                              "/dashboard/drivers/location-history/tracks",
                                            search: queryParamsFromFilterBar,
                                          });
                                        } else {
                                          setClickedOnStopOrRoute(true);
                                          navigate(
                                            `/dashboard/drivers/location-history/track/${x.id}`,
                                            {
                                              state: {
                                                queryParams:
                                                  queryParamsFromFilterBar,
                                              },
                                            }
                                          );
                                        }
                                      }}
                                      active={x.id === currentActive}
                                    />
                                    {idx === 0 && (
                                      <div className="driver-lh-splitter-line" />
                                    )}
                                  </div>
                                );
                              } else {
                                return (
                                  <div key={x.id}>
                                    <BreakRow
                                      id={x.id}
                                      title={t(
                                        "locationHistory.breakRow.title"
                                      )}
                                      time={formatTotalTime(
                                        x.firstPosition.gpsPositionTimestamp,
                                        x.lastPosition.gpsPositionTimestamp
                                      )}
                                      clickCallback={(isSelected) => {
                                        if (!isSelected) {
                                          navigate({
                                            pathname:
                                              "/dashboard/drivers/location-history/tracks",
                                            search: queryParamsFromFilterBar,
                                          });
                                        } else {
                                          setClickedOnStopOrRoute(true);
                                          navigate(
                                            `/dashboard/drivers/location-history/stop/${x.id}`,
                                            {
                                              state: {
                                                queryParams:
                                                  queryParamsFromFilterBar,
                                              },
                                            }
                                          );
                                        }
                                      }}
                                      active={x.id === currentActive}
                                    />
                                    {groupedRoutes[date].length > 1 && (
                                      <div className="driver-lh-splitter-line" />
                                    )}
                                  </div>
                                );
                              }
                            })}
                          </React.Fragment>
                        );
                      })}
                    </div>
                  )}
              </Sidebar>
            </div>
          </ContentSidebar>
          <div className="col">
            {enableFakeMap && (
              <img
                className="driver-lh-static-map"
                alt="minimap"
                src={`https://maps.googleapis.com/maps/api/staticmap?center=${41.9027835},${12.4963655}&zoom=6&size=1633x901&key=${
                  process.env.REACT_APP_GOOGLE_MAPS_API_KEY
                }&style=element%3Alabels.text.fill%7Ccolor%3A0x7c93a3%7Clightness%3A-10&amp&style=feature%3Aadministrative.country%7Celement%3Ageometry%7Cvisibility%3Aon&amp&style=feature%3Aadministrative.country%7Celement%3Ageometry.stroke%7Ccolor%3A0xa0a4a5&amp&style=feature%3Aadministrative.province%7Celement%3Ageometry.stroke%7Ccolor%3A0x62838e&amp&style=feature%3Alandscape%7Celement%3Ageometry.fill%7Ccolor%3A0xdde3e3&amp&style=feature%3Alandscape.man_made%7Celement%3Ageometry.stroke%7Ccolor%3A0x3f4a51%7Cweight%3A0.30&amp&style=feature%3Apoi%7Cvisibility%3Asimplified&amp&style=feature%3Apoi.attraction%7Cvisibility%3Aon&amp&style=feature%3Apoi.business%7Cvisibility%3Aoff&amp&style=feature%3Apoi.government%7Cvisibility%3Aoff&amp&style=feature%3Apoi.park%7Cvisibility%3Aon&amp&style=feature%3Apoi.place_of_worship%7Cvisibility%3Aoff&amp&style=feature%3Apoi.school%7Cvisibility%3Aoff&amp&style=feature%3Apoi.sports_complex%7Cvisibility%3Aoff&amp&style=feature%3Aroad%7Csaturation%3A-100%7Cvisibility%3Aon&amp&style=feature%3Aroad%7Celement%3Ageometry.stroke%7Cvisibility%3Aon&amp&style=feature%3Aroad.highway%7Celement%3Ageometry.fill%7Ccolor%3A0xbbcacf&amp&style=feature%3Aroad.highway%7Celement%3Ageometry.stroke%7Clightness%3A0%7Ccolor%3A0xbbcacf%7Cweight%3A0.50&amp&style=feature%3Aroad.highway%7Celement%3Alabels%7Cvisibility%3Aon&amp&style=feature%3Aroad.highway%7Celement%3Alabels.text%7Cvisibility%3Aon&amp&style=feature%3Aroad.highway.controlled_access%7Celement%3Ageometry.fill%7Ccolor%3A0xffffff&amp&style=feature%3Aroad.highway.controlled_access%7Celement%3Ageometry.stroke%7Ccolor%3A0xa9b4b8&amp&style=feature%3Atransit%7Cvisibility%3Aoff&amp&style=feature%3Awater%7Celement%3Ageometry.fill%7Ccolor%3A0xa3c7df&amp;rnd=1636735607959`}
              />
            )}
            {!enableFakeMap && (
              <>
                <LocationHistoryMap
                  id={0}
                  googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY!}
                  zoom={10}
                  latitude={41.9027835}
                  longitude={12.4963655}
                  infoTrack={infoTrack}
                  openTrackDetails={openTrackDetails}
                  openStopDetails={openStopDetails}
                  expanded={expanded}
                  viewPolylineMarkers={false}
                  openTrackBar={
                    !!infoTrack &&
                    (infoTrack?.routeStateType === "TRACK" ||
                      infoTrack?.routeStateType === "STOP")
                  }
                  isDisableArrow={{ isDisabled: false }}
                />
              </>
            )}
          </div>
        </PageContent>
      )}
    </>
  );
};
