import i18next, { t } from "i18next";
import { useContext, useEffect, useRef, useState } from "react";
import {} from "react-i18next";
import { useAppSelector } from "../../app/hooks";
import { store } from "../../app/store";
import PageContent from "../../layout/PageContent";
import PageFilters from "../../layout/PageFilters";
import { PageCounter } from "../../ui/Table/PageCounter";
import { Table } from "../../ui/Table/Table";
import { ToastNotification } from "../../utils/ToastNotification";
import { getQueryString } from "../../utils/Utils";
import { Preferences } from "../users/preference/preferencesSlice";
import {
  Preset,
  presetsSelectors,
  selectpresetsSliceStatus,
} from "../users/preset/presetsSlice";
import { UserPermissions } from "../users/privilege/privilegesSlice";
import UserContext from "../users/userContext";
import { LogFilterBar } from "./LogFilterBar";
import "./Logs.css";
import { LogsTableBuilder } from "./LogsTableBuilder";
import {
  LogEvent,
  getFilteredLogEventsPaginationAsync,
  logEventsEmptyState,
  logEventsSelectors,
  selectLogEventsSlicePage,
  selectLogEventsSliceReasonCode,
  selectLogEventsSliceStatus,
  selectLogEventsSliceTotalElements,
} from "./logSlice";

interface LogsProps {
  permissions: UserPermissions;
}

interface TableData {
  columns: { label: string; field: string; sort: boolean }[] | undefined;
  rows: { [key: string]: any };
}

const tableData: TableData = {} as TableData;

const context = "logEvent";
tableData.rows = [];

export const Logs: React.FC<LogsProps> = ({ permissions }) => {
  const tableBuilderRef = useRef<LogsTableBuilder>(new LogsTableBuilder());
  let tableBuilder = tableBuilderRef.current;

  let presets: Preset[] = useAppSelector(presetsSelectors.selectAll);

  const [preferencesContext]: [Preferences] = useContext(UserContext);

  const presetsLogSliceStatus = useAppSelector(selectpresetsSliceStatus);

  const [queryParamsFromFilterBar, setQueryParamsFromFilterBar] =
    useState<string>("");
  const [resetPage, setResetPage] = useState<boolean>(false);

  const logEvents: LogEvent[] = useAppSelector(logEventsSelectors.selectAll);

  const logEventsSliceStatus = useAppSelector(selectLogEventsSliceStatus);
  const logEventsSliceReasonCode = useAppSelector(
    selectLogEventsSliceReasonCode
  );
  const logEventsPages = useAppSelector(selectLogEventsSlicePage);
  const logEventsTotalElements = useAppSelector(
    selectLogEventsSliceTotalElements
  );
  const isLogIdle = logEventsSliceStatus === "idle";
  const [queryParamsChanged, setQueryParamsChanged] = useState<boolean>(false);

  //#region handle queryparams
  useEffect(() => {
    if (!!queryParamsFromFilterBar) {
      setQueryParamsChanged(true);
    }
  }, [queryParamsFromFilterBar]);

  useEffect(() => {
    if (queryParamsChanged && isLogIdle) {
      setQueryParamsChanged(false);
      setResetPage(true);
    } else {
      setResetPage(false);
    }
  }, [isLogIdle, queryParamsChanged]);
  //#endregion handle queryparams

  useEffect(() => {
    if (logEventsSliceStatus === "failed" && logEventsSliceReasonCode === "") {
      console.error(i18next.t("common.networkError"));
      ToastNotification({
        toastId: "networkError",
        status: "error",
        description: i18next.t("common.networkError"),
      });
    }
  }, [logEventsSliceStatus, logEventsSliceReasonCode]);

  if (presetsLogSliceStatus === "idle" && presets.length > 0) {
    let preset =
      presets.find((element) => element.name === undefined) ??
      presets.find((element) => element.lastSelected === true);
    if (!preset) {
      preset = presets.find(
        (element) => element.name === "Default" && element.context === context
      );
    }

    tableData.columns = tableBuilder.setColumns(
      preset?.columns
        ? preset?.columns.map((element) => {
            return {
              name: element,
            };
          })
        : []
    );

    tableData.rows =
      presetsLogSliceStatus === "idle" &&
      logEventsSliceStatus === "idle" &&
      tableData.columns &&
      tableData?.columns?.length > 0
        ? logEvents.map((logEvent: LogEvent) => {
            return tableBuilder.rowsBuilder(
              tableData.columns,
              {
                logEvent: logEvent,
              },
              preferencesContext
            );
          })
        : tableData.rows ?? [];
  }

  // loader for PageCounter
  const isLoading = logEventsSliceStatus === "loading";

  useEffect(() => {
    document.title = t("navigation.userMenu.logs");
    return () => {
      store.dispatch(logEventsEmptyState());
    };
  }, []);

  return (
    <>
      {permissions.logs.write && (
        <>
          <PageFilters>
            <div className="col col-16">
              <LogFilterBar
                presets={presets}
                callback={setQueryParamsFromFilterBar}
                disabledButton={tableData.rows?.length === 0}
              />
            </div>
          </PageFilters>
          {tableData.rows?.length > 0 && (
            <PageContent>
              <div className="col log-table">
                <Table data={tableData}>
                  <Table.Head hasTableSpace={false} />
                  <Table.Body />
                </Table>
              </div>
              <div className="log-pagination">
                <PageCounter
                  isActionPerforming={isLoading}
                  totalElements={logEventsTotalElements}
                  resetPage={resetPage}
                  disabled={!isLogIdle}
                  numOfPages={logEventsPages}
                  onClick={(id, currentPage) => {
                    if (id !== currentPage) {
                      const pageAndSize = getQueryString({
                        page: id - 1,
                        size: "10",
                      });
                      const finalQueryParams = queryParamsFromFilterBar
                        ? queryParamsFromFilterBar +
                          pageAndSize.replace("?", "&")
                        : pageAndSize;
                      store.dispatch(
                        getFilteredLogEventsPaginationAsync({
                          queryParams: finalQueryParams,
                        })
                      );
                    }
                  }}
                />
              </div>
            </PageContent>
          )}
        </>
      )}
    </>
  );
};
