import i18next, { t } from "i18next";
import _ from "lodash";
import { useContext, useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useAppSelector } from "../../../app/hooks";
import { store } from "../../../app/store";
import { GTFleetErrorCodes } from "../../../config/GTfleetErrorCodes";
import PageContent from "../../../layout/PageContent";
import PageFilters from "../../../layout/PageFilters";
import { Button } from "../../../ui/Button/Button";
import { Checkbox } from "../../../ui/Forms/Checkbox";
import { IconMap } from "../../../ui/Icon/Line/Map";
import { MapMarkerTruckNumber } from "../../../ui/Marker/TruckNumber";
import { PageCounter } from "../../../ui/Table/PageCounter";
import { Table } from "../../../ui/Table/Table";
import { ToastNotification } from "../../../utils/ToastNotification";
import {
  routeHistoryEmptyRoutes,
  selectRoutesHistorySliceReasonCode,
  selectRoutesHistorySliceStatus,
} from "../../route/routesHistorySlice";
import { Preferences } from "../../users/preference/preferencesSlice";
import {
  Preset,
  presetsSelectors,
  selectpresetsSliceReasonCode,
  selectpresetsSliceStatus,
} from "../../users/preset/presetsSlice";
import { UserPermissions } from "../../users/privilege/privilegesSlice";
import UserContext from "../../users/userContext";
import { ReportMap } from "../ReportMap";
import "./ReportIO.css";
import { ReportIODetails } from "./ReportIODetails";
import { IoReportFilterBar } from "./ReportIOFilterBar";
import { ReportIOTableBuilder } from "./ReportIOTableBuilder";
import {
  ReportIO,
  reportsIOEmptyState,
  reportsIOSelectors,
  selectReportsIOSliceReasonCode,
  selectReportsIOSliceStatus,
} from "./reportsIOSlice";
import {
  ReportsIOSummary,
  reportsIOSummaryEmptyState,
  reportsIOSummarySelectors,
  selectReportsIOSummarySliceReasonCode,
  selectReportsIOSummarySliceStatus,
} from "./reportsIOSummarySlice";

interface IoReportProps {
  permissions: UserPermissions;
}

interface TableData {
  columns: { label: string; field: string; sort: boolean }[] | undefined;
  rows: { [key: string]: any };
}

const tableData: TableData = {} as TableData;

const context = "reportIO";

export const IoReport: React.FC<IoReportProps> = ({ permissions }) => {
  const [preferencesContext]: [Preferences] = useContext(UserContext);

  const tableBuilderRef = useRef<ReportIOTableBuilder>(
    new ReportIOTableBuilder()
  );
  let tableBuilder = tableBuilderRef.current;
  const trackColors = [
    "--global-colors-ui-primary",
    "--global-colors-palette-violet",
    "--global-colors-palette-green",
    "--global-colors-palette-orange",
    "--global-colors-palette-bordeaux",
    "--global-colors-palette-yellow",
    "--global-colors-palette-light-bordeaux",
    "--global-colors-palette-light-blue",
    "--global-colors-palette-light-violet",
    "--global-colors-palette-light-red",
  ];
  let presets: Preset[] = useAppSelector(presetsSelectors.selectAll);
  const presetsIOSliceStatus = useAppSelector(selectpresetsSliceStatus);
  const presetsIOSliceReasonCode = useAppSelector(selectpresetsSliceReasonCode);

  const [queryParamsFromFilterBar, setQueryParamsFromFilterBar] =
    useState<string>("");

  const [searchParams] = useSearchParams();

  const currentVehicleId = searchParams.get("vehicleId");

  const reportsIO: ReportIO[] = useAppSelector(reportsIOSelectors.selectAll);

  const reportsSliceStatus = useAppSelector(selectReportsIOSliceStatus);
  const reportsSliceReasonCode = useAppSelector(selectReportsIOSliceReasonCode);
  const reportsIOSummary: ReportsIOSummary[] = useAppSelector(
    reportsIOSummarySelectors.selectAll
  );

  const reportsSummarySliceStatus = useAppSelector(
    selectReportsIOSummarySliceStatus
  );

  const reportsSummarySliceReasonCode = useAppSelector(
    selectReportsIOSummarySliceReasonCode
  );

  const routesHistorySliceStatus = useAppSelector(
    selectRoutesHistorySliceStatus
  );
  const routesHistorySliceReasonCode = useAppSelector(
    selectRoutesHistorySliceReasonCode
  );

  const [queryParamsChanged, setQueryParamsChanged] = useState<boolean>(false);
  const [resetPage, setResetPage] = useState<boolean>(false);
  const [numPages, setNumPages] = useState(0);

  const [pagedReportsIO, setPagedReportsIO] = useState<ReportIO[]>(
    reportsIO.slice(0, 9)
  );

  useEffect(() => {
    if (reportsIO.length > 0) {
      setPagedReportsIO(reportsIO.slice(0, 9));
      let numPageDivided = reportsIO.length / 10;
      setNumPages(
        Math.floor(numPageDivided) === numPageDivided
          ? numPageDivided
          : Math.floor(numPageDivided) + 1
      );
    } else {
      setPagedReportsIO([]);
      setNumPages(0);
    }
  }, [reportsIO]);

  useEffect(() => {
    if (reportsSummarySliceStatus === "idle" && queryParamsChanged) {
      setQueryParamsChanged(false);
      setResetPage(true);
    } else {
      setResetPage(false);
      setCheckedList([]);
    }
  }, [reportsSummarySliceStatus, queryParamsChanged]);

  //#region error handling
  useEffect(() => {
    if (reportsSliceStatus === "failed" && reportsSliceReasonCode === "") {
      console.error("common.networkError");
      ToastNotification({
        toastId: "networkError",
        status: "error",
        description: t("common.networkError"),
      });
    }
  }, [reportsSliceStatus, reportsSliceReasonCode]);

  useEffect(() => {
    if (
      reportsSummarySliceStatus === "failed" &&
      reportsSummarySliceReasonCode === ""
    ) {
      console.error(i18next.t("common.networkError"));
      ToastNotification({
        toastId: "reportsSummaryNetworkError",
        status: "error",
        description: i18next.t("common.networkError"),
      });
    }
  }, [reportsSummarySliceStatus, reportsSummarySliceReasonCode]);

  useEffect(() => {
    if (presetsIOSliceStatus === "failed" && presetsIOSliceReasonCode === "") {
      console.error(i18next.t("common.networkError"));
      ToastNotification({
        toastId: "presetsIONetworkError",
        status: "error",
        description: i18next.t("common.networkError"),
      });
    }
  }, [presetsIOSliceStatus, presetsIOSliceReasonCode]);

  useEffect(() => {
    if (
      routesHistorySliceStatus === "failed" &&
      routesHistorySliceReasonCode === ""
    ) {
      console.error(t("common.networkError"));
      ToastNotification({
        toastId: "networkError",
        status: "error",
        description: t("common.networkError"),
      });
    }
    if (
      routesHistorySliceStatus === "failed" &&
      routesHistorySliceReasonCode ===
        GTFleetErrorCodes.ROUTE_HISTORY_DOES_NOT_EXIST
    ) {
      console.error(t("report.io.routeNotFound"));
      ToastNotification({
        toastId: "networkError",
        status: "error",
        description: t("report.io.routeNotFound"),
      });
    }
  }, [routesHistorySliceStatus, routesHistorySliceReasonCode]);
  //#endregion error handling

  if (presetsIOSliceStatus === "idle" && presets.length > 0) {
    let preset =
      presets.find((element) => element.name === undefined) ??
      presets.find((element) => element.lastSelected === true);
    if (!preset) {
      preset = presets.find(
        (element) => element.name === "Default" && element.context === context
      );
    }

    tableData.columns = tableBuilder.setColumns(
      preset?.columns
        ? preset?.columns.map((element) => {
            return {
              name: element,
            };
          })
        : []
    );

    tableData.rows =
      reportsSliceStatus === "idle" &&
      presetsIOSliceStatus === "idle" &&
      tableData.columns &&
      tableData?.columns?.length > 0
        ? pagedReportsIO.map((reportIO: ReportIO) => {
            return tableBuilder.rowsBuilder(
              tableData.columns,

              {
                reportIO: reportIO,
              },
              preferencesContext
            );
          })
        : tableData.rows;
  }

  interface CheckedProps {
    id: number;
    color: string;
    number: number;
    vehicleId: number;
    queryParams: string;
  }

  const [viewMap, setViewMap] = useState(false);
  const [rowHeight, setRowHeight] = useState("");
  const [checkedList, setCheckedList] = useState<CheckedProps[]>([]);
  const [removedIndexes, setRemovedIndexes] = useState<number[]>([]);

  const onCheckboxChange = (
    el: any,
    id: number,
    color: string,
    vehicleId: number,
    queryParams: string
  ) => {
    let updatedCheckedList = [...checkedList];
    let updatedRemovedIndexes = [...removedIndexes];
    if (el.target.checked) {
      let indexToAssign = 0;
      if (updatedRemovedIndexes.length > 0) {
        indexToAssign = updatedRemovedIndexes[0];
        updatedRemovedIndexes.shift();
      } else {
        indexToAssign = updatedCheckedList.length;
      }
      updatedCheckedList.push({
        id: id,
        color: color,
        number: indexToAssign,
        vehicleId: vehicleId,
        queryParams: queryParams,
      });
    } else {
      updatedCheckedList = checkedList.filter(
        (Checked: any) => Checked.id !== id
      );
      const currentRemovedElement = checkedList.find(
        (Checked: any) => Checked.id === id
      );
      if (currentRemovedElement) {
        if (!updatedRemovedIndexes.includes(currentRemovedElement?.number)) {
          updatedRemovedIndexes.push(currentRemovedElement?.number);
        }
      }
    }
    setRemovedIndexes(_.sortBy(updatedRemovedIndexes));
    setCheckedList(updatedCheckedList);
  };

  function isSelected(option: any) {
    return checkedList ? checkedList.some((el) => el.id === option) : false;
  }

  useEffect(() => {
    setRowHeight(
      document.getElementById("ior-table-body")?.clientHeight + "px"
    );
  }, [viewMap]);

  useEffect(() => {
    document.title =
      t("breadcrumbs.ioReport") + " - " + t("breadcrumbs.reports");
    return () => {
      store.dispatch(reportsIOEmptyState());
      store.dispatch(reportsIOSummaryEmptyState());
      store.dispatch(routeHistoryEmptyRoutes());
    };
  }, []);

  return (
    <>
      <PageFilters>
        <div className="col col-16">
          <IoReportFilterBar
            presets={presets}
            callback={(queryParams) => {
              setQueryParamsChanged(true);
              setQueryParamsFromFilterBar(queryParams);
            }}
            disableButton={tableData.rows?.length === 0}
          />
        </div>
        {queryParamsFromFilterBar !== "" && (
          <div className="col col-16">
            <div className="details-reportIO-wrapper">
              <div className="details-report-title">
                {t("report.details.title")}
              </div>
              <div className="details-report-container">
                <div className="details-report-icons">
                  <ReportIODetails
                    duration={reportsIOSummary[0]?.duration}
                    eventDistance={reportsIOSummary[0]?.eventDistance}
                  />
                </div>
                <div className="details-report-button">
                  <Button
                    aspect="secondary"
                    size="small"
                    label={
                      viewMap
                        ? t("report.details.hideMap")
                        : t("report.details.viewMap")
                    }
                    onClick={() => {
                      setViewMap(!viewMap);
                      checkedList.length = 0;
                    }}
                    disabled={tableData.rows?.length === 0}
                  >
                    <IconMap size={14} color="--global-colors-ink-light" />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}
      </PageFilters>
      {queryParamsFromFilterBar !== "" && tableData.rows?.length > 0 && (
        <div className="ior-container">
          <PageContent>
            <div
              style={
                !viewMap
                  ? {
                      display: "inline-flex",
                    }
                  : {
                      display: "inline-flex",
                      overflow: "hidden",
                    }
              }
            >
              <div
                className={
                  viewMap
                    ? "ior-table-container-collapsed"
                    : "ior-table-container"
                }
              >
                <div style={{ display: "inline-flex", width: "100%" }}>
                  <div className="ior-checkbox-list">
                    {viewMap &&
                      tableData.rows.map((_elem: any, idx: number) => (
                        <div
                          key={idx}
                          className="ior-checkbox"
                          style={{
                            height: rowHeight,
                          }}
                        >
                          <Checkbox
                            checked={isSelected(pagedReportsIO[idx].routeId)}
                            onChange={(e: any) => {
                              if (currentVehicleId) {
                                onCheckboxChange(
                                  e,
                                  pagedReportsIO[idx].routeId,
                                  trackColors[idx],
                                  Number.parseInt(currentVehicleId),
                                  queryParamsFromFilterBar
                                );
                              }
                            }}
                            label=""
                            disabled={
                              checkedList.length >= 10 &&
                              idx !== checkedList[idx].id &&
                              !isSelected(pagedReportsIO[idx].routeId)
                            }
                          />
                        </div>
                      ))}
                  </div>
                  <div
                    className={
                      viewMap
                        ? "ior-rows-container-collapsed"
                        : "ior-rows-container"
                    }
                  >
                    <Table data={tableData}>
                      <Table.Head />
                      <Table.Body id="ior-table-body" />
                    </Table>
                  </div>
                  <div className="ior-marker-list">
                    {viewMap &&
                      checkedList.length > 0 &&
                      tableData.rows.map((_el: any, index: number) => (
                        <div
                          key={index}
                          className="ior-marker"
                          style={{
                            height:
                              document.getElementById("ior-table-body")
                                ?.clientHeight,
                          }}
                        >
                          {checkedList.map(
                            (element) =>
                              element.id === pagedReportsIO[index].routeId && (
                                <div key={index}>
                                  <MapMarkerTruckNumber
                                    number={element.number + 1}
                                    color={trackColors[index]}
                                  />
                                </div>
                              )
                          )}
                        </div>
                      ))}
                  </div>
                </div>
                <div>
                  <PageCounter
                    isActionPerforming={reportsSliceStatus === "loading"}
                    totalElements={reportsIO ? reportsIO.length : 0}
                    numOfPages={numPages}
                    numSelected={checkedList.length}
                    resetPage={resetPage}
                    onClick={(id) => {
                      // Restore dispatch once Backend Pagination is completed
                      const paginatedElements = reportsIO.slice(
                        10 * (id - 1),
                        10 * id - 1
                      );
                      setPagedReportsIO(paginatedElements);
                    }}
                  />
                </div>
              </div>
              {viewMap && (
                <div className="ior-map-container">
                  <ReportMap
                    id={0}
                    googleMapsApiKey={
                      process.env.REACT_APP_GOOGLE_MAPS_API_KEY!
                    }
                    zoom={9}
                    latitude={41.9109}
                    longitude={12.4818}
                    checkedList={checkedList}
                    limit={10}
                  />
                </div>
              )}
            </div>
          </PageContent>
        </div>
      )}
    </>
  );
};
