import i18next from "i18next";
import PropTypes from "prop-types";
import { useState } from "react";
import Form from "../../Forms/Form";
import TextField from "../../Forms/TextField";
import { ModalBody } from "../ModalBody";
import { ModalFooter } from "../ModalFooter";
import { ModalWrapper } from "../ModalWrapper";
import "./AddGroupModal.css";

export const AddGroupModal = ({ open, onClose, isLoading }) => {
  const [group, setGroup] = useState("");
  const [errorList, setErrorList] = useState([]);

  return (
    <div className="add-group-modal">
      <ModalWrapper open={open} closeAction={onClose}>
        <ModalBody title={i18next.t("customModals.addGroup.title")}>
          {i18next.t("customModals.addGroup.desc")}
          <div className="group-input">
            <Form>
              <TextField
                id="Group name"
                placeholder={i18next.t("customModals.addGroup.label")}
                validate="alphaNumeric"
                errorList={setErrorList}
                events={{
                  onChange: (data) => {
                    setGroup(data.value);
                  },
                }}
                value={group}
              />
            </Form>
          </div>
        </ModalBody>
        <ModalFooter
          disablePrimaryButton={group === "" || errorList.length !== 0}
          primaryLabel={i18next.t("customModals.addGroup.primaryButton")}
          primaryAction={() => {
            if (!isLoading) {
              onClose(group);
            }
          }}
          secondaryLabel={i18next.t("customModals.addGroup.secondaryButton")}
          secondaryAction={() => {
            setGroup("");
            onClose();
          }}
          loadingPrimaryButton={isLoading}
        />
      </ModalWrapper>
    </div>
  );
};

AddGroupModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

AddGroupModal.defaultValue = {
  open: true,
};
