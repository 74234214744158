import PropTypes from "prop-types";

export const IllustrationTrailerCar = ({ size }) => (
  <svg
    id="a"
    width={size}
    height={size}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 61 46"
  >
    <defs>
      <mask
        id="f"
        y="15.84"
        width="60.42"
        height="29.53"
        maskUnits="userSpaceOnUse"
      />
    </defs>
    <g id="b">
      <g id="c">
        <g id="d">
          <g id="e" opacity=".1">
            <g mask="url(#f)">
              <path
                id="g"
                d="M53.35,23.15c-12.69,.04,1.59-3.28-1.87-4.58l-7.22-2.73-2.82,1.63-1.33-.41L.37,39.86c-.5,.29-.5,1.01,0,1.3l3.45,2.09c1.47,.85,13.93-2.8,15.57-2.8,1.89,0,8.42,5.81,10.12,4.83l30.84-18.07c.77-.45-6.11-4.05-7.01-4.05"
                fill="#031832"
                fillRule="evenodd"
              />
            </g>
          </g>
          <polygon
            id="h"
            points="54.83 21.12 54.61 21.02 54.36 20.95 54.1 20.91 53.82 20.92 53.53 20.96 53.22 21.05 52.91 21.18 52.58 21.35 52.42 21.45 52.26 21.55 52.1 21.67 51.94 21.79 51.79 21.92 51.63 22.06 51.49 22.2 51.34 22.35 51.2 22.51 51.06 22.67 50.93 22.83 50.8 23 50.68 23.18 50.56 23.36 50.44 23.54 50.33 23.72 50.22 23.91 50.12 24.1 50.03 24.3 49.94 24.49 49.86 24.69 49.78 24.89 49.71 25.09 49.65 25.29 49.59 25.49 49.54 25.69 49.5 25.89 49.46 26.08 49.43 26.28 49.41 26.48 49.4 26.67 49.4 26.86 49.41 27.23 49.46 27.57 49.54 27.87 49.65 28.15 49.78 28.39 49.94 28.6 50.12 28.78 50.33 28.92 50.87 29.24 50.87 29.24 52.48 30.17 56.99 22.37 56.15 21.88 54.83 21.12"
            fill="#3a2626"
            fillRule="evenodd"
          />
          <g id="i">
            <path
              id="j"
              d="M54.73,22.59c-1.76,1.02-3.18,3.49-3.18,5.52s1.43,2.85,3.18,1.84c1.76-1.01,3.18-3.49,3.18-5.52s-1.43-2.85-3.18-1.84"
              fill="#4f3938"
              fillRule="evenodd"
            />
            <path
              id="k"
              d="M54.73,24.74c-.73,.42-1.32,1.44-1.32,2.29s.59,1.18,1.32,.76c.73-.42,1.32-1.44,1.32-2.29s-.59-1.18-1.32-.76"
              fill="#dcd8ce"
              fillRule="evenodd"
            />
            <path
              id="l"
              d="M54.73,25.52c-.36,.21-.65,.71-.65,1.12s.29,.58,.65,.37c.36-.2,.65-.71,.65-1.12s-.29-.58-.65-.37"
              fill="#3a2626"
              fillRule="evenodd"
            />
            <polygon
              id="m"
              points="30.37 35.03 30.15 34.92 29.9 34.85 29.64 34.82 29.36 34.83 29.07 34.87 28.77 34.96 28.45 35.09 28.12 35.25 27.96 35.35 27.8 35.46 27.64 35.58 27.48 35.7 27.33 35.83 27.18 35.97 27.03 36.11 26.88 36.26 26.74 36.41 26.61 36.57 26.47 36.74 26.34 36.91 26.22 37.09 26.1 37.26 25.98 37.45 25.87 37.63 25.77 37.82 25.67 38.01 25.57 38.2 25.48 38.4 25.4 38.6 25.32 38.79 25.25 38.99 25.19 39.19 25.13 39.39 25.08 39.59 25.04 39.79 25 39.99 24.98 40.19 24.95 40.39 24.94 40.58 24.94 40.77 24.95 41.14 25 41.47 25.08 41.78 25.19 42.06 25.32 42.3 25.48 42.51 25.67 42.69 25.87 42.83 26.41 43.14 26.41 43.15 28.02 44.08 32.53 36.27 31.69 35.79 30.37 35.03"
              fill="#3a2626"
              fillRule="evenodd"
            />
            <path
              id="n"
              d="M30.28,36.5c-1.76,1.02-3.18,3.49-3.18,5.52s1.43,2.85,3.18,1.84c1.76-1.01,3.18-3.49,3.18-5.52s-1.43-2.85-3.18-1.84"
              fill="#4f3938"
              fillRule="evenodd"
            />
            <path
              id="o"
              d="M30.28,38.65c-.73,.42-1.32,1.44-1.32,2.29s.59,1.18,1.32,.76c.73-.42,1.32-1.44,1.32-2.29s-.59-1.18-1.32-.76"
              fill="#dcd8ce"
              fillRule="evenodd"
            />
            <path
              id="p"
              d="M30.28,39.43c-.36,.21-.65,.71-.65,1.12s.29,.58,.65,.37c.36-.2,.65-.71,.65-1.12s-.29-.58-.65-.37"
              fill="#3a2626"
              fillRule="evenodd"
            />
            <polygon
              id="q"
              points="10.7 24.6 10.47 24.49 10.23 24.42 9.96 24.39 9.69 24.4 9.39 24.44 9.09 24.53 8.77 24.65 8.45 24.82 8.28 24.92 8.12 25.03 7.96 25.15 7.8 25.27 7.65 25.4 7.5 25.54 7.35 25.68 7.21 25.83 7.06 25.98 6.93 26.14 6.79 26.31 6.67 26.48 6.54 26.65 6.42 26.83 6.3 27.02 6.19 27.2 6.09 27.39 5.99 27.58 5.89 27.77 5.8 27.97 5.72 28.17 5.65 28.36 5.58 28.56 5.51 28.76 5.45 28.96 5.4 29.16 5.36 29.36 5.33 29.56 5.3 29.76 5.28 29.95 5.27 30.15 5.26 30.34 5.28 30.71 5.33 31.04 5.4 31.35 5.51 31.63 5.65 31.87 5.8 32.08 5.99 32.26 6.19 32.4 6.74 32.71 6.74 32.71 8.35 33.64 12.85 25.84 12.01 25.36 10.7 24.6"
              fill="#3a2626"
              fillRule="evenodd"
            />
            <path
              id="r"
              d="M10.6,26.07c-1.76,1.02-3.18,3.49-3.18,5.52s1.43,2.85,3.18,1.84c1.76-1.02,3.18-3.49,3.18-5.52s-1.43-2.85-3.18-1.84"
              fill="#4f3938"
              fillRule="evenodd"
            />
            <path
              id="s"
              d="M10.6,28.22c-.73,.42-1.32,1.44-1.32,2.29s.59,1.18,1.32,.76c.73-.42,1.32-1.44,1.32-2.29s-.59-1.18-1.32-.76"
              fill="#dcd8ce"
              fillRule="evenodd"
            />
            <path
              id="t"
              d="M10.6,29c-.36,.21-.65,.71-.65,1.12s.29,.58,.65,.37c.36-.2,.65-.71,.65-1.12s-.29-.58-.65-.37"
              fill="#3a2626"
              fillRule="evenodd"
            />
            <polygon
              id="u"
              points="45.29 15.41 57.97 22.73 57.97 21.47 45.29 14.15 45.29 15.41"
              fill="#e8dcc6"
              fillRule="evenodd"
            />
            <polygon
              id="v"
              points="57.97 22.73 58.55 22.39 58.55 21.13 57.97 21.47 57.97 22.73"
              fill="#dec7ae"
              fillRule="evenodd"
            />
            <polygon
              id="w"
              points="45.29 14.15 57.97 21.47 58.55 21.13 45.87 13.81 45.29 14.15"
              fill="#f2e7d7"
              fillRule="evenodd"
            />
            <polygon
              id="x"
              points="4.42 28.76 23.6 39.83 57.91 20.02 38.73 8.95 4.42 28.76"
              fill="#84726e"
              fillRule="evenodd"
            />
            <polygon
              id="y"
              points="4.42 20.19 4.42 28.76 38.73 8.95 38.73 .38 4.42 20.19"
              fill="#dec7ae"
              fillRule="evenodd"
            />
            <polygon
              id="z"
              points="4.42 28.76 23.58 39.82 23.58 31.25 4.42 20.19 4.42 28.76"
              fill="#e8dcc6"
              fillRule="evenodd"
            />
            <polygon
              id="aa"
              points="38.73 8.95 57.89 20.01 57.89 11.45 38.73 .38 38.73 8.95"
              fill="#e8dcc6"
              fillRule="evenodd"
            />
            <polygon
              id="ab"
              points="23.6 31.27 23.6 39.83 57.91 20.03 57.91 11.46 23.6 31.27"
              fill="#dec7ae"
              fillRule="evenodd"
            />
            <polygon
              id="ac"
              points="4.42 20.19 5.78 20.97 40.08 1.16 38.73 .38 4.42 20.19"
              fill="#f8f1eb"
              fillRule="evenodd"
            />
            <polygon
              id="ad"
              points="22.25 30.46 23.61 31.25 57.92 11.44 56.56 10.65 22.25 30.46"
              fill="#f8f1eb"
              fillRule="evenodd"
            />
            <polygon
              id="ae"
              points="37.36 1.17 56.52 12.23 57.88 11.45 38.71 .38 37.36 1.17"
              fill="#f8f1eb"
              fillRule="evenodd"
            />
            <polygon
              id="af"
              points="4.42 20.17 23.58 31.24 24.94 30.45 5.78 19.39 4.42 20.17"
              fill="#f8f1eb"
              fillRule="evenodd"
            />
            <polygon
              id="ag"
              points="25.85 32.11 55.03 15.19 55.03 13.76 25.85 30.67 25.85 32.11"
              fill="#84726e"
              fillRule="evenodd"
            />
            <polygon
              id="ah"
              points="55.03 15.2 55.65 15.55 55.65 14.12 55.03 13.76 55.03 15.2"
              fill="#e8dcc6"
              fillRule="evenodd"
            />
            <polygon
              id="ai"
              points="25.85 32.1 26.47 32.46 55.65 15.55 55.03 15.19 25.85 32.1"
              fill="#f8f1eb"
              fillRule="evenodd"
            />
            <polygon
              id="aj"
              points="25.85 32.1 26.47 32.46 26.47 31.03 25.85 30.67 25.85 32.1"
              fill="#dec7ae"
              fillRule="evenodd"
            />
            <polygon
              id="ak"
              points="25.85 30.67 26.47 31.03 55.65 14.12 55.03 13.76 25.85 30.67"
              fill="#dec7ae"
              fillRule="evenodd"
            />
            <polygon
              id="al"
              points="25.85 34.82 55.03 17.91 55.03 16.48 25.85 33.39 25.85 34.82"
              fill="#84726e"
              fillRule="evenodd"
            />
            <polygon
              id="am"
              points="55.03 17.91 55.65 18.27 55.65 16.84 55.03 16.48 55.03 17.91"
              fill="#e8dcc6"
              fillRule="evenodd"
            />
            <polygon
              id="an"
              points="25.85 34.82 26.47 35.18 55.65 18.27 55.03 17.91 25.85 34.82"
              fill="#f8f1eb"
              fillRule="evenodd"
            />
            <polygon
              id="ao"
              points="25.85 34.82 26.47 35.18 26.47 33.75 25.85 33.39 25.85 34.82"
              fill="#dec7ae"
              fillRule="evenodd"
            />
            <polygon
              id="ap"
              points="25.85 33.39 26.47 33.75 55.65 16.84 55.03 16.48 25.85 33.39"
              fill="#dec7ae"
              fillRule="evenodd"
            />
            <polygon
              id="aq"
              points="26.02 37.17 55.2 20.26 55.2 18.83 26.02 35.74 26.02 37.17"
              fill="#84726e"
              fillRule="evenodd"
            />
            <polygon
              id="ar"
              points="55.2 20.26 55.82 20.62 55.82 19.19 55.2 18.83 55.2 20.26"
              fill="#e8dcc6"
              fillRule="evenodd"
            />
            <polygon
              id="as"
              points="26.02 37.17 26.64 37.53 55.82 20.62 55.2 20.26 26.02 37.17"
              fill="#f8f1eb"
              fillRule="evenodd"
            />
            <polygon
              id="at"
              points="26.02 37.17 26.64 37.53 26.64 36.1 26.02 35.74 26.02 37.17"
              fill="#dec7ae"
              fillRule="evenodd"
            />
            <polygon
              id="au"
              points="26.02 35.74 26.64 36.1 55.82 19.19 55.2 18.83 26.02 35.74"
              fill="#dec7ae"
              fillRule="evenodd"
            />
            <polygon
              id="av"
              points="8.47 39.96 3.7 37.21 9.03 31.43 7.62 30.62 1.37 37.41 8.12 41.31 19.88 37.69 18.48 36.89 8.47 39.96"
              fill="#335774"
              fillRule="evenodd"
            />
            <polygon
              id="aw"
              points=".03 40.27 3.17 42.08 6.32 40.27 3.17 38.45 .03 40.27"
              fill="#335774"
              fillRule="evenodd"
            />
            <path
              id="ax"
              d="M4.1,39.73c-.51-.29-1.34-.29-1.85,0-.51,.29-.51,.77,0,1.07,.51,.29,1.34,.29,1.85,0,.51-.29,.51-.77,0-1.07"
              fill="#335774"
              fillRule="evenodd"
            />
            <polygon
              id="ay"
              points="8.47 38.74 3.7 35.99 9.03 30.21 7.62 29.4 1.37 36.19 8.12 40.09 19.88 36.47 18.48 35.67 8.47 38.74"
              fill="#9baeb0"
              fillRule="evenodd"
            />
            <polygon
              id="az"
              points=".03 39.05 3.17 40.86 6.32 39.05 3.17 37.23 .03 39.05"
              fill="#9baeb0"
              fillRule="evenodd"
            />
            <path
              id="ba"
              d="M4.1,38.51c-.51-.29-1.34-.29-1.85,0-.51,.29-.51,.77,0,1.07,.51,.29,1.34,.29,1.85,0,.51-.29,.51-.77,0-1.07"
              fill="#335774"
              fillRule="evenodd"
            />
            <polygon
              id="bb"
              points=".03 40.27 1.48 39.89 .03 39.05 .03 40.27"
              fill="#335774"
              fillRule="evenodd"
            />
            <polygon
              id="bc"
              points="1.37 37.41 2.17 36.65 1.37 36.19 1.37 37.41"
              fill="#335774"
              fillRule="evenodd"
            />
            <polygon
              id="bd"
              points="9.03 31.43 9.03 30.21 8.14 31.17 9.03 31.43"
              fill="#335774"
              fillRule="evenodd"
            />
            <polygon
              id="be"
              points="19.88 37.69 19.88 36.47 18.28 36.97 19.88 37.69"
              fill="#335774"
              fillRule="evenodd"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

IllustrationTrailerCar.propTypes = {
  /**
   * Size of the illustration.
   */
  size: PropTypes.number,
};

IllustrationTrailerCar.defaultProps = {
  size: 70,
};

export { IllustrationTrailerCar as default } from "./TrailerCar";
