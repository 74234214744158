import { t } from "i18next";
import { Link } from "react-router-dom";
import { IconAlert } from "../../ui/Icon/Line/Alert";
import { IconDownload } from "../../ui/Icon/Line/Download";
import { IconLocationHistory } from "../../ui/Icon/Line/LocationHistory";
import { IconTimer } from "../../ui/Icon/Line/Timer";
import { IconSuccess } from "../../ui/Icon/Solid/Success";
import { LabelText } from "../../ui/LabelText/LabelText";
import { Table } from "../../ui/Table/Table";
import {
  AbstractTableBuilder,
  Row,
} from "../../ui/Table/utils/AbstractTableBuilder";
import "../../ui/Table/utils/TableBuilder.css";
import { ConvertTimeZone } from "../../utils/DateAndTimeUtils";
import { downloadFileFromS3 } from "../../utils/S3Client";
import { Driver } from "../driver/driversSlice";
import { Preferences } from "../users/preference/preferencesSlice";
import { Vehicle } from "../vehicle/vehiclesSlice";
import { TachographFileStatus } from "./tachographFilesStatusSlice";

export class DashboardTachographFilesStatusTableBuilder extends AbstractTableBuilder {
  rowsBuilder = (
    columns: { label: string; field: string; sort: boolean }[] | undefined,
    entities: {
      tachographFileStatus?: TachographFileStatus;
      vehicle?: Vehicle;
      driver?: Driver;
    },
    preferencesContext: Preferences,
    writePermission: boolean = false
  ) => {
    let row: Row = { render: {} as Row };

    let tachographFileStatus: TachographFileStatus =
      entities.tachographFileStatus ?? ({} as TachographFileStatus);

    let vehicle: Vehicle = entities.vehicle ?? ({} as Vehicle);

    let driver: Driver = entities.driver ?? ({} as Driver);

    columns?.forEach((column) => {
      switch (column.field) {
        case "vehicle":
          row.vehicle = vehicle.alias ?? t("common.na");
          row.render.vehicle = (
            <LabelText
              label={vehicle.alias ?? t("common.na")}
              text={
                vehicle.brand
                  ? vehicle.brand + " " + vehicle.model
                  : t("common.na")
              }
            />
          );
          break;
        case "driver":
          row.render.driver = (
            <LabelText
              label={driver.id ? driver.id + "" : t("common.na")}
              text={
                driver.firstName
                  ? driver.firstName + " " + driver.lastName
                  : t("common.na")
              }
            />
          );
          break;
        case "license":
          row.render.license = vehicle.plate ?? t("common.na");
          break;
        case "downloadDate":
          row.render.downloadDate = ConvertTimeZone(
            tachographFileStatus.timestamp,
            preferencesContext.timeZone ?? undefined,
            preferencesContext.localeFormat ?? undefined
          );
          break;
        case "expirationDate":
          row.render.expirationDate = ConvertTimeZone(
            tachographFileStatus.deadline,
            preferencesContext.timeZone ?? undefined,
            preferencesContext.localeFormat ?? undefined
          );
          break;
        case "delay":
          row.render.delay =
            (tachographFileStatus.delay > 0
              ? "+" + tachographFileStatus.delay
              : tachographFileStatus.delay) +
            " " +
            t("common.days").toLowerCase();
          break;
        case "status":
          let statusIcon = (
            <IconAlert size={14} color="--global-colors-feedback-danger" />
          );
          switch (tachographFileStatus.status) {
            case "OK":
              statusIcon = (
                <IconSuccess
                  size={14}
                  color="--global-colors-feedback-success"
                />
              );
              break;
            case "PENDING":
              statusIcon = (
                <IconTimer
                  size={14}
                  withBackground={true}
                  backgroundColor="--global-colors-ui-secondary"
                  color="--global-colors-ui-white"
                />
              );
              break;
            default:
              break;
          }
          row.render.status = (
            <Table.TextIcon
              text={t(
                "pages.dashboardTachographFile.status." +
                  tachographFileStatus.status
              )}
              icon={statusIcon}
            />
          );
          break;
        case "fileName":
          row.render.fileName = tachographFileStatus.fileName;
          break;
        case "download":
          row.render.download = (
            <button
              onClick={() => {
                if (
                  tachographFileStatus?.path.includes(".DDD") &&
                  tachographFileStatus.fileName.includes(".DDD")
                ) {
                  downloadFileFromS3(
                    tachographFileStatus.path,
                    tachographFileStatus.fileName,
                    process.env.REACT_APP_AMAZON_S3_BUCKET_TACHOGRAPHFILE
                  );
                }
              }}
            >
              <IconDownload color="--global-colors-ink-ink" size={14} />
            </button>
          );
          break;
        case "archive":
          row.render.archive = (
            <Link
              to={`/dashboard/tachograph-files/${tachographFileStatus.type}/${tachographFileStatus.entityId}/`}
            >
              <IconLocationHistory color="--global-colors-ink-ink" size={14} />
            </Link>
          );
          break;
        default:
          break;
      }
    });

    return row;
  };
}
