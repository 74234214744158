import i18next from "i18next";
import _ from "lodash";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../../ui/Button/Button";
import Form from "../../ui/Forms/Form";
import SearchField from "../../ui/Forms/SearchField";
import { IconClose } from "../../ui/Icon/Line/Close";
import { BaseMap, ComponentOnMap } from "../../ui/Map/BaseMap";
import { DrawGeofence } from "../../ui/Map/DrawGeofence";
import { EditGeofences } from "../../ui/Map/EditGeofences";
import { MapType } from "../../ui/Map/MapType";
import { MarkerAsComponent } from "../../ui/Map/MarkerAsComponent";
import { Zoom } from "../../ui/Map/Zoom";
import { MapMarkerPin } from "../../ui/Marker/MapMarkerPin";
import { FeedbackModal } from "../../ui/Modal/CustomModals/FeedbackModal";
import { ToastNotification } from "../../utils/ToastNotification";
import {
  areGPSCoordinates,
  autoCompleteAddressFields,
  kmToMiles,
  mtToFt,
  mtToKm,
  numberAnnotation,
} from "../../utils/Utils";
import { Preferences } from "../users/preference/preferencesSlice";
import UserContext from "../users/userContext";
import "./GeofenceMap.css";
import { Circle, Geofence, Polygon, Position } from "./geofenceSlice";

interface PolyOptions {
  fillColor: string;
  fillOpacity: number;
  strokeOpacity: number;
  strokeWeight: number;
  strokeColor: string;
  editable: boolean;
  clickable: boolean;
  draggable: boolean;
  path?: Position[];
  center?: Position;
  radius?: number;
}
interface GeofenceMapProps {
  id: number;
  googleMapsApiKey: string;
  zoom: number;
  latitude: number;
  longitude: number;
  getDrawedPoints: (e: any) => any;
  getShapeType: (e: any) => any;
  getEditedShape?: (e: any) => any;
  setGeofence?: Geofence;
}
declare global {
  interface HTMLElement {
    msRequestFullScreen?: () => Promise<void>;
    mozRequestFullScreen?: () => Promise<void>;
    webkitRequestFullScreen?: () => Promise<void>;
  }
}

export const GeofenceMap: React.FC<GeofenceMapProps> = ({
  id,
  googleMapsApiKey,
  zoom,
  latitude,
  longitude,
  getDrawedPoints,
  getShapeType,
  setGeofence,
  getEditedShape,
}) => {
  // hooks to map handling
  let googleMap = window.google;
  const selectedShape = useRef<any>(null);
  const [map, setMap] = useState<any>();
  const [isMapIdle, setIsMapIdle] = useState<boolean>(false);
  const [componentsOnMap, setComponentsOnMap] = useState<ComponentOnMap[]>([]);
  const [openExitModal, setOpenExitModal] = useState(false);
  const [searchMarker, setSearchMarker] = useState<any>();

  const [preferencesContext]: [Preferences] = useContext(UserContext);
  // Routing hooks
  const [location, setLocation] = useState<string>("");
  const navigate = useNavigate();

  // hooks to search field that contains address of selected place
  const [addressSelected, setAddressSelected] = useState<any>();
  const [coordinatesSelected, setCoordinatesSelected] = useState<number[]>();
  // hooks to state undo and redo drawing geofence
  const stateShape = useRef<any[]>([]);
  const currentPositon = useRef<number>(-1);

  // hooks and variables to geofences
  let overlays: any[] = [];
  let drawingManager: any;
  let infoWindow: any;

  const drawFinish = useRef(false);

  // options for polyline/circle
  let polyOptions: PolyOptions = {
    fillColor: "#00aaff",
    fillOpacity: 0.3,
    strokeOpacity: 0.8,
    strokeWeight: 2,
    strokeColor: "#00aaff",
    editable: true,
    clickable: false,
    draggable: false,
  };

  const showTooltip = (drawingBound: any) => {
    let radius = Math.ceil(drawingBound.radius);
    let contents = preferencesContext.isMetric
      ? radius < 1000
        ? `${numberAnnotation(radius)} m`
        : `${numberAnnotation(mtToKm(radius))} km`
      : mtToFt(radius) < 1000
      ? `${numberAnnotation(mtToFt(radius))} ft`
      : `${numberAnnotation(kmToMiles(mtToKm(radius)))} mil`;

    infoWindow.setContent(contents);
    infoWindow.setPosition({
      lat: drawingBound.getBounds().getNorthEast().lat(),
      lng: drawingBound.getCenter().lng(),
    });
    infoWindow.open(map);
  };

  const hideRevert = () => {
    let node =
      document &&
      (document.querySelector('img[src$="undo_poly.png"]')
        ?.parentElement as HTMLElement);
    node.style.display = "none";
  };

  // handle cancel geofence area
  const deleteSelectedShape = () => {
    drawFinish.current = false;
    if (_.isEmpty(setGeofence)) {
      (document.querySelector(".alertMessage") as HTMLElement).style.display =
        "none";

      (document.querySelector(".infoMessage") as HTMLElement).style.display =
        "block";
    } else {
      (document.querySelector(".infoMessage") as HTMLElement).style.display =
        "block";
      (
        document.querySelector(".editInfoMessage") as HTMLElement
      ).style.display = "none";
    }
    getDrawedPoints({} as Polygon | Circle);
    if (selectedShape && selectedShape.current) {
      selectedShape.current.setMap(null);
      selectedShape.current = null;
    }
    if (googleMap) {
      infoWindow?.close();
      drawingManager.setDrawingMode(googleMap.maps.drawing.OverlayType.POLYGON);
    }
  };

  const redrawShapeGeofence = (geofence: any, path: Position[] | any) => {
    if (geofence.type.toLowerCase() === "polygon") {
      geofence.setOptions({
        path: path,
      });
      googleMap.maps.event.addListener(
        geofence.getPath(),
        "set_at",
        modifyShapeGeofencePolyline
      );
    } else {
      selectedShape.current.setOptions({
        stateRadius: true,
        stateCenter: true,
      });
      selectedShape.current.setCenter("center" in path ? path.center : []);
      selectedShape.current.setRadius("radius" in path ? path.radius : 0);
    }
  };

  // undo the last line segment of a polyline
  const undoLineSegment = () => {
    // undo from stored states
    if (currentPositon.current === 0 && stateShape.current.length) {
      currentPositon.current -= 1;
      let path;
      if (selectedShape.current.type.toLowerCase() === "polygon") {
        path =
          !!setGeofence && "points" in setGeofence.shape
            ? setGeofence.shape?.points
            : [];
      } else {
        path = {
          center: !!setGeofence && setGeofence.shape?.center,
          radius:
            !!setGeofence &&
            "radius" in setGeofence.shape &&
            setGeofence.shape?.radius,
        };
      }
      redrawShapeGeofence(selectedShape.current, path);
    } else if (currentPositon.current > 0 && stateShape.current.length) {
      currentPositon.current -= 1;
      let path;
      if (selectedShape.current.type.toLowerCase() === "polygon") {
        path = stateShape.current[currentPositon.current];
      } else {
        path = {
          center: stateShape.current[currentPositon.current][0],
          radius: stateShape.current[currentPositon.current][1],
        };
      }
      redrawShapeGeofence(selectedShape.current, path);
    } else if (!!setGeofence) {
      ToastNotification({
        toastId: "redoError",
        status: "default",
        description: i18next.t("admin.geofences.editViewCreate.redoUndoError"),
      });
    } else {
      // undo from drawing point
      let lastOverlay =
        overlays.length > 0 ? overlays[overlays.length - 1] : null;
      infoWindow?.close();
      if (googleMap && lastOverlay) {
        if (lastOverlay.type === googleMap.maps.drawing.OverlayType.CIRCLE) {
          // if it is circle remove entire area because it is not closed
          deleteSelectedShape();
          // set drawing mode to type of area drawed after last point
          drawingManager.setDrawingMode(
            googleMap.maps.drawing.OverlayType.CIRCLE
          );
        } else if (lastOverlay.overlay.getPath().length < 4) {
          // if there are unless of 3 points remove entire area because it is not closed
          deleteSelectedShape();
          // to anticipate error on emtpy array points
          if (lastOverlay.overlay.getPath().length === 1) {
            // set drawing mode to type of area drawed after last point
            drawingManager.setDrawingMode(
              googleMap.maps.drawing.OverlayType.POLYGON
            );
          }
        } else {
          lastOverlay.overlay.getPath().pop(); // remove last line segment
        }
      }
    }
  };

  // undo the last line segment of a polyline
  const redoLineSegment = () => {
    if (
      stateShape.current.length &&
      currentPositon.current !== stateShape.current.length - 1
    ) {
      currentPositon.current += 1;

      let path;
      if (selectedShape.current.type.toLowerCase() === "polygon") {
        path = stateShape.current[currentPositon.current];
      } else {
        path = {
          center: stateShape.current[currentPositon.current][0],
          radius: stateShape.current[currentPositon.current][1],
        };
      }
      redrawShapeGeofence(selectedShape.current, path);
    } else {
      ToastNotification({
        toastId: "redoError",
        status: "default",
        description: i18next.t("admin.geofences.editViewCreate.redoUndoError"),
      });
    }
  };

  // this method get geofence area coordinates for each point
  const getDrawedShapeData = (drawingBound: any) => {
    getShapeType(drawingBound.type.toUpperCase());

    if (drawingBound.type === "circle") {
      getDrawedPoints({
        center: {
          lat: drawingBound.getCenter().lat(),
          lng: drawingBound.getCenter().lng(),
        },
        radius: drawingBound.getRadius(),
      });
    } else {
      let polygonBounds = new googleMap.maps.LatLngBounds();

      for (let i = 0; i < drawingBound.getPath().getLength(); i++) {
        polygonBounds.extend(
          new googleMap.maps.LatLng({
            lat: drawingBound.getPath().getAt(i).lat(),
            lng: drawingBound.getPath().getAt(i).lng(),
          })
        );
      }

      let arrayOfPoints = [];
      for (let i = 0; i < drawingBound.getPath().getLength(); i++) {
        arrayOfPoints.push({
          lat: drawingBound.getPath().getAt(i).lat(),
          lng: drawingBound.getPath().getAt(i).lng(),
        });
      }

      getDrawedPoints({
        center: {
          lat: polygonBounds.getCenter().lat(),
          lng: polygonBounds.getCenter().lng(),
        },
        points: arrayOfPoints,
      });
    }
  };

  const handleEditView = (drawingBound: any) => {
    let bounds = new google.maps.LatLngBounds();
    let paths = drawingBound.getPaths();
    let path;
    for (let index = 0; index < paths.getLength(); index++) {
      path = paths.getAt(index);
      for (let i = 0; i < path.getLength(); i++) {
        bounds.extend(path.getAt(i));
      }
    }
    return bounds;
  };

  //#region this method is in charge to add components on map
  useEffect(() => {
    const componentsOnMapArray: ComponentOnMap[] = [];
    if (googleMap && map && isMapIdle) {
      infoWindow = new googleMap.maps.InfoWindow();

      // Creates a drawing manager attached to the map that allows the user to draw lines and shapes.
      drawingManager = new googleMap.maps.drawing.DrawingManager({
        drawingControl: false,
        circleOptions: polyOptions,
        polygonOptions: polyOptions,
      });

      if (!!setGeofence) {
        if (
          setGeofence.geofenceShapeEnum.toString() === "POLYGON" &&
          "points" in setGeofence.shape
        ) {
          polyOptions.path = setGeofence.shape?.points;
          polyOptions.editable = true;

          let tempPolyOptions: any = { ...polyOptions };
          tempPolyOptions.type = "polygon";

          selectedShape.current = new googleMap.maps.Polygon(tempPolyOptions);
          overlays.push({ overlay: selectedShape.current });

          map.fitBounds(handleEditView(selectedShape.current), 150);
        } else if (
          setGeofence.geofenceShapeEnum.toString() === "CIRCLE" &&
          "radius" in setGeofence.shape
        ) {
          polyOptions.center = setGeofence.shape?.center;
          polyOptions.radius = setGeofence.shape?.radius;
          polyOptions.editable = true;

          let tempPolyOptions: any = { ...polyOptions };
          tempPolyOptions.type = "circle";
          tempPolyOptions.stateRadius = false;
          tempPolyOptions.stateCenter = false;

          selectedShape.current = new googleMap.maps.Circle(tempPolyOptions);
          showTooltip(selectedShape.current);

          map.fitBounds(selectedShape.current.getBounds(), 150);
        }
        selectedShape.current?.setMap(map);
        drawFinish.current = true;
        getDrawedShapeData(selectedShape.current);

        (document.querySelector(".infoMessage") as HTMLElement).style.display =
          "none";
        (
          document.querySelector(".editInfoMessage") as HTMLElement
        ).style.display = "block";
      } else {
        drawingManager.setOptions({
          drawingMode: googleMap.maps.drawing.OverlayType.POLYGON,
        });
        drawingManager.setMap(map);
      }

      googleMap.maps.event.addListener(
        drawingManager,
        "overlaycomplete",
        function (e) {
          overlays.push(e); // store reference to added overlay

          drawFinish.current = true;
          (
            document.querySelector(".infoMessage") as HTMLElement
          ).style.display = "none";
          // Switch back to non-drawing mode after drawing a shape.
          drawingManager.setDrawingMode(null);

          // save overlay of drawed shape
          let selectedShapeTemp = e.overlay;
          selectedShapeTemp.type = e.type;
          selectedShape.current = selectedShapeTemp;

          getDrawedShapeData(selectedShape.current);

          getEditedShape && getEditedShape(true);

          // This event hide default redo option button
          googleMap.maps.event.addListener(
            selectedShape.current,
            "bounds_changed",
            hideRevert
          );
          //#region this events show info window for circle with radius
          googleMap.maps.event.addListener(
            selectedShape.current,
            "radius_changed",
            function () {
              showTooltip(selectedShape.current);
            }
          );

          googleMap.maps.event.addListener(
            selectedShape.current,
            "center_changed",
            function () {
              showTooltip(selectedShape.current);
            }
          );

          if (drawingManager)
            googleMap.maps.event.addListener(
              drawingManager,
              "circlecomplete",
              function () {
                showTooltip(selectedShape.current);
              }
            );

          googleMap.maps.event.addListener(
            selectedShape.current,
            "mouseup",
            hideRevert
          );
          //#endregion this events show info window for circle with radius
        }
      );

      if (!!setGeofence) {
        if (selectedShape.current.type.toLowerCase() === "polygon") {
          googleMap.maps.event.addListener(
            selectedShape.current.getPath(),
            "set_at",
            modifyShapeGeofencePolyline
          );
          googleMap.maps.event.addListener(
            selectedShape.current.getPath(),
            "insert_at",
            modifyShapeGeofencePolyline
          );
        } else {
          googleMap.maps.event.addListener(
            selectedShape.current,
            "radius_changed",
            modifyShapeGeofenceCircleRadius
          );

          googleMap.maps.event.addListener(
            selectedShape.current,
            "center_changed",
            modifyShapeGeofenceCircleCenter
          );
        }
      }

      componentsOnMapArray.push({
        selectorName: ".zoom-control-container",
        selectorPosition: googleMap.maps.ControlPosition.RIGHT_BOTTOM,
        component: (
          <div
            className="zoom-control-container"
            style={{
              zIndex: 1,
              position: "absolute",
              padding: "16px",
              marginBottom: "-10px",
            }}
          >
            <Zoom handleMinus={minusClick} handlePlus={plusClick} />
          </div>
        ),
      });

      componentsOnMapArray.push({
        selectorName: ".address-search-field",
        selectorPosition: googleMap.maps.ControlPosition.TOP_CENTER,
        component: (
          <div className="address-search-field">
            <Form>
              <SearchField
                id="geofenceAddressSelected"
                size="normal"
                placeholder={i18next.t(
                  "admin.geofences.editViewCreate.searchPlaceholder"
                )}
                onChange={(data: string) => {
                  setLocation(data);
                }}
                value={location}
                searchAddress={true}
                onAddressClick={(place: any) => {
                  if (!place.name || !areGPSCoordinates(place.name)) {
                    setLocation(place.formatted_address);
                    setAddressSelected(place);
                  } else {
                    let stringArray: number[] = [];
                    if (place.name.includes(",")) {
                      stringArray = place.name.split(/(,+)/);
                    } else {
                      stringArray = place.name.split(/(\s+)/);
                    }
                    setCoordinatesSelected(stringArray);
                  }
                }}
              />
            </Form>
          </div>
        ),
      });

      componentsOnMapArray.push({
        selectorName: ".maptype-control",
        selectorPosition: googleMap.maps.ControlPosition.BOTTOM_RIGHT,
        component: (
          <MapType
            handleMap={mapClick}
            handleSatellite={satelliteClick}
            activeSatellite={false}
          />
        ),
      });

      componentsOnMapArray.push({
        selectorName: ".geofence-button-close",
        selectorPosition: googleMap.maps.ControlPosition.TOP_RIGHT,
        component: (
          <div className="geofence-button-close nohover">
            <Button
              label=""
              onClick={() => {
                setOpenExitModal(true);
              }}
              onlyIcon
              size="small"
              aspect="danger"
            >
              <IconClose size={11} color="--global-colors-ui-white" />
            </Button>
          </div>
        ),
      });

      componentsOnMapArray.push({
        selectorName: ".geofence-draw-button",
        selectorPosition: googleMap.maps.ControlPosition.TOP_LEFT,
        component: (
          <div className="geofence-draw-button">
            <DrawGeofence
              clickOval={() => {
                clickDrawGeofence(googleMap.maps.drawing.OverlayType.CIRCLE);
              }}
              clickLine={() => {
                clickDrawGeofence(googleMap.maps.drawing.OverlayType.POLYGON);
              }}
              hasDrawedShape={{ value: drawFinish.current }}
            />
          </div>
        ),
      });

      componentsOnMapArray.push({
        selectorName: ".geofence-edit-button",
        selectorPosition: googleMap.maps.ControlPosition.TOP_RIGHT,
        component: (
          <div className="geofence-edit-button">
            <EditGeofences
              onCancel={() => {
                undoLineSegment();
              }}
              onAhead={() => {
                redoLineSegment();
              }}
              disableAhead={{
                // @todo: disable when the current value of the stateShape is equal to the last index of the same vector
                value: false,
              }}
              onDelete={deleteSelectedShape}
            />
          </div>
        ),
      });
      setComponentsOnMap(componentsOnMapArray);
    }
  }, [map, isMapIdle]);

  //#endregion

  const modifyShapeGeofencePolyline = () => {
    const currentPath = [
      ...selectedShape.current
        .getPath()
        .getArray()
        .map((latLng: any) => {
          return {
            lat: latLng.lat(),
            lng: latLng.lng(),
          };
        }),
    ];

    if (stateShape.current.length === 10) {
      stateShape.current.shift();
    } else if (currentPositon.current + 1 < stateShape.current.length) {
      stateShape.current.splice(currentPositon.current + 1);
      currentPositon.current += 1;
    } else {
      currentPositon.current += 1;
    }
    stateShape.current.push([...currentPath]);

    getDrawedShapeData(selectedShape.current);
    getEditedShape && getEditedShape(true);
  };

  const redrawShapeCircle = () => {
    const latCenter = selectedShape.current.getCenter().lat();
    const lngCenter = selectedShape.current.getCenter().lng();
    const radius = selectedShape.current.getRadius();
    const currentCircle = [
      {
        lat: latCenter,
        lng: lngCenter,
      },
      radius,
    ];

    if (stateShape.current.length === 10) {
      stateShape.current.shift();
    } else if (currentPositon.current + 1 < stateShape.current.length) {
      stateShape.current.splice(currentPositon.current + 1);
      currentPositon.current += 1;
    } else {
      currentPositon.current += 1;
    }
    stateShape.current.push([...currentCircle]);
    showTooltip(selectedShape.current);
    getDrawedShapeData(selectedShape.current);
    getEditedShape && getEditedShape(true);
  };
  const modifyShapeGeofenceCircleRadius = (a: any, b: any, c: any) => {
    if (!selectedShape.current.stateRadius) {
      redrawShapeCircle();
    }
    selectedShape.current.stateRadius = false;
  };
  const modifyShapeGeofenceCircleCenter = (a: any, b: any, c: any) => {
    if (!selectedShape.current.stateCenter) {
      redrawShapeCircle();
    }
    selectedShape.current.stateCenter = false;
  };

  const clickDrawGeofence = (type: string) => {
    if (!drawFinish.current) {
      drawingManager.setDrawingMode(type);
    } else {
      (document.querySelector(".alertMessage") as HTMLElement).style.display =
        "block";
      drawFinish.current = true;
    }
  };

  /**
   *  This use effect updates components on map on geofences data changes
  //  */
  useEffect(() => {
    setComponentsOnMap((prev: ComponentOnMap[]) => {
      prev.forEach((item: any) => {
        if (item.selectorName === ".geofence-draw-button") {
          item.component.props.children.props.hasDrawedShape.value =
            drawFinish.current;
        }
      });
      return prev;
    });
  }, [drawFinish.current]);

  /**
   *  This use effect updates components on map on geofences data changes
  //  */

  //#region map buttons methods
  // This method is used by zoom + button for zooming map
  const plusClick = () => {
    if (map) {
      const zoomTemp = map.getZoom();
      if (zoomTemp) {
        map.setZoom(zoomTemp + 1);
      }
    }
  };

  // This method is used by zoom - button for zooming map
  const minusClick = () => {
    if (map) {
      const zoomTemp = map.getZoom();
      if (zoomTemp) {
        map.setZoom(zoomTemp - 1);
      }
    }
  };

  // this method is used to activate street map view from mapType button
  const mapClick = () => {
    map.setMapTypeId("roadmap");
  };

  // this method is used to activate satellite view from mapType button
  const satelliteClick = () => {
    map.setMapTypeId("hybrid");
  };

  useEffect(() => {
    // handle places autocomplete on map
    if (googleMap && addressSelected && addressSelected.geometry) {
      const addressFields = autoCompleteAddressFields(
        addressSelected,
        () => {}
      );

      searchMarker?.setMap(null);
      let bounds = new googleMap.maps.LatLngBounds();
      if (addressSelected.geometry.viewport) {
        bounds.union(addressSelected.geometry.viewport);
      } else {
        bounds.extend(addressSelected.geometry.location);
      }
      map.fitBounds(bounds);
      addressFields?.streetNumber &&
        setSearchMarker(
          MarkerAsComponent({
            googleMap: googleMap,
            lat: addressSelected.geometry.location.lat(),
            lng: addressSelected.geometry.location.lng(),
            map: map,
            component: (
              <>
                <MapMarkerPin color="#0052BD" />
              </>
            ),
          })
        );
    }
  }, [addressSelected]);

  useEffect(() => {
    // handle places autocomplete on map
    if (googleMap && coordinatesSelected && coordinatesSelected.length > 0) {
      searchMarker?.setMap(null);
      map.setCenter({
        lat: Number(coordinatesSelected[0]),
        lng: Number(coordinatesSelected[2]),
      });
      map.setZoom(18);
      setSearchMarker(
        MarkerAsComponent({
          googleMap: googleMap,
          lat: coordinatesSelected[0],
          lng: coordinatesSelected[1],
          map: map,
          component: (
            <>
              <MapMarkerPin color="#0052BD" />
            </>
          ),
        })
      );
      setCoordinatesSelected([]);
    }
  }, [coordinatesSelected]);

  //#endregion map buttons methods

  return (
    <>
      <BaseMap
        id={id}
        googleMapsApiKey={googleMapsApiKey}
        zoom={zoom}
        latitude={latitude}
        longitude={longitude}
        getMap={setMap}
        getIsMapIdle={setIsMapIdle}
      >
        {componentsOnMap}
      </BaseMap>

      <div className="geofence-alert-info__align">
        <div className="geofence-alert-info infoMessage">
          {i18next.t("admin.geofences.editViewCreate.infoMessage")}
        </div>

        <div
          className="geofence-alert-info editInfoMessage"
          style={{ display: "none" }}
        >
          {i18next.t("admin.geofences.editViewCreate.editInfoMessage")}
        </div>

        <div
          className="geofence-alert-info alertMessage"
          style={{ display: "none" }}
        >
          {i18next.t("admin.geofences.editViewCreate.alertMessage")}
        </div>
      </div>
      <FeedbackModal
        open={openExitModal}
        title={i18next.t("admin.geofences.editViewCreate.modalTitle")}
        desc={i18next.t("admin.geofences.editViewCreate.modalDesc")}
        primaryLabel={i18next.t("common.confirm")}
        primaryAction={() => {
          navigate("/admin/geofences");
          setOpenExitModal(false);
        }}
        secondaryLabel={i18next.t("common.cancel")}
        secondaryAction={() => {
          setOpenExitModal(false);
        }}
        closeAction={() => {
          setOpenExitModal(false);
        }}
      />
    </>
  );
};
