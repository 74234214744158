import { t } from "i18next";
import { useEffect, useRef, useState } from "react";
import { useMatch, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { store } from "../../app/store";
import { GTFleetSuccessCodes } from "../../config/GTFleetSuccessCodes";
import { GTFleetErrorCodes } from "../../config/GTfleetErrorCodes";
import PageContent from "../../layout/PageContent";
import PageFilters from "../../layout/PageFilters";
import { ModalBody } from "../../ui/Modal/ModalBody";
import { ModalFooter } from "../../ui/Modal/ModalFooter";
import { ModalWrapper } from "../../ui/Modal/ModalWrapper";
import { PageCounter } from "../../ui/Table/PageCounter";
import { Table } from "../../ui/Table/Table";
import { vehicleStatusValues } from "../../ui/Vehicles/VehicleTypes";
import { ToastNotification } from "../../utils/ToastNotification";
import { getQueryString } from "../../utils/Utils";
import {
  Contract,
  contractsSelectors,
  getContractsAsync,
  selectContractsSliceStatus,
} from "../contract/contractsSlice";
import { Device, devicesSelectors } from "../device/devicesSlice";
import { FleetView, fleetViewsSelectors } from "../fleet/fleetViewsSlice";
import { Preset, presetsSelectors } from "../users/preset/presetsSlice";
import { UserPermissions } from "../users/privilege/privilegesSlice";
import { AdminVehicleFilterBar } from "./AdminVehicleFilterBar";
import "./AdminVehicles.css";
import { AdminVehiclesTableBuilder } from "./AdminVehiclesTableBuilder";
import {
  Vehicle,
  activateVehicleAsync,
  deactivateVehicleAsync,
  getVehicleDetailsAsync,
  getVehiclesDetailsPaginationAsync,
  selectVehiclesSlicePage,
  selectVehiclesSliceReasonCode,
  selectVehiclesSliceStatus,
  selectVehiclesSliceTotalElements,
  vehiclesEmptyState,
  vehiclesSelectors,
} from "./vehiclesSlice";
import {
  selectVehiclesStatusSliceReasonCode,
  selectVehiclesStatusSliceStatus,
  vehiclesStatusEmptyState,
} from "./vehiclesStatusSlice";

interface AdminVehiclesProps {
  permissions: UserPermissions;
}

const context = "vehicleAdmin";
interface TableData {
  columns: { label: string; field: string; sort: boolean }[] | undefined;
  rows: { [key: string]: any };
}

const tableData: TableData = {} as TableData;

const tableSchemaFix = [
  "vehicle",
  "group",
  "license",
  "vehicleStatus",
  "imei",
  "functionalities",
];

export const AdminVehicles: React.FC<AdminVehiclesProps> = ({
  permissions,
}) => {
  const navigate = useNavigate();
  const tableBuilderRef = useRef<AdminVehiclesTableBuilder>(
    new AdminVehiclesTableBuilder()
  );
  let tableBuilder = tableBuilderRef.current;

  const vehicleRoute = useMatch("/admin/vehicles/:action/:fleetId/:vehicleId");

  const [queryParamsFromFilterBar, setQueryParamsFromFilterBar] =
    useState<string>("");

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [currentSelectedVehicle, setCurrentSelectedVehicle] = useState(-1);
  let presets: Preset[] = useAppSelector(presetsSelectors.selectAll);
  let vehicles: Vehicle[] =
    useAppSelector(vehiclesSelectors.selectAll) ?? ({} as Vehicle[]);
  const contractsSliceStatus = useAppSelector(selectContractsSliceStatus);
  const selectedVehicle =
    useAppSelector((state) =>
      vehiclesSelectors.selectById(state, currentSelectedVehicle)
    ) ?? ({} as Vehicle);

  const vehiclesSliceStatus = useAppSelector(selectVehiclesSliceStatus);
  const vehiclesSliceReasonCode = useAppSelector(selectVehiclesSliceReasonCode);
  const vehiclesStatusSliceStatus = useAppSelector(
    selectVehiclesStatusSliceStatus
  );
  const vehiclesStatusSliceReasonCode = useAppSelector(
    selectVehiclesStatusSliceReasonCode
  );

  const isActive = selectedVehicle.status === vehicleStatusValues.ACTIVE;
  const isVehicleStatusIdle = vehiclesStatusSliceStatus === "idle";
  const [queryParamsChanged, setQueryParamsChanged] = useState<boolean>(false);
  const [resetPage, setResetPage] = useState<boolean>(false);

  useEffect(() => {
    if (queryParamsFromFilterBar) {
      setQueryParamsChanged(true);
    }
  }, [queryParamsFromFilterBar]);

  useEffect(() => {
    if (queryParamsChanged && isVehicleStatusIdle) {
      setQueryParamsChanged(false);
      setResetPage(true);
    } else {
      setResetPage(false);
    }
  }, [isVehicleStatusIdle, queryParamsChanged]);

  useEffect(() => {
    if (vehicleRoute !== null) {
      if (vehicleRoute.params.fleetId && vehicleRoute.params.vehicleId) {
        if (
          vehicleRoute.params.action === "activate" ||
          vehicleRoute.params.action === "deactivate"
        ) {
          store.dispatch(
            getVehicleDetailsAsync({
              id: parseInt(vehicleRoute.params.vehicleId),
              fleetId: parseInt(vehicleRoute.params.fleetId),
            })
          );
          setCurrentSelectedVehicle(Number(vehicleRoute.params.vehicleId));
          setShowConfirmation(true);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, vehicleRoute]);

  useEffect(() => {
    if (vehiclesSliceStatus === "idle") {
      if (vehiclesSliceReasonCode === GTFleetSuccessCodes.PATCH) {
        if (vehicleRoute?.params?.action === "activate") {
          ToastNotification({
            toastId: "vehicleActivateSuccess",
            status: "success",
            description: t("admin.vehicle.activateVehicle.success"),
          });
          onClose();
        } else if (vehicleRoute?.params?.action === "deactivate") {
          ToastNotification({
            toastId: "vehicleDeactivateSuccess",
            status: "success",
            description: t("admin.vehicle.deactivateVehicle.success"),
          });
          onClose();
        }
      }
    }
    if (vehiclesSliceStatus === "failed") {
      switch (vehiclesSliceReasonCode) {
        case GTFleetErrorCodes.USER_UNAUTHORIZED:
          ToastNotification({
            toastId: "unauthorizedError",
            status: "error",
            description: t("common.unauthorizedError"),
          });
          break;
        case GTFleetErrorCodes.VEHICLE_NOT_FOUND:
          if (vehicleRoute?.params?.action === "activate") {
            ToastNotification({
              toastId: "vehicleActivateError",
              status: "error",
              description: t("admin.vehicle.activateVehicle.error"),
            });
            onClose();
          } else if (vehicleRoute?.params?.action === "deactivate") {
            ToastNotification({
              toastId: "vehicleDeactivateError",
              status: "error",
              description: t("admin.vehicle.deactivateVehicle.error"),
            });
            onClose();
          } else {
            ToastNotification({
              toastId: "vehicleNotFoundError",
              status: "error",
              description: t("common.vehicleNotFoundError"),
            });
          }
          break;
        default:
          ToastNotification({
            toastId: "networkError",
            status: "error",
            description: t("common.networkError"),
          });
          break;
      }
    }
  }, [vehiclesSliceStatus, vehiclesSliceReasonCode]);

  const vehiclesSlicePages = useAppSelector(selectVehiclesSlicePage);

  const vehiclesSliceTotalElements = useAppSelector(
    selectVehiclesSliceTotalElements
  );

  useEffect(() => {
    if (
      vehiclesStatusSliceStatus === "failed" &&
      vehiclesStatusSliceReasonCode === ""
    ) {
      ToastNotification({
        toastId: "networkError",
        status: "error",
        description: t("common.networkError"),
      });
    }
  }, [vehiclesStatusSliceStatus, vehiclesStatusSliceReasonCode]);

  useEffect(() => {
    if (vehicles.length > 0) {
      const vehicleIds = vehicles.map((x) => x.id);
      const queryParams = getQueryString({ "vehicle.id": vehicleIds });
      store.dispatch(getContractsAsync({ queryParams: queryParams }));
    }
  }, [vehicles]);

  tableData.columns = tableBuilder.setColumns(
    tableSchemaFix
      ? tableSchemaFix?.map((element) => {
          return {
            name: element,
            sort: element === "vehicle",
          };
        })
      : []
  );

  tableData.rows =
    vehiclesSliceStatus === "idle" &&
    (contractsSliceStatus === "idle" || contractsSliceStatus === "failed") &&
    tableData.columns &&
    tableData?.columns?.length > 0
      ? vehicles.map((vehicle: Vehicle) => {
          let fleetView: FleetView =
            fleetViewsSelectors.selectById(store.getState(), vehicle.fleet) ??
            ({} as FleetView);
          let device: Device =
            devicesSelectors.selectById(store.getState(), vehicle.device) ??
            ({} as Device);
          let contract: Contract =
            contractsSelectors.selectById(store.getState(), vehicle.contract) ??
            ({} as Contract);
          return tableBuilder.rowsBuilder(
            tableData.columns,
            context,
            {
              vehicle,
              fleetView,
              contract,
              device,
            },
            navigate,
            permissions.vehicles.write
          );
        })
      : tableData.rows;

  useEffect(() => {
    document.title = t("navigation.adminMenu.vehicles") + " - Admin";
    return () => {
      store.dispatch(vehiclesStatusEmptyState());
      store.dispatch(vehiclesEmptyState());
    };
  }, []);

  function onClose() {
    setShowConfirmation(false);
    setCurrentSelectedVehicle(-1);
    navigate({
      pathname: "/admin/vehicles",
      search: queryParamsFromFilterBar,
    });
  }

  return (
    <>
      <PageFilters>
        <div className="col col-16">
          <AdminVehicleFilterBar
            presets={presets}
            callback={setQueryParamsFromFilterBar}
          />
        </div>
      </PageFilters>
      <PageContent>
        <div className="col">
          {vehiclesSliceStatus === "idle" ? (
            <ModalWrapper open={showConfirmation} closeAction={onClose}>
              <ModalBody
                title={
                  isActive
                    ? t("admin.vehicle.deactivateVehicle.title")
                    : t("admin.vehicle.activateVehicle.title")
                }
                desc={
                  isActive
                    ? t("admin.vehicle.deactivateVehicle.desc").replace(
                        "*name",
                        `${selectedVehicle.id} ${selectedVehicle.name}`
                      )
                    : t("admin.vehicle.activateVehicle.desc").replace(
                        "*name",
                        `${selectedVehicle.id} ${selectedVehicle.name}`
                      )
                }
                isScrollable={false}
              />
              <ModalFooter
                primaryAction={() => {
                  if (isActive) {
                    store.dispatch(
                      deactivateVehicleAsync({
                        fleetId: selectedVehicle.fleet,
                        id: selectedVehicle.id,
                      })
                    );
                  } else {
                    store.dispatch(
                      activateVehicleAsync({
                        fleetId: selectedVehicle.fleet,
                        id: selectedVehicle.id,
                      })
                    );
                  }
                }}
                primaryLabel={t("common.confirm")}
                secondaryAction={onClose}
                secondaryLabel={t("common.cancel")}
              />
            </ModalWrapper>
          ) : null}
          {tableData?.rows?.length > 0 && (
            <>
              <div className="ev-table">
                <Table data={tableData}>
                  <Table.Head hasTableSpace={true} />
                  <Table.Body />
                </Table>
              </div>
              <div className="ev-pagination">
                <PageCounter
                  isActionPerforming={vehiclesSliceStatus === "loading"}
                  resetPage={resetPage}
                  totalElements={vehiclesSliceTotalElements}
                  disabled={vehiclesSliceStatus !== "idle"}
                  numOfPages={vehiclesSlicePages}
                  onClick={(id, currentPage) => {
                    if (id !== currentPage) {
                      const pageAndSize = getQueryString({
                        page: id - 1,
                        size: "10",
                      });
                      const finalQueryParams = queryParamsFromFilterBar
                        ? queryParamsFromFilterBar +
                          pageAndSize.replace("?", "&")
                        : pageAndSize;
                      store.dispatch(
                        getVehiclesDetailsPaginationAsync({
                          queryParams: finalQueryParams,
                        })
                      );
                    }
                  }}
                />
              </div>
            </>
          )}
        </div>
      </PageContent>
    </>
  );
};
