import HttpClient from "../../../utils/HttpClient";

export default class PublicRoutesRepository extends HttpClient {
  constructor() {
    super(true);
  }

  getPublicRoutes(queryParams?: string) {
    const tenantId = localStorage.getItem("tenantId");
    return this.instance.get(
      "tenants/" + tenantId + "/publicRoutes/view" + (queryParams ?? "")
    );
  }

  getPublicRoutesListView() {
    const tenantId = localStorage.getItem("tenantId");
    return this.instance.get("tenants/" + tenantId + "/publicRoutes/listView");
  }
}
