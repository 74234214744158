import { t } from "i18next";
import _ from "lodash";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useAppSelector } from "../../../app/hooks";
import { store } from "../../../app/store";
import { Button } from "../../../ui/Button/Button";
import { Dropdown } from "../../../ui/Dropdown/Dropdown";
import Form from "../../../ui/Forms/Form";
import SearchField from "../../../ui/Forms/SearchField";
import { IconMap } from "../../../ui/Icon/Line/Map";
import { getQueryString } from "../../../utils/Utils";
import {
  VehicleView,
  getFilteredVehiclesDetailsViewsAsync,
  selectVehiclesViewSliceStatus,
  vehiclesViewEmptyState,
  vehiclesViewSelectors,
} from "../../vehicle/vehiclesViewSlice";
import {
  PublicRouteView,
  getFilteredPublicRoutesAsync,
  publicRoutesSelectors,
} from "./publicRoutesSlice";

interface QueryParams {
  [paramName: string]: any;
}

interface DropDownItem {
  key: string;
  label: string;
}

interface AdminPublicRoutesFilterBarProps {
  callback: (buildQueryParam: string) => void;
  onViewMapChange: (e: any) => any;
  externalViewMap: boolean;
}

export const AdminPublicRoutesFilterBar: React.FC<AdminPublicRoutesFilterBarProps> =
  ({ callback, onViewMapChange, externalViewMap }) => {
    const queryParamsRef = useRef<QueryParams>({});
    const [searchParams] = useSearchParams();
    const [viewMap, setViewMap] = useState(false);
    let queryParams: QueryParams = queryParamsRef.current;
    const navigate = useNavigate();
    const location = useLocation();
    const { pathname } = location;
    const [publicRouteStringSearch, setPublicRouteStringSearch] =
      useState<string>("");
    const [checkedVehicles, setCheckedVehicles] = useState<DropDownItem[]>([]);
    const publicRouteViews: PublicRouteView[] = useAppSelector(
      publicRoutesSelectors.selectAll
    );
    const vehicles: VehicleView[] = useAppSelector(
      vehiclesViewSelectors.selectAll
    );

    const vehiclesViewSliceStatus = useAppSelector(
      selectVehiclesViewSliceStatus
    );

    useEffect(() => {
      store.dispatch(getFilteredVehiclesDetailsViewsAsync(""));
      return function cleanUp() {
        store.dispatch(vehiclesViewEmptyState());
      };
    }, []);

    useEffect(() => {
      setViewMap(externalViewMap);
    }, [externalViewMap]);

    useEffect(() => {
      const map = new Map();
      const currentSearchParams =
        searchParams.toString() !== "" ? searchParams : null;
      if (!!currentSearchParams && _.isEmpty(queryParams)) {
        const size = currentSearchParams.get("size");
        map.set("size", size ?? "10");

        // searchBar
        const nameSearch = currentSearchParams.get("longName");
        if (nameSearch) {
          setPublicRouteStringSearch(nameSearch);
          map.set("longName", nameSearch);
        }

        // vehicles Dropdown
        const vehicle = currentSearchParams?.getAll("vehicle.id");
        if (!!vehicle) {
          const selectedVehicle: DropDownItem[] = [];
          vehicle.forEach((vehicle) => {
            selectedVehicle.push({
              key: vehicle,
              label: t(vehicle.toLowerCase()),
            } as DropDownItem);
          });
          if (selectedVehicle && selectedVehicle.length > 0) {
            setCheckedVehicles(selectedVehicle);
            map.set(
              "vehicle.id",
              selectedVehicle.map((x) => x.key)
            );
          }
        }
      }
      if (map.size === 0 && _.isEmpty(queryParams)) {
        map.set("size", "10");
      }
      if (map.size > 0) {
        handleChanges(map);
      }
    }, [location]);

    const handleChanges = (params: Map<string, string[] | string>): void => {
      let queryString = "";
      let executeQuery = false;
      if (!!params) {
        params.forEach((value, key) => {
          if (!!value && value.length > 0) {
            queryParams[key] = value;
            executeQuery = true;
          } else {
            if (queryParams.hasOwnProperty(key)) {
              delete queryParams[key];
              executeQuery = true;
            }
          }
        });
        queryString = getQueryString(queryParams);
      }
      // effettuare chiamata API con parametri in query string
      if (executeQuery) {
        store.dispatch(
          getFilteredPublicRoutesAsync({
            queryParams: queryString,
          })
        );
        navigate({
          pathname: pathname,
          search: queryString,
        });
        callback(queryString);
      }
    };

    const debouncedSearch = useRef(
      _.debounce(async (val) => {
        const searchRoute = new Map();
        searchRoute.set("longName", val);
        handleChanges(searchRoute);
        setPublicRouteStringSearch(val);
      }, 1000)
    ).current;

    useEffect(() => {
      return () => {
        debouncedSearch.cancel();
      };
    }, [debouncedSearch]);

    async function handleSearch(val: string) {
      await debouncedSearch(val);
    }

    const vehicleInfos: DropDownItem[] = vehicles.map(
      (vehicle: VehicleView) => {
        return {
          label: vehicle.alias + " - " + vehicle.plate,
          key: vehicle.id.toString(),
        };
      }
    );

    return (
      <>
        <div className="publicRoutes-filterbar-row">
          <div className="publicRoutes-filterbar-search">
            <Form>
              <SearchField
                name="search"
                id="publicRoutes"
                size="small"
                placeholder={t("admin.notifications.searchField.searchByName")}
                value={publicRouteStringSearch}
                onChange={(val: string) => {
                  handleSearch(val);
                }}
              />
            </Form>
          </div>
          <div className="publicRoutes-filterbar-vehicles">
            <Dropdown
              placeholderInValue={""}
              itemAttribute="label"
              hasCheckbox={true}
              placeholder={t("breadcrumbs.vehicles")}
              size={"small"}
              loading={vehiclesViewSliceStatus === "loading"}
              onChange={(val: DropDownItem[]) => {
                const map = new Map();
                const vehicleValues: string[] = [];
                val.forEach((v) => {
                  const vehicleValue = vehicleInfos.find(
                    (vehicle) => vehicle.key === v.key
                  )?.key;
                  if (vehicleValue) {
                    vehicleValues.push(vehicleValue);
                  }
                });
                map.set("vehicle.id", vehicleValues);
                handleChanges(map);
              }}
              options={vehicleInfos}
              value={checkedVehicles}
            />
          </div>
          <div className="publicRoutes-filterbar-viewMap">
            <Button
              aspect="secondary"
              size="small"
              label={
                viewMap
                  ? t("report.details.hideMap")
                  : t("report.details.viewMap")
              }
              onClick={() => {
                setViewMap(!viewMap);
                onViewMapChange(!viewMap);
              }}
              disabled={publicRouteViews.length === 0}
            >
              <IconMap size={14} color="--global-colors-ink-light" />
            </Button>
          </div>
        </div>
      </>
    );
  };
