import dayjs from "dayjs";
import "dayjs/locale/it";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { IconCheck } from "../Icon/Line/Check";
import { Check } from "../Indicators/Check";
import { DatePickerContext } from "./DatePickerContext";
import { formatPresetDate } from "./utils/DatePickerPresetFormatter";
dayjs.extend(require("dayjs/plugin/localizedFormat"));
dayjs.extend(require("dayjs/plugin/isToday"));

export const DatePickerPreset = (weekPresets) => {
  const picker = useContext(DatePickerContext);
  const { t } = useTranslation();

  useEffect(() => {
    const today = dayjs();
    const startOfWeek = today.startOf("week").format("YYYY-MM-DD");
    const endOfWeek = today.endOf("week").format("YYYY-MM-DD");
    const currentWeek = { startRange: startOfWeek, endRange: endOfWeek };

    picker.handleFixedWeekPreset(currentWeek);
    picker.setPreset(t("common.datePicker.datePresets.thisWeek"));
  }, []);

  const computeStatus = (status) => {
    switch (status) {
      case "TO_COMPILE":
        return "nothing";
      case "TO_COMPLETE":
        return "pending";
      case "COMPLETED":
        return "success";
    }
  };

  return (
    <div>
      <div className="preset-title">
        {t("common.datePicker.datePresets.title")}
      </div>
      <div className="preset-list">
        {weekPresets && weekPresets.length > 0 ? (
          weekPresets.map((week, index) => {
            const presetLabel = formatPresetDate(
              week.startRange,
              week.endRange
            );
            return (
              <ul>
                <li
                  key={index}
                  onClick={() => {
                    picker.handleFixedWeekPreset(week);
                    picker.setPreset(presetLabel);
                  }}
                  className={
                    picker.preset === presetLabel ? "active-preset" : ""
                  }
                >
                  <div className="preset-check">
                    {picker.preset === presetLabel && (
                      <IconCheck size={14} color="--global-colors-ui-white" />
                    )}
                  </div>
                  {presetLabel}
                  <div className="preset-status">
                    <Check
                      size={"medium"}
                      status={computeStatus(week.status)}
                    />
                  </div>
                </li>
              </ul>
            );
          })
        ) : (
          <ul>
            <li
              onClick={() => {
                picker.handleDatePreset("lifetime");
                picker.setPreset("Lifetime");
              }}
              className={picker.preset === "Lifetime" ? "active-preset" : ""}
            >
              <span>
                {picker.preset === "Lifetime" && (
                  <IconCheck size={14} color="--global-colors-ui-white" />
                )}
              </span>
              {t("common.datePicker.datePresets.lifetime")}
            </li>
            <li
              onClick={() => {
                picker.handleDatePreset("today");
                picker.setPreset(t("common.datePicker.datePresets.today"));
              }}
              className={
                picker.preset === t("common.datePicker.datePresets.today")
                  ? "active-preset"
                  : ""
              }
            >
              <span>
                {picker.preset === t("common.datePicker.datePresets.today") && (
                  <IconCheck size={14} color="--global-colors-ui-white" />
                )}
              </span>
              {t("common.datePicker.datePresets.today")}
            </li>
            <li
              onClick={() => {
                picker.handleDatePreset("yesterday");
                picker.setPreset(t("common.datePicker.datePresets.yesterday"));
              }}
              className={
                picker.preset === t("common.datePicker.datePresets.yesterday")
                  ? "active-preset"
                  : ""
              }
            >
              <span>
                {picker.preset ===
                  t("common.datePicker.datePresets.yesterday") && (
                  <IconCheck size={14} color="--global-colors-ui-white" />
                )}
              </span>
              {t("common.datePicker.datePresets.yesterday")}
            </li>
            <li
              onClick={() => {
                picker.handleDatePreset("3-days");
                picker.setPreset(
                  t("common.datePicker.datePresets.lastThreeDays")
                );
              }}
              className={
                picker.preset ===
                t("common.datePicker.datePresets.lastThreeDays")
                  ? "active-preset"
                  : ""
              }
            >
              <span>
                {picker.preset ===
                  t("common.datePicker.datePresets.lastThreeDays") && (
                  <IconCheck size={14} color="--global-colors-ui-white" />
                )}
              </span>
              {t("common.datePicker.datePresets.lastThreeDays")}
            </li>
            <li
              onClick={() => {
                picker.handleDatePreset("5-days");
                picker.setPreset(
                  t("common.datePicker.datePresets.lastFiveDays")
                );
              }}
              className={
                picker.preset ===
                t("common.datePicker.datePresets.lastFiveDays")
                  ? "active-preset"
                  : ""
              }
            >
              <span>
                {picker.preset ===
                  t("common.datePicker.datePresets.lastFiveDays") && (
                  <IconCheck size={14} color="--global-colors-ui-white" />
                )}
              </span>
              {t("common.datePicker.datePresets.lastFiveDays")}
            </li>
            <li
              onClick={() => {
                picker.handleDatePreset("current-week");
                picker.setPreset(t("common.datePicker.datePresets.thisWeek"));
              }}
              className={
                picker.preset === t("common.datePicker.datePresets.thisWeek")
                  ? "active-preset"
                  : ""
              }
            >
              <span>
                {picker.preset ===
                  t("common.datePicker.datePresets.thisWeek") && (
                  <IconCheck size={14} color="--global-colors-ui-white" />
                )}
              </span>
              {t("common.datePicker.datePresets.thisWeek")}
            </li>
            <li
              onClick={() => {
                picker.handleDatePreset("last-week");
                picker.setPreset(t("common.datePicker.datePresets.lastWeek"));
              }}
              className={
                picker.preset === t("common.datePicker.datePresets.lastWeek")
                  ? "active-preset"
                  : ""
              }
            >
              <span>
                {picker.preset ===
                  t("common.datePicker.datePresets.lastWeek") && (
                  <IconCheck size={14} color="--global-colors-ui-white" />
                )}
              </span>
              {t("common.datePicker.datePresets.lastWeek")}
            </li>
            <li
              onClick={() => {
                picker.handleDatePreset("current-month");
                picker.setPreset(t("common.datePicker.datePresets.thisMonth"));
              }}
              className={
                picker.preset === t("common.datePicker.datePresets.thisMonth")
                  ? "active-preset"
                  : ""
              }
            >
              <span>
                {picker.preset ===
                  t("common.datePicker.datePresets.thisMonth") && (
                  <IconCheck size={14} color="--global-colors-ui-white" />
                )}
              </span>
              {t("common.datePicker.datePresets.thisMonth")}
            </li>
            <li
              onClick={() => {
                picker.handleDatePreset("last-month");
                picker.setPreset(t("common.datePicker.datePresets.lastMonth"));
              }}
              className={
                picker.preset === t("common.datePicker.datePresets.lastMonth")
                  ? "active-preset"
                  : ""
              }
            >
              <span>
                {picker.preset ===
                  t("common.datePicker.datePresets.lastMonth") && (
                  <IconCheck size={14} color="--global-colors-ui-white" />
                )}
              </span>
              {t("common.datePicker.datePresets.lastMonth")}
            </li>
          </ul>
        )}
      </div>
    </div>
  );
};
