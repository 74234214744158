import dayjs from "dayjs";
import { t } from "i18next";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { store } from "../../app/store";
import { GTFleetSuccessCodes } from "../../config/GTFleetSuccessCodes";
import PageContent from "../../layout/PageContent";
import PageFilters from "../../layout/PageFilters";
import { Button } from "../../ui/Button/Button";
import { Dropdown } from "../../ui/Dropdown/Dropdown";
import Form from "../../ui/Forms/Form";
import { SingleDatePicker } from "../../ui/Forms/SingleDatePicker";
import TextField from "../../ui/Forms/TextField";
import { Tooltip } from "../../ui/Forms/Tooltip";
import { Check } from "../../ui/Indicators/Check";
import { Tag } from "../../ui/Tags/Tag";
import { DataCommon } from "../../utils/Common";
import { ToastNotification } from "../../utils/ToastNotification";
import { Preferences } from "./preference/preferencesSlice";
import {
  PrivilegesDefault,
  getPrivilegesDefaultAsync,
  privilegesDefaultEmptyState,
  privilegesDefaultSelectors,
  selectPrivilegesDefaultSliceStatus,
} from "./privilege/privilegesDefaultSlice";
import {
  Privileges,
  UserPermissions,
  selectPrivilegesSliceReasonCode,
  selectPrivilegesSliceStatus,
} from "./privilege/privilegesSlice";

import { GTFleetErrorCodes } from "../../config/GTfleetErrorCodes";
import { Switch } from "../../ui/Forms/Switch";
import { ElementType } from "../../ui/Group/ElementTypeEnum";
import { IconEye } from "../../ui/Icon/Line/Eye";
import { IconEyeSlash } from "../../ui/Icon/Line/EyeSlash";
import { TabSection } from "../../ui/Tabs/TabSection";
import { Tabs } from "../../ui/Tabs/Tabs";
import { ThumbProfile } from "../../ui/ThumbProfile/ThumbProfile";
import { getFullDate } from "../../utils/DateAndTimeUtils";
import {
  getIllustrationFromVehicleType,
  getQueryString,
} from "../../utils/Utils";
import {
  AdminPrivilegesResourceTab,
  ResourceProps,
} from "../components/AdminPrivilegesResourceTab";
import { Driver, driversSelectors } from "../driver/driversSlice";
import {
  DriverStatus,
  driversStatusSelectors,
  getFilteredDriversStatusAndDetailsAsync,
  selectDriverStatusSliceReasonCode,
  selectDriverStatusSliceStatus,
} from "../driver/driversStatusSlice";
import {
  Geofence,
  geofencesSelectors,
  getGeofencesAsync,
  selectGeofencesSliceReasonCode,
  selectGeofencesSliceStatus,
} from "../geofence/geofenceSlice";
import {
  GeofenceCategory,
  geofenceCategoriesSelectors,
  getGeofenceCategoriesAsync,
  selectGeofenceCategoriesSliceReasonCode,
  selectGeofenceCategoriesSliceStatus,
} from "../geofenceCategory/geofenceCategoriesSlice";
import { Vehicle, vehiclesSelectors } from "../vehicle/vehiclesSlice";
import {
  VehicleStatus,
  getVehiclesAsync,
  selectVehiclesStatusSliceReasonCode,
  selectVehiclesStatusSliceStatus,
  vehiclesStatusSelectors,
} from "../vehicle/vehiclesStatusSlice";
import "./User.css";
import {
  SecurityProfiles,
  getSecurityProfilesAsync,
  securityProfilesEmptyState,
  securityProfilesSelectors,
  selectSecurityProfilesSliceReasonCode,
  selectSecurityProfilesSliceStatus,
} from "./securityProfile/securityProfilesSlice";
import UserContext from "./userContext";
import {
  UserInfo,
  createUserAsync,
  selectUsersInfoSliceReasonCode,
  selectUsersInfoSliceStatus,
  userInfoSelectors,
  usersInfoEmptyState,
} from "./usersInfoSlice";

interface CreateUserProps {
  permissions: UserPermissions;
}

interface DataCommonProps {
  label: string;
}

export type SelectedPrivileges = {
  vehicles: number[];
  drivers: number[];
  geofences: number[];
};

const CreateUser: React.FC<CreateUserProps> = ({ permissions }) => {
  const navigate = useNavigate();
  const prefixList: DataCommonProps[] =
    DataCommon()?.prefixList ?? ([] as DataCommonProps[]);
  const now = new Date();
  const [editUser, setEditUser] = useState<UserInfo>({
    prefix: "+39",
    activationDate: now,
    securityProfile: { id: 1, name: "FLEET_MANAGER" },
    expirationDate: new Date(
      now.getFullYear() + 1,
      now.getMonth(),
      now.getDay()
    ),
  } as UserInfo);
  const [errorList, setErrorList] = useState<string[]>([]);
  const [privilegesGroup, setPrivilegesGroup] = useState<boolean>(false);
  const [modifiedPrivilege, setModifiedPrivilege] =
    useState<SelectedPrivileges>({} as SelectedPrivileges);

  useEffect(() => {
    document.title = t("admin.user.editViewCreate.createAccount") + " - Admin";
    store.dispatch(getSecurityProfilesAsync());
    return () => {
      store.dispatch(usersInfoEmptyState());
      store.dispatch(privilegesDefaultEmptyState());
      store.dispatch(securityProfilesEmptyState());
    };
  }, []);

  const selectedUser: UserInfo[] =
    useAppSelector(userInfoSelectors.selectAll) ?? ({} as UserInfo);
  const userSliceStatus = useAppSelector(selectUsersInfoSliceStatus);
  const userSliceReasonCode = useAppSelector(selectUsersInfoSliceReasonCode);

  let privilegesDefault: PrivilegesDefault[] =
    useAppSelector((state: any) =>
      privilegesDefaultSelectors.selectAll(state)
    ) ?? ({} as PrivilegesDefault);

  const securityProfiles: SecurityProfiles[] = useAppSelector(
    securityProfilesSelectors.selectAll
  ).filter(
    (x) =>
      x.name != "HS_INTEGRATION" &&
      x.name != "SYSTEM" &&
      x.name != "TENANT_MANAGER"
  );
  const securityProfileSliceStatus = useAppSelector(
    selectSecurityProfilesSliceStatus
  );
  const securityProfileSliceReasonCode = useAppSelector(
    selectSecurityProfilesSliceReasonCode
  );

  const privilegeDefaultSliceStatus = useAppSelector(
    selectPrivilegesDefaultSliceStatus
  );

  const privilegesSliceStatus = useAppSelector(selectPrivilegesSliceStatus);
  const privilegesSliceReasonCode = useAppSelector(
    selectPrivilegesSliceReasonCode
  );

  const [preferencesContext]: [Preferences] = useContext(UserContext);

  const editPreferences: Partial<Preferences> = { ...preferencesContext };
  delete editPreferences.id;

  useEffect(() => {
    if (editUser.securityProfile?.id !== undefined) {
      store.dispatch(
        getPrivilegesDefaultAsync({
          id: editUser.securityProfile?.id,
        })
      );
    }
  }, [editUser.securityProfile?.id]);
  //#endregion

  //#region "Password validation"
  const [newPassword, setNewPassword] = useState<string | null>(null);
  const [viewNewPassword, setViewNewPassword] = useState<boolean>(true);
  const [repeatPassword, setRepeatPassword] = useState<string>("");
  const [viewRepeatPassword, setViewRepeatPassword] = useState<boolean>(true);
  const [allValidationArray, setAllValidationArray] = useState({
    minLenght: false,
    lowUp: false,
    number: false,
    specChar: false,
  });

  useEffect(() => {
    if (newPassword) {
      const controlPassword: any = newPassword;
      setAllValidationArray({
        minLenght: controlPassword?.length >= 8,
        lowUp:
          controlPassword?.toUpperCase() !== controlPassword &&
          controlPassword?.toLowerCase() !== controlPassword,
        number: controlPassword?.match(/\d+/g),
        specChar: controlPassword?.match(
          /[ `!@#$%^&*()_+\-=[\]{};':"|,.<>\\/?~]/
        ),
      });
      const findPassword = errorList.findIndex((item) => item === "password");
      controlPassword.length > 1 &&
      controlPassword === repeatPassword &&
      allValidationArray
        ? setErrorList(errorList.filter((item) => item !== "password"))
        : findPassword < 0 && setErrorList([...errorList, "password"]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newPassword, repeatPassword]);
  //#endregion

  //#region Render

  const displayValidator = (condition: string) => {
    if (editUser.password === "") {
      return "default-constraint";
    } else {
      switch (condition) {
        case "minLenght":
          return allValidationArray.minLenght
            ? "success-constraint"
            : "error-constraint";
        case "lowUp":
          return allValidationArray.lowUp
            ? "success-constraint"
            : "error-constraint";
        case "number":
          return allValidationArray.number
            ? "success-constraint"
            : "error-constraint";
        case "specChar":
          return allValidationArray.specChar
            ? "success-constraint"
            : "error-constraint";
        case "password":
          return repeatPassword !== "" && newPassword === repeatPassword
            ? "success-constraint"
            : "error-constraint";
      }
    }
  };

  const viewExpirationDate = () => {
    if ("expirationDate" in editUser) {
      return editUser.expirationDate
        ? ""
        : t("admin.user.editViewCreate.never");
    } else {
      return (
        t("admin.user.editViewCreate.expirationDate") +
        " (" +
        preferencesContext.localeFormat +
        ")"
      );
    }
  };

  const translateSecurityProfiles = (securityProfile: string) => {
    switch (securityProfile) {
      case "FLEET_MANAGER":
        return t("admin.user.profile.fleet");
      case "MAINTENANCE_MANAGER":
        return t("admin.user.profile.maintenance");
      case "DRIVER":
        return t("admin.user.profile.driver");
      case "BASIC":
        return t("admin.user.profile.basic");
      default:
        return t("admin.user.profile.unknown");
    }
  };

  const handleSecurityProfileChange = (val: any) => {
    if (val[0].id) {
      const securityProfile: SecurityProfiles =
        securityProfiles.find(
          (securityProfile) => securityProfile.id === val[0].id
        ) ?? ({} as SecurityProfiles);
      setEditUser({
        ...editUser,
        securityProfile: {
          name: securityProfile.name,
          id: securityProfile.id,
        },
      });
    }
  };
  //#endregion

  //#region vehicles variables
  const [vehiclesCannotSee, setVehiclesCannotSee] = useState(
    [] as ResourceProps[]
  );

  const [vehiclesCanSee, setVehiclesCanSee] = useState<ResourceProps[]>(
    [] as ResourceProps[]
  );

  const [driversCanSee, setDriversCanSee] = useState<ResourceProps[]>(
    [] as ResourceProps[]
  );

  const [geofencesCanSee, setGeofencesCanSee] = useState<ResourceProps[]>(
    [] as ResourceProps[]
  );

  let vehicles: Vehicle[] = useAppSelector((state) =>
    vehiclesSelectors.selectAll(state)
  );

  let vehiclesStatus: VehicleStatus[] = useAppSelector((state) =>
    vehiclesStatusSelectors.selectAll(state)
  );
  const vehiclesStatusSliceStatus = useAppSelector(
    selectVehiclesStatusSliceStatus
  );
  const vehiclesStatusSliceReasonCode = useAppSelector(
    selectVehiclesStatusSliceReasonCode
  );

  useEffect(() => {
    if (vehicles.length > 0 && vehiclesStatus.length > 0) {
      const vehiclesCannotSeeTemp = vehicles.map((vehicle) => {
        const vehicleStatus = vehiclesStatus.find((x) => x.id === vehicle.id);
        return {
          key: vehicle.id,
          id: vehicle.id,
          firstTitle: vehicle.alias,
          secondTitle: vehicle.plate,
          firstSubtitle: vehicle.status,
          secondSubtitle: getFullDate(
            vehicleStatus?.dynamicFields?.lastUpdate,
            false
          ),
          type: ElementType.vehicle,
          icon: getIllustrationFromVehicleType(vehicle),
        } as ResourceProps;
      });

      setVehiclesCannotSee(vehiclesCannotSeeTemp);
    }
  }, [vehicles, vehiclesStatus]);
  //#endregion

  //#region drivers variables
  const [driversCannotSee, setDriversCannotSee] = useState(
    [] as ResourceProps[]
  );
  let drivers: Driver[] = useAppSelector((state) =>
    driversSelectors.selectAll(state)
  );

  let driversStatus: DriverStatus[] = useAppSelector((state) =>
    driversStatusSelectors.selectAll(state)
  );
  const driversStatusSliceStatus = useAppSelector(
    selectDriverStatusSliceStatus
  );
  const driversStatusSliceReasonCode = useAppSelector(
    selectDriverStatusSliceReasonCode
  );
  useEffect(() => {
    if (drivers.length > 0 && driversStatus.length > 0) {
      const driversCannotSeeTemp = drivers.map((driver) => {
        const driverStatus = driversStatus.find((x) => x.id === driver.id);
        return {
          key: driver.id,
          id: driver.id,
          firstTitle: `${driver.firstName} ${driver.lastName}`,
          firstSubtitle: driver.status,
          secondSubtitle: getFullDate(
            driverStatus?.dynamicFields?.lastUpdate,
            false
          ),
          type: ElementType.driver,
          icon: (
            <ThumbProfile
              size="small"
              alt="Driver Thumbnail"
              imgUrl={driver.avatar}
            />
          ),
        } as ResourceProps;
      });
      setDriversCannotSee(driversCannotSeeTemp);
    }
  }, [drivers, driversStatus]);
  //#endregion

  //#region geofences variables
  const [geofencesCannotSee, setGeofencesCannotSee] = useState(
    [] as ResourceProps[]
  );
  let geofences: Geofence[] = useAppSelector(geofencesSelectors.selectAll);
  let geofenceCategories: GeofenceCategory[] = useAppSelector(
    geofenceCategoriesSelectors.selectAll
  );
  const geofenceCategoriesSliceStatus = useAppSelector(
    selectGeofenceCategoriesSliceStatus
  );
  const geofenceCategoriesSliceReasonCode = useAppSelector(
    selectGeofenceCategoriesSliceReasonCode
  );

  const geofencesSliceStatus = useAppSelector(selectGeofencesSliceStatus);
  const geofencesSliceReasonCode = useAppSelector(
    selectGeofencesSliceReasonCode
  );
  useEffect(() => {
    if (geofences.length > 0 && geofenceCategories.length > 0) {
      const geofencesCannotSeeTemp = geofences.map((geofence) => {
        const geofenceCategory = geofenceCategories.find(
          (x) => x.id === geofence.geofenceCategory
        );
        const resourceProp = {
          key: geofence.id,
          id: geofence.id,
          firstTitle: geofence.name,
          secondTitle: geofenceCategory?.name,
          type: ElementType.geofence,
          icon: getIllustrationFromVehicleType(geofence),
          categoryColor: geofenceCategory?.color,
        } as ResourceProps;
        return resourceProp;
      });
      setGeofencesCannotSee(geofencesCannotSeeTemp);
    }
  }, [geofences, geofenceCategories]);

  // Triggered on switch movement

  useEffect(() => {
    if (privilegesGroup) {
      if (
        privilegesDefault.some((x) => x.entityType === "Vehicles") &&
        permissions.vehicles.read
      ) {
        store.dispatch(getVehiclesAsync());
      }
      if (
        privilegesDefault.some((x) => x.entityType === "Drivers") &&
        permissions.drivers.read
      ) {
        store.dispatch(getFilteredDriversStatusAndDetailsAsync(""));
      }
      if (
        privilegesDefault.some((x) => x.entityType === "Geofences") &&
        permissions.geofences.read
      ) {
        store.dispatch(
          getGeofencesAsync({
            queryParams: getQueryString({ status: "ACTIVE" }),
          })
        );
        store.dispatch(getGeofenceCategoriesAsync());
      }
    }
  }, [privilegesGroup, privilegesDefault]);

  let write: string[] = [];
  let read: string[] = [];
  privilegesDefault.forEach((privilegeDefault) => {
    if (
      privilegeDefault.permission === "WRITE" &&
      permissions[
        privilegeDefault.entityType.toLowerCase() as keyof UserPermissions
      ]?.write
    ) {
      write.push(t("admin.user.editViewCreate." + privilegeDefault.entityType));
    } else if (
      permissions[
        privilegeDefault.entityType.toLowerCase() as keyof UserPermissions
      ]?.read
    ) {
      read.push(t("admin.user.editViewCreate." + privilegeDefault.entityType));
    }
  });

  //#region toast notifications
  useEffect(() => {
    if (
      userSliceStatus === "idle" &&
      userSliceReasonCode === GTFleetSuccessCodes.POST
    ) {
      ToastNotification({
        toastId: "userCreateSuccess",
        status: "success",
        description: t("common.userCreateSuccess"),
      });
      const id =
        selectedUser.find(
          (user) =>
            user.username === editUser.username &&
            user.firstName === editUser.firstName
        )?.id ?? undefined;
      navigate(id ? "/admin/users/view/" + id : "/admin/users");
    } else if (userSliceStatus === "failed") {
      switch (userSliceReasonCode) {
        case GTFleetErrorCodes.USER_ALREADY_EXISTS:
          ToastNotification({
            toastId: "usernameAlreadyExists",
            status: "error",
            description:
              editUser && editUser?.username + " " + t("common.alreadyExists"),
          });
          break;
        case GTFleetErrorCodes.USER_UNAUTHORIZED:
          ToastNotification({
            toastId: "unauthorizedError",
            status: "error",
            description: t("common.unauthorizedError"),
          });
          break;
        default:
          ToastNotification({
            toastId: "networkError",
            status: "error",
            description: t("common.networkError"),
          });
          break;
      }
    }
  }, [userSliceStatus, userSliceReasonCode]);

  useEffect(() => {
    if (
      securityProfileSliceStatus === "failed" &&
      securityProfileSliceReasonCode === ""
    ) {
      ToastNotification({
        toastId: "networkError",
        status: "error",
        description: t("common.networkError"),
      });
    }
  }, [securityProfileSliceStatus, securityProfileSliceReasonCode, navigate]);

  useEffect(() => {
    if (
      privilegesSliceStatus === "failed" &&
      privilegesSliceReasonCode === ""
    ) {
      ToastNotification({
        toastId: "networkError",
        status: "error",
        description: t("common.networkError"),
      });
    }
  }, [privilegesSliceStatus, privilegesSliceReasonCode]);

  useEffect(() => {
    if (
      driversStatusSliceStatus === "failed" &&
      driversStatusSliceReasonCode === ""
    ) {
      ToastNotification({
        toastId: "networkError",
        status: "error",
        description: t("common.networkError"),
      });
    }
  }, [driversStatusSliceStatus, driversStatusSliceReasonCode]);

  useEffect(() => {
    if (
      vehiclesStatusSliceStatus === "failed" &&
      vehiclesStatusSliceReasonCode === ""
    ) {
      ToastNotification({
        toastId: "networkError",
        status: "error",
        description: t("common.networkError"),
      });
    }
  }, [vehiclesStatusSliceStatus, vehiclesStatusSliceReasonCode]);

  useEffect(() => {
    if (geofenceCategoriesSliceStatus === "failed") {
      if (geofenceCategoriesSliceReasonCode === "") {
        ToastNotification({
          toastId: "networkError",
          status: "error",
          description: t("common.networkError"),
        });
      }
    }
  }, [geofenceCategoriesSliceStatus, geofenceCategoriesSliceReasonCode]);

  useEffect(() => {
    if (geofencesSliceStatus === "failed") {
      //gestire not found
      if (geofencesSliceReasonCode === "") {
        ToastNotification({
          toastId: "networkError",
          status: "error",
          description: t("common.networkError"),
        });
      }
    }
  }, [geofencesSliceStatus, geofencesSliceReasonCode]);
  //#endregion toast notifications
  return (
    <>
      <PageFilters>
        <div className="col col-16">
          <div className="drvr-subtopbar">
            <div className="drvr-subsection-name">
              <div className="drvr-no-avatar">
                {t("admin.user.editViewCreate.createUser")}
              </div>
            </div>
            <div className="button-save-group drvr-buttons">
              <Button
                size="small"
                aspect="secondary"
                label={t("common.cancel")}
                onClick={() => navigate("/admin/users")}
              />
              <Button
                size="small"
                aspect="primary"
                disabled={
                  errorList.length !== 0 ||
                  Object.values(editUser).length < 9 ||
                  (privilegesGroup &&
                    !(
                      vehiclesCanSee.length > 0 &&
                      driversCanSee.length > 0 &&
                      geofencesCanSee.length > 0
                    ))
                }
                label={t("common.save")}
                onClick={() => {
                  const privilegesBody: Privileges[] = [];
                  Object.entries(modifiedPrivilege).forEach((privileges) => {
                    const [key, value] = privileges;
                    privilegesBody.push(
                      ...value.map((val) => {
                        return {
                          entityId: val,
                          entityType:
                            key.charAt(0).toUpperCase() + key.slice(1),
                          permission: "READ",
                        };
                      })
                    );
                    privilegesBody.push(
                      ...value.map((val) => {
                        return {
                          entityId: val,
                          entityType:
                            key.charAt(0).toUpperCase() + key.slice(1),
                          permission: "WRITE",
                        };
                      })
                    );
                  });
                  if (Object.values(editUser).length > 0) {
                    store.dispatch(
                      createUserAsync({
                        userInfo: {
                          ...editUser,
                          privileges: privilegesBody,
                        },
                        preferences: editPreferences,
                      })
                    );
                  }
                }}
              ></Button>
            </div>
          </div>
        </div>
      </PageFilters>
      <PageContent>
        <div className="ed-form-container">
          {
            //#region General
          }
          <div className="drvr-info-form">
            <Form>
              <div className="drvr-forms-title">
                {t("admin.driver.editViewCreate.general")}
              </div>
              <div className="drvr-forms-container">
                <div className="drvr-half-form">
                  <div className="drvr-forms-label">{t("common.name")} *</div>
                  <div className="drvr-forms">
                    <div className="drvr-left-form-name">
                      <TextField
                        id={t("common.name")}
                        validate="alphabetic|isNotEmpty"
                        placeholder={t("common.name")}
                        value={editUser.firstName}
                        errorList={setErrorList}
                        events={{
                          onChange: (data) =>
                            setEditUser({
                              ...editUser,
                              firstName: data.value ?? undefined,
                            }),
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="drvr-half-form">
                  <div className="drvr-right-forms-label">
                    {t("common.surname")} *
                  </div>
                  <div className="drvr-forms">
                    <div className="drvr-right-form-item">
                      <TextField
                        id={t("common.surname")}
                        validate="alphabetic|isNotEmpty"
                        placeholder={t("common.surname")}
                        value={editUser.secondName}
                        errorList={setErrorList}
                        events={{
                          onChange: (data) =>
                            setEditUser({
                              ...editUser,
                              secondName: data.value,
                            }),
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="drvr-forms-container">
                <div className="drvr-forms-label">{t("common.username")} *</div>
                <div className="drvr-forms">
                  <div className="left-form">
                    <TextField
                      id={t("common.username")}
                      validate="isNotEmpty"
                      placeholder={t("common.username")}
                      value={editUser.username}
                      errorList={setErrorList}
                      events={{
                        onChange: (data) =>
                          setEditUser({
                            ...editUser,
                            username: data.value,
                          }),
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="drvr-forms-container">
                <div className="drvr-forms-label">{t("common.email")} *</div>
                <div className="drvr-forms">
                  <div className="drvr-left-form">
                    <TextField
                      id={t("common.email")}
                      validate="email|isNotEmpty"
                      placeholder={t("common.email")}
                      value={editUser.email}
                      errorList={setErrorList}
                      events={{
                        onChange: (data) =>
                          setEditUser({
                            ...editUser,
                            email: data.value,
                          }),
                      }}
                    />
                  </div>
                </div>
              </div>
            </Form>
            <div className="drvr-forms-container">
              <div className="drvr-forms-label">{t("common.phone")}</div>
              <div className="drvr-forms">
                <div className="user-left-form-phone">
                  <div className="drvr-drpdwn-small">
                    <Dropdown
                      isClearable={false}
                      placeholder={t("common.phonePrefix")}
                      value={{
                        label: editUser.prefix,
                      }}
                      size="normal"
                      itemAttribute="label"
                      onChange={(val) => {
                        setEditUser({
                          ...editUser,
                          prefix: val[0].label,
                        });
                      }}
                      options={prefixList}
                    />
                  </div>
                </div>
                <div className="user-right-form-phone">
                  <Form>
                    <TextField
                      id={t("common.phone")}
                      validate="numeric"
                      placeholder={t("common.phone")}
                      errorList={setErrorList}
                      value={editUser.telephone}
                      events={{
                        onChange: (data) =>
                          setEditUser({
                            ...editUser,
                            telephone: data.value,
                          }),
                      }}
                      maxLength="10"
                      minLength="9"
                    />
                  </Form>
                </div>
              </div>
            </div>
          </div>
          {
            //#endregion
          }

          {
            //#region Security
          }
          <div className="drvr-info-form">
            <div className="drvr-forms-title">
              {t("admin.user.editViewCreate.security")}
            </div>
            <div className="drvr-forms-container">
              <div className="dvr-half-permission">
                <div className="drvr-forms-label">{t("common.password")} *</div>
              </div>
              <div className="drvr-half-form drvr-half-form-permission">
                <Form className="form-permission">
                  <div className="drvr-forms drvr-forms-permission">
                    <div className="drvr-left-form-name ">
                      <TextField
                        id="Password"
                        placeholder={t(
                          "account.settings.changePassword.newPassword"
                        )}
                        value={newPassword}
                        errorList={setErrorList}
                        events={{
                          onChange: (data) => {
                            setNewPassword(data.value);
                            setEditUser({
                              ...editUser,
                              password: data.value,
                            });
                          },
                        }}
                        type={viewNewPassword ? "password" : "text"}
                      >
                        {viewNewPassword ? (
                          <IconEyeSlash
                            onClick={() => setViewNewPassword(false)}
                            color={"--global-colors-ink-light"}
                            size={14}
                          />
                        ) : (
                          <IconEye
                            onClick={() => setViewNewPassword(true)}
                            color={"--global-colors-ink-light"}
                            size={14}
                          />
                        )}
                      </TextField>
                    </div>
                  </div>
                </Form>
                <Form className="form-permission">
                  <div className="drvr-forms drvr-forms-permission">
                    <div className="drvr-right-form-item ">
                      <TextField
                        id="RepeatPassowrd"
                        placeholder={t(
                          "account.settings.changePassword.confirmPassword"
                        )}
                        value={repeatPassword ?? null}
                        errorList={setErrorList}
                        events={{
                          onChange: (data) => setRepeatPassword(data.value),
                        }}
                        type={viewRepeatPassword ? "password" : "text"}
                      >
                        {viewRepeatPassword ? (
                          <IconEyeSlash
                            onClick={() => setViewRepeatPassword(false)}
                            color={"--global-colors-ink-light"}
                            size={14}
                          />
                        ) : (
                          <IconEye
                            onClick={() => setViewRepeatPassword(true)}
                            color={"--global-colors-ink-light"}
                            size={14}
                          />
                        )}
                      </TextField>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
            <div className={`constraint-container ${"invalidPassword"}`}>
              <div className="constraint-title">
                {t("customModals.verifyCode.passwordConstraintTitle")}
              </div>
              <div className="main-check">
                <div
                  className={
                    editUser.password === ""
                      ? "hide-constraint-check"
                      : "constraint-check"
                  }
                >
                  {allValidationArray.minLenght ? (
                    <Check status="success" size="xsmall" />
                  ) : (
                    <Check status="failed" size="xsmall" />
                  )}
                </div>
                <div className={displayValidator("minLenght")}>
                  {t("customModals.verifyCode.passwordConstraintLength")}
                </div>
              </div>
              <div className="main-check">
                <div
                  className={
                    editUser.password === ""
                      ? "hide-constraint-check"
                      : "constraint-check"
                  }
                >
                  {allValidationArray.lowUp ? (
                    <Check status="success" size="xsmall" />
                  ) : (
                    <Check status="failed" size="xsmall" />
                  )}
                </div>
                <div className={displayValidator("lowUp")}>
                  {t("customModals.verifyCode.passwordConstraintLowUp")}
                </div>
              </div>
              <div className="main-check">
                <div
                  className={
                    editUser.password === ""
                      ? "hide-constraint-check"
                      : "constraint-check"
                  }
                >
                  {allValidationArray.number ? (
                    <Check status="success" size="xsmall" />
                  ) : (
                    <Check status="failed" size="xsmall" />
                  )}
                </div>
                <div className={displayValidator("number")}>
                  {t("customModals.verifyCode.passwordConstraintNumber")}
                </div>
              </div>
              <div className="main-check">
                <div
                  className={
                    editUser.password === ""
                      ? "hide-constraint-check"
                      : "constraint-check"
                  }
                >
                  {allValidationArray.specChar ? (
                    <Check status="success" size="xsmall" />
                  ) : (
                    <Check status="failed" size="xsmall" />
                  )}
                </div>
                <div className={displayValidator("specChar")}>
                  {t("customModals.verifyCode.passwordConstraintChar")}
                </div>
              </div>
              <div className="main-check main-check-right">
                <div
                  className={
                    editUser.password === ""
                      ? "hide-constraint-check"
                      : "constraint-check"
                  }
                >
                  {repeatPassword !== "" && newPassword === repeatPassword ? (
                    <Check status="success" size="xsmall" />
                  ) : (
                    <Check status="failed" size="xsmall" />
                  )}
                </div>
                <div className={displayValidator("password")}>
                  {t("customModals.verifyCode.equalPasswords")}
                </div>
              </div>
            </div>
            <Form>
              <div className="drvr-forms-container">
                <Tooltip />
                <span
                  data-for="tooltip"
                  data-tip={t("common.warningActivationDate")}
                >
                  <div className="drvr-forms-label">
                    {t("admin.user.editViewCreate.activationDate")}
                  </div>
                </span>
                <div className="drvr-forms">
                  <div className="drvr-left-form-name ">
                    <SingleDatePicker
                      id="ActivationDate"
                      setErrorList={setErrorList}
                      errorList={errorList}
                      dateRange={[
                        dayjs().add(-1, "day"),
                        dayjs().add(5, "year"),
                      ]}
                      validation="isNotEmpty"
                      oldDate={
                        editUser.activationDate
                          ? String(editUser.activationDate)
                          : ""
                      }
                      setDate={(data) => {
                        setEditUser({
                          ...editUser,
                          activationDate: data,
                        });
                      }}
                      placeholder={t(
                        "admin.user.editViewCreate.activationDate"
                      )}
                      localeFormat={
                        preferencesContext.localeFormat ?? "DD / MM / YYYY"
                      }
                      language={preferencesContext.language ?? "it"}
                    />
                  </div>
                </div>
              </div>
              <div className="drvr-forms-container">
                <div className="drvr-forms-label">
                  {t("admin.user.editViewCreate.expirationDate")}
                </div>
                <div className="drvr-forms">
                  <div className="drvr-left-form-name ">
                    <SingleDatePicker
                      id="ExpirationDate"
                      setErrorList={setErrorList}
                      errorList={errorList}
                      dateRange={[
                        dayjs().add(-1, "day"),
                        dayjs().add(10, "year"),
                      ]}
                      oldDate={
                        editUser.expirationDate
                          ? String(editUser.expirationDate)
                          : ""
                      }
                      setDate={(data) => {
                        setEditUser({
                          ...editUser,
                          expirationDate: data,
                        });
                      }}
                      placeholder={viewExpirationDate()}
                      localeFormat={
                        preferencesContext.localeFormat ?? "DD / MM / YYYY"
                      }
                      language={preferencesContext.language ?? "it"}
                      notExpirationDate={(_val) => {
                        setEditUser({
                          ...editUser,
                          expirationDate: null,
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            </Form>
          </div>
          {
            //#endregion
          }

          {
            //#region Profile and privileges
          }
          <div className="drvr-info-form">
            <div className="drvr-forms-title">
              {t("admin.user.editViewCreate.profile")}
            </div>
            <div className="drvr-forms-container">
              <div className="drvr-forms-label">
                {t("admin.user.editViewCreate.role")}
              </div>
              <div className="drvr-forms">
                <div className="drvr-left-form ">
                  {securityProfileSliceStatus == "idle" ? (
                    <Dropdown
                      isClearable={false}
                      placeholder={t("admin.user.editViewCreate.role")}
                      value={{
                        name: editUser.securityProfile?.name
                          ? translateSecurityProfiles(
                              editUser.securityProfile?.name
                            )
                          : undefined,
                      }}
                      size="normal"
                      itemAttribute="name"
                      onChange={handleSecurityProfileChange}
                      options={securityProfiles.map((securityProfile) => {
                        return {
                          ...securityProfile,
                          name: translateSecurityProfiles(securityProfile.name),
                        };
                      })}
                    />
                  ) : (
                    <div className="page-counter-loader" />
                  )}
                </div>
              </div>
            </div>
            <div className="drvr-forms-container drvr-forms-function">
              <div className="drvr-forms-label">
                {t("admin.user.editViewCreate.functions")}
              </div>
              <div className="form-function">
                <div className="drvr-forms">
                  <div className="drvr-left-form drvr-user">
                    {write.length > 0 ? (
                      privilegeDefaultSliceStatus == "idle" ? (
                        <div className="drvr-onblock">
                          <div
                            className="mn-label-text__text mn-label-text__text-function"
                            key={"write"}
                          >
                            {t("admin.user.editViewCreate.read&Write")}
                          </div>
                          <div className="drvr-user-fuction drvr-user-fuction-first">
                            {write.map((privilege) => {
                              return (
                                <Tag
                                  key={"write_" + privilege}
                                  text={privilege}
                                  mode="outline"
                                  type="neutro"
                                />
                              );
                            })}
                          </div>
                        </div>
                      ) : (
                        <div className="page-counter-loader" />
                      )
                    ) : null}
                    {read.length > 0 ? (
                      privilegeDefaultSliceStatus == "idle" ? (
                        <div className="drvr-onblock">
                          <div
                            className="mn-label-text__text mn-label-text__text-function"
                            key={"read"}
                          >
                            {t("admin.user.editViewCreate.read")}
                          </div>
                          <div className="drvr-user-fuction drvr-user-fuction-second">
                            {read.map((privilege) => {
                              return (
                                <Tag
                                  key={"read_" + privilege}
                                  text={privilege}
                                  mode="outline"
                                  type="neutro"
                                />
                              );
                            })}
                          </div>
                        </div>
                      ) : (
                        <div className="page-counter-loader" />
                      )
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            {privilegesDefault.length > 0 && (
              <div className="drvr-forms-container drvr-forms-function">
                <div className="drvr-forms-label">
                  {t("admin.user.editViewCreate.managePermissions")}
                </div>
                <Form className="form-function">
                  <div className="drvr-forms">
                    <div className="drvr-left-form drvr-user">
                      <Switch
                        checked={privilegesGroup}
                        onChange={() => setPrivilegesGroup(!privilegesGroup)}
                        label=""
                      />
                    </div>
                  </div>
                </Form>
              </div>
            )}
            {privilegesGroup && (
              <div className="privileges-tabs-container">
                <Tabs>
                  {privilegesDefault.some(
                    (privilegeDefault) =>
                      privilegeDefault.entityType === "Vehicles"
                  ) && permissions.vehicles.read ? (
                    <TabSection label="Vehicles">
                      <AdminPrivilegesResourceTab
                        tabType="vehicles"
                        disabled={!permissions.vehicles.write}
                        customTitleFirstGroup={t(
                          "admin.user.editViewCreate.customTitleFirstGroup"
                        ).replace("$$", editUser.username ?? t("common.user"))}
                        customTitleSecondGroup={t(
                          "admin.user.editViewCreate.customTitleSecondGroup"
                        ).replace("$$", editUser.username ?? t("common.user"))}
                        rightGroupProp={vehiclesCannotSee}
                        leftGroupProp={vehiclesCanSee}
                        leftButtonText={t(
                          "admin.groups.editGroups.widgetButtonLabelRemove"
                        )}
                        rightButtonText={t(
                          "admin.groups.editGroups.widgetButtonLabelAdd"
                        )}
                        output={(
                          leftResources: ResourceProps[],
                          rightResources: ResourceProps[]
                        ) => {
                          // Updating setModifiedPrivilege with selected elements
                          setModifiedPrivilege((prevState) => ({
                            ...prevState,
                            vehicles: rightResources.map(
                              (resource) => resource.id
                            ),
                          }));
                          setVehiclesCanSee(rightResources);
                          setVehiclesCannotSee(leftResources);
                        }}
                      />
                    </TabSection>
                  ) : null}
                  {privilegesDefault.some(
                    (privilegeDefault) =>
                      privilegeDefault.entityType === "Drivers"
                  ) && permissions.drivers.read ? (
                    <TabSection label="Drivers">
                      <AdminPrivilegesResourceTab
                        tabType="drivers"
                        disabled={!permissions.drivers.write}
                        customTitleFirstGroup={t(
                          "admin.user.editViewCreate.customTitleFirstGroup"
                        ).replace("$$", editUser.username ?? t("common.user"))}
                        customTitleSecondGroup={t(
                          "admin.user.editViewCreate.customTitleSecondGroup"
                        ).replace("$$", editUser.username ?? t("common.user"))}
                        rightGroupProp={driversCannotSee}
                        leftGroupProp={driversCanSee}
                        leftButtonText={t(
                          "admin.groups.editGroups.widgetButtonLabelRemove"
                        )}
                        rightButtonText={t(
                          "admin.groups.editGroups.widgetButtonLabelAdd"
                        )}
                        output={(
                          leftResources: ResourceProps[],
                          rightResources: ResourceProps[]
                        ) => {
                          // Updating setModifiedPrivilege with selected elements
                          setModifiedPrivilege((prevState) => ({
                            ...prevState,
                            drivers: rightResources.map(
                              (resource) => resource.id
                            ),
                          }));
                          setDriversCanSee(rightResources);
                          setDriversCannotSee(leftResources);
                        }}
                      />
                    </TabSection>
                  ) : null}
                  {privilegesDefault.some(
                    (privilegeDefault) =>
                      privilegeDefault.entityType === "Geofences"
                  ) && permissions.geofences.read ? (
                    <TabSection label="Geofences">
                      <AdminPrivilegesResourceTab
                        tabType="geofences"
                        disabled={!permissions.geofences.write}
                        customTitleFirstGroup={t(
                          "admin.user.editViewCreate.customTitleFirstGroup"
                        ).replace("$$", editUser.username ?? t("common.user"))}
                        customTitleSecondGroup={t(
                          "admin.user.editViewCreate.customTitleSecondGroup"
                        ).replace("$$", editUser.username ?? t("common.user"))}
                        rightGroupProp={geofencesCannotSee}
                        leftGroupProp={geofencesCanSee}
                        leftButtonText={t(
                          "admin.groups.editGroups.widgetButtonLabelRemove"
                        )}
                        rightButtonText={t(
                          "admin.groups.editGroups.widgetButtonLabelAdd"
                        )}
                        output={(
                          leftResources: ResourceProps[],
                          rightResources: ResourceProps[]
                        ) => {
                          // Updating setModifiedPrivilege with selected elements
                          setModifiedPrivilege((prevState) => ({
                            ...prevState,
                            geofences: rightResources.map(
                              (resource) => resource.id
                            ),
                          }));
                          setGeofencesCanSee(rightResources);
                          setGeofencesCannotSee(leftResources);
                        }}
                      />
                    </TabSection>
                  ) : null}
                </Tabs>
              </div>
            )}
          </div>
        </div>
      </PageContent>
    </>
  );
};
export default CreateUser;
