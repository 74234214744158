import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { IconArrowToLeft } from "../Icon/Line/ArrowToLeft";
import { IconArrowToRight } from "../Icon/Line/ArrowToRight";
import { Logo } from "../Logo/Logo";
import { PartnerLogo } from "../Partner/Partner";
import { PoweredBy } from "../PoweredBy/PoweredBy";
import "./Menu.css";

export const Menu = ({
  expanded,
  expandedUpdated,
  isAdmin,
  children,
  partnerLogoPositive,
  partnerLogoNegative,
  ...props
}) => {
  const [isExpanded, setIsExpanded] = useState(expanded);
  const classes = ["mn-menu"];
  const navigate = useNavigate();
  const { t } = useTranslation();

  if (isExpanded) {
    classes.push("mn-menu--expanded");
  }

  if (isAdmin) {
    classes.push("mn-menu--admin");
  }

  useEffect(() => {
    setIsExpanded(expanded);
  }, [expanded]);

  const toggleMenu = () => {
    setIsExpanded(!isExpanded);
    if (expandedUpdated) {
      expandedUpdated(!isExpanded);
    }
  };

  return (
    <div className={classes.join(" ")} {...props}>
      <div
        className="mn-menu__logo"
        onClick={() => {
          navigate("/dashboard/fleet-control");
        }}
      >
        <Logo
          mode={isAdmin ? "colors" : "white"}
          type={localStorage.getItem("logoType")}
          width={isExpanded ? 123 : 29}
          isCompressed={!isExpanded}
        />
      </div>
      {isExpanded && (
        <div className="mn-menu__logo-powered">
          <PoweredBy isNegative={isAdmin} />
        </div>
      )}
      <ul className="mn-menu__nav-list">{children}</ul>
      <div>
        {isExpanded && !!partnerLogoPositive && !!partnerLogoNegative && (
          <div className="mn-menu__partner-logo-container">
            <div className="mn-menu__partner-logo">
              <PartnerLogo isNegative={isAdmin} />
            </div>
            <div className="mn-menu__theguardian-logo-container">
              {
                <img
                  src={isAdmin ? partnerLogoPositive : partnerLogoNegative}
                />
              }
            </div>
          </div>
        )}
        <div
          className={isAdmin ? "mn-menu__collapse-admin" : "mn-menu__collapse"}
        >
          <div onClick={toggleMenu}>
            {isExpanded ? (
              <>
                <IconArrowToLeft
                  size={14}
                  color={(function () {
                    if (isAdmin) {
                      return "--global-colors-ink-dark";
                    } else {
                      return "--global-colors-ui-white";
                    }
                  })()}
                />
                <span>{t("common.collapse")}</span>
              </>
            ) : (
              <IconArrowToRight
                size={14}
                color={(function () {
                  if (isAdmin) {
                    return "--global-colors-ink-dark";
                  } else {
                    return "--global-colors-ui-white";
                  }
                })()}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

Menu.propTypes = {
  /**
   * Status of the menu.
   */
  expanded: PropTypes.bool,
  /**
   * If true show the admin menu, o/w user menu.
   */
  isAdmin: PropTypes.bool,

  partnerLogoPositive: PropTypes.string,
  partnerLogoNegative: PropTypes.string,
};

Menu.defaultProps = {
  expanded: true,
  isAdmin: false,
};
