import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { DataLabel } from "../../../ui/DataLabel/DataLabel";
import { IconGasStation } from "../../../ui/Icon/Line/GasStation";
import { IconKilometers } from "../../../ui/Icon/Line/Kilometers";
import {
  formatData,
  kmToMiles,
  ltToGal,
  mtToKm,
  numberAnnotation,
} from "../../../utils/Utils";
import { Preferences } from "../../users/preference/preferencesSlice";
import UserContext from "../../users/userContext";

interface RefuellingReportDetailsProps {
  totalTraveled?: number;
  refueled?: number;
  refuels?: number;
}

export const RefuellingReportDetails: React.FC<RefuellingReportDetailsProps> =
  ({ totalTraveled, refueled, refuels }) => {
    const { t } = useTranslation();
    const [preferencesContext]: [Preferences] = useContext(UserContext);

    return (
      <>
        {totalTraveled != null && (
          <div className="details-refuelling-report-item" key={1}>
            <DataLabel
              dataText={`${formatData(
                mtToKm(totalTraveled),
                kmToMiles,
                preferencesContext,
                0
              )} ${preferencesContext.isMetric ? "km" : "mil"}`}
              icon={<IconKilometers size={14} />}
              label={t("report.details.travelDistance")}
            />
          </div>
        )}
        {refueled != null && (
          <div className="details-refuelling-report-item" key={2}>
            <DataLabel
              dataText={
                preferencesContext.isMetric
                  ? numberAnnotation(refueled) + " lt"
                  : numberAnnotation(ltToGal(refueled)) + " gal"
              }
              icon={<IconGasStation size={14} />}
              label={t("report.details.refueled")}
            />
          </div>
        )}

        {refuels != null && (
          <div className="details-refuelling-report-item" key={3}>
            <DataLabel
              dataText={refuels}
              icon={<IconGasStation size={14} />}
              label={t("report.details.refuels")}
            />
          </div>
        )}
      </>
    );
  };
