import "./GridContainer.css";

interface GridContainerProps {
  children: object;
}

function GridContainer({ children }: GridContainerProps) {
  return <div className="grid-container container-fluid">{children}</div>;
}

export default GridContainer;
