import {
  PayloadAction,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import { GTFleetSuccessCodes } from "../../../config/GTFleetSuccessCodes";
import { getReportsUtilizationAsync } from "./ReportUtilizationSlice";

export type ReportUtilizationSummary = {
  id: number;
  startDate: Date;
  endDate: Date;
  totalWorkingTime: string;
};

const reportsUtilizationSummaryAdapter =
  createEntityAdapter<ReportUtilizationSummary>({
    selectId: (reportsUtilizationSummary) => reportsUtilizationSummary.id,
  });

export const reportsUtilizationSummarySlice = createSlice({
  name: "reportsUtilizationSummary",
  initialState: reportsUtilizationSummaryAdapter.getInitialState({
    status: "idle",
    reasonCode: "",
  }),

  reducers: {
    reportsUtilizationSummaryEmptyState: (state) => {
      reportsUtilizationSummaryAdapter.setAll(state, []);
      state.reasonCode = "";
      state.status = "idle";
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(
        getReportsUtilizationAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          reportsUtilizationSummaryAdapter.setAll(
            state,
            action.payload.summary ?? {}
          );
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.GET;
        }
      )
      .addCase(getReportsUtilizationAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(getReportsUtilizationAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = "";
      });
  },
});

export const reportsUtilizationSummarySelectors =
  reportsUtilizationSummaryAdapter.getSelectors<RootState>(
    (state) => state.reportsUtilizationSummary
  );

export const selectReportsUtilizationSummarySliceStatus = (state: any) =>
  state.reportsUtilizationSummary.status;
export const selectReportsUtilizationSummarySliceReasonCode = (state: any) =>
  state.reportsUtilizationSummary.reasonCode;
export const { reportsUtilizationSummaryEmptyState } =
  reportsUtilizationSummarySlice.actions;

export default reportsUtilizationSummarySlice.reducer;
