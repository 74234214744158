import HttpClient from "../../../utils/HttpClient";

export default class SecurityProfilesRepository extends HttpClient {
  constructor() {
    super(true);
  }

  getSecurityProfiles() {
    return this.instance.get("securityprofiles");
  }
}
