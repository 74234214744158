import React from "react";

import { IconLocator } from "../Icon/Solid/Locator";

import "./CenterLocation.css";

export const CenterLocation = ({ className, onClick, ...props }) => {
  return (
    <div
      className={["map-button", className].join(" ")}
      onClick={onClick}
      {...props}
    >
      <IconLocator size={14} />
    </div>
  );
};
