import { useContext } from "react";
import { useTranslation } from "react-i18next";
import UserContext from "../../../features/users/userContext";
import { DataLabel } from "../../../ui/DataLabel/DataLabel";
import { IconAverageSpeed } from "../../../ui/Icon/Line/AverageSpeed";
import { IconClock } from "../../../ui/Icon/Line/Clock";
import { IconGasStation } from "../../../ui/Icon/Line/GasStation";
import { IconKilometers } from "../../../ui/Icon/Line/Kilometers";
import { IconMap } from "../../../ui/Icon/Line/Map";
import { IconParkingPoint } from "../../../ui/Icon/Line/ParkingPoint";
import { IconSpeed } from "../../../ui/Icon/Line/Speed";
import { formatTime } from "../../../utils/DateAndTimeUtils";
import {
  kmPerHToMilesPerH,
  kmToMiles,
  ltToGal,
  mtToKm,
  numberAnnotation,
} from "../../../utils/Utils";
import { Preferences } from "../../users/preference/preferencesSlice";

interface VehicleTravelReportDetailsProps {
  kmTot?: number;
  consTot?: number;
  speedMax?: number;
  speedAvg?: number;
  driveTime?: number;
  idleTime?: number;
  travels?: number;
}

export const VehicleTravelReportDetails: React.FC<VehicleTravelReportDetailsProps> =
  ({ kmTot, consTot, speedMax, speedAvg, driveTime, idleTime, travels }) => {
    const { t } = useTranslation();
    const [preferencesContext]: [Preferences] = useContext(UserContext);

    return (
      <>
        {travels != null && (
          <div className="details-report-item" key={0}>
            <DataLabel
              dataText={travels}
              icon={<IconMap size={14} />}
              label={t("report.details.travels")}
            />
          </div>
        )}
        {kmTot != null && (
          <div className="details-report-item" key={1}>
            <DataLabel
              dataText={
                preferencesContext.isMetric
                  ? numberAnnotation(mtToKm(kmTot)) + " km"
                  : numberAnnotation(kmToMiles(mtToKm(kmTot))) + " mil"
              }
              icon={<IconKilometers size={14} />}
              label={t("report.details.kmTot")}
            />
          </div>
        )}
        {consTot != null && (
          <div className="details-report-item" key={2}>
            <DataLabel
              dataText={
                preferencesContext.isMetric
                  ? numberAnnotation(consTot) + " lt"
                  : numberAnnotation(ltToGal(consTot)) + " gal"
              }
              icon={<IconGasStation size={14} />}
              label={t("report.details.consTot")}
            />
          </div>
        )}
        {speedMax != null && (
          <div className="details-report-item" key={3}>
            <DataLabel
              dataText={
                preferencesContext.isMetric
                  ? numberAnnotation(speedMax) + " km/h"
                  : numberAnnotation(kmPerHToMilesPerH(speedMax)) + " mph"
              }
              icon={<IconSpeed size={14} />}
              label={t("report.details.speedMax")}
            />
          </div>
        )}
        {speedAvg != null && (
          <div className="details-report-item" key={4}>
            <DataLabel
              dataText={
                preferencesContext.isMetric
                  ? numberAnnotation(speedAvg) + " km/h"
                  : numberAnnotation(kmPerHToMilesPerH(speedAvg)) + " mph"
              }
              icon={<IconAverageSpeed size={14} />}
              label={t("report.details.speedAvg")}
            />
          </div>
        )}
        {driveTime != null && (
          <div className="details-report-item" key={5}>
            <DataLabel
              dataText={formatTime(
                driveTime * 1000,
                preferencesContext.language
              )}
              icon={<IconClock size={14} />}
              label={t("report.details.driveTime")}
            />
          </div>
        )}
        {idleTime != null && (
          <div className="details-report-item" key={6}>
            <DataLabel
              dataText={formatTime(
                idleTime * 1000,
                preferencesContext.language
              )}
              icon={<IconParkingPoint size={14} />}
              label={t("report.details.idleTime")}
            />
          </div>
        )}
      </>
    );
  };
