import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import addressBookReducer from "../features/address/addressBookSlice";
import assetReducer from "../features/asset/assetsSlice";
import commandRequestReducer from "../features/commands/commandSlice";
import contractReducer from "../features/contract/contractsSlice";
import deviceReducer from "../features/device/devicesSlice";
import driverReducer from "../features/driver/driversSlice";
import driverStatusReducer from "../features/driver/driversStatusSlice";
import eventsReducer from "../features/event/eventsSlice";
import eventsIOReducer from "../features/eventIO/eventsIOSlice";
import fleetFuelCostsReducer from "../features/fleet/fleetFuelCostsSlice";
import fleetFuelConsumptionsReducer from "../features/fleet/fleetFuelsConsumptionsSlice";
import fleetMaintenanceCostsReducer from "../features/fleet/fleetMaintenanceCostsSlice";
import fleetReducer from "../features/fleet/fleetsSlice";
import fleetViewReducer from "../features/fleet/fleetViewsSlice";
import geofenceReducer from "../features/geofence/geofenceSlice";
import geofenceCategoryReducer from "../features/geofenceCategory/geofenceCategoriesSlice";
import logEventsReducer from "../features/log/logSlice";
import deadlinesReducer from "../features/maintenance-deadline/deadlinesSlice";
import chatAliasReducer from "../features/messages/chatAliasSlice";
import chatReducer from "../features/messages/chatSlice";
import messagesReducer from "../features/messages/messagesSlice";
import notificationCategoriesReducer from "../features/notification/notificationCategorySlice";
import previewNotificationEventsReducer from "../features/notification/notificationPreviewSlice";
import notificationEventsReducer from "../features/notification/notificationSlice";
import notificationRuleAggregationsReducer from "../features/notification/rule/notificationRuleAggregationSlice";
import notificationRuleDestinationsReducer from "../features/notification/rule/notificationRuleDestinationSlice";
import notificationRulesReducer from "../features/notification/rule/notificationRuleSlice";
import publicRoutePlanningReducer from "../features/publicTransport/planning/publicRoutePlanningSlice";
import publicRoutePlanningSummaryReducer from "../features/publicTransport/planning/publicRoutePlanningSummarySlice";
import publicRoutePresetsReducer from "../features/publicTransport/planning/publicRoutePresetSlice";
import publicRoutesReducer from "../features/publicTransport/route/publicRoutesSlice";
import publicRouteViewReducer from "../features/publicTransport/route/publicRouteViewSlice";
import publicRouteShiftReducer from "../features/publicTransport/shift/publicRouteShiftSlice";
import publicRouteStopReducer from "../features/publicTransport/stop/publicRouteStopSlice";
import driverTravelsMacroSummaryReducer from "../features/report/driver/driverTravelsMacroSummarySlice";
import driverTravelsSummaryReducer from "../features/report/driver/driverTravelsSummarySlice";
import reportsGeofenceSummaryReducer from "../features/report/geofence/GeofenceReportsSummarySlice";
import geofencesReportsReducer from "../features/report/geofence/GeofencesReportsSlice";
import gpsDataReportsReducer from "../features/report/gpsData/gpsDataReportSlice";
import gpsDataReportsSummaryReducer from "../features/report/gpsData/gpsDataReportsSummarySlice";
import refuelChartDataReportReducer from "../features/report/refuelling/refuellingChartDataReportSlice";
import refuellingReportReducer from "../features/report/refuelling/refuellingReportSlice";
import refuellingReportSummaryReducer from "../features/report/refuelling/refuellingReportSummarySlice";
import reportsMetadataReducer from "../features/report/reportsMetadataSlice";
import speedDataReportsReducer from "../features/report/speed/speedReportSlice";
import reportUtilizationReducer from "../features/report/utilization/ReportUtilizationSlice";
import reportsUtilizationSummaryReducer from "../features/report/utilization/ReportUtilizationSummarySlice";
import reportsIOReducer from "../features/report/vehicle/reportsIOSlice";
import reportsIOSummaryReducer from "../features/report/vehicle/reportsIOSummarySlice";
import vehicleStopSummaryReducer from "../features/report/vehicle/vehicleStopsSummarySlice";
import vehicleTravelsMacroSummaryReducer from "../features/report/vehicle/vehicleTravelsMacroSummarySlice";
import vehicleTravelSummaryReducer from "../features/report/vehicle/vehicleTravelsSummarySlice";
import vehicleUtilizationReducer from "../features/report/vehicle/vehicleUtilizationSlice";
import routeEventReducer from "../features/route/routeEventsSlice";
import routesHistoryReducer from "../features/route/routesHistorySlice";
import routesHistoryViewReducer from "../features/route/routesHistoryViewSlice";
import routesReducer from "../features/route/routesSlice";
import schedulesReducer from "../features/schedules/schedulesSlice";
import tachographFileReducer from "../features/tachograph/tachographFilesSlice";
import tachographFileStatusReducer from "../features/tachograph/tachographFilesStatusSlice";
import tachographFileStatusSummaryReducer from "../features/tachograph/tachographFilesStatusSummarySlice";
import partnerReducer from "../features/tenants/partnerSlice";
import tenantReducer from "../features/tenants/tenantsSlice";
import preferencesReducer from "../features/users/preference/preferencesSlice";
import presetReducer from "../features/users/preset/presetsSlice";
import privilegesDefaultReducer from "../features/users/privilege/privilegesDefaultSlice";
import privilegesReducer from "../features/users/privilege/privilegesSlice";
import securityProfilesReducer from "../features/users/securityProfile/securityProfilesSlice";
import usersInfoReducer from "../features/users/usersInfoSlice";
import vehicleFuelCostsReducer from "../features/vehicle/vehiclesFuelCostsSlice";
import vehicleFuelsConsumptionReducer from "../features/vehicle/vehiclesFuelsConsumptionSlice";
import vehicleMaintenanceCostsReducer from "../features/vehicle/vehiclesMaintenaceCostsSlice";
import vehicleReducer from "../features/vehicle/vehiclesSlice";
import vehicleStatusAmountReducer from "../features/vehicle/vehiclesStatusAmountSlice";
import vehicleStatusReducer from "../features/vehicle/vehiclesStatusSlice";
import vehicleStatusViewReducer from "../features/vehicle/vehiclesStatusViewSlice";
import vehicleViewReducer from "../features/vehicle/vehiclesViewSlice";

export const store = configureStore({
  reducer: {
    drivers: driverReducer,
    vehicles: vehicleReducer,
    chats: chatReducer,
    chatsAliases: chatAliasReducer,
    messages: messagesReducer,
    vehiclesView: vehicleViewReducer,
    vehiclesStatusView: vehicleStatusViewReducer,
    vehiclesFuelCosts: vehicleFuelCostsReducer,
    fleetFuelCosts: fleetFuelCostsReducer,
    vehiclesFuelsConsumption: vehicleFuelsConsumptionReducer,
    fleetFuelsConsumption: fleetFuelConsumptionsReducer,
    fleetMaintenanceCosts: fleetMaintenanceCostsReducer,
    vehiclesMaintenanceCosts: vehicleMaintenanceCostsReducer,
    devices: deviceReducer,
    contracts: contractReducer,
    tenants: tenantReducer,
    fleetViews: fleetViewReducer,
    fleets: fleetReducer,
    notificationRuleAggregations: notificationRuleAggregationsReducer,
    notificationRules: notificationRulesReducer,
    notificationRuleDestinations: notificationRuleDestinationsReducer,
    presets: presetReducer,
    vehiclesStatus: vehicleStatusReducer,
    geofences: geofenceReducer,
    geofenceCategories: geofenceCategoryReducer,
    driversStatus: driverStatusReducer,
    preferences: preferencesReducer,
    usersInfo: usersInfoReducer,
    routesHistory: routesHistoryReducer,
    routesHistoryView: routesHistoryViewReducer,
    reportsMetadata: reportsMetadataReducer,
    schedules: schedulesReducer,
    vehicleTravelsSummary: vehicleTravelSummaryReducer,
    vehicleStopsSummary: vehicleStopSummaryReducer,
    vehicleUtilization: vehicleUtilizationReducer,
    vehicleTravelsMacroSummary: vehicleTravelsMacroSummaryReducer,
    driverTravelsSummary: driverTravelsSummaryReducer,
    driverTravelsMacroSummary: driverTravelsMacroSummaryReducer,
    geofencesReports: geofencesReportsReducer,
    reportsIOSummary: reportsIOSummaryReducer,
    reportsIO: reportsIOReducer,
    gpsDataReports: gpsDataReportsReducer,
    speedDataReports: speedDataReportsReducer,
    gpsDataReportsSummary: gpsDataReportsSummaryReducer,
    deadlines: deadlinesReducer,
    reportsGeofenceSummary: reportsGeofenceSummaryReducer,
    securityProfiles: securityProfilesReducer,
    logEvents: logEventsReducer,
    notificationsEvent: notificationEventsReducer,
    previewNotificationEvents: previewNotificationEventsReducer,
    notificationCategory: notificationCategoriesReducer,
    privilegesDefault: privilegesDefaultReducer,
    privileges: privilegesReducer,
    events: eventsReducer,
    commandRequests: commandRequestReducer,
    eventsIO: eventsIOReducer,
    addressBook: addressBookReducer,
    vehicleStatusAmount: vehicleStatusAmountReducer,
    tachographFilesStatusSummary: tachographFileStatusSummaryReducer,
    tachographFilesStatus: tachographFileStatusReducer,
    tachographFiles: tachographFileReducer,
    refuelReport: refuellingReportReducer,
    refuellingReportSummary: refuellingReportSummaryReducer,
    refuelChartDataReport: refuelChartDataReportReducer,
    assets: assetReducer,
    routes: routesReducer,
    routeEvents: routeEventReducer,
    reportsUtilization: reportUtilizationReducer,
    reportsUtilizationSummary: reportsUtilizationSummaryReducer,
    partners: partnerReducer,
    publicRoutePlannings: publicRoutePlanningReducer,
    publicRouteShifts: publicRouteShiftReducer,
    publicRoutePlanningSummaries: publicRoutePlanningSummaryReducer,
    publicRoutesListView: publicRouteViewReducer,
    publicRoutes: publicRoutesReducer,
    publicRoutePresets: publicRoutePresetsReducer,
    publicRouteStops: publicRouteStopReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
