import i18next from "i18next";
import _ from "lodash";
import React, { useEffect } from "react";
import { Driver } from "../../features/driver/driversSlice";
import { IconArrowDown } from "../Icon/Line/ArrowDown";
import { ChildDropdown, Option } from "./ChildDropdown";
import "./DriversDropdown.css";

interface DriversDropdownProps {
  options: Option[];
  valueSelected?: Driver[];
  getValueSelected?: (e: any) => any;
  driversLimit?: number;
  loading: boolean;
}

export const DriversDropdown: React.FC<DriversDropdownProps> = ({
  options,
  getValueSelected,
  valueSelected,
  driversLimit,
  loading,
}: DriversDropdownProps) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [isActive, setIsActive] = React.useState(false);
  const [value, setValue] = React.useState<Driver[]>([]);
  const parentRef = React.useRef<any>(null);
  const wrapperRef = React.useRef<any>(null);

  const optionChildDropdown = () => {
    if (options.length > 0) {
      const option = {
        label: i18next.t("common.selectAll"),
        hasCheckbox: true,
        hasDropdown: false,
        hasCount: true,
        drivers: [],
      } as Option;
      const selectAll: Option[] = [];
      selectAll.push(option);
      options.forEach((x) => selectAll.push(x));

      return selectAll;
    }
    return [];
  };

  useEffect(() => {
    if (!_.isEqual(value, valueSelected)) {
      valueSelected !== undefined && setValue(valueSelected);
    }
  }, [valueSelected]);

  useEffect(() => {
    if (getValueSelected != undefined) {
      getValueSelected(value ?? []);
    }
  }, [value]);

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (
        wrapperRef.current &&
        !wrapperRef.current?.contains(event.target) &&
        !parentRef.current?.contains(event.target)
      ) {
        setIsOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  function calculateWidth() {
    let text = displayValue();
    if (text === "") {
      return "180px";
    } else if (value.length === 1) {
      return text.length + 6 + "ch";
    } else {
      return "220px";
    }
  }

  function displayValue() {
    let driversCount = value.length;
    if (driversCount === 0) {
      return "";
    } else if (driversCount === 1) {
      return `${value[0].firstName} ${value[0].lastName}`;
    } else {
      return `${driversCount} ${i18next.t("common.drivers")}`;
    }
  }

  function getClassName() {
    if (isOpen && isActive) {
      return "drivers-dropdown-btn active";
    } else if (value.length !== 0) {
      return "drivers-dropdown-btn selected";
    }
    return "drivers-dropdown-btn";
  }

  return (
    <React.Fragment>
      <div
        ref={parentRef}
        style={{ width: calculateWidth() }}
        data-testid="dropdown-btn"
        className={getClassName()}
        onClick={() => {
          setIsOpen(!isOpen);
          setIsActive(true);
        }}
      >
        <span className="drivers-dropdown-span">
          <input
            type="text"
            readOnly
            placeholder={i18next.t("common.selectAllDrivers")}
            value={displayValue()}
          />
        </span>
        <div
          data-testid="dropdown-arrow"
          className={isOpen ? "dropdown-arrow open" : " dropdown-arrow"}
        >
          <IconArrowDown size={12} color="--global-colors-ink-light" />
        </div>
      </div>
      <div ref={wrapperRef}>
        <ChildDropdown
          loading={loading}
          visible={isOpen}
          hasSearch
          options={optionChildDropdown()}
          value={value}
          setValue={setValue}
          type={"drivers"}
          onlyCheckbox={true}
          elementsLimit={driversLimit}
        />
      </div>
    </React.Fragment>
  );
};
