import { schema } from "normalizr";
import { vehicleDetailsSchema } from "../../vehicle/vehicleNormalization";

export const reportGeofenceData = new schema.Entity("reportGeofenceData", {
  vehicle: vehicleDetailsSchema,
});

export const reportGeofenceDataArray = new schema.Array(reportGeofenceData);

export const reportGeofenceSummary = new schema.Entity(
  "reportsGeofenceSummary"
);

export const reportGeofencesAndSummary = new schema.Entity(
  "reportGeofencesAndSummaries",
  {
    data: reportGeofenceDataArray,
    summary: reportGeofenceSummary,
  }
);
