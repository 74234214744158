import dayjs from "dayjs";
import { Fragment, useContext, useState } from "react";
import { DatePickerContext } from "./DatePickerContext";
import DatePickerHeader from "./utils/DatePickerHeader";
import { formatPresetDate } from "./utils/DatePickerPresetFormatter";
import dayStyles from "./utils/styles";

export const DatePickerCalendar = ({ calendar, isSingleDate, value }) => {
  const picker = useContext(DatePickerContext);

  var weekday = require("dayjs/plugin/weekday");

  dayjs.extend(weekday);

  const weekDays = [0, 1, 2, 3, 4, 5, 6].map((dow) =>
    dayjs().locale(picker.language).weekday(dow).format("ddd")
  );

  const [hoveredDay, setHoveredDay] = useState(null);

  const selectWeekByDay = (day) => {
    const startOfWeek = day.startOf("week");

    const endOfWeek = day.endOf("week");

    picker.handleOnChange(startOfWeek, isSingleDate);

    picker.handleOnChange(endOfWeek, isSingleDate);

    picker.setStartDate(startOfWeek);

    picker.setEndDate(endOfWeek);

    picker.setPreset(formatPresetDate(startOfWeek, endOfWeek));
  };

  return (
    <div>
      <DatePickerHeader value={value} />

      <div className="body">
        <div className="date-picker__day-names">
          {weekDays.map((weekDay) => (
            <Fragment key={weekDay}>
              <div className="week">{weekDay}</div>
            </Fragment>
          ))}
        </div>

        {calendar.map((week, i) => (
          <div key={i}>
            {week.map((day, j) => {
              const isHoveredWeek =
                hoveredDay &&
                day.isBetween(
                  hoveredDay.startOf("week"),

                  hoveredDay.endOf("week"),

                  null,

                  "[]"
                );

              return (
                <div
                  className={`date-picker__day ${dayStyles(
                    day,

                    value,

                    picker.startDate,

                    picker.endDate,

                    picker.secondStartDate,

                    picker.secondEndDate,

                    isSingleDate,

                    picker?.dateRange,

                    picker.hasDatePresets
                  )} ${
                    isHoveredWeek && picker.hasDatePresets ? "hovered-week" : ""
                  }`}
                  key={j}
                  onClick={() => {
                    if (value.month() === day.month()) {
                      if (picker.dateRange) {
                        if (
                          !day.isAfter(picker?.dateRange[1]) &&
                          day.isAfter(picker?.dateRange[0])
                        )
                          picker.handleOnChange(day, isSingleDate);
                      } else picker.handleOnChange(day, isSingleDate);
                    }

                    if (picker.hasDatePresets) {
                      selectWeekByDay(day);
                    }
                  }}
                  onMouseEnter={() => setHoveredDay(day)}
                  onMouseLeave={() => setHoveredDay(null)}
                >
                  <div>{day.format("D")}</div>
                </div>
              );
            })}
          </div>
        ))}
      </div>
    </div>
  );
};
