import React from "react";
import propTypes from "prop-types";
import { IconLoader } from "../Icon/Line/Loader";
import "./mapMarker.css";

export const MapMarkerCustomIcon = ({
  status,
  id,
  iconSelected,
  backgroundColor,
  isLoading,
  ...props
}) => {
  let statusColor;
  switch (status) {
    case undefined:
      statusColor = backgroundColor;
      break;
    case "MOVING":
      statusColor = "var(--global-colors-feedback-success)";
      break;
    case "PARKING":
      statusColor = "var(--global-colors-feedback-danger)";
      break;
    case "OFFLINE":
      statusColor = "var(--global-colors-ink-light)";
      break;
    case "UNKNOWN":
      statusColor = "var(--global-colors-ink-lighter)";
      break;
    case "STOP":
      statusColor = "var(--global-colors-ui-secondary)";
      break;
    default:
      statusColor = "var(--global-colors-ui-primary)";
      break;
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="38"
      height="42"
      {...props}
    >
      <defs>
        <filter
          id="MapMarkerCustomIconA"
          width="123.1%"
          height="172.7%"
          x="-11.5%"
          y="-18.2%"
          filterUnits="objectBoundingBox"
        >
          <feMorphology
            in="SourceAlpha"
            radius="0.5"
            result="shadowSpreadOuter1"
          ></feMorphology>
          <feOffset
            dy="3"
            in="shadowSpreadOuter1"
            result="shadowOffsetOuter1"
          ></feOffset>
          <feGaussianBlur
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
            stdDeviation="1"
          ></feGaussianBlur>
          <feComposite
            in="shadowBlurOuter1"
            in2="SourceAlpha"
            operator="out"
            result="shadowBlurOuter1"
          ></feComposite>
          <feColorMatrix
            in="shadowBlurOuter1"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
          ></feColorMatrix>
        </filter>
        <filter
          id="MapMarkerCustomIconC"
          width="116.7%"
          height="117.2%"
          x="-8.3%"
          y="-4.9%"
          filterUnits="objectBoundingBox"
        >
          <feMorphology
            in="SourceAlpha"
            radius="0.5"
            result="shadowSpreadOuter1"
          ></feMorphology>
          <feOffset
            dy="3"
            in="shadowSpreadOuter1"
            result="shadowOffsetOuter1"
          ></feOffset>
          <feGaussianBlur
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
            stdDeviation="1"
          ></feGaussianBlur>
          <feComposite
            in="shadowBlurOuter1"
            in2="SourceAlpha"
            operator="out"
            result="shadowBlurOuter1"
          ></feComposite>
          <feColorMatrix
            in="shadowBlurOuter1"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
          ></feColorMatrix>
        </filter>
        <filter
          id="MapMarkerCustomIconF"
          width="103.1%"
          height="103.1%"
          x="-1.6%"
          y="-1.6%"
          filterUnits="objectBoundingBox"
        >
          <feMorphology
            in="SourceAlpha"
            radius="1"
            result="shadowSpreadInner1"
          ></feMorphology>
          <feOffset
            in="shadowSpreadInner1"
            result="shadowOffsetInner1"
          ></feOffset>
          <feComposite
            in="shadowOffsetInner1"
            in2="SourceAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
            result="shadowInnerInner1"
          ></feComposite>
          <feColorMatrix
            in="shadowInnerInner1"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
          ></feColorMatrix>
        </filter>
        <path
          id="MapMarkerCustomIconD"
          d="M18 41s2.71-5.154 3.994-5.445C30.013 33.74 36 26.57 36 18c0-9.941-8.059-18-18-18S0 8.059 0 18c0 8.564 5.981 15.732 13.995 17.553C15.283 35.845 18 41 18 41z"
        ></path>
        <path
          id="MapMarkerCustomIconG"
          d="M9.487 7.837v4.061c-1.03.845-2.462 1.435-4.177 1.435-1.117 0-2.146-.287-3-.805l2.842-1.855c.098.011.19.017.294.017.544 0 .839-.083 1.084-.22v-.696l2.957-1.937zM16 3.587v2.528h-2.843v7.009h-3.23V7.555L16 3.587zM3.191 8.444c.017 1.015.556 1.788 1.394 2.097l-2.652 1.733A4.735 4.735 0 01.37 10.287zm7.047-5.11l-4.253 2.78h-.637v-.01l-.033.01c-1.084 0-1.9.74-2.086 1.8L.223 9.884A5.236 5.236 0 010 8.366V8.34c0-2.864 2.227-5 5.217-5h.13v-.006h4.891zM9.1 7.587l-2.94 1.92H4.906v-1.92H9.1zm6.513-4.255l-5.68 3.72v-.938h-3.17l4.254-2.782h4.596z"
        ></path>
        <circle id="MapMarkerCustomIconE" cx="18" cy="18" r="16"></circle>
        <rect
          id="MapMarkerCustomIconB"
          width="26"
          height="11"
          x="0"
          y="1"
          rx="2"
        ></rect>
      </defs>
      <g fill="none" fillRule="evenodd">
        {id !== undefined ? (
          <>
            <g transform="translate(6.5 43)">
              <use
                fill="#000"
                filter="url(#MapMarkerCustomIconA)"
                xlinkHref="#MapMarkerCustomIconB"
              ></use>
              <rect
                width="24"
                height="9"
                x="1"
                y="2"
                fill="#FFF"
                stroke="#FFF"
                strokeLinejoin="square"
                strokeWidth="2"
                rx="2"
              ></rect>
            </g>
            <text
              fill="#031832"
              fontFamily="Helvetica"
              fontSize="10"
              transform="translate(6.5 43)"
            >
              <tspan x="4.104" y="10">
                {id}
              </tspan>
            </text>
          </>
        ) : null}
        <g transform="translate(1)">
          <use
            fill="#000"
            filter="url(#MapMarkerCustomIconC)"
            xlinkHref="#MapMarkerCustomIconD"
          ></use>
          <path
            fill="#FFF"
            stroke="#FFF"
            strokeLinejoin="square"
            strokeWidth="2"
            d="M18 1c4.694 0 8.944 1.903 12.02 4.98A16.947 16.947 0 0135 18a16.93 16.93 0 01-3.703 10.594 17.001 17.001 0 01-9.524 5.986c-.956.217-2.712 2.8-3.774 4.491-1.064-1.692-2.825-4.276-3.783-4.493A17.002 17.002 0 014.7 28.589 16.927 16.927 0 011 18c0-4.694 1.903-8.944 4.98-12.02A16.947 16.947 0 0118 1z"
          ></path>
        </g>

        <g transform="translate(1)">
          <use fill={statusColor} xlinkHref="#MapMarkerCustomIconE"></use>
          <use
            fill="#000"
            filter="url(#MapMarkerCustomIconF)"
            xlinkHref="#MapMarkerCustomIconE"
          ></use>
        </g>
        {isLoading ? (
          <g>
            <animateTransform
              attributeName="transform"
              type="rotate"
              from="0 19 18"
              to="360 19 18"
              dur="2s"
              repeatCount="indefinite"
            />
            <foreignObject
              transform="translate(10.5, 9)"
              width="18"
              height="18"
            >
              {React.Children.map(
                <IconLoader size={17} color="--global-colors-ui-white" />,
                (child) => {
                  return React.cloneElement(
                    child,
                    { color: "--global-colors-ui-white" },
                    null
                  );
                }
              )}
            </foreignObject>
          </g>
        ) : (
          <foreignObject transform="translate(10.5, 9)" width="18" height="18">
            {React.Children.map(iconSelected, (child) => {
              return React.cloneElement(
                child,
                { color: "--global-colors-ui-white" },
                null
              );
            })}
          </foreignObject>
        )}
      </g>
    </svg>
  );
};

MapMarkerCustomIcon.propTypes = {
  /**
   * The status of the marker.
   */
  status: propTypes.oneOf(["UNKNOWN", "OFFLINE", "MOVING", "PARKING", "STOP"]),
  /**
   * The id to show in the label.
   */
  id: propTypes.string,
  /**
   * The background color when status is not defined.
   */
  backgroundColor: propTypes.string,
  isLoading: propTypes.bool,
};

MapMarkerCustomIcon.defaultProps = {
  backgroundColor: "#0052bd",
};
