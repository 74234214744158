import { schema } from "normalizr";

//#region VEHICLE_STOPS
export const vehicleStopSummarySchema: schema.Entity<any> = new schema.Entity(
  "vehicleStopSummary",
  {},
  { idAttribute: "routeId" }
);
export const vehicleStopsSummarySchema = new schema.Array(
  vehicleStopSummarySchema
);

export const vehicleStopsMacroSummarySchema: schema.Entity<any> =
  new schema.Entity(
    "vehicleStopsMacroSummary",
    {},
    { idAttribute: "vehicleId" }
  );

//#endregion VEHICLE_STOPS
