import { EntityId } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import { t } from "i18next";
import _ from "lodash";
import React, { useContext, useEffect, useState } from "react";
import {
  Location,
  useLocation,
  useMatch,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { store } from "../../app/store";
import { GTFleetSuccessCodes } from "../../config/GTFleetSuccessCodes";
import ContentSidebar from "../../layout/ContentSidebar";
import PageContent from "../../layout/PageContent";
import PageFilters from "../../layout/PageFilters";
import {
  VehicleBox,
  Vehicle as VehicleBoxType,
} from "../../ui/FleetControl/VehicleBox";
import { VehicleBoxGroupButton } from "../../ui/FleetControl/VehicleBoxGroupButton";
import { BackToSummary } from "../../ui/LocationHistory/BackToSummary";
import { BreakRow } from "../../ui/LocationHistory/BreakRow";
import { Divider } from "../../ui/LocationHistory/Divider";
import { OnlyStopRow } from "../../ui/LocationHistory/OnlyStopRow";
import { SingleTrackRow } from "../../ui/LocationHistory/SingleTrackRow";
import { TrackSummary } from "../../ui/LocationHistory/TrackSummary";
import { VehicleArrowDisabledProps } from "../../ui/LocationHistory/ViewArrow";
import { Sidebar } from "../../ui/Navigation/Sidebar";
import { formatTotalTime } from "../../utils/DateAndTimeUtils";
import { ToastNotification } from "../../utils/ToastNotification";
import {
  getAddressView,
  getIllustrationFromVehicleType,
  getQueryString,
} from "../../utils/Utils";
import { LocationHistoryMap } from "../components/LocationHistoryMap";
import { LocationHistorySummaryMap } from "../components/LocationHistorySummaryMap";
import { getFilteredDriversDetailsPaginationAsync } from "../driver/driversSlice";
import { Event, eventsSelectors } from "../event/eventsSlice";
import { fleetViewsSelectors } from "../fleet/fleetViewsSlice";
import {
  geofencesEmptyState,
  getGeofencesAsync,
} from "../geofence/geofenceSlice";
import {
  geofenceCategoriesEmptyState,
  getGeofenceCategoriesAsync,
} from "../geofenceCategory/geofenceCategoriesSlice";
import {
  VehicleTravelsMacroSummary,
  getVehicleTravelsMacroSummaryAsync,
  vehicleTravelsMacroSummaryEmptyState,
  vehicleTravelsMacroSummarySelectors,
} from "../report/vehicle/vehicleTravelsMacroSummarySlice";
import {
  VehicleTravelSummary,
  getVehicleTravelSummaryAsync,
  selectVehicleTravelsSummarySliceReasonCode,
  selectVehicleTravelsSummarySliceStatus,
  vehicleTravelsSummarySelectors,
} from "../report/vehicle/vehicleTravelsSummarySlice";
import {
  GpsDataEvent,
  RouteEvents,
  getEventAsync,
  routeEventEmptyState,
  routeEventSelectors,
  selectRouteEventSliceReasonCode,
  selectRouteEventSliceStatus,
} from "../route/routeEventsSlice";
import {
  RouteHistory,
  RoutePosition,
  readRouteHistoryAsync,
  routeHistoryEmptyRoutes,
  routesHistorySelectors,
  selectRoutesHistorySliceReasonCode,
} from "../route/routesHistorySlice";
import {
  RouteHistoryView,
  getRoutesHistoryViewAsync,
  routeHistoryViewEmptyState,
  routesHistoryViewSelectors,
  selectRoutesHistoryViewSliceReasonCode,
  selectRoutesHistoryViewSliceStatus,
} from "../route/routesHistoryViewSlice";
import {
  Route,
  getRoutesAsync,
  gpsDataSelectors,
  routesEmptyState,
  selectRouteSliceStatus,
} from "../route/routesSlice";
import { Preferences } from "../users/preference/preferencesSlice";
import { UserPermissions } from "../users/privilege/privilegesSlice";
import UserContext from "../users/userContext";
import { Vehicle } from "../vehicle/vehiclesSlice";
import "./VehiclesLocationHistory.css";
import { VehiclesLocationHistoryFilterBar } from "./VehiclesLocationHistoryFilterBar";
import {
  getVehiclesViewAsync,
  selectVehiclesStatusViewSliceReasonCode,
  selectVehiclesStatusViewSliceStatus,
  vehiclesStatusViewEmptyState,
  vehiclesStatusViewSelectors,
} from "./vehiclesStatusViewSlice";
import {
  vehiclesViewEmptyState,
  vehiclesViewSelectors,
} from "./vehiclesViewSlice";
dayjs.extend(duration);

export type RoutePoint = {
  location: string;
  time: string;
};
export type VehicleRouteHistoryView = {
  start: RoutePoint;
  end: RoutePoint;
  open?: boolean;
};
interface VehiclesLocationHistoryProps {
  expandedMain: boolean;
  permissions: UserPermissions;
}

export interface VehicleGroup {
  name: string;
  vehicles: Vehicle[];
}

interface QueryParams {
  [paramName: string]: any;
}

export const VehiclesLocationHistory: React.FC<VehiclesLocationHistoryProps> =
  ({ expandedMain, permissions }) => {
    const navigate = useNavigate();

    //#region STATES & DATA
    const [preferencesContext]: [Preferences] = useContext(UserContext);
    const [isDisabledArrow, setIsDisabledArrow] =
      useState<VehicleArrowDisabledProps>();
    const [vehicleSearchKey, setVehicleSearchKey] = useState<string>("");
    const [selectedEvents, setSelectedEvents] = useState<Event[]>([]);

    const [queryParamsFromFilterBar, setQueryParamsFromFilterBar] =
      useState<string>("");
    const [expanded, setExpanded] = useState(true);
    const [currentActive, setCurrentActive] = useState(null as any);
    const [showSummaryMap, setShowSummaryMap] = useState(false);
    const [showLocationHistoryMap, setShowLocationHistoryMap] = useState(false);
    const [openTrackDetails, setOpenTrackDetails] = useState(false);
    const [openStopDetails, setOpenStopDetails] = useState(false);
    const [showTrackbar, setShowTrackbar] = useState(false);
    const [currentActiveType, setCurrentActiveType] = useState(
      "" as "TRACK" | "STOP" | "NONE"
    );
    const [aliasFromVehicleBox, setAliasFromVehicleBox] = useState("");
    const [idFromVehicleBox, setIdFromVehicleBox] = useState(-1);
    const [customQueryParamsForSummaryMap, setCustomQueryParamsForSummaryMap] =
      useState<string>("");
    const [listGroup, setListGroup] = useState([] as VehicleGroup[]);
    const [infoVehicle, setInfoVehicle] = useState<Vehicle>({} as Vehicle);
    const [searchParams, setSearchParams] = useSearchParams();
    //#endregion STATES & DATA

    //#region SELECTORS
    const [openedTrackOrStop, setOpenedTrackOrStop] =
      useState<RouteHistoryView>({} as RouteHistoryView);
    const infoTrack: RouteHistory = useAppSelector(
      (state) =>
        routesHistorySelectors.selectById(state, currentActive ?? -1) ??
        ({} as RouteHistory)
    );
    const [currentRequestedViewIds, setCurrentRequestedViewIds] = useState<
      Map<String, EntityId[]>
    >(new Map());

    const [currentRequestedRouteIds, setCurrentRequestedRouteIds] = useState<
      Map<String, EntityId[]>
    >(new Map());

    const [currentRequestedEventIds, setCurrentRequestedEventIds] = useState<
      Map<String, EntityId[]>
    >(new Map());

    const [currentRequestedIds, setCurrentRequestedIds] = useState<
      Map<String, EntityId[]>
    >(new Map());

    const routesHistoryViewIds = useAppSelector(
      routesHistoryViewSelectors.selectIds
    );

    const routesHistoryView: RouteHistoryView[] = useAppSelector(
      routesHistoryViewSelectors.selectAll
    );

    const routesHistoryViewSliceStatus = useAppSelector(
      selectRoutesHistoryViewSliceStatus
    );
    const routesHistoryViewSliceReasonCode = useAppSelector(
      selectRoutesHistoryViewSliceReasonCode
    );
    const routeHistoryViewSize =
      routesHistoryView.length + (!_.isEmpty(openedTrackOrStop) ? 1 : 0);

    const gpsDataSliceStatus = useAppSelector(selectRouteSliceStatus);
    const routes: Route[] = useAppSelector(gpsDataSelectors.selectAll);
    const routesSliceStatus = useAppSelector(selectRouteSliceStatus);
    const routesSliceReasonCode = useAppSelector(
      selectRoutesHistorySliceReasonCode
    );

    const routeIds = useAppSelector(gpsDataSelectors.selectIds);

    const events: RouteEvents[] = useAppSelector(routeEventSelectors.selectAll);
    const [eventsWithOpenTrackOrStop, setEventsWithOpenTrackOrStop] = useState(
      [] as RouteEvents[]
    );

    const eventSliceStatus = useAppSelector(selectRouteEventSliceStatus);
    const eventSliceReasonCode = useAppSelector(
      selectRouteEventSliceReasonCode
    );

    const eventsIds = useAppSelector(routeEventSelectors.selectIds);

    const vehiclesStatusViewSliceStatus = useAppSelector(
      selectVehiclesStatusViewSliceStatus
    );
    const vehiclesStatusViewSliceReasonCode = useAppSelector(
      selectVehiclesStatusViewSliceReasonCode
    );

    const eventType: Event[] = useAppSelector(eventsSelectors.selectAll);

    const vehicleTravelSummary: VehicleTravelSummary =
      useAppSelector((state) =>
        vehicleTravelsSummarySelectors.selectById(state, currentActive ?? -1)
      ) ?? ({} as VehicleTravelSummary);

    const vehicleTravelSummarySliceStatus = useAppSelector(
      selectVehicleTravelsSummarySliceStatus
    );

    const vehicleTravelSummarySliceReasonCode = useAppSelector(
      selectVehicleTravelsSummarySliceReasonCode
    );

    const vehicleTravelsMacroSummary: VehicleTravelsMacroSummary[] =
      useAppSelector(vehicleTravelsMacroSummarySelectors.selectAll);

    //#endregion SELECTORS

    // Dispatch for list of vehicles
    useEffect(() => {
      document.title =
        t("navigation.userMenu.locationHistoryVehicle") +
        " - " +
        t("breadcrumbs.vehicles");
      store.dispatch(getVehiclesViewAsync(""));
      if (permissions.geofences.read) {
        store.dispatch(
          getGeofencesAsync({
            queryParams: getQueryString({ status: "ACTIVE" }),
          })
        );
        store.dispatch(getGeofenceCategoriesAsync());
      }
      if (permissions.drivers.read) {
        store.dispatch(
          getFilteredDriversDetailsPaginationAsync({
            queryParams: "?size=" + Number.MAX_SAFE_INTEGER,
          })
        );
      }
      return function cleanUp() {
        store.dispatch(vehicleTravelsMacroSummaryEmptyState());
        setOpenedTrackOrStop({} as RouteHistoryView);
        setEventsWithOpenTrackOrStop([]);
        store.dispatch(vehiclesViewEmptyState());
        store.dispatch(vehiclesStatusViewEmptyState());

        store.dispatch(geofencesEmptyState());
        store.dispatch(geofenceCategoriesEmptyState());
      };
    }, []);

    //#region ERROR_HANDLING
    useEffect(() => {
      if (
        routesHistoryViewSliceStatus === "failed" &&
        routesHistoryViewSliceReasonCode === ""
      ) {
        console.error(t("common.networkError"));
        ToastNotification({
          toastId: "networkError",
          status: "error",
          description: t("common.networkError"),
        });
      }
    }, [routesHistoryViewSliceStatus, routesHistoryViewSliceReasonCode]);

    useEffect(() => {
      if (eventSliceStatus === "failed" && eventSliceReasonCode === "") {
        console.error(t("common.networkError"));
        ToastNotification({
          toastId: "networkError",
          status: "error",
          description: t("common.networkError"),
        });
      }
    }, [eventSliceStatus, eventSliceReasonCode]);

    useEffect(() => {
      if (routesSliceStatus === "failed" && routesSliceReasonCode === "") {
        console.error(t("common.networkError"));
        ToastNotification({
          toastId: "networkError",
          status: "error",
          description: t("common.networkError"),
        });
      }
    }, [routesSliceStatus, routesSliceReasonCode]);

    useEffect(() => {
      if (routesHistoryView.length > -1) {
        if (routesHistoryViewSliceStatus === "idle") {
          if (routesHistoryViewSliceReasonCode === GTFleetSuccessCodes.GET) {
            if (routesHistoryView.length > -1) {
              const map = new Map();
              searchParams.forEach((value: string, key: string) => {
                if (value && value.length > 0) {
                  if (!map.get(key)) {
                    map.set(key, [value]);
                  } else {
                    map.set(key, [map.get(key)[0], value]);
                  }
                }
              });

              let queryParamsFixed: QueryParams = {};
              map.forEach((value, key) => {
                if (value && value.length > 0) {
                  queryParamsFixed[key] = value;
                }
              });

              const stringifiedQueryParams = getQueryString(queryParamsFixed);
              setCustomQueryParamsForSummaryMap(stringifiedQueryParams);
              store.dispatch(
                getRoutesAsync({
                  vehicleId: vehicleIdFromRoute,
                  queryParams: stringifiedQueryParams,
                })
              );
              store.dispatch(
                getEventAsync({
                  vehicleId: vehicleIdFromRoute,
                  queryParams: stringifiedQueryParams,
                })
              );
              setOpenedTrackOrStop({} as RouteHistoryView);
              setEventsWithOpenTrackOrStop([]);
            }
          }
        }

        if (
          routesHistoryViewSliceStatus === "failed" &&
          routesHistoryViewSliceReasonCode === ""
        ) {
          console.error(t("common.networkError"));
          ToastNotification({
            toastId: "networkError",
            status: "error",
            description: t("common.networkError"),
          });
        }
      }
    }, [routesHistoryViewSliceStatus, routesHistoryViewSliceReasonCode]);

    useEffect(() => {
      if (
        vehicleTravelSummarySliceStatus === "failed" &&
        vehicleTravelSummarySliceReasonCode === ""
      ) {
        console.error(t("common.networkError"));
        ToastNotification({
          toastId: "networkError",
          status: "error",
          description: t("common.networkError"),
        });
      }
    }, [vehicleTravelSummarySliceStatus, vehicleTravelSummarySliceReasonCode]);

    useEffect(() => {
      if (
        vehiclesStatusViewSliceStatus === "failed" &&
        vehiclesStatusViewSliceReasonCode === ""
      ) {
        console.error(t("common.networkError"));
        ToastNotification({
          toastId: "networkError",
          status: "error",
          description: t("common.networkError"),
        });
      }
    }, [vehiclesStatusViewSliceStatus, vehiclesStatusViewSliceReasonCode]);
    //#endregion

    /*
     * This method is in charge of grouping all tracks and stops by date.
     */
    let groupedRoutes = !_.isEmpty(openedTrackOrStop)
      ? [openedTrackOrStop, ...routesHistoryView].reduce(
          (dataObj: any, routes) => {
            const { gpsPositionTimestamp } = routes.firstPosition;
            const timeStampString = gpsPositionTimestamp.toString();
            dataObj[timeStampString] = dataObj[timeStampString] ?? [];
            dataObj[timeStampString].push(routes);
            return dataObj;
          },
          {}
        )
      : routesHistoryView.reduce((dataObj: any, routes) => {
          const { gpsPositionTimestamp } = routes.firstPosition;
          const timeStampString = gpsPositionTimestamp.toString();
          dataObj[timeStampString] = dataObj[timeStampString] ?? [];
          dataObj[timeStampString].push(routes);
          return dataObj;
        }, {});

    let groupedRoutesDate = Object.keys(groupedRoutes).reverse();
    let groupedDate: string[] = [];
    if (groupedRoutesDate.length > 0) {
      groupedRoutesDate = _.sortBy(groupedRoutesDate).reverse();
      groupedDate = groupedRoutesDate.map((timestamp) =>
        dayjs(timestamp).format("YYYY-MM-DD")
      );
    }
    //#region LOCATION_HANDLING

    const location: Location = useLocation();
    const queryParamsFromRoute = searchParams.toString();
    const currentQueryParamsFromRoute = queryParamsFromRoute
      .split("&")
      .filter((x) => !x.includes("currentPos"))
      .join("&");

    const locationHistoryRoute = useMatch(
      "/dashboard/vehicles/location-history"
    );

    let isLocationHistoryRoute = locationHistoryRoute !== null;

    const tracksRoute = useMatch(`/dashboard/vehicles/location-history/tracks`);
    let isTracksRoute = tracksRoute !== null;
    const trackRoute = useMatch(
      "/dashboard/vehicles/location-history/track/:trackId"
    );
    const stopRoute = useMatch(
      "/dashboard/vehicles/location-history/stop/:stopId"
    );

    let isTrackRoute = trackRoute !== null;
    let isStopRoute = stopRoute !== null;
    const trackIdFromRoute = trackRoute?.params?.trackId;
    const stopIdFromRoute = stopRoute?.params?.stopId;
    const trackOrStopIdFromRoute =
      trackRoute?.params?.trackId ?? stopRoute?.params?.stopId;
    const vehicleIdFromRoute = Number(searchParams.get("vehicle.id"));
    const currentPos = searchParams.get("currentPos");

    let isTracksOrStopOrTrackRoute =
      isTrackRoute || isStopRoute || isTracksRoute;

    // It updates the tab name with the selected vehicle
    useEffect(() => {
      if (!locationHistoryRoute && infoVehicle?.alias && infoVehicle?.plate) {
        document.title =
          infoVehicle.alias +
          " - " +
          infoVehicle.plate +
          " - " +
          t("navigation.userMenu.locationHistoryVehicle") +
          " - " +
          t("breadcrumbs.vehicles");
      } else {
        document.title =
          t("navigation.userMenu.locationHistoryVehicle") +
          " - " +
          t("breadcrumbs.vehicles");
      }
    }, [location, infoVehicle]);

    useEffect(() => {
      if (
        routeIds.length > 0 &&
        routesHistoryViewIds.length > -1 &&
        gpsDataSliceStatus === "idle" &&
        routesHistoryViewSliceStatus === "idle"
      ) {
        const routesHistoryViewIdsTemp = routesHistoryViewIds.map((x) =>
          x.valueOf()
        );
        const routeIdsTemp = routeIds.map((x) => x.valueOf());
        const missingRouteIds = routeIdsTemp.filter(
          (routeId) => !routesHistoryViewIdsTemp.includes(routeId)
        );
        if (missingRouteIds.length > 0) {
          const openedTrackOrStopTemp = gpsDataSelectors.selectById(
            store.getState(),
            missingRouteIds[0]
          );
          const trackOrStopStart = openedTrackOrStopTemp?.gpsData[0];
          const trackOrStopEnd =
            openedTrackOrStopTemp?.gpsData[
              openedTrackOrStopTemp?.gpsData?.length - 1
            ];
          const firstPosition: RoutePosition = {
            address: trackOrStopStart?.dynamicFields?.address ?? "",
            gpsPositionTimestamp: trackOrStopStart?.gpsdataByRouteTypeKey
              .timestamp
              ? new Date(trackOrStopStart?.gpsdataByRouteTypeKey.timestamp)
              : "",
            latitude: trackOrStopStart?.dynamicFields.latitude ?? -1,
            longitude: trackOrStopStart?.dynamicFields.longitude ?? -1,
          } as RoutePosition;
          const lastPosition: RoutePosition = {
            address: trackOrStopEnd?.dynamicFields?.address ?? "",
            gpsPositionTimestamp: trackOrStopEnd?.gpsdataByRouteTypeKey
              .timestamp
              ? new Date(trackOrStopEnd?.gpsdataByRouteTypeKey.timestamp)
              : "",
            latitude: trackOrStopEnd?.dynamicFields.latitude ?? -1,
            longitude: trackOrStopEnd?.dynamicFields.longitude ?? -1,
          } as RoutePosition;
          setOpenedTrackOrStop({
            id: missingRouteIds[0],
            firstPosition: firstPosition,
            lastPosition: lastPosition,
            routeStateType: trackOrStopEnd?.gpsdataByRouteTypeKey.routeType,
            open: true,
          } as RouteHistoryView);
        }
      }
    }, [
      routeIds,
      routesHistoryViewIds,
      gpsDataSliceStatus,
      routesHistoryViewSliceStatus,
    ]);

    // This useEffect helps to build or maintain the list of event for currently open track or stop
    useEffect(() => {
      if (!_.isEmpty(openedTrackOrStop) && eventSliceStatus === "idle") {
        const gpsData = gpsDataSelectors.selectById(
          store.getState(),
          openedTrackOrStop.id
        );
        if (gpsData && gpsData?.gpsData.length > 0) {
          let lastGPSData = gpsData?.gpsData[gpsData.gpsData.length - 1];
          if (events.some((e) => e.id === gpsData.id)) {
            const newEventsTemp = events.map((event) => {
              if (event.id === gpsData.id) {
                let newRouteEvent = Object.assign({}, event);
                let newRouteEventList = [
                  ...event.event,
                  {
                    driverId: lastGPSData.driverId,
                    dynamicFields: lastGPSData.dynamicFields,
                    eventLogByVehicleIdKey: {
                      name:
                        openedTrackOrStop.routeStateType === "TRACK"
                          ? "END"
                          : "START",
                      vehicleId: lastGPSData.gpsdataByRouteTypeKey.vehicleId,
                      trimesterBucket:
                        event.event[0].eventLogByVehicleIdKey.trimesterBucket,
                      timestamp: lastGPSData.gpsdataByRouteTypeKey.timestamp,
                    },
                    eventOutcomeEnum: "OK",
                    routeId: gpsData.id,
                    typeId: eventType.filter(
                      (type) =>
                        type.name ===
                        (openedTrackOrStop.routeStateType === "TRACK"
                          ? "END"
                          : "START")
                    )[0].id,
                  } as GpsDataEvent,
                ];
                newRouteEvent.event = newRouteEventList;
                return newRouteEvent;
              }
              return event;
            });
            setEventsWithOpenTrackOrStop(newEventsTemp);
          } else {
            const newEventsTemp = [
              ...events,
              {
                id: gpsData.id,
                event: [
                  {
                    driverId: lastGPSData.driverId,
                    dynamicFields: lastGPSData.dynamicFields,
                    eventLogByVehicleIdKey: {
                      name:
                        openedTrackOrStop.routeStateType === "TRACK"
                          ? "END"
                          : "START",
                      vehicleId: lastGPSData.gpsdataByRouteTypeKey.vehicleId,
                      trimesterBucket: -1,
                      timestamp: lastGPSData.gpsdataByRouteTypeKey.timestamp,
                    },
                    eventOutcomeEnum: "OK",
                    routeId: gpsData.id,
                    typeId: eventType.filter(
                      (type) =>
                        type.name ===
                        (openedTrackOrStop.routeStateType === "TRACK"
                          ? "END"
                          : "START")
                    )[0].id,
                  } as GpsDataEvent,
                ],
              } as RouteEvents,
            ];
            setEventsWithOpenTrackOrStop(newEventsTemp);
          }
        }
      }
    }, [openedTrackOrStop, events, eventSliceStatus]);

    /**
     * This use effect gets executed every time the URL changes.
     * Theorically, this useEffect happens right just after the filter bar changes one.
     */
    useEffect(() => {
      // If there are no vehicles they should be requested
      if (vehiclesStatusViewSliceStatus === "idle" && vehicles.length === 0) {
        store.dispatch(getVehiclesViewAsync(""));
      }

      // If the current location is the main page then the state should be wiped out
      if (isLocationHistoryRoute) {
        restoreLocationHistoryState();
      }
      if (isTracksRoute) {
        restoreLocationHistoryTracksState();
        const viewIds = currentRequestedViewIds.get(queryParamsFromFilterBar);
        const missingRouteViews = !_.isEqual(viewIds, routesHistoryViewIds);

        const routesIds = currentRequestedRouteIds.get(
          queryParamsFromFilterBar
        );
        const missingRoutes = !_.isEqual(routesIds, routeIds);

        if (
          missingRouteViews &&
          queryParamsFromFilterBar &&
          queryParamsFromFilterBar !== ""
        ) {
          store.dispatch(getRoutesHistoryViewAsync(queryParamsFromFilterBar));
        }

        if (queryParamsFromFilterBar && missingRoutes && vehicleIdFromRoute) {
          store.dispatch(routesEmptyState());
          store.dispatch(routeEventEmptyState());

          store.dispatch(
            getVehicleTravelsMacroSummaryAsync({
              vehicleId: vehicleIdFromRoute,
              queryParams: queryParamsFromFilterBar,
            })
          );
        }
      }

      // If the current location is the stop/track page then info of the specific route should be required
      if (isTrackRoute || isStopRoute) {
        const ids = currentRequestedViewIds.get(queryParamsFromFilterBar);
        const missingRoutes = !_.isEqual(ids, routesHistoryViewIds);
        queryParamsFromFilterBar &&
          missingRoutes &&
          store.dispatch(getRoutesHistoryViewAsync(queryParamsFromFilterBar));
        if (isTrackRoute && trackIdFromRoute) {
          restoreLocationHistoryTrackState();
          selectedTrack(parseInt(trackIdFromRoute), "TRACK");
        }
        if (isStopRoute && stopIdFromRoute) {
          restoreLocationHistoryStopState();
          selectedTrack(parseInt(stopIdFromRoute), "STOP");
        }
      }
    }, [location, queryParamsFromFilterBar]);

    useEffect(() => {
      if (isTracksRoute) {
        setShowTrackbar(false);
      }
    }, [queryParamsFromFilterBar]);

    useEffect(() => {
      if (currentActiveType === "STOP") {
        setIsDisabledArrow({ isDisabled: true });
      } else {
        setIsDisabledArrow({ isDisabled: false });
      }
    }, [currentActiveType]);

    //#region STATE_RESTORE
    function restoreLocationHistoryState() {
      showSummaryMap && setShowSummaryMap(false);
      showLocationHistoryMap && setShowLocationHistoryMap(false);
      aliasFromVehicleBox !== "" && setAliasFromVehicleBox("");
      idFromVehicleBox !== -1 && setIdFromVehicleBox(-1);
      queryParamsFromFilterBar !== "" && setQueryParamsFromFilterBar("");
      currentActive !== "" && setCurrentActive(null);
      !_.isEmpty(infoVehicle) && setInfoVehicle({} as Vehicle);
      vehicleTravelsMacroSummary.length > 0 &&
        store.dispatch(vehicleTravelsMacroSummaryEmptyState());
      !_.isEmpty(infoTrack) && store.dispatch(routeHistoryEmptyRoutes());
      routesHistoryView.length > 0 &&
        store.dispatch(routeHistoryViewEmptyState());
    }

    function restoreLocationHistoryTracksState() {
      setShowSummaryMap(true);
      showLocationHistoryMap && setShowLocationHistoryMap(false);
      currentActive && setCurrentActive(null);
      currentActiveType !== "NONE" && setCurrentActiveType("NONE");
      openStopDetails && setOpenStopDetails(false);
      openTrackDetails && setOpenTrackDetails(false);
    }

    function restoreLocationHistoryTrackState() {
      setShowSummaryMap(false);
      !showLocationHistoryMap && setShowLocationHistoryMap(true);
      !openTrackDetails && setOpenTrackDetails(true);
      openStopDetails && setOpenStopDetails(false);
    }

    function restoreLocationHistoryStopState() {
      setShowSummaryMap(false);
      !showLocationHistoryMap && setShowLocationHistoryMap(true);
      !openStopDetails && setOpenStopDetails(true);
      openTrackDetails && setOpenTrackDetails(false);
    }

    useEffect(() => {
      const ids = currentRequestedViewIds.get(queryParamsFromFilterBar);
      if (routesHistoryViewIds.length > 0) {
        if (!_.isEqual(ids, routesHistoryViewIds)) {
          currentRequestedViewIds.set(
            queryParamsFromFilterBar,
            routesHistoryViewIds
          );
          setCurrentRequestedViewIds(currentRequestedViewIds);
        }
      }
    }, [routesHistoryViewIds]);

    useEffect(() => {
      const ids = currentRequestedRouteIds.get(queryParamsFromFilterBar);
      if (routeIds.length > 0) {
        if (!_.isEqual(ids, routeIds)) {
          currentRequestedRouteIds.set(queryParamsFromFilterBar, routeIds);
          setCurrentRequestedRouteIds(currentRequestedRouteIds);
        }
      }
    }, [routeIds]);

    useEffect(() => {
      const ids = currentRequestedEventIds.get(queryParamsFromFilterBar);
      if (eventsIds.length > 0) {
        if (!_.isEqual(ids, eventsIds)) {
          currentRequestedEventIds.set(queryParamsFromFilterBar, eventsIds);
          setCurrentRequestedEventIds(currentRequestedEventIds);
        }
      }
    }, [eventsIds]);

    useEffect(() => {
      const ids = currentRequestedIds.get(queryParamsFromFilterBar) ?? [];
      if (routeIds.length > 0 && tracksRoute) {
        if (!_.isEqual(ids, routeIds)) {
          const newRoutesHistoryIds = routeIds.filter((x) => !ids.includes(x));
          const newIds = ids.concat(newRoutesHistoryIds);
          currentRequestedIds.set(queryParamsFromFilterBar, newIds);
          setCurrentRequestedIds(currentRequestedIds);
        }
      }
    }, [routeIds]);

    useEffect(() => {
      if (
        !!currentActive &&
        Number(trackOrStopIdFromRoute) === currentActive &&
        !!routesHistoryView &&
        routesHistoryView.length > 0 &&
        !_.isEmpty(infoVehicle) &&
        infoVehicle?.id
      ) {
        const infoTrackView: RouteHistoryView =
          routesHistoryViewSelectors.selectById(
            store.getState(),
            currentActive
          ) ?? ({} as RouteHistoryView);
        if (
          infoTrackView?.firstPosition?.gpsPositionTimestamp &&
          infoTrackView?.lastPosition?.gpsPositionTimestamp
        ) {
          let trackQueryString = getQueryString({
            firstPointDate: [
              infoTrackView?.firstPosition?.gpsPositionTimestamp,
              infoTrackView?.lastPosition?.gpsPositionTimestamp,
            ],
            routeType: infoTrackView?.routeStateType,
          });

          if (
            !vehicleTravelsSummarySelectors.selectById(
              store.getState(),
              currentActive
            )
          ) {
            store.dispatch(
              getVehicleTravelSummaryAsync({
                routeId: currentActive,
                queryParams: trackQueryString,
                vehicleId: infoVehicle.id,
              })
            );
          }
        }
      }
    }, [currentActive, routesHistoryView, infoVehicle]);

    function selectedTrack(trackId: number, type: "TRACK" | "STOP" | "NONE") {
      if (currentActive !== trackId) {
        if (!routesHistorySelectors.selectById(store.getState(), trackId)) {
          store.dispatch(
            readRouteHistoryAsync({
              vehicleId: vehicleIdFromRoute,
              routeHistoryId: trackId,
              queryParams: queryParamsFromFilterBar,
            })
          );
        }
        setCurrentActive(trackId);
        setCurrentActiveType(type);
        setShowTrackbar(true);
      }
    }

    //#endregion

    //#endregion

    /*
     * Track Summary data definitions
     */

    //#region Take Vehicles data
    let vehicles: Vehicle[] = useAppSelector((state) =>
      vehiclesViewSelectors.selectAll(state)
    ) as Vehicle[];

    let vehiclesStatusIds: EntityId[] = useAppSelector((state) =>
      vehiclesStatusViewSelectors.selectIds(state)
    );

    const containsCurrentActiveVehicle = (vehicles: Vehicle[]) => {
      return vehicles.some((vehicle) => vehicle.id === currentActive);
    };

    /**
     * This is useEffect restores the alias of the selected vehicle.
     */
    useEffect(() => {
      if (
        !!vehicleIdFromRoute &&
        vehicleIdFromRoute !== 0 &&
        vehicles.length > 0
      ) {
        if (
          !infoVehicle ||
          _.isEmpty(infoVehicle) ||
          infoVehicle?.id !== vehicleIdFromRoute
        ) {
          setInfoVehicle(
            (vehiclesViewSelectors.selectById(
              store.getState(),
              vehicleIdFromRoute
            ) as Vehicle) ?? infoVehicle
          );
        }
      }
    }, [vehicleIdFromRoute, vehicles]);

    useEffect(() => {
      if (vehicles.length > 0) {
        let groupNames: VehicleGroup[] = [];

        vehiclesStatusIds.forEach((vehicleStatusId) => {
          const vehicle: Vehicle =
            (vehiclesViewSelectors.selectById(
              store.getState(),
              vehicleStatusId.valueOf()
            ) as Vehicle) ?? ({} as Vehicle);
          if (!!vehicle && !_.isEmpty(vehicle)) {
            let vehicleTemp: Vehicle = { ...vehicle };
            const fleetName = fleetViewsSelectors.selectById(
              store.getState(),
              vehicle.fleet
            )?.name;
            if (
              groupNames.filter(function (e) {
                return e.name === fleetName;
              }).length === 0
            ) {
              groupNames.push({
                name: fleetName ?? t("common.na"),
                vehicles: [vehicleTemp],
              });
            } else {
              let indexGroupNames = groupNames.findIndex((value) => {
                return (
                  value.name ===
                  fleetViewsSelectors.selectById(
                    store.getState(),
                    vehicle.fleet
                  )?.name
                );
              });
              groupNames[indexGroupNames].vehicles.push(vehicleTemp);
            }
          }
        });
        if (groupNames.length > 0) {
          let sortedGroupNames = groupNames.map((groupName) => {
            return {
              name: groupName.name,
              vehicles: _.sortBy(groupName.vehicles, (x) => x.alias),
            } as VehicleGroup;
          });
          sortedGroupNames = _.sortBy(sortedGroupNames, (x) => x.name);
          setListGroup([...sortedGroupNames]);
        }
      }
    }, [vehicles]);

    /**
     *  This useEffect makes possible the auto scroll to the selected stop or track into the sidebar
     */
    useEffect(() => {
      let element;
      if (isTrackRoute && trackIdFromRoute) {
        element = document.getElementById(
          `single-track-row-${trackIdFromRoute}`
        );
      }
      if (isStopRoute && stopIdFromRoute) {
        element = document.getElementById(`mn-break-row-${stopIdFromRoute}`);
      }

      if (element && routeHistoryViewSize !== 0) {
        console.log(element);
        element.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "end",
        });
      }
    }, [
      isTrackRoute,
      trackIdFromRoute,
      isStopRoute,
      routesHistoryView,
      stopIdFromRoute,
    ]);

    const viewListVehicles = () => {
      return listGroup?.map((groupName, index) => (
        <React.Fragment key={index}>
          <VehicleBoxGroupButton
            open={
              listGroup?.length == 1 ||
              containsCurrentActiveVehicle(groupName.vehicles) ||
              (!!vehicleSearchKey && vehicleSearchKey != "")
            }
            title={groupName.name}
          >
            {groupName.vehicles
              // eslint-disable-next-line array-callback-return
              ?.filter((vehicle: Vehicle) => {
                const filterVehicle =
                  vehicleSearchKey == "" ||
                  (vehicle?.alias &&
                    vehicle?.alias
                      ?.toLowerCase()
                      .includes(vehicleSearchKey.toLowerCase())) ||
                  (vehicle?.plate &&
                    vehicle?.plate
                      ?.toLowerCase()
                      .includes(vehicleSearchKey.toLowerCase()));
                return filterVehicle;
              })
              .map((vehicle, idx) => {
                const vehicleStatus = vehiclesStatusViewSelectors.selectById(
                  store.getState(),
                  vehicle.vehicleStatus
                );
                if (!!vehicleStatus && !!vehicle) {
                  return (
                    <React.Fragment key={idx}>
                      <VehicleBox
                        id={vehicle.id}
                        vehicle={vehicle}
                        active={
                          parseInt(tracksRoute?.params.vehicleId ?? "-1") ===
                            vehicle.id || vehicle.id === idFromVehicleBox
                        }
                        icon={getIllustrationFromVehicleType(vehicle)}
                        hasSubtitleIcon={false}
                        clickCallback={(e: VehicleBoxType) => {
                          setAliasFromVehicleBox(e.alias);
                          setIdFromVehicleBox(e.id);
                        }}
                      />
                    </React.Fragment>
                  );
                }
              })
              .filter((x) => !!x)}
          </VehicleBoxGroupButton>
        </React.Fragment>
      ));
    };
    //#endregion Take Vehicles data

    const renderSplitterLine = (index: number) => {
      if (index > 0) {
        if (
          groupedDate[index] === groupedDate[index - 1] ||
          index - 1 === groupedDate.length
        ) {
          return <div className="vehicle-lh-splitter-line" />;
        }
      }
    };

    /**
     * Handle routes from filter bar QueryParams
     * @param queryParams
     */
    const handleRoutesFromFilterBarQueryParams = (queryParams: string) => {
      // If filter bar query params changes
      // if filter bar query params are different from component state for query params, then update them
      if (queryParams !== queryParamsFromFilterBar) {
        setQueryParamsFromFilterBar(queryParams);
      }
      // If URL query params are different from filter bar query params, then update URL
      if ("?" + currentQueryParamsFromRoute !== queryParams) {
        // If current location is track or stop page, then go back to tracks
        if (isTrackRoute || isStopRoute) {
          navigate({
            pathname: "/dashboard/vehicles/location-history/tracks",
            search: queryParams,
          });
        } else if (isLocationHistoryRoute) {
          // Go to the tracks page and update its query parameters
          window.open(
            `/dashboard/vehicles/location-history/tracks` + queryParams,
            "_blank",
            "noopener,noreferrer"
          );
        } else {
          // Else simply update URL query params
          setSearchParams(queryParams);
        }
      }
    };

    return (
      <>
        <PageFilters hidden={expanded ? "" : "none"}>
          <div className="col col-16">
            <VehiclesLocationHistoryFilterBar
              vehicleSearch={setVehicleSearchKey}
              selectedEventsFromDropdown={setSelectedEvents}
              aliasFromLocation={aliasFromVehicleBox}
              callback={handleRoutesFromFilterBarQueryParams}
            />
          </div>
        </PageFilters>
        {(queryParamsFromFilterBar || trackIdFromRoute || stopIdFromRoute) !==
          "" && (
          <PageContent height={expanded}>
            <ContentSidebar>
              <div className="vehicle-lh-sidebar">
                <Sidebar expanded={expanded} expandedUpdated={setExpanded}>
                  {/* List of vehicles */}
                  {isLocationHistoryRoute && viewListVehicles()}
                  {/* Tracks or Stop of selected vehicle */}
                  {!isLocationHistoryRoute &&
                    groupedRoutesDate &&
                    isTracksOrStopOrTrackRoute && (
                      <div className="vehicle-lh-all-tracks-wrapper">
                        <div className="vehicle-lh-all-tracks-header">
                          <BackToSummary
                            text={t("locationHistory.backToSummary.back")}
                            onClick={() => {
                              navigate({
                                pathname:
                                  "/dashboard/vehicles/location-history",
                              });
                            }}
                          />
                          {!_.isEmpty(infoVehicle) && (
                            <VehicleBox
                              id={infoVehicle?.id || 0}
                              vehicle={infoVehicle}
                              active={!showTrackbar}
                              icon={getIllustrationFromVehicleType(infoVehicle)}
                              hasSubtitleIcon={false}
                              clickCallback={() => {
                                setShowTrackbar(!showTrackbar);
                                if (showTrackbar) {
                                  navigate({
                                    pathname:
                                      "/dashboard/vehicles/location-history/tracks",
                                    search: queryParamsFromFilterBar,
                                  });
                                }
                              }}
                            />
                          )}
                        </div>
                        {/* Show list of tracks or string "No tracks found" */}
                        <div className="vehicle-lh-all-tracks">
                          {routeHistoryViewSize === 0 &&
                            routesHistoryViewSliceStatus === "idle" &&
                            routesSliceStatus === "idle" &&
                            eventSliceStatus === "idle" && (
                              <TrackSummary
                                shortType={true}
                                preference={preferencesContext}
                              />
                            )}
                          {routeHistoryViewSize !== 0 &&
                            groupedRoutesDate?.map(
                              (date: string, index: number) => {
                                return (
                                  <React.Fragment key={index}>
                                    {index === 0 && (
                                      <Divider
                                        hasData={false}
                                        date={groupedDate[index]}
                                      />
                                    )}
                                    {groupedRoutes[date].map((x: any) => {
                                      if (x.routeStateType === "TRACK") {
                                        const point: VehicleRouteHistoryView = {
                                          start: {
                                            location: getAddressView(
                                              x.firstPosition.address
                                            ),
                                            time: x.firstPosition
                                              .gpsPositionTimestamp,
                                          },
                                          end: {
                                            location: getAddressView(
                                              x.lastPosition.address
                                            ),
                                            time: x.lastPosition
                                              .gpsPositionTimestamp,
                                          },
                                          open: x?.open,
                                        };
                                        return (
                                          <div key={x.id}>
                                            {renderSplitterLine(index)}
                                            <SingleTrackRow
                                              id={x.id}
                                              points={point}
                                              clickCallback={(isSelected) => {
                                                if (!isSelected) {
                                                  navigate({
                                                    pathname:
                                                      "/dashboard/vehicles/location-history/tracks",
                                                    search:
                                                      queryParamsFromFilterBar,
                                                  });
                                                  setShowTrackbar(false);
                                                } else {
                                                  navigate({
                                                    pathname: `/dashboard/vehicles/location-history/track/${x.id}`,
                                                    search:
                                                      queryParamsFromFilterBar,
                                                  });
                                                  setShowTrackbar(true);
                                                }
                                              }}
                                              active={x.id === currentActive}
                                            />
                                          </div>
                                        );
                                      } else if (
                                        infoVehicle.type === "TRAILER_CAR" ||
                                        infoVehicle.type === "TRAILER"
                                      ) {
                                        const point = {
                                          location: getAddressView(
                                            x.lastPosition.address
                                          ),
                                          time: x.lastPosition
                                            .gpsPositionTimestamp,
                                          open: x?.open,
                                        };
                                        return (
                                          <div key={x.id}>
                                            {renderSplitterLine(index)}
                                            <OnlyStopRow
                                              id={x.id}
                                              point={point}
                                              clickCallback={(isSelected) => {
                                                if (!isSelected) {
                                                  navigate({
                                                    pathname:
                                                      "/dashboard/vehicles/location-history/tracks",
                                                    search:
                                                      queryParamsFromFilterBar,
                                                  });
                                                } else {
                                                  navigate({
                                                    pathname: `/dashboard/vehicles/location-history/stop/${x.id}`,
                                                    search:
                                                      queryParamsFromFilterBar,
                                                  });
                                                }
                                              }}
                                              active={x.id === currentActive}
                                            />
                                          </div>
                                        );
                                      } else {
                                        return (
                                          <div key={x.id}>
                                            {renderSplitterLine(index)}
                                            <BreakRow
                                              id={x.id}
                                              title={t(
                                                "locationHistory.breakRow.title"
                                              )}
                                              open={x?.open}
                                              time={formatTotalTime(
                                                x.firstPosition
                                                  .gpsPositionTimestamp,
                                                x.lastPosition
                                                  .gpsPositionTimestamp
                                              )}
                                              clickCallback={(isSelected) => {
                                                if (!isSelected) {
                                                  navigate({
                                                    pathname:
                                                      "/dashboard/vehicles/location-history/tracks",
                                                    search:
                                                      queryParamsFromFilterBar,
                                                  });
                                                  setShowTrackbar(false);
                                                } else {
                                                  navigate({
                                                    pathname: `/dashboard/vehicles/location-history/stop/${x.id}`,
                                                    search:
                                                      queryParamsFromFilterBar,
                                                  });
                                                  setShowTrackbar(true);
                                                }
                                              }}
                                              active={x.id === currentActive}
                                            />
                                          </div>
                                        );
                                      }
                                    })}
                                    {index < groupedDate.length - 1 &&
                                      groupedDate[index] !==
                                        groupedDate[index + 1] && (
                                        <Divider
                                          hasData={index !== 0}
                                          date={groupedDate[index + 1]}
                                        />
                                      )}
                                  </React.Fragment>
                                );
                              }
                            )}
                        </div>
                      </div>
                    )}
                </Sidebar>
              </div>
            </ContentSidebar>
            <div
              className="col"
              style={{ height: "100%", position: "relative" }}
            >
              {isLocationHistoryRoute && (
                <img
                  className="vehicle-lh-static-map"
                  alt="minimap"
                  src={`https://maps.googleapis.com/maps/api/staticmap?center=${41.9027835},${12.4963655}&zoom=6&size=1633x901&key=${
                    process.env.REACT_APP_GOOGLE_MAPS_API_KEY
                  }&style=element%3Alabels.text.fill%7Ccolor%3A0x7c93a3%7Clightness%3A-10&amp&style=feature%3Aadministrative.country%7Celement%3Ageometry%7Cvisibility%3Aon&amp&style=feature%3Aadministrative.country%7Celement%3Ageometry.stroke%7Ccolor%3A0xa0a4a5&amp&style=feature%3Aadministrative.province%7Celement%3Ageometry.stroke%7Ccolor%3A0x62838e&amp&style=feature%3Alandscape%7Celement%3Ageometry.fill%7Ccolor%3A0xdde3e3&amp&style=feature%3Alandscape.man_made%7Celement%3Ageometry.stroke%7Ccolor%3A0x3f4a51%7Cweight%3A0.30&amp&style=feature%3Apoi%7Cvisibility%3Asimplified&amp&style=feature%3Apoi.attraction%7Cvisibility%3Aon&amp&style=feature%3Apoi.business%7Cvisibility%3Aoff&amp&style=feature%3Apoi.government%7Cvisibility%3Aoff&amp&style=feature%3Apoi.park%7Cvisibility%3Aon&amp&style=feature%3Apoi.place_of_worship%7Cvisibility%3Aoff&amp&style=feature%3Apoi.school%7Cvisibility%3Aoff&amp&style=feature%3Apoi.sports_complex%7Cvisibility%3Aoff&amp&style=feature%3Aroad%7Csaturation%3A-100%7Cvisibility%3Aon&amp&style=feature%3Aroad%7Celement%3Ageometry.stroke%7Cvisibility%3Aon&amp&style=feature%3Aroad.highway%7Celement%3Ageometry.fill%7Ccolor%3A0xbbcacf&amp&style=feature%3Aroad.highway%7Celement%3Ageometry.stroke%7Clightness%3A0%7Ccolor%3A0xbbcacf%7Cweight%3A0.50&amp&style=feature%3Aroad.highway%7Celement%3Alabels%7Cvisibility%3Aon&amp&style=feature%3Aroad.highway%7Celement%3Alabels.text%7Cvisibility%3Aon&amp&style=feature%3Aroad.highway.controlled_access%7Celement%3Ageometry.fill%7Ccolor%3A0xffffff&amp&style=feature%3Aroad.highway.controlled_access%7Celement%3Ageometry.stroke%7Ccolor%3A0xa9b4b8&amp&style=feature%3Atransit%7Cvisibility%3Aoff&amp&style=feature%3Awater%7Celement%3Ageometry.fill%7Ccolor%3A0xa3c7df&amp;rnd=1636735607959`}
                />
              )}
              {showSummaryMap && !_.isEmpty(infoVehicle) && (
                <>
                  <LocationHistorySummaryMap
                    id={0}
                    vehicle={infoVehicle}
                    expandedSidebar={expanded}
                    selectedEvents={selectedEvents}
                    googleMapsApiKey={
                      process.env.REACT_APP_GOOGLE_MAPS_API_KEY!
                    }
                    vehicleType={infoVehicle.type}
                    routes={routes}
                    summaryDetails={
                      infoVehicle.type === "TRAILER_CAR"
                        ? ({
                            stopCounter:
                              vehicleTravelsMacroSummary[0]?.stopCounter,
                          } as VehicleTravelsMacroSummary)
                        : vehicleTravelsMacroSummary[0]
                    }
                    openTrackDetails={openTrackDetails}
                    events={(!_.isEmpty(openedTrackOrStop) &&
                    eventsWithOpenTrackOrStop.length > 0
                      ? eventsWithOpenTrackOrStop
                      : events
                    ).map((eventFilter) => ({
                      ...eventFilter,
                      event: eventFilter.event.filter(
                        (elem) =>
                          (elem?.dynamicFields?.latitude !== 0.0 &&
                            elem?.dynamicFields?.longitude !== 0.0 &&
                            selectedEvents.some(
                              (el) =>
                                el.name === elem.eventLogByVehicleIdKey.name
                            )) ||
                          elem.eventLogByVehicleIdKey.name === "START" ||
                          elem.eventLogByVehicleIdKey.name === "END"
                      ),
                    }))}
                    eventType={eventType}
                    routesSliceStatus={routesSliceStatus}
                    eventSliceStatus={eventSliceStatus}
                    showTrackbar={showTrackbar}
                    zoom={5}
                    latitude={41.9027835}
                    longitude={12.4963655}
                    routesIds={routes.map((route) => route.id)}
                    completedRoutesIds={routesHistoryView.length > 0}
                    queryParams={customQueryParamsForSummaryMap}
                  />
                </>
              )}
              {showLocationHistoryMap && !_.isEmpty(infoVehicle) && (
                <>
                  <LocationHistoryMap
                    id={1}
                    selectedEvents={selectedEvents}
                    eventType={eventType}
                    googleMapsApiKey={
                      process.env.REACT_APP_GOOGLE_MAPS_API_KEY!
                    }
                    isDisableArrow={isDisabledArrow}
                    currentActiveType={currentActiveType}
                    zoom={5}
                    latitude={41.9027835}
                    longitude={12.4963655}
                    summaryTrack={vehicleTravelSummary}
                    openTrackOrStop={
                      infoTrack?.id === openedTrackOrStop?.id &&
                      _.isEmpty(vehicleTravelSummary)
                    }
                    infoTrack={
                      !!infoTrack && !_.isEmpty(infoTrack)
                        ? {
                            ...infoTrack,
                            routeStates: infoTrack.routeStates
                              .filter(
                                (elem) =>
                                  elem.dynamicFields.latitude !== 0.0 &&
                                  elem.dynamicFields.longitude !== 0.0
                              )
                              .map((routeState) => {
                                return {
                                  ...routeState,
                                  events: routeState.events.filter(
                                    (e) =>
                                      selectedEvents
                                        .map((se) => se.name)
                                        .includes(e.type.name) ||
                                      [
                                        "START",
                                        "IGNITION_KEY_ON",
                                        "END",
                                        "IGNITION_KEY_OFF",
                                      ].includes(e.type.name)
                                  ),
                                };
                              }),
                          }
                        : undefined
                    }
                    openTrackDetails={openTrackDetails}
                    openStopDetails={openStopDetails}
                    expanded={expanded}
                    viewPolylineMarkers={false}
                    openTrackBar={
                      !!infoTrack &&
                      (infoTrack?.routeStateType === "TRACK" ||
                        infoTrack?.routeStateType === "STOP")
                    }
                    expandedMain={expandedMain}
                    currentRouteStateTimestamp={currentPos ?? undefined}
                    sendCurrentTrackBarPosition={(
                      currentTrackBarPosition: string
                    ) => {
                      if (currentPos !== currentTrackBarPosition) {
                        setSearchParams(
                          queryParamsFromFilterBar
                            ? queryParamsFromFilterBar +
                                "&currentPos=" +
                                currentTrackBarPosition
                            : "?currentPos=" + currentTrackBarPosition
                        );
                      }
                    }}
                  />
                </>
              )}
            </div>
          </PageContent>
        )}
      </>
    );
  };
