import { t } from "i18next";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppSelector } from "../../../app/hooks";
import { store } from "../../../app/store";
import { GTFleetErrorCodes } from "../../../config/GTfleetErrorCodes";
import { ToastNotification } from "../../../utils/ToastNotification";
import { getFleetViewsAsync } from "../../fleet/fleetViewsSlice";
import { Vehicle, vehiclesSelectors } from "../../vehicle/vehiclesSlice";
import {
  getVehicleAsync,
  selectVehiclesStatusSliceReasonCode,
  selectVehiclesStatusSliceStatus,
} from "../../vehicle/vehiclesStatusSlice";
import { Deadline } from "./../deadlinesSlice";
import { EditDeadlineModal } from "./EditDeadline";

interface DeadlineEditModalProps {
  deadlineStatus: Deadline;
  defaultDeadlineId: number;
  defaultFleetId: number;
  defaultVehicleId: number;
}

const DeadlineEditModal: React.FC<DeadlineEditModalProps> = ({
  deadlineStatus,
  defaultFleetId,
  defaultDeadlineId,
  defaultVehicleId,
}) => {
  const [addMaintenanceModal, setAddMaintenanceModal] = useState(true);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const vehicleStatusSliceStatus = useAppSelector(
    selectVehiclesStatusSliceStatus
  );
  const vehicleStatusSliceReasonCode = useAppSelector(
    selectVehiclesStatusSliceReasonCode
  );

  useEffect(() => {
    store.dispatch(getFleetViewsAsync());
    store.dispatch(
      getVehicleAsync({ id: defaultVehicleId, fleetId: defaultFleetId })
    );
  }, []);

  const vehicle: Vehicle = vehiclesSelectors.selectById(
    store.getState(),
    defaultVehicleId
  ) as Vehicle;

  useEffect(() => {
    if (
      vehicleStatusSliceStatus === "failed" &&
      vehicleStatusSliceReasonCode ===
        GTFleetErrorCodes.VEHICLE_STATUS_NOT_FOUND
    ) {
      console.error(t("common.vehicleStatusError"));
      ToastNotification({
        toastId: "vehicleStatusError",
        status: "error",
        description: t("common.vehicleStatusError"),
      });
    } else if (
      vehicleStatusSliceStatus === "failed" &&
      vehicleStatusSliceReasonCode === ""
    ) {
      ToastNotification({
        toastId: "networkError",
        status: "error",
        description: t("common.networkError"),
      });
    }
  }, [vehicleStatusSliceStatus, vehicleStatusSliceReasonCode]);

  return (
    <EditDeadlineModal
      open={addMaintenanceModal}
      defaultValues={deadlineStatus}
      vehicleSelected={vehicle}
      deadlineId={defaultDeadlineId}
      onClose={() => {
        setAddMaintenanceModal(false);
        navigate({
          pathname: "/dashboard/maintenance",
          search: searchParams.toString(),
        });
      }}
    />
  );
};
export default DeadlineEditModal;
