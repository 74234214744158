import HttpClient from "../../../utils/HttpClient";

export default class VehicleStopsRepository extends HttpClient {
  constructor() {
    super(true);
  }

  getVehicleLastStops(vehicleId: number, queryParams?: string) {
    const tenantId = localStorage.getItem("tenantId");
    return this.instance.post(
      "tenants/" +
        tenantId +
        "/vehicles/" +
        vehicleId +
        "/lastTracks" +
        (queryParams ?? "")
    );
  }
}
