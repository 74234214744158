import HttpClient from "../../utils/HttpClient";

export default class EventsRepository extends HttpClient {
  constructor() {
    super(true);
  }

  getEvents() {
    return this.instance.get("/event_types?size=100");
  }

  public deleteEvent(vehicleId: number, timestamp: string, name: string) {
    const tenantId = localStorage.getItem("tenantId");
    return this.instance.delete(
      `/tenants/${tenantId}/vehicles/${vehicleId}/timestamps/${timestamp}/events/${name}`
    );
  }
}
