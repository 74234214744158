import HttpClient from "../../../utils/HttpClient";

export default class DriverTravelsRepository extends HttpClient {
  constructor() {
    super(true);
  }

  getDriverTravelsSummary(
    driverId: number,
    queryParams?: string,
    body?: { pagingState?: string }
  ) {
    const tenantId = localStorage.getItem("tenantId");
    return this.instance.post(
      "tenants/" +
        tenantId +
        "/drivers/" +
        driverId +
        "/travels/summary" +
        (queryParams ?? ""),
      body
    );
  }

  getDriverTravelsMacroSummary(driverId: number, queryParams?: string) {
    const tenantId = localStorage.getItem("tenantId");
    return this.instance.get(
      "tenants/" +
        tenantId +
        "/drivers/" +
        driverId +
        "/travels/macro-summary" +
        (queryParams ?? "")
    );
  }

  getDriverTravelSummary(
    driverId: number,
    routeId: number,
    queryParams?: string
  ) {
    const tenantId = localStorage.getItem("tenantId");
    return this.instance.get(
      "tenants/" +
        tenantId +
        "/drivers/" +
        driverId +
        "/travels/" +
        routeId +
        "/summary" +
        (queryParams ?? "")
    );
  }

  getDriverLastTracks(vehicleId: number, queryParams?: string) {
    const tenantId = localStorage.getItem("tenantId");
    return this.instance.post(
      "tenants/" +
        tenantId +
        "/drivers/" +
        vehicleId +
        "/lastTracks" +
        (queryParams ?? "")
    );
  }
}
