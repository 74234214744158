import React from "react";
import { ModalWrapper } from "../../ui/Modal/ModalWrapper";
import { ModalBody } from "../../ui/Modal/ModalBody";
import { ModalFooter } from "../../ui/Modal/ModalFooter";
import { t } from "i18next";
import "./PreviewAttachmentModal.css";

interface PreviewAttachmentModalProps {
  open: boolean;
  onClose: (e: any) => any;
  vehicleId: number;
  fileName: string;
}

export const PreviewAttachmentModal: React.FC<PreviewAttachmentModalProps> = ({
  open,
  onClose,
  vehicleId,
  fileName,
}) => {
  return (
    <div className="preview-attachment-modal">
      <ModalWrapper open={open} closeAction={onClose}>
        <ModalBody title={t("customModals.previewAttachment.title")}>
          <div className="preview-attachment-file-wrapper">
            <div className="preview-attachment-file-container">
              {(process.env.REACT_APP_BUCKET_MAINTENANCE_URL &&
                fileName.includes(".png")) ||
              fileName.includes(".jpeg") ||
              fileName.includes(".gif") ||
              fileName.includes(".jpg") ? (
                <img
                  id="file"
                  alt=""
                  src={
                    process.env.REACT_APP_BUCKET_MAINTENANCE_URL !== undefined
                      ? process.env.REACT_APP_BUCKET_MAINTENANCE_URL +
                        "Vehicles/" +
                        vehicleId +
                        "/" +
                        fileName
                      : ""
                  }
                />
              ) : (
                <embed
                  id="file"
                  type="application/pdf"
                  width="100%"
                  height="100%"
                  src={
                    process.env.REACT_APP_BUCKET_MAINTENANCE_URL !== undefined
                      ? process.env.REACT_APP_BUCKET_MAINTENANCE_URL +
                        "Vehicles/" +
                        vehicleId +
                        "/" +
                        fileName +
                        "#toolbar=0&navpanes=0&scrollbar=0"
                      : ""
                  }
                />
              )}
            </div>
          </div>
        </ModalBody>
        <ModalFooter
          secondaryLabel={t("customModals.previewAttachment.secondaryButton")}
          secondaryAction={() => {
            onClose("");
          }}
        />
      </ModalWrapper>
    </div>
  );
};
