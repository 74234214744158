import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { IconArrowToLeft } from "../Icon/Line/ArrowToLeft";
import { IconArrowToRight } from "../Icon/Line/ArrowToRight";
import "./Sidebar.css";

export const Sidebar = ({ expanded, expandedUpdated, children, ...props }) => {
  const [isExpanded, setIsExpanded] = useState(expanded);
  const classes = ["mn-sidebar"];
  const { t } = useTranslation();
  if (isExpanded) {
    classes.push("mn-sidebar--expanded");
  }

  useEffect(() => {
    setIsExpanded(expanded);
  }, [expanded]);

  const toggleSidebar = () => {
    const newValue = !isExpanded;
    setIsExpanded(newValue);
    if (expandedUpdated) {
      expandedUpdated(newValue);
    }
  };

  return (
    <>
      <div className={classes.join(" ")} {...props}>
        {isExpanded ? (
          <div className="mn-sidebar__content">{children}</div>
        ) : null}
        <div className="mn-sidebar__collapse">
          <div className="collapsed" onClick={toggleSidebar}>
            {isExpanded ? (
              <>
                <IconArrowToLeft size={14} color="--global-colors-ink-dark" />
                <span>{t("common.collapse")}</span>
              </>
            ) : (
              <>
                <IconArrowToRight size={14} color="--global-colors-ink-dark" />
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

Sidebar.propTypes = {
  /**
   * Status of the sidebar.
   */
  expanded: PropTypes.bool,
};

Sidebar.defaultProps = {
  expanded: true,
};
