import { t } from "i18next";
import { useContext, useEffect, useRef, useState } from "react";
import {} from "react-i18next";
import { useMatch, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { store } from "../../app/store";
import { GTFleetSuccessCodes } from "../../config/GTFleetSuccessCodes";
import { GTFleetErrorCodes } from "../../config/GTfleetErrorCodes";

import PageContent from "../../layout/PageContent";
import PageFilters from "../../layout/PageFilters";
import { ModalBody } from "../../ui/Modal/ModalBody";
import { ModalFooter } from "../../ui/Modal/ModalFooter";
import { ModalWrapper } from "../../ui/Modal/ModalWrapper";

import _ from "lodash";
import { PageCounter } from "../../ui/Table/PageCounter";
import { Table } from "../../ui/Table/Table";
import { ToastNotification } from "../../utils/ToastNotification";
import { getQueryString } from "../../utils/Utils";
import { Preferences } from "../users/preference/preferencesSlice";
import { UserPermissions } from "../users/privilege/privilegesSlice";
import UserContext from "../users/userContext";
import { NotificationsFilterBar } from "./NotificationFilterBar";
import { NotificationTableBuilder } from "./NotificationTableBuilder";
import "./Notifications.css";
import { notificationCategorySelectors } from "./notificationCategorySlice";
import {
  NotificationEvent,
  getFilteredNotificationsAsync,
  markAllAsReadAsync,
  markOneAsReadAsync,
  notificationEventsEmptyState,
  notificationEventsSelectors,
  selectNotificationEventsSlicePage,
  selectNotificationEventsSliceReasonCode,
  selectNotificationEventsSliceStatus,
  selectNotificationEventsSliceTotalElements,
  selectNotificationEventsSliceUnreadNotifications,
} from "./notificationSlice";

interface NotificationsListProps {
  permissions: UserPermissions;
}

interface TableData {
  columns: { label: string; field: string; sort: boolean }[] | undefined;
  rows: { [key: string]: any };
}
const tableData: TableData = {} as TableData;

const tableSchemaFix = [
  { id: 1, name: "reference" },
  { id: 2, name: "item" },
  { id: 3, name: "dateTime" },
  { id: 4, name: "type" },
];

export const NotificationsList: React.FC<NotificationsListProps> = ({
  permissions,
}) => {
  const navigate = useNavigate();
  const [preferencesContext]: [Preferences] = useContext(UserContext);
  const [queryParamsChanged, setQueryParamsChanged] = useState<boolean>(false);
  const notificationsEventSliceStatus = useAppSelector(
    selectNotificationEventsSliceStatus
  );
  const isLoading = notificationsEventSliceStatus === "loading";
  const [resetPage, setResetPage] = useState<boolean>(false);
  const isNotificationIdle = notificationsEventSliceStatus === "idle";
  const notificationSlicePages = useAppSelector(
    selectNotificationEventsSlicePage
  );

  const notificationSliceTotalElements = useAppSelector(
    selectNotificationEventsSliceTotalElements
  );

  const [queryParamsFromFilterBar, setQueryParamsFromFilterBar] =
    useState<string>("");

  const tableBuilderRef = useRef<NotificationTableBuilder>(
    new NotificationTableBuilder()
  );
  let tableBuilder = tableBuilderRef.current;
  let notifications: NotificationEvent[] = useAppSelector((state) =>
    notificationEventsSelectors.selectAll(state)
  );

  const notificationsEventSliceReasonCode = useAppSelector(
    selectNotificationEventsSliceReasonCode
  );
  const notificationRoute = useMatch("/dashboard/notifications/markallasread");
  let isNotificationRoute = notificationRoute !== null;

  const [idRead, setIdRead] = useState<number>(-1);

  const notificationsUnRead = useAppSelector(
    selectNotificationEventsSliceUnreadNotifications
  );

  useEffect(() => {
    document.title = t("navigation.userMenu.notifications");
    return function cleanUp() {
      notificationEventsEmptyState();
    };
  }, []);

  //#region Deadline toast notification
  useEffect(() => {
    if (
      notificationsEventSliceStatus === "failed" &&
      notificationsEventSliceReasonCode === ""
    ) {
      ToastNotification({
        toastId: "notificationEventError",
        status: "error",
        description: t("notificationEvent.toast.notificationError"),
      });
    }
    if (
      notificationsEventSliceStatus === "failed" &&
      notificationsEventSliceReasonCode ===
        GTFleetErrorCodes.INTERNAL_SERVER_ERROR
    ) {
      ToastNotification({
        toastId: "notificationEventError",
        status: "error",
        description: t("notificationEvent.toast.notificationError"),
      });
    }

    if (
      notificationsEventSliceStatus === "idle" &&
      notificationsEventSliceReasonCode === GTFleetSuccessCodes.PATCH
    ) {
      if (idRead === -1) {
        store.dispatch(
          getFilteredNotificationsAsync({
            queryParams: queryParamsFromFilterBar.replace("read=false", ""),
          })
        );
        ToastNotification({
          toastId: "notificationsReadSuccess",
          status: "success",
          description: t("notificationEvent.toast.notificationMarkSuccess"),
        });
      } else {
        setIdRead(-1);
        ToastNotification({
          toastId: "notificationsReadSuccess",
          status: "success",
          description: t("notificationEvent.toast.notificationUpdateSuccess"),
        });
      }
    }
  }, [notificationsEventSliceStatus, notificationsEventSliceReasonCode]);
  //#endregion Deadline toast notification

  useEffect(() => {
    if (queryParamsChanged && isNotificationIdle) {
      setQueryParamsChanged(false);
      setResetPage(true);
    } else {
      setResetPage(false);
    }
  }, [isNotificationIdle, queryParamsChanged]);

  useEffect(() => {
    if (!!queryParamsFromFilterBar) {
      setQueryParamsChanged(true);
    }
  }, [queryParamsFromFilterBar]);

  tableData.columns = tableBuilder.setColumns(tableSchemaFix);

  if (
    notificationsEventSliceStatus === "idle" &&
    tableData.columns &&
    tableData?.columns?.length > 0 &&
    notifications.length
  ) {
    tableData.rows = _.orderBy(notifications, "sendDate", "desc").map(
      (notificationEvent: NotificationEvent) => {
        let notificationCategory = notificationCategorySelectors.selectById(
          store.getState(),
          notificationEvent.notificationCategory
        );
        return tableBuilder.rowsBuilder(
          tableData.columns,
          { notificationEvent, notificationCategory },
          preferencesContext,
          navigate,
          permissions.notifications.write,
          setIdRead
        );
      }
    );
  } else if (!notifications.length) {
    tableData.rows = [];
  }

  useEffect(() => {
    if (idRead !== -1) {
      store.dispatch(
        markOneAsReadAsync({
          id: idRead,
        })
      );
    }
  }, [idRead]);

  return (
    <>
      <PageFilters>
        <div className="col col-16">
          <NotificationsFilterBar
            callback={setQueryParamsFromFilterBar}
            write={permissions.notifications.write}
          />
        </div>
      </PageFilters>
      <div className="notifications-Modal">
        <ModalWrapper
          open={isNotificationRoute}
          closeAction={() => {
            navigate({
              pathname: "/dashboard/notifications",
              search: queryParamsFromFilterBar,
            });
          }}
        >
          <ModalBody
            title={
              notificationsUnRead === 1
                ? t("notificationEvent.filterBar.titlemarkOneAsRead1") +
                  notificationsUnRead +
                  t("notificationEvent.filterBar.titlemarkOneAsRead2")
                : t("notificationEvent.filterBar.titlemarkAllAsRead1") +
                  notificationsUnRead +
                  t("notificationEvent.filterBar.titlemarkAllAsRead2")
            }
            desc={t(
              "notificationEvent.filterBar.descriptionModalmarkAllAsRead"
            )}
            isScrollable={false}
          />
          <ModalFooter
            primaryAction={() => {
              store.dispatch(markAllAsReadAsync());
              navigate({
                pathname: "/dashboard/notifications",
                search: queryParamsFromFilterBar,
              });
            }}
            primaryLabel={t("common.confirm")}
            secondaryAction={() => {
              navigate({
                pathname: "/dashboard/notifications",
                search: queryParamsFromFilterBar,
              });
            }}
            secondaryLabel={t("common.close")}
          />
        </ModalWrapper>
      </div>
      {tableData.rows?.length > 0 && (
        <PageContent>
          <div className="notification-table">
            <Table data={tableData}>
              <Table.Head hasTableSpace={true} />
              <Table.Body />
            </Table>
          </div>
          <div className="notification-pagination">
            <PageCounter
              isActionPerforming={isLoading}
              totalElements={notificationSliceTotalElements}
              resetPage={resetPage}
              disabled={!isNotificationIdle}
              numOfPages={notificationSlicePages}
              onClick={(id, currentPage) => {
                if (id !== currentPage) {
                  const pageAndSize = getQueryString({
                    page: id,
                    size: "10",
                  });
                  const finalQueryParams = queryParamsFromFilterBar
                    ? queryParamsFromFilterBar + pageAndSize.replace("?", "&")
                    : pageAndSize;
                  store.dispatch(
                    getFilteredNotificationsAsync({
                      queryParams: finalQueryParams,
                    })
                  );
                }
              }}
            />
          </div>
        </PageContent>
      )}
    </>
  );
};
