import dayjs from "dayjs";
import { t } from "i18next";
import _ from "lodash";
import { Link } from "react-router-dom";
import { TravelDetail } from "../../../features/report/TravelDetailTypes";
import { DropdownButton } from "../../../ui/Button/DropdownButton";
import { IconUser } from "../../../ui/Icon/Line/User";
import { Table } from "../../../ui/Table/Table";
import {
  AbstractTableBuilder,
  Row,
} from "../../../ui/Table/utils/AbstractTableBuilder";
import "../../../ui/Table/utils/TableBuilder.css";
import {
  ConvertTimeZone,
  tableFormatTime,
} from "../../../utils/DateAndTimeUtils";
import {
  getAddress,
  getCity,
  getQueryString,
  kmPerHToMilesPerH,
  kmToMiles,
  ltToGal,
  mtToKm,
  numberAnnotation,
} from "../../../utils/Utils";
import { Driver } from "../../driver/driversSlice";
import { FleetView } from "../../fleet/fleetViewsSlice";
import { Preferences } from "../../users/preference/preferencesSlice";
import { Vehicle } from "../../vehicle/vehiclesSlice";

export class VehicleTravelReportTableBuilder extends AbstractTableBuilder {
  /**
   * Given data, it builds a table row
   * @param vehicle
   * @returns
   */
  rowsBuilder = (
    columns: { label: string; field: string; sort: boolean }[] | undefined,
    entities: {
      travelDetail?: TravelDetail;
      vehicle?: Vehicle;
      drivers?: Driver[];
      fleetView?: FleetView;
      setCurrentSelectedTravel?: any;
      dateRange: string[];
    },
    preferencesContext: Preferences
  ) => {
    let row: Row = { render: {} as Row };

    let drivers: Driver[] = entities.drivers ?? [];

    let setCurrentSelectedTravel: any = entities.setCurrentSelectedTravel;

    let travelDetail: TravelDetail =
      entities.travelDetail ?? ({} as TravelDetail);

    let dateRange: string[] = entities.dateRange;

    columns?.forEach((column) => {
      switch (column.field) {
        case "travelDriver":
          row.render.travelDriver = (
            <>
              {drivers.map((driver) => {
                return (
                  <Link
                    key={driver?.id}
                    to={
                      driver?.id !== null && !_.isEmpty(dateRange)
                        ? `/reports/driver-travel-report/${driver?.id}` +
                          getQueryString({
                            startPeriod: dateRange[0],
                            endPeriod: dateRange[1],
                          })
                        : ""
                    }
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Table.TextIcon
                      text={driver.firstName + " " + driver.lastName}
                      icon={
                        <IconUser
                          size={14}
                          color="--global-colors-ink-light"
                          class="classTravelDriver"
                        />
                      }
                    />
                  </Link>
                );
              })}
            </>
          );
          break;
        case "startTime":
          let nonConvertedStartTime = undefined;
          nonConvertedStartTime =
            travelDetail?.firstPosition?.gpsPositionTimestamp;
          if (nonConvertedStartTime !== undefined) {
            const startTime = ConvertTimeZone(
              nonConvertedStartTime,
              preferencesContext.timeZone ?? undefined,
              preferencesContext.localeFormat ?? undefined
            ).split(" ");
            row.render.startTime = (
              <>
                <p className="reportMarked">{startTime[0]}</p>
                <p className="reportNormal">{startTime[1]}</p>
              </>
            );
          } else {
            row.render.startTime = (
              <>
                <p className="reportMarked">--:--:----</p>
                <p className="reportNormal">--:--:--</p>
              </>
            );
          }
          break;
        case "startPosition":
          let startPosition = undefined;
          startPosition = travelDetail?.firstPosition?.address;
          if (startPosition !== undefined) {
            row.render.startPosition = (
              <>
                <p className="reportMarked">{getCity(startPosition)}</p>
                <p className="reportNormal">{getAddress(startPosition)}</p>
              </>
            );
          } else {
            row.render.startPosition = "--";
          }
          break;
        case "endTime":
          let nonConvertedEndTime = undefined;
          nonConvertedEndTime =
            travelDetail?.lastPosition?.gpsPositionTimestamp;
          if (nonConvertedEndTime !== undefined) {
            const startTime = ConvertTimeZone(
              nonConvertedEndTime,
              preferencesContext.timeZone ?? undefined,
              preferencesContext.localeFormat ?? undefined
            ).split(" ");
            row.render.endTime = (
              <>
                <p className="reportMarked">{startTime[0]}</p>
                <p className="reportNormal">{startTime[1]}</p>
              </>
            );
          } else {
            row.render.endTime = (
              <>
                <p className="reportMarked">--:--:----</p>
                <p className="reportNormal">--:--:--</p>
              </>
            );
          }
          break;
        case "endPosition":
          let lastPosition = undefined;
          lastPosition = travelDetail?.lastPosition?.address;
          if (lastPosition !== undefined) {
            row.render.endPosition = (
              <>
                <p className="reportMarked">{getCity(lastPosition)}</p>
                <p className="reportNormal">{getAddress(lastPosition)}</p>
              </>
            );
          } else {
            row.render.endPosition = "--";
          }
          break;
        case "traveled":
          if (travelDetail?.traveled !== undefined) {
            row.render.traveled = preferencesContext.isMetric
              ? numberAnnotation(mtToKm(travelDetail.traveled))
              : numberAnnotation(kmToMiles(mtToKm(travelDetail.traveled)));
          } else {
            row.render.traveled = "--";
          }
          break;
        case "consumption":
          if (travelDetail?.consumption !== undefined) {
            row.render.consumption = preferencesContext.isMetric
              ? numberAnnotation(travelDetail?.consumption) + " lt"
              : numberAnnotation(ltToGal(travelDetail?.consumption)) + " gal";
          } else {
            row.render.consumption = "--";
          }
          break;
        case "avgSpeed":
          if (travelDetail?.avgSpeed !== undefined) {
            row.render.avgSpeed = preferencesContext.isMetric
              ? numberAnnotation(travelDetail.avgSpeed)
              : numberAnnotation(kmPerHToMilesPerH(travelDetail.avgSpeed));
          } else {
            row.render.avgSpeed = "--";
          }
          break;
        case "maxSpeed":
          if (travelDetail?.maxSpeed) {
            row.render.maxSpeed = preferencesContext.isMetric
              ? numberAnnotation(travelDetail.maxSpeed)
              : numberAnnotation(kmPerHToMilesPerH(travelDetail.maxSpeed));
          } else {
            row.render.maxSpeed = "--";
          }
          break;
        case "driveTime":
          let drivingTime = undefined;
          drivingTime = travelDetail?.drivingTime;
          if (drivingTime !== undefined) {
            row.render.driveTime = tableFormatTime(drivingTime, "seconds");
          } else {
            row.render.driveTime = "--";
          }
          break;
        case "parkingTime":
          let parkingTime = undefined;
          parkingTime = travelDetail?.parkingTime;
          if (parkingTime !== undefined) {
            row.render.parkingTime = tableFormatTime(parkingTime, "seconds");
          } else {
            row.render.parkingTime = "--";
          }
          break;
        case "idleTime":
          let idleTime = undefined;
          idleTime = travelDetail?.idleTime;
          if (idleTime !== undefined) {
            row.render.idleTime = tableFormatTime(idleTime, "seconds");
          } else {
            row.render.idleTime = "--";
          }
          break;
        //#end region Vehicle Travel Report

        default:
          break;
      }
    });
    const vehicleTravelsDropDownList = [
      {
        id: 0,
        title: t("common.viewOnMap"),
        onClick: () => {
          window.open(
            `/dashboard/vehicles/location-history/track/${travelDetail.routeId}` +
              getQueryString({
                firstPointDate: [
                  dayjs(
                    new Date(
                      travelDetail.firstPosition.gpsPositionTimestamp
                    ).setDate(
                      new Date(
                        travelDetail.firstPosition.gpsPositionTimestamp
                      ).getDate() - 1
                    )
                  ).format("YYYY/MM/DD HH:mm"),
                  dayjs(
                    new Date(
                      travelDetail.lastPosition.gpsPositionTimestamp
                    ).setDate(
                      new Date(
                        travelDetail.lastPosition.gpsPositionTimestamp
                      ).getDate() + 1
                    )
                  ).format("YYYY/MM/DD HH:mm"),
                ],
                routeStateTypeEnum: "TRACK",
                "vehicle.id": travelDetail.vehicleId ?? "",
              }),
            "_blank",
            "noopener,noreferrer"
          );
        },
      },
      {
        id: 1,
        title: t("common.viewTravelDetails"),
        onClick: () => {
          setCurrentSelectedTravel(travelDetail.routeId);
        },
      },
    ];

    row.render.action = (
      <DropdownButton
        aspect="ghost"
        size="small"
        list={vehicleTravelsDropDownList}
      />
    );
    return row;
  };
}
