import { t } from "i18next";
import _ from "lodash";
import { Tooltip } from "../../../ui/Forms/Tooltip";
import {
  AbstractTableBuilder,
  Row,
} from "../../../ui/Table/utils/AbstractTableBuilder";
import "../../../ui/Table/utils/TableBuilder.css";
import { ConvertTimeZone } from "../../../utils/DateAndTimeUtils";
import { getAddress, getCity } from "../../../utils/Utils";
import { Driver } from "../../driver/driversSlice";
import { FleetView } from "../../fleet/fleetViewsSlice";
import { Preferences } from "../../users/preference/preferencesSlice";
import { ReportUtilization } from "./ReportUtilizationSlice";

export class ReportUtilizationTableBuilder extends AbstractTableBuilder {
  rowsBuilder = (
    columns: { label: string; field: string; sort: boolean }[] | undefined,
    entities: {
      driver?: Driver;
      fleetView?: FleetView;
      reportUtilization?: ReportUtilization;
    },
    preferencesContext: Preferences,
    writePermission: boolean = false
  ) => {
    let row: Row = { render: {} as Row };

    let reportUtilization: ReportUtilization =
      entities.reportUtilization ?? ({} as ReportUtilization);

    columns &&
      columns.forEach((column) => {
        switch (column.field) {
          case "vehicle":
            row.render.vehicle = (
              <div>
                {reportUtilization.vehicleAlias &&
                reportUtilization.vehiclePlate ? (
                  <>
                    <p className="reportMarked">
                      {reportUtilization.vehicleAlias}
                    </p>
                    <p className="reportNormal">
                      {reportUtilization.vehiclePlate}
                    </p>
                  </>
                ) : (
                  <></>
                )}
              </div>
            );
            break;
          case "currentDriver":
            row.render.currentDriver = (
              <div>
                {reportUtilization.driverStart.id !==
                  reportUtilization.driverEnd.id &&
                reportUtilization.driverEnd.id !== undefined ? (
                  <>
                    <Tooltip />
                    <span
                      data-for="tooltip"
                      data-tip={
                        reportUtilization.driverEnd.firstName +
                        " " +
                        reportUtilization.driverEnd.lastName
                      }
                    >
                      <p className="reportUtilization">
                        {!_.isEmpty(reportUtilization.driverStart)
                          ? reportUtilization.driverStart.firstName +
                            " " +
                            reportUtilization.driverStart.lastName +
                            " + 1"
                          : t("common.na")}
                      </p>
                    </span>
                  </>
                ) : (
                  <>
                    <p className="reportUtilization">
                      {!_.isEmpty(reportUtilization.driverStart) &&
                      reportUtilization.driverStart.firstName !== null &&
                      reportUtilization.driverStart.lastName !== null
                        ? reportUtilization.driverStart.firstName +
                          " " +
                          reportUtilization.driverStart.lastName
                        : t("common.na")}
                    </p>
                  </>
                )}
              </div>
            );
            break;
          case "keyOnTimeStamp":
            if (
              reportUtilization.keyOnPositionTimestamp.gpsPositionTimestamp !==
              undefined
            ) {
              const keyOnPositionTimestamp = ConvertTimeZone(
                reportUtilization.keyOnPositionTimestamp.gpsPositionTimestamp,
                preferencesContext.timeZone,
                preferencesContext.localeFormat
              ).split(" ");
              row.render.keyOnPositionTimestamp = (
                <>
                  <p className="reportMarked">{keyOnPositionTimestamp[0]}</p>
                  <p className="reportNormal">{keyOnPositionTimestamp[1]}</p>
                </>
              );
            } else {
              row.render.keyOnPositionTimestamp = (
                <>
                  <p className="reportMarked">--:--:----</p>
                  <p className="reportNormal">--:--:--</p>
                </>
              );
            }
            break;
          case "keyOffTimeStamp":
            if (
              reportUtilization.keyOffPositionTimestamp.gpsPositionTimestamp !==
              undefined
            ) {
              const keyOffPositionTimestamp = ConvertTimeZone(
                reportUtilization.keyOffPositionTimestamp.gpsPositionTimestamp,
                preferencesContext.timeZone,
                preferencesContext.localeFormat
              ).split(" ");
              row.render.keyOffPositionTimestamp = (
                <>
                  <p className="reportMarked">{keyOffPositionTimestamp[0]}</p>
                  <p className="reportNormal">{keyOffPositionTimestamp[1]}</p>
                </>
              );
            } else {
              row.render.keyOffPositionTimestamp = (
                <>
                  <p className="reportMarked">--:--:----</p>
                  <p className="reportNormal">--:--:--</p>
                </>
              );
            }
            break;
          case "workingTime":
            if (reportUtilization.workingTime !== undefined) {
              row.render.workingTime = reportUtilization.workingTime;
            } else {
              row.render.workingTime = "--";
            }
            break;
          case "keyOffAddress":
            if (
              reportUtilization.keyOnPositionTimestamp.address !== undefined
            ) {
              row.render.endPosition =
                getAddress(reportUtilization.keyOnPositionTimestamp.address) ==
                  getAddress(
                    reportUtilization.keyOffPositionTimestamp.address
                  ) &&
                getCity(reportUtilization.keyOnPositionTimestamp.address) ==
                  getCity(reportUtilization.keyOffPositionTimestamp.address) ? (
                  <>
                    <div>
                      <p className="reportUtilization">
                        <span>{t("common.keyOn")}: </span>
                        {getAddress(
                          reportUtilization.keyOnPositionTimestamp.address
                        )}{" "}
                        {getCity(
                          reportUtilization.keyOnPositionTimestamp.address
                        )}
                      </p>
                      <p className="reportUtilization">
                        <span>{t("common.keyOff")}: </span>
                        {getAddress(
                          reportUtilization.keyOffPositionTimestamp.address
                        )}{" "}
                        {getCity(
                          reportUtilization.keyOffPositionTimestamp.address
                        )}
                      </p>
                    </div>
                  </>
                ) : (
                  <>
                    <div>
                      <p className="reportUtilization">
                        <span>{t("common.keyOn")}: </span>
                        {getAddress(
                          reportUtilization.keyOnPositionTimestamp.address
                        )}{" "}
                        {getCity(
                          reportUtilization.keyOnPositionTimestamp.address
                        )}
                      </p>
                      <p className="reportUtilization">
                        <span>{t("common.keyOff")}: </span>
                        {getAddress(
                          reportUtilization.keyOffPositionTimestamp.address
                        )}{" "}
                        {getCity(
                          reportUtilization.keyOffPositionTimestamp.address
                        )}
                      </p>
                    </div>
                  </>
                );
            } else {
              row.render.endPosition = "--";
            }
            break;
          case "keyOffAddress":
            break;
          default:
            break;
        }
      });

    return row;
  };
}
