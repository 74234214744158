import {
  createSlice,
  createEntityAdapter,
  PayloadAction,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { normalize } from "normalizr";
import { Config } from "../../config/Config";
import _ from "lodash";
import { vehicleFuelsConsumptionSchema } from "./vehicleNormalization";
import { GTFleetSuccessCodes } from "../../config/GTFleetSuccessCodes";
import { getErrorCodes } from "../../utils/Utils";
import VehiclesFuelsRepository from "./vehiclesFuelsRepository";
import { GTFleetErrorCodes } from "../../config/GTfleetErrorCodes";

//#region Type
export interface VehicleFuelsConsumption {
  vehicleId: number;
  summary: {
    avgConsumption: number;
    totalConsumption: number;
    zeroConsumption: number;
    refillsFrequences: number;
  };
  fuelsConsumption: { data: number; date: string }[];
  fuelsLevel: { data: number; date: string }[];
}
//#endregion Type

//#region API
export const getVehicleFuelsConsumptionAsync = createAsyncThunk(
  "vehicles/getVehicleFuelsConsumption",
  async (
    data: { queryParams: string; vehicleId: number },
    { rejectWithValue }
  ) => {
    try {
      const vehiclesFuelsRepository = new VehiclesFuelsRepository();
      vehiclesFuelsConsumptionEmptyState();
      const response = await vehiclesFuelsRepository.getFuelsConsumption(
        data.vehicleId,
        data.queryParams
      );
      // The value we return becomes the `fulfilled` action payload
      const fuels = _.get(response, Config.VEHICLE_FUELS_RESPONSE_PATH);
      const normalizedData = normalize(fuels, vehicleFuelsConsumptionSchema);
      return normalizedData.entities;
    } catch (error: any) {
      if (!error.response) throw error;
      return rejectWithValue(error?.response?.data);
    }
  }
);
//#endregion API

//#region Slice
const vehiclesFuelsConsumptionAdapter =
  createEntityAdapter<VehicleFuelsConsumption>({
    selectId: (vehicleFuelsConsumption) => vehicleFuelsConsumption.vehicleId,
  });

export const vehiclesFuelsConsumptionSlice = createSlice({
  name: "vehiclesFuelsConsumption",
  initialState: vehiclesFuelsConsumptionAdapter.getInitialState({
    status: "idle",
    reasonCode: "",
  }),
  reducers: {
    vehiclesFuelsConsumptionEmptyState: (state) => {
      vehiclesFuelsConsumptionAdapter.setAll(state, []);
      state.reasonCode = "";
      state.status = "idle";
    },
  },
  extraReducers: (builder) => {
    builder
      //#region Entity Reducers
      .addCase(
        getVehicleFuelsConsumptionAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          vehiclesFuelsConsumptionAdapter.setAll(
            state,
            action.payload.vehicleFuelsConsumption ?? []
          );
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.GET;
        }
      )
      .addCase(
        getVehicleFuelsConsumptionAsync.rejected,
        (state: any, action: PayloadAction<any>) => {
          state.status = "failed";
          let errorType: any = action.payload.message;
          if (action?.payload && action.payload?.status === 500) {
            errorType = GTFleetErrorCodes.INTERNAL_SERVER_ERROR;
          }
          state.reasonCode = getErrorCodes(errorType);
        }
      )
      .addCase(getVehicleFuelsConsumptionAsync.pending, (state: any) => {
        state.status = "loading";
      });
    //#endregion Entity Reducers
  },
});
//#endregion Slice

//#region Status
export const vehiclesFuelsConsumptionSelectors =
  vehiclesFuelsConsumptionAdapter.getSelectors<RootState>(
    (state) => state.vehiclesFuelsConsumption
  );

export const selectVehiclesFuelsConsumptionSliceStatus = (state: any) =>
  state.vehiclesFuelsConsumption.status;
export const selectVehiclesFuelsConsumptionSliceReasonCode = (state: any) =>
  state.vehiclesFuelsConsumption.reasonCode;
//#endregion Status

export const { vehiclesFuelsConsumptionEmptyState } =
  vehiclesFuelsConsumptionSlice.actions;

export default vehiclesFuelsConsumptionSlice.reducer;
