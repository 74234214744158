import HttpClient from "../../utils/HttpClient";

export default class FleetMaintenanceCostsRepository extends HttpClient {
  constructor() {
    super(true);
  }
  getFleetMaintenanceCosts(queryParams: string) {
    const tenantId = localStorage.getItem("tenantId");
    const url =
      "/tenants/" + tenantId + "/deadlines/costs" + (queryParams ?? "");
    return this.instance.get(url);
  }
}
