import _ from "lodash";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Location,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { store } from "../../app/store";
import { getQueryString } from "../../utils/Utils";
import { fleetViewsSelectors } from "../fleet/fleetViewsSlice";
import {
  Vehicle,
  getFilteredVehiclesDetailsAsync,
  selectVehiclesSliceReasonCode,
  selectVehiclesSliceStatus,
  vehiclesEmptyState,
  vehiclesSelectors,
} from "../vehicle/vehiclesSlice";
import "./FileArchiveModalFilterBar.css";

import dayjs from "dayjs";
import { t } from "i18next";
import { Button } from "../../ui/Button/Button";
import { DatePickerDropdown } from "../../ui/Forms/DatePickerDropdown";
import { DriversDropdown } from "../../ui/Forms/DriversDropdown";
import Form from "../../ui/Forms/Form";
import { VehiclesDropdown } from "../../ui/Forms/VehiclesDropdown";
import { IconCalendar } from "../../ui/Icon/Line/Calendar";
import { ToastNotification } from "../../utils/ToastNotification";
import {
  Driver,
  driversEmptyState,
  driversSelectors,
} from "../driver/driversSlice";
import {
  getFilteredDriversStatusAndDetailsAsync,
  selectDriverStatusSliceReasonCode,
  selectDriverStatusSliceStatus,
} from "../driver/driversStatusSlice";
import { Preferences } from "../users/preference/preferencesSlice";
import UserContext from "../users/userContext";

interface QueryParams {
  [paramName: string]: any;
}

interface FileArchiveModalFilterBarProps {
  api: (data: { queryParams: string | undefined; page: number }) => any;
  callback: (buildQueryParam: string) => any;
  queryParamsName: {
    vehicle?: string;
    driver?: string;
    date: [string, string];
  };
}

export const FileArchiveModalFilterBar: React.FC<FileArchiveModalFilterBarProps> =
  ({ api, callback, queryParamsName }) => {
    //#region Initialization
    const navigate = useNavigate();

    useEffect(() => {
      if (queryParamsName.vehicle) {
        store.dispatch(getFilteredDriversStatusAndDetailsAsync(""));
      }
      if (queryParamsName.driver) {
        store.dispatch(getFilteredVehiclesDetailsAsync(""));
      }
      return () => {
        if (queryParamsName.vehicle) {
          store.dispatch(vehiclesEmptyState());
        }
        if (queryParamsName.driver) {
          store.dispatch(driversEmptyState());
        }
      };
    }, []);

    const [preferencesContext]: [Preferences] = useContext(UserContext);

    //#endregion Initialization

    //#region Data
    const vehicles: Vehicle[] = useAppSelector(vehiclesSelectors.selectAll);
    const drivers: Driver[] = useAppSelector(driversSelectors.selectAll);
    //#endregion Data

    //#region Status

    const vehicleSliceStatus = useAppSelector(selectVehiclesSliceStatus);
    const vehicleSliceReasonCode = useAppSelector(
      selectVehiclesSliceReasonCode
    );

    const driverStatusSliceStatus = useAppSelector(
      selectDriverStatusSliceStatus
    );
    const driverStatusSliceReasonCode = useAppSelector(
      selectDriverStatusSliceReasonCode
    );

    useEffect(() => {
      if (vehicleSliceStatus === "failed" && vehicleSliceReasonCode === "") {
        ToastNotification({
          toastId: "networkError",
          status: "error",
          description: t("common.networkError"),
        });
      }
    }, [vehicleSliceStatus, vehicleSliceReasonCode]);

    useEffect(() => {
      if (
        driverStatusSliceStatus === "failed" &&
        driverStatusSliceReasonCode === ""
      ) {
        ToastNotification({
          toastId: "networkError",
          status: "error",
          description: t("common.networkError"),
        });
      }
    }, [driverStatusSliceStatus, driverStatusSliceReasonCode]);

    const isIdle =
      vehicleSliceStatus === "idle" && driverStatusSliceStatus === "idle";
    //#endregion Status

    //#region Search

    const dateMinus7Days = new Date();
    dateMinus7Days.setDate(new Date().getDate() - 6);
    const [initialDateValue, setInitialDateValue] = useState<Date[]>([
      dateMinus7Days,
      new Date(),
    ]);

    const [vehicleIds, setVehicleIds] = useState<number[]>([]);
    const [vehicleList, setVehicleList] = useState<Vehicle[]>([]);

    const [driverIds, setDriverIds] = useState<number[]>([]);
    const [driverList, setDriverList] = useState<Driver[]>([]);

    const queryParamsRef = useRef<QueryParams>({});
    let queryParams: QueryParams = queryParamsRef.current;

    const handleChanges = (
      params: Map<string, string[] | string>,
      executeQuery: boolean = true
    ): void => {
      params.forEach((value, key) => {
        if (value && value.length > 0) {
          queryParams[key] = value;
        } else {
          if (queryParams.hasOwnProperty(key)) {
            delete queryParams[key];
          }
        }
      });
      const stringifiedQueryParams = getQueryString(queryParams);
      navigate(`${window.location.pathname}${stringifiedQueryParams}`);
      if (executeQuery) {
        GenerateResult();
      }
    };

    const GenerateResult = () => {
      const stringifiedQueryParams = getQueryString(queryParams);
      GenerateResultWithParams(stringifiedQueryParams);
    };

    const GenerateResultWithParams = (queryString: string) => {
      store.dispatch(
        api({
          queryParams: queryString,
          page: 0,
        })
      );
      callback(queryString);
    };

    const [searchParams] = useSearchParams();
    const location: Location = useLocation();

    useEffect(() => {
      const map = new Map();
      const currentSearchParams =
        searchParams.toString() !== "" ? searchParams : null;
      if (!!currentSearchParams && _.isEmpty(queryParams)) {
        const startPeriod = currentSearchParams.get(queryParamsName.date[0]);
        const endPeriod = currentSearchParams.get(queryParamsName.date[1]);
        map.set(
          queryParamsName.date[0],
          startPeriod ?? dayjs().subtract(90, "days").format("YYYY-MM-DDTHH:mm")
        );
        setInitialDateValue((prev) => {
          let prevValue = prev;

          prevValue[0] = startPeriod ? new Date(startPeriod) : dateMinus7Days;

          return prevValue;
        });
        map.set(
          queryParamsName.date[1],
          endPeriod ?? dayjs().format("YYYY-MM-DDTHH:mm")
        );
        setInitialDateValue((prev) => {
          let prevValue = prev;

          prevValue[1] = endPeriod ? new Date(endPeriod) : new Date();

          return prevValue;
        });
        if (queryParamsName.driver) {
          const driverIds = currentSearchParams.getAll(queryParamsName.driver);
          if (driverIds) {
            if (driverIds?.length > 0) {
              setDriverIds(driverIds.map((driverId) => parseInt(driverId)));
              map.set("driverIds", driverIds);
            }
          }
        }
        if (queryParamsName.vehicle) {
          const vehicleIds = currentSearchParams.getAll(
            queryParamsName.vehicle
          );
          if (vehicleIds) {
            if (vehicleIds?.length > 0) {
              setVehicleIds(vehicleIds.map((entityId) => parseInt(entityId)));
              map.set(queryParamsName.vehicle, vehicleIds);
            }
          }
        }
      } else if (_.isEmpty(queryParams)) {
        map.set(
          queryParamsName.date[0],
          dayjs().subtract(90, "days").format("YYYY-MM-DDTHH:mm")
        );
        map.set(queryParamsName.date[1], dayjs().format("YYYY-MM-DDTHH:mm"));
      }
      if (map.size > 0) {
        handleChanges(map);
      }
    }, [location]);

    /**
     * This useEffect takes the vehicles identifiers retrieved from the URL and based
     * on the downloaded vehicles details builds the vehicle list (vehicleList hook).
     */
    useEffect(() => {
      if (vehicleIds && vehicleIds.length > 0) {
        if (
          vehicleSliceStatus === "idle" &&
          vehicles.length > 0 &&
          vehicleList.length === 0
        ) {
          const selectedVehicles: Vehicle[] = [];
          vehicleIds.forEach((vehicleId: number) => {
            const selectedVehicle =
              vehiclesSelectors.selectById(store.getState(), vehicleId ?? -1) ??
              ({} as Vehicle);
            selectedVehicle &&
              !_.isEmpty(selectedVehicle) &&
              selectedVehicles.push(selectedVehicle);
          });
          setVehicleList(selectedVehicles);
        }
      }
    }, [vehicleIds, vehicleSliceStatus]);

    /**
     * This useEffect takes the drivers identifiers retrieved from the URL and based
     * on the downloaded drivers details builds the driver list (driverList hook).
     */
    useEffect(() => {
      if (driverIds && driverIds.length > 0) {
        if (
          driverStatusSliceStatus === "idle" &&
          drivers.length > 0 &&
          driverList.length === 0
        ) {
          const selectedDrivers: Driver[] = [];
          driverIds.forEach((driverId: number) => {
            const selectedDriver =
              driversSelectors.selectById(store.getState(), driverId ?? -1) ??
              ({} as Driver);
            selectedDriver &&
              !_.isEmpty(selectedDriver) &&
              selectedDrivers.push(selectedDriver);
          });
          setDriverList(selectedDrivers);
        }
      }
    }, [driverIds, driverStatusSliceStatus]);

    function resetParams() {
      const map = new Map();
      map.set("entityId", null);
      handleChanges(map);
    }
    //#endregion Search

    //#region Vehicle
    let vehiclesGroupByFleet = vehicles.reduce(
      (group: any, vehicle: Vehicle) => {
        const { fleet } = vehicle;
        const fleetName = fleetViewsSelectors.selectById(
          store.getState(),
          fleet
        )?.name;
        if (fleetName) {
          group[fleetName] = group[fleetName] ?? [];
          group[fleetName].push(vehicle);
        }
        return group;
      },
      {}
    );

    let vehiclesfleetNames = Object.keys(vehiclesGroupByFleet);

    let vehiclesOptions = vehiclesfleetNames?.map(
      (vehicleFleetName: string) => ({
        label: vehicleFleetName,
        hasCheckbox: true,
        hasDropdown: true,
        hasCount: true,
        vehicles: vehiclesGroupByFleet[vehicleFleetName],
      })
    );

    //#endregion Vehicle

    //#region Driver
    let driversGroupByFleet = drivers.reduce((group: any, driver: Driver) => {
      const { fleet } = driver;
      const fleetName = fleetViewsSelectors.selectById(
        store.getState(),
        fleet
      )?.name;
      if (fleetName) {
        group[fleetName] = group[fleetName] ?? [];
        group[fleetName].push(driver);
      }
      return group;
    }, {});

    let driversFleetNames = Object.keys(driversGroupByFleet);

    let driversOptions = driversFleetNames?.map((driverFleetName: string) => ({
      label: driverFleetName,
      hasCheckbox: true,
      hasDropdown: true,
      hasCount: true,
      drivers: driversGroupByFleet[driverFleetName],
    }));
    //#endregion Driver

    return (
      <div className="archive-file-filterbar-row">
        <Form>
          <DatePickerDropdown
            setDate={(val) => {
              const map = new Map();
              let startPeriod = "";
              let endPeriod = "";
              if (_.isArray(val)) {
                if (val.length === 1) {
                  endPeriod =
                    typeof val[0] === "string"
                      ? val[0]
                      : val[0].format("YYYY-MM-DDTHH:mm");
                  const startPeriodDate = new Date(endPeriod);
                  startPeriodDate.setHours(0, 0, 0, 0);
                  startPeriod =
                    dayjs(startPeriodDate).format("YYYY-MM-DDTHH:mm");
                } else {
                  startPeriod =
                    typeof val[0] === "string"
                      ? val[0]
                      : val[0].format("YYYY-MM-DDTHH:mm");
                  endPeriod = val[1]?.format("YYYY-MM-DDTHH:mm") ?? "";
                }
              }
              map.set(queryParamsName.date[0], startPeriod);
              map.set(queryParamsName.date[1], endPeriod);
              handleChanges(map, false);
            }}
            hasTime={false}
            localeFormat={preferencesContext.localeFormat ?? "DD / MM / YYYY"}
            language={preferencesContext.language ?? "it"}
            icon={<IconCalendar size={12} color="--global-colors-ink-light" />}
            initialValue={initialDateValue.length ? initialDateValue : []}
            defaultValue={initialDateValue[1]}
            dateRange={[dayjs().subtract(1, "years"), dayjs()]} //From today to past 1 year
            limitDaysRange={90}
          />
        </Form>
        {queryParamsName.driver ? (
          <div className="archive-file-filter-drivers-dropdown">
            <DriversDropdown
              loading={driverStatusSliceStatus === "loading"}
              options={driversOptions}
              driversLimit={20}
              getValueSelected={(values: Driver[]) => {
                if (!_.isEqual(values, driverList)) {
                  const map = new Map();
                  if (values && values?.length > 0) {
                    map.set(
                      queryParamsName.driver,
                      values.map((driver) => driver.id)
                    );
                    setDriverList(values);
                  } else {
                    map.set(queryParamsName.driver, []);
                    setDriverList([]);
                  }
                  handleChanges(map, false);
                }
              }}
              valueSelected={driverList}
            />
          </div>
        ) : undefined}
        {queryParamsName.vehicle ? (
          <div className="archive-file-filter-vehicles-dropdown">
            <VehiclesDropdown
              loading={vehicleSliceStatus === "loading"}
              options={vehiclesOptions}
              vehiclesLimit={20}
              getValueSelected={(values: Vehicle[]) => {
                if (!_.isEqual(values, vehicleList)) {
                  const map = new Map();
                  if (values && values?.length > 0) {
                    map.set(
                      queryParamsName.vehicle,
                      values.map((vehicle) => vehicle.id)
                    );
                    setVehicleList(values);
                  } else {
                    map.set(queryParamsName.vehicle, []);
                    setVehicleList([]);
                  }
                  handleChanges(map, false);
                }
              }}
              valueSelected={vehicleList}
            />
          </div>
        ) : undefined}
        <div className="archive-file-filter-btn-generate">
          <Button
            aspect="primary"
            size="small"
            label={t("common.search")}
            onClick={() => {
              GenerateResult();
            }}
            isLoading={!isIdle}
          />
        </div>
      </div>
    );
  };
