import React from "react";
import PropTypes from "prop-types";
import "../IconFont/icons.css";

export const IconExternalLink = ({ size, ...props }) => (
  <div
    className="svg"
    onClick={() => {
      if (props.onClick) {
        props.onClick(props?.index);
      }
    }}
  >
    <span
      style={
        props.color
          ? { fontSize: `${size}px`, color: `var(${props.color})` }
          : { fontSize: `${size}px` }
      }
    >
      &#xe82b;
    </span>
  </div>
);

IconExternalLink.propTypes = {
  /**
   * Size of the icon.
   */
  size: PropTypes.number,
  /**
   * Color of the icon.
   */
  color: PropTypes.string,
};

IconExternalLink.defaultProps = {
  color: "--global-colors-ui-primary",
  size: 24,
};
