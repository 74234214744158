import i18next from "i18next";
import { Fragment } from "react";
import { IconArrowRight } from "../Icon/Line/ArrowRight";

export const TopBarBreadcrumb = ({ breadcrumbs, ...props }) => {
  // method to convert text from path to camelcase (es. location-history -> locationHistory) to match translations
  const textToCamelCase = (breadcrumb) => {
    if (breadcrumb) {
      return i18next.t(
        `breadcrumbs.${breadcrumb
          .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, idx) {
            return idx === 0 ? word.toLowerCase() : word.toUpperCase();
          })
          .replace(/\s+/g, "")}`
      );
    }
  };

  // method to match translations for each node of breadcrumb
  const breadcrumbTranslation = (breadcrumb, index) => {
    if (
      !(
        !breadcrumb.key.includes("vehicles") ||
        !breadcrumb.key.includes("drivers") ||
        !breadcrumb.key.includes("users")
      ) &&
      index !== 0
    )
      textToCamelCase(breadcrumb.props.children);
    else if (
      textToCamelCase(breadcrumb.props.children) === undefined ||
      textToCamelCase(breadcrumb.props.children).includes("breadcrumbs.")
    ) {
      return breadcrumb;
    } else {
      return textToCamelCase(breadcrumb.props.children);
    }
  };

  return (
    <div className="mn-top-bar__title" {...props}>
      <ul>
        {breadcrumbs &&
          breadcrumbs.map(({ breadcrumb }, index) => (
            <Fragment key={index}>
              {breadcrumbTranslation(breadcrumb, index)}
              {index !== breadcrumbs.length - 1 && (
                <li
                  key={`separator-${index}`}
                  className="mn-top-bar__title__separator"
                >
                  <IconArrowRight color="--global-colors-ink-light" size={8} />
                </li>
              )}
            </Fragment>
          ))}
      </ul>
    </div>
  );
};
