import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Card } from "../Card/Card";
import { RadialTime } from "../Chart/RadialTime";
import { UserBox } from "../Chat/UserBox";
import { Dropdown } from "../Dropdown/Dropdown";
import { IconVehicle } from "../Icon/Line/Vehicle";
import { LabelText } from "../LabelText/LabelText";
import { ThumbProfile } from "../ThumbProfile/ThumbProfile";
import "./HoursService.css";

export const HoursService = ({ slots }) => {
  const { t } = useTranslation();

  const [value, setValue] = useState(slots[0]);
  const [slot, setSlot] = useState(slots);

  useEffect(() => {
    setValue(slots.find((o) => o.label === slot[0].label));
  }, [slot, slots]);

  return (
    <Card
      title={t("fleetControl.hoursOfService.title")}
      className="hs-vehicle-utilization"
    >
      {value && value.driver && (
        <div className="hs-header">
          <div>
            <UserBox
              title={value && value.driver}
              icon={<ThumbProfile size="small" />}
              status="ONLINE"
              size="regular"
              iconSubTitle={
                <IconVehicle size={8} color="--global-colors-ink-light" />
              }
              subTitle="A8 Volvo FH"
              disableClick={true}
            />
          </div>
          <div className="hs-slot">
            <Dropdown
              options={slots}
              itemAttribute="label"
              placeholder={slots[0].label}
              onChange={setSlot}
              size="small"
            />
          </div>
        </div>
      )}
      <div>
        {value && value.vehicleType === "Truck" ? (
          <div className="hs-table-driver-inner">
            <div className="hs-table-driver-summary">
              <div>
                <div className="hs-table-driver-cycle">
                  <LabelText
                    label={t(
                      "fleetControl.hoursOfService.labelText.todayCicle"
                    )}
                    text="14hr 0min 0sec"
                  />
                </div>
                <div className="hs-table-driver-tomorrow">
                  <LabelText
                    label={t(
                      "fleetControl.hoursOfService.labelText.hoursAvailableTomorrow"
                    )}
                    text="14hr 0min 0 sec"
                  />
                  {value && value.driver ? (
                    <div className="hs-table-driver-last">
                      {t("fleetControl.hoursOfService.lastUpdate")}{" "}
                      {value && value.lastUpdate}
                    </div>
                  ) : (
                    <div className="hs-table-driver-last-no-driver">
                      {t("fleetControl.hoursOfService.lastUpdate")}{" "}
                      {value && value.lastUpdate}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="hs-table-driver-graphs">
              <div
                style={{ marginTop: value && value.driver ? "4.2rem" : "0" }}
              >
                <RadialTime
                  className="hs-table-driver-graph-driving"
                  title={t(
                    "fleetControl.hoursOfService.radialTitle.driving"
                  ).toUpperCase()}
                  data={value && value.driving}
                  size={{ width: "106", height: "106" }}
                  color="--global-colors-feedback-success"
                />
                {value && value.nextBreak ? (
                  <RadialTime
                    title={t(
                      "fleetControl.hoursOfService.radialTitle.nextBreak"
                    ).toUpperCase()}
                    data={value && value.nextBreak}
                    threshold="00:10"
                    max="2:00"
                    size={{ width: "106", height: "106" }}
                    color="--global-colors-radialbar-break"
                  />
                ) : (
                  <RadialTime
                    title={t(
                      "fleetControl.hoursOfService.radialTitle.break"
                    ).toUpperCase()}
                    data={value && value.break}
                    threshold="00:10"
                    max="00:45"
                    size={{ width: "106", height: "106" }}
                    color="--global-colors-radialbar-break"
                  />
                )}
              </div>
              <div
                className="hs-table-driver-graph-available"
                style={{ marginBottom: value && value.driver ? "2.6rem" : "0" }}
              >
                <RadialTime
                  title={t(
                    "fleetControl.hoursOfService.radialTitle.available"
                  ).toUpperCase()}
                  data={value && value.available}
                  threshold="1:30"
                  max="8:00"
                  size={{ width: "106", height: "106" }}
                  color="--global-colors-ui-secondary"
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="hs-table-driver-inner">
            <div>
              <div className="hs-driver-detail-lower-row-second-type">
                <div className="hs-driver-green-row" />
                <div className="hs-chart">
                  <span className="hs-chart__label">
                    {t(
                      "fleetControl.hoursOfService.drivingSince"
                    ).toUpperCase()}
                  </span>
                  <span className="hs-chart__data">
                    {value && value.drivingSince}
                  </span>
                </div>
                <div className="hs-driver-orange-row" />
                <div className="hs-chart">
                  <span className="hs-chart__label">
                    {t("fleetControl.hoursOfService.totalToday").toUpperCase()}
                  </span>
                  <span className="hs-chart__data">
                    {value && value.totalToday}
                  </span>
                </div>
                <div className="hs-driver-sky-blue-row" />
                <div className="hs-chart">
                  <span className="hs-chart__label">
                    {t("fleetControl.hoursOfService.kmTraveled").toUpperCase()}
                  </span>
                  <span className="hs-chart__data">
                    {value && value.kmTraveled}
                  </span>
                </div>
              </div>
              <div className="hs-table-driver-last-no-truck">
                {t("fleetControl.hoursOfService.lastUpdate")}{" "}
                {value && value.lastUpdate}
              </div>
            </div>
          </div>
        )}
      </div>
    </Card>
  );
};

HoursService.propTypes = {
  slots: PropTypes.array.isRequired,
};
