import { useContext } from "react";
import { DatePickerContext } from "./DatePickerContext";
import { useTranslation } from "react-i18next";

export const YearPicker = () => {
    const picker = useContext(DatePickerContext);
    const { t } = useTranslation();
    var yearsList = countYears();

    function countYears() {
        if (picker.dateRange) {
            let startYear = picker.dateRange[0].year(), years = []
            let endYear = picker.dateRange[1].year()
            while (startYear <= endYear) {
                years.push(startYear++)
            }
            return years.sort((a, b) => { return b - a });
        } else {
            let currentYear = new Date().getFullYear(), years = [];
            let startYear = currentYear - 100;
            while (startYear <= currentYear) {
                years.push(startYear++);
            }
            return years.sort((a, b) => { return b - a });

        }
    }

    const yearLi = document.getElementById(picker.value.year());
    yearLi && yearLi.scrollIntoView({ block: "nearest" });

    return (
        <div className="right">
            <h4>{t("common.datePicker.yearTitle")}</h4>
            <ul>
                {yearsList.map((year, index) => {
                    return (
                        <li
                            id={year}
                            key={index}
                            onClick={() => { picker.setSelectedYear(year); }}
                            className={(picker.value.year() === year) ? "year-selected" : ""}>
                            {year}
                        </li>
                    )
                })}
            </ul>
        </div>
    );
}