import { useDrop, DndProvider, DropTargetMonitor } from "react-dnd";
import { IconAdd } from "../Icon/Line/Add";

import "./DropArea.css";
import { useCallback, useRef, useState } from "react";
import { HTML5Backend } from "react-dnd-html5-backend";
import { NativeTypes } from "react-dnd-html5-backend";
import "./DropFile.css";

import { useTranslation } from "react-i18next";

const TargetBox = (props: any) => {
  const inputFile = useRef(null);
  const { onDrop } = props;
  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: [NativeTypes.FILE],
      drop(item: { files: any[] }) {
        if (onDrop) {
          onDrop(item);
        }
      },
      canDrop(item: any) {
        console.log("canDrop", item.files, item.items);
        return true;
      },
      hover(item: any) {
        console.log("hover", item.files, item.items);
      },
      collect: (monitor: DropTargetMonitor) => {
        const item = monitor.getItem() as any;
        if (item) {
          console.log("collect", item.files, item.items);
        }

        return {
          isOver: monitor.isOver(),
          canDrop: monitor.canDrop(),
        };
      },
    }),
    [props]
  );

  const change = (event: any) => {
    onDrop([event.target.files[0]]);
  };

  const click = (ev: any) => {
    ev.target.value = null;
  };

  const { t } = useTranslation();

  const isActive = canDrop && isOver;
  const className = isActive
    ? "dropdragfile mn-mw-drop-area--active"
    : "dropdragfile mn-mw-drop-area--not-active";

  return (
    <>
      <div
        ref={drop}
        className={className}
        onClick={() => {
          document.getElementById("inputFile")?.click();
        }}
      >
        <IconAdd
          size={61}
          className={""}
          color={
            isActive ? "--global-colors-ui-white" : "--global-colors-ink-light"
          }
        />
        <p>{t("customModals.uploadImage.dragDrop")}</p>
        <p>{t("customModals.uploadImage.typeImage")}</p>
      </div>
      <input
        ref={inputFile}
        id="inputFile"
        type="file"
        autoComplete="off"
        accept="image/png, image/jpeg, image/gif"
        style={{ display: "none" }}
        onChange={change}
        onClick={click}
      ></input>
    </>
  );
};

interface DropFileComponentProps {
  getDroppedFiles: (e: any) => any;
}
const DropFileComponent = ({ getDroppedFiles }: DropFileComponentProps) => {
  const [, setDroppedFiles] = useState([]);
  const handleFileDrop = useCallback(
    (item) => {
      let files;
      if (item && item.files) {
        files = item.files;
      } else {
        files = item;
      }
      if (
        files[0].size <= 2097152 &&
        ["image/png", "image/jpeg", "image/gif"].includes(files[0].type)
      ) {
        setDroppedFiles(files);
        getDroppedFiles(files);
      } else if (files[0].size > 2097152) {
        getDroppedFiles("errorSizeFile");
      } else {
        getDroppedFiles("errorTypeImage");
      }
    },
    [setDroppedFiles]
  );

  return (
    <>
      <TargetBox onDrop={handleFileDrop} />
    </>
  );
};

interface DropFileProps {
  droppedFiles: (e: any) => any;
}
export const DropFile = ({ droppedFiles }: DropFileProps) => {
  return (
    <DndProvider backend={HTML5Backend}>
      <DropFileComponent
        getDroppedFiles={(drp: any) => {
          droppedFiles(drp);
        }}
      />
    </DndProvider>
  );
};
