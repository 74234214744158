import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import _ from "lodash";
import { normalize } from "normalizr";
import { RootState } from "../../app/store";
import { Config } from "../../config/Config";
import { GTFleetSuccessCodes } from "../../config/GTFleetSuccessCodes";
import { tachographFilesSchema } from "./tachographFileNormalization";
import TachographFileRepository from "./tachographFilesRepository";

//#region Type
export interface TachographFile {
  type: TachographFileType;
  entityId: number;
  timestamp: Date;
  path: string;
  fileName: string;
}

export const tachographFileTypeValues = {
  VEHICLE: "VEHICLE",
  DRIVER: "DRIVER",
  REPORT_DAILY_ACTIVITY: "REPORT_DAILY_ACTIVITY",
  REPORT_INFRINGEMENT_LETTER: "REPORT_INFRINGEMENT_LETTER",
};
export type TachographFileType = keyof typeof tachographFileTypeValues;

type PagingStateType = {
  pagingState?: string;
};

type PagingStateMapping = {
  page: number;
  pagingState: string;
};
//#endregion Type

//#region API

export const getTachographFilesAsync = createAsyncThunk(
  "tachographFile/getTachographFiles",
  async (
    data: {
      type: TachographFileType;
      entityId: number;
      queryParams?: string;
      page: number;
    },
    thunkAPI
  ) => {
    const tachographFileRepository = new TachographFileRepository();
    const pagingStates: PagingStateMapping[] =
      selectTachographFilesSlicePagingStates(thunkAPI.getState());
    const pagingStateMap = pagingStates.find((x) => x.page === data.page);
    const pagingState = {
      pagingState: pagingStateMap?.pagingState,
    } as PagingStateType;
    const response = await tachographFileRepository.getTachographFiles(
      data.type,
      data.entityId,
      data.queryParams,
      pagingState
    );
    // The value we return becomes the `fulfilled` action payload
    const tachographFiles = _.get(
      response,
      Config.TACHOGRAPH_FILES_RESPONSE_PATH
    );
    const currentPagingState = _.get(
      response,
      Config.TACHOGRAPH_FILES_PAGING_STATE_RESPONSE_PATH
    );
    thunkAPI.dispatch(
      setNewPagingState({
        page: data.page + 1,
        pagingState: currentPagingState,
      })
    );
    const normalizedData = normalize(tachographFiles, tachographFilesSchema);
    return normalizedData.entities;
  }
);

const getTachographFilesReportAsync = async (
  data: {
    type: TachographFileType;
    queryParams?: string;
    page: number;
  },
  thunkAPI: any
) => {
  const tachographFileRepository = new TachographFileRepository();
  const pagingStates: PagingStateMapping[] =
    selectTachographFilesSlicePagingStates(thunkAPI.getState());
  const pagingStateMap = pagingStates.find((x) => x.page === data.page);
  const pagingState = {
    pagingState: pagingStateMap?.pagingState,
  } as PagingStateType;
  const response = await tachographFileRepository.getTachographFilesReport(
    data.type,
    data.queryParams,
    pagingState
  );
  // The value we return becomes the `fulfilled` action payload
  const tachographFilesStatus = _.get(
    response,
    Config.TACHOGRAPH_FILES_REPORT_RESPONSE_PATH
  );
  const currentPagingState = _.get(
    response,
    Config.TACHOGRAPH_FILES_REPORT_PAGING_STATE_RESPONSE_PATH
  );
  thunkAPI.dispatch(
    setNewPagingState({
      page: data.page + 1,
      pagingState: currentPagingState,
    })
  );
  const normalizedData = normalize(
    tachographFilesStatus,
    tachographFilesSchema
  );
  return normalizedData.entities;
};

export const getTachographFilesActivityReportAsync = createAsyncThunk(
  "tachographFile/getTachographFilesActivityReport",
  async (data: { queryParams?: string; page: number }, thunkAPI) => {
    return getTachographFilesReportAsync(
      {
        type: "REPORT_DAILY_ACTIVITY",
        queryParams: data.queryParams,
        page: data.page,
      },
      thunkAPI
    );
  }
);
export const getTachographFilesInfringementLetterReportAsync = createAsyncThunk(
  "tachographFile/getTachographFilesInfringementLetterReport",
  async (data: { queryParams?: string; page: number }, thunkAPI) => {
    return getTachographFilesReportAsync(
      {
        type: "REPORT_INFRINGEMENT_LETTER",
        queryParams: data.queryParams,
        page: data.page,
      },
      thunkAPI
    );
  }
);
//#endregion API

//#region Custom Functions
function setFilteredData(state: any, action: PayloadAction<any>) {
  action.payload.tachographFile
    ? tachographFilesAdapter.setAll(state, action.payload.tachographFile)
    : tachographFilesAdapter.setAll(state, []);
}
//#endregiorn Custom Functions

//#region Slice
const tachographFilesAdapter = createEntityAdapter<TachographFile>({
  selectId: (tachographFile) => tachographFile.fileName,
  sortComparer: (a, b) => a.fileName.localeCompare(b.fileName),
});

export const tachographFileSlice = createSlice({
  name: "tachographFiles",
  initialState: tachographFilesAdapter.getInitialState({
    status: "idle",
    reasonCode: "",
    totalPages: 0,
    pagingStates: [] as PagingStateMapping[],
  }),
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setNumberOfPages: (state, action: PayloadAction<number>) => {
      state.totalPages = action.payload;
    },
    setNewPagingState: (
      state,
      action: PayloadAction<{
        page: number;
        pagingState: string;
      }>
    ) => {
      const pagingStates = state.pagingStates;
      const newPagingState = [...pagingStates];
      const pagingStateMap = newPagingState.find(
        (x) => x.page === action.payload.page
      );
      if (!pagingStateMap) {
        newPagingState.push({
          page: action.payload.page,
          pagingState: action.payload.pagingState,
        } as PagingStateMapping);
      }
      state.pagingStates = newPagingState;
    },
    tachographFileEmptyState: (state) => {
      tachographFilesAdapter.setAll(state, []);
      state.status = "idle";
      state.reasonCode = "";
      state.pagingStates = [] as PagingStateMapping[];
      state.totalPages = 0;
    },
  },

  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      //#region Entity Reducers
      .addCase(
        getTachographFilesAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          setFilteredData(state, action);
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.GET;
        }
      )
      .addCase(getTachographFilesAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(getTachographFilesAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = "";
      })
      .addCase(
        getTachographFilesActivityReportAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          setFilteredData(state, action);
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.POST;
        }
      )
      .addCase(getTachographFilesActivityReportAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(getTachographFilesActivityReportAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = "";
      })
      .addCase(
        getTachographFilesInfringementLetterReportAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          setFilteredData(state, action);
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.POST;
        }
      )
      .addCase(
        getTachographFilesInfringementLetterReportAsync.pending,
        (state: any) => {
          state.status = "loading";
        }
      )
      .addCase(
        getTachographFilesInfringementLetterReportAsync.rejected,
        (state: any) => {
          state.status = "failed";
          state.reasonCode = "";
        }
      );
    //#endregion Entity Reducers
    //#endregion Entity Reducers
  },
});
//#endregion Slice

//#region Status
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const tachographFilesSelectors =
  tachographFilesAdapter.getSelectors<RootState>(
    (state) => state.tachographFiles
  );

export const selectTachographFilesSliceStatus = (state: any) =>
  state.tachographFiles.status;
export const selectTachographFilesSliceReasonCode = (state: any) =>
  state.tachographFiles.reasonCode;
export const selectTachographFilesSlicePage = (state: any) =>
  state.tachographFiles.totalPages;
export const selectTachographFilesSlicePagingStates = (state: any) =>
  state.tachographFiles.pagingStates;
//#endregion Status
export const { setNumberOfPages, tachographFileEmptyState, setNewPagingState } =
  tachographFileSlice.actions;

export default tachographFileSlice.reducer;
