import HttpClient from "../../utils/HttpClient";
import { Tenant } from "./tenantsSlice";

export default class TenantsRepository extends HttpClient {
  constructor() {
    super(true);
  }

  getTenant() {
    const id = localStorage.getItem("tenantId");
    return this.instance.get("tenants/" + id);
  }

  partiallyUpdateTenant(tenantId: number, tenant: Tenant) {
    return this.instance.patch("tenants/" + tenantId, tenant);
  }
}
