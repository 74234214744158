import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { GTFleetErrorCodes } from "../../config/GTfleetErrorCodes";
import PageContent from "../../layout/PageContent";
import PageFilters from "../../layout/PageFilters";
import { Button } from "../../ui/Button/Button";
import Form from "../../ui/Forms/Form";
import TextField from "../../ui/Forms/TextField";
import { IconEye } from "../../ui/Icon/Line/Eye";
import { IconEyeSlash } from "../../ui/Icon/Line/EyeSlash";
import { Check } from "../../ui/Indicators/Check";
import { ToastNotification } from "../../utils/ToastNotification";
import "./ChangePassword.css";
import UsersRepository from "./usersRepository";

function ChangePassword() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [showOldPassword, setShowOldPassword] = useState(true);
  const [showNewPassword, setShowNewPassword] = useState(true);
  const [showRepeatPassword, setShowRepeatPassword] = useState(true);
  const [passwordEquals, setPasswordEquals] = useState(false);
  const [allValidation, setAllValidation] =
    useState<RegExpMatchArray | boolean | null>(false);
  const [disableButton, setDisableButton] = useState(true);

  const [viewIconOldPassword, setViewIconOldPassword] = useState(false);
  const [viewIconNewPassword, setViewIconNewPassword] = useState(false);
  const [viewIconRepeatPassword, setViewIconRepeatPassword] = useState(false);

  const [hiddenNewPassword, setHiddenNewPassword] = useState(true);

  const [invalidOldPassword, setInvalidOldPassword] = useState(false);
  const [invalidNewPassword, setInvalidNewPassword] = useState(false);

  useEffect(() => {
    document.title =
      t("account.settings.changePassword.title") +
      " - " +
      t("navigation.menuBalloon.userMenu.accountSettings");
  }, []);

  useEffect(() => {
    if (oldPassword) {
      setHiddenNewPassword(false);
    } else {
      setHiddenNewPassword(true);
    }
  }, [oldPassword]);

  const [hiddenValidation, setHiddenValidation] = useState(true);

  const [allValidationArray, setAllValidationArray] = useState({
    minLenght: false,
    lowUp: false,
    number: false,
    specChar: false,
  });
  useEffect(() => {
    setAllValidationArray({
      minLenght: newPassword.length >= 8,
      lowUp:
        newPassword.toUpperCase() !== newPassword &&
        newPassword.toLowerCase() !== newPassword,
      number: newPassword.match(/\d+/g) ? true : false,
      specChar: newPassword.match(/[ `!@#$%^&*()_+\-=\\[\]{};':"\\|,.<>\\/?~]/)
        ? true
        : false,
    });
    setAllValidation(
      newPassword.length >= 8 &&
        newPassword.toUpperCase() !== newPassword &&
        newPassword.toLowerCase() !== newPassword &&
        newPassword.match(/\d+/g) &&
        newPassword.match(/[ `!@#$%^&*()_+\-=\\[\]{};':"\\|,.<>\\/?~]/)
    );
    newPassword === repeatPassword || !allValidation
      ? setPasswordEquals(true)
      : setPasswordEquals(false);
    newPassword &&
    repeatPassword &&
    (newPassword === repeatPassword || !allValidation)
      ? setDisableButton(false)
      : setDisableButton(true);
  }, [newPassword, repeatPassword]);

  async function changePassword() {
    const usersRepository = new UsersRepository();
    try {
      const result = await usersRepository.changePassword(
        oldPassword,
        newPassword
      );
      invalidOldPassword && setInvalidOldPassword(!invalidOldPassword);
      invalidNewPassword && setInvalidNewPassword(!invalidNewPassword);
      navigate("/account-settings/edit-account/edit");
    } catch (err: any) {
      console.log(err.response);
      err.response.data.message === GTFleetErrorCodes.INVALID_PASSWORD &&
        !invalidOldPassword &&
        setInvalidOldPassword(!invalidOldPassword);

      err.response.status === 409 &&
        err.response.data.message === GTFleetErrorCodes.NEW_PASSWORD_CONFLICT &&
        !invalidNewPassword &&
        setInvalidNewPassword(!invalidNewPassword);

      !(
        err.response.data.message === GTFleetErrorCodes.INVALID_PASSWORD ||
        err.response.data.message === GTFleetErrorCodes.NEW_PASSWORD_CONFLICT
      ) &&
        ToastNotification({
          toastId: "networkError",
          status: "error",
          description: t("common.networkError") + ": " + err.response.data.message,
        });
    }
  }

  useEffect(() => {
    if (invalidOldPassword || invalidNewPassword) {
      setDisableButton(true);
    } else {
      setDisableButton(false);
    }
  }, [invalidOldPassword, invalidNewPassword]);

  return (
    <>
      <PageFilters>
        {/*------------ Password FilterBar ---------*/}
        <div className="col col-16">
          <div className="subtopbar">
            <div className="subsection-security-name">
              {t("account.settings.changePassword.title")}
            </div>
            <div className="button-save-group">
              <Button
                size="small"
                aspect="secondary"
                label={t("account.settings.changePassword.cancel")}
                onClick={() => {
                  navigate("/account-settings/edit-account/edit");
                }}
              />
              <Button
                size="small"
                aspect="primary"
                label={t("account.settings.changePassword.save")}
                onClick={changePassword}
                disabled={disableButton}
              />
            </div>
          </div>
        </div>
      </PageFilters>
      <PageContent>
        <div className="form-container">
          <div className="security-psw-form">
            <div className="as-forms-title">
              {t("account.settings.changePassword.security")}
            </div>
            <div className="as-forms-container">
              <div className="security-psw-label">
                {t("account.settings.changePassword.currentPassword")}
              </div>
              <div className="security-psw-forms">
                <div
                  className={
                    "left-form " +
                    (invalidOldPassword ? "password-not-equals" : "")
                  }
                >
                  <Form>
                    <TextField
                      id="old-password"
                      placeholder={t(
                        "account.settings.changePassword.typeCurrentPassword"
                      )}
                      type={showOldPassword ? "password" : "text"}
                      events={{
                        onChange: (data) => {
                          setOldPassword(data.value);
                          invalidOldPassword &&
                            setInvalidOldPassword(!invalidOldPassword);
                          invalidNewPassword &&
                            setInvalidNewPassword(!invalidNewPassword);
                        },
                        onFocus: () => {
                          setViewIconOldPassword(true);
                        },
                      }}
                      value={oldPassword}
                    >
                      {viewIconOldPassword && showOldPassword && (
                        <IconEyeSlash
                          onClick={() => setShowOldPassword(false)}
                          size={14}
                          color="--global-colors-ink-light"
                        />
                      )}
                      {viewIconOldPassword && !showOldPassword && (
                        <IconEye
                          onClick={() => setShowOldPassword(true)}
                          size={14}
                          color="--global-colors-ink-light"
                        />
                      )}
                    </TextField>
                  </Form>
                  {invalidOldPassword && (
                    <div className="password-not-equals-label">
                      {t("account.settings.changePassword.wrongPassword")}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="as-forms-container">
              <div className="security-psw-label">
                {t("account.settings.changePassword.newPassword")}
              </div>
              <div className="security-psw-forms">
                <div
                  className={
                    "left-form " +
                    (passwordEquals
                      ? !invalidNewPassword
                        ? ""
                        : "new-password-conflict"
                      : "password-not-equals")
                  }
                >
                  <Form>
                    <TextField
                      id="new-password"
                      placeholder={
                        hiddenNewPassword
                          ? ""
                          : t("account.settings.changePassword.typeNewPassword")
                      }
                      type={showNewPassword ? "password" : "text"}
                      events={{
                        onChange: (data) => {
                          setNewPassword(data.value);
                          invalidOldPassword &&
                            setInvalidOldPassword(!invalidOldPassword);
                          invalidNewPassword &&
                            setInvalidNewPassword(!invalidNewPassword);
                        },
                        onFocus: () => {
                          setHiddenValidation(false);
                          setViewIconNewPassword(true);
                        },
                      }}
                      value={newPassword}
                      disabled={hiddenNewPassword}
                    >
                      {showNewPassword && viewIconNewPassword && (
                        <IconEyeSlash
                          onClick={() => setShowNewPassword(false)}
                          size={14}
                          color="--global-colors-ink-light"
                        />
                      )}
                      {!showNewPassword && viewIconNewPassword && (
                        <IconEye
                          onClick={() => setShowNewPassword(true)}
                          size={14}
                          color="--global-colors-ink-light"
                        />
                      )}
                    </TextField>
                  </Form>
                  {invalidNewPassword && (
                    <div className="new-password-conflict-label">
                      {t("account.settings.changePassword.newPasswordConflict")}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div
              className={`constraint-container ${
                invalidNewPassword && "invalidPassword"
              }`}
              style={{ display: hiddenValidation ? "none" : "" }}
            >
              <div className="constraint-title">
                {t("customModals.verifyCode.passwordConstraintTitle")}
              </div>
              <div className="main-check">
                <div
                  className={
                    newPassword === ""
                      ? "hide-constraint-check"
                      : "constraint-check"
                  }
                >
                  {allValidationArray.minLenght ? (
                    <Check status="success" size="xsmall" />
                  ) : (
                    <Check status="failed" size="xsmall" />
                  )}
                </div>
                <div
                  className={
                    newPassword === ""
                      ? "default-constraint"
                      : allValidationArray.minLenght
                      ? "success-constraint"
                      : "error-constraint"
                  }
                >
                  {t("customModals.verifyCode.passwordConstraintLength")}
                </div>
              </div>
              <div className="main-check">
                <div
                  className={
                    newPassword === ""
                      ? "hide-constraint-check"
                      : "constraint-check"
                  }
                >
                  {allValidationArray.lowUp ? (
                    <Check status="success" size="xsmall" />
                  ) : (
                    <Check status="failed" size="xsmall" />
                  )}
                </div>
                <div
                  className={
                    newPassword === ""
                      ? "default-constraint"
                      : allValidationArray.lowUp
                      ? "success-constraint"
                      : "error-constraint"
                  }
                >
                  {t("customModals.verifyCode.passwordConstraintLowUp")}
                </div>
              </div>
              <div className="main-check">
                <div
                  className={
                    newPassword === ""
                      ? "hide-constraint-check"
                      : "constraint-check"
                  }
                >
                  {allValidationArray.number ? (
                    <Check status="success" size="xsmall" />
                  ) : (
                    <Check status="failed" size="xsmall" />
                  )}
                </div>
                <div
                  className={
                    newPassword === ""
                      ? "default-constraint"
                      : allValidationArray.number
                      ? "success-constraint"
                      : "error-constraint"
                  }
                >
                  {t("customModals.verifyCode.passwordConstraintNumber")}
                </div>
              </div>
              <div className="main-check">
                <div
                  className={
                    newPassword === ""
                      ? "hide-constraint-check"
                      : "constraint-check"
                  }
                >
                  {allValidationArray.specChar ? (
                    <Check status="success" size="xsmall" />
                  ) : (
                    <Check status="failed" size="xsmall" />
                  )}
                </div>
                <div
                  className={
                    newPassword === ""
                      ? "default-constraint"
                      : allValidationArray.specChar
                      ? "success-constraint"
                      : "error-constraint"
                  }
                >
                  {t("customModals.verifyCode.passwordConstraintChar")}
                </div>
              </div>
            </div>
            <div className="as-forms-container">
              <div className="security-psw-label">
                {t("account.settings.changePassword.confirmPassword")}
              </div>
              <div className="security-psw-forms">
                <div
                  className={
                    "left-form " + (passwordEquals ? "" : "password-not-equals")
                  }
                >
                  <Form>
                    <TextField
                      id="repeat-password"
                      placeholder={
                        allValidation
                          ? t(
                              "account.settings.changePassword.typeConfirmPassword"
                            )
                          : ""
                      }
                      type={showRepeatPassword ? "password" : "text"}
                      events={{
                        onChange: (data) => {
                          setRepeatPassword(data.value);
                          invalidOldPassword &&
                            setInvalidOldPassword(!invalidOldPassword);
                          invalidNewPassword &&
                            setInvalidNewPassword(!invalidNewPassword);
                        },
                        onFocus: () => {
                          setViewIconRepeatPassword(true);
                        },
                      }}
                      value={repeatPassword}
                      disabled={!allValidation}
                    >
                      {showRepeatPassword && viewIconRepeatPassword && (
                        <IconEyeSlash
                          onClick={() => setShowRepeatPassword(false)}
                          size={14}
                          color="--global-colors-ink-light"
                        />
                      )}
                      {!showRepeatPassword && viewIconRepeatPassword && (
                        <IconEye
                          onClick={() => setShowRepeatPassword(true)}
                          size={14}
                          color="--global-colors-ink-light"
                        />
                      )}
                    </TextField>
                  </Form>
                  {!passwordEquals && (
                    <div className="password-not-equals-label">
                      {t("customModals.verifyCode.PasswordNotEquals")}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </PageContent>
    </>
  );
}
export default ChangePassword;
