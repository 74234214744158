import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import {
  createNotificationRuleAsync,
  editNotificationRuleAsync,
  getFilteredNotificationsRuleAggregationsAsync,
  getNotificationRule,
} from "./notificationRuleAggregationSlice";

export type NotificationRule = {
  id: number;
  event: string;
  name: string;
  frequency: number;
  notificationRuleAggregation: number;
  templateHtml: string;
  templateText: string;
  tenant: number;
  destinations: number[];
};

const notitficationRulesAdapter = createEntityAdapter<NotificationRule>({
  selectId: (notificationRule) => notificationRule.id,
});

export const notificationRuleSlice = createSlice({
  name: "notificationRules",
  initialState: notitficationRulesAdapter.getInitialState({
    status: "idle",
    reasonCode: "",
    totalPages: 0,
  }),
  reducers: {
    notificationRuleEmptyState: (state) => {
      notitficationRulesAdapter.setAll(state, []);
      state.reasonCode = "";
      state.status = "idle";
    },
    setNumberOfPages: (state, action: PayloadAction<number>) => {
      state.totalPages = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      //#region Entity Reducers
      //#endregion

      .addCase(
        getFilteredNotificationsRuleAggregationsAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          action.payload?.notificationRule &&
            notitficationRulesAdapter.upsertMany(
              state,
              action.payload?.notificationRule
            );
        }
      )
      .addCase(
        getNotificationRule.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          action.payload?.notificationRule &&
            notitficationRulesAdapter.upsertMany(
              state,
              action.payload?.notificationRule
            );
        }
      )
      .addCase(
        editNotificationRuleAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          notitficationRulesAdapter.upsertMany(
            state,
            action.payload.notificationRule ?? []
          );
        }
      )
      .addCase(
        createNotificationRuleAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          notitficationRulesAdapter.upsertMany(
            state,
            action.payload.notificationRule ?? []
          );
        }
      );
  },
});

export const notificationRulesSelectors =
  notitficationRulesAdapter.getSelectors<RootState>(
    (state) => state.notificationRules
  );
export const selectNotificationRulesSliceStatus = (state: any) =>
  state.notificationRules.status;

export const selectNotificationRulesSliceReasonCode = (state: any) =>
  state.notificationRules.reasonCode;

export const selectNotificationRulesSlicePage = (state: any) =>
  state.notificationRules.totalPages;

export const { notificationRuleEmptyState, setNumberOfPages } =
  notificationRuleSlice.actions;

export default notificationRuleSlice.reducer;
