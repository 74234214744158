import dayjs from "dayjs";
import HttpClient from "../../../utils/HttpClient";

export default class VehicleTravelsRepository extends HttpClient {
  constructor() {
    super(true);
  }

  /**
   * Returns a list of vehicle travels summary (aggregation for each travel)
   * @param vehicleId
   * @param queryParams
   * @param body
   * @returns
   */
  getVehicleTravelsSummary(
    vehicleId: number,
    queryParams?: string,
    body?: { pagingState?: string }
  ) {
    const tenantId = localStorage.getItem("tenantId");
    return this.instance.post(
      "tenants/" +
        tenantId +
        "/vehicles/" +
        vehicleId +
        "/travels/summary" +
        (queryParams ?? ""),
      body
    );
  }

  /**
   * Returns a list of the last n vehicle travels summary (aggregation for each travel)
   * @param vehicleId
   * @param queryParams
   * @returns
   */
  getVehicleLastTracks(vehicleId: number, queryParams?: string) {
    const tenantId = localStorage.getItem("tenantId");
    return this.instance.post(
      "tenants/" +
        tenantId +
        "/vehicles/" +
        vehicleId +
        "/lastTracks" +
        (queryParams ?? "")
    );
  }

  /**
   * Returns the macro summary of the vehicle travels included in the selected period (aggregation of the aggregations of travels)
   * @param vehicleId
   * @param queryParams
   * @returns
   */
  getVehicleTravelsMacroSummary(vehicleId: number, queryParams?: string) {
    const tenantId = localStorage.getItem("tenantId");
    const queryParamsObj = new URLSearchParams(queryParams);
    const dateRange = queryParamsObj.getAll("firstPointDate");
    if (dateRange.length > 1) {
      queryParamsObj.set(
        "firstPointDate",
        dayjs(new Date(dateRange[0])).utc().format("YYYY/MM/DD HH:mm")
      );
    }
    if (dateRange[1]) {
      queryParamsObj.append(
        "firstPointDate",
        dayjs(new Date(dateRange[1])).utc().format("YYYY/MM/DD HH:mm")
      );
    }
    return this.instance.get(
      "tenants/" +
        tenantId +
        "/vehicles/" +
        vehicleId +
        "/travels/macro-summary?" +
        (queryParamsObj.toString() ?? "")
    );
  }

  /**
   * Returns a vehicle travel summary (aggregation of a travel)
   * @param vehicleId
   * @param routeId
   * @param queryParams
   * @returns
   */
  getVehicleTravelSummary(
    vehicleId: number,
    routeId: number,
    queryParams?: string
  ) {
    const tenantId = localStorage.getItem("tenantId");
    return this.instance.get(
      "tenants/" +
        tenantId +
        "/vehicles/" +
        vehicleId +
        "/travels/" +
        routeId +
        "/summary" +
        (queryParams ?? "")
    );
  }
}
