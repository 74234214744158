import React, { useState } from "react";
import "./ColorPicker.css";

interface ColorPickerProps {
  colors: string[][];
  onClick: (e: any) => any;
  defaultColor: string;
}

export const ColorPicker: React.FC<ColorPickerProps> = ({
  colors,
  onClick,
  defaultColor,
}) => {
  const [colorSelected, setColorSelected] = useState(defaultColor);
  return (
    <div className="colors">
      <table>
        <tbody>
          {colors.map(function (el, index) {
            return (
              <tr key={index}>
                {el.map((color, i) => {
                  return (
                    <td key={i}>
                      <label>
                        <input
                          type="radio"
                          name="color"
                          autoComplete="off"
                          onChange={() => {
                            onClick(color);
                            setColorSelected(color);
                          }}
                          checked={color === colorSelected}
                        />
                        <span
                          className="circle"
                          style={{
                            backgroundColor: `${color}`,
                            border: `1px solid ${color}`,
                          }}
                        ></span>
                      </label>
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

ColorPicker.defaultProps = {
  colors: [
    ["#000000", "#333333", "#656565", "#989898", "#CBCBCB", "#EDEDED"],
    ["#7A3CA1", "#B089C6", "#E4D8EC", "#020088", "#6667B9", "#CCCCE8"],
    ["#0265B3", "#66A3D2", "#CDE0EF", "#007F4D", "#67B293", "#CBE5DA"],
    ["#FEF305", "#FFF967", "#FFFDCC", "#F57631", "#F7AB82", "#F7AB82"],
    ["#FDE3D6", "#FD0201", "#FF6766", "#FFCCCB", "#A8272B", "#C97D7F"],
  ],
};
