import { t } from "i18next";
import _ from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../app/hooks";
import { store } from "../../../app/store";
import PageContent from "../../../layout/PageContent";
import PageFilters from "../../../layout/PageFilters";
import { Button } from "../../../ui/Button/Button";
import { Radio } from "../../../ui/Forms/Radio";
import { Tooltip } from "../../../ui/Forms/Tooltip";
import { IconEdit } from "../../../ui/Icon/Line/Edit";
import { IconMap } from "../../../ui/Icon/Line/Map";
import { IconRepeat } from "../../../ui/Icon/Line/Repeat";
import { PageCounter } from "../../../ui/Table/PageCounter";
import { Table } from "../../../ui/Table/Table";
import { ToastNotification } from "../../../utils/ToastNotification";
import { getQueryString, kmToMiles } from "../../../utils/Utils";
import { Preferences } from "../../users/preference/preferencesSlice";
import { UserPermissions } from "../../users/privilege/privilegesSlice";
import UserContext from "../../users/userContext";
import { PublicTransportMap } from "../PublicTransportMap";
import "./AdminPublicRoutes.css";
import { AdminPublicRoutesFilterBar } from "./AdminPublicRoutesFilterBar";
import {
  PublicRouteView,
  PublicStopView,
  getFilteredPublicRoutesAsync,
  publicRoutesEmptyState,
  publicRoutesSelectors,
  selectPublicRoutesSlicePage,
  selectPublicRoutesSliceReasonCode,
  selectPublicRoutesSliceStatus,
  selectPublicRoutesSliceTotalElements,
} from "./publicRoutesSlice";

interface AdminPublicRoutesProps {
  permissions: UserPermissions;
}

interface CheckedProps {
  id: number;
  name: string;
  color: string;
  number: number;
  vehicleId: number;
  queryParams?: string;
}

export const AdminPublicRoutes: React.FC<AdminPublicRoutesProps> = ({
  permissions,
}) => {
  //#region state&redux

  const publicRouteViews: PublicRouteView[] = useAppSelector(
    publicRoutesSelectors.selectAll
  );
  const publicRouteViewsSliceStatus = useAppSelector(
    selectPublicRoutesSliceStatus
  );
  const isRoutesLoading = publicRouteViewsSliceStatus === "loading";
  const isRoutesIdle = publicRouteViewsSliceStatus === "idle";

  const publicRouteViewsSliceReasonCode = useAppSelector(
    selectPublicRoutesSliceReasonCode
  );
  const publicRouteViewsTotalElements = useAppSelector(
    selectPublicRoutesSliceTotalElements
  );
  const publicRouteViewsTotalPages = useAppSelector(
    selectPublicRoutesSlicePage
  );
  const [queryParamsFromFilterBar, setQueryParamsFromFilterBar] =
    useState<string>("");
  const [viewMap, setViewMap] = useState(false);
  const [clickedChild, setClickedChild] = useState<number>(-1);
  const [clickedDiv, setClickedDiv] = useState<HTMLDivElement>();
  const [checkedList, setCheckedList] = useState<CheckedProps[]>([]);
  const [resetPage] = useState<boolean>(false);
  const [preferencesContext]: [Preferences] = useContext(UserContext);
  const isMetric: boolean = preferencesContext.isMetric;
  const [currentlyCheckedItem, setCurrentlyCheckedItem] = useState<string>("");

  const publicRouteView: PublicRouteView = !_.isEmpty(checkedList)
    ? (publicRoutesSelectors.selectById(
        store.getState(),
        checkedList[0].id
      ) as PublicRouteView)
    : ({} as PublicRouteView);
  const navigate = useNavigate();
  //#endregion state&redux

  //#region useEffect

  useEffect(() => {
    document.title = t("navigation.adminMenu.lines") + " - Admin";
    /**
     * When the component is unmountend the related redux state should be cleaned.
     */
    return () => {
      store.dispatch(publicRoutesEmptyState());
    };
  }, []);

  useEffect(() => {
    if (
      publicRouteViewsSliceStatus === "failed" &&
      publicRouteViewsSliceReasonCode === ""
    ) {
      ToastNotification({
        toastId: "networkError",
        status: "error",
        description: t("common.networkError"),
      });
    }
  }, [publicRouteViewsSliceStatus, publicRouteViewsSliceReasonCode]);

  useEffect(() => {
    if (!viewMap) {
      setCheckedList([]);
    }
  }, [viewMap]);

  useEffect(() => {
    setCheckedList([]);
  }, [queryParamsFromFilterBar]);

  //#endregion useEffect

  //#region functions

  const handleStateChange = (newState: boolean) => {
    setViewMap(newState);
  };

  const onRadioChange = (
    id: number,
    name: string,
    color: string,
    number: number,
    vehicleId: number,
    queryParams?: string
  ) => {
    const newChecked: CheckedProps[] = [
      {
        id,
        name,
        color,
        number,
        vehicleId,
        queryParams,
      },
    ];
    if (!_.isEqual(checkedList, newChecked)) {
      setCheckedList(newChecked);
    }
  };

  function buildPublicRoutesViewTable() {
    const handleClick = (
      e: React.MouseEvent<HTMLDivElement, MouseEvent>,
      stopId: number
    ) => {
      if (viewMap) {
        if (clickedChild == -1) {
          setClickedChild(stopId);
          setClickedDiv(e.currentTarget);
          if (e.currentTarget != null) {
            e.currentTarget.id = "publicRoute-clicked";
          }
        } else if (clickedChild == stopId) {
          setClickedChild(-1);
          e.currentTarget.id = "publicRoute";
        } else if (clickedChild != stopId && clickedDiv) {
          setClickedChild(stopId);
          clickedDiv.id = "publicRoute";
          e.currentTarget.id = "publicRoute-clicked";
          setClickedDiv(e.currentTarget);
        }
      }
    };

    return {
      columns: viewMap
        ? [
            {
              label: t(""),
              field: "radio",
              className: "radio",
            },
            {
              label: t("table.columns.name"),
              field: "name",
            },
            {
              label: t("table.columns.vehicle"),
              field: "vehicleInfo",
            },
            {
              label: t("table.columns.stops"),
              field: "stops",
            },
            {
              label: t("table.columns.numberOfTrips"),
              field: "numberOfTrips",
            },
          ]
        : [
            {
              label: t("table.columns.name"),
              field: "name",
            },
            {
              label: t("table.columns.vehicle"),
              field: "vehicleInfo",
            },
            {
              label: t("table.columns.stops"),
              field: "stops",
            },
            {
              label: t("table.columns.distance"),
              field: "distance",
            },
            {
              label: t("table.columns.time"),
              field: "time",
            },
            {
              label: t("table.columns.numberOfTrips"),
              field: "numberOfTrips",
            },
            {
              label: t("table.columns.map"),
              field: "map",
            },
          ],
      rows: _.sortBy(publicRouteViews, "name").map((routeRow) => {
        const rowsGrouped = routeRow.stops.map(
          (stop: PublicStopView, stopIndex: number) => {
            return {
              render: viewMap
                ? [
                    <div
                      className={"show-child"}
                      key={"radio"}
                      id={"radio"}
                    ></div>,
                    <div className={"show-child"} key={"name"}></div>,
                    <div className={"show-child"} key={"vehicleInfo"}></div>,
                    <div className={"show-child"} key={"stops"}>
                      {stop.name}
                    </div>,
                    <div className={"show-child"} key={"numberOfTrips"}></div>,
                    <div
                      className="show-child"
                      id={"arrow"}
                      onClick={() => {
                        window
                          .open(
                            `/admin/geofences/edit-geofence/${stop.geofenceId}`,
                            "_blank"
                          )
                          ?.focus();
                      }}
                    >
                      <Tooltip />
                      <Table.TextIcon
                        text={""}
                        icon={
                          <span
                            key={routeRow.id}
                            data-for="tooltip"
                            data-tip={t(
                              "admin.geofences.editViewCreate.editGeofence"
                            )}
                          >
                            <IconEdit
                              size={12}
                              color={
                                clickedChild == stopIndex
                                  ? "--global-colors-ui-white"
                                  : "--global-colors-ink-dark"
                              }
                            />
                          </span>
                        }
                      />
                    </div>,
                  ]
                : [
                    <div className="show-child" key={"name"}></div>,
                    <div className="show-child" key={"vehicleInfo"}></div>,
                    <div className="show-child" key={"stops"}>
                      {stop.name}
                    </div>,
                    <div className="show-child" key={"numberOfTrips"}></div>,
                    <div className="show-child" key={"distance"}></div>,
                    <div className="show-child" key={"time"}></div>,
                    <div className="show-child" key={""}></div>,
                    <div className="show-child" key={""}></div>,
                  ],
              onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
                handleClick(e, stopIndex),
            };
          }
        );

        return {
          data: viewMap
            ? {
                radio: viewMap ? (
                  <Radio
                    name="publicRoutes"
                    label=""
                    disabled={false}
                    checked={checkedList.some(
                      (checkedItem) => checkedItem.id === routeRow.id
                    )}
                    onChange={() => {
                      onRadioChange(
                        routeRow.id,
                        routeRow.name,
                        routeRow.color,
                        0,
                        routeRow.id,
                        ""
                      );
                    }}
                  />
                ) : null,
                name: (
                  <Table.TextIcon
                    text={routeRow.name ?? t("common.na")}
                    icon={
                      <div
                        className="circle"
                        style={{
                          width: "15px",
                          height: "15px",
                          backgroundColor: routeRow.color ?? "",
                          border: `1px solid ${routeRow.color ?? ""}`,
                          borderRadius: "50%",
                        }}
                      ></div>
                    }
                  />
                ),
                vehicleInfo:
                  (routeRow.vehicleAlias ?? " ") +
                  " - " +
                  (routeRow.vehiclePlate ?? " "),
                stops: routeRow.stops.length,
                numberOfTrips: (
                  <>
                    <Table.TextIcon
                      text={routeRow.numberOfTrips.toString() ?? t("common.na")}
                      icon={
                        <IconRepeat
                          size={14}
                          color="--global-colors-ink-dark"
                        />
                      }
                    />
                  </>
                ),
              }
            : {
                name: (
                  <Table.TextIcon
                    text={routeRow.name ?? t("common.na")}
                    icon={
                      <div
                        className="circle"
                        style={{
                          width: "15px",
                          height: "15px",
                          backgroundColor: routeRow.color ?? "",
                          border: `1px solid ${routeRow.color ?? ""}`,
                          borderRadius: "50%",
                        }}
                      ></div>
                    }
                  />
                ),
                vehicleInfo:
                  (routeRow.vehicleAlias ?? " ") +
                  " - " +
                  (routeRow.vehiclePlate ?? " "),
                stops: routeRow.stops.length ?? t("common.na"),
                distance: routeRow.routeLength
                  ? isMetric
                    ? routeRow.routeLength + " km"
                    : kmToMiles(routeRow.routeLength) + " mi"
                  : isMetric
                  ? "0 km"
                  : "0 mi",
                time: routeRow.tripDuration
                  ? routeRow.tripDuration + " min"
                  : "0 min",
                numberOfTrips: (
                  <>
                    <Tooltip />
                    <Table.TextIcon
                      text={routeRow.numberOfTrips.toString() ?? t("common.na")}
                      icon={
                        <span
                          key={routeRow.id}
                          data-for="tooltip"
                          data-tip={t("admin.publicRoute.trips")}
                        >
                          <IconRepeat
                            size={14}
                            color="--global-colors-ink-dark"
                          />
                        </span>
                      }
                    />
                  </>
                ),
                map: !viewMap ? (
                  <span
                    key={routeRow.id}
                    data-for="tooltip"
                    data-tip={t("admin.publicRoute.showMap")}
                  >
                    <Tooltip />
                    <Button
                      aspect="ghost"
                      size="small"
                      label={""}
                      onClick={() => {
                        setViewMap(!viewMap);
                        onRadioChange(
                          routeRow.id,
                          routeRow.name,
                          routeRow.color,
                          0,
                          routeRow.id,
                          ""
                        );
                      }}
                      disabled={publicRouteViews.length === 0}
                    >
                      <IconMap size={14} color="--global-colors-ink-light" />
                    </Button>
                  </span>
                ) : null,
              },
          rowsGrouped: rowsGrouped,
        };
      }),
    };
  }

  //#endregion functions

  //#region Render

  return (
    <>
      {permissions.publicTransport.write ? (
        <>
          <PageFilters>
            <div className="col col-16">
              <AdminPublicRoutesFilterBar
                callback={setQueryParamsFromFilterBar}
                onViewMapChange={(viewMap: any) => handleStateChange(viewMap)}
                externalViewMap={viewMap}
              />
            </div>
          </PageFilters>
        </>
      ) : null}
      {publicRouteViews.length !== 0 &&
        buildPublicRoutesViewTable().rows?.length > 0 && (
          <div className="routes-table-container">
            <PageContent>
              <div
                className={
                  viewMap ? "routes-container-collapsed" : "routes-container"
                }
              >
                <Table data={buildPublicRoutesViewTable()} isShowTable={true}>
                  <Table.Head hasTableSpace={true} />
                  <Table.Body
                    id="publicRoute"
                    getShownRow={(data) => {
                      setCurrentlyCheckedItem(
                        !!data && data?.name?.props?.text
                          ? data.name.props.text
                          : ""
                      );
                      const publicRouteView = publicRouteViews.filter(
                        (publicRouteView) =>
                          publicRouteView.name === data?.name?.props?.text
                      )[0];
                      onRadioChange(
                        publicRouteView.id,
                        publicRouteView.name,
                        publicRouteView.color,
                        0,
                        publicRouteView.id,
                        ""
                      );
                      setClickedChild(-1);
                    }}
                  />
                </Table>
              </div>

              <div
                className={
                  viewMap
                    ? "routes-container-pagination-collapsed"
                    : "routes-container-pagination"
                }
              >
                <PageCounter
                  isActionPerforming={isRoutesLoading}
                  totalElements={publicRouteViewsTotalElements}
                  resetPage={resetPage}
                  disabled={!isRoutesIdle}
                  numOfPages={publicRouteViewsTotalPages}
                  onClick={(id, currentPage) => {
                    if (id !== currentPage) {
                      const pageAndSize = getQueryString({
                        page: id - 1,
                        size: "10",
                      });
                      const finalQueryParams = queryParamsFromFilterBar
                        ? queryParamsFromFilterBar +
                          pageAndSize.replace("?", "&")
                        : pageAndSize;
                      store.dispatch(
                        getFilteredPublicRoutesAsync({
                          queryParams: finalQueryParams,
                        })
                      );
                    }
                  }}
                />
              </div>
              {viewMap && (
                <div className="routes-map-container">
                  <PublicTransportMap
                    stops={
                      publicRouteView && !_.isEmpty(publicRouteView.stops)
                        ? publicRouteView.stops
                        : []
                    }
                    highlightedStop={clickedChild}
                    points={
                      publicRouteView && !_.isEmpty(publicRouteView.points)
                        ? publicRouteView.points
                        : []
                    }
                    id={publicRouteView.id}
                    googleMapsApiKey={
                      process.env.REACT_APP_GOOGLE_MAPS_API_KEY!
                    }
                    zoom={4}
                    latitude={41.9027835}
                    longitude={12.4963655}
                    checkedList={checkedList}
                    limit={10}
                  />
                </div>
              )}
            </PageContent>
          </div>
        )}
    </>
  );
  //#endregion Render
};

export default AdminPublicRoutes;
