import {
  PayloadAction,
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import _ from "lodash";
import { normalize } from "normalizr";
import { RootState } from "../../../app/store";
import { Config } from "../../../config/Config";
import { GTFleetSuccessCodes } from "../../../config/GTFleetSuccessCodes";
import { speedDataReportSchema } from "./speedNormalization";
import SpeedRepository from "./speedRepository";

//#region Type
export type VehicleSpeedData = {
  timestamp: Date;
  speed: number;
};

export type SpeedDataReport = {
  vehicleId: number;
  vehicleSpeedData: VehicleSpeedData[];
};
//#endregion Type

//#region API
// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const getSpeedDataReportsAsync = createAsyncThunk(
  "speedDataReports/getSpeedDataReportsAsync",
  async (data: { queryParams: string }) => {
    const speedRepository = new SpeedRepository();
    const response = await speedRepository.getSpeedDataReports(
      data.queryParams
    );
    const speedData = _.get(response, Config.SPEED_DATA_RESPONSE_PATH);
    const normalizedData = normalize(speedData, speedDataReportSchema);
    return normalizedData.entities;
  }
);
//#endregion API

//#region Custom Functions
function setFilteredData(state: any, action: PayloadAction<any>) {
  action.payload.speedData &&
    speedDataReportsAdapter.setAll(state, action.payload.speedData);
}
//#endregiorn Custom Functions

//#region Slice
const speedDataReportsAdapter = createEntityAdapter<SpeedDataReport>({
  selectId: (speedDataReport) => speedDataReport.vehicleId,
});

export const speedDataReportsSlice = createSlice({
  name: "speedDataReportsSlice",
  initialState: speedDataReportsAdapter.getInitialState({
    status: "idle",
    reasonCode: "",
  }),
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    speedDataReportsEmptyState: (state) => {
      speedDataReportsAdapter.setAll(state, []);
      state.status = "idle";
      state.reasonCode = GTFleetSuccessCodes.GET;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      //#region Entity Reducers
      .addCase(
        getSpeedDataReportsAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          setFilteredData(state, action);
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.GET;
        }
      )
      .addCase(getSpeedDataReportsAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(getSpeedDataReportsAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = "";
      });
    //#endregion Entity Reducers
  },
});
//#endregion Slice

//#region Status
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const speedDataReportsSelectors =
  speedDataReportsAdapter.getSelectors<RootState>(
    (state) => state.speedDataReports
  );

export const selectSpeedDataReportsSliceStatus = (state: any) =>
  state.speedDataReports.status;
export const selectSpeedDataReportsSliceReasonCode = (state: any) =>
  state.speedDataReports.reasonCode;
//#endregion Status
export const { speedDataReportsEmptyState } = speedDataReportsSlice.actions;

export default speedDataReportsSlice.reducer;
