import HttpClient from "../../utils/HttpClient";
import { Driver } from "./driversSlice";

export default class DriversRepository extends HttpClient {
  constructor() {
    super(true);
  }

  getDriversDetails(queryParams?: string) {
    const tenantId = localStorage.getItem("tenantId");
    let path = "/tenants/" + tenantId + "/drivers/details";
    return this.instance.get(path + (queryParams ?? ""));
  }

  getDriverDetails(driverId: number) {
    const tenantId = localStorage.getItem("tenantId");
    return this.instance.get(
      "tenants/" + tenantId + "/drivers/" + driverId + "/details"
    );
  }

  getDriverStatus(driverId: number) {
    const tenantId = localStorage.getItem("tenantId");
    return this.instance.get(
      "/tenants/" + tenantId + "/drivers/" + driverId + "/status"
    );
  }

  partiallyUpdateDriver(
    driverId: number,
    removedVehicle: boolean,
    driver: any
  ) {
    const removeVehicleParam = removedVehicle ? "true" : "false";
    const tenantId = localStorage.getItem("tenantId");
    return this.instance.patch(
      "tenants/" +
        tenantId +
        "/drivers/" +
        driverId +
        "/details" +
        "?removeVehicle=" +
        removeVehicleParam,
      driver
    );
  }

  createDriver(driver: any) {
    const tenantId = localStorage.getItem("tenantId");
    return this.instance.post(
      "tenants/" + tenantId + "/drivers/details",
      driver
    );
  }

  editDriver(driver: Driver) {
    const tenantId = localStorage.getItem("tenantId");
    return this.instance.put(
      "tenants/" + tenantId + "/driverDetails/view",
      driver
    );
  }
}
