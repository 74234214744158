import dayjs from "dayjs";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { store } from "../../../app/store";
import { ModalBody } from "../../../ui/Modal/ModalBody";
import { ModalFooter } from "../../../ui/Modal/ModalFooter";
import { ModalWrapper } from "../../../ui/Modal/ModalWrapper";
import { ScheduleDate } from "../../../ui/Schedules/ScheduleDate";
import { ScheduleDistance } from "../../../ui/Schedules/ScheduleDistance";
import { ScheduleNote } from "../../../ui/Schedules/ScheduleNote";
import { ScheduleType } from "../../../ui/Schedules/ScheduleType";
import { ScheduleWorkingHours } from "../../../ui/Schedules/ScheduleWorkingHours";
import { DataCommon } from "../../../utils/Common";
import { getMeter, mtToDynamicMeasure, mtToKm } from "../../../utils/Utils";
import { Preferences } from "../../users/preference/preferencesSlice";
import UserContext from "../../users/userContext";
import { Vehicle } from "../../vehicle/vehiclesSlice";
import { VehicleStatus } from "../../vehicle/vehiclesStatusSlice";
import { Deadline, updateDeadlineAsync } from "./../deadlinesSlice";
import "./EditMaintenance.css";
interface EditMaintenanceModalProps {
  open: boolean;
  onClose: () => any;
  defaultValues: Deadline;
  maintenanceId: number;
  vehicleSelected?: Vehicle;
  selectedVehicleStatus?: VehicleStatus;
}

export const EditMaintenanceModal: React.FC<EditMaintenanceModalProps> = ({
  open,
  onClose,
  defaultValues,
  maintenanceId,
  vehicleSelected,
  selectedVehicleStatus,
}) => {
  const { t } = useTranslation();

  const [isOpened, setIsOpened] = useState(-1);
  const [isEditedNote, setIsEditedNote] = useState(false);
  const [isEditedType, setIsEditedType] = useState(false);
  const [isEditedDistance, setIsEditedDistance] = useState(false);
  const [isEditedWorkingHours, setIsEditedWorkingHours] = useState(false);
  const [isEditedDate, setIsEditedDate] = useState(false);
  const [isEditedAdditionalInfo, setIsEditedAdditionalInfo] = useState(false);

  const [editMaintenanceInfo, setEditMaintenanceInfo] = useState(defaultValues);

  const [preferencesContext]: [Preferences] = useContext(UserContext);

  const types: any = DataCommon()?.maintenanceTypes;
  const [buttonLoading, setButtonLoading] = useState(false);
  const [typeIsEmpty, setTypeIsEmpty] = useState(false);

  /**
   * The edited hook should be equal to the default value.
   */
  useEffect(() => {
    if (defaultValues && !_.isEmpty(defaultValues)) {
      setEditMaintenanceInfo(defaultValues);
    }
  }, [defaultValues]);

  const handleSubmit = () => {
    setButtonLoading(true);
    if (isOpened !== -1) {
      setIsOpened(-1);
      setButtonLoading(false);
    }
    if (!buttonLoading) {
      const expirationDate =
        editMaintenanceInfo?.expirationDate &&
        new Date(editMaintenanceInfo.expirationDate);
      expirationDate?.setHours(8);
      store.dispatch(
        updateDeadlineAsync({
          id: maintenanceId,
          deadline: {
            ...editMaintenanceInfo,
            expirationDate: expirationDate,
            isMaintenance: true,
            status: null,
            complete: false,
          },
        })
      );
      onClose();
    }
  };

  const submitModalRef = useRef<any>();
  useEffect(() => {
    const keyUp = (event: any) => {
      if (event.keyCode === 13) {
        event.preventDefault();
        handleSubmit();
      }
    };

    if (submitModalRef && submitModalRef.current) {
      submitModalRef.current.addEventListener("keydown", keyUp);
      let parentRef = submitModalRef;
      return () => {
        parentRef.current &&
          parentRef.current.removeEventListener("keydown", keyUp);
      };
    }
  });

  const localeFormatDate = preferencesContext.localeFormat ?? "DD / MM / YYYY";

  return (
    <div className="edit-maintenance-container" ref={submitModalRef}>
      <ModalWrapper open={open} closeAction={onClose}>
        <div className="set-edit-maintenance">
          <ModalBody
            title={
              defaultValues.complete
                ? t("customModals.addMaintenance.resumeViewTitle")
                : t("customModals.addMaintenance.viewTitle")
            }
            desc={
              defaultValues.complete
                ? `${t("customModals.addMaintenance.resumeDescConfFirst")} ${
                    vehicleSelected?.alias
                  } - ${vehicleSelected?.plate} ${t(
                    "customModals.addMaintenance.resumeDescConfLast"
                  )}`
                : vehicleSelected &&
                  `${t("customModals.addMaintenance.descConf")} ${
                    vehicleSelected?.alias
                  } - ${vehicleSelected?.plate}`
            }
          >
            <div className="split-line-edit-maintenance" />
            <ScheduleType
              defaultType={{
                typeName: defaultValues?.type,
                customType:
                  defaultValues?.additionalInfoJson &&
                  JSON.parse(defaultValues?.additionalInfoJson).customTypeName,
              }}
              types={types}
              getTypeIsEmpty={setTypeIsEmpty}
              open={isOpened === 0}
              getCustomType={(data) => {
                const defaultCustomTypeName =
                  (defaultValues?.additionalInfoJson &&
                    JSON.parse(defaultValues?.additionalInfoJson)
                      .customTypeName) ??
                  "";
                if (data !== defaultCustomTypeName) {
                  setIsEditedAdditionalInfo(true);
                  setEditMaintenanceInfo({
                    // setting the updated value
                    ...editMaintenanceInfo,
                    additionalInfoJson: JSON.stringify({
                      customTypeName: data,
                    }),
                  });
                } else {
                  setIsEditedAdditionalInfo(false);
                  setEditMaintenanceInfo({
                    // restoring the initial value
                    ...editMaintenanceInfo,
                    additionalInfoJson: defaultValues?.additionalInfoJson,
                  });
                }
              }}
              getValue={(data) => {
                if (data !== defaultValues?.type) {
                  setIsEditedType(true);
                  setEditMaintenanceInfo({
                    // setting the updated value
                    ...editMaintenanceInfo,
                    type: data,
                  });
                } else {
                  setIsEditedType(false);
                  setEditMaintenanceInfo({
                    // restoring the initial value
                    ...editMaintenanceInfo,
                    type: defaultValues.type,
                  });
                }
              }}
              setIsOpened={setIsOpened}
            />
            <div className="split-line-edit-maintenance" />
            {vehicleSelected?.type === "OPERATING_MACHINE" ? (
              <ScheduleWorkingHours
                defaultHour={{
                  value:
                    defaultValues?.expirationUtilizationMinutes -
                      defaultValues.currentUtilizationMinutes >
                    0
                      ? defaultValues?.expirationUtilizationMinutes -
                        defaultValues.currentUtilizationMinutes
                      : 0,
                  repeat: defaultValues?.rescheduleUtilizationMinutes ?? 0,
                }}
                open={isOpened === 6}
                getValue={(data) => {
                  const defaultCustomHour = {
                    value: defaultValues?.expirationUtilizationMinutes ?? 0,
                    repeat: defaultValues?.rescheduleUtilizationMinutes ?? 0,
                    isRepeated: Boolean(
                      defaultValues?.rescheduleUtilizationMinutes
                    ),
                  };
                  if (
                    data.hour !== defaultCustomHour.value ||
                    parseInt(data.repeat) !== defaultCustomHour.repeat ||
                    data.isRepeated !== defaultCustomHour.isRepeated
                  ) {
                    setIsEditedWorkingHours(true);
                    const totalHours =
                      data.hour +
                      (defaultValues?.currentUtilizationMinutes
                        ? defaultValues.currentUtilizationMinutes
                        : selectedVehicleStatus?.dynamicFields?.utilizationTime
                        ? selectedVehicleStatus.dynamicFields.utilizationTime
                        : 0);
                    setEditMaintenanceInfo({
                      // setting the updated value
                      ...editMaintenanceInfo,
                      rescheduleUtilizationMinutes: data.repeat,
                      expirationUtilizationMinutes: totalHours,
                    });
                  } else {
                    setIsEditedWorkingHours(false);
                    setEditMaintenanceInfo({
                      // restoring the initial value
                      ...editMaintenanceInfo,
                      rescheduleUtilizationMinutes:
                        defaultValues.rescheduleUtilizationMinutes,
                      expirationUtilizationMinutes:
                        defaultValues.expirationUtilizationMinutes,
                    });
                  }
                }}
                typeIsEmpty={typeIsEmpty}
                initialHours={
                  selectedVehicleStatus?.dynamicFields?.utilizationTime ?? 0
                }
                setIsOpened={setIsOpened}
              />
            ) : (
              <ScheduleDistance
                defaultDistance={{
                  value:
                    defaultValues?.expirationMeters -
                      (defaultValues?.currentOdometer ?? 0) >
                    0
                      ? mtToKm(
                          defaultValues?.expirationMeters -
                            (defaultValues?.currentOdometer ?? 0)
                        )
                      : 0,
                  repeat: defaultValues?.rescheduleMeters
                    ? mtToKm(defaultValues?.rescheduleMeters)
                    : 0,
                }}
                open={isOpened === 4}
                getValue={(data) => {
                  const defaultCustomDistance = {
                    value: defaultValues?.expirationMeters
                      ? mtToDynamicMeasure(
                          defaultValues?.rescheduleMeters,
                          preferencesContext.isMetric
                        )
                      : 0,
                    repeat: defaultValues?.rescheduleMeters
                      ? mtToDynamicMeasure(
                          defaultValues?.rescheduleMeters,
                          preferencesContext.isMetric
                        )
                      : 0,
                    isRepeated: Boolean(defaultValues?.rescheduleMeters),
                  };
                  if (
                    data.hour !== defaultCustomDistance.value ||
                    parseInt(data.repeat) !== defaultCustomDistance.repeat ||
                    data.isRepeated !== defaultCustomDistance.isRepeated
                  ) {
                    setIsEditedDistance(true);
                    const totalMeters =
                      getMeter(data.distance, preferencesContext.isMetric) +
                      (selectedVehicleStatus?.dynamicFields?.odometer
                        ? selectedVehicleStatus.dynamicFields.odometer
                        : defaultValues.currentOdometer
                        ? defaultValues.currentOdometer
                        : 0);
                    setEditMaintenanceInfo({
                      // setting the updated value
                      ...editMaintenanceInfo,
                      rescheduleMeters: getMeter(
                        data.repeat,
                        preferencesContext.isMetric
                      ),
                      expirationMeters: totalMeters,
                    });
                  } else {
                    setIsEditedDistance(false);
                    setEditMaintenanceInfo({
                      // restoring the initial value
                      ...editMaintenanceInfo,
                      rescheduleMeters: defaultValues.rescheduleMeters,
                      expirationMeters: defaultValues.expirationMeters,
                    });
                  }
                }}
                typeIsEmpty={typeIsEmpty}
                isMetric={preferencesContext.isMetric}
                initialDistance={
                  selectedVehicleStatus?.dynamicFields?.odometer ?? 0
                }
                setIsOpened={setIsOpened}
              />
            )}
            <div className="split-line-edit-maintenance" />
            <ScheduleDate
              defaultDate={
                defaultValues?.expirationDate
                  ? {
                      value:
                        defaultValues?.expirationDate.toString() ??
                        new Date().toString(),
                      repeat: defaultValues?.rescheduleMonths ?? 0,
                    }
                  : undefined
              }
              getValue={(data) => {
                const defaultCustomDate = defaultValues?.expirationDate
                  ? {
                      value:
                        defaultValues?.expirationDate.toString() ??
                        new Date().toString(),
                      repeat: defaultValues?.rescheduleMonths ?? 0,
                      isRepeated: Boolean(defaultValues?.rescheduleMonths),
                    }
                  : undefined;
                if (
                  (defaultCustomDate &&
                    data.date !==
                      dayjs(defaultCustomDate.value).format("YYYY-MM-DD")) ||
                  data.repeat !== defaultCustomDate?.repeat ||
                  data.isRepeated !== defaultCustomDate?.isRepeated
                ) {
                  setIsEditedDate(true);
                  setEditMaintenanceInfo({
                    // setting the updated value
                    ...editMaintenanceInfo,
                    rescheduleMonths: data.repeat,
                    expirationDate: data.date,
                  });
                } else {
                  setIsEditedDate(false);
                  setEditMaintenanceInfo({
                    // restoring the initial value
                    ...editMaintenanceInfo,
                    rescheduleMonths: defaultValues.rescheduleMonths,
                    expirationDate: defaultValues.expirationDate,
                  });
                }
              }}
              typeIsEmpty={typeIsEmpty}
              open={isOpened === 2}
              language={preferencesContext.language ?? "it"}
              localeFormat={localeFormatDate}
              setIsOpened={setIsOpened}
            />
            <div className="split-line-edit-maintenance" />
            <ScheduleNote
              defaultNote={defaultValues?.note}
              getValue={(data) => {
                if (data !== defaultValues.note) {
                  setIsEditedNote(true);
                  setEditMaintenanceInfo({
                    ...editMaintenanceInfo,
                    note: data, // setting the updated value
                  });
                } else {
                  setIsEditedNote(false);
                  setEditMaintenanceInfo({
                    ...editMaintenanceInfo,
                    note: defaultValues.note, // restoring the initial value
                  });
                }
              }}
              typeIsEmpty={typeIsEmpty}
              open={isOpened === 3}
              setIsOpened={setIsOpened}
            />
            <div className="split-line-edit-maintenance" />
          </ModalBody>
        </div>
        <ModalFooter
          primaryLabel={t("common.confirm")}
          primaryAction={handleSubmit}
          hasBack={true}
          disablePrimaryButton={
            !defaultValues.complete
              ? !(
                  isEditedAdditionalInfo ||
                  isEditedWorkingHours ||
                  isEditedDistance ||
                  isEditedNote ||
                  isEditedType ||
                  isEditedDate
                )
              : false || typeIsEmpty
          }
          secondaryLabel={t("common.cancel")}
          secondaryAction={() => {
            onClose();
          }}
        />
      </ModalWrapper>
    </div>
  );
};

EditMaintenanceModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  maintenanceId: PropTypes.number.isRequired,
  vehicleSelected: PropTypes.any,
};

EditMaintenanceModal.defaultProps = {
  open: false,
};
