import PropTypes from "prop-types";

import "./checkbox.css";

export const Checkbox = ({
  id,
  label,
  checked,
  name,
  onChange,
  disabled,
  className,
  value,
  subLabel,
  readOnly,
  color,
}) => {
  return (
    <>
      {/*htmlfor needs to check checkbox*/}
      <label htmlFor={id} className={["checkbox", className].join(" ")}>
        <input
          type="checkbox"
          name={name}
          checked={checked}
          onChange={onChange}
          disabled={disabled}
          value={value}
          readOnly={readOnly}
        />
        <span>
          {color && (
            <div
              className="color"
              style={{
                backgroundColor: color,
              }}
            />
          )}
          <div className="checkbox-label">{label}</div>
        </span>
        {subLabel && <p className="m-0">{subLabel}</p>}
      </label>
    </>
  );
};

Checkbox.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string,
  checked: PropTypes.bool,
  subLabel: PropTypes.string,
  color: PropTypes.string,
};

Checkbox.defaultProps = {
  label: "Checkbox",
  name: "",
  disabled: false,
  checked: false,
  subLabel: "",
};
