import {
  PayloadAction,
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import _ from "lodash";
import { normalize } from "normalizr";
import { RootState } from "../../app/store";
import { Config } from "../../config/Config";
import { getUserInfoAsync, getUsersInfoAsync } from "../users/usersInfoSlice";
import {
  getVehicleAsync,
  getVehiclesAsync,
} from "../vehicle/vehiclesStatusSlice";
import { tenantSchema } from "./tenantNormalization";
import TenantsRepository from "./tenantsRepository";

//#region Type

export type Tenant = {
  id: number;
  name: string;
  address: string;
  city: string;
  province: string;
  country: string;
  enabled: true;
  prefix: string;
  telephone: string;
  vatNumber: string;
  zip: string;
  partner: number;
};
//#endregion Type

//#region API

export const updateTenantAsync = createAsyncThunk(
  "tenants/updateTenant",
  async (data: { id: number; tenant: Tenant }) => {
    const tenantsRepository = new TenantsRepository();
    const response = await tenantsRepository.partiallyUpdateTenant(
      data.id,
      data.tenant
    );
    const tenant = _.get(response, Config.TENANT_RESPONSE_PATH);
    const normalizedData = normalize(tenant, tenantSchema);
    return normalizedData.entities;
  }
);
//#endregion API

//#region Slice
const tenantsAdapter = createEntityAdapter<Tenant>({
  selectId: (tenant) => tenant.id,
  sortComparer: (a, b) => a.name.localeCompare(b.name),
});

export const tenantSlice = createSlice({
  name: "tenants",
  initialState: tenantsAdapter.getInitialState({
    status: "idle",
    reasonCode: "",
  }),
  reducers: {
    tenantsEmptyState: (state) => {
      tenantsAdapter.setAll(state, []);
      state.reasonCode = "";
      state.status = "idle";
    },
  },
  extraReducers: (builder) => {
    builder
      //#region Entity Reducers
      // update Tenant
      .addCase(updateTenantAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(
        updateTenantAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          tenantsAdapter.upsertMany(state, action.payload.tenant);
          state.status = "idle";
        }
      )
      .addCase(updateTenantAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = "";
      })
      //#endregion Entity Reducers

      //#region External Entity Reducers
      .addCase(
        getUsersInfoAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          action.payload.tenant &&
            tenantsAdapter.upsertMany(state, action.payload.tenant);
        }
      )
      .addCase(
        getUserInfoAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          action.payload.tenant &&
            tenantsAdapter.upsertMany(state, action.payload.tenant);
        }
      )
      .addCase(
        getVehicleAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          action.payload.tenant &&
            tenantsAdapter.upsertMany(state, action.payload.tenant);
        }
      )
      .addCase(
        getVehiclesAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          action.payload.tenant &&
            tenantsAdapter.upsertMany(state, action.payload.tenant);
        }
      );
    //#endregion External Entity Reducers
  },
});
//#endregion Slice

//#region Status
export const tenantsSelectors = tenantsAdapter.getSelectors<RootState>(
  (state) => state.tenants
);
export const selectTenantsSliceReasonCode = (state: any) =>
  state.tenants.reasonCode;
export const { tenantsEmptyState } = tenantSlice.actions;
//#endregion Status

export default tenantSlice.reducer;
