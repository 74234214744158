import PropTypes from "prop-types";
import { useEffect, useState } from "react";

export const Tab = ({ activeTab, label, icon, onClickHandle, ...props }) => {
  const [coords, setCoords] = useState({ x: -1, y: -1 });
  const [isRippling, setIsRippling] = useState(false);

  const onClick = (e) => {
    const rect = e.target.getBoundingClientRect();
    setCoords({
      x: rect.width / 2,
      y: rect.height / 2,
    });
    if (onClickHandle !== undefined) {
      onClickHandle && onClickHandle(label);
    }
  };

  useEffect(() => {
    if (coords.x !== -1 && coords.y !== -1) {
      setIsRippling(true);
      setTimeout(() => setIsRippling(false), 300);
    } else {
      setIsRippling(false);
    }
  }, [coords]);

  useEffect(() => {
    if (!isRippling) {
      setCoords({ x: -1, y: -1 });
    }
  }, [isRippling]);

  let className = "mn-tabs__list-item";

  if (activeTab === label) {
    className += " mn-tabs__list-item--active";
  }

  return (
    <li className={className} onClick={onClick} {...props}>
      <span className="mn-tabs__label">
        {label}
        {icon && <span className="mn-tabs__label-icon">{icon}</span>}
        {activeTab === label
          ? <div className="mn-tabs__list-item-underline" />
          : <div className="mn-tabs__list-item-underline-inactive" />}
      </span>
      {isRippling &&
        <span className="ripple" style={{ left: coords.x, top: coords.y }} />}
    </li>
  );
};

Tab.propTypes = {
  activeTab: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onClickHandle: PropTypes.func.isRequired,
};
