import dayjs from "dayjs";
import { t } from "i18next";
import _ from "lodash";
import { useContext, useEffect, useMemo, useState } from "react";
import { useMatch, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { store } from "../../app/store";
import { GTFleetErrorCodes } from "../../config/GTfleetErrorCodes";
import PageContent from "../../layout/PageContent";
import PageFilters from "../../layout/PageFilters";
import { Button } from "../../ui/Button/Button";
import { Dropdown } from "../../ui/Dropdown/Dropdown";
import Form from "../../ui/Forms/Form";
import { Switch } from "../../ui/Forms/Switch";
import TextField from "../../ui/Forms/TextField";
import { Tooltip } from "../../ui/Forms/Tooltip";
import { ElementType } from "../../ui/Group/ElementTypeEnum";
import { IconCalendar } from "../../ui/Icon/Line/Calendar";
import { IconEdit } from "../../ui/Icon/Line/Edit";
import { TabSection } from "../../ui/Tabs/TabSection";
import { Tabs } from "../../ui/Tabs/Tabs";
import { Tag } from "../../ui/Tags/Tag";
import { ThumbProfile } from "../../ui/ThumbProfile/ThumbProfile";
import { DataCommon } from "../../utils/Common";
import { getFullDate } from "../../utils/DateAndTimeUtils";
import { ToastNotification } from "../../utils/ToastNotification";
import {
  getIllustrationFromVehicleType,
  getQueryString,
} from "../../utils/Utils";
import { AdminPrivilegesResourceTab } from "../components/AdminPrivilegesResourceTab";
import { Driver, driversSelectors } from "../driver/driversSlice";
import {
  DriverStatus,
  driversStatusSelectors,
  getFilteredDriversStatusAndDetailsAsync,
  selectDriverStatusSliceReasonCode,
  selectDriverStatusSliceStatus,
} from "../driver/driversStatusSlice";
import { ResourceProps } from "../fleet/AdminFleetsResourceTab";
import {
  Geofence,
  geofencesSelectors,
  getGeofencesAsync,
  selectGeofencesSliceReasonCode,
  selectGeofencesSliceStatus,
} from "../geofence/geofenceSlice";
import {
  GeofenceCategory,
  geofenceCategoriesSelectors,
  getGeofenceCategoriesAsync,
  selectGeofenceCategoriesSliceReasonCode,
  selectGeofenceCategoriesSliceStatus,
} from "../geofenceCategory/geofenceCategoriesSlice";
import { Vehicle, vehiclesSelectors } from "../vehicle/vehiclesSlice";
import {
  VehicleStatus,
  getVehiclesAsync,
  selectVehiclesStatusSliceReasonCode,
  selectVehiclesStatusSliceStatus,
  vehiclesStatusSelectors,
} from "../vehicle/vehiclesStatusSlice";
import "./User.css";
import { Preferences } from "./preference/preferencesSlice";
import {
  PrivilegesDefault,
  getPrivilegesDefaultAsync,
  privilegesDefaultSelectors,
} from "./privilege/privilegesDefaultSlice";
import {
  UserPermissions,
  privilegesEmptyState,
  selectPrivilegesSliceReasonCode,
  selectPrivilegesSliceStatus,
} from "./privilege/privilegesSlice";
import { securityProfilesEmptyState } from "./securityProfile/securityProfilesSlice";
import UserContext from "./userContext";
import {
  UserInfo,
  getUserInfoAsync,
  selectUsersInfoSliceReasonCode,
  selectUsersInfoSliceStatus,
  userInfoSelectors,
  usersInfoEmptyState,
} from "./usersInfoSlice";

interface ViewUserProps {
  permissions: UserPermissions;
}

interface DataCommonProps {
  label: string;
}

const ViewUser: React.FC<ViewUserProps> = ({ permissions }) => {
  const navigate = useNavigate();
  const viewUserRoute = useMatch("/admin/users/view/:userId");

  const [id, setId] = useState<number>(-1);
  const phonePrefixList: DataCommonProps[] =
    DataCommon()?.prefixList ?? ([] as DataCommonProps[]);

  const userInfo: UserInfo =
    useAppSelector((state) => userInfoSelectors.selectById(state, id)) ??
    ({} as UserInfo);
  const userSliceStatus = useAppSelector(selectUsersInfoSliceStatus);
  const userSliceReasonCode = useAppSelector(selectUsersInfoSliceReasonCode);

  let privilegesDefault: PrivilegesDefault[] =
    useAppSelector((state: any) =>
      privilegesDefaultSelectors.selectAll(state)
    ) ?? ({} as PrivilegesDefault);

  const [preferencesContext]: [Preferences] = useContext(UserContext);
  const privilegesSliceStatus = useAppSelector(selectPrivilegesSliceStatus);
  const privilegesSliceReasonCode = useAppSelector(
    selectPrivilegesSliceReasonCode
  );
  const [vehiclesCanSee, setVehiclesCanSee] = useState<ResourceProps[]>(
    [] as ResourceProps[]
  );
  const [driversCanSee, setDriversCanSee] = useState<ResourceProps[]>(
    [] as ResourceProps[]
  );
  const [geofencesCanSee, setGeofencesCanSee] = useState<ResourceProps[]>(
    [] as ResourceProps[]
  );

  document.title = useMemo(() => {
    if (userInfo?.username) {
      return (
        `${userInfo?.username} - ` +
        t("admin.user.editViewCreate.editAccount") +
        " - Admin"
      );
    } else {
      return t("admin.user.editViewCreate.editAccount") + " - Admin";
    }
  }, [userInfo]);

  //#region vehicles variables
  const [vehicesCannotSee, setVehiclesCannotSee] = useState(
    [] as ResourceProps[]
  );

  let vehicles: Vehicle[] = useAppSelector((state) =>
    vehiclesSelectors.selectAll(state)
  );

  let vehiclesStatus: VehicleStatus[] = useAppSelector((state) =>
    vehiclesStatusSelectors.selectAll(state)
  );
  const vehiclesStatusSliceStatus = useAppSelector(
    selectVehiclesStatusSliceStatus
  );
  const vehiclesStatusSliceReasonCode = useAppSelector(
    selectVehiclesStatusSliceReasonCode
  );

  /**
   * This useEffect separates into two list (can see and cannot se) the downloaded vehicles
   * on the basis of the current editing user privileges.
   */
  useEffect(() => {
    if (
      vehicles.length > 0 &&
      vehiclesStatus.length > 0 &&
      !_.isEmpty(userInfo)
    ) {
      const vehiclesForPrivilegesTab = vehicles.map((vehicle) => {
        const vehicleStatus = vehiclesStatus.find((x) => x.id === vehicle.id);
        return {
          key: vehicle.id,
          id: vehicle.id,
          firstTitle: vehicle.alias,
          secondTitle: vehicle.plate,
          firstSubtitle: vehicle.status,
          secondSubtitle: getFullDate(
            vehicleStatus?.dynamicFields?.lastUpdate,
            false
          ),
          type: ElementType.vehicle,
          icon: getIllustrationFromVehicleType(vehicle),
        } as ResourceProps;
      });
      if (vehiclesForPrivilegesTab.length > 0 && permissions.vehicles.write) {
        const vehiclesPrivileges = userInfo.privileges
          .filter((x) => x.entityType === "Vehicles")
          .map((x) => x.entityId);
        const vehiclesCannotSeeTemp = vehiclesForPrivilegesTab.filter((el) => {
          return !vehiclesPrivileges.includes(el.id);
        });

        setVehiclesCannotSee(
          vehiclesCannotSeeTemp.length > 0
            ? vehiclesCannotSeeTemp
            : vehiclesForPrivilegesTab
        );

        let vehiclesCanSeeTemp = vehiclesForPrivilegesTab.filter((el) => {
          return userInfo.privileges.some(
            (privilege) =>
              privilege.entityType === "Vehicles" &&
              el.id === privilege.entityId
          );
        });

        setVehiclesCanSee(vehiclesCanSeeTemp);
      }
    }
  }, [vehicles, vehiclesStatus, userInfo]);
  //#endregion

  //#region drivers variables
  const [driversCannotSee, setDriversCannotSee] = useState(
    [] as ResourceProps[]
  );
  let drivers: Driver[] = useAppSelector((state) =>
    driversSelectors.selectAll(state)
  );

  let driversStatus: DriverStatus[] = useAppSelector((state) =>
    driversStatusSelectors.selectAll(state)
  );
  const driversStatusSliceStatus = useAppSelector(
    selectDriverStatusSliceStatus
  );
  const driversStatusSliceReasonCode = useAppSelector(
    selectDriverStatusSliceReasonCode
  );

  /**
   * This useEffect separates into two list (can see and cannot se) the downloaded drivers
   * on the basis of the current editing user privileges.
   */
  useEffect(() => {
    if (
      drivers.length > 0 &&
      driversStatus.length > 0 &&
      !_.isEmpty(userInfo)
    ) {
      const driversForPrivilegesTab = drivers.map((driver) => {
        const driverStatus = driversStatus.find((x) => x.id === driver.id);
        return {
          key: driver.id,
          id: driver.id,
          firstTitle: `${driver.firstName} ${driver.lastName}`,
          firstSubtitle: driver.status,
          secondSubtitle: getFullDate(
            driverStatus?.dynamicFields?.lastUpdate,
            false
          ),
          type: ElementType.driver,
          icon: (
            <ThumbProfile
              size="small"
              alt="Driver Thumbnail"
              imgUrl={driver.avatar}
            />
          ),
        } as ResourceProps;
      });
      if (driversForPrivilegesTab.length > 0 && permissions.drivers.write) {
        const driversPrivileges = userInfo.privileges
          .filter((x) => x.entityType === "Drivers")
          .map((x) => x.entityId);
        const driversCannotSeeTemp = driversForPrivilegesTab.filter((el) => {
          return !driversPrivileges.includes(el.id);
        });

        setDriversCannotSee(
          driversCannotSeeTemp.length > 0
            ? driversCannotSeeTemp
            : driversForPrivilegesTab
        );

        let driverCanSeeTemp = driversForPrivilegesTab.filter((el) => {
          return userInfo.privileges.some(
            (privilege) =>
              privilege.entityType === "Drivers" && el.id === privilege.entityId
          );
        });

        setDriversCanSee(driverCanSeeTemp);
      }
    }
  }, [drivers, driversStatus, userInfo]);
  //#endregion

  //#region geofences variables
  const [geofencesCannotSee, setGeofencesCannotSee] = useState(
    [] as ResourceProps[]
  );
  let geofences: Geofence[] = useAppSelector(geofencesSelectors.selectAll);
  let geofenceCategories: GeofenceCategory[] = useAppSelector(
    geofenceCategoriesSelectors.selectAll
  );
  const geofenceCategoriesSliceStatus = useAppSelector(
    selectGeofenceCategoriesSliceStatus
  );
  const geofenceCategoriesSliceReasonCode = useAppSelector(
    selectGeofenceCategoriesSliceReasonCode
  );

  const geofencesSliceStatus = useAppSelector(selectGeofencesSliceStatus);
  const geofencesSliceReasonCode = useAppSelector(
    selectGeofencesSliceReasonCode
  );
  /**
   * This useEffect separates into two list (can see and cannot se) the downloaded geofences
   * on the basis of the current editing user privileges.
   */
  useEffect(() => {
    if (
      geofences.length > 0 &&
      geofenceCategories.length > 0 &&
      !_.isEmpty(userInfo)
    ) {
      const geofencesForPrivilegesTab = geofences.map((geofence) => {
        const geofenceCategory = geofenceCategories.find(
          (x) => x.id === geofence.geofenceCategory
        );
        const resourceProp = {
          key: geofence.id,
          id: geofence.id,
          firstTitle: geofence.name,
          secondTitle: geofenceCategory?.name,
          type: ElementType.geofence,
          icon: getIllustrationFromVehicleType(geofence),
          categoryColor: geofenceCategory?.color,
        } as ResourceProps;
        return resourceProp;
      });
      if (geofencesForPrivilegesTab.length > 0 && permissions.geofences.write) {
        const geofencesPrivileges = userInfo.privileges
          .filter((x) => x.entityType === "Geofences")
          .map((x) => x.entityId);
        const geofencesCannotSeeTemp = geofencesForPrivilegesTab.filter(
          (el) => {
            return !geofencesPrivileges.includes(el.id);
          }
        );

        setGeofencesCannotSee(
          geofencesCannotSeeTemp.length > 0
            ? geofencesCannotSeeTemp
            : geofencesForPrivilegesTab
        );
        let geofencesCanSeeTemp = geofencesForPrivilegesTab.filter((el) => {
          return userInfo.privileges.some(
            (privilege) =>
              privilege.entityType === "Geofences" &&
              el.id === privilege.entityId
          );
        });

        setGeofencesCanSee(geofencesCanSeeTemp);
      }
    }
  }, [geofences, geofenceCategories, userInfo]);
  //#endregion

  //#region Toast notifications
  useEffect(() => {
    if (userSliceStatus === "failed") {
      switch (userSliceReasonCode) {
        case GTFleetErrorCodes.USERNAME_NOT_FOUND:
          ToastNotification({
            toastId: "userNotFoundError",
            status: "error",
            description: t("common.userNotFoundError"),
          });
          break;
        case GTFleetErrorCodes.USER_UNAUTHORIZED:
          ToastNotification({
            toastId: "unauthorizedError",
            status: "error",
            description: t("common.unauthorizedError"),
          });
          break;
        default:
          ToastNotification({
            toastId: "networkError",
            status: "error",
            description: t("common.networkError"),
          });
          break;
      }
      navigate("/admin/users");
    }
  }, [userSliceStatus, userSliceReasonCode, navigate]);

  useEffect(() => {
    if (
      privilegesSliceStatus === "failed" &&
      privilegesSliceReasonCode === ""
    ) {
      ToastNotification({
        toastId: "networkError",
        status: "error",
        description: t("common.networkError"),
      });
    }
  }, [privilegesSliceStatus, privilegesSliceReasonCode]);

  useEffect(() => {
    if (
      driversStatusSliceStatus === "failed" &&
      driversStatusSliceReasonCode === ""
    ) {
      ToastNotification({
        toastId: "networkError",
        status: "error",
        description: t("common.networkError"),
      });
    }
  }, [driversStatusSliceStatus, driversStatusSliceReasonCode]);

  useEffect(() => {
    if (
      vehiclesStatusSliceStatus === "failed" &&
      vehiclesStatusSliceReasonCode === ""
    ) {
      ToastNotification({
        toastId: "networkError",
        status: "error",
        description: t("common.networkError"),
      });
    }
  }, [vehiclesStatusSliceStatus, vehiclesStatusSliceReasonCode]);

  useEffect(() => {
    if (geofenceCategoriesSliceStatus === "failed") {
      if (geofenceCategoriesSliceReasonCode === "") {
        ToastNotification({
          toastId: "networkError",
          status: "error",
          description: t("common.networkError"),
        });
      }
    }
  }, [geofenceCategoriesSliceStatus, geofenceCategoriesSliceReasonCode]);

  useEffect(() => {
    if (geofencesSliceStatus === "failed") {
      if (geofencesSliceReasonCode === "") {
        ToastNotification({
          toastId: "networkError",
          status: "error",
          description: t("common.networkError"),
        });
      }
    }
  }, [geofencesSliceStatus, geofencesSliceReasonCode]);
  //#endregion

  //#region Upload Data
  useEffect(() => {
    if (viewUserRoute !== null) {
      if (viewUserRoute.params.userId) {
        setId(parseInt(viewUserRoute.params.userId));
        store.dispatch(
          getUserInfoAsync({
            id: Number(viewUserRoute.params.userId),
          })
        );
        return;
      }
    }
  }, [navigate, viewUserRoute]);

  useEffect(() => {
    if (
      privilegesDefault.some((x) => x.entityType === "Vehicles") &&
      permissions.vehicles.read
    ) {
      store.dispatch(getVehiclesAsync());
    }
    if (
      privilegesDefault.some((x) => x.entityType === "Drivers") &&
      permissions.drivers.read
    ) {
      store.dispatch(getFilteredDriversStatusAndDetailsAsync(""));
    }
    if (
      privilegesDefault.some((x) => x.entityType === "Geofences") &&
      permissions.geofences.read
    ) {
      store.dispatch(
        getGeofencesAsync({ queryParams: getQueryString({ status: "ACTIVE" }) })
      );
      store.dispatch(getGeofenceCategoriesAsync());
    }
  }, [privilegesDefault]);

  //#endregion

  //#region security profile - fuction
  let write: string[] = [];
  let read: string[] = [];

  useEffect(() => {
    document.title = t("admin.user.editViewCreate.viewAccount") + " - Admin";
    return function cleanUp() {
      store.dispatch(privilegesEmptyState());
      store.dispatch(usersInfoEmptyState());
      store.dispatch(securityProfilesEmptyState());
    };
  }, []);

  useEffect(() => {
    if (userInfo.securityProfile?.id !== undefined) {
      store.dispatch(
        getPrivilegesDefaultAsync({
          id: userInfo.securityProfile?.id,
        })
      );
    }
  }, [userInfo.securityProfile?.id]);

  privilegesDefault.forEach((privilegeDefault) => {
    if (
      privilegeDefault.permission === "WRITE" &&
      permissions[
        privilegeDefault.entityType.toLowerCase() as keyof UserPermissions
      ]?.write
    ) {
      write.push(t("admin.user.editViewCreate." + privilegeDefault.entityType));
    } else if (
      permissions[
        privilegeDefault.entityType.toLowerCase() as keyof UserPermissions
      ]?.read
    ) {
      read.push(t("admin.user.editViewCreate." + privilegeDefault.entityType));
    }
  });
  //#endregion

  //#region Render

  const translateSecurityProfiles = (securityProfile: string) => {
    switch (securityProfile) {
      case "FLEET_MANAGER":
        return t("admin.user.profile.fleet");
      case "MAINTENANCE_MANAGER":
        return t("admin.user.profile.maintenance");
      case "DRIVER":
        return t("admin.user.profile.driver");
      case "BASIC":
        return t("admin.user.profile.basic");
      default:
        return t("admin.user.profile.unknown");
    }
  };

  //#endregion

  return (
    <>
      <PageFilters>
        <div className="col col-16">
          <div className="drvr-subtopbar">
            {!!userInfo && !_.isEmpty(userInfo) && (
              <div className="drvr-subsection-name">
                <div className="drvr-no-avatar">{userInfo.username}</div>
              </div>
            )}
            <div className="button-save-group drvr-buttons">
              <Button
                size="small"
                aspect="secondary"
                label={t("admin.user.editViewCreate.allUser")}
                onClick={() => navigate("/admin/users")}
              />
              {permissions.users.write && (
                <Button
                  size="small"
                  aspect="primary"
                  label={t("common.edit")}
                  onClick={() => {
                    navigate("/admin/users/edit/" + id);
                  }}
                >
                  <IconEdit size={14} color="--global-colors-ui-white" />
                </Button>
              )}
            </div>
          </div>
        </div>
      </PageFilters>
      <PageContent>
        <div className="ed-form-container">
          {
            //#region General
          }
          <div className="drvr-info-form">
            <div className="drvr-forms-title">
              {t("admin.driver.editViewCreate.general")}
            </div>

            <div className="drvr-forms-container">
              <div className="drvr-half-form">
                <div className="drvr-forms-label">{t("common.name")} *</div>
                <Form>
                  <div className="drvr-forms">
                    <div className="drvr-left-form-name drvr-only-view">
                      <TextField
                        id="Name"
                        validate="alphabetic|isNotEmpty"
                        placeholder={
                          userInfo.firstName ? t("common.name") : t("common.na")
                        }
                        value={userInfo.firstName ?? null}
                      ></TextField>
                    </div>
                  </div>
                </Form>
              </div>
              <div className="drvr-half-form">
                <div className="drvr-right-forms-label">
                  {t("common.surname")} *
                </div>
                <Form>
                  <div className="drvr-forms">
                    <div className="drvr-right-form-item drvr-only-view">
                      <TextField
                        id="Surname"
                        validate="alphabetic|isNotEmpty"
                        placeholder={
                          userInfo.secondName
                            ? t("common.surname")
                            : t("common.na")
                        }
                        value={userInfo.secondName ?? null}
                      ></TextField>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
            <div className="drvr-forms-container">
              <div className="drvr-forms-label">{t("common.username")} *</div>
              <Form>
                <div className="drvr-forms">
                  <div className="left-form drvr-only-view">
                    <TextField
                      id="Username"
                      placeholder={
                        userInfo.username ? t("common.address") : t("common.na")
                      }
                      validate="isNotEmpty"
                      value={userInfo.username ?? null}
                    ></TextField>
                  </div>
                </div>
              </Form>
            </div>
            <div className="drvr-forms-container">
              <div className="drvr-forms-label">{t("common.email")} *</div>
              <Form>
                <div className="drvr-forms">
                  <div className="drvr-left-form drvr-only-view">
                    <TextField
                      id="driver-email"
                      validate="email|isNotEmpty"
                      type="email"
                      placeholder={
                        userInfo.email ? t("common.email") : t("common.na")
                      }
                      value={userInfo.email ?? null}
                    ></TextField>
                  </div>
                </div>
              </Form>
            </div>
            <div className="drvr-forms-container">
              <div className="drvr-forms-label">{t("common.phone")}</div>
              <Form>
                <div className="drvr-forms">
                  <div className="user-left-form-phone">
                    <div className="drvr-drpdwn-small drvr-only-view">
                      <Dropdown
                        isClearable={false}
                        placeholder={
                          userInfo.prefix ? t("common.phone") : t("common.na")
                        }
                        value={{
                          label: userInfo.prefix ?? undefined,
                        }}
                        size="normal"
                        itemAttribute="label"
                        onChange={(val) => {
                          console.log(val);
                        }}
                        options={phonePrefixList}
                      />
                    </div>
                  </div>
                  <div className="user-right-form-phone drvr-only-view">
                    <TextField
                      id="Number"
                      validate="phoneNumber"
                      placeholder={
                        userInfo.telephone ? t("common.phone") : t("common.na")
                      }
                      value={userInfo.telephone ?? null}
                    ></TextField>
                  </div>
                </div>
              </Form>
            </div>
          </div>
          {
            //#endregion
          }

          {
            //#region Security
          }
          <div className="drvr-info-form">
            <div className="drvr-forms-title">
              {t("admin.user.editViewCreate.security")}
            </div>
            <div className="drvr-forms-container">
              <div className="dvr-half-permission">
                <div className="drvr-forms-label">{t("common.password")} *</div>
              </div>
              <div className="drvr-half-form drvr-half-form-permission">
                <Form className="form-permission">
                  <div className="drvr-forms drvr-forms-permission">
                    <div className="drvr-left-form-name drvr-only-view">
                      <TextField
                        id="Password"
                        placeholder={t("common.password")}
                        value="********"
                        type="password"
                      ></TextField>
                    </div>
                  </div>
                </Form>
                <Form className="form-permission">
                  <div className="drvr-forms drvr-forms-permission">
                    <div className="drvr-right-form-item drvr-only-view">
                      <TextField
                        id="Repeatpassword"
                        placeholder={t(
                          "admin.user.editViewCreate.repeatPassword"
                        )}
                        value="********"
                        type="password"
                      ></TextField>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
            <div className="drvr-forms-container">
              <Tooltip />
              <span
                data-for="tooltip"
                data-tip={t("common.warningActivationDate")}
              >
                <div className="drvr-forms-label">
                  {t("admin.user.editViewCreate.activationDate")}
                </div>
              </span>
              <Form>
                <div className="drvr-forms">
                  <div className="drvr-left-form-name drvr-only-view">
                    <TextField
                      id="activationDate"
                      placeholder={
                        userInfo.activationDate
                          ? t("common.phone")
                          : t("common.na")
                      }
                      value={
                        dayjs(userInfo.activationDate).format(
                          preferencesContext.localeFormat
                        ) ?? null
                      }
                    >
                      <IconCalendar
                        size={14}
                        color="--global-colors-stuserInfoatus-stopped"
                      />
                    </TextField>
                  </div>
                </div>
              </Form>
            </div>
            <div className="drvr-forms-container">
              <div className="drvr-forms-label">
                {t("admin.user.editViewCreate.expirationDate")}
              </div>
              <Form>
                <div className="drvr-forms">
                  <div className="drvr-left-form-name drvr-only-view">
                    <TextField
                      id="expirationDate"
                      placeholder={
                        userInfo.expirationDate
                          ? t("admin.user.editViewCreate.expirationDate")
                          : t("common.na")
                      }
                      value={
                        userInfo.expirationDate
                          ? dayjs(userInfo.expirationDate).format(
                              preferencesContext.localeFormat
                            )
                          : `${t("admin.user.editViewCreate.never")}`
                      }
                    >
                      <IconCalendar
                        size={14}
                        color="--global-colors-status-stopped"
                      />
                    </TextField>
                  </div>
                </div>
              </Form>
            </div>
          </div>
          {
            //#endregion
          }

          {
            //#region Profile
          }
          <div className="drvr-info-form">
            <div className="drvr-forms-title">
              {t("admin.user.editViewCreate.profile")}
            </div>

            <div className="drvr-forms-container">
              <div className="drvr-forms-label">
                {t("admin.user.editViewCreate.role")}
              </div>
              <Form>
                <div className="drvr-forms">
                  <div className="drvr-left-form drvr-only-view">
                    <Dropdown
                      isClearable={false}
                      placeholder={
                        userInfo.securityProfile?.name &&
                        translateSecurityProfiles(
                          userInfo.securityProfile?.name
                        )
                          ? t("admin.user.editViewCreate.role")
                          : t("common.na")
                      }
                      value={{
                        label:
                          (userInfo.securityProfile?.name &&
                            translateSecurityProfiles(
                              userInfo.securityProfile?.name
                            )) ??
                          undefined,
                      }}
                      size="normal"
                      itemAttribute="label"
                      onChange={(val) => {
                        console.log(val);
                      }}
                      options={[]}
                    />
                  </div>
                </div>
              </Form>
            </div>
            <div className="drvr-forms-container drvr-forms-function">
              <div className="drvr-forms-label">
                {t("admin.user.editViewCreate.functions")}
              </div>
              <Form className="form-function">
                <div className="drvr-forms">
                  <div className="drvr-left-form drvr-user">
                    {write.length > 0 ? (
                      <div className="drvr-onblock">
                        <div
                          className="mn-label-text__text mn-label-text__text-function"
                          key={"write"}
                        >
                          {t("admin.user.editViewCreate.read&Write")}
                        </div>
                        <div className="drvr-user-fuction drvr-user-fuction-first">
                          {write.map((privilege) => {
                            return (
                              <Tag
                                key={"write_" + privilege}
                                text={privilege}
                                mode="outline"
                                type="neutro"
                              />
                            );
                          })}
                        </div>
                      </div>
                    ) : null}
                    {read.length > 0 ? (
                      <div className="drvr-onblock">
                        <div
                          className="mn-label-text__text mn-label-text__text-function"
                          key={"read"}
                        >
                          {t("admin.user.editViewCreate.read")}
                        </div>
                        <div className="drvr-user-fuction drvr-user-fuction-second">
                          {read.map((privilege) => {
                            return (
                              <Tag
                                key={"read_" + privilege}
                                text={privilege}
                                mode="outline"
                                type="neutro"
                              />
                            );
                          })}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </Form>
            </div>
            {privilegesDefault.length > 0 && (
              <div className="drvr-forms-container drvr-forms-function">
                <div className="drvr-forms-label">
                  {t("admin.user.editViewCreate.managePermissions")}
                </div>
                <Form className="form-function">
                  <div className="drvr-forms">
                    <div className="drvr-left-form drvr-user">
                      <Switch
                        checked={userInfo.privileges?.length > 0 ?? false}
                        disabled={true}
                        label=""
                      />
                    </div>
                  </div>
                </Form>
              </div>
            )}
            {userInfo.privileges?.length > 0
              ? (vehicesCannotSee.length > 0 ||
                  driversCannotSee.length > 0 ||
                  geofencesCannotSee.length > 0) && (
                  <div className="privileges-tabs-container">
                    <Tabs enableTab="Vehicles">
                      {privilegesDefault.some(
                        (privilegeDefault) =>
                          privilegeDefault.entityType === "Vehicles"
                      ) && permissions.vehicles.read ? (
                        <TabSection label="Vehicles">
                          <AdminPrivilegesResourceTab
                            tabType="vehicles"
                            customTitleFirstGroup={t(
                              "admin.user.editViewCreate.customTitleFirstGroup"
                            ).replace(
                              "$$",
                              userInfo.username ?? t("common.user")
                            )}
                            customTitleSecondGroup={t(
                              "admin.user.editViewCreate.customTitleSecondGroup"
                            ).replace(
                              "$$",
                              userInfo.username ?? t("common.user")
                            )}
                            rightGroupProp={vehicesCannotSee}
                            leftGroupProp={vehiclesCanSee}
                            leftButtonText={t(
                              "admin.groups.editGroups.widgetButtonLabelRemove"
                            )}
                            rightButtonText={t(
                              "admin.groups.editGroups.widgetButtonLabelAdd"
                            )}
                            output={() => {}}
                            disabled={true}
                          />
                        </TabSection>
                      ) : null}
                      {privilegesDefault.some(
                        (privilegeDefault) =>
                          privilegeDefault.entityType === "Drivers"
                      ) && permissions.drivers.read ? (
                        <TabSection label="Drivers">
                          {
                            <AdminPrivilegesResourceTab
                              tabType="drivers"
                              customTitleFirstGroup={t(
                                "admin.user.editViewCreate.customTitleFirstGroup"
                              ).replace(
                                "$$",
                                userInfo.username ?? t("common.user")
                              )}
                              customTitleSecondGroup={t(
                                "admin.user.editViewCreate.customTitleSecondGroup"
                              ).replace(
                                "$$",
                                userInfo.username ?? t("common.user")
                              )}
                              rightGroupProp={driversCannotSee}
                              leftGroupProp={driversCanSee}
                              leftButtonText={t(
                                "admin.groups.editGroups.widgetButtonLabelRemove"
                              )}
                              rightButtonText={t(
                                "admin.groups.editGroups.widgetButtonLabelAdd"
                              )}
                              output={() => {}}
                              disabled={true}
                            />
                          }
                        </TabSection>
                      ) : null}
                      {privilegesDefault.some(
                        (privilegeDefault) =>
                          privilegeDefault.entityType === "Geofences"
                      ) && permissions.geofences.read ? (
                        <TabSection label="Geofences">
                          <AdminPrivilegesResourceTab
                            customTitleFirstGroup={t(
                              "admin.user.editViewCreate.customTitleFirstGroup"
                            ).replace(
                              "$$",
                              userInfo.username ?? t("common.user")
                            )}
                            customTitleSecondGroup={t(
                              "admin.user.editViewCreate.customTitleSecondGroup"
                            ).replace(
                              "$$",
                              userInfo.username ?? t("common.user")
                            )}
                            tabType="geofences"
                            rightGroupProp={geofencesCannotSee}
                            leftGroupProp={geofencesCanSee}
                            leftButtonText={t(
                              "admin.groups.editGroups.widgetButtonLabelRemove"
                            )}
                            rightButtonText={t(
                              "admin.groups.editGroups.widgetButtonLabelAdd"
                            )}
                            output={() => {}}
                            disabled={true}
                          />
                        </TabSection>
                      ) : null}
                    </Tabs>
                  </div>
                )
              : null}
          </div>
          {
            //#endregion
          }
        </div>
      </PageContent>
    </>
  );
};
export default ViewUser;
