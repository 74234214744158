import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import _ from "lodash";
import { normalize } from "normalizr";
import { RootState } from "../../app/store";
import { Config } from "../../config/Config";
import { GTFleetErrorCodes } from "../../config/GTfleetErrorCodes";
import { GTFleetSuccessCodes } from "../../config/GTFleetSuccessCodes";
import { getErrorCodes } from "../../utils/Utils";
import { getFleetAsync } from "../fleet/fleetsSlice";
import { geofenceSchema, geofencesSchema } from "./geofenceNormalization";
import GeofencesRepository from "./geofencesRepository";

//#region Type
export const GeofenceStatusEnum = {
  ACTIVE: "ACTIVE",
  INACTIVE: "INACTIVE",
  DELETED: "DELETED",
};
export type StatusType = keyof typeof GeofenceStatusEnum;

export enum GeofenceShapeEnum {
  CIRCLE,
  POLYGON,
}

export type Position = {
  lat: number;
  lng: number;
};

export type Polygon = {
  center: Position;
  points: Position[];
};

export type Circle = {
  center: Position;
  radius: number;
};

export type Geofence = {
  id: number;
  name: string;
  geofenceShapeEnum: GeofenceShapeEnum;
  shape: Polygon | Circle;
  geofenceCategory: number;
  tenant: number;
  geofenceStatus: StatusType;
};

//#endregion Type

//#region Slice
const geofencesAdapter = createEntityAdapter<Geofence>({
  selectId: (geofence) => geofence.id,
});

export const getGeofencesAsync = createAsyncThunk(
  "geofences/getGeofences",
  async (data: { queryParams?: string }, { rejectWithValue }) => {
    try {
      const geofencesRepository = new GeofencesRepository();
      const response = await geofencesRepository.getGeofences(
        data?.queryParams
      );
      const geofences = _.get(response, Config.GEOFENCES_RESPONSE_PATH);
      const normalizedData = normalize(geofences, geofencesSchema);
      return normalizedData.entities;
    } catch (error: any) {
      if (!error.response) throw error;
      return rejectWithValue(error.response.status);
    }
  }
);

export const getGeofenceAsync = createAsyncThunk(
  "geofences/getGeofence",
  async (geofenceId: number, { rejectWithValue }) => {
    try {
      const geofencesRepository = new GeofencesRepository();
      const response = await geofencesRepository.getGeofence(geofenceId);
      const geofences = _.get(response, Config.GEOFENCES_RESPONSE_PATH);
      const normalizedData = normalize(geofences, geofencesSchema);
      return normalizedData.entities;
    } catch (error: any) {
      if (!error.response) throw error;
      return rejectWithValue(error.response.status);
    }
  }
);

export const getFilteredGeofencesAsync = createAsyncThunk(
  "geofences/getFilteredGeofencesAsync",
  async (data: { queryParams?: string }, { dispatch, rejectWithValue }) => {
    try {
      const geofencesRepository = new GeofencesRepository();
      const response = await geofencesRepository.getFilteredGeofences(
        data.queryParams
      );
      const geofences = _.get(response, Config.GEOFENCES_RESPONSE_PATH);
      const totalPages = _.get(
        response,
        Config.GEOFENCES_TOTAL_PAGES_RESPONSE_PATH
      );
      const totalElements = _.get(
        response,
        Config.GEOFENCES_TOTAL_ELEMENTS_RESPONSE_PATH
      );
      if (totalPages) {
        dispatch(setNumberOfPages(totalPages));
      }
      if (totalElements) {
        dispatch(setNumberOfElements(totalElements));
      }
      const normalizedData = normalize(geofences, geofencesSchema);
      return normalizedData.entities;
    } catch (error: any) {
      if (!error.response) throw error;
      return rejectWithValue(error.response.status);
    }
  }
);

export const createGeofenceAsync = createAsyncThunk(
  "geofences/createGeofence",
  async (geofenceObj: Partial<Geofence>, { rejectWithValue }) => {
    try {
      const geofenceRepository = new GeofencesRepository();
      const response = await geofenceRepository.createGeofence(geofenceObj);
      const geofences = _.get(response, Config.GEOFENCE_RESPONSE_PATH);
      const normalizedData = normalize(geofences, geofenceSchema);
      return normalizedData.entities;
    } catch (error: any) {
      if (!error.response) throw error;
      return rejectWithValue(error.response.status);
    }
  }
);

export const updateGeofenceAsync = createAsyncThunk(
  "geofences/updateGeofence",
  async (
    data: { geofenceId: number; geofenceObj: Partial<Geofence> },
    { rejectWithValue }
  ) => {
    try {
      const geofencesRepository = new GeofencesRepository();
      const response = await geofencesRepository.updateGeofence(
        data.geofenceId,
        data.geofenceObj as Geofence
      );
      const geofences = _.get(response, Config.GEOFENCE_RESPONSE_PATH);
      const normalizedData = normalize(geofences, geofenceSchema);
      return normalizedData.entities;
    } catch (error: any) {
      if (!error.response) throw error;
      return rejectWithValue(error.response.status);
    }
  }
);

export const activateGeofenceAsync = createAsyncThunk(
  "geofences/activateGeofence",
  async (geofenceId: number, { rejectWithValue }) => {
    try {
      const geofencesRepository = new GeofencesRepository();
      const response = await geofencesRepository.updateGeofence(geofenceId, {
        geofenceStatus: "ACTIVE",
      } as Geofence);
      const geofences = _.get(response, Config.GEOFENCE_RESPONSE_PATH);
      const normalizedData = normalize(geofences, geofenceSchema);
      return normalizedData.entities;
    } catch (error: any) {
      if (!error.response) throw error;
      return rejectWithValue(error.response.status);
    }
  }
);

export const deactivateGeofenceAsync = createAsyncThunk(
  "geofences/deactivateGeofence",
  async (geofenceId: number, { rejectWithValue }) => {
    try {
      const geofencesRepository = new GeofencesRepository();
      const response = await geofencesRepository.updateGeofence(geofenceId, {
        geofenceStatus: "INACTIVE",
      } as Geofence);
      const geofences = _.get(response, Config.GEOFENCE_RESPONSE_PATH);
      const normalizedData = normalize(geofences, geofenceSchema);
      return normalizedData.entities;
    } catch (error: any) {
      if (!error.response) throw error;
      return rejectWithValue(error.response.status);
    }
  }
);

export const deleteGeofenceAsync = createAsyncThunk(
  "geofences/deleteGeofence",
  async (geofenceId: number, { rejectWithValue }) => {
    try {
      const geofencesRepository = new GeofencesRepository();
      const response = await geofencesRepository.updateGeofence(geofenceId, {
        geofenceStatus: "DELETED",
      } as Geofence);
      const geofences = _.get(response, Config.GEOFENCE_RESPONSE_PATH);
      const normalizedData = normalize(geofences, geofenceSchema);
      return normalizedData.entities;
    } catch (error: any) {
      if (!error.response) throw error;
      return rejectWithValue(error.response.status);
    }
  }
);

//#region Custom Functions
function setFilteredData(state: any, action: PayloadAction<any>) {
  if (action.payload.geofence) {
    geofencesAdapter.setAll(state, action.payload.geofence);
  } else {
    geofencesAdapter.setAll(state, []);
  }
}
//#endregiorn Custom Functions

export const geofencesSlice = createSlice({
  name: "geofences",
  initialState: geofencesAdapter.getInitialState({
    status: "idle",
    reasonCode: "",
    exists: false,
    totalPages: 0,
    totalElements: 0,
  }),
  reducers: {
    geofencesEmptyState: (state: any) => {
      geofencesAdapter.setAll(state, []);
      state.reasonCode = "";
      state.status = "idle";
    },
    setNumberOfPages: (state, action: PayloadAction<number>) => {
      state.totalPages = action.payload;
    },
    setNumberOfElements: (state, action: PayloadAction<number>) => {
      state.totalElements = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      //#region Entity Reducers
      .addCase(getGeofencesAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(
        getGeofencesAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          geofencesAdapter.upsertMany(state, action.payload?.geofence ?? []);
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.GET;
        }
      )
      .addCase(getGeofencesAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = "";
      })
      .addCase(getGeofenceAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(
        getGeofenceAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          geofencesAdapter.setAll(state, action.payload?.geofence ?? []);
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.GET;
        }
      )
      .addCase(getGeofenceAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = "";
      })
      .addCase(getFilteredGeofencesAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(
        getFilteredGeofencesAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          setFilteredData(state, action);
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.GET;
        }
      )
      .addCase(
        getFilteredGeofencesAsync.rejected,
        (state: any, action: PayloadAction<any>) => {
          state.status = "failed";
          state.reasonCode = getErrorCodes(action.payload);
        }
      )
      .addCase(createGeofenceAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(
        createGeofenceAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          geofencesAdapter.upsertMany(state, action.payload?.geofence ?? []);
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.POST;
        }
      )
      .addCase(
        createGeofenceAsync.rejected,
        (state: any, action: PayloadAction<any>) => {
          state.status = "failed";
          if (action.payload === 409) {
            state.reasonCode = GTFleetErrorCodes.GEOFENCE_NAME_ALREADY_EXIST;
          } else state.reasonCode = getErrorCodes(action.payload);
        }
      )
      .addCase(updateGeofenceAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(
        updateGeofenceAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          geofencesAdapter.upsertMany(state, action.payload?.geofence ?? []);
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.PATCH;
        }
      )
      .addCase(updateGeofenceAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = "";
      })
      .addCase(
        deleteGeofenceAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          let id = _.keys(action.payload.geofence)[0];
          id && geofencesAdapter.removeOne(state, id);
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.DELETE;
        }
      )
      .addCase(deleteGeofenceAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(
        deleteGeofenceAsync.rejected,
        (state: any, action: PayloadAction<any>) => {
          state.status = "failed";
          if (action.payload === 404) {
            state.reasonCode =
              GTFleetErrorCodes.GEOFENCE_TO_BE_DELETED_NOT_FOUND;
          } else state.reasonCode = getErrorCodes(action.payload);
        }
      )
      .addCase(
        activateGeofenceAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          geofencesAdapter.upsertMany(state, action.payload.geofence);
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.PATCH;
        }
      )
      .addCase(activateGeofenceAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(
        activateGeofenceAsync.rejected,
        (state: any, action: PayloadAction<any>) => {
          state.status = "failed";
          if (action.payload === 404) {
            state.reasonCode =
              GTFleetErrorCodes.GEOFENCE_TO_BE_DEACTIVATED_NOT_FOUND;
          } else state.reasonCode = getErrorCodes(action.payload);
        }
      )
      .addCase(
        deactivateGeofenceAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          geofencesAdapter.upsertMany(state, action.payload.geofence);
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.PATCH;
        }
      )
      .addCase(deactivateGeofenceAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(
        deactivateGeofenceAsync.rejected,
        (state: any, action: PayloadAction<any>) => {
          state.status = "failed";
          if (action.payload === 404) {
            state.reasonCode =
              GTFleetErrorCodes.GEOFENCE_TO_BE_DEACTIVATED_NOT_FOUND;
          } else state.reasonCode = getErrorCodes(action.payload);
        }
      ) // getFleetAsync
      .addCase(
        getFleetAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          geofencesAdapter.upsertMany(state, action.payload.geofence ?? []);
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.GET;
        }
      );
    //#endregion Entity Reducers
  },
});
//#endregion Slice

//#region Status
export const geofencesSelectors = geofencesAdapter.getSelectors<RootState>(
  (state) => state.geofences
);

export const selectGeofencesSliceStatus = (state: any) =>
  state.geofences.status;
export const selectGeofencesSliceReasonCode = (state: any) =>
  state.geofences.reasonCode;
export const selectGeofencesSlicePage = (state: any) =>
  state.geofences.totalPages;

export const selectGeofencesSliceTotalElement = (state: any) =>
  state.geofences.totalElements;
export const { geofencesEmptyState, setNumberOfPages, setNumberOfElements } =
  geofencesSlice.actions;
//#endregion Status

export default geofencesSlice.reducer;
