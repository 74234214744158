import HttpClient from "../../utils/HttpClient";
import { Contract } from "./contractsSlice";

export default class ContractsRepository extends HttpClient {
  constructor() {
    super(true);
  }

  public partiallyUpdateContract(id: number, contract: Contract) {
    return this.instance.patch("/contracts/" + id, contract);
  }

  public getContratcWithFunctionalities(deviceId: number) {
    const tenantId = localStorage.getItem("tenantId");
    return this.instance.get(
      "/tenants/" + tenantId + "/devices/" + deviceId + "/contracts"
    );
  }

  public getContratcsWithFunctionalities(customQueryParams?: string) {
    const tenantId = localStorage.getItem("tenantId");
    let queryParams = "";
    if (customQueryParams) {
      queryParams = customQueryParams;
    } else {
      queryParams = "?";
    }
    return this.instance.get(
      "/tenants/" + tenantId + "/devices/contracts" + queryParams
    );
  }
}
