import i18next, { t } from "i18next";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { store } from "../../../app/store";
import { FeedbackModal } from "../../../ui/Modal/CustomModals/FeedbackModal";
import { deleteDeadlineAsync } from "./../deadlinesSlice";

interface DeadlineDeleteModalProps {
  vehicle: string;
  date: string;
  meters: string;
  utilization: string;
  type: string;
  deadlineId: number;
}

const DeadlineDeleteModal: React.FC<DeadlineDeleteModalProps> = ({
  vehicle,
  date,
  meters,
  utilization,
  type,
  deadlineId,
}) => {
  const [deadlineDeleteModal, setDeadlineDeleteModal] = useState(true);
  const navigate = useNavigate();

  /**
   * This function builds the description of the modal based on the presence and combination of:
   * - meters
   * - date
   * - utilization
   * The possible combinations are:
   * - date
   * - date and utilization
   * - date and meters
   * - utilization
   * - meters
   * @returns
   */
  const buildString = () => {
    const metersArePresent = !meters.includes("null");
    const utilizationIsPresent =
      !utilization.includes("null") && !utilization.includes("undefined");

    if (date) {
      if (utilizationIsPresent) {
        return (
          t("customModals.deleteDeadline.descIn") +
          date +
          t("common.orBetween") +
          utilization +
          " "
        );
      }
      if (metersArePresent) {
        return (
          i18next.t("customModals.deleteDeadline.descIn") +
          date +
          i18next.t("common.orBetween") +
          meters +
          " "
        );
      }
      return i18next.t("customModals.deleteDeadline.descIn") + date + " ";
    } else {
      if (utilizationIsPresent) {
        return t("common.between") + utilization + " ";
      }
      if (metersArePresent) {
        return i18next.t("common.between") + meters + " ";
      }
    }
  };

  const closeModal = () => {
    setDeadlineDeleteModal(false);
    navigate({
      pathname: "/dashboard/maintenance",
    });
  };

  return (
    <FeedbackModal
      open={deadlineDeleteModal}
      title={t("customModals.deleteDeadline.title")}
      desc={
        type +
        t("customModals.deleteDeadline.descDate") +
        buildString() +
        t("customModals.deleteDeadline.descDelete") +
        vehicle +
        ". " +
        t("customModals.deleteDeadline.descLast")
      }
      primaryLabel={t("common.confirm")}
      isDanger={true}
      primaryAction={() => {
        store.dispatch(deleteDeadlineAsync(deadlineId));
        closeModal();
      }}
      secondaryLabel={t("common.cancel")}
      secondaryAction={() => {
        closeModal();
      }}
      closeAction={() => {
        closeModal();
      }}
    />
  );
};
export default DeadlineDeleteModal;
