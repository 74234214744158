import "./ContainerWrapper.css";

interface ContainerWrapperProps {
  children: object;
}

function ContainerWrapper({ children }: ContainerWrapperProps) {
  return <div className="container-wrapper">{children}</div>;
}

export default ContainerWrapper;
