import HttpClient from "../../../utils/HttpClient";

export default class PublicRoutePresetRepository extends HttpClient {
  constructor() {
    super(true);
  }

  public getPublicRoutePresets() {
    const tenantId = localStorage.getItem("tenantId");
    return this.instance.get(`/tenants/${tenantId}/publicRoutePlannings/view`);
  }
}
