import { Loader } from "@googlemaps/js-api-loader";
import { t } from "i18next";
import _ from "lodash";
import { useContext, useEffect, useMemo, useState } from "react";
import { useMatch, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { useAppSelector } from "../../app/hooks";
import { store } from "../../app/store";
import { GTFleetErrorCodes } from "../../config/GTfleetErrorCodes";
import PageContent from "../../layout/PageContent";
import PageFilters from "../../layout/PageFilters";
import { Button } from "../../ui/Button/Button";
import Form from "../../ui/Forms/Form";
import TextField from "../../ui/Forms/TextField";
import { IconEdit } from "../../ui/Icon/Line/Edit";
import { IconForward } from "../../ui/Icon/Line/Forward";
import { datetoLocaleFormat } from "../../utils/DateAndTimeUtils";
import { ToastNotification } from "../../utils/ToastNotification";
import {
  FleetView,
  fleetViewsEmptyState,
  fleetViewsSelectors,
} from "../fleet/fleetViewsSlice";
import {
  Preferences,
  getPreferencesAsync,
  selectPreferencesSliceReasonCode,
  selectPreferencesSliceStatus,
} from "../users/preference/preferencesSlice";
import { UserPermissions } from "../users/privilege/privilegesSlice";
import UserContext from "../users/userContext";
import {
  Vehicle,
  getVehiclesDetailsAsync,
  selectVehiclesSliceReasonCode,
  selectVehiclesSliceStatus,
  vehiclesEmptyState,
  vehiclesSelectors,
} from "../vehicle/vehiclesSlice";
import "./AdminDriver.css";
import {
  Driver,
  driversEmptyState,
  driversSelectors,
  getDriverDetailsAsync,
  selectDriversSliceReasonCode,
  selectDriversSliceStatus,
} from "./driversSlice";

interface ViewDriverProps {
  permissions: UserPermissions;
}

const ViewDriver: React.FC<ViewDriverProps> = ({ permissions }) => {
  const navigate = useNavigate();

  const driverSliceStatus = useAppSelector(selectDriversSliceStatus);
  const driverSliceReasonCode = useAppSelector(selectDriversSliceReasonCode);
  const vehiclesSliceStatus = useAppSelector(selectVehiclesSliceStatus);
  const vehiclesSliceReasonCode = useAppSelector(selectVehiclesSliceReasonCode);
  const preferencesSliceStatus = useAppSelector(selectPreferencesSliceStatus);
  const preferencesSliceReasonCode = useAppSelector(
    selectPreferencesSliceReasonCode
  );

  const driverRoute = useMatch("/admin/drivers/view/:driverId");

  const [id, setId] = useState<number>(-1);

  let driver: Driver =
    useAppSelector((state) => driversSelectors.selectById(state, id)) ??
    ({} as Driver);
  const vehicle: Vehicle =
    useAppSelector((state) =>
      vehiclesSelectors.selectById(state, driver?.vehicle ?? -1)
    ) ?? ({} as Vehicle);
  let fleetView: FleetView =
    useAppSelector((state) =>
      fleetViewsSelectors.selectById(state, driver.fleet)
    ) ?? ({} as FleetView);

  const [preferencesContext]: [Preferences] = useContext(UserContext);

  document.title = useMemo(() => {
    if (!!driver?.firstName && !!driver?.lastName) {
      return (
        `${driver?.firstName} ${driver?.lastName} - ` +
        t("navigation.adminMenu.drivers") +
        " - Admin"
      );
    } else {
      return t("navigation.adminMenu.drivers") + " - Admin";
    }
  }, [driver]);

  useEffect(() => {
    document.title = t("navigation.adminMenu.drivers") + " - Admin";

    const loader = new Loader({
      apiKey: process.env?.REACT_APP_GOOGLE_MAPS_API_KEY ?? "",
      libraries: ["places", "drawing", "geometry"],
      version: "beta",
    });
    loader.load().then();
    return () => {
      loader.deleteScript();
      store.dispatch(driversEmptyState());
      store.dispatch(fleetViewsEmptyState());
      store.dispatch(vehiclesEmptyState());
    };
  }, []);

  useEffect(() => {
    // get driver id and fleet name
    if (driverRoute !== null && driverRoute.params.driverId) {
      setId(parseInt(driverRoute.params.driverId));
      store.dispatch(
        getDriverDetailsAsync(parseInt(driverRoute.params.driverId))
      );
      store.dispatch(getVehiclesDetailsAsync());
      store.dispatch(getPreferencesAsync());
    } else {
      ToastNotification({
        toastId: "unauthorizedError",
        status: "error",
        description: t("common.unauthorizedError"),
      });
      navigate(-1);
    }
  }, [navigate, driverRoute]);

  useEffect(() => {
    if (vehiclesSliceStatus === "failed" && vehiclesSliceReasonCode === "") {
      ToastNotification({
        toastId: "networkError",
        status: "error",
        description: t("common.networkError"),
      });
    }
  }, [navigate, vehiclesSliceStatus, vehiclesSliceReasonCode]);

  useEffect(() => {
    if (driverSliceStatus === "idle") {
      document.title =
        `${driver?.firstName} ${driver?.lastName} - ` +
        t("navigation.adminMenu.drivers") +
        " - Admin";
    } else if (driverSliceStatus === "failed") {
      switch (driverSliceReasonCode) {
        case GTFleetErrorCodes.DRIVER_NOT_FOUND:
          ToastNotification({
            toastId: "driverNotFoundError",
            status: "error",
            description: t("common.driverNotFoundError"),
          });
          break;
        case GTFleetErrorCodes.USER_UNAUTHORIZED:
          ToastNotification({
            toastId: "unauthorizedError",
            status: "error",
            description: t("common.unauthorizedError"),
          });
          break;
        default:
          ToastNotification({
            toastId: "networkError",
            status: "error",
            description: t("common.networkError"),
          });
          break;
      }
      navigate("/admin/drivers");
    }
  }, [driverSliceStatus, driverSliceReasonCode, navigate]);

  useEffect(() => {
    if (
      preferencesSliceStatus === "failed" &&
      preferencesSliceReasonCode === ""
    ) {
      ToastNotification({
        toastId: "networkError",
        status: "error",
        description: t("common.networkError"),
      });
    }
  }, [preferencesSliceStatus, preferencesSliceReasonCode]);

  return (
    <>
      <ToastContainer />
      {!!driver && !_.isEmpty(driver) && (
        <PageFilters>
          <div className="col col-16">
            <div className="drvr-subtopbar">
              <div className="drvr-subsection-name">
                <div className="drvr-view">
                  <img
                    className="drvr-view-avatar"
                    src={driver.avatar}
                    alt="User Thumbnail"
                  />
                  <span>{driver.firstName + " " + driver.lastName}</span>
                </div>
              </div>
              <div className="button-save-group drvr-buttons">
                <Button
                  size="small"
                  aspect="secondary"
                  label={t("admin.driver.editViewCreate.allDrivers")}
                  onClick={() => navigate("/admin/drivers")}
                />
                <Button
                  size="small"
                  aspect="primary"
                  label={t("common.edit")}
                  onClick={() => navigate("/admin/drivers/edit/" + driver.id)}
                >
                  <IconEdit className="" color="" size={14} />
                </Button>
              </div>
            </div>
          </div>
        </PageFilters>
      )}
      {driverSliceStatus === "idle" && (
        <PageContent>
          <div className="ed-form-container">
            {/* General */}
            <div className="drvr-info-form">
              <div className="drvr-forms-title">
                {t("admin.driver.editViewCreate.general")}
              </div>
              <div className="drvr-forms-container">
                <div className="drvr-half-form">
                  <div className="drvr-forms-label">{t("common.address")}</div>
                  <Form>
                    <div className="drvr-forms">
                      <div className="drvr-left-form-name drvr-only-view">
                        <TextField
                          id="Address"
                          placeholder={
                            driver.address
                              ? t("common.address")
                              : t("common.na")
                          }
                          value={driver.address ?? null}
                        ></TextField>
                      </div>
                    </div>
                  </Form>
                </div>
                <div className="drvr-half-form">
                  <div className="drvr-right-forms-label">
                    {t("common.city")}
                  </div>
                  <Form>
                    <div className="drvr-forms">
                      <div className="drvr-right-form-item drvr-only-view">
                        <TextField
                          id="City"
                          placeholder={
                            driver.city ? t("common.city") : t("common.na")
                          }
                          value={driver.city ?? null}
                        ></TextField>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>

              <div className="drvr-forms-container">
                <div className="drvr-half-form">
                  <Form>
                    <div className="drvr-forms-label">
                      {t("common.province")}
                    </div>
                    <div className="drvr-forms">
                      <div className="drvr-left-form-name drvr-only-view">
                        <TextField
                          id="Province"
                          placeholder={
                            driver.province
                              ? t("common.province")
                              : t("common.na")
                          }
                          value={driver.province ?? null}
                        ></TextField>
                      </div>
                    </div>
                  </Form>
                </div>
                <div className="drvr-half-form">
                  <div className="drvr-right-forms-label">
                    {t("common.zip")}
                  </div>
                  <Form>
                    <div className="drvr-forms">
                      <div className="drvr-right-form-item drvr-only-view">
                        <TextField
                          id="Cap"
                          placeholder={
                            driver.zip ? t("common.zip") : t("common.na")
                          }
                          value={driver.zip ?? null}
                        ></TextField>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
              <div className="drvr-forms-container">
                <div className="drvr-half-form">
                  <Form>
                    <div className="drvr-forms-label">
                      {t("common.country")}
                    </div>
                    <div className="drvr-forms">
                      <div className="drvr-left-form-name drvr-only-view">
                        <TextField
                          id="Country"
                          placeholder={
                            driver.country
                              ? t("common.country")
                              : t("common.na")
                          }
                          value={driver.country ?? null}
                        ></TextField>
                      </div>
                    </div>
                  </Form>
                </div>
                <div className="drvr-half-form">
                  <div className="drvr-right-forms-label">
                    {t("common.phone")}
                  </div>
                  <Form>
                    <div className="drvr-forms">
                      <div className="drvr-left-form-phone">
                        <div className="drvr-drpdwn-small drvr-only-view">
                          <TextField
                            id="Prefix"
                            placeholder={
                              driver.phoneNumberPrefix
                                ? t("common.phone")
                                : t("common.na")
                            }
                            value={driver.phoneNumberPrefix ?? null}
                          ></TextField>
                        </div>
                      </div>
                      <div className="drvr-right-form-phone drvr-only-view">
                        <TextField
                          id="Number"
                          placeholder={
                            driver.phoneNumber
                              ? t("common.phone")
                              : t("common.na")
                          }
                          value={driver.phoneNumber ?? null}
                        ></TextField>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
              <div className="drvr-forms-container">
                <div className="drvr-forms-label">{t("common.email")} </div>
                <Form>
                  <div className="drvr-forms">
                    <div className="drvr-left-form drvr-only-view">
                      <TextField
                        id="driver-mail"
                        type="email"
                        placeholder={
                          driver.email ? t("common.email") : t("common.na")
                        }
                        value={driver.email ?? null}
                      ></TextField>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
            {/* Personal info */}
            <div className="drvr-info-form">
              <div className="drvr-forms-title">
                {t("admin.driver.editViewCreate.personalInfo")}
              </div>
              <div className="drvr-forms-container">
                <div className="drvr-half-form">
                  <div className="drvr-forms-label">
                    {t("common.dateOfBirth")}
                  </div>
                  <Form>
                    <div className="drvr-forms">
                      <div className="drvr-left-form-name drvr-only-view">
                        <TextField
                          id="dateOfBirth"
                          placeholder={
                            driver.dateOfBirth
                              ? t("common.dateOfBirth")
                              : t("common.na")
                          }
                          value={
                            driver.dateOfBirth
                              ? datetoLocaleFormat(
                                  driver.dateOfBirth,
                                  preferencesContext.localeFormat ?? undefined
                                )
                              : null
                          }
                        ></TextField>
                      </div>
                    </div>
                  </Form>
                </div>
                <div className="drvr-half-form">
                  <div className="drvr-right-forms-label">
                    {t("common.fiscalCode")}
                  </div>
                  <Form>
                    <div className="drvr-forms">
                      <div className="drvr-right-form-item drvr-only-view">
                        <TextField
                          id="fiscalCode"
                          placeholder={
                            driver.fiscalCode
                              ? t("common.fiscalCode")
                              : t("common.na")
                          }
                          value={driver.fiscalCode ?? null}
                        ></TextField>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
            {/* Driver License */}
            <div className="drvr-info-form">
              <div className="drvr-forms-title">
                {t("admin.driver.editViewCreate.driveLicense")}
              </div>
              <div className="drvr-forms-container">
                <div className="drvr-half-form">
                  <div className="drvr-forms-label">
                    {t("admin.driver.editViewCreate.licenseId")}
                  </div>
                  <Form>
                    <div className="drvr-forms">
                      <div className="drvr-left-form-name drvr-only-view">
                        <TextField
                          id="licenseId"
                          placeholder={
                            driver.licenseId
                              ? t("admin.driver.editViewCreate.licenseId")
                              : t("common.na")
                          }
                          value={driver.licenseId ?? null}
                        ></TextField>
                      </div>
                    </div>
                  </Form>
                </div>
                <div className="drvr-half-form">
                  <div className="drvr-right-forms-label">
                    {t("admin.driver.editViewCreate.licenseExpDate")}
                  </div>
                  <Form>
                    <div className="drvr-forms">
                      <div className="drvr-right-form-item drvr-only-view">
                        <TextField
                          id="licenseExpDate"
                          placeholder={
                            driver.licenseExpirationDate
                              ? t("admin.driver.editViewCreate.licenseExpDate")
                              : t("common.na")
                          }
                          value={
                            driver.licenseExpirationDate
                              ? datetoLocaleFormat(
                                  driver.licenseExpirationDate,
                                  preferencesContext.localeFormat ?? undefined
                                )
                              : null
                          }
                        ></TextField>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
              <div className="drvr-forms-container">
                <div className="drvr-forms-label">
                  {t("admin.driver.editViewCreate.licenseType")}
                </div>
                <Form>
                  <div className="drvr-forms">
                    <div className="drvr-left-form drvr-only-view">
                      <TextField
                        id="licenseType"
                        placeholder={
                          driver.licenseType
                            ? t("admin.driver.editViewCreate.licenseType")
                            : t("common.na")
                        }
                        value={driver.licenseType ?? null}
                      ></TextField>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
            {/* Group */}
            <div className="drvr-info-form">
              <div className="drvr-forms-title">{t("common.group")}</div>
              <div className="drvr-forms-container">
                <div className="drvr-forms-label">{t("common.group")}</div>
                <Form>
                  <div className="drvr-forms">
                    <div className="drvr-left-form drvr-only-view">
                      <TextField
                        id="selectGroup"
                        placeholder={
                          fleetView.name
                            ? t("admin.driver.editViewCreate.selectGroup")
                            : t("common.na")
                        }
                        value={fleetView.name ?? null}
                      ></TextField>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
            {/* Identification / Vehicle */}
            <div className="drvr-info-form">
              <div className="drvr-forms-title">
                {t("admin.driver.editViewCreate.identification")}
              </div>
              <div className="drvr-forms-container">
                <div className="drvr-forms-label">
                  {t("admin.driver.editViewCreate.driverId")}
                </div>
                <Form>
                  <div className="drvr-forms">
                    <div className="drvr-left-form drvr-only-view">
                      <TextField
                        id="driverId"
                        placeholder={
                          driver.id
                            ? t("admin.driver.editViewCreate.driverId")
                            : t("common.na")
                        }
                        value={driver.id ?? null}
                      ></TextField>
                    </div>
                  </div>
                </Form>
              </div>
              <div className="drvr-forms-container">
                <div className="drvr-forms-label">
                  {t("admin.driver.editViewCreate.linkedTachograph")}
                </div>
                <Form>
                  <div className="drvr-forms">
                    <div className="drvr-left-form drvr-only-view">
                      <TextField
                        id="tachographCard"
                        placeholder={
                          driver.tachographCard
                            ? t("admin.driver.editViewCreate.linkedTachograph")
                            : t("common.na")
                        }
                        value={driver.tachographCard ?? null}
                      ></TextField>
                    </div>
                  </div>
                </Form>
              </div>
              {driver?.mac ? (
                <div className="drvr-forms-container">
                  <div className="drvr-forms-label">
                    {t("admin.driver.editViewCreate.beaconMac")}
                  </div>
                  <Form>
                    <div className="drvr-forms">
                      <div className="drvr-left-form drvr-only-view">
                        <TextField
                          id="mac"
                          placeholder={
                            driver.mac
                              ? t("admin.driver.editViewCreate.beaconMac")
                              : t("common.na")
                          }
                          value={driver.mac ?? null}
                        ></TextField>
                      </div>
                    </div>
                  </Form>
                </div>
              ) : null}
              <hr className="drvr-separator" />
              <div className="drvr-forms-title">
                {t("admin.driver.editViewCreate.associatedVehicle")}
              </div>
              <div className="drvr-forms-container">
                <div className="drvr-forms-label">{t("common.vehicle")}</div>
                <Form>
                  <div className="drvr-forms">
                    <div className="drvr-left-form drvr-only-view">
                      <TextField
                        id="selectVehicle"
                        placeholder={
                          driver.vehicle
                            ? t("admin.driver.editViewCreate.selectVehicle")
                            : t("common.na")
                        }
                        value={
                          driver?.vehicle && vehicle?.alias && vehicle?.plate
                            ? vehicle?.alias + "-" + vehicle?.plate
                            : null
                        }
                      ></TextField>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
            {/* Account */}
            <div className="drvr-info-form">
              <div className="drvr-forms-title">{t("common.account")}</div>

              <div className="drvr-forms-container">
                <div className="drvr-forms-label">{t("common.username")}</div>
                <Form>
                  <div className="drvr-forms">
                    <div className="left-form drvr-link drvr-only-view">
                      <TextField
                        id="username"
                        placeholder={
                          driver.username
                            ? t("common.username")
                            : t("common.na")
                        }
                        value={driver.username ?? null}
                      >
                        <IconForward
                          className={"drvr-username-button"}
                          size={14}
                          onClick={() => {
                            alert("Account page");
                          }}
                        ></IconForward>
                      </TextField>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </PageContent>
      )}
    </>
  );
};
export default ViewDriver;
