import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import _ from "lodash";
import { normalize } from "normalizr";
import { RootState } from "../../app/store";
import { Config } from "../../config/Config";
import { GTFleetSuccessCodes } from "../../config/GTFleetSuccessCodes";
import { contractSchema, contractsSchema } from "./contractNormalization";
import ContractsRepository from "./contractsRepository";

//#region Type
export type Contract = {
  id: number;
  activationDate: Date;
  expirationDate: Date;
  availableCredits: number;
  threshold: boolean;
  status: boolean;
  service: string;
  options: {
    name: string;
    description: string;
    type: OptionType;
    category: OptionCategory;
    command: boolean;
    enabled: boolean;
  }[];
  accessories: {
    id: number;
    name: string;
    imei: string;
  }[];
};

export const optionTypeValues = {
  FUNCTIONALITY: "FUNCTIONALITY",
  OPTION: "OPTION",
};
export type OptionType = keyof typeof optionTypeValues;

export const optionCategoryValues = {
  LOC:"LOC",
  DRIVER:"DRIVER",
  TEL:"TEL",
  SIC:"SIC",
  CRONO:"CRONO",
};
export type OptionCategory = keyof typeof optionCategoryValues;

//#endregion Type

//#region API
export const updateContractAsync = createAsyncThunk(
  "contracts/updateContract",
  async (data: { id: number; contract: Contract }) => {
    const contractsRepository = new ContractsRepository();
    const response = await contractsRepository.partiallyUpdateContract(
      data.id,
      data.contract
    );
    const contract = _.get(response, Config.CONTRACT_RESPONSE_PATH);
    const normalizedData = normalize(contract, contractSchema);
    return normalizedData.entities;
  }
);

export const getContractAsync = createAsyncThunk(
  "contracts/getContract",
  async (data: { deviceId: number }, { rejectWithValue }) => {
    try {
      const contractsRepository = new ContractsRepository();
      const response = await contractsRepository.getContratcWithFunctionalities(
        data.deviceId
      );
      const contract = _.get(response, Config.CONTRACT_RESPONSE_PATH);
      const normalizedData = normalize(contract, contractSchema);
      return normalizedData.entities;
    } catch (error: any) {
      if (!error.response) throw error;
      return rejectWithValue(error.response.status);
    }
  }
);

export const getContractsAsync = createAsyncThunk(
  "contracts/getContracts",
  async (data: { queryParams?: string }, { rejectWithValue }) => {
    try {
      const contractsRepository = new ContractsRepository();
      const response =
        await contractsRepository.getContratcsWithFunctionalities(
          data?.queryParams
        );
      const contract = _.get(response, Config.CONTRACT_RESPONSE_PATH);
      const normalizedData = normalize(contract, contractsSchema);
      return normalizedData.entities;
    } catch (error: any) {
      if (!error.response) throw error;
      return rejectWithValue(error.response.status);
    }
  }
);
//#endregion API

//#region Custom Functions
function setFilteredData(state: any, action: PayloadAction<any>) {
  action.payload.contract
    ? contractsAdapter.setAll(state, action.payload.contract)
    : contractsAdapter.setAll(state, []);
}
//#endregiorn Custom Functions

//#region Slice
const contractsAdapter = createEntityAdapter<Contract>({
  selectId: (contract) => contract.id,
  sortComparer: (a, b) => a.id.toString().localeCompare(b.id.toString()),
});

export const contractSlice = createSlice({
  name: "contracts",
  initialState: contractsAdapter.getInitialState({
    status: "idle",
    reasonCode: "",
  }),
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      //#region Entity Reducers
      .addCase(
        updateContractAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          if (action.payload?.contract) {
            contractsAdapter.upsertMany(state, action.payload.contract);
          }
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.PATCH;
        }
      )
      .addCase(updateContractAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = "";
      })
      .addCase(updateContractAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(getContractAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(
        getContractAsync.fulfilled,
        (state, action: PayloadAction<any>) => {
          if (action.payload?.contract) {
            contractsAdapter.upsertMany(state, action.payload.contract);
          }
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.GET;
        }
      )
      .addCase(getContractAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = "";
      })
      .addCase(getContractsAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(
        getContractsAsync.fulfilled,
        (state, action: PayloadAction<any>) => {
          if (action.payload?.contract) {
            contractsAdapter.upsertMany(state, action.payload.contract);
          }
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.GET;
        }
      )
      .addCase(getContractsAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = "";
      });

    //#endregion Entity Reducers

    //#region External Entity Reducers
    //#endregion External Entity Reducers
  },
});
//#endregion Slice

//#region Status
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const contractsSelectors = contractsAdapter.getSelectors<RootState>(
  (state) => state.contracts
);

export const selectContractsSliceReasonCode = (state: any) =>
  state.contracts.reasonCode;
export const selectContractsSliceStatus = (state: any) =>
  state.contracts.status;
//#endregion Status

export default contractSlice.reducer;
