import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { DataLabel } from "../../../ui/DataLabel/DataLabel";
import { IconDown } from "../../../ui/Icon/Line/Down";
import { IconKilometers } from "../../../ui/Icon/Line/Kilometers";
import { IconParking } from "../../../ui/Icon/Line/Parking";
import { IconTruck } from "../../../ui/Icon/Line/Truck";
import { IconUp } from "../../../ui/Icon/Line/Up";
import { IconRoad } from "../../../ui/Icon/Solid/Road";
import { formatTime } from "../../../utils/DateAndTimeUtils";
import { Preferences } from "../../users/preference/preferencesSlice";
import UserContext from "../../users/userContext";

interface GeofenceReportDetailsProps {
  geofenceVehicles?: number;
  geofences?: number;
  inEvents?: number;
  outEvents?: number;
  stopTime?: number;
  trackTime?: number;
}

export const GeofenceReportDetails: React.FC<GeofenceReportDetailsProps> = ({
  geofenceVehicles,
  geofences,
  inEvents,
  outEvents,
  stopTime,
  trackTime,
}) => {
  const { t } = useTranslation();
  const [preferencesContext]: [Preferences] = useContext(UserContext);

  return (
    <>
      {geofenceVehicles != null && (
        <div className="details-report-item" key={0}>
          <DataLabel
            dataText={geofenceVehicles}
            icon={<IconTruck size={14} />}
            label={t("report.details.vehicles")}
          />
        </div>
      )}
      {geofences != null && (
        <div className="details-report-item" key={1}>
          <DataLabel
            dataText={geofences}
            icon={<IconKilometers size={14} />}
            label={t("report.details.geofences")}
          />
        </div>
      )}
      {inEvents != null && (
        <div className="details-report-item" key={2}>
          <DataLabel
            dataText={inEvents}
            icon={<IconUp size={14} />}
            label={t("report.details.inEvents")}
          />
        </div>
      )}
      {outEvents != null && (
        <div className="details-report-item" key={3}>
          <DataLabel
            dataText={outEvents}
            icon={<IconDown size={14} />}
            label={t("report.details.outEvents")}
          />
        </div>
      )}
      {stopTime != null && (
        <div className="details-report-item" key={4}>
          <DataLabel
            dataText={formatTime(
              stopTime,
              "-",
              preferencesContext.language,
              true,
              true,
              true,
              true,
              0
            )}
            icon={<IconParking size={14} />}
            label={t("report.details.stopTime")}
          />
        </div>
      )}
      {trackTime != null && (
        <div className="details-report-item" key={5}>
          <DataLabel
            dataText={formatTime(
              trackTime,
              "-",
              preferencesContext.language,
              true,
              true,
              true,
              true,
              0
            )}
            icon={<IconRoad size={14} />}
            label={t("report.details.trackTime")}
          />
        </div>
      )}
    </>
  );
};
