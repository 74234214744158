import i18next, { t } from "i18next";
import _ from "lodash";
import { Tooltip } from "../../../ui/Forms/Tooltip";
import {
  AbstractTableBuilder,
  Row,
} from "../../../ui/Table/utils/AbstractTableBuilder";
import "../../../ui/Table/utils/TableBuilder.css";
import {
  ConvertTimeZone,
  tableFormatTime,
} from "../../../utils/DateAndTimeUtils";
import { getAddress, getCity } from "../../../utils/Utils";
import { Driver } from "../../driver/driversSlice";
import { FleetView } from "../../fleet/fleetViewsSlice";
import { Preferences } from "../../users/preference/preferencesSlice";
import { ReportIO } from "./reportsIOSlice";

export class ReportIOTableBuilder extends AbstractTableBuilder {
  rowsBuilder = (
    columns: { label: string; field: string; sort: boolean }[] | undefined,
    entities: {
      driver?: Driver;
      fleetView?: FleetView;
      reportIO?: ReportIO;
    },
    preferencesContext: Preferences,
    writePermission: boolean = false
  ) => {
    let row: Row = { render: {} as Row };

    let reportIO: ReportIO = entities.reportIO ?? ({} as ReportIO);

    columns &&
      columns.forEach((column) => {
        switch (column.field) {
          case "eventStart":
            if (reportIO.eventStart.gpsPositionTimestamp !== undefined) {
              const eventStart = ConvertTimeZone(
                reportIO.eventStart.gpsPositionTimestamp,
                preferencesContext.timeZone,
                preferencesContext.localeFormat
              ).split(" ");
              row.render.eventStart = (
                <>
                  <p className="reportMarked">{eventStart[0]}</p>
                  <p className="reportNormal">{eventStart[1]}</p>
                </>
              );
            } else {
              row.render.eventStart = (
                <>
                  <p className="reportMarked">--:--:----</p>
                  <p className="reportNormal">--:--:--</p>
                </>
              );
            }
            break;
          case "eventEnd":
            if (reportIO.eventEnd.gpsPositionTimestamp !== undefined) {
              const eventEnd = ConvertTimeZone(
                reportIO.eventEnd.gpsPositionTimestamp,
                preferencesContext.timeZone,
                preferencesContext.localeFormat
              ).split(" ");
              row.render.eventEnd = (
                <>
                  <p className="reportMarked">{eventEnd[0]}</p>
                  <p className="reportNormal">{eventEnd[1]}</p>
                </>
              );
            } else {
              row.render.eventEnd = (
                <>
                  <p className="reportMarked">--:--:----</p>
                  <p className="reportNormal">--:--:--</p>
                </>
              );
            }
            break;
          case "duration":
            if (reportIO.duration !== undefined) {
              row.render.duration = tableFormatTime(
                reportIO.duration,
                "seconds"
              );
            } else {
              row.render.duration = "--";
            }
            break;
          case "eventDistance":
            row.render.eventDistance =
              reportIO.eventDistance !== undefined
                ? reportIO.eventDistance.toLocaleString(i18next.language) +
                  " km"
                : "--";
            break;
          case "startAddress":
            if (reportIO.eventStart.address !== undefined) {
              row.render.endPosition =
                getAddress(reportIO.eventStart.address) ==
                  getAddress(reportIO.eventEnd.address) &&
                getCity(reportIO.eventStart.address) ==
                  getCity(reportIO.eventEnd.address) ? (
                  <p className="reportIO">
                    {getAddress(reportIO.eventStart.address)}{" "}
                    {getCity(reportIO.eventStart.address)}
                  </p>
                ) : (
                  <>
                    <p className="reportIO">
                      {t("common.start")}:{" "}
                      {getAddress(reportIO.eventStart.address)}{" "}
                      {getCity(reportIO.eventStart.address)}
                    </p>
                    <p className="reportIO">
                      {t("common.end")}: {getAddress(reportIO.eventEnd.address)}{" "}
                      {getCity(reportIO.eventEnd.address)}
                    </p>
                  </>
                );
            } else {
              row.render.endPosition = "--";
            }
            break;
          case "currentDriver":
            row.render.currentDriver = (
              <div>
                {reportIO.driverEnd ? (
                  <>
                    <p className="reportIO">
                      <span>{t("common.start")}: </span>
                      {!_.isEmpty(reportIO.driverStart)
                        ? reportIO.driverStart.firstName +
                          " " +
                          reportIO.driverStart.lastName
                        : t("common.na")}
                    </p>
                    <p className="reportIO">
                      <span>{t("common.end")}: </span>
                      {!_.isEmpty(reportIO.driverEnd)
                        ? reportIO.driverEnd.firstName +
                          " " +
                          reportIO.driverEnd.lastName
                        : t("common.na")}
                    </p>
                  </>
                ) : (
                  <>
                    <p className="reportIO">
                      {!_.isEmpty(reportIO.driverStart)
                        ? reportIO.driverStart.firstName +
                          " " +
                          reportIO.driverStart.lastName
                        : t("common.na")}
                    </p>
                  </>
                )}
              </div>
            );
            row.render.currentDriver = (
              <div>
                {reportIO.driverStart.id !== reportIO.driverEnd.id &&
                reportIO.driverEnd.id !== undefined ? (
                  <>
                    <Tooltip />
                    <span
                      data-for="tooltip"
                      data-tip={
                        reportIO.driverEnd.firstName +
                        " " +
                        reportIO.driverEnd.lastName
                      }
                    >
                      <p className="reportIO">
                        {!_.isEmpty(reportIO.driverStart)
                          ? reportIO.driverStart.firstName +
                            " " +
                            reportIO.driverStart.lastName +
                            " + 1"
                          : t("common.na")}
                      </p>
                    </span>
                  </>
                ) : (
                  <>
                    <p className="reportIO">
                      {!_.isEmpty(reportIO.driverStart) &&
                      reportIO.driverStart.firstName !== null &&
                      reportIO.driverStart.lastName !== null
                        ? reportIO.driverStart.firstName +
                          " " +
                          reportIO.driverStart.lastName
                        : t("common.na")}
                    </p>
                  </>
                )}
              </div>
            );
            break;
          default:
            break;
        }
      });

    return row;
  };
}
