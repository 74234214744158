import { Loader } from "@googlemaps/js-api-loader";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useIsMounted } from "./../../utils/useIsMounted";
import "./Map.css";
import options from "./assets/mapStyles.json";

let loadedMapIconsobserver = null;

/**
 * This component represents a wrapper for the google map.
 * It implements the main funtionalities to provide a basic map in react.
 * It can render external components in an agnostic way.
 * If you want to use this components and its map be sure that "map" isntance
 * is not null and the map is idle (getMap and getIsMapIdle shouldn't be removed)
 * @param {*} param0
 * @returns
 */
export const BaseMap = ({
  id,
  googleMapsApiKey,
  zoom,
  hasStreetView,
  hasStreetViewUp,
  latitude,
  longitude,
  trafficLayer,
  satelliteLayer,
  draggable,
  getMap,
  getIsMapIdle,
  getMapIconsLoaded,
  setStreetViewEnabled,
  exitFromStreetView,
  useDefaultPosition = true,
  isArrowsDisabled,
  removeComponents,
  ...props
}) => {
  let isMounted = useIsMounted();
  const [map, setMap] = useState(null);
  const [isMapIdle, setIsMapIdle] = useState(false);
  const [propsChildren, setPropsChildren] = useState(props.children);

  const loader = new Loader({
    apiKey: googleMapsApiKey,
    libraries: ["places", "drawing", "geometry"],
    version: "beta",
  });

  useEffect(() => {
    if (map && exitFromStreetView) {
      let panorama = map.getStreetView();
      panorama.setVisible(false);
    }
  }, [map, exitFromStreetView]);

  useEffect(() => {
    if (propsChildren && propsChildren.length > 0 && map) {
      propsChildren.forEach((componentOnMap) => {
        const control = document.querySelector(componentOnMap.selectorName);
        const controlsArray =
          map.controls[componentOnMap.selectorPosition].getArray();

        const removePreviousString = (arr, str) => {
          const index = arr.findIndex((item) => {
            return _.isEqual(
              item.className.replace(/\s/g, ""),
              str.replace(/\./g, "")
            );
          });
          if (index > -1) {
            map.controls[componentOnMap.selectorPosition].removeAt(index);
          }

          if (removeComponents) {
            removeComponents.forEach((removeComponent) => {
              const indexTemp = arr.findIndex((item) => {
                return _.isEqual(
                  item.className.replace(/\s/g, ""),
                  removeComponent
                );
              });
              if (indexTemp > -1) {
                map.controls[componentOnMap.selectorPosition].removeAt(
                  indexTemp
                );
              }
            });
          }
        };

        removePreviousString(controlsArray, componentOnMap.selectorName);
        control && map.controls[componentOnMap.selectorPosition].push(control);
      });
    }
  }, [propsChildren, map]);

  // Check loaded map Icons
  let pegmanLoaded = false;
  let centerLocationLoaded = false;
  let actionControlLoaded = false;
  const checkLoadedMapIcons = (mutationsList) => {
    for (const mutation of mutationsList) {
      if (mutation.type === "childList") {
        for (let component in mutation.addedNodes) {
          let item = mutation.addedNodes[component];
          if (item.className === "gm-svpc") {
            pegmanLoaded = true;
          } else if (item.className === "center-location") {
            centerLocationLoaded = true;
          } else if (item.className === "action-control") {
            actionControlLoaded = true;
          }
        }
      }
    }
    if (
      pegmanLoaded === true &&
      centerLocationLoaded === true &&
      actionControlLoaded === true
    )
      getMapIconsLoaded(true);
  };

  useEffect(() => {
    if (!!map && getMap) {
      getMap(map);
    }
  }, [map]);

  useEffect(() => {
    if (isMapIdle && useDefaultPosition) {
      map.setCenter({
        lat: latitude,
        lng: longitude,
      });
    }
  }, [latitude, longitude, isMapIdle, useDefaultPosition]);

  useEffect(() => {
    loadedMapIconsobserver = new MutationObserver(checkLoadedMapIcons);
    if (!map) {
      loader.load().then(async (google) => {
        if (isMounted.current && document.getElementById(`map${id}`) !== null) {
          let streetViewControlOptions = {};

          // Aggiungi la condizione per determinare la posizione
          if (!!hasStreetViewUp) {
            streetViewControlOptions = {
              position: google.maps.ControlPosition.RIGHT_TOP,
            };
          } else {
            streetViewControlOptions = {};
          }
          const mapInstance = await new google.maps.Map(
            document.getElementById(`map${id}`),
            {
              center: {
                lat: latitude,
                lng: longitude,
              },
              zoom: zoom,
              minZoom: 3,
              maxZoom: 20,
              heading: 0,
              tilt: 0,
              rotateControl: true,
              options: options,
              draggable: draggable,
              draggableCursor: draggable,
              disableDefaultUI: true,
              streetViewControl: !!hasStreetView,
              streetViewControlOptions: streetViewControlOptions,
              mapId: "658e86176a2b6b2e",
            }
          );
          // Listen to map is loaded
          google.maps.event.addListener(
            mapInstance,
            "tilesloaded",
            function () {
              const targetNode = document.getElementById(`map${id}`);
              const config = {
                attributes: true,
                childList: true,
                subtree: true,
              };
              setIsMapIdle(true);
              !!getIsMapIdle && getIsMapIdle(true);
              // Check loaded map icons
              if (targetNode) {
                loadedMapIconsobserver.observe(targetNode, config);
              }
            }
          );
          if (mapInstance) {
            let panorama = mapInstance.getStreetView();
            if (panorama) {
              google.maps.event.addListener(
                panorama,
                "visible_changed",
                function () {
                  if (panorama.getVisible()) {
                    setStreetViewEnabled(true);
                  } else {
                    setStreetViewEnabled(false);
                  }
                }
              );
            }
          }

          if (trafficLayer)
            trafficLayer.current = new google.maps.TrafficLayer();

          setMap(mapInstance);
        }
      });
    }
    return function cleanup() {
      loadedMapIconsobserver.disconnect();
    };
  }, []);

  return (
    <div
      style={{ height: "100%", width: "100%", position: "relative", zIndex: 0 }}
    >
      <div
        id={`map${id}`}
        style={{
          height: "100%",
          width: "100%",
          position: "relative",
          zIndex: 0,
        }}
      ></div>
      {props &&
        props.children &&
        props.children.map((componentOnMap, index) => {
          if (index === props.children.length - 1) {
            if (!_.isEqual(props.children, propsChildren)) {
              setPropsChildren(props.children);
            }
          }
          return <div key={index}>{componentOnMap.component}</div>;
        })}
    </div>
  );
};
