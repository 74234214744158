import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { IconForward } from "../Icon/Line/Forward";

export const WidgetHelpItem = ({ icon, label, onClick, ...props }) => {


  const [coords, setCoords] = useState({ x: -1, y: -1 });
  const [isRippling, setIsRippling] = useState(false);

  useEffect(() => {
    if (coords.x !== -1 && coords.y !== -1) {
      setIsRippling(true);
      setTimeout(() => setIsRippling(false), 300);
    } else {
      setIsRippling(false);
    }
  }, [coords]);

  useEffect(() => {
    if (!isRippling) {
      setCoords({ x: -1, y: -1 });
    }
  }, [isRippling]);

  return (
    <li
      className="mn-widget-help__item"
      onClick={(e) => {
        const rect = e.target.getBoundingClientRect();
        setCoords({
          x: rect.width / 2,
          y: rect.height / 2,
        });
        if (onClick !== undefined) {
          onClick && onClick(e);
        }
      }}
      {...props}
    >
      <span className="mn-widget-help__item-icon">{icon}</span>
      <span className="mn-widget-help__item-label">{label}</span>
      <span className="mn-widget-help__item-forward">
        <IconForward size={14} />
      </span>
      {isRippling ? (
        <span className="ripple" style={{ left: coords.x, top: coords.y }} />
      ) : (
        ""
      )}
    </li>
  );
};

WidgetHelpItem.propTypes = {
  label: PropTypes.string,
};
