import { schema } from "normalizr";
import { driverSchema } from "../driver/driverNormalization";
import { tenantSchema } from "../tenants/tenantNormalization";
import { vehicleSchema } from "../vehicle/vehicleNormalization";

export const deadlineSchema: schema.Entity<any> = new schema.Entity(
  "deadlines",
  {
    vehicle: vehicleSchema,
    tenant: tenantSchema,
    driver: driverSchema,
  }
);

export const deadlinesSchema = new schema.Array(deadlineSchema);
