import { t } from "i18next";
import { NavigateFunction } from "react-router-dom";
import { Button } from "../../ui/Button/Button";
import { IconCheck } from "../../ui/Icon/Line/Check";
import {
  AbstractTableBuilder,
  Row,
} from "../../ui/Table/utils/AbstractTableBuilder";
import { Tag } from "../../ui/Tags/Tag";
import { ConvertTimeZone } from "../../utils/DateAndTimeUtils";
import { Preferences } from "../users/preference/preferencesSlice";
import { NotificationCategory } from "./notificationCategorySlice";
import { NotificationEvent } from "./notificationSlice";

export class NotificationTableBuilder extends AbstractTableBuilder {
  rowsBuilder(
    columns: { label: string; field: string; sort: boolean }[] | undefined,
    entries: {
      notificationEvent?: NotificationEvent;
      notificationCategory?: NotificationCategory;
    },
    preferencesContext: Preferences,
    navigate: NavigateFunction,
    writePermission: boolean = false,
    setIdRead: any
  ): any {
    let row: Row = { render: {} as Row };

    let notificationEvent: NotificationEvent =
      entries.notificationEvent ?? ({} as NotificationEvent);
    let notificationCategory: NotificationCategory =
      entries.notificationCategory ?? ({} as NotificationCategory);

    const getTitleFromNotification = (text: string): string => {
      const httpsIndex = text.indexOf("https");
      const title = httpsIndex !== -1 ? text.slice(0, httpsIndex) : text;
      return title;
    };

    const getUrlFromNotification = (text: string): string => {
      const pattern = /https?:\/\/\w+(\/?[\w#:.?+=&%@!\-/]*)/;
      const match = pattern.exec(text);
      const url = match && new URL(match[0]);
      const stringUrl = url?.href;
      return stringUrl ?? "";
    };

    columns &&
      columns.forEach((column) => {
        switch (column.field) {
          case "reference":
            row.render.notificationEvent = (
              <div
                className={
                  notificationEvent.read
                    ? "notification-read"
                    : "notification-unread"
                }
              >
                {notificationEvent.message.includes("https") ? (
                  <a
                    href={getUrlFromNotification(notificationEvent.message)}
                    rel="noreferrer"
                  >
                    {getTitleFromNotification(notificationEvent.message)}
                  </a>
                ) : (
                  notificationEvent.message ?? t("common.na")
                )}
              </div>
            );
            break;

          case "item":
            row.render.item = (
              <div
                className={
                  notificationEvent.read
                    ? "notification-read"
                    : "notification-unread"
                }
              >
                {notificationEvent.title ?? t("common.na")}
              </div>
            );
            break;

          case "dateTime":
            row.render.dateTime = (
              <div
                className={
                  notificationEvent.read
                    ? "notification-read"
                    : "notification-unread"
                }
              >
                {notificationEvent?.sendDate
                  ? ConvertTimeZone(
                      notificationEvent?.sendDate,
                      preferencesContext.timeZone ?? undefined,
                      preferencesContext.localeFormat ?? undefined
                    )
                  : t("common.na")}
              </div>
            );
            break;

          case "type":
            row.render.type = (
              <Tag
                text={
                  notificationCategory.name
                    ? t(notificationCategory.name)
                    : t("common.na")
                }
                size="small"
                mode={!notificationEvent.read ? "fill" : "outline"}
                type={!notificationEvent.read ? "secondary" : "neutro"}
              />
            );
            break;
          default:
            break;
        }
      });

    row.render.action = (
      <>
        {!notificationEvent.read && writePermission ? (
          <div className="button-mark-read">
            <Button
              aspect={"outline"}
              size={"small"}
              label={t("notificationEvent.filterBar.markAsRead")}
              onClick={() => {
                setIdRead(notificationEvent.id);
              }}
            >
              <div className="iconCheck-mark-read">
                <IconCheck size={15} color="--global-colors-ink-white" />
              </div>
            </Button>
          </div>
        ) : null}
        {/* <div className="iconExternalLink">
          <IconExternalLink
            size={14}
            color="--global-colors-ink-ink"
            onClick={() => {
              navigate(`/dashboard/overview`);
            }}
          />
        </div> */}
      </>
    );
    return row;
  }
}
