import { t } from "i18next";
import { useEffect, useState } from "react";
import { Messages } from "../../stories/Icon.line.stories";
import { Button } from "../../ui/Button/Button";
import Form from "../../ui/Forms/Form";
import SearchField from "../../ui/Forms/SearchField";
import "./DashboardMessagesFilterbar.css";

interface QueryParams {
  [paramName: string]: any;
}

interface MessagesFilterBarProps {
  newMessageClick: (e: any) => any;
  openNewMessage: boolean;
}

export const DashboardMessagesFilterbar: React.FC<MessagesFilterBarProps> = ({
  newMessageClick,
  openNewMessage,
}) => {
  const [messagesAndChatStringSearch, setMessagesAndChatStringSearch] =
    useState<string>("");

  useEffect(() => {
    return function cleanUp() {};
  }, []);

  return (
    <div className="messages-filterbar-row">
      <div className="messages-filterbar-search">
        <Form>
          <SearchField
            name="search"
            id="messages"
            size="normal"
            placeholder={t("messages.findonchat")}
            value={messagesAndChatStringSearch}
            onChange={(val: string) => {}}
            disabled={true}
          />
        </Form>
      </div>

      <div className="messages-filterbar-button">
        <Button
          aspect="primary"
          size="small"
          onClick={() => newMessageClick(!openNewMessage)}
          label={t("messages.newmessage")}
          disabled={openNewMessage}
        >
          <Messages size={14} color="--global-colors-ink-white" />
        </Button>
      </div>
    </div>
  );
};
