import {
  PayloadAction,
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import _ from "lodash";
import { normalize } from "normalizr";
import { RootState } from "../../app/store";
import { Config } from "../../config/Config";
import { GTFleetSuccessCodes } from "../../config/GTFleetSuccessCodes";
import { GTFleetErrorCodes } from "../../config/GTfleetErrorCodes";
import FileService from "../../utils/FileService";
import { getErrorCodes } from "../../utils/Utils";
import { deadlineSchema, deadlinesSchema } from "./deadlinesNormalization";
import DeadlinesRepository from "./deadlinesRepository";

export const statusTypeValues = {
  SCHEDULED: "SCHEDULED",
  IN_PROGRESS: "IN_PROGRESS",
  EXPIRED: "EXPIRED",
  EXPIRING: "EXPIRING",
  COMPLETED: "COMPLETED",
};
export type StatusType = keyof typeof statusTypeValues;

export const deadlineEnumValues = {
  MAINTENANCE_CAR_SERVICE: "MAINTENANCE_CAR_SERVICE",
  MAINTENANCE_TIRES: "MAINTENANCE_TIRES",
  MAINTENANCE_INSPECTION: "MAINTENANCE_INSPECTION",
  MAINTENANCE_CAR_BODYWORK: "MAINTENANCE_CAR_BODYWORK",
  MAINTENANCE_ORDINARY: "MAINTENANCE_ORDINARY",
  MAINTENANCE_EXTRAORDINARY: "MAINTENANCE_EXTRAORDINARY",
  MAINTENANCE_CUSTOM: "MAINTENANCE_CUSTOM",
  INSURANCE: "INSURANCE",
  TAXES: "TAXES",
  REVISION: "REVISION",
  CUSTOM: "CUSTOM",
};
export type DeadlineEnum = keyof typeof deadlineEnumValues;

//#region Type
export type Deadline = {
  id: number;
  isMaintenance: boolean;
  type: DeadlineEnum;
  expirationType: string;
  expirationDate: Date;
  rescheduleMonths: number;
  expirationMeters: number;
  rescheduleMeters: number;
  expirationUtilizationMinutes: number;
  rescheduleUtilizationMinutes: number;
  complete: boolean;
  cost: number;
  amount: number;
  uploadFile: string;
  note: string;
  additionalInfoJson: string;
  vehicle: number;
  driver: number;
  status: StatusType | null;
  currentOdometer: number;
  currentUtilizationMinutes: number;
};

export type DeadlineMultiVehicle = {
  isMaintenance: boolean;
  type: DeadlineEnum;
  expirationType: string;
  expirationDate: Date;
  rescheduleMonths: number;
  expirationMeters: number;
  rescheduleMeters: number;
  expirationUtilizationMinutes: number;
  rescheduleUtilizationMinutes: number;
  cost: number;
  amount: number;
  uploadFile: string;
  note: string;
  additionalInfoJson: string;
  vehicleIds: number[];
  driver: number;
  currentOdometer: number;
  currentUtilizationMinutes: number;
  tenantId?: number;
};
//#endregion Type

//#region API
// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.

export const getDeadlinesAsync = createAsyncThunk(
  "deadlines/getDeadlines",
  async (data: { queryParams?: string }, thunkAPI) => {
    const deadlinesRepository = new DeadlinesRepository();
    const response = await deadlinesRepository.getDeadlines(data.queryParams);
    // The value we return becomes the `fulfilled` action payload
    const deadlines = _.get(response, Config.DEADLINES_RESPONSE_PATH);
    const totalPages = _.get(
      response,
      Config.DEADLINES_TOTAL_PAGES_RESPONSE_PATH
    );
    const totalElements = _.get(
      response,
      Config.DEADLINES_TOTAL_ELEMENTS_RESPONSE_PATH
    );
    if (totalElements) {
      thunkAPI.dispatch(setNumberOfElements(totalElements));
    }
    if (totalPages) {
      thunkAPI.dispatch(setNumberOfPages(totalPages));
    }
    const normalizedData = normalize(deadlines, deadlinesSchema);
    if (normalizedData?.entities?.deadlines) {
      let deadlinesOrdered: Deadline[] = [];
      normalizedData.result.forEach((element: number) => {
        const deadline = _.get(normalizedData.entities.deadlines, element);
        deadlinesOrdered.push(deadline);
      });
      normalizedData.entities.deadlines = deadlinesOrdered;
    }
    return normalizedData.entities;
  }
);

export const getDeadlineAsync = createAsyncThunk(
  "deadlines/getDeadline",
  async (dedlineId: number) => {
    const deadlinesRepository = new DeadlinesRepository();
    const response = await deadlinesRepository.getDeadline(dedlineId);
    // The value we return becomes the `fulfilled` action payload
    const deadlines = _.get(response, Config.DEADLINE_RESPONSE_PATH);
    const normalizedData = normalize(deadlines, deadlineSchema);
    return normalizedData.entities;
  }
);

export const deleteDeadlineAsync = createAsyncThunk(
  "deadlines/deleteDeadline",
  async (deadlineId: number, { rejectWithValue }) => {
    try {
      const deadlinesRepository = new DeadlinesRepository();
      await deadlinesRepository.deleteDeadline(deadlineId);
      return deadlineId;
    } catch (error: any) {
      if (!error.response) throw error;
      return rejectWithValue(error.response.status);
    }
  }
);

export const createDeadlineAsync = createAsyncThunk(
  "deadlines/createDeadline",
  async (deadlineMultiVehicle: DeadlineMultiVehicle, { rejectWithValue }) => {
    try {
      const deadlinesRepository = new DeadlinesRepository();
      const response = await deadlinesRepository.createDeadline(
        deadlineMultiVehicle
      );
      // The value we return becomes the `fulfilled` action payload
      const deadlines = _.get(response, Config.DEADLINE_POST_RESPONSE_PATH);
      const normalizedData = normalize(deadlines, deadlinesSchema);
      return normalizedData.entities;
    } catch (error: any) {
      if (!error.response) throw error;
      return rejectWithValue(error.response.data.status);
    }
  }
);

export const updateDeadlineAsync = createAsyncThunk(
  "deadline/updateDeadline",
  async (
    data: {
      id: number;
      deadline: Partial<Deadline>;
    },
    { rejectWithValue }
  ) => {
    try {
      const deadlinesRepository = new DeadlinesRepository();
      const response = await deadlinesRepository.partiallyUpdateDeadline(
        data.id,
        data.deadline
      );
      const deadline = _.get(response, Config.DEADLINE_RESPONSE_PATH);
      const normalizedData = normalize(deadline, deadlineSchema);
      return normalizedData.entities;
    } catch (err: any) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data.message);
    }
  }
);

export const uploadFileDeadlineAsync = createAsyncThunk(
  "deadlines/uploadFile",
  async (data: { file: any; type: string }) => {
    const fileService = new FileService();
    const response = await fileService.uploadFile(data.file, data.type);
    const device = _.get(response, Config.DEVICE_RESPONSE_PATH);
    const normalizedData = normalize(device, deadlineSchema);
    return normalizedData.entities;
  }
);

export const deleteFileDeadlineAsync = createAsyncThunk(
  "deadlines/deleteFile",
  async (data: { file: any; type: string }) => {
    const fileService = new FileService();
    const response = await fileService.deleteFile(data.file, data.type);
    const device = _.get(response, Config.DEVICE_RESPONSE_PATH);
    const normalizedData = normalize(device, deadlineSchema);
    return normalizedData.entities;
  }
);
//#endregion API

//#region Custom Functions
function setFilteredData(state: any, action: PayloadAction<any>) {
  action.payload.deadlines
    ? deadlinesAdapter.setAll(state, action.payload.deadlines)
    : deadlinesAdapter.setAll(state, []);
}
//#endregiorn Custom Functions

//#region Slice
const deadlinesAdapter = createEntityAdapter<Deadline>({
  selectId: (deadline) => deadline.id,
});

export const deadlinesSlice = createSlice({
  name: "deadlinesSlice",
  initialState: deadlinesAdapter.getInitialState({
    status: "idle",
    reasonCode: "",
    totalPages: 0,
    totalElements: 0,
  }),
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    deadlinesEmptyState: (state: any) => {
      deadlinesAdapter.setAll(state, []);
      state.reasonCode = "";
      state.status = "idle";
    },
    setNumberOfPages: (state, action: PayloadAction<number>) => {
      state.totalPages = action.payload;
    },
    setNumberOfElements: (state, action: PayloadAction<number>) => {
      state.totalElements = action.payload;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      //#region Entity Reducers
      // getRoutesHistoryAsync extra reducers
      .addCase(
        getDeadlinesAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          setFilteredData(state, action);
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.GET;
        }
      )
      .addCase(getDeadlinesAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(getDeadlinesAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = "";
      })

      .addCase(
        getDeadlineAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          setFilteredData(state, action);
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.GET;
        }
      )
      .addCase(getDeadlineAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(getDeadlineAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = "";
      })
      .addCase(
        deleteDeadlineAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          deadlinesAdapter.removeOne(state, action.payload);
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.DELETE;
        }
      )
      .addCase(deleteDeadlineAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(
        deleteDeadlineAsync.rejected,
        (state: any, action: PayloadAction<any>) => {
          state.status = "failed";
          let castAction: any = action;
          if (castAction.payload && castAction.payload === 404) {
            state.reasonCode =
              GTFleetErrorCodes.DEADLINE_TO_BE_DELETED_NOT_FOUND;
          } else state.reasonCode = getErrorCodes(action.payload);
        }
      )
      .addCase(
        createDeadlineAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          deadlinesAdapter.setMany(state, action.payload.deadlines);
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.POST;
        }
      )
      .addCase(
        createDeadlineAsync.rejected,
        (state: any, action: PayloadAction<any>) => {
          state.status = "failed";
          state.reasonCode = getErrorCodes(action.payload);
        }
      )
      .addCase(createDeadlineAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(
        updateDeadlineAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          deadlinesAdapter.upsertMany(state, action.payload.deadlines);
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.PATCH;
        }
      )
      .addCase(updateDeadlineAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = "";
      })
      .addCase(updateDeadlineAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(
        uploadFileDeadlineAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          if (action?.payload?.deadlines) {
            deadlinesAdapter.upsertMany(state, action.payload.deadlines);
          }
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.POST;
        }
      )
      .addCase(uploadFileDeadlineAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = "";
      })
      .addCase(uploadFileDeadlineAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(
        deleteFileDeadlineAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          deadlinesAdapter.upsertMany(state, action?.payload?.deadlines);
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.DELETE;
        }
      )
      .addCase(deleteFileDeadlineAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = "";
      })
      .addCase(deleteFileDeadlineAsync.pending, (state: any) => {
        state.status = "loading";
      });
    //#endregion Entity Reducers
  },
});
//#endregion Slice

//#region Status
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const deadlinesSelectors = deadlinesAdapter.getSelectors<RootState>(
  (state) => state.deadlines
);

export const selectDeadlinesSliceStatus = (state: any) =>
  state.deadlines.status;
export const selectDeadlinesSliceReasonCode = (state: any) =>
  state.deadlines.reasonCode;
export const selectDeadlinesSlicePage = (state: any) =>
  state.deadlines.totalPages;
export const selectDeadlinesSliceTotalElements = (state: any) =>
  state.deadlines.totalElements;
export const { deadlinesEmptyState, setNumberOfPages, setNumberOfElements } =
  deadlinesSlice.actions;

//#endregion Status

export default deadlinesSlice.reducer;
