export const MapMarkerPositionDirection = ({
  hasDirection,
  isSatellite,
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="70"
      height="70"
      {...props}
    >
      <defs>
        <filter
          id="MapMarkerPositionDirectionA"
          width="125%"
          height="125%"
          x="-12.5%"
          y="-12.5%"
          filterUnits="objectBoundingBox"
        >
          <feMorphology
            in="SourceAlpha"
            operator="dilate"
            radius="1"
            result="shadowSpreadOuter1"
          ></feMorphology>
          <feOffset
            in="shadowSpreadOuter1"
            result="shadowOffsetOuter1"
          ></feOffset>
          <feGaussianBlur
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
            stdDeviation="0.5"
          ></feGaussianBlur>
          <feComposite
            in="shadowBlurOuter1"
            in2="SourceAlpha"
            operator="out"
            result="shadowBlurOuter1"
          ></feComposite>
          <feColorMatrix
            in="shadowBlurOuter1"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
          ></feColorMatrix>
        </filter>
        <circle
          id="MapMarkerPositionDirectionB"
          cx="35"
          cy="35"
          r="10"
        ></circle>
      </defs>
      <g fill="none" fillRule="evenodd">
        <circle
          cx="35"
          cy="35"
          r="34.5"
          fill={isSatellite ? "#00AAFF" : "#0052BD"}
          fillOpacity="0.1"
          stroke={isSatellite ? "#00AAFF" : "#0052BD"}
          strokeOpacity="0.504"
        ></circle>
        <use
          fill="#000"
          filter="url(#MapMarkerPositionDirectionA)"
          xlinkHref="#MapMarkerPositionDirectionB"
        ></use>
        <use
          fill={isSatellite ? "#00AAFF" : "#0052BD"}
          stroke="#FFF"
          strokeWidth="2"
          xlinkHref="#MapMarkerPositionDirectionB"
        ></use>
        {hasDirection && (
          <path
            fill={isSatellite ? "#00AAFF" : "#0052BD"}
            d="M43 24c-2.222-1.533-4.889-2.3-8-2.3-3.111 0-5.778.767-8 2.3l7.148-11.616a1 1 0 011.704 0L43 24z"
          ></path>
        )}
      </g>
    </svg>
  );
};
