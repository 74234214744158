import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyB6pLRhbSUy0Ps_QBHiagakg8kWxUYERYs",
  authDomain: "gt-fleet-365-daac2.firebaseapp.com",
  projectId: "gt-fleet-365-daac2",
  storageBucket: "gt-fleet-365-daac2.appspot.com",
  messagingSenderId: "694639816144",
  appId: "1:694639816144:web:c309f465ec6d722b079dd5",
  measurementId: "G-0PVBRGS1TQ"
};

let firebaseApp;
let messaging;
if (
  process.env.REACT_APP_DEPLOY_TARGET === "local" ||
  process.env.REACT_APP_DEPLOY_TARGET === "prod" ||
  process.env.REACT_APP_DEPLOY_TARGET === "pre-prod"
) {
  firebaseApp = initializeApp(firebaseConfig);
  messaging = getMessaging(firebaseApp);
}

export const getFbToken = () => {
  return getToken(messaging, {
    vapidKey: "BLkOsqvpGsll8NPMSnatMAYghvYrTfBcwt_-xqaZ99Eb7V5rFVhRJSd51MsBNm3NOoe0F1v5XuG94ikXCvARd98",
  }).then((currentToken) => {
    if (currentToken) {
      localStorage.setItem("firebaseToken", currentToken);
      return currentToken;
    }
  });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log("Received foreground message: ", payload);
      resolve(payload);
    });
  });
