import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import ReactApexChart from "react-apexcharts";
import _ from "lodash";
import { PeriodNav } from "../Navigation/PeriodNav";
import { RadioDropdown } from "../Forms/RadioDropdown";
import "./SpeedReport.css";

export const SpeedReport = ({ searchFrom, searchTo, series, size }) => {
  const [graphSeries, setGraphSeries] = useState([]);
  const [categories, setCategories] = useState([]);

  const [graphOption, setGraphOption] = useState({});

  const [allPeriodSeries, setAllPeriodSeries] = useState([]);
  const [titleGraphAllPeriod, setTitleGraphAllPeriod] = useState();

  const [avgDays, setAvgDays] = useState([]);
  const [topSpeedDays, setTopSpeedDays] = useState([]);

  const [avgWeekly, setAvgWeekly] = useState([]);
  const [topSpeedWeekly, setTopSpeedWeekly] = useState([]);

  const [avgMonthly, setAvgMonthly] = useState([]);
  const [topSpeedMonthly, setTopSpeedMonthly] = useState([]);

  const [textPeriod, setTextPeriod] = useState();
  const [currentPeriod, setCurrentPeriod] = useState();
  const [totalPeriod, setTotalPeriod] = useState();
  const [periodNavHidden, setPeriodNavHidden] = useState(true);

  const tempFrom = new Date(searchFrom),
    tempTo = new Date(searchTo);

  const daysPeriod =
    Math.abs(
      new Date(tempTo.toLocaleDateString("en")) -
        new Date(tempFrom.toLocaleDateString("en"))
    ) /
      (1000 * 3600 * 24) +
    1;

  const orderSelect = [
      { value: "Ordered by Top Speed", disabled: false },
      { value: "Ordered by Avg Speed", disabled: false },
    ],
    orderSelectWidth = 200;

  const diff = tempTo.getTime() - tempFrom.getTime();
  const daydiff = diff / (1000 * 60 * 60 * 24) + 1;

  const allPeriodSelect = [
      { value: "All period", disabled: false },
      { value: "Daily", disabled: false },
      { value: "Weekly", disabled: false },
      { value: "Monthly", disabled: false },
    ],
    widthDropdownAllPeriod = 117;

  let disablePeriodItems = "";
  if (daydiff < 2) {
    disablePeriodItems = "none";
  } else {
    if (
      daydiff < 7 &&
      tempFrom.getDay() < (tempTo.getDay() === 0 ? 7 : tempTo.getDay())
    ) {
      allPeriodSelect[2].disabled = true;
    }
    if (tempFrom.getMonth() === tempTo.getMonth()) {
      allPeriodSelect[3].disabled = true;
    }
  }

  /* assegna l'options del grafico alla variabile di set */
  useEffect(() => {
    chartOptions();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* chiama la prima funzione (all period) alla load del componente */
  useEffect(() => {
    if (
      !(Object.keys(graphOption).length === 0) &&
      allPeriodSeries.length === 0
    ) {
      allPeriod();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [graphOption]);

  /* assegna le opzioni del grafico */
  function chartOptions() {
    const tempGraphOption = {
      chart: {
        height: size.height,
        width: size.width,
        type: "bar",
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "40%",
          endingShape: "rounded",
          borderRadius: 2,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 6,
        colors: ["transparent"],
      },
      xaxis: {
        categories: categories,
        labels: {
          style: {
            fontFamily: "global-font-semibold",
            fontSize: "12px",
            fontWeight: 600,
            colors: ["#031832"],
          },
        },
      },
      yaxis: {
        labels: {
          formatter: function (value) {
            return value + " km/h";
          },
          style: {
            fontFamily: "global-font-regular",
            fontSize: "10px",
            fontWeight: "normal",
            colors: ["#687484"],
          },
        },
        max: 100,
        tickAmount: 5,
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          return (
            '<div class="arrow_box" style="background-color:' +
            w.globals.colors[seriesIndex] +
            '; padding: 12px 8px; color: white; display: flex; align-items: center; justify-content: center; flex: row; flex-direction: column; flex-wrap: 10px;"><p style="margin: 0 0 8px 0; font-size: 11px; font-family: \'global-font-regular\';">' +
            w.globals.labels[dataPointIndex] +
            "</p><span style=\"font-family: 'global-font-regular'; font-size: 12px; \">" +
            w.globals.seriesNames[seriesIndex] +
            ": " +
            (series[seriesIndex][dataPointIndex] % 1 === 0
              ? series[seriesIndex][dataPointIndex]
              : series[seriesIndex][dataPointIndex].toFixed(2)) +
            " km/h</span>" +
            "</div>"
          );
        },
      },
      legend: {
        position: "top",
        offsetY: -32,
        offsetX: -400,
        fontSize: "12px",
        fontFamily: "global-font-semibold",
        fontWeight: 600,
        labels: {
          colors: ["#687484"],
        },
      },

      title: {
        text: "",
        align: "left",
        style: {
          fontFamily: "global-font-regular",
          fontSize: "16px",
          fontWeight: "normal",
          color: "#031832",
        },
      },
      colors: ["#0052BD", "#00AAFF"],
    };
    updateOption(tempGraphOption);
  }

  /* aggiorna tutte le opzioni del grafico */
  function updateOption(tempGraphOption) {
    if (Object.keys(graphOption).length === 0) {
      setGraphOption(tempGraphOption);
    }
  }

  function updateCategories(tempCategories) {
    setCategories(tempCategories);
  }

  /* aggiorna le opzioni specifiche del grafico */
  function updateOptions(tempCategories, max, tickAmount, title) {
    setGraphOption({
      ...graphOption,
      xaxis: {
        ...graphOption.xaxis,
        categories: tempCategories,
      },
      yaxis: {
        ...graphOption.yaxis,
        max: max,
        tickAmount: tickAmount,
      },
      title: {
        ...graphOption.title,
        text: title,
      },
    });
  }

  /* aggiorna il titolo del grafico */
  function updateTitle(title, tempCategories) {
    if (!tempCategories) {
      setGraphOption({
        ...graphOption,
        title: {
          ...graphOption.title,
          text: title,
        },
      });
    } else if (tempCategories) {
      setGraphOption({
        ...graphOption,
        title: {
          ...graphOption.title,
          text: title,
        },
        xaxis: {
          ...graphOption.xaxis,
          categories: tempCategories,
        },
      });
    }
  }

  /* aggiorna le serie visualizzate nel grafico */
  function updateSeries(data) {
    if (graphSeries.length === 0 || !_.isEqual(data, graphSeries)) {
      setGraphSeries(data);
    }
  }

  function sortArrays(
    arrays,
    comparator = (a, b) => (a > b ? -1 : a < b ? 1 : 0)
  ) {
    let arrayKeys = Object.keys(arrays);
    let sortableArray = Object.values(arrays)[0];
    let indexes = Object.keys(sortableArray);
    let sortedIndexes = indexes.sort((a, b) =>
      comparator(sortableArray[a], sortableArray[b])
    );

    let sortByIndexes = (array, sortedIndexes) =>
      sortedIndexes.map((sortedIndex) => array[sortedIndex]);

    if (Array.isArray(arrays)) {
      return arrayKeys.map((arrayIndex) =>
        sortByIndexes(arrays[arrayIndex], sortedIndexes)
      );
    } else {
      let sortedArrays = {};
      arrayKeys.forEach((arrayKey) => {
        sortedArrays[arrayKey] = sortByIndexes(arrays[arrayKey], sortedIndexes);
      });
      return sortedArrays;
    }
  }

  /* assegna le serie per allperiod */
  function updateAllPeriod(data) {
    if (allPeriodSeries.length === 0 || !_.isEqual(data, allPeriodSeries)) {
      setAllPeriodSeries(data);
    }
  }

  /* algorimto per allperiod */
  function allPeriod() {
    setPeriodNavHidden(true);

    if (allPeriodSeries.length === 0) {
      let tempAvgSerie = [],
        tempTopSpeedSerie = [],
        tempCategories = [];

      let tempMax, tempTickAmount;
      let tempTitle;

      series.forEach((item, index) => {
        tempCategories.push(item.name);

        let tempTopSpeed,
          tempData = [];
        item.data.forEach((kms) => {
          tempTopSpeed = Math.max.apply(null, kms.kms);

          if (
            tempTopSpeedSerie[index] &&
            tempTopSpeedSerie[index] < tempTopSpeed
          ) {
            tempTopSpeedSerie[index] = tempTopSpeed;
          } else if (!tempTopSpeedSerie[index]) {
            tempTopSpeedSerie.push(tempTopSpeed);
          }

          tempData = tempData.concat(kms.kms);
        });

        const sum = tempData.reduce((a, b) => a + b, 0);
        const avg = sum / tempData.length || 0;

        tempAvgSerie.push(avg);
      });

      let tempSort = sortArrays([
        tempTopSpeedSerie,
        tempAvgSerie,
        tempCategories,
      ]);
      updateSeries([
        { name: "Avg Speed", data: tempSort[1] },
        { name: "Top Speed", data: tempSort[0] },
      ]);

      updateAllPeriod([
        { name: "Avg Speed", data: tempAvgSerie },
        { name: "Top Speed", data: tempTopSpeedSerie },
      ]);
      updateCategories(tempCategories);

      if (
        Math.max.apply(null, tempTopSpeedSerie) > 101 &&
        Math.max.apply(null, tempTopSpeedSerie) < 180
      ) {
        tempMax = 180;
        tempTickAmount = 9;
      } else if (Math.max.apply(null, tempTopSpeedSerie) > 179) {
        tempMax = 250;
        tempTickAmount = 10;
      }

      if (
        tempFrom.getFullYear() === tempTo.getFullYear() &&
        tempFrom.getMonth() === tempTo.getMonth() &&
        tempFrom.getDate() === tempTo.getDate()
      ) {
        setTitleGraphAllPeriod(
          ("0" + tempFrom.getDate()).slice(-2) +
            " " +
            tempFrom.toLocaleString("en", { month: "long" }) +
            " " +
            tempFrom.getFullYear()
        );
        tempTitle =
          ("0" + tempFrom.getDate()).slice(-2) +
          " " +
          tempFrom.toLocaleString("en", { month: "long" }) +
          " " +
          tempFrom.getFullYear();
      } else if (
        tempFrom.getFullYear() === tempTo.getFullYear() &&
        tempFrom.getMonth() === tempTo.getMonth()
      ) {
        setTitleGraphAllPeriod(
          ("0" + tempFrom.getDate()).slice(-2) +
            " - " +
            ("0" + tempTo.getDate()).slice(-2) +
            " " +
            tempFrom.toLocaleString("en", { month: "long" }) +
            " " +
            tempFrom.getFullYear()
        );
        tempTitle =
          ("0" + tempFrom.getDate()).slice(-2) +
          " - " +
          ("0" + tempTo.getDate()).slice(-2) +
          " " +
          tempFrom.toLocaleString("en", { month: "long" }) +
          " " +
          tempFrom.getFullYear();
      } else if (tempFrom.getFullYear() === tempTo.getFullYear()) {
        setTitleGraphAllPeriod(
          ("0" + tempFrom.getDate()).slice(-2) +
            " " +
            tempFrom.toLocaleString("en", { month: "long" }) +
            " - " +
            ("0" + tempTo.getDate()).slice(-2) +
            " " +
            tempTo.toLocaleString("en", { month: "long" }) +
            " " +
            tempFrom.getFullYear()
        );
        tempTitle =
          ("0" + tempFrom.getDate()).slice(-2) +
          " " +
          tempFrom.toLocaleString("en", { month: "long" }) +
          " - " +
          ("0" + tempTo.getDate()).slice(-2) +
          " " +
          tempTo.toLocaleString("en", { month: "long" }) +
          " " +
          tempFrom.getFullYear();
      } else {
        setTitleGraphAllPeriod(
          ("0" + tempFrom.getDate()).slice(-2) +
            " " +
            tempFrom.toLocaleString("en", { month: "long" }) +
            " " +
            tempFrom.getFullYear() +
            " - " +
            ("0" + tempTo.getDate()).slice(-2) +
            " " +
            tempTo.toLocaleString("en", { month: "long" }) +
            " " +
            tempTo.getFullYear()
        );
        tempTitle =
          ("0" + tempFrom.getDate()).slice(-2) +
          " " +
          tempFrom.toLocaleString("en", { month: "long" }) +
          " " +
          tempFrom.getFullYear() +
          " - " +
          ("0" + tempTo.getDate()).slice(-2) +
          " " +
          tempTo.toLocaleString("en", { month: "long" }) +
          " " +
          tempTo.getFullYear();
      }

      updateOptions(tempSort[2], tempMax, tempTickAmount, tempTitle);
    } else {
      let tempSeries = [...allPeriodSeries],
        tempCategory = [...categories];
      let dataort =
        valueOrder === "Top"
          ? [tempSeries[1].data, tempSeries[0].data, tempCategory]
          : [tempSeries[0].data, tempSeries[1].data, tempCategory];
      sortArrays(dataort).forEach((el, index) => {
        if (index === 0) {
          dataort[0] = el;
        } else if (index === 1) {
          dataort[1] = el;
        } else {
          dataort[2] = el;
        }
      });

      valueOrder === "Top"
        ? updateSeries([
            { name: "Avg Speed", data: dataort[1] },
            { name: "Top Speed", data: dataort[0] },
          ])
        : updateSeries([
            { name: "Avg Speed", data: dataort[0] },
            { name: "Top Speed", data: dataort[1] },
          ]);
      updateTitle(titleGraphAllPeriod, dataort[2]);
    }
  }

  /* assegna le serie per days */
  function updateDays(tempAvg, tempTopSpeed) {
    if (avgDays.length === 0 || !_.isEqual(tempAvg, avgDays)) {
      setAvgDays(tempAvg);
    }
    if (topSpeedDays.length === 0 || !_.isEqual(tempTopSpeed, topSpeedDays)) {
      setTopSpeedDays(tempTopSpeed);
    }
  }

  /* algoritmo per days */
  function days() {
    setPeriodNavHidden(false);
    let currentPeriodTemp = currentPeriod;
    let titleday;
    let tempCategory;

    if (textPeriod !== "days") {
      setTextPeriod("days");
      setCurrentPeriod(1);
      currentPeriodTemp = 1;
      setTotalPeriod(daysPeriod);
    }

    if (Object.keys(avgDays).length === 0) {
      let tempTopSpeed = [],
        tempAvgSerie = [];
      for (
        let i = new Date(tempFrom);
        i <= new Date(tempTo);
        i.setDate(i.getDate() + 1)
      ) {
        tempTopSpeed[i.toISOString().split("T")[0]] = [];
        tempAvgSerie[i.toISOString().split("T")[0]] = [];
        for (let j = 0; j < categories.length; j++) {
          tempTopSpeed[i.toISOString().split("T")[0]][j] = "";
          tempAvgSerie[i.toISOString().split("T")[0]][j] = "";
        }
      }

      series.forEach((item, index) => {
        item.data.forEach((kms) => {
          tempTopSpeed[kms.date][index] = Math.max.apply(null, kms.kms);

          const sum = kms.kms.reduce((a, b) => a + b, 0);
          const avg = sum / kms.kms.length || 0;

          tempAvgSerie[kms.date][index] = avg;
        });
      });

      tempCategory = [...categories];
      let dataort =
        valueOrder === "Top"
          ? [
              tempTopSpeed[Object.keys(tempTopSpeed)[0]],
              tempAvgSerie[Object.keys(tempAvgSerie)[0]],
              tempCategory,
            ]
          : [
              tempAvgSerie[Object.keys(tempAvgSerie)[0]],
              tempTopSpeed[Object.keys(tempTopSpeed)[0]],
              tempCategory,
            ];

      let tempSort = sortArrays(dataort);
      updateSeries([
        {
          name: "Avg Speed",
          data: valueOrder === "Top" ? tempSort[1] : tempSort[0],
        },
        {
          name: "Top Speed",
          data: valueOrder === "Top" ? tempSort[0] : tempSort[1],
        },
      ]);
      updateDays(tempAvgSerie, tempTopSpeed);

      titleday = new Date(Object.keys(tempAvgSerie)[0]);
      tempCategory = dataort[2];
    } else {
      let tempSeriesA = { ...avgDays },
        tempSeriesT = { ...topSpeedDays };
      tempCategory = [...categories];
      let dataort =
        valueOrder === "Top"
          ? [
              tempSeriesT[Object.keys(tempSeriesT)[currentPeriodTemp - 1]],
              tempSeriesA[Object.keys(tempSeriesA)[currentPeriodTemp - 1]],
              tempCategory,
            ]
          : [
              tempSeriesA[Object.keys(tempSeriesA)[currentPeriodTemp - 1]],
              tempSeriesT[Object.keys(tempSeriesT)[currentPeriodTemp - 1]],
              tempCategory,
            ];
      sortArrays(dataort).forEach((el, index) => {
        if (index === 0) {
          dataort[0] = el;
        } else if (index === 1) {
          dataort[1] = el;
        } else {
          dataort[2] = el;
        }
      });

      valueOrder === "Top"
        ? updateSeries([
            { name: "Avg Speed", data: dataort[1] },
            { name: "Top Speed", data: dataort[0] },
          ])
        : updateSeries([
            { name: "Avg Speed", data: dataort[0] },
            { name: "Top Speed", data: dataort[1] },
          ]);
      titleday = new Date(Object.keys(tempSeriesA)[currentPeriodTemp - 1]);
      tempCategory = dataort[2];
    }

    updateTitle(
      titleday.getDate() +
        " " +
        titleday.toLocaleDateString("en", { month: "long" }) +
        " " +
        titleday.getFullYear(),
      tempCategory
    );
  }

  /* assegna le serie per weekly */
  function updateWeekly(tempAvg, tempTopSpeed) {
    if (avgWeekly.length === 0 || !_.isEqual(tempAvg, avgWeekly)) {
      setAvgWeekly(tempAvg);
    }
    if (
      topSpeedWeekly.length === 0 ||
      !_.isEqual(tempTopSpeed, topSpeedWeekly)
    ) {
      setTopSpeedWeekly(tempTopSpeed);
    }
  }

  /* calcola il giorno della settimana */
  function weekDay(day, from) {
    let weekday = 7;
    switch (day) {
      case "Tuesday":
        weekday = 6;
        break;

      case "Wednesday":
        weekday = 5;
        break;

      case "Thursday":
        weekday = 4;
        break;

      case "Friday":
        weekday = 3;
        break;

      case "Saturday":
        weekday = 2;
        break;

      case "Sunday":
        weekday = 1;
        break;

      default:
        break;
    }

    return Math.abs(from - weekday);
  }

  /* calcola in numero delle settimane in base al periodo */
  function calcWeek() {
    let dayFrom = tempFrom.toLocaleDateString("en", { weekday: "long" });
    let diffDays = weekDay(dayFrom, 0);

    let calcDays = daysPeriod;

    let tempWeek =
      Math.floor((calcDays - diffDays) / 7) +
      ((calcDays - diffDays) % 7 > 0 ? 1 : 0) +
      (diffDays < 7 ? 1 : 0);

    return {
      tempWeek,
      diffDays,
    };
  }

  /* ritorna la stringa in base alle date passate*/
  function nameObject(dateFrom, dateTo) {
    let name =
      dateFrom.getDate().toString() +
      (dateFrom.getMonth() !== dateTo.getMonth()
        ? " " + dateFrom.toLocaleString("en", { month: "long" })
        : "") +
      " - " +
      dateTo.getDate().toString() +
      (dateFrom.getMonth() !== dateTo.getMonth()
        ? " " + dateTo.toLocaleString("en", { month: "long" })
        : "") +
      (dateFrom.getMonth() === dateTo.getMonth()
        ? " " + dateTo.toLocaleString("en", { month: "long" })
        : "");

    return name;
  }

  /* algoritmo per weekly */
  function weekly() {
    setPeriodNavHidden(false);
    let currentPeriodTemp = currentPeriod;
    let tempCategory;

    let tempCalcWeek;
    if (textPeriod !== "weekly") {
      setTextPeriod("weekly");
      setCurrentPeriod(1);
      currentPeriodTemp = 1;

      tempCalcWeek = calcWeek();
      setTotalPeriod(tempCalcWeek.tempWeek);
    }

    if (Object.keys(avgWeekly).length === 0) {
      let tempTopSpeed = [],
        tempAvgSerie = [];
      let arrayDate = [];

      let dateIndex = tempFrom.toISOString().split("T")[0];
      while (dateIndex <= tempTo.toISOString().split("T")[0]) {
        if (dateIndex === tempFrom.toISOString().split("T")[0]) {
          let nameIndex = nameObject(
            tempFrom,
            new Date(
              new Date(dateIndex).setDate(
                new Date(dateIndex).getDate() + tempCalcWeek.diffDays - 1
              )
            )
          );

          arrayDate.push(
            new Date(
              new Date(dateIndex).setDate(
                new Date(dateIndex).getDate() + tempCalcWeek.diffDays - 1
              )
            )
          );

          tempTopSpeed[nameIndex] = [];
          tempAvgSerie[nameIndex] = [];

          dateIndex = new Date(
            new Date(dateIndex).setDate(
              new Date(dateIndex).getDate() + tempCalcWeek.diffDays
            )
          )
            .toISOString()
            .split("T")[0];
        } else if (
          new Date(
            new Date(dateIndex).setDate(new Date(dateIndex).getDate() + 7)
          ) > tempTo
        ) {
          let wd = weekDay(
            tempTo.toLocaleDateString("en", { weekday: "long" }),
            8
          );

          let nameIndex = nameObject(
            new Date(new Date(dateIndex)),
            new Date(
              new Date(dateIndex).setDate(
                new Date(dateIndex).getDate() + wd - 1
              )
            )
          );

          arrayDate.push(
            new Date(
              new Date(dateIndex).setDate(
                new Date(dateIndex).getDate() + wd - 1
              )
            )
          );

          tempTopSpeed[nameIndex] = [];
          tempAvgSerie[nameIndex] = [];

          dateIndex = new Date(
            new Date(dateIndex).setDate(new Date(dateIndex).getDate() + wd)
          )
            .toISOString()
            .split("T")[0];
        } else {
          let nameIndex = nameObject(
            new Date(new Date(dateIndex)),
            new Date(
              new Date(dateIndex).setDate(new Date(dateIndex).getDate() + 6)
            )
          );

          arrayDate.push(
            new Date(
              new Date(dateIndex).setDate(new Date(dateIndex).getDate() + 6)
            )
          );

          tempTopSpeed[nameIndex] = [];
          tempAvgSerie[nameIndex] = [];

          dateIndex = new Date(
            new Date(dateIndex).setDate(new Date(dateIndex).getDate() + 7)
          )
            .toISOString()
            .split("T")[0];
        }
      }

      for (const [key] of Object.entries(tempTopSpeed)) {
        for (let j = 0; j < categories.length; j++) {
          tempTopSpeed[key][j] = "";
          tempAvgSerie[key][j] = "";
        }
      }

      let tempdataeries = [];
      arrayDate.forEach((date) => {
        tempdataeries.push({ date: date, kms: [] });
      });
      series.forEach((item, index) => {
        item.data.forEach((kms) => {
          tempdataeries.forEach((temp) => {
            if (new Date(kms.date) <= temp.date) {
              temp.kms[index] = kms.kms;
            }
          });
        });
      });

      tempdataeries.forEach((item) => {
        for (const [key, value] of Object.entries(tempTopSpeed)) {
          if (
            key.includes(item.date.getDate()) &&
            key.includes(item.date.toLocaleString("en", { month: "long" }))
          ) {
            item.kms.forEach((kms, index) => {
              value[index] = Math.max.apply(null, kms);

              const sum = kms.reduce((a, b) => a + b, 0);
              const avg = sum / kms.length || 0;

              tempAvgSerie[key][index] = avg;
            });
          }
        }
      });

      tempCategory = [...categories];
      let dataort =
        valueOrder === "Top"
          ? [
              tempTopSpeed[Object.keys(tempTopSpeed)[0]],
              tempAvgSerie[Object.keys(tempAvgSerie)[0]],
              tempCategory,
            ]
          : [
              tempAvgSerie[Object.keys(tempAvgSerie)[0]],
              tempTopSpeed[Object.keys(tempTopSpeed)[0]],
              tempCategory,
            ];
      let tempSort = sortArrays(dataort);
      updateSeries([
        {
          name: "Avg Speed",
          data: valueOrder === "Top" ? tempSort[1] : tempSort[0],
        },
        {
          name: "Top Speed",
          data: valueOrder === "Top" ? tempSort[0] : tempSort[1],
        },
      ]);

      updateWeekly(tempAvgSerie, tempTopSpeed);
      updateTitle(Object.keys(tempAvgSerie)[0], dataort[2]);
    } else {
      let tempSeriesA = { ...avgWeekly },
        tempSeriesT = { ...topSpeedWeekly };
      tempCategory = [...categories];
      let dataort =
        valueOrder === "Top"
          ? [
              tempSeriesT[Object.keys(tempSeriesT)[currentPeriodTemp - 1]],
              tempSeriesA[Object.keys(tempSeriesA)[currentPeriodTemp - 1]],
              tempCategory,
            ]
          : [
              tempSeriesA[Object.keys(tempSeriesA)[currentPeriodTemp - 1]],
              tempSeriesT[Object.keys(tempSeriesT)[currentPeriodTemp - 1]],
              tempCategory,
            ];
      sortArrays(dataort).forEach((el, index) => {
        if (index === 0) {
          dataort[0] = el;
        } else if (index === 1) {
          dataort[1] = el;
        } else {
          dataort[2] = el;
        }
      });

      valueOrder === "Top"
        ? updateSeries([
            { name: "Avg Speed", data: dataort[1] },
            { name: "Top Speed", data: dataort[0] },
          ])
        : updateSeries([
            { name: "Avg Speed", data: dataort[0] },
            { name: "Top Speed", data: dataort[1] },
          ]);
      updateTitle(Object.keys(tempSeriesA)[currentPeriodTemp - 1], dataort[2]);
    }
  }

  /* assegna le serie per monthly */
  function updateMonthly(tempAvg, tempTopSpeed) {
    if (avgMonthly.length === 0 || !_.isEqual(tempAvg, avgMonthly)) {
      setAvgMonthly(tempAvg);
    }
    if (
      topSpeedMonthly.length === 0 ||
      !_.isEqual(tempTopSpeed, topSpeedMonthly)
    ) {
      setTopSpeedMonthly(tempTopSpeed);
    }
  }

  /* calcola i mesi del periodo calcolato */
  function calcMonth() {
    let monthNames = [];
    for (let indexDate = 0; indexDate <= 11; indexDate++) {
      monthNames.push(
        new Date(2001, indexDate, 1).toLocaleString("en", { month: "long" })
      );
    }

    let months = [];
    let fromYear = tempFrom.getFullYear();
    let toYear = tempTo.getFullYear();
    let diffYear = 12 * (toYear - fromYear) + tempTo.getMonth();
    for (let i = tempFrom.getMonth(); i <= diffYear; i++) {
      months.push(monthNames[i % 12] + " " + Math.floor(fromYear + i / 12));
    }

    return months;
  }

  /* algoritmo per monthly */
  function monthly() {
    setPeriodNavHidden(false);
    let currentPeriodTemp = currentPeriod;
    let tempCategory;

    let tempCalcMonth;
    if (textPeriod !== "monthly") {
      setTextPeriod("monthly");
      setCurrentPeriod(1);
      currentPeriodTemp = 1;

      tempCalcMonth = calcMonth();

      setTotalPeriod(tempCalcMonth.length);
    }

    if (Object.keys(avgMonthly).length === 0) {
      let tempTopSpeed = [],
        tempAvgSerie = [];
      let tempdataeries = [];

      tempCalcMonth.forEach((item) => {
        tempTopSpeed[item] = [];
        tempAvgSerie[item] = [];

        tempdataeries.push({ date: item, kms: [] });
      });

      for (const [key] of Object.entries(tempTopSpeed)) {
        for (let j = 0; j < categories.length; j++) {
          tempTopSpeed[key][j] = "";
          tempAvgSerie[key][j] = "";
        }
      }

      series.forEach((item, index) => {
        item.data.forEach((kms) => {
          tempdataeries.forEach((temp) => {
            if (
              temp.date.includes(
                new Date(kms.date).toLocaleString("en", { month: "long" })
              )
            ) {
              temp.kms[index] = kms.kms;
            }
          });
        });
      });

      tempdataeries.forEach((temp) => {
        temp.kms.forEach((kms, index) => {
          tempTopSpeed[temp.date][index] = Math.max.apply(null, kms);

          const sum = kms.reduce((a, b) => a + b, 0);
          const avg = sum / kms.length || 0;

          tempAvgSerie[temp.date][index] = avg;
        });
      });

      tempCategory = [...categories];
      let dataort =
        valueOrder === "Top"
          ? [
              tempTopSpeed[Object.keys(tempTopSpeed)[0]],
              tempAvgSerie[Object.keys(tempAvgSerie)[0]],
              tempCategory,
            ]
          : [
              tempAvgSerie[Object.keys(tempAvgSerie)[0]],
              tempTopSpeed[Object.keys(tempTopSpeed)[0]],
              tempCategory,
            ];
      let tempSort = sortArrays(dataort);
      updateSeries([
        {
          name: "Avg Speed",
          data: valueOrder === "Top" ? tempSort[1] : tempSort[0],
        },
        {
          name: "Top Speed",
          data: valueOrder === "Top" ? tempSort[0] : tempSort[1],
        },
      ]);

      updateMonthly(tempAvgSerie, tempTopSpeed);
      updateTitle(Object.keys(tempAvgSerie)[0], dataort[2]);
    } else {
      let tempSeriesA = { ...avgMonthly },
        tempSeriesT = { ...topSpeedMonthly };
      tempCategory = [...categories];
      let dataort =
        valueOrder === "Top"
          ? [
              tempSeriesT[Object.keys(tempSeriesT)[currentPeriodTemp - 1]],
              tempSeriesA[Object.keys(tempSeriesA)[currentPeriodTemp - 1]],
              tempCategory,
            ]
          : [
              tempSeriesA[Object.keys(tempSeriesA)[currentPeriodTemp - 1]],
              tempSeriesT[Object.keys(tempSeriesT)[currentPeriodTemp - 1]],
              tempCategory,
            ];
      sortArrays(dataort).forEach((el, index) => {
        if (index === 0) {
          dataort[0] = el;
        } else if (index === 1) {
          dataort[1] = el;
        } else {
          dataort[2] = el;
        }
      });

      valueOrder === "Top"
        ? updateSeries([
            { name: "Avg Speed", data: dataort[1] },
            { name: "Top Speed", data: dataort[0] },
          ])
        : updateSeries([
            { name: "Avg Speed", data: dataort[0] },
            { name: "Top Speed", data: dataort[1] },
          ]);
      updateTitle(Object.keys(tempSeriesA)[currentPeriodTemp - 1], dataort[2]);
    }
  }

  const onChange = (valueSelected) => {
    switch (valueSelected) {
      case "All period":
        allPeriod();
        break;

      case "Daily":
        days();
        break;

      case "Weekly":
        weekly();
        break;

      case "Monthly":
        monthly();
        break;

      default:
        break;
    }
  };

  const handleNext = () => {
    let title,
      tempSeriesA,
      tempSeriesT,
      tempCategory = [...categories];

    if (textPeriod === "days") {
      tempSeriesA = avgDays[Object.keys(avgDays)[currentPeriod]];
      tempSeriesT = topSpeedDays[Object.keys(topSpeedDays)[currentPeriod]];

      let titleday = new Date(Object.keys(avgDays)[currentPeriod]);
      title =
        titleday.getDate() +
        " " +
        titleday.toLocaleDateString("en", { month: "long" }) +
        " " +
        titleday.getFullYear();
    } else if (textPeriod === "weekly") {
      tempSeriesA = avgWeekly[Object.keys(avgWeekly)[currentPeriod]];
      tempSeriesT = topSpeedWeekly[Object.keys(topSpeedWeekly)[currentPeriod]];
      title = Object.keys(avgWeekly)[currentPeriod];
    } else if (textPeriod === "monthly") {
      tempSeriesA = avgMonthly[Object.keys(avgMonthly)[currentPeriod]];
      tempSeriesT =
        topSpeedMonthly[Object.keys(topSpeedMonthly)[currentPeriod]];
      title = Object.keys(avgMonthly)[currentPeriod];
    }

    let dataort =
      valueOrder === "Top"
        ? [tempSeriesT, tempSeriesA, tempCategory]
        : [tempSeriesA, tempSeriesT, tempCategory];
    sortArrays(dataort).forEach((el, index) => {
      if (index === 0) {
        dataort[0] = el;
      } else if (index === 1) {
        dataort[1] = el;
      } else {
        dataort[2] = el;
      }
    });

    valueOrder === "Top"
      ? updateSeries([
          { name: "Avg Speed", data: dataort[1] },
          { name: "Top Speed", data: dataort[0] },
        ])
      : updateSeries([
          { name: "Avg Speed", data: dataort[0] },
          { name: "Top Speed", data: dataort[1] },
        ]);

    updateTitle(title, dataort[2]);
    setCurrentPeriod(currentPeriod + 1);
  };

  const handlePrev = () => {
    let title,
      tempSeriesA,
      tempSeriesT,
      tempCategory = [...categories];
    if (textPeriod === "days") {
      tempSeriesA = avgDays[Object.keys(avgDays)[currentPeriod - 2]];
      tempSeriesT = topSpeedDays[Object.keys(topSpeedDays)[currentPeriod - 2]];

      let titleday = new Date(Object.keys(avgDays)[currentPeriod - 2]);
      title =
        titleday.getDate() +
        " " +
        titleday.toLocaleDateString("en", { month: "long" }) +
        " " +
        titleday.getFullYear();
    } else if (textPeriod === "weekly") {
      tempSeriesA = avgWeekly[Object.keys(avgWeekly)[currentPeriod - 2]];
      tempSeriesT =
        topSpeedWeekly[Object.keys(topSpeedWeekly)[currentPeriod - 2]];
      title = Object.keys(avgWeekly)[currentPeriod - 2];
    } else if (textPeriod === "monthly") {
      tempSeriesA = avgMonthly[Object.keys(avgMonthly)[currentPeriod - 2]];
      tempSeriesT =
        topSpeedMonthly[Object.keys(topSpeedMonthly)[currentPeriod - 2]];
      title = Object.keys(avgMonthly)[currentPeriod - 2];
    }

    let dataort =
      valueOrder === "Top"
        ? [tempSeriesT, tempSeriesA, tempCategory]
        : [tempSeriesA, tempSeriesT, tempCategory];
    sortArrays(dataort).forEach((el, index) => {
      if (index === 0) {
        dataort[0] = el;
      } else if (index === 1) {
        dataort[1] = el;
      } else {
        dataort[2] = el;
      }
    });

    valueOrder === "Top"
      ? updateSeries([
          { name: "Avg Speed", data: dataort[1] },
          { name: "Top Speed", data: dataort[0] },
        ])
      : updateSeries([
          { name: "Avg Speed", data: dataort[0] },
          { name: "Top Speed", data: dataort[1] },
        ]);

    updateTitle(title, dataort[2]);
    setCurrentPeriod(currentPeriod - 1);
  };

  const [valueOrder, setValueOrder] = useState("Top");
  const changeDropdownState = (orderSelected) => {
    let tempSeriesA = graphSeries[0].data,
      tempSeriesT = graphSeries[1].data,
      tempCategory = [...categories],
      dataort = [];
    if (orderSelected.includes("Top")) {
      dataort = [tempSeriesT, tempSeriesA, tempCategory];
      sortArrays(dataort).forEach((el, index) => {
        if (index === 0) {
          dataort[0] = el;
        } else if (index === 1) {
          dataort[1] = el;
        } else {
          dataort[2] = el;
        }
      });
      updateSeries([
        { name: "Avg Speed", data: dataort[1] },
        { name: "Top Speed", data: dataort[0] },
      ]);
      setValueOrder("Top");
    } else {
      dataort = [tempSeriesA, tempSeriesT, tempCategory];
      sortArrays(dataort).forEach((el, index) => {
        if (index === 0) {
          dataort[0] = el;
        } else if (index === 1) {
          dataort[1] = el;
        } else {
          dataort[2] = el;
        }
      });
      updateSeries([
        { name: "Avg Speed", data: dataort[0] },
        { name: "Top Speed", data: dataort[1] },
      ]);
      setValueOrder("Avg");
    }

    updateTitle(graphOption.title.text, dataort[2]);
  };

  return (
    <div className="mn-graph">
      <div className="graph-dropdown">
        <PeriodNav
          textPeriod={textPeriod}
          currentPeriod={currentPeriod}
          totalPeriod={totalPeriod}
          handlePrev={() => handlePrev()}
          handleNext={() => handleNext()}
          hidden={periodNavHidden}
        />
        <div
          className="radioDropdownClass"
          style={{ display: disablePeriodItems }}
        >
          <RadioDropdown
            radioList={allPeriodSelect}
            widthDropdown={widthDropdownAllPeriod}
            onChange={onChange}
          />
        </div>
        <div>
          <RadioDropdown
            radioList={orderSelect}
            widthDropdown={orderSelectWidth}
            onChange={changeDropdownState}
          />
        </div>
      </div>
      <div>
        <ReactApexChart
          options={graphOption}
          series={graphSeries}
          type="bar"
          width={size.width}
          height={size.height}
        />
      </div>
    </div>
  );
};

SpeedReport.propTypes = {
  /**
   * Data search from
   */
  searchFrom: PropTypes.string.isRequired,
  /**
   * Data search to
   */
  searchTo: PropTypes.string.isRequired,

  /**
   * Series of data to render.
   * name: name of driver
   * data: array of date and kms
   * @see https://apexcharts.com/docs/options/series/
   */
  series: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      data: PropTypes.arrayOf(
        PropTypes.shape({
          date: PropTypes.string.isRequired,
          kms: PropTypes.array.isRequired,
        }).isRequired
      ).array,
    }).isRequired
  ).isRequired,
  /**
   * Size of the chart.
   */
  size: PropTypes.shape({
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
};
