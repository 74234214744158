import "./HeaderMenu.css";

interface HeaderMenuProps {
  children: object;
}

function HeaderMenu({ children }: HeaderMenuProps) {
  return (
    <div className="row header-menu">
      <div className="col col-16">{children}</div>
    </div>
  );
}

export default HeaderMenu;
