import { t } from "i18next";
import { useMemo } from "react";
import { formatTime } from "../../utils/DateAndTimeUtils";
import {
  kmPerHToMilesPerH,
  kmToMiles,
  ltToGal,
  mtToKm,
  numberAnnotation,
} from "../../utils/Utils";
import { Card } from "../Card/Card";
import { DataLabel } from "../DataLabel/DataLabel";
import { IconAverageSpeed } from "../Icon/Line/AverageSpeed";
import { IconClock } from "../Icon/Line/Clock";
import { IconEuro } from "../Icon/Line/Euro";
import { IconGasStation } from "../Icon/Line/GasStation";
import { IconMenuRoutes } from "../Icon/Line/MenuRoutes";
import { IconParking } from "../Icon/Line/Parking";
import { IconSpeed } from "../Icon/Line/Speed";
import { IconDriver } from "../Icon/Solid/Driver";
import "./SummaryDetails.css";

export const SummaryDetails = ({ details, preference }) => {
  const tracksOrStopsCounterString = useMemo(() => {
    if (
      details?.trackCounter === undefined &&
      details?.stopCounter !== undefined
    ) {
      return `${t("locationHistory.trackSummary.title.onlyStop").replace(
        "*name",
        details?.stopCounter
      )}`;
    }
    return (
      `${t("locationHistory.trackSummary.title.stopTrack")
        .replace(
          "*name1",
          details?.trackCounter !== undefined ? details?.trackCounter : 0
        )
        .replace(
          "*name2",
          details?.stopCounter !== undefined ? details?.stopCounter : 0
        )}` +
      " " +
      t("common.completedPlural")
    );
  }, [details]);

  return (
    <Card
      className="mn-lh-summary-details"
      title={t("locationHistory.summaryDetails.title")}
      hasCollapse={true}
    >
      <div className="description-summary-details">
        {tracksOrStopsCounterString}
      </div>
      <div className="mn-lh-summary-details__details">
        {details?.totalKm !== undefined && (
          <div className="total-km">
            <DataLabel
              dataText={
                preference.isMetric
                  ? numberAnnotation(mtToKm(details.totalKm)) + " km"
                  : numberAnnotation(kmToMiles(mtToKm(details.totalKm))) +
                    " mil"
              }
              icon={<IconMenuRoutes size={14} />}
              label={t("locationHistory.trackDetails.totalKm")}
            />
          </div>
        )}
        {false && details.cost !== undefined && (
          <div className="cost">
            <DataLabel
              dataText={numberAnnotation(details.cost) + " €"}
              icon={<IconEuro size={14} />}
              label={t("locationHistory.trackDetails.cost")}
            />
          </div>
        )}
        {details?.totalParkingTime !== undefined && (
          <div className="parking-time">
            <DataLabel
              dataText={formatTime(
                details.totalParkingTime * 1000,
                preference.language
              )}
              icon={<IconParking size={14} />}
              label={t("locationHistory.summaryDetails.stopTime")}
            />
          </div>
        )}
        {details?.trackTime !== undefined && (
          <div className="track-time">
            <DataLabel
              dataText={formatTime(
                details.trackTime * 1000,
                preference.language
              )}
              icon={<IconDriver size={14} />}
              label={t("locationHistory.summaryDetails.trackTime")}
            />
          </div>
        )}
        {details?.utilization !== null && details?.utilization !== undefined && (
          <div className="utilization">
            <DataLabel
              dataText={details.utilization}
              icon={<IconClock size={14} />}
              label={t("locationHistory.summaryDetails.utilization")}
            />
          </div>
        )}
        {details?.fuelCons !== undefined && (
          <div className="max-speed">
            <DataLabel
              dataText={
                preference.isMetric
                  ? numberAnnotation(details.fuelCons) + " lt"
                  : numberAnnotation(ltToGal(details.fuelCons)) + " gal"
              }
              icon={<IconGasStation size={14} />}
              label={t("locationHistory.trackDetails.fuelCons")}
            />
          </div>
        )}
        {details?.avgSpeed !== undefined && (
          <div className="avg-speed">
            <DataLabel
              dataText={
                preference.isMetric
                  ? numberAnnotation(details.avgSpeed) + " km/h"
                  : numberAnnotation(kmPerHToMilesPerH(details.avgSpeed)) +
                    " mph"
              }
              icon={<IconAverageSpeed size={15} />}
              label={t("locationHistory.trackDetails.avgSpeed")}
            />
          </div>
        )}
        {details?.maxSpeed !== undefined && (
          <div className="max-speed">
            <DataLabel
              dataText={
                preference.isMetric
                  ? numberAnnotation(details.maxSpeed) + " km/h"
                  : numberAnnotation(kmPerHToMilesPerH(details.maxSpeed)) +
                    " mph"
              }
              icon={<IconSpeed size={13} />}
              label={t("locationHistory.trackDetails.maxSpeed")}
            />
          </div>
        )}
      </div>
    </Card>
  );
};
