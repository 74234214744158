import i18next, { t } from "i18next";
import _ from "lodash";
import React, { useEffect } from "react";
import { ToastNotification } from "../../utils/ToastNotification";
import { IconArrowDown } from "../Icon/Line/ArrowDown";
import { Geofence } from "../Map/GeofenceDataStructure";
import { ChildDropdown, Option } from "./ChildDropdown";
import "./GeofencesDropdown.css";

interface GeofencesDropdownProps {
  options: Option[];
  valueSelected?: Geofence[];
  getValueSelected?: (e: any) => any;
  loading: boolean;
}

export const GeofencesDropdown: React.FC<GeofencesDropdownProps> = ({
  options,
  getValueSelected,
  valueSelected,
  loading,
}: GeofencesDropdownProps) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [isActive, setIsActive] = React.useState(true);
  const [value, setValue] = React.useState<Geofence[]>([]);
  const parentRef = React.useRef<any>(null);
  const wrapperRef = React.useRef<any>(null);

  const optionChildDropdown = () => {
    if (options.length > 0) {
      const option = {
        label: i18next.t("report.filterBar.geofencesDropdownSelectAll"),
        hasCheckbox: true,
        hasDropdown: false,
        hasCount: true,
        geofences: [],
      } as Option;
      const selectAll: Option[] = [];
      selectAll.push(option);
      options.forEach((x) => selectAll.push(x));

      return selectAll;
    }

    return [];
  };

  useEffect(() => {
    if (loading) {
      setIsActive(true);
    } else {
      if (!loading && options.length === 0) {
        setIsActive(false);
        setIsOpen(false);
      } else {
        setIsActive(true);
      }
    }
  }, [loading, options]);

  useEffect(() => {
    if (!_.isEqual(value, valueSelected)) {
      valueSelected !== undefined && setValue(valueSelected);
    }
  }, [valueSelected]);

  useEffect(() => {
    getValueSelected && getValueSelected(value !== undefined ? value : []);
  }, [value]);

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (
        wrapperRef.current &&
        !wrapperRef.current?.contains(event.target) &&
        !parentRef.current?.contains(event.target)
      ) {
        setIsOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  function calculateWidth() {
    if (selectedGeofences() === "0") return "180px";
    else return selectedGeofences().length + 35 + "ch";
  }

  function selectedGeofences() {
    let total = value.length;
    return `${total}`;
  }

  function displayValue() {
    if (selectedGeofences() === "0") return "";
    else if (selectedGeofences() === "1") return `${value[0].name}`;
    else return `${selectedGeofences()} ${i18next.t("common.geofences")}`;
  }

  function getDropdDownClass() {
    if (isOpen && isActive) {
      return "geofences-dropdown-btn selected";
    } else if (value.length !== 0) {
      return "geofences-dropdown-btn active";
    } else {
      return "geofences-dropdown-btn";
    }
  }

  return (
    <React.Fragment>
      <div
        ref={parentRef}
        style={{ width: calculateWidth() }}
        data-testid="dropdown-btn"
        className={getDropdDownClass()}
        onClick={() => {
          if (!isActive) {
            ToastNotification({
              toastId: "noGeofenceDefined",
              status: "default",
              description: t("report.filterBar.noGeofencesDropdown"),
            });
          } else {
            setIsOpen(!isOpen);
          }
        }}
      >
        <span className="geofences-dropdown-span">
          <input
            type="text"
            disabled={!(isActive || loading)}
            readOnly
            placeholder={i18next.t("report.filterBar.geofencesDropdownLabel")}
            value={displayValue()}
          />
        </span>
        <div
          className={`geofences-dropdown-arrow ${isOpen ? "open" : null} ${
            !isActive ? "iconDisabled" : null
          }`}
        >
          <IconArrowDown size={12} color="--global-colors-ink-light" />
        </div>
      </div>
      <div ref={wrapperRef}>
        <ChildDropdown
          visible={isOpen}
          hasSearch
          options={optionChildDropdown()}
          value={value}
          setValue={setValue}
          type={"geofences"}
          loading={loading}
          onlyCheckbox={true}
        />
      </div>
    </React.Fragment>
  );
};
