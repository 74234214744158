import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { normalize } from "normalizr";
import { RootState } from "../../../app/store";
import { GTFleetSuccessCodes } from "../../../config/GTFleetSuccessCodes";
import { publicRouteStopsSchema } from "./publicRouteStopNormalization";
import PublicRouteStopRepository from "./publicRouteStopRepository";

export const PublicStopStatusEnum = {
  ACTIVE: "ACTIVE",
  SUPPRESSED: "SUPPRESSED",
  DELETED: "DELETED",
};

export type PublicStopStatusEnum = keyof typeof PublicStopStatusEnum;

export interface PublicRouteAndStops {
  id: number;
  color: string;
  shortName: string;
  longName: string;
  publicRouteType: string;
  publicStops: PublicRouteStop[];
}

export interface PublicRouteStop {
  routeStopId: number;
  name: string;
  code: string;
  isSmart: boolean;
  terminus: boolean;
  latitude: any;
  longitude: any;
  publicStopsTimes: publicStopsTime[];
}

export interface publicStopsTime {
  tripIndex: number;
  arrivalTime: string;
  status: PublicStopStatusEnum;
  monthsSeasonalExecution: number[];
  publicTripCode: string;
}

const publicRouteStopAdapter = createEntityAdapter<PublicRouteAndStops>({
  selectId: (publicRouteStop) => publicRouteStop.id,
});

export const getPublicRouteStopsAsync = createAsyncThunk(
  "pblicRouteStops/getPublicRouteStops",
  async () => {
    try {
      const publicRouteStopRepository = new PublicRouteStopRepository();
      const response = await publicRouteStopRepository.getPublicRouteStops();
      const normalizedData = normalize(response.data, publicRouteStopsSchema);
      return normalizedData.entities;
    } catch (err: any) {
      if (!err.response) throw err;
      return err.response.data.message;
    }
  }
);

export const publicRouteStopSlice = createSlice({
  name: "publicRouteStop",
  initialState: publicRouteStopAdapter.getInitialState({
    status: "idle",
    reasonCode: "",
  }),
  reducers: {
    publicRouteStopEmptyState: (state: any) => {
      publicRouteStopAdapter.setAll(state, []);
      state.reasonCode = "";
      state.status = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPublicRouteStopsAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(
        getPublicRouteStopsAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          action.payload.publicRouteStop &&
            publicRouteStopAdapter.setAll(
              state,
              action.payload.publicRouteStop
            );
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.GET;
        }
      )
      .addCase(getPublicRouteStopsAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = "";
      });
  },
});

export const publicRouteStopSelector =
  publicRouteStopAdapter.getSelectors<RootState>(
    (state) => state.publicRouteStops
  );

export const selectPublicRouteStopStatus = (state: any) =>
  state.publicRouteStops.status;
export const selectPublicRouteStopReasonCode = (state: any) =>
  state.publicRouteStops.reasonCode;
export const { publicRouteStopEmptyState } = publicRouteStopSlice.actions;
export default publicRouteStopSlice.reducer;
