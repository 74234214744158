import React from 'react'
import PropTypes from 'prop-types'

import { IconLocator } from "../Icon/Solid/Locator"

import './location-information.css'

export const LocationInformation = ({ location, time, className, ...props }) => {
	return (
		<div className={['location-information', className].join(' ')} {...props}>
			<div className="icon">
				<IconLocator size={14} />
			</div>
			<div className="location-information__details">
				<p className="location">{location}</p>
				<time >{time}</time>
			</div>
		</div>
	)
}

LocationInformation.propTypes = {
	location: PropTypes.string,
	time: PropTypes.string
}

LocationInformation.defaultProps = {
	location: 'Roma, Autostrada Roma L\'Aquila Teramo',
	time: '10:58'
}